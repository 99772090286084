import { t } from "i18next";
import React from "react";
import PlusCircleIcon from "../../../assets/icons/plus-circle-white.svg?react";
import RobotIcon from "../../../assets/icons/robot.svg?react";
import { Button } from "../../Common/Button/Button";

export const NoAutomationAutomationList: React.FC<{
  onAddAutomation: () => void;
}> = ({ onAddAutomation }) => {
  return (
    <div
      className="flex items-center justify-center w-full p-4 mt-2 bg-element-background rounded-6px"
      style={{ height: "calc(100vh - 150px)" }}
    >
      <div className="flex flex-col items-center">
        <RobotIcon width={56} height={56} />

        <p className="my-4 font-semibold text-xxl text-high-contrast">
          {t("Automation.NoAutomation.title")}
        </p>

        <div className="flex gap-4 mt-4">
          <div className="w-72">
            <Button
              type="primary"
              RightIcon={PlusCircleIcon}
              onClick={onAddAutomation}
            >
              {t("Automation.NoAutomation.addAutomation")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
