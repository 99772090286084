import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import { useFetchDays } from "../../../hooks/api/calendar";
import { CalendarDay } from "../../../pages/CalendarList/CalendarListPage.type";
import { CalendarInputValueRange } from "../CalendarInput/CalendarInput.type";
import { DateRangePreset } from "./DateFilter.type";

/**
 *
 * @param dates
 * @param onStart
 * @param onSuccessFetchDays
 * @param onErrorFetchDays
 * @param onStartFetchDays
 * @param onEndFetchDays
 */
export const useHandleChangeDates = (
  selectedWorkspaces: ValueType[] | null,
  dates: CalendarInputValueRange,
  onStart: (nbRangeDate: number) => void,
  onSuccessFetchDays: (days: CalendarDay | undefined) => void,
  onErrorFetchDays: (message: string | undefined) => void,
  onStartFetchDays?: () => void,
  onEndFetchDays?: () => void
) => {
  if (dates.length >= 2) {
    const nextStartDate: Date | null = dates[0];
    const nextEndDate: Date | null = dates[1];

    onStart(moment(nextEndDate).diff(moment(nextStartDate), "days") + 1);

    if (
      (nextStartDate === null && nextEndDate === null) ||
      (nextStartDate instanceof Date && nextEndDate instanceof Date)
    ) {
      useFetchDays({
        from: nextStartDate,
        to: nextEndDate,
        onSuccess: onSuccessFetchDays,
        onError: onErrorFetchDays,
        onStart: onStartFetchDays,
        onEnd: onEndFetchDays,
      });
    }
  }
};

/**
 *
 * @param value
 * @param setDateRangePreset
 * @param setIsDateRangePreset
 */
export const useChangeDateRangePreset = (
  value: ValueType,
  setDateRangePreset: Dispatch<SetStateAction<DateRangePreset>>,
  setIsDateRangePreset: Dispatch<SetStateAction<boolean>>
) => {
  setDateRangePreset(value as DateRangePreset);
  setIsDateRangePreset(false);
};
