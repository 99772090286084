import React from "react";
import { Title } from "../../../Common/Title/Title";
import { useTranslation } from "react-i18next";
import { RegisterTypeInputs, RegisterTypeProps } from "./RegisterType.type";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../Common/Button/Button";
import { RegisterTypeCheckboxes } from "./RegisterTypeCheckboxes";
import ArrowRight from "../../../../assets/icons/arrow-right-white.svg?react";
import { Keys } from "../../../../values";

export const RegisterType: React.FC<RegisterTypeProps> = ({
  firstName = "firstName",
  investor,
  sub,
  janitorial,
  agency,
  loading = false,
  onSubmit,
  onChange,
}) => {
  const { t } = useTranslation();
  const {
    control,
    setError,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<RegisterTypeInputs>({
    defaultValues: {
      checkboxes: {
        investor,
        sub,
        janitorial,
        agency,
      },
    },
  });

  watch((data, { name }) => {
    if (name === "checkboxes" && typeof onChange === "function") {
      onChange({
        investor: data.checkboxes?.investor ?? false,
        sub: data.checkboxes?.sub ?? false,
        janitorial: data.checkboxes?.janitorial ?? false,
        agency: data.checkboxes?.agency ?? false,
      });
    }
  });

  const handleNext: SubmitHandler<RegisterTypeInputs> = (data) => {
    const {
      checkboxes: { investor, sub, janitorial, agency },
    } = data;

    if (!investor && !sub && !janitorial && !agency) {
      setError("checkboxes", {
        message: t("Register.Type.checkboxesError").toString(),
      });
    } else {
      onSubmit(data.checkboxes);
    }
  };

  return (
    <div>
      <Title>
        {t("Register.Type.hello")}, {firstName} !
      </Title>
      <Title>{t("Register.Type.title")}</Title>

      <div className="mt-6">
        <p className="font-semibold">{t("Register.Type.text1")}</p>
        <p className="font-light text-secondary">{t("Register.Type.text2")}</p>
      </div>

      <Controller
        control={control}
        name="checkboxes"
        render={({ field: { onChange, value } }) => (
          <RegisterTypeCheckboxes
            onChange={onChange}
            value={value}
            loading={loading}
            error={errors.checkboxes?.message}
          />
        )}
      />

      <div className="flex flex-1 mt-8">
        <Button
          RightIcon={ArrowRight}
          keyToSubmit={Keys.Enter}
          onClick={handleSubmit(handleNext)}
          loading={loading}
        >
          {t("Register.Type.submit")}
        </Button>
      </div>
    </div>
  );
};
