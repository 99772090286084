import React from "react";
import { useTranslation } from "react-i18next";

import RobotIcon from "../../../assets/icons/robot.svg?react";

export const ScheduledMessageNoData: React.FC<{}> = ({}) => {
  const { t } = useTranslation();

  return (
    <div className="flex-1 flex flex-col items-center justify-center">
      <RobotIcon className="h-12 w-12" />
      <p className="text-high-contrast text-base font-semibold mt-1">
        {t("ScheduledMessageList.NoData.title")}
      </p>

      <p className="text-low-contrast font-light text-center">
        {t("ScheduledMessageList.NoData.content1")}
        <span className="font-semibold">
          {t("ScheduledMessageList.NoData.content2")}
        </span>
        {t("ScheduledMessageList.NoData.content3")}
      </p>
    </div>
  );
};
