const cguText =
  `Conditions Générales d'utilisation (CGU)

<strong>1. Ullamco duis enim commodo cupidatat est eu incididunt nisi ea minim fugiat et.</strong>

Do ea officia commodo ea elit enim. Cupidatat pariatur nulla sit ea aliquip ullamco deserunt. Sit sit consequat quis cupidatat deserunt ut cupidatat ut sunt eiusmod officia. Enim elit et commodo do magna. Sunt esse culpa aute non sit commodo ullamco id sunt quis consequat commodo ea incididunt. Nisi occaecat aliquip pariatur quis non cillum incididunt fugiat. Velit anim velit ut cupidatat do in.

<strong>2. Veniam ipsum ad aliquip culpa minim aliquip ut do pariatur id cupidatat commodo irure.</strong>

Adipisicing duis sunt ea non esse commodo sint est. Consectetur tempor fugiat adipisicing cillum et. Non ad enim commodo occaecat irure cupidatat occaecat id. Aute aliquip elit et id irure aliquip irure. Reprehenderit veniam esse duis id veniam adipisicing.

<strong>3. Duis elit nostrud id dolore id excepteur ad nulla nostrud nisi est eiusmod duis amet.</strong>

Est do ea cillum id sint ullamco dolore commodo nostrud consectetur adipisicing non. Labore id ipsum magna duis ipsum. Id non labore velit veniam deserunt nostrud culpa ad incididunt veniam.

<strong>4. Ex eiusmod do occaecat ut sunt ex Lorem ad commodo amet reprehenderit.</strong>

Proident fugiat in fugiat amet cupidatat ipsum irure ipsum occaecat cupidatat id. Reprehenderit anim nisi sit commodo velit ut nisi irure mollit aute non nisi. Voluptate proident esse consequat occaecat ea anim labore duis id sit eiusmod occaecat minim est. Ad adipisicing Lorem occaecat nostrud dolore incididunt veniam nisi deserunt fugiat consectetur quis id aliqua.

<strong>5. Dolor nostrud consectetur ut sit minim do voluptate.</strong>

Ipsum amet fugiat aliquip incididunt. Incididunt cupidatat dolor incididunt ut magna dolor tempor. Labore in duis elit esse nostrud adipisicing veniam enim labore esse exercitation id in dolor. Cillum cillum ex incididunt ut ipsum sit officia nostrud fugiat. Eiusmod non esse culpa aute Lorem culpa culpa eu. Mollit quis excepteur reprehenderit commodo laboris nisi.
`
    .replace(/\n\s*/g, "<br>")
    .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");


export default cguText;
