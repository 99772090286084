import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TaskItemResponse } from "../../../types/GETTypes";
import { RightModal } from "../../Common/RightModal/RightModal";
import moment from "moment";
import { Separator } from "../../Common/Separator/Separator";
import { ChecklistCheckbox } from "../EditTaskScheduled/ChecklistCheckbox";
import EditIcon from "../../../assets/icons/edit.svg?react";
import { Button } from "../../Common/Button/Button";
import CheckCircleGreenIcon from "../../../assets/icons/check-circle-green.svg?react";
import { CleaninessInput } from "../EditTaskStarted/CleaninessInput";
import { CleaninessType } from "../EditTaskStarted/EditTaskStarted.type";
import { useModal } from "../../../hooks/useModal";
import { PreviewImage } from "../../Common/PreviewImage/PreviewImage";

export const EditTaskDone = ({
  task,
  isVisible,
  onComplete,
  onClose,
}: {
  task?: TaskItemResponse | undefined | null;
  isVisible: boolean;
  onComplete: (task: TaskItemResponse | undefined | null) => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const previewImage = useModal();

  const handleClose = () => {
    onClose();
  };

  const getOperatorsAvatars = (task: TaskItemResponse) => {
    return (
      <div
        className="relative"
        style={{ minWidth: 32 + 16 * (task.assigned_operators?.length - 1) }}
      >
        {task.assigned_operators?.map((ope, index) => (
          <img
            key={ope.id}
            className="size-8 rounded-full border-1 border-element-border"
            src={ope.photo}
            style={{ paddingLeft: index * 16 }}
          />
        ))}
      </div>
    );
  };

  const getOperatorsNames = (task: TaskItemResponse) => {
    const names = task.assigned_operators?.map(
      (ope) => `${ope.first_name} ${ope.last_name}`,
    );

    return (
      <p className="font-semibold text-high-contrast line-clamp-1">
        {names?.join(",")}
      </p>
    );
  };

  const getTitle = () => {
    return (
      <div className="flex items-center justify-between text-base">
        <p className="text-high-contrast font-semibold">{task?.name}</p>

        <div className="flex gap-2 bg-element-background-success rounded-6px items-center w-fit p-3 text-sm">
          <CheckCircleGreenIcon className="size-4" />
          <p className="text-success font-semibold">
            {t("Task.List.completed")}
          </p>
        </div>
      </div>
    );
  };

  return (
    <RightModal title={getTitle()} isVisible={isVisible} onClose={handleClose}>
      <div className="flex flex-col justify-between">
        <div className="flex-1 w-full flex flex-col gap-3 overflow-y-auto pb-4">
          <label className="text-low-contrast font-bold text-sm">
            {t("Global.info")}
          </label>

          <p>
            {t("Task.Modal.dateToDo")}{" "}
            <span className="font-semibold text-high-contrast">
              {moment(task?.scheduled_date).format("dddd DD MMMM YYYY")} -
              {" " + task?.scheduled_time}
            </span>
          </p>

          {/* Rental and Assignees */}
          <div className="flex justify-between">
            {/* Rental infos */}
            <div className="flex gap-2 my-2 mx-2 items-center">
              <img
                className="size-12 rounded-6px border-1 border-element-border shrink-0 cursor-pointer"
                src={task?.rental?.cover}
                onClick={() => {
                  previewImage.open({
                    image: "rental_cover",
                  });
                }}
              />

              {previewImage.data?.image === "rental_cover" && (
                <PreviewImage
                  imageUrl={task?.rental?.cover}
                  imageModal={previewImage}
                />
              )}

              <div className="flex flex-col">
                <p className="font-semibold text-high-contrast">
                  {task?.rental?.name}
                </p>
                <p className="font-light text-low-contrast">
                  {task?.rental?.address} {task?.rental?.postal_code}{" "}
                  {task?.rental?.city}
                </p>
              </div>
            </div>

            {task && (
              <div className="flex gap-2 items-center min-w-44">
                {getOperatorsAvatars(task)}
                <div className="flex flex-col">
                  {getOperatorsNames(task)}
                  <p className="text-low-contrast">
                    {t("Task.Modal.personAssigned")}
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* Remarks */}
          <div className="flex flex-col gap-1">
            {typeof task?.started_task_data?.next_reservation?.guest_remarks ===
              "string" && (
              <>
                <p className="text-low-contrast font-light">
                  {t("Task.Modal.remarkNextGuest")}
                </p>

                <p className="text-high-contrast font-semibold">
                  {task?.started_task_data?.next_reservation?.guest_remarks}
                </p>
              </>
            )}

            {typeof task?.started_task_data?.remark === "string" && (
              <>
                <p className="text-low-contrast font-light mt-3">
                  {t("Task.Modal.remarkTask")}
                </p>
                <p className="text-high-contrast font-semibold">
                  {task?.started_task_data?.remark}
                </p>
              </>
            )}

            {typeof task?.started_task_data?.next_reservation?.private_notes ===
              "string" && (
              <>
                <p className="text-low-contrast font-light mt-3">
                  {t("Task.Modal.remarkNextReservation")}
                </p>
                <p className="text-high-contrast font-semibold">
                  {task?.started_task_data?.next_reservation?.private_notes}
                </p>
              </>
            )}
          </div>

          <p className="font-semibold text-low-contrast">
            {t("Task.Modal.taskValidated", {
              date: moment
                .utc(
                  task?.completed_task_data?.simple_assignation
                    ?.validation_date,
                )
                .local()
                .format("DD/MM/YYYY"),

              time: moment
                .utc(
                  task?.completed_task_data?.simple_assignation
                    ?.validation_date,
                )
                .local()
                .format("HH:mm"),
            })}
          </p>
          {/* Display checklist */}
          {task?.completed_task_data?.simple_assignation?.checklist && (
            <>
              <ChecklistCheckbox
                disabled={true}
                checklistSections={JSON.parse(
                  task?.completed_task_data?.simple_assignation?.checklist ??
                    "",
                )}
              />

              <Separator />
            </>
          )}

          {/* Photos */}
          {(task?.completed_task_data?.simple_assignation?.photos?.length ??
            0) > 0 && (
            <>
              <p className="font-semibold text-low-contrast">
                {t("Task.Modal.importedPhotos")}
              </p>
              <div className="flex flex-wrap gap-3">
                {task?.completed_task_data?.simple_assignation?.photos?.map(
                  (photo) => (
                    <>
                      <img
                        key={photo.id}
                        className="border-1 border-element-border rounded-6px size-24 shrink-0 cursor-pointer"
                        src={photo.original_url}
                        onClick={() => {
                          previewImage.open({
                            image: `preview-${photo.original_url}`,
                          });
                        }}
                      />
                      {previewImage.data?.image ===
                        `preview-${photo.original_url}` && (
                        <PreviewImage
                          imageUrl={photo.original_url}
                          imageModal={previewImage}
                        />
                      )}
                    </>
                  ),
                )}
              </div>

              <Separator />
            </>
          )}

          {/* Notes and remarks */}
          <p className="font-semibold text-low-contrast">
            {t("Task.Modal.notesRemarks")}
          </p>

          <p>{t("Task.Modal.cleaniness")}</p>
          <CleaninessInput
            value={
              task?.completed_task_data.simple_assignation
                .cleanness as CleaninessType
            }
            onlyValue={true}
            onChange={() => {}}
          />

          {task?.type === "cleaning" && (
            <>
              <p>{t("Task.Modal.bedsChanged")}</p>
              <p className="font-semibold text-high-contrast">
                {t("Task.Modal.sleeping", {
                  count:
                    task?.completed_task_data?.simple_assignation
                      ?.cleaned_beds_count ?? 0,
                })}
              </p>
            </>
          )}

          {typeof task?.completed_task_data?.simple_assignation?.remarks ===
            "string" && (
            <>
              <p>{t("Task.Modal.remarks")}</p>
              <p className="font-semibold text-high-contrast">
                {task?.completed_task_data?.simple_assignation?.remarks}
              </p>
            </>
          )}
        </div>

        <div className="flex gap-1 flex-col">
          <Button
            type="secondary"
            RightIcon={EditIcon}
            onClick={() => onComplete(task)}
          >
            {t("Task.Modal.completeOrEdit")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
