import React, { useRef } from "react";
import { CalendarInputProps, CalendarInputValue } from "./CalendarInput.type";

import { DatePicker } from "@mantine/dates";
import "dayjs/locale/fr";
import { cn } from "../../../helpers/classHelper";
import { useOutsideDetector } from "../../../hooks/useOutsideDetector";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import "./CalendarInput.css";
import {
  useGetCalendarInputDefaultNode,
  useGetCalendarInputRangeNode,
  useGetCalendarInputs,
  useInitCalendarInput,
  useInitCalendarInputStates,
} from "./CalendarInput.hooks";

export const CalendarInput: React.FC<CalendarInputProps> = ({
  classNames,
  label,
  anchor = "start",
  register,
  disabled = false,
  required,
  value,
  allowSingleDateInRange = true,
  Node,
  height = "normal",
  dateType = "default",
  isVisible,
  isExcludeDate,
  onChangeStartDateInput = () => {},
  onChangeEndDateInput = () => {},
  onChangeDate = () => {},
  error,
}) => {
  const initStates = useInitCalendarInputStates();
  const [dates, setDates] = initStates.datesState;
  const [isPopupVisible, setPopupVisible] = initStates.isPopupVisibleState;
  const [focusedInput, setFocusedInput] = initStates.focusedInputState;

  const calendarInputRef = useRef<HTMLDivElement>(null);
  useOutsideDetector(calendarInputRef, () => setPopupVisible(false));
  const currentDate = new Date();

  useInitCalendarInput(
    value,
    [focusedInput, setFocusedInput],
    dateType,
    [dates, setDates],
    isVisible,
    setPopupVisible
  );

  return (
    <div
      className={cn(`flex flex-1 flex-col`, {
        "h-full": height === "full",
      })}
      ref={calendarInputRef}
    >
      <div className="flex items-start space-x-1">
        {label ? (
          <label
            className="mb-1 font-semibold text-left"
            htmlFor={`input-${register?.name}`}
          >
            {label}
          </label>
        ) : null}
        {label && required ? (
          <span className="relative text-lg -top-1 text-active">*</span>
        ) : null}
      </div>
      <div
        className={cn(
          `calendar-input relative flex items-center px-2 cursor-pointer border-1 border-element-border rounded-4px text-high-contrast`,
          {
            "h-8": height === "normal",
            "h-full": height === "full",
          },
          classNames?.button
        )}
        onClick={(e) => {
          if ((e.target as Element).classList.contains("calendar-input")) {
            setPopupVisible(!isPopupVisible);
          }
        }}
      >
        {Node ? (
          <div className="pointer-events-none select-none">{Node}</div>
        ) : (
          <>
            {dateType === "range"
              ? useGetCalendarInputRangeNode(dates)
              : useGetCalendarInputDefaultNode(dates)}
          </>
        )}

        {isPopupVisible ? (
          <div
            className={cn(
              `absolute z-20 px-2 pt-2 pb-4 select-none bg-element-background top-12 border-1 border-element-border rounded-8px`,
              {
                "left-0": anchor === "start",
                "right-0": anchor === "end",
              }
            )}
          >
            <div className="flex items-center justify-center mb-3 gap-x-3">
              {useGetCalendarInputs(
                dateType,
                dates,
                setFocusedInput,
                onChangeStartDateInput,
                onChangeEndDateInput
              )}
            </div>
            <DatePicker
              allowSingleDateInRange={allowSingleDateInRange}
              value={value ?? undefined}
              excludeDate={isExcludeDate}
              type={dateType}
              maxLevel="year"
              size="sm"
              locale="fr"
              minDate={currentDate}
              onChange={(value: CalendarInputValue) => onChangeDate(value)}
            />
          </div>
        ) : null}
      </div>
      {!isPopupVisible && error ? (
        <ErrorMessage errorType="FORM">{error}</ErrorMessage>
      ) : null}
    </div>
  );
};
