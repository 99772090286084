import React, { useState } from "react";
import SuperhoteIcon from "../../assets/icons/superhote.svg?react";
import { useTranslation } from "react-i18next";
import { Recover } from "../../components/Authentication/Recover/Recover";
import { RecoverSubmitType } from "../../components/Authentication/Recover/Recover.type";
import { RecoverThanks } from "../../components/Authentication/RecoverThanks/RecoverThanks";
import { ForgotPasswordPageProps } from "./ForgotPasswordPage.type";
import paths from "../../constants/paths";
import { usePost } from "../../hooks/usePost";
import { FullScreenLayout } from "../../components/Layout/FullScreenLayout/FullScreenLayout";

export const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = () => {
  const { t } = useTranslation();

  const { perform: post, success, loading, error } = usePost();

  const [email, setEmail] = useState<string>("");

  const handleRecover = async ({ email }: RecoverSubmitType) => {
    setEmail(email);
    post(`${import.meta.env.VITE_API_URL}${paths.API.FORGOT_PASSWORD}`, {
      email,
    })
  };

  return (
    <FullScreenLayout isCheckAuthEnabled={false}>
      {/* Left */}
      <div className="w-2/5 max-w-md p-2">
        <div className="flex flex-col min-h-full bg-subtle border-1 border-element-border/50 p-8 rounded-6px">
          <div className="flex-1 pb-4">
            <SuperhoteIcon />
          </div>

          <div className="flex flex-col gap-2">
            <p className="font-semibold text-high-contrast text-[18px]">
              {t("Recover.leftTitle1")}
            </p>
            <p className="font-semibold text-high-contrast text-[18px]">
              {t("Recover.leftTitle2")}
            </p>
            <p className="mt-1 text-sm font-light text-low-contrast">
              {t("Recover.leftSubTitle")}
            </p>
          </div>
        </div>
      </div>

      {/* Right */}
      <div className="flex-1 px-12 py-4">
        {!success ? (
          <Recover
            loading={loading}
            error={error ?? undefined}
            onSubmit={handleRecover}
          />
        ) : (
          <RecoverThanks email={email} />
        )}
      </div>
    </FullScreenLayout>
  );
};
