import React from "react";
import { useTranslation } from "react-i18next";
import CleanIcon from "../../../assets/icons/clean.svg?react";
import HatIcon from "../../../assets/icons/hat.svg?react";
import PersonWithLuggageIcon from "../../../assets/icons/person-with-luggage.svg?react";
import DotsVerticalIcon from "../../../assets/icons/dots-vertical.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import KeyIcon from "../../../assets/icons/key.svg?react";
import TrashActiveIcon from "../../../assets/icons/trash-active.svg?react";
import { TableListItemProps } from "../../Common/TableList/TableList.type";
import { OperationalManagementRoleListItemResponse } from "../../../types/GETTypes";
import { Button } from "../../Common/Button/Button";

export const ManagementRoleTableItem: React.FC<TableListItemProps> = ({
  data,
  onClick,
  onClickAction = () => {},
}) => {
  const { t } = useTranslation();
  const role: OperationalManagementRoleListItemResponse = data;

  const getDropItems = () => {
    const editButton = {
      label: t("Global.edit"),
      value: "edit",
      Icon: EditIcon,
    };
    const removeButton = {
      label: t("Global.remove"),
      value: "remove",
      Icon: TrashActiveIcon,
      isRed: true,
    };

    if (
      role.type === "house_cleaner" ||
      role.type === "house_owner" ||
      role.type === "manager"
    ) {
      return [editButton];
    } else return [editButton, removeButton];
  };

  return (
    <tr className="cursor-pointer hover:bg-element-background/40">
      <td className="py-3 px-2" onClick={() => onClick(role.id)}>
        <div className="flex items-center gap-2">
          {getRoleIcon(role)}
          <p className="font-semibold text-high-contrast">{role.name}</p>
        </div>
      </td>

      <td className="py-3 px-2" onClick={() => onClick(role.id)}>
        <div className="flex items-center gap-2">
          <p className="font-light text-low-contrast">
            {t("Management.RoleList.member", { count: role.members.length })}
          </p>
        </div>
      </td>

      <td className="py-3 px-2" onClick={() => onClick(role.id)}>
        <div className="flex items-center gap-2">
          <p className="font-light text-low-contrast">
            {t("Management.RoleList.access", {
              count: role.permissions_selected.length,
            })}
          </p>
        </div>
      </td>

      <td className="py-3 px-2">
        <div className="flex items-center gap-2">
          <Button
            RightIcon={DotsVerticalIcon}
            type="secondary"
            dropItems={getDropItems()}
            onClick={(value: any) => onClickAction(role.id, value)}
          />
        </div>
      </td>
    </tr>
  );
};

export const getRoleIcon = (
  role: OperationalManagementRoleListItemResponse
) => {
  if (role.type === "house_cleaner") return <CleanIcon className="size-4" />;
  else if (role.type === "manager") return <HatIcon className="size-4" />;
  else if (role.type === "house_owner") return <KeyIcon className="size-4" />;
  return <PersonWithLuggageIcon className="size-4" />;
};
