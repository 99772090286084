import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import ArrowRightIcon from "../../../assets/icons/arrow-right-white.svg?react";
import paths from "../../../constants/paths";
import { Keys } from "../../../values";
import { Button } from "../../Common/Button/Button";
import { Callout } from "../../Common/Callout/Callout";
import { Title } from "../../Common/Title/Title";
import { RecoverThanksProps } from "./RecoverThanks.type";

export const RecoverThanks: React.FC<RecoverThanksProps> = ({ email }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex w-1/5 mb-10">
        <Button
          onClick={() => navigate(-1)}
          LeftIcon={ArrowLeftIcon}
          type="secondary"
        >
          {t("Global.back")}
        </Button>
      </div>

      <Title>{t("RecoverThanks.title")}</Title>

      <div className="mt-8">
        <Callout
          title={t("RecoverThanks.calloutTitle")}
          message={`${t("RecoverThanks.calloutMessageP1")} ${email}, ${t(
            "RecoverThanks.calloutMessageP2"
          )}`}
        />
      </div>

      <div className="flex w-full mt-8">
        <Button
          keyToSubmit={Keys.Enter}
          onClick={() => navigate(paths.LOGIN)}
          RightIcon={ArrowRightIcon}
        >
          {t("RecoverThanks.login")}
        </Button>
      </div>
    </div>
  );
};
