import React from "react";
import {TableListItemProps} from "../Common/TableList/TableList.type";
import {Button} from "../Common/Button/Button";
import DotsVerticalIcon from "../../assets/icons/dots-vertical.svg?react";
import {useTranslation} from "react-i18next";
import TrashActiveIcon from "../../assets/icons/trash-active.svg?react";
import useUserStore from "../../stores/useUserStore";
import { getCurrentCanEdit } from "../../helpers/workspaceHelper";

export const ShortcodeListPageItem: React.FC<TableListItemProps> = ({
  data,
  onClick = () => {},
  onClickAction = () => {},
}) => {
  const { t } = useTranslation();
  const userStore = useUserStore();

  const handleClick = () => {
    if (data.type === "custom") {
      onClick(data.id);
    }
  };

  return (
    <tr className="cursor-pointer hover:bg-element-background/40 ml-4">
        {/* description */}
        <td
            className="px-4 py-2  text-sm align-middle border-t-1 border-element-border text-low-contrast font-light"
            onClick={handleClick}
        >
            {data.description}
        </td>
      {/* name */}
      <td
        className="flex flex-row items-center py-2 space-x-4 text-sm border-t-1 border-element-border text-high-contrast font-semibold h-12"
        onClick={handleClick}
      >
        {data.trigger}
      </td>

      {/* actions */}
      <td className="py-2 text-sm align-middle border-t-1 border-element-border">
        {data.type === "custom" && (
          <div className="flex gap-2">
            <div className="w-24">
              <Button
                type="secondary"
                disabled={
                  !getCurrentCanEdit({
                    user: userStore.user,
                    workspaceOwner: data.workspace_owner,
                  })
                }
                onClick={handleClick}
              >
                {t("Global.edit")}
              </Button>
            </div>
            <div className="w-10">
              <Button
                LeftIcon={DotsVerticalIcon}
                type="secondary"
                disabled={
                  !getCurrentCanEdit({
                    user: userStore.user,
                    workspaceOwner: data.workspace_owner,
                  })
                }
                dropItems={[
                  {
                    label: t("Global.remove"),
                    value: "remove",
                    isRed: true,
                    Icon: TrashActiveIcon,
                  },
                ]}
                onClick={(action: string) => onClickAction(data?.id, action)}
              />
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};
