import React from "react";
import { TitleProps } from "./Title.type";

export const Title: React.FC<TitleProps> = ({ children, type = "primary",style ="" }) => {
  if (type === "primary") {
    return <h1 className={`block m-0 py-2 font-bold text-xl ${style}`}>{children}</h1>;
  } else {
    return (
      <h1 className={`block m-0 pb-2 font-extralight text-secondary text-base ${style}`}>
        {children}
      </h1>
    );
  }
};
