import React, { useEffect, useState } from "react";
import {
  Template,
  TemplateListGroupType,
  TemplateListProps,
  TemplateListSortByType,
} from "./TemplatesModal.type";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../Common/TextInput/TextInput";
import { Button } from "../../Common/Button/Button";
import { InputSelect } from "../../Common/InputSelect/InputSelect";
import { TemplateGroupItem } from "./TemplateGroupItem";
import paths from "../../../constants/paths";
import { del, get, post } from "../../../helpers/APIHelper";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { TemplateGroupItemSkeleton } from "./TemplateGroupItemSkeleton";
import { RemoveTemplateModal } from "./RemoveTemplateModal";

import SearchIcon from "../../../assets/icons/search.svg?react";
import ChatIcon from "../../../assets/icons/double-messages.svg?react";

export const TemplateList: React.FC<TemplateListProps> = ({
  onAdd,
  onEdit = (template: Template) => {},
  onInsert = (template: Template) => {},
}) => {
  const { t } = useTranslation();

  const [isRemoveModalVisible, setRemoveModalVisible] =
    useState<boolean>(false);
  const [currentTemplateToRemove, setCurrentTemplateToRemove] = useState<
    Template | undefined | null
  >();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<null | string>(null);
  const [templates, setTemplates] = useState<Template[]>([]);

  const [currentSearch, setCurrentSearch] = useState<string>("");
  const [currentSortBy, setCurrentSortBy] =
    useState<TemplateListSortByType>("language");
  const [groups, setGroups] = useState<TemplateListGroupType[]>();

  const fetchTemplates = async () => {
    setLoading(true);
    setError(null);

    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.MESSAGE_TEMPLATES}`
    );

    if (response.data?.success) {
      setTemplates(response.data.result["messages_template"]);

      groupTemplates(response.data.result["messages_template"], currentSortBy);
    } else {
      setError(response.response.data.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const groupTemplates = (
    value: Template[],
    sortBy: TemplateListSortByType
  ) => {
    if (sortBy === "language") {
      setGroups(groupByLanguage(value));
    } else if (sortBy === "rental") {
      setGroups(groupByRental(value));
    }
  };

  const groupByLanguage = (data: Template[]) => {
    const groups: TemplateListGroupType[] = [];

    data.forEach((item: Template) => {
      const languageId: any = item.language.id;
      const languageName = item.language.name;

      if (!groups[languageId]) {
        groups[languageId] = {
          value: languageId,
          label: languageName ?? "",
          items: [],
        };
      }

      groups[languageId].items.push(item);
    });

    return Object.values(groups);
  };

  const groupByRental = (data: Template[]) => {
    const tmpGroups: TemplateListGroupType[] = [];

    data.forEach((item: Template) => {
      const rentalId: any = item.rental?.id ?? -1;
      const rentalName = item.rental?.name ?? t("Templates.generic");

      if (!tmpGroups[rentalId]) {
        tmpGroups[rentalId] = {
          value: rentalId,
          label: rentalName,
          items: [],
        };
      }

      tmpGroups[rentalId].items.push(item);
    });

    const orderedGroups = [];
    if (tmpGroups[-1]) {
      orderedGroups.push(tmpGroups[-1]);
    }
    orderedGroups.push(...tmpGroups.filter((g, index) => index !== -1));

    return Object.values(orderedGroups);
  };

  const searchAndGroup = (text: string, sortBy: TemplateListSortByType) => {
    if (text !== "") {
      const nextTemplates = templates?.filter((template) =>
        template.title?.toUpperCase().match(text.toUpperCase())
      );

      groupTemplates(nextTemplates, sortBy);
    } else groupTemplates(templates, sortBy);
  };

  const handleSearch = (value: string) => {
    setCurrentSearch(value);

    searchAndGroup(value, currentSortBy);
  };

  const handleSelectSortBy = (value: TemplateListSortByType) => {
    setCurrentSortBy(value as TemplateListSortByType);

    searchAndGroup(currentSearch, value);
  };

  const handleInsertTemplate = (template: Template) => {
    onInsert(template);
  };

  const handleEditTemplate = (template: Template) => {
    onEdit(template);
  };

  const handleDuplicateTemplate = async (template: Template) => {
    setLoading(true);
    setError(null);

    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.MESSAGE_TEMPLATES}`,
      {
        rental_id: template.rental?.id ?? null,
        language_code: template.language.code,
        title: template.title,
        message: template.message,
      }
    );

    if (response.data?.success) {
      await fetchTemplates();
    } else {
      setError(response.response.data.message);
    }

    setLoading(false);
  };

  const handleRemoveTemplate = async (template: Template) => {
    setCurrentTemplateToRemove(template);
    setRemoveModalVisible(true);
  };

  const handleConfirmRemoveTemplate = async () => {
    if (!currentTemplateToRemove) return;

    setRemoveModalVisible(false);
    setLoading(true);
    setError(null);

    const response = await del(
      `${import.meta.env.VITE_API_URL}${paths.API.MESSAGE_TEMPLATES}/${
        currentTemplateToRemove?.id
      }`
    );

    if (response.data?.success) {
      await fetchTemplates();
    } else {
      setError(response.response.data.message);
    }

    setLoading(false);
  };

  return (
    <>
      <RemoveTemplateModal
        isVisble={isRemoveModalVisible}
        template={currentTemplateToRemove!}
        onClose={() => setRemoveModalVisible(false)}
        onRemove={handleConfirmRemoveTemplate}
      />

      <div className="flex flex-col justify-between flex-1 w-full h-full gap-2">
          {(loading || templates?.length! > 0) && (
            <>
              <div className="flex flex-row space-x-3">
                <TextInput
                  placeholder={t("Templates.placeholderSearch")}
                  Icon={SearchIcon}
                  onChangeText={handleSearch}
                  disabled={loading}
                />
              </div>

              <div className="flex flex-col mt-2">
                <InputSelect
                  label={t("Global.sortBy")}
                  items={[
                    { label: t("Templates.sortByRentals"), value: "rental" },
                    {
                      label: t("Templates.sortByLanguages"),
                      value: "language",
                    },
                  ]}
                  selectedValue={currentSortBy}
                  onSelect={(value) =>
                    handleSelectSortBy(value as TemplateListSortByType)
                  }
                  disabled={loading}
                />
              </div>
            </>
          )}

          {<ErrorMessage>{error}</ErrorMessage>}

          {!loading && (!templates || templates.length === 0) && (
            <div className="">
              <div className="flex flex-row bg-element-background rounded-4px mb-4 w-full h-fit p-2 gap-2 ">
                <ChatIcon className="w-6 h-6" />
                <div className="flex-1">
                  <p className="font-semibold text-high-contrast">
                    {t("Templates.emptyTitle")}
                  </p>
                  <p className="font-light text-low-contrast mt-1">
                    {t("Templates.emptyContent")}
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="flex flex-col gap-3 mt-3 flex-1 overflow-y-scroll h-full">
            {loading && <TemplateGroupItemSkeleton />}

            {!loading &&
              groups?.map((group) => (
                <TemplateGroupItem
                  group={group}
                  withFlag={currentSortBy === "language"}
                  onInsert={handleInsertTemplate}
                  onEdit={handleEditTemplate}
                  onDuplicate={handleDuplicateTemplate}
                  onRemove={handleRemoveTemplate}
                />
              ))}
          </div>


        <div className="border-t-1 border-element-border pb-2 pt-4 mt-3">
          <Button onClick={onAdd}>{t("Templates.add")}</Button>
        </div>
      </div>
    </>
  );
};
