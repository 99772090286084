import React from "react";
import { useTranslation } from "react-i18next";
import ChevronDownIcon from "../../../assets/icons/chevron-down.svg?react";
import ChevronUpIcon from "../../../assets/icons/chevron-up.svg?react";

export type PaymentDepositListHeaderProps = {
  sortField: string;
  sortDirection: "asc" | "desc";
  onChangeSort: (field: string) => void;
};

export const PaymentDepositListHeader: React.FC<
  PaymentDepositListHeaderProps
> = ({ sortField, sortDirection, onChangeSort = () => {} }) => {
  const { t } = useTranslation();

  const handleChangeSort = (column: string) => {
    if (column === sortField && sortDirection === "asc") onChangeSort(column);
  };

  const getIcon = (column: string) => {
    const isActive = sortField === column;

    if (isActive && sortDirection === "asc") return <ChevronDownIcon />;
    else if (isActive && sortDirection === "desc") return <ChevronUpIcon />;
    else return <ChevronDownIcon />;
  };

  return (
    <tr className="bg-element-background border-b-1 border-element-border">
      <th
        className="w-3/12 py-3 text-sm ps-4 pe-2"
        onClick={() => handleChangeSort("name")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "name" ? "underline" : null
          }`}
        >
          <p>{t("Payments.PaymentDepositList.rentalLabel")}</p>{" "}
          {getIcon("name")}
        </div>
      </th>

      <th
        className="px-2 py-3 text-sm"
        onClick={() => handleChangeSort("enabled")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "enabled" ? "underline" : null
          }`}
        >
          <p>{t("Payments.PaymentDepositList.guestLabel")}</p>{" "}
          {getIcon("enabled")}
        </div>
      </th>

      <th
        className="px-2 py-3 text-sm"
        onClick={() => handleChangeSort("checkin")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "checkin" ? "underline" : null
          }`}
        >
          <p>{t("Payments.PaymentDepositList.platformLabel")}</p>{" "}
          {getIcon("checkin")}
        </div>
      </th>

      <th
        className="px-2 py-3 text-sm"
        onClick={() => handleChangeSort("checkout")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline justify-center ${
            sortField === "checkout" ? "underline" : null
          }`}
        >
          <p>{t("Payments.PaymentDepositList.paymentDateLabel")}</p>{" "}
          {getIcon("checkout")}
        </div>
      </th>

      <th
        className="px-2 py-3 text-sm"
        onClick={() => handleChangeSort("status_Deposit")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline justify-center ${
            sortField === "status_Deposit" ? "underline" : null
          }`}
        >
          <p>{t("Payments.PaymentDepositList.depositTypeLabel")}</p>{" "}
          {getIcon("status_Deposit")}
        </div>
      </th>

      <th
        className="py-3 text-sm ps-2 pe-4"
        onClick={() => handleChangeSort("status_payment")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline justify-end ${
            sortField === "status_payment" ? "underline" : null
          }`}
        >
          <p>{t("Payments.PaymentDepositList.amountLabel")}</p>{" "}
          {getIcon("status_payment")}
        </div>
      </th>
    </tr>
  );
};
