import React from "react";
import {
  RestrictionCellProps,
  RestrictionType,
} from "../../components/Calendar/Calendar.type";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {
  CALENDAR_CELL_WIDTH,
  CALENDAR_RESTRICTION_HEIGHT,
} from "../../constants/calendar";

export const CalendarRestrictionCell: React.FC<RestrictionCellProps> = ({
  index,
  rental,
  restrictionField,
  daysOffset,
  onClick,
}) => {
  const {t} = useTranslation();

  const date = moment(new Date()).add(index - daysOffset, "days");
  const isToday =
    moment(new Date()).format("YYYY-MM-DD") === date.format("YYYY-MM-DD");
  const restriction: RestrictionType = rental.restrictions.find(
    (r: any) => r.date === date.format("YYYY-MM-DD")
  );

  const getBorder = () => {
    if (date.format("DD") === "01") {
      return "border-l-1 border-[#5C6163]/20";
    } else return "";
  };

  const getValue = () => {
    if (
      restrictionField === "no_checkin" ||
      restrictionField === "no_checkout"
    ) {
      // @ts-ignore
      return restriction[restrictionField] === 1
        ? t("Global.yes")
        : t("Global.no");
      // @ts-ignore
    } else return restriction[restrictionField];
  };

  const handleClick = () => {
    onClick(rental, date, restriction);
  };

  return (
    <td
      style={{
        minWidth: CALENDAR_CELL_WIDTH,
        height: CALENDAR_RESTRICTION_HEIGHT,
      }}
      className={`border-r-1 border-t-1 border-element-border cursor-pointer ${
        isToday ? "bg-[#7D8284]/10" : "bg-gray-50"
      } ${getBorder()}`}
      onClick={handleClick}
    >
      {restriction && (
        <div className="flex items-center justify-end mr-2 text-high-contrast font-light">
          {
            // @ts-ignore
            getValue()
          }
        </div>
      )}
    </td>
  );
};
