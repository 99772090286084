import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import InfoIcon from "../../assets/icons/information.svg?react";
import { TableList } from "../../components/Common/TableList/TableList";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { AccountListTableFilters } from "../../components/Payment/AccountList/AccountListTableFilters";
import { AccountListTableHeader } from "../../components/Payment/AccountList/AccountListTableHeader";
import { AccountListTableItem } from "../../components/Payment/AccountList/AccountListTableItem";
import { AccountListTableItemSkeleton } from "../../components/Payment/AccountList/AccountListTableItemSkeleton";
import { AddStripeAccountErrorModal } from "../../components/Payment/AccountList/AddStripeAccountErrorModal";
import { AddStripeAccountModal } from "../../components/Payment/AccountList/AddStripeAccountModal";
import { AddStripeAccountSuccessModal } from "../../components/Payment/AccountList/AddStripeAccountSuccessModal";
import { EditStripeAccountModal } from "../../components/Payment/AccountList/EditStripeAccountModal";
import { RemoveAccountModal } from "../../components/Payment/AccountList/RemoveAccountModal";
import paths from "../../constants/paths";
import { useTablePage } from "../../hooks/useTablePage";
import { ValueType } from "../../types/commonTypes";
import { PaymentAccountListItemResponse } from "../../types/GETTypes";
import { PaymentAccountListNoData } from "./PaymentAccountListNoData";

export const PaymentAccountListPage: React.FC<{}> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [addStripeVisible, setAddStripeVisible] = useState<boolean>(false);
  const [addStripeSuccessVisible, setAddStripeSuccessVisible] =
    useState<boolean>(false);
  const [addStripeErrorVisible, setAddStripeErrorVisible] =
    useState<boolean>(false);

  const [removeModalVisible, setRemoveModalVisible] = useState<boolean>(false);
  const [idToRemove, setIdToRemove] = useState<ValueType | null>(null);

  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [accountToEdit, setAccountToEdit] =
    useState<PaymentAccountListItemResponse | null>(null);

  const queryParameters = new URLSearchParams(window.location.search);
  const queryCode = queryParameters.get("code");
  const queryError = queryParameters.get("error");

  const getTitle = () => {
    return (
      <p className="flex flex-row items-center gap-1">
        {t("Payments.title")} <ChevronRightIcon />
        {t("Payments.accountList")}
      </p>
    );
  };

  const getFiltersNode = () => {
    return (
      <AccountListTableFilters
        onAddIBANAccount={() => {}}
        onAddStripeAccount={handleAddStripeAccount}
        onSearchText={tablePage.handleChangeSearch}
      />
    );
  };

  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.ACCOUNTS}`,
    "payment_accounts"
  );

  useEffect(() => {
    tablePage.fetch({});
  }, []);

  useEffect(() => {
    if (queryCode && queryCode !== "") {
      setAddStripeVisible(false);
      setAddStripeErrorVisible(false);

      setAddStripeSuccessVisible(true);
    }
  }, [queryCode]);

  useEffect(() => {
    if (queryError && queryError !== "") {
      setAddStripeVisible(false);
      setAddStripeSuccessVisible(false);

      setAddStripeErrorVisible(true);
    }
  }, [queryError]);

  useEffect(() => {
    // Check if ?modal=1 is in the current URL
    const searchParams = new URLSearchParams(location.search);
    const isModalOpen = searchParams.get("modal") === "1";

    if (isModalOpen) {
      handleAddStripeAccount();

      // Remove modal=1 from the URL without reloading the page
      searchParams.delete("modal");
      navigate(
        {
          pathname: location.pathname,
          search: searchParams.toString(),
        },
        { replace: true }
      );
    }
  }, [location, navigate]);

  const handleAddStripeAccount = () => {
    setAddStripeVisible(true);
  };

  const handleFinishAddStripe = () => {
    tablePage.fetch({});
    setAddStripeVisible(false);
    setAddStripeSuccessVisible(false);
    setAddStripeErrorVisible(false);
  };

  const handleFinishEditStripe = () => {
    tablePage.fetch({});
    setEditModalVisible(false);
    setAccountToEdit(null);
  };

  const handleRemoveSuccess = () => {
    setEditModalVisible(false);
    setAccountToEdit(null);

    setRemoveModalVisible(false);
    setIdToRemove(null);

    tablePage.fetch({});
  };

  const handleClickAction = (id: ValueType, action: ValueType) => {
    if (action === "remove") {
      setRemoveModalVisible(true);
      setIdToRemove(id);
    } else if (action === "edit") {
      const index = tablePage.data?.findIndex(
        (data: PaymentAccountListItemResponse) => data?.id === id
      );

      if (index !== -1) {
        setAccountToEdit(tablePage.data[index]);
        setEditModalVisible(true);
      }
    }
  };

  return (
    <>
      <AddStripeAccountModal
        isVisible={addStripeVisible}
        onClose={() => setAddStripeVisible(false)}
      />

      <AddStripeAccountSuccessModal
        isVisible={addStripeSuccessVisible}
        onClose={() => setAddStripeSuccessVisible(false)}
        onFinish={handleFinishAddStripe}
      />

      <AddStripeAccountErrorModal
        isVisible={addStripeErrorVisible}
        onClose={() => setAddStripeErrorVisible(false)}
      />

      <EditStripeAccountModal
        account={accountToEdit}
        isVisible={editModalVisible}
        onClose={() => setEditModalVisible(false)}
        onRemove={(id) => handleClickAction(id, "remove")}
        onFinish={handleFinishEditStripe}
      />

      <RemoveAccountModal
        isVisible={removeModalVisible}
        idToRemove={idToRemove}
        onClose={() => setRemoveModalVisible(false)}
        onSuccess={handleRemoveSuccess}
      />

      <MainLayout
        title={getTitle()}
        sidebarActiveItem="payments"
        sidebarActiveSubItem="accounts"
      >
        {/* Info */}
        {tablePage.data.length > 0 && (
          <div className="flex w-full gap-2 p-4 bg-element-background rounded-6px">
            <div>
              <InfoIcon className="w-5 h-5" />
            </div>
            <div className="flex flex-col gap-1">
              <p className="font-semibold text-low-contrast">
                {t("Payments.Infos.title")}
              </p>
              <p className="font-light text-low-contrast">
                {t("Payments.Infos.content")}
              </p>
            </div>
          </div>
        )}

        {/* Table with accounts */}
        <div className="mt-4">
          <TableList
            i18nElement="Payments.accountList"
            Filters={getFiltersNode()}
            Header={AccountListTableHeader}
            Item={AccountListTableItem}
            Skeleton={AccountListTableItemSkeleton}
            NoData={
              <PaymentAccountListNoData
                onAddStripeAccount={handleAddStripeAccount}
              />
            }
            tablePage={tablePage}
            onClick={(id) => handleClickAction(id, "edit")}
            onClickAction={handleClickAction}
          />
        </div>
      </MainLayout>
    </>
  );
};
