import React from "react";
import { RightModal } from "../../Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import StripeLogo from "../../../assets/icons/stripe.svg?react";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import { Button } from "../../Common/Button/Button";
import { Separator } from "../../Common/Separator/Separator";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";

type AddStripeAccountModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

export const AddStripeAccountErrorModal: React.FC<
  AddStripeAccountModalProps
> = ({ isVisible, onClose }) => {
  const { t } = useTranslation();

  const queryParameters = new URLSearchParams(window.location.search);
  let queryErrorDescription = queryParameters.get("error_description");

  if (queryErrorDescription === 'The user denied your request')
    queryErrorDescription = t("Payments.Stripe.stripeConnectBackLinkClicked");

  return (
    <RightModal
      isVisible={isVisible}
      onClose={onClose}
      title={t("Payments.addStripe")}
    >
      <div className="flex flex-col w-full">
        <div className="flex flex-col flex-1">
          <StripeLogo />
          <p className="font-semibold text-high-contrast mt-2">
            {t("Payments.Stripe.addTitle")}
          </p>
          <p className="text-low-contrast font-light mt-3">
            {t("Payments.Stripe.addContent")}
          </p>

          <div className="mt-6">
            <ErrorMessage>{queryErrorDescription}</ErrorMessage>
          </div>
        </div>

        <Separator />

        <div className="flex gap-3">
          <Button type="secondary" disabled={true}>
            {t("Global.cancel")}
          </Button>
          <Button RightIcon={CheckWhiteIcon} onClick={onClose}>
            {t("Global.done")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
