import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CheckIcon from "../../assets/icons/check-white.svg?react";
import CleanIcon from "../../assets/icons/clean.svg?react";
import HatIcon from "../../assets/icons/hat.svg?react";
import KeyIcon from "../../assets/icons/key.svg?react";
import { Button } from "../../components/Common/Button/Button";
import { CheckBox } from "../../components/Common/CheckBox/CheckBox";
import { InputMultiSelect } from "../../components/Common/InputMultiSelect/InputMultiSelect";
import { MultiSelectRentalsTable } from "../../components/Common/MultiSelectRentalsTable/MultiSelectRentalsTable";
import { RightModal } from "../../components/Common/RightModal/RightModal";
import { Separator } from "../../components/Common/Separator/Separator";
import paths from "../../constants/paths";
import { capitalizeFirstLetter } from "../../helpers/stringHelper";
import { useOperationalManagementPosition } from "../../hooks/api/operationalManagementPosition";
import { useOperationalManagementTeam } from "../../hooks/api/operationalManagementTeam";
import { useTasks } from "../../hooks/api/tasks";
import { useTablePage } from "../../hooks/useTablePage";
import { ValueType } from "../../types/commonTypes";
import {
  OperationalManagementPositionItemResponse,
  OperationalManagementPositionTypeResponse,
  OperationalManagementTeamListItemResponse,
  RentalLightListItemResponse,
} from "../../types/GETTypes";
import {
  ManagementTaskListFilterForm,
  ManagementTaskListFilterModalProps,
} from "./ManagementTaskListPage.type";

export const ManagementTaskListFilterModal: React.FC<
  ManagementTaskListFilterModalProps
> = ({ modal, listFilters, onTaskListFiltered, onError }) => {
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [resetValidationLoading, setResetValidationLoading] =
    useState<boolean>(false);

  const form = useForm<ManagementTaskListFilterForm>({
    mode: "all",
    defaultValues: {
      assignedPersons: [],
      positions: {
        manager: undefined,
        house_cleaner: undefined,
        house_owner: undefined,
      },
      teams: [],
    },
  });

  // MULTI SELECT RENTALS
  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`,
    "rentals"
  );

  useEffect(() => {
    tablePage.fetch({});

    const { getAll: getAllTeams } = useOperationalManagementTeam({
      onSuccess: (
        teams: OperationalManagementTeamListItemResponse[] | undefined
      ) => setTeams(teams),
      onError: (message: string | undefined) => onError(message),
    });

    getAllTeams();

    const { getAll: getAllPositions } = useOperationalManagementPosition({
      onSuccess: (
        positions: OperationalManagementPositionItemResponse[] | undefined
      ) => setPositions(positions),
      onError: (message: string | undefined) => onError(message),
    });

    getAllPositions();
  }, []);

  const [teams, setTeams] = useState<
    OperationalManagementTeamListItemResponse[] | undefined
  >();

  useEffect(() => {
    if (teams) {
      form.setValue(
        "teams",
        teams.map((team) => team.id)
      );

      form.setValue(
        "assignedPersons",
        teams.flatMap((team) =>
          team.members
            .filter((member) => Boolean(member.accepted))
            .map((member) => member.id)
        )
      );
    }
  }, [teams]);

  const [positions, setPositions] = useState<
    OperationalManagementPositionItemResponse[] | undefined
  >();

  useEffect(() => {
    if (positions) {
      positions.forEach((position) => {
        const type = position.type.toLowerCase();

        if (
          type === "house_owner" &&
          !form.getValues("positions.house_owner")
        ) {
          form.setValue("positions.house_owner", position.id);
        } else if (type === "manager" && !form.getValues("positions.manager")) {
          form.setValue("positions.manager", position.id);
        } else if (
          type === "house_cleaner" &&
          !form.getValues("positions.house_cleaner")
        ) {
          form.setValue("positions.house_cleaner", position.id);
        }
      });
    }
  }, [positions]);

  const getPositionIcon = (type: OperationalManagementPositionTypeResponse) => {
    if (type === "manager") {
      return <HatIcon />;
    }

    if (type === "house_cleaner") {
      return <CleanIcon />;
    }

    if (type === "house_owner") {
      return <KeyIcon />;
    }

    return undefined;
  };

  const onSelectPosition = (
    position: OperationalManagementPositionItemResponse
  ) => {
    form.setValue(
      `positions.${position.type}`,
      form.getValues(`positions.${position.type}`) !== undefined
        ? undefined
        : position.id
    );
  };

  const [selectedRentalIds, setSelectedRentalIds] = useState<Set<ValueType>>(
    new Set([])
  );

  useEffect(() => {
    setSelectedRentalIds(
      tablePage.data.map((rental: RentalLightListItemResponse) => rental.id)
    );
  }, [tablePage.data]);

  const onSelectRental = (id: ValueType) => {
    const exists =
      Array.from(selectedRentalIds)?.findIndex((i) => i === id) > -1;

    if (exists) {
      setSelectedRentalIds(
        (prevIds) => new Set([...Array.from(prevIds).filter((i) => i !== id)])
      );
    } else {
      setSelectedRentalIds((prevIds) => new Set([...prevIds, id]));
    }
  };

  const onApplyFilters = () => {
    const { getAll: getAllTasks } = useTasks({
      onSuccess: (tasks) => {
        onTaskListFiltered(tasks);
      },
      onError: (message: string | undefined) => onError(message),
      onStart: () => {
        onError(undefined);
        setValidationLoading(true);
      },
      onEnd: () => {
        setValidationLoading(false);
        modal.close();
      },
    });

    getAllTasks({
      status: listFilters.status,
      from: listFilters.from,
      to: listFilters.to,
      operatorTeamIds: form.getValues("teams"),
      operatorIds: form.getValues("assignedPersons"),
      positions: form.getValues("positions"),
      selectedRentalsIds: selectedRentalIds,
    });
  };

  const onResetFilters = () => {
    const { getAll: getAllTasks } = useTasks({
      onSuccess: (tasks) => {
        onTaskListFiltered(tasks);
      },
      onError: (message: string | undefined) => onError(message),
      onStart: () => {
        onError(undefined);
        setResetValidationLoading(true);
      },
      onEnd: () => {
        setResetValidationLoading(false);
        modal.close();
      },
    });

    form.setValue("teams", teams?.map((team) => team.id) ?? []);

    form.setValue(
      "assignedPersons",
      teams?.flatMap((team) =>
        team.members
          .filter((member) => Boolean(member.accepted))
          .map((member) => member.id)
      ) ?? []
    );

    setSelectedRentalIds(
      tablePage.data.map((rental: RentalLightListItemResponse) => rental.id)
    );

    getAllTasks({
      status: listFilters.status,
      from: listFilters.from,
      to: listFilters.to,
    });
  };

  form.watch();

  return (
    <RightModal
      classNames={{
        mainContentParent: "overflow-y-hidden pe-0",
      }}
      title={t("Task.FilterModal.title")}
      isVisible={modal.isVisible}
      onClose={
        validationLoading || resetValidationLoading ? () => {} : modal.close
      }
    >
      <div className="flex flex-col justify-between w-full gap-y-2">
        <div className="flex flex-col flex-1 overflow-y-scroll gap-y-4">
          {/* TEAMS */}
          <InputMultiSelect
            register={form.register("teams")}
            label={t("Task.FilterModal.teams")}
            items={
              teams?.map((team) => {
                return {
                  label: team.name,
                  value: team.id,
                };
              }) ?? []
            }
            selectedValues={form.getValues("teams")}
          />
          {/* ASSIGNED PERSONS */}
          <div>
            <p className="mb-3 text-sm select-none text-low-contrast">
              {t("Task.FilterModal.filterLabel")}
            </p>
            <InputMultiSelect
              register={form.register("assignedPersons")}
              label={t("Task.FilterModal.assignedPersons")}
              items={
                teams?.flatMap((team) =>
                  team.members
                    .filter((member) => Boolean(member.accepted))
                    .map((member) => ({
                      imgSrc:
                        "http://localhost/api/dummy_40x40_ffffff_E54D2E_sh.png",
                      label: `${capitalizeFirstLetter(
                        member.first_name
                      )} ${capitalizeFirstLetter(member.last_name)}`,
                      value: member.id,
                    }))
                ) ?? []
              }
              selectedValues={form.getValues("assignedPersons")}
            />
          </div>

          {/* POSITIONS */}
          <div className="flex flex-col gap-y-1">
            <p className="font-bold select-none text-high-contrast">
              {t("Task.FilterModal.positionsLabel")}
            </p>

            <div className="flex flex-col gap-y-2">
              {positions
                ? positions.map((position) => (
                    <div
                      className="flex items-center space-x-2 cursor-pointer w-max"
                      onClick={() => onSelectPosition(position)}
                      key={position.id}
                    >
                      <CheckBox
                        value={Boolean(
                          form.getValues(`positions.${position.type}`)
                        )}
                      />
                      <div className="flex items-center justify-center px-6 py-3 space-x-2 rounded-lg bg-element-background max-w-44 w-44">
                        {getPositionIcon(position.type)}
                        <span className="font-semibold select-none text-low-contrast">
                          {position.name}
                        </span>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>

          <div className="select-none">
            <p className="mb-3 text-low-contrast">
              {t("Task.FilterModal.rentalFilterLabel")}
            </p>

            <MultiSelectRentalsTable
              tablePage={tablePage}
              selectedRentals={Array.from(selectedRentalIds)}
              onSelectRental={onSelectRental}
            />
          </div>
        </div>

        <Separator />

        {/* BUTTONS */}
        <div className="flex items-center justify-between gap-x-4 pe-4">
          <Button
            type="secondary"
            disabled={validationLoading || resetValidationLoading}
            onClick={() => modal.close()}
          >
            {t("Global.cancel")}
          </Button>
          <div className="w-max">
            <Button
              type="secondary"
              disabled={validationLoading || resetValidationLoading}
              loading={resetValidationLoading}
              onClick={onResetFilters}
            >
              {t("Task.FilterModal.resetFilters")}
            </Button>
          </div>
          <Button
            type="primary"
            RightIcon={CheckIcon}
            disabled={validationLoading || resetValidationLoading}
            loading={validationLoading}
            onClick={onApplyFilters}
          >
            {t("Global.apply")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
