import { useEffect, useState } from "react";
import { MultiSelectGroupOption } from "../components/Common/MultiSelectRentals/MultiSelectRentals.type";
import { ValueType } from "../types/commonTypes";
import resRentalGroups from "../res/rentalsGroups";
import resRentals from "../res/rentals";

export function useGetRentals() {
  const [rentalGroups, setRentalGroups] = useState<MultiSelectGroupOption[]>(
    []
  );
  const [currentRentals, setCurrentRentals] = useState<ValueType[]>([]);

  useEffect(() => {
    const allIds: ValueType[] = [];

    const result: MultiSelectGroupOption[] = resRentalGroups.map((group) => {
      const newGroup: MultiSelectGroupOption = {
        value: group.id,
        label: group.name,
        rentals: group.rentalsId?.map((rentalId) => {
          const rental = resRentals.find((r) => r.id === rentalId);
          const newRental = {
            value: rental?.id!,
            label: rental?.name!,
          };

          allIds.push(rentalId);

          return newRental;
        }),
      };

      return newGroup;
    });

    setRentalGroups(result);
    setCurrentRentals(allIds);
  }, []);

  return { rentalGroups, setRentalGroups, currentRentals, setCurrentRentals };
}
