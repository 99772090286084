/**
 * Main page to list all integrations available.
 *
 * @author Elysio
 */

import React from "react";

import SearchIcon from "../../assets/icons/search.svg?react";

import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { TextInput } from "../../components/Common/TextInput/TextInput";
import { PlatformTemplate } from "../../components/PlatForm/Details/PlatformTemplate";
import { Platform } from "../../components/PlatForm/Platform";
import { useTranslation } from "react-i18next";

export const MainPageThirdParty = ({
  handleSearchAction,
  filteredPlatforms,
  handleClickItem,
}) => {
  const { t } = useTranslation();

  return (
    <MainLayout
      title={t("Global.platforms")}
      sidebarActiveItem="platforms"
      sidebarActiveSubItem="general"
    >
      <header>
        <TextInput
          Icon={SearchIcon}
          type={"text"}
          placeholder={t("ThirdParty.search")}
          onChangeText={handleSearchAction}
        />
      </header>
      <main className={"flex flex-col gap-8 mt-8"}>
        {filteredPlatforms.map((template, index) => (
          <PlatformTemplate title={template.category}>
            {template.items.map((platform, idx) => (
              <Platform
                Icon={platform.Icon}
                title={platform.title}
                description={platform.description}
                page={platform.page}
                handleClickPlatform={handleClickItem}
                isConnected={platform.checkConnect()}
              />
            ))}
          </PlatformTemplate>
        ))}
      </main>
    </MainLayout>
  );
};
