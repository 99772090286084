import React, {useEffect, useState} from "react";
import {RentalLightListItemResponse} from "../../../../types/GETTypes";
import {useTranslation} from "react-i18next";
import {BookingConnectRentalLineSkeleton} from "../../../Modal/Booking/Connect/BookingConnectRentalLineSkeleton";
import {BookingConnectRentalLine} from "../../../Modal/Booking/Connect/BookingConnectRentalLine";
import {Button} from "../../../Common/Button/Button";
import {get} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";


type BookingConnect2Props = {
    rentalId: number;
}
export const BookingConnectSingleRentalStep2: React.FC<BookingConnect2Props> = ({rentalId,setIsAssociated}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const [rentalDetail, setRentalDetail] = useState<RentalLightListItemResponse>({});


    const fetchRentals = async ({ page = 1, limit = 5, search = "" }) => {
        setLoading(true);
        const params = {
            page,
            limit,
            search,
        };

        const response = await get(
            `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}/${rentalId}`,
            { params }
        );

        if (response.data?.success) {
            const data = response.data?.result

            const reformattedRental = {
                id: data.id,
                ...data.details.informations,
                ...data.details.photos,
                external_connexions: data.details.external_connexions,

            };

            setRentalDetail(reformattedRental);
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchRentals({});
    }, []);

    return (
        <div className="flex flex-col">

            <div className="mt-3">
                <table
                    className="table-auto w-full rounded-4px border-1 border-element-border border-separate border-spacing-0">
                    {/* Table Header */}
                    <tr className="bg-element-background border-b-1 border-element-border">
                        <th className=" px-2 py-2 text-sm">
                            <div className="flex flex-row items-center hover:underline">
                                <p>{t("Progression.rentalInSH")}</p>
                            </div>
                        </th>
                    </tr>

                    {loading && <BookingConnectRentalLineSkeleton />}

                    {!loading &&
                        <BookingConnectRentalLine
                            rental={rentalDetail}
                            onReload={() => fetchRentals({})}
                            setIsAssociated={setIsAssociated}
                        />
                    }
                </table>
            </div>
        </div>
    );
}
