import React from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "../../../src/assets/icons/edit.svg?react";
import InformationIcon from "../../../src/assets/icons/information.svg?react";
import {Card} from "../Common/Card/Card";
import {Separator} from "../Common/Separator/Separator";
import {SettingSkeletonProps} from "../../pages/Settings/Settings.type";

export const SettingSkeleton: React.FC<SettingSkeletonProps> = ({page}) => {
    const { t } = useTranslation();
    return (
        <Card
            Icon={InformationIcon}
            label={page === "user_information"? t("Global.user_information") : t("Global.profil") }
            button={{ Icon: EditIcon, label: page === "user_information"? t("Global.user_information") : t("Global.profil"), skeleton: true }}
        >
            <div className="flex flex-col animate-pulse">
                <div className="flex flex-row justify-between gap-3">
                    <div className="flex flex-col gap-3 mt-2">
                        <p className="w-56 h-4 rounded bg-slate-200"></p>
                        <p className="h-2 rounded-lg w-52 bg-slate-200"></p>

                        <div className="flex flex-row flex-wrap w-full gap-x-2 text-low-contrast">
                            <span className="h-2 rounded-lg w-52 bg-slate-200"></span>
                        </div>

                        <div className="h-2 rounded-lg w-52 bg-slate-200"></div>

                        <div className="w-40 h-10 rounded-md bg-slate-200"></div>
                    </div>

                    <div>
                        <div className="h-9 w-22 rounded-6px bg-slate-200"></div>
                    </div>
                </div>

                <Separator />

                <div className="flex flex-row gap-x-6">
                    <div className="flex flex-col gap-y-6">
                        <div>
                            <p className="w-32 h-2 mb-3 rounded-lg bg-slate-200"></p>
                            <p className="w-20 h-3 rounded-lg bg-slate-200"></p>
                        </div>

                        <div>
                            <p className="w-32 h-2 mb-3 rounded-lg bg-slate-200"></p>
                            <p className="w-20 h-3 rounded-lg bg-slate-200"></p>
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-6">
                        <div>
                            <p className="w-32 h-2 mb-3 rounded-lg bg-slate-200"></p>
                            <p className="w-20 h-3 rounded-lg bg-slate-200"></p>
                        </div>
                        <div>
                            <p className="w-32 h-2 mb-3 rounded-lg bg-slate-200"></p>
                            <p className="w-20 h-3 rounded-lg bg-slate-200"></p>
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-6">
                        <div>
                            <p className="w-32 h-2 mb-3 rounded-lg bg-slate-200"></p>
                            <p className="w-20 h-3 rounded-lg bg-slate-200"></p>
                        </div>
                    </div>
                </div>

                <Separator />

                <div>
                    <p className="w-32 h-2 mb-3 rounded-lg bg-slate-200"></p>
                    <div className="flex flex-row items-center gap-2">
                        <div className="relative">
                            <div className="w-10 h-10 rounded-4px bg-slate-200"></div>
                        </div>

                        <div>
                            <p className="h-2 mb-3 rounded-lg w-96 bg-slate-200"></p>
                            <p className="h-2 rounded-lg w-96 bg-slate-200"></p>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};
