import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {MainLayout} from "../../components/Layout/MainLayout/MainLayout";
import {AirbnbConnectModal} from "../../components/Modal/Airbnb/Connect/AirbnbConnectModal";
import {BookingConnectModal} from "../../components/Modal/Booking/Connect/BookingConnectModal";
import {ListingImportFinishModal} from "../../components/Progression/ListingImportFinishModal";
import {Progression, ProgressionStatusEnum, ProgressionStepEnum,} from "../../components/Progression/Progression.type";
import {ProgressionRightSide} from "../../components/Progression/ProgressionRightSide";
import {RulesSyncFinishModal} from "../../components/Progression/RulesSyncFinishModal";
import {ProgressionStepAirbnb} from "../../components/Progression/Steps/Airbnb";
import {ProgressionStepCalendar} from "../../components/Progression/Steps/Calendar";
import {ProgressionStepGift} from "../../components/Progression/Steps/Gift";
import {ProgressionStepMessages} from "../../components/Progression/Steps/Messages";
import {ProgressionStepNone} from "../../components/Progression/Steps/None";
import {ProgressionStepPlatforms} from "../../components/Progression/Steps/Platforms";
import {ProgressionStepRentals} from "../../components/Progression/Steps/Rentals";
import {ProgressionStepRules} from "../../components/Progression/Steps/Rules";
import {ProgressionStepTeam} from "../../components/Progression/Steps/Team";
import paths from "../../constants/paths";
import {get, post} from "../../helpers/APIHelper";
import {ProgressionPageProps} from "./ProgressionPage.type";

import {ListingImportWaitingModal} from "../../components/Progression/ListingImportWaitingModal";
import {RulesSyncWaitingModal} from "../../components/Progression/RulesSyncWaitingModal";
import {useCheckAuth} from "../../hooks/useCheckAuth";
import useProgressionStore from "../../stores/useProgressStore";
import {ProgressionConnectStripe} from "../../components/Progression/Steps/ConnectStripe";

export const ProgressionPage: React.FC<ProgressionPageProps> = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const { triggerRefreshProgression } = useProgressionStore();

  const queryParameters = new URLSearchParams(window.location.search);
  const syncInProgress = queryParameters.get("sync_in_progress");

  const [isAirbnbConnectModalVisible, setAirbnbConnectModalVisible] =
    useState(false);
  const [isBookingConnectModalVisible, setBookingConnectModalVisible] =
    useState(false);

  const [
    isListingImportWaitingPopupVisible,
    setIsListingImportWaitingPopupVisible,
  ] = useState<boolean>(false);

  const [isSyncWaitingPopupVisible, setIsSyncWaitingPopupVisible] =
    useState<boolean>(false);

  const [currentProgression, setCurrentProgression] = useState<Progression>({
    step: ProgressionStepEnum.STEP_NONE,
    status: ProgressionStatusEnum.INCOMPLETE,
    processedJobs: 0,
    totalJobs: 0,
    progress: null,
    progressText: "",
    bookingAlreadyAssociated: false,
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [allSteps, setAllSteps] = useState<ProgressionStepEnum[]>([
    ProgressionStepEnum.STEP_NONE,
  ]);

  const getProgressionStatus = (
    step: ProgressionStepEnum
  ): "active" | "inactive" | "success" | "error" | "pending" => {
    const stepsIndexes = Object.entries(ProgressionStepEnum).map(
      (entry) => entry[1]
    );
    const stepIndex = stepsIndexes.findIndex((s) => s === step);
    const currentStepIndex = stepsIndexes.findIndex(
      (s) => s === currentProgression.step
    );


    if (stepIndex > currentStepIndex) "inactive";
    else if (
      stepIndex === currentStepIndex &&
      currentProgression.status === ProgressionStatusEnum.ERROR
    )
      return "error";
    else if (
      stepIndex === currentStepIndex &&
      currentProgression.status === ProgressionStatusEnum.PENDING
    )
      return "pending";
    else if (stepIndex === currentStepIndex) return "active";
    else if (stepIndex < currentStepIndex) return "success";
    return "inactive";
  };
  const {user, setUser} = useCheckAuth();

  const updateCurrentProgression = (response: any) => {
    const result = response?.data?.result;
    const newUser = result?.user;
    setUser(newUser);

    setCurrentProgression({
      step: result?.progression_step,
      status: result?.progression_step_status,
      processedJobs: result?.progression_step_pending_processed_jobs,
      totalJobs: result?.progression_step_pending_total_jobs,
      progress: result?.progression_step_pending_progress_percents,
      progressText: result?.progression_step_pending_progress_text,
      bookingAlreadyAssociated:
        result?.progression_step_booking_already_associated,
    });
  };

  const loopProgressionStatus = () => {
    console.log("START LOOOOOOP");

    const interval = setInterval(async () => {
      const response = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.PROGRESSION}`
      );
      updateCurrentProgression(response);
      triggerRefreshProgression();

      if (
        response.data?.success &&
        response.data?.result?.progression_step_status !==
          ProgressionStatusEnum.PENDING
      ) {
        clearInterval(interval);
      } else {
        setError(response?.response?.data?.message);
      }
    }, 2 * 1000);
  };

  const fetchAllSteps = async () => {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.ALL_STEPS}`
    );

    if (response.data.success) {
      setAllSteps(JSON.parse(response.data.result.progression_steps));
    }

    fetchProgression();
  };

  const fetchProgression = async () => {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.PROGRESSION}`
    );

    if (response?.data?.success) {
      updateCurrentProgression(response);

      if (
        response?.data?.result?.progression_step_status ===
        ProgressionStatusEnum.PENDING
      ) {
        loopProgressionStatus();
      }
    } else {
      setError(response?.response?.data?.message);
    }

    setLoading(false);
  };


  const completeCurrentStep = async () => {
    setError(null);
    setLoading(true);

    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.COMPLETE_PROGRESSION_STEP}`,
      {progression_step: currentProgression.step}
    );

    if (!response?.data?.success) {
      setError(response?.response?.data?.message);
      setLoading(false);
    } else {
      fetchProgression();
    }
  };

  const handleFinishAirbnb = () => {
    console.log("AIBNB FINISHED !!!");

    setAirbnbConnectModalVisible(false);
    setIsListingImportWaitingPopupVisible(true);
    // DEBUG
    setTimeout(() => {
      loopProgressionStatus();
    }, 2 * 1000);
  };

  const handleFinishAirbnbDebug = () => {
    handleFinishAirbnb();

    setCurrentProgression({
      progress: 0,
      processedJobs: 1,
      totalJobs: 3,
      progressText: "1/3 hébergements",
      status: ProgressionStatusEnum.PENDING,
      step: ProgressionStepEnum.STEP_CONNECT_AIRBNB,
      bookingAlreadyAssociated: false,
    });

    setTimeout(() => {
      setCurrentProgression({
        progress: 50,
        processedJobs: 2,
        totalJobs: 3,
        progressText: "2/3 hébergements",
        status: ProgressionStatusEnum.PENDING,
        step: ProgressionStepEnum.STEP_CONNECT_AIRBNB,
        bookingAlreadyAssociated: false,
      });
    }, 2000);

    setTimeout(() => {
      setCurrentProgression({
        progress: 100,
        processedJobs: 3,
        totalJobs: 3,
        progressText: "3/3 hébergements",
        status: ProgressionStatusEnum.PENDING,
        step: ProgressionStepEnum.STEP_CONNECT_AIRBNB,
        bookingAlreadyAssociated: false,
      });
    }, 4000);

    setTimeout(() => {
      setCurrentProgression({
        progress: null,
        processedJobs: 0,
        totalJobs: 0,
        progressText: "",
        status: ProgressionStatusEnum.INCOMPLETE,
        step: ProgressionStepEnum.STEP_CONNECT_BOOKING,
        bookingAlreadyAssociated: false,
      });
    }, 5000);
  };

  const handleRentalRulesDebug = () => {
    setIsSyncWaitingPopupVisible(true);

    setCurrentProgression({
      progress: 0,
      processedJobs: 0,
      totalJobs: 3,
      progressText: "0/3 hébergements",
      status: ProgressionStatusEnum.PENDING,
      step: ProgressionStepEnum.STEP_SYNCHRONIZE,
      bookingAlreadyAssociated: false,
    });

    setTimeout(() => {
      setCurrentProgression({
        progress: 50,
        processedJobs: 1,
        totalJobs: 3,
        progressText: "1/3 hébergements",
        status: ProgressionStatusEnum.PENDING,
        step: ProgressionStepEnum.STEP_SYNCHRONIZE,
        bookingAlreadyAssociated: false,
      });
    }, 2000);

    setTimeout(() => {
      setCurrentProgression({
        progress: 100,
        processedJobs: 3,
        totalJobs: 3,
        progressText: "3/3 hébergements",
        status: ProgressionStatusEnum.PENDING,
        step: ProgressionStepEnum.STEP_SYNCHRONIZE,
        bookingAlreadyAssociated: false,
      });
    }, 4000);

    setTimeout(() => {
      setCurrentProgression({
        progress: null,
        processedJobs: 0,
        totalJobs: 0,
        progressText: "",
        status: ProgressionStatusEnum.INCOMPLETE,
        step: ProgressionStepEnum.STEP_CHECK_CALENDAR,
        bookingAlreadyAssociated: false,
      });
    }, 5000);
  };

  useEffect(() => {
    console.log("useEffect fetchAllSteps");
    fetchAllSteps();
  }, []);

  useEffect(() => {
    if (
      syncInProgress ||
      (currentProgression.step === ProgressionStepEnum.STEP_SYNCHRONIZE &&
        currentProgression.status === ProgressionStatusEnum.PENDING)
    ) {
      setIsSyncWaitingPopupVisible(true);
    }
  }, [syncInProgress, currentProgression.step, currentProgression.status]);

  const handleCloseBookingModal = () => {
    setBookingConnectModalVisible(false);

    setLoading(true);
    fetchProgression();
  };

  const handleConfirmStripe = () => {
    navigate(paths.PAYMENTS_ACCOUNTS);
    completeCurrentStep();
  }

  return (
    <>
      <ListingImportWaitingModal
        progression={currentProgression.progress}
        isVisible={
          currentProgression.status !== ProgressionStatusEnum.ERROR &&
          (isListingImportWaitingPopupVisible ||
            (currentProgression.step ===
              ProgressionStepEnum.STEP_CONNECT_AIRBNB &&
              currentProgression.status === ProgressionStatusEnum.PENDING))
        }
        processedJobs={currentProgression.processedJobs ?? 0}
        totalJobs={currentProgression.totalJobs}
      />

      <ListingImportFinishModal
        isVisible={
          currentProgression.status !== ProgressionStatusEnum.ERROR &&
          isListingImportWaitingPopupVisible &&
          currentProgression.step! != ProgressionStepEnum.STEP_CONNECT_AIRBNB &&
          currentProgression.step! != ProgressionStepEnum.STEP_NONE
        }
        onClose={() => setIsListingImportWaitingPopupVisible(false)}
      />

      <RulesSyncWaitingModal
        progression={currentProgression.progress}
        progressText={currentProgression.progressText}
        isVisible={
          currentProgression.status !== ProgressionStatusEnum.ERROR &&
          ((isSyncWaitingPopupVisible &&
            currentProgression.step !==
              ProgressionStepEnum.STEP_CHECK_CALENDAR) ||
            (currentProgression.step === ProgressionStepEnum.STEP_SYNCHRONIZE &&
              currentProgression.status === ProgressionStatusEnum.PENDING))
        }
      />

      <RulesSyncFinishModal
        isVisible={
          currentProgression.status !== ProgressionStatusEnum.ERROR &&
          isSyncWaitingPopupVisible &&
          currentProgression.step === ProgressionStepEnum.STEP_CHECK_CALENDAR &&
          currentProgression.status === ProgressionStatusEnum.INCOMPLETE
        }
        onClose={() => setIsSyncWaitingPopupVisible(false)}
      />

      <AirbnbConnectModal
        isVisible={isAirbnbConnectModalVisible}
        onClose={() => setAirbnbConnectModalVisible(false)}
        onFinish={handleFinishAirbnb}
        onSelectRental={(count: number) => {
          setCurrentProgression((prevValue) => {
            return {...prevValue, totalJobs: count};
          });
        }}
      />

      <BookingConnectModal
        isVisible={isBookingConnectModalVisible}
        onClose={handleCloseBookingModal}
        onFinish={handleCloseBookingModal}
      />

      <MainLayout
        title={t("Progression.title")}
        sidebarActiveItem="progression"
        userData={user}
      >
        {/* <Button onClick={handleFinishAirbnbDebug}>DEBUG FINISH AIRBNB</Button> */}
        {/* <div style={{ zIndex: 99 }}>
          <Button onClick={handleRentalRulesDebug}>
            DEBUG SYNC RENTAL RULES
          </Button>
        </div> */}

        <div className="flex flex-row justify-center">
          <div className="flex flex-row flex-1 gap-4 mt-2 max-w-7xl">
            {/* Left side with steps */}
            <div className="flex-1">
              <div className="w-full overflow-hidden border-1 border-element-border rounded-6px">
                {allSteps?.map((step, index) => {
                  return (
                    <>
                      {/* Elite None just for loading */}
                      {step === ProgressionStepEnum.STEP_NONE &&
                        Array.from({length: 7}).map((s, index) => (
                          <ProgressionStepNone
                            key={`ProgressionStepNone-${index}`}
                            status="inactive"
                            visible={step === ProgressionStepEnum.STEP_NONE}
                            number={index + 1}
                            error={error}
                            loading={true}
                            step={step}
                          />
                        ))}

                      {/* Step AirBnB */}
                      <ProgressionStepAirbnb
                        key={"ProgressionStepAirbnb"}
                        currentProgression={currentProgression}
                        status={getProgressionStatus(
                            ProgressionStepEnum.STEP_CONNECT_AIRBNB
                        )}
                        visible={step === ProgressionStepEnum.STEP_CONNECT_AIRBNB}
                        number={index + 1}
                        error={error}
                        loading={loading}
                        onAirbnbClick={() => setAirbnbConnectModalVisible(true)}
                      />
                      {/* Step Rentals */}
                      <ProgressionStepRentals
                        key={"ProgressionStepRentals"}
                        currentProgression={currentProgression}
                        status={getProgressionStatus(
                          ProgressionStepEnum.STEP_CREATE_RENTAL
                        )}
                        visible={
                          step === ProgressionStepEnum.STEP_CREATE_RENTAL
                        }
                        number={index + 1}
                        error={error}
                        loading={loading}
                        onCompleteStep={completeCurrentStep}
                      />

                      {/* Step Other platforms */}
                      <ProgressionStepPlatforms
                        key={"ProgressionStepPlatforms"}
                        isBookingConnected={
                          currentProgression.bookingAlreadyAssociated
                        }
                        status={getProgressionStatus(
                          ProgressionStepEnum.STEP_CONNECT_BOOKING
                        )}
                        currentProgression={currentProgression}
                        visible={
                          step === ProgressionStepEnum.STEP_CONNECT_BOOKING
                        }
                        number={index + 1}
                        error={error}
                        loading={loading}
                        onBookingClick={() =>
                          setBookingConnectModalVisible(true)
                        }
                        onCompleteStep={completeCurrentStep}
                        step={step}
                      />


                      {/* Step Rentals Rules */}
                      <ProgressionStepRules
                        key={"ProgressionStepRules"}
                        status={getProgressionStatus(
                          ProgressionStepEnum.STEP_SYNCHRONIZE
                        )}
                        currentProgression={currentProgression}
                        visible={step === ProgressionStepEnum.STEP_SYNCHRONIZE}
                        number={index + 1}
                        error={error}
                        loading={loading}
                        onRulesClick={() =>
                          navigate(paths.PROGRESSION_RENTALS_RULES)
                        }
                        step={step}
                      />

                      {/* View Calendar Step */}
                      <ProgressionStepCalendar
                        key={"ProgressionStepCalendar"}
                        status={getProgressionStatus(
                          ProgressionStepEnum.STEP_CHECK_CALENDAR
                        )}
                        visible={
                          step === ProgressionStepEnum.STEP_CHECK_CALENDAR
                        }
                        number={index + 1}
                        error={error}
                        loading={loading}
                        onCalendarClick={() => navigate(paths.CALENDAR)}
                        step={step}
                      />
                      {/* Messages Step */}
                      <ProgressionStepMessages
                        key={"ProgressionStepMessages"}
                        status={getProgressionStatus(
                          ProgressionStepEnum.STEP_CREATE_MESSAGES
                        )}
                        visible={
                          step === ProgressionStepEnum.STEP_CREATE_MESSAGES
                        }
                        number={index + 1}
                        error={error}
                        loading={loading}
                        step={step}
                      />

                      {/* Team Step */}
                      <ProgressionStepTeam
                        key={"ProgressionStepTeam"}
                        status={getProgressionStatus(
                          ProgressionStepEnum.STEP_CREATE_TEAM
                        )}
                        visible={step === ProgressionStepEnum.STEP_CREATE_TEAM}
                        number={index + 1}
                        error={error}
                        loading={loading}
                        step={step}
                      />
                      {/* Team Step */}
                      <ProgressionConnectStripe
                          key={"ProgressionStepConnectStripe"}
                          status={getProgressionStatus(
                              ProgressionStepEnum.STEP_CONNECT_STRIPE
                          )}
                          visible={
                              step === ProgressionStepEnum.STEP_CONNECT_STRIPE
                          }
                          number={index + 1}
                          error={error}
                          loading={loading}
                          step={step}
                          onStripeClick={() => handleConfirmStripe()}
                          onNextClick={() => completeCurrentStep()}
                      />

                      {/* Gift Step */}
                      <ProgressionStepGift
                        key={"ProgressionStepGift"}
                        status={getProgressionStatus(
                          ProgressionStepEnum.STEP_GIFT
                        )}
                        visible={step === ProgressionStepEnum.STEP_GIFT}
                        number={index + 1}
                        error={error}
                        loading={loading}
                        step={step}
                      />
                    </>
                  );
                })}
              </div>
            </div>
            {/* Right side with pics */}
            <ProgressionRightSide getProgressionStatus={getProgressionStatus} user={user} />
          </div>
        </div>
      </MainLayout>
    </>
  );
};
