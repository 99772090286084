import React from "react";
import { useTranslation } from "react-i18next";
import { ValueType } from "../../../../types/commonTypes";

export const AirbnbConnectStepSummary: React.FC<{
  nbRentals: number;
  email: string;
  priceCut: ValueType;
}> = ({ nbRentals, email, priceCut }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6">
      <p className="text-base text-low-contrast">
        {Number(priceCut) === 0 && (
          <>
            {t("Progression.airbnbConnectStep7Content1", {
              count: nbRentals,
            })}
          </>
        )}

        {Number(priceCut) > 0 && (
          <>
            {t("Progression.airbnbConnectStep7Content3", {
              count: nbRentals,
              priceCut,
            })}
          </>
        )}
        {/* {t("Progression.airbnbConnectStep7Content1")}{" "}
        <strong>
          {nbRentals} {t("Progression.airbnbConnectStep7Content2")}
        </strong>{" "}
        {t("Progression.airbnbConnectStep7Content3")} <strong>{email}</strong>{" "}
        {t("Progression.airbnbConnectStep7Content4")}{" "}
        <strong>{priceCut}%</strong>. */}
      </p>

      <p className="text-base font-light text-low-contrast">
        {t("Progression.airbnbConnectStep7Content2")}
      </p>
    </div>
  );
};
