import React from "react";
import { LinkProps } from "./Link.type";

export const Link: React.FC<LinkProps> = ({
  children,
  url,
  openTab = false,
}) => {
  return (
    <a href={url} className="underline" target={openTab ? "_blank" : ""}>
      {children}
    </a>
  );
};
