import React from "react";
import { Button } from "../../Common/Button/Button";
import { TextInput } from "../../Common/TextInput/TextInput";
import { ManagementAutomationFiltersProps } from "./ManagementAutomationFilters.type";

import { t } from "i18next";
import PlusWhiteIcon from "../../../assets/icons/plus-white.svg?react";
import { MultiSelectRentals } from "../../Common/MultiSelectRentals/MultiSelectRentals";

export const ManagementAutomationFilters: React.FC<
  ManagementAutomationFiltersProps
> = ({
  initialRentals,
  onSearchText,
  addAutomationModal,
  onSelectedRentals,
}) => {
  return (
    <div className="flex gap-x-3">
      <TextInput
        placeholder={t("Automation.List.searchPlaceholder")}
        size="large"
        onChangeText={onSearchText}
      />

      <div>
        <MultiSelectRentals
          initialRentals={initialRentals}
          onSelect={onSelectedRentals}
        />
      </div>

      <div>
        <Button RightIcon={PlusWhiteIcon} onClick={addAutomationModal.open}>
          {t("Automation.List.add")}
        </Button>
      </div>
    </div>
  );
};
