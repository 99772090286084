import React from "react";
import { useTranslation } from "react-i18next";
import DeniedIcon from "../../assets/icons/denied.svg?react";
import {FullScreenLayout} from "../Layout/FullScreenLayout/FullScreenLayout";
import {Carousel} from "../Common/Carousel/Carousel";
import Carousel1Image from "../../assets/images/register-carousel-1.svg?react";
import Carousel2Image from "../../assets/images/register-carousel-2.svg?react";
import Carousel3Image from "../../assets/images/register-carousel-3.svg?react";
import SuperhoteIcon from "../../assets/icons/superhote.svg?react";
import { Title } from "../Common/Title/Title";
import {Button} from "../Common/Button/Button";
import PlusCircleIcon from "../../assets/icons/message.svg?react";
import ArrowRight from "../../assets/icons/arrow-white-right.svg?react";
import {Link} from "react-router-dom";
import paths from "../../constants/paths";




export const AccessDenied: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
      <FullScreenLayout isCheckAuthEnabled={false}>
          <div className="w-2/5 max-w-md p-2 max-h-[850px]">
              <div className="flex flex-col min-h-full bg-subtle border-1 border-element-border/50 p-8 rounded-6px">
                  <div className="flex-1 pb-4">
                      <SuperhoteIcon/>
                  </div>
                  <Carousel
                      items={[
                          {
                              Image: Carousel1Image,
                              title: t("Register.carousel1Title"),
                              subTitle: t("Register.carousel1SubTitle"),
                          },
                          {
                              Image: Carousel2Image,
                              title: t("Register.carousel2Title"),
                              subTitle: t("Register.carousel2SubTitle"),
                          },
                          {
                              Image: Carousel3Image,
                              title: t("Register.carousel3Title"),
                              subTitle: t("Register.carousel3SubTitle"),
                          },
                      ]}
                  />

              </div>
          </div>

          <div className="flex px-12 py-8 justify-center items-center flex-col space-y-5">
              <DeniedIcon/>

              <h1 className="block m-0 py-2 font-bold text-xxl">{t("Register.connexionDeniedTitle")}</h1>

              <h1 className="block m-0 pb-2 font-extralight text-secondary text-base text-center">
                  {t("Register.connexionDeniedSubTitle1")}
              </h1>
              <h1 className="block m-0 pb-2 font-extralight text-secondary text-base text-center">
                  {t("Register.connexionDeniedTitle2")}
              </h1>
              <div className="flex mt-4 gap-4">
                  <div className="w-72">
                      <Button type="secondary" RightIcon={PlusCircleIcon} onClick={() => {
                      }}>
                          {t("Register.contactSupport")}
                      </Button>
                  </div>

                  <div className="w-72">
                      <Link to={paths.ROOT}>
                          <Button
                              RightIcon={ArrowRight}
                              onClick={() => console.log("Connect platform")}
                          >
                              {t("Register.goBack")}
                          </Button>
                      </Link>

                  </div>
              </div>

          </div>
      </FullScreenLayout>

  );
};
