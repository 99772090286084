import { PlatformTemplateProps } from "../../../pages/PlatForms/Platform.type";
import { Platform } from "../Platform";
import React from "react";

export const PlatformTemplate = ({
  title,
  children,
  className,
}: PlatformTemplateProps) => {
  return (
    <section className={"flex flex-col gap-4"}>
      <h1 className={"text-xl font-bold text-gray-500"}>{title}</h1>
      <div className={"flex items-center gap-6 flex-wrap " + className}>
        {children}
      </div>
    </section>
  );
};
