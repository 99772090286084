import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../Common/Button/Button";

import moment from "moment";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import countries from "../../../constants/countries";
import paths from "../../../constants/paths";
import {post} from "../../../helpers/APIHelper";
import {RequiredFields} from "../../../types/commonTypes";
import {AddRentalInfosType, AddRentalType} from "../AddRental.type";
import {formatTime} from "../../../helpers/dateHelper";
import {MapView} from "../../MapView/MapView";
import {TextInput} from "../../Common/TextInput/TextInput";
import {geocodeLatLng} from "../../../helpers/geocodeHelper";

export type AddRentalRulesType = {
  minTimein: string;
  maxTimein: string;
  minTimeout: string;
  maxTimeout: string;
  minNights: number;
  maxNights: number;
};

export const ConfirmAddress: React.FC<{
  rental?: AddRentalType;
  onCancel: () => void;
  onNext: (values: AddRentalInfosType) => void;
  onBack: () => void;
}> = ({ rental, onCancel, onNext, onBack }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const form = useForm({
    defaultValues: {
    },
  });


  const [marker, setMarker] = useState({
    lat: rental?.infos?.latitude ?? 0,
    lng: rental?.infos?.longitude ?? 0,
  });
  const [newAddress, setNewAddress] = useState(rental?.infos?.address);


  const handleNextStep = async (values: AddRentalRulesType) => {
    setLoading(true);
    setError(null);
    const data = {
        address: newAddress,
        latitude: marker.lat,
        longitude: marker.lng,
    }
    const res = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_ADD_CHECK_INFOS}`,
        {
            name: rental?.infos?.name,
            rental_category_id: rental?.infos?.rentalCategory,
            rental_type_id: rental?.infos?.rentalType,
            renting_type: rental?.infos?.rentingType,
            management_type: rental?.infos?.managementType,
            address: newAddress,
            postal_code: rental?.infos?.postal_code,
            city: rental?.infos?.city,
            country_code: countries.find(
                (country) => country.value === rental?.infos?.country
            )?.code,
            guests_max_capacity: rental?.infos?.maxGuests,
            adults_max_capacity: rental?.infos?.maxAdults,
            children_max_capacity: rental?.infos?.maxChildren,
            bathrooms_count: rental?.infos?.bathrooms,
            latitude: marker.lat,
            longitude: marker.lng,
        }
    );

    if (res.data?.success) {
        //console.log(rental, '[handleNextStep] ConfirmAddress');
        onNext(data);
    } else {
      setError(res.response.data.message);
    }

    setLoading(false);
  };

    useEffect(() => {
        const updateAddressAndMarker = async () => {
            const latitude = parseFloat(marker.lat.toString());
            const longitude = parseFloat(marker.lng.toString());

            if (latitude && longitude) {
                try {
                    const address = await geocodeLatLng(latitude, longitude);
                    setNewAddress(address);
                    setMarker({
                        lat: latitude,
                        lng: longitude,
                    });
                } catch (error) {
                    console.error(error);
                }
            }
        };
        updateAddressAndMarker();
    }, [marker.lng, marker.lat]);

  return (
    <form onSubmit={form.handleSubmit(handleNextStep)}>
        <div className="flex flex-col w-full text-sm">
            <div className="flex justify-start pb-9">
                <div className="w-44">
                    <Button
                        type="secondary"
                        disabled={loading}
                        LeftIcon={ArrowLeftIcon}
                        onClick={onBack}
                    >
                        {t("Global.previousStep")}
                    </Button>
                </div>
            </div>
            <p className="text-xl font-semibold text-high-contrast">
                {t("AddRental.ConfirmAddress.title")}
            </p>

            <p className="mt-2 mb-3 font-light text-low-contrast">
                {t("AddRental.ConfirmAddress.subTitle")}
            </p>

            <p className="mt-2 mb-3 font-light text-low-contrast">
                {t("AddRental.ConfirmAddress.subTitle2")}
            </p>

            <p className="mt-2 mb-2 font-light text-low-contrast">
                {t("AddRental.ConfirmAddress.subTitle3")}
            </p>
            <p className="text-lg font-semibold text-high-contrast">
                {newAddress}
            </p>

            <div className="flex items-center gap-1 mt-3">
                <MapView setMarker={setMarker} marker={marker} setNewAddress={setNewAddress}/>
            </div>

            <div className="flex flex-row gap-2 mt-3">
                <TextInput
                    label={t("AddRental.ConfirmAddress.latitude")}
                    value={marker.lat.toString()}
                    onChangeText={(value) => setMarker({...marker, lat: parseFloat(value)})}
                    disabled={loading}
                    error={""}
                />
                <TextInput
                    label={t("AddRental.ConfirmAddress.longitude")}
                    value={marker.lng.toString()}
                    onChangeText={(value) => setMarker({...marker, lng: parseFloat(value)})}
                    disabled={loading}
                    error={""}
                />
            </div>

            <div className="flex pb-4 mt-8">
                <div className="flex flex-row gap-4 w-[50%] ml-auto">
                    <Button type="secondary" onClick={onCancel} disabled={loading}>
                        {t("Global.cancel")}
                    </Button>
                    <Button
                        RightIcon={ArrowRightWhiteIcon}
                        loading={loading}
                        onClick={handleNextStep}
                    >
                        {t("AddReservation.Infos.nextStep")}
                    </Button>
                </div>
            </div>
        </div>
    </form>
  );
};
