import React from "react";
import { ProgressionStep } from "../../../pages/Progression/ProgressionStep";
import { useTranslation } from "react-i18next";
import { Button } from "../../Common/Button/Button";
import {
  ProgressionStatusEnum,
  ProgressionStepPlatformsProps,
} from "../Progression.type";
import { Badge } from "../../Common/Badge/Badge";

import PlusIcon from "../../../assets/icons/plus.svg?react";
import BookingIcon from "../../../assets/icons/booking.svg?react";

export const ProgressionStepPlatforms: React.FC<
  ProgressionStepPlatformsProps
> = ({
  status,
  visible,
  number,
  error,
  isBookingConnected,
  loading,
  currentProgression,
  onBookingClick,
  onCompleteStep,
}) => {
  const { t } = useTranslation();

  if (!visible) return null;

  return (
    <ProgressionStep
      number={number.toString()}
      title={t("Progression.StepPlatforms.title")}
      status={status}
      error={error}
      loading={loading}
    >
      <p className="text-low-contrast font-light">
        {t("Progression.StepPlatforms.content1")}
      </p>

      <div className="flex flex-row gap-2 mt-2">
        <div
          className="flex flex-row gap-2 flex-1 h-9 border-1 border-element-border rounded-6px items-center p-2 px-3 cursor-pointer"
          onClick={onBookingClick}
        >
          <BookingIcon className="w-5 h-5" />
          <p className="text-high-contrast font-semibold text-[13px]">
            Booking.com
          </p>
        </div>
        <div>
          {isBookingConnected ? (
            <Badge
              label={t("Global.connected")}
              size="normal"
              status="success"
            />
          ) : (
            <Button
              onClick={onBookingClick}
              disabled={
                currentProgression.status === ProgressionStatusEnum.PENDING
              }
            >
              {t("Progression.connect")}
            </Button>
          )}
        </div>
      </div>

      <div className="flex gap-3 mt-5">
        <Button onClick={onCompleteStep}>
          {t("Progression.StepPlatforms.next")}
        </Button>
      </div>
    </ProgressionStep>
  );
};
