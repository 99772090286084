import React, { useEffect, useRef } from "react";
import { FileInstruction } from "../../../types/GETTypes";
import { isImageFile, isVideoFile } from "../../../helpers/fileHelper";
import { cn } from "../../../helpers/classHelper";

interface CarouselGuestPageProps {
  images: FileInstruction[];
  slideDuration?: number;
  selectedImageIndex: number;
  setSelectedImageIndex: (value: React.SetStateAction<number>) => void;
  displayDot?: boolean;
  isAnimating: boolean;
  setIsAnimating: (value: React.SetStateAction<boolean>) => void;
  parentDiv?: string;
}

const CarouselGuestPage: React.FC<CarouselGuestPageProps> = ({
  images,
  slideDuration = 0,
  selectedImageIndex,
  setSelectedImageIndex,
  displayDot = false,
  setIsAnimating,
  parentDiv = "",
}) => {
  const itemsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (slideDuration > 0) {
      const interval = setInterval(() => {
        setIsAnimating(true);
        setTimeout(() => {
          setSelectedImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
          );
        }, 150);
      }, slideDuration);

      return () => clearInterval(interval);
    }
  }, [images.length, setSelectedImageIndex, setIsAnimating, slideDuration]);

  useEffect(() => {
    if (itemsRef.current) {
      itemsRef.current.scrollTo({
        left: itemsRef.current.clientWidth * selectedImageIndex,
        behavior: "smooth",
      });
    }
  }, [selectedImageIndex]);

  return (
    <>
      {/* ITEMS */}
      <div
        className={cn(
          "items relative flex overflow-x-auto snap-mandatory snap-x scroll-smooth",
          parentDiv
        )}
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        ref={itemsRef}
      >
        {images.map((file, index) => (
          <div
            key={file.id}
            onClick={() => {
              setIsAnimating(true);
              setTimeout(() => {
                setSelectedImageIndex(index);
              }, 150);
            }}
            className={`flex-shrink-0 w-[50px] h-[50px] m-1 bg-gray-200 rounded-lg cursor-pointer snap-center ${
              selectedImageIndex === index ? "border-2 border-red-500" : ""
            }`}
          >
            {isImageFile(file.mime_type) ? (
              <img
                src={file.original_url}
                alt={file.name}
                className="h-full w-full object-cover rounded-lg"
              />
            ) : isVideoFile(file.mime_type) ? (
              <div className="relative h-full w-full">
                <video
                  src={file.original_url}
                  className="h-full w-full object-cover rounded-lg"
                  muted
                  preload="metadata"
                />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-25">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.752 11.168l-7.197-4.132A1 1 0 006 7.868v8.264a1 1 0 001.555.832l7.197-4.132a1 1 0 000-1.664z"
                    />
                  </svg>
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>

      {/* DOTS */}
      {displayDot && (
        <div className="flex flex-row justify-center mt-2 space-x-2">
          {images.map((_, i) => (
            <div
              className={`w-2 h-2 rounded-full ${
                selectedImageIndex === i ? "bg-low-contrast" : "bg-element-background"
              }`}
              key={i}
            ></div>
          ))}
        </div>
      )}
    </>
  );
};

export default CarouselGuestPage;