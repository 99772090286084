import React, { useState } from "react";
import { WorkspaceResponse } from "../../types/GETTypes";
import { ValueType } from "../../types/commonTypes";
import { useTranslation } from "react-i18next";
import paths from "../../constants/paths";
import { CenterModal } from "../Common/CenterModal/CenterModal";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";
import { Button } from "../Common/Button/Button";
import { get } from "../../helpers/APIHelper";

export const LeaveWorkspaceModal: React.FC<{
  isVisible: boolean;
  data: WorkspaceResponse | undefined | null;
  onClose: () => void;
  onSuccess: (wsId: ValueType) => void;
}> = ({ isVisible, data, onClose, onSuccess }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    if (!loading) onClose();
  };

  const handleLeave = async () => {
    setLoading(true);

    const res = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.LEAVE_WORKSPACE
      }/${data?.id}`
    );

    if (res.data?.success) {
      onSuccess(res.data?.result);
      onClose();
    } else {
      setError(res?.response?.data?.message);
    }

    setLoading(false);
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={handleClose}
      title={t("Settings.LeaveWorkspace.title")}
    >
      <div>
        <p className="text-sm font-light text-low-contrast mb-4">
          {t("Settings.LeaveWorkspace.content")}
        </p>

        <ErrorMessage>{error}</ErrorMessage>

        <div className="flex gap-3">
          <Button type="secondary" onClick={handleClose} disabled={loading}>
            {t("Global.cancel")}
          </Button>
          <Button type="destructive" onClick={handleLeave} loading={loading}>
            {t("Settings.LeaveWorkspace.button")}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};
