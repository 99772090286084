import React from "react";

export const BookingConnectRentalLineSkeleton: React.FC<{}> = () => {
  return (
    <div className="animate-pulse flex gap-x-3 p-4 border-b-1 border-element-border last:border-0">
      <div className="w-12 h-12 rounded-4px bg-slate-200"></div>
      <div className="flex flex-col gap-y-6 w-full">
        <div className="flex flex-col space-y-2">
          <p className=" bg-slate-300 h-2 rounded w-72"></p>
          <p className=" bg-slate-200 h-2 rounded w-72"></p>
          <p className=" bg-slate-200 h-2 rounded w-72"></p>
        </div>

        <div className="flex gap-3">
          <div className="h-8 flex-1 rounded-6px bg-slate-200" />
          <div className="h-8 flex-1 rounded-6px bg-slate-200" />
        </div>
      </div>
    </div>
  );
};
