import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import CloseIcon from "../../../../assets/icons/close.svg?react";
import {Button} from "../../../Common/Button/Button";
import {CenterModal} from "../../../Common/CenterModal/CenterModal";
import {del, post} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";


export const DeleteExtaModal: React.FC<any> = ({rentalId,exta_selected, displayModalDeleting, setDisplayModalDeleting, setListExtras}) => {
    const {t} = useTranslation();
        useState<boolean>(false);
        const [loading, setLoading] = useState<boolean>(false);

    const handleDeleteExtra = async () => {
        console.log('handleSubmitPicture', exta_selected.id)
        setLoading(true)

        const response = await del(
            `${import.meta.env.VITE_API_URL}${
                paths.API.RENTALS_UPDATE
            }/${rentalId}${paths.API.GUEST_PAGE_EXTRAS}/${exta_selected.id}`,
        );

        if (response.data?.success) {
            //console.log('response', response.data)
            setDisplayModalDeleting(false)
            setLoading(false)
            setListExtras(response.data.result.guest_page.extras_available)

        }else {
            console.log('error', response.data)
            setLoading(false)
        }


    }

    return (

        <CenterModal
            isVisible={displayModalDeleting}
            onClose={() => setDisplayModalDeleting(false)}
        >
            <div className="flex flex-col gap-10">

                <div className="flex flex-row  pb-0">
                    <div className="flex flex-col gap-3">
                        <p className="text-xl font-extrabold">
                            {t("Rental.Infos.TravelerPage.ExtraCard.deleteExtraTitle")}
                        </p>
                        <p className="text-low-contrast">
                            {t("Rental.Infos.TravelerPage.ExtraCard.deleteExtraSubtitle")}
                        </p>
                    </div>
                    <div className="cursor-pointer"
                         onClick={() => setDisplayModalDeleting(false)}>
                    <CloseIcon width={18} height={16}/>
                    </div>
                </div>


                <div className="flex gap-3">
                    <Button type="secondary" onClick={() => {
                        setDisplayModalDeleting(false)
                    }}>
                        {t("Global.cancel")}
                    </Button>
                    <Button type="destructive"
                            loading={loading}
                            onClick={() => {
                                handleDeleteExtra()
                            }}
                    >
                        {t("Global.removeWithConfirmation")}
                    </Button>
                </div>
            </div>
        </CenterModal>
    )
}
