import paths from "../../constants/paths";
import { ProgressionStepEnum } from "../../enums/GETenums";
import { post } from "../../helpers/APIHelper";
import { RentalLightListItemResponse } from "../../types/GETTypes";

/**
 * This hook can be used to change the progression current step
 * @param {Function} onStart - Function to execute when the fetch starts.
 * @param {Function} onEnd - Function to execute when the fetch ends.
 * @param {Function} onSuccess - Function to execute when the fetch is successful.
 * @param {Function} onError - Function to execute when there is an error.
 */
export const useCompleteCurrentStepProgression = async (
  onSuccess: () => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }
    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.COMPLETE_PROGRESSION_STEP}`,
      { progression_step: ProgressionStepEnum.STEP_SYNCHRONIZE }
    );

    if (response?.data?.success) {
      onSuccess();
    } else {
      onError(response?.response?.data?.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Updates the progression of a rental item.
 *
 * @param {RentalLightListItemResponse | undefined} newCurrentRental - The new rental item data to update.
 * @param {() => void} onSuccess - Callback function to be executed on successful update.
 * @param {(message: string | undefined) => void} onError - Callback function to be executed on error with an optional error message.
 * @param {() => void} [onStart] - Optional callback function to be executed at the start of the update process.
 * @param {() => void} [onEnd] - Optional callback function to be executed at the end of the update process, regardless of success or failure.
 */
export const useUpdateProgression = async (
  newCurrentRental: RentalLightListItemResponse | undefined,
  onSuccess: () => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    let res = null;

    res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.UPDATE_FROM_PROGRESSION}`,
      newCurrentRental
    );

    if (res?.data?.success) {
      onSuccess();
    } else {
      onError(res?.response?.data?.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};
