import React from "react";
import KeyIcon from "../../../assets/icons/key.svg?react";

interface Code {
  label: string;
  code: string;
}
const CodeComponent = ({label, code}: Code) => {
  if(!code){
    return null
  }
  return (
    <div className="flex flex-col rounded-lg ">
      <span className="text-low-contrast text-sm max-w-[120px] md:max-w-full md:w-full md:text-lg font-bold">{label}</span>
      <div className="flex mt-2 items-center bg-element-border/50 rounded-lg px-4 py-1">
        <KeyIcon className="w-6 h-6 text-low-contrast mr-2" />
        <span className="text-xl font-semibold text-high-contrast md:text-xxl">{code}</span>
      </div>
    </div>
  );
};

export default CodeComponent;
