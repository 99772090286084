import React from "react";
import { useTranslation } from "react-i18next";
import { RentalPlatformsCardBookingProps } from "./Platforms.type";
import { RentalPlatformsCardElement } from "./PlatformsCardElement";

import BookingIcon from "../../../../assets/icons/booking.svg?react";
import InfoIcon from "../../../../assets/icons/information.svg?react";

export const RentalPlatformsCardBooking: React.FC<
  RentalPlatformsCardBookingProps
> = ({ platformBooking, disabled, onClick = () => {} }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (disabled) return;

    onClick();
  };

  if (platformBooking === null || !platformBooking?.enabled)
    return (
      <RentalPlatformsCardElement
        classNames={{
          container: disabled ? "cursor-not-allowed" : "cursor-default",
        }}
        onClick={handleClick}
      >
        <div className="rounded-full h-3 w-3 bg-[#E5484D]" />

        <BookingIcon className="w-6 h-6" />

        <p className="font-semibold text-low-contrast">
          {t("Rental.Infos.Platforms.inactive")}
        </p>
      </RentalPlatformsCardElement>
    );
  else
    return (
      <RentalPlatformsCardElement
        classNames={{
          container: disabled ? "cursor-not-allowed" : "cursor-pointer",
        }}
        onClick={handleClick}
      >
        <div className="w-3 h-3 rounded-full bg-success" />
        <BookingIcon className="w-6 h-6" />
        <p className="font-semibold text-high-contrast">
          {t("Rental.Infos.Platforms.active")} •{" "}
          {t("Rental.Infos.Platforms.bookingAssign")} {platformBooking.room_id}
        </p>
        <InfoIcon className="w-5 h-5" />
      </RentalPlatformsCardElement>
    );
};
