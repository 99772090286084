export default [
  {
    id: 1,
    name: "Paris",
    rentalsId: [
      1, 2, 3, 4, 5, 9, 10, 11, 13, 18, 19, 24, 25, 27, 31, 33, 42, 43, 45, 51,
      53, 70, 84, 89,
    ],
  },
  {
    id: 2,
    name: "Balnéaire",
    rentalsId: [
      6, 7, 8, 21, 32, 36, 37, 41, 48, 50, 52, 54, 58, 60, 63, 67, 68, 72, 80,
      81, 83, 86, 87, 91, 38,
    ],
  },
  {
    id: 3,
    name: "Région Parisienne",
    rentalsId: [12, 14, 15, 16, 17, 20, 22, 23, 26, 29, 30, 34, 35, 39],
  },
  {
    id: 4,
    name: "Province",
    rentalsId: [
      28, 40, 44, 46, 47, 49, 56, 57, 59, 61, 64, 65, 66, 69, 71, 75, 76, 77,
      78, 79, 82, 85, 88, 90, 94, 95, 97, 98, 99, 100, 96,
    ],
  },
  {
    id: 5,
    name: "Montagne",
    rentalsId: [55, 62, 73, 74, 92, 93],
  },
];
