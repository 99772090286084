import React from "react";

import { TableListHeaderProps } from "../../../Common/TableList/TableList.type";

import { useTranslation } from "react-i18next";

import ChevronDownIcon from "../../../../assets/icons/chevron-down.svg?react";
import ChevronUpIcon from "../../../../assets/icons/chevron-up.svg?react";

export const AirbnbRentalsListHeader = ({
  sortField,
  sortDirection,
  areAllSelected,
  totalItems,
  disabled,
  onChangeSort,
  onSelectAll,
}: TableListHeaderProps) => {
  const { t } = useTranslation();

  const handleChangeSort = (column: string) => {
    let nextDirection: "asc" | "desc" = "asc";

    if (column === sortField && sortDirection === "asc") nextDirection = "desc";

    onChangeSort(column);
  };

  const getIcon = (column: string) => {
    const isActive = sortField === column;

    if (isActive && sortDirection === "asc") return <ChevronDownIcon />;
    else if (isActive && sortDirection === "desc") return <ChevronUpIcon />;
    else return <ChevronDownIcon />;
  };

  return (
    <tr className="table-header-group w-full bg-element-background border-b-1 border-element-border">
      <th
        className="w-8/12 px-2 py-3 text-sm ps-4 pe-2 rounded-tl-6px"
        onClick={() => handleChangeSort("name")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "name" && "underline"
          }`}
        >
          <p>{t("ThirdParty.Airbnb.titleRentals")}</p> {getIcon("name")}
        </div>
      </th>
      <th
        className="w-3/12 px-2 py-3 text-sm ps-4 pe-2 rounded-tl-6px"
        onClick={() => handleChangeSort("name")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "name" && "underline"
          }`}
        >
          <p>{t("ThirdParty.Airbnb.titleAccount")}</p> {getIcon("name")}
        </div>
      </th>
      <th
        className="w-1/12 px-2 py-3 text-sm ps-4 pe-2 rounded-tl-6px"
        onClick={() => handleChangeSort("name")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "name" && "underline"
          }`}
        >
          <p>{t("ThirdParty.Airbnb.titleState")}</p> {getIcon("name")}
        </div>
      </th>
    </tr>
  );
};
