/**
 * Component for contain form to add new traveler.
 *
 * @author Elysio
 */

import React, { ChangeEvent, useState } from "react";
import { AddtravelerType } from "./Addtraveler.type";

import { useTranslation } from "react-i18next";
import { PictureInput } from "../../Common/PictureInput/PictureInput";
import { TextInput } from "../../Common/TextInput/TextInput";
import { PhoneInput } from "../../Common/PhoneInput/PhoneInput";
import { InputSelect } from "../../Common/InputSelect/InputSelect";
import { TextAreaInput } from "../../Common/TextAreaInput/TextAreaInput";
import { Button } from "../../Common/Button/Button";
import { RightModal } from "../../Common/RightModal/RightModal";

import TimeZone from "../../../res/timeZone";
import Countries from "../../../constants/countries";
import Currencies from "../../../res/currencies";

import CheckWhite from "../../../assets/icons/check-white.svg?react";
import { useForm } from "react-hook-form";
import paths from "../../../constants/paths";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { post } from "../../../helpers/APIHelper";
import { CheckBox } from "../../Common/CheckBox/CheckBox";
import { ReviewInput } from "../../Common/ReviewInput/ReviewInput";

export const TravelerAddModal: React.FC<AddtravelerType> = ({
  isVisible,
  setIsVisible,
  onAddTraveler,
}) => {
  const { t } = useTranslation();

  const [currentFile, setCurrentFile] = useState(null);
  const [currentPath, setCurrentPath] = useState(null);

  const [countryValue, setCountryValue] = useState(73);
  const [currencyValue, setCurrencyValue] = useState(23);
  const [timezoneValue, setTimezoneValue] = useState("Europe/Paris");
  const [languageValue, setLanguageValue] = useState("France");

  const [isBusiness, setIsBusiness] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  /**
   * All values for sending form to API,
   * and handleSubmitForm call API.
   *
   * @author Elysio
   */
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      country_id: 73,
      locale: "France",
      timezone: "Europe/Paris",
      currency_id: 23,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      guest_type: "private",
      address: "",
      city: "",
      postal_code: "",
      note: "",
      photo: "",
      business_name: "",
      invoice_address: "",
      siret: "",
    },
  });

  const updatePicture = (file: Blob | string) => {
    setCurrentFile(file);
    setCurrentPath(URL.createObjectURL(file[0]));
  };

  /**
   * Function to handle submiting form to create new guest.
   *
   * @author Elysio
   * @param formData
   */
  const handleSubmitForm = async (formData: any) => {
    setIsLoading(true);
    let request = await post(
      `${import.meta.env.VITE_API_URL}/${paths.API.CRM_GUEST_MODULE.CREATE}`,
      {
        ...formData,
      },
      {
        headers: { "Content-Type": "application/json" },
      },
    );

    if (request.status === 200) {
      onAddTraveler(request.data.result.id);
      handleCloseModalAddTraveler();
      reset();
      setError(null);
    } else {
      setError(request.response.data.message);
    }

    setIsLoading(false);
  };

  /**
   * Function to close modal form when button "Annuler" is
   * clicked.
   *
   * @author Elysio
   */
  const handleCloseModalAddTraveler = () => {
    setIsVisible(false);
  };

  const handleCheckbox = (isBusiness: boolean) => {
    if (isBusiness) {
      setIsBusiness(true);
      setValue("guest_type", "business");
      setValue("address", "");
      setValue("invoice_address", "");
    } else {
      setIsBusiness(false);
      setValue("guest_type", "private");
      setValue("address", "");
      setValue("invoice_address", "");
    }
  };

  // ===== VIEW ====
  return (
    <>
      <RightModal
        title={t("Booking.Guest.addTraveler")}
        isVisible={isVisible}
        onClose={handleCloseModalAddTraveler}
      >
        <form
          onSubmit={handleSubmit(handleSubmitForm)}
          className="flex flex-col text-high-contrast font-normal w-full"
        >
          <p className={"text-gray-500 mt-2 mb-6 font-bold text-xs"}>
            {t("Booking.Guest.profilLabel")}
          </p>
          <div className={"w-full flex-1 py-2 rounded"}>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <div className={"flex justify-between items-center gap-4"}>
              <div className={"flex gap-2 flex-col w-full items-center"}>
                <div className={"w-full"}>
                  <TextInput
                    label={t("Booking.Guest.firstNameLabel")}
                    placeholder={"Louis"}
                    register={register("first_name", {
                      required: {
                        value: true,
                        message: t("RegisterMember.Infos.firstNameRequired"),
                      },
                    })}
                    error={errors.first_name && errors.first_name?.message}
                    required={true}
                  />
                </div>
                <div className={"w-full"}>
                  <TextInput
                    label={t("Booking.Guest.lastNameLabel")}
                    placeholder={"Dupont"}
                    register={register("last_name", {
                      required: {
                        value: true,
                        message: t("RegisterMember.Infos.lastNameRequired"),
                      },
                    })}
                    error={errors.last_name && errors.last_name?.message}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className={"flex justify-between gap-4 mt-4"}>
              <TextInput
                label={t("Booking.Guest.emailLabel")}
                placeholder={"louis.dupont@mail.com"}
                register={register("email", {
                  required: {
                    value: true,
                    message: t("Recover.emailRequired"),
                  },
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Regex pour valider l'email
                    message: t("Recover.emailInvalid"),
                  },
                })}
                error={errors.email && errors.email?.message}
                required={true}
              />
              <PhoneInput
                label={t("Booking.Guest.phoneNumberLabel")}
                placeholder={"0 00 00 00 00"}
                onChangeText={(e) => {
                  setValue("phone", e);
                }}
                error={errors.phone && errors.phone?.message}
                required={true}
                value={getValues("phone")}
                {...register("phone", {
                  required: {
                    value: true,
                    message: t("Recover.phoneRequired"),
                  },
                  pattern: {
                    value: /^\+?[\d\s-]{7,15}$/,
                    message: t("Recover.phoneInvalid"),
                  },
                })}
              />
            </div>
            <hr className={"mt-4 mb-2 border-gray-300"} />
            <div className="flex flex-col gap-4">
              <p className={"text-gray-500 mt-2  font-bold text-xs"}>
                {t("Booking.Guest.travelerType")}
              </p>
              <div className={"flex justify-between gap-4 items-center"}>
                <div className={"flex flex-col gap-2 w-1/2"}>
                  <div className="flex gap-2 items-center">
                    <div className="flex gap-2 items-center">
                      <CheckBox
                        isRadio={true}
                        value={isBusiness}
                        onChange={(e) => handleCheckbox(e)}
                      />
                    </div>
                    <label
                      htmlFor={"business"}
                      className={"font-bold text-gray-500 cursor-pointer"}
                      onClick={() => handleCheckbox(!isBusiness)}
                    >
                      {t("Booking.Guest.businessType")}
                    </label>
                  </div>

                  {isBusiness ? (
                    <>
                      <TextInput
                        label={t("Booking.Guest.businessName")}
                        placeholder={"SAS Immozed"}
                        register={register("business_name", {
                          required: {
                            value: true,
                            message: t("Booking.Guest.businessNameRequired"),
                          },
                        })}
                        error={
                          errors.business_name && errors.business_name?.message
                        }
                        required={true}
                      />
                      <TextInput
                        label={t("Booking.Guest.invoiceAddress")}
                        placeholder={"25 rue business name"}
                        register={register("invoice_address", {
                          required: {
                            value: true,
                            message: t("Booking.Guest.invoiceAddressRequired"),
                          },
                        })}
                        error={
                          errors.invoice_address &&
                          errors.invoice_address?.message
                        }
                        required={true}
                      />
                      <TextInput
                        label={t("Booking.Guest.zipCode")}
                        placeholder={"75001"}
                        register={register("postal_code", {
                          required: {
                            value: true,
                            message: t("Booking.Guest.zipCodeRequired"),
                          },
                          pattern: {
                            value: /^[0-9]{4,10}$/,
                            message: t("Booking.Guest.zipCodeInvalid"),
                          },
                        })}
                        error={errors.zip && errors.zip?.message}
                        required={true}
                      />
                    </>
                  ) : (
                    <>
                      <TextInput
                        label={t("Booking.Guest.addressLabel")}
                        placeholder={"3 rue de Source"}
                        register={register("address", {
                          required: {
                            value: true,
                            message: t("Booking.Guest.addressRequired"),
                          },
                        })}
                        error={errors.address && errors.address?.message}
                        required={true}
                      />
                      <TextInput
                        label={t("Booking.Guest.postalCodeLabel")}
                        placeholder={"75000"}
                        register={register("postal_code", {
                          required: {
                            value: true,
                            message: t("Booking.Guest.zipRequired"),
                          },
                          pattern: {
                            value: /^[0-9]{4,10}$/,
                            message: t("Booking.Guest.zipCodeInvalid"),
                          },
                        })}
                        error={
                          errors.postal_code && errors.postal_code?.message
                        }
                        required={true}
                      />
                    </>
                  )}
                </div>
                <div className={"flex flex-col gap-2 w-1/2"}>
                  <div className="flex gap-2 items-center">
                    <div className="grid place-items-center relative">
                      <div className="flex gap-2 items-center">
                        <CheckBox
                          isRadio={true}
                          value={!isBusiness}
                          register={register("guest_type")}
                          onChange={(e) => handleCheckbox(!e)}
                        />
                      </div>
                    </div>
                    <label
                      htmlFor={"private"}
                      className={"font-bold text-gray-500 cursor-pointer"}
                      onClick={() => handleCheckbox(!isBusiness)}
                    >
                      {t("Booking.Guest.guestType")}
                    </label>
                  </div>
                  {isBusiness && (
                    <>
                      <TextInput
                        label={t("Booking.Guest.siret")}
                        placeholder={"FR1234567891"}
                        register={register("siret", {
                          required: {
                            value: true,
                            message: t("Booking.Guest.siretRequired"),
                          },
                        })}
                        error={errors.siret && errors.siret?.message}
                        required={true}
                      />
                    </>
                  )}
                  <TextInput
                    label={t("Booking.Guest.cityLabel")}
                    placeholder={"Paris"}
                    register={register("city", {
                      required: {
                        value: true,
                        message: t("Booking.Guest.cityRequired"),
                      },
                    })}
                    error={errors.city && errors.city?.message}
                    required={true}
                  />
                  <InputSelect
                    label={t("Profil.country")}
                    items={Countries.map(({ value, label, Icon }) => ({
                      value,
                      label,
                      Icon,
                    }))}
                    error={errors.country_id && errors.country_id?.message}
                    register={register("country_id", {
                      required: {
                        value: true,
                        message: t("Booking.Guest.countryRequired"),
                      },
                      onChange: (e) => {
                        setCountryValue(e.target.value);
                      },
                    })}
                    defaultValue={getValues("country_id")}
                    selectedValue={countryValue}
                  />
                </div>
              </div>
            </div>
            <hr className={"mt-4 mb-4 border-gray-300"} />
            <p className={"text-gray-500 mt-2  font-bold text-xs"}>
              {t("Global.info")}
            </p>
            <div className={"flex justify-between items-center gap-4 mt-4"}>
              <div className={"w-1/2"}>
                <InputSelect
                  label={t("Profil.timeZone")}
                  items={TimeZone.map(({ value, label, Icon }) => ({
                    value: value,
                    label: label,
                  }))}
                  error={errors.timezone && errors.timezone?.message}
                  defaultValue={getValues("timezone")}
                  selectedValue={timezoneValue}
                  register={register("timezone", {
                    required: {
                      message: t("Booking.Guest.timezoneRequired"),
                    },
                    onChange: (e) => {
                      setTimezoneValue(e.target.value);
                    },
                  })}
                  required={true}
                />
              </div>
              <div className={"w-1/2"}>
                <InputSelect
                  label={t("Booking.Guest.languageCommunication")}
                  items={Countries.map(({ value, label, Icon }) => ({
                    value: label,
                    label: label,
                    Icon: Icon,
                  }))}
                  error={errors.locale && errors.locale?.message}
                  defaultValue={getValues("locale")}
                  register={register("locale", {
                    required: {
                      message: t("Booking.Guest.languageRequired"),
                    },
                    onChange: (e) => {
                      setLanguageValue(e.target.value);
                    },
                  })}
                  selectedValue={languageValue}
                />
              </div>
            </div>
            <div className={"w-full mt-2"}>
              <InputSelect
                label={t("Rental.Pricing.PricingRules.currencyTitle")}
                items={Currencies.map(({ code, name, symbol, id }) => ({
                  value: id,
                  label: name + " (" + symbol + ")",
                }))}
                register={register("currency_id", {
                  required: {
                    value: true,
                    message: t("Booking.Guest.currencyRequired"),
                  },
                  onChange: (e) => {
                    setCurrencyValue(e.target.value);
                  },
                })}
                defaultValue={getValues("currency_id")}
                error={errors.currency_id && errors.currency_id?.message}
                required={true}
                onSelect={(e) => {
                  setCurrencyValue(e);
                }}
                selectedValue={currencyValue}
              />
              <ReviewInput
                label={t("Booking.Guest.review")}
                rate={2}
                classNameDiv={"mt-4"}
                onChangeRate={(rate: number) => {
                  setValue("average_rating", rate);
                }}
              />
            </div>
            <hr className={"mt-6 mb-4 border-gray-300"} />
            <div>
              <TextAreaInput
                register={register("note", {})}
                error={errors.note && errors.note?.message}
                label={t("Booking.Guest.detailTraveler")}
                rows={4}
                value={getValues("note")}
              />
            </div>
          </div>
          <hr className={"mt-6 mb-4 border-gray-300"} />
          <div className={"flex justify-between gap-4 pb-4"}>
            <Button type={"secondary"} onClick={handleCloseModalAddTraveler}>
              {t("Global.cancel")}
            </Button>
            <Button type={"primary"} RightIcon={CheckWhite} loading={isLoading}>
              {t("Booking.Guest.addTravelerConfirm")}
            </Button>
          </div>
        </form>
      </RightModal>
    </>
  );
};
