import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../Common/Button/Button";
import { CarouselWizard } from "../../../Common/CarouselWizard/CarouselWizard";
import { RightModal } from "../../../Common/RightModal/RightModal";
import { BookingConnectModalProps } from "../../Booking/Connect/BookingConnectModal.type";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import { Separator } from "../../../Common/Separator/Separator";
import { BookingConnectStep1 } from "../../Booking/Connect/BookingConnectStep1";
import {BookingConnectSingleRentalStep2} from "../../../Rental/Infos/Platforms/BookingConnectSingleRentalStep2";
import {AirbnbSynchronizeStepImport} from "./AirbnbSynchronizeStepImport";
import {useForm} from "react-hook-form";
import {AirbnbSynchronizeStepAccount} from "./AirbnbSynchronizeStepAccount";
import {get} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";

export type AirbnbSynchronizeForm = {
    account_id: string;
    listing_id: string;
};
export const PlatformConnectSingleRentalModal: React.FC<BookingConnectModalProps> = ({isVisible, onClose, onFinish,rental, platformActive}) => {
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState(0);
    const handleCancel = () => {
        if (currentStep === 0) {
            onClose();
        } else {
            setCurrentStep((prev) => prev - 1);
        }
    };
    const handleSubmit = () => {
        if (currentStep === 1) {
            onFinish(selected.airbnb_account_id, selected.airbnb_listing_id);
            onClose();
        }
    };
    const handleClose = () => {
        onClose();
        setIsAssociated(false);
    }

    const form = useForm<AirbnbSynchronizeForm>({
        defaultValues: {
            account_id: "",
            listing_id: "",
        },
    });
    const [isAssociated, setIsAssociated] = useState<boolean>(false);
    const [announces, setAnnounces] = useState([]);
    const [listings, setListings] = useState<{
        apiError: string | null;
        formError: string | null;
        ids: string[];
    }>({ids: [], apiError: null, formError: null});
    const [selected, setSelected] = useState<object>({
        airbnb_account_id: null,
        airbnb_listing_id: null,
    }); // selected account and listing for resynchronization
    const handleNext = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const handleFetchListings = async (account_id) => {
        setSelected((prev) => ({...prev, airbnb_account_id: account_id}));
        const res = await get(
            `${import.meta.env.VITE_API_URL}${paths.API.AIRBNB.GET_LISTINGS}?airbnb_account_id=${account_id}`
        );
        let allAnnonces = [];
        if (res?.data?.success) {
              const listingsData = res?.data?.result?.listings;

                listingsData.map((listing: any) => {
                    const data = {
                        value:listing.id,
                        label:listing.name
                    }
                    allAnnonces.push(data)
                })
            setAnnounces(allAnnonces);
        }
    }

    return (
        <RightModal
            isVisible={isVisible}
            onClose={handleClose}
            title={platformActive === "booking" ? t("Progression.bookingConnect") : t("Progression.airbnbConnect")}
        >
            {
                platformActive === "booking" ? (
                    <div className="flex flex-col w-full">
                        <div className="flex-1 overflow-y-scroll">
                            <CarouselWizard
                                currentStepIndex={currentStep}
                                onChangeStep={(value) => setCurrentStep(value)}
                                steps={[
                                    {
                                        title: t("Progression.bookingConnectStep1Title"),
                                        Node: (
                                            <BookingConnectStep1
                                                onNext={() => setCurrentStep((prev) => prev + 1)}
                                            />
                                        ),
                                    },
                                    {
                                        title: t("Progression.bookingConnectSingleRentalStep2Title"),
                                        Node: <BookingConnectSingleRentalStep2 rentalId={rental.id}
                                                                               setIsAssociated={setIsAssociated}/>,
                                    },
                                ]}
                            />
                            <p className="font-semibold cursor-pointer text-icon-active hover:underline mt-3">
                                <a
                                    href="https://help.superhote.com/support/solutions/articles/150000113541-synchroniser-et-importer-les-logements-depuis-booking-vers-superhote"
                                    target="_blank"
                                >
                                    {t("Rental.Infos.Platforms.Booking.tutorial")}
                                </a>
                            </p>
                        </div>


                        <Separator/>

                        <div className="flex gap-3">
                            <Button type="secondary" onClick={handleCancel}>
                                {t("Global.cancel")}
                            </Button>
                            <Button
                                RightIcon={CheckIcon}
                                disabled={currentStep === 0 || !isAssociated}
                                onClick={handleSubmit}
                            >
                                {t("Global.synchronize")}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col w-full">
                        <div className="flex-1 overflow-y-scroll">
                            <CarouselWizard
                                currentStepIndex={currentStep}
                                onChangeStep={(value) => setCurrentStep(value)}
                                steps={[
                                    {
                                        title: t(
                                            "Rental.Infos.Platforms.SynchronizeModal.Airbnb.airbnbSynchronizeStep1Title"
                                        ),
                                        Node: (
                                            <AirbnbSynchronizeStepAccount
                                                form={form}
                                                apiError={listings.apiError}
                                                formError={listings.formError}
                                                onNext={handleNext}
                                                selectAccount={handleFetchListings}
                                            />
                                        ),
                                    },
                                    {
                                        title: t(
                                            "Rental.Infos.Platforms.SynchronizeModal.Airbnb.airbnbSynchronizeStep2Title"
                                        ),
                                        Node: (
                                            <AirbnbSynchronizeStepImport
                                                form={form}
                                                apiError={listings.apiError}
                                                formError={listings.formError}
                                                announces={announces}
                                                selectListing={setSelected}
                                            />
                                        ),
                                    },
                                ]}
                            />
                        </div>
                        <Separator/>
                        <div className="flex gap-x-3">
                            <Button type="secondary" onClick={onClose}>
                                {t("Global.cancel")}
                            </Button>
                            <Button
                                RightIcon={CheckIcon}
                                disabled={
                                    form.getValues("account_id") && form.getValues("listing_id")
                                        ? false
                                        : true
                                }
                                onClick={handleSubmit}
                            >
                                {t("Global.synchronize")}
                            </Button>
                        </div>
                    </div>
                )
            }

        </RightModal>
    );
};
