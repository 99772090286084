import moment from "moment";
import React from "react";
import {
  CALENDAR_CELL_HEIGHT,
  CALENDAR_CELL_WIDTH,
  CALENDAR_HEADER_HEIGHT,
} from "../../constants/calendar";
import { getDayName } from "../../helpers/dateHelper";
import { DateItemProps } from "./Calendar.type";
import { capitalizeFirstLetter } from "../../helpers/stringHelper";

export const CalendarDateItem: React.FC<DateItemProps> = ({
  index,
  daysOffset,
}) => {
  const date = moment(new Date()).add(index - daysOffset, "days");
  const now = moment(new Date());

  const isToday =
    date.format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD");

  const monthTextColor =
    now.format("MM") === date.format("MM")
      ? "text-active"
      : "text-high-contrast";

  const getBg = () => {
    if (isToday) return "bg-[#E54D2E]";
    else if (date.weekday() === 5 || date.weekday() === 6)
      return "bg-[#5C6163]/20";
    else return "bg-white";
  };

  const getBorder = () => {
    if (date.format("DD") === "01") {
      return "border-l-1 border-[#5C6163]/20";
    } else return "";
  };

  return (
    <th
      className={`overflow-x-visible ${getBorder()}`}
      style={{
        minWidth: CALENDAR_CELL_WIDTH,
        maxWidth: CALENDAR_CELL_WIDTH,
        height: CALENDAR_HEADER_HEIGHT,
      }}
    >
      {/* Upper part */}
      <div
        style={{ height: CALENDAR_CELL_HEIGHT }}
        className={`flex overflow-x-visible`}
      >
        {index === 0 && (
          <div className="flex-col">
            <p className={`ml-2 overflow-visible text-lg ${monthTextColor}`}>
              {capitalizeFirstLetter(date.format("MMM"))}
            </p>
            <p className="text-xxs text-low-contrast font-semibold">2024</p>
          </div>
        )}
        {date.format("DD") === "01" && (
          <div className="flex-col">
            <p className={`ml-2 overflow-visible text-lg ${monthTextColor}`}>
              {capitalizeFirstLetter(date.format("MMM"))}
            </p>
            <p className="text-xxs text-low-contrast font-semibold">
              {date.format("YYYY")}
            </p>
          </div>
        )}
      </div>

      {/* Day */}
      <div
        className={`${getBg()} rounded-6px`}
        style={{ height: CALENDAR_CELL_HEIGHT + 5 }}
      >
        <p
          className={`${
            isToday ? "text-white" : "text-low-contrast"
          } font-light text-sm pt-1`}
        >
          {getDayName(date.toDate())}
        </p>
        <p
          className={`${
            isToday ? "text-white" : "text-high-contrast"
          } font-semibold text-[17px]`}
        >
          {date.format("DD")}
        </p>
      </div>
    </th>
  );
};
