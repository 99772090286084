/**
 * Main page for details platform, see video and tutorial to connect integration.
 *
 * @author Elysio
 */

import { PlatFormDetailsBody } from "../../components/PlatForm/Details/PlatFormDetailsBody";
import { PlatFormDetails } from "../../components/PlatForm/Details/PlatFormDetails";
import React from "react";
import { MainPageDetailsThirdPartyProps } from "./Platform.type";

export const MainPageDetailsThirdParty = ({
  pageData,
  setPage,
  setPageData,
  modal,
  page,
  isConnected,
}: MainPageDetailsThirdPartyProps) => {
  return (
    <>
      <PlatFormDetails
        title={pageData?.title}
        Icon={pageData?.Icon}
        description={pageData?.description}
        ModalConnect={pageData?.ModalConnect}
        onClickDefaultPage={() => {
          setPage("default");
          setPageData(null);
        }}
      >
        <PlatFormDetailsBody
          modal={modal}
          pageData={pageData}
          page={page}
          isConnected={isConnected}
        >
          {/*Ici on pourra ajouter la liste des resa / hebergement etc, c'est fais exprès !*/}
          {isConnected && <pageData.DataList />}
        </PlatFormDetailsBody>
      </PlatFormDetails>
    </>
  );
};
