import React from "react";
import { SimpleBox } from "../../Modal/Airbnb/Connect/AirbnbConnectStepImport";

export const ManagementTeamTeamCheckbox: React.FC<{
  teamName: string;
  value: boolean;
  onClick: () => void;
}> = ({ teamName, value, onClick }) => {
  return (
    <div
      className="flex flex-row gap-3 items-center cursor-pointer"
      onClick={onClick}
    >
      <p className="text-high-contrast font-semibold flex-1">{teamName}</p>

      <SimpleBox value={value} />
    </div>
  );
};
