import React from "react";
import { TemplateLineProps } from "./TemplatesModal.type";
import { useTranslation } from "react-i18next";
import { getLanguageFlag } from "../../../helpers/languageHelper";
import { shorterStringBeforeSpace } from "../../../helpers/stringHelper";
import { Button } from "../../Common/Button/Button";

import DotsVerticalIcon from "../../../assets/icons/dots-vertical.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import CopyIcon from "../../../assets/icons/copy.svg?react";
import TrashIcon from "../../../assets/icons/trash-active.svg?react";
import { ValueType } from "../../../types/commonTypes";

export const TemplateItem: React.FC<TemplateLineProps> = ({
  template,
  onInsert,
  onEdit,
  onDuplicate,
  onRemove,
}) => {
  const { t } = useTranslation();

  const handleClickMore = (value: ValueType) => {
    if (value === "edit") onEdit(template);
    else if (value === "duplicate") onDuplicate(template);
    else if (value === "remove") onRemove(template);
  };

  return (
    <tr className="">
      <td className="flex flex-row space-x-2 px-2 py-3 border-b-1 last:border-b-0 border-element-border-light">
        <div className="flex-1 flex flex-col text-sm">
          <div className="flex gap-2 items-center">
            <p className="text-high-contrast font-semibold">{template.title}</p>
            {getLanguageFlag(template.language.code)}
          </div>

          <p className="text-low-contrast font-light">
            {shorterStringBeforeSpace(template.message?.toString() ?? "", 100)}{" "}
          </p>
        </div>

        <div>
          <Button type="secondary" onClick={() => onInsert(template)}>
            {t("Templates.insert")}
          </Button>
        </div>

        <div>
          <Button
            type="secondary"
            RightIcon={DotsVerticalIcon}
            onClick={handleClickMore}
            dropItems={[
              { label: t("Global.edit"), value: "edit", Icon: EditIcon },
              {
                label: t("Global.duplicate"),
                value: "duplicate",
                Icon: CopyIcon,
              },
              {
                label: t("Global.remove"),
                value: "remove",
                isRed: true,
                Icon: TrashIcon,
              },
            ]}
          ></Button>
        </div>
      </td>
    </tr>
  );
};
