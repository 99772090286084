import { t } from "i18next";
import React from "react";
import BalanceSheetIcon from "../../../assets/icons/balance-sheet.svg?react";
import { Card } from "../../Common/Card/Card";
import { WorkInProgress } from "../../WorkInProgress/WorkInProgress";

type ReservationInvoiceCardProps = {};

export const ReservationInvoiceCard: React.FC<
  ReservationInvoiceCardProps
> = ({}) => {
  return (
    <Card Icon={BalanceSheetIcon} label={t("Booking.Invoice.title")}>
      <WorkInProgress />
    </Card>
  );
};
