const timeZoneList = [
    {
        label: "GMT+12:00",
        value: "Europe/Moscow",
    },
    {
        label: "GMT+11:00",
        value: "Pacific/Fiji",
    },
    {
        label: "GMT+10:00",
        value: "Pacific/Guam",
    },
    {
        label: "GMT+09:00",
        value: "Asia/Seoul",
    },
    {
        label: "GMT+08:00",
        value: "Asia/Shanghai",
    },
    {
        label: "GMT+01",
        value: "Europe/Paris",
    }

]

export default timeZoneList;
