import React from "react";
import {RightModal} from "../../Common/RightModal/RightModal";
import {useTranslation} from "react-i18next";
import {CenterModal} from "../../Common/CenterModal/CenterModal";
import {
  NotificationStatusesEnumResponse,
  NotificationStatusesListItemResponse,
} from "../../../types/GETTypes";
import {
  capitalizeFirstLetter,
  generateSentenceForPlatforms,
} from "../../../helpers/stringHelper";
import moment from "moment";
import momentTimezone from "moment-timezone";
import {Button} from "../../Common/Button/Button";

export const ScheduledMessageViewModal: React.FC<{
  message: NotificationStatusesListItemResponse | null | undefined;
  withEditButton?: boolean;
  isVisible: boolean;
  onClose: () => void;
  onEdit: (message: NotificationStatusesListItemResponse) => void;
  guest_email: string;
}> = ({
  message,
  withEditButton = true,
  isVisible,
  onClose,
  onEdit,
  guest_email,
}) => {
  const {t} = useTranslation();

  const handleEdit = () => {
    onEdit(message!);
    handleClose();
  };

  const handleClose = () => {
    onClose();
  };

  const showEditBtn =
    withEditButton &&
    message?.status !== NotificationStatusesEnumResponse.COMPLETED;

  const currentTimeZone = momentTimezone.tz.guess();
  const messageDate = moment.utc(message?.ready_at)
    .tz(currentTimeZone)
    .format("dddd D MMM YYYY [à] HH:mm");

  const channelPlatformSend = generateSentenceForPlatforms(
    message?.channels ?? []
  );
  return (
    <CenterModal
      isVisible={isVisible}
      onClose={handleClose}
      title={message?.notification.name}
      size="large"
    >
      <p className="font-light text-low-contrast">
        {`${capitalizeFirstLetter(
          t("ScheduledMessageList.scheduledTo")
        )} ${capitalizeFirstLetter(messageDate)} `}
        {channelPlatformSend}
      </p>

      <p className="bg-element-background rounded-6px w-full p-2 font-semibold mt-4 whitespace-pre-line">
        {message?.content_preview.message}
      </p>

      <div className="flex flex-row gap-3 mt-4">
        {showEditBtn && (
          <Button type="secondary" onClick={handleEdit}>
            {t("Global.edit")}
          </Button>
        )}
        <Button onClick={handleClose}>{t("Global.ok")}</Button>
      </div>
    </CenterModal>
  );
};
