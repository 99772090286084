import React from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "../Switch/Switch";
import { DaysFilterDayItem } from "./DaysFilterDayItem";

export const Daysfilter: React.FC<{
  disabled?: boolean;
  value: {
    isActive: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
  onChange: (value: {
    isActive: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  }) => void;
}> = ({ disabled, value, onChange }) => {
  const { t } = useTranslation();

  const areAllChecked = () => {
    return (
      value.monday &&
      value.tuesday &&
      value.wednesday &&
      value.thursday &&
      value.friday &&
      value.saturday &&
      value.sunday
    );
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col">
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={() => onChange({ ...value, isActive: !value.isActive })}
        >
          <p className="font-semibold text-low-contrast">
            {t("Global.applyOnDays")}
          </p>

          <Switch value={value.isActive} disabled={disabled} />
        </div>
      </div>

      {value.isActive && (
        <div className="flex justify-between">
          <p className="text-high-contrast font-semibold self-end">
            {t("Global.daysConcerned")}
          </p>

          <div className="flex gap-2">
            <DaysFilterDayItem
              value={value.monday}
              label="Lu"
              disabled={disabled}
              onChange={() => onChange({ ...value, monday: !value.monday })}
            />
            <DaysFilterDayItem
              value={value.tuesday}
              label="Ma"
              disabled={disabled}
              onChange={() => onChange({ ...value, tuesday: !value.tuesday })}
            />
            <DaysFilterDayItem
              value={value.wednesday}
              label="Me"
              disabled={disabled}
              onChange={() =>
                onChange({ ...value, wednesday: !value.wednesday })
              }
            />
            <DaysFilterDayItem
              value={value.thursday}
              label="Je"
              disabled={disabled}
              onChange={() => onChange({ ...value, thursday: !value.thursday })}
            />
            <DaysFilterDayItem
              value={value.friday}
              label="Ve"
              disabled={disabled}
              onChange={() => onChange({ ...value, friday: !value.friday })}
            />
            <DaysFilterDayItem
              value={value.saturday}
              label="Sa"
              disabled={disabled}
              onChange={() => onChange({ ...value, saturday: !value.saturday })}
            />
            <DaysFilterDayItem
              value={value.sunday}
              label="Di"
              disabled={disabled}
              onChange={() => onChange({ ...value, sunday: !value.sunday })}
            />
            <DaysFilterDayItem
              value={areAllChecked()}
              label="Tout"
              disabled={disabled}
              onChange={() =>
                onChange({
                  ...value,
                  monday: true,
                  tuesday: true,
                  wednesday: true,
                  thursday: true,
                  friday: true,
                  saturday: true,
                  sunday: true,
                })
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};
