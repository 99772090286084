import React, {useEffect, useState} from "react";
import {Autocomplete, OptionsFilter, ComboboxItem} from "@mantine/core";
import {TextType} from "../../../types/commonTypes";
import SearchIcon from "../../../assets/icons/search.svg?react";
import {SearchLoader} from "../SearchLoader/SearchLoader";
import {Button} from "../Button/Button";
import {useTranslation} from "react-i18next";
import {GuestResponse} from "../../../types/GETTypes";

export const AutoCompleteInput: React.FC<{
  label?: TextType;
  initialValue?: string;
  placeholder?: TextType;
  disabled?: boolean;
  isSearching?: boolean;
  data: {value: string; label: string}[];
  onSearch?: (text: string) => void;
  onTrueDataChoosen?: (item: {value: string; label: string} | null) => void;
  onEdit: () => void;
  onReinit: () => void;
  includeCheck?: {
    guestByEmail?: boolean;
  };
  guests?: GuestResponse[];
}> = ({
  label,
  initialValue,
  placeholder,
  disabled,
  isSearching = false,
  data,
  onSearch = () => {},
  onTrueDataChoosen = () => {},
  onEdit = () => {},
  onReinit = () => {},
  includeCheck,
  guests,
}) => {
  const {t} = useTranslation();
  const [currentText, setCurrentText] = useState<string>("");

  useEffect(() => {
    if (initialValue) setCurrentText(initialValue);
  }, [initialValue]);

  const handleChangeValue = (text: string) => {
    onSearch(text);
    setCurrentText(text);

    let selectedData = null;
    data?.forEach((d) => {
      if (d.label === text) selectedData = d;
    });

    onTrueDataChoosen(selectedData);
  };

  const optionsFilter: OptionsFilter = ({options, search}) => {
    return (options as ComboboxItem[]).filter((option) => {
      if (includeCheck?.guestByEmail && guests) {
        const findEmailSearch = guests?.find((guest) =>
          guest?.email?.toLowerCase()?.includes(search)
        );
        return (
          option.label.toLowerCase().includes(search.toLowerCase()) ||
          option.label
            .toLowerCase()
            .includes(
              `${findEmailSearch?.first_name} ${findEmailSearch?.last_name}`.toLowerCase()
            )
        );
      }
      return option.label.toLowerCase().includes(search.toLowerCase());
    });
  };

  const getCloseIcon = () => {
    if (isSearching) return <SearchLoader />;
    else return null;
  };

  const handleReinit = () => {
    setCurrentText("");
    onReinit();
  };

  const handleEdit = () => {
    setCurrentText("");
    onEdit();
  };

  return (
    <div className="flex flex-col flex-1 gap-1">
      <p className="text-high-contrast font-semibold mb-1">{label}</p>
      <div className="flex gap-3">
        <Autocomplete
          placeholder={placeholder}
          leftSection={<SearchIcon className="w-5 h-5" />}
          rightSection={getCloseIcon()}
          value={currentText}
          onChange={handleChangeValue}
          data={data}
          filter={optionsFilter}
          disabled={disabled}
          className="flex-1"
        />

        <div className="w-32">
          <Button
            type="secondary"
            onClick={handleReinit}
            disabled={disabled || currentText === ""}
          >
            {t("Global.reinit")}
          </Button>
        </div>
        <div className="w-32">
          <Button
            type="secondary"
            onClick={handleEdit}
            disabled={disabled || currentText === ""}
          >
            {t("Global.edit")}
          </Button>
        </div>
      </div>
    </div>
  );
};
