import {EditContactForm} from "../../pages/Settings/Settings.type";
import {RentalInformationsResponse} from "../../types/GETTypes";
import {del, post} from "../../helpers/APIHelper";
import paths from "../../constants/paths";
import {Rental} from "../../pages/RentalPage/RentalPage.type";
import {
  InstructionForm,
  WifiUpdateForm,
} from "../../components/Rental/TravelerPage/Instructions/InstructionCard.type";

export const useUpdateContact = async (
  rentalId: number | undefined,
  guestPagelId: number | undefined,
  contacts: EditContactForm,
  onSuccess: (rental: Rental) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const jsonContacts = contacts.contacts.map((contact) =>
      JSON.stringify(contact)
    );

    const jsonContactsExtras = contacts.contacts_extras.map((contact) =>
      JSON.stringify(contact)
    );

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE.INDEX
      }/${guestPagelId}/contacts`,
      {
        contacts: jsonContacts,
        contacts_extras:jsonContactsExtras
      }
    );

    if (res?.data.success) {
      const data: Rental = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useUpdateInstruction = async (
  rentalId: number | undefined,
  guestPageInstructionId: number | undefined,
  instruction: InstructionForm,
  onSuccess: (rental: Rental) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE.INDEX
      }${paths.API.GUEST_INSTRUCTION}/${guestPageInstructionId}`,
      {...instruction}
    );

    if (res?.data.success) {
      const data: Rental = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useDeleteContact = async (
  rentalId: number | undefined,
  contactId: number | undefined,
  onSuccess: (rental: Rental) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await del(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_CONTACTS_DELETE
      }/${contactId}`
    );

    if (res?.data.success) {
      const data: Rental = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useEnableInformations = async (
  rentalId: number | undefined,
  onSuccess: (rental: Rental) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_ENABLE_INFORMATIONS
      }`
    );

    if (res?.data.success) {
      const data: Rental = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useDisableInformations = async (
  rentalId: number | undefined,
  onSuccess: (rental: Rental) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_DISABLE_INFORMATIONS
      }`
    );

    if (res?.data.success) {
      const data: Rental = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useDisableReviews = async (
  rentalId: number | undefined,
  onSuccess: (rental: Rental) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_DISABLE_REVIEWS
      }`
    );

    if (res?.data.success) {
      const data: Rental = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useEnableReviews = async (
  rentalId: number | undefined,
  onSuccess: (rental: Rental) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_ENABLE_REVIEWS
      }`
    );

    if (res?.data.success) {
      const data: Rental = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useUpdateWifi = async (
  rentalId: number | undefined,
  data: WifiUpdateForm,
  onSuccess: (rental: Rental) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_UPDATE_WIFI
      }`,
      data
    );
    if (res?.data.success) {
      const data: Rental = res?.data?.result;

      if (data) {
        onSuccess(data);
      }
    } else {
      onError(res?.data?.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useUpdateInformationGuestPage = async (
  rentalId: number | undefined,
  data: any,
  onSuccess: (rental: Rental) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_REQUIRED_RESSOURCES
      }`,
      data
    );

    if (res?.data.success) {
      const data: Rental = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useEnableGuestPage = async (
  rentalId: string | undefined,
  onSuccess: (rental: Rental) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_ENABLE
      }`
    );

    if (res?.data.success) {
      const data: Rental = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useDisableGuestPage = async (
  rentalId: string | undefined,
  onSuccess: (rental: Rental) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_DISABLE
      }`
    );

    if (res?.data.success) {
      const data: Rental = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useDisableExtras = async (
  rentalId: number | undefined,
  onSuccess: (rental: Rental) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_DISABLE_EXTRAS
      }`
    );

    if (res?.data.success) {
      const data: Rental = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useEnableExtras = async (
  rentalId: string | undefined,
  onSuccess: (rental: Rental) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE_ENABLE_EXTRAS
      }`
    );

    if (res?.data.success) {
      const data: Rental = res?.data?.result;

      if (data) {
        console.log(data, "[onSuccess]");
        onSuccess(data);
      }
    } else {
      console.log(res?.data.message, "[onSuccess]");
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};
