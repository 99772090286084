import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ArrowRightIcon from "../../../../assets/icons/arrow-right-white.svg?react";
import { WelcomeStepEnum } from "../../../../enums/GETenums";
import { Button } from "../../../Common/Button/Button";
import { CheckBox } from "../../../Common/CheckBox/CheckBox";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import { TextAreaInput } from "../../../Common/TextAreaInput/TextAreaInput";
import { Title } from "../../../Common/Title/Title";
import { RegisterThanksForm, RegisterThanksProps } from "./RegisterThanks.type";

export const RegisterThanks: React.FC<RegisterThanksProps> = ({
  firstName,
  onSubmit,
  onConfigure,
  loading = false,
}) => {
  const { t } = useTranslation();

  const form = useForm<RegisterThanksForm>({
    mode: "all",
    defaultValues: {
      usage: "",
      objectives: {
        moreReservations: false,
        saveTime: false,
        automateRentalBusiness: false,
        becomePlatformIndependent: false,
        exchangeWithOtherRentalBusiness: false,
        other: false,
        otherValue: "",
      },
    },
  });

  const handleSave = (data: RegisterThanksForm) => {
    onSubmit({ usage: data.usage, objectives: data.objectives });
  };

  const allObjectivesValuesAreFalse = (values?: RegisterThanksForm) => {
    if (values) {
      return Object.values(values.objectives).every((obj) => !obj);
    } else {
      return Object.values(form.getValues("objectives")).every((obj) => !obj);
    }
  };

  const checkAllValues = (values: RegisterThanksForm) => {
    form.trigger("usage");
    if (allObjectivesValuesAreFalse(values)) {
      form.setError("objectives", {
        message: t("Register.Thanks.Objectives.objectivesError"),
      });
    } else {
      form.clearErrors("objectives");
    }
  };

  form.watch();

  return (
    <div id="thanks-root">
      <Title>
        {t("Register.Thanks.title")}, {firstName ? `${firstName} !` : ""}
      </Title>

      <div className="mt-4">
        <Title type="secondary">{t("Register.Thanks.subTitle1")}</Title>
      </div>

      <div className="mt-4">
        <Title type="secondary">{t("Register.Thanks.subTitle2")}</Title>
      </div>

      <div className="mt-4">
        <Title type="secondary">{t("Register.Thanks.subTitle3")}</Title>
      </div>

      <form onSubmit={form.handleSubmit(handleSave)}>
        <div className="mt-4 mb-4">
          <Controller
            control={form.control}
            name="usage"
            rules={{
              required: {
                value: true,
                message: t("Register.Thanks.UsageError"),
              },
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <div className="flex-1">
                  <InputSelect
                    label={t("Register.Thanks.usageTitle")}
                    disabled={loading}
                    items={[
                      {
                        value: "",
                        label: t("Register.Thanks.choose"),
                      },
                      {
                        value: WelcomeStepEnum.BY_SEARCH,
                        label: t("Register.Thanks.bySearch"),
                      },
                      {
                        value: WelcomeStepEnum.BY_REFERENCE,
                        label: t("Register.Thanks.byReference"),
                      },
                      {
                        value: WelcomeStepEnum.BY_ADVERTISING,
                        label: t("Register.Thanks.byAdvertising"),
                      },
                      {
                        value: WelcomeStepEnum.BY_SOCIAL_NETWORK,
                        label: t("Register.Thanks.socialNetwork"),
                      },
                      {
                        value: WelcomeStepEnum.BY_EVENT,
                        label: t("Register.Thanks.byEvent"),
                      },
                      {
                        value: WelcomeStepEnum.BY_WORD_OF_MOUTH,
                        label: t("Register.Thanks.byWordOfMouth"),
                      },
                      {
                        value: WelcomeStepEnum.BY_EMAIL,
                        label: t("Register.Thanks.byEmail"),
                      },
                      {
                        value: WelcomeStepEnum.BY_OTHER,
                        label: t("Register.Thanks.byOther"),
                      },
                    ]}
                    selectedValue={value}
                    onSelect={onChange}
                    error={form.formState.errors.usage?.message}
                  />
                </div>
              );
            }}
          />
        </div>

        {/* USER OBJECTIVES */}
        <p className="text-sm font-bold text-high-contrast">
          {t("Register.Thanks.objectiveTitle")}
        </p>

        <div className="flex flex-col gap-y-2">
          <CheckBox
            label={
              <span className="text-sm font-semibold">
                {t("Register.Thanks.Objectives.moreReservations")}
              </span>
            }
            onChange={(value: boolean) => {
              form.setValue("objectives.moreReservations", value);
              checkAllValues({
                usage: form.getValues("usage"),
                objectives: {
                  ...form.getValues("objectives"),
                  moreReservations: value,
                },
              });
            }}
            value={form.getValues("objectives.moreReservations")}
            disabled={loading}
            withBorder={true}
            error={form.formState.errors.objectives?.moreReservations?.message}
          />

          <CheckBox
            label={
              <span className="text-sm font-semibold">
                {t("Register.Thanks.Objectives.saveTime")}
              </span>
            }
            onChange={(value: boolean) => {
              form.setValue("objectives.saveTime", value);
              checkAllValues({
                usage: form.getValues("usage"),
                objectives: {
                  ...form.getValues("objectives"),
                  saveTime: value,
                },
              });
            }}
            value={form.getValues("objectives.saveTime")}
            disabled={loading}
            withBorder={true}
            error={form.formState.errors.objectives?.saveTime?.message}
          />

          <CheckBox
            label={
              <span className="text-sm font-semibold">
                {t("Register.Thanks.Objectives.automateRentalBusiness")}
              </span>
            }
            onChange={(value: boolean) => {
              form.setValue("objectives.automateRentalBusiness", value);
              checkAllValues({
                usage: form.getValues("usage"),
                objectives: {
                  ...form.getValues("objectives"),
                  automateRentalBusiness: value,
                },
              });
            }}
            value={form.getValues("objectives.automateRentalBusiness")}
            disabled={loading}
            withBorder={true}
            error={
              form.formState.errors.objectives?.automateRentalBusiness?.message
            }
          />

          <CheckBox
            label={
              <span className="text-sm font-semibold">
                {t("Register.Thanks.Objectives.becomePlatformIndependent")}
              </span>
            }
            onChange={(value: boolean) => {
              form.setValue("objectives.becomePlatformIndependent", value);
              checkAllValues({
                usage: form.getValues("usage"),
                objectives: {
                  ...form.getValues("objectives"),
                  becomePlatformIndependent: value,
                },
              });
            }}
            value={form.getValues("objectives.becomePlatformIndependent")}
            disabled={loading}
            withBorder={true}
            error={
              form.formState.errors.objectives?.becomePlatformIndependent
                ?.message
            }
          />

          <CheckBox
            label={
              <span className="text-sm font-semibold">
                {t(
                  "Register.Thanks.Objectives.exchangeWithOtherRentalBusiness"
                )}
              </span>
            }
            onChange={(value: boolean) => {
              form.setValue(
                "objectives.exchangeWithOtherRentalBusiness",
                value
              );
              checkAllValues({
                usage: form.getValues("usage"),
                objectives: {
                  ...form.getValues("objectives"),
                  exchangeWithOtherRentalBusiness: value,
                },
              });
            }}
            value={form.getValues("objectives.exchangeWithOtherRentalBusiness")}
            disabled={loading}
            withBorder={true}
            error={
              form.formState.errors.objectives?.exchangeWithOtherRentalBusiness
                ?.message
            }
          />

          <div>
            <CheckBox
              label={
                <span className="text-sm font-semibold">
                  {t("Register.Thanks.Objectives.other")}
                </span>
              }
              onChange={(value: boolean) => {
                form.setValue("objectives.other", value);
                form.setValue("objectives.otherValue", undefined);
                checkAllValues({
                  usage: form.getValues("usage"),
                  objectives: {
                    ...form.getValues("objectives"),
                    other: value,
                  },
                });
              }}
              value={form.getValues("objectives.other")}
              disabled={loading}
              withBorder={true}
              error={form.formState.errors.objectives?.other?.message}
            />

            {form.getValues("objectives.other") ? (
              <Controller
                control={form.control}
                name="objectives.otherValue"
                rules={{
                  required: {
                    value: true,
                    message: t("Register.Thanks.Objectives.otherValueError"),
                  },
                }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <TextAreaInput
                      value={value}
                      placeholder={t(
                        "Register.Thanks.Objectives.otherValuePlaceholder"
                      )}
                      onTextChange={onChange}
                      error={
                        form.formState.errors.objectives?.otherValue?.message
                      }
                      disabled={loading || !form.getValues("objectives.other")}
                    />
                  );
                }}
              />
            ) : null}
          </div>
        </div>

        <ErrorMessage errorType="FORM">
          {form.formState.errors.objectives?.message}
        </ErrorMessage>

        <div className="flex mt-8">
          <Button
            RightIcon={ArrowRightIcon}
            onClick={form.handleSubmit(handleSave)}
            loading={loading}
            disabled={
              loading ||
              !form.formState.isValid ||
              Object.values(form.formState.errors).length > 0
            }
          >
            {t("Register.Thanks.submit")}
          </Button>
        </div>
      </form>
    </div>
  );
};
