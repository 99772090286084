import React, { useEffect, useState } from "react";
import { RegisterBillingProps } from "./RegisterBilling.type";
import { Title } from "../../../Common/Title/Title";
import { useTranslation } from "react-i18next";
// @ts-ignore
import ReactSlider from "react-slider";
import { CheckBox } from "../../../Common/CheckBox/CheckBox";
import { TextType } from "../../../../types/commonTypes";
import { Separator } from "../../../Common/Separator/Separator";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { Loader } from "../../../Common/Loader/Loader";
import { post } from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";

import CheckIcon from "../../../../assets/icons/check-circle-active.svg?react";
import CloseIcon from "../../../../assets/icons/close-circle.svg?react";
import { CheckoutForm } from "../../CheckoutForm/CheckoutForm";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

export const RegisterBilling: React.FC<RegisterBillingProps> = ({
  email = "bonjour2@gmail.com",
  onSubmit = () => {},
}) => {
  const { t } = useTranslation();

  const [stripeOptions, setStripeOptions] = useState<
    StripeElementsOptions | undefined
  >();
  const [error, setError] = useState<string | null>(null);

  const [nbRentals, setNbRentals] = useState<number>(3);
  const [selectedPlan, setSelectedPlan] = useState<
    "superhote_pro_annual" | "superhote_pro_monthly"
  >("superhote_pro_annual");

  const [yearlyPrice, setYearlyPrice] = useState(57);
  const [monthlyPrice, setMonthlyPrice] = useState(77);

  const basicYearlyPrice = 570;
  const rentalYearlyPrice = 70;

  const basicMonthlyPrice = 57;
  const rentalMonthlyPrice = 7;

  useEffect(() => {
    const fetchClientSecret = async () => {
      const response = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.INIT_SUBSCRIPTION}`,
        {
          email,
        }
      );

      console.log("RESPONSE", response);
      if (response.data?.success) {
        setStripeOptions({ clientSecret: response.data.result.client_secret });
      } else {
        setError(response.response.data.message);
      }
    };

    fetchClientSecret();
  }, []);

  useEffect(() => {
    if (nbRentals <= 3) {
      setYearlyPrice(basicYearlyPrice);
      setMonthlyPrice(basicMonthlyPrice);
    } else {
      setYearlyPrice(basicYearlyPrice + (nbRentals - 3) * rentalYearlyPrice);
      setMonthlyPrice(basicMonthlyPrice + (nbRentals - 3) * rentalMonthlyPrice);
    }
  }, [nbRentals]);

  return (
    <div>
      <Title>{t("Register.Billing.title")}</Title>

      <div className="pb-5 px-2">
        <ReactSlider
          step={1}
          min={0}
          max={500}
          className="w-full h-4 pr-2 my-4 bg-gray-200 rounded-lg cursor-grab"
          thumbClassName="absolute w-4 h-4 cursor-grab bg-icon-active rounded-full outline-none ring-2 ring-offset-2 ring-icon-active"
          value={nbRentals}
          renderThumb={(props: any, state: any) => (
            <div {...props} className={`${props.className} relative`}>
              <div className="absolute top-6 text-[11px] p-1 bg-element-background-selection-subtle/75 text-icon-active w-[32px] text-center left-[-8px] font-semibold rounded-2px">
                {state.value}
              </div>
            </div>
          )}
          onChange={(value: number) => {
            setNbRentals(value);
          }}
        />
      </div>

      <div className="flex flex-row gap-4 mt-4">
        <div className="flex-1 flex flex-col gap-2 border-r-1 border-element-border pr-6">
          <PlanItem
            name={t("Register.Billing.yearlyTitle")}
            price={
              t("Register.Billing.yearlyPrice", {
                monthlyPrice: (yearlyPrice / 12).toFixed(2),
              }) +
              " " +
              t("Register.Billing.yearlyBonusPrice", { yearlyPrice })
            }
            description={t("Register.Billing.yearlyDescription")}
            isActive={selectedPlan === "superhote_pro_annual"}
            onClick={() => setSelectedPlan("superhote_pro_annual")}
          />
          <PlanItem
            name={t("Register.Billing.monthlyTitle")}
            price={t("Register.Billing.monthlyPrice", { monthlyPrice })}
            description={t("Register.Billing.monthlyDescription")}
            isActive={selectedPlan === "superhote_pro_monthly"}
            onClick={() => setSelectedPlan("superhote_pro_monthly")}
          />

          <Separator accent="dark" />

          {/* Stripe Element */}
          {!error && (
            <div className="text-sm w-full self-center bg-element-background/70 px-8 py-4 rounded-6px">
              {!stripeOptions && (
                <div className="flex w-full justify-center">
                  <Loader />
                </div>
              )}

              {stripeOptions && (
                <Elements stripe={stripePromise} options={stripeOptions}>
                  <CheckoutForm
                    email={email}
                    plan={selectedPlan}
                    onSuccess={onSubmit}
                  />
                </Elements>
              )}
            </div>
          )}

          <ErrorMessage>{error}</ErrorMessage>
        </div>
        <div className="w-56 flex flex-col gap-3">
          {/* Monthly & Yearly Bonus Item */}
          <BonusItem
            label={t("Register.Billing.monthlyBonusItem1")}
            isActive={true}
          />
          <BonusItem
            label={t("Register.Billing.monthlyBonusItem2")}
            isActive={true}
          />
          <BonusItem
            label={t("Register.Billing.monthlyBonusItem3")}
            isActive={true}
          />
          <BonusItem
            label={t("Register.Billing.monthlyBonusItem4")}
            isActive={true}
          />
          <BonusItem
            label={t("Register.Billing.monthlyBonusItem5")}
            isActive={true}
          />
          <BonusItem
            label={t("Register.Billing.monthlyBonusItem6")}
            isActive={true}
          />
          <BonusItem
            label={t("Register.Billing.monthlyBonusItem7")}
            isActive={true}
          />
          <BonusItem
            label={t("Register.Billing.monthlyBonusItem8")}
            isActive={true}
          />
          <BonusItem
            label={t("Register.Billing.monthlyBonusItem9")}
            isActive={true}
          />
          <BonusItem
            label={t("Register.Billing.monthlyBonusItem10")}
            isActive={true}
          />

          {/* Only Yearly Bonus Item */}
          <BonusItem
            label={t("Register.Billing.yearlyBonusItem1")}
            isActive={selectedPlan === "superhote_pro_annual"}
          />
          <BonusItem
            label={t("Register.Billing.yearlyBonusItem2")}
            isActive={selectedPlan === "superhote_pro_annual"}
          />
          <BonusItem
            label={t("Register.Billing.yearlyBonusItem3")}
            isActive={selectedPlan === "superhote_pro_annual"}
          />
          <BonusItem
            label={t("Register.Billing.yearlyBonusItem4")}
            isActive={selectedPlan === "superhote_pro_annual"}
          />
        </div>
      </div>
    </div>
  );
};

type PlanItemProps = {
  name: string;
  price: string;
  description: string;
  isActive?: boolean;
  onClick?: () => void;
};

const PlanItem: React.FC<PlanItemProps> = ({
  name,
  price,
  description,
  isActive,
  onClick = () => {},
}) => {
  const border = isActive
    ? "border-2 border-icon-active"
    : "border-1 border-element-border hover:border-transparent hover:outline hover:outline-2 outline-element-background-selection-subtle";

  return (
    <div
      className={`flex flex-row p-4 rounded-6px cursor-pointer ${border} `}
      onClick={onClick}
    >
      <CheckBox isRadio={true} value={isActive} />
      <div>
        <p className="text-sm text-high-contrast font-semibold">
          <span className="text-icon-active">{name}</span> - {price}
        </p>
        <p className="text-sm font-light text-low-contrast">{description}</p>
      </div>
    </div>
  );
};

type BonusItemProps = { label: TextType; isActive?: boolean };

const BonusItem: React.FC<BonusItemProps> = ({ label, isActive }) => {
  const line = isActive ? "" : "line-through";
  const textColor = isActive
    ? "text-high-contrast font-semibold"
    : "text-low-contrast font-light";

  return (
    <div className="flex flex-row gap-1 items-center">
      {isActive ? (
        <CheckIcon className="min-w-max w-4 min-h-max h-4" />
      ) : (
        <CloseIcon className="min-w-max w-4 min-h-max h-4" />
      )}

      <p className={`text-sm ${line} ${textColor} leading-relaxed`}>{label}</p>
    </div>
  );
};
