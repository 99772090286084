import {ChildrenType, TextType} from "../../types/commonTypes";

export enum ProgressionStatusEnum {
  INCOMPLETE = "INCOMPLETE",
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
}

// Warning : the order is important
export enum ProgressionStepEnum {
  STEP_NONE = "NONE",
  STEP_CONNECT_AIRBNB = "STEP_CONNECT_AIRBNB",
  STEP_CREATE_RENTAL = "STEP_CREATE_RENTAL",
  STEP_CONNECT_BOOKING = "STEP_CONNECT_BOOKING",
  STEP_SYNCHRONIZE = "STEP_SYNCHRONIZE",
  STEP_CHECK_CALENDAR = "STEP_CHECK_CALENDAR",
  STEP_CREATE_MESSAGES = "STEP_CREATE_MESSAGES",
  STEP_CREATE_TEAM = "STEP_CREATE_TEAM",
  STEP_CONNECT_STRIPE = "STEP_CONNECT_STRIPE",
  STEP_GIFT = "STEP_GIFT",
}

export enum ProgressionTextEnum {
  STEP_SYNCHRONIZE_PHASE_AIBNB_IMPORT_RESERVATIONS = "STEP_SYNCHRONIZE_PHASE_AIBNB_IMPORT_RESERVATIONS",
  STEP_SYNCHRONIZE_PHASE_BOOKING_IMPORT_RESERVATIONS = "STEP_SYNCHRONIZE_PHASE_BOOKING_IMPORT_RESERVATIONS",
  STEP_SYNCHRONIZE_PHASE_SEND_RESTRICTIONS = "STEP_SYNCHRONIZE_PHASE_SEND_RESTRICTIONS",
}

export type Progression = {
  step: ProgressionStepEnum | null;
  status: ProgressionStatusEnum | null;
  processedJobs: number;
  totalJobs: number;
  progress: number | null;
  progressText: string;
  bookingAlreadyAssociated: boolean;
};

export type ProgressionBooking = {
  status: ProgressionStatusEnum | null;
  progress: number | null;
  progressText: string;
};

export type ProgressionBookingResponse = {
  synchronization_step_status: null;
  synchronization_step_pending_progress_percents: number | null;
  synchronization_step_pending_progress_text: string;
};

export type RulesSyncFinishModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

export type RulesSyncWaitingModalProps = {
  isVisible: boolean;
  progression: number | null;
  progressText: string;
};

export type  BookingRulesSyncWaitingModalProps = {
  isVisible: boolean;
  progression: number | null;
  progressText: string;
  headerStatus: string;
  close: () => void;
};

export type ListingImportFinishModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

export type ListingImportWaitingModalProps = {
  isVisible: boolean;
  progression: number | null;
  processedJobs?: number;
  totalJobs?: number;
};

export type typeStep = "previous" | "next" | null | "";

export type ProgressionStepProps = {
  number: TextType;
  title: TextType;
  children?: ChildrenType;
  status?: "active" | "inactive" | "success" | "error" | "pending";
  error?: string | null;
  loading?: boolean;
};

export type ProgressionStepAirbnbProps = {
  currentProgression: Progression;
  status: "active" | "inactive" | "success" | "error" | "pending";
  visible: boolean;
  number: number;
  loading: boolean;
  error: string | null;
  onAirbnbClick: () => void;
};

export type ProgressionStepRentalsProps = {
  currentProgression: Progression;
  status: "active" | "inactive" | "success" | "error" | "pending";
  visible: boolean;
  number: number;
  loading: boolean;
  error: string | null;
  onCompleteStep: () => void;
};

export type ProgressionStepPlatformsProps = {
  status: "active" | "inactive" | "success" | "error" | "pending";
  visible: boolean;
  number: number;
  error: string | null;
  isBookingConnected: boolean;
  loading: boolean;
  currentProgression: Progression;
  onBookingClick: () => void;
  onCompleteStep: () => void;
  step: ProgressionStepEnum;
};

export type ProgressionStepRulesProps = {
  status: "active" | "inactive" | "success" | "error" | "pending";
  visible: boolean;
  number: number;
  loading: boolean;
  error: string | null;
  currentProgression: Progression;
  onRulesClick: () => void;
  step: ProgressionStepEnum;
};

export type ProgressionStepStripeConnectProps = {
  status: "active" | "inactive" | "success" | "error" | "pending";
  visible: boolean;
  number: number;
  loading: boolean;
  error: string | null;
  currentProgression: Progression;
  onStripeClick: () => void;
  onNextClick: () => void;
  step: ProgressionStepEnum;
};

export type ProgressionStepCalendarProps = {
  status: "active" | "inactive" | "success" | "error" | "pending";
  visible: boolean;
  number: number;
  loading: boolean;
  error: string | null;
  onCalendarClick: () => void;
  step: ProgressionStepEnum;
};

export type ProgressionStepMessagesProps = {
  status: "active" | "inactive" | "success" | "error" | "pending";
  visible: boolean;
  number: number;
  loading: boolean;
  error: string | null;
  step: ProgressionStepEnum;
};

export type ProgressionStepTeamProps = {
  status: "active" | "inactive" | "success" | "error" | "pending";
  visible: boolean;
  number: number;
  loading: boolean;
  error: string | null;
  step: ProgressionStepEnum;
};

export type ProgressionStepEliteProps = {
  status: "active" | "inactive" | "success" | "error" | "pending";
  visible: boolean;
  number: number;
  loading: boolean;
  error: string | null;
  step: ProgressionStepEnum;
};

export type ProgressionStepNoneProps = {
  status: "active" | "inactive" | "success" | "error" | "pending";
  visible: boolean;
  number: number;
  loading: boolean;
  error: string | null;
  step: ProgressionStepEnum;
};
