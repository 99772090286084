import React from "react";
import { InfoModalProps } from "./InfoModal.type";
import { useTranslation } from "react-i18next";
import { CenterModal } from "../../Common/CenterModal/CenterModal";
import { Button } from "../../Common/Button/Button";

export const InfoModal: React.FC<InfoModalProps> = ({
  isVisible,
  onClose = () => {},
  title,
  content,
}) => {
  const { t } = useTranslation();

  return (
    <CenterModal isVisible={isVisible} onClose={onClose} title={title}>
      <p className="text-sm text-low-contrast font-light">{content}</p>

      <div className="flex w-full mt-4">
        <Button onClick={onClose} type="secondary">
          {t("Analytics.infoOk")}
        </Button>
      </div>
    </CenterModal>
  );
};
