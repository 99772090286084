import axios, { AxiosRequestConfig } from "axios";
import paths from "../constants/paths";

const getClient = () => {
  return axios.create({
    withCredentials: true,
    withXSRFToken: true,
  });
};

export const get = async (
  url: string,
  config?: AxiosRequestConfig<any> | undefined
) => {
  const client = getClient();
  const defaultConfig: AxiosRequestConfig<any> = {
    headers: { Accept: "application/json" },
  };

  try {
    return await client.get(url, config ?? defaultConfig);
  } catch (e: any) {
    const status = e.response?.status;
    const message = e.response?.data?.message;

    if (status === 419 && message === "CSRF token mismatch.") {
      await client.get(
        `${import.meta.env.VITE_API_URL}${paths.API.CSRF_COOKIE}`
      );

      try {
        return await client.get(url, config ?? defaultConfig);
      } catch (e) {
        return e;
      }
    } else {
      return e;
    }
  }
};

export const post = async (
  url: string,
  data?: any,
  config?: AxiosRequestConfig<any> | undefined
) => {
  const client = getClient();
  const defaultConfig: AxiosRequestConfig<any> = {
    headers: { Accept: "application/json" },
  };

  try {
    return await client.post(url, data, config ?? defaultConfig);
  } catch (e: any) {
    const status = e.response.status;
    const message = e.response.data.message;

    if (status === 419 && message === "CSRF token mismatch.") {
      await client.get(
        `${import.meta.env.VITE_API_URL}${paths.API.CSRF_COOKIE}`
      );

      try {
        return await client.post(url, data, config ?? defaultConfig);
      } catch (e) {
        return e;
      }
    } else {
      return e;
    }
  }
};

export const patch = async (
  url: string,
  data?: any,
  config?: AxiosRequestConfig<any> | undefined
) => {
  const client = getClient();
  const defaultConfig: AxiosRequestConfig<any> = {
    headers: { Accept: "application/json" },
  };

  try {
    return await client.patch(url, data, config ?? defaultConfig);
  } catch (e: any) {
    const status = e.response.status;
    const message = e.response.data.message;

    if (status === 419 && message === "CSRF token mismatch.") {
      await client.get(
        `${import.meta.env.VITE_API_URL}${paths.API.CSRF_COOKIE}`
      );

      try {
        return await client.patch(url, data, config ?? defaultConfig);
      } catch (e) {
        return e;
      }
    } else {
      return e;
    }
  }
};

export const update = async (
  url: string,
  data: any,
  config?: AxiosRequestConfig<any> | undefined
) => {
  const client = getClient();
  const defaultConfig: AxiosRequestConfig<any> = {
    headers: { Accept: "application/json" },
  };

  try {
    return await client.put(url, data, config ?? defaultConfig);
  } catch (e: any) {
    const status = e.response?.status;
    const message = e.response?.data?.message;

    if (status === 419 && message === "CSRF token mismatch.") {
      await client.get(
        `${import.meta.env.VITE_API_URL}${paths.API.CSRF_COOKIE}`
      );

      try {
        return await client.put(url, data, config ?? defaultConfig);
      } catch (e) {
        return e;
      }
    } else {
      return e;
    }
  }
};

export const del = async (
  url: string,
  config?: AxiosRequestConfig<any> | undefined
) => {
  const client = getClient();
  const defaultConfig: AxiosRequestConfig<any> = {
    headers: { Accept: "application/json" },
  };

  try {
    return await client.delete(url, config ?? defaultConfig);
  } catch (e: any) {
    const status = e.response?.status;
    const message = e.response?.data?.message;

    if (status === 419 && message === "CSRF token mismatch.") {
      await client.get(
        `${import.meta.env.VITE_API_URL}${paths.API.CSRF_COOKIE}`
      );

      try {
        return await client.delete(url, config ?? defaultConfig);
      } catch (e) {
        return e;
      }
    } else {
      return e;
    }
  }
};
