import React from "react";

export const ManagementOwnerTableSkeleton: React.FC<{}> = () => {
  return (
    <tr className="cursor-pointer hover:bg-element-background/40">
      <td className="py-3 px-2">
        <div className="flex items-center gap-2">
          <p className="font-semibold text-high-contrast h-2 rounded w-56 bg-slate-300"></p>
        </div>
      </td>

      <td className="py-3 px-2">
        <div className="flex items-center gap-2">
          <p className="font-semibold text-high-contrast h-2 rounded w-40 bg-slate-200"></p>
        </div>
      </td>

      <td className="py-3 px-2">
        <div className="flex items-center gap-2">
          <p className="font-semibold text-high-contrast h-2 rounded w-40 bg-slate-200"></p>
        </div>
      </td>

      <td className="py-3 px-2">
        <div className="flex items-center gap-2">
          <div className="h-8 rounded-6px bg-slate-200 w-16" />
        </div>
      </td>
    </tr>
  );
};
