import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../Common/Button/Button";
import {CalendarListItemProps} from "./Calendar.type";

import moment from "moment";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import ChevronDownIcon from "../../assets/icons/chevron-down.svg?react";
import ChevronUpIcon from "../../assets/icons/chevron-up.svg?react";
import EmojiHappyIcon from "../../assets/icons/emoji-happy.svg?react";
import LogInIcon from "../../assets/icons/log-in.svg?react";
import LogOutIcon from "../../assets/icons/log-out.svg?react";
import MailIcon from "../../assets/icons/mail.svg?react";
import PhoneIcon from "../../assets/icons/phone.svg?react";
import ShowIcon from "../../assets/icons/show.svg?react";
import UsersIcon from "../../assets/icons/users.svg?react";
import MoonIcon from "../../assets/icons/moon.svg?react";
import {cn} from "../../helpers/classHelper";
import {getPlatformIcon} from "../../helpers/platformHelper";
import {capitalizeFirstLetter} from "../../helpers/stringHelper";
import {ReservationForCalendarList} from "../../pages/Booking/BookingPage.type";

export const CalendarListItem: React.FC<CalendarListItemProps> = ({
  date,
  checkins,
  checkouts,
  onClick = () => {},
  onClickGroup,
}) => {
  const {t} = useTranslation();

  const [isGroupOpened, setIsGroupOpened] = useState<boolean>(
    checkins.length === 0 && checkouts.length === 0 ? false : true
  );

  const handleClickGroup = () => {
    setIsGroupOpened(!isGroupOpened);
  };

  useEffect(() => {
    if (typeof onClickGroup === "function") {
      onClickGroup(moment(date).format("YYYY_MM_DD"), isGroupOpened);
    }
  }, [isGroupOpened]);

  return (
    <div className="flex flex-col mt-4">
      <div
        className={cn(
          `select-none flex items-center justify-between p-4 cursor-pointer bg-element-background-light border-1 border-element-light`,
          {
            "rounded-t-lg": isGroupOpened,
            "rounded-lg": !isGroupOpened,
          }
        )}
        onClick={handleClickGroup}
      >
        <div className="flex flex-col gap-y-1">
          <p className="font-bold text-md text-high-contrast">
            {capitalizeFirstLetter(moment(date).format("dddd DD MMMM YYYY "))}
          </p>
          <p className=" text-low-contrast">
            (
            {`${checkins.length} ${t("Global.checkin", {
              count: checkins.length,
            }).toLowerCase()} - ${checkouts.length} ${t("Global.checkout", {
              count: checkouts.length,
            }).toLowerCase()}`}
            )
          </p>
        </div>
        {isGroupOpened ? (
          <ChevronUpIcon width={27} height={27} />
        ) : (
          <ChevronDownIcon width={27} height={27} />
        )}
      </div>

      {isGroupOpened ? (
        <>
          <div className="p-3 font-semibold tracking-wider border-t-0 selecte-none bg-element-background text-low-contrast border-1 border-element-border last:rounded-b-lg">
            {t("Global.checkout", {count: checkouts.length}).toUpperCase()}
          </div>

          {/* Checkouts */}
          {checkouts.map((checkout, index) => (
            <div key={index}>
              <CalendarListItemReservation
                index={index}
                isCheckin={false}
                reservation={checkout}
                onClick={onClick}
              />
            </div>
          ))}

          <div className="p-3 font-semibold tracking-wider border-t-0 selecte-none bg-element-background text-low-contrast border-1 border-element-border last:rounded-b-lg">
            {t("Global.checkin", {count: checkins.length}).toUpperCase()}
          </div>

          {/* Checkin */}
          {checkins.map((checkin, index) => (
            <div key={index}>
              <CalendarListItemReservation
                index={index}
                isCheckin={true}
                reservation={checkin}
                onClick={onClick}
              />
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
};

export const CalendarListItemReservation: React.FC<{
  isCheckin: boolean;
  index: number;
  reservation: ReservationForCalendarList;
  onClick: (reservation: ReservationForCalendarList) => void;
}> = ({isCheckin, index, reservation, onClick = () => {}}) => {
  const {t} = useTranslation();

  return (
    <div
      className={cn(
        `flex items-center gap-x-24 p-4 border-t-0 border-b-1 border-x-1 border-element-border hover:cursor-pointer hover:bg-element-background/30`,
        {
          "last:rounded-b-lg": !isCheckin,
        }
      )}
      onClick={() => onClick(reservation)}
    >
      {/* Label */}
      {/* <div className="flex items-center w-32 gap-1">
        <ArrowRightIcon className="w-6 h-6" />
        <p className="font-semibold text-high-contrast">
          {isCheckin
            ? t("Global.checkin", { count: 1 })
            : t("Global.checkout", { count: 1 })}{" "}
          {index + 1}
        </p>
      </div> */}

      <div className="flex items-center space-x-3">
        {isCheckin ? (
          <LogOutIcon width={22} height={22} />
        ) : (
          <LogInIcon width={23} height={23} />
        )}

        {/* Guest */}
        <div className="flex items-center flex-1 space-x-2 w-52">
          <img
            src={reservation.guest?.photo!}
            className="rounded-full w-14 h-14 bg-slate-200 border-1 border-element-border-light"
          />

          <div className="flex flex-col space-y-1">
            <div className="flex items-center gap-2">
              <p className="text-base font-semibold text-high-contrast">
                {reservation.guest?.first_name} {reservation.guest?.last_name}
              </p>
            </div>

            <div className="flex flex-col space-y-1">
              <div className="flex items-center space-x-2">
                <MailIcon />
                <p className="text-low-contrast">
                  {reservation.guest?.email ?? "-"}
                </p>
              </div>
              <div className="flex items-center space-x-2">
                <PhoneIcon />
                <p className="text-low-contrast">{reservation.guest?.phone}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col space-y-1">
        <div className="flex items-center space-x-1">
          <MoonIcon className="w-5 h-5" />
          <p className="text-base font-bold text-high-contrast">
            {t("Global.night", {count: reservation.nights_count ?? 0})}
          </p>
        </div>

        {reservation.adults_count > 0 && (
          <div className="flex items-center space-x-1">
            <UsersIcon className="w-5 h-5" />
            <p className="text-xs text-low-contrast">
              {t("Global.adults", {count: reservation.adults_count})}
            </p>
          </div>
        )}

        {reservation.children_count > 0 && (
          <div className="flex items-center space-x-1">
            <EmojiHappyIcon className="w-5 h-5" />
            <p className="text-xs text-low-contrast">
              {t("Global.children", {count: reservation.children_count})}
            </p>
          </div>
        )}
      </div>

      {/* Rental */}
      <div className="flex items-center flex-1 p-2 gap-x-6">
        <div className="relative ">
          <img
            src={reservation?.rental?.photo!}
            className="w-12 h-12 rounded-4px border-1 border-element-light bg-slate-200 "
          />

          <div className="absolute -translate-y-2/4 top-2/4 -right-3">
            {getPlatformIcon(reservation.platform)}
          </div>
        </div>

        <div className="flex flex-col space-y-1">
          <div className="flex items-center gap-1">
            <p className="text-base font-bold text-high-contrast">
              {reservation.rental?.name}
            </p>
          </div>

          <div className="flex items-center gap-1">
            <ArrowRightIcon className="w-4 h-4 font-light ms-1 text-low-contrast" />
            {isCheckin ? (
              <p className="font-light text-md text-low-contrast">
                {reservation.time_in
                  ? `${t("Calendar.checkin_time")} : ${reservation.time_in}`
                  : t("Global.NA", {context: "female"})}
              </p>
            ) : (
              <p className="font-light text-md text-low-contrast">
                {reservation.time_out
                  ? `${t("Calendar.checkout_time")} : ${reservation.time_out}`
                  : t("Global.NA", {context: "female"})}
              </p>
            )}
          </div>
        </div>
      </div>

      {/* Button */}
      <div className="">
        <Button type="secondary" RightIcon={ShowIcon} size="normal">
          {t("Calendar.bookingDetails")}
        </Button>
      </div>
    </div>
  );
};
