import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Title } from "../../components/Common/Title/Title";
import { IconType, TextType, ValueType } from "../../types/commonTypes";
import { GroupButton } from "../../components/Common/GroupButton/GroupButton";
import { Button } from "../../components/Common/Button/Button";
import { InputSelect } from "../../components/Common/InputSelect/InputSelect";
import { RightModal } from "../../components/Common/RightModal/RightModal";
import { CalendarInput } from "../../components/Common/CalendarInput/CalendarInput";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { InfoModal } from "../../components/Modal/InfoModal/InfoModal";
import { CheckBox } from "../../components/Common/CheckBox/CheckBox";
import { TextInput } from "../../components/Common/TextInput/TextInput";
import moment from "moment";
import { MultiSelectRentals } from "../../components/Common/MultiSelectRentals/MultiSelectRentals";

import AirBnBIcon from "../../assets/icons/airbnb.svg?react";
import BookingIcon from "../../assets/icons/booking.svg?react";
import VRBOIcon from "../../assets/icons/vrbo.svg?react";
import DirectIcon from "../../assets/icons/tv.svg?react";
import CalendarIcon from "../../assets/icons/calendar.svg?react";
import UploadIcon from "../../assets/icons/upload-white.svg?react";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg?react";
import InfoIcon from "../../assets/icons/information.svg?react";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import ChevronDownIcon from "../../assets/icons/chevron-down.svg?react";
import MinusErrorIcon from "../../assets/icons/minus-circle-error.svg?react";
import SearchIcon from "../../assets/icons/search.svg?react";
import MinusIcon from "../../assets/icons/minus.svg?react";
import Rental1Thumbnail from "../../assets/images/rental1.webp";
import Rental2Thumbnail from "../../assets/images/rental2.webp";
import Rental3Thumbnail from "../../assets/images/rental3.webp";
import Rental4Thumbnail from "../../assets/images/rental4.webp";
import Rental5Thumbnail from "../../assets/images/rental5.webp";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { useGetRentals } from "../../hooks/useGetRentals";
import {InProgressPlaceholder} from "../../components/Common/Dashboard/InProgressPlaceholder";

export const AnalyticsPage = () => {
  const { t } = useTranslation();

  const revenueByChannel = [
    {
      description: "AirBnB",
      Icon: AirBnBIcon,
      color: "bg-horizontal-airbnb",
      percent: 72,
    },
    {
      description: "Booking.com",
      Icon: BookingIcon,
      color: "bg-horizontal-booking",
      percent: 18,
    },
    {
      description: "VRBO / Abritel",
      Icon: VRBOIcon,
      color: "bg-horizontal-vrbo",
      percent: 6,
    },
    {
      description: "Réservations en direct",
      Icon: DirectIcon,
      color: "bg-horizontal-other",
      percent: 4,
    },
  ];

  const rentals = [
    {
      title: "Appartement Le Marais",
      description: "16 Rue Charlemagne 75004 Paris",
      img: Rental1Thumbnail,
      color: "bg-slate-900",
      percent: 72,
    },
    {
      title: "Appartement Lafayette",
      description: "Place du Champs de Mars 75007 Paris",
      img: Rental2Thumbnail,
      color: "bg-slate-700",
      percent: 18,
    },
    {
      title: "Appartement Saint Denis",
      description: "15 Rue Alfred Dehodencq 75004 Paris",
      img: Rental3Thumbnail,
      color: "bg-slate-600",
      percent: 5,
    },
    {
      title: "Appartement Tour Eiffel",
      description: "51 Boulevard Garibaldi 78000 Versailles",
      img: Rental4Thumbnail,
      color: "bg-slate-500",
      percent: 3,
    },
    {
      title: "Appartement Moulin Rouge",
      description: "16 Boulevard Saint-Denis 78000 Versailles",
      img: Rental5Thumbnail,
      color: "bg-slate-400",
      percent: 2,
    },
  ];

  const [isExportVisible, setExportVisible] = useState<boolean>(false);
  const [isHistoryModal, setHistoryModal] = useState<boolean>(false);
  const [isInfoVisible, setInfoVisible] = useState<boolean>(false);
  const [info, setInfo] = useState<{ title: TextType; content: TextType }>({
    title: "",
    content: "",
  });
  const [currentPeriod, setCurrentPeriod] = useState<
    "period" | "daily" | "weekly" | "monthly" | "yearly"
  >("yearly");

  const [customFrom, setCustomFrom] = useState<Date>(new Date());
  const [customTo, setCustomTo] = useState<Date>(new Date());

  const { currentRentals, rentalGroups, setCurrentRentals } = useGetRentals();

  const handleChangePeriod = ({
    type,
    value,
  }: {
    type: "from" | "to";
    value: any;
  }) => {
    if (type === "from") setCustomFrom(value);
    else if (type === "to") setCustomTo(value);
  };

  return (
    <>
      <ExportModal
        isVisible={isExportVisible}
        onClose={() => setExportVisible(false)}
      />

      <HistoryModal
        isVisible={isHistoryModal}
        onClose={() => setHistoryModal(false)}
      />

      <InfoModal
        isVisible={isInfoVisible}
        onClose={() => setInfoVisible(false)}
        title={info.title}
        content={info.content}
      />

      <MainLayout
        title={t("Analytics.title")}
        sidebarActiveItem="analytics"
        sidebarActiveSubItem="general"
      >

        {/* Top
         <div className="flex flex-row justify-between items-center">
          <div className="flex flex-row gap-3">
            <GroupButton
                items={[
                  // {
                  //   label: `${t("Dashboard.from")} ${moment(customFrom)
                  //     .format("D MMM")
                  //     .toLowerCase()}. ${t("Dashboard.to")}  ${moment(customTo)
                  //     .format("D MMM")
                  //     .toLowerCase()}.`,
                  //   value: "period",
                  //   Icon: CalendarIcon,
                  //   isPeriod: true,
                  //   isActive: currentPeriod === "period",
                  //   from: customFrom,
                  //   to: customTo,
                  // },
                  {
                    label: `${t("Dashboard.today")}`,
                    value: "daily",
                    isActive: currentPeriod === "daily",
                  },
                  {
                    label: `${t("Dashboard.thisWeek")}`,
                    value: "weekly",
                    isActive: currentPeriod === "weekly",
                  },
                  {
                    label: `${t("Dashboard.thisMonth")}`,
                    value: "monthly",
                    isActive: currentPeriod === "monthly",
                  },
                  {
                    label: `${t("Dashboard.thisYear")}`,
                    value: "yearly",
                    isActive: currentPeriod === "yearly",
                  },
                ]}
                // @ts-ignore
                onClick={(value) => setCurrentPeriod(value)}
                onChangePeriod={handleChangePeriod}
            />

            <MultiSelectRentals
                selectedRentals={currentRentals}
                onSelect={(values) => setCurrentRentals(values)}
            />
          </div>

          <div>
            <Button
                onClick={() => setExportVisible(true)}
                RightIcon={UploadIcon}
            >
              {t("Analytics.export")}
            </Button>
          </div>
        </div>
        */}
        {/*
          <div className="p-4 bg-element-background mt-4 text-sm rounded-6px">
          <div className="flex flex-row items-center gap-1">
            <InfoIcon className="w-4 h-4" />
            <p className="font-semibold text-high-contrast">
              {t("Analytics.infosTitle")}
            </p>
          </div>

          <p className="font-light text-low-contrast mt-1">
            {t("Analytics.infosContent1")}
          </p>
          <p className="font-light text-low-contrast">
            {t("Analytics.infosContent2")}
          </p>
        </div>
        */}

        {/*
         <div className="grid grid-cols-2 gap-4 mt-4">
          <div>
            <div className="border-1 border-element-border rounded-6px text-sm overflow-hidden">
              <div className="p-4">
                <p className="text-[2.5rem] text-high-contrast font-bold mt-2">
                  {currentPeriod === "daily" && "750,00 €"}
                  {currentPeriod === "weekly" && "2 650,00 €"}
                  {currentPeriod === "monthly" && "43 425,00 €"}
                  {currentPeriod === "yearly" && "43 425,00 €"}
                </p>
                <p className="font-light text-low-contrast flex flex-row text-sm items-center mt-3">
                  {t("Analytics.brutRevenues")} jan 23{"  "}
                  <ArrowRightIcon width={12} className="mx-1" /> dec 23
                </p>

                <div className="my-2">
                  <StackedBarChart type={currentPeriod} />
                </div>

                <div className="flex flex-row gap-4">
                  <div className="flex flex-col">
                    <p className="text-high-contrast font-semibold">
                      11 940,00 €
                    </p>
                    <div className="flex flex-row items-center gap-2 mt-1">
                      <div className="bg-slate-900 w-4 h-4 rounded-2px" />
                      <p className="font-light text-low-contrast">Déjà versé</p>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <p className="text-high-contrast font-semibold">
                      31 454,00 €
                    </p>
                    <div className="flex flex-row items-center gap-2 mt-1">
                      <div className="bg-slate-200 w-4 h-4 rounded-2px" />
                      <p className="font-light text-low-contrast">À venir</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-subtle">
                <div className="flex flex-col px-4 py-2">
                  <CardElement
                      label={t("Analytics.revenue")}
                      onInfo={() => {
                        setInfoVisible(true);
                        setInfo({
                          title: t("Analytics.revenue"),
                          content: t("Analytics.revenueInfo"),
                        });
                      }}
                      value={"43 425,00 €"}
                      onHistory={() => setHistoryModal(true)}
                  />

                  <CardElement
                      label={t("Analytics.commissions")}
                      onInfo={() => {
                        setInfoVisible(true);
                        setInfo({
                          title: t("Analytics.revenue"),
                          content: t("Analytics.revenueInfo"),
                        });
                      }}
                      value={"1 548,00 €"}
                      isNegative={true}
                      onHistory={() => setHistoryModal(true)}
                  />

                  <CardElement
                      label={t("Analytics.tax")}
                      onInfo={() => {
                        setInfoVisible(true);
                        setInfo({
                          title: t("Analytics.revenue"),
                          content: t("Analytics.revenueInfo"),
                        });
                      }}
                      value={"2 332,68 €"}
                      isNegative={true}
                      onHistory={() => setHistoryModal(true)}
                  />

                  <CardElement
                      label={t("Analytics.cleaning")}
                      onInfo={() => {
                        setInfoVisible(true);
                        setInfo({
                          title: t("Analytics.revenue"),
                          content: t("Analytics.revenueInfo"),
                        });
                      }}
                      value={"4 500,00 €"}
                      isNegative={true}
                      onHistory={() => setHistoryModal(true)}
                  />
                </div>

                <div className="border-t-1 border-element-border bg-element-background p-4">
                  <div className="flex flex-row justify-between items-center text-high-contrast font-semibold">
                    <p className="flex flex-row gap-1 items-center">
                      {t("Analytics.net")}{" "}
                      <InfoIcon
                          className="w-4 h-4 cursor-pointer"
                          onClick={() => {
                            setInfoVisible(true);
                            setInfo({
                              title: t("Analytics.revenue"),
                              content: t("Analytics.revenueInfo"),
                            });
                          }}
                      />
                    </p>
                    <p>35 044,32 €</p>
                  </div>

                  <p className="font-light text-low-contrast">
                    {t("Analytics.amountReceived")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="border-1 border-element-border rounded-6px overflow-hidden">
              <div className="p-4 text-sm flex flex-row justify-between">
                <p className="text-high-contrast font-semibold">
                  {t("Analytics.occupancyRate")}
                </p>
                <p className="text-low-contrast font-light">
                  {t("Analytics.occupancy")} : 75,6 %
                </p>
              </div>

              <div className="p-4">
                <SimpleAreaChart type={currentPeriod} />
              </div>

              <div className="bg-subtle text-sm">
                <div className="flex flex-col px-4 py-2">


                  <CardElement
                      label={t("Analytics.rentedNights")}
                      onInfo={() => {
                        setInfoVisible(true);
                        setInfo({
                          title: t("Analytics.revenue"),
                          content: t("Analytics.revenueInfo"),
                        });
                      }}
                      value={46}
                      onHistory={() => setHistoryModal(true)}
                  />

                  <CardElement
                      label={t("Analytics.notRentedNights")}
                      onInfo={() => {
                        setInfoVisible(true);
                        setInfo({
                          title: t("Analytics.revenue"),
                          content: t("Analytics.revenueInfo"),
                        });
                      }}
                      value={15}
                      onHistory={() => setHistoryModal(true)}
                  />

                  <div className="py-2 border-b-1 border-element-border last:border-b-0 text-high-contrast ">
                    <div className="flex flex-row items-center justify-between cursor-pointer">
                      <div className="flex flex-row gap-1 items-center">
                        <MinusIcon className="w-4 h-4" />
                        {t("Analytics.notRentedNights")}
                        <InfoIcon className="w-4 h-4 cursor-pointer" />
                      </div>

                      <div className="font-semibold text-low-contrast">15</div>
                    </div>
                  </div>

                  <CardElement
                      label={t("Analytics.bookingLeadTime")}
                      onInfo={() => {
                        setInfoVisible(true);
                        setInfo({
                          title: t("Analytics.revenue"),
                          content: t("Analytics.revenueInfo"),
                        });
                      }}
                      value={"6 mois"}
                      onHistory={() => setHistoryModal(true)}
                  />

                  <CardElement
                      label={t("Analytics.nightsPerBooking")}
                      onInfo={() => {
                        setInfoVisible(true);
                        setInfo({
                          title: t("Analytics.revenue"),
                          content: t("Analytics.revenueInfo"),
                        });
                      }}
                      value={"2,14"}
                      onHistory={() => setHistoryModal(true)}
                  />

                  <CardElement
                      label={t("Analytics.averagePrice")}
                      onInfo={() => {
                        setInfoVisible(true);
                        setInfo({
                          title: t("Analytics.revenue"),
                          content: t("Analytics.revenueInfo"),
                        });
                      }}
                      value={"269,92 €"}
                      onHistory={() => setHistoryModal(true)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="border-1 border-element-border rounded-6px p-4">
              <HorizontalChart
                  title={t("Analytics.rentals")}
                  data={rentals}
                  withTable={true}
              />
            </div>
          </div>

          <div>
            <div className="border-1 border-element-border rounded-6px p-4 text-sm">
              <div className="flex flex-row justify-between">
                <p className="text-high-contrast font-semibold">
                  {t("Analytics.evalutations")}
                </p>
                <p className="text-low-contrast font-light">
                  {t("Analytics.average")} : 4,6/5
                </p>
              </div>

              <div className="flex flex-col mt-1">
                <div className="py-2 text-low-contrast flex flex-row items-center gap-2 border-b-1 border-element-border last:border-0">
                  <AirBnBIcon />
                  <p className="flex-1">AirBnB</p>
                  <p className="text-high-contrast font-semibold">4,9/5</p>
                </div>

                <div className="py-2 text-low-contrast flex flex-row items-center gap-2 border-b-1 border-element-border last:border-0">
                  <BookingIcon />
                  <p className="flex-1">Booking.com</p>
                  <p className="text-high-contrast font-semibold">4,7/5</p>
                </div>

                <div className="py-2 text-low-contrast flex flex-row items-center gap-2 border-b-1 border-element-border last:border-0">
                  <VRBOIcon />
                  <p className="flex-1">VRBO / Abritel</p>
                  <p className="text-high-contrast font-semibold">4,2/5</p>
                </div>
              </div>

              <div className="flex w-full mt-2">
                <Button type="secondary" onClick={() => {}}>
                  {t("Analytics.allBookings")}
                </Button>
              </div>
            </div>

            <div className="border-1 border-element-border rounded-6px p-4 mt-4">
              <HorizontalChart
                  title={t("Analytics.revenueByChannel")}
                  data={revenueByChannel}
                  withTable={true}
                  withColor={false}
              />
            </div>
          </div>
        </div>
        */}
        <InProgressPlaceholder/>
      </MainLayout>
    </>
  );
};

type CardElementProps = {
  label: TextType;
  value: ValueType;
  subValues?: {
    airbnb?: ValueType;
    booking?: ValueType;
    VRBO?: ValueType;
    direct?: ValueType;
  };
  isNegative?: boolean;
  onInfo: () => void;
  onHistory: () => void;
};

const CardElement: React.FC<CardElementProps> = ({
  label,
  value,
  subValues = { airbnb: 12, booking: 23, VRBO: 4, direct: 0 },
  isNegative = false,
  onInfo = () => {},
  onHistory = () => {},
}) => {
  const [toggle, setToggle] = useState<boolean>(false);
  return (
    <div className="py-2 border-b-1 border-element-border last:border-b-0 text-high-contrast ">
      <div
        className="flex flex-row items-center justify-between cursor-pointer"
        onClick={() => setToggle(!toggle)}
      >
        <div className="flex flex-row gap-1 items-center">
          {toggle && <ChevronDownIcon className="w-4 h-4" />}
          {!toggle && <ChevronRightIcon className="w-4 h-4" />}
          {label}{" "}
          <InfoIcon className="w-4 h-4 cursor-pointer" onClick={onInfo} />
        </div>

        {isNegative && (
          <div className="font-semibold text-error flex flex-row gap-1 items-center">
            <MinusErrorIcon className="w-4 h-4" />
            {value}
          </div>
        )}

        {!isNegative && (
          <div className="font-semibold text-low-contrast">{value}</div>
        )}
      </div>

      {toggle && (
        <div className="flex flex-col mt-1">
          <div
            className="py-2 text-low-contrast ml-8 flex flex-row items-center gap-2 border-b-1 border-element-border last:border-0 cursor-pointer"
            onClick={onHistory}
          >
            <AirBnBIcon className="w-5 h-5" />
            <p className="flex-1">Airbnb</p>
            <p>{subValues.airbnb}</p>
            <ArrowRightIcon />
          </div>

          <div
            className="py-2 text-low-contrast ml-8 flex flex-row items-center gap-2 border-b-1 border-element-border last:border-0 cursor-pointer"
            onClick={onHistory}
          >
            <BookingIcon className="w-5 h-5" />
            <p className="flex-1">Booking.com</p>
            <p>{subValues.booking}</p>
            <ArrowRightIcon />
          </div>

          <div
            className="py-2 text-low-contrast ml-8 flex flex-row items-center gap-2 border-b-1 border-element-border last:border-0 cursor-pointer"
            onClick={onHistory}
          >
            <VRBOIcon className="w-5 h-5" />
            <p className="flex-1">VRBO / Abritel</p>
            <p>{subValues.VRBO}</p>
            <ArrowRightIcon />
          </div>

          <div
            className="py-2 text-low-contrast ml-8 flex flex-row items-center gap-2 border-b-1 border-element-border last:border-0 cursor-pointer"
            onClick={onHistory}
          >
            <DirectIcon className="w-5 h-5" />
            <p className="flex-1">Réservations en direct</p>
            <p className="text-low-contrast/50">{subValues.direct}</p>
          </div>
        </div>
      )}
    </div>
  );
};

type HorizontalChartDataType = {
  title?: TextType;
  description?: TextType;
  Icon?: IconType;
  img?: string;
  color: string;
  percent: number;
}[];

type HorizontalChartProps = {
  title: TextType;
  data: HorizontalChartDataType;
  withTable?: boolean;
  withColor?: boolean;
};

const HorizontalChart: React.FC<HorizontalChartProps> = ({
  title,
  data,
  withTable = true,
  withColor = true,
}) => {
  // TODO, ne pas oublier de trier par value

  const getCumul = (lastIndex: number) => {
    let result = 0;

    for (let i = 0; i < lastIndex; i++) {
      if (data[i]) result += data[i].percent;
    }

    return result;
  };

  return (
    <div className="w-full">
      <Title>{title}</Title>

      <div className="w-full h-12 rounded-6px overflow-hidden relative mt-1">
        {data?.map((h, index) => (
          <div
            className={`absolute h-full ${h.color}`}
            style={{ left: `${getCumul(index)}%`, width: `${h.percent}%` }}
          />
        ))}
      </div>

      {withTable && (
        <div className="flex flex-col mt-2 text-sm ">
          {data?.map((e) => (
            <div className="border-b-1 border-element-border last:border-b-0 py-2 flex flex-row gap-3 items-center">
              {e.Icon && <e.Icon width={24} height={24} />}
              {withColor && (
                <div className={`h-4 w-4 ${e.color} rounded-2px`} />
              )}
              {e.img && (
                <img
                  src={e.img}
                  className="w-10 h-10 rounded-6px border-1 border-element-border"
                />
              )}
              <div className="flex flex-col flex-1">
                {e.title && (
                  <p className="text-high-contrast font-semibold">{e.title}</p>
                )}
                {e.description && (
                  <p className="text-low-contrast">{e.description}</p>
                )}
              </div>

              <p className="text-high-contrast font-semibold">{e.percent} %</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

type ExportModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

const ExportModal: React.FC<ExportModalProps> = ({
  isVisible,
  onClose = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <RightModal
      isVisible={isVisible}
      onClose={onClose}
      title={t("Analytics.export")}
    >
      <div className="flex flex-col w-full">
        <div>
          <p className="text-low-contrast font-light">
            {t("Analytics.exportSubTitle")}
          </p>
        </div>

        <div className="mt-6">
          <CalendarInput label={t("Analytics.exportStart")} />
        </div>

        <div className="mt-4">
          <CalendarInput label={t("Analytics.exportEnd")} />
        </div>

        <div className="mt-4">
          <InputSelect
            label={t("Analytics.rentals")}
            items={[{ value: 0, label: t("Analytics.allRentals") }]}
            selectedValue={0}
          />
        </div>

        <div className="flex-1" />

        <div className="flex">
          <Button onClick={() => onClose()} RightIcon={UploadIcon}>
            {t("Analytics.exportPerform")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};

type HistoryModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

const HistoryModal: React.FC<HistoryModalProps> = ({
  isVisible,
  onClose = () => {},
}) => {
  const { t } = useTranslation();

  const getTitle = () => {
    return (
      <div className="flex flex-row items-center gap-1">
        <p>{t("Analytics.history")}</p>
        <ChevronRightIcon />
        <AirBnBIcon className="w-5 h-5" />
        <p>Airbnb</p>
      </div>
    );
  };

  return (
    <RightModal isVisible={isVisible} onClose={onClose} title={getTitle()}>
      <div className="flex flex-col w-full">
        <div>
          <p className="text-low-contrast font-light">
            {t("Analytics.historySubTitle")}
          </p>
        </div>

        <div className="flex flex-row gap-2 mt-4 items-center">
          <TextInput
            Icon={SearchIcon}
            placeholder={t("Analytics.placeholderSearchBooking")}
          />

          <div>
            <Button onClick={() => {}} type="secondary" size="small">
              {t("Analytics.search")}
            </Button>
          </div>
        </div>

        <div className="mt-3">
          <table className="table-auto w-full rounded-4px border-1 border-element-border border-separate overflow-hidden border-spacing-0">
            {/* Table Header */}
            <tr className="bg-element-background border-b-1 border-element-border">
              <th className="p-2 pr-0 text-sm">
                <CheckBox />
              </th>
              <th className="px-2 py-2 text-sm">
                <div className="flex flex-row items-center cursor-pointer hover:underline">
                  <p>{t("Analytics.booking")}</p>
                </div>
              </th>

              <th className="px-2 py-2 text-sm">
                <div className="flex flex-row items-center cursor-pointer hover:underline">
                  <p>{t("Analytics.tax")}</p>
                </div>
              </th>

              <th className="px-2 py-2 text-sm">
                <div className="flex flex-row items-center cursor-pointer hover:underline">
                  <p>{t("Analytics.payout")}</p>
                </div>
              </th>
            </tr>

            <tr>
              <td className="p-2 pr-0 text-sm border-b-1 border-element-border">
                <CheckBox />
              </td>

              <td className="flex flex-col p-2 border-b-1 border-element-border">
                <p className="text-high-contrast font-semibold">
                  16/04/2023 · HMWBTEKK34 ↗︎
                </p>
                <p className="text-low-contrast font-light">
                  Séjour du 16/04/2023 au 18/04/2023 Appartment Lafayette
                </p>
              </td>

              <td className="p-2 border-b-1 border-element-border">
                <p className="text-high-contrast font-semibold text-right">
                  18,75 €
                </p>
              </td>

              <td className="p-2 border-b-1 border-element-border">
                <p className="text-high-contrast font-semibold text-right">
                  330,00 €
                </p>
              </td>
            </tr>

            <tr>
              <td className="p-2 pr-0 text-sm border-b-1 border-element-border">
                <CheckBox />
              </td>

              <td className="flex flex-col p-2 border-b-1 border-element-border">
                <p className="text-high-contrast font-semibold">
                  16/04/2023 · HMYJH8MRYN ↗︎
                </p>
                <p className="text-low-contrast font-light">
                  Séjour du 15/04/2023 au 17/04/2023 Appartment Lafayette
                </p>
              </td>

              <td className="p-2 border-b-1 border-element-border">
                <p className="text-high-contrast font-semibold text-right">
                  7,70 €
                </p>
              </td>

              <td className="p-2 border-b-1 border-element-border">
                <p className="text-high-contrast font-semibold text-right">
                  174,00 €
                </p>
              </td>
            </tr>

            <tr>
              <td className="p-2 pr-0 text-sm border-b-1 border-element-border">
                <CheckBox />
              </td>

              <td className="flex flex-col p-2 border-b-1 border-element-border">
                <p className="text-high-contrast font-semibold">
                  16/04/2023 · HMJ535RJ93 ↗︎
                </p>
                <p className="text-low-contrast font-light">
                  Séjour du 13/04/2023 au 15/04/2023 Appartment Lafayette
                </p>
              </td>

              <td className="p-2 border-b-1 border-element-border">
                <p className="text-high-contrast font-semibold text-right">
                  7,55 €
                </p>
              </td>

              <td className="p-2 border-b-1 border-element-border">
                <p className="text-high-contrast font-semibold text-right">
                  171,00 €
                </p>
              </td>
            </tr>

            <tr>
              <td className="p-2 pr-0 text-sm">
                <CheckBox />
              </td>

              <td className="flex flex-col p-2">
                <p className="text-high-contrast font-semibold">
                  16/04/2023 · HMHTXEDJHE ↗︎
                </p>
                <p className="text-low-contrast font-light">
                  Séjour du 12/04/2023 au 16/04/2023 Appartment Lafayette
                </p>
              </td>

              <td className="p-2">
                <p className="text-high-contrast font-semibold text-right">
                  27,50 €
                </p>
              </td>

              <td className="p-2">
                <p className="text-high-contrast font-semibold text-right">
                  470,00 €
                </p>
              </td>
            </tr>
          </table>
        </div>

        {/* Bottom with paging */}
        <div className="flex flex-row mt-4 items-center">
          <div className="flex flex-1 flex-row items-center space-x-2">
            <p className="text-high-contrast text-sm font-semibold">
              {t("Analytics.displayUpTo")}
            </p>

            <div>
              <InputSelect
                selectedValue={5}
                items={[
                  { value: 5, label: t("Analytics.bookings5") },
                  { value: 10, label: t("Analytics.booking10") },
                ]}
              />
            </div>
          </div>

          <div className="flex flex-row space-x-2">
            <Button
              type="secondary"
              LeftIcon={ArrowLeftIcon}
              disabled={true}
              onClick={() => {
                console.log("click back");
              }}
            ></Button>
            <Button
              type="secondary"
              onClick={() => {
                console.log("click page 1");
              }}
            >
              <span className="px-1">1</span>
            </Button>
            <Button
              type="secondary"
              LeftIcon={ArrowRightIcon}
              disabled={true}
              onClick={() => {
                console.log("click next");
              }}
            ></Button>
          </div>
        </div>
      </div>
    </RightModal>
  );
};

type StackedBarChartProps = {
  type: "period" | "daily" | "weekly" | "monthly" | "yearly";
};

const StackedBarChart: React.FC<StackedBarChartProps> = ({
  type = "yearly",
}) => {
  const dailyData = [
    {
      name: "Lun 4",
      attente: 4000,
      verse: 2400,
    },
    {
      name: "Mar 5",
      attente: 3000,
      verse: 1398,
    },
    {
      name: "Mer 6",
      attente: 2000,
      verse: 9800,
    },
    {
      name: "Jeu 7",
      attente: 2780,
      verse: 3908,
    },
    {
      name: "Ven 8",
      attente: 1890,
      verse: 4800,
    },
    {
      name: "Sam 9",
      attente: 2390,
      verse: 3800,
    },
    {
      name: "Dim 10",
      attente: 3490,
      verse: 4300,
    },
  ];

  const monthlyData = [
    {
      name: "1",
      attente: 4000,
      verse: 2400,
    },
    {
      name: "2",
      attente: 3000,
      verse: 1398,
    },
    {
      name: "3",
      attente: 2000,
      verse: 9800,
    },
    {
      name: "4",
      attente: 2780,
      verse: 3908,
    },
    {
      name: "5",
      attente: 1890,
      verse: 4800,
    },
    {
      name: "6",
      attente: 2390,
      verse: 3800,
    },
    {
      name: "7",
      attente: 3490,
      verse: 4300,
    },
    {
      name: "8",
      attente: 1490,
      verse: 3300,
    },
    {
      name: "9",
      attente: 3490,
      verse: 1200,
    },
    {
      name: "10",
      attente: 3490,
      verse: 2000,
    },
    {
      name: "11",
      attente: 3490,
      verse: 1860,
    },
    {
      name: "12",
      attente: 3490,
      verse: 1470,
    },
    {
      name: "13",
      attente: 4000,
      verse: 2400,
    },
    {
      name: "14",
      attente: 3000,
      verse: 1398,
    },
    {
      name: "15",
      attente: 2000,
      verse: 9800,
    },
    {
      name: "16",
      attente: 2780,
      verse: 3908,
    },
    {
      name: "17",
      attente: 1890,
      verse: 4800,
    },
    {
      name: "18",
      attente: 2390,
      verse: 3800,
    },
    {
      name: "19",
      attente: 3490,
      verse: 4300,
    },
    {
      name: "20",
      attente: 1490,
      verse: 3300,
    },
    {
      name: "21",
      attente: 3490,
      verse: 0,
    },
    {
      name: "22",
      attente: 3490,
      verse: 0,
    },
    {
      name: "23",
      attente: 3490,
      verse: 0,
    },
    {
      name: "24",
      attente: 3490,
      verse: 0,
    },
    {
      name: "25",
      attente: 3490,
      verse: 4300,
    },
    {
      name: "26",
      attente: 1490,
      verse: 3300,
    },
    {
      name: "27",
      attente: 3490,
      verse: 0,
    },
    {
      name: "28",
      attente: 3490,
      verse: 0,
    },
    {
      name: "29",
      attente: 3490,
      verse: 0,
    },
    {
      name: "30",
      attente: 3490,
      verse: 0,
    },
  ];

  const yearlyData = [
    {
      name: "Janv",
      attente: 4000,
      verse: 2400,
    },
    {
      name: "Fevr",
      attente: 3000,
      verse: 1398,
    },
    {
      name: "Mars",
      attente: 2000,
      verse: 9800,
    },
    {
      name: "Avril",
      attente: 2780,
      verse: 3908,
    },
    {
      name: "Mai",
      attente: 1890,
      verse: 4800,
    },
    {
      name: "Juin",
      attente: 2390,
      verse: 3800,
    },
    {
      name: "Jui",
      attente: 3490,
      verse: 4300,
    },
    {
      name: "Aout",
      attente: 1490,
      verse: 3300,
    },
    {
      name: "Sept",
      attente: 3490,
      verse: 0,
    },
    {
      name: "Oct",
      attente: 3490,
      verse: 0,
    },
    {
      name: "Nov",
      attente: 3490,
      verse: 0,
    },
    {
      name: "Dec",
      attente: 3490,
      verse: 0,
    },
  ];

  const getData = () => {
    if (type === "period") return dailyData;
    else if (type === "daily") return dailyData;
    if (type === "weekly") return dailyData;
    else if (type === "monthly") return monthlyData;
    else return yearlyData;
  };

  return (
    <>
      <div className="w-full h-72">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={getData()}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
            <Bar dataKey="verse" stackId="a" fill="#0F172A" />
            <Bar dataKey="attente" stackId="a" fill="#E2E8F0" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

type SimpleAreaChartProps = {
  type: "period" | "daily" | "weekly" | "monthly" | "yearly";
};

const SimpleAreaChart: React.FC<SimpleAreaChartProps> = ({
  type = "yearly",
}) => {
  const dailyData = [
    {
      name: "Lun 4",
      occ: 55,
    },
    {
      name: "Mar 5",
      occ: 46,
    },
    {
      name: "Mer 6",
      occ: 80,
    },
    {
      name: "Jeu 7",
      occ: 97,
    },
    {
      name: "Ven 8",
      occ: 98,
    },
    {
      name: "Sam 9",
      occ: 92,
    },
    {
      name: "Dim 10",
      occ: 93,
    },
  ];

  const monthlyData = [
    {
      name: "1",
      occ: 55,
    },
    {
      name: "2",
      occ: 46,
    },
    {
      name: "3",
      occ: 80,
    },
    {
      name: "4",
      occ: 97,
    },
    {
      name: "5",
      occ: 98,
    },
    {
      name: "6",
      occ: 92,
    },
    {
      name: "7",
      occ: 93,
    },
    {
      name: "8",
      occ: 100,
    },
    {
      name: "9",
      occ: 96,
    },
    {
      name: "10",
      occ: 82,
    },
    {
      name: "11",
      occ: 73,
    },
    {
      name: "12",
      occ: 98,
    },
    {
      name: "13",
      occ: 97,
    },
    {
      name: "14",
      occ: 97,
    },
    {
      name: "15",
      occ: 75,
    },
    {
      name: "16",
      occ: 86,
    },
    {
      name: "17",
      occ: 98,
    },
    {
      name: "18",
      occ: 92,
    },
    {
      name: "19",
      occ: 93,
    },
    {
      name: "20",
      occ: 100,
    },
    {
      name: "21",
      occ: 96,
    },
    {
      name: "22",
      occ: 82,
    },
    {
      name: "23",
      occ: 73,
    },
    {
      name: "24",
      occ: 64,
    },
    {
      name: "25",
      occ: 93,
    },
    {
      name: "26",
      occ: 100,
    },
    {
      name: "27",
      occ: 96,
    },
    {
      name: "28",
      occ: 82,
    },
    {
      name: "29",
      occ: 73,
    },
    {
      name: "30",
      occ: 64,
    },
  ];

  const yearlyData = [
    {
      name: "Janv",
      occ: 55,
    },
    {
      name: "Fevr",
      occ: 46,
    },
    {
      name: "Mars",
      occ: 80,
    },
    {
      name: "Avril",
      occ: 97,
    },
    {
      name: "Mai",
      occ: 98,
    },
    {
      name: "Juin",
      occ: 92,
    },
    {
      name: "Jui",
      occ: 93,
    },
    {
      name: "Aout",
      occ: 100,
    },
    {
      name: "Sept",
      occ: 96,
    },
    {
      name: "Oct",
      occ: 82,
    },
    {
      name: "Nov",
      occ: 73,
    },
    {
      name: "Dec",
      occ: 64,
    },
  ];

  const getData = () => {
    if (type === "period") return dailyData;
    else if (type === "daily") return dailyData;
    if (type === "weekly") return dailyData;
    else if (type === "monthly") return monthlyData;
    else return yearlyData;
  };

  return (
    <>
      <div className="w-full h-72">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={getData()}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="occ"
              stroke="#0F172A"
              fill="#0F172A"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
