import React, { useState } from "react";
import { RightModal } from "../../Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import StripeLogo from "../../../assets/icons/stripe.svg?react";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import CheckGreenIcon from "../../../assets/icons/check-circle-green.svg?react";
import { Button } from "../../Common/Button/Button";
import { Separator } from "../../Common/Separator/Separator";
import { post } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { TextInput } from "../../Common/TextInput/TextInput";

type AddStripeAccountModalProps = {
  isVisible: boolean;
  onClose: () => void;
  onFinish: () => void;
};

export const AddStripeAccountSuccessModal: React.FC<
  AddStripeAccountModalProps
> = ({ isVisible, onClose, onFinish }) => {
  const { t } = useTranslation();

  const queryParameters = new URLSearchParams(window.location.search);
  const queryCode = queryParameters.get("code");

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [name, setName] = useState<string>(t("Payments.Stripe.defaultName"));
  const [nameError, setNameError] = useState<string>("");

  const handleChangeName = (text: string) => {
    setName(text);

    if (text === "") setNameError(t("Payments.Stripe.nameError"));
    else setNameError("");
  };

  const handleAdd = async () => {
    if (name.trim() === "") {
      setNameError(t("Payments.Stripe.nameError"));

      return;
    }

    setError(null);
    setNameError("");
    setLoading(true);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.ACCOUNTS}`,
      {
        type: "stripe",
        name,
        stripe_authorization_code: queryCode,
      }
    );

    if (res?.data?.success) {
      onFinish();
    } else {
      setError(res.response.data.message);
    }

    setLoading(false);
  };

  return (
    <RightModal
      isVisible={isVisible}
      onClose={onClose}
      title={t("Payments.addStripe")}
    >
      <div className="flex flex-col w-full">
        <div className="flex flex-col flex-1">
          <StripeLogo />
          <p className="font-semibold text-high-contrast mt-2">
            {t("Payments.Stripe.addTitle")}
          </p>
          <p className="text-low-contrast font-light mt-3">
            {t("Payments.Stripe.addContent")}
          </p>

          {/* Success */}
          <div className="flex gap-2 w-full p-4 bg-element-background-success rounded-6px mt-6">
            <div>
              <CheckGreenIcon className="w-5 h-5" />
            </div>
            <div className="flex flex-col gap-1">
              <p className="font-semibold text-success">
                {t("Payments.Stripe.successTitle")}
              </p>
              <p className="font-light text-success">
                {t("Payments.Stripe.successContent")}
              </p>
            </div>
          </div>

          {/* Account name */}
          <div className="mt-4">
            <TextInput
              label={t("Payments.Stripe.name")}
              placeholder={t("Payments.Stripe.namePlaceholder")}
              disabled={loading}
              onChangeText={handleChangeName}
              value={name}
              error={nameError}
            />
            <p className="text-[10px] text-low-contrast font-light">
              {t("Payments.Stripe.nameHint")}
            </p>
          </div>

          <div className="mt-4">
            <ErrorMessage>{error}</ErrorMessage>
          </div>
        </div>

        <Separator />

        <div className="flex gap-3">
          <Button type="secondary" onClick={onClose} disabled={loading}>
            {t("Global.cancel")}
          </Button>
          <Button
            RightIcon={CheckWhiteIcon}
            onClick={handleAdd}
            loading={loading}
          >
            {t("Global.done")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
