import React, { useEffect, useRef, useState } from "react";
import "./Carousel.css";
import { CarouselProps } from "./Carousel.type";

export const Carousel: React.FC<CarouselProps> = ({
  items,
  slideDuration = 5000,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const itemsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let xScrollTo: number = 0;
    const interval = setInterval(() => {
      if (itemsRef.current !== null) {
        const itemsContainer = itemsRef.current;
        const currentScroll: number = itemsContainer.scrollLeft;
        const maxScroll: number =
          (items.length - 1) * itemsContainer.clientWidth;

        if (currentScroll !== maxScroll) {
          xScrollTo += itemsContainer.clientWidth;
        } else {
          xScrollTo = 0;
        }
        itemsContainer.scrollTo(xScrollTo, 0);
        setCurrentStep((prevStep) => (xScrollTo === 0 ? 0 : prevStep + 1));
      }
    }, slideDuration);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {/* ITEMS */}
      <div
        className="items relative flex overflow-hidden snap-mandatory snap-x scroll-smooth"
        ref={itemsRef}
      >
        {items?.map((item, i) => (
          <div
            className="item bg-element-background p-6 pt-0 rounded-16px w-full left-0 shrink-0 object-cover snap-start"
            key={i}
          >
            <item.Image className="w-full" />
            <p className="text-xl font-semibold">{item.title}</p>
            <p className="mt-2 font-light text-low-contrast">{item.subTitle}</p>
          </div>
        ))}
      </div>

      {/* DOTS */}
      <div className="flex flex-row justify-center mt-2 space-x-2">
        {items?.map((item, i) => (
          <div
            className={`w-2 h-2 rounded-full ${
              currentStep === i ? "bg-low-contrast" : "bg-element-background"
            }`}
            key={i}
          ></div>
        ))}
      </div>
    </>
  );
};
