import React from "react";
import { Switch } from "../Common/Switch/Switch";

export const AutoMessageLineSkeleton: React.FC<{}> = () => {
  return (
    <tr className="hover:bg-element-background/40">
      <td className="border-t-1 border-element-border text-sm px-2 py-2">
        <div className="flex flex-col gap-1">
          <Switch value={false} disabled={true} />
        </div>
      </td>

      <td className="border-t-1 border-element-border text-sm px-2 py-2">
        <div className="flex flex-col gap-1">
          <span
            className={`font-semibold text-high-contrast h-2 w-52 rounded bg-slate-300`}
          ></span>

          <div className="flex gap-1 h-4 w-4 bg-slate-200 rounded"></div>
        </div>
      </td>

      <td className="border-t-1 border-element-border text-sm px-2 py-2">
        <div className="flex flex-col gap-1">
          <span
            className={`font-light text-low-contrast bg-slate-200 h-2 w-32 rounded`}
          ></span>

          <span
            className={`font-light text-low-contrast bg-slate-200 h-2 w-32 rounded`}
          ></span>
        </div>
      </td>

      <td className="border-t-1 border-element-border text-sm px-2 py-2">
        <div
          className={`font-light text-low-contrast bg-slate-200 h-2 w-32 rounded`}
        ></div>
      </td>

      <td className="border-t-1 border-element-border">
        <div className="flex flex-col py-1">
          <div className="flex gap-1">
            <div className="w-6 h-6 bg-slate-200 rounded-full" />
            <span
              className={`font-light text-low-contrast bg-slate-200 h-2 w-16 rounded`}
            ></span>
          </div>

          <div className="flex gap-1">
            <div className="w-6 h-6 bg-slate-200 rounded-full" />
            <span
              className={`font-light text-low-contrast bg-slate-200 h-2 w-16 rounded`}
            ></span>
          </div>

          <div className="flex gap-1">
            <div className="w-6 h-6 bg-slate-200 rounded-full" />
            <span
              className={`font-light text-low-contrast bg-slate-200 h-2 w-16 rounded`}
            ></span>
          </div>
        </div>
      </td>

      <td className="border-t-1 border-element-border w-32">
        <div className="space-x-2 py-1 flex items-center">
          <div>
            <div className="bg-slate-200 h-8 w-20 rounded-6px" />
          </div>

          <div className="pr-2">
            <div className="bg-slate-200 h-8 w-12 rounded-6px" />
          </div>
        </div>
      </td>
    </tr>
  );
};
