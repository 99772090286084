import React, {useEffect} from "react";
import {GuestPageTab} from "../GuestPageHome/GuestPageHomeComponent";
import {useTranslation} from "react-i18next";
import ContactCardComponent from "./ContactCardComponent";
import LayoutBlockComponent from "../Common/LayoutBlockComponent";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import useGuestPageTextCoverStore from "../../../stores/guestPageStore/useGuestPageTextCoverStore";

const GuestPageContact = ({}: GuestPageTab) => {
  const {guestPageData, rentalPreview,isPreviewMode} = useGuestPageDataStore();
  const {contacts} = guestPageData || {};
  const {guest_page: {contacts: contactsPreview} = {}} = rentalPreview || {};
  const {t} = useTranslation();
  const {storeTitle, storeSubTitle} = useGuestPageTextCoverStore();
  useEffect(() => {
    storeTitle(t("GuestPage.Contact.title"));
    storeSubTitle && storeSubTitle(t("GuestPage.Contact.contactHost"));
  }, []);

  const datas = isPreviewMode ? contactsPreview : contacts
  return (
    <LayoutBlockComponent
      title={t("GuestPage.Contact.title")}
      subTitle={t("GuestPage.Contact.contactHost")}
      blockStyle={{
        titleStyle: "text-[32px] pl-1 md:pl-2 font-bold",
        childrenDivStyle: "pl-0 md:pl-2 md:w-full md:h-full border-b-0",
        subTitleStyle: "md:pl-2 md:w-full md:h-full",
        parentDivStyle: "md:w-full",
      }}
    >

      {datas?.map((contact, index) => (
        <ContactCardComponent contact={contact} digit={index + 1} />
      ))}
    </LayoutBlockComponent>
  );
};

export default GuestPageContact;
