/**
 * Component Skeleton Guests for TableList
 */

import React from "react";

import { cn } from "../../../helpers/classHelper";

import { ClassName } from "react-calendar/dist/cjs/shared/types";

export const GuestItemListSkeleton: React.FC<{}> = () => {
  const getBorder = (): ClassName =>
    "border-element-border border-t-2 border-gray-200";
  return (
    <>
      <tr className="cursor-pointer hover:bg-element-background/40 animate-pulse">
        <td
          className={cn(
            `flex flex-row items-center px-4 py-4 text-sm border-element-border`,
            `${getBorder()}`,
          )}
        >
          <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
        </td>
        <td className={cn(`px-2 py-4`, `${getBorder()}`)}>
          <div className="w-[235px] h-6 bg-gray-300 rounded"></div>
        </td>
        <td className={cn(`${getBorder()}`)}>
          <div className="w-20 h-6 bg-gray-300 rounded"></div>
        </td>
        <td className={cn(`py-4`, `${getBorder()}`)}>
          <div className="w-40 h-6 bg-gray-300 rounded"></div>
        </td>
        <td className={cn(`py-4`, `${getBorder()}`)}>
          <div className="w-20 h-6 bg-gray-300 rounded"></div>
        </td>
        <td className={cn(`py-4`, `${getBorder()}`)}>
          <div className="w-34 h-6 bg-gray-300 rounded"></div>
        </td>
        <td className={cn(`py-4 pl-2`, `${getBorder()}`)}>
          <div className="flex gap-1 justify-center">
            <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
            <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
            <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
            <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
            <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
          </div>
        </td>
        <td className={cn(`px-4 py-4`, `${getBorder()}`)}>
          <div className="w-20 h-6 bg-gray-300 rounded"></div>
        </td>
        <td className={cn(`px-4 py-4`, `${getBorder()}`)}>
          <div className="w-10 h-6 bg-gray-300 rounded"></div>
        </td>
      </tr>
    </>
  );
};
