import {
  PaymentReservationResponse,
  ReservationResponse,
} from "../../../../types/GETTypes";

export type PaymentReservationListModalProps = {
  loading: boolean;
  useMode: PaymentReservationModalUseMode;
  reservation?: ReservationResponse;
  paymentReservation: PaymentReservationResponse | undefined;
  paymentScheduleId?: number | undefined;
  isVisible: boolean;
  onBack: () => void;
  onClose: () => void;
  onTransactionDetailClick: (
    paymentReservationId: number,
    paymentReservationDetailId: number
  ) => void;
};

export type PaymentReservationModalUseMode =
  | "paymentReservation"
  | "paymentReservationDetail";

export const PaymentReservationModalUseModeItems = [
  "paymentReservation",
  "paymentReservationDetail",
];
