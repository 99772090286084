import React from "react";
import CloseIcon from "../../../../assets/icons/close.svg?react";
import { cn } from "../../../../helpers/classHelper";
import { Button } from "../../../Common/Button/Button";
import { CenterModal } from "../../../Common/CenterModal/CenterModal";
import { useSmartlocksContext } from "./SmartlocksContext";

type RentalSmartlocksDeleteModalProps = {};

export const RentalSmartlocksDeleteModal: React.FC<
  RentalSmartlocksDeleteModalProps
> = ({}) => {
  const {
    t,
    currentLock,
    deleteValidationLoading,
    isDeleteModalVisible,
    onCloseDeleteModal,
    onUnlinkLock,
  } = useSmartlocksContext();

  return (
    <CenterModal
      isVisible={isDeleteModalVisible}
      onClose={deleteValidationLoading ? () => {} : onCloseDeleteModal}
    >
      <div className="flex flex-col space-y-6">
        {currentLock?.provider === "manual" ? (
          <>
            <div className="flex justify-between">
              <h2 className="text-xl font-bold me-4">
                {t("Rental.Services.Smartlocks.DeleteModal.manualTitle")}
              </h2>
              <CloseIcon
                className={cn({
                  "cursor-pointer": !deleteValidationLoading,
                  "cursor-not-allowed": deleteValidationLoading,
                })}
                width={30}
                height={30}
                onClick={
                  deleteValidationLoading ? () => {} : onCloseDeleteModal
                }
              />
            </div>

            <p className="text-base text-low-contrast">
              {t("Rental.Services.Smartlocks.DeleteModal.manualDescription")}
            </p>

            <div className="flex space-x-6">
              <Button
                type="secondary"
                onClick={onCloseDeleteModal}
                disabled={deleteValidationLoading}
              >
                {t("Global.cancel")}
              </Button>
              <Button
                type="destructive"
                onClick={() => {}}
                loading={deleteValidationLoading}
              >
                {t(
                  "Rental.Services.Smartlocks.DeleteModal.removeWithConfirmation"
                )}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-between">
              <h2 className="text-xl font-bold me-4">
                {t("Rental.Services.Smartlocks.DeleteModal.title")}
              </h2>
              <CloseIcon
                className={cn({
                  "cursor-pointer": !deleteValidationLoading,
                  "cursor-not-allowed": deleteValidationLoading,
                })}
                width={30}
                height={30}
                onClick={
                  deleteValidationLoading ? () => {} : onCloseDeleteModal
                }
              />
            </div>

            <p className="text-base text-low-contrast">
              {t("Rental.Services.Smartlocks.DeleteModal.description")}
            </p>

            <div className="flex space-x-6">
              <Button
                type="secondary"
                onClick={onCloseDeleteModal}
                disabled={deleteValidationLoading}
              >
                {t("Global.cancel")}
              </Button>
              <Button
                type="destructive"
                onClick={onUnlinkLock}
                loading={deleteValidationLoading}
              >
                {t(
                  "Rental.Services.Smartlocks.DeleteModal.disconnectWithConfirmation"
                )}
              </Button>
            </div>
          </>
        )}
      </div>
    </CenterModal>
  );
};
