import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import { TableList } from "../../components/Common/TableList/TableList";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { PaymentReservationFilters } from "../../components/Payment/PaymentReservationList/Filters/PaymentReservationFilters";
import {
  PaymentReservationFiltersTotals,
  PaymentReservationStatusFilterButton,
} from "../../components/Payment/PaymentReservationList/Filters/PaymentReservationFilters.type";
import { PaymentReservationListHeader } from "../../components/Payment/PaymentReservationList/PaymentReservationListHeader";
import { PaymentReservationListItem } from "../../components/Payment/PaymentReservationList/PaymentReservationListItem";
import paths from "../../constants/paths";
import { isCustomStringType } from "../../helpers/validationHelper";
import { useTablePage } from "../../hooks/useTablePage";
import { PaymentReservationResponse } from "../../types/GETTypes";
import { ValueType } from "../../types/commonTypes";
import { useFetchPaymentSchedule } from "./PaymentReservationList.hooks";
import { PaymentReservationListPageSkeleton } from "./PaymentReservationListPageSkeleton";
import { NoPaymentReservation } from "./NoPaymentReservation";
import {
  PaymentReservationModalUseMode,
  PaymentReservationModalUseModeItems,
} from "../../components/Payment/PaymentReservationList/PaymentReservationListModal/PaymentReservationListModal.type";
import { PaymentReservationListModal } from "../../components/Payment/PaymentReservationList/PaymentReservationListModal/PaymentReservationListModal";

export const PaymentReservationListPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.RESERVATIONS}`,
    "payment_reservations",
    {
      otherMetas: [
        "total",
        "total_canceled",
        "total_filtered",
        "total_overdue",
        "total_paid",
        "total_partially_paid",
        "total_unpaid",
      ],
    }
  );

  const { currentOtherMetas } = tablePage;

  useEffect(() => {
    tablePage.fetch({});
  }, []);

  const [currentActiveButtonFilter, setCurrentActiveButtonFilter] =
    useState<PaymentReservationStatusFilterButton>("ALL");

  useEffect(() => {
    if (currentActiveButtonFilter !== "ALL") {
      tablePage.handleFilterStatus(currentActiveButtonFilter);
    } else {
      tablePage.fetch({});
    }
  }, [currentActiveButtonFilter]);

  const [
    isPaymentReservationModalVisible,
    setIsPaymentReservationModalVisible,
  ] = useState<boolean>(false);
  const [currentPaymentReservation, setCurrentPaymentReservation] = useState<
    PaymentReservationResponse | undefined
  >();
  const [currentPaymentScheduleId, setCurrentPaymentScheduleId] = useState<
    number | undefined
  >();

  const [paymentReservationModalUseMode, setPaymentReservationModalUseMode] =
    useState<PaymentReservationModalUseMode | null>(null);

  const handleClickItem = (id: ValueType) => {
    if (typeof id === "number") {
      useFetchPaymentSchedule(
        setLoading,
        [currentPaymentReservation, setCurrentPaymentReservation],
        id
      );
    }
    setPaymentReservationModalUseMode("paymentReservation");
  };

  const handleClickItemDetail = (ids: ValueType, useMode: ValueType) => {
    let paymentReservationId: number | undefined = undefined;
    let paymentReservationDetailId: number | undefined = undefined;
    if (typeof ids === "string") {
      const idsSplit = ids.split(",");
      paymentReservationId = Number(idsSplit[0]);
      paymentReservationDetailId = Number(idsSplit[1]);
    }
    if (
      paymentReservationId !== undefined &&
      paymentReservationId >= 0 &&
      paymentReservationDetailId !== undefined &&
      paymentReservationDetailId >= 0
    ) {
      useFetchPaymentSchedule(
        setLoading,
        [currentPaymentReservation, setCurrentPaymentReservation],
        paymentReservationId
      );
      const useModeTypeChecker = (
        value: string
      ): value is PaymentReservationModalUseMode =>
        PaymentReservationModalUseModeItems.includes(value);
      if (
        typeof useMode === "string" &&
        isCustomStringType<PaymentReservationModalUseMode>(
          useMode,
          useModeTypeChecker
        )
      ) {
        setPaymentReservationModalUseMode(useMode);
      }

      setCurrentPaymentScheduleId(paymentReservationDetailId);
    }
  };

  useEffect(() => {
    if (
      paymentReservationModalUseMode !== null &&
      !isPaymentReservationModalVisible
    ) {
      setIsPaymentReservationModalVisible(true);
    }
  }, [paymentReservationModalUseMode]);

  useEffect(() => {
    if (!isPaymentReservationModalVisible) {
      setPaymentReservationModalUseMode(null);
    }
  }, [isPaymentReservationModalVisible]);

  const handleClickTransactionDetail = (
    paymentReservationId: number,
    paymentReservationDetailId: number
  ) => {
    if (
      paymentReservationId !== undefined &&
      paymentReservationId >= 0 &&
      paymentReservationDetailId !== undefined &&
      paymentReservationDetailId >= 0
    ) {
      useFetchPaymentSchedule(
        setLoading,
        [currentPaymentReservation, setCurrentPaymentReservation],
        paymentReservationId
      );
      setPaymentReservationModalUseMode("paymentReservationDetail");
    }
  };

  const getTitle = () => {
    return (
      <p className="flex flex-row items-center gap-1">
        {t("Payments.title")} <ChevronRightIcon />
        {t("Payments.reservationList")}
      </p>
    );
  };

  const getFiltersNode = (): ReactNode => {
    const totals: PaymentReservationFiltersTotals = {
      unpaid:
        currentOtherMetas.find((meta) => meta.key === "total_unpaid")?.value ??
        "0",
      partiallyPaid:
        currentOtherMetas.find((meta) => meta.key === "total_partially_paid")
          ?.value ?? "0",
      paid:
        currentOtherMetas.find((meta) => meta.key === "total_paid")?.value ??
        "0",
      overdue:
        currentOtherMetas.find((meta) => meta.key === "total_overdue")?.value ??
        "0",
      canceled:
        currentOtherMetas.find((meta) => meta.key === "total_canceled")
          ?.value ?? "0",
      all: currentOtherMetas.find((meta) => meta.key === "total")?.value ?? "0",
    };
    return (
      <PaymentReservationFilters
        onSearchText={tablePage.handleChangeSearch}
        totals={totals}
        currentActiveButtonFilter={currentActiveButtonFilter}
        onFilterButtonClick={(value) =>
          setCurrentActiveButtonFilter(
            value as PaymentReservationStatusFilterButton
          )
        }
        tablePage={tablePage}
      />
    );
  };

  return (
    <>
      <MainLayout
        title={getTitle()}
        sidebarActiveItem="payments"
        sidebarActiveSubItem="reservations"
      >
        <PaymentReservationListModal
          loading={loading}
          useMode={paymentReservationModalUseMode!}
          isVisible={isPaymentReservationModalVisible}
          paymentReservation={currentPaymentReservation}
          paymentScheduleId={currentPaymentScheduleId}
          onBack={() => setPaymentReservationModalUseMode("paymentReservation")}
          onClose={() => setIsPaymentReservationModalVisible(false)}
          onTransactionDetailClick={handleClickTransactionDetail}
        />

        <TableList
          i18nElement="Global.payment"
          Filters={getFiltersNode()}
          filterTotal={
            Number(
              currentOtherMetas.find((meta) => meta.key === "total")?.value
            ) ?? 0
          }
          Header={PaymentReservationListHeader}
          tablePage={tablePage}
          Item={PaymentReservationListItem}
          Skeleton={PaymentReservationListPageSkeleton}
          NoData={
            <NoPaymentReservation
              title={t("Global.noPaymentReservationTitle")}
              description={t("Global.noPaymentReservationContent")}
            />
          }
          onClick={handleClickItem}
          onClickAction={handleClickItemDetail}
        />
      </MainLayout>
    </>
  );
};
