import React, { useEffect, useRef, useState } from "react";
import { Button } from "../Common/Button/Button";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg?react";
import { Title } from "../Common/Title/Title";
import { Controller, useForm } from "react-hook-form";
import { TextInput } from "../Common/TextInput/TextInput";
import AlertCircleIcon from "../../assets/icons/alert-circle.svg?react";
import CheckWhiteIcon from "../../assets/icons/check-white.svg?react";
import { RegisterMemberPasswordForm } from "./RegisterMemberStepPassword.type";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";

export const RegisterMemberStepPassword: React.FC<{
  loading: boolean;
  error: string | null;
  onBack: () => void;
  onSubmit: (values: RegisterMemberPasswordForm) => void;
}> = ({ loading, error, onBack, onSubmit }) => {
  const { t } = useTranslation();
  const form = useForm<RegisterMemberPasswordForm>({
    defaultValues: { password: "", confirmationPassword: "" },
  });

  const [criteria, setCriteria] = useState<{
    length: boolean;
    upper: boolean;
    number: boolean;
    specialChar: boolean;
  }>({
    length: false,
    upper: false,
    number: false,
    specialChar: false,
  });

  const passwordRef = useRef({});
  passwordRef.current = form.watch("password", "");

  const validatePassword = (password: string) => {
    setCriteria({
      length: password?.length >= 8,
      upper: /[A-Z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[\W_]/.test(password),
    });
  };

  useEffect(() => {
    validatePassword(passwordRef.current as string);
  }, [passwordRef.current]);

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-3">
        <div className="w-52 mb-2">
          <Button
            type="secondary"
            LeftIcon={ArrowLeftIcon}
            disabled={loading}
            onClick={onBack}
          >
            {t("Global.previousStep")}
          </Button>
        </div>

        <Title>{t("RegisterMember.Password.title")}</Title>

        <div className="mt-3">
          <Controller
            control={form.control}
            name="password"
            rules={{
              required: t("Reset.required").toString(),
              minLength: {
                value: 8,
                message: t("Reset.minLength").toString(),
              },
              maxLength: {
                value: 24,
                message: t("Reset.maxLength").toString(),
              },
              pattern: {
                value: /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,24}$/,
                message: t("Reset.invalidFormat").toString(),
              },
            }}
            render={({ field: { value, onChange } }) => (
              <TextInput
                label={t("Reset.create")}
                type="password"
                error={form.formState.errors.password?.message}
                required={true}
                disabled={loading}
                autoComplete="password"
                value={value}
                placeholder="P4ss-sh-w0rd!"
                onChangeText={onChange}
              />
            )}
          />
        </div>

        <div>
          <p
            className={`mt-2 text-sm font-light text-left ${
              criteria.length ? "text-green" : "text-low-contrast "
            } flex flex-row gap-1`}
          >
            <AlertCircleIcon
              className={`${
                criteria.length ? "fill-green" : "fill-gray-500 "
              } me-2 shrink-0`}
              width={18}
              height={18}
            />
            {t("Reset.rule1Title")}
          </p>
          <p
            className={`mt-2 text-sm font-light text-left ${
              criteria.upper ? "text-green" : "text-low-contrast "
            } flex flex-row gap-1`}
          >
            <AlertCircleIcon
              className={`${
                criteria.upper ? "fill-green" : "fill-gray-500 "
              } me-2 shrink-0`}
              width={18}
              height={18}
            />
            {t("Reset.rule4Title")}
          </p>
          <p
            className={`mt-2 text-sm font-light text-left ${
              criteria.number ? "text-green" : "text-low-contrast "
            }  flex flex-row gap-1`}
          >
            <AlertCircleIcon
              className={`${
                criteria.number ? "fill-green" : "fill-gray-500 "
              } me-2 shrink-0`}
              width={18}
              height={18}
            />
            {t("Reset.rule2Title")}
          </p>
          <p
            className={`mt-2 text-sm font-light text-left ${
              criteria.specialChar ? "text-green" : "text-low-contrast "
            }  flex flex-row gap-1`}
          >
            <AlertCircleIcon
              className={`${
                criteria.specialChar ? "fill-green" : "fill-gray-500 "
              } me-2 shrink-0`}
              width={18}
              height={18}
            />
            {t("Reset.rule3Title")}
          </p>
        </div>

        <div className="mt-4">
          <TextInput
            register={form.register("confirmationPassword", {
              validate: (value) =>
                value === passwordRef.current || t("Reset.notMatch").toString(),
            })}
            label={t("Reset.confirm")}
            type="password"
            error={form.formState.errors.confirmationPassword?.message}
            disabled={loading}
            placeholder="P4ss-sh-w0rd!"
            required={true}
          />
        </div>

        <ErrorMessage errorType="API">{error}</ErrorMessage>

        <div className="mt-3">
          <Button RightIcon={CheckWhiteIcon} loading={loading}>
            {t("RegisterMember.Password.button")}
          </Button>
        </div>
      </div>
    </form>
  );
};
