import React from "react";
import KeyIcon from "../../../../assets/icons/key.svg?react";
import PlusIcon from "../../../../assets/icons/plus.svg?react";
import RefreshIcon from "../../../../assets/icons/refresh.svg?react";
import { Card } from "../../../Common/Card/Card";
import { useSmartLocksList } from "./Smartlocks.hooks";
import { useSmartlocksContext } from "./SmartlocksContext";

export const RentalSmartlocksCard: React.FC = () => {
  const { t, onOpenAddModal } = useSmartlocksContext();

  return (
    <Card
      Icon={KeyIcon}
      label={t("Rental.Services.Smartlocks.title")}
      button={{
        Icon: PlusIcon,
        type: "secondary",
        onClick: onOpenAddModal,
      }}
      secondaryButton={{
        Icon: RefreshIcon,
        type: "secondary",
        disabled: true,
      }}
    >
      <div className="flex flex-col items-center justify-center space-y-2">
        {useSmartLocksList()}
      </div>
    </Card>
  );
};
