import React from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../../Common/Button/Button";
import {GuestPageContact} from "../../../types/GETTypes";
import PhoneIcon from "../../../assets/icons/phone.svg?react";
import MessageIcon from "../../../assets/icons/message.svg?react";
import AirBnbIcon from "../../../assets/icons/airbnb-2-color.svg?react";
import WhatsAppIcon from "../../../assets/icons/whatsapp.svg?react";
import MailIcon from "../../../assets/icons/mail-white.svg?react";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";

const ContactCardComponent = ({
  contact,
  digit,
}: {
  contact: GuestPageContact;
  digit: number;
}) => {
  const {t} = useTranslation();
  const {isPreviewMode} = useGuestPageDataStore();
  const {
    email,
    email_enabled,
    phone_enabled,
    last_name,
    first_name,
    phone,
    photo,
  } = contact || {};

  const src = photo ?? "";
  const textMobile = isPreviewMode ? "text-lg" : "text-lg";
  return (
    <div className="flex flex-col p-2 md:pb-6 w-full mt-4 md:mt-0 md:pt-0 mb-8 border-b-1 border-element-border">
      <h3 className="text-xl text-low-contrast mb-6 uppercase">
        {t("GuestPage.Contact.title")} {digit}
      </h3>
      <div className="flex flex-row">
        {src ? (
          <img
            src={src}
            alt="Profile"
            className="w-[96px] md:w-[150px] h-[96px] md:h-[150px] rounded-md object-cover"
          />
        ) : (
          <div className="w-[96px] md:w-[150px] h-[96px] md:h-[150px] rounded-md object-cover bg-slate-200"></div>
        )}

        <div className="ml-4 flex flex-col justify-center w-3/4">
          <h2 className={`${textMobile} md:text-[32px] font-semibold mb-2`}>
            {first_name} {last_name}
          </h2>
          <div className="flex flex-1 justify-between items-center mb-1">
            {phone_enabled ? (
              <p className={`text-gray-700 ${textMobile} md:text-xxl`}>
                {phone}
              </p>
            ) : null}
            {phone_enabled ? (
              <div className="flex items-center  mt-1">
                <div className="flex shadow-md border-1 border-gray-200 h-8 md:h-12 w-8 md:w-12 items-center justify-center rounded-md">
                  <a href={`tel:${phone}`} target="_blank">
                    <PhoneIcon className="w-4 h-4 md:w-8 md:h-8" />
                  </a>
                </div>
                <div className="flex shadow-md border-1 border-gray-200 h-8 md:h-12 w-8 md:w-12 items-center justify-center rounded-md ml-2">
                  <a href={`sms:${phone}`} target="_blank">
                    <MessageIcon className="w-4 h-4 md:w-8 md:h-8" />
                  </a>
                </div>
              </div>
            ) : null}
          </div>
          {email_enabled ? (
            <p className={`text-gray-500 mt-1 ${textMobile} md:text-xxl`}>
              {email}
            </p>
          ) : null}
        </div>
      </div>
      {/* <div className="mt-4">
        <Button className="" RightIcon={AirBnbIcon} type="secondary">
          {t("GuestPage.Contact.contactViaAirbnb")}
        </Button>
      </div> */}
      {phone ? (
        <div className="mt-2 md:mt-8 md:flex md:justify-start">
          <a href={`https://wa.me/${phone}`} target="_blank">
            <Button
              className=""
              RightIcon={WhatsAppIcon}
              type="secondary"
              buttonClassName="md:w-[350px] md:h-[40px] md:h-12"
            >
              {t("GuestPage.Contact.contactViaWhatsapp")}
            </Button>
          </a>
        </div>
      ) : null}
      {email_enabled ? (
        <div className="mt-2 md:mt-8 md:flex md:justify-start">
          <a href={`mailto:${email}`} target="_blank">
            <Button
              className=""
              buttonClassName="md:w-[350px] md:h-[40px] md:h-12"
              textClassName="md:text-xl"
              RightIcon={MailIcon}
              type="primary"
            >
              {t("GuestPage.Contact.sendEmail")}
            </Button>
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default ContactCardComponent;
