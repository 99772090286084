import { PaymentReservationScheduleResponse } from "../types/GETTypes";

export const getPaymentMessageByPaymentSchedule: (
  paymendSchedule: PaymentReservationScheduleResponse,
  index: number,
  isDeposit: boolean
) => { subject: string; message: string } = (
  paymendSchedule,
  index,
  isDeposit
) => {
  if (isDeposit) {
    return {
      subject: "Caution - [RENTAL:NAME]",
      message: `Bonjour,

Nous vous invitons à cliquer sur le lien ci-dessous pour effectuer le dépôt:

Titre : [DEPOSIT:TITLE]
Description : [DEPOSIT:DESCRIPTION]
Lien : [DEPOSITLINK]

A très vite,

--

[RENTAL:NAME]`,
    };
  } else if (!isDeposit) {
    return {
      subject:
        "Confirmez le paiement de votre réservation - [RENTAL:NAME] - [GUEST:FIRST_NAME]",
      message: `Bonjour,

Voici le lien pour effectuer le Paiement :

[PAYLINK]

Il vous suffit de régler en cliquant sur le lien ci-dessus.

Titre : [PAYMENT:TITLE]
Description : [PAYMENT:DESCRIPTION]

-------

Ceci est un email automatique. Merci de ne pas répondre a cet email.

Pour toutes questions ou commentaires, merci de contacter votre hote.

Merci.
`,
    };
  }

  return {
    subject: "Default subject",
    message: "Default message",
  };
};
