import React from "react";
import { useTranslation } from "react-i18next";
import { CenterModal } from "../../../Common/CenterModal/CenterModal";
import { Button } from "../../../Common/Button/Button";
import { ValueType } from "../../../../types/commonTypes";
import { PaymentReservationScheduleListItemResponse } from "../../../../types/GETTypes";
import { capitalizeFirstLetter } from "../../../../helpers/stringHelper";

export const PaymentReservationListModalRefund: React.FC<{
  isVisible: boolean;
  paymentReservationDetail:
    | PaymentReservationScheduleListItemResponse
    | undefined
    | null;
  onClose: () => void;
  onRefundPaymentReservation: (id: ValueType) => void;
}> = ({
  isVisible,
  paymentReservationDetail,
  onClose,
  onRefundPaymentReservation,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
    <CenterModal isVisible={isVisible} onClose={handleClose}>
      <div className="flex flex-col gap-5">
        <p className="text-xl font-extrabold">
          {t("Payments.PaymentReservationList.refundModalTitle")}
        </p>
        <p className="text-sm text-low-contrast">
          {t("Payments.PaymentReservationList.refundModalDescription", {
            refundType: capitalizeFirstLetter(
              paymentReservationDetail?.payment_account?.type
            ),
          })}
        </p>
        <div className="flex gap-3">
          <Button type="secondary" onClick={handleClose}>
            {t("Global.cancel")}
          </Button>
          <div title={t("Global.wip")}>
            <Button
              type="destructive"
              onClick={() =>
                onRefundPaymentReservation(Number(paymentReservationDetail?.id))
              }
              disabled={true}
            >
              {t("Global.refundWithConfirmation")}
            </Button>
          </div>
        </div>
      </div>
    </CenterModal>
  );
};
