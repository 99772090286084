import {PaymentReservationStatusEnum} from "../../../enums/GETenums";
import {GuestPagePaymentAccount} from "../../../types/GETTypes";

function generateRandomPaymentObject() {
  const paymentStatuses = ["PAID", "PARTIALLY_PAID", "UNPAID"];

  return {
    id: Number((Math.random() * 1000).toFixed(2)),
    payment_status: paymentStatuses[
      Math.floor(Math.random() * paymentStatuses.length)
    ] as PaymentReservationStatusEnum,
    value_type: "",
    percentage_value: null,
    price_value: Number((Math.random() * 1000).toFixed(2)),
    trigger: "",
    specific_date: new Date(Date.now() + Math.random() * 10000000000)
      .toISOString()
      .replace("T", " ")
      .replace("Z", ""),
    payment_account_type: "",
    payment_account: {
      id: Number((Math.random() * 1000).toFixed(2)),
      name: "",
      type: "",
      stripe_account_id: "",
      stripe_account_name: "",
      stripe_account_email: "",
      created_at: "",
      updated_at: "",
    },
    payment_link: Math.random().toString(36).substring(2, 38),
    payment_success_date: null,
  };
}

export function generateRandomPaymentsArray(numberOfPayments: number) {
  const paymentsArray = [];
  for (let i = 0; i < numberOfPayments; i++) {
    paymentsArray.push(generateRandomPaymentObject());
  }
  return paymentsArray;
}

export function generateLoremIpsum(wordCount:number) {
  const loremIpsumWords = [
    "lorem", "ipsum", "dolor", "sit", "amet", "consectetur", "adipiscing", "elit", 
    "sed", "do", "eiusmod", "tempor", "incididunt", "ut", "labore", "et", "dolore", 
    "magna", "aliqua", "ut", "enim", "ad", "minim", "veniam", "quis", "nostrud", 
    "exercitation", "ullamco", "laboris", "nisi", "ut", "aliquip", "ex", "ea", 
    "commodo", "consequat", "duis", "aute", "irure", "dolor", "in", "reprehenderit", 
    "in", "voluptate", "velit", "esse", "cillum", "dolore", "eu", "fugiat", "nulla", 
    "pariatur", "excepteur", "sint", "occaecat", "cupidatat", "non", "proident", 
    "sunt", "in", "culpa", "qui", "officia", "deserunt", "mollit", "anim", "id", 
    "est", "laborum"
  ];

  let loremIpsumText = [];
  
  for (let i = 0; i < wordCount; i++) {
    const randomIndex = Math.floor(Math.random() * loremIpsumWords.length);
    loremIpsumText.push(loremIpsumWords[randomIndex]);
  }

  return loremIpsumText.join(' ');
}


