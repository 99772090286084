import React, {useEffect, useState} from "react";
import {GuestPageTab} from "../GuestPageHome/GuestPageHomeComponent";
import ExtraComponent, {areAllExtrasDisabled, ExtrasOrder} from "../Common/ExtraComponent";
import {useTranslation} from "react-i18next";
import {Button} from "../../Common/Button/Button";
import ClockIcon from "../../../assets/icons/clock.svg?react";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import useGuestPageTextCoverStore from "../../../stores/guestPageStore/useGuestPageTextCoverStore";
import useWindowSize from "../../../hooks/useWindowSize";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import useExtrasStore from "../../../stores/guestPageStore/useExtrasStore";
import {post} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import {ResultOrder} from "../../../types/GETTypes";



const GuestPageExtra = ({onChangeTab}: GuestPageTab) => {
  const {isMobile} = useWindowSize();
  const {t} = useTranslation();
  const {storeTitle, storeSubTitle} = useGuestPageTextCoverStore();
  const {extrasOrder, storeExtraValidateData,storeSendExtraData} =
    useExtrasStore();
  const {guestPageData, isPreviewMode, rentalPreview} = useGuestPageDataStore();
  const {
    guest,
    id: reservation_id,
    extras_available_for_order,
  } = guestPageData || {};
  const {guest_page} = rentalPreview || {};
  const {extras_available} = guest_page || {};
  const {email} = guest || {};
  const [loadingValidate, setLoadingValidate] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    storeTitle(t("GuestPage.Reservation.extras"));
    storeSubTitle && storeSubTitle(t("GuestPage.Reservation.extraSubTitle"));
  }, []);

  const calculateTotalPrice = (
  ): number => {
    return extrasOrder.reduce((total, order) => {
      const matchingExtra = extras_available_for_order?.find(extra => extra.id === order.id);
      if (matchingExtra && order.quantity) {
        total += matchingExtra.unit_price * order.quantity;
      }
  
      return total;
    }, 0);
  };

  const onValidate = async () => {
    setLoadingValidate(true);
    if (extrasOrder?.length > 0) {
      const orderedResult: ResultOrder = extrasOrder.reduce<ResultOrder>(
        (acc, item, index) => {
          if (index === 0) {
            acc.ordered_extras_ids = `${item.id}`;
            acc.ordered_extras_quantities = `${item.quantity}`;
          } else {
            acc.ordered_extras_ids += `,${item.id}`;
            acc.ordered_extras_quantities += `,${item.quantity}`;
          }
          return acc;
        },
        {ordered_extras_ids: "", ordered_extras_quantities: ""}
      );

      const data = {
        reservation_id: String(reservation_id),
        email,
        ...orderedResult,
      };
      const res = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.GUEST_PAGE.VALIDATE_ORDER_EXTRAS
        }`,
        data
      );
      if (res?.data?.success) {
        storeSendExtraData(data)
        storeExtraValidateData({...res.data?.result});
        setLoadingValidate(false);
        onChangeTab && onChangeTab("extraPayment");
      } else {
        setError(res?.response?.data?.message);
      }
    }
    setLoadingValidate(false);
  };

  const extras = isPreviewMode ? extras_available : extras_available_for_order;

  const allDisabled = areAllExtrasDisabled(extras);

  const hasExtraAvailable = extras && extras?.length > 0 && !allDisabled;

  return (
    <div className="flex flex-1 flex-col md:w-full  md:h-[85vh]">
      {isMobile ? (
        <div className="flex flex-1 md:flex-initial justify-end md:justify-start md:flex-col md:items-end">
          <Button
            RightIcon={ClockIcon}
            type="secondary"
            displayLargeBtn={false}
            className="m-4 mt-8 md:mt-0"
            buttonClassName="md:h-12"
            textClassName="md:text-xl"
            onClick={() => onChangeTab && onChangeTab("extraHistorical")}
            disabled={isPreviewMode}
          >
            {t("GuestPage.ExtraHistorical.displayHistorique")}
          </Button>
        </div>
      ) : null}
      <ExtraComponent
        title={t("GuestPage.Reservation.extras")}
        subTitle={t("GuestPage.Reservation.extraSubTitle")}
        blockStyle={{
          titleStyle: "text-[32px]",
          childrenDivStyle: `border-b-0 ${
            !hasExtraAvailable ? "md:w-full" : "md:w-[70%]"
          }`,
          parentDivStyle:
            "md:flex md:mt-0 md:w-full md:mt-14 md:flex-wrap md:flex-col mb-24",
        }}
        fromPage="extra" 
        onChangeTab={onChangeTab}
        displayTitles={true}
      />
      {isMobile ? null : (
        <>
          <div className="flex flex-col flex-1 justify-end items-center mb-8">
            <ErrorMessage childrenClassName="md:text-lg w-[75%]">
              {error}
            </ErrorMessage>
            <div className="flex flex-row justify-between">
              <div className="flex flex-1 md:flex-initial md:justify-start md:flex-col md:items-end ">
                <Button
                  RightIcon={ClockIcon}
                  type="secondary"
                  displayLargeBtn={false}
                  className="m-4 ml-0 mt-8 md:mt-0"
                  buttonClassName="md:h-12"
                  textClassName="md:text-xl"
                  onClick={() => onChangeTab && onChangeTab("extraHistorical")}
                  disabled={isPreviewMode}
                >
                  {t("GuestPage.ExtraHistorical.displayHistorique")}
                </Button>
              </div>
              <div className="flex flex-1 md:flex-initial md:justify-end md:flex-col md:items-end ">
                <Button
                  RightIcon={ArrowRightWhiteIcon}
                  displayLargeBtn={false}
                  onClick={onValidate}
                  className="m-4 mr-0 mt-8 md:mt-0"
                  buttonClassName="md:h-12"
                  textClassName="md:text-xl"
                  disabled={isPreviewMode || !extrasOrder?.length}
                  loading={loadingValidate}
                >
                  {t("GuestPage.Extras.validateExtras")}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GuestPageExtra;
