import React from "react";

export const MultiSelectRentalItemSkeleton: React.FC<{}> = ({}) => {
  return (
    <div className="py-3 px-4 flex flex-row gap-2 items-center border-b-1 border-element-border-light last:border-0 animate-pulse">
      <div
        className={`min-w-[16px] min-h-[16px] flex items-center justify-center border-element-border rounded-sm  "bg-white border-1 overflow-hidden`}
      />

      <div className="flex flex-col">
        <div className="flex flex-row gap-2 items-center">
          {/* Cover */}
          <div className="object-fill w-12 h-12 shrink-0 rounded-4px border-1 border-element-border/50 overflow-hidden bg-element-background"></div>

          {/* Name and address */}
          <div className="text-left flex flex-col gap-1">
            <p className="text-high-contrast font-semibold h-2 w-72 bg-slate-300 rounded" />
            <p className="text-high-contrast font-semibold h-2 w-72 bg-slate-200 rounded" />
            <p className="text-high-contrast font-semibold h-2 w-56 bg-slate-200 rounded" />
          </div>
        </div>
      </div>
    </div>
  );
};
