import React from "react";
import EntryActiveIcon from "../../../assets/icons/entry-active.svg?react";
import EntryIcon from "../../../assets/icons/entry.svg?react";
import { TextType } from "../../../types/commonTypes";

type SidebarSubItemProps = {
  label: TextType;
  isActive: boolean;
  url: string;
};

export const SidebarSubItem: React.FC<SidebarSubItemProps> = ({
  label,
  isActive,
  url,
}) => {
  return (
    <div className="pt-1 ml-4 flex flex-row items-center space-x-2 cursor-pointer">
      {isActive ? <EntryActiveIcon /> : <EntryIcon />}
      <p
        className={`text-sm pt-2 font-semibold ${
          isActive ? "text-icon-active" : "text-low-contrast"
        }`}
      >
        <a href={url}>{label}</a>
      </p>
    </div>
  );
};
