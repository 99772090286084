import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NotificationStatusesListItemResponse} from "../../../types/GETTypes";
import {Button} from "../../Common/Button/Button";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import CloseIcon from "../../../assets/icons/close.svg?react";
import {update} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import {Controller, useForm} from "react-hook-form";
import {CalendarInput} from "../../Common/CalendarInput/CalendarInput";
import {TimeInput} from "../../Common/TimeInput/TimeInput";
import {
  capitalizeFirstLetter,
  insertTextAtPosition,
} from "../../../helpers/stringHelper";
import moment from "moment";
import {RightModal} from "../../Common/RightModal/RightModal";
import InfoIcon from "../../../assets/icons/information.svg?react";
import {useNavigate} from "react-router-dom";
import {TextAreaInput} from "../../Common/TextAreaInput/TextAreaInput";
import {ShortCodeList} from "../ShortCodeList/ShortCodeList";
import {Switch} from "../../Common/Switch/Switch";
import {Title} from "../../Common/Title/Title";
import {validateDate} from "../../../helpers/calendarHelper";
import {formatTime} from "../../../helpers/dateHelper";

type ScheduleForm = {
  content: string;
  scheduleDate: Date;
  scheduleTime: string;
};

export const ScheduledMessageEditModal: React.FC<{
  message: NotificationStatusesListItemResponse | null | undefined;
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({message, isVisible, onClose, onSuccess}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [formError, setFormError] = useState<string | null>(null);
  const [isShortcodesVisible, setShortcodesVisible] = useState<boolean>(false);
  const [currentContentCursor, setCurrentContentCursor] = useState<number>(0);

  const form = useForm<ScheduleForm>({
    defaultValues: {
      content: "",
      scheduleDate: new Date(),
      scheduleTime: "00:00",
    },
  });

  useEffect(() => {
    setCurrentContentCursor(0);
    setShortcodesVisible(false);
  }, [isVisible]);

  useEffect(() => {
    if (message) {
      form.setValue("content", message.content.message);
      form.setValue("scheduleDate", moment(message?.ready_at).toDate());
      form.setValue("scheduleTime", formatTime(message?.ready_at));
    }
  }, [message]);

 
  const handleNavigateToEdit = () =>
    navigate(`${paths.AUTO_MESSAGES}?edit_id=${message?.notification.id}`);

  const handleEdit = async (values: ScheduleForm) => {
    setError(null);
    setFormError(null);

    const {isValid, reason, value} = validateDate(values);

    if (!isValid) {
      reason === "missing"
        ? setFormError(t("ScheduledMessageList.Edit.formErrorMissing")) : 
      reason === "day"
        ? setFormError(t("ScheduledMessageList.Edit.formErrorDay", {value}))
        : setFormError(t("ScheduledMessageList.Edit.formErrorTime", {value}));
      return;
    }

    setLoading(true);

    const URL = `${import.meta.env.VITE_API_URL}${
      paths.API.SCHEDULED_MESSAGE
    }/${message?.id}${paths.API.SCHEDULED_MESSAGE_EDIT}`;

    const res = await update(URL, {
      ready_at: `${moment(values.scheduleDate).format("YYYY-MM-DD")} ${
        values.scheduleTime
      }`,
      content: {
        title: message?.content.title,
        message: values.content,
      },
    });

    if (res.data?.success) {
      onSuccess();
      handleClose();
    } else {
      setError(res?.response?.data?.message);
    }

    setLoading(false);
  };

  const handleClose = () => {
    setFormError(null);
    setError(null);
    onClose();
  };

  const getTitle = () => {
    if (isShortcodesVisible)
      return (
        <div className="flex">
          <div className="flex justify-between w-modal">
            <Title>{t("Templates.shortcodes")}</Title>
            <div
              className="pt-1 hover:cursor-pointer mr-6"
              onClick={() => setShortcodesVisible(false)}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="flex-1">
            <Title>{t("ScheduledMessageList.Edit.title")}</Title>
          </div>
        </div>
      );
    else return t("ScheduledMessageList.Edit.title");
  };

  const handleInsertShortCode = (trigger: string) => {
    const nextContent = insertTextAtPosition(
      form.watch("content"),
      trigger,
      currentContentCursor
    );

    form.setValue("content", nextContent);
  };

  return (
    <RightModal
      isVisible={isVisible}
      onClose={handleClose}
      title={getTitle()}
      isSplit={isShortcodesVisible}
    >
      {isShortcodesVisible && (
        <div className="flex-1 overflow-y-scroll pr-8">
          <ShortCodeList onInsert={handleInsertShortCode} />
        </div>
      )}

      <div
        className={`flex flex-col w-full ${
          isShortcodesVisible ? "w-modal-inside" : "w-full"
        }`}
      >
        <form
          onSubmit={form.handleSubmit(handleEdit)}
          className="flex flex-1 h-full"
        >
          <div className="w-full flex-1 overflow-y-auto flex flex-col justify-between">
            <div className="overflow-y-scroll flex-1">
              <div className="flex gap-2 bg-element-background rounded-6px p-2">
                <InfoIcon className="h-5 w-5 shrink-0" />
                <div className="flex flex-col gap-2">
                  <p className="text-high-contrast font-semibold">
                    {t("ScheduledMessageList.Edit.infoTitle")}
                  </p>

                  <p className="text-low-contrast font-light">
                    {t("ScheduledMessageList.Edit.infoDescription")}
                  </p>

                  <div className="w-72">
                    <Button type="secondary" onClick={handleNavigateToEdit}>
                      {t("ScheduledMessageList.Edit.infoButton")}
                    </Button>
                  </div>
                </div>
              </div>

              <div className="h-4" />

              <Controller
                control={form.control}
                name="content"
                rules={{
                  required: {
                    value: true,
                    message: t("ScheduledMessageList.Edit.contentError"),
                  },
                }}
                render={({field: {value, onChange}}) => (
                  <TextAreaInput
                    label={t("ScheduledMessageList.Edit.content")}
                    required={true}
                    value={value}
                    onTextChange={onChange}
                    onSelect={(event) =>
                      setCurrentContentCursor(event.target.selectionStart)
                    }
                    error={form.formState.errors.content?.message}
                  />
                )}
              />

              <div className="h-4" />

              <div
                className="flex flex-row justify-between items-center cursor-pointer"
                onClick={() => setShortcodesVisible(!isShortcodesVisible)}
              >
                <p className="text-low-contrast font-light">
                  {t("ScheduledMessageList.Edit.showShortcode")}
                </p>
                <Switch value={isShortcodesVisible} />
              </div>

              <div className="h-4" />

              <Controller
                control={form.control}
                name="scheduleDate"
                rules={{
                  minLength: {
                    value: 5,
                    message: t("ScheduledMessageList.Edit.scheduleError"),
                  },
                  maxLength: {
                    value: 5,
                    message: t("ScheduledMessageList.Edit.scheduleError"),
                  },
                }}
                render={({field: {value, onChange}}) => (
                  <CalendarInput
                    label={t("ScheduledMessageList.Edit.schedule")}
                    dateType="default"
                    value={value}
                    onChangeDate={onChange}
                    classNames={{button: "h-8"}}
                    isExcludeDate={(date: Date) => {
                      const d = moment(date).startOf("day");
                      const today = moment().startOf("day");
                      return d.isBefore(today, "day");
                    }}
                  />
                )}
              />

              <div className="h-4" />

              <Controller
                control={form.control}
                name="scheduleTime"
                render={({field: {value, onChange}}) => (
                  <TimeInput
                    label={capitalizeFirstLetter(t("Global.at"))}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              <ErrorMessage errorType="FORM">{formError}</ErrorMessage>
              <ErrorMessage>{error}</ErrorMessage>
            </div>

            <div className="flex flex-row gap-3 mt-2 pt-4 border-t-1 border-element-border">
              <Button type="secondary" onClick={handleClose} disabled={loading}>
                {t("Global.cancel")}
              </Button>

              <Button loading={loading} RightIcon={CheckWhiteIcon}>
                {t("ScheduledMessageList.Enable.save")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </RightModal>
  );
};
