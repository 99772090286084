import React from "react";
import LayoutBlockComponent from "./LayoutBlockComponent";
import {useTranslation} from "react-i18next";
import {Button} from "../../Common/Button/Button";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import {replaceAsteriskWithStrongBalise} from "../../../helpers/stringHelper";

const GuestPageConditions = ({
  conditions = "",
  title = "",
  setOpenConditions,
  goBack = true,
}: {
  title?: string;
  conditions?: string;
  setOpenConditions: React.Dispatch<React.SetStateAction<boolean>>;
  goBack?: boolean;
}) => {
  const {t} = useTranslation();
  return (
    <div>
      {goBack && (
        <Button
          LeftIcon={ArrowLeftIcon}
          type="secondary"
          displayLargeBtn={false}
          className="m-4 mt-8 md:mt-14"
          buttonClassName="md:h-12"
          textClassName="md:text-xl"
          onClick={() => setOpenConditions(false)}
        >
          {t("Global.back")}
        </Button>
      )}

      <LayoutBlockComponent
        title={title ?? ""}
        subTitle={""}
        blockStyle={{
          titleStyle: "text-[32px] font-bold",
          childrenDivStyle: "pl-2 md:w-full md:h-full border-b-0",
          parentDivStyle: "md:w-full",
        }}
      >
        <div
          className="whitespace-pre-line text-base md:text-lg"
          dangerouslySetInnerHTML={{
            __html: replaceAsteriskWithStrongBalise(conditions ?? ""),
          }}
        />
      </LayoutBlockComponent>
    </div>
  );
};

export default GuestPageConditions;
