/**
 * Component for show all Externals platforms in rental.
 */

import { Separator } from "../../../../Common/Separator/Separator";
import { RentalPlatformsCardElement } from "../PlatformsCardElement";
import { Button } from "../../../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import { useGetRental } from "../../../../../hooks/api/rental";
import { useEffect, useState } from "react";
import { useIcalendar } from "../../../../../hooks/api/calendar";
import { ErrorMessage } from "../../../../Common/ErrorMessage/ErrorMessage";
import { ExternalPlatformsSkeleton } from "./ExternalPlatformsSkeleton";
import { cn } from "../../../../../helpers/classHelper";
import { useModal } from "../../../../../hooks/useModal";
import { ExternalPlatformModal } from "./ExternalPlatformModal";

import WebIcon from "../../../../../assets/icons/web.svg?react";
import Plus from "../../../../../assets/icons/plus.svg?react";

import {
  RentalPlatformIcalendarResponse,
  RentalResponse,
} from "../../../../../types/GETTypes";

import {ExternalPlatformsCardProps} from "./ExternalPlatform.type";

export const ExternalPlatformsCard = ({ rentalId }: ExternalPlatformsCardProps) => {
  /** All hooks */
  const { t } = useTranslation();
  const { generateIcsFile } = useIcalendar();

  /** All modals */
  const externalPlatformRightModal = useModal();

  /** All states */
  const [rental, setRental] = useState<RentalResponse | null>(null);
  const [error, setError] = useState(null);
  const [externalsIcalendarConnections, setExternalsIcalendarConnection] = useState(null);
  const [platformClicked, setPlatformClicked] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * Function for get rental with rental ID,
   * and after, show externals connections of rentals.
   */
  const loadRental = async () => {
    try {
      const rental = await useGetRental(rentalId);
      setRental(rental);
      setExternalsIcalendarConnection(
        rental?.details.external_connexions?.platform_icalendar,
      );
    } catch (error) {
      setError(error?.message);
    }
  };

  /**
   * Run loadRental for load all informations externals_connections.
   */
  useEffect(() => {
    loadRental();
  }, []);

  /**
   * Function for set visible RightModal form to add or delete
   * connected platform external.
   */
  const handleClickConnectOtherPlatforms = () => {
    setLoading(true);
    setPlatformClicked(null);
    if (rental?.details.external_connexions?.superhote_icalendar_link === "") {
      generateIcsFile(rentalId).then((response) => {
        loadRental().then((response) => {
          externalPlatformRightModal.open();
        });
      });
    } else externalPlatformRightModal.open();
    setLoading(false);
  };

  /**
   * Execute this function and close modal form and refresh component
   * for show new state of this component.
   */
  const handleFinishFormModal = () => {
    externalPlatformRightModal.close();
    loadRental();
  };

  /**
   * Function for handle click action in externals platform and
   * open modal form for show informations OR disconnect calendar.
   * @param platform
   */
  const handleClickExternalPlatform = (
    platform: RentalPlatformIcalendarResponse,
  ) => {
    setPlatformClicked(platform);
    externalPlatformRightModal.open({
      platform: platform,
    });
  };

  /**
   * Vue React for show externals connections connected to
   * rentals.
   */
  return (
    <>
      <Separator />
      <p className="font-semibold text-low-contrast">
        {t("Rental.Infos.Platforms.otherSource")}
      </p>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      {/** Build all externals platforms components */}
      {rental ? (
        externalsIcalendarConnections?.length > 0 &&
        externalsIcalendarConnections.map(
          (externalConnection: RentalPlatformIcalendarResponse, index) => (
            <RentalPlatformsCardElement
              onClick={() => handleClickExternalPlatform(externalConnection)}
            >
              <div
                className={cn(
                  "rounded-full h-3 w-3",
                  externalConnection.enabled
                    ? "bg-success animate-pulse"
                    : "bg-[#E5484D]",
                )}
              />
              <WebIcon />
              <p className="font-semibold text-low-contrast">
                {externalConnection.connexion_name} -{" "}
                {t(
                  externalConnection.enabled
                    ? "Rental.Infos.Platforms.actif"
                    : "Rental.Infos.Platforms.inactif",
                )}
              </p>
            </RentalPlatformsCardElement>
          ),
        )
      ) : (
        <ExternalPlatformsSkeleton />
      )}

      {/** Modal form external platform for disconnect and connect action */}
      <ExternalPlatformModal
        icalendarSuperhoteLink={
          rental?.details?.external_connexions?.superhote_icalendar_link
        }
        modal={externalPlatformRightModal}
        rental={rental}
        isSynchronized={platformClicked !== null}
        platform={platformClicked}
        onFinish={handleFinishFormModal}
      />

      {/** Button for add others external platforms */}
      <Button
        loading={loading}
        RightIcon={Plus}
        type={"secondary"}
        onClick={handleClickConnectOtherPlatforms}
      >
        {t("Rental.Infos.Platforms.connectedOther")}
      </Button>
    </>
  );
};
