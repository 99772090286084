import React from "react";
import { CellSkeletonProps } from "./Calendar.type";
import moment from "moment";
import {
  CALENDAR_CELL_HEIGHT,
  CALENDAR_CELL_WIDTH,
} from "../../constants/calendar";

export const CalendarCellSkeleton: React.FC<CellSkeletonProps> = ({
  index,
  daysOffset,
}) => {
  const date = moment(new Date()).add(index - daysOffset, "days");
  const isToday =
    moment(new Date()).format("YYYY-MM-DD") === date.format("YYYY-MM-DD");

  const getBorder = () => {
    if (date.format("DD") === "01") {
      return "border-l-4 border-[#5C6163]/20";
    } else return "";
  };

  return (
    <td
      style={{
        minWidth: CALENDAR_CELL_WIDTH,
        height: CALENDAR_CELL_HEIGHT,
      }}
      className={`border-r-1 border-t-1 border-element-border cursor-pointer ${
        isToday ? "bg-[#7D8284]/25" : "bg-[#7D8284]/10"
      } ${getBorder()} animate-pulse`}
    >
      <div className="flex items-center gap-1">
        <div className="flex items-center ml-6 h-2 w-6 bg-slate-300"></div>
        <div className="flex items-center h-2 w-3 bg-slate-300"></div>
      </div>
    </td>
  );
};
