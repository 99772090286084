import React from "react";
import ChevronRightIcon from "../../../assets/icons/chevron-right.svg?react";
import { useTranslation } from "react-i18next";

export const RentalTitleSkeleton: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center space-x-1">
      <h1 className="m-0 font-bold text-lg text-low-contrast">
        {t("Rental.title")}
      </h1>

      <ChevronRightIcon />

      <div className="w-6 h-6 bg-slate-400 rounded-full object-cover animate-pulse" />
      <h1 className="pl-1 font-bold text-lg text-high-contrast animate-pulse">
        <p className="h-2 w-52 bg-slate-400 rounded"></p>
      </h1>
    </div>
  );
};
