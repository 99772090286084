import React, { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import BalanceSheetIcon from "../../../../assets/icons/balance-sheet.svg?react";
import { cn } from "../../../../helpers/classHelper";
import { lowerFirstLetter } from "../../../../helpers/stringHelper";
import { useUpdateFavoritePaymentPolicy } from "../../../../hooks/api/paymentPolicies";
import { SimpleRadio } from "../../../Common/SimpleRadio/SimpleRadio";
import {
  PaymentPolicyDepositPaymentOptionEnum,
  PaymentPolicyRefundConditionEnum,
  PaymentPolicyResponse,
  PaymentPolicyValueTypeEnum,
} from "../../../Payment/Payment.type";
import { RentalPaymentPolicyItemProps } from "./PaymentPolicy.type";

export const RentalPaymentPolicyItem: React.FC<
  RentalPaymentPolicyItemProps
> = ({
  index,
  item,
  rentalId,
  onClick,
  onUpdateFavoritePaymentPolicy,
  onUpdateFavoritePaymentPolicyError,
}) => {
  const { t } = useTranslation();

  const [apiError, setApiError] = useState<string | undefined>();
  const [validationLoading, setValidationLoading] = useState<boolean>(false);

  const getRefundLabel = (value: string | null) => {
    let label: string = "";
    if (
      value ===
      PaymentPolicyRefundConditionEnum.TWENTY_FOUR_HOURS_BEFORE_ARRIVAL
    ) {
      label = t("Payments.RefundConditions.TwentyFourHoursBeforeArrival");
    } else if (
      value === PaymentPolicyRefundConditionEnum.FIVE_DAYS_BEFORE_ARRIVAL
    ) {
      label = t("Payments.RefundConditions.FiveDaysBeforeArrival");
    } else if (
      value === PaymentPolicyRefundConditionEnum.SEVEN_DAYS_BEFORE_ARRIVAL
    ) {
      label = t("Payments.RefundConditions.SevenDaysBeforeArrival");
    } else if (
      value === PaymentPolicyRefundConditionEnum.FOURTEEN_DAYS_BEFORE_ARRIVAL
    ) {
      label = t("Payments.RefundConditions.FourteenDaysBeforeArrival");
    } else if (
      value === PaymentPolicyRefundConditionEnum.THIRTY_DAYS_BEFORE_ARRIVAL
    ) {
      label = t("Payments.RefundConditions.ThirtyDaysBeforeArrival");
    } else if (
      value === PaymentPolicyRefundConditionEnum.SIXTY_DAYS_BEFORE_ARRIVAL
    ) {
      label = t("Payments.RefundConditions.SixtyBeforeArrival");
    }

    return label;
  };

  const getDepositType = (value: string | null) => {
    let label: string = t("Payments.DepositPaymentOptions.preAuthorisation");
    if (value === PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION) {
      label = t("Payments.DepositPaymentOptions.preAuthorisation");
    } else if (
      value === PaymentPolicyDepositPaymentOptionEnum.CARD_PAYMENT_TO_REFUND
    ) {
      label = t("Payments.DepositPaymentOptions.cbRefund");
    } else if (
      value === PaymentPolicyDepositPaymentOptionEnum.BANK_TRANSFER_TO_REFUND
    ) {
      label = t("Payments.DepositPaymentOptions.sepaRefund");
    } else if (
      value ===
      PaymentPolicyDepositPaymentOptionEnum.BANK_CHECK_OR_CASH_AT_ARRIVAL
    ) {
      label = t("Payments.DepositPaymentOptions.checkOrCash");
    }

    return lowerFirstLetter(label);
  };

  const handleClickRadio = (e: SyntheticEvent) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    if (!Boolean(item.is_favorite_policy)) {
      useUpdateFavoritePaymentPolicy(
        rentalId,
        item.id,
        (
          paymentPolicyId: number | undefined,
          paymentPolicies: PaymentPolicyResponse[]
        ) => onUpdateFavoritePaymentPolicy(paymentPolicyId, paymentPolicies),
        (message: string | undefined) =>
          onUpdateFavoritePaymentPolicyError(message),
        () => {
          onUpdateFavoritePaymentPolicyError(undefined);
          setValidationLoading(true);
        },
        () => setValidationLoading(false)
      );
    }
  };

  return (
    <div
      className={cn(
        "flex items-center justify-between p-4 gap-x-3 border-1 rounded-6px bg-subtle border-element-border cursor-pointer"
      )}
      key={index}
      onClick={() => onClick(item)}
    >
      <div className="flex items-center space-x-2">
        <div className={cn(`flex flex-col`)}>
          <BalanceSheetIcon className="w-6 h-6" />
        </div>

        <div className="flex flex-col space-y-1">
          <div className="flex items-center mb-2 space-x-2">
            <SimpleRadio
              disabled={validationLoading}
              value={Boolean(item.is_favorite_policy)}
              label={
                item.is_favorite_policy
                  ? t(
                      "Rental.Pricing.PaymentPolicy.Item.selectedPaymentPolicyLabel"
                    )
                  : undefined
              }
              classNames={{
                radio: "w-5 h-5 pointer-events-none",
                contentRadio: "w-2 h-2",
                label: "text-active font-semibold tracking-wide",
              }}
              onClick={handleClickRadio}
            />

            {item.is_favorite_policy ? (
              <p className="font-semibold tracking-wide text-active"></p>
            ) : null}
          </div>

          <span className="text-base font-semibold tracking-normal me-1">
            {item.name}
          </span>

          <p className="tracking-normal text-low-contrast">
            {t("Rental.Pricing.PaymentPolicy.paymentScheduleInfo", {
              nb:
                item.payments_policy_items.length === 0
                  ? 1
                  : item.payments_policy_items.length,
              description:
                item.payments_policy_items.length !== 0
                  ? `(${item.payments_policy_items
                      .map((item) =>
                        item.value_type === PaymentPolicyValueTypeEnum.PERCENT
                          ? `${item.value}%`
                          : `${item.value}${t("Global.currencySymbol")}`
                      )
                      .join(", ")})`
                  : "",
            })}
          </p>
          <p className="tracking-normal text-low-contrast">
            {item.refund_value
              ? t("Rental.Pricing.PaymentPolicy.cancellationInfo", {
                  value: t("Payments.refundableLabel"),
                  percentage: item.refund_value ?? 0,
                  canceled_condition: getRefundLabel(item.refund_condition),
                })
              : t("Rental.Pricing.PaymentPolicy.cancellationInfo0Percent")}
          </p>
          <p className="tracking-normal text-low-contrast">
            {item.deposit_value
              ? t("Rental.Pricing.PaymentPolicy.depositInfo", {
                  value: `${item.deposit_value}${t("Global.currencySymbol")}`,
                  depositPaymentOption: getDepositType(
                    item.deposit_payment_option
                  ),
                })
              : t("Rental.Pricing.PaymentPolicy.depositInfoNone")}
          </p>
        </div>
      </div>
    </div>
  );
};
