import { useMemo } from "react";
import { ValueType } from "../types/commonTypes";

export const useUrlSearchParams = <T extends string>(
  initialParams?: Record<T, string>
) => {
  const params = useMemo(
    () => new URLSearchParams(initialParams ?? window.location.search),
    [initialParams]
  );

  const has = (key: T): boolean => {
    return params.has(key);
  };

  const get = (key: T): string | null => {
    return params.get(key);
  };

  const set = (key: T, value: ValueType): void => {
    params.set(key, value.toString());
    updateURL(); // Met à jour l'URL
  };

  const remove = (key: T): void => {
    params.delete(key);
    updateURL(); // Met à jour l'URL
  };

  const updateURL = () => {
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, "", newUrl);
  };

  const getAllParams = (): Record<T, string | null> => {
    return Object.fromEntries(
      Array.from(params.keys()).map((key) => [key, params.get(key as T)])
    ) as Record<T, string | null>;
  };

  return { has, get, set, remove, getAllParams };
};
