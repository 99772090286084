import { t } from "i18next";
import React, { useState } from "react";
import { useAutomation } from "../../hooks/api/automation";
import { UseModal } from "../../hooks/useModal";
import { ValueType } from "../../types/commonTypes";
import { AutomationItemResponse } from "../../types/GETTypes";
import { Button } from "../Common/Button/Button";
import { CenterModal } from "../Common/CenterModal/CenterModal";

type ManagementAutomationListRemoveModalFromUpdateProps = {
  modal: UseModal<ValueType>;
  onRemove: (automation: AutomationItemResponse) => void;
  onError: (message: string | null) => void;
};

export const ManagementAutomationListRemoveModalFromUpdate: React.FC<
  ManagementAutomationListRemoveModalFromUpdateProps
> = ({ modal, onRemove, onError }) => {
  const [validationLoading, setValidationLoading] = useState<boolean>(false);

  const { remove } = useAutomation({
    onSuccess: (automation) => {
      if (automation) {
        onRemove(automation as AutomationItemResponse);
        modal.close();
      }
    },
    onError: (message: string | null) => onError(message),
    onStart: () => {
      onError(null);
      setValidationLoading(true);
    },
    onEnd: () => {
      setValidationLoading(false);
    },
  });

  return (
    <CenterModal
      title={t("Automation.RemoveModal.title", {
        count: Array.isArray(modal.data) ? modal.data?.length : 1,
      })}
      isVisible={modal.isVisible}
      onClose={validationLoading ? () => {} : modal.close}
    >
      <div className="flex flex-col gap-y-6">
        <div>
          <p className="mb-4 text-base leading-relaxed tracking-wide text-low-contrast">
            {t("Automation.RemoveModal.description1", {
              count: Array.isArray(modal.data) ? modal.data?.length : 1,
            })}
          </p>
          <p className="text-base leading-relaxed tracking-wide text-low-contrast">
            {t("Automation.RemoveModal.description2")}
          </p>
        </div>

        <div className="flex justify-between space-x-2">
          <Button
            type="secondary"
            disabled={validationLoading}
            onClick={modal.close}
          >
            {t("Global.cancel")}
          </Button>
          <Button
            type="destructive"
            disabled={validationLoading}
            loading={validationLoading}
            onClick={() => remove(modal.data!, false)}
          >
            {t("Global.removeWithConfirmation")}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};
