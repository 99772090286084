import { t } from "i18next";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAutomation } from "../../hooks/api/automation";
import { UseModal } from "../../hooks/useModal";
import { ValueType } from "../../types/commonTypes";
import { AutomationItemResponse } from "../../types/GETTypes";
import { Button } from "../Common/Button/Button";
import { CenterModal } from "../Common/CenterModal/CenterModal";
import { SimpleRadio } from "../Common/SimpleRadio/SimpleRadio";

type ManagementAutomationListDeactivateModalProps = {
  modal: UseModal<ValueType | ValueType[]>;
  onDeactivate: (
    data: AutomationItemResponse | AutomationItemResponse[]
  ) => void;
  onError: (message: string | null) => void;
};

type ManagementAutomationListDeactivateModalForm = {
  removeAllTasks: boolean;
};

export const ManagementAutomationListDeactivateModal: React.FC<
  ManagementAutomationListDeactivateModalProps
> = ({ modal, onDeactivate, onError }) => {
  const [validationLoading, setValidationLoading] = useState<boolean>(false);

  const form = useForm<ManagementAutomationListDeactivateModalForm>({
    mode: "all",
    defaultValues: {
      removeAllTasks: true,
    },
  });

  const { disable, disableMultiple } = useAutomation({
    onSuccess: (data) => {
      if (data) {
        onDeactivate(data);
        modal.close();
      }
    },
    onError: (message: string | null) => onError(message),
    onStart: () => {
      onError(null);
      setValidationLoading(true);
    },
    onEnd: () => setValidationLoading(false),
  });

  form.watch();

  return (
    <CenterModal
      title={t("Automation.DeactivateModal.title", {
        count: Array.isArray(modal.data) ? modal.data.length : 1,
      })}
      isVisible={modal.isVisible}
      onClose={validationLoading ? () => {} : modal.close}
    >
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-4">
          <p className="text-base leading-relaxed tracking-wide text-low-contrast">
            {t("Automation.DeactivateModal.description1", {
              count: Array.isArray(modal.data) ? modal.data.length : 1,
            })}
          </p>
          <p className="text-base leading-relaxed tracking-wide text-low-contrast">
            {t("Automation.DeactivateModal.description2", {
              count: Array.isArray(modal.data) ? modal.data.length : 1,
            })}
          </p>

          <p className="text-base leading-relaxed tracking-wide text-low-contrast">
            {t("Automation.DeactivateModal.description3", {
              count: Array.isArray(modal.data) ? modal.data.length : 1,
            })}
          </p>
        </div>

        <Controller
          control={form.control}
          name="removeAllTasks"
          render={({ field: { onChange, value } }) => (
            <div className="flex flex-col gap-y-4">
              <SimpleRadio
                classNames={{
                  label: "font-bold text-sm",
                  description: "text-sm",
                }}
                label={t("Automation.DeactivateModal.RemoveOptions.option1")}
                description={t(
                  "Automation.DeactivateModal.RemoveOptions.option1Description"
                )}
                onClick={() => onChange(true)}
                value={value === true}
              />
              <SimpleRadio
                classNames={{
                  label: "font-bold text-sm",
                  description: "text-sm",
                }}
                label={t("Automation.DeactivateModal.RemoveOptions.option2")}
                onClick={() => onChange(false)}
                value={value === false}
              />
            </div>
          )}
        />

        <div className="flex justify-between space-x-2">
          <Button
            type="secondary"
            disabled={validationLoading}
            onClick={modal.close}
          >
            {t("Global.cancel")}
          </Button>
          <Button
            type="destructive"
            disabled={validationLoading}
            loading={validationLoading}
            onClick={() =>
              typeof modal.data === "string" || typeof modal.data === "number"
                ? disable(modal.data!, form.getValues("removeAllTasks"))
                : disableMultiple(modal.data!, form.getValues("removeAllTasks"))
            }
          >
            {t("Global.deactivateWithConfirmation")}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};
