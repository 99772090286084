import React, { useEffect, useState } from "react";
import {
  OperationalManagementTeamListItemResponse,
  OperationalManagementTeamMemberResponse,
} from "../../../types/GETTypes";
import { RightModal } from "../../Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { ValueType } from "../../../types/commonTypes";
import { TextInput } from "../../Common/TextInput/TextInput";
import { Button } from "../../Common/Button/Button";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import { InputMultiSelect } from "../../Common/InputMultiSelect/InputMultiSelect";
import { post } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";

type EditTeamFormSchema = {
  name: string;
  memberIds: ValueType[];
};

export const EditTeamModal: React.FC<{
  team: OperationalManagementTeamListItemResponse | undefined | null;
  members: OperationalManagementTeamMemberResponse[];
  isVisible: boolean;
  onClose: () => void;
  onRemoveTeam: (team: OperationalManagementTeamListItemResponse) => void;
  onSuccess: (newTeam: OperationalManagementTeamListItemResponse) => void;
}> = ({ team, members, isVisible, onClose, onRemoveTeam, onSuccess }) => {
  const { t } = useTranslation();

  const form = useForm<EditTeamFormSchema>({
    defaultValues: {
      name: "",
      memberIds: [],
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isVisible && team) {
      form.setValue("name", team.name);
      form.setValue(
        "memberIds",
        team.members.map((member) => member.id)
      );
    }
  }, [isVisible, team]);

  const handleClose = () => {
    if (loading) return;

    onClose();
  };

  const save = async (values: EditTeamFormSchema) => {
    setLoading(true);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.UPDATE_TEAM
      }/${team?.id}`,
      {
        name: values.name,
        user_ids: values.memberIds,
      }
    );

    if (res.data?.success) {
      onSuccess(res.data.result);
    } else {
      setError(res.response.data.message);
    }

    setLoading(false);
  };

  return (
    <RightModal title={team?.name} isVisible={isVisible} onClose={handleClose}>
      <form
        onSubmit={form.handleSubmit(save)}
        className="flex flex-col w-full flex-1"
      >
        <div className="w-full flex-1 flex flex-col gap-4">
          <p className="text-low-contrast font-semibold">
            {t("Management.EditTeam.informations")}
          </p>

          <div>
            <Controller
              control={form.control}
              name="name"
              rules={{
                required: {
                  value: true,
                  message: t("Management.EditTeam.nameError"),
                },
              }}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  label={t("Management.EditTeam.name")}
                  size="large"
                  required={true}
                  value={value}
                  error={form.formState.errors.name?.message}
                  disabled={loading}
                  onChangeText={onChange}
                />
              )}
            />
          </div>

          <div>
            <Controller
              control={form.control}
              name="memberIds"
              render={({ field: { value, onChange } }) => (
                <InputMultiSelect
                  label={t("Management.EditTeam.members")}
                  items={members?.map((member) => {
                    const label =
                      member.first_name !== "" && member.last_name !== ""
                        ? `${member.first_name} ${member.last_name}`
                        : `${member.email} (${t(
                            "Management.EditTeam.pending"
                          )})`;

                    return { label, value: member.id, imgSrc: member.photo };
                  })}
                  disabled={loading}
                  selectedValues={value}
                  onSelect={onChange}
                />
              )}
            />
          </div>

          <ErrorMessage errorType="API">{error}</ErrorMessage>
        </div>

        {/* Buttons */}
        <div className="pt-4 border-t-1 border-element-border flex gap-4">
          <Button type="secondary" disabled={loading} onClick={handleClose}>
            {t("Global.cancel")}
          </Button>
          <Button
            type="alert"
            disabled={loading}
            onClick={() => onRemoveTeam(team!)}
          >
            {t("Global.remove")}
          </Button>
          <Button RightIcon={CheckWhiteIcon} loading={loading}>
            {t("Global.save")}
          </Button>
        </div>
      </form>
    </RightModal>
  );
};
