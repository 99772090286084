import React from "react";
import {useTranslation} from "react-i18next";

import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import EmojiHappyIcon from "../../assets/icons/emoji-happy.svg?react";
import MoonIcon from "../../assets/icons/moon.svg?react";
import UsersIcon from "../../assets/icons/users.svg?react";
import {getPlatformIcon} from "../../helpers/platformHelper";

export const CalendarListItemSkeleton: React.FC<{}> = () => {
  const {t} = useTranslation();

  return (
    <div className="flex flex-col mt-4 animate-pulse">
      <div className="font-semibold text-high-contrast bg-element-background-light rounded-t-6px p-2 text-[12px] border-1  border-element-border">
        <p className="h-2 rounded w-72 bg-slate-300"></p>
      </div>

      <div className="p-2 font-semibold border-t-0 bg-element-background text-low-contrast border-1 border-element-border last:rounded-b-6px">
        {t("Global.checkout", {count: 2}).toUpperCase()}
      </div>

      {/* Checkouts */}
      {Array.from({length: 2}).map((v, index) => (
        <CalendarListItemReservationSkeleton key={index} />
      ))}

      <div className="p-2 font-semibold border-t-0 bg-element-background text-low-contrast border-1 border-element-border last:rounded-b-6px">
        {t("Global.checkin", {count: 2}).toUpperCase()}
      </div>

      {/* Checkin */}
      {Array.from({length: 2}).map((v, index) => (
        <CalendarListItemReservationSkeleton key={index} />
      ))}
    </div>
  );
};

export const CalendarListItemReservationSkeleton: React.FC<{}> = () => {
  return (
    <div className="flex items-center p-2 border-t-0 border-1 border-element-border last:rounded-b-6px hover:cursor-pointer hover:bg-element-background/30">
      {/* Label */}
      <div className="flex items-center w-32 gap-1">
        <ArrowRightIcon className="w-6 h-6" />
        <p className="w-16 h-2 font-semibold rounded text-high-contrast bg-slate-300"></p>
      </div>

      {/* Guest */}
      <div className="flex items-center flex-1 gap-2 p-2">
        <div className="w-8 h-8 rounded-full bg-slate-200 border-1 border-element-border" />

        <div className="flex flex-col">
          <div className="flex items-center gap-2 font-semibold text-high-contrast">
            <p className="w-32 h-2 rounded bg-slate-300" />

            <MoonIcon className="w-4 h-4" />
            <p className="w-8 h-2 rounded bg-slate-300" />
          </div>

          <div className="flex items-center gap-1 font-light text-low-contrast">
            <UsersIcon className="w-4 h-4" />
            <p className="w-8 h-2 rounded bg-slate-300"></p>
            <EmojiHappyIcon className="w-4 h-4" />
            <p className="w-8 h-2 rounded bg-slate-300"></p>
          </div>
        </div>
      </div>

      {/* Rental */}
      <div className="flex items-center flex-1 gap-4 p-2">
        <div className="relative ">
          <div className="w-8 h-8 rounded-4px border-1 border-element-border bg-slate-200 " />

          <div className="absolute" style={{top: 4, left: 18}}>
            {getPlatformIcon(null)}
          </div>
        </div>

        <div className="flex flex-col">
          <div className="flex items-center gap-1 font-semibold text-high-contrast">
            <p className="h-2 rounded w-44 bg-slate-300"></p>
          </div>

          <div className="flex items-center gap-1 font-light text-low-contrast">
            <ArrowRightIcon className="w-4 h-4" />
            <p className="w-8 h-2 rounded bg-slate-300" />
          </div>
        </div>
      </div>

      {/* Assign */}
      <div className="flex items-center gap-2 p-2 w-52">
        <div className="relative">
          <div className="object-cover w-8 h-8 rounded-full bg-slate-300" />
        </div>

        <p className="w-16 h-2 rounded bg-slate-200"></p>
      </div>

      {/* Button */}
      <div className="">
        <div className="w-40 h-8 rounded-6px border-element-border bg-slate-200" />
      </div>
    </div>
  );
};
