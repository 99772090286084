import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CheckWhiteIcon from "../../../../assets/icons/check-white.svg?react";
import InformationActiveIcon from "../../../../assets/icons/information-active.svg?react";
import { usePricelabs } from "../../../../hooks/api/pricelabs";
import { ValueType } from "../../../../types/commonTypes";
import { Button } from "../../../Common/Button/Button";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { RightModal } from "../../../Common/RightModal/RightModal";
import { TextInput } from "../../../Common/TextInput/TextInput";
import {
  PricelabsConnectionForm,
  RentalPricelabsResponse,
} from "./Pricelabs.type";

export const PricelabsConnectionModal: React.FC<{
  rentalId: ValueType;
  isVisible: boolean;
  onSuccessConnect: (pricelabsResponse: RentalPricelabsResponse) => void;
  onClose: () => void;
}> = ({ rentalId, isVisible, onSuccessConnect, onClose }) => {
  const { t } = useTranslation();

  const form = useForm<PricelabsConnectionForm>({
    defaultValues: {
      email: "",
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { connect } = usePricelabs({
    rentalId: rentalId,
    onSuccess: (pricelabsResponse) => {
      onSuccessConnect(pricelabsResponse!);
      handleClose();
    },
    onError: (message: string | null) => {
      setError(message);
    },
    onStart: () => {
      setLoading(true);
      setError(null);
    },
    onEnd: () => {
      setLoading(false);
    },
  });

  const handleValid = (values: PricelabsConnectionForm) => {
    connect(values);
  };

  const handleClose = () => {
    if (loading) return;
    form.reset();
    setError(null);
    onClose();
  };

  return (
    <RightModal
      title={t("Rental.Services.Pricelabs.ConnectionModal.title")}
      isVisible={isVisible}
      onClose={onClose}
      classNames={{
        mainContentParent: "overflow-y-auto",
      }}
    >
      <form
        onSubmit={form.handleSubmit(handleValid)}
        className="flex-1 w-full h-full"
      >
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col flex-1 w-full gap-3 pb-4 overflow-y-auto">
            <p className="text-low-contrast">
              <Trans
                i18nKey="Rental.Services.Pricelabs.ConnectionModal.text"
                components={{
                  strong: <strong />,
                }}
              />
            </p>

            <div>
              <Controller
                control={form.control}
                name="email"
                rules={{
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: t(
                      "Rental.Services.Pricelabs.ConnectionModal.emailInvalid"
                    ),
                  },
                  required: {
                    value: true,
                    message: t(
                      "Rental.Services.Pricelabs.ConnectionModal.emailRequired"
                    ),
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    label={t(
                      "Rental.Services.Pricelabs.ConnectionModal.emailLabel"
                    )}
                    required={true}
                    placeholder={t(
                      "Rental.Services.Pricelabs.ConnectionModal.emailPlaceholder"
                    )}
                    disabled={loading}
                    value={value}
                    error={form.formState.errors.email?.message}
                    onChangeText={onChange}
                  />
                )}
              />
            </div>

            <Link
              to={
                "https://help.pricelabs.co/portal/fr/kb/articles/connecting-pricelabs-to-superhote"
              }
              className="text-active"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Rental.Services.Pricelabs.ConnectionModal.tutorialTitle")}
            </Link>

            <div className="flex flex-row w-full gap-2 p-2 mb-4 bg-element-background rounded-4px h-fit">
              <div className="">
                <InformationActiveIcon className="w-5 h-5" />
              </div>

              <div className="flex-1">
                <p className="text-low-contrast">
                  <Trans
                    i18nKey="Rental.Services.Pricelabs.ConnectionModal.info1"
                    components={{
                      strong: <strong />,
                    }}
                  />
                </p>
                <p className="mt-3 text-low-contrast">
                  <Trans
                    i18nKey="Rental.Services.Pricelabs.ConnectionModal.info2"
                    components={{
                      strong: <strong />,
                    }}
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col pt-4 gap-y-3 border-t-1 border-element-border">
            <ErrorMessage>{error}</ErrorMessage>
            <div className="flex gap-x-3">
              <Button type="secondary" disabled={loading} onClick={handleClose}>
                {t("Global.cancel")}
              </Button>

              <Button RightIcon={CheckWhiteIcon} loading={loading}>
                {t("Global.synchronize")}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </RightModal>
  );
};
