import React from "react";
import {RecoverProps, RevoverFormType} from "./Recover.type";
import {Title} from "../../Common/Title/Title";
import {useTranslation} from "react-i18next";
import {TextInput} from "../../Common/TextInput/TextInput";
import {Button} from "../../Common/Button/Button";
import {useForm} from "react-hook-form";
import ArrowRightIcon from "../../../assets/icons/arrow-right-white.svg?react";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import {Keys} from "../../../values";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import {useNavigate} from "react-router-dom";

export const Recover: React.FC<RecoverProps> = ({error, loading, onSubmit}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    formState: {errors},
  } = useForm<RevoverFormType>();

  const handleSave = () => {
    if (typeof onSubmit === "function") {
      onSubmit({email: getValues().email});
    }
  };

  const onBack = () => {
    navigate("/login");
  };

  return (
    <div>
      <div className="flex justify-start pb-12">
        <div className="max-w-48">
          <Button
            type="secondary"
            disabled={loading}
            LeftIcon={ArrowLeftIcon}
            onClick={onBack}
          >
            {t("Global.back")}
          </Button>
        </div>
      </div>

      <Title>{t("Recover.title")}</Title>

      <div className="mt-4">
        <Title type="secondary">{t("Recover.subTitle")}</Title>
      </div>

      <div className="mt-8">
        <TextInput
          register={register("email", {
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: t("Recover.emailInvalid"),
            },
            required: {
              value: true,
              message: t("Recover.emailRequired"),
            },
          })}
          disabled={loading}
          label={t("Recover.email")}
          placeholder="jane.doe@mail.com"
          error={errors.email?.message}
        />
      </div>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      <div className="flex mt-4">
        <Button
          RightIcon={ArrowRightIcon}
          loading={loading}
          keyToSubmit={Keys.Enter}
          onClick={handleSubmit(handleSave)}
          displayLargeBtn={false}
        >
          {t("Recover.submit")}
        </Button>
      </div>
    </div>
  );
};
