import React from "react";
import { ListingImportFinishModalProps } from "./Progression.type";
import { useTranslation } from "react-i18next";
import { CenterModal } from "../Common/CenterModal/CenterModal";
import { Button } from "../Common/Button/Button";

export const ListingImportFinishModal: React.FC<
  ListingImportFinishModalProps
> = ({ isVisible, onClose }) => {
  const { t } = useTranslation();

  return (
    <CenterModal isVisible={isVisible} onClose={onClose} header="success">
      <p className="text-high-contrast font-semibold">
        {t("Progression.Airbnb.Sync.successTitle")}
      </p>

      <p className="text-low-contrast font-light mt-2 text-sm">
        {t("Progression.Airbnb.Sync.successContent")}
      </p>

      <div className="mt-4">
        <Button onClick={onClose}>{t("Global.ok")}</Button>
      </div>
    </CenterModal>
  );
};
