import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FrenchFlag from "../../../assets/flags/France.svg?react";
import SendWhiteIcon from "../../../assets/icons/send-white.svg?react";
import paths from "../../../constants/paths";
import { post } from "../../../helpers/APIHelper";
import { getPaymentMessageByPaymentSchedule } from "../../../helpers/messageHelper";
import { ReservationResponse } from "../../../types/GETTypes";
import { AddReservationType } from "../../AddReservation/AddReservation.type";
import { Button } from "../../Common/Button/Button";
import { CenterModal } from "../../Common/CenterModal/CenterModal";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../../Common/InputSelect/InputSelect";
import { SimpleRadio } from "../../Common/SimpleRadio/SimpleRadio";
import { TextAreaInput } from "../../Common/TextAreaInput/TextAreaInput";
import { TextInput } from "../../Common/TextInput/TextInput";

type formSchema = {
  type: "email" | "sms";
  language: string;
  email: string;
  subject: string;
  message: string;
};

export const ReservationPaymentAndDepositLinksModal: React.FC<{
  reservation: AddReservationType | ReservationResponse;
  data: { index: number; isDeposit: boolean } | null | undefined;
  isVisible: boolean;
  onClose: () => void;
  onSuccess: (
    data: { index: number; isDeposit: boolean } | null | undefined
  ) => void;
}> = ({ reservation, data, isVisible, onClose, onSuccess }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [mode, setMode] = useState<"add" | "detail">("add");

  const form = useForm<formSchema>({
    defaultValues: {
      type: "email",
      language: "fr",
      email: reservation?.guest?.email,
      subject: "",
      message: "",
    },
  });

  useEffect(() => {
    if ("tarification" in reservation) {
      setMode("add");
    } else {
      setMode("detail");
    }
  }, [reservation]);

  useEffect(() => {
    if (isVisible && reservation && reservation?.payment_schedule) {
      const paymentMessage = getPaymentMessageByPaymentSchedule(
        reservation?.payment_schedule,
        data?.index ?? 0,
        data?.isDeposit ?? false
      );

      console.log(paymentMessage, 'paymentMessage content')

      form.setValue("subject", paymentMessage.subject);
      form.setValue("message", paymentMessage.message);
    }
  }, [isVisible, reservation]);

  const getTitle = () => {
    if (data?.isDeposit) return t("AddReservation.Done.Send.titleDeposit");
    else return t("AddReservation.Done.Send.titlePayment");
  };

  const handleSend = async (values: formSchema) => {
    setLoading(true);
    setError(null);

    if (data?.isDeposit) await sendDeposit(values);
    else await sendPayment(values);

    setLoading(false);
  };

  const sendDeposit = async (values: formSchema) => {
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.SEND_DEPOSIT}`,
      {
        reservation_id:
          mode === "add"
            ? (reservation as AddReservationType).reservation_id?.toString()
            : (reservation as ReservationResponse).id.toString(),
        lang: "FR",
        email: values.email,
        subject: values.subject,
        message: values.message,
        payment_link: `${location.protocol}//${location.host}/reservation/${
          mode === "add"
            ? (reservation as AddReservationType).reservation_id
            : (reservation as ReservationResponse).id
        }/deposit/${reservation.payment_schedule?.deposit_payment_link}`,
      }
    );

    if (res?.data?.success) {
      onSuccess(data);
    } else {
      setError(res?.response?.data?.message);
    }
  };

  const sendPayment = async (values: formSchema) => {
    const index = data?.index ?? 0;
    const payment =
      reservation?.payment_schedule?.payments_schedule_items[index];

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.SEND_PAYMENT}`,
      {
        reservation_id:
          mode === "add"
            ? (reservation as AddReservationType).reservation_id?.toString()
            : (reservation as ReservationResponse).id.toString(),
        lang: "FR",
        email: values.email,
        subject: values.subject,
        message: values.message,
        payment_link: `${location.protocol}//${location.host}/reservation/${
          mode === "add"
            ? (reservation as AddReservationType).reservation_id
            : (reservation as ReservationResponse)
        }/payment/${payment?.payment_link}`,
      }
    );

    if (res?.data?.success) {
      onSuccess(data);
    } else {
      setError(res?.response?.data?.message);
    }
  };

  return (
    <CenterModal isVisible={isVisible} onClose={onClose} title={getTitle()}>
      <form onSubmit={form.handleSubmit(handleSend)}>
        <div className="flex flex-col text-sm">
          <p className="mb-1 font-semibold text-high-contrast">
            {t("AddReservation.Done.Send.type")}
          </p>

          <Controller
            control={form.control}
            name="type"
            render={({ field: { value, onChange } }) => (
              <div className="flex gap-1">
                <SimpleRadio
                  value={value === "email"}
                  onClick={() => onChange("email")}
                />
                <p
                  className="mr-4 font-semibold cursor-pointer text-low-contrast"
                  onClick={() => onChange("email")}
                >
                  {t("Global.email")}
                </p>
                <SimpleRadio disabled={true} value={value === "sms"} />{" "}
                <p className="font-semibold cursor-not-allowed text-low-contrast">
                  {t("Global.SMS")}
                </p>
              </div>
            )}
          />

          <div className="mt-4">
            <Controller
              control={form.control}
              name="language"
              render={({ field: { value, onChange } }) => (
                <InputSelect
                  label={t("AddReservation.Done.Send.language")}
                  items={[{ Icon: FrenchFlag, value: "fr", label: "Français" }]}
                  selectedValue={value}
                  onSelect={onChange}
                  disabled={true}
                />
              )}
            />
          </div>

          <div className="mt-4">
            <Controller
              control={form.control}
              name="email"
              rules={{
                required: {
                  value: true,
                  message: t("AddReservation.Done.Send.emailRequired"),
                },
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: t("AddReservation.Done.Send.emailFormat"),
                },
              }}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  label={t("AddReservation.Done.Send.email")}
                  value={value}
                  error={form.formState.errors.email?.message}
                  disabled={loading}
                  onChangeText={onChange}
                />
              )}
            />
          </div>

          <div className="mt-4">
            <Controller
              control={form.control}
              name="subject"
              rules={{
                required: {
                  value: true,
                  message: t("AddReservation.Done.Send.subjectRequired"),
                },
              }}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  label={t("AddReservation.Done.Send.subject")}
                  value={value}
                  error={form.formState.errors.subject?.message}
                  disabled={loading}
                  onChangeText={onChange}
                />
              )}
            />
          </div>

          <div className="mt-4">
            <Controller
              control={form.control}
              name="message"
              rules={{
                required: {
                  value: true,
                  message: t("AddReservation.Done.Send.messageRequired"),
                },
              }}
              render={({ field: { value, onChange } }) => (
                <TextAreaInput
                  label={t("AddReservation.Done.Send.message")}
                  value={value}
                  error={form.formState.errors.message?.message}
                  disabled={loading}
                  onTextChange={onChange}
                />
              )}
            />
          </div>

          <ErrorMessage>{error}</ErrorMessage>

          <div className="flex gap-3 mt-8">
            <Button type="secondary" onClick={onClose} disabled={loading}>
              {t("Global.cancel")}
            </Button>
            <Button RightIcon={SendWhiteIcon} loading={loading}>
              {t("AddReservation.Done.Send.button")}
            </Button>
          </div>
        </div>
      </form>
    </CenterModal>
  );
};
