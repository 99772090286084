import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import CheckIcon from "../../../../assets/icons/check.svg?react";
import { useUpdateLock } from "../../../../hooks/api/locks";
import { useRentalPageContext } from "../../../../pages/RentalPage/RentalPageContext";
import { RequiredFields } from "../../../../types/commonTypes";
import { SmartlocksUpdateResponse } from "../../../../types/GETTypes";
import { Button } from "../../../Common/Button/Button";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import { RightModal } from "../../../Common/RightModal/RightModal";
import { Separator } from "../../../Common/Separator/Separator";
import { TextInput } from "../../../Common/TextInput/TextInput";
import {
  SmartlocksManualUpdateModalForm,
  SmartlocksUpdateModalForm,
  SmartlocksUpdatePayload,
} from "./Smartlocks.type";
import { useSmartlocksContext } from "./SmartlocksContext";
import { rentalSmartlocksUpdateModalSkeleton } from "./SmartlocksSkeleton";

export const RentalSmartlocksUpdateModal: React.FC = () => {
  const { rentalId } = useParams();

  const { rental } = useRentalPageContext();

  const {
    t,
    loadingState: [loading],
    validationLoading,
    apiError,
    LockItem,
    timeList,
    loginForm,
    lockIdItems,
    currentLock,
    isUpdateModalVisible,
    onCloseUpdateModal,
    onUpdateLock,
    onOpenDeleteModal,
    updateLockDynamicField,
    LockDynamicFieldInfo,
  } = useSmartlocksContext();

  const { onStart, onSuccess, onError, onEnd } = onUpdateLock();

  const {
    register: manualRegister,
    setValue: manualSetValue,
    getValues: manualGetValues,
    formState: { errors: manualErrors, isValid: manualIsValid },
    handleSubmit: manualHandleSubmit,
    watch: manualWatch,
  } = useForm<SmartlocksManualUpdateModalForm>({
    mode: "all",
    defaultValues: {
      name: "",
      code: "",
    },
  });

  const manualRequiredFields: RequiredFields<SmartlocksManualUpdateModalForm> =
    {
      name: true,
      code: true,
    };

  const {
    control,
    register,
    getValues,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
    watch,
  } = useForm<SmartlocksUpdateModalForm>({
    mode: "all",
    defaultValues: {
      deviceId: "",
      name: "",
      checkin: "",
      checkout: "",
    },
  });

  const requiredFields: RequiredFields<SmartlocksUpdateModalForm> = {
    deviceId: true,
    name: true,
    checkin: true,
    checkout: true,
  };

  useEffect(() => {
    if (rental) {
      setValue("checkin", rental.details.informations.checkin_max_time);
      setValue("checkout", rental.details.informations.checkout_max_time);
    }
  }, [rental]);

  useEffect(() => {
    if (currentLock) {
      if (currentLock.provider === "static") {
        manualSetValue("name", currentLock.name);
        manualSetValue("code", currentLock.static_code ?? "");
      } else {
        setValue("deviceId", currentLock.device_id);
        setValue("name", currentLock.name);
      }
    }
  }, [currentLock]);

  const handleUpdate = () => {
    let data: SmartlocksUpdatePayload | undefined = undefined;

    if (currentLock) {
      if (currentLock.provider === "static") {
        // * TODO: [SmartLocks] Implémenter le payload pour l'update côté code d'accès
        data = {
          lock_rental_id: currentLock.id,
          name: manualGetValues("name"),
          code: manualGetValues("code"),
        };
      } else {
        data = {
          lock_rental_id: currentLock.id,
          name: getValues("name"),
        };
      }
    }

    if (data) {
      useUpdateLock(
        rentalId,
        data,
        (smartlocksUpdateResponse: SmartlocksUpdateResponse) =>
          onSuccess(smartlocksUpdateResponse),
        (message: string | undefined) => onError(message),
        () => onStart(),
        () => onEnd()
      );
    }
  };

  manualWatch();
  watch();

  if (loading || currentLock === undefined)
    return rentalSmartlocksUpdateModalSkeleton();

  return (
    <RightModal
      title={
        currentLock.provider === "static"
          ? t("Rental.Services.Smartlocks.UpdateModal.manualTitle")
          : t("Rental.Services.Smartlocks.UpdateModal.title")
      }
      isVisible={isUpdateModalVisible}
      onClose={onCloseUpdateModal}
    >
      <div className="flex flex-col h-full">
        <div className="flex flex-col justify-between flex-1 w-full overflow-y-auto">
          <ErrorMessage>{apiError}</ErrorMessage>

          <div className="flex flex-col space-y-4">
            {/* LOCK CARD */}
            <LockItem mode="update" lock={currentLock} rental={rental} />

            <p className="font-bold text-low-contrast">
              {t("Rental.Services.Smartlocks.UpdateModal.informationsSubtitle")}
            </p>

            {currentLock.provider === "static" ? (
              <>
                <TextInput
                  register={manualRegister("name", {
                    required: {
                      value: manualRequiredFields.name,
                      message: t("Global.Errors.requiredField", {
                        fieldName: t(
                          "Rental.Services.Smartlocks.UpdateModal.lockNameLabel"
                        ),
                      }),
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9\s]*$/g,
                      message: t(
                        "Rental.Services.Smartlocks.UpdateModal.lockNameError"
                      ).toString(),
                    },
                    onChange: (e: any) =>
                      updateLockDynamicField(e.target.value),
                  })}
                  label={t(
                    "Rental.Services.Smartlocks.UpdateModal.lockNameLabel"
                  )}
                  placeholder={t(
                    "Rental.Services.Smartlocks.UpdateModal.lockNamePlaceholder"
                  )}
                  required={manualRequiredFields.name}
                  value={manualGetValues("name")}
                  error={manualErrors.name?.message}
                />

                {!manualErrors.name ? (
                  <LockDynamicFieldInfo type="created" />
                ) : null}

                <TextInput
                  register={manualRegister("code", {
                    required: {
                      value: manualRequiredFields.code,
                      message: t("Global.Errors.requiredField", {
                        fieldName: t(
                          "Rental.Services.Smartlocks.UpdateModal.lockCodeLabel"
                        ),
                      }),
                    },
                  })}
                  label={t(
                    "Rental.Services.Smartlocks.UpdateModal.lockCodeLabel"
                  )}
                  placeholder={t(
                    "Rental.Services.Smartlocks.UpdateModal.lockCodePlaceholder"
                  )}
                  required={manualRequiredFields.code}
                  value={manualGetValues("code")}
                  error={manualErrors.code?.message}
                />
              </>
            ) : (
              <>
                <Controller
                  control={control}
                  name="deviceId"
                  rules={{
                    required: requiredFields.deviceId,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <InputSelect
                        required={requiredFields.deviceId}
                        label={`${t(
                          "Rental.Services.Smartlocks.UpdateModal.deviceIdLabel"
                        )}`}
                        items={lockIdItems ?? []}
                        selectedValue={value ?? ""}
                        onSelect={onChange}
                        disabled={validationLoading}
                        error={errors.deviceId?.message}
                      />
                    );
                  }}
                />

                <TextInput
                  register={register("name", {
                    required: {
                      value: requiredFields.name,
                      message: t("Global.Errors.requiredField", {
                        fieldName: t(
                          "Rental.Services.Smartlocks.UpdateModal.lockNameLabel"
                        ),
                      }),
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9\s]*$/g,
                      message: t(
                        "Rental.Services.Smartlocks.UpdateModal.lockNameError"
                      ).toString(),
                    },
                    onChange: (e: any) =>
                      updateLockDynamicField(e.target.value),
                  })}
                  label={t(
                    "Rental.Services.Smartlocks.UpdateModal.lockNameLabel"
                  )}
                  placeholder={t(
                    "Rental.Services.Smartlocks.UpdateModal.lockNamePlaceholder"
                  )}
                  required={requiredFields.name}
                  value={getValues("name")}
                  error={errors.name?.message}
                />

                {!errors.name ? <LockDynamicFieldInfo type="created" /> : null}

                <div className="flex space-x-4">
                  <div className="flex-1">
                    <Controller
                      control={control}
                      name="checkin"
                      rules={{
                        required: requiredFields.checkin,
                      }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputSelect
                            required={requiredFields.checkin}
                            label={`${t(
                              "Rental.Services.Smartlocks.UpdateModal.lockCheckinTimeLabel"
                            )}`}
                            items={timeList}
                            selectedValue={value ?? ""}
                            onSelect={onChange}
                            disabled={true}
                            error={errors.checkin?.message}
                          />
                        );
                      }}
                    />
                  </div>

                  <div className="flex-1">
                    <Controller
                      control={control}
                      name="checkout"
                      rules={{
                        required: requiredFields.checkout,
                      }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputSelect
                            required={requiredFields.checkout}
                            label={`${t(
                              "Rental.Services.Smartlocks.UpdateModal.lockCheckoutTimeLabel"
                            )}`}
                            items={timeList}
                            selectedValue={value ?? ""}
                            onSelect={onChange}
                            disabled={true}
                            error={errors.checkout?.message}
                          />
                        );
                      }}
                    />
                  </div>
                </div>

                <p className="font-light text-low-contrast">
                  {t(
                    "Rental.Services.Smartlocks.UpdateModal.smartlockDescription"
                  )}
                </p>
                <p className="font-light text-low-contrast">
                  {t(
                    "Rental.Services.Smartlocks.UpdateModal.smartlockDescription2"
                  )}
                </p>
              </>
            )}
          </div>
        </div>

        <Separator
          classNames={{
            divParent: "m-0 py-4",
          }}
        />

        <div className="flex space-x-4">
          <Button type="secondary" onClick={onCloseUpdateModal}>
            {t("Global.cancel")}
          </Button>
          <Button type="destructive" onClick={onOpenDeleteModal}>
            {currentLock.provider === "static"
              ? t("Global.remove")
              : t("Global.disconnect")}
          </Button>
          <Button
            type="primary"
            RightIcon={CheckIcon}
            loading={validationLoading}
            disabled={
              validationLoading ||
              (currentLock.provider === "static" ? !manualIsValid : !isValid)
            }
            onClick={
              currentLock.provider === "static"
                ? manualHandleSubmit(handleUpdate)
                : handleSubmit(handleUpdate)
            }
          >
            {t("Global.record")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
