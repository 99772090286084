import React, { useCallback, useEffect, useState } from "react";
import { ManagementLayout } from "../../components/Layout/ManagementLayout/ManagementLayout";
import { useTranslation } from "react-i18next";
import { TableList } from "../../components/Common/TableList/TableList";
import { useTablePage } from "../../hooks/useTablePage";
import paths from "../../constants/paths";
import { TextInput } from "../../components/Common/TextInput/TextInput";
import SearchIcon from "../../assets/icons/search.svg?react";
import { ManagementRoleTableHeader } from "../../components/Management/ManagementRoleList/ManagementRoleTableHeader";
import { ManagementRoleTableNoItem } from "../../components/Management/ManagementRoleList/ManagementRoleTableNoItem";
import { ManagementRoleTableItem } from "../../components/Management/ManagementRoleList/ManagementRoleTableItem";
import { ManagementRoleTableSkeleton } from "../../components/Management/ManagementRoleList/ManagementRoleTableSkeleton";
import { Button } from "../../components/Common/Button/Button";
import PlusWhiteIcon from "../../assets/icons/plus-white.svg?react";
import { useModal } from "../../hooks/useModal";
import {
  OperationalManagementRoleListItemResponse,
  OperationalManagementRolePermissionResponse,
  OperationalManagementTeamMemberResponse,
} from "../../types/GETTypes";
import { RemoveRoleModal } from "../../components/Management/RemoveRole/RemoveRoleModal";
import { ValueType } from "../../types/commonTypes";
import { AddRoleModal } from "../../components/Management/AddRole/AddRoleModal";
import { get } from "../../helpers/APIHelper";
import { EditRoleModal } from "../../components/Management/EditRole/EditRoleModal";

export const ManagementRoleListPage = () => {
  const { t } = useTranslation();

  const removeRoleModal = useModal<OperationalManagementRoleListItemResponse>();
  const addRoleModal = useModal();
  const editRoleModal = useModal<OperationalManagementRoleListItemResponse>();

  const [members, setMembers] = useState<
    OperationalManagementTeamMemberResponse[]
  >([]);
  const [errorMembers, setErrorMembers] = useState<string | null>(null);

  const [permissions, setPermissions] = useState<
    OperationalManagementRolePermissionResponse[]
  >([]);
  const [errorPermissions, setErrorPermissions] = useState<string | null>(null);

  // Use the real API URL
  const rolesTable = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.OPERATIONNAL_MANAGEMENT.ROLES}`,
    "postes"
  );

  const fetchMembers = useCallback(async () => {
    let URL = `${import.meta.env.VITE_API_URL}${
      paths.API.OPERATIONNAL_MANAGEMENT.MEMBERS
    }`;

    const res = await get(URL);

    if (res.data?.success) {
      setMembers(res.data.result.members);
    } else {
      setErrorMembers(res.response.data.message);
    }
  }, []);

  const fetchPemissions = useCallback(async () => {
    let URL = `${import.meta.env.VITE_API_URL}${
      paths.API.OPERATIONNAL_MANAGEMENT.PERMISSIONS
    }`;

    const res = await get(URL);

    if (res.data?.success) {
      setPermissions(res.data.result.all_access);
    } else {
      setErrorMembers(res.response.data.message);
    }
  }, []);

  useEffect(() => {
    rolesTable.fetch({});
    fetchMembers();
    fetchPemissions();
  }, []);

  const handleSearch = (text: string) => {
    rolesTable.handleChangeSearch(text);
  };

  const handleRemove = (role: OperationalManagementRoleListItemResponse) => {
    removeRoleModal.open(role);
  };

  const handleClickItem = (value: ValueType, action: ValueType) => {
    const roleIndex = rolesTable.data?.findIndex(
      (r: OperationalManagementRoleListItemResponse) => r.id === value
    );
    if (roleIndex === -1) return;

    const role = rolesTable.data[roleIndex];

    if (action === "remove") {
      handleRemove(role);
    } else if (action === "edit") {
      editRoleModal.open(role);
    }
  };

  const handleSuccess = () => {
    removeRoleModal.close();
    addRoleModal.close();
    editRoleModal.close();

    window.location.reload();
  };

  return (
    <>
      <AddRoleModal
        permissions={permissions}
        isVisible={addRoleModal.isVisible}
        onClose={addRoleModal.close}
        onSuccess={handleSuccess}
      />

      <EditRoleModal
        permissions={permissions}
        role={editRoleModal.data}
        isVisible={editRoleModal.isVisible}
        onClose={editRoleModal.close}
        onSuccess={handleSuccess}
        onRemove={handleRemove}
      />

      <RemoveRoleModal
        isVisible={removeRoleModal.isVisible}
        data={removeRoleModal.data}
        onClose={removeRoleModal.close}
        onSuccess={handleSuccess}
      />

      <ManagementLayout subTitle="roles" sidebarActiveSubItem="teams">
        <div className="flex flex-row gap-4 items-center mt-4">
          <TextInput
            Icon={SearchIcon}
            size="large"
            placeholder={t("Management.searchRole")}
            onChangeText={handleSearch}
          />

          <div>
            <Button RightIcon={PlusWhiteIcon} onClick={addRoleModal.open}>
              {t("Management.RoleList.addRole")}
            </Button>
          </div>
        </div>

        <TableList
          Header={ManagementRoleTableHeader}
          Item={ManagementRoleTableItem}
          NoData={<ManagementRoleTableNoItem />}
          Skeleton={ManagementRoleTableSkeleton}
          i18nElement="Management.roles"
          tablePage={rolesTable}
          onClick={(value) => handleClickItem(value, "edit")}
          onClickAction={handleClickItem}
          disabled={false}
        />
      </ManagementLayout>
    </>
  );
};
