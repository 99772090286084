import React from "react";
import { useTranslation } from "react-i18next";
import ConstructionIcon from "../../assets/icons/construction.svg?react";

export const WorkInProgress: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2 items-center justify-center w-full h-full p-4">
      <ConstructionIcon />
      <p className="text-low-contrast text-base w-max">{t("Global.wip")}</p>
    </div>
  );
};
