import React from "react";
import { NotificationStatusesListItemResponse } from "../../../types/GETTypes";
import { Button } from "../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { capitalizeFirstLetter } from "../../../helpers/stringHelper";

import CheckGreenIcon from "../../../assets/icons/check-green.svg?react";
import DotsVerticalIcon from "../../../assets/icons/dots-vertical.svg?react";
import EyeSolidIcon from "../../../assets/icons/eye-solid.svg?react";
import SendIcon from "../../../assets/icons/send.svg?react";

export const ScheduledMessageSent: React.FC<{
  message: NotificationStatusesListItemResponse;
  withActionButtons: boolean;
  onView: (message: NotificationStatusesListItemResponse) => void;
  onReSend: (message: NotificationStatusesListItemResponse) => void;
}> = ({
  message,
  withActionButtons,
  onView = () => {},
  onReSend = () => {},
}) => {
  const { t } = useTranslation();

  const handleClickDrop = (action: string) => {
    if (action === "resend") onReSend(message);
  };

  return (
    <div className="flex flex-row gap-2 justify-between">
      <div className="flex flex-rown gap-2">
        <div className="flex flex-col items-center">
          <div
            className={`flex bg-element-background-success w-5 h-5 shrink-0 rounded-full items-center justify-center mt-2`}
          >
            <CheckGreenIcon className="w-5 h-5" />
          </div>

          <div className="border-1 border-element-border h-full w-0 rounded-2px my-1"></div>
        </div>

        <div className="mt-2">
          <p className={`font-semibold text-high-contrast line-through`}>
            {message.notification.name}
          </p>

          <p className="font-light text-low-contrast mt-1">
            {`${capitalizeFirstLetter(
             moment(message.sent_at).format("dddd D MMM YYYY [à] HH:mm")
            )} via ${capitalizeFirstLetter(message.channel)}`}
          </p>
        </div>
      </div>

      <div className="flex flex-row gap-1 mt-2">
        <Button
          type="secondary"
          size="small"
          RightIcon={EyeSolidIcon}
          onClick={() => onView(message)}
        >
          {t("ScheduledMessageList.see")}
        </Button>

        {withActionButtons && message.notification?.id !== null && (
          <div className="w-10">
            <Button
              type="secondary"
              size="small"
              dropItems={[
                {
                  label: t("ScheduledMessageList.reSend"),
                  value: "resend",
                  Icon: SendIcon,
                },
              ]}
              onClick={handleClickDrop}
              LeftIcon={DotsVerticalIcon}
            />
          </div>
        )}
      </div>
    </div>
  );
};
