import React from "react";
import { WorkspaceResponse } from "../../../types/GETTypes";
import { SimpleBox } from "../../Modal/Airbnb/Connect/AirbnbConnectStepImport";
import { ValueType } from "../../../types/commonTypes";

export const WorkspaceItem: React.FC<{
  workspace: WorkspaceResponse;
  isSelected: boolean;
  userId: ValueType;
  onClick: (id: ValueType) => void;
}> = ({ workspace, isSelected, userId, onClick }) => {
  return (
    <div className="flex cursor-pointer" onClick={() => onClick(workspace.id)}>
      <p className="flex-1 text-high-contrast font-semibold">
        {workspace.name} {workspace.created_by === userId && "(Owner)"}
      </p>

      <SimpleBox value={isSelected} />
    </div>
  );
};
