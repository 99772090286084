import React from "react";
import { MessageItemServiceProps } from "./MessageItem.type";
import CheckIcon from "../../../assets/icons/check.svg?react";

export const MessageItemService: React.FC<MessageItemServiceProps> = ({
  content,
}) => {
  return (
    <div className="flex justify-center w-full bg-element-background p-3 mt-4 rounded-6px">
      <div className="flex gap-2 text-high-contrast font-semibold items-center">
        <CheckIcon className="w-5 h-5 shrink-0" />
        <p>{content}</p>
      </div>
    </div>
  );
};
