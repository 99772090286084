import React from "react";
import {PaymentExtraListItemResponse, ReservationResponse} from "../../../../types/GETTypes";
import MoneyIcon from "../../../../assets/icons/money.svg?react";
import EmojiHappyIcon from "../../../../assets/icons/emoji-happy.svg?react";
import MoonIcon from "../../..//../assets/icons/moon-2.svg?react";
import UsersIcon from "../../../../assets/icons/users.svg?react";
import EyeOffIcon from "../../../../assets/icons/eye-off.svg?react";
import {useTranslation} from "react-i18next";
import {getPlatformByName, getPlatformIcon} from "../../../../helpers/platformHelper";
import {Rating} from "../../../Common/Rating/Rating";
import moment from "moment";


const ExtraOrderReservationCard = ({
  reservation,
}: {
  reservation: ReservationResponse | PaymentExtraListItemResponse | undefined;
}) => {
  const {t} = useTranslation();
  const {
    children_count,
    adults_count,
    nights_count,
    checkin,
    checkout,
    platform,
    guest,
    rental,
    total_guest_price
  } = reservation || {};
  
  if (!reservation) {
    return null;
  }
  return (
    <>
      <div>
        <p className="font-bold text-high-contrast">
          {t("Payments.PaymentReservationList.guestLabel")}
        </p>
        <div className="flex flex-row items-center justify-between p-2 space-x-3 pl-0">
          <div className="flex items-center space-x-4">
            <div className="relative">
              {/* LOGO */}
              {platform ? (
                <div
                  className="absolute z-10"
                  style={{top: 22, left: 26, width: 16, height: 16}}
                >
                  {getPlatformIcon(platform, "small")}
                </div>
              ) : (
                <div
                  className="absolute z-10 border-2 border-white rounded-full bg-slate-200"
                  style={{top: 22, left: 26, width: 16, height: 16}}
                />
              )}

              {/* PHOTO */}
              <img
                src={guest?.photo}
                alt="Guest thumbnail"
                className="w-10 h-10 rounded-full border-1 border-element-border/50"
              />
            </div>

            <div>
              {/* FIRSTNAME & LASTNAME */}
              <>
                <p className="font-semibold text-high-contrast">
                  {`${guest?.first_name} ${guest?.last_name}`}
                </p>

                {!guest?.first_name && !guest?.last_name && (
                  <div className="flex items-center space-x-2">
                    <EyeOffIcon width={15} height={15} />
                    <p className="text-low-contrast">
                      {t("Booking.Guest.hiddenInformation")}
                    </p>
                  </div>
                )}
              </>

              {/* CITY & COUNTRY */}
              <div>
                <p className="font-light text-low-contrast">
                  {guest?.city}
                  {guest?.country ? `, ${guest?.country}` : ""}
                </p>
              </div>
            </div>
          </div>

          {/* RATING */}
          <div>
            <Rating value={guest?.rate} maxStars={5} />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex flex-col space-y-3">
          <div className="space-y-1">
            <p className="font-bold text-high-contrast">
              {t("Payments.PaymentReservationList.reservationViaPlatform", {
                name: getPlatformByName(platform?.name),
              })}
            </p>

            {platform?.reservation_reference && (
              <p className="text-low-contrast">
                {t("Payments.PaymentReservationList.reference", {
                  value: "",
                })}
                {platform.reservation_reference}
              </p>
            )}

            <p className="text-low-contrast">
              {t("Payments.PaymentReservationList.rangeDate", {
                startDate: moment(checkin).format("DD MMM YYYY"),
                endDate: moment(checkout).format("DD MMM YYYY"),
              })}
            </p>
          </div>

          <div className="flex items-center flex-1 space-x-3">
            <img
              src={rental?.details?.photos?.cover}
              alt=""
              className="w-12 h-12 rounded-6px bg-slate-200"
            />
            <div className="flex flex-col">
              <p className="text-base text-wrap w-52 text-high-contrast">
                {rental?.details?.informations?.name}
              </p>
              <p className="text-wrap w-52 text-low-contrast">{`${rental?.details?.informations?.address} ${rental?.details?.informations?.postal_code} ${rental?.details?.informations?.city}`}</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-end flex-1 space-y-2">
          <div className="flex items-center space-x-2">
            <p className="text-base font-bold text-high-contrast">
              {t("Global.nights", {
                count: nights_count ?? 0,
              })}
            </p>
            <MoonIcon width={26} height={26} />
          </div>

          <div className="flex items-center space-x-2">
            <p className="text-base text-low-contrast">
              {t("Global.adults", {
                count: adults_count,
              })}
            </p>
            <UsersIcon width={20} height={20} />
          </div>

          <div className="flex items-center space-x-2">
            <p className="text-base text-low-contrast">
              {t("Global.children", {
                count: children_count,
              })}
            </p>
            <EmojiHappyIcon width={20} height={20} />
          </div>

          <div className="flex items-center space-x-2">
            <p className="text-base text-low-contrast">{`${total_guest_price} ${t(
              "Global.currencySymbol"
            )}`}</p>
            <MoneyIcon width={26} height={26} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExtraOrderReservationCard;
