import React from "react";
import {cn} from "../../../helpers/classHelper";
import MapComponent from "./MapComponent";

interface CardRental {
  cover?: string;
  name?: string;
  address?: string;
  postalCode?: string;
  city?: string;
  divImageStyle?: string;
  mapLabel?:string
  mapClassNames?:{
    label?: string;
  }
}

const CardRentalComponent = ({
  cover,
  name,
  address,
  postalCode,
  city,
  divImageStyle,
  mapLabel,
  mapClassNames
}: CardRental) => {
  
  return (
    <div className=" ">
      <div className="flex flex-row items-center text-sm space-x-2">
        <div className="flex items-center">
          <div className="relative">
            <div
              className={cn(
                "object-fill w-24 h-24 overflow-hidden rounded-4px border-1 border-element-border/50",
                divImageStyle
              )}
            >
              <img
                src={cover}
                alt={name}
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <p className="font-semibold text-high-contrast md:text-lg">{name}</p>
          <div className="flex flex-col md:text-lg">
            <p className="font-light text-low-contrast">{address}</p>
            <p className="font-light text-low-contrast">
              {postalCode} {city}
            </p>
          </div>
        </div>
      </div>
      <div className="my-4">
        <MapComponent
          address={address ?? ""}
          fallbackAddress={`${city} ${postalCode}`}
          mapContainerStyle={{height: "200px", width: "100%"}}
          label={mapLabel}
          mapClassNames={mapClassNames}
        />
      </div>
    </div>
  );
};

export default CardRentalComponent;
