import React, { useEffect, useState } from "react";
import {
  ChecklistSection,
  File,
  TaskItemResponse,
} from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { CleaninessType } from "../EditTaskStarted/EditTaskStarted.type";
import { ValueType } from "../../../types/commonTypes";
import MoonIcon from "../../../assets/icons/moon.svg?react";
import UsersIcon from "../../../assets/icons/users.svg?react";
import LoginIcon from "../../../assets/icons/log-in.svg?react";
import LogoutIcon from "../../../assets/icons/log-out.svg?react";
import CheckCircleGreenIcon from "../../../assets/icons/check-circle-green.svg?react";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import { RightModal } from "../../Common/RightModal/RightModal";
import moment from "moment";
import { Separator } from "../../Common/Separator/Separator";
import { ChecklistCheckbox } from "../EditTaskScheduled/ChecklistCheckbox";
import { PictureInput } from "../../Common/PictureInput/PictureInput";
import { NumberInput } from "../../Common/NumberInput/NumberInput";
import { CleaninessInput } from "../EditTaskStarted/CleaninessInput";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { TextAreaInput } from "../../Common/TextAreaInput/TextAreaInput";
import { Button } from "../../Common/Button/Button";
import paths from "../../../constants/paths";
import { post } from "../../../helpers/APIHelper";
import { useModal } from "../../../hooks/useModal";
import { PreviewImage } from "../../Common/PreviewImage/PreviewImage";

export const EditTaskEditComplete: React.FC<{
  task: TaskItemResponse | undefined | null;
  isVisible: boolean;
  onClose: (task: TaskItemResponse | null | undefined) => void;
  onSuccess: (task: TaskItemResponse) => void;
}> = ({ task, isVisible, onClose, onSuccess }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [checklistSections, setChecklistSections] = useState<
    ChecklistSection[]
  >([]);
  const [files, setFiles] = useState<Blob[]>([]);
  const [oldFiles, setOldFiles] = useState<File[]>([]);
  const [countBeds, setCountBeds] = useState<number>(0);
  const [cleaniness, setCleaniness] = useState<CleaninessType | undefined>();
  const [errorCleaniness, setErrorCleaniness] = useState<string | null>(null);
  const [remarks, setRemarks] = useState<string>("");
  const previewImage = useModal();

  console.log("task to complete or edit", task);

  useEffect(() => {
    if (task && task.started_task_data) {
      setChecklistSections(
        JSON.parse(task?.completed_task_data?.simple_assignation?.checklist!),
      );
    }

    if (task) {
      setOldFiles(task?.completed_task_data?.simple_assignation?.photos ?? []);

      setCountBeds(
        task?.completed_task_data?.simple_assignation?.cleaned_beds_count ?? 0,
      );
      setCleaniness(
        task?.completed_task_data?.simple_assignation
          ?.cleanness as CleaninessType,
      );
      setRemarks(task?.completed_task_data?.simple_assignation?.remarks ?? "");
    }
  }, [task]);

  const handleCheckSection = (sectionId: ValueType, value: boolean) => {
    setChecklistSections((prevSections) =>
      prevSections.map((section) => {
        if (section.section_id === sectionId) {
          return {
            ...section,
            done: value,
            steps: section.steps.map((step) => ({
              ...step,
              done: value,
            })),
          };
        }
        return section;
      }),
    );
  };

  const handleCheckStep = (
    sectionId: ValueType,
    stepId: ValueType,
    value: boolean,
  ) => {
    setChecklistSections((prevSections) =>
      prevSections.map((section) => {
        if (section.section_id === sectionId) {
          const updatedSteps = section.steps.map((step) =>
            step.step_id === stepId ? { ...step, done: value } : step,
          );

          const allStepsDone = updatedSteps.every((step) => step.done);

          return {
            ...section,
            steps: updatedSteps,
            done: allStepsDone,
          };
        }
        return section;
      }),
    );
  };

  const handleDeleteFile = (fileId: ValueType) => {
    setOldFiles(oldFiles?.filter((f) => f.id !== fileId));
  };

  const emptyFields = () => {
    setChecklistSections([]);
    setFiles([]);
    setOldFiles([]);
    setCountBeds(0);
    setCleaniness(undefined);
    setRemarks("");
  };

  const handleClose = () => {
    if (loading) return;

    emptyFields();

    onClose(task);
  };

  const handleSuccess = (task: TaskItemResponse) => {
    emptyFields();

    onSuccess(task);
  };

  function getDelPhotoIds(): number[] {
    const oldFileIds = new Set(oldFiles.map((file) => file.id));
    return (
      task?.completed_task_data?.simple_assignation?.photos
        ?.map((photo) => photo.id)
        .filter((id) => !oldFileIds.has(id)) ?? []
    );
  }

  const handleComplete = async () => {
    if (cleaniness === undefined) {
      setErrorCleaniness(t("Task.Modal.cleaninessRequired"));

      return;
    }

    setLoading(true);
    setError(null);
    setErrorCleaniness(null);

    const formData = new FormData();
    formData.append("task_id", task?.id?.toString()!);

    console.log("countBeds.toString()", countBeds.toString());

    if (task?.type === "CLEANING")
      formData.append("cleaned_beds_count", countBeds.toString());

    if (cleaniness) formData.append("cleanness", cleaniness);

    if (task?.started_task_data?.checklist_sections)
      formData.append("sections", JSON.stringify(checklistSections));

    formData.append("remarks", remarks);

    formData.append("del_photo_ids", getDelPhotoIds().join(","));

    files.forEach((f, index) => formData.append(`photos[${index}]`, f));

    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.TASKS.EDIT
      }`,
      formData,
    );

    if (res.data?.success) {
      handleSuccess(res.data?.result);
    } else {
      setError(res.response?.data?.message);
    }

    setLoading(false);
  };

  const getOperatorsAvatars = (task: TaskItemResponse) => {
    return (
      <div
        className="relative"
        style={{ minWidth: 32 + 16 * (task.assigned_operators?.length - 1) }}
      >
        {task.assigned_operators?.map((ope, index) => (
          <img
            className="size-8 rounded-full border-1 border-element-border"
            src={ope.photo}
            style={{ paddingLeft: index * 16 }}
          />
        ))}
      </div>
    );
  };

  const getOperatorsNames = (task: TaskItemResponse) => {
    const names = task.assigned_operators?.map(
      (ope) => `${ope.first_name} ${ope.last_name}`,
    );

    return (
      <p className="font-semibold text-high-contrast line-clamp-1">
        {names?.join(",")}
      </p>
    );
  };

  const getTitle = () => {
    return (
      <div className="flex items-center justify-between text-base">
        <p className="text-high-contrast font-semibold">{task?.name}</p>

        <div className="flex gap-2 bg-element-background-success rounded-6px items-center w-fit p-3 text-sm">
          <CheckCircleGreenIcon className="size-4" />
          <p className="text-success font-semibold">
            {t("Task.List.completed")}
          </p>
        </div>
      </div>
    );
  };

  return (
    <RightModal title={getTitle()} isVisible={isVisible} onClose={handleClose}>
      <div className="flex flex-col flex-1 justify-between w-full">
        <div className="flex-1 w-full flex flex-col gap-3 overflow-y-auto pb-4">
          <label className="text-low-contrast font-bold text-sm">
            {t("Global.info")}
          </label>

          <p>
            {t("Task.Modal.dateToDo")}{" "}
            <span className="font-semibold text-high-contrast">
              {moment(task?.scheduled_date).format("dddd DD MMMM YYYY")} -
              {" " + task?.scheduled_time}
            </span>
          </p>

          <div className="flex justify-between">
            {/* Rental infos */}
            <div className="flex gap-2 my-2 mx-2 items-center">
              <img
                className="size-12 rounded-6px border-1 border-element-border shrink-0 cursor-pointer"
                src={task?.rental?.cover}
                onClick={() => previewImage.open()}
              />
              <PreviewImage
                imageUrl={task?.rental?.cover}
                imageModal={previewImage}
              />

              <div className="flex flex-col">
                <p className="font-semibold text-high-contrast">
                  {task?.rental?.name}
                </p>
                <p className="font-light text-low-contrast">
                  {task?.rental?.address} {task?.rental?.postal_code}{" "}
                  {task?.rental?.city}
                </p>
              </div>
            </div>

            {task && (
              <div className="flex gap-2 items-center min-w-44">
                {getOperatorsAvatars(task)}
                <div className="flex flex-col">
                  {getOperatorsNames(task)}
                  <p className="text-low-contrast">
                    {t("Task.Modal.personAssigned")}
                  </p>
                </div>
              </div>
            )}
          </div>

          <Separator />

          <div className="flex flex-col gap-1">
            {typeof task?.started_task_data?.next_reservation?.guest_remarks ===
              "string" && (
              <>
                <p className="text-low-contrast font-light">
                  {t("Task.Modal.remarkNextGuest")}
                </p>

                <p className="text-high-contrast font-semibold">
                  {task?.started_task_data?.next_reservation?.guest_remarks}
                </p>
              </>
            )}

            {typeof task?.started_task_data?.remark === "string" && (
              <>
                <p className="text-low-contrast font-light mt-3">
                  {t("Task.Modal.remarkTask")}
                </p>
                <p className="text-high-contrast font-semibold">
                  {task?.started_task_data?.remark}
                </p>
              </>
            )}

            {typeof task?.started_task_data?.next_reservation?.private_notes ===
              "string" && (
              <>
                <p className="text-low-contrast font-light mt-3">
                  {t("Task.Modal.remarkNextReservation")}
                </p>
                <p className="text-high-contrast font-semibold">
                  {task?.started_task_data?.next_reservation?.private_notes}
                </p>
              </>
            )}
          </div>

          {(typeof task?.started_task_data?.next_reservation?.guest_remarks ===
            "string" ||
            typeof task?.started_task_data?.remark === "string" ||
            typeof task?.started_task_data?.next_reservation?.private_notes ===
              "string") && <Separator />}

          {(task?.started_task_data?.checklist_photos?.length ?? 0) > 0 && (
            <>
              <p className="font-semibold text-low-contrast">
                {t("Task.Modal.rentalPhotos")}
              </p>
              <div className="flex flex-wrap gap-3">
                {task?.started_task_data.checklist_photos?.map((photo) => (
                  <img
                    className="border-1 border-element-border rounded-6px size-24 shrink-0"
                    src={photo.original_url}
                  />
                ))}
              </div>

              <Separator />
            </>
          )}

          {/* Display checklist or description */}
          {task?.started_task_data?.checklist_sections ? (
            <>
              <p className="font-semibold text-low-contrast">
                {t("Task.Modal.checklistToDo")}
              </p>
              <ChecklistCheckbox
                disabled={false}
                checklistSections={checklistSections}
                onCheckSection={handleCheckSection}
                onCheckStep={handleCheckStep}
              />
              <Separator />
            </>
          ) : (
            <>
              <p className="font-semibold text-high-contrast">
                {t("Task.Modal.description")} :
              </p>
              <p className="text-high-contrast">
                {task?.started_task_data?.description}
              </p>
            </>
          )}

          {/* Photos */}
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-low-contrast">
              {t("Task.Modal.photosOrVideos")}
            </p>
            <PictureInput
              onChangeNewFiles={setFiles}
              onDeleteFile={handleDeleteFile}
              newFiles={files}
              oldFiles={oldFiles}
              displayCover={false}
              disabled={loading}
              activePreviewSystem={true}
            />
          </div>

          <Separator />

          {task?.type === "CLEANING" && (
            <NumberInput
              label={t("Task.Modal.howManyBeds")}
              acceptDecimal={false}
              acceptNegative={false}
              value={countBeds}
              disabled={loading}
              onChangeText={(value) => setCountBeds(Number(value))}
            />
          )}

          <div className="flex flex-col gap-1 mt-2">
            <p className="text-high-contrast font-semibold">
              {t("Task.Modal.cleaniness")}*
            </p>
            <p className="font-light text-low-contrast">
              {t("Task.Modal.cleaninessQuestion")}
            </p>
            <CleaninessInput value={cleaniness} onChange={setCleaniness} />
            <p className="font-light text-low-contrast">
              {t("Task.Modal.cleaninessRemark")}
            </p>
            <ErrorMessage errorType="FORM">{errorCleaniness}</ErrorMessage>
          </div>

          <TextAreaInput
            label={t("Task.Modal.remarks")}
            placeholder={t("Task.Modal.remarksPlaceholder")}
            rows={2}
            value={remarks}
            disabled={loading}
            onTextChange={setRemarks}
          />
        </div>

        <div className="flex gap-1 flex-col pt-4">
          <ErrorMessage>{error}</ErrorMessage>

          <div className="flex gap-2">
            <Button type="secondary" onClick={handleClose} disabled={loading}>
              {t("Global.cancel")}
            </Button>

            <Button
              RightIcon={CheckWhiteIcon}
              onClick={handleComplete}
              loading={loading}
            >
              {t("Global.validate")}
            </Button>
          </div>
        </div>
      </div>
    </RightModal>
  );
};
