import moment from "moment";
import React from "react";
import { ClassName } from "react-calendar/dist/cjs/shared/types";
import { useTranslation } from "react-i18next";
import { PaymentAccountTypeEnum } from "../../../enums/GETenums";
import { cn } from "../../../helpers/classHelper";
import { getPlatformNode } from "../../../helpers/platformHelper";
import { capitalizeFirstLetter } from "../../../helpers/stringHelper";
import { usePaymentReservationStatusBadge } from "../../../pages/PaymentReservationList/PaymentReservationList.hooks";
import { PaymentReservationResponse } from "../../../types/GETTypes";
import { TableListItemProps } from "../../Common/TableList/TableList.type";
import { getPaymentReservationAccountType } from "../../../helpers/reservationHelper";

export const PaymentReservationListItem: React.FC<TableListItemProps> = ({
  data,
  onClick = () => {},
  onClickAction = () => {},
}) => {
  const paymentReservation: PaymentReservationResponse = data;
  const { t } = useTranslation();

  const getBorder = (): ClassName =>
    !paymentReservation?.payment_schedule?.is_single_payment &&
    paymentReservation?.payment_schedule?.payments_schedule_items?.length > 1
      ? "border-element-border border-y-1"
      : "border-element-border border-t-1";

  return (
    <>
      <tr
        className="cursor-pointer hover:bg-element-background/40"
        onClick={() => onClick(paymentReservation.id)}
      >
        <td
          className={cn(
            `flex flex-row items-center px-4 py-4 space-x-4 text-sm border-element-border`,
            `${getBorder()}`
          )}
        >
          <div className="flex items-center">
            <div className="relative">
              <div className="object-fill w-12 h-12 overflow-hidden rounded-4px border-1 border-element-border/50">
                <img
                  src={paymentReservation.rental.cover!}
                  alt={paymentReservation.rental.name}
                  className="object-fill w-full h-full"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-base font-semibold tracking-normal text-high-contrast">
              {paymentReservation.rental.name}
            </p>
            <div>
              <p className="font-light tracking-normal text-low-contrast">
                {paymentReservation.rental.address}
              </p>
              <p className="font-light tracking-normal text-low-contrast">
                {paymentReservation.rental.postal_code}{" "}
                {paymentReservation.rental.city}
              </p>
            </div>
          </div>
        </td>

        <td className={cn(`px-2 py-4`, `${getBorder()}`)}>
          <span className="font-bold tracking-normal">{`${paymentReservation.guest.first_name} ${paymentReservation.guest.last_name}`}</span>
        </td>

        <td className={`${getBorder()}`}>
          {getPlatformNode(paymentReservation.platform)}
        </td>

        <td className={cn(`py-4 text-center`, `${getBorder()}`)}>
          <span className="text-base">
            {paymentReservation?.payment_schedule?.payments_schedule_items
              ?.length > 0 &&
            paymentReservation?.payment_schedule?.payments_schedule_items?.[0]
              ?.specific_date
              ? moment(
                  paymentReservation?.payment_schedule
                    ?.payments_schedule_items?.[0]?.specific_date
                ).format("DD/MM/YYYY")
              : null}
          </span>
        </td>

        <td className={cn(`py-4 text-center`, `${getBorder()}`)}>
          <p className="text-base">
            {paymentReservation?.payment_schedule?.payments_schedule_items
              ?.length > 0 &&
              getPaymentReservationAccountType(
                paymentReservation?.payment_schedule
                  ?.payments_schedule_items?.[0]?.payment_account_type,
                t
              )}
          </p>
        </td>

        <td className={cn(`py-4`, `${getBorder()}`)}>
          {usePaymentReservationStatusBadge(
            paymentReservation?.payment_schedule?.payment_status.toUpperCase()
          )}
        </td>

        <td className={cn(`py-4 text-right pe-4`, `${getBorder()}`)}>
          <span className="font-bold tracking-wide">
            {typeof paymentReservation?.payment_schedule?.total_price_value ===
            "number"
              ? `${Number(
                  paymentReservation?.payment_schedule?.total_price_value
                ).toFixed(2)} ${t("Global.currencySymbol")}`
              : `${paymentReservation?.payment_schedule?.total_price_value} ${t(
                  "Global.currencySymbol"
                )}`}
          </span>
        </td>
      </tr>

      {!paymentReservation?.payment_schedule?.is_single_payment &&
      paymentReservation?.payment_schedule?.payments_schedule_items?.length > 1
        ? paymentReservation?.payment_schedule?.payments_schedule_items?.map(
            (ps) => {
              return (
                <tr
                  className="w-full cursor-pointer bg-element-background"
                  onClick={() =>
                    onClickAction(
                      `${paymentReservation.id},${ps.id}`,
                      "paymentReservationDetail"
                    )
                  }
                  key={ps.id}
                >
                  <td className="relative h-16 px-6" colSpan={3}>
                    <div className="absolute w-4 transform rotate-90 border-t top-[23px] left-4 border-element-border-hover"></div>
                    <div className="absolute transform -translate-y-1/2 border-t border-element-border-hover left-6 right-4 top-1/2"></div>
                  </td>

                  <td className="px-2 py-3 text-center">
                    <span className="text-base">
                      {ps.specific_date
                        ? moment(ps.specific_date).format("DD/MM/YYYY")
                        : null}
                    </span>
                  </td>

                  <td className="px-2 py-3 text-center">
                    <span className="text-base">
                      {getPaymentReservationAccountType(
                        ps?.payment_account_type,
                        t
                      )}
                    </span>
                  </td>
                  <td className="py-3 text-sm">
                    {usePaymentReservationStatusBadge(
                      ps.payment_status.toUpperCase()
                    )}
                  </td>
                  <td className="py-3 text-right">
                    <span className="text-sm font-bold tracking-wide pe-4">
                      {`${
                        typeof ps.price_value === "number"
                          ? ps.price_value?.toFixed(2)
                          : ps.price_value
                      } ${t("Global.currencySymbol")}`}
                    </span>
                  </td>
                </tr>
              );
            }
          )
        : null}
    </>
  );
};
