import React from "react";
import { useTranslation } from "react-i18next";
import CameraIcon from "../../../../assets/icons/camera.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import { Card } from "../../../Common/Card/Card";
import { PictureInput } from "../../../Common/PictureInput/PictureInput";
import { RentalPhotosCardProps } from "./RentalPhotosCard.type";
import { RentalPhotosCardSkeleton } from "./RentalPhotosCardSkeleton";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";
import useUserStore from "../../../../stores/useUserStore";

export const RentalPhotosCard: React.FC<RentalPhotosCardProps> = ({
  loading,
  oldFiles,
  newFiles,
  workspaceOwner,
  onOpenUpdateModal,
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  if (loading) return <RentalPhotosCardSkeleton />;

  return (
    <Card
      Icon={CameraIcon}
      label={t("Rental.Infos.Photos.title")}
      button={{
        Icon: EditIcon,
        label: t("Global.edit"),
        type: "secondary",
        disabled: !getCurrentCanEdit({ user: userStore.user, workspaceOwner }),
        onClick: onOpenUpdateModal,
      }}
    >
      <div className="flex flex-col">
        <div className="flex flex-row flex-wrap">
          <PictureInput
            disabled={loading}
            mode="wrap"
            oldFiles={oldFiles}
            newFiles={newFiles}
            onWrapButtonClick={onOpenUpdateModal}
            activePreviewSystem={true}
          />
        </div>
      </div>
    </Card>
  );
};
