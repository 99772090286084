import React from "react";
import { useTranslation } from "react-i18next";
import AirBnBIcon from "../../../../assets/icons/airbnb.svg?react";
import { PlatformColorList } from "../../../../constants/colors";
import { ConnectionLoader } from "../../../Common/ConnectionLoader/ConnectionLoader";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";

export const AirbnbConnectStepValidation: React.FC<{
  error?: string | null;
}> = ({ error }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2">
      <ConnectionLoader
        platformRight={{
          icon: AirBnBIcon,
          color: PlatformColorList.Airbnb,
        }}
      />

      <p className="text-sm font-semibold text-low-contrast">
        {t("Progression.Airbnb.Validation.content1")}
      </p>
      <p className="text-sm font-light text-low-contrast">
        {t("Progression.Airbnb.Validation.content2")}
      </p>
      <p className="text-sm font-light text-low-contrast">
        {t("Progression.Airbnb.Validation.content3")}{" "}
        <a
          href={`mailto:${t(
            "Progression.Airbnb.Validation.supportEmail"
          ).toString()}`}
          className="font-semibold"
        >
          {t("Progression.Airbnb.Validation.supportEmail")}
        </a>
      </p>

      <ErrorMessage>{error}</ErrorMessage>
    </div>
  );
};
