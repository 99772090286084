import { t } from "i18next";
import moment from "moment";
import React from "react";
import { Trans } from "react-i18next";
import IgloohomeRenderIcon from "../../../assets/icons/igloohome-render.svg?react";
import KeyHoleIcon from "../../../assets/icons/keyhole.svg?react";
import NukiRoundedIcon from "../../../assets/icons/nuki-rounded.svg?react";
import Ttlock2Icon from "../../../assets/icons/ttlock-2.svg?react";
import { SmartLocksEnum } from "../../../constants/locks";
import { capitalizeFirstLetter } from "../../../helpers/stringHelper";
import { ReservationLockItemResponse } from "../../../types/GETTypes";

export const ReservationLockItem: React.FC<{
  lock: ReservationLockItemResponse;
}> = ({ lock }) => {
  const getLockIcon = () => {
    if (lock.provider === "static") {
      return <KeyHoleIcon className="size-7" />;
    } else if (
      lock.provider === SmartLocksEnum.IGLOOHOME.toLowerCase() ||
      lock.provider === SmartLocksEnum.IGLOOHOMEV2.toLowerCase()
    ) {
      return <IgloohomeRenderIcon className="size-7" />;
    } else if (lock.provider === SmartLocksEnum.NUKI.toLowerCase()) {
      return <NukiRoundedIcon className="size-7" />;
    } else if (lock.provider === SmartLocksEnum.TTLOCK.toLowerCase()) {
      return <Ttlock2Icon className="size-7" />;
    }
  };

  const getLockType = () => {
    let lockType = t("Booking.Locks.staticLockType");

    if (
      lock.provider === SmartLocksEnum.IGLOOHOME.toLowerCase() ||
      lock.provider === SmartLocksEnum.IGLOOHOMEV2.toLowerCase()
    ) {
      lockType = t("Booking.Locks.lockType", {
        name: capitalizeFirstLetter(SmartLocksEnum.IGLOOHOME.toLowerCase()),
      });
    } else if (lock.provider === SmartLocksEnum.NUKI.toLowerCase()) {
      lockType = t("Booking.Locks.lockType", {
        name: capitalizeFirstLetter(SmartLocksEnum.NUKI.toLowerCase()),
      });
    } else if (lock.provider === SmartLocksEnum.TTLOCK.toLowerCase()) {
      lockType = t("Booking.Locks.lockType", {
        name: capitalizeFirstLetter(SmartLocksEnum.TTLOCK.toLowerCase()),
      });
    }

    return lockType;
  };

  return (
    <div className="flex items-center justify-between w-full p-2 rounded bg-subtle border-1 border-element-border">
      <div className="flex items-center space-x-4">
        {getLockIcon()}
        <div className="flex flex-col space-y-1">
          <p className="text-sm font-semibold text-nowrap text-high-contrast">
            {getLockType()}
          </p>
          {lock.provider !== "static" ? (
            <p className="text-base break-all whitespace-normal text-low-contrast">
              {lock.device?.id}
            </p>
          ) : null}
        </div>
      </div>

      <div className="flex flex-col items-end space-y-2">
        <div className="flex items-center space-x-4">
          <p className="text-base font-light truncate select-none max-w-96 text-low-contrast">
            {lock.device?.name}
          </p>
          <p className="p-1 font-bold bg-white rounded select-none text-nowrap border-1 border-element-border text-low-contrast">
            {lock.provider === "static"
              ? t("Booking.Locks.fixedCode")
              : t("Booking.Locks.dynamicCode")}
          </p>
          <p className="text-xl font-semibold text-active text-nowrap">
            {lock.code}
          </p>
        </div>

        {lock.provider === "static" ? (
          <p className="text-low-contrast">{t("Booking.Locks.alwaysActive")}</p>
        ) : (
          <>
            <p className="text-low-contrast">
              <Trans
                i18nKey="Booking.Locks.activeTime"
                components={{
                  strong: <span className="font-bold"></span>,
                }}
                values={{
                  date: moment(lock.checkin).format("DD/MM/YYYY"),
                  hour: moment(lock.checkin).format("HH:mm"),
                }}
              />
            </p>
            <p className="text-low-contrast">
              <Trans
                i18nKey="Booking.Locks.untilTime"
                components={{
                  strong: <span className="font-bold"></span>,
                }}
                values={{
                  date: moment(lock.checkout).format("DD/MM/YYYY"),
                  hour: moment(lock.checkout).format("HH:mm"),
                }}
              />
            </p>
          </>
        )}
      </div>
    </div>
  );
};
