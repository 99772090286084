import React from "react";

export const AccountListTableItemSkeleton: React.FC<{}> = () => {
  return (
    <tr className="fcursor-pointer hover:bg-element-background/40 animate-pulse">
      <td className="px-2 py-4 text-sm border-t-1 border-element-border">
        <div className="flex flex-col">
          <p className="font-semibold text-high-contrast h-2 rounded w-52 bg-slate-300"></p>
        </div>
      </td>

      <td className="px-2 py-4 text-sm border-t-1 border-element-border">
        <div className="flex flex-col gap-1">
          <p className="font-semibold text-high-contrast h-2 rounded w-72 bg-slate-300"></p>
          <p className="font-light text-low-contrast h-2 rounded w-52 bg-slate-200"></p>
        </div>
      </td>

      <td className="px-2 py-4 text-sm border-t-1 border-element-border">
        <div className="w-44 h-2 bg-slate-200 rounded-6px" />
      </td>

      <td className="px-2 py-4 text-sm border-t-1 border-element-border">
        <div className="w-24 h-2 bg-slate-200 rounded-6px" />
      </td>

      <td className="px-2 py-4 text-sm border-t-1 border-element-border">
        <div className="w-12 h-9 bg-slate-200 rounded-6px" />
      </td>
    </tr>
  );
};
