import React, { useEffect, useState } from "react";
import SuperHoteIcon from "../../../assets/icons/superhote-circle.svg?react";
import { PlatformColorList } from "../../../constants/colors";
import { PlatformSyncLoaderProps } from "./PlatformSyncLoader.type";

export const PlatformSyncLoader: React.FC<PlatformSyncLoaderProps> = ({
  platformLeft,
  platformRight,
  duration = 300,
  nbDots = 3,
  type = "old",
}) => {
  const minStep = 0;
  const maxStep = nbDots;
  const [leftLoadingStep, setLeftLoadingStep] = useState(minStep);
  const [rightLoadingStep, setRightLoadingStep] = useState(minStep);

  let reversedAnimation = false;
  const handleLoading = (value: number): number => {
    if (value === minStep) {
      reversedAnimation = false;
    } else if (value === maxStep) {
      reversedAnimation = true;
    }

    if (value >= minStep && value <= maxStep) {
      return reversedAnimation ? value - 1 : value + 1;
    }

    return value;
  };

  // DOT COLORS
  let leftColorDot = "bg-secondary";
  let rightColorDot = "bg-secondary";
  const platformNameList = Object.keys(PlatformColorList);
  platformNameList.forEach((name) => {
    if (name.toLowerCase() !== PlatformColorList.Airbnb.name) {
      if (
        platformLeft &&
        name.toLowerCase() === platformLeft.color?.name.toLowerCase()
      ) {
        leftColorDot = platformLeft.color.background;
      }

      if (
        platformRight &&
        name.toLowerCase() === platformRight.color?.name.toLowerCase()
      ) {
        rightColorDot = platformRight.color.background;
      }
    }
  });

  const superhoteBg = PlatformColorList.Superhote.background;
  useEffect(() => {
    const intervalLoading = setInterval(() => {
      // If both platform
      if (platformLeft && platformRight) {
        setLeftLoadingStep((prev) => handleLoading(prev));
        setRightLoadingStep((prev) => handleLoading(prev));
      }

      // If only left platform
      if (platformLeft && platformRight === undefined) {
        setLeftLoadingStep((prev) => handleLoading(prev));
      }

      // If only right platform
      if (platformRight && platformLeft === undefined) {
        setRightLoadingStep((prev) => handleLoading(prev));
      }
    }, duration);

    return () => {
      clearInterval(intervalLoading);
    };
  }, []);

  return type === "old" ? (
    <div className="flex items-center justify-center h-32 gap-4 bg-element-background-light">
      {/* Left Side */}
      {platformLeft && (
        <>
          <platformLeft.icon width={32} height={32} />
          {[...Array(maxStep)].map((v, i) => (
            <div
              className={`w-2 h-2 rounded-full ${
                leftLoadingStep >= i + 1 ? leftColorDot : superhoteBg
              }`}
              key={`${i}_left`}
            />
          ))}
        </>
      )}
      <SuperHoteIcon width={32} height={32} />

      {/* Right Side */}
      {platformRight && (
        <>
          {[...Array(maxStep)].map((v, i) => (
            <div
              className={`w-2 h-2 rounded-full ${
                rightLoadingStep >= i + 1 ? superhoteBg : rightColorDot
              }`}
              key={`${i}_right`}
            />
          ))}
          <platformRight.icon width={32} height={32} />
        </>
      )}
    </div>
  ) : (
    <div className="flex items-center justify-center h-32 gap-4 bg-element-background-light">
      {/* Left Side */}
      <SuperHoteIcon width={32} height={32} />
      <>
        {[...Array(maxStep)].map((v, i) => (
          <div
            className={`w-2 h-2 rounded-full ${
              leftLoadingStep >= i + 1 ? leftColorDot : superhoteBg
            }`}
            key={`${i}_left`}
          />
        ))}
      </>

      {platformLeft && <platformLeft.icon width={32} height={32} />}
    </div>
  );
};
