import React from "react";
import { useTranslation } from "react-i18next";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import { InputSelectOptionProps } from "../../../Common/InputSelect/InputSelect.type";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import { SimpleRadio } from "../../../Common/SimpleRadio/SimpleRadio";

export type TemplateUpsertRentalProps = {
  rentals: InputSelectOptionProps[];
  value: ValueType | null;
  onChange: (value: ValueType | null) => void;
};

export const TemplateUpsertRental: React.FC<TemplateUpsertRentalProps> = ({
  rentals,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mt-2 pb-4">
      <p className="font-light text-low-contrast">
        {t("Templates.thisTemplateIs")}
      </p>
      <div className="flex gap-1 mt-2 items-center">
        <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => onChange(null)}
        >
          <SimpleRadio value={value === null} />
          <p className="font-light text-low-contrast">
            {t("Templates.isGeneric")}
          </p>
        </div>

        <div className="w-6" />

        <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => onChange(rentals[0]?.value)}
        >
          <SimpleRadio value={value !== null} />
          <p className="font-light text-low-contrast">
            {t("Templates.isSpecific")}
          </p>
        </div>
      </div>

      {value !== null && (
        <div className="mt-3">
          <InputSelect
            label={t("Templates.rentals")}
            items={rentals}
            selectedValue={value as number}
            onSelect={(value) => onChange(value)}
          />
        </div>
      )}
    </div>
  );
};
