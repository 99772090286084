import React from "react";
import HomeIcon from "../../../assets/icons/home.svg?react";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import { Button } from "../../Common/Button/Button";
import { useTranslation } from "react-i18next";

export const AddRentalRoomsNoRoom: React.FC<{ onAdd: () => void }> = ({
  onAdd = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <div
      className="flex items-center justify-center rounded-6px border-1 border-element-border bg-element-background"
      style={{ height: "500px", minHeight: "300px" }}
    >
      {/*style={{ height: "calc(100vh - 450px)", minHeight: "300px" }}*/}
      <div className="flex flex-col items-center gap-4">
        <HomeIcon className="w-12 h-12" />

        <div className="flex flex-col items-center">
          <p className="text-high-contrast font-semibold">
            {t("AddRental.Rooms.noRoomTitle")}
          </p>
          <p className="text-low-contrast font-light">
            {t("AddRental.Rooms.noRoomDesc")}
          </p>

        </div>


        <div className="mt-4">
          <Button type="secondary" RightIcon={PlusIcon} onClick={onAdd}>
            {t("AddRental.Rooms.noRoomButton")}
          </Button>
        </div>
      </div>
    </div>
  );
};
