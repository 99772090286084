import React, { useState } from "react";
import { Button } from "../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import SendWhiteIcon from "../../../assets/icons/send-white.svg?react";
import { post } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import { ValueType } from "../../../types/commonTypes";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import CheckCircleGreenIcon from "../../../assets/icons/check-circle-green.svg?react";

export const ViewMemberReSend: React.FC<{
  email: string | undefined;
  teamId: ValueType | undefined;
}> = ({ email, teamId }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [sent, setSent] = useState<boolean>(false);

  const handleResendInvit = async () => {
    if (!email || !teamId) return;

    setLoading(true);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.RESEND_INVIT
      }`,
      {
        email,
        team_id: teamId,
      }
    );

    if (res.data?.success) {
      setSent(true);
    } else {
      setError(res.response?.data?.message);
    }

    setLoading(false);
  };

  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  if (sent) return <CheckCircleGreenIcon className="size-6" />;

  return (
    <Button
      RightIcon={SendWhiteIcon}
      onClick={handleResendInvit}
      loading={loading}
    >
      {t("Management.ViewMember.reSendInvit")}
    </Button>
  );
};
