import React from "react";
import {Card} from "../../Common/Card/Card";
import {useTranslation} from "react-i18next";

export const ReservationPlatforNoteCardSkeleton: React.FC = () => {
  const {t} = useTranslation();

  return (
    <Card label={t("Booking.PlatformNote.title")}>
      <div className="flex flex-col gap-2 animate-pulse">
        <p className="h-3 w-full bg-slate-200 rounded"></p>
        <p className="h-3 w-full bg-slate-200 rounded"></p>
        <p className="h-3 w-full bg-slate-200 rounded"></p>
        <p className="h-3 w-full bg-slate-200 rounded"></p>
        <p className="h-3 w-32 bg-slate-200 rounded"></p>
      </div>
    </Card>
  );
};
