import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TaskItemResponse } from "../../../types/GETTypes";
import { CenterModal } from "../../Common/CenterModal/CenterModal";
import { Button } from "../../Common/Button/Button";
import CheckActiveIcon from "../../../assets/icons/check-active.svg?react";
import { post } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";

export const DeclineTaskModal = ({
  task,
  isVisible,
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: () => void;
  task?: TaskItemResponse | undefined | null;
  isVisible: boolean;
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    if (loading) return;

    onClose();
  };

  const handleDecline = async () => {
    setLoading(true);
    setError(null);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.TASKS.DECLINE
      }`,
      { task_id: task?.id?.toString() }
    );

    if (res.data?.success) {
      setLoading(false);
      onClose();
      onSuccess();
    } else {
      setError(res.response?.data?.message);
    }

    setLoading(false);
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={handleClose}
      title={t("Task.Modal.Decline.title")}
    >
      <p className="text-sm font-light text-low-contrast">
        {t("Task.Modal.Decline.content")}
      </p>

      <ErrorMessage>{error}</ErrorMessage>

      <div className="flex gap-2 mt-3">
        <Button type="secondary" disabled={loading} onClick={handleClose}>
          {t("Global.cancel")}
        </Button>
        <Button
          type="alert"
          RightIcon={CheckActiveIcon}
          onClick={handleDecline}
          loading={loading}
        >
          {t("Task.Modal.Decline.button")}
        </Button>
      </div>
    </CenterModal>
  );
};
