import React from "react";
import { useTranslation } from "react-i18next";
import CameraIcon from "../../../../assets/icons/camera.svg?react";
import { Card } from "../../../Common/Card/Card";

export const RentalPhotosCardSkeleton = () => {
  const { t } = useTranslation();

  return (
    <Card label={t("Rental.Infos.Photos.title")} Icon={CameraIcon}>
      <div className="flex flex-col gap-3 animate-pulse">
        <p className="w-2/3 h-2 rounded bg-slate-200"></p>

        <div className="flex flex-row flex-wrap gap-3">
          <div className="w-32 h-32 bg-slate-200 border-1 border-element-border rounded-6px" />
          <div className="w-32 h-32 bg-slate-200 border-1 border-element-border rounded-6px" />
          <div className="w-32 h-32 bg-slate-200 border-1 border-element-border rounded-6px" />
          <div className="w-32 h-32 bg-slate-200 border-1 border-element-border rounded-6px" />
        </div>
      </div>
    </Card>
  );
};
