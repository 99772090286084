import React from "react";

import FranceFlag from "../assets/flags/France.svg?react";
import EnglishFlag from "../assets/flags/United Kingdom.svg?react";
import PortugalFlag from "../assets/flags/Portugal.svg?react";
import ItalyFlag from "../assets/flags/Italy.svg?react";
import SpainFlag from "../assets/flags/Spain.svg?react";
import GermanyFlag from "../assets/flags/Germany.svg?react";

export const getLanguageFlag = (langCode: string | null) => {
  switch (langCode?.toUpperCase()) {
    case "FR":
      return <FranceFlag className={`min-w-[16px] w-[16px]`} />;

    case "EN":
      return <EnglishFlag className={`min-w-[16px] w-[16px]`} />;

    case "PT":
      return <PortugalFlag className={`min-w-[16px] w-[16px]`} />;

    case "IT":
      return <ItalyFlag className={`min-w-[16px] w-[16px]`} />;

    case "ES":
      return <SpainFlag className={`min-w-[16px] w-[16px]`} />;

    case "DE":
      return <GermanyFlag className={`min-w-[16px] w-[16px]`} />;

    default:
      return null;
  }
};

export const getLanguageString = (lang: string | null) => {
  if (lang === null || lang === undefined) return "";

  let l = JSON.parse(lang);

  try {
    if (Array.isArray(l)) {
    
      const flatArray = l.flat(Infinity);
      return flatArray
        .map((item, index) => {

          if (typeof item !== "string") {
            console.log(`🚀 ~ getLanguageString ~ Unexpected non-string item at index ${index}:`, item);
            return "-";
          }

          return getCorrectLanguage(item);
        })
        .join(", "); // Join the mapped values into a string
    } else {
      return getCorrectLanguage(l);
    }
  } catch (e) {
    console.log("🚀 ~ getLanguageString ~ e (error):", e);
    return "-";
  }
};

export const getCorrectLanguage = (lang: string | null) => {
  
  if (!lang || typeof lang !== "string") {
    console.log("🚀 ~ getCorrectLanguage ~ Invalid value passed:", lang);
    return "";
  }

  // Translate language to French equivalents
  switch (lang) {
    case "Español":
      return "Espagnol";
    case "Português":
      return "Portugais";
    case "English":
      return "Anglais";
    case "Deutsch":
      return "Allemand";
    default:
      return lang;
  }
};

export const getLanguageIcon = (code: string, exclude?: string | string[]) => {
  if (Array.isArray(exclude) && exclude.includes(code)) {
    return null;
  }

  if (typeof exclude === "string" && code === exclude) {
    return null;
  }

  switch (code) {
    case "FR":
      return FranceFlag;
    case "EN":
      return EnglishFlag;
    case "ES":
      return SpainFlag;
    case "DE":
      return GermanyFlag;
    case "PT":
      return PortugalFlag;
    case "IT":
      return ItalyFlag;
    default:
      return null;
  }
};
