import React from "react";
import { TableListHeaderProps } from "../../Common/TableList/TableList.type";
import { useTranslation } from "react-i18next";

export const ProgressionStepRentalsTableHeader: React.FC<
  TableListHeaderProps
> = ({}) => {
  const { t } = useTranslation();

  return (
    <tr className="table-header-group bg-element-background border-b-1 border-element-border ">
      <th className="px-2 py-3 text-sm ps-4 pe-2 rounded-t-6px">
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline`}
        >
          <p>{t("Progression.StepRentals.tableTitle")}</p>
        </div>
      </th>
    </tr>
  );
};
