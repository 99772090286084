import React from "react";
import { useTranslation } from "react-i18next";
import MonitorIcon from "../../assets/icons/monitor.svg?react";
import { PlatformColorList } from "../../constants/colors";
import { TextType } from "../../types/commonTypes";
import { CenterModal } from "../Common/CenterModal/CenterModal";
import { ConnectionLoader } from "../Common/ConnectionLoader/ConnectionLoader";
import { ProgressionBar } from "../Common/ProgressionBar/ProgressionBar";
import {
  ProgressionTextEnum,
  RulesSyncWaitingModalProps,
} from "./Progression.type";

export const RulesSyncWaitingModal: React.FC<RulesSyncWaitingModalProps> = ({
  isVisible,
  progression,
  progressText = "",
}) => {
  const { t } = useTranslation();

  const getProgressText = (): TextType => {
    let newText: TextType = t(
      "Progression.RentalsRules.Sync.progressInfoAirbnbImport"
    );
    switch (progressText) {
      case ProgressionTextEnum.STEP_SYNCHRONIZE_PHASE_AIBNB_IMPORT_RESERVATIONS:
        newText = t("Progression.RentalsRules.Sync.progressInfoAirbnbImport");
      case ProgressionTextEnum.STEP_SYNCHRONIZE_PHASE_BOOKING_IMPORT_RESERVATIONS:
        newText = t("Progression.RentalsRules.Sync.progressInfoBookingImport");
      case ProgressionTextEnum.STEP_SYNCHRONIZE_PHASE_SEND_RESTRICTIONS:
        newText = t(
          "Progression.RentalsRules.Sync.progressInfoSendRestriction"
        );
    }

    return newText;
  };

  const header = () => {
    return (
      <ConnectionLoader
        platformLeft={{
          icon: MonitorIcon,
          color: PlatformColorList.Airbnb,
        }}
        type={"new"}
      />
    );
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={() => {}}
      CustomHeader={header()}
    >
      <p className="text-high-contrast font-semibold">
        {t("Progression.RentalsRules.Sync.title")}
      </p>

      <p className="text-low-contrast font-light mt-2 text-sm">
        {t("Progression.RentalsRules.Sync.content")}
      </p>

      <div className="mt-4">
        <ProgressionBar progression={progression} />
      </div>

      <p className="text-secondary font-light mt-2 text-sm">
        {getProgressText()}
      </p>
    </CenterModal>
  );
};
