import { ReviewInputProps } from "./ReviewInput.type";

import RateIconFullBase from "../../../assets/icons/star-filled.svg?react";
import RateIconHalfBase from "../../../assets/icons/star-half.svg?react";
import RateIconEmptyBase from "../../../assets/icons/star-empty.svg?react";
import { useState } from "react";

export const ReviewInput = ({
  rate,
  label,
  onChangeRate,
  classNameDiv = "",
  classNameStars = "",
}: ReviewInputProps) => {
  const [starsNumber, setStars] = useState(rate);

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (starsNumber >= i) {
        stars.push(
          <RateIconFullBase
            key={i}
            onClick={() => handleStarClick(i)}
            className={"cursor-pointer w-6 h-6 " + classNameStars}
          />,
        );
      } else if (starsNumber >= i - 0.5) {
        stars.push(
          <RateIconHalfBase
            key={i}
            onClick={() => handleStarClick(i)}
            className={"cursor-pointer w-6 h-6 " + classNameStars}
          />,
        );
      } else {
        stars.push(
          <RateIconEmptyBase
            key={i}
            onClick={() => handleStarClick(i)}
            className={"cursor-pointer w-6 h-6 " + classNameStars}
          />,
        );
      }
    }
    return stars;
  };

  const handleStarClick = (clickedRate: number) => {
    onChangeRate(clickedRate);
    setStars(clickedRate);
  };

  return (
    <div className={classNameDiv}>
      <label className={"font-bold"}>{label}</label>
      <div className="flex items-center space-x-1">{renderStars()}</div>
    </div>
  );
};
