import React from "react";
import { useTranslation } from "react-i18next";
import { PlatformLineProps } from "./AutoMessageUpsertPlatforms.type";
import { CheckBox } from "../../../Common/CheckBox/CheckBox";

export const PlatformLine: React.FC<PlatformLineProps> = ({
  Icon,
  name,
  nbRentals,
  selected,
}) => {
  const { t } = useTranslation();

  return (
    <tr className="hover:bg-element-background/40 cursor-pointer">
      <td className="p-2 pr-0 w-8">
        <CheckBox value={selected} />
      </td>
      <td className="py-2">
        <div className="flex flex-row space-x-2 items-center">
          <div>
            <Icon
              className="border-2 border-white rounded-full z-0"
              width={28}
              height={28}
            />
          </div>
          <div className="text-sm">
            <p className="text-high-contrast font-semibold">{name}</p>
            <p className="text-low-contrast font-light">
              {nbRentals} {t("AutoMessageUpsert.rentals").toLowerCase()}
            </p>
          </div>
        </div>
      </td>
    </tr>
  );
};
