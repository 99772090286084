/**
 * Skeleton for externals platforms in ExternalPlatformsCard.tsx
 * @constructor
 */

export const ExternalPlatformsSkeleton = () => {
  return (
    <div className="animate-pulse h-10 px-2 w-full bg-gray-200 rounded flex items-center gap-2">
      <div className="rounded-full h-3 w-3 bg-gray-300 animate-pulse"></div>

      <div className="animate-pulse">
        <div className="h-6 w-6 bg-gray-300 rounded-full"></div>
      </div>

      <div className="animate-pulse">
        <div className="h-4 bg-gray-300 rounded w-20"></div>
      </div>
    </div>
  );
};
