import React from "react";
import { ProgressionRentalsRulesTableHeaderProps } from "./ProgressionRentalsRules.type";
import { useTranslation } from "react-i18next";

import ChevronDownIcon from "../../../assets/icons/chevron-down.svg?react";
import ChevronUpIcon from "../../../assets/icons/chevron-up.svg?react";

export const ProgressionRentalsRulesTableHeader: React.FC<
  ProgressionRentalsRulesTableHeaderProps
> = ({ sortField, sortDirection, totalItems, onChangeSort = () => {} }) => {
  const { t } = useTranslation();

  const handleChangeSort = (column: string) => {
    if (column === sortField && sortDirection === "asc") onChangeSort(column);
  };

  const getIcon = (column: string) => {
    const isActive = sortField === column;

    if (isActive && sortDirection === "asc") return <ChevronDownIcon />;
    else if (isActive && sortDirection === "desc") return <ChevronUpIcon />;
    else return <ChevronDownIcon />;
  };

  return (
    <tr className="bg-element-background border-b-1 border-element-border">
      <th
        className="w-3/12 px-2 py-2 text-sm"
        onClick={() => handleChangeSort("name")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "name" && "underline"
          }`}
        >
          <p>
            {t("Global.rentals", {
              count: totalItems ?? 0,
            })}
          </p>{" "}
          {getIcon("name")}
        </div>
      </th>

      <th className="w-52 px-2 py-2 text-sm">
        <div className={`flex flex-row justify-center items-center w-40`}>
          <p>{t("Progression.RentalsRules.capacity")}</p>
        </div>
      </th>

      <th className="w-1/12 px-2 py-2 text-sm">
        <div className="flex flex-col items-center">
          <p>{t("Progression.RentalsRules.checkin")}</p>
          <p>{t("Progression.RentalsRules.checkout")}</p>
        </div>
      </th>

      <th className="w-2/12 px-2 py-2 text-sm">
        <div className={`flex flex-row justify-center items-center`}>
          <p>{t("Progression.RentalsRules.extraTraveller")}</p>
        </div>
      </th>

      <th className="w-1/12 px-2 py-2 text-sm">
        <div className={`flex flex-row justify-center items-center`}>
          <p>{t("Progression.RentalsRules.cleaningFees")}</p>
        </div>
      </th>

      <th className="w-1/12 px-2 py-2 text-sm">
        <div className={`flex flex-row justify-center items-center`}>
          <p>{t("Progression.RentalsRules.deposit")}</p>
        </div>
      </th>

      <th className="w-1/12 px-2 py-2 text-sm">
        <div className={`flex flex-row justify-center items-center`}>
          <p>{t("Progression.RentalsRules.holidayTax")}</p>
        </div>
      </th>

      <th className="w-1/12 px-2 py-2 text-sm">
        <div className={`flex flex-row justify-center items-center`}>
          <p>{t("Progression.RentalsRules.priceMultiplier")}</p>
        </div>
      </th>

      <th className="w-1/12 px-2 py-2 text-sm"></th>
    </tr>
  );
};
