import React from "react";
import { ResctrictionLineProps } from "./Calendar.type";
import {
  CALENDAR_NB_DAYS_AFTER,
  CALENDAR_NB_DAYS_BEFORE,
  CALENDAR_RENTAL_NAME_WIDTH,
  CALENDAR_RESTRICTION_HEIGHT,
} from "../../constants/calendar";
import { CalendarRestrictionCellSkeleton } from "./RestrictionCellSkeleton";
import { CalendarRestrictionCell } from "./RestrictionCell";

export const CalendarResctrictionLine: React.FC<ResctrictionLineProps> = ({
  label,
  rental,
  restrictionField,
  nbDays,
  daysOffset,
  onClick,
}) => {
  return (
    <tr>
      <td
        style={{
          width: CALENDAR_RENTAL_NAME_WIDTH,
          height: CALENDAR_RESTRICTION_HEIGHT,
        }}
        className="bg-gray-50 border-t-1 border-element-border "
      >
        <div className="flex gap-2 items-center cursor-pointer">
          <p className="text-low-contrast font-light flex-1">{label}</p>

          <div
            className="absolute bg-element-border"
            style={{
              width: 1,
              height: CALENDAR_RESTRICTION_HEIGHT + 3,
              left: CALENDAR_RENTAL_NAME_WIDTH - 2,
              top: -4,
            }}
          />
        </div>
      </td>

      {Array.from({ length: CALENDAR_NB_DAYS_BEFORE }).map(() => (
        <CalendarRestrictionCellSkeleton />
      ))}

      {Array.from({ length: nbDays }).map((v, index) => (
        <CalendarRestrictionCell
          key={index}
          index={index}
          rental={rental}
          restrictionField={restrictionField}
          daysOffset={daysOffset}
          onClick={onClick}
        />
      ))}

      {Array.from({ length: CALENDAR_NB_DAYS_AFTER }).map(() => (
        <CalendarRestrictionCellSkeleton />
      ))}
    </tr>
  );
};
