import {
  PaymentPolicyPayload,
  PaymentPolicyResponse,
} from "../../components/Payment/Payment.type";
import paths from "../../constants/paths";
import { del, post } from "../../helpers/APIHelper";

/**
 * This hook can be used to save payment policy in database
 * @param rentalId
 * @param paymentPolicy
 * @param {Function} onStart - Function to execute when the fetch starts.
 * @param {Function} onEnd - Function to execute when the fetch ends.
 * @param {Function} onSuccess - Function to execute when the fetch is successful.
 * @param {Function} onError - Function to execute when there is an error.
 */
export const useCreatePaymentPolicy = async (
  rentalId: string | undefined,
  paymentPolicy: PaymentPolicyPayload,
  onSuccess: (paymentPolicies: PaymentPolicyResponse[]) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (rentalId && paymentPolicy) {
    try {
      if (typeof onStart === "function") {
        onStart();
      }
      const response = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_UPDATE}/${rentalId}${
          paths.API.PAYMENTS.POLICIES
        }`,
        paymentPolicy
      );
      if (response?.data?.success) {
        onSuccess(response?.data.result);
      } else {
        onError(response?.data?.message ?? response.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  }
};

/**
 * This hook can be used to update payment policy in database
 * @param rentalId
 * @param paymentPolicyId
 * @param paymentPolicy
 * @param {Function} onStart - Function to execute when the fetch starts.
 * @param {Function} onEnd - Function to execute when the fetch ends.
 * @param {Function} onSuccess - Function to execute when the fetch is successful.
 * @param {Function} onError - Function to execute when there is an error.
 */
export const useUpdatePaymentPolicy = async (
  rentalId: string | undefined,
  paymentPolicyId: number | undefined,
  paymentPolicy: PaymentPolicyPayload,
  onSuccess: (paymentPolicies: PaymentPolicyResponse[]) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (rentalId && paymentPolicyId && paymentPolicy) {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      const response = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_UPDATE}/${rentalId}${
          paths.API.PAYMENTS.POLICIES
        }/${paymentPolicyId}`,
        {
          _method: "PUT",
          ...paymentPolicy,
        }
      );

      if (response?.data?.success) {
        onSuccess(response?.data.result);
      } else {
        onError(response?.response?.data.message ?? response?.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  }
};

/**
 * This hook can be used to delete payment policy in database
 * @param rentalId
 * @param paymentPolicyId
 * @param {Function} onStart - Function to execute when the fetch starts.
 * @param {Function} onEnd - Function to execute when the fetch ends.
 * @param {Function} onSuccess - Function to execute when the fetch is successful.
 * @param {Function} onError - Function to execute when there is an error.
 */
export const useDeletePaymentPolicy = async (
  rentalId: string | undefined,
  paymentPolicyId: number | undefined,
  onSuccess: (paymentPolicies: PaymentPolicyResponse[]) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }

  if (paymentPolicyId) {
    try {
      const response = await del(
        `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_UPDATE}/${rentalId}${
          paths.API.PAYMENTS.POLICIES
        }/${paymentPolicyId}`
      );

      if (response?.data?.success) {
        onSuccess(response?.data.result);
      } else {
        onError(response?.data.message ?? response.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  }
};

/**
 * This hook can be used to update the favorite payment policy in database
 * @param rentalId
 * @param paymentPolicyId
 * @param {Function} onStart - Function to execute when the fetch starts.
 * @param {Function} onEnd - Function to execute when the fetch ends.
 * @param {Function} onSuccess - Function to execute when the fetch is successful.
 * @param {Function} onError - Function to execute when there is an error.
 */
export const useUpdateFavoritePaymentPolicy = async (
  rentalId: string | undefined,
  paymentPolicyId: number | undefined,
  onSuccess: (
    paymentPolicyId: number | undefined,
    paymentPolicies: PaymentPolicyResponse[]
  ) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (rentalId && paymentPolicyId) {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      const response = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_UPDATE}/${rentalId}${
          paths.API.RENTAL.UPDATE_FAVORITE_PAYMENT_POLICY
        }/${paymentPolicyId}`
      );

      if (response?.data?.success) {
        onSuccess(paymentPolicyId, response?.data.result);
      } else {
        onError(response?.response?.data.message ?? response?.data.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  }
};
