import React from "react";
import { ProgressionStep } from "../../../pages/Progression/ProgressionStep";
import { useTranslation } from "react-i18next";
import { Button } from "../../Common/Button/Button";
import {
  ProgressionStatusEnum,
  ProgressionStepRulesProps,
} from "../Progression.type";

export const ProgressionStepRules: React.FC<ProgressionStepRulesProps> = ({
  status,
  visible,
  number,
  error,
  loading,
  currentProgression,
  onRulesClick,
}) => {
  const { t } = useTranslation();

  if (!visible) return null;

  return (
    <ProgressionStep
      number={number.toString()}
      title={t("Progression.StepRules.title")}
      status={status}
      error={error}
      loading={loading}
    >
      <p className="font-light text-low-contrast">
        {t("Progression.StepRules.content1")}
      </p>

      <div className="mt-4 w-50">
        <Button
          onClick={onRulesClick}
          disabled={currentProgression.status === ProgressionStatusEnum.PENDING}
        >
          {t("Progression.StepRules.config")}
        </Button>
      </div>
    </ProgressionStep>
  );
};
