import moment from "moment";
import { Dispatch, SetStateAction, useEffect } from "react";
import {
  CalendarInputValue,
  CalendarInputValueRange,
} from "../../components/Common/CalendarInput/CalendarInput.type";
import { DateRangePreset } from "../../components/Common/DateFilter/DateFilter.type";
import { CalendarReservationResponse } from "../../types/GETTypes";
import { CalendarDay } from "./CalendarListPage.type";

/**
 *
 * @param dates
 * @param nbRangeDate
 * @param setOpenedDayGroups
 */
export const useInitCalendarListPage = (
  dates: CalendarInputValueRange,
  nbRangeDate: number,
  setOpenedDayGroups: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >
) => {
  // * Set default all day group to true
  useEffect(() => {
    const dayGroups = Array.from({ length: nbRangeDate }).map((v, index) =>
      moment(dates[0]).add(index, "days").format("YYYY_MM_DD")
    );

    let openedDayGroupsData: { [key: string]: boolean } = {};
    dayGroups.forEach((group: string) => {
      openedDayGroupsData[group] = true;
    });

    setOpenedDayGroups(openedDayGroupsData);
  }, [nbRangeDate]);
};

/**
 *
 * @param reservation
 * @param setCurrentReservation
 * @param setReservationVisible
 */
export const useClickReservationCalendarListPage = (
  reservation: CalendarReservationResponse,
  setCurrentReservation: Dispatch<
    SetStateAction<CalendarReservationResponse | undefined>
  >,
  setReservationVisible: Dispatch<SetStateAction<boolean>>
) => {
  setCurrentReservation(reservation);
  setReservationVisible(true);
};

/**
 *
 * @param date
 * @param days
 * @returns
 */
export const useReservationsCalendarListPage = (
  date: Date,
  days: CalendarDay | undefined
) => {
  const nextDate: string = moment(date).format("YYYY-MM-DD");

  const checkins: CalendarDay[] = [];
  const checkouts: CalendarDay[] = [];

  if (days && Object.entries(days).length > 0) {
    Object.keys(days).forEach((date) => {
      if (days[date].checkins && date === nextDate) {
        checkins.push(...days[date].checkins!);
      }
      if (days[date].checkouts && date === nextDate) {
        checkouts.push(...days[date].checkouts!);
      }
    });
  }

  return {
    checkins: checkins,
    checkouts: checkouts,
  };
};

/**
 *
 * @param datesState
 * @param dateRangePreset
 * @returns
 */
export const usePreviousPeriod = (
  datesState: [
    CalendarInputValue,
    Dispatch<SetStateAction<CalendarInputValue>>
  ],
  dateRangePreset: DateRangePreset
) => {
  let [dates, setDates] = datesState;
  dates = dates as CalendarInputValueRange;

  if (dates && dates.length >= 2) {
    switch (dateRangePreset) {
      case "all":
        setDates([null, null]);
        return;
      case "today":
        setDates([
          moment(dates[0]).add(-1, "days").toDate(),
          moment(dates[1]).add(-1, "days").toDate(),
        ]);
        return;
      case "next_seven_days":
        setDates([
          moment(dates[0]).add(-7, "days").toDate(),
          moment(dates[1]).add(-7, "days").toDate(),
        ]);
        return;
      case "last_four_weeks":
        setDates([
          moment(dates[0]).add(-4, "weeks").toDate(),
          moment(dates[1]).add(-4, "weeks").toDate(),
        ]);
        return;
      case "last_three_months":
        setDates([
          moment(dates[0]).add(-3, "months").toDate(),
          moment(dates[1]).add(-3, "months").toDate(),
        ]);
        return;
      case "last_twelve_months":
        setDates([
          moment(dates[0]).add(-12, "months").toDate(),
          moment(dates[1]).add(-12, "months").toDate(),
        ]);
        return;
      case "custom":
        setDates([
          moment(dates[0]).add(-1, "weeks").toDate(),
          moment(dates[1]).add(-1, "weeks").toDate(),
        ]);
        return;
    }
  }
};

/**
 *
 * @param datesState
 * @param dateRangePreset
 * @returns
 */
export const useNextPeriod = (
  datesState: [
    CalendarInputValue,
    Dispatch<SetStateAction<CalendarInputValue>>
  ],
  dateRangePreset: DateRangePreset
) => {
  let [dates, setDates] = datesState;
  dates = dates as CalendarInputValueRange;

  if (dates && dates.length >= 2) {
    switch (dateRangePreset) {
      case "all":
        setDates([null, null]);
        return;
      case "today":
        setDates([
          moment(dates[0]).add(1, "days").toDate(),
          moment(dates[1]).add(1, "days").toDate(),
        ]);
        return;
      case "next_seven_days":
        setDates([
          moment(dates[1]).add(1, "days").toDate(),
          moment(dates[1]).add(7, "days").toDate(),
        ]);
        return;
      case "last_four_weeks":
        setDates([
          moment(dates[0]).add(4, "weeks").toDate(),
          moment(dates[1]).add(4, "weeks").toDate(),
        ]);
        return;
      case "last_three_months":
        setDates([
          moment(dates[0]).add(3, "months").toDate(),
          moment(dates[1]).add(3, "months").toDate(),
        ]);
        return;
      case "last_twelve_months":
        setDates([
          moment(dates[0]).add(12, "months").toDate(),
          moment(dates[1]).add(12, "months").toDate(),
        ]);
        return;
      case "custom":
        setDates([
          moment(dates[0]).add(1, "weeks").toDate(),
          moment(dates[1]).add(1, "weeks").toDate(),
        ]);
        return;
    }
  }
};

// * -- CALENDAR LIST ITEM --

/**
 *
 * @param which
 * @param value
 * @param openedDayGroupsState
 */
export const useClickDayGroup = (
  which: string,
  value: boolean,
  openedDayGroupsState: [
    {
      [key: string]: boolean;
    },
    Dispatch<
      SetStateAction<{
        [key: string]: boolean;
      }>
    >
  ]
) => {
  const [openedDayGroups, setOpenedDayGroups] = openedDayGroupsState;
  setOpenedDayGroups({ ...openedDayGroups, [which]: value });
};
