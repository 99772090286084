import React, {useEffect, useState} from "react";
import { BookingResourceListPageProps } from "./BookingResourceListPage.type";
import { BookingLayout } from "../../components/Layout/BookingLayout/BookingLayout";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Common/Button/Button";

import ChevronDownIcon from "../../assets/icons/chevron-down.svg?react";
import EyeIcon from "../../assets/icons/eye.svg?react";
import DotsVerticalIcon from "../../assets/icons/dots-vertical.svg?react";
import EditIcon from "../../assets/icons/edit.svg?react";
import TrashIcon from "../../assets/icons/trash-active.svg?react";
import PlusIcon from "../../assets/icons/plus-circle-white.svg?react";
import SamplePassportSrc from "../../assets/images/sample-passport.jpeg";
import {InProgressPlaceholder} from "../../components/Common/Dashboard/InProgressPlaceholder";
import {useParams} from "react-router-dom";
import {ReservationResponse} from "../../types/GETTypes";
import {useTablePage} from "../../hooks/useTablePage";
import paths from "../../constants/paths";
import {fetchOneReservation} from "../../helpers/reservationHelper";

export const BookingResourceListPage: React.FC<
  BookingResourceListPageProps
> = () => {
    const { reservationId } = useParams();
    const [reservation, setReservation] = useState<ReservationResponse>();
    const tableMessages = useTablePage(
        `${import.meta.env.VITE_API_URL}${paths.API.MESSAGES}/${reservationId}`,
        "messages",
        { combine: true, reverseData: true }
    );


    const handleReservationSuccess = (value: ReservationResponse) => {
        setReservation(value);
    };

    useEffect(() => {
        if (reservationId) {
            fetchOneReservation(
                reservationId,
                handleReservationSuccess,
                ()=> {}
            );

            tableMessages.fetch({
                limit: 10,
                sort_field: "captured_at",
                sort_direction: "desc",
            });

        }
    }, [reservationId]);

    const getActions = () => {
    return (
      <div>
          {/*
        <Button RightIcon={PlusIcon} onClick={() => {}}>
          {t("Booking.addResource")}
        </Button>
        */}
      </div>
    );
  };

  return (
      <BookingLayout navbarActiveItem="checklists" rightChildren={getActions()} reservation={reservation}>
        <div className="flex flex-col flex-1 gap-3">
          <InProgressPlaceholder/>
        </div>
      </BookingLayout>
  );
};
