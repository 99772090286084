import React from "react";
import { MessageItemProps } from "./MessageItem.type";
import { useTranslation } from "react-i18next";

import AirBnBIcon from "../../../assets/icons/airbnb.svg?react";
import MailIcon from "../../../assets/icons/mail.svg?react";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { useModal } from "../../../hooks/useModal";
import { PreviewImage } from "../../Common/PreviewImage/PreviewImage";

export const MessageItem: React.FC<MessageItemProps> = ({
  id,
  isUser,
  dateTime,
  fullName,
  content,
  isAutomated = false,
  messageType,
  readAt,
  avatar,
  photos,
  type,
}) => {
  const { t } = useTranslation();
  const today = moment(new Date());
  const previewImage = useModal();

  const currentTimeZone = momentTimezone.tz.guess();
  const messageDate = momentTimezone(dateTime + " UTC").tz(currentTimeZone);

  const displayDate = () => {
    if (today.format("DD/MM/YYYY") === messageDate.format("DD/MM/YYYY"))
      return messageDate.format("HH:mm");
    else return messageDate.format("DD/MM/YYYY, HH:mm");
  };

  return (
    <div className={`flex flex-row mt-4 ${isUser ? "justify-end" : ""}`}>
      <div className="w-2/3 items-end flex flex-col">
        {id && (
          <p className="px-2 py-1 rounded bg-blue-200">message ID : {id}</p>
        )}
        <div
          className={`flex flex-row border-1 border-element-border-light p-2 w-full rounded-6px mb-1 ${
            isUser ? "bg-gray-50/80" : "bg-gray-50/30"
          }`}
        >
          {!isUser && (
            <div className="w-8 h-8 rounded-full mr-2">
              <img
                src={avatar}
                className="w-8 h-8 rounded-full object-cover bg-slate-200"
              />
            </div>
          )}

          {/* Message */}
          <div className="flex-1">
            <div className="flex flex-row space-x-1 items-center">
              {isUser ? (
                <div className="flex flex-row flex-1 justify-between pr-2 gap-1 items-center">
                  {messageType === "Airbnb" && (
                    <AirBnBIcon className="w-4 h-4" />
                  )}
                  {messageType === "email" && <MailIcon className="w-4 h-4" />}

                  <p className="text-low-contrast font-semibold">
                    {displayDate()}
                  </p>
                  <p className="text-high-contrast font-semibold flex-1">
                    {fullName}
                  </p>
                  {/* <p className="flex-1"></p> */}
                </div>
              ) : (
                <>
                  <p className="text-high-contrast font-semibold flex-1">
                    {fullName}
                  </p>
                  <p className="text-low-contrast font-semibold">
                    {displayDate()}
                  </p>
                  {messageType === "Airbnb" && (
                    <AirBnBIcon className="w-4 h-4" />
                  )}
                  {messageType === "email" && <MailIcon className="w-4 h-4" />}
                </>
              )}
            </div>
            <div className="text-low-contrast font-medium mt-1 whitespace-pre-wrap text-base">
              {content}
            </div>
          </div>
          {/* Avatar right for isUser */}

          {isUser && (
            <div className="w-8 h-8 rounded-full border-1 border-element-border">
              <img src={avatar} className="rounded-full w-8 h-8" />
            </div>
          )}
        </div>
        {isAutomated && (
          <div className="flex flex-row items-center space-x-2">
            <p className="font-semibold text-high-contrast">
              {t("MessageList.automated")}
            </p>
          </div>
        )}

        {type === "email" && (
          <div className="flex flex-col space-y-1">
            <div className="flex flex-row  ml-auto items-center gap-1"></div>
            <p className=" text-xs text-low-contrast">
              Par email - noreply@superhote.com
            </p>
          </div>
        )}
        {photos && (
          <div className="flex flex-col gap-1">
            {photos?.map((photo) => (
              <>
                <img
                  src={photo.original_url}
                  alt={photo.name}
                  className="rounded-6px max-h-[350px] cursor-pointer"
                  onClick={() => {
                    previewImage.open({
                      image: photo.id,
                    });
                  }}
                />
                {previewImage.data?.image === photo.id && (
                  <PreviewImage
                    altText={photo.name}
                    imageUrl={photo.original_url}
                    imageModal={previewImage}
                  />
                )}
              </>
            ))}
          </div>
        )}

        {/* <p className="font-light text-low-contrast">
          {readAt && <span>· Lu {readAt}</span>}
        </p> */}
      </div>
    </div>
  );
};
