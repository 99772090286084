import { post, get, del } from "../../helpers/APIHelper";
import paths from "../../constants/paths";

export interface filterGuestsType {
  sortField?: string;
  sortDirection?: string;
  search?: string;
  stays_to?: number;
  stays_from?: number;
  spent_to?: number;
  spent_from?: number;
  page?: number;
  limit?: number;
}

export type mergeValueType =
  | "keep_guest_1"
  | "keep_guest_2"
  | "keep_most_recent";

export const useGuests = (
  onSuccess?: () => void,
  onError?: (error: string) => void,
) => {
  const getGuest = async (guestId: number): Promise<void> => {
    try {
      const response = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.CRM_GUEST_MODULE.GET_BY_ID(guestId)}`,
      );

      if (response.status === 200 && response?.data?.result) {
        return response.data.result;
      } else {
        throw new Error(response?.data?.message ?? response.message);
      }
    } catch (error: any) {
      return error.message || "An unknown error occurred";
    }
  };

  const getAllGuests = async (filters: filterGuestsType) => {
    try {
      const validFilters = Object.fromEntries(
        Object.entries(filters).filter(([key, value]) => value != null),
      );

      const queryString = new URLSearchParams(
        validFilters as Record<string, string>,
      ).toString();
      const response = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.CRM_GUEST_MODULE.GET_ALL}?${queryString}`,
      );

      if (response.status === 200 && response?.data?.result) {
        return response.data.result;
      } else {
        throw new Error(response?.data?.message ?? response.message);
      }
    } catch (error: any) {
      return error.message || "An unknown error occurred";
    }
  };

  const deleteGuest = async (guestId: number) => {
    try {
      const response = await del(
        `${import.meta.env.VITE_API_URL}${paths.API.CRM_GUEST_MODULE.DELETE(guestId)}`,
      );

      if (response.status === 200 && response?.data?.result) {
        return response.data.result;
      } else {
        throw new Error(response?.response.data?.message ?? response.message);
      }
    } catch (error: any) {
      throw new Error(error.message || "An unknown error occurred");
    }
  };

  const multiDeleteGuests = async (guestId: string[]) => {
    try {
      const response = await del(
        `${import.meta.env.VITE_API_URL}${paths.API.CRM_GUEST_MODULE.MULTI_DELETE}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            ids: [...guestId],
          },
        },
      );

      if (response.status === 200 && response?.data?.result) {
        return response.data.result;
      } else {
        throw new Error(response?.response?.data?.message ?? response.message);
      }
    } catch (error: any) {
      throw new Error(error.message || "An unknown error occurred");
    }
  };

  const mergeGuests = async (
    guestOneId: number,
    guestTwoId: number,
    mergeType: mergeValueType,
  ) => {
    try {
      const response = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.CRM_GUEST_MODULE.MERGE}`,
        {
          guest_id_1: guestOneId,
          guest_id_2: guestTwoId,
          merge_type: mergeType,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      if (response.status === 200 && response?.data?.result) {
        onSuccess(response?.data?.result);
        return response.data.result;
      } else {
        onError(response?.response?.data?.message ?? response.message);
      }
    } catch (error: any) {
      return error.message || "An unknown error occurred";
    }
  };

  return {
    getGuest,
    getAllGuests,
    deleteGuest,
    multiDeleteGuests,
    mergeGuests,
  };
};
