import React, {useEffect, useState} from "react";
import {RegisterPlatformInputs, RegisterPlatformProps,} from "./RegisterPlatform.type";
import {Title} from "../../../Common/Title/Title";
import {useTranslation} from "react-i18next";
import {RegisterPlatformCheckboxes} from "./RegisterPlatformCheckboxes";
import {Button} from "../../../Common/Button/Button";
import {Controller, useForm} from "react-hook-form";
import ArrowRightIcon from "../../../../assets/icons/arrow-right-white.svg?react";
import {Keys} from "../../../../values";

export const RegisterPlatform: React.FC<RegisterPlatformProps> = ({
  platforms,
  loading = false,
  onChange = () => {},
  onSubmit,
}) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<{ platforms: any }>();

  const [currentPlatforms, setCurrentPlatforms] = useState<string[]>([]);
  const [hasOtherPlatform, setHasOtherPlatform] = useState<boolean>(false);

  // Set previous platforms
  useEffect(() => {
    if (platforms !== undefined && platforms.length !== 0) {
      setCurrentPlatforms(platforms);
    }
  }, []);

  const handleChange = (value: RegisterPlatformInputs) => {
    let nextValue = value.platforms;

    if (value.isOtherVisible) {
      setHasOtherPlatform(true);
      nextValue = [...nextValue, ...value.others];
    } else {
      setHasOtherPlatform(false);
    }

    nextValue = nextValue.filter((n) => n !== "");

    setCurrentPlatforms(nextValue);

    onChange({ platforms: nextValue });
  };

  const handleClickSubmit = () => {
    const platformBasiques = ["airbnb", "booking", "abritel"];

    if (currentPlatforms?.length > 0) {
      if (hasOtherPlatform) {
        const hasValidPlatform = currentPlatforms.some(
            (platform) => !platformBasiques.includes(platform.toLowerCase())
        );

        if (!hasValidPlatform) {
          setError("platforms", {
            message: t("Register.Platform.invalidPlatformError").toString(),
          });
          return;
        }
      }
      onSubmit({ platforms: currentPlatforms });
    } else {
      setError("platforms", {
        message: t("Register.Platform.platformError").toString(),
      });
    }
  };

  return (
    <div>
      <Title>{t("Register.Platform.title")}</Title>
      <Controller
        control={control}
        name="platforms"
        render={() => (
          <RegisterPlatformCheckboxes
            value={platforms || []}
            loading={loading}
            onChange={handleChange}
            error={errors.platforms?.message?.toString()}
          />
        )}
      />

      <div className="flex mt-4">
        <Button
          RightIcon={ArrowRightIcon}
          keyToSubmit={Keys.Enter}
          onClick={handleSubmit(handleClickSubmit)}
          loading={loading}
        >
          {t("Register.Platform.submit")}
        </Button>
      </div>
    </div>
  );
};
