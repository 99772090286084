import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import paths from "../../../constants/paths";
import { post } from "../../../helpers/APIHelper";
import resFacilities from "../../../res/facilities";
import { Button } from "../../Common/Button/Button";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { FacilitiesGroupType } from "../../Rental/Infos/Facilities/Facilities.type";
import { RentalFacilitiesEditGroup } from "../../Rental/Infos/Facilities/FacilitiesEditGroup";
import { AddRentalType } from "../AddRental.type";
import countries from "../../../constants/countries";

export const AddRentalFacilities: React.FC<{
  rental?: AddRentalType;
  onCancel: () => void;
  onNext: (facilities: string[]) => void;
  onBack: () => void;
}> = ({ rental, onCancel, onNext, onBack }) => {
  const { t } = useTranslation();

  const [currentFacilities, setCurrentFacilities] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const facilitiesGroups: FacilitiesGroupType[] = resFacilities;

  useEffect(() => {
    if (rental && rental.facilities) {
      setCurrentFacilities(rental.facilities);
    }
  }, [rental]);

  const handleNextStep = async () => {
    setLoading(true);
    setError(null);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_ADD_CHECK_FACILITIES}`,
      {
        name: rental?.infos?.name,
        rental_category_id: rental?.infos?.rentalCategory,
        rental_type_id: rental?.infos?.rentalType,
        renting_type: rental?.infos?.rentingType,
        management_type: rental?.infos?.managementType,
        address: rental?.infos?.address,
        postal_code: rental?.infos?.postal_code,
        city: rental?.infos?.city,
        longitude: rental?.infos?.longitude,
        latitude: rental?.infos?.latitude,
        country_code: countries.find((country) => country.value === rental?.infos?.country)?.code,
        guests_max_capacity: rental?.infos?.maxGuests,
        adults_max_capacity: rental?.infos?.maxAdults,
        children_max_capacity: rental?.infos?.maxChildren,
        bathrooms_count: rental?.infos?.bathrooms,
        bedrooms: rental?.rooms?.map((r) => JSON.stringify(r)),
        equipments: currentFacilities.join(",").toUpperCase(),
      }
    );

    if (res.data?.success) {
      onNext(currentFacilities);
    } else {
      setError(res.response.data.message);
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="flex flex-col w-full text-sm">
        <div className="flex justify-start pb-12">
          <div className="w-44">
            <Button
              type="secondary"
              disabled={loading}
              LeftIcon={ArrowLeftIcon}
              onClick={onBack}
            >
              {t("Global.previousStep")}
            </Button>
          </div>
        </div>

        <p className="text-lg font-semibold text-high-contrast">
          {t("AddRental.Facilities.title")}
        </p>

        <p className="mt-4 mb-6 font-light text-low-contrast">
          {t("AddRental.Facilities.subTitle")}
        </p>

        {facilitiesGroups.map((group) => (
          <RentalFacilitiesEditGroup
            group={group}
            equipments={currentFacilities}
            loading={false}
            onChangeFacility={setCurrentFacilities}
          />
        ))}
      </div>

      <ErrorMessage>{error}</ErrorMessage>

      <div className="flex gap-4 pb-4 mt-8">
        <Button type="secondary" onClick={onCancel} disabled={loading}>
          {t("Global.cancel")}
        </Button>
        <Button
          RightIcon={ArrowRightWhiteIcon}
          loading={loading}
          onClick={handleNextStep}
        >
          {t("AddReservation.Infos.nextStep")}
        </Button>
      </div>
    </div>
  );
};
