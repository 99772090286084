import React, { useState } from "react";
import { CenterModal } from "../Common/CenterModal/CenterModal";
import { useTranslation } from "react-i18next";
import { Button } from "../Common/Button/Button";
import { del } from "../../helpers/APIHelper";
import paths from "../../constants/paths";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";

export const ShortcodeListPageRemoveModal: React.FC<{
  id: any;
  isVisible: boolean;
  onSuccess: () => void;
  onClose: () => void;
}> = ({ id, isVisible, onSuccess, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleRemove = async () => {
    setLoading(true);

    const res = await del(
      `${import.meta.env.VITE_API_URL}${paths.API.SHORTCODES}/${id}`
    );

    if (res?.data?.success) onSuccess();
    else setError(res?.response?.data?.message);

    setLoading(false);
  };

  const handleClose = () => {
    if (!loading) {
      onClose();
      setError(null);
    }
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={handleClose}
      title={t("ShortcodeList.RemoveModal.title")}
    >
      <div>
        <p className="text-sm font-light text-low-contrast mb-4">
          {t("ShortcodeList.RemoveModal.content")}
        </p>

        <ErrorMessage>{error}</ErrorMessage>

        <div className="flex gap-3">
          <Button type="secondary" onClick={handleClose} disabled={loading}>
            {t("Global.cancel")}
          </Button>
          <Button type="destructive" onClick={handleRemove} loading={loading}>
            {t("ShortcodeList.RemoveModal.button")}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};
