import React, { forwardRef, useImperativeHandle } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AirbnbIcon from "../../../../assets/icons/airbnb.svg?react";
import BookingIcon from "../../../../assets/icons/booking.svg?react";
import ChevronRightIcon from "../../../../assets/icons/chevron-right.svg?react";
import { RentalLightListItemResponse } from "../../../../types/GETTypes";
import { RequiredFields } from "../../../../types/commonTypes";
import { NumberInput } from "../../../Common/NumberInput/NumberInput";
import { SimpleRadio } from "../../../Common/SimpleRadio/SimpleRadio";
import {
  ProgressionRentalsRulesModalNextStepOtherFields,
  ProgressionRentalsRulesModalRef,
} from "../ProgressionRentalsRulesModal.type";

export type ProgressionRentalsRulesPriceForm = {
  price_night_default: number;
  min_night_price: number;
  guests_max_capacity: number;
  price_night_added_per_guests: number;
  cleaning_fees: number;
  deposit: number;
  fixed_or_percentage: "fixed" | "percentage";
  adult_tax_per_night: number;
  night_price_percentage: number | null;
  night_price_limit_guest: number | null;
  adult_tax_percentage_per_night: number;
  airbnb_price_multiplier: number | undefined;
  booking_price_multiplier: number | undefined;
};

export const ProgressionRentalsRulesPrice = forwardRef<
  ProgressionRentalsRulesModalRef,
  {
    rental?: RentalLightListItemResponse;
    onNext: (
      nextRental: RentalLightListItemResponse,
      nextOtherFields: ProgressionRentalsRulesModalNextStepOtherFields
    ) => void;
    rulesPriceForm: UseFormReturn<
      ProgressionRentalsRulesPriceForm,
      any,
      undefined
    >;
    otherFields: ProgressionRentalsRulesModalNextStepOtherFields | undefined;
  }
>(({ rental, onNext, rulesPriceForm, otherFields }, ref) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    getValues,
    formState: { errors },
    watch,
    trigger,
  } = rulesPriceForm;

  const requiredFields: RequiredFields<ProgressionRentalsRulesPriceForm> = {
    price_night_default: false,
    min_night_price: false,
    guests_max_capacity: false,
    price_night_added_per_guests: false,
    cleaning_fees: false,
    deposit: false,
    fixed_or_percentage: false,
    adult_tax_per_night: false,
    night_price_percentage: false,
    night_price_limit_guest: false,
    adult_tax_percentage_per_night: false,
    airbnb_price_multiplier: false,
    booking_price_multiplier: false,
  };

  watch();

  const handleNext = () => {
    const nextRental = {
      ...rental!,
      price_night_default: getValues("price_night_default"),
      price_night_min: getValues("min_night_price"),
      guests_max_capacity: getValues("guests_max_capacity"),
      price_night_added_per_guests: getValues("price_night_added_per_guests"),
      cleaning_default: getValues("cleaning_fees"),
      deposit: getValues("deposit"),
      city_tax_default: getValues("adult_tax_per_night"),
      city_tax_percent: getValues("night_price_percentage"),
      city_tax_limit: getValues("night_price_limit_guest"),
      city_tax_additional: getValues("adult_tax_percentage_per_night"),
      external_connexions: {
        platform_airbnb: !getValues("airbnb_price_multiplier")
          ? null
          : {
              ...rental?.external_connexions?.platform_airbnb!,
              price_multiplier_value: getValues("airbnb_price_multiplier")!,
            },
        platform_booking: !getValues("booking_price_multiplier")
          ? null
          : {
              ...rental?.external_connexions?.platform_booking!,
              price_multiplier_value: getValues("booking_price_multiplier")!,
            },
      },
    };

    onNext(nextRental, {
      ...otherFields!,
      fixed_or_percentage: getValues("fixed_or_percentage"),
    });
  };

  // * Expose method to submit from parent component (ProgressionRentalsRulesModal)
  useImperativeHandle(ref, () => ({
    submit: async () =>
      await trigger()
        .then((validFields) => {
          if (validFields) {
            handleNext();
          }
        })
        .catch((err) => console.error(err)),
  }));

  const priceMultiplierCalc = (value: number | undefined): string => {
    let nextValue: number = 0;

    if (typeof value === "number") {
      nextValue =
        getValues("price_night_default") +
        (getValues("price_night_default") * (value ?? 0)) / 100;
    }

    if (isNaN(nextValue) || nextValue !== undefined || nextValue !== null) {
      return nextValue.toString();
    }

    return Number(nextValue).toFixed(2);
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex gap-3">
        <NumberInput
          register={register("price_night_default", {
            required: {
              value: requiredFields.price_night_default,
              message: t("Global.Errors.requiredField", {
                fieldName: t("Progression.RentalsRules.Price.basePrice"),
              }),
            },
          })}
          required={requiredFields.price_night_default}
          label={t("Progression.RentalsRules.Price.basePrice")}
          currency={t("Global.currencySymbol")}
          placeholder="200"
          value={getValues("price_night_default")}
          error={errors.price_night_default?.message}
        />
        <NumberInput
          register={register("min_night_price", {
            required: {
              value: requiredFields.min_night_price,
              message: t("Global.Errors.requiredField", {
                fieldName: t("Progression.RentalsRules.Price.minPrice"),
              }),
            },
          })}
          required={requiredFields.min_night_price}
          label={t("Progression.RentalsRules.Price.minPrice")}
          currency={t("Global.currencySymbol")}
          placeholder="200"
          value={getValues("min_night_price")}
          error={errors.min_night_price?.message}
        />
      </div>

      <div className="flex gap-3">
        <NumberInput
          register={register("guests_max_capacity", {
            required: {
              value: requiredFields.guests_max_capacity,
              message: t("Global.Errors.requiredField", {
                fieldName: t("Progression.RentalsRules.Price.maxGuest"),
              }),
            },
          })}
          required={requiredFields.guests_max_capacity}
          label={t("Progression.RentalsRules.Price.maxGuest")}
          placeholder="2"
          value={getValues("guests_max_capacity")}
          acceptDecimal={false}
          acceptNegative={false}
          error={errors.guests_max_capacity?.message}
        />

        <NumberInput
          register={register("price_night_added_per_guests", {
            required: {
              value: requiredFields.price_night_added_per_guests,
              message: t("Global.Errors.requiredField", {
                fieldName: t("Progression.RentalsRules.Price.extraGuest"),
              }),
            },
          })}
          required={requiredFields.price_night_added_per_guests}
          label={t("Progression.RentalsRules.Price.extraGuest")}
          currency={t("Global.currencySymbol")}
          placeholder="20"
          value={getValues("price_night_added_per_guests")}
          error={errors.price_night_added_per_guests?.message}
        />
      </div>

      <div className="flex gap-3">
        <NumberInput
          register={register("cleaning_fees", {
            required: {
              value: requiredFields.cleaning_fees,
              message: t("Global.Errors.requiredField", {
                fieldName: t("Progression.RentalsRules.Price.cleaning"),
              }),
            },
          })}
          required={requiredFields.cleaning_fees}
          label={t("Progression.RentalsRules.Price.cleaning")}
          placeholder="2"
          value={getValues("cleaning_fees")}
          acceptDecimal={false}
          acceptNegative={false}
          currency={t("Global.currencySymbol")}
          error={errors.cleaning_fees?.message}
        />

        <NumberInput
          register={register("deposit", {
            required: {
              value: requiredFields.deposit,
              message: t("Global.Errors.requiredField", {
                fieldName: t("Global.deposit"),
              }),
            },
          })}
          required={requiredFields.deposit}
          label={t("Global.deposit")}
          currency={t("Global.currencySymbol")}
          placeholder="20"
          value={getValues("deposit")}
          error={errors.deposit?.message}
        />
      </div>

      {/* Holiday Tax Radio Buttons */}
      <div className="flex flex-col">
        <p className="mb-1 text-sm font-semibold text-left">
          {t("Progression.RentalsRules.Price.holidayTypeTax")}
        </p>
        <div className="flex justify-start space-x-4">
          <Controller
            control={control}
            name="fixed_or_percentage"
            render={({ field: { onChange, value } }) => (
              <>
                <SimpleRadio
                  label={t("Progression.RentalsRules.Price.fixed")}
                  onClick={() => onChange("fixed")}
                  value={value === "fixed"}
                />
                <SimpleRadio
                  label={t("Progression.RentalsRules.Price.perPercentage")}
                  onClick={() => onChange("percentage")}
                  value={value === "percentage"}
                />
              </>
            )}
          />
        </div>
      </div>

      {getValues("fixed_or_percentage") === "fixed" && (
        <>
          {/* Tax per adult per night */}
          <NumberInput
            register={register("adult_tax_per_night", {
              required: {
                value: requiredFields.adult_tax_per_night,
                message: t("Global.Errors.requiredField", {
                  fieldName: t(
                    "Progression.RentalsRules.Price.adultTaxPerNight"
                  ),
                }),
              },
            })}
            required={requiredFields.adult_tax_per_night}
            label={t("Progression.RentalsRules.Price.adultTaxPerNight")}
            currency={t("Global.currencySymbol")}
            placeholder="20"
            value={getValues("adult_tax_per_night")}
            error={errors.adult_tax_per_night?.message}
          />
        </>
      )}

      {getValues("fixed_or_percentage") === "percentage" && (
        <>
          <div className="flex space-x-3">
            {/* Price percentage per night */}
            <NumberInput
              register={register("night_price_percentage", {
                required: {
                  value: requiredFields.night_price_percentage,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t(
                      "Progression.RentalsRules.Price.nightPricePercentage"
                    ),
                  }),
                },
              })}
              required={requiredFields.night_price_percentage}
              label={t("Progression.RentalsRules.Price.nightPricePercentage")}
              currency={"%"}
              placeholder="5,5 %"
              value={getValues("night_price_percentage")}
              error={errors.night_price_percentage?.message}
            />

            {/* Ceiling per person per night */}
            <NumberInput
              register={register("night_price_limit_guest", {
                required: {
                  value: requiredFields.night_price_limit_guest,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t(
                      "Progression.RentalsRules.Price.nightPriceLimitGuest"
                    ),
                  }),
                },
              })}
              required={requiredFields.night_price_limit_guest}
              label={t("Progression.RentalsRules.Price.nightPriceLimitGuest")}
              currency={t("Global.currencySymbol")}
              placeholder="20"
              value={getValues("night_price_limit_guest")}
              error={errors.night_price_limit_guest?.message}
            />
          </div>

          <div className="flex gap-3">
            {/* Additional taxes (percentage) */}
            <NumberInput
              register={register("adult_tax_percentage_per_night", {
                required: {
                  value: requiredFields.adult_tax_percentage_per_night,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t(
                      "Progression.RentalsRules.Price.adultTaxPercentagePerNight"
                    ),
                  }),
                },
              })}
              required={requiredFields.adult_tax_percentage_per_night}
              label={t(
                "Progression.RentalsRules.Price.adultTaxPercentagePerNight"
              )}
              infoTooltip={t(
                "Progression.RentalsRules.Price.adultTaxPercentagePerNightInfo"
              )}
              acceptDecimal={true}
              currency={"%"}
              placeholder="5,5 %"
              value={getValues("adult_tax_percentage_per_night")}
              error={errors.adult_tax_percentage_per_night?.message}
            />
          </div>
        </>
      )}

      {/* Price Multiplier */}
      {(getValues("airbnb_price_multiplier") !== undefined ||
        getValues("booking_price_multiplier") !== undefined) && (
        <>
          <div>
            <p className="font-semibold text-high-contrast">
              {t("Progression.RentalsRules.Price.priceMultiplier")}
            </p>

            <p className="mt-2 text-xs font-normal text-low-contrast">
              {t("Progression.RentalsRules.Price.priceMultiplierDescription")}
            </p>
          </div>

          {/* AIRBNB */}
          {getValues("airbnb_price_multiplier") !== undefined && (
            <div className="grid items-center grid-cols-2 gap-3">
              <div className="flex gap-2">
                <AirbnbIcon className="w-6 h-6" />
                <p className="font-semibold text-high-contrast">Airbnb</p>
              </div>

              <NumberInput
                currency="%"
                placeholder="0"
                register={register("airbnb_price_multiplier", {
                  required: {
                    value: requiredFields.airbnb_price_multiplier,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t(
                        "Progression.RentalsRules.Price.airbnbPriceMultiplier"
                      ),
                    }),
                  },
                })}
                required={requiredFields.airbnb_price_multiplier}
                value={getValues("airbnb_price_multiplier")}
                error={errors?.airbnb_price_multiplier?.message}
              />
            </div>
          )}

          {/* BOOKING */}
          {getValues("booking_price_multiplier") !== undefined && (
            <div className="grid items-center grid-cols-2 gap-3">
              <div className="flex gap-2">
                <BookingIcon className="w-6 h-6" />
                <p className="font-semibold text-high-contrast">Booking.com</p>
              </div>

              <NumberInput
                currency="%"
                placeholder="0"
                register={register("booking_price_multiplier", {
                  required: {
                    value: requiredFields.booking_price_multiplier,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t(
                        "Progression.RentalsRules.Price.bookingPriceMultiplier"
                      ),
                    }),
                  },
                })}
                required={requiredFields.booking_price_multiplier}
                value={getValues("booking_price_multiplier")}
                error={errors?.booking_price_multiplier?.message}
              />
            </div>
          )}
        </>
      )}

      {(getValues("airbnb_price_multiplier") !== undefined ||
        getValues("booking_price_multiplier") !== undefined) && (
        <div className="flex flex-col gap-3 p-2 rounded bg-element-background">
          <div className="flex flex-row items-center space-x-1">
            <h1 className="m-0 text-sm font-bold text-low-contrast">
              SuperHote
            </h1>

            <ChevronRightIcon />

            <h1 className="pl-1 text-sm font-bold text-high-contrast">
              {t("Progression.RentalsRules.Price.priceMultiplierImpact")}
            </h1>
          </div>

          {/* AIRBNB */}
          {getValues("airbnb_price_multiplier") !== undefined && (
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <AirbnbIcon />
                <span className="font-bold text-high-contrast">Airbnb</span>
              </div>

              <div>
                <span className="font-bold text-high-contrast me-2">
                  {`${priceMultiplierCalc(
                    getValues("airbnb_price_multiplier")
                  )} ${t("Global.currencySymbol")}`}
                </span>
                <span className="line-through text-low-contrast">
                  {`${getValues("price_night_default") ?? 0} ${t(
                    "Global.currencySymbol"
                  )}`}
                </span>
              </div>
            </div>
          )}

          {/* BOOKING */}
          {getValues("booking_price_multiplier") !== undefined && (
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <BookingIcon />
                <span className="font-bold text-high-contrast">
                  Booking.com
                </span>
              </div>
              <div>
                <span className="font-bold text-high-contrast me-2">
                  {`${priceMultiplierCalc(
                    getValues("booking_price_multiplier")
                  )} ${t("Global.currencySymbol")}`}
                </span>
                <span className="line-through text-low-contrast">
                  {`${getValues("price_night_default") ?? 0} ${t(
                    "Global.currencySymbol"
                  )}`}
                </span>
              </div>
            </div>
          )}

          {/* TODO: [PROGRESSION] - To be managed when Abritel is implemented on the back end */}
          {/* <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <VrboIcon />
            <span className="font-bold text-high-contrast">Abritel | VRBO</span>
          </div>

          <div>
            <span className="font-bold text-high-contrast me-2">192 €</span>
            <span className="line-through text-low-contrast">176 €</span>
          </div>
        </div> */}
        </div>
      )}
    </div>
  );
});
