import React from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import {Button} from "../../../Common/Button/Button";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";


export const AirbnbConnectStepCheckMFA: React.FC<{
  code: string;
  error: string | null;
  codeError: string | null;
  loading?: boolean;
  onChangeCode: (code: string) => void;
  onNext: () => void;
  OnReset: () => void;
}> = ({ code, error, loading, codeError, onChangeCode, onNext, OnReset }) => {
  const { t } = useTranslation();

  return (
      <div className="flex flex-col gap-2">
          <p className="text-sm font-semibold text-low-contrast">
              {t("Progression.Airbnb.CheckMFA.content1")}
          </p>
          <p className="text-sm font-semibold text-low-contrast">
              {t("Progression.Airbnb.CheckMFA.content2")}
          </p>

          <div className="flex flex-row my-2">
              <TextInput
                  label={t("Progression.Airbnb.CheckMFA.code")}
                  value={code}
                  onChangeText={(value) => onChangeCode(value)}
                  disabled={loading}
                  error={codeError ?? ""}
              />
          </div>
          <div className="flex gap-x-3">
              <Button type="secondary" onClick={()=> OnReset()}>
                  {t("Progression.Airbnb.CheckMFA.reset")}
              </Button>
              <Button
                  RightIcon={CheckIcon}
                  onClick={() => onNext()}
              >
                  {t("Progression.Airbnb.CheckMFA.validate")}
              </Button>
          </div>

          <ErrorMessage>{error}</ErrorMessage>
      </div>
  );
};
