import {
  PaymentMethodCreateParams,
  Stripe,
  StripeElementsOptions,
  loadStripe,
} from "@stripe/stripe-js";
import { Dispatch, SetStateAction, useEffect } from "react";
import { Params, useNavigate, useParams } from "react-router-dom";
import paths from "../../constants/paths";
import { PaymentReservationStatusEnum } from "../../enums/GETenums";
import { capitalizeFirstLetter } from "../../helpers/stringHelper";
import { usePaymentReservations } from "../../hooks/api/paymentReservation";
import {
  PaymentReservationLinkResponse,
  PaymentReservationScheduleListItemResponse,
} from "../../types/GETTypes";

/**
 *
 * @param setLoading
 * @param paymentDataState
 * @param stripePromiseState
 * @param setStripeOptions
 * @param setBillingDetails
 */
export const useInitPaymentReservationPage = (
  setLoading: Dispatch<SetStateAction<boolean>>,
  paymentDataState: [
    PaymentReservationLinkResponse | undefined,
    Dispatch<SetStateAction<PaymentReservationLinkResponse | undefined>>
  ],
  stripePromiseState: [
    Promise<Stripe | null> | undefined,
    Dispatch<SetStateAction<Promise<Stripe | null> | undefined>>
  ],
  setStripeOptions: Dispatch<SetStateAction<StripeElementsOptions | undefined>>,
  setBillingDetails: Dispatch<
    SetStateAction<PaymentMethodCreateParams.BillingDetails | undefined>
  >
) => {
  const navigate = useNavigate();
  const params = useParams();

  const [paymentData, setPaymentData] = paymentDataState;
  const [stripePromise, setStripePromise] = stripePromiseState;

  useEffect(() => {
    usePaymentReservations(
      params,
      (paymentReservations: PaymentReservationLinkResponse | undefined) => {
        setPaymentData(paymentReservations);
      },
      (message: string | undefined) => navigate(`${paths.DASHBOARD}`),
      () => setLoading(true),
      () => setLoading(false)
    );
  }, [params.reservationId]);

  useEffect(() => {
    if (paymentData) {
      if (
        !stripePromise &&
        paymentData.payment_schedules.payment_schedule.payments_schedule_items
          .length > 0
      ) {
        setStripePromise(
          loadStripe(import.meta.env.VITE_STRIPE_KEY, {
            stripeAccount:
              paymentData.payment_schedules.payment_schedule
                .payments_schedule_items[0].payment_account?.stripe_account_id,
          })
        );
      }
      setStripeOptions({
        locale: "fr",
        clientSecret: paymentData.stripe_client_secret,
      });

      setBillingDetails({
        name: `${capitalizeFirstLetter(
          paymentData.payment_schedules.guest.first_name
        )} ${capitalizeFirstLetter(
          paymentData.payment_schedules.guest.last_name
        )}`,
        address: {
          city: capitalizeFirstLetter(paymentData.payment_schedules.guest.city),
          country: capitalizeFirstLetter(
            paymentData.payment_schedules.guest.country
          ),
        },
      });
    }
  }, [paymentData]);
};

export const useOnValidatePaymentReservation = (
  status: PaymentReservationStatusEnum | undefined,
  params: Readonly<Params<string>>,
  paymentDataState: [
    PaymentReservationLinkResponse | undefined,
    Dispatch<SetStateAction<PaymentReservationLinkResponse | undefined>>
  ]
) => {
  const [paymentData, setPaymentData] = paymentDataState;

  if (status && paymentData) {
    setPaymentData((prevValue) => {
      if (prevValue) {
        return {
          ...prevValue,
          payment_schedules: {
            ...prevValue.payment_schedules,
            payment_schedule: {
              ...prevValue.payment_schedules.payment_schedule,
              payments_schedule_items:
                prevValue.payment_schedules.payment_schedule.payments_schedule_items.map(
                  (v) =>
                    v.payment_link === params.paymentId
                      ? { ...v, payment_status: status }
                      : v
                ),
            },
          },
        };
      }

      return undefined;
    });
  }
};

/**
 *
 * @param paymentData
 * @returns
 */
export const useGetPaymentReservationPrice = (
  urlParams: Readonly<Params<string>>,
  paymentData: PaymentReservationLinkResponse | undefined,
  paymentId?: string
): number => {
  if (paymentData) {
    const paymentScheduleItems =
      paymentData?.payment_schedules.payment_schedule.payments_schedule_items;

    const allPaymentsArePaid =
      paymentScheduleItems.filter(
        (item) => item.payment_status === PaymentReservationStatusEnum.PAID
      ).length === paymentScheduleItems.length;

    if (allPaymentsArePaid) {
      return Number(
        paymentData.payment_schedules.payment_schedule.total_price_value
      );
    } else {
      let currentPaymentReservationItem:
        | PaymentReservationScheduleListItemResponse
        | undefined;
      let id;
      if (paymentId) {
        id = paymentId;
      } else {
        id = urlParams?.paymentId;
      }
      currentPaymentReservationItem = paymentScheduleItems.find(
        (item) => item.payment_link === id
      );

      if (currentPaymentReservationItem) {
        return currentPaymentReservationItem.price_value;
      } else {
        return 0;
      }
    }
  }

  return 0;
};

/**
 *
 * @param paymentData
 * @returns
 */
export const useGetPaymentReservationStatus = (
  urlParams: Readonly<Params<string>>,
  paymentData: PaymentReservationLinkResponse | undefined,
  paymentId?: string
): PaymentReservationStatusEnum | undefined => {
  const findPaymentItem = (
    paymentId: string | undefined,
    paymentData: PaymentReservationLinkResponse | undefined
  ) => {
    return paymentData?.payment_schedules.payment_schedule.payments_schedule_items.find(
      (item) => item.payment_link === paymentId
    );
  };
  const resolvedPaymentId = paymentId ?? urlParams?.paymentId;
  const paymentItem = findPaymentItem(resolvedPaymentId, paymentData);

  return paymentItem?.payment_status;
};
