import React from "react";
import { useTranslation } from "react-i18next";
import { TableListWithCustomItemsHeaderProps } from "../Common/TableListWithCustomItems/TableListWithCustomItems.type";

export const ChecklistTableHeader: React.FC<
  TableListWithCustomItemsHeaderProps
> = () => {
  const { t } = useTranslation();

  return (
    <tr className="bg-element-background border-b-1 border-element-border">
      <th className="w-3/6 py-3 text-sm ps-4 pe-2 text-start">
        <p>{t("Rental.Checklist.List.nameColumn")}</p>
      </th>

      <th className="w-1/6 px-2 py-3 text-sm text-start">
        <p>{t("Rental.Checklist.List.typeColumn")}</p>
      </th>

      <th className="w-1/6 px-2 py-3 text-sm text-start">
        <p>{t("Rental.Checklist.List.stepsColumn")}</p>
      </th>

      <th className="w-1/6 px-2 py-3 text-sm text-start"></th>
    </tr>
  );
};
