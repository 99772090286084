import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import paths from "../../../../constants/paths";
import { get, post } from "../../../../helpers/APIHelper";
import { TextType, ValueType } from "../../../../types/commonTypes";
import { Button } from "../../../Common/Button/Button";
import { CarouselWizard } from "../../../Common/CarouselWizard/CarouselWizard";
import { RightModal } from "../../../Common/RightModal/RightModal";
import { Separator } from "../../../Common/Separator/Separator";
import { ProgressionStepEnum } from "../../../Progression/Progression.type";
import { AirbnbConnectModalProps } from "./AirbnbConnectModal.type";
import { AirbnbConnectStepCheckMFA } from "./AirbnbConnectStepCheckMFA";
import { AirbnbConnectStepCreds } from "./AirbnbConnectStepCreds";
import { AirbnbConnectStepImport } from "./AirbnbConnectStepImport";
import { AirbnbConnectStepOptimization } from "./AirbnbConnectStepOptimization";
import { AirbnbConnectStepSendMFA } from "./AirbnbConnectStepSendMFA";
import { AirbnbConnectStepSummary } from "./AirbnbConnectStepSummary";
import { AirbnbConnectStepValidation } from "./AirbnbConnectStepValidation";

enum AIRBNB_CONNECT_STEPS {
  CREDS = 0,
  VALIDATION,
  SEND_MFA,
  CHECK_MFA,
  IMPORT,
  OPTIMIZATION,
  SUMMARY,
}

export const AirbnbConnectModal: React.FC<AirbnbConnectModalProps> = ({
  isVisible,
  onClose,
  onFinish,
  onSelectRental = (count: number) => {},
}) => {
  const { t } = useTranslation();

  const [DEBUG_RESPONSE_CODE, SET_DEBUG_RESPONSE_CODE] =
    useState<ValueType>("LOGGED_IN");

  const [currentStep, setCurrentStep] = useState<AIRBNB_CONNECT_STEPS>(
    AIRBNB_CONNECT_STEPS.CREDS
  );

  const [loading, setLoading] = useState<boolean>(false);

  const [canSubmit, setCanSubmit] = useState<{
    creds: boolean;
    validation: boolean;
    send_mfa: boolean;
    check_mfa: boolean;
    import: boolean;
    optimization: boolean;
  }>({
    creds: false,
    validation: true,
    send_mfa: true,
    check_mfa: true,
    import: false,
    optimization: false,
  });

  const [creds, setCreds] = useState<{
    email: string | null;
    password: string | null;
    airbnb_account_id: string | null;
  }>({
    email: null,
    password: null,
    airbnb_account_id: null,
  });

  const [apiError, setApiError] = useState<string | null>(null);

  const [validation, setValidation] = useState<{ apiError: string | null }>({
    apiError: null,
  });

  const [sendMFA, setSendMFA] = useState<{
    options: { id: string; friction: string; obfuscated: string }[] | null;
    selectedOption: { id: string; friction: string; obfuscated: string };
    formErrors: {};
    apiError: string | null;
  }>({
    options: null,
    selectedOption: {
      id: "",
      friction: "email_code_verification",
      obfuscated: "",
    },
    apiError: null,
    formErrors: {},
  });

  const [checkMFA, setCheckMFA] = useState<{
    code: string;
    formErrors: { code: string | null };
    apiError: string | null;
  }>({
    code: "",
    apiError: null,
    formErrors: { code: null },
  });

  const [listings, setListings] = useState<{
    apiError: string | null;
    formError: string | null;
    ids: string[];
  }>({ ids: [], apiError: null, formError: null });

  const [pricingCut, setPricingCut] = useState<ValueType | null>(null);

  const goToCredsWithError = (value: TextType) => {
    setCurrentStep(AIRBNB_CONNECT_STEPS.CREDS);
    setApiError(value);
  };

  const manageResponseCode = (
    responseCode: string,
    payload?: any,
    airbnb_account_id?: string
  ) => {
    console.log("manageResponseCode", responseCode, payload);

    if (responseCode === "LOGGED_IN") {
      // Tout s’est bien passé : ???? Vers l'étape d'import ?
      setCurrentStep(AIRBNB_CONNECT_STEPS.IMPORT);
    } else if (responseCode === "INVALID_CREDENTIALS") {
      // Erreur de login => Retour étape Login
      goToCredsWithError(t("Progression.Airbnb.Creds.errorInvalidCred"));
    } else if (responseCode === "AIRLOCK_VALIDATION") {
      setCurrentStep(AIRBNB_CONNECT_STEPS.VALIDATION);
      loopValidationStatus(airbnb_account_id!);
    } else if (responseCode === "HARD_BLOCK") {
      // Erreur de proxy => ????? Voir quoi afficher
      goToCredsWithError(t("Progression.Airbnb.Creds.errorProxy"));
    } else if (responseCode === "MFA_REQUIRED") {
      // => Passer à l’étape Vérification multi facteurs (avec des données supplémentaires, les téléphones, les emails, )
      setSendMFA({
        ...sendMFA,
        options: payload,
        selectedOption:
          payload?.length > 0
            ? payload[0]
            : { id: "", obfuscated: "", friction: "" },
      });
      setCurrentStep(AIRBNB_CONNECT_STEPS.SEND_MFA);
    } else if (responseCode === "MFA_NOT_AVAILABLE") {
      // c'est que ses options de double authentification ne correspondent pas a un email, sms ou appel
      goToCredsWithError(t("Progression.Airbnb.Creds.mfaNotAvailable"));
    } else if (responseCode === "SH_ALREADY_LINKED") {
      // Compte déjà existant => Retour étape Login
      goToCredsWithError(t("Progression.Airbnb.Creds.errorAccountLinked"));
    } else if (responseCode === "SOCIAL_LINKED") {
      // Pas possible si connecté en direct avec Google, … => ??? Quel message à afficher ? Voir avec le support
      goToCredsWithError(t("Progression.Airbnb.Creds.errorSocialLinked"));
    } else if (responseCode === "AIRLOCK_V2_ERROR") {
      // Erreur aléatoire de la part de ABB => Retour étape Login
      goToCredsWithError(t("Progression.Airbnb.Creds.errorAirlockV2"));
    } else if (responseCode === "UNMANAGED") {
      // Erreur système de notre côté => Retour étape Login
      goToCredsWithError(t("Progression.Airbnb.Creds.errorUnmanaged"));
    } else if (responseCode === "INTERNAL_ERROR") {
      // Erreur système ABB => Retour étape Login
      goToCredsWithError(t("Progression.Airbnb.Creds.errorAbb"));
    } else if (responseCode === "ACCOUNT_NOT_FOUND") {
      // Compte existe pas côté SH => Retour étape Login
      goToCredsWithError(t("Progression.Airbnb.Creds.errorAccountNotFound"));
    } else if (responseCode === "ACCOUNT_BLOCKED") {
      // si le compte est ban/ou en attente de document chez Airbnb
      goToCredsWithError(t("Progression.Airbnb.Creds.errorAccountBlocked"));
    } else if (responseCode === "MFA_CODE_SENT") {
      // Etape de confirmation OK => Etape fantome
      setCurrentStep(AIRBNB_CONNECT_STEPS.CHECK_MFA);
    } else if (responseCode === "MFA_CODE_SUCCESS") {
      // Etape fantome valide => Forcer requête de l’étape Login avec email et password save en local
      loginAttempt();
    } else if (responseCode === "MFA_CODE_FAILED") {
      // Etape fantome invalide => Message d’erreur pour qu’il retenté la saisie
      setSendMFA({
        ...sendMFA,
        apiError: "Airbnb error : " + (payload ?? ""),
      });
      setCurrentStep(AIRBNB_CONNECT_STEPS.SEND_MFA);
    } else if (responseCode === "MFA_CODE_SENT_FAILED") {
      // erreur renvoyé par airbnb lors de /mfa/send
      setSendMFA({
        ...sendMFA,
        apiError: t("Progression.Airbnb.Creds.errorMfaSentFailed"),
      });
      setCurrentStep(AIRBNB_CONNECT_STEPS.SEND_MFA);
    } else if (responseCode === "MFA_CODE_EXPIRED") {
      // erreur quand l'envoi du code est éxpiré
      setSendMFA({
        ...sendMFA,
        apiError: t("Progression.Airbnb.Creds.errorCodeExpired"),
      });
      setCurrentStep(AIRBNB_CONNECT_STEPS.SEND_MFA);
    } else if (responseCode === "SH_VALIDATION_WAITING") {
      // Attendre
      setCurrentStep(AIRBNB_CONNECT_STEPS.VALIDATION);
    } else if (responseCode === "SH_VALIDATION_DONE") {
      loginAttempt();
    }
  };

  const handleDebugResponseCode = () => {
    let payload = null;

    if (DEBUG_RESPONSE_CODE.toString() === "MFA_REQUIRED") {
      payload = [
        {
          friction: "PHONE_VERIFICATION_VIA_TEXT",
          id: "67160775",
          obfuscated: "+33 • •• •• 73 40",
        },
        {
          friction: "PHONE_VERIFICATION_VIA_TEXT",
          id: "67160774",
          obfuscated: "+33 • •• •• 73 41",
        },
        {
          friction: "PHONE_VERIFICATION_VIA_CALL",
          id: "67160772",
          obfuscated: "+33 • •• •• 73 40",
        },
        {
          friction: "EMAIL_CODE_VERIFICATION",
          id: "123456890",
          obfuscated: "nico***@***.com",
        },
      ];
    }

    manageResponseCode(DEBUG_RESPONSE_CODE.toString(), payload);
  };

  const handleLogin = (email: string, password: string) => {
    setCreds({
      ...creds,
      email,
      password,
    });
  };

  useEffect(() => {
    if (creds.email && creds.password) {
      loginAttempt();
    }
  }, [creds.email, creds.password]);

  const loginAttempt = async () => {
    console.log("loginAttempt");
    setLoading(true);

    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.AIRBNB.LOGIN}`,
      {
        email: creds.email,
        password: creds.password,
      }
    );

    console.log(response);

    if (response?.data?.success) {
      const payload = response.data?.result?.mfa_options;

      setCreds({
        ...creds,
        airbnb_account_id: response.data?.result?.airbnb_account_id,
      });

      manageResponseCode(
        response.data?.result?.response_code,
        payload,
        response.data?.result?.airbnb_account_id
      );

      setCanSubmit((prevValue) => {
        return { ...prevValue, creds: true };
      });
    } else {
      setApiError(response?.response?.data?.message);
      setCurrentStep(AIRBNB_CONNECT_STEPS.CREDS);
    }

    setLoading(false);
  };

  const loopValidationStatus = (airbnb_account_id: string) => {
    console.log("handleValidationStatus");

    setValidation({ ...validation, apiError: null });

    const interval = setInterval(async () => {
      const response = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.AIRBNB.STATUS}`,
        { params: { airbnb_account_id: airbnb_account_id } }
      );

      console.log("setInterval status", response);

      if (
        response.data?.success &&
        response.data?.result?.response_code === "SH_VALIDATION_DONE"
      ) {
        clearInterval(interval);
        manageResponseCode(response.data?.result?.response_code);
      } else {
        setValidation({
          ...loopValidationStatus,
          apiError: response?.response?.data?.message,
        });
      }
    }, 3 * 1000);
  };

  const handleSendMFA = async () => {
    setLoading(true);
    setSendMFA({ ...sendMFA, apiError: null });

    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.AIRBNB.SEND_MFA}`,
      {
        airbnb_account_id: creds.airbnb_account_id,
        mfa_option: sendMFA.selectedOption.friction,
        phone_number_id:
          sendMFA.selectedOption.friction.toLowerCase() ===
          "email_code_verification"
            ? null
            : sendMFA.selectedOption.id,
      }
    );

    console.log("handleSendMFA", response);

    if (response.data?.success) {
      manageResponseCode(response.data?.result?.response_code);
    } else {
      setSendMFA({
        ...sendMFA,
        apiError: response?.response?.data?.message,
      });
    }

    setLoading(false);
  };

  const handleCheckMFA = async () => {
    if (checkMFA.code === "") {
      setCheckMFA({
        ...checkMFA,
        formErrors: { code: t("Progression.Airbnb.CheckMFA.errorCode") },
      });
      return;
    }

    setLoading(true);

    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.AIRBNB.CHECK_MFA}`,
      {
        airbnb_account_id: creds.airbnb_account_id,
        mfa_option: sendMFA.selectedOption.friction,
        phone_number_id: sendMFA.selectedOption.id,
        code: checkMFA.code,
      }
    );

    console.log("handleCheckMFA", response);

    if (response.data?.success) {
      manageResponseCode(response.data?.result?.response_code);
    } else {
      setCheckMFA({
        ...checkMFA,
        apiError: response?.response?.data?.message,
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    onSelectRental(listings.ids.length);
  }, [listings.ids]);

  const handleListingsSelectionChange = (ids: string[]) => {
    setListings({ ...listings, ids, formError: null });

    console.log("handleListingsSelectionChange", ids);
  };

  const handleImport = () => {
    setCanSubmit((prevValue) => {
      return { ...prevValue, import: true };
    });

    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleOptimization = (pricingCut: number) => {
    setCurrentStep(AIRBNB_CONNECT_STEPS.SUMMARY);

    setCanSubmit((prevValue) => {
      return { ...prevValue, optimization: true };
    });
    setPricingCut(pricingCut);
  };

  const startImport = async () => {
    if (Object.values(canSubmit).every(Boolean)) {
      console.log("IMPORT");

      if (listings?.ids?.length === 0) {
        setListings({
          ...listings,
          formError: t("Progression.Airbnb.Listings.errorRequired"),
          apiError: null,
        });
        return;
      } else {
        setListings({
          ...listings,
          formError: null,
          apiError: null,
        });
      }

      setLoading(true);

      post(
        `${import.meta.env.VITE_API_URL}${paths.API.COMPLETE_PROGRESSION_STEP}`,
        {
          progression_step: ProgressionStepEnum.STEP_CONNECT_AIRBNB,
          airbnb_account_id: creds.airbnb_account_id,
          listing_ids: listings.ids.toString(),
          pricing_cut: pricingCut,
        }
      );

      // RESET VALUES
      setCurrentStep(AIRBNB_CONNECT_STEPS.CREDS);
      setCanSubmit({
        creds: false,
        validation: true,
        send_mfa: true,
        check_mfa: true,
        import: false,
        optimization: false,
      });
      setPricingCut(null);
      setCreds({
        email: null,
        password: null,
        airbnb_account_id: null,
      });

      setLoading(false);
      onFinish();
    }
  };

  return (
    <RightModal
      isVisible={isVisible}
      onClose={onClose}
      isLarge={false}
      title={t("Progression.airbnbModalTitle")}
      closeOnEmpty={false}
    >
      <div className="flex flex-col w-full">
        {/* DEBUG */}
        {/* <div className="flex gap-2 pb-4">
          <InputSelect
            items={[
              { value: "LOGGED_IN", label: "LOGGED_IN" },
              { value: "INVALID_CREDENTIALS", label: "INVALID_CREDENTIALS" },
              { value: "AIRLOCK_VALIDATION", label: "AIRLOCK_VALIDATION" },
              { value: "HARD_BLOCK", label: "HARD_BLOCK" },
              { value: "MFA_REQUIRED", label: "MFA_REQUIRED" },
              { value: "MFA_NOT_AVAILABLE", label: "MFA_NOT_AVAILABLE" },
              { value: "SH_ALREADY_LINKED", label: "SH_ALREADY_LINKED" },
              { value: "SOCIAL_LINKED", label: "SOCIAL_LINKED" },
              { value: "AIRLOCK_V2_ERROR", label: "AIRLOCK_V2_ERROR" },
              { value: "UNMANAGED", label: "UNMANAGED" },

              { value: "INTERNAL_ERROR", label: "INTERNAL_ERROR" },
              { value: "ACCOUNT_NOT_FOUND", label: "ACCOUNT_NOT_FOUND" },
              { value: "ACCOUNT_BLOCKED", label: "ACCOUNT_BLOCKED" },

              { value: "MFA_CODE_SENT", label: "MFA_CODE_SENT" },
              { value: "MFA_CODE_SUCCESS", label: "MFA_CODE_SUCCESS" },
              { value: "MFA_CODE_FAILED", label: "MFA_CODE_FAILED" },
              { value: "MFA_CODE_SENT_FAILED", label: "MFA_CODE_SENT_FAILED" },
              { value: "MFA_CODE_EXPIRED", label: "MFA_CODE_EXPIRED" },

              {
                value: "SH_VALIDATION_WAITING",
                label: "SH_VALIDATION_WAITING",
              },
              { value: "SH_VALIDATION_DONE", label: "SH_VALIDATION_DONE" },
            ]}
            selectedValue={DEBUG_RESPONSE_CODE}
            onSelect={(value) => SET_DEBUG_RESPONSE_CODE(value)}
          />

          <div>
            <Button type="secondary" onClick={handleDebugResponseCode}>
              TEST RESPONSE CODE
            </Button>
          </div>
        </div> */}

        <div className="flex-1 overflow-y-scroll">
          <CarouselWizard
            disableClickBefore={true}
            currentStepIndex={currentStep}
            onChangeStep={(value) => setCurrentStep(value)}
            steps={[
              {
                title: t("Progression.Airbnb.Creds.title"),
                Node: (
                  <AirbnbConnectStepCreds
                    onNext={handleLogin}
                    loading={loading}
                    apiError={apiError}
                  />
                ),
              },
              {
                title: t("Progression.Airbnb.Validation.title"),
                Node: (
                  <AirbnbConnectStepValidation error={validation.apiError} />
                ),
              },
              {
                title: t("Progression.Airbnb.SendMFA.title"),
                Node: (
                  <AirbnbConnectStepSendMFA
                    options={sendMFA.options}
                    selectedOption={sendMFA.selectedOption}
                    onSelectOption={(value: any) =>
                      setSendMFA({ ...sendMFA, selectedOption: value })
                    }
                    onNext={handleSendMFA}
                    error={sendMFA.apiError}
                    loading={loading}

                  />
                ),
              },
              {
                title: t("Progression.Airbnb.CheckMFA.title"),
                Node: (
                  <AirbnbConnectStepCheckMFA
                    code={checkMFA.code}
                    error={checkMFA.apiError}
                    codeError={checkMFA.formErrors.code}
                    loading={loading}
                    onChangeCode={(value) =>
                      setCheckMFA({
                        ...checkMFA,
                        code: value,
                        formErrors: { code: null },
                      })
                    }
                    onNext={handleCheckMFA}
                    OnReset={handleSendMFA}
                  />
                ),
              },
              {
                title: t("Progression.airbnbConnectStep5Title"),
                Node: (
                  <AirbnbConnectStepImport
                    airbnb_account_id={creds.airbnb_account_id}
                    apiLoading={loading}
                    apiError={listings.apiError}
                    formError={listings.formError}
                    onSelectionChange={handleListingsSelectionChange}
                    onNext={handleImport}
                  />
                ),
              },
              {
                title: t("Progression.airbnbConnectStep6Title"),
                Node: (
                  <AirbnbConnectStepOptimization onNext={handleOptimization} />
                ),
              },
              {
                title: t("Progression.airbnbConnectStep7Title"),
                Node: (
                  <AirbnbConnectStepSummary
                    nbRentals={listings.ids?.length}
                    email={creds.email!}
                    priceCut={pricingCut!}
                  />
                ),
              },
            ]}
          />
        </div>

        <Separator />
        <div className="flex gap-x-3">
          <Button type="secondary" onClick={onClose}>
            {t("Global.cancel")}
          </Button>
          <Button
            RightIcon={CheckIcon}
            disabled={!Object.values(canSubmit).every(Boolean)}
            onClick={startImport}
          >
            {t("Progression.startImport")}
          </Button>
        </div>

        {/* <div className="mt-4">
          {currentStep === AIRBNB_CONNECT_STEPS.CREDS && (
            <Button onClick={handleLogin} loading={loading}>
              {t("Progression.airbnbConnect")}
            </Button>
          )}

          {currentStep === AIRBNB_CONNECT_STEPS.VALIDATION && (
            <Button onClick={handleLogin} loading={true}></Button>
          )}

          {currentStep === AIRBNB_CONNECT_STEPS.SEND_MFA && (
            <Button onClick={handleSendMFA} loading={loading}>
              {t("Progression.Airbnb.SendMFA.send")}
            </Button>
          )}

          {currentStep === AIRBNB_CONNECT_STEPS.CHECK_MFA && (
            <Button onClick={handleCheckMFA} loading={loading}>
              {t("Progression.Airbnb.CheckMFA.confirm")}
            </Button>
          )}

          {currentStep === AIRBNB_CONNECT_STEPS.IMPORT && (
            <Button onClick={handleImport} loading={loading}>
              {i18n.t("Progression.import", {
                count: listings.ids?.length ?? 0,
                s: listings?.ids?.length > 1 ? "s" : "",
              })}
            </Button>
          )}
        </div> */}
      </div>
    </RightModal>
  );
};
