import React, {useEffect, useState} from "react";
import {
  EditProfilForm,
  EditProfilProps,
} from "../../pages/Settings/Settings.type";
import {Controller, useForm} from "react-hook-form";
import CheckIcon from "../../assets/icons/check-white.svg?react";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg?react";
import {Card} from "../Common/Card/Card";
import {useTranslation} from "react-i18next";
import EditIcon from "../../assets/icons/edit.svg?react";
import CloseIcon from "../../assets/icons/close.svg?react";
import UserIcon from "../../assets/icons/user.svg?react";
import {TextInput} from "../Common/TextInput/TextInput";
import {PhoneInput} from "../Common/PhoneInput/PhoneInput";
import {Separator} from "../Common/Separator/Separator";
import {RequiredFields} from "../../types/commonTypes";
import {InputSelect} from "../Common/InputSelect/InputSelect";
import timeZoneList from "../../res/timeZone";
import languages from "../../res/languages";
import countries from "../../constants/countries";
import {post} from "../../helpers/APIHelper";
import moment from "moment";
import {ErrorMessage} from "../Common/ErrorMessage/ErrorMessage";
import {SettingSkeleton} from "./SettingSkeleton";
import {PictureInput} from "../Common/PictureInput/PictureInput";
import {Button} from "../Common/Button/Button";
import {CenterModal} from "../Common/CenterModal/CenterModal";

export const EditProfilCard: React.FC<EditProfilProps> = ({user, onUpdate}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const {t} = useTranslation();
  const {
    control,
    register,
    handleSubmit,
    formState: {isValid, errors},
    getValues,
    setValue,
    watch,
  } = useForm<EditProfilForm>({
    mode: "all",
    photo: user?.photo || null,
  });

  const [currentFile, setCurrentFile] = useState<any>(null);
  const [currentPath, setCurrentPath] = useState<string | undefined | null>(
    user?.photo
  );

  const initSubmit = () => {
    setError("");
    setValidationLoading(true);
  };
  const updateProfil = async () => {
    initSubmit();
    const response = await post(
      `${import.meta.env.VITE_API_URL}/user/update/${user.id}`,
      {
        first_name: getValues("first_name"),
        last_name: getValues("last_name"),
        address: getValues("address"),
        city: getValues("city"),
        postal_code: getValues("postal_code"),
        phone: getValues("phone"),
        email: getValues("email"),
        locale: getValues("locale"),
        country_code: countries.find(
          (country) => country.value === getValues("country_id")
        )?.code,
        time_zone: getValues("time_zone"),
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (typeof getValues("photo") !== "undefined") {
      updateProfilPicture(getValues("photo") as any);
    }

    if (response.data?.success) {
      onUpdate(response.data.result);
      setValidationLoading(false);
      setEditMode(false);
    } else {
      setError(response.data?.message);
      setValidationLoading(false);
    }
  };

  const updateProfilPicture = async (file: Blob | string) => {
    initSubmit();
    const formData = new FormData();
    formData.append("photo", file);

    try {
      const response = await post(
        `${import.meta.env.VITE_API_URL}/user/update-picture/${user.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data?.success) {
        onUpdate(response.data.result);
        setValidationLoading(false);
      } else {
        setError(response.data?.message);
        setValidationLoading(false);
      }
    } catch (e) {
      setError(t("Global.unknownErrorPhoto"));
      setValidationLoading(false);
    }
  };

  const handleProfilEdit = () => {
    if (editMode) {
      updateProfil(user?.photo);
    } else {
      setEditMode(true);
    }
  };

  const [isDeletePictureVisible, setIsDeletePictureVisible] =
    useState<boolean>(false);

  const handleSubmitPicture = () => {
    setIsDeletePictureVisible(false);
    setCurrentPath(null);
    setCurrentFile(null);
    updateProfilPicture("");
  };

  const UpdatePicture = (file: Blob | string) => {
    setCurrentFile(file as any);
    setCurrentPath(URL.createObjectURL(file[0]));
    setValue("photo", file[0], {shouldValidate: true});
  };

  useEffect(() => {
    if (user && editMode === true) {
      setValue("first_name", user?.first_name);
      setValue("last_name", user?.last_name);
      setValue("address", user?.address);
      setValue("city", user?.city);
      setValue("postal_code", user?.postal_code);
      setValue("phone", user?.phone);
      setValue("email", user?.email);
      setValue("time_zone", user?.time_zone);
      setValue("locale", user?.locale);
      setValue("country_id", user?.country_id);
    }
    console.log(user?.photo, "user?.photo");
    setCurrentPath(user?.photo);
  }, [user, editMode]);

  const requiredFields: RequiredFields<EditProfilForm> = {
    first_name: true,
    last_name: true,
    email: true,
    address: true,
    city: true,
    postal_code: true,
    phone: true,
    time_zone: true,
    country_id: true,
    photo: true,
    locale: true,
  };

  if (typeof user === "undefined") {
    return <SettingSkeleton page={"user_information"} />;
  }

  return (
    <Card
      Icon={UserIcon}
      label={t("Global.user_information")}
      loading={validationLoading}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        disabled: editMode && (validationLoading || !isValid),
        onClick: editMode ? handleSubmit(handleProfilEdit) : handleProfilEdit,
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => setEditMode(false),
            }
          : undefined
      }
    >
      {editMode ? (
        <div className={"flex flex-col gap-3"}>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <div className="flex  flex-row space-x-4">
            <div>
              <p className="mb-1 text-sm font-semibold text-left">
                {t("Booking.Guest.photoLabel")}
              </p>
              <PictureInput
                mode="picture"
                photoPath={currentPath}
                onChangeFile={(file) => UpdatePicture(file)}
                displayModal={isDeletePictureVisible}
                setDisplayModal={setIsDeletePictureVisible}
              />

              <CenterModal
                isVisible={isDeletePictureVisible}
                onClose={() => setIsDeletePictureVisible(false)}
              >
                <div className="flex flex-col gap-10">
                  <div className="flex flex-row  pb-0">
                    <p className="text-xl font-extrabold">
                      {t("Global.deletePictureTitle")}
                    </p>

                    <div
                      className="cursor-pointer"
                      onClick={() => setIsDeletePictureVisible(false)}
                    >
                      <CloseIcon width={18} height={16} />
                    </div>
                  </div>

                  <div className="flex gap-3">
                    <Button
                      type="secondary"
                      onClick={() => {
                        setIsDeletePictureVisible(false);
                      }}
                    >
                      {t("Global.cancel")}
                    </Button>
                    <Button
                      type="destructive"
                      onClick={() => {
                        handleSubmitPicture();
                      }}
                    >
                      {t("Global.removeWithConfirmation")}
                    </Button>
                  </div>
                </div>
              </CenterModal>
            </div>

            <div className="flex flex-col w-[90%]">
              <TextInput
                disabled={validationLoading}
                label={t("Profil.firstName")}
                required={requiredFields.first_name}
                register={register("first_name", {
                  required: {
                    value: requiredFields.first_name,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Rental.Infos.Infos.nameFieldLabel"),
                    }),
                  },
                })}
              />
              <TextInput
                disabled={validationLoading}
                label={t("Profil.lastName")}
                required={requiredFields.first_name}
                value={user?.last_name}
                register={register("last_name", {
                  required: {
                    value: requiredFields.last_name,
                    message: t("Global.Errors.requiredField", {
                      fieldName: t("Rental.Infos.Infos.nameFieldLabel"),
                    }),
                  },
                })}
              />
            </div>
          </div>

          <div className="flex gap-3">
            <TextInput
              disabled={validationLoading}
              label={t("Adresse")}
              value={user?.address}
              required={requiredFields.address}
              register={register("address", {
                required: {
                  value: requiredFields.address,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Rental.Infos.Infos.nameFieldLabel"),
                  }),
                },
              })}
            />
            <TextInput
              disabled={validationLoading}
              label={t("Ville")}
              value={user?.city}
              required={requiredFields.city}
              register={register("city", {
                required: {
                  value: requiredFields.city,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Rental.Infos.Infos.nameFieldLabel"),
                  }),
                },
              })}
            />
          </div>

          <div className="flex gap-3">
            <TextInput
              disabled={validationLoading}
              label={t("Profil.postal_code")}
              value={user?.postal_code}
              required={requiredFields.postal_code}
              register={register("postal_code", {
                required: {
                  value: requiredFields.postal_code,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Rental.Infos.Infos.nameFieldLabel"),
                  }),
                },
              })}
            />
            <Controller
              control={control}
              name="country_id"
              rules={{
                required: {
                  value: requiredFields.country_id,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Profil.country"),
                  }),
                },
                onChange: (event) => {
                  setValue("country_id", event.target.value);
                },
              }}
              render={({field: {onChange}}) => (
                <div className="flex-1">
                  <InputSelect
                    size={"small"}
                    required={requiredFields.country_id}
                    disabled={validationLoading}
                    label={t("Profil.country")}
                    selectedValue={getValues("country_id")}
                    items={countries.sort((a, b) =>
                      a.label.localeCompare(b.label)
                    )}
                    onSelect={onChange}
                  />
                </div>
              )}
            />
          </div>
          <Separator />
          <div className="flex gap-3">
            <TextInput
              disabled={true}
              label={t("Profil.email")}
              value={user?.email}
              required={requiredFields.email}
            />
            <Controller
              control={control}
              name="phone"
              render={({field: {onChange, value}}) => (
                <PhoneInput
                  size={"normal"}
                  label={t("Profil.phoneNumber")}
                  value={user?.phone}
                  placeholder="06 11 23 58 13"
                  onChangeText={onChange}
                />
              )}
            />
          </div>
          <div className="flex gap-3">
            <Controller
              control={control}
              name="time_zone"
              rules={{
                required: {
                  value: requiredFields.time_zone,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Profil.timeZone"),
                  }),
                },
                onChange: (event) => {
                  setValue("time_zone", event.target.value);
                },
              }}
              render={({field: {onChange}}) => (
                <div className="flex-1">
                  <InputSelect
                    required={requiredFields.time_zone}
                    disabled={validationLoading}
                    label={t("Profil.timeZone")}
                    selectedValue={getValues("time_zone")}
                    items={timeZoneList}
                    onSelect={onChange}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="locale"
              rules={{
                required: {
                  value: requiredFields.locale,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Profil.language_for_communication"),
                  }),
                },
                onChange: (event) => {
                  setValue("locale", event.target.value);
                },
              }}
              render={({field: {onChange}}) => (
                <div className="flex-1">
                  <InputSelect
                    required={requiredFields.time_zone}
                    disabled={validationLoading}
                    label={t("Profil.languageForCommunication")}
                    selectedValue={getValues("locale")}
                    items={languages}
                    onSelect={onChange}
                  />
                </div>
              )}
            ></Controller>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <div className="flex flex-row space-x-2 items-center">
              <div className="w-8 h-8 rounded-full border-1 border-element-border bg-slate-200">
                <img src={user?.photo!} className="rounded-full w-8 h-8 " />
              </div>
              <div className="flex-1">
                {user ? (
                  <p className="text-sm font-semibold text-high-contrast">
                    {user?.first_name} {user?.last_name}
                  </p>
                ) : (
                  <p className="w-full h-2 mt-1 text-sm font-semibold rounded text-high-contrast bg-slate-300"></p>
                )}
                <p className="text-low-contrast text-[12px] font-semibold">
                  Paris, france
                </p>
              </div>
            </div>
            <p className="text-low-contrast font-['Inter'] text-xs">
              {t("Settings.member_since")}{" "}
              {moment(user?.created_at).locale("fr").format("MMMM YYYY")}
            </p>
          </div>
          <Separator />

          <div className="flex flex-col space-y-6">
            <div className="flex items-start space-x-36">
              <div className="data flex flex-col items-start gap-1 w-1/2">
                <p className="label self-stretch text-[#6f6f6f] font-['Inter'] text-xs">
                  E-mail
                </p>
                <p className="value self-stretch text-neutral-900 font-['Inter'] text-sm font-semibold leading-5">
                  {user?.email}
                </p>
              </div>
              <div className="data-1 flex flex-col items-start gap-1 w-1/2">
                <p className="label-1 self-stretch text-[#6f6f6f] font-['Inter'] text-xs">
                  {t("Profil.phoneNumber")}
                </p>
                <p className="value-1 self-stretch text-neutral-900 font-['Inter'] text-sm font-semibold leading-5">
                  {user?.phone}
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-36">
              <div className="data flex flex-col items-start gap-1 w-1/2">
                <p className="label self-stretch text-[#6f6f6f] font-['Inter'] text-xs">
                  {t("Dashboard.timeZone")}
                </p>
                <p className="value self-stretch text-neutral-900 font-['Inter'] text-sm font-semibold">
                  {
                    timeZoneList.find(
                      (timeZone) => timeZone.value === user?.time_zone
                    )?.label
                  }
                </p>
              </div>
              <div className="data-1 flex flex-col items-start gap-1 w-1/2">
                <p className="label-1 self-stretch text-[#6f6f6f] font-['Inter'] text-xs">
                  {t("Profil.languageForCommunication")} {user?.local}
                </p>
                <p className="value-1 self-stretch text-neutral-900 font-['Inter'] text-sm font-semibold leading-5">
                  {
                    languages.find(
                      (language) => language.value === user?.locale
                    )?.label
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};
