import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../../../Common/Button/Button";
import {InputSelect} from "../../../Common/InputSelect/InputSelect";
import {RightModal} from "../../../Common/RightModal/RightModal";
import {Separator} from "../../../Common/Separator/Separator";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {
  RentalPlatformsBookingSettingsModalForm,
  RentalPlatformsBookingSettingsProps,
} from "./Platforms.type";

import {Controller, useForm} from "react-hook-form";
import CheckWhiteIcon from "../../../../assets/icons/check-white.svg?react";
import LinkIcon from "../../../../assets/icons/link.svg?react";
import paths from "../../../../constants/paths";
import {get} from "../../../../helpers/APIHelper";
import {RequiredFields, ValueType} from "../../../../types/commonTypes";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {CenterModal} from "../../../Common/CenterModal/CenterModal";

export const RentalPlatformsBookingSettingsModal: React.FC<
  RentalPlatformsBookingSettingsProps
> = ({
  platformBooking,
  isVisible,
  onClose,
  setPlatform,
  setOpenDesynchronizeModal,
}) => {
  const {t} = useTranslation();
  const {
    room_id,
    room_name,
    hotel_id,
    rate_id,
    rate_name,
    enabled,
    listing_link,
  } = platformBooking || {};

  const isConnected = platformBooking !== null || Boolean(enabled);

  const openConfirmModal = () => {
    setPlatform("booking");
    setOpenDesynchronizeModal(true);
  };

  return (
    <RightModal
      isVisible={isVisible}
      onClose={onClose}
      title={t("Rental.Infos.Platforms.Booking.title")}
    >
      <div className="flex flex-col w-full">
        <div className="flex-1 space-y-3">
          <a href={listing_link ?? ""} target="_blank">
            <Button
              RightIcon={LinkIcon}
              type="secondary"
              disabled={!isConnected}
            >
              {t("Rental.Infos.Platforms.Booking.view")}
            </Button>
          </a>

          <div className="flex items-end gap-x-3">
            <TextInput
              label={t("Rental.Infos.Platforms.Booking.hotelId")}
              placeholder={`${hotel_id}`}
              disabled={true}
            />

            <div>
              <Button type="secondary" size="small" disabled={true}>
                {t("Rental.Infos.Platforms.Booking.getCode")}
              </Button>
            </div>
          </div>

          <TextInput
            label={t("Rental.Infos.Platforms.Booking.roomId")}
            placeholder={`${room_id} - ${room_name}`}
            disabled={true}
          />

          <TextInput
            label={t("Rental.Infos.Platforms.Booking.rate")}
            placeholder={`${rate_id} - ${rate_name}`}
            disabled={true}
          />
        </div>

        <p className="font-semibold cursor-pointer text-icon-active hover:underline">
          <a
            href="https://help.superhote.com/support/solutions/articles/150000113541-synchroniser-et-importer-les-logements-depuis-booking-vers-superhote"
            target="_blank"
          >
            {t("Rental.Infos.Platforms.Booking.tutorial")}
          </a>
        </p>

        <Separator />

        <div className="flex gap-x-3">
          <Button type="secondary" onClick={onClose}>
            {t("Global.cancel")}
          </Button>
          <Button type="alert" onClick={openConfirmModal}>
            {t("Global.desynchronize")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
