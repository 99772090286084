import React from "react";
import { useTranslation } from "react-i18next";

export const ConversationListItemSkeleton: React.FC<{}> = ({}) => {
  return (
    <div className="flex flex-row items-center cursor-pointer hover:bg-element-background/40 animate-pulse">
      {/* Avatar & Platform */}
      <div className="p-4 relative">
        <div className="w-12 h-12 rounded-full bg-slate-300" />

        <div
          className="absolute left-12 top-12 bg-slate-200 rounded-full"
          style={{ width: 20, height: 20 }}
        />
      </div>
      {/* Other infos */}
      <div className="flex-1 flex-col p-4 pl-0 pt-2">
        <div className="flex flex-row gap-2 text-sm">
          <p className="w-full bg-slate-200 h-2 rounded"></p>

          <p
            className={`flex flex-row items-center space-x-1 h-2 rounded w-16 bg-slate-300`}
          ></p>
        </div>

        <p className="text-high-contrast text-base font-semibold mt-1 h-2 bg-slate-300 rounded"></p>

        <p className={`text-base bg-slate-200 mt-1 h-2 rounded w-full`}></p>
        <p className={`text-base bg-slate-200 mt-1 h-2 rounded w-full`}></p>

        <p className="flex text-low-contrast text-sm mt-1 font-light items-center gap-1">
          <p className={`text-base bg-slate-200 h-2 rounded w-8`}></p>
          <p className={`text-base bg-slate-200 h-2 rounded w-12`}></p>
        </p>
      </div>
    </div>
  );
};
