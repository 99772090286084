import React from "react";
import { useTranslation } from "react-i18next";
import { CenterModal } from "../../../Common/CenterModal/CenterModal";
import { Button } from "../../../Common/Button/Button";
import { ValueType } from "../../../../types/commonTypes";
import { PaymentReservationScheduleListItemResponse } from "../../../../types/GETTypes";

export const PaymentReservationListModalRemove: React.FC<{
  isVisible: boolean;
  paymentReservationDetail:
    | PaymentReservationScheduleListItemResponse
    | undefined
    | null;
  onClose: () => void;
  onRemovePaymentReservation: (id: ValueType) => void;
}> = ({
  isVisible,
  paymentReservationDetail,
  onClose,
  onRemovePaymentReservation,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
    <CenterModal isVisible={isVisible} onClose={handleClose}>
      <div className="flex flex-col gap-5">
        <p className="text-xl font-extrabold">
          {t("Payments.PaymentReservationList.removeModalTitle")}
        </p>
        <p className="text-sm text-low-contrast">
          {t("Payments.PaymentReservationList.removeModalDescription")}
        </p>
        <div className="flex gap-3">
          <Button type="secondary" onClick={handleClose}>
            {t("Global.cancel")}
          </Button>
          <div title={t("Global.wip")}>
            <Button
              type="destructive"
              onClick={() =>
                onRemovePaymentReservation(Number(paymentReservationDetail?.id))
              }
              disabled={true}
            >
              {t("Global.removeWithConfirmation")}
            </Button>
          </div>
        </div>
      </div>
    </CenterModal>
  );
};
