import React from "react";
import { TableListHeaderProps } from "../Common/TableList/TableList.type";
import { useTranslation } from "react-i18next";

export const AutoMessageListHeader: React.FC<TableListHeaderProps> = () => {
  const { t } = useTranslation();

  return (
    <tr className="bg-element-background border-b-1 border-element-border">
      <th className="w-24 px-2 py-2 text-sm rounded-tl-6px">
        <div className={`flex flex-row items-center`}>
          <p>{t("AutoMessageList.status")}</p>
        </div>
      </th>

      <th className={`px-2 py-2 text-sm`}>
        <div className="flex flex-row items-center">
          <p>{t("AutoMessageList.name")}</p>
        </div>
      </th>

      <th className={`px-2 py-2 text-sm`}>
        <div className="flex flex-row items-center">
          <p>{t("AutoMessageList.trigger")}</p>
        </div>
      </th>
      <th className={`px-2 py-2 text-sm`}>
        <div className="flex flex-row items-center">
          <p>{t("AutoMessageList.rentals")}</p>
        </div>
      </th>
      <th className="px-2 py-2 text-sm">
        <div className="flex flex-row items-center">
          <p>{t("AutoMessageList.channel")}</p>
        </div>
      </th>

      <th className="px-2 py-2 text-sm w-32 rounded-tr-6px">
        <div className="flex flex-row items-center">
          <p>{t("AutoMessageList.actions")}</p>
        </div>
      </th>
    </tr>
  );
};
