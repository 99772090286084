import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import PlusIcon from "../../../../assets/icons/plus.svg?react";
import SendMoneyIcon from "../../../../assets/icons/send-money-2.svg?react";
import { Card } from "../../../Common/Card/Card";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { PaymentPolicyResponse } from "../../../Payment/Payment.type";
import { RentalPaymentPolicyCardProps } from "./PaymentPolicy.type";
import { RentalPaymentPolicyCardSkeleton } from "./PaymentPolicyCardSkeleton";
import { RentalPaymentPolicyItem } from "./PaymentPolicyItem";
import useUserStore from "../../../../stores/useUserStore";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";

export const RentalPaymentPolicyCard: React.FC<
  RentalPaymentPolicyCardProps
> = ({
  loading,
  rentalId,
  paymentPolicies,
  workspaceOwner,
  onClickActionButton,
  onClickItem,
  onUpdateFavoritePaymentPolicy,
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  const isDisabled = () => {
    return !getCurrentCanEdit({ user: userStore.user, workspaceOwner });
  };

  const [
    updateFavoritePaymentPolicyError,
    setUpdateFavoritePaymentPolicyError,
  ] = useState<string | undefined>();

  const getPaymentPolicyItem = (
    pp: PaymentPolicyResponse,
    key: number
  ): ReactNode => {
    return (
      <RentalPaymentPolicyItem
        key={key}
        index={key}
        item={pp}
        rentalId={rentalId}
        onClick={(value) => !isDisabled() && onClickItem(value)}
        onUpdateFavoritePaymentPolicy={onUpdateFavoritePaymentPolicy}
        onUpdateFavoritePaymentPolicyError={(message: string | undefined) =>
          setUpdateFavoritePaymentPolicyError(message)
        }
      />
    );
  };

  if (loading) return <RentalPaymentPolicyCardSkeleton />;

  return (
    <Card
      loading={loading}
      Icon={SendMoneyIcon}
      label={t("Rental.Pricing.PaymentPolicy.title")}
      button={{
        Icon: PlusIcon,
        disabled: isDisabled(),
        onClick: onClickActionButton,
        skeleton: loading,
      }}
      hasBody={Array.isArray(paymentPolicies) && paymentPolicies.length > 0}
    >
      {!loading ? (
        <div className="flex flex-col gap-y-3">
          <ErrorMessage>{updateFavoritePaymentPolicyError}</ErrorMessage>
          {/* -- WHILE PAYMENT POLICIES -- */}
          {Array.isArray(paymentPolicies) && paymentPolicies.length > 0
            ? paymentPolicies.map((pp, key) => getPaymentPolicyItem(pp, key))
            : null}
        </div>
      ) : null}
    </Card>
  );
};
