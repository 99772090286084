import React from "react";
import { useTranslation } from "react-i18next";
import { FacilitiesGroupType } from "./Facilities.type";

export const RentalFacilitiesViewGroupSkeleton: React.FC<{
  group: FacilitiesGroupType;
}> = ({ group }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col my-3 pb-3 border-b-1 border-element-border last:border-0 gap-2 animate-pulse">
      <div className="flex gap-2 items-center">
        {group.Icon && <group.Icon className="w-4 h-4" />}

        <p className="text-high-contrast font-semibold">{t(group.nameI18n)}</p>
      </div>

      <div className="grid grid-cols-2 px-4">
        {Array.from({ length: 5 }).map((f, index) => (
          <p key={index} className="w-52 h-2 bg-slate-200 mt-2 rounded"></p>
        ))}
      </div>
    </div>
  );
};
