import React from "react";
import MessageIcon from "../../../assets/icons/message.svg?react";
import { ScheduledMessageListProps } from "./AutomatedMessageList.type";
import { ScheduledMessageSent } from "./ScheduledMessageSent";
import { useTranslation } from "react-i18next";
import {
  NotificationStatusesEnumResponse,
  NotificationStatusesListItemResponse,
} from "../../../types/GETTypes";
import { ScheduledMessagePending } from "./ScheduledMessagePending";
import { ScheduledMessageCanceled } from "./ScheduledMessageCanceled";
import { ScheduledMessageError } from "./ScheduledMessageError";
import { ScheduledMessageViewModal } from "./ScheduledMessageViewModal";
import { useModal } from "../../../hooks/useModal";
import { ScheduledMessageReSendModal } from "./ScheduledMessageReSendModal";
import { ScheduledMessageCancelModal } from "./ScheduledMessageCancelModal";
import { ScheduledMessageEnableModal } from "./ScheduledMessageEnableModal";
import { ScheduledMessageEditModal } from "./ScheduledMessageEditModal";
import { ScheduledMessageSkeleton } from "./ScheduledMessageSkeleton";
import { ScheduledMessageNoData } from "./ScheduledMessageNoData";

export const ScheduledMessageList: React.FC<ScheduledMessageListProps> = ({
  messages,
  loading,
    guest_email,
  withActionButtons = true,
  onRefresh,
}) => {
  const { t } = useTranslation();

  const viewModal = useModal<NotificationStatusesListItemResponse>();
  const reSendModal = useModal<NotificationStatusesListItemResponse>();
  const cancelModal = useModal<NotificationStatusesListItemResponse>();
  const enableModal = useModal<NotificationStatusesListItemResponse>();
  const editModal = useModal<NotificationStatusesListItemResponse>();

  const handleView = (message: NotificationStatusesListItemResponse) => {
    viewModal.open(message);
  };

  const handleResend = (message: NotificationStatusesListItemResponse) => {
    reSendModal.open(message);
  };

  const handleCancel = (message: NotificationStatusesListItemResponse) => {
    cancelModal.open(message);
  };

  const handleEnable = (message: NotificationStatusesListItemResponse) => {
    enableModal.open(message);
  };

  const handleEdit = (message: NotificationStatusesListItemResponse) => {
    editModal.open(message);
  };

  const handleSuccess = () => {
    onRefresh();
  };

  return (
    <>
      <ScheduledMessageViewModal
        message={viewModal.data}
        withEditButton={withActionButtons}
        isVisible={viewModal.isVisible}
        onClose={viewModal.close}
        onEdit={handleEdit}
        guest_email={guest_email}
      />

      <ScheduledMessageReSendModal
        message={reSendModal.data}
        isVisible={reSendModal.isVisible}
        onClose={reSendModal.close}
        onSuccess={handleSuccess}
      />

      <ScheduledMessageCancelModal
        message={cancelModal.data}
        isVisible={cancelModal.isVisible}
        onClose={cancelModal.close}
        onSuccess={handleSuccess}
      />

      <ScheduledMessageEnableModal
        message={enableModal.data}
        isVisible={enableModal.isVisible}
        onClose={enableModal.close}
        onSuccess={handleSuccess}
      />

      <ScheduledMessageEditModal
        message={editModal.data}
        isVisible={editModal.isVisible}
        onClose={editModal.close}
        onSuccess={handleSuccess}
      />

      <div className="flex flex-col h-full">
        <div className="flex gap-2 items-center border-b-1 border-element-border p-3">
          <MessageIcon className="h-4 w-4" />
          <p className="font-semibold">{t("ScheduledMessageList.title")}</p>
        </div>

        {/* Scheduled messages */}
        <div className="flex flex-col px-2 pt-2 h-full">
          {!loading && messages && messages.length === 0 && (
            <ScheduledMessageNoData />
          )}

          {loading &&
            Array.from({ length: 5 }).map((d, index) => (
              <ScheduledMessageSkeleton key={index} />
            ))}

          {!loading &&
            messages?.map((message) => {
              if (
                message.status === NotificationStatusesEnumResponse.PENDING ||
                message.status ===
                  NotificationStatusesEnumResponse.JOB_DISPATCHED ||
                message.status === NotificationStatusesEnumResponse.PROCESSING
              ) {
                return (
                  <ScheduledMessagePending
                    key={message.id}
                    message={message}
                    withActionButtons={withActionButtons}
                    onView={handleView}
                    onCancel={handleCancel}
                    onEdit={handleEdit}
                    guest_email={guest_email}

                  />
                );
              } else if (
                message.status === NotificationStatusesEnumResponse.COMPLETED
              ) {
                return (
                  <ScheduledMessageSent
                    key={message.id}
                    message={message}
                    withActionButtons={withActionButtons}
                    onView={handleView}
                    onReSend={handleResend}
                  />
                );
              } else if (
                message.status === NotificationStatusesEnumResponse.CANCELLED
              ) {
                return (
                  <ScheduledMessageCanceled
                    key={message.id}
                    message={message}
                    withActionButtons={withActionButtons}
                    onView={handleView}
                    onEdit={handleEdit}
                    onEnable={handleEnable}
                  />
                );
              } else if (
                message.status === NotificationStatusesEnumResponse.ERRORED
              ) {
                return (
                  <ScheduledMessageError
                    key={message.id}
                    message={message}
                    withActionButtons={withActionButtons}
                    onView={handleView}
                    onEdit={handleEdit}
                    onReSend={handleResend}
                  />
                );
              }
            })}
        </div>
      </div>
    </>
  );
};
