import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import { TableList } from "../../components/Common/TableList/TableList";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { PaymentDepositFilters } from "../../components/Payment/PaymentDepositList/Filters/PaymentDepositFilters";
import {
  PaymentDepositFiltersTotals,
  PaymentDepositStatusFilterButton,
} from "../../components/Payment/PaymentDepositList/Filters/PaymentDepositFilters.type";
import { PaymentDepositDetailsModal } from "../../components/Payment/PaymentDepositList/PaymentDepositDetailsModal";
import { PaymentDepositListHeader } from "../../components/Payment/PaymentDepositList/PaymentDepositListHeader";
import { PaymentDepositListItem } from "../../components/Payment/PaymentDepositList/PaymentDepositListItem";
import { PaymentDepositListItemSkeleton } from "../../components/Payment/PaymentDepositList/PaymentDepositListItemSkeleton";
import paths from "../../constants/paths";
import { useModal } from "../../hooks/useModal";
import { useTablePage } from "../../hooks/useTablePage";
import { PaymentReservationResponse } from "../../types/GETTypes";
import { ValueType } from "../../types/commonTypes";
import { NoPaymentReservation } from "../PaymentReservationList/NoPaymentReservation";

export const PaymentDepositListPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  const depositModal = useModal<PaymentReservationResponse>();

  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.DEPOSITS}`,
    "deposit_reservations",
    {
      otherMetas: [
        "total_unpaid",
        "total_overdue",
        "total_pre_authorization",
        "total_payment_refundable",
        "total_canceled",
        "total_filtered",
      ],
    }
  );

  useEffect(() => {
    tablePage.fetch({});
  }, []);

  // * -- PAYMENT RESERVATION BUTTON FILTER --
  const [currentActiveButtonFilter, setCurrentActiveButtonFilter] =
    useState<PaymentDepositStatusFilterButton>("ALL");

  const fetchWithFilter = () => {
    if (currentActiveButtonFilter !== "ALL") {
      tablePage.handleFilterStatus(currentActiveButtonFilter);
    } else {
      tablePage.fetch({});
    }
  };

  useEffect(() => {
    if (currentActiveButtonFilter !== "ALL") {
      fetchWithFilter();
    }
  }, [currentActiveButtonFilter]);

  const getTitle = () => {
    return (
      <p className="flex flex-row items-center gap-1">
        {t("Payments.title")} <ChevronRightIcon />
        {t("Payments.depositList")}
      </p>
    );
  };

  const getFiltersNode = (): ReactNode => {
    const totals: PaymentDepositFiltersTotals = {
      unpaid:
        tablePage.currentOtherMetas.find((meta) => meta.key === "total_unpaid")
          ?.value ?? "0",
      overdue:
        tablePage.currentOtherMetas.find((meta) => meta.key === "total_overdue")
          ?.value ?? "0",
      pre_authorization:
        tablePage.currentOtherMetas.find(
          (meta) => meta.key === "total_pre_authorization"
        )?.value ?? "0",
      payment_refundable:
        tablePage.currentOtherMetas.find(
          (meta) => meta.key === "total_payment_refundable"
        )?.value ?? "0",
      canceled:
        tablePage.currentOtherMetas.find(
          (meta) => meta.key === "total_canceled"
        )?.value ?? "0",
      all: tablePage.total?.toString() ?? "0",
    };

    return (
      <PaymentDepositFilters
        onSearchText={tablePage.handleChangeSearch}
        totals={totals}
        currentActiveButtonFilter={currentActiveButtonFilter}
        onFilterButtonClick={(value) =>
          setCurrentActiveButtonFilter(
            value as PaymentDepositStatusFilterButton
          )
        }
        tablePage={tablePage}
      />
    );
  };

  const handleClickItem = (id: ValueType) => {
    const dataIndex = tablePage.data?.findIndex((d: any) => d.id === id);
    if (dataIndex === -1) return;

    depositModal.open(tablePage.data[dataIndex]);
  };

  return (
    <>
      <PaymentDepositDetailsModal
        paymentReservation={depositModal.data!}
        isVisible={depositModal.isVisible}
        onAskRefresh={fetchWithFilter}
        onClose={depositModal.close}
      />

      <MainLayout
        title={getTitle()}
        sidebarActiveItem="payments"
        sidebarActiveSubItem="deposits"
      >
        <TableList
          i18nElement="Global.deposit"
          Filters={getFiltersNode()}
          filterTotal={tablePage.total ?? 0}
          Header={PaymentDepositListHeader}
          tablePage={tablePage}
          Item={PaymentDepositListItem}
          Skeleton={PaymentDepositListItemSkeleton}
          NoData={
            <NoPaymentReservation
              title={t("Global.noCautionTitle")}
              description={t("Global.noCautionContent")}
            />
          }
          onClick={handleClickItem}
          onClickAction={() => {}}
        />
      </MainLayout>
    </>
  );
};
