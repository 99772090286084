export default {
  MAIN: "#E54D2E",
  HIGH_CONTRAST: "#171717",
  LOW_CONTRAST: "#6F6F6F",
};

export const PlatformColorList = {
  Airbnb: {
    name: "airbnb",
    hex: "#FF5A5F",
    text: "text-[#FF5A5F]",
    background: "bg-[#FF5A5F]",
    host: "airbnb.com",
  },
  Booking: {
    name: "booking",
    hex: "#273B7D",
    text: "text-[#273B7D]",
    background: "bg-[#273B7D]",
    host: "booking.com",
  },
  Vrbo: {
    name: "vrbo",
    hex: "#2A6EBB",
    text: "text-[#2A6EBB]",
    background: "bg-[#2A6EBB]",
    host: "vrbo.com",
  },
  Superhote: {
    name: "superhote",
    hex: "#E83535",
    text: "text-[#E83535]",
    background: "bg-[#E83535]",
    host: "superhote.com",
  },
} as const;
