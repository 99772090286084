import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Common/Button/Button";
import PlusIcon from "../../assets/icons/plus.svg?react";
import { ChecklistSection, ChecklistStep } from "../../types/GETTypes";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SixDotsIcon from "../../assets/icons/six-dots.svg?react";
import CloseIcon from "../../assets/icons/close.svg?react";
import CheckActiveIcon from "../../assets/icons/check-circle-active.svg?react";
import { TextInput } from "../Common/TextInput/TextInput";
import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  rectIntersection,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

const SectionsComponent = ({
  sectionsData,
  onChange,
}: {
  sectionsData: ChecklistSection[];
  onChange: (value: ChecklistSection[]) => void;
}) => {
  const { t } = useTranslation();

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const [sections, setSections] = useState(sectionsData);

  useEffect(() => {
    setSections(sectionsData);
  }, [sectionsData]);

  const generateRandomId = () =>
    Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;

  const addSection = () => {
    const nextSections = reorderSectionsAndSteps([
      ...sections,
      {
        section_id: generateRandomId(),
        name: t("Rental.Checklist.Upsert.newSection"),
        section_order: sections.length,
        steps: [],
      },
    ]);

    setSections(nextSections);
    onChange(nextSections);
  };

  const deleteSection = (section: ChecklistSection) => {
    const nextSections = reorderSectionsAndSteps(
      sections.filter((s) => s.section_id !== section.section_id)
    );

    setSections(nextSections);
    onChange(nextSections);
  };

  const updateSection = (section: ChecklistSection, data: { name: string }) => {
    const nextSections = reorderSectionsAndSteps(
      sections.map((s) =>
        s.section_id === section.section_id ? { ...s, ...data } : s
      )
    );

    setSections(nextSections);
    onChange(nextSections);
  };

  const deleteStep = (section: ChecklistSection, step: ChecklistStep) => {
    const nextSections = reorderSectionsAndSteps(
      sections.map((s) => {
        if (s.section_id === section.section_id) {
          return {
            ...s,
            steps: s.steps.filter((st) => st.step_id !== step.step_id),
          };
        }
        return s;
      })
    );

    setSections(nextSections);
    onChange(nextSections);
  };

  const updateStep = (
    section: ChecklistSection,
    step: ChecklistStep,
    data: { name: string }
  ) => {
    const nextSections = reorderSectionsAndSteps(
      sections.map((s) => {
        if (s.section_id === section.section_id) {
          return {
            ...s,
            steps: s.steps.map((st) =>
              st.step_id === step.step_id ? { ...st, ...data } : st
            ),
          };
        }
        return s;
      })
    );

    setSections(nextSections);
    onChange(nextSections);
  };

  const addStep = () => {
    const updatedSections = sections.map((section) => {
      if (section.section_id === sections[sections.length - 1].section_id) {
        return {
          ...section,
          steps: [
            ...section.steps,
            {
              step_id: generateRandomId(),
              name: t("Rental.Checklist.Upsert.newStep"),
              order: section.steps.length,
            },
          ],
        };
      }
      return section;
    });
    const nextSections = reorderSectionsAndSteps(updatedSections);

    setSections(nextSections);
    onChange(nextSections);
  };

  const handleDragOver = ({ active, over }: { active: any; over: any }) => {
    if (!over) return;

    const [activeId, activeType] = active.id.split("-");
    const [overId, overType] = over.id.split("-");

    if (activeType === "step" && overType === "step") {
      const activeSection = sections.find((section) =>
        section.steps.some((step) => step.step_id === parseInt(activeId))
      );
      const overSection = sections.find((section) =>
        section.steps.some((step) => step.step_id === parseInt(overId))
      );

      if (activeSection && overSection) {
        const activeIndex = activeSection.steps.findIndex(
          (step) => step.step_id === parseInt(activeId)
        );
        const overIndex = overSection.steps.findIndex(
          (step) => step.step_id === parseInt(overId)
        );

        if (activeSection.section_id === overSection.section_id) {
          // Reorder within the same section
          const updatedSteps = arrayMove(
            activeSection.steps,
            activeIndex,
            overIndex
          );
          const updatedSections = sections.map((section) =>
            section.section_id === activeSection.section_id
              ? { ...section, steps: updatedSteps }
              : section
          );
          setSections(updatedSections);
          onChange(updatedSections);
        } else {
          // Move between different sections
          const [movedStep] = activeSection.steps.splice(activeIndex, 1);
          overSection.steps.splice(overIndex, 0, movedStep);
          const nextSections = reorderSectionsAndSteps([...sections]);
          setSections(nextSections);
          onChange(nextSections);
        }
      }
    } else if (activeType === "step" && overType === "section") {
      const activeSection = sections.find((section) =>
        section.steps.some((step) => step.step_id === parseInt(activeId))
      );
      const targetSection = sections.find(
        (section) => section.section_id === parseInt(overId)
      );

      if (
        activeSection &&
        targetSection &&
        activeSection.section_id !== targetSection.section_id
      ) {
        const activeStepIndex = activeSection.steps.findIndex(
          (step) => step.step_id === parseInt(activeId)
        );
        const [movedStep] = activeSection.steps.splice(activeStepIndex, 1);
        targetSection.steps.push(movedStep);
        const nextSections = reorderSectionsAndSteps([...sections]);
        setSections(nextSections);
        onChange(nextSections);
      }
    } else if (activeType === "section" && overType === "section") {
      const oldIndex = sections.findIndex(
        (section) => section.section_id === parseInt(activeId)
      );
      const newIndex = sections.findIndex(
        (section) => section.section_id === parseInt(overId)
      );
      const reorderedSections = arrayMove(sections, oldIndex, newIndex);

      const nextSections = reorderSectionsAndSteps(reorderedSections);
      setSections(nextSections);
      onChange(nextSections);
    }
  };

  const reorderSectionsAndSteps = (
    sections: ChecklistSection[]
  ): ChecklistSection[] => {
    return sections
      .map((section, sectionIndex) => ({
        ...section,
        section_order: sectionIndex, // Reorder sections based on their index
        steps: section.steps
          .sort((a, b) => a.order - b.order) // Ensure steps are sorted by order
          .map((step, stepIndex) => ({
            ...step,
            order: stepIndex, // Reorder steps based on their index within the section
          })),
      }))
      .sort((a, b) => a.section_order - b.section_order);
  };

  return (
    <div className="flex flex-col w-full h-auto">
      <div className="flex flex-col">
        <label className="text-sm text-low-contrast mb-4">
          {t("Rental.Checklist.Upsert.checklist")}
        </label>
        <DndContext
          autoScroll={false}
          sensors={sensors}
          onDragOver={handleDragOver}
          collisionDetection={rectIntersection}
        >
          <SortableContext
            items={sections.map((section) => `${section.section_id}-section`)}
            strategy={verticalListSortingStrategy}
          >
            {sections.map((section) => (
              <SectionComponent
                key={section.section_id}
                section={section}
                updateSection={updateSection}
                deleteSection={deleteSection}
                updateStep={updateStep}
                deleteStep={deleteStep}
              />
            ))}
          </SortableContext>
        </DndContext>

        <div className="flex mt-2 justify-center">
          <Button
            RightIcon={PlusIcon}
            type="secondary"
            onClick={addSection}
            displayLargeBtn={false}
            className="flex w-[100%] mr-2"
          >
            {t("Rental.Checklist.Section.addSection")}
          </Button>
          <Button
            RightIcon={PlusIcon}
            type="secondary"
            onClick={addStep}
            displayLargeBtn={false}
            className="flex w-[100%] ml-2"
          >
            {t("Rental.Checklist.Section.addStep")}
          </Button>
        </div>
      </div>
    </div>
  );
};

const SectionComponent = ({
  section,
  updateSection,
  deleteSection,
  updateStep,
  deleteStep,
}: {
  section: ChecklistSection;
  updateSection: (
    section: ChecklistSection,
    data: {
      name: string;
    }
  ) => void;
  deleteSection: (section: ChecklistSection) => void;
  updateStep: (
    section: ChecklistSection,
    step: ChecklistStep,
    data: {
      name: string;
    }
  ) => void;
  deleteStep: (section: ChecklistSection, step: ChecklistStep) => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { section_id, name: sectionName, steps } = section;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: `${section_id}-section`,
    });

  const inputRef = useRef(null);
  const [name, setName] = useState(sectionName);

  useEffect(() => {
    if (isEditing) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing]);

  const handleClickOutside = (event: any) => {
    const current = inputRef?.current as any;
    if (current && !current.contains(event.target)) {
      setIsEditing(false);
    }
  };

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (value: string) => {
    setName(value);
    updateSection(section, { name: value });
  };

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    minHeight: "auto",
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div className="flex justify-between">
        <div className="flex items-center">
          <SixDotsIcon
            className="cursor-move"
            width={30}
            height={30}
            style={{ outline: "none" }}
            // tabIndex={-1}
            {...listeners}
            {...attributes}
          />
          {isEditing ? (
            <TextInput
              ref={inputRef}
              value={name}
              type="text"
              onChangeText={handleInputChange}
              onBlur={() => setIsEditing(false)}
              autoFocus
              classNames={{ input: "w-auto" }}
            />
          ) : (
            <label
              className="text-high-contrast font-bold cursor-pointer"
              onClick={handleTextClick}
            >
              {name}
            </label>
          )}
        </div>
        <div>
          <CloseIcon
            className="cursor-pointer"
            width={20}
            height={20}
            onClick={() => deleteSection(section)}
          />
        </div>
      </div>
      <div className="mt-2">
        <SortableContext
          items={steps.map((step) => `${step.step_id}-step`)}
          strategy={verticalListSortingStrategy}
        >
          {steps.map((step) => (
            <StepComponent
              key={step.step_id}
              step={step}
              section={section}
              updateStep={updateStep}
              deleteStep={deleteStep}
            />
          ))}
        </SortableContext>
      </div>
    </div>
  );
};

const StepComponent = ({
  section,
  step,
  updateStep,
  deleteStep,
}: {
  section: ChecklistSection;
  step: ChecklistStep;
  updateStep: (
    section: ChecklistSection,
    step: ChecklistStep,
    data: {
      name: string;
    }
  ) => void;
  deleteStep: (section: ChecklistSection, step: ChecklistStep) => void;
}) => {
  const { name: stepName, step_id } = step;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: `${step_id}-step`,
    });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  const [isEditing, setIsEditing] = useState(false);

  const inputRef = useRef(null);
  const [name, setName] = useState(stepName);

  useEffect(() => {
    if (isEditing) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isEditing]);

  const handleClickOutside = (event: any) => {
    const current = inputRef?.current as any;
    if (current && !current.contains(event.target)) {
      setIsEditing(false);
    }
  };

  const handleTextClick = (e: any) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleInputChange = (value: string) => {
    setName(value);
    updateStep(section, step, { name: value });
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="flex justify-between border-b-1 border-element-border mb-2"
    >
      <div className="flex justify-between my-2 w-full ml-6 items-center">
        <div className="flex items-center">
          <SixDotsIcon
            className="cursor-move shrink-0"
            width={25}
            height={25}
            style={{ outline: "none" }}
            {...listeners}
            {...attributes}
          />
          <CheckActiveIcon
            className="cursor-move shrink-0"
            width={25}
            height={25}
          />
          {isEditing ? (
            <TextInput
              ref={inputRef}
              value={name}
              type="text"
              onChangeText={handleInputChange}
              onBlur={() => setIsEditing(false)}
              autoFocus
              classNames={{ input: "w-auto", divInput: "ml-2" }}
            />
          ) : (
            <label
              className="text-low-contrast ml-2"
              onClick={(e) => handleTextClick(e)}
            >
              {name}
            </label>
          )}
        </div>
        <div>
          <CloseIcon
            className="cursor-pointer"
            width={20}
            height={20}
            onClick={() => deleteStep(section, step)}
          />
        </div>
      </div>
    </div>
  );
};

export default SectionsComponent;
