import React from "react";
import { useTranslation } from "react-i18next";

export const ManagementOwnerTableNoItem: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-52 flex flex-col gap-3 items-center justify-center rounded-6px bg-element-background border-1 border-element-border">
      <p className="font-semibold text-high-contrast text-base">
        {t("Management.noRoles")}
      </p>
    </div>
  );
};
