import React, { useEffect, useState } from "react";
import { ManagementLayout } from "../../components/Layout/ManagementLayout/ManagementLayout";
import { Button } from "../../components/Common/Button/Button";
import { UseModal, useModal } from "../../hooks/useModal";
import { RightModal } from "../../components/Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import PrintIcon from "../../assets/icons/print.svg?react";
import PlusWhiteIcon from "../../assets/icons/plus-white.svg?react";
import moment from "moment";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg?react";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import FilterIcon from "../../assets/icons/filter_.svg?react";
import { DateFilter } from "../../components/Common/DateFilter/DateFilter";
import { DateRangePreset } from "../../components/Common/DateFilter/DateFilter.type";
import { ErrorMessage } from "../../components/Common/ErrorMessage/ErrorMessage";
import AddTaskModal from "../../components/Management/AddTask/AddTaskModal";
import { TaskList } from "../../components/Management/TaskList/TaskList";
import { TaskListWithTasksSkeleton } from "../../components/Management/TaskList/TaskListWithTasksSkeleton";
import { TaskFilterStatus } from "../../components/Task/TaskFilterStatus";
import { useTasks } from "../../hooks/api/tasks";
import { TaskItemResponse, TaskListByDateResponse } from "../../types/GETTypes";
import {
  ManagementTaskListFilterForm,
  TasksFilterStatusEnum,
  TasksFilterStatusMeta,
} from "./ManagementTaskListPage.type";
import { EditTaskScheduled } from "../../components/Management/EditTaskScheduled/EditTaskScheduled";
import { EditTaskStarted } from "../../components/Management/EditTaskStarted/EditTaskStarted";
import { AcceptTaskModal } from "../../components/Management/AcceptTaskModal/AcceptTaskModal";
import { DeclineTaskModal } from "../../components/Management/DeclineTaskModal/DeclineTaskModal";
import { EditTaskDone } from "../../components/Management/EditTaskDone/EditTaskDone";
import { CancelTask } from "../../components/Management/CancelTask/CancelTask";
import { EditTaskCanceled } from "../../components/Management/EditTaskCanceled/EditTaskCanceled";
import { EditTaskEditComplete } from "../../components/Management/EditTaskEditComplete/EditTaskEditComplete";
import { ManagementTaskListFilterModal } from "./ManagementTaskListFilterModal";
import InfoIcon from "../../assets/icons/information.svg?react";
import useUserStore from "../../stores/useUserStore";

export const ManagementTaskListPage = () => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();

  const [, setNbRangeDate] = useState<number>(0);
  const [, setDateRangePreset] = useState<DateRangePreset>("next_seven_days");

  const createModal = useModal<TaskItemResponse>();
  const editTaskScheduled = useModal<TaskItemResponse>();
  const editTaskStarted = useModal<TaskItemResponse>();
  const editTaskDone = useModal<TaskItemResponse>();
  const editTaskEditComplete = useModal<TaskItemResponse>();
  const editTaskCanceled = useModal<TaskItemResponse>();
  const cancelTaskModal = useModal<TaskItemResponse>();
  const acceptTaskModal = useModal<TaskItemResponse>();
  const declineTaskModal = useModal<TaskItemResponse>();

  const [metas, setMetas] = useState<TasksFilterStatusMeta>({
    all: undefined,
    canceled: undefined,
    completed: undefined,
    notAssigned: undefined,
    pending: undefined,
    scheduled: undefined,
    refused: undefined,
  });
  const [tasks, setTasks] = useState<TaskListByDateResponse | undefined>(
    undefined
  );

  const [currentFilterStatus, setCurrentFilterStatus] =
    useState<TasksFilterStatusEnum>(TasksFilterStatusEnum.all);

  const [currentDateRange, setCurrentDateRange] = useState({
    from: moment(new Date()),
    to: moment(new Date()).add(7, "days"),
  });

  const { getAll } = useTasks({
    onSuccess: (result) => {
      setTasks(result?.tasks);
      if (result?.tasks) {
        console.log("result", result);
        setMetas({
          all: result.total?.toString(),
          canceled: result.total_canceled?.toString(),
          completed: result.total_completed?.toString(),
          notAssigned: result.total_not_assigned?.toString(),
          pending: result.total_started?.toString(),
          scheduled: result.total_assigned?.toString(),
          refused: result.total_declined?.toString(),
        });
      }
    },
    onError: (message: string | undefined) => {
      setError(message);
    },
    onStart: () => {
      setError(undefined);
      setLoading(true);
    },
    onEnd: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (currentDateRange.from.isValid() && currentDateRange.to.isValid()) {
      getAll({
        from: currentDateRange.from,
        to: currentDateRange.to,
        status: currentFilterStatus,
      });
    }
  }, [currentDateRange, currentFilterStatus]);

  const handleChangeStatusFilter = (nextStatus: TasksFilterStatusEnum) => {
    setCurrentFilterStatus(nextStatus);
  };

  const handlePrevious = () => {
    const period = currentDateRange.to.diff(currentDateRange.from, "days");
    const nextFrom = currentDateRange.from.add(period * -1, "days");
    const nextTo = currentDateRange.to.add(period * -1, "days");

    setCurrentDateRange({
      from: nextFrom,
      to: nextTo,
    });
  };

  const handleNext = () => {
    const period = currentDateRange.to.diff(currentDateRange.from, "days");
    const nextFrom = currentDateRange.from.add(period, "days");
    const nextTo = currentDateRange.to.add(period, "days");

    setCurrentDateRange({
      from: nextFrom,
      to: nextTo,
    });
  };

  const onSuccess = () => {
    getAll({
      from: currentDateRange.from,
      to: currentDateRange.to,
      status: currentFilterStatus,
    });
  };

  const openEditTaskModal = (task: TaskItemResponse) => {
    if (task.status === "ASSIGNED") editTaskScheduled.open(task);
    else if (task.status === "STARTED") editTaskStarted.open(task);
    else if (task.status === "COMPLETED") editTaskDone.open(task);
    else if (task.status === "CANCELED") editTaskCanceled.open(task);
  };

  const onCloseModal = () => {
    createModal.close();
  };

  const handleStart = (task: TaskItemResponse) => {
    editTaskScheduled.close();
    editTaskStarted.open(task);

    getAll({
      from: currentDateRange.from,
      to: currentDateRange.to,
      status: currentFilterStatus,
    });
  };

  const handleCancel = (task: TaskItemResponse) => {
    cancelTaskModal.open(task);
  };

  // * -- FILTER MODAL --
  const filterModal: UseModal<ManagementTaskListFilterForm> =
    useModal<ManagementTaskListFilterForm>();

  const handleTaskListFiltered = (
    tasks: TaskListByDateResponse | undefined
  ) => {
    setTasks(tasks);
  };

  return (
    <>
      {/* CREATE MODAL */}
      <RightModal
        title={t("Task.Modal.title")}
        classNames={{
          mainContentParent: "overflow-y-hidden pe-0",
        }}
        isVisible={createModal.isVisible}
        onClose={onCloseModal}
      >
        <AddTaskModal
          onClose={onCloseModal}
          onSuccess={onSuccess}
          task={createModal.data!}
        />
      </RightModal>

      <EditTaskScheduled
        task={editTaskScheduled.data!}
        isVisible={editTaskScheduled.isVisible}
        onClose={editTaskScheduled.close}
        onSuccess={onSuccess}
        onStart={handleStart}
        onCancel={handleCancel}
      />

      <EditTaskCanceled
        task={editTaskCanceled.data!}
        isVisible={editTaskCanceled.isVisible}
        onClose={editTaskCanceled.close}
      />

      <CancelTask
        task={cancelTaskModal.data}
        isVisible={cancelTaskModal.isVisible}
        onClose={cancelTaskModal.close}
        onSuccess={() => {
          onSuccess();
          editTaskScheduled.close();
        }}
      />

      <EditTaskStarted
        task={editTaskStarted.data}
        isVisible={editTaskStarted.isVisible}
        onClose={editTaskStarted.close}
        onSuccess={onSuccess}
      />

      <EditTaskDone
        task={editTaskDone.data}
        isVisible={editTaskDone.isVisible}
        onClose={editTaskDone.close}
        onComplete={(task) => {
          editTaskDone.close();
          editTaskEditComplete.open(task!);
        }}
      />

      <EditTaskEditComplete
        task={editTaskEditComplete.data}
        isVisible={editTaskEditComplete.isVisible}
        onClose={(task) => {
          editTaskEditComplete.close();
          editTaskDone.open(task!);
        }}
        onSuccess={(task) => {
          onSuccess();
          editTaskEditComplete.close();
          editTaskDone.open(task!);
        }}
      />

      <AcceptTaskModal
        task={acceptTaskModal.data}
        isVisible={acceptTaskModal.isVisible}
        onClose={acceptTaskModal.close}
        onSuccess={onSuccess}
      />

      <DeclineTaskModal
        task={declineTaskModal.data}
        isVisible={declineTaskModal.isVisible}
        onClose={declineTaskModal.close}
        onSuccess={onSuccess}
      />

      {/* FILTER MODAL */}
      <ManagementTaskListFilterModal
        modal={filterModal}
        listFilters={{
          status: currentFilterStatus,
          from: currentDateRange.from,
          to: currentDateRange.to,
        }}
        onError={(message: string | undefined) => setError(message)}
        onTaskListFiltered={handleTaskListFiltered}
      />

      <ManagementLayout subTitle="tasks" sidebarActiveSubItem="tasks">
        <div className="flex items-center gap-3">
          {/* Status filter */}
          <div className="flex-1">
            <TaskFilterStatus
              metas={metas}
              filter={currentFilterStatus}
              onChange={handleChangeStatusFilter}
            />
          </div>

          <div>
            <Button RightIcon={PrintIcon} type="secondary">
              {t("Task.List.print")}
            </Button>
          </div>

          <div>
            <Button
              RightIcon={PlusWhiteIcon}
              disabled={!userStore.canEdit}
              onClick={createModal.open}
            >
              {t("Task.List.create")}
            </Button>
          </div>
        </div>

        <div className="flex items-center mt-4 gap-y-2 gap-x-6">
          {/* Date filter */}
          <div className="flex">
            <DateFilter
              onDatesChanged={(dates, nbRangeDate) => {
                setCurrentDateRange({
                  from: moment(dates[0]),
                  to: moment(dates[1]),
                });

                if (nbRangeDate) {
                  setNbRangeDate(nbRangeDate);
                }
              }}
              onDateRangePresetChanged={(dateRangePreset) => {
                setDateRangePreset(dateRangePreset);
              }}
              onLoading={(loading) => setLoading(loading)}
              onError={(error) => setError(error)}
            />
          </div>

          <div>
            <Button type="secondary" onClick={() => filterModal.open()}>
              <FilterIcon />
            </Button>
          </div>

          <div className="flex justify-end flex-1 gap-x-2">
            <div>
              <Button
                type="secondary"
                LeftIcon={ArrowLeftIcon}
                onClick={handlePrevious}
              >
                {t("Calendar.previousPeriod")}
              </Button>
            </div>

            <div>
              <Button
                type="secondary"
                RightIcon={ArrowRightIcon}
                onClick={handleNext}
              >
                {t("Calendar.nextPeriod")}
              </Button>
            </div>
          </div>
        </div>

        {/* Refused info message */}
        {currentFilterStatus === TasksFilterStatusEnum.refused && (
          <div className="rounded-6px flex gap-2 bg-element-background p-2 mt-4">
            <InfoIcon className="size-4" />

            <div className="flex flex-col gap-1">
              <p className="text-high-contrast font-semibold">
                {t("Task.List.refusedInfoTitle")}
              </p>
              <p className="text-low-contrast font-light">
                {t("Task.List.refusedInfoContent")}
              </p>
            </div>
          </div>
        )}

        <ErrorMessage>{error}</ErrorMessage>

        {loading && <TaskListWithTasksSkeleton />}

        {!loading && (
          <TaskList
            tasksListByDate={tasks}
            onAcceptTask={acceptTaskModal.open}
            onDeclineTask={declineTaskModal.open}
            openEditTaskModal={openEditTaskModal}
          />
        )}
      </ManagementLayout>
    </>
  );
};
