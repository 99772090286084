import { Elements } from "@stripe/react-stripe-js";
import {
  PaymentMethodCreateParams,
  Stripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import CheckCircleGreenIcon from "../../assets/icons/check-circle-green.svg?react";
import CheckDoubleGreenIcon from "../../assets/icons/check-double-green.svg?react";
import CheckGreenIcon from "../../assets/icons/check-green.svg?react";
import ForbidIcon from "../../assets/icons/forbid-warning.svg?react";
import SendMoneyIcon from "../../assets/icons/send-money-warning.svg?react";
import { PaymentPolicyDepositPaymentOptionEnum } from "../../components/Payment/Payment.type";
import { PaymentReservationDepositStatusEnum } from "../../enums/GETenums";
import { PaymentDepositCheckoutForm } from "./PaymentDepositCheckoutForm";
import { PaymentDepositInfo } from "./PaymentDepositInfo";

type PaymentDepositFormOrInfoProps = {
  status: PaymentReservationDepositStatusEnum;
  option: PaymentPolicyDepositPaymentOptionEnum | undefined;
  amount: number | undefined;
  stripeOptions: StripeElementsOptions | undefined;
  stripePromise: Promise<Stripe | null> | undefined;
  stripeClientSecret: string | undefined;
  billingDetails: PaymentMethodCreateParams.BillingDetails | undefined;
  onValidatePayment?: (status?: PaymentReservationDepositStatusEnum) => void;
  displayModalSuccessGuestPage?: boolean
};
export const PaymentDepositFormOrInfo: React.FC<
  PaymentDepositFormOrInfoProps
> = ({
  status,
  option,
  amount,
  stripeOptions,
  stripePromise,
  stripeClientSecret,
  billingDetails,
  onValidatePayment,
  displayModalSuccessGuestPage = false
}) => {
  const { t } = useTranslation();

  const getIcon = () => {
    let icon: ReactNode = <CheckGreenIcon width={40} height={40} />;

    if (status === PaymentReservationDepositStatusEnum.PAID) {
      icon = <CheckCircleGreenIcon width={40} height={40} />;
    } else if (status === PaymentReservationDepositStatusEnum.PRE_AUTHORIZED) {
      icon = <CheckCircleGreenIcon width={40} height={40} />;
    } else if (status === PaymentReservationDepositStatusEnum.RELEASED) {
      icon = <CheckDoubleGreenIcon width={40} height={40} />;
    } else if (status === PaymentReservationDepositStatusEnum.CAPTURED) {
      icon = <SendMoneyIcon width={40} height={40} />;
    } else if (status === PaymentReservationDepositStatusEnum.REFUNDED) {
      icon = <CheckDoubleGreenIcon width={40} height={40} />;
    } else if (status === PaymentReservationDepositStatusEnum.NOT_REFUNDED) {
      icon = <ForbidIcon width={40} height={40} />;
    } else if (
      status === PaymentReservationDepositStatusEnum.MANAGED_BY_PLATFORM
    ) {
      icon = <CheckCircleGreenIcon width={40} height={40} />;
    }

    return icon;
  };

  const getTitle = () => {
    let title: string = t("Payments.PaymentDeposit.successPaymentValidated");

    if (status === PaymentReservationDepositStatusEnum.PAID) {
      if (option === PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION) {
        title = t(
          "Payments.PaymentDeposit.PreAuthorization.successPaymentValidated"
        );
      } else {
        title = t("Payments.PaymentDeposit.successPaymentValidated");
      }
    } else if (status === PaymentReservationDepositStatusEnum.PRE_AUTHORIZED) {
      if (option === PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION) {
        title = t(
          "Payments.PaymentDeposit.PreAuthorization.preAuthorizedPayment"
        );
      } else {
        title = t("Payments.PaymentDeposit.preAuthorizedPayment");
      }
    } else if (status === PaymentReservationDepositStatusEnum.RELEASED) {
      if (option === PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION) {
        title = t("Payments.PaymentDeposit.PreAuthorization.releasedPayment");
      } else {
        title = t("Payments.PaymentDeposit.releasedPayment");
      }
    } else if (status === PaymentReservationDepositStatusEnum.CAPTURED) {
      if (option === PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION) {
        title = t("Payments.PaymentDeposit.PreAuthorization.capturedPayment");
      } else {
        title = t("Payments.PaymentDeposit.capturedPayment");
      }
    } else if (status === PaymentReservationDepositStatusEnum.REFUNDED) {
      if (option === PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION) {
        title = t("Payments.PaymentDeposit.PreAuthorization.refundedPayment");
      } else {
        title = t("Payments.PaymentDeposit.refundedPayment");
      }
    } else if (status === PaymentReservationDepositStatusEnum.NOT_REFUNDED) {
      if (option === PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION) {
        title = t(
          "Payments.PaymentDeposit.PreAuthorization.notRefundedPayment"
        );
      } else {
        title = t("Payments.PaymentDeposit.notRefundedPayment");
      }
    } else if (
      status === PaymentReservationDepositStatusEnum.MANAGED_BY_PLATFORM
    ) {
      if (option === PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION) {
        title = t(
          "Payments.PaymentDeposit.PreAuthorization.managedByPlatformPayment"
        );
      } else {
        title = t("Payments.PaymentDeposit.managedByPlatformPayment");
      }
    }

    return title;
  };

  const getDescription = () => {
    let description: string = t(
      "Payments.PaymentDeposit.successInfoPaymentValidated",
      {
        amount: amount,
        currency: t("Global.currencySymbol"),
      }
    );

    if (status === PaymentReservationDepositStatusEnum.PAID) {
      if (option === PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION) {
        description = t(
          "Payments.PaymentDeposit.PreAuthorization.successInfoPaymentValidated",
          {
            amount: amount,
            currency: t("Global.currencySymbol"),
          }
        );
      } else {
        description = t("Payments.PaymentDeposit.successInfoPaymentValidated", {
          amount: amount,
          currency: t("Global.currencySymbol"),
        });
      }
    } else if (status === PaymentReservationDepositStatusEnum.PRE_AUTHORIZED) {
      if (option === PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION) {
        description = t(
          "Payments.PaymentDeposit.PreAuthorization.preAuthorizedInfoPayment",
          {
            amount: amount,
            currency: t("Global.currencySymbol"),
          }
        );
      } else {
        description = t("Payments.PaymentDeposit.preAuthorizedInfoPayment", {
          amount: amount,
          currency: t("Global.currencySymbol"),
        });
      }
    } else if (status === PaymentReservationDepositStatusEnum.RELEASED) {
      if (option === PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION) {
        description = t(
          "Payments.PaymentDeposit.PreAuthorization.releasedInfoPayment",
          {
            amount: amount,
            currency: t("Global.currencySymbol"),
          }
        );
      } else {
        description = t("Payments.PaymentDeposit.releasedInfoPayment", {
          amount: amount,
          currency: t("Global.currencySymbol"),
        });
      }
    } else if (status === PaymentReservationDepositStatusEnum.CAPTURED) {
      if (option === PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION) {
        description = t(
          "Payments.PaymentDeposit.PreAuthorization.capturedInfoPayment",
          {
            amount: amount,
            currency: t("Global.currencySymbol"),
          }
        );
      } else {
        description = t("Payments.PaymentDeposit.capturedInfoPayment", {
          amount: amount,
          currency: t("Global.currencySymbol"),
        });
      }
    } else if (status === PaymentReservationDepositStatusEnum.REFUNDED) {
      if (option === PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION) {
        description = t(
          "Payments.PaymentDeposit.PreAuthorization.refundedInfoPayment",
          {
            amount: amount,
            currency: t("Global.currencySymbol"),
          }
        );
      } else {
        description = t("Payments.PaymentDeposit.refundedInfoPayment", {
          amount: amount,
          currency: t("Global.currencySymbol"),
        });
      }
    } else if (status === PaymentReservationDepositStatusEnum.NOT_REFUNDED) {
      if (option === PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION) {
        description = t(
          "Payments.PaymentDeposit.PreAuthorization.notRefundedInfoPayment",
          {
            amount: amount,
            currency: t("Global.currencySymbol"),
          }
        );
      } else {
        description = t("Payments.PaymentDeposit.notRefundedInfoPayment", {
          amount: amount,
          currency: t("Global.currencySymbol"),
        });
      }
    } else if (
      status === PaymentReservationDepositStatusEnum.MANAGED_BY_PLATFORM
    ) {
      if (option === PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION) {
        description = t(
          "Payments.PaymentDeposit.PreAuthorization.managedByPlatformInfoPayment",
          {
            amount: amount,
            currency: t("Global.currencySymbol"),
          }
        );
      } else {
        description = t(
          "Payments.PaymentDeposit.managedByPlatformInfoPayment",
          {
            amount: amount,
            currency: t("Global.currencySymbol"),
          }
        );
      }
    }

    return description;
  };

  return (
    <>
      {status !== PaymentReservationDepositStatusEnum.UNPAID &&
      status !== PaymentReservationDepositStatusEnum.OVERDUE &&
      status !== PaymentReservationDepositStatusEnum.CANCELED &&
      status !== PaymentReservationDepositStatusEnum.NOT_REQUIRED ? (
        <PaymentDepositInfo
          icon={getIcon()}
          title={getTitle()}
          description={getDescription()}
          status={status}
        />
      ) : (
        <>
          {stripeOptions ? (
            <Elements
              stripe={stripePromise!}
              options={stripeOptions}
              key={stripeClientSecret}
            >
              <PaymentDepositCheckoutForm
                amount={amount}
                billingDetails={billingDetails!}
                option={option}
                icon={getIcon()}
                title={getTitle()}
                description={getDescription()}
                status={status}
                onValidatePayment={onValidatePayment}
                displayModalSuccessGuestPage={displayModalSuccessGuestPage}
              />
            </Elements>
          ) : null}
        </>
      )}
    </>
  );
};
