import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import MinusIcon from "../../../assets/icons/minus.svg?react";
import PhotoIcon from "../../../assets/icons/photo.svg?react";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import countries from "../../../constants/countries";
import paths from "../../../constants/paths";
import { post } from "../../../helpers/APIHelper";
import { Button } from "../../Common/Button/Button";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { PictureWithDelete } from "../../Common/PictureWithDelete/PictureWithDelete";
import { Separator } from "../../Common/Separator/Separator";
import { TextAreaInput } from "../../Common/TextAreaInput/TextAreaInput";
import { RentalUpdatePhotosModal } from "../../Rental/Infos/Photos/RentalUpdatePhotosModal";
import { AddRentalPhotosType, AddRentalType } from "../AddRental.type";

export const AddRentalPhotos: React.FC<{
  rental: AddRentalType | undefined;
  onCancel: () => void;
  onNext: (values: AddRentalPhotosType) => void;
  onBack: () => void;
}> = ({ rental, onCancel, onNext, onBack }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const currentForm = useForm<AddRentalPhotosType>({
    defaultValues: {
      description: "",
      photos: [],
    },
  });

  const [newFiles, setNewFiles] = useState<Blob[]>([]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleAdd = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    if (rental) {
      currentForm.setValue("description", rental?.photos?.description ?? "");
      setNewFiles(rental?.photos?.photos ?? []);
    }
  }, [rental]);

  const handleChangeNewFiles = (files: Blob[]) => {
    console.log("[handleChangeNewFiles]", files);
    setNewFiles([...newFiles, ...files]);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault(); // Empêche l'ouverture des fichiers.
  };

  const handleDrop = (event: any) => {
    event.preventDefault(); // Empêche l'ouverture des fichiers.

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      // Ajoutez ici votre logique pour traiter les fichiers
      const files: Blob[] = Array.from(event.dataTransfer.files);
      handleChangeNewFiles(files); // Une fonction pour traiter les fichiers
      event.dataTransfer.clearData(); // Efface les fichiers de l'objet dataTransfer
    }
  };

  const handleAddFromInput = (event: any) => {
    setNewFiles([...newFiles, ...event.target.files]);
  };

  const handleRemoveFile = (index: number) => {
    setNewFiles(newFiles.filter((v, i) => i !== index));
  };

  const handleNextStep = async (values: AddRentalPhotosType) => {
    setError(null);
    setLoading(true);

    if (newFiles.length === 0) {
      setError(t("AddRental.Photos.noPhotos"));
      setLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("name", rental?.infos?.name ?? "");
    formData.append(
      "rental_category_id",
      rental?.infos?.rentalCategory?.toString() ?? ""
    );
    formData.append(
      "rental_type_id",
      rental?.infos?.rentalType?.toString() ?? ""
    );
    formData.append(
      "renting_type",
      rental?.infos?.rentingType?.toString() ?? ""
    );
    formData.append(
      "management_type",
      rental?.infos?.managementType?.toString() ?? ""
    );
    formData.append("address", rental?.infos?.address ?? "");
    formData.append("longitude", rental?.infos?.longitude?.toString() ?? "");
    formData.append("latitude", rental?.infos?.latitude?.toString() ?? "");
    formData.append("postal_code", rental?.infos?.postal_code ?? "");
    formData.append("city", rental?.infos?.city ?? "");
    formData.append(
      "country_code",
      countries.find((country) => country.value === rental?.infos?.country)
        ?.code ?? ""
    );
    formData.append(
      "guests_max_capacity",
      rental?.infos?.maxGuests?.toString() ?? ""
    );
    formData.append(
      "adults_max_capacity",
      rental?.infos?.maxAdults?.toString() ?? ""
    );
    formData.append(
      "children_max_capacity",
      rental?.infos?.maxChildren?.toString() ?? ""
    );
    formData.append(
      "bathrooms_count",
      rental?.infos?.bathrooms?.toString() ?? ""
    );

    rental?.rooms?.forEach((room, i) => {
      formData.append(`bedrooms[${i}]`, JSON.stringify(room));
    });

    formData.append("description", values.description);

    newFiles.forEach((photo, i) => {
      formData.append(`photos[${i}]`, photo);
    });

    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_ADD_CHECK_PHOTOS}`,
      formData
    );

    if (response?.data?.success) {
      onNext({ description: values.description, photos: newFiles });
    } else {
      setError(response.response?.data?.message);
    }

    setLoading(false);
  };

  return (
    <>
      <RentalUpdatePhotosModal
        isVisible={false}
        rentalId={"1"}
        oldFiles={[]}
        newFiles={newFiles}
        onClose={() => {}}
      />

      <form onSubmit={currentForm.handleSubmit(handleNextStep)}>
        <div className="flex flex-col w-full text-sm">
          <div className="flex justify-start pb-12">
            <div className="w-44">
              <Button
                type="secondary"
                disabled={loading}
                LeftIcon={ArrowLeftIcon}
                onClick={onBack}
              >
                {t("Global.previousStep")}
              </Button>
            </div>
          </div>

          <p className="text-lg font-semibold text-high-contrast">
            {t("AddRental.Photos.title")}
          </p>

          <p className="mt-4 mb-1 font-light text-low-contrast">
            {t("AddRental.Photos.subTitle")}
          </p>

          <div className="flex items-start space-x-1">
            <span className="mb-6 font-light text-low-contrast">
              {t("AddRental.Photos.subTitle2")}
            </span>

            <Link
              to={
                "https://docs.google.com/document/d/1rps-AZoWcvulD4pD35r3XA8mt3zjgkABqw2Po0dUbZ0/copy"
              }
              target="_blank"
              className="flex items-center font-bold underline underline-offset-4 text-low-contrast"
            >
              <span>La SuperDescription</span>
              <ArrowLeftIcon
                className="rotate-[130deg]"
                width={15}
                height={15}
              />
            </Link>
          </div>

          <div className="pb-2">
            <Controller
              control={currentForm.control}
              name="description"
              rules={{
                required: {
                  value: true,
                  message: t("AddRental.Photos.descriptionError"),
                },
              }}
              render={({ field: { value, onChange } }) => (
                <TextAreaInput
                  label={t("AddRental.Photos.description")}
                  rows={3}
                  value={value}
                  onTextChange={onChange}
                  error={currentForm.formState.errors.description?.message}
                  disabled={loading}
                  required={true}
                />
              )}
            />
          </div>

          <Separator accent="dark" />
          <div
            className="relative flex flex-col gap-4 mt-2"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              type="file"
              multiple={true}
              style={{ display: "none" }}
              ref={fileInputRef}
              accept="image/jpeg, image/png"
              onChange={handleAddFromInput}
            />
            {newFiles.length === 0 ? (
              <div
                className="flex flex-col items-center justify-center w-full gap-2 p-4 border-dashed cursor-pointer bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active"
                onClick={handleAdd}
              >
                <PhotoIcon className="w-10 h-10" />
                <p className="font-semibold text-high-contrast">
                  {t("AddRental.Photos.addPhoto")}
                </p>
                <div className="flex flex-col items-center justify-center gap-1">
                  <p className="font-light text-low-contrast">
                    {t("AddRental.Photos.upTo1")}
                  </p>
                  <p className="font-light text-low-contrast">
                    {t("AddRental.Photos.formatAccepted")}
                  </p>
                  <p className="font-light text-low-contrast">
                    {t("AddRental.Photos.WeightAccepted")}
                  </p>
                  <p className="font-light text-low-contrast">
                    {t("AddRental.Photos.DimensionsMinimum")}
                  </p>
                </div>

                <p className="mt-4 font-semibold underline text-low-contrast">
                  {t("AddRental.Photos.download")}
                </p>
              </div>
            ) : (
              <div className="w-full h-full group">
                {!loading && (
                  <div
                    className="absolute items-center justify-center hidden w-6 h-6 bg-white rounded-sm cursor-pointer border-element-border border-1 group-hover:flex"
                    style={{ top: -6, right: -6 }}
                    onClick={() => handleRemoveFile(0)}
                  >
                    <MinusIcon width={20} height={20} />
                  </div>
                )}

                <div className="flex flex-col items-center justify-center w-full h-48 gap-2 overflow-hidden border-dashed bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active">
                  <img
                    src={URL.createObjectURL(newFiles[0])}
                    className="object-cover w-full h-full"
                  />
                </div>
              </div>
            )}

            <div className="flex flex-wrap gap-2">
              {newFiles
                .filter((v, index) => index > 0)
                .map((file, index) => (
                  <PictureWithDelete
                    src={URL.createObjectURL(file)}
                    disabled={loading}
                    onRemove={() => handleRemoveFile(index + 1)}
                    type="image"
                    mime_type={file.type}
                  />
                ))}

              {Array.from({
                length: newFiles.length === 0 ? 4 : 5 - newFiles.length,
              }).map((p) => (
                <PictureWithDelete src={null} onRemove={() => {}} />
              ))}

              {!loading && (
                <div
                  className={`flex flex-col items-center justify-center w-24 h-24 bg-white rounded-6px border-1 border-element-border gap-2 cursor-pointer hover:bg-element-background-active`}
                  onClick={handleAdd}
                >
                  <PlusIcon className="w-8 h-8" />
                </div>
              )}
            </div>
          </div>

          <ErrorMessage>{error}</ErrorMessage>

          <div className="flex gap-4 pb-4 mt-8">
            <Button type="secondary" onClick={onCancel} disabled={loading}>
              {t("Global.cancel")}
            </Button>
            <Button RightIcon={ArrowRightWhiteIcon} loading={loading}>
              {t("AddReservation.Infos.nextStep")}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
