import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  RentalInformationsCardForm,
  RentalInformationsPayload,
} from "../../components/Rental/Infos/Informations/InformationsCard.type";
import paths from "../../constants/paths";
import { get } from "../../helpers/APIHelper";
import { useRentalInformationsUpdate } from "../../hooks/api/rental";
import { Rental, RentalInformations } from "./RentalPage.type";

interface RentalPageContextType {
  loadingState: {
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  };
  error: string | undefined;
  rental: Rental | undefined;
  currentRentalState: {
    currentRental: Rental | undefined;
    setCurrentRental: React.Dispatch<React.SetStateAction<Rental | undefined>>;
  };
  onUpdateRental: (rental: Rental | undefined) => void;
  isRentalActive: boolean | undefined;
  informations: {
    validationLoading: boolean;
    error: string | undefined;
    editModeState: {
      editMode: boolean;
      setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    };
    form: UseFormReturn<RentalInformationsCardForm>;
    onUpdate: (params?: { enabled?: number }) => void;
    disableRentalModalVisible: boolean;
    onDisableRental: () => void;
    onCloseDisableRental: () => void;
    onChangeIsRentalActive: () => void;
  };
}

const RentalPageContext = createContext<RentalPageContextType | undefined>(
  undefined
);

export const useRentalPageContext = () => {
  const context = useContext(RentalPageContext);
  if (!context) {
    throw new Error(
      "useRentalPageContext doit être utilisé dans RentalPageProvider"
    );
  }
  return context;
};

export const RentalPageProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { rentalId } = useParams();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const loadingValue = useMemo(() => ({ loading, setLoading }), [loading]);

  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const validationLoadingValue = useMemo(
    () => validationLoading,
    [validationLoading]
  );

  const [error, setError] = useState<string | undefined>();
  const errorValue = useMemo(() => error, [error]);

  const [editMode, setEditMode] = useState<boolean>(false);
  const editModeValue = useMemo(() => ({ editMode, setEditMode }), [editMode]);

  const [currentRental, setCurrentRental] = useState<Rental | undefined>();
  const currentRentalValue = useMemo(
    () => ({ currentRental, setCurrentRental }),
    [currentRental]
  );

  const [rental, setRental] = useState<Rental | undefined>();
  const rentalValue = useMemo(() => rental, [rental]);

  const onUpdateRental = (rental: Rental | undefined) => setRental(rental);

  const [isRentalActive, setIsRentalActive] = useState<boolean | undefined>(
    undefined
  );

  const fetchOneRental = async (id: string) => {
    setLoading(true);

    const URL = `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}/${id}`;
    const response = await get(URL);

    if (response.data?.success) {
      //TODO DEBUG
      const tmp: Rental = response.data.result;
      // tmp.details.external_connexions = {
      //   platform_booking: {
      //     enabled: true,
      //     last_connexion_date: "",
      //     hotel_id: "9729332",
      //     room_id: "972933202",
      //     rate_id: "34066250",
      //     price_multiplier_value: 0,
      //   },
      //   platform_airbnb: {
      //     enabled: true,
      //     airbnb_account_id: 1,
      //     last_connexion_date: "123",
      //     listing_id: "1",
      //     price_multiplier_value: 12,
      //   },
      // };
      // TODO CHANGE IN BACK TO DIRECTLY SEND AN ARRAY OF EQUIPMENTS
      tmp.details.equipments = JSON.parse(
        response.data.result.details.equipments
      );
      setRental(tmp);
      // setCurrentRental(tmp);
    } else {
      console.log(response);
      if (response.response.status === 400) {
        navigate(`${paths.RENTALS}`);
      }
      setError(response.response.data.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (Number(rentalId)) {
      fetchOneRental(rentalId as string);
    } else {
      navigate(paths.NOT_FOUND);
    }
  }, [rentalId]);

  useEffect(() => {
    if (rental) {
      setIsRentalActive(Boolean(rental?.details.informations.enabled));
    }
  }, [rental?.details.informations.enabled]);

  // * -- INFORMATIONS --
  const [informationsError, setInformationsError] = useState<
    string | undefined
  >();
  const form = useForm<RentalInformationsCardForm>({
    mode: "onChange",
  });

  // Enable / Disable rental
  const [disableRentalModalVisible, setDisableRentalModalVisible] =
    useState<boolean>(false);

  const onDisableRental = () => {
    setIsRentalActive(false);
    setDisableRentalModalVisible(!disableRentalModalVisible);
    onUpdate({ enabled: 0 });
  };

  const onCloseDisableRental = () => {
    setDisableRentalModalVisible(false);
  };

  const onChangeIsRentalActive = () => {
    if (isRentalActive) {
      setDisableRentalModalVisible(!disableRentalModalVisible);
    } else {
      setIsRentalActive(true);
      onUpdate({ enabled: 1 });
    }
  };

  const onUpdate = async (params?: { enabled?: number }) => {
    // TODO: [RentalInformationsCard] Add rental dependencies to data
    const payload: RentalInformationsPayload = {
      rental_category_id: form.getValues("rentalCategory"),
      rental_type_id: form.getValues("rentalType"),
      renting_type: form.getValues("rentingType"),
      management_type: form.getValues("managementType"),
      enabled:
        params?.enabled !== undefined
          ? params?.enabled
          : rental?.details?.informations?.enabled!,
      name: form.getValues("name"),
      adults_max_capacity: form.getValues("maxAdults"),
      children_max_capacity: form.getValues("maxChildren"),
      guests_max_capacity: form.getValues("maxGuest"),
      bathrooms_count: form.getValues("maxBathrooms"),
      checkin_min_time: form.getValues("minCheckinHour"),
      checkin_max_time: form.getValues("maxCheckinHour"),
      checkout_min_time: form.getValues("minCheckoutHour"),
      checkout_max_time: form.getValues("maxCheckoutHour"),
      min_nights_default: form.getValues("minNights"),
      max_nights_default: form.getValues("maxNights"),
    };

    useRentalInformationsUpdate(
      rentalId,
      payload,
      (rentalInformations: RentalInformations) => {
        if (rentalInformations) {
          if (rental) {
            setRental({
              ...rental,
              details: {
                ...rental.details,
                informations: {
                  rental_group_id: rentalInformations.rental_group_id,
                  rental_category_id: form.getValues("rentalCategory"),
                  rental_type_id: form.getValues("rentalType"),
                  renting_type: form.getValues("rentingType"),
                  management_type: form.getValues("managementType"),
                  enabled: isRentalActive ? 1 : 0,
                  name: form.getValues("name"),
                  short_name: rentalInformations.short_name,
                  address: rentalInformations.address,
                  longitude: rentalInformations.longitude,
                  latitude: rentalInformations.latitude,
                  postal_code: form.getValues("postal_code"),
                  city: form.getValues("city"),
                  country: {
                    ...rentalInformations.country,
                    id: form.getValues("countryId"),
                  },
                  guests_max_capacity: form.getValues("maxGuest"),
                  adults_max_capacity: form.getValues("maxAdults"),
                  children_max_capacity: form.getValues("maxChildren"),
                  bathrooms_count: form.getValues("maxBathrooms"),
                  bedrooms_count: form.getValues("maxBathrooms"),
                  beds_count: rentalInformations.bedrooms_count,
                  checkin_min_time: form.getValues("minCheckinHour"),
                  checkin_max_time: form.getValues("maxCheckinHour"),
                  checkout_min_time: form.getValues("minCheckoutHour"),
                  checkout_max_time: form.getValues("maxCheckoutHour"),
                  min_nights_default: form.getValues("minNights"),
                  max_nights_default: form.getValues("maxNights"),
                  parent_dependency: null,
                  children_dependencies: null,
                },
              },
            });
          }

          setEditMode(false);
        }
      },
      (message: string | undefined) => setInformationsError(message),
      () => {
        setInformationsError(undefined);
        setValidationLoading(true);
      },
      () => setValidationLoading(false)
    );
  };

  return (
    <RentalPageContext.Provider
      value={{
        loadingState: loadingValue,
        error: errorValue,
        rental: rentalValue,
        currentRentalState: currentRentalValue,
        onUpdateRental,
        isRentalActive,
        informations: {
          validationLoading: validationLoadingValue,
          error: informationsError,
          editModeState: editModeValue,
          form,
          onUpdate,
          disableRentalModalVisible,
          onDisableRental,
          onCloseDisableRental,
          onChangeIsRentalActive,
        },
      }}
    >
      {children}
    </RentalPageContext.Provider>
  );
};
