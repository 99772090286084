import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import ContactIcon from "../../../../assets/icons/contacts.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import PlusIcon from "../../../../assets/icons/plus.svg?react";
import TrashIcon from "../../../../assets/icons/trash.svg?react";
import {
  useDeleteContact,
  useUpdateContact,
} from "../../../../hooks/api/guestPage";
import { Rental } from "../../../../pages/RentalPage/RentalPage.type";
import {
  Contact,
  ContactExtras,
  EditContactForm,
} from "../../../../pages/Settings/Settings.type";
import { Badge } from "../../../Common/Badge/Badge";
import { Button } from "../../../Common/Button/Button";
import { Card } from "../../../Common/Card/Card";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { PhoneInput } from "../../../Common/PhoneInput/PhoneInput";
import { Separator } from "../../../Common/Separator/Separator";
import { Switch } from "../../../Common/Switch/Switch";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { Tooltip } from "../../../Common/Tooltip/Tooltip";
import { ContactProps } from "./ContactCard.type";
import { ContactCardSkeleton } from "./ContactCardSkeleton";
import useUserStore from "../../../../stores/useUserStore";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";

export const ContactCard: React.FC<ContactProps> = ({
  loading,
  rentals,
  rentalId,
  guestPage,
  workspaceOwner,
}) => {
  const userStore = useUserStore();
  const { t, i18n } = useTranslation();

  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [contacts, setContacts] = useState<Contact[]>(
    guestPage?.contacts || [],
  );
  const [contactsExtras, setContactsExtras] = useState<ContactExtras[]>(
    guestPage?.contacts_extras || [],
  );
  const [deletedContacts, setDeletedContacts] = useState<any>([]);
  const requiredFields: {
    email_enabled: boolean;
    phone_enabled: boolean;
    last_name: boolean;
    first_name: boolean;
    email: boolean;
  } = {
    first_name: true,
    last_name: true,
    email: true,
    phone_enabled: true,
    email_enabled: true,
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, errors },
    setValue,
    getValues,
    reset,
    clearErrors,
  } = useForm<EditContactForm>({
    mode: "all",
    defaultValues: {
      contacts: contacts || [],
      contacts_extras: contactsExtras || [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contacts",
  });

  const { fields: fieldsExtras } = useFieldArray({
    control,
    name: "contacts_extras",
  });

  const handleSwitch = (index: number, fieldName: string) => {
    const currentContacts = getValues("contacts");
    const updatedContacts = currentContacts.map((contact, idx) => {
      if (idx === index) {
        if (fieldName === "phone")
          return { ...contact, phone_enabled: !contact.phone_enabled };
        if (fieldName === "email")
          return { ...contact, email_enabled: !contact.email_enabled };
      }
      return contact;
    });
    setValue("contacts", updatedContacts);
  };

  useEffect(() => {
    if (contacts && editMode) {
      setValue("contacts", contacts);
    }
  }, [editMode, contacts]);

  useEffect(() => {
    if (contactsExtras && editMode) {
      setValue("contacts_extras", contactsExtras);
    }
  }, [editMode, contactsExtras]);

  const initSubmit = () => {
    setError("");
    setValidationLoading(true);
  };
  const handleAddOrUpdateContact = async (data: EditContactForm) => {
    initSubmit();

    try {
      if (deletedContacts.length > 0) {
        await handleDeleteContactById();
      }

      await useUpdateContact(
        rentalId,
        guestPage.id,
        data,
        (rental: Rental) => {
          setValidationLoading(false);
          setEditMode(false);
          const contactsData = rental.guest_page.contacts;
          const contactExtrasData = rental.guest_page.contacts_extras;
          setContacts(contactsData);
          setContactsExtras(contactExtrasData);
        },
        (message: string | undefined) => {
          setValidationLoading(false);
          setError(message);
        },
      );
    } catch (error) {
      setValidationLoading(false);
      setError("An unexpected error occurred.");
    }
  };

  const handleDeleteContact = (index: number) => {
    contacts[index] &&
      contacts[index].id &&
      setDeletedContacts([...deletedContacts, contacts[index].id]);
    remove(index);
  };

  const handleDeleteContactById = async () => {
    try {
      const deletionPromises = deletedContacts.map(
        async (contactId: string) => {
          return useDeleteContact(
            rentalId,
            parseInt(contactId),
            (rental: Rental) => {
              console.log(
                rental,
                `[useDeleteContact_success] Deleted contact with ID: ${contactId}`,
              );
            },
            (message: string | undefined) => {
              console.error(
                message,
                `[useDeleteContact_error] Failed to delete contact with ID: ${contactId}`,
              );
            },
          );
        },
      );
      await Promise.all(deletionPromises);
    } catch (error) {
      console.error("Error deleting contacts:", error);
    }
  };

  const handleChangeMode = () => {
    reset();
    setEditMode(!editMode);
    setDeletedContacts([]);
  };

  if (loading) return <ContactCardSkeleton />;

  return (
    <Card
      loading={validationLoading}
      Icon={ContactIcon}
      label={t("Rental.Infos.TravelerPage.contact")}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        disabled: !getCurrentCanEdit({ user: userStore.user, workspaceOwner }),
        onClick: editMode
          ? handleSubmit((data: EditContactForm) => {
              handleAddOrUpdateContact(data);
            })
          : () => handleChangeMode(),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => {
                setEditMode(false);
                clearErrors();
              },
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-y-4">
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        {editMode ? (
          <div className="flex flex-col gap-3">
            <div className="">
              <div className="flex flex-col gap-2">
                <p className="text-lg font-semibold">Contact Principal</p>
                {fields.map((item, index) => (
                  <div className="flex flex-col gap-4" key={item.id}>
                    <div className="flex gap-3">
                      <TextInput
                        disabled={validationLoading}
                        label={t("GuestPage.Contact.firstName")}
                        value={item.first_name ?? ""}
                        error={errors.contacts?.[index]?.first_name?.message}
                        required={requiredFields.first_name}
                        register={register(`contacts.${index}.first_name`, {
                          required: {
                            value: requiredFields.first_name,
                            message: t("Global.Errors.requiredField", {
                              fieldName: t(
                                "Rental.Infos.TravelerPage.ContactCard.firstNameFieldLabel",
                              ),
                            }),
                          },
                        })}
                      />
                      <TextInput
                        disabled={validationLoading}
                        label={t("GuestPage.Contact.lastName")}
                        value={item.last_name}
                        required={requiredFields.last_name}
                        error={errors.contacts?.[index]?.last_name?.message}
                        register={register(`contacts.${index}.last_name`, {
                          required: {
                            value: requiredFields.last_name,
                            message: t("Global.Errors.requiredField", {
                              fieldName: t(
                                "Rental.Infos.TravelerPage.ContactCard.nameFieldLabel",
                              ),
                            }),
                          },
                        })}
                      />
                    </div>
                    <div className="flex gap-3">
                      <TextInput
                        disabled={validationLoading}
                        label={t("GuestPage.Contact.email")}
                        value={item.email ?? ""}
                        error={errors.contacts?.[index]?.email?.message}
                        required={
                          item.email_enabled ? requiredFields.email : false
                        }
                        register={register(`contacts.${index}.email`, {
                          required: {
                            value: item.email_enabled
                              ? requiredFields.email
                              : false,
                            message: t("Global.Errors.requiredField", {
                              fieldName: t(
                                "Rental.Infos.TravelerPage.ContactCard.emailFieldLabel",
                              ),
                            }),
                          },
                        })}
                        leftButton={
                          <Switch
                            value={item.email_enabled as boolean}
                            onClick={() => handleSwitch(index, "email")}
                          />
                        }
                        classNames={{ divLabel: "justify-between mb-2" }}
                      />
                    </div>
                    <div>
                      <Controller
                        control={control}
                        name={`contacts.${index}.phone`}
                        rules={{
                          required: {
                            value: true,
                            message: t("Global.Errors.requiredField", {
                              fieldName: t(
                                "Rental.Infos.TravelerPage.ContactCard.phoneFieldErrorLabel",
                              ),
                            }),
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <PhoneInput
                            label={t("Profil.phoneNumber")}
                            value={value}
                            placeholder="06 11 23 58 13"
                            onChangeText={onChange}
                            required={true}
                            leftButton={
                              <Switch
                                value={item.phone_enabled as boolean}
                                onClick={() => handleSwitch(index, "phone")}
                              />
                            }
                            error={errors.contacts?.[index]?.phone?.message}
                            classNames={{ input: "mt-2" }}
                          />
                        )}
                      />
                    </div>
                    <div className="flex flex-row justify-end">
                      {index !== 0 && (
                        <Button
                          type="secondary"
                          className=""
                          displayLargeBtn={false}
                          LeftIcon={TrashIcon}
                          onClick={() => handleDeleteContact(index)}
                        />
                      )}
                    </div>
                    <Separator />
                  </div>
                ))}
              </div>
              <div className="flex float-right">
                <Button
                  type="secondary"
                  RightIcon={PlusIcon}
                  className="flex w-1/2"
                  onClick={() =>
                    append({
                      email_enabled: false,
                      phone_enabled: false,
                      first_name: "",
                      last_name: "",
                      email: "",
                      phone: "",
                    })
                  }
                >
                  {t("GuestPage.Contact.addContact")}
                </Button>
              </div>
            </div>
            <Separator accent="dark" />
            <p className="text-lg font-semibold">Contact pour les extras</p>
            {fieldsExtras.map((item, index) => (
              <div className="flex flex-col gap-4">
                <div className="flex gap-3">
                  <TextInput
                    disabled={validationLoading}
                    label={t("GuestPage.Contact.firstName")}
                    value={item.first_name ?? ""}
                    error={errors.contacts_extras?.[index]?.first_name?.message}
                    required={requiredFields.first_name}
                    register={register(`contacts_extras.${index}.first_name`, {
                      required: {
                        value: requiredFields.first_name,
                        message: t("Global.Errors.requiredField", {
                          fieldName: t(
                            "Rental.Infos.TravelerPage.ContactCard.firstNameFieldLabel",
                          ),
                        }),
                      },
                    })}
                  />
                  <TextInput
                    disabled={validationLoading}
                    label={t("GuestPage.Contact.lastName")}
                    value={item.last_name}
                    required={requiredFields.last_name}
                    error={errors.contacts_extras?.[index]?.last_name?.message}
                    register={register(`contacts_extras.${index}.last_name`, {
                      required: {
                        value: requiredFields.last_name,
                        message: t("Global.Errors.requiredField", {
                          fieldName: t(
                            "Rental.Infos.TravelerPage.ContactCard.nameFieldLabel",
                          ),
                        }),
                      },
                    })}
                  />
                </div>
                <div className="flex gap-3">
                  <TextInput
                    disabled={validationLoading}
                    label={t("GuestPage.Contact.email")}
                    value={item.email ?? ""}
                    error={errors.contacts_extras?.[index]?.email?.message}
                    required={true}
                    register={register(`contacts_extras.${index}.email`, {
                      required: {
                        value: true,
                        message: t("Global.Errors.requiredField", {
                          fieldName: t(
                            "Rental.Infos.TravelerPage.ContactCard.emailFieldLabel",
                          ),
                        }),
                      },
                    })}
                    classNames={{ divLabel: "justify-between mb-2" }}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name={`contacts_extras.${index}.phone`}
                    rules={{
                      required: {
                        value: true,
                        message: t("Global.Errors.requiredField", {
                          fieldName: t(
                            "Rental.Infos.TravelerPage.ContactCard.phoneFieldErrorLabel",
                          ),
                        }),
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        label={t("Profil.phoneNumber")}
                        value={value}
                        placeholder="06 11 23 58 13"
                        onChangeText={onChange}
                        required={true}
                        error={errors.contacts_extras?.[index]?.phone?.message}
                        classNames={{ input: "mt-2" }}
                      />
                    )}
                  />
                </div>
                <Separator />
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-3">
              <p className="text-lg font-semibold">Contact Principal</p>
              {contacts.map((contact: Contact, index: number) => (
                <div className="flex flex-col gap-3" key={index}>
                  <div className="flex flex-col gap-1">
                    <p className="text-sm font-semibold">
                      {t(
                        "Rental.Infos.TravelerPage.ContactCard.firstnameAndName",
                      )}
                    </p>
                    <p className="text-xs text-low-contrast">
                      {contact.first_name} {contact.last_name}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between gap-1">
                    <div className="flex flex-col gap-1">
                      <p className="text-sm font-semibold">
                        {t(
                          "Rental.Infos.TravelerPage.ContactCard.emailFieldLabel",
                        )}
                      </p>
                      <p className="text-xs text-low-contrast">
                        {contact.email}
                      </p>
                    </div>

                    <div>
                      <Tooltip
                        Icon={
                          <Badge
                            label={
                              contact.email_enabled
                                ? t("GuestPage.Contact.emailVisible")
                                : t("GuestPage.Contact.emailNotVisible")
                            }
                            status={contact.email_enabled ? "success" : "fail"}
                            size="large"
                          />
                        }
                        value={
                          contact.email_enabled
                            ? t("GuestPage.Contact.emailVisible")
                            : t("GuestPage.Contact.emailNotVisible")
                        }
                        classNames={{
                          container: "top-full mt-1 right-0",
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between gap-1">
                    <div className="flex flex-col gap-1">
                      <p className="text-sm font-semibold">
                        {t(
                          "Rental.Infos.TravelerPage.ContactCard.phoneFieldLabel",
                        )}
                      </p>
                      <p className="text-xs text-low-contrast">
                        {contact.phone}
                      </p>
                    </div>

                    <div>
                      <Tooltip
                        Icon={
                          <Badge
                            label={
                              contact.phone_enabled
                                ? t("GuestPage.Contact.phoneVisible")
                                : t("GuestPage.Contact.phoneNotVisible")
                            }
                            status={contact.phone_enabled ? "success" : "fail"}
                            size="large"
                          />
                        }
                        value={
                          contact.phone_enabled
                            ? t("GuestPage.Contact.phoneVisible")
                            : t("GuestPage.Contact.phoneNotVisible")
                        }
                        classNames={{
                          container: "top-full mt-1 right-0",
                        }}
                      />
                    </div>
                  </div>
                  {index !== contacts.length - 1 && contacts.length != 1 && (
                    <Separator />
                  )}
                </div>
              ))}
              <Separator accent="dark" />
              <p className="text-lg font-semibold">Contact pour les extras</p>
              {contactsExtras.map((contact: ContactExtras, index: number) => (
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col gap-1">
                    <p className="text-sm font-semibold">
                      {t(
                        "Rental.Infos.TravelerPage.ContactCard.firstnameAndName",
                      )}
                    </p>
                    <p className="text-xs text-low-contrast">
                      {contact.first_name} {contact.last_name}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between gap-1">
                    <div className="flex flex-col gap-1">
                      <p className="text-sm font-semibold">
                        {t(
                          "Rental.Infos.TravelerPage.ContactCard.emailFieldLabel",
                        )}
                      </p>
                      <p className="text-xs text-low-contrast">
                        {contact.email}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between gap-1">
                    <div className="flex flex-col gap-1">
                      <p className="text-sm font-semibold">
                        {t(
                          "Rental.Infos.TravelerPage.ContactCard.phoneFieldLabel",
                        )}
                      </p>
                      <p className="text-xs text-low-contrast">
                        {contact.phone}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};
