export default [
  {
    id: 1,
    name: "United Arab Emirates dirham",
    symbol: "د.إ",
    code: "AED",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 2,
    name: "Afghan afghani",
    symbol: "Af",
    code: "AFN",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 3,
    name: "Swazi lilangeni",
    symbol: "L",
    code: "SZL",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 4,
    name: "Armenian dram",
    symbol: "Դ",
    code: "AMD",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 5,
    name: "Aruban florin",
    symbol: "ƒ",
    code: "AWG",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 6,
    name: "Angolan kwanza",
    symbol: "Kz",
    code: "AOA",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 7,
    name: "Azerbaijani manat",
    symbol: "ман",
    code: "AZN",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 8,
    name: "Bulgarian lev",
    symbol: "лв",
    code: "BGN",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 9,
    name: "Bahraini dinar",
    symbol: "ب.د",
    code: "BHD",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 10,
    name: "CFP franc",
    symbol: "f",
    code: "XPF",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 11,
    name: "Boliviano",
    symbol: "Bs.",
    code: "BOB",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 12,
    name: "Brazilian real",
    symbol: "R$",
    code: "BRL",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 13,
    name: "Indian rupee",
    symbol: "₹",
    code: "INR",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 14,
    name: "Macanese pataca",
    symbol: "P",
    code: "MOP",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 15,
    name: "Belarusian ruble",
    symbol: "Br",
    code: "BYN",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 16,
    name: "Japanese yen",
    symbol: "¥",
    code: "JPY",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 17,
    name: "Costa Rican colon",
    symbol: "₡",
    code: "CRC",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 18,
    name: "Czech koruna",
    symbol: "Kč",
    code: "CZK",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 19,
    name: "Swedish krona",
    symbol: "kr",
    code: "SEK",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 20,
    name: "Algerian dinar",
    symbol: "د.ج",
    code: "DZD",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 21,
    name: "South Sudanese pound",
    symbol: "£",
    code: "SSP",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 22,
    name: "Eritrean nakfa",
    symbol: "Nfk",
    code: "ERN",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 23,
    name: "Euro",
    symbol: "€",
    code: "EUR",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 24,
    name: "Georgian lari",
    symbol: "ლ",
    code: "GEL",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 25,
    name: "Ghanaian cedi",
    symbol: "¢",
    code: "GHS",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 26,
    name: "Gambian dalasi",
    symbol: "D",
    code: "GMD",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 27,
    name: "Guatemalan quetzal",
    symbol: "Q",
    code: "GTQ",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 28,
    name: "Haitian gourde",
    symbol: "G",
    code: "HTG",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 29,
    name: "Hungarian forint",
    symbol: "Ft",
    code: "HUF",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 30,
    name: "Indonesian rupiah",
    symbol: "Rp",
    code: "IDR",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 31,
    name: "Israeli new shekel",
    symbol: "₪",
    code: "ILS",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 32,
    name: "Iraqi dinar",
    symbol: "ع.د",
    code: "IQD",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 33,
    name: "Yemeni rial",
    symbol: "rial",
    code: "YER",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 34,
    name: "Icelandic króna",
    symbol: "Kr",
    code: "ISK",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 35,
    name: "Jordanian dinar",
    symbol: "د.ا",
    code: "JOD",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 36,
    name: "Ugandan shilling",
    symbol: "Sh",
    code: "UGX",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 37,
    name: "Cambodian riel",
    symbol: "៛",
    code: "KHR",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 38,
    name: "Comoro franc",
    symbol: "fc",
    code: "KMF",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 39,
    name: "South Korean won",
    symbol: "₩",
    code: "KRW",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 40,
    name: "Kuwaiti dinar",
    symbol: "د.ك",
    code: "KWD",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 41,
    name: "Kazakhstani tenge",
    symbol: "〒",
    code: "KZT",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 42,
    name: "Lao kip",
    symbol: "₭",
    code: "LAK",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 43,
    name: "Lebanese pound",
    symbol: "ل.ل",
    code: "LBP",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 44,
    name: "Sri Lankan rupee",
    symbol: "Rs",
    code: "LKR",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 45,
    name: "Libyan dinar",
    symbol: "ل.د",
    code: "LYD",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 46,
    name: "Moroccan dirham",
    symbol: "د.م.",
    code: "MAD",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 47,
    name: "Macedonian denar",
    symbol: "ден",
    code: "MKD",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 48,
    name: "Papua New Guinean kina",
    symbol: "K",
    code: "PGK",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 49,
    name: "Mongolian tögrög",
    symbol: "₮",
    code: "MNT",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 50,
    name: "Mauritanian ouguiya",
    symbol: "UM",
    code: "MRO",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 51,
    name: "Seychelles rupee",
    symbol: "Rs",
    code: "SCR",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 52,
    name: "Maldivian rufiyaa",
    symbol: "ރ.",
    code: "",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 53,
    name: "Malawian kwacha",
    symbol: "MK",
    code: "MWK",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 54,
    name: "Malaysian ringgit",
    symbol: "RM",
    code: "MYR",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 55,
    name: "Mozambican metical",
    symbol: "MTn",
    code: "MZN",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 56,
    name: "Nigerian naira",
    symbol: "₦",
    code: "NGN",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 57,
    name: "Nicaraguan córdoba",
    symbol: "C$",
    code: "NIO",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 58,
    name: "Omani rial",
    symbol: "ر.ع.",
    code: "OMR",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 59,
    name: "Panamanian balboa",
    symbol: "B/.",
    code: "PAB",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 60,
    name: "Peruvian Sol",
    symbol: "S/.",
    code: "PEN",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 61,
    name: "Philippine peso",
    symbol: "₱",
    code: "PHP",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 62,
    name: "Polish złoty",
    symbol: "zł",
    code: "PLN",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 63,
    name: "Paraguayan guaraní",
    symbol: "₲",
    code: "PYG",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 64,
    name: "Qatari riyal",
    symbol: "ر.ق",
    code: "QAR",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 65,
    name: "Serbian dinar",
    symbol: "din",
    code: "RSD",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 66,
    name: "Russian ruble",
    symbol: "₽",
    code: "RUB",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 67,
    name: "Saudi riyal",
    symbol: "ر.س",
    code: "SAR",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 68,
    name: "Sierra Leonean leone",
    symbol: "Le",
    code: "SLL",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 69,
    name: "São Tomé and Príncipe dobra",
    symbol: "Db",
    code: "STN",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 70,
    name: "Syrian pound",
    symbol: "ل.س",
    code: "SYP",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 71,
    name: "Thai baht",
    symbol: "฿",
    code: "THB",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 72,
    name: "Tajikistani somoni",
    symbol: "ЅМ",
    code: "TJS",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 73,
    name: "Turkmenistani manat",
    symbol: "m",
    code: "TMT",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 74,
    name: "Tunisian dinar",
    symbol: "د.ت",
    code: "TND",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 75,
    name: "Tongan paʻanga",
    symbol: "T$",
    code: "TOP",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 76,
    name: "Turkish lira",
    symbol: "₺",
    code: "TRY",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 77,
    name: "Ukrainian hryvnia",
    symbol: "₴",
    code: "UAH",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 78,
    name: "Uruguay Peso en Unidades Indexadas",
    symbol: "UYI",
    code: "UYI",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 79,
    name: "Venezuelan bolívar",
    symbol: "Bs F",
    code: "VEF",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 80,
    name: "Vietnamese đồng",
    symbol: "₫",
    code: "VND",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 81,
    name: "Vanuatu vatu",
    symbol: "Vt",
    code: "VUV",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 82,
    name: "Samoan tala",
    symbol: "T",
    code: "WST",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 83,
    name: "West African CFA franc",
    symbol: "XOF",
    code: "XOF",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 84,
    name: "South African rand",
    symbol: "R",
    code: "ZAR",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 85,
    name: "Zambian kwacha",
    symbol: "ZK",
    code: "ZMW",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 86,
    name: "Romanian leu",
    symbol: "RON",
    code: "RON",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 87,
    name: "Swiss franc",
    symbol: "CHF",
    code: "CHF",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 88,
    name: "United States dollar",
    symbol: "$",
    code: "USD",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 89,
    name: "Cameroonian CFA franc",
    symbol: "XAF",
    code: "XAF",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
  {
    id: 90,
    name: "Canadian dollar",
    symbol: "$",
    code: "CAD",
    created_at: "2024-09-23 14:06:06",
    updated_at: "2024-09-23 14:06:06",
  },
];
