import React from "react";
import { CalendarRentalLineSkeletonProps } from "./Calendar.type";
import {
  CALENDAR_CELL_HEIGHT,
  CALENDAR_NB_DAYS_AFTER,
  CALENDAR_NB_DAYS_BEFORE,
  CALENDAR_RENTAL_NAME_WIDTH,
} from "../../constants/calendar";
import { CalendarCellSkeleton } from "./CellSkeleton";

export const CalendarRentalLineSkeleton: React.FC<
  CalendarRentalLineSkeletonProps
> = ({ from, to, daysOffset }) => {
  const nbDays = to.diff(from, "days");

  return (
    <>
      <tr>
        <td
          style={{
            width: CALENDAR_RENTAL_NAME_WIDTH,
            height: CALENDAR_CELL_HEIGHT,
          }}
          className="bg-white border-t-1 border-element-border animate-pulse"
        >
          <div className="flex gap-2 items-center cursor-pointer relative">
            <div className="w-8 h-8 border-1 rounded-2px bg-slate-200" />
            <p className="h-2 w-44 bg-slate-300"></p>

            <div
              className="absolute bg-element-border"
              style={{
                width: 1,
                height: CALENDAR_CELL_HEIGHT + 3,
                left: CALENDAR_RENTAL_NAME_WIDTH - 7,
                top: -10,
              }}
            />
          </div>
        </td>

        {Array.from({ length: CALENDAR_NB_DAYS_BEFORE }).map((v, index) => (
          <CalendarCellSkeleton
            key={index}
            index={index}
            daysOffset={daysOffset}
          />
        ))}

        {Array.from({ length: nbDays }).map((v, index) => (
          <CalendarCellSkeleton
            key={index}
            index={index}
            daysOffset={daysOffset}
          />
        ))}

        {Array.from({ length: CALENDAR_NB_DAYS_AFTER }).map((v, index) => (
          <CalendarCellSkeleton
            key={index}
            index={index}
            daysOffset={daysOffset}
          />
        ))}
      </tr>
    </>
  );
};
