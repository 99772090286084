import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BookingLayout } from "../../components/Layout/BookingLayout/BookingLayout";
import { BookingInvoicePageProps } from "./BookingInvoicePage.type";

import { useParams } from "react-router-dom";
import { ReservationInvoiceCard } from "../../components/Reservation/Invoice/ReservationInvoiceCard";
import { ReservationPaymentAndDepositLinksCard } from "../../components/Reservation/PaymentAndDepositLinks/PaymentAndDepositLinksCard";
import { ReservationPaymentAndDepositLinksModal } from "../../components/Reservation/PaymentAndDepositLinks/PaymentAndDepositLinksModal";
import { ReservationSummaryCard } from "../../components/Reservation/Summary/SummaryCard";
import paths from "../../constants/paths";
import { fetchOneReservation } from "../../helpers/reservationHelper";
import { useModal } from "../../hooks/useModal";
import { useTablePage } from "../../hooks/useTablePage";
import { ReservationResponse } from "../../types/GETTypes";

export const BookingInvoicePage: React.FC<BookingInvoicePageProps> = () => {
  const { t } = useTranslation();
  const { reservationId } = useParams();
  const [reservation, setReservation] = useState<
    ReservationResponse | undefined
  >();
  const tableMessages = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.MESSAGES}/${reservationId}`,
    "messages",
    { combine: true, reverseData: true }
  );

  const handleReservationSuccess = (value: ReservationResponse) => {
    setReservation(value);
  };

  useEffect(() => {
    if (reservationId) {
      fetchOneReservation(reservationId, handleReservationSuccess, () => {});

      tableMessages.fetch({
        limit: 10,
        sort_field: "captured_at",
        sort_direction: "desc",
      });
    }
  }, [reservationId]);

  const getActions = () => {
    return <div className="flex flex-row gap-2"></div>;
  };

  // Payment & deposit links card
  const sendModal = useModal<{ index: number; isDeposit: boolean }>();

  const [sendSuccessed, setSendSuccessed] = useState<{
    payments: boolean[];
    deposit: boolean;
  }>({ payments: [false, false, false], deposit: false });

  const handleSendPaymentOrDepositLink = (
    data: { index: number; isDeposit: boolean } | undefined | null
  ) => {
    if (data?.isDeposit) {
      setSendSuccessed({ ...sendSuccessed, deposit: true });
    } else {
      const nextPayments = JSON.parse(JSON.stringify(sendSuccessed?.payments));
      nextPayments[data?.index ?? 0] = true;
      setSendSuccessed({ ...sendSuccessed, payments: nextPayments });
    }

    // Close modal
    sendModal.close();
  };

  return (
    <BookingLayout
      navbarActiveItem="payments"
      rightChildren={getActions()}
      reservation={reservation}
    >
      <>
        {reservation !== undefined ? (
          <ReservationPaymentAndDepositLinksModal
            reservation={reservation}
            data={sendModal.data}
            isVisible={sendModal.isVisible}
            onClose={sendModal.close}
            onSuccess={handleSendPaymentOrDepositLink}
          />
        ) : null}

        <div className="flex flex-col w-full gap-y-8">
          <ReservationSummaryCard />

          <div className="flex justify-between gap-x-8">
            <div className="flex-1">
              <ReservationPaymentAndDepositLinksCard
                paymentSchedule={reservation?.payment_schedule}
                sendSuccessed={sendSuccessed}
                onSendPaymentOrDeposit={(index, isDeposit) =>
                  sendModal.open({ index, isDeposit })
                }
              />
            </div>

            <div className="flex-1">
              <ReservationInvoiceCard />
            </div>
          </div>
        </div>
      </>
    </BookingLayout>
  );
};
