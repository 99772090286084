import React, { useEffect, useState } from "react";
import { RentalFacilitiesEditGroupProps } from "./Facilities.type";

import { useTranslation } from "react-i18next";
import ChevronDownIcon from "../../../../assets/icons/chevron-down.svg?react";
import ChevronRightIcon from "../../../../assets/icons/chevron-right.svg?react";
import { SimpleBox } from "../../../Modal/Airbnb/Connect/AirbnbConnectStepImport";

export const RentalFacilitiesEditGroup: React.FC<
  RentalFacilitiesEditGroupProps
> = ({ group, equipments, loading, onChangeFacility = () => {} }) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState<boolean>(false);

  const containsAnEquipment = () => {
    let result = false;

    group.facilities.forEach((f) => {
      if (
        equipments?.findIndex(
          (e) => e.toUpperCase() === f.code.toUpperCase()
        ) !== -1
      )
        result = true;
    });

    return result;
  };

  useEffect(() => {
    setOpen(containsAnEquipment());
  }, []);

  const handleClickFacility = (value: string) => {
    if (loading) return;

    if (
      equipments?.findIndex((e) => e.toUpperCase() === value.toUpperCase()) !==
      -1
    )
      onChangeFacility(
        equipments?.filter((e) => e.toUpperCase() !== value.toUpperCase()) ?? []
      );
    else onChangeFacility([...(equipments ?? []), value.toUpperCase()]);
  };

  return (
    <div className="flex flex-col gap-2 pb-3 mt-2 mb-4 border-b-1 border-element-border last:border-0">
      <div
        className="flex items-center gap-2 cursor-pointer"
        onClick={() => setOpen(!isOpen)}
      >
        {group.Icon ? <group.Icon className="w-4 h-4" /> : null}
        <p className="flex-1 font-semibold text-low-contrast">
          {t(group.nameI18n)}
        </p>

        {isOpen ? (
          <ChevronDownIcon className="w-5 h-5" />
        ) : (
          <ChevronRightIcon className="w-5 h-5" />
        )}
      </div>

      {isOpen ? (
        <div className="mt-1 bg-white rounded-6px border-element-border border-1">
          {group.facilities.map((f) => {
            const isSelected =
              equipments?.findIndex(
                (e) => e.toUpperCase() === f.code.toUpperCase()
              ) !== -1;

            return (
              <div
                key={f.code}
                className="flex gap-2 p-3 cursor-pointer border-b-1 border-element-border last:border-0"
                onClick={() => handleClickFacility(f.code)}
              >
                <SimpleBox value={isSelected} disabled={loading} />
                <p className="font-semibold text-high-contrast">
                  {t(f.nameI18n)}
                </p>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
