import { ClassValue } from "clsx";
import React, { useEffect, useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { cn } from "../../../helpers/classHelper";
import { ClassNamesType, TextType } from "../../../types/commonTypes";
import { Button } from "../Button/Button";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

export type TextAreaInputProps = {
  register?: UseFormRegisterReturn<any>;
  label?: TextType;
  value: string | undefined;
  required?: boolean;
  error?: string | null;
  placeholder?: TextType;
  resize?: { x: boolean; y: boolean } | boolean;
  disabled?: boolean;
  rows?: number;
  countLimit?: number;
  onSelect?: (event: any) => void;
  onTextChange?: (text: string) => void;
  onSave?: () => void;
  onCancel?: () => void;
  onFocus?: () => void;
  classNames?: ClassNamesType;
};

export const TextAreaInput: React.FC<TextAreaInputProps> = ({
  register,
  label,
  value,
  required = false,
  error,
  placeholder,
  resize = true,
  disabled,
  rows = 6,
  countLimit,
  onSelect = () => {},
  onTextChange = () => {},
  onSave,
  onCancel,
  onFocus = () => {},
  classNames = undefined,
}) => {
  const { t } = useTranslation();

  const [currentCount, setCurrentCount] = useState<number>(0);
  const [showButtons, setShowButtons] = useState<boolean>(false);

  useEffect(() => {
    if (value && value.length >= 0 && value.length <= countLimit!) {
      setCurrentCount(value.length);
    }
  }, [value]);

  const handleChange = (event: any) => {
    if (countLimit) {
      if (event.target.value?.length <= countLimit!) {
        onTextChange(event.target.value);
      }
    } else onTextChange(event.target.value);
  };

  const getBorder = () => {
    if (error) return "border-error";
    else return "border-element-border";
  };

  const getTextColor = () => {
    return disabled ? "text-low-contrast" : "text-high-contrast";
  };

  const getOutline = () => {
    if (error) return "focus:outline-error";
    else return "focus:outline-focus";
  };

  const getRows = () => {
    let nextRows = rows;

    if (onSave && showButtons) nextRows -= 2;

    return nextRows < 1 ? 1 : nextRows;
  };

  const getResize = (): ClassValue => {
    let classes: ClassValue[] = [];
    if (typeof resize === "boolean" && resize) {
      classes.push("resize-y");
    }

    if (typeof resize === "object") {
      if (resize.x) {
        classes.push("resize-x");
      }

      if (resize.y) {
        classes.push("resize-y");
      }

      if (resize.x && resize.y) {
        classes.push("resize");
      }
    }

    if (classes?.length === 0) {
      classes.push("resize-none");
    }

    return classes.join(" ");
  };

  const handleCancel = () => {
    setShowButtons(false);
    onCancel && onCancel();
  };

  const handleSave = () => {
    setShowButtons(false);
    onSave && onSave();
  };

  return (
    <div className="flex flex-col mt-1">
      <div className="flex justify-between">
        {label ? (
          <>
            <p
              className={cn(
                "mb-1 text-sm font-semibold text-left w-max",
                classNames?.label
              )}
            >
              {label}
              {required ? (
                <span
                  className={cn(
                    "ml-1 text-lg text-active",
                    classNames?.labelRequired
                  )}
                >
                  *
                </span>
              ) : null}
            </p>
          </>
        ) : null}
        {countLimit ? (
          <p
            className={cn("text-low-contrast", classNames?.label)}
          >{`${currentCount}/${countLimit}`}</p>
        ) : null}
      </div>
      <textarea
        {...register}
        rows={getRows()}
        className={cn(
          `mt-1 border-1 ${getBorder()} rounded-6px p-2 ${getOutline()} ${getTextColor()} ${getResize()}`,
          {
            "bg-element-background-disabled": disabled,
          },
          classNames?.input
        )}
        placeholder={placeholder?.toString()}
        disabled={disabled}
        value={register ? undefined : value}
        onChange={register?.onChange ?? handleChange}
        onSelect={onSelect}
        onFocus={() => setShowButtons(true)}
        onFocusCapture={onFocus}
      />
      {onSave && showButtons && (
        <div className="flex gap-3 mt-2">
          <Button type="secondary" onClick={handleCancel} disabled={disabled}>
            {t("Global.cancel")}
          </Button>
          <Button onClick={handleSave} disabled={disabled}>
            {t("Global.save")}
          </Button>
        </div>
      )}
      <ErrorMessage errorType="FORM" childrenClassName={classNames?.error}>
        {error}
      </ErrorMessage>
    </div>
  );
};
