import React from "react";
import { TextType } from "../../../types/commonTypes";
import { SimpleBox } from "../../Modal/Airbnb/Connect/AirbnbConnectStepImport";

export const DaysFilterDayItem: React.FC<{
  label: TextType;
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}> = ({ label, disabled, value, onChange }) => {
  return (
    <div
      className="flex flex-col gap-2 justify-center items-center w-6 cursor-pointer"
      onClick={() => onChange(!value)}
    >
      <p className="font-semibold text-high-contrast">{label}</p>
      <SimpleBox value={value} disabled={disabled} />
    </div>
  );
};
