import { useState } from "react";
import { CalendarInputValue } from "../../../Common/CalendarInput/CalendarInput.type";
import { DateRangePreset } from "../../../Common/DateFilter/DateFilter.type";

export const useInitPaymentDepositFiltersStates = () => {
  const [dates, setDates] = useState<CalendarInputValue>([null, null]);
  const [dateRangePreset, setDateRangePreset] =
    useState<DateRangePreset>("next_seven_days");

  return {
    datesState: [dates, setDates],
    dateRangePresetState: [dateRangePreset, setDateRangePreset],
  } as const;
};
