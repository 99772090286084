import { t } from "i18next";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CloseIcon from "../../assets/icons/close.svg?react";
import { useChecklist } from "../../hooks/api/checklist";
import { ValueType } from "../../types/commonTypes";
import { Button } from "../Common/Button/Button";
import { CenterModal } from "../Common/CenterModal/CenterModal";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";

export const ChecklistDeleteModal: React.FC<{
  isVisible: boolean;
  onClose: () => void;
  onSuccessDelete: (id: ValueType) => void;
  checklistId: ValueType | undefined | null;
}> = ({ isVisible, onClose, checklistId, onSuccessDelete }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { rentalId } = useParams();

  const handleClose = () => {
    if (loading) return;

    onClose();
  };

  const handleSuccess = () => {
    onSuccessDelete(checklistId!);
    handleClose();
  };

  const { remove } = useChecklist({
    rentalId: rentalId!,
    onSuccess: handleSuccess,
    onError: (message: string | null) => {
      setError(message);
    },
    onStart: () => {
      setError(null);
      setLoading(true);
    },
    onEnd: () => {
      setLoading(false);
    },
  });

  return (
    <CenterModal isVisible={isVisible} onClose={onClose}>
      <div className="flex flex-col gap-5">
        <div className="flex items-start justify-between">
          <p className="text-xl font-extrabold">
            {t("Rental.Checklist.Delete.title")}
          </p>
          <div className="cursor-pointer">
            <CloseIcon width={20} height={20} onClick={onClose} />
          </div>
        </div>
        <p className="text-base text-low-contrast">
          {t("Rental.Checklist.Delete.description")}
        </p>

        <ErrorMessage>{error}</ErrorMessage>

        <div className="flex gap-3">
          <div className="flex-1">
            <Button type="secondary" onClick={onClose} disabled={loading}>
              {t("Global.cancel")}
            </Button>
          </div>
          <div className="flex-1">
            <Button
              type="destructive"
              onClick={() => remove(checklistId!)}
              loading={loading}
            >
              {t("Global.deactivateWithDelete")}
            </Button>
          </div>
        </div>
      </div>
    </CenterModal>
  );
};
