import React, {useEffect, useState} from "react";
import {Title} from "../../components/Common/Title/Title";
import {useTranslation} from "react-i18next";
import {AutoMessageListPageProps} from "./AutoMessageListPage.type";
import {MainLayout} from "../../components/Layout/MainLayout/MainLayout";
import {TableList} from "../../components/Common/TableList/TableList";
import {useTablePage} from "../../hooks/useTablePage";
import paths from "../../constants/paths";
import {AutoMessageLineSkeleton} from "../../components/AutoMessageList/AutoMessageLineSkeleton";
import {AutoMessageListFilter} from "../../components/AutoMessageList/AutoMessageListFilter";
import {AutoMessageListHeader} from "../../components/AutoMessageList/AutoMessageListHeader";
import {AutoMessageLine} from "../../components/AutoMessageList/AutoMessageLine";
import {NoAutoMessageList} from "../../components/AutoMessageList/NoAutoMessage/NoAutoMessageList";
import {ValueType} from "../../types/commonTypes";
import {AutoMessageRemoveModal} from "../../components/AutoMessageList/AutoMessageRemoveModal";
import {useModal} from "../../hooks/useModal";
import {AutoMessageDuplicateModal} from "../../components/AutoMessageList/AutoMessageDuplicateModal";
import {AutoMessageUpsertModal} from "../../components/AutoMessage/AutoMessageUpsertModal";
import {
  AutoMessageLanguageEnum,
  AutoMessageUpsertChannelsForm,
  AutoMessageUpsertContentsForm,
  AutoMessageUpsertGeneralForm,
} from "../../components/AutoMessage/AutoMessage.type";
import {get, post} from "../../helpers/APIHelper";
import {NotificationListItemResponse} from "../../types/GETTypes";
import {CheckAutoMessagesBanner} from "../../components/AutoMessageList/CheckAutoMessagesBanner";

export const AutoMessageListPage: React.FC<AutoMessageListPageProps> = () => {
  const {t} = useTranslation();

  const removeModal = useModal();
  const duplicateModal = useModal();
  const upsertModal = useModal<NotificationListItemResponse>();

  const [loadingUpsert, setLoadingUpsert] = useState<boolean>(false);
  const [errorUpsert, setErrorUpsert] = useState<string | null>(null);

  const getTitle = () => {
    return (
      <div className="flex flex-row items-center">
        <div className="flex-1">
          <Title>{t("AutoMessageList.title")}</Title>
        </div>
      </div>
    );
  };

  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.AUTO_MESSAGES}`,
    "notifications",
    {
      otherMetas: [
        "total_pre_checkin",
        "total_post_checkin",
        "total_payments_cautions",
        "total_post_checkout",
        "show_progression_checking_banner",
      ],
    }
  );

  const queryParameters = new URLSearchParams(window.location.search);
  const editIdQuery = queryParameters.get("edit_id");

  useEffect(() => {
    tablePage.fetch({sort_field: "enabled", sort_direction: "desc"});
  }, []);

  useEffect(() => {
    const openAutoMessageById = async (id: string | null) => {
      const res = await get(
        `${import.meta.env.VITE_API_URL}${
          paths.API.AUTO_MESSAGES
        }/${editIdQuery}`
      );

      if (res.data?.success) {
        upsertModal.open(res.data?.result);
      }
    };

    if (editIdQuery) {
      openAutoMessageById(editIdQuery);
    }
  }, [editIdQuery]);

  const getFiltersNode = () => {
    return (
      <AutoMessageListFilter
        currentTrigger={tablePage.currentFilterCustomValue}
        preCheckinCount={
          tablePage.currentOtherMetas.find((m) => m.key === "total_pre_checkin")
            ?.value ?? ""
        }
        postCheckinCount={
          tablePage.currentOtherMetas.find(
            (m) => m.key === "total_post_checkin"
          )?.value ?? ""
        }
        paymentsCount={
          tablePage.currentOtherMetas.find(
            (m) => m.key === "total_payments_cautions"
          )?.value ?? ""
        }
        postCheckoutCount={
          tablePage.currentOtherMetas.find(
            (m) => m.key === "total_post_checkout"
          )?.value ?? ""
        }
        totalCount={tablePage.total?.toString() ?? ""}
        onAdd={handleAdd}
        onSearch={tablePage.handleChangeSearch}
        onSelect={(value) =>
          tablePage.handleFilterCustom("trigger_filter", value)
        }
      />
    );
  };

  const handleAction = (id: ValueType, action: ValueType) => {
    if (action === "delete") {
      removeModal.open(id);
    } else if (action === "duplicate") {
      const index = tablePage.data?.findIndex((d: any) => d.id === id);
      if (index !== -1) {
        duplicateModal.open(tablePage.data[index]);
      }
    }
  };

  const handleEdit = (id: ValueType) => {
    const index = tablePage.data?.findIndex((d: any) => d.id === id);
    if (index !== -1) {
      upsertModal.open(tablePage.data[index]);
    }
  };

  const handleAdd = () => {
    upsertModal.open();
  };

  const handleRemoveSuccess = () => {
    removeModal.close();
    tablePage.fetch({
      limit: tablePage.currentNbPerPage,
      page: tablePage.currentPage,
      search: tablePage.currentSearch,
      include_ids: tablePage.currentRentalsId,
    });
  };

  const handleDuplicateSuccess = () => {
    duplicateModal.close();
    tablePage.fetch({
      limit: tablePage.currentNbPerPage,
      page: tablePage.currentPage,
      search: tablePage.currentSearch,
      include_ids: tablePage.currentRentalsId,
    });
  };

  const transformContents = (
    contents: AutoMessageUpsertContentsForm | undefined
  ) => {
    const result = [];

    if (contents?.english?.title !== "" || contents?.english?.message !== "") {
      result.push({
        language_id: AutoMessageLanguageEnum.english,
        ...contents?.english,
      });
    }

    if (contents?.french?.title !== "" || contents?.french?.message !== "") {
      result.push({
        language_id: AutoMessageLanguageEnum.french,
        ...contents?.french,
      });
    }

    if (contents?.spanish?.title !== "" || contents?.spanish?.message !== "") {
      result.push({
        language_id: AutoMessageLanguageEnum.spanish,
        ...contents?.spanish,
      });
    }

    if (contents?.german?.title !== "" || contents?.german?.message !== "") {
      result.push({
        language_id: AutoMessageLanguageEnum.german,
        ...contents?.german,
      });
    }

    return result;
  };

  const handlePerformUpsert = async (data: {
    general?: AutoMessageUpsertGeneralForm | undefined;
    contents?: AutoMessageUpsertContentsForm | undefined;
    channels?: AutoMessageUpsertChannelsForm | undefined;
  }) => {
    setLoadingUpsert(true);
    setErrorUpsert(null);

    const URL = upsertModal.data
      ? `${import.meta.env.VITE_API_URL}${paths.API.AUTO_MESSAGES}/${
          upsertModal.data?.id
        }`
      : `${import.meta.env.VITE_API_URL}${paths.API.AUTO_MESSAGES}`;

    const res = await post(URL, {
      _method: upsertModal.data ? "PUT" : "POST",
      name: data?.contents?.name,
      trigger: data?.general?.trigger,
      delay: data?.general?.delay,
      conditions: data?.general?.conditions,
      rentals: data?.contents?.isAllRentals ? null : data?.contents?.rentalsId,
      contents: transformContents(data.contents),
      channels: {
        airbnb: data?.channels?.Airbnb.active
          ? data?.channels?.Airbnb?.value
          : null,
        booking: data?.channels?.Booking.active
          ? data?.channels?.Booking?.value
          : null,
        direct: data?.channels?.Direct.active
          ? data?.channels?.Direct?.value
          : null,
      },
    });

    if (res.data?.success) {
      if (!upsertModal.data) {
        //enabled created automated message
        try {
          await post(
            `${import.meta.env.VITE_API_URL}${paths.API.AUTO_MESSAGES_ENABLE}`,
            {notification_id: res?.data?.result?.id}
          );
        } catch (e) {
          console.log("🚀 ~ e:", e);
        }
      }
      upsertModal.close();
      /*
      tablePage.fetch({
        limit: tablePage.currentNbPerPage,
        page: tablePage.currentPage,
        search: tablePage.currentSearch,
        include_ids: tablePage.currentRentalsId,
      });

       */
      tablePage.handleFilterCustom(
        "trigger_filter",
        tablePage.currentFilterCustomValue
      );
    } else {
      setErrorUpsert(res?.response?.data?.message);
    }

    setLoadingUpsert(false);
  };

  const isBannerVisible = () => {
    return (tablePage.currentOtherMetas.find(
      (m) => m.key === "show_progression_checking_banner"
    )?.value ?? false) as boolean;
  };

  return (
    <>
      <AutoMessageRemoveModal
        id={removeModal.data}
        isVisible={removeModal.isVisible}
        onSuccess={handleRemoveSuccess}
        onClose={removeModal.close}
      />

      <AutoMessageDuplicateModal
        data={duplicateModal.data}
        isVisible={duplicateModal.isVisible}
        onSuccess={handleDuplicateSuccess}
        onClose={duplicateModal.close}
      />

      <AutoMessageUpsertModal
        isVisible={upsertModal.isVisible}
        data={upsertModal.data}
        loading={loadingUpsert}
        error={errorUpsert}
        onClose={() => {
          upsertModal.close();
          setErrorUpsert(null);
        }}
        onUpsert={handlePerformUpsert}
      />

      <MainLayout
        title={getTitle()}
        sidebarActiveItem="auto_messages"
        sidebarActiveSubItem="auto_messages"
      >
        <div>
          <CheckAutoMessagesBanner isVisible={isBannerVisible()} />

          <div className="mt-4">
            <TableList
              i18nElement="AutoMessageList.autoMessage"
              Filters={getFiltersNode()}
              Header={AutoMessageListHeader}
              Item={AutoMessageLine}
              Skeleton={AutoMessageLineSkeleton}
              NoData={<NoAutoMessageList onAdd={upsertModal.open} />}
              tablePage={tablePage}
              onClick={handleEdit}
              onClickAction={handleAction}
            />
          </div>
        </div>
      </MainLayout>
    </>
  );
};
