import React from "react";
import {cn} from "../../../helpers/classHelper";
import useWindowSize from "../../../hooks/useWindowSize";
import {replaceAsteriskWithStrongBalise} from "../../../helpers/stringHelper";

export interface BlockStyle {
  parentDivStyle?: string;
  childrenDivStyle?: string;
  titleStyle?: string;
  subTitleStyle?: string;
}
const LayoutBlockComponent = ({
  title,
  subTitle,
  icon,
  children,
  blockStyle = {
    parentDivStyle: "",
    childrenDivStyle: "",
    titleStyle: "",
    subTitleStyle: "",
  },
  displayTitles = false,
}: {
  title?: string;
  subTitle?: string;
  icon?: string;
  children?: React.ReactNode;
  displayTitles?: boolean;
  blockStyle?: BlockStyle;
}) => {
  const {isMobile} = useWindowSize();
  const displayText = isMobile || displayTitles;
  return (
    <div className={cn("mb-4 mt-8 mx-3", blockStyle.parentDivStyle)}>
      {displayText && (
        <>
          <div
            className={cn(
              "text-high-contrast text-xl font-bold px-2",
              blockStyle?.titleStyle
            )}
          >
            {title} {icon}
          </div>
          <div
            className={cn(
              "whitespace-pre-line text-low-contrast text-base px-2",
              blockStyle?.subTitleStyle
            )}
            dangerouslySetInnerHTML={{
              __html: replaceAsteriskWithStrongBalise(subTitle ?? ""),
            }}
          />
        </>
      )}
      <div
        className={cn(
          "flex flex-col mt-4 px-4 pt-2 pb-4 text-sm border-b-1 border-element-border",
          blockStyle?.childrenDivStyle
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default LayoutBlockComponent;
