import React from "react";

export const ManagementAutomationItemSkeleton: React.FC<{}> = () => {
  return (
    <tr className="cursor-pointer hover:bg-element-background/40 animate-pulse">
      {/* Checkbox selection */}
      <td className="w-4 py-2 text-sm ps-4 pe-2 border-t-1 border-element-border">
        <p className="rounded size-4 bg-slate-200"></p>
      </td>

      {/* Scheduled task */}
      <td className="px-2 py-2 text-sm border-t-1 border-element-border">
        <div className="flex flex-col">
          <p className="w-32 h-2 rounded bg-slate-200"></p>
          <p className="h-2 mt-2 rounded-lg w-52 bg-slate-300"></p>
        </div>
      </td>

      {/* Trigger */}
      <td className="px-2 py-2 text-sm border-t-1 border-element-border">
        <div className="flex flex-col">
          <p className="w-32 h-2 rounded bg-slate-200"></p>
          <p className="h-2 mt-2 rounded-lg w-52 bg-slate-300"></p>
        </div>
      </td>

      {/* Concerned rental */}
      <td className="flex flex-row items-center py-4 space-x-4 text-sm border-t-1 border-element-border ps-4 pe-2">
        <div className="relative">
          <div className="w-10 h-10 rounded-4px border-1 border-element-border/50 bg-slate-200" />
        </div>

        <div className="flex flex-col">
          <p className="w-32 h-2 rounded bg-slate-300"></p>
          <p className="h-2 mt-2 rounded w-52 bg-slate-200"></p>
          <p className="w-12 h-2 mt-1 rounded bg-slate-200"></p>
        </div>
      </td>

      {/* Assignation */}
      <td className="py-2 text-sm border-t-1 border-element-border">
        <div className="flex items-center justify-center">
          <div className="w-10 h-10 ml-0 border-2 border-white rounded-full bg-slate-200"></div>
          <div className="w-10 h-10 -ml-3 border-2 border-white rounded-full bg-slate-200"></div>
          <p className="w-20 h-2 ml-2 rounded bg-slate-200"></p>
        </div>
      </td>

      {/* State */}
      <td className="py-2 border-t-1 border-element-border">
        <div className="flex justify-center">
          <p className="w-10 h-6 mt-2 rounded-full bg-slate-200"></p>
        </div>
      </td>

      {/* Actions button */}
      <td className="py-2 pe-4 border-t-1 border-element-border">
        <div className="flex items-center justify-end">
          <p className="rounded-6px w-11 h-9 bg-slate-200"></p>
        </div>
      </td>
    </tr>
  );
};
