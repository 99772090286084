import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../Common/Button/Button";

import InformationIcon from "../../../../assets/icons/information.svg?react";
import ArrowRightIcon from "../../../../assets/icons/arrow-right.svg?react";

export const PaymentReservationListModalMultiInfos: React.FC<{
  onBack: () => void;
}> = ({ onBack }) => {
  const { t } = useTranslation();

  return (
    <div className="flex p-4 space-x-3 rounded-md bg-element-background">
      <div className="flex flex-col">
        <InformationIcon />
      </div>
      <div className="flex flex-col space-y-2">
        <p className="font-bold text-low-contrast">
          {t("Payments.PaymentReservationList.infoTitle")}
        </p>
        <p className="text-low-contrast">
          {t("Payments.PaymentReservationList.infoDescription")}
        </p>

        <div className="w-max">
          <Button type="secondary" onClick={onBack} RightIcon={ArrowRightIcon}>
            {t("Payments.PaymentReservationList.infoButton")}
          </Button>
        </div>
      </div>
    </div>
  );
};
