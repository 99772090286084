import React from "react";

export const ReservationPaymentAndDepositLinksSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col gap-y-6">
      {Array.from({ length: 5 }).map((v) => (
        <div className="flex flex-col gap-4 animate-pulse">
          <p className="w-40 h-3 rounded-lg bg-slate-300"></p>
          <div className="flex justify-between gap-x-4">
            <div className="flex-1 w-full h-5 rounded-lg bg-slate-300"></div>
            <div className="w-40 h-5 rounded-lg bg-slate-300"></div>
          </div>
        </div>
      ))}
    </div>
  );
};
