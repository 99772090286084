import React from "react";
import {TableListItemProps} from "../Common/TableList/TableList.type";
import {ReservationResponse} from "../../types/GETTypes";
import {useTranslation} from "react-i18next";
import {getPlatformIcon} from "../../helpers/platformHelper";
import moment from "moment";
import {ReservationStatusEnum} from "../../enums/GETenums";
import {getPaymentStatusBadge} from "../../helpers/reservationHelper";
import {Badge} from "../Common/Badge/Badge";

import UsersIcon from "../../assets/icons/users.svg?react";
import EmojiHappyIcon from "../../assets/icons/emoji-happy.svg?react";
import CheckCircleGreenIcon from "../../assets/icons/check-circle-green.svg?react";
import CloseCircleRedIcon from "../../assets/icons/close-circle-red.svg?react";

export const ReservationItem: React.FC<TableListItemProps> = ({
  data,
  onClick = () => {},
}) => {
  const booking: ReservationResponse = data;
  const {t} = useTranslation();

  return (
    <tr
      className="cursor-pointer hover:bg-element-background/40"
      onClick={() => onClick(booking.id)}
    >
      <td className="flex flex-row items-center py-4 space-x-4 text-sm border-t-1 border-element-border ps-4 pe-2">
        <div className="relative">
          {booking?.platform ? (
            <div
              className="absolute z-10"
              style={{top: 8, left: 26, width: 24, height: 24}}
            >
              {getPlatformIcon(booking?.platform)}
            </div>
          ) : (
            <div
              className="absolute z-10 border-2 border-white rounded-full bg-slate-200"
              style={{top: 8, left: 26, width: 24, height: 24}}
            />
          )}

          {booking.rental?.details.photos.cover ? (
            <div className="object-fill w-10 h-10 overflow-hidden rounded-4px border-1 border-element-border/50">
              <img
                src={booking.rental?.details.photos.cover}
                className="object-fill w-10 h-10"
              />
            </div>
          ) : (
            <div className="w-10 h-10 rounded-4px border-1 border-element-border/50 bg-slate-200" />
          )}
        </div>
        <div className="flex flex-col">
          <p className="font-semibold text-high-contrast truncate">
            {booking.rental?.details.informations.name}
          </p>
          <p className="font-light text-low-contrast">
            {booking.rental?.details.informations.address}{" "}
            {booking.rental?.details.informations.postal_code}{" "}
            {booking.rental?.details.informations.city}{" "}
            {booking.rental?.details.informations.country.name}
          </p>
        </div>
      </td>

      <td className="px-2 py-4 text-sm border-t-1 border-element-border">
        <div className="flex flex-col">
          <p className="font-semibold text-high-contrast">
            {booking.guest?.first_name} {booking.guest?.last_name}
          </p>
          <div className="flex flex-row items-center space-x-1 font-light text-low-contrast">
            <UsersIcon width={18} height={18} />
            <p>
              {t("BookingList.adults", {count: Number(booking.adults_count)})}
            </p>
            {booking.children_count && booking.children_count > 0 ? (
              <>
                <EmojiHappyIcon width={18} height={18} />
                <p>
                  {t("BookingList.children", {
                    count: Number(booking.children_count),
                  })}
                </p>
              </>
            ) : null}
          </div>
        </div>
      </td>

      <td className="px-2 py-4 text-sm border-t-1 border-element-border">
        <div className="flex flex-col">
          <p className="font-semibold text-high-contrast">
            {moment(booking.checkin).format("DD/MM/YYYY")}
          </p>
          <div className="flex flex-row items-center space-x-1 font-light text-low-contrast">
            {booking.time_in}
          </div>
        </div>
      </td>

      <td className="px-2 py-4 text-sm border-t-1 border-element-border">
        <div className="flex flex-col">
          <p className="font-semibold text-high-contrast">
            {moment(booking.checkout).format("DD/MM/YYYY")}
          </p>
          <div className="flex flex-row items-center space-x-1 font-light text-low-contrast">
            {booking.time_out}
          </div>
        </div>
      </td>

      <td className="border-t-1 border-element-border">
        {booking.status_reservation === ReservationStatusEnum.REQUEST && (
          <div className="flex items-center justify-center px-2 mx-2 font-semibold h-10 bg-element-background text-[#EBA621] rounded-6px w-36 text-center">
            {t("Booking.Status.request")}
          </div>
        )}

        {booking.status_reservation === ReservationStatusEnum.PRE_APPROVED && (
          <div className="flex items-center justify-center h-10 px-2 mx-2 font-semibold text-center text-green-900 bg-element-background rounded-6px w-36">
            {t("Booking.Status.preApprouved")}
          </div>
        )}

        {booking.status_reservation ===
          ReservationStatusEnum.CONFIRMED_PROGRESS && (
          <div className="flex items-center justify-center h-10 gap-1 px-2 mx-2 font-semibold text-center bg-element-background-success text-success rounded-6px w-36">
            <CheckCircleGreenIcon className="shrink-0" />
            {t("Booking.Status.progress")}
          </div>
        )}

        {booking.status_reservation ===
          ReservationStatusEnum.CONFIRMED_COMING && (
          <div className="flex items-center justify-center h-10 px-2 mx-2 font-semibold text-center bg-element-background text-low-contrast rounded-6px w-36">
            {t("Booking.Status.coming")}
          </div>
        )}

        {booking.status_reservation ===
          ReservationStatusEnum.CONFIRMED_FINISHED && (
          <div className="flex items-center justify-center h-10 px-2 mx-2 font-semibold text-center bg-element-background text-low-contrast rounded-6px w-36">
            {t("Booking.Status.finished")}
          </div>
        )}

        {booking.status_reservation === ReservationStatusEnum.CANCELED && (
          <div className="flex items-center justify-center h-10 gap-1 px-2 mx-2 font-semibold text-center bg-element-background-error text-error rounded-6px w-36">
            <CloseCircleRedIcon className="shrink-0" />
            {t("Booking.Status.canceled")}
          </div>
        )}

        {booking.status_reservation ===
          ReservationStatusEnum.CANCELED_BY_TRAVELER && (
          <div className="flex items-center justify-center h-10 gap-1 px-2 mx-2 font-semibold text-center bg-element-background-error text-error rounded-6px w-36">
            <CloseCircleRedIcon className="shrink-0" />
            {t("Booking.Status.canceledByTraveler")}
          </div>
        )}
      </td>

      <td className="border-t-1 border-element-border">
        {getPaymentStatusBadge(booking.status_payment)}
      </td>
    </tr>
  );
};
