import React from "react";
import { ManagementLayout } from "../../components/Layout/ManagementLayout/ManagementLayout";
import { WorkInProgress } from "../../components/WorkInProgress/WorkInProgress";

export const ManagementPaymentListPage: React.FC<{}> = () => {
  return (
    <ManagementLayout subTitle="payments" sidebarActiveSubItem="payments">
      <WorkInProgress />
    </ManagementLayout>
  );
};
