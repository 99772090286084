import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { AddMemberEmailForm } from "./AddMemberModal.type";
import { TextInput } from "../../Common/TextInput/TextInput";
import { useTranslation } from "react-i18next";

export const AddMemberStepEmail: React.FC<{
  refButton: React.MutableRefObject<HTMLInputElement | undefined>;
  initialData: AddMemberEmailForm | undefined;
  onNextStep: (values: AddMemberEmailForm) => void;
}> = ({ refButton, initialData, onNextStep }) => {
  const form = useForm<AddMemberEmailForm>({ defaultValues: { email: "" } });
  const { t } = useTranslation();

  useEffect(() => {
    if (initialData) {
      form.setValue("email", initialData.email);
    }
  }, [initialData]);

  const handleNextStep = (values: AddMemberEmailForm) => {
    onNextStep(values);
  };

  return (
    <form onSubmit={form.handleSubmit(handleNextStep)}>
      <div>
        <Controller
          control={form.control}
          name="email"
          rules={{
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: t("Management.AddMember.Email.emailInvalid"),
            },
            required: {
              value: true,
              message: t("Management.AddMember.Email.emailRequired"),
            },
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <TextInput
                label={t("Management.AddMember.Email.email")}
                value={value}
                placeholder={t("Management.AddMember.Email.emailPlaceholder")}
                onChangeText={onChange}
                error={form.formState.errors.email?.message}
              />
            );
          }}
        />

        <p className="mt-4 font-light text-low-contrast">
          {t("Management.AddMember.Email.content1")}
        </p>

        <p className="mt-2 font-light text-low-contrast">
          {t("Management.AddMember.Email.content2")}
        </p>
      </div>

      <input
        type="submit"
        value="go"
        ref={refButton as React.MutableRefObject<HTMLInputElement>}
        className="hidden"
      />
    </form>
  );
};
