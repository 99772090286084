export default [
  {
    id: 1,
    rental_id: 1,
    full_name: "Elie Al Shami", // Nom prénom cohérent
    checkin_date: "08/07/2023", // Date récente ou futur
    checkin_time: "15:00", // Toujours garder cette valeur
    checkout_date: "10/07/2023", // Date après checkin_date
    checkout_time: "10:00", // Toujours garder cette valeur
    nb_adults: 2, // Entre 1 et 6
    nb_children: 1, // Entre 0 et 4
    status: "confirmed", // parmis les valeurs suivantes : to_arrive, confirmed, pre_approuved, not_confirmed, canceled
    paid_status: "paid", // parmis les valeurs suivantes : paid, not_paid, error
    team_checkin: false, // Booleen
    team_cleaning: true, // Booleen
  },
  {
    id: 2,
    rental_id: 2,
    full_name: "Sophie Martin",
    checkin_date: "12/09/2023",
    checkin_time: "15:00",
    checkout_date: "15/09/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 0,
    status: "not_confirmed",
    paid_status: "not_paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 3,
    rental_id: 3,
    full_name: "Emma Wilson",
    checkin_date: "25/10/2023",
    checkin_time: "15:00",
    checkout_date: "28/10/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 4,
    rental_id: 4,
    full_name: "Lucas Dupuis",
    checkin_date: "03/12/2023",
    checkin_time: "15:00",
    checkout_date: "05/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "pre_approuved",
    paid_status: "not_paid",
    team_checkin: true,
    team_cleaning: false,
  },
  {
    id: 5,
    rental_id: 5,
    full_name: "Elena Rossi",
    checkin_date: "17/11/2023",
    checkin_time: "15:00",
    checkout_date: "20/11/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 1,
    status: "to_arrive",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 6,
    rental_id: 6,
    full_name: "Maria Hernandez",
    checkin_date: "02/09/2023",
    checkin_time: "15:00",
    checkout_date: "05/06/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 2,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 7,
    rental_id: 7,
    full_name: "Thomas Müller",
    checkin_date: "10/09/2023",
    checkin_time: "15:00",
    checkout_date: "14/09/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 1,
    status: "canceled",
    paid_status: "error",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 8,
    rental_id: 8,
    full_name: "Anna Petrova",
    checkin_date: "20/07/2023",
    checkin_time: "15:00",
    checkout_date: "25/07/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "pre_approuved",
    paid_status: "not_paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 9,
    rental_id: 9,
    full_name: "Julien Lambert",
    checkin_date: "14/08/2023",
    checkin_time: "15:00",
    checkout_date: "18/08/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "to_arrive",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 10,
    rental_id: 10,
    full_name: "Sophie Martin",
    checkin_date: "03/11/2023",
    checkin_time: "15:00",
    checkout_date: "05/11/2023",
    checkout_time: "10:00",
    nb_adults: 1,
    nb_children: 0,
    status: "not_confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: false,
  },
  {
    id: 11,
    rental_id: 11,
    full_name: "Sophie Dubois",
    checkin_date: "15/09/2023",
    checkin_time: "15:00",
    checkout_date: "18/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 1,
    status: "to_arrive",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 12,
    rental_id: 12,
    full_name: "Marco Rossi",
    checkin_date: "10/10/2023",
    checkin_time: "15:00",
    checkout_date: "14/10/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 0,
    status: "pre_approuved",
    paid_status: "error",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 13,
    rental_id: 13,
    full_name: "Elena Garcia",
    checkin_date: "05/12/2023",
    checkin_time: "15:00",
    checkout_date: "08/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 14,
    rental_id: 14,
    full_name: "Lucas Dupont",
    checkin_date: "20/11/2023",
    checkin_time: "15:00",
    checkout_date: "25/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "confirmed",
    paid_status: "not_paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 15,
    rental_id: 15,
    full_name: "Maria Lopez",
    checkin_date: "08/10/2023",
    checkin_time: "15:00",
    checkout_date: "12/10/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 2,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 16,
    rental_id: 16,
    full_name: "Thomas Müller",
    checkin_date: "03/09/2023",
    checkin_time: "15:00",
    checkout_date: "07/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 1,
    status: "not_confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 17,
    rental_id: 17,
    full_name: "Julie Dupont",
    checkin_date: "14/10/2023",
    checkin_time: "15:00",
    checkout_date: "18/10/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 0,
    status: "to_arrive",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 18,
    rental_id: 18,
    full_name: "David Wilson",
    checkin_date: "05/11/2023",
    checkin_time: "15:00",
    checkout_date: "08/11/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "pre_approuved",
    paid_status: "error",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 19,
    rental_id: 19,
    full_name: "Emma Smith",
    checkin_date: "10/12/2023",
    checkin_time: "15:00",
    checkout_date: "13/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 1,
    status: "canceled",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: false,
  },
  {
    id: 20,
    rental_id: 20,
    full_name: "Sophia Martin",
    checkin_date: "15/11/2023",
    checkin_time: "15:00",
    checkout_date: "18/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 0,
    status: "confirmed",
    paid_status: "not_paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 21,
    rental_id: 21,
    full_name: "Léa Martinez",
    checkin_date: "05/09/2023",
    checkin_time: "15:00",
    checkout_date: "08/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 22,
    rental_id: 22,
    full_name: "Maxime Durand",
    checkin_date: "14/10/2023",
    checkin_time: "15:00",
    checkout_date: "18/10/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 23,
    rental_id: 23,
    full_name: "Marta Fernández",
    checkin_date: "05/11/2023",
    checkin_time: "15:00",
    checkout_date: "09/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 0,
    status: "not_confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 24,
    rental_id: 24,
    full_name: "Antonio Rossi",
    checkin_date: "10/12/2023",
    checkin_time: "15:00",
    checkout_date: "14/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "to_arrive",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: false,
  },
  {
    id: 25,
    rental_id: 25,
    full_name: "Laura Smith",
    checkin_date: "15/11/2023",
    checkin_time: "15:00",
    checkout_date: "19/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "canceled",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 26,
    rental_id: 26,
    full_name: "Mohammed Ali",
    checkin_date: "02/09/2023",
    checkin_time: "15:00",
    checkout_date: "06/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 1,
    status: "pre_approuved",
    paid_status: "error",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 27,
    rental_id: 27,
    full_name: "Olga Ivanova",
    checkin_date: "18/10/2023",
    checkin_time: "15:00",
    checkout_date: "22/10/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "confirmed",
    paid_status: "not_paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 28,
    rental_id: 28,
    full_name: "Diego Rodriguez",
    checkin_date: "05/11/2023",
    checkin_time: "15:00",
    checkout_date: "09/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 0,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 29,
    rental_id: 29,
    full_name: "Sophie Müller",
    checkin_date: "10/12/2023",
    checkin_time: "15:00",
    checkout_date: "14/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "not_confirmed",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: false,
  },
  {
    id: 30,
    rental_id: 30,
    full_name: "Ibrahim Ahmed",
    checkin_date: "15/11/2023",
    checkin_time: "15:00",
    checkout_date: "19/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 31,
    rental_id: 31,
    full_name: "Léonie Dubois",
    checkin_date: "02/09/2023",
    checkin_time: "15:00",
    checkout_date: "06/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 1,
    status: "to_arrive",
    paid_status: "error",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 32,
    rental_id: 32,
    full_name: "Hans Schmidt",
    checkin_date: "18/10/2023",
    checkin_time: "15:00",
    checkout_date: "22/10/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "canceled",
    paid_status: "not_paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 33,
    rental_id: 33,
    full_name: "Ana Fernández",
    checkin_date: "05/11/2023",
    checkin_time: "15:00",
    checkout_date: "09/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 0,
    status: "to_arrive",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 34,
    rental_id: 34,
    full_name: "Luca Rossi",
    checkin_date: "10/12/2023",
    checkin_time: "15:00",
    checkout_date: "14/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: false,
  },
  {
    id: 35,
    rental_id: 35,
    full_name: "Emily Smith",
    checkin_date: "15/11/2023",
    checkin_time: "15:00",
    checkout_date: "19/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "to_arrive",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 36,
    rental_id: 36,
    full_name: "Julia Müller",
    checkin_date: "02/09/2023",
    checkin_time: "15:00",
    checkout_date: "06/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 1,
    status: "confirmed",
    paid_status: "error",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 37,
    rental_id: 37,
    full_name: "Ivan Petrov",
    checkin_date: "18/10/2023",
    checkin_time: "15:00",
    checkout_date: "22/10/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "not_confirmed",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 38,
    rental_id: 39,
    full_name: "Sophie Martin",
    checkin_date: "05/11/2023",
    checkin_time: "15:00",
    checkout_date: "09/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 0,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 39,
    rental_id: 39,
    full_name: "Antonella Bianchi",
    checkin_date: "10/12/2023",
    checkin_time: "15:00",
    checkout_date: "14/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "canceled",
    paid_status: "not_paid",
    team_checkin: false,
    team_cleaning: false,
  },
  {
    id: 40,
    rental_id: 40,
    full_name: "Alexandre Dupont",
    checkin_date: "15/11/2023",
    checkin_time: "15:00",
    checkout_date: "19/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 41,
    rental_id: 41,
    full_name: "Elena Fernandez",
    checkin_date: "02/09/2023",
    checkin_time: "15:00",
    checkout_date: "06/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 1,
    status: "to_arrive",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 42,
    rental_id: 42,
    full_name: "Andreas Weber",
    checkin_date: "18/10/2023",
    checkin_time: "15:00",
    checkout_date: "22/10/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 43,
    rental_id: 43,
    full_name: "Marta Rossi",
    checkin_date: "05/11/2023",
    checkin_time: "15:00",
    checkout_date: "09/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 0,
    status: "not_confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 44,
    rental_id: 44,
    full_name: "Luigi Bianchi",
    checkin_date: "10/12/2023",
    checkin_time: "15:00",
    checkout_date: "14/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: false,
  },
  {
    id: 45,
    rental_id: 45,
    full_name: "Sophie Dupont",
    checkin_date: "15/11/2023",
    checkin_time: "15:00",
    checkout_date: "19/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "canceled",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 46,
    rental_id: 46,
    full_name: "Raphael Martinez",
    checkin_date: "02/09/2023",
    checkin_time: "15:00",
    checkout_date: "06/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 1,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 47,
    rental_id: 47,
    full_name: "Nina Schmidt",
    checkin_date: "18/10/2023",
    checkin_time: "15:00",
    checkout_date: "22/10/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "to_arrive",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 48,
    rental_id: 48,
    full_name: "Lucia Fernandez",
    checkin_date: "05/11/2023",
    checkin_time: "15:00",
    checkout_date: "09/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 0,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 49,
    rental_id: 49,
    full_name: "Giovanni Rossi",
    checkin_date: "10/12/2023",
    checkin_time: "15:00",
    checkout_date: "14/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: false,
  },
  {
    id: 50,
    rental_id: 50,
    full_name: "Sophie Martin",
    checkin_date: "15/11/2023",
    checkin_time: "15:00",
    checkout_date: "19/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "not_confirmed",
    paid_status: "error",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 51,
    rental_id: 51,
    full_name: "Sophie Dupont",
    checkin_date: "02/09/2023",
    checkin_time: "15:00",
    checkout_date: "06/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 1,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 52,
    rental_id: 52,
    full_name: "Raphael Martinez",
    checkin_date: "18/10/2023",
    checkin_time: "15:00",
    checkout_date: "22/10/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "canceled",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 53,
    rental_id: 53,
    full_name: "Nina Schmidt",
    checkin_date: "05/11/2023",
    checkin_time: "15:00",
    checkout_date: "09/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 0,
    status: "confirmed",
    paid_status: "not_paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 54,
    rental_id: 54,
    full_name: "Lucia Fernandez",
    checkin_date: "10/12/2023",
    checkin_time: "15:00",
    checkout_date: "14/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: false,
  },
  {
    id: 55,
    rental_id: 55,
    full_name: "Giovanni Rossi",
    checkin_date: "15/11/2023",
    checkin_time: "15:00",
    checkout_date: "19/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "to_arrive",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 56,
    rental_id: 56,
    full_name: "Laura Martinez",
    checkin_date: "22/09/2023",
    checkin_time: "15:00",
    checkout_date: "26/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: false,
  },
  {
    id: 57,
    rental_id: 57,
    full_name: "Andreas Weber",
    checkin_date: "07/10/2023",
    checkin_time: "15:00",
    checkout_date: "11/10/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 58,
    rental_id: 58,
    full_name: "Elena Fernandez",
    checkin_date: "12/11/2023",
    checkin_time: "15:00",
    checkout_date: "16/11/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "not_confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 59,
    rental_id: 59,
    full_name: "Marta Rossi",
    checkin_date: "05/12/2023",
    checkin_time: "15:00",
    checkout_date: "09/12/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 60,
    rental_id: 60,
    full_name: "Diego Rodriguez",
    checkin_date: "20/10/2023",
    checkin_time: "15:00",
    checkout_date: "24/10/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "canceled",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: false,
  },
  {
    id: 61,
    rental_id: 61,
    full_name: "Maria Lopez",
    checkin_date: "02/09/2023",
    checkin_time: "15:00",
    checkout_date: "06/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 1,
    status: "pre_approuved",
    paid_status: "not_paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 62,
    rental_id: 62,
    full_name: "Thomas Fischer",
    checkin_date: "18/10/2023",
    checkin_time: "15:00",
    checkout_date: "22/10/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "to_arrive",
    paid_status: "error",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 63,
    rental_id: 63,
    full_name: "Laura Smith",
    checkin_date: "05/11/2023",
    checkin_time: "15:00",
    checkout_date: "09/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 0,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 64,
    rental_id: 64,
    full_name: "David Wilson",
    checkin_date: "10/12/2023",
    checkin_time: "15:00",
    checkout_date: "14/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "not_confirmed",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: false,
  },
  {
    id: 65,
    rental_id: 65,
    full_name: "Anna Garcia",
    checkin_date: "15/11/2023",
    checkin_time: "15:00",
    checkout_date: "19/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 66,
    rental_id: 66,
    full_name: "Julian Martinez",
    checkin_date: "22/09/2023",
    checkin_time: "15:00",
    checkout_date: "26/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "canceled",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: false,
  },
  {
    id: 67,
    rental_id: 67,
    full_name: "Sofia Rodriguez",
    checkin_date: "07/10/2023",
    checkin_time: "15:00",
    checkout_date: "11/10/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 68,
    rental_id: 68,
    full_name: "Matteo Ferrari",
    checkin_date: "12/11/2023",
    checkin_time: "15:00",
    checkout_date: "16/11/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 69,
    rental_id: 69,
    full_name: "Sara Moretti",
    checkin_date: "05/12/2023",
    checkin_time: "15:00",
    checkout_date: "09/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 70,
    rental_id: 70,
    full_name: "Liam Taylor",
    checkin_date: "20/10/2023",
    checkin_time: "15:00",
    checkout_date: "24/10/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: false,
  },
  {
    id: 71,
    rental_id: 71,
    full_name: "Sophie Durand",
    checkin_date: "02/09/2023",
    checkin_time: "15:00",
    checkout_date: "06/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 1,
    status: "to_arrive",
    paid_status: "error",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 72,
    rental_id: 72,
    full_name: "David Martin",
    checkin_date: "18/10/2023",
    checkin_time: "15:00",
    checkout_date: "22/10/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "canceled",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 73,
    rental_id: 73,
    full_name: "Léa Dubois",
    checkin_date: "05/11/2023",
    checkin_time: "15:00",
    checkout_date: "09/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 0,
    status: "confirmed",
    paid_status: "not_paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 74,
    rental_id: 74,
    full_name: "Thomas Lefebvre",
    checkin_date: "10/12/2023",
    checkin_time: "15:00",
    checkout_date: "14/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: false,
  },
  {
    id: 75,
    rental_id: 75,
    full_name: "Emma Moreau",
    checkin_date: "15/11/2023",
    checkin_time: "15:00",
    checkout_date: "19/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 76,
    rental_id: 76,
    full_name: "Hugo Girard",
    checkin_date: "22/09/2023",
    checkin_time: "15:00",
    checkout_date: "26/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: false,
  },
  {
    id: 77,
    rental_id: 77,
    full_name: "Chloé Petit",
    checkin_date: "07/10/2023",
    checkin_time: "15:00",
    checkout_date: "11/10/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 78,
    rental_id: 78,
    full_name: "Lucas Laurent",
    checkin_date: "12/11/2023",
    checkin_time: "15:00",
    checkout_date: "16/11/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 79,
    rental_id: 79,
    full_name: "Léna Rousseau",
    checkin_date: "05/12/2023",
    checkin_time: "15:00",
    checkout_date: "09/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 80,
    rental_id: 80,
    full_name: "Louis Martin",
    checkin_date: "20/10/2023",
    checkin_time: "15:00",
    checkout_date: "24/10/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "to_arrive",
    paid_status: "not_paid",
    team_checkin: true,
    team_cleaning: false,
  },
  {
    id: 81,
    rental_id: 81,
    full_name: "Eva Bernard",
    checkin_date: "02/09/2023",
    checkin_time: "15:00",
    checkout_date: "06/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 1,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 82,
    rental_id: 82,
    full_name: "Maxime Dupuis",
    checkin_date: "18/10/2023",
    checkin_time: "15:00",
    checkout_date: "22/10/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 83,
    rental_id: 83,
    full_name: "Sophie Martin",
    checkin_date: "05/11/2023",
    checkin_time: "15:00",
    checkout_date: "09/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 0,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 84,
    rental_id: 84,
    full_name: "Lucas Dupont",
    checkin_date: "10/12/2023",
    checkin_time: "15:00",
    checkout_date: "14/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "confirmed",
    paid_status: "not_paid",
    team_checkin: false,
    team_cleaning: false,
  },
  {
    id: 85,
    rental_id: 85,
    full_name: "Emma Lefebvre",
    checkin_date: "15/11/2023",
    checkin_time: "15:00",
    checkout_date: "19/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 86,
    rental_id: 86,
    full_name: "Hugo Dupuis",
    checkin_date: "22/09/2023",
    checkin_time: "15:00",
    checkout_date: "26/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: false,
  },
  {
    id: 87,
    rental_id: 87,
    full_name: "Chloé Martin",
    checkin_date: "07/10/2023",
    checkin_time: "15:00",
    checkout_date: "11/10/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "to_arrive",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 88,
    rental_id: 88,
    full_name: "Lucas Bernard",
    checkin_date: "12/11/2023",
    checkin_time: "15:00",
    checkout_date: "16/11/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 89,
    rental_id: 89,
    full_name: "Léa Dupont",
    checkin_date: "05/12/2023",
    checkin_time: "15:00",
    checkout_date: "09/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "confirmed",
    paid_status: "error",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 90,
    rental_id: 90,
    full_name: "Louis Martin",
    checkin_date: "20/10/2023",
    checkin_time: "15:00",
    checkout_date: "24/10/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: false,
  },
  {
    id: 91,
    rental_id: 91,
    full_name: "Eva Martin",
    checkin_date: "02/09/2023",
    checkin_time: "15:00",
    checkout_date: "06/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 1,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 92,
    rental_id: 92,
    full_name: "Maxime Dupont",
    checkin_date: "18/10/2023",
    checkin_time: "15:00",
    checkout_date: "22/10/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 93,
    rental_id: 93,
    full_name: "Sophie Lefebvre",
    checkin_date: "05/11/2023",
    checkin_time: "15:00",
    checkout_date: "09/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 0,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 94,
    rental_id: 94,
    full_name: "Lucas Dupuis",
    checkin_date: "10/12/2023",
    checkin_time: "15:00",
    checkout_date: "14/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: false,
  },
  {
    id: 95,
    rental_id: 95,
    full_name: "Emma Lefebvre",
    checkin_date: "15/11/2023",
    checkin_time: "15:00",
    checkout_date: "19/11/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "to_arrive",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 96,
    rental_id: 96,
    full_name: "Hugo Dupuis",
    checkin_date: "22/09/2023",
    checkin_time: "15:00",
    checkout_date: "26/09/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "confirmed",
    paid_status: "not_paid",
    team_checkin: true,
    team_cleaning: false,
  },
  {
    id: 97,
    rental_id: 97,
    full_name: "Chloé Lefebvre",
    checkin_date: "07/10/2023",
    checkin_time: "15:00",
    checkout_date: "11/10/2023",
    checkout_time: "10:00",
    nb_adults: 3,
    nb_children: 1,
    status: "pre_approuved",
    paid_status: "paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 98,
    rental_id: 98,
    full_name: "Lucas Dupuis",
    checkin_date: "12/11/2023",
    checkin_time: "15:00",
    checkout_date: "16/11/2023",
    checkout_time: "10:00",
    nb_adults: 4,
    nb_children: 2,
    status: "confirmed",
    paid_status: "not_paid",
    team_checkin: false,
    team_cleaning: true,
  },
  {
    id: 99,
    rental_id: 99,
    full_name: "Léa Dupont",
    checkin_date: "05/12/2023",
    checkin_time: "15:00",
    checkout_date: "09/12/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "confirmed",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: true,
  },
  {
    id: 100,
    rental_id: 100,
    full_name: "Louis Martin",
    checkin_date: "20/10/2023",
    checkin_time: "15:00",
    checkout_date: "24/10/2023",
    checkout_time: "10:00",
    nb_adults: 2,
    nb_children: 0,
    status: "to_arrive",
    paid_status: "paid",
    team_checkin: true,
    team_cleaning: false,
  },
];
