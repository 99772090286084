import React from "react";
import { useTranslation } from "react-i18next";
import { ProgressionStep } from "../../../pages/Progression/ProgressionStep";
import { Button } from "../../Common/Button/Button";
import { ProgressionStepCalendarProps } from "../Progression.type";

export const ProgressionStepCalendar: React.FC<
  ProgressionStepCalendarProps
> = ({ status, visible, number, loading, error, onCalendarClick }) => {
  const { t } = useTranslation();

  if (!visible) return null;

  return (
    <ProgressionStep
      number={number.toString()}
      title={t("Progression.StepCalendar.title")}
      status={status}
      error={error}
      loading={loading}
    >
      <p className="mt-1 font-light text-low-contrast">
        {t("Progression.StepCalendar.content")}
      </p>

      <div className="mt-4 w-44">
        <Button onClick={onCalendarClick} buttonClassName="px-0">
          {t("Progression.StepCalendar.button")}
        </Button>
      </div>
    </ProgressionStep>
  );
};
