import React from "react";

const GuestPagePaymentFormSkeleton = () => {
  return (
    <div className="w-full md:w-1/2">
      <div className="relative h-96 lg:h-32 bg-cover bg-center bg-slate-400 animate-pulse">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="text-white px-4">
            <h1 className="flex lg:text-3xl text-[32px] font-bold"></h1>
            <p className="mt-1 text-base font-normal leading-7"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestPagePaymentFormSkeleton;
