import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AutoMessageUpsertStepEnum,
  AutoMessageUpsertModalProps,
} from "./AutoMessageUpsertModal.type";
import { RightModal } from "../../../Common/RightModal/RightModal";
import { CarouselWizard } from "../../../Common/CarouselWizard/CarouselWizard";
import { AutoMessageUpsertGeneral } from "../AutoMessageUpsertGeneral/AutoMessageUpsertGeneral";
import { AutoMessageUpsertMessage } from "../AutoMessageUpsertMessage/AutoMessageUpsertMessage";
import { AutoMessageUpsertRentals } from "../AutoMessageUpsertRentals/AutoMessageUpsertRentals";
import { AutoMessageUpsertPlatforms } from "../AutoMessageUpsertPlatforms/AutoMessageUpsertPlatforms";
import { Button } from "../../../Common/Button/Button";
import { CenterModal } from "../../../Common/CenterModal/CenterModal";
import { Title } from "../../../Common/Title/Title";

import ArrowRightIcon from "../../../../assets/icons/arrow-right-white.svg?react";
import PlusCircleIcon from "../../../../assets/icons/plus-circle-white.svg?react";
import EditIcon from "../../../../assets/icons/edit-white.svg?react";
import MailIcon from "../../../../assets/icons/mail.svg?react";

export const AutoMessageUpsertModal: React.FC<AutoMessageUpsertModalProps> = ({
  type = "add",
  isVisible,
  onClose = () => {},
}) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<AutoMessageUpsertStepEnum>(
    AutoMessageUpsertStepEnum.general
  );

  const [isWizardFinish, setWizardFinish] = useState<boolean>(false);

  const handleClose = () => {
    setCurrentStep(0);
    setWizardFinish(false);
    onClose();
  };

  const handleNextStep = () => {
    let nextStep = currentStep;

    if (currentStep === AutoMessageUpsertStepEnum.platforms) {
      setWizardFinish(true);
      nextStep = AutoMessageUpsertStepEnum.general;
    } else {
      nextStep++;
    }

    setCurrentStep(nextStep);
  };

  return (
    <>
      {isWizardFinish ? (
        <CenterModal isVisible={isWizardFinish} onClose={handleClose}>
          <Title>
            {type === "add"
              ? t("AutoMessageUpsert.successAddTitle")
              : t("AutoMessageUpsert.successEditTitle")}
          </Title>

          <p className="text-low-contrast font-light text-sm py-2">
            {t("AutoMessageUpsert.successP1")}
          </p>

          <p className="text-low-contrast font-light text-sm">
            {t("AutoMessageUpsert.successP2")}
          </p>

          <div className="flex flex-1 mt-4">
            <Button type="secondary" onClick={() => {}} RightIcon={MailIcon}>
              {t("AutoMessageUpsert.successTest")}
            </Button>
          </div>
        </CenterModal>
      ) : (
        <RightModal
          title={
            type === "add"
              ? t("AutoMessageUpsert.titleAdd")
              : t("AutoMessageUpsert.titleEdit")
          }
          isVisible={isVisible}
          onClose={handleClose}
        >
          <div className="flex flex-col flex-1 h-full">
            {/* Content */}
            <div className="flex-grow overflow-y-auto">
              <CarouselWizard
                currentStepIndex={currentStep}
                steps={[
                  {
                    title: t("AutoMessageUpsert.general"),
                    Component: AutoMessageUpsertGeneral,
                  },
                  {
                    title: t("AutoMessageUpsert.message"),
                    Component: AutoMessageUpsertMessage,
                  },
                  {
                    title: t("AutoMessageUpsert.rentals"),
                    Component: AutoMessageUpsertRentals,
                  },
                  {
                    title: t("AutoMessageUpsert.platforms"),
                    Component: AutoMessageUpsertPlatforms,
                  },
                ]}
                onChangeStep={(index) => setCurrentStep(index)}
              />
            </div>

            {/* Button footer */}
            <div className="flex w-full mt-3">
              {currentStep < 3 ? (
                <Button onClick={handleNextStep} RightIcon={ArrowRightIcon}>
                  {t("AutoMessageUpsert.next")}
                </Button>
              ) : (
                <Button
                  onClick={handleNextStep}
                  RightIcon={type === "add" ? PlusCircleIcon : EditIcon}
                >
                  {type === "add"
                    ? t("AutoMessageUpsert.submitAdd")
                    : t("AutoMessageUpsert.submitEdit")}
                </Button>
              )}
            </div>
          </div>
        </RightModal>
      )}
    </>
  );
};
