import React from "react";
import LogInIcon from "../../../assets/icons/log-in.svg?react";
import LogOutIcon from "../../../assets/icons/log-out.svg?react";
import MoneyIcon from "../../../assets/icons/money.svg?react";
import EmojiHappyIcon from "../../../assets/icons/emoji-happy.svg?react";
import MoonIcon from "../../../assets/icons/moon-2.svg?react";
import UsersIcon from "../../../assets/icons/users.svg?react";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../../helpers/stringHelper";
import moment from "moment";
import LayoutBlockComponent from "../Common/LayoutBlockComponent";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import { useModal } from "../../../hooks/useModal";
import { PreviewImage } from "../../Common/PreviewImage/PreviewImage";


const randomValue = Number((Math.random() * 10).toFixed(0));
const ReservationComponent = ({}: {}) => {
  const { t } = useTranslation();
  const previewResaImage = useModal();
  const { guestPageData, isPreviewMode, rentalPreview } =
      useGuestPageDataStore();
  const {
    rental,
    checkin,
    checkout,
    nights_count,
    adults_count,
    children_count,
    payment_schedule,
  } = guestPageData || {};
  const {
    name: rentalName,
    address: rentalAddress,
    postal_code: rentalPostalCode,
    city: rentalCity,
    checkin_min_time: rentalCheckinMinTime,
    checkin_max_time: rentalCheckinMaxTime,
    checkout_min_time: rentalCheckoutMinTime,
    checkout_max_time: rentalCheckoutMaxTime,
  } = rental || {};
  const {
    name: rentalPreviewName,
    address: rentalPreviewAddress,
    postal_code: rentalPreviewPostalCode,
    city: rentalPreviewCity,
    checkin_min_time: rentalPreviewCheckinMinTime,
    checkin_max_time: rentalPreviewCheckinMaxTime,
    checkout_min_time: rentalPreviewCheckoutMinTime,
    checkout_max_time: rentalPreviewCheckoutMaxTime,
  } = rentalPreview?.details.informations || {};
  const { tarification: tarificationPreview } = rentalPreview || {};
  const { rules: { rules: { currency: currencyPreview } = {} } = {} } =
  tarificationPreview || {};
  const { total_price_value } = payment_schedule || {};
  const { currency } = rental || {};


  const rentalCover = isPreviewMode
      ? rentalPreview?.details?.photos?.cover
      : rental?.cover;
  const name = isPreviewMode ? rentalPreviewName : rentalName;
  const adress = isPreviewMode ? rentalPreviewAddress : rentalAddress;
  const postalCode = isPreviewMode ? rentalPreviewPostalCode : rentalPostalCode;
  const city = isPreviewMode ? rentalPreviewCity : rentalCity;
  const checkinMinTime = isPreviewMode
      ? rentalPreviewCheckinMinTime
      : rentalCheckinMinTime;
  const checkinMaxTime = isPreviewMode
      ? rentalPreviewCheckinMaxTime
      : rentalCheckinMaxTime;
  const checkoutMinTime = isPreviewMode
      ? rentalPreviewCheckoutMinTime
      : rentalCheckoutMinTime;
  const checkoutMaxTime = isPreviewMode
      ? rentalPreviewCheckoutMaxTime
      : rentalCheckoutMaxTime;


  const nightsNb = isPreviewMode ? randomValue : nights_count;
  const adultsNb = isPreviewMode ? randomValue : adults_count;
  const childrenNb = isPreviewMode ? randomValue : children_count;
  const total = isPreviewMode ? randomValue : total_price_value;
  const symbol = isPreviewMode ? currencyPreview?.symbol : currency?.symbol;
  return (
      <LayoutBlockComponent
          title={t("GuestPage.Reservation.title")}
          icon={"🧳"}
          displayTitles
          blockStyle={{ titleStyle: "md:text-[32px]", parentDivStyle: "md:ml-0" }}
      >
        <>
          <div className="flex flex-row items-center text-sm  space-x-2">
            <div className="flex items-center">
              <div className="relative">
                <div className="object-fill w-16 h-16 overflow-hidden rounded-4px border-1 border-element-border/50">
                  <img
                      src={rentalCover}
                      alt={rentalName}
                      className="object-cover w-full h-full cursor-pointer"
                      onClick={() => {
                        previewResaImage.open();
                      }}
                  />
                  <PreviewImage
                      imageUrl={rentalCover}
                      altText={rentalName}
                      imageModal={previewResaImage}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <p className="font-semibold text-high-contrast md:text-lg">
                {name}
              </p>
              <div className="flex flex-col">
                <p className="font-light text-low-contrast md:text-lg">
                  {adress}
                </p>
                <p className="font-light text-low-contrast md:text-lg">
                  {postalCode} {city}
                </p>
              </div>
            </div>
          </div>
          <div className="md:flex md:flex-row md:justify-between">
            <div className="flex flex-row md:flex-col items-start text-sm text-low-contrast mt-3 justify-between ">
              <div className="flex flex-col md:mt-3 w-auto">
                <div className="flex md:text-lg md:items-center md:mr-[10px]">
                  <LogInIcon className="mr-1 " />{" "}
                  {t("GuestPage.Reservation.checkin")}
                </div>
                <span className=" text-high-contrast font-bold md:text-lg">
               {capitalizeFirstLetter(
                   moment(checkin).format("dddd D MMMM YYYY"),
               )}
             </span>
                <span className="font-light text-low-contrast md:text-lg">
               {t("GuestPage.Reservation.between")}{" "}
                  {moment(checkinMinTime, "HH:mm").format("HH:mm")}{" "}
                  {t("GuestPage.Reservation.and")}{" "}
                  {moment(checkinMaxTime, "HH:mm").format("HH:mm")}
             </span>
              </div>


              <div className="flex flex-col md:mt-6 w-auto">
                <div className="flex md:text-lg md:items-center">
                  <LogOutIcon className="mr-1" />{" "}
                  {t("GuestPage.Reservation.checkout")}
                </div>
                <span className="text-high-contrast font-bold md:text-lg">
               {capitalizeFirstLetter(
                   moment(checkout).format("dddd D MMMM YYYY"),
               )}
             </span>
                <span className="font-light text-low-contrast md:text-lg">
               {t("GuestPage.Reservation.between")}{" "}
                  {moment(checkoutMinTime, "HH:mm").format("HH:mm")}{" "}
                  {t("GuestPage.Reservation.and")}{" "}
                  {moment(checkoutMaxTime, "HH:mm").format("HH:mm")}
             </span>
              </div>
            </div>
            <div className="flex mt-3 md:flex-col md:space-y-1 items-center md:items-end justify-between font-light text-low-contrast">
              <div className="flex items-center md:text-lg">
                <MoonIcon className="flex-shrink-0 inline w-5 mr-1" />{" "}
                {t("Global.nights", {
                  count: nightsNb ?? 0,
                }).toLowerCase()}
              </div>
              <div className="flex items-center md:text-lg">
                <UsersIcon className="flex-shrink-0 inline w-5 mr-1" />{" "}
                {t("Global.adults", { count: adultsNb ?? 0 })}
              </div>
              <div className="flex items-center md:text-lg">
                <EmojiHappyIcon className="flex-shrink-0 inline w-5 mr-1" />{" "}
                {t("Global.children", {
                  count: childrenNb ?? 0,
                })}
              </div>
              <div className="flex items-center md:text-lg">
                <MoneyIcon className="flex-shrink-0 inline w-5 mr-1" />{" "}
                {total ?? 0} {symbol ?? "€"}
              </div>
            </div>
          </div>
        </>
      </LayoutBlockComponent>
  );
};


export default ReservationComponent;

