import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AlertCircleIcon from "../../../../assets/icons/alert-circle.svg?react";
import ArrowRightIcon from "../../../../assets/icons/arrow-right-white.svg?react";
import CheckCircleIcon from "../../../../assets/icons/check-circle-green.svg?react";
import { cn } from "../../../../helpers/classHelper";
import { Keys } from "../../../../values";
import { Button } from "../../../Common/Button/Button";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { Title } from "../../../Common/Title/Title";
import {
  RegisterPasswordForm,
  RegisterPasswordProps,
} from "./RegisterPassword.type";

export const RegisterPassword: React.FC<RegisterPasswordProps> = ({
  error,
  loading = false,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const [criteria, setCriteria] = useState({
    length: false,
    upper: false,
    low: false,
    number: false,
    specialChar: false,
  });

  const {
    register,
    formState: { errors, isValid },
    clearErrors,
    getValues,
    handleSubmit,
    watch,
  } = useForm<RegisterPasswordForm>({
    mode: "all",
    defaultValues: { password: "", passwordConfirmation: "" },
  });

  const passwordRef = useRef<string>("");
  passwordRef.current = watch("password", "");

  const minPasswordLength = 8;
  const maxPasswordLength = 24;

  const handleSave = (data: RegisterPasswordForm) => {
    if (typeof onSubmit === "function") {
      onSubmit({ password: data.password });
    }
  };

  const validatePassword = (password: string) => {
    setCriteria({
      length: password?.length >= minPasswordLength,
      upper: /[A-Z]/.test(password),
      low: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[\W_]/.test(password),
    });
  };

  useEffect(() => {
    validatePassword(passwordRef.current);
  }, [passwordRef.current]);

  useEffect(() => {
    if (
      passwordRef.current === getValues("passwordConfirmation") &&
      Object.values(criteria).every((c) => c === true)
    ) {
      clearErrors();
    }
  }, [criteria]);

  watch();

  return (
    <div>
      <Title>{t("Register.Password.title")}</Title>

      <form onSubmit={handleSubmit(handleSave)}>
        <div className="mt-8">
          <TextInput
            register={register("password", {
              required: t("Register.Password.required").toString(),
              minLength: {
                value: minPasswordLength,
                message: t("Register.Password.min", {
                  length: minPasswordLength,
                }).toString(),
              },
              maxLength: {
                value: maxPasswordLength,
                message: t("Register.Password.max", {
                  length: maxPasswordLength,
                }).toString(),
              },
              pattern: {
                value: /^(?!.*\s)(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,24}$/i,
                message: t("Register.Password.invalidFormat").toString(),
              },
            })}
            label={t("Register.Password.create")}
            type="password"
            disabled={loading}
            error={errors.password?.message}
            placeholder={t("Register.Password.placeholder")}
          />
        </div>
        <div>
          <p
              className={cn(
                  `mt-2 text-sm font-light text-left flex flex-row gap-1`,
                  {
                    "text-success": criteria.length,
                    "text-low-contrast": !criteria.length,
                  }
              )}
          >
            {criteria.length ? (
                <CheckCircleIcon
                    className="me-2 shrink-0"
                    width={18}
                    height={18}
                />
            ) : (
                <AlertCircleIcon
                    className="fill-gray-500 me-2 shrink-0"
                    width={18}
                    height={18}
                />
            )}

            {t("Reset.rule1Title")}
          </p>
          <p
              className={cn(
                  `mt-2 text-sm font-light text-left flex flex-row gap-1`,
                  {
                    "text-success": criteria.upper,
                    "text-low-contrast": !criteria.upper,
                  }
              )}
          >
            {criteria.upper ? (
                <CheckCircleIcon
                    className="me-2 shrink-0"
                    width={18}
                    height={18}
                />
            ) : (
                <AlertCircleIcon
                    className="fill-gray-500 me-2 shrink-0"
                    width={18}
                    height={18}
                />
            )}
            {t("Reset.rule4Title")}
          </p>

          <p
              className={cn(
                  `mt-2 text-sm font-light text-left flex flex-row gap-1`,
                  {
                    "text-success": criteria.low,
                    "text-low-contrast": !criteria.low,
                  }
              )}
          >
            {criteria.low ? (
                <CheckCircleIcon
                    className="me-2 shrink-0"
                    width={18}
                    height={18}
                />
            ) : (
                <AlertCircleIcon
                    className="fill-gray-500 me-2 shrink-0"
                    width={18}
                    height={18}
                />
            )}
            {t("Reset.rule5Title")}
          </p>
          <p
              className={cn(
                  `mt-2 text-sm font-light text-left flex flex-row gap-1`,
                  {
                    "text-success": criteria.number,
                    "text-low-contrast": !criteria.number,
                  }
              )}
          >
            {criteria.number ? (
                <CheckCircleIcon
                    className="me-2 shrink-0"
                    width={18}
                    height={18}
                />
            ) : (
                <AlertCircleIcon
                    className="fill-gray-500 me-2 shrink-0"
                    width={18}
                    height={18}
                />
            )}
            {t("Reset.rule2Title")}
          </p>
          <p
              className={cn(
                  `mt-2 text-sm font-light text-left flex flex-row gap-1`,
                  {
                    "text-success": criteria.specialChar,
                    "text-low-contrast": !criteria.specialChar,
                  }
              )}
          >
            {criteria.specialChar ? (
                <CheckCircleIcon
                    className="me-2 shrink-0"
                    width={18}
                    height={18}
                />
            ) : (
                <AlertCircleIcon
                    className="fill-gray-500 me-2 shrink-0"
                    width={18}
                    height={18}
                />
            )}
            {t("Reset.rule3Title")}
          </p>
        </div>

        <div className="mt-4">
          <TextInput
              register={register("passwordConfirmation", {
                validate: (value) =>
                    value === passwordRef.current ||
                    t("Register.Password.notMatch").toString(),
              })}
              label={t("Register.Password.confirm")}
              type="password"
              disabled={loading}
              error={errors.passwordConfirmation?.message}
              placeholder={t("Register.Password.placeholder")}
          />
        </div>

        <ErrorMessage>{error}</ErrorMessage>

        <div className="flex mt-4">
          <Button
              RightIcon={ArrowRightIcon}
              keyToSubmit={Keys.Enter}
            disabled={loading || !isValid}
            onClick={handleSubmit(handleSave)}
            loading={loading}
          >
            {t("Register.Password.submit")}
          </Button>
        </div>
      </form>
    </div>
  );
};
