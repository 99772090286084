import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { get } from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import { debounce } from "lodash";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { Button } from "../../../Common/Button/Button";
import SearchIcon from "../../../../assets/icons/search.svg?react";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import ArrowRightIcon from "../../../../assets/icons/arrow-right.svg?react";
import { BookingConnectRentalLine } from "./BookingConnectRentalLine";
import { BookingConnectRentalLineSkeleton } from "./BookingConnectRentalLineSkeleton";
import { RentalLightListItemResponse } from "../../../../types/GETTypes";

type BookingConnect2Props = {};

export const BookingConnectStep2: React.FC<BookingConnect2Props> = ({}) => {
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentNbPerPage, setCurrentNbPerPage] = useState<number>(5);
  const [currentSearch, setCurrentSearch] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(true);
  const [rentals, setRentals] = useState<RentalLightListItemResponse[]>([]);
  const [total, setTotal] = useState<number | null>(null);
  const [lastPage, setLastPage] = useState<number>(1);

  const fetchRentals = async ({ page = 1, limit = 5, search = "" }) => {
    setLoading(true);

    const params = {
      page,
      limit,
      search,
    };

    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`,
      { params }
    );

    console.log("fetchRentals", response);

    if (response.data?.success) {
      setRentals(response.data?.result?.rentals);
      setTotal(response.data?.result?.total);
      setLastPage(response.data?.result?.last_page);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchRentals({});
  }, []);

  const handleGoBack = () => {
    const nextPage = currentPage > 1 ? currentPage - 1 : 1;

    setCurrentPage(nextPage);

    fetchRentals({
      page: nextPage,
      limit: currentNbPerPage,
      search: currentSearch,
    });
  };

  const handleGoNext = () => {
    const nextPage = currentPage >= lastPage ? lastPage : currentPage + 1;

    setCurrentPage(nextPage);

    fetchRentals({
      page: nextPage,
      limit: currentNbPerPage,
      search: currentSearch,
    });
  };

  const debouncedSearch = React.useRef(
    debounce(async (text) => {
      const nextPage = 1;

      setCurrentPage(nextPage);
      fetchRentals({
        page: nextPage,
        search: text,
      });
    }, 300)
  ).current;

  React.useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleChangeSearch = (value: string) => {
    setCurrentSearch(value);

    debouncedSearch(value);
  };

  const handleReloadRentals = () => {
    fetchRentals({
      search: currentSearch,
      limit: currentNbPerPage,
      page: currentPage,
    });
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-3">
        <TextInput
          Icon={SearchIcon}
          placeholder={t("Progression.searchRental")}
          value={currentSearch}
          onChangeText={handleChangeSearch}
        />
      </div>

      <div className="mt-3">
        <table className="table-auto w-full rounded-4px border-1 border-element-border border-separate border-spacing-0">
          {/* Table Header */}
          <tr className="bg-element-background border-b-1 border-element-border">
            <th className=" px-2 py-2 text-sm">
              <div className="flex flex-row items-center hover:underline">
                <p>{t("Progression.rentalInSH")}</p>
              </div>
            </th>
          </tr>

          {loading &&
            Array.from({ length: 5 })?.map(() => (
              <BookingConnectRentalLineSkeleton />
            ))}

          {!loading &&
            rentals?.map((rental: RentalLightListItemResponse) => (
              <BookingConnectRentalLine
                rental={rental}
                onReload={handleReloadRentals}
              />
            ))}
        </table>
      </div>

      {/* Bottom with paging */}
      <div className="flex flex-row mt-4 items-center">
        <div className="flex flex-1 flex-row items-center gap-2">
          {total ? (
            <p className="font-light text-low-contrast">
              {t("Global.total")} : {total}
            </p>
          ) : (
            <div className="h-2 w-24 bg-slate-200 rounded animate-pulse" />
          )}
        </div>

        <div className="flex flex-row space-x-2">
          <Button
            type="secondary"
            LeftIcon={ArrowLeftIcon}
            disabled={currentPage <= 1}
            onClick={handleGoBack}
          ></Button>
          <Button
            type="secondary"
            onClick={() => {
              console.log("click page 1");
            }}
          >
            <span className="px-1">{currentPage}</span>
          </Button>
          <Button
            type="secondary"
            LeftIcon={ArrowRightIcon}
            disabled={currentPage >= lastPage}
            onClick={handleGoNext}
          ></Button>
        </div>
      </div>
    </div>
  );
};
