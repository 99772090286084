import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import ArrowRightWhiteIcon from "../../../../assets/icons/arrow-right-white.svg?react";
import ArrowRightIcon from "../../../../assets/icons/arrow-right.svg?react";
import ArrowUpRightWhiteIcon from "../../../../assets/icons/arrow-up-right-white.svg?react";
import ArrowUpRightIcon from "../../../../assets/icons/arrow-up-right.svg?react";
import CheckCircleIcon from "../../../../assets/icons/check-circle-green.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import CloseCircleIcon from "../../../../assets/icons/close-circle-red.svg?react";
import IgloohomeRenderIcon from "../../../../assets/icons/igloohome-render.svg?react";
import IgloohomeIcon from "../../../../assets/icons/igloohome.svg?react";
import KeyHoleIcon from "../../../../assets/icons/keyhole.svg?react";
import NukiRoundedIcon from "../../../../assets/icons/nuki-rounded.svg?react";
import NukiIcon from "../../../../assets/icons/nuki.svg?react";
import PlusWhiteIcon from "../../../../assets/icons/plus-white.svg?react";
import RefreshIcon from "../../../../assets/icons/refresh.svg?react";
import Ttlock2Icon from "../../../../assets/icons/ttlock-2.svg?react";
import TtlockIcon from "../../../../assets/icons/ttlock.svg?react";
import { PlatformColorList } from "../../../../constants/colors";
import { SmartLocksEnum } from "../../../../constants/locks";
import paths from "../../../../constants/paths";
import {
  capitalizeFirstLetter,
  capitalizeLetters,
} from "../../../../helpers/stringHelper";
import { useRentalPageContext } from "../../../../pages/RentalPage/RentalPageContext";
import { RequiredFields } from "../../../../types/commonTypes";
import { SmartlocksLinkedItemResponse } from "../../../../types/GETTypes";
import { Button } from "../../../Common/Button/Button";
import InfoCard from "../../../Common/Card/InfoCard";
import { ConnectionLoader } from "../../../Common/ConnectionLoader/ConnectionLoader";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import { Separator } from "../../../Common/Separator/Separator";
import { SimpleRadio } from "../../../Common/SimpleRadio/SimpleRadio";
import { TextInput } from "../../../Common/TextInput/TextInput";
import {
  SmartlocksInformationsForm,
  SmartlocksInformationsManualForm,
  SmartlocksLoginIgloohomeForm,
  SmartlocksLoginManualForm,
  SmartlocksLoginNukiForm,
  SmartlocksLoginTtlockForm,
} from "./Smartlocks.type";
import { useSmartlocksContext } from "./SmartlocksContext";
import {
  rentalSmartlocksAddInformationsLoginSkeleton,
  rentalSmartlocksListSkeleton,
  rentalSmartlocksLoginStepFormSkeleton,
} from "./SmartlocksSkeleton";

export const useSmartLocksList = () => {
  const { rental } = useRentalPageContext();
  const { t, locks, LockItem, onOpenAddModal } = useSmartlocksContext();

  if (!locks || !rental) return rentalSmartlocksListSkeleton();

  return Array.isArray(locks) && locks.length > 0 && rental ? (
    locks.map((lock: SmartlocksLinkedItemResponse) => (
      <LockItem key={lock.id} mode="listing" lock={lock} rental={rental} />
    ))
  ) : (
    <>
      <p className="text-lg font-light text-center text-low-contrast">
        {t("Rental.Services.Smartlocks.noLocks")}
      </p>

      <Button
        className="font-bold text-white bg-element-background"
        RightIcon={PlusWhiteIcon}
        onClick={onOpenAddModal}
      >
        {t("Rental.Services.Smartlocks.addLocks")}
      </Button>
    </>
  );
};

// * -- SMART LOCKS ADD MODAL --
export const useSmartLocksLoginStep = () => {
  const {
    t,
    validationLoading,
    currentCarouselWizardIndex,
    getLockProviderIcon,
    getLockFormattedProviderName,
    connectionStatus,
    attemptConnection,
    resetConnection,
    changeStep,
    onNextStep,
    LockDynamicFieldInfo,
    igloohomeLockAccounts,
    nukiLockAccounts,
    ttlockLockAccounts,
    loginForm: { control, getValues, setValue, watch },
    igloohomeLoginForm,
    nukiLoginForm,
    ttlockLoginForm,
    staticLoginForm: {
      register: staticLoginRegister,
      getValues: staticLoginValues,
      formState: { errors: staticLoginErrors, isValid: staticLoginIsValid },
      handleSubmit: staticLoginHandleSubmit,
      watch: staticLoginWatch,
    },
    locks,
  } = useSmartlocksContext();

  const igloohomeLoginRequiredFields: RequiredFields<SmartlocksLoginIgloohomeForm> =
    {
      lockAccount: true,
    };

  const nukiLoginRequiredFields: RequiredFields<SmartlocksLoginNukiForm> = {
    lockAccount: true,
  };

  const ttlockLoginRequiredFields: RequiredFields<SmartlocksLoginTtlockForm> = {
    lockAccount: true,
    email: true,
    password: true,
  };

  const manualRequiredFields: RequiredFields<SmartlocksLoginManualForm> = {
    name: true,
  };

  const loadingNode = (
    <div className="flex flex-col items-center justify-center py-4 space-y-4 bg-element-background-light">
      <ConnectionLoader
        classNames={{ container: "h-max" }}
        platformRight={{
          icon: getLockProviderIcon(getValues("lockProvider")),
          color: PlatformColorList.Booking,
        }}
      />
      <div className="text-center">
        <p className="mb-3 font-bold text-low-contrast">
          {t("Rental.Services.Smartlocks.AddModal.Steps.Login.loadingTitle", {
            name: capitalizeLetters(
              getValues("lockProvider").toLowerCase(),
              getValues("lockProvider") === SmartLocksEnum.TTLOCK ? [0, 1] : [0]
            ),
          })}
        </p>
        <div>
          <p className="font-light text-low-contrast">
            {t(
              "Rental.Services.Smartlocks.AddModal.Steps.Login.loadingSubtitle1"
            )}
          </p>
          <p className="font-light text-low-contrast">
            {t(
              "Rental.Services.Smartlocks.AddModal.Steps.Login.loadingSubtitle2"
            )}
          </p>
        </div>
      </div>
    </div>
  );

  const ttlockInputsNode = () => {
    return (
      <>
        <TextInput
          register={ttlockLoginForm.register("email", {
            required: {
              value: ttlockLoginRequiredFields.email,
              message: t("Global.Errors.requiredField", {
                fieldName: t(
                  "Rental.Services.Smartlocks.AddModal.Steps.Login.emailLabel"
                ),
              }),
            },
            pattern: {
              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: t(
                "Rental.Services.Smartlocks.AddModal.Steps.Login.emailError"
              ),
            },
          })}
          label={t(
            "Rental.Services.Smartlocks.AddModal.Steps.Login.emailLabel"
          )}
          placeholder={t(
            "Rental.Services.Smartlocks.AddModal.Steps.Login.emailPlaceholder"
          )}
          required={ttlockLoginRequiredFields.email}
          value={ttlockLoginForm.getValues("email")}
          error={ttlockLoginForm.formState.errors.email?.message}
        />

        <TextInput
          register={ttlockLoginForm.register("password", {
            required: {
              value: ttlockLoginRequiredFields.password,
              message: t("Global.Errors.requiredField", {
                fieldName: t(
                  "Rental.Services.Smartlocks.AddModal.Steps.Login.passwordLabel"
                ),
              }),
            },
            minLength: {
              value: 8,
              message: t("Global.PasswordValidation.min", {
                length: 8,
              }).toString(),
            },
            maxLength: {
              value: 20,
              message: t("Global.PasswordValidation.max", {
                length: 20,
              }).toString(),
            },
          })}
          type="password"
          label={t(
            "Rental.Services.Smartlocks.AddModal.Steps.Login.passwordLabel"
          )}
          placeholder={t(
            "Rental.Services.Smartlocks.AddModal.Steps.Login.passwordPlaceholder"
          )}
          required={ttlockLoginRequiredFields.password}
          value={ttlockLoginForm.getValues("password")}
          error={ttlockLoginForm.formState.errors.password?.message}
        />
      </>
    );
  };

  const defaultNode = (
    <>
      <div className="flex flex-row items-center justify-between">
        <Controller
          control={control}
          name="lockProvider"
          render={({ field: { value } }) => (
            <>
              <SimpleRadio
                Icon={IgloohomeIcon}
                value={value === SmartLocksEnum.IGLOOHOMEV2}
                onClick={() =>
                  setValue("lockProvider", SmartLocksEnum.IGLOOHOMEV2)
                }
              />
              <SimpleRadio
                Icon={NukiIcon}
                disabled={false}
                value={value === SmartLocksEnum.NUKI}
                onClick={() => setValue("lockProvider", SmartLocksEnum.NUKI)}
              />
              <SimpleRadio
                Icon={TtlockIcon}
                disabled={false}
                value={value === SmartLocksEnum.TTLOCK}
                onClick={() => setValue("lockProvider", SmartLocksEnum.TTLOCK)}
              />
              <SimpleRadio
                value={value === "static"}
                disabled={false}
                onClick={() => setValue("lockProvider", "static")}
                label={t(
                  "Rental.Services.Smartlocks.AddModal.Steps.Login.manualOptionLabel"
                )}
              />
            </>
          )}
        />
      </div>

      <Separator accent="dark" />

      {getValues("lockProvider") === SmartLocksEnum.IGLOOHOMEV2 ? (
        <>
          {igloohomeLockAccounts && igloohomeLockAccounts.length === 0 && (
            <Button
              RightIcon={ArrowUpRightWhiteIcon}
              onClick={() => attemptConnection()}
            >
              {t(
                "Rental.Services.Smartlocks.AddModal.Steps.Login.connectionIgloohomeCta"
              )}
            </Button>
          )}

          {igloohomeLockAccounts && igloohomeLockAccounts.length > 0 && (
            <div className="flex flex-col items-center justify-center space-y-4">
              <div className="w-full">
                <Controller
                  control={igloohomeLoginForm.control}
                  name="lockAccount"
                  rules={{
                    required: igloohomeLoginRequiredFields.lockAccount,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <InputSelect
                        required={igloohomeLoginRequiredFields.lockAccount}
                        label={t(
                          "Rental.Services.Smartlocks.AddModal.Steps.Login.igloohomeConnectedAccountsLabel"
                        )}
                        items={igloohomeLockAccounts.map((account) => {
                          return {
                            value: account.id,
                            label: account.email,
                          };
                        })}
                        selectedValue={value!}
                        onSelect={onChange}
                        disabled={validationLoading}
                        error={
                          igloohomeLoginForm.formState.errors.lockAccount
                            ?.message
                        }
                      />
                    );
                  }}
                />
              </div>

              <div className="w-full">
                <Button
                  RightIcon={ArrowRightWhiteIcon}
                  onClick={() => onNextStep({ which: "informations" })}
                >
                  {t(
                    "Rental.Services.Smartlocks.AddModal.Steps.Login.connectLock"
                  )}
                </Button>
              </div>

              <p className="text-base font-light text-low-contrast">
                {capitalizeFirstLetter(t("Global.or"))}
              </p>

              <div className="w-full">
                <Button
                  type="secondary"
                  RightIcon={ArrowUpRightIcon}
                  onClick={() => attemptConnection()}
                >
                  {t(
                    "Rental.Services.Smartlocks.AddModal.Steps.Login.connectionIgloohomeCta2"
                  )}
                </Button>
              </div>
            </div>
          )}

          {igloohomeLockAccounts && igloohomeLockAccounts.length >= 0 && (
            <p className="text-xs font-light tracking-wide text-low-contrast">
              {t(
                "Rental.Services.Smartlocks.AddModal.Steps.Login.descriptionIgloohome"
              )}
            </p>
          )}

          {/* SKELETON */}
          {igloohomeLockAccounts === undefined &&
            rentalSmartlocksLoginStepFormSkeleton()}
        </>
      ) : null}

      {getValues("lockProvider") === SmartLocksEnum.NUKI ? (
        <>
          {nukiLockAccounts && nukiLockAccounts.length === 0 && (
            <Button
              RightIcon={ArrowUpRightWhiteIcon}
              onClick={() => attemptConnection()}
              loading={connectionStatus === "loading"}
            >
              {t(
                "Rental.Services.Smartlocks.AddModal.Steps.Login.connectionNukiCta"
              )}
            </Button>
          )}

          {nukiLockAccounts && nukiLockAccounts.length > 0 && (
            <div className="flex flex-col items-center justify-center space-y-4">
              <div className="w-full">
                <Controller
                  control={nukiLoginForm.control}
                  name="lockAccount"
                  rules={{
                    required: nukiLoginRequiredFields.lockAccount,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <InputSelect
                        required={nukiLoginRequiredFields.lockAccount}
                        label={t(
                          "Rental.Services.Smartlocks.AddModal.Steps.Login.nukiConnectedAccountsLabel"
                        )}
                        items={nukiLockAccounts.map((account) => {
                          return {
                            value: account.id,
                            label: account.email,
                          };
                        })}
                        selectedValue={value!}
                        onSelect={onChange}
                        disabled={validationLoading}
                        error={
                          nukiLoginForm.formState.errors.lockAccount?.message
                        }
                      />
                    );
                  }}
                />
              </div>

              <div className="w-full">
                <Button
                  RightIcon={ArrowRightWhiteIcon}
                  onClick={() => onNextStep({ which: "informations" })}
                >
                  {t(
                    "Rental.Services.Smartlocks.AddModal.Steps.Login.connectLock"
                  )}
                </Button>
              </div>

              <p className="text-base font-light text-low-contrast">
                {capitalizeFirstLetter(t("Global.or"))}
              </p>

              <div className="w-full">
                <Button
                  type="secondary"
                  RightIcon={ArrowUpRightIcon}
                  onClick={() => attemptConnection()}
                >
                  {t(
                    "Rental.Services.Smartlocks.AddModal.Steps.Login.connectionNukiCta2"
                  )}
                </Button>
              </div>
            </div>
          )}

          {nukiLockAccounts && nukiLockAccounts.length >= 0 && (
            <p className="text-xs font-light tracking-wide text-low-contrast">
              {t(
                "Rental.Services.Smartlocks.AddModal.Steps.Login.descriptionNuki"
              )}
            </p>
          )}

          {/* SKELETON */}
          {nukiLockAccounts === undefined &&
            rentalSmartlocksLoginStepFormSkeleton()}
        </>
      ) : null}

      {getValues("lockProvider") === SmartLocksEnum.TTLOCK ? (
        <>
          {ttlockLockAccounts && ttlockLockAccounts.length === 0 && (
            <>
              {ttlockInputsNode()}
              <Button
                RightIcon={ArrowUpRightWhiteIcon}
                disabled={
                  validationLoading ||
                  !ttlockLoginForm.formState.isValid ||
                  Object.values(ttlockLoginForm.formState.errors).length > 0
                }
                loading={validationLoading}
                onClick={() => attemptConnection()}
              >
                {t(
                  "Rental.Services.Smartlocks.AddModal.Steps.Login.connectionTtlockCta"
                )}
              </Button>
            </>
          )}

          {ttlockLockAccounts && ttlockLockAccounts.length > 0 && (
            <div className="flex flex-col items-center justify-center space-y-4">
              <div className="w-full">
                <Controller
                  control={ttlockLoginForm.control}
                  name="lockAccount"
                  rules={{
                    required: ttlockLoginRequiredFields.lockAccount,
                  }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <InputSelect
                        required={ttlockLoginRequiredFields.lockAccount}
                        label={t(
                          "Rental.Services.Smartlocks.AddModal.Steps.Login.ttlockConnectedAccountsLabel"
                        )}
                        items={ttlockLockAccounts.map((account) => {
                          return {
                            value: account.id,
                            label: account.email,
                          };
                        })}
                        selectedValue={value!}
                        onSelect={onChange}
                        disabled={validationLoading}
                        error={
                          ttlockLoginForm.formState.errors.lockAccount?.message
                        }
                      />
                    );
                  }}
                />
              </div>

              <div className="w-full">
                <Button
                  RightIcon={CheckIcon}
                  onClick={() => onNextStep({ which: "informations" })}
                >
                  {t(
                    "Rental.Services.Smartlocks.AddModal.Steps.Login.connectLock"
                  )}
                </Button>
              </div>

              <p className="text-base font-light text-low-contrast">
                {capitalizeFirstLetter(t("Global.or"))}
              </p>

              <div className="w-full">
                <Button
                  type="secondary"
                  RightIcon={ArrowUpRightIcon}
                  onClick={() => attemptConnection()}
                >
                  {t(
                    "Rental.Services.Smartlocks.AddModal.Steps.Login.connectionTtlockCta2"
                  )}
                </Button>
              </div>
            </div>
          )}

          {ttlockLockAccounts && ttlockLockAccounts.length >= 0 && (
            <p className="text-xs font-light tracking-wide text-low-contrast">
              {t(
                "Rental.Services.Smartlocks.AddModal.Steps.Login.descriptionTtlock"
              )}
            </p>
          )}
          {/* SKELETON */}
          {ttlockLockAccounts === undefined &&
            rentalSmartlocksLoginStepFormSkeleton()}
          {/* <p className="text-low-contrast">
            {t(
              "Rental.Services.Smartlocks.AddModal.Steps.Login.informationsLabel"
            )}
          </p>
          <TextInput
            register={ttlockLoginRegister("email", {
              required: {
                value: ttlockLoginRequiredFields.email,
                message: t("Global.Errors.requiredField", {
                  fieldName: t(
                    "Rental.Services.Smartlocks.AddModal.Steps.Login.emailLabel"
                  ),
                }),
              },
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t(
                  "Rental.Services.Smartlocks.AddModal.Steps.Login.emailError"
                ),
              },
            })}
            label={t(
              "Rental.Services.Smartlocks.AddModal.Steps.Login.emailLabel"
            )}
            placeholder={t(
              "Rental.Services.Smartlocks.AddModal.Steps.Login.emailPlaceholder"
            )}
            required={ttlockLoginRequiredFields.email}
            value={ttlockLoginValues("email")}
            error={ttlockLoginErrors.email?.message}
          />

          <TextInput
            register={ttlockLoginRegister("password", {
              required: {
                value: ttlockLoginRequiredFields.password,
                message: t("Global.Errors.requiredField", {
                  fieldName: t(
                    "Rental.Services.Smartlocks.AddModal.Steps.Login.passwordLabel"
                  ),
                }),
              },
            })}
            type="password"
            label={t(
              "Rental.Services.Smartlocks.AddModal.Steps.Login.passwordLabel"
            )}
            placeholder={t(
              "Rental.Services.Smartlocks.AddModal.Steps.Login.passwordPlaceholder"
            )}
            required={ttlockLoginRequiredFields.password}
            value={ttlockLoginValues("password")}
            error={ttlockLoginErrors.password?.message}
          />

          <Button
            RightIcon={CheckIcon}
            onClick={ttlockLoginHandleSubmit(attemptConnection)}
            loading={connectionStatus === "loading"}
            disabled={
              !ttlockLoginIsValid ||
              Object.keys(ttlockLoginErrors).length !== 0 ||
              connectionStatus === "loading"
            }
          >
            {t("Global.connect")}
          </Button> */}
        </>
      ) : null}

      {getValues("lockProvider") === "static" ? (
        <>
          <p className="text-low-contrast">
            {t(
              "Rental.Services.Smartlocks.AddModal.Steps.Login.informationsLabel"
            )}
          </p>

          <TextInput
            register={staticLoginRegister("name", {
              required: {
                value: manualRequiredFields.name,
                message: t("Global.Errors.requiredField", {
                  fieldName: t(
                    "Rental.Services.Smartlocks.AddModal.Steps.Login.accessCodeNameLabel"
                  ),
                }),
              },
            })}
            label={t(
              "Rental.Services.Smartlocks.AddModal.Steps.Login.accessCodeNameLabel"
            )}
            placeholder={t(
              "Rental.Services.Smartlocks.AddModal.Steps.Login.accessCodeNamePlaceholder"
            )}
            required={manualRequiredFields.name}
            value={staticLoginValues("name")}
            error={staticLoginErrors.name?.message}
          />

          <LockDynamicFieldInfo type="create" />

          <Button
            RightIcon={ArrowRightWhiteIcon}
            onClick={staticLoginHandleSubmit(() =>
              changeStep(currentCarouselWizardIndex + 1)
            )}
            loading={validationLoading}
            disabled={
              !staticLoginIsValid ||
              Object.keys(staticLoginErrors).length !== 0 ||
              validationLoading
            }
          >
            {t("Global.next")}
          </Button>
        </>
      ) : null}
    </>
  );

  const successNode = (
    <div className="flex flex-col items-center justify-center gap-y-4">
      <div className="flex flex-col items-center justify-center w-full p-4 space-y-3 rounded-md bg-element-background-success">
        <CheckCircleIcon width={30} height={30} />
        <p className="text-base font-bold text-success">
          {t(
            "Rental.Services.Smartlocks.AddModal.Steps.Login.successConnection",
            { name: getLockFormattedProviderName(getValues("lockProvider")) }
          )}
        </p>
      </div>

      <div className="w-full">
        <Button
          RightIcon={ArrowRightWhiteIcon}
          onClick={() => onNextStep({ which: "informations" })}
        >
          {t("Global.next")}
        </Button>
      </div>
    </div>
  );

  const errorNode = (
    <div className="flex flex-col items-center justify-center gap-y-4">
      <div className="flex flex-col items-center justify-center w-full p-4 space-y-3 rounded-md bg-element-background-error">
        <CloseCircleIcon width={30} height={30} />
        <p className="text-base font-bold text-error">
          {t(
            "Rental.Services.Smartlocks.AddModal.Steps.Login.errorConnection",
            {
              name: getLockFormattedProviderName(getValues("lockProvider")),
            }
          )}
        </p>
      </div>

      <div className="w-full">
        <Button RightIcon={ArrowUpRightIcon} onClick={() => resetConnection()}>
          {t("Global.retry")}
        </Button>
      </div>
    </div>
  );

  watch();
  ttlockLoginForm.watch();
  staticLoginWatch();

  return (
    <div className="flex flex-col space-y-4">
      {connectionStatus === null ? defaultNode : null}
      {connectionStatus === "loading" ? loadingNode : null}
      {connectionStatus === "success" ? successNode : null}
      {connectionStatus === "error" ? errorNode : null}
    </div>
  );
};

export const useSmartLocksInformationsStep = () => {
  const { rental } = useRentalPageContext();

  const {
    t,
    validationLoading,
    updateLockDynamicField,
    LockDynamicFieldInfo,
    onNextStep,
    loginForm,
    timeList,
    getLockFormattedProviderName,
    resetConnection,
    informationsForm: {
      control: informationsControl,
      register: informationsRegister,
      setValue: informationSetValue,
      getValues: informationsValues,
      formState: { errors: informationsErrors, isValid: informationsIsValid },
      handleSubmit: informationsHandleSubmit,
      watch: informationsWatch,
    },
    informationsManualForm: {
      register: informationsManualRegister,
      getValues: informationsManualValues,
      formState: {
        errors: informationsManualErrors,
        isValid: informationsManualIsValid,
      },
      handleSubmit: informationsStaticHandleSubmit,
      watch: informationsManualWatch,
    },
    lockIdItems,
  } = useSmartlocksContext();

  const informationsRequiredFields: RequiredFields<SmartlocksInformationsForm> =
    {
      lockName: true,
      lockId: true,
      checkinTime: true,
      checkoutTime: true,
    };

  const informationsStaticRequiredFields: RequiredFields<SmartlocksInformationsManualForm> =
    {
      code: true,
    };

  const noLocksNode = (provider: SmartLocksEnum) => {
    let providerLink = undefined;
    if (
      provider === SmartLocksEnum.IGLOOHOMEV2 ||
      provider === SmartLocksEnum.IGLOOHOME
    ) {
      providerLink = "https://igloohome.fr/mon-compte-client/";
    } else if (provider === SmartLocksEnum.NUKI) {
      providerLink = "https://web.nuki.io/#/login";
    } else if (provider === SmartLocksEnum.TTLOCK) {
      providerLink = "https://lock2.ttlock.com";
    }

    return (
      <div className="flex flex-col gap-y-4">
        <InfoCard
          title={t(
            "Rental.Services.Smartlocks.AddModal.Steps.Informations.noLocksTitle",
            {
              providerName: getLockFormattedProviderName(provider),
            }
          )}
          description={t(
            "Rental.Services.Smartlocks.AddModal.Steps.Informations.noLocksDescription"
          )}
        />

        <Link to={providerLink!} target="_blank">
          <Button RightIcon={ArrowUpRightWhiteIcon}>
            {t(
              "Rental.Services.Smartlocks.AddModal.Steps.Informations.noLocksAddLock",
              {
                providerName: getLockFormattedProviderName(provider),
              }
            )}
          </Button>
        </Link>

        <Button
          type="secondary"
          RightIcon={RefreshIcon}
          onClick={() => resetConnection()}
        >
          {t("Global.retry")}
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (rental) {
      informationSetValue(
        "checkinTime",
        rental.details.informations.checkin_max_time
      );
      informationSetValue(
        "checkoutTime",
        rental.details.informations.checkout_max_time
      );
    }
  }, [rental]);

  informationsWatch();
  informationsManualWatch();

  // Skeleton or no locks placeholder
  if (loginForm.getValues("lockProvider") !== "static") {
    if (!lockIdItems) {
      return rentalSmartlocksAddInformationsLoginSkeleton();
    } else if (lockIdItems?.length === 0) {
      return noLocksNode(
        loginForm.getValues("lockProvider").toUpperCase() as SmartLocksEnum
      );
    }
  }

  return (
    <div className="flex flex-col space-y-4">
      {loginForm.getValues("lockProvider") === "static" ? (
        <>
          <TextInput
            register={informationsManualRegister("code", {
              required: {
                value: informationsStaticRequiredFields.code ?? false,
                message: t("Global.Errors.requiredField", {
                  fieldName: t(
                    "Rental.Services.Smartlocks.AddModal.Steps.Informations.lockCodeLabel"
                  ),
                }),
              },
            })}
            label={t(
              "Rental.Services.Smartlocks.AddModal.Steps.Informations.lockCodeLabel"
            )}
            placeholder={t(
              "Rental.Services.Smartlocks.AddModal.Steps.Informations.lockCodePlaceholder"
            )}
            required={informationsStaticRequiredFields.code}
            value={informationsManualValues("code")}
            error={informationsManualErrors.code?.message}
          />
        </>
      ) : (
        <>
          <Controller
            control={informationsControl}
            name="lockId"
            rules={{
              required: informationsRequiredFields.lockId,
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <InputSelect
                  required={informationsRequiredFields.lockId}
                  label={`${t(
                    "Rental.Services.Smartlocks.AddModal.Steps.Informations.lockIdLabel"
                  )}`}
                  items={lockIdItems ?? []}
                  selectedValue={value ?? ""}
                  onSelect={onChange}
                  disabled={validationLoading}
                  error={informationsErrors.lockId?.message}
                />
              );
            }}
          />

          <TextInput
            register={informationsRegister("lockName", {
              required: {
                value: informationsRequiredFields.lockName,
                message: t("Global.Errors.requiredField", {
                  fieldName: t(
                    "Rental.Services.Smartlocks.AddModal.Steps.Informations.lockNameLabel"
                  ),
                }),
              },
              onChange: (e) => updateLockDynamicField(e.target.value),
            })}
            label={t(
              "Rental.Services.Smartlocks.AddModal.Steps.Informations.lockNameLabel"
            )}
            placeholder={t(
              "Rental.Services.Smartlocks.AddModal.Steps.Informations.lockNamePlaceholder"
            )}
            required={informationsRequiredFields.lockName}
            value={informationsValues("lockName")}
            error={informationsErrors.lockName?.message}
          />

          {!informationsErrors.lockName ? (
            <LockDynamicFieldInfo type="create" />
          ) : null}

          <div className="flex space-x-4">
            <div className="flex-1">
              <Controller
                control={informationsControl}
                name="checkinTime"
                rules={{
                  required: informationsRequiredFields.checkinTime,
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <InputSelect
                      required={informationsRequiredFields.checkinTime}
                      label={`${t(
                        "Rental.Services.Smartlocks.AddModal.Steps.Informations.checkinTimeLabel"
                      )}`}
                      items={timeList}
                      selectedValue={value ?? ""}
                      onSelect={onChange}
                      disabled={true}
                      error={informationsErrors.checkinTime?.message}
                    />
                  );
                }}
              />
            </div>

            <div className="flex-1">
              <Controller
                control={informationsControl}
                name="checkoutTime"
                rules={{
                  required: informationsRequiredFields.checkoutTime,
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <InputSelect
                      required={informationsRequiredFields.checkoutTime}
                      label={`${t(
                        "Rental.Services.Smartlocks.AddModal.Steps.Informations.checkoutTimeLabel"
                      )}`}
                      items={timeList}
                      selectedValue={value ?? ""}
                      onSelect={onChange}
                      disabled={true}
                      error={informationsErrors.checkoutTime?.message}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div>
            <p className="mb-2 font-light text-low-contrast">
              <Trans
                i18nKey={
                  "Rental.Services.Smartlocks.AddModal.Steps.Informations.description"
                }
                components={{
                  strong: (
                    <span className="font-bold text-high-contrast"></span>
                  ),
                }}
              />
            </p>
            <p className="font-light text-low-contrast">
              {t(
                "Rental.Services.Smartlocks.AddModal.Steps.Informations.description2"
              )}
            </p>
          </div>
        </>
      )}

      <div className="w-full">
        <Button
          RightIcon={
            loginForm.getValues("lockProvider") === "static"
              ? CheckIcon
              : ArrowRightWhiteIcon
          }
          onClick={
            loginForm.getValues("lockProvider") === "static"
              ? informationsStaticHandleSubmit(() =>
                  onNextStep({ which: "automated_messages" })
                )
              : informationsHandleSubmit(() =>
                  onNextStep({ which: "automated_messages" })
                )
          }
          loading={validationLoading}
          disabled={
            validationLoading ||
            (loginForm.getValues("lockProvider") === "static"
              ? !informationsManualIsValid
              : !informationsIsValid)
          }
        >
          {loginForm.getValues("lockProvider") === "static"
            ? t(
                "Rental.Services.Smartlocks.AddModal.Steps.Informations.addLockCode"
              )
            : t(
                "Rental.Services.Smartlocks.AddModal.Steps.Informations.addLock"
              )}
        </Button>
      </div>
    </div>
  );
};
export const useSmartLocksAutomatedMessagesStep = () => {
  const {
    t,
    navigate,
    validationLoading,
    LockItem,
    LockDynamicFieldInfo,
    loginForm,
    staticLoginForm,
    informationsForm,
    informationsManualForm,
    onCloseAddModal,
  } = useSmartlocksContext();

  const getLockIcon = () => {
    if (loginForm.getValues("lockProvider").toLowerCase() === "static") {
      return <KeyHoleIcon className="size-7" />;
    } else if (
      loginForm.getValues("lockProvider").toLowerCase() ===
      SmartLocksEnum.IGLOOHOMEV2.toLowerCase()
    ) {
      return <IgloohomeRenderIcon className="size-7" />;
    } else if (
      loginForm.getValues("lockProvider").toLowerCase() ===
      SmartLocksEnum.NUKI.toLowerCase()
    ) {
      return <NukiRoundedIcon className="size-7" />;
    } else if (
      loginForm.getValues("lockProvider").toLowerCase() ===
      SmartLocksEnum.TTLOCK.toLowerCase()
    ) {
      return <Ttlock2Icon className="size-7" />;
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <p className="font-light text-low-contrast">
        {loginForm.getValues("lockProvider") === "static"
          ? t(
              "Rental.Services.Smartlocks.AddModal.Steps.AutomatedMessages.manualDescription"
            )
          : t(
              "Rental.Services.Smartlocks.AddModal.Steps.AutomatedMessages.description"
            )}
      </p>

      <div className="flex items-center p-2 rounded gap-x-2 bg-subtle border-1 border-element-border">
        {getLockIcon()}
        <div className="flex flex-col space-y-2">
          <p className="font-bold text-high-contrast">
            {loginForm.getValues("lockProvider") === "static"
              ? staticLoginForm.getValues("name")
              : informationsForm.getValues("lockName")}
          </p>
          {loginForm.getValues("lockProvider") !== "static" ? (
            <p className="text-low-contrast">
              {informationsForm.getValues("lockId")}
            </p>
          ) : null}
        </div>
      </div>

      <LockDynamicFieldInfo type="created" />

      <p className="font-light text-low-contrast">
        {t(
          "Rental.Services.Smartlocks.AddModal.Steps.AutomatedMessages.description2"
        )}
      </p>

      <div className="flex flex-col w-full space-y-4">
        {/** TODO: [Smartlocks] To update with the real video */}
        <iframe
          src="https://player.vimeo.com/video/826078811?h=6f93f38bc5"
          height="250"
          allow="autoplay; fullscreen"
        ></iframe>
        <Link to={paths.AUTO_MESSAGES} target="_blank">
          <Button type="secondary" RightIcon={ArrowRightIcon}>
            {t(
              "Rental.Services.Smartlocks.AddModal.Steps.AutomatedMessages.ctaAutomatedMessages"
            )}
          </Button>
        </Link>
        <Button
          RightIcon={CheckIcon}
          loading={validationLoading}
          onClick={() => {
            onCloseAddModal();
            navigate(0);
          }}
        >
          {t("Global.finish")}
        </Button>
      </div>
    </div>
  );
};
