import React from "react";
import { ClassNameValue } from "tailwind-merge";
import { cn } from "../../../../helpers/classHelper";
import { ChildrenType } from "../../../../types/commonTypes";

export const RentalPlatformsCardElement: React.FC<{
  children: ChildrenType;
  classNames?: {
    container?: ClassNameValue;
  };
  onClick?: () => void;
}> = ({ children, classNames, onClick = () => {} }) => {
  return (
    <div
      className={cn(
        `flex items-center w-full h-10 gap-2 px-2 cursor-pointer bg-element-background rounded-6px border-1 border-element-border hover:bg-element-background-active hover:border-element-border-hover `,
        classNames?.container
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
