import React from "react";
import { ProgressionStep } from "../../../pages/Progression/ProgressionStep";
import { useTranslation } from "react-i18next";
import { Button } from "../../Common/Button/Button";
import { ProgressionStepStripeConnectProps,
} from "../Progression.type";
import CheckIcon from "../../../assets/icons/plus-white.svg?react";


export const ProgressionConnectStripe: React.FC<ProgressionStepStripeConnectProps> = ({
  status,
  visible,
  number,
  error,
  loading,
  currentProgression,
  onStripeClick,
  onNextClick
}) => {
  const { t } = useTranslation();

  if (!visible) return null;
//disabled={currentProgression.status === ProgressionStatusEnum.PENDING}
  return (
      <ProgressionStep
          number={number.toString()}
          title={t("Progression.ConnectStripe.title")}
          status={status}
          error={error}
          loading={loading}
      >
        <p className="font-light text-low-contrast">
          {t("Progression.ConnectStripe.content")}
        </p>

        <div className="flex gap-x-3 mt-2">
          <Button
              RightIcon={CheckIcon}
              onClick={()=> onStripeClick()}
          >
            {t("Progression.ConnectStripe.addStripe")}
          </Button>
          <Button type="secondary"  onClick={()=> onNextClick()}>
            {t("Progression.ConnectStripe.nextStep")}
          </Button>
        </div>
      </ProgressionStep>
  );
};
