import {
  DndContext,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useEffect, useState } from "react";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { PagingButtons } from "../PagingButtons/PagingButtons";
import { TableListProps } from "./TableList.type";

export const TableList: React.FC<TableListProps> = ({
  tablePage,
  filterTotal,
  i18nElement,
  Filters,
  ViewTypeGroupButton,
  Header,
  Item,
  Skeleton,
  NoData,
  selectedItems = [],
  draggableItem = false,
  disabled,
  onClick,
  onClickAction,
  onChangeOrder,
}) => {
  const {
    error,
    loading,
    currentSortField,
    currentSortDirection,
    handleChangeSort,
    currentNbPerPage,
    currentPage,
    total,
    lastPage,
    handleChangeNbPerPage,
    handlePaginationBack,
    handlePaginationNext,
    currentSearch,
    data,
  } = tablePage;

  const [newData, setNewData] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      setNewData(data);
    }
  }, [data]);

  const getAreAllSelected = () => {
    const allIds = data.map((d: any) => d.id);
    return allIds.every((id: any) =>
      selectedItems.some((itemId: ValueType) => itemId === id)
    );
  };

  const handleSelectAll = () => {
    if (getAreAllSelected()) {
      // Si tout est selectionnÃ©, on envoie un Ã©vÃ¨nement de Click sur tous les Ã©lÃ©ments, comme Ã§a Ã§a les dÃ©selectionne
      data?.forEach((d: any) => {
        onClickAction && onClickAction(d.id, "select");
      });
    } else {
      // sinon, on envoie un Ã©vÃ¨nement de Click sur tous les Ã©lÃ©ments non sÃ©lectionnÃ©s pour qu'ils soient tous sÃ©lectionnÃ©
      data?.forEach((d: any) => {
        const exists = selectedItems?.findIndex((i) => i === d.id) > -1;

        if (!exists) onClickAction && onClickAction(d.id, "select");
      });
    }
  };

  // * -- Draggable items logic --
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const getDataPosition = (id: number) =>
    newData && newData.findIndex((item: any) => item.id === id);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id === over.id) return;

    setNewData((prevData: any) => {
      const originalPosition = getDataPosition(active.id);
      const newPosition = getDataPosition(over.id);

      const nextData: any[] = arrayMove(
        prevData,
        originalPosition,
        newPosition
      );

      if (prevData.length > 0 && draggableItem && nextData) {
        const nextRentalIds: string = nextData
          .map((item: any) => item.id)
          .join(",");
        if (typeof onChangeOrder === "function") {
          onChangeOrder(nextRentalIds);
        }
      }

      return arrayMove(prevData, originalPosition, newPosition);
    });
  };

  if (error && error !== "") return <ErrorMessage>{error}</ErrorMessage>;

  if (!loading && total === 0 && data.length === 0 && currentSearch === "")
    return <div>{NoData}</div>;

  return (
    <>
      {Filters}

      <div className="mt-4">
        {ViewTypeGroupButton}

        <table className="w-full border-separate table-auto overflow rounded-8px border-1 border-element-border border-spacing-0">
          <Header
            sortField={currentSortField}
            sortDirection={currentSortDirection}
            areAllSelected={loading ? false : getAreAllSelected()}
            totalItems={tablePage.total!}
            disabled={disabled}
            onChangeSort={handleChangeSort}
            onSelectAll={handleSelectAll}
          />

          {loading &&
            Array.from({ length: currentNbPerPage }).map((v, index: number) => (
              <Skeleton key={index} />
            ))}
          {draggableItem && newData.length > 1 ? (
            <>
              {!loading && (
                <DndContext
                  sensors={sensors}
                  onDragEnd={handleDragEnd}
                  collisionDetection={closestCorners}
                >
                  <tbody>
                    <SortableContext
                      items={newData}
                      strategy={verticalListSortingStrategy}
                    >
                      {newData.map((d: any) => (
                        <Item
                          key={d.id}
                          data={d}
                          isSelected={
                            selectedItems?.findIndex((i) => i === d.id) > -1
                          }
                          disabled={disabled}
                          draggableItem={draggableItem && newData.length > 1}
                          onClick={() => onClick && onClick(d.id)}
                        />
                      ))}
                    </SortableContext>
                  </tbody>
                </DndContext>
              )}
            </>
          ) : (
            <>
              {!loading &&
                newData.map((d: any) => (
                  <Item
                    key={d.id}
                    data={d}
                    isSelected={
                      selectedItems?.findIndex((i) => i === d.id) > -1
                    }
                    disabled={disabled}
                    onClick={() => onClick && onClick(d.id)}
                    onClickAction={onClickAction}
                  />
                ))}
            </>
          )}
        </table>
      </div>

      <PagingButtons
        i18nElement={i18nElement}
        loading={loading}
        nbPerPage={currentNbPerPage}
        page={currentPage}
        total={filterTotal ?? total}
        lastPage={lastPage}
        onChangeNbPerPage={handleChangeNbPerPage}
        onPaginationBack={handlePaginationBack}
        onPaginationNext={handlePaginationNext}
      />
    </>
  );
};
