import React from "react";
import { RegisterMemberInfosForm } from "./RegisterMemberStepInfos.type";
import { RegisterMemberPasswordForm } from "./RegisterMemberStepPassword.type";
import { Loader } from "../Common/Loader/Loader";
import { RegisterMemberStepInfos } from "./RegisterMemberStepInfos";
import { RegisterMemberStepPassword } from "./RegisterMemberStepPassword";

export const RegisterMemberSteps: React.FC<{
  loadingInvit: boolean;
  loading: boolean;
  error: string | null;
  currentStep: "infos" | "password";
  stepData: {
    infos: RegisterMemberInfosForm;
    password: RegisterMemberPasswordForm;
  };
  onBackToInfos: () => void;
  onSubmitStepInfos: (values: RegisterMemberInfosForm) => void;
  onSubmitStepPassword: (values: RegisterMemberPasswordForm) => void;
}> = ({
  loading,
  loadingInvit,
  error,
  currentStep,
  stepData,
  onBackToInfos,
  onSubmitStepInfos,
  onSubmitStepPassword,
}) => {
  if (loadingInvit)
    return (
      <div className="flex w-full h-screen items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <div className="flex-1 px-12 py-8">
      {currentStep === "infos" && (
        <RegisterMemberStepInfos
          infos={stepData.infos}
          onNextStep={onSubmitStepInfos}
        />
      )}
      {currentStep === "password" && (
        <RegisterMemberStepPassword
          loading={loading}
          error={error}
          onBack={onBackToInfos}
          onSubmit={onSubmitStepPassword}
        />
      )}
    </div>
  );
};
