import React from "react";
import { useTranslation } from "react-i18next";
import EmojiSadIcon from "../../assets/icons/emoji-sad.svg?react";
import MessageIcon from "../../assets/icons/message.svg?react";
import ArrowRightIcon from "../../assets/icons/arrow-right-white.svg?react";
import { Button } from "../Common/Button/Button";
import { Link } from "react-router-dom";
import paths from "../../constants/paths";
import { FullScreenLayout } from "../Layout/FullScreenLayout/FullScreenLayout";
import Carousel1Image from "../../assets/images/register-carousel-1.svg?react";
import Carousel2Image from "../../assets/images/register-carousel-2.svg?react";
import Carousel3Image from "../../assets/images/register-carousel-3.svg?react";
import SuperhoteIcon from "../../assets/icons/superhote.svg?react";
import { Carousel } from "../Common/Carousel/Carousel";

export const RegisterMemberAccessDenied = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="hidden md:block w-full h-full">
        <FullScreenLayout isCheckAuthEnabled={false}>
          {/* Left */}
          <div className="w-2/5 max-w-md p-2">
            <div className="flex flex-col min-h-full bg-subtle border-1 border-element-border/50 p-8 rounded-6px">
              <div className="flex-1 pb-4">
                <SuperhoteIcon />
              </div>

              <div>
                <Carousel
                  items={[
                    {
                      Image: Carousel1Image,
                      title: t("Register.carousel1Title"),
                      subTitle: t("Register.carousel1SubTitle"),
                    },
                    {
                      Image: Carousel2Image,
                      title: t("Register.carousel2Title"),
                      subTitle: t("Register.carousel2SubTitle"),
                    },
                    {
                      Image: Carousel3Image,
                      title: t("Register.carousel3Title"),
                      subTitle: t("Register.carousel3SubTitle"),
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          {/* Left */}
          <AccessDeniedContent />
        </FullScreenLayout>
      </div>

      {/* Mobile view */}
      <div className="flex flex-col md:hidden w-full h-full">
        <div className="px-12 pt-8">
          <SuperhoteIcon />
        </div>

        <AccessDeniedContent />
      </div>
    </>
  );
};

const AccessDeniedContent = () => {
  const { t } = useTranslation();

  return (
    <div className="flex px-12 py-8 justify-center items-center flex-col space-y-5">
      <EmojiSadIcon className="size-12" />

      <h1 className="block m-0 py-2 font-bold text-xxl">
        {t("RegisterMember.Denied.title")}
      </h1>

      <h1 className="block m-0 pb-2 font-extralight text-secondary text-base text-center">
        {t("RegisterMember.Denied.content1")}
      </h1>
      <h1 className="block m-0 pb-2 font-extralight text-secondary text-base text-center">
        {t("RegisterMember.Denied.content2")}
      </h1>
      <div className="flex mt-4 gap-4">
        <div className="w-72">
          <Link to={paths.HELP}>
            <Button type="secondary" onClick={() => {}} RightIcon={MessageIcon}>
              {t("RegisterMember.Denied.contact")}
            </Button>
          </Link>
        </div>

        <div className="w-72">
          <Link to={paths.LOGIN}>
            <Button
              onClick={() => console.log("Connect platform")}
              RightIcon={ArrowRightIcon}
            >
              {t("RegisterMember.Denied.login")}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
