import React from "react";
import { TextInput } from "../Common/TextInput/TextInput";
import { TextAreaInput } from "../Common/TextAreaInput/TextAreaInput";
import { useTranslation } from "react-i18next";

export const AutoMessageUpsertContentItem: React.FC<{
  object: string;
  message: string;
  required: boolean;
  error?: string | undefined;
  onChangeObject: (value: string) => void;
  onChangeMessage: (value: string) => void;
  onFocusObject: () => void;
  onFocusMessage: () => void;
  onChangeCursor: (index: number) => void;
}> = ({
  object,
  message,
  required,
  error,
  onChangeMessage,
  onChangeObject,
  onFocusMessage,
  onFocusObject,
  onChangeCursor,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col gap-1">
        <TextInput
          label={t("AutoMessageList.Upsert.Contents.subject")}
          placeholder={t("AutoMessageList.Upsert.Contents.subjectPlaceholder")}
          value={object}
          onChangeText={onChangeObject}
          onFocus={onFocusObject}
          onSelect={(event) => onChangeCursor(event.target.selectionStart)}
        />
        <p className="text-low-contrast font-light">
          {t("AutoMessageList.Upsert.Contents.subjectInfos")}
        </p>
      </div>

      <TextAreaInput
        label={t("AutoMessageList.Upsert.Contents.message")}
        placeholder={t("AutoMessageList.Upsert.Contents.messagePlaceholder")}
        required={required}
        error={error}
        value={message}
        onTextChange={onChangeMessage}
        onFocus={onFocusMessage}
        onSelect={(event) => onChangeCursor(event.target.selectionStart)}
      />
    </>
  );
};
