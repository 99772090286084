import React from "react";
import { useTranslation } from "react-i18next";
import MonitorIcon from "../../../../../src/assets/icons/monitor.svg?react";
import { PlatformColorList } from "../../../../constants/colors";
import { Button } from "../../../Common/Button/Button";
import { CenterModal } from "../../../Common/CenterModal/CenterModal";
import { PlatformSyncLoader } from "../../../Common/PlatformSyncLoader/PlatformSyncLoader";
import { ProgressionBar } from "../../../Common/ProgressionBar/ProgressionBar";
import { BookingRulesSyncWaitingModalProps } from "../../../Progression/Progression.type";

export const BookingRulesSyncWaitingModal: React.FC<
  BookingRulesSyncWaitingModalProps
> = ({ isVisible, progression, progressText = "", headerStatus, close }) => {
  const { t } = useTranslation();
  const header = () => {
    return headerStatus !== "success" ? (
      <PlatformSyncLoader
        platformLeft={{
          icon: MonitorIcon,
          color: PlatformColorList.Airbnb,
        }}
        type={"new"}
      />
    ) : (
      <></>
    );
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={() => {}}
      CustomHeader={header()}
      header={headerStatus}
    >
      {headerStatus != "success" ? (
        <>
          <p className="text-xl font-semibold text-high-contrast">
            {t("Progression.RentalsRules.Sync.title")}
          </p>

          <p className="mt-2 text-sm font-light text-low-contrast">
            {t("Progression.bookingConnectStep1Content5")}
          </p>

          <p className="mt-2 text-sm font-light text-low-contrast">
            {t("Progression.bookingConnectStep1Content6")}
          </p>
          <div className="mt-4">
            <ProgressionBar progression={progression} />
          </div>
          <p className="mt-2 text-sm font-light text-secondary">
            {t("Progression.bookingConnectStep1Content7")}
          </p>
        </>
      ) : (
        <div className="flex flex-col">
          <p className="text-xl font-semibold text-high-contrast">
            {t("Progression.bookingConnectStep1Content8")}
          </p>

          <p className="mt-2 text-sm font-light text-low-contrast">
            {t("Progression.bookingConnectStep1Content9")}
          </p>
          <div className="w-full mt-4">
            <Button
              type="primary"
              onClick={() => {
                close();
              }}
            >
              {t("Progression.ok")}
            </Button>
          </div>
        </div>
      )}
    </CenterModal>
  );
};
