import { Platform } from "../Platform";
import React from "react";
import { useTranslation } from "react-i18next";
import { PlatFormDetailsBodyProps } from "../../../pages/PlatForms/Platform.type";

export const PlatFormDetailsBody = ({
  modal,
  pageData,
  page,
  children,
  isConnected,
}: PlatFormDetailsBodyProps) => {
  const { t } = useTranslation();

  const handleClickButtonConnect = () => {
    if (pageData.isRedirectButton) {
      pageData.onClickButton();
    } else {
      modal.open();
    }
  };

  return (
    <>
      {!pageData.isRedirectButton && (
        <pageData.ModalConnect
          isVisible={modal.isVisible}
          onClose={modal.close}
        />
      )}
      <Platform
        Icon={pageData?.Icon}
        title={pageData?.title}
        description={pageData?.description}
        page={page}
        isLongDescription={true}
        button={true}
        buttonName={pageData?.buttonName}
        longDescription={pageData?.longDescription}
        onClickButton={handleClickButtonConnect}
        isConnected={isConnected}
      />
      {children}
      <section className={"grid grid-cols-2 mt-10 gap-10"}>
        <div
          className={
            "bg-gray-50 border border-gray-300 rounded-xl p-4 flex flex-col justify-between"
          }
        >
          <div>
            <h1 className={"font-bold text-lg"}>
              {pageData?.step.howToConnect}
            </h1>
            <p className={"font-bold text-lg text-gray-500 w-1/2 mt-4"}>
              {pageData?.step?.titleTuto}
            </p>
            <ol className="list-decimal pl-6 mt-4 text-md text-gray-500 flex flex-col gap-2 mb-40">
              <li>
                {pageData?.step.stepOne?.split("\n").map((line, index) => (
                  <div
                    key={`stepOne-${index}`}
                    style={{ marginLeft: `${line.split("\t").length - 1}rem` }}
                  >
                    {line.replace(/\t/g, "")}
                  </div>
                ))}
              </li>
              <li>
                {pageData?.step.stepTwo?.split("\n").map((line, index) => (
                  <div
                    key={`stepTwo-${index}`}
                    style={{ marginLeft: `${line.split("\t").length - 1}rem` }}
                  >
                    {line.replace(/\t/g, "")}
                  </div>
                ))}
              </li>
              <li>
                {pageData?.step.stepThree?.split("\n").map((line, index) => (
                  <div
                    key={`stepThree-${index}`}
                    style={{ marginLeft: `${line.split("\t").length - 1}rem` }}
                  >
                    {line.replace(/\t/g, "")}
                  </div>
                ))}
              </li>
              {pageData?.step.stepFour && (
                <li>
                  {pageData.step.stepFour.split("\n").map((line, index) => (
                    <div
                      key={`stepFour-${index}`}
                      style={{
                        marginLeft: `${line.split("\t").length - 1}rem`,
                      }}
                    >
                      {line.replace(/\t/g, "")}
                    </div>
                  ))}
                </li>
              )}
              {pageData?.step.stepFive && (
                <li>
                  {pageData.step.stepFive.split("\n").map((line, index) => (
                    <div
                      key={`stepFive-${index}`}
                      style={{
                        marginLeft: `${line.split("\t").length - 1}rem`,
                      }}
                    >
                      {line.replace(/\t/g, "")}
                    </div>
                  ))}
                </li>
              )}
            </ol>
          </div>
          <a href={""} className={"text-red-500"}>
            {t("ThirdParty.tutoFound")}
          </a>
        </div>
        <div
          className={
            "bg-gray-50 border border-gray-300 rounded-xl p-4 h-[500px]"
          }
        >
          <h1 className={"font-bold text-lg"}>{t("ThirdParty.tutoVideo")}</h1>
          <iframe
            src={pageData?.step.videoUrl}
            width="100%"
            height="90%"
            allow="fullscreen"
            className={"mt-4 rounded-xl self-start"}
          ></iframe>
        </div>
      </section>
    </>
  );
};
