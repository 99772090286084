// * This method checks a custom string type
export const isCustomStringType = <T extends string>(
  value: string | null,
  typeChecker: (val: string) => val is T
): value is T => {
  return value !== null ? typeChecker(value) : false;
};

export const isValueInEnum = (value: any, enumType: any): boolean => {
  return Object.values(enumType).includes(value);
};
