import React from "react";
import { NotificationStatusesListItemResponse } from "../../../types/GETTypes";
import { Button } from "../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  capitalizeFirstLetter,
  generateSentenceForPlatforms,
} from "../../../helpers/stringHelper";

import DotsVerticalIcon from "../../../assets/icons/dots-vertical.svg?react";
import EyeSolidIcon from "../../../assets/icons/eye-solid.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import CloseCircleRedIcon from "../../../assets/icons/close-circle-red.svg?react";
import momentTimezone from "moment-timezone";

export const ScheduledMessagePending: React.FC<{
  message: NotificationStatusesListItemResponse;
  withActionButtons: boolean;
  onView: (message: NotificationStatusesListItemResponse) => void;
  onEdit: (message: NotificationStatusesListItemResponse) => void;
  onCancel: (message: NotificationStatusesListItemResponse) => void;
  guest_email: string;
}> = ({
  message,
  withActionButtons,
  onView = () => {},
  onCancel = () => {},
  onEdit = () => {},
  guest_email,
}) => {
  const { t } = useTranslation();

  const handleClickDrop = (action: string) => {
    if (action === "cancel") onCancel(message);
    else if (action === "edit") onEdit(message);
  };

  const currentTimeZone = momentTimezone.tz.guess();
  const messageDate = moment.utc(message.ready_at)
  .tz(currentTimeZone)

  return (
    <div className="flex flex-row gap-2 justify-between">
      <div className="flex flex-rown gap-2">
        <div className="flex flex-col items-center">
          <div
            className={`w-5 h-5 shrink-0 rounded-full mt-2 border-2 border-element-border-active`}
          ></div>
          <div className="border-1 border-element-border h-full w-0 rounded-2px my-1"></div>
        </div>

        <div className="mt-2">
          <p className="font-semibold text-high-contrast">
            {message.notification.name}
          </p>

          <p className="font-light text-low-contrast mt-1">
            {`${capitalizeFirstLetter(
              t("ScheduledMessageList.scheduledTo")
            )} ${capitalizeFirstLetter(messageDate.format("dddd D MMM YYYY [à] HH:mm"))} ${generateSentenceForPlatforms(message.channels)}`}
            {generateSentenceForPlatforms(message.channels) === "Email" && {
              guest_email,
            }}
          </p>
        </div>
      </div>

      <div className="flex flex-row gap-1 mt-2">
        <Button
          type="secondary"
          size="small"
          RightIcon={EyeSolidIcon}
          onClick={() => onView(message)}
        >
          {t("ScheduledMessageList.see")}
        </Button>

        {withActionButtons && (
          <div className="w-10">
            <Button
              type="secondary"
              size="small"
              dropItems={[
                {
                  label: t("ScheduledMessageList.edit"),
                  value: "edit",
                  Icon: EditIcon,
                },
                {
                  label: t("ScheduledMessageList.cancel"),
                  value: "cancel",
                  Icon: CloseCircleRedIcon,
                  isRed: true,
                },
              ]}
              onClick={handleClickDrop}
              LeftIcon={DotsVerticalIcon}
            />
          </div>
        )}
      </div>
    </div>
  );
};
