import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CheckIcon from "../../assets/icons/check-white.svg?react";
import LockIcon from "../../assets/icons/lock.svg?react";
import FundedEuropeanUnionImage from "../../assets/images/funded_european_union.png";
import PciImage from "../../assets/images/pci.png";
import VerifiedImage from "../../assets/images/visa_verified.png";
import {Button} from "../../components/Common/Button/Button";
import {ErrorMessage} from "../../components/Common/ErrorMessage/ErrorMessage";
import {PaymentPolicyDepositPaymentOptionEnum} from "../../components/Payment/Payment.type";
import {PaymentDepositCheckoutFormProps} from "./PaymentDepositCheckoutForm.type";
import {PaymentDepositInfo} from "./PaymentDepositInfo";
import {PaymentReservationDepositStatusEnum} from "../../enums/GETenums";
import {Badge} from "../../components/Common/Badge/Badge";
import {CenterModal} from "../../components/Common/CenterModal/CenterModal";

export const PaymentDepositCheckoutForm: React.FC<
  PaymentDepositCheckoutFormProps
> = ({
  amount,
  billingDetails,
  option,
  icon,
  title,
  description,
  status,
  onValidatePayment,
  displayModalSuccessGuestPage = false,
}) => {
  const {t} = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null | undefined>(null);
  const [depositPaymentStatus, setDepositPaymentStatus] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      setError(t("Stripe.noStripe"));
      return;
    }

    const confirmed = await stripe.confirmPayment({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: billingDetails,
        },
      },
      redirect: "if_required",
    });
    if (confirmed.error) {
      setError(confirmed.error.message);
      setLoading(false);
    } else {
      setDepositPaymentStatus(confirmed?.paymentIntent?.status);
      setLoading(false);
      setSuccess(true);
    }
  };

  useEffect(() => {
    if (success && !displayModalSuccessGuestPage) {
      onValidatePayment &&
        onValidatePayment(PaymentReservationDepositStatusEnum.PAID);
    }
  }, [success]);

  return (
    <>
      {success && !displayModalSuccessGuestPage ? (
        <PaymentDepositInfo
          icon={icon}
          title={title}
          description={description}
          status={status}
        />
      ) : (
        <>
          <CenterModal
            isVisible={success && displayModalSuccessGuestPage}
            onClose={() =>
              onValidatePayment &&
              onValidatePayment(PaymentReservationDepositStatusEnum.PAID)
            }
            CustomHeader={
              <div className="bg-element-background-success h-32 flex flex-1 items-center justify-center relative rounded-t-16px">
                <div className="absolute cursor-pointer">
                  <Badge
                    status={"success"}
                    size="large"
                    customBg="bg-transparent"
                    iconSize={30}
                  />
                </div>
              </div>
            }
            classNames={{
              divParentModal: "w-full h-full",
              divModal: "md:ml-[20%] w-auto",
            }}
          >
            <h2 className="text-high-contrast font-bold">
              {t("GuestPage.PaymentModal.title")}
            </h2>

            <p className="text-low-contrast font-light text-sm mt-2">
              {t("GuestPage.PaymentModal.description")}
            </p>

            <div className="flex flex-row gap-2 pt-4">
              <Button
                onClick={() =>
                  onValidatePayment &&
                  onValidatePayment(PaymentReservationDepositStatusEnum.PAID)
                }
              >
                {t("GuestPage.PaymentModal.button")}
              </Button>
            </div>
          </CenterModal>
          <div className="w-full">
            <div className="flex flex-col justify-between w-full h-full p-6 rounded-lg bg-element-background">
              <h3 className="font-bold text-center text-xxl whitespace-pre-line">
                {option ===
                PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION
                  ? t("Payments.PaymentDeposit.PreAuthorization.depositTitle", {
                      amount: `\n ${amount?.toFixed(2)} ${t(
                        "Global.currencySymbol"
                      )}`,
                    })
                  : t("Payments.PaymentDeposit.depositTitle", {
                      amount: `\n ${amount?.toFixed(2)} ${t(
                        "Global.currencySymbol"
                      )}`,
                    })}
              </h3>

              <form>
                <PaymentElement
                  options={{
                    layout: "tabs",
                    terms: {
                      card: "never",
                    },
                  }}
                />
              </form>

              <ErrorMessage>{error}</ErrorMessage>

              <div className="mt-6 mb-3">
                <Button
                  RightIcon={CheckIcon}
                  onClick={handleSubmit}
                  disabled={!stripe}
                  loading={loading}
                  size="normal"
                >
                  {option ===
                  PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION
                    ? t(
                        "Payments.PaymentDeposit.PreAuthorization.validatePreAuthorisation"
                      )
                    : t("Global.payNow")}
                </Button>
              </div>

              <div className="flex items-center justify-center mt-3 space-x-2">
                <LockIcon width={25} height={25} />
                <p className="text-[14px] font-light text-low-contrast">
                  {t("Global.securedTransaction")}
                </p>
              </div>
              <div className="flex justify-center mt-4 space-x-6">
                <img
                  className="w-28"
                  src={VerifiedImage}
                  alt="Verified by VISA"
                />
                <img
                  className="w-28"
                  src={FundedEuropeanUnionImage}
                  alt="Funded by the European Union"
                />
                <img className="w-28" src={PciImage} alt="PCI DSS compliant" />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
