import React, { useEffect, useRef, useState } from "react";
import { MessagesArea } from "./MessagesArea";
import { post } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import { TemplatesModal } from "../../MessageList/TemplatesModal/TemplatesModal";
import { Template } from "../../MessageList/TemplatesModal/TemplatesModal.type";
import { insertTextAtPosition } from "../../../helpers/stringHelper";

import { convertBase64ToBlob } from "../../../helpers/fileHelper";
import { SendMessageArea } from "./SendMessageArea";
import { ViewAndSendMessagesProps } from "./ViewAndSendMessages.type";
import { MessageTemplatePhotoResponse } from "../../../types/GETTypes";

export const ViewAndSendMessages: React.FC<ViewAndSendMessagesProps> = ({
  tableMessages,
  loading,
  reservation,
  onLoadMessages,
  isNewReservationsList,
  numberOfConversations
}) => {
  const bottomElement = useRef(null);

  const [isTemplatesVisible, setTemplatesVisible] = useState<boolean>(false);

  const [contentCursorPosition, setContentCursorPosition] = useState<number>(0);

  const [type, setType] = useState<
    "airbnb" | "booking" | "email" | "whatsapp" | "sms" | ""
  >("");
  const [content, setContent] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [loadingSend, setLoadingSend] = useState<boolean>(false);
  const [errorSend, setErrorSend] = useState<string | null>(null);
  const [messages, setMessages] = useState<any[]>([]);
  const [photos, setPhotos] = useState<Blob[]>([]);

  useEffect(() => {
    setErrorSend(null);

    if (reservation?.platform?.name?.toUpperCase() === "AIRBNB.COM") {
      setType("airbnb");
    } else if (reservation?.platform?.name?.toUpperCase() === "BOOKING.COM") {
      setType("booking");
    } else {
      setType("email");
    }
  }, [reservation]);

  useEffect(() => {
    setMessages(tableMessages.data);
  }, [tableMessages]);

  const handleChangeContent = (text: string) => {
    setContent(text);
  };

  const handleChangePhotos = (values: Blob[]) => {
    setPhotos(values);
  };

  const handleChangeCursor = (event: any) => {
    setContentCursorPosition(event.target.selectionStart);
  };

  const handleSend = async () => {
    setErrorSend(null);

    if (type === "whatsapp") {
      goToWhatsAppURL();
      return;
    }


    setLoadingSend(true);

    const form = new FormData();
    form.append("reservation_id", reservation?.id.toString() ?? "");
    form.append(
      "type",
      type === "booking" || type === "airbnb" ? "platform" : type
    );
    if (content !== "") form.append("content", content);

    photos.forEach((photo, i) => {
      form.append(`photos[${i}]`, photo);
    });

    form.append("display_name",messages.length > 0 ?  messages[0].display_name === null ? "": messages[0].display_name  : "")

    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.SEND_MESSAGE}`,
      form
    );

    if (response.data?.success) {
      setLoadingSend(false);
      setContent("");
      setPhotos([]);
      setMessages((prev) => [...prev, response?.data?.result]);
    } else {
      setErrorSend(response?.response?.data.message);
      setLoadingSend(false);
    }
  };

  const goToWhatsAppURL = () => {
    if (!reservation?.guest?.phone) return;
    let url = "https://wa.me/";

    const cleanPhone = reservation?.guest?.phone
      .replace("+", "")
      .replace(")", "")
      .replace("(", "")
      .replace("-", "")
      .replace(/^0/, "")
      .replace(/^00/, "")
      .replace(/./g, "")
      .replace(/ /g, "");

    url += cleanPhone;

    if (content && content !== "") {
      url += `?text=${encodeURI(content)}`.replaceAll("#", "%23");
    }

    window.open(url, "_blank");
  };

  const handleInsertTemplate = (template: Template) => {
    reformat({content: template.message?.toString() ?? "", reservation_id: reservation?.id}).then((response) => {
      setContent(
          insertTextAtPosition(
              content,
              response.data.content ??  "",
              contentCursorPosition
          )
      );
    })

    const newPhotos: Blob[] = template?.photos?.map(
      (photo: MessageTemplatePhotoResponse) => {
        return convertBase64ToBlob(photo);
      }
    );

    setPhotos([...photos, ...newPhotos]);
  };

  const reformat = async  (data: any) => {
    const response = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.GET_SHORT_CODES_REPLACED_TEXT}`,
        data
    );

    return response;
  }

  return (
    <>
      <TemplatesModal
        isVisible={isTemplatesVisible}
        onInsert={handleInsertTemplate}
        onClose={() => setTemplatesVisible(false)}
      />

      <div className="flex flex-col overflow-y-scroll w-full border-r-1 border-element-border h-full">
        {/* Messages */}
        <MessagesArea
          bottomElement={bottomElement}
          loading={loading || tableMessages.loading}
          messages={messages}
          reservation={reservation}
          isLastPage={
            tableMessages.currentPage >= (tableMessages.lastPage || 0)
          }
          onLoad={onLoadMessages}
          isNewReservationsList={isNewReservationsList}
          numberOfConversations={numberOfConversations}
        />

        {
          !isNewReservationsList && (
                <SendMessageArea
                    reservation={reservation}
                    photos={photos}
                    content={content}
                    errorSend={errorSend}
                    loading={loading}
                    loadingSend={loadingSend}
                    type={type}
                    onChangeContent={handleChangeContent}
                    onChangeCursor={handleChangeCursor}
                    onChangeType={(value) => setType(value)}
                    onClickTemplate={() => setTemplatesVisible(true)}
                    onSend={handleSend}
                    onChangePhotos={handleChangePhotos}
                />
            )
        }

      </div>
    </>
  );
};
