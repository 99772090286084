import React, { useEffect } from "react";
import { ManagementLayout } from "../../components/Layout/ManagementLayout/ManagementLayout";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../components/Common/TextInput/TextInput";
import { Button } from "../../components/Common/Button/Button";
import SearchIcon from "../../assets/icons/search.svg?react";
import PlusWhiteIcon from "../../assets/icons/plus-white.svg?react";
import { useTablePage } from "../../hooks/useTablePage";
import paths from "../../constants/paths";
import { TableList } from "../../components/Common/TableList/TableList";
import { ManagementOwnerTableHeader } from "../../components/Management/ManagementOwnerList/ManagementOwnerTableHeader";
import { ManagementOwnerTableItem } from "../../components/Management/ManagementOwnerList/ManagementOwnerTableItem";
import { ManagementOwnerTableNoItem } from "../../components/Management/ManagementOwnerList/ManagementOwnerTableNoItem";
import { ManagementOwnerTableSkeleton } from "../../components/Management/ManagementOwnerList/ManagementOwnerTableSkeleton";

export const ManagementOwnerListPage = () => {
  const { t } = useTranslation();

  // Use the real API URL
  const ownersTable = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.RENTALS}`,
    "rentals"
  );

  useEffect(() => {
    ownersTable.fetch({});
  }, []);

  return (
    <>
      <ManagementLayout subTitle="owners" sidebarActiveSubItem="teams">
        {/* Search and add bar */}
        <div className="flex flex-row gap-4 items-center mt-4">
          <TextInput
            Icon={SearchIcon}
            size="large"
            placeholder={t("Management.searchOwner")}
          />
          <div>
            <Button RightIcon={PlusWhiteIcon}>
              {t("Management.addOwner")}
            </Button>
          </div>
        </div>

        <TableList
          Header={ManagementOwnerTableHeader}
          Item={ManagementOwnerTableItem}
          NoData={<ManagementOwnerTableNoItem />}
          Skeleton={ManagementOwnerTableSkeleton}
          i18nElement="Management.owners"
          tablePage={ownersTable}
          onClick={() => {}}
          disabled={false}
        />
      </ManagementLayout>
    </>
  );
};
