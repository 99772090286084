import React from "react";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "../../../assets/icons/chevron-right.svg?react";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { MainLayout } from "../MainLayout/MainLayout";
import { SubscriptionLayoutNavbar } from "./Navbar/SubscriptionLayoutNavbar";
import { SubscriptionLayoutProps } from "./SubscriptionLayout.type";

export const SubscriptionLayout: React.FC<SubscriptionLayoutProps> = ({
  children,
  rightChildren,
  navbarActiveItem,
  error,
}) => {
  const { t } = useTranslation();
  const getTitle = () => {
    return (
      <p className="flex flex-row items-center gap-1">
        {t("Settings.title")} <ChevronRightIcon />
        {t("Settings.subscription")}
      </p>
    );
  };

  return (
    <MainLayout
      title={getTitle()}
      sidebarActiveItem="settings"
      sidebarActiveSubItem="subscription"
    >
      {/* Navbar */}
      <div className="flex flex-row items-center gap-2 pb-5 mt-3">
        <div className="flex-1">
          <SubscriptionLayoutNavbar activeItem={navbarActiveItem} />
        </div>

        {rightChildren ? <div>{rightChildren}</div> : null}
      </div>

      {error ? <ErrorMessage>{error}</ErrorMessage> : children}
    </MainLayout>
  );
};
