import React from "react";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import {Button} from "../../Common/Button/Button";
import LayoutBlockComponent from "../Common/LayoutBlockComponent";
import {GuestPageTab} from "../GuestPageHome/GuestPageHomeComponent";
import {useTranslation} from "react-i18next";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import {StepForm} from "../../../pages/PaymentReservation/PaymentReservationFormOrInfo";
import InvoiceComponent from "./InvoiceComponent";

const GuestPagePaymentInvoice = ({
  onChangeTab,
}: {
  onChangeTab: React.Dispatch<React.SetStateAction<StepForm>>;
}) => {
  const {t} = useTranslation();
  return (
    <div className="flex flex-col md:w-full bg-white md:bg-inherit">
      <Button
        LeftIcon={ArrowLeftIcon}
        type="secondary"
        displayLargeBtn={false}
        className="m-4 mt-8 md:mt-14 md:mb-2"
        buttonClassName="md:h-12"
        textClassName="md:text-xl"
        onClick={() => onChangeTab && onChangeTab("list")}
      >
        {t("GuestPage.ExtraHistorical.goBack")}
      </Button>
      <LayoutBlockComponent
        title={t("GuestPage.Invoice.title")}
        subTitle={t("GuestPage.Invoice.yourInvoice")}
        blockStyle={{
          parentDivStyle: "h-full",
          titleStyle: "text-[32px]",
          childrenDivStyle: "border-b-0 pl-2",
        }}
      >
        <InvoiceComponent />
        <div className="flex flex-col mb-8 md:flex-row md:self-end">
          {/* <div className="md:w-84 md:mr-2">
            <Button
              type="secondary"
              buttonClassName="my-2 md:mt-0 md:h-12"
              textClassName="md:text-xl"
              disabled
            >
              {t("GuestPage.Invoice.updateInfo")}
            </Button>
          </div>
          <div className="md:w-56 mt-4 md:mt-0">
            <Button
              type="primary"
              buttonClassName="mb-4 md:h-12"
              textClassName="md:text-xl"
              disabled
            >
              {t("GuestPage.Invoice.download")}
            </Button>
          </div> */}
        </div>
      </LayoutBlockComponent>
    </div>
  );
};

export default GuestPagePaymentInvoice;
