import {
  PaymentMethodCreateParams,
  Stripe,
  StripeElementsOptions,
  loadStripe,
} from "@stripe/stripe-js";
import { t } from "i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PaymentPolicyDepositPaymentOptionEnum } from "../../components/Payment/Payment.type";
import paths from "../../constants/paths";
import { PaymentReservationDepositStatusEnum } from "../../enums/GETenums";
import { capitalizeFirstLetter } from "../../helpers/stringHelper";
import { usePaymentDeposits } from "../../hooks/api/paymentDeposit";
import { PaymentDepositReservationLinkResponse } from "../../types/GETTypes";

export const useInitPaymentDepositStates = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [depositData, setDepositData] = useState<
    PaymentDepositReservationLinkResponse | undefined
  >();
  const [billingDetails, setBillingDetails] = useState<
    PaymentMethodCreateParams.BillingDetails | undefined
  >();
  const [stripePromise, setStripePromise] = useState<
    Promise<Stripe | null> | undefined
  >();
  const [stripeOptions, setStripeOptions] = useState<
    StripeElementsOptions | undefined
  >();

  return {
    loadingState: [loading, setLoading],
    depositDataState: [depositData, setDepositData],
    billingDetailsState: [billingDetails, setBillingDetails],
    stripePromiseState: [stripePromise, setStripePromise],
    stripeOptionsState: [stripeOptions, setStripeOptions],
  } as const;
};

export const useInitPaymentDeposit = (
  setLoading: Dispatch<SetStateAction<boolean>>,
  depositDataState: [
    PaymentDepositReservationLinkResponse | undefined,
    Dispatch<SetStateAction<PaymentDepositReservationLinkResponse | undefined>>
  ],
  stripePromiseState: [
    Promise<Stripe | null> | undefined,
    Dispatch<SetStateAction<Promise<Stripe | null> | undefined>>
  ],
  setStripeOptions: Dispatch<SetStateAction<StripeElementsOptions | undefined>>,
  setBillingDetails: Dispatch<
    SetStateAction<PaymentMethodCreateParams.BillingDetails | undefined>
  >,
  option: PaymentPolicyDepositPaymentOptionEnum | undefined
) => {
  const navigate = useNavigate();
  const params = useParams();

  const [depositData, setDepositData] = depositDataState;
  const [stripePromise, setStripePromise] = stripePromiseState;

  useEffect(() => {
    usePaymentDeposits(
      params,
      (paymentDeposits: PaymentDepositReservationLinkResponse | undefined) => {
        if (
          paymentDeposits?.deposit_schedules.payment_schedule
            .deposit_payment_status ===
          PaymentReservationDepositStatusEnum.CANCELED
        ) {
          navigate(`${paths.PAYMENTS_DEPOSITS}`);
        } else {
          setDepositData(paymentDeposits);
        }
      },
      (message: string | undefined) => {
        navigate(`${paths.DASHBOARD}`);
      },
      () => setLoading(true),
      () => setLoading(false)
    );
  }, []);

  useEffect(() => {
    // * Override the tab title
    if (option !== PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION) {
      document.title = t("Tab.depositTitle");
    }
  }, [option]);

  useEffect(() => {
    if (depositData) {
      if (
        !stripePromise &&
        depositData.deposit_schedules.payment_schedule.payments_schedule_items
          .length > 0
      ) {
        setStripePromise(
          loadStripe(import.meta.env.VITE_STRIPE_KEY, {
            stripeAccount:
              depositData.stripe_account_id,
          })
        );
      }
      setStripeOptions({
        locale: "fr",
        clientSecret: depositData.stripe_client_secret,
      });

      setBillingDetails({
        name: `${capitalizeFirstLetter(
          depositData.deposit_schedules.guest.first_name
        )} ${capitalizeFirstLetter(
          depositData.deposit_schedules.guest.last_name
        )}`,
        address: {
          city: capitalizeFirstLetter(depositData.deposit_schedules.guest.city),
          country: capitalizeFirstLetter(
            depositData.deposit_schedules.guest.country
          ),
        },
      });
    }
  }, [depositData]);
};
