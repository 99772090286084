import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { AutoMessageUpsertChannelsForm } from "./AutoMessage.type";
import { useTranslation } from "react-i18next";
import { Switch } from "../Common/Switch/Switch";
import AirbnbIcon from "../../assets/icons/airbnb.svg?react";
import BookingIcon from "../../assets/icons/booking.svg?react";
import { SimpleRadio } from "../Common/SimpleRadio/SimpleRadio";
import { Separator } from "../Common/Separator/Separator";

export const AutoMessageUpsertChannels: React.FC<{
  refButton: React.MutableRefObject<HTMLInputElement | undefined>;
  channels: AutoMessageUpsertChannelsForm | undefined;
  onNextStep: (values: AutoMessageUpsertChannelsForm) => void;
}> = ({ refButton, channels, onNextStep }) => {
  const { t } = useTranslation();
  const form = useForm<AutoMessageUpsertChannelsForm>({
    defaultValues: {
      Airbnb: { active: true, value: "platform" },
      Booking: { active: true, value: "platform" },
      Direct: { active: true, value: "email" },
    },
  });

  const handleNext = (values: AutoMessageUpsertChannelsForm) => {
    onNextStep(values);
  };

  useEffect(() => {
    if (channels) {
      form.setValue("Airbnb", channels.Airbnb);
      form.setValue("Booking", channels.Booking);
      form.setValue("Direct", channels.Direct);
    }
  }, [channels]);

  return (
    <form onSubmit={form.handleSubmit(handleNext)}>
      <div className="flex flex-col gap-0">
        <p className="text-low-contrast font-light">
          {t("AutoMessageList.Upsert.Channels.infos")}
        </p>

        {/* Airbnb */}
        <div className="flex flex-col gap-2 mt-3">
          <Controller
            control={form.control}
            name="Airbnb.active"
            render={({ field: { value, onChange } }) => (
              <>
                <div
                  className="flex justify-between cursor-pointer"
                  onClick={() => onChange(!value)}
                >
                  <div className="flex gap-2 items-center">
                    <p className="text-high-contrast font-semibold">
                      {t("AutoMessageList.Upsert.Channels.airbnbBooking")}
                    </p>

                    <AirbnbIcon />
                  </div>

                  <Switch value={value} />
                </div>

                {value === true && (
                  <div className="flex flex-col gap-2">
                    <p className="text-low-contrast font-light">
                      {t("AutoMessageList.Upsert.Channels.sendBy")}
                    </p>

                    <Controller
                      control={form.control}
                      name="Airbnb.value"
                      render={({ field: { onChange, value } }) => (
                        <div className="grid grid-cols-2 gap-2">
                          {/* <SimpleRadio
                            label={t("Global.email")}
                            value={value === "email"}
                            onClick={() => onChange("email")}
                          /> */}

                          <SimpleRadio
                            label={t(
                              "AutoMessageList.Upsert.Channels.byPlatform"
                            )}
                            value={value === "platform"}
                            disabled={false}
                            onClick={() => onChange("platform")}
                          />

                          <div className="flex gap-2 items-center">
                            <SimpleRadio
                              label={t("Global.SMS")}
                              value={value === "SMS"}
                              disabled={true}
                              onClick={() => onChange("SMS")}
                            />
                            {/* <div className="p-1 rounded-6px font-semibold bg-element-background-warning text-active text-[11px] cursor-not-allowed">
                              {t(
                                "AutoMessageList.Upsert.Channels.optionToActive"
                              )}
                            </div> */}
                          </div>
                        </div>
                      )}
                    />
                  </div>
                )}
              </>
            )}
          />
        </div>

        <Separator />

        {/* Booking.com */}
        <div className="flex flex-col gap-2">
          <Controller
            control={form.control}
            name="Booking.active"
            render={({ field: { value, onChange } }) => (
              <>
                <div
                  className="flex justify-between cursor-pointer"
                  onClick={() => onChange(!value)}
                >
                  <div className="flex gap-2 items-center">
                    <p className="text-high-contrast font-semibold">
                      {t("AutoMessageList.Upsert.Channels.bookingBooking")}
                    </p>

                    <BookingIcon />
                  </div>

                  <Switch value={value} />
                </div>

                {value === true && (
                  <div className="flex flex-col gap-2">
                    <p className="text-low-contrast font-light">
                      {t("AutoMessageList.Upsert.Channels.sendBy")}
                    </p>

                    <Controller
                      control={form.control}
                      name="Booking.value"
                      render={({ field: { onChange, value } }) => (
                        <div className="grid grid-cols-2 gap-2">
                          <SimpleRadio
                            label={t(
                              "AutoMessageList.Upsert.Channels.byPlatform"
                            )}
                            value={value === "platform"}
                            disabled={false}
                            onClick={() => onChange("platform")}
                          />

                          <div className="flex gap-2 items-center">
                            <SimpleRadio
                              label={t("Global.SMS")}
                              value={value === "SMS"}
                              disabled={true}
                              onClick={() => onChange("SMS")}
                            />
                            {/* <div className="p-1 rounded-6px font-semibold bg-element-background-warning text-active text-[11px] cursor-not-allowed">
                              {t(
                                "AutoMessageList.Upsert.Channels.optionToActive"
                              )}
                            </div> */}
                          </div>

                          <SimpleRadio
                            label={t("Global.email")}
                            value={value === "email"}
                            disabled={true}
                            onClick={() => onChange("email")}
                          />
                        </div>
                      )}
                    />
                  </div>
                )}
              </>
            )}
          />
        </div>

        <Separator />

        {/* Direct */}
        <div className="flex flex-col gap-2">
          <Controller
            control={form.control}
            name="Direct.active"
            render={({ field: { value, onChange } }) => (
              <>
                <div
                  className="flex justify-between cursor-pointer"
                  onClick={() => onChange(!value)}
                >
                  <div className="flex gap-2 items-center">
                    <p className="text-high-contrast font-semibold">
                      {t("AutoMessageList.Upsert.Channels.directBooking")}
                    </p>
                  </div>

                  <Switch value={value} />
                </div>

                {value === true && (
                  <div className="flex flex-col gap-2">
                    <p className="text-low-contrast font-light">
                      {t("AutoMessageList.Upsert.Channels.sendBy")}
                    </p>

                    <Controller
                      control={form.control}
                      name="Direct.value"
                      render={({ field: { onChange, value } }) => (
                        <div className="grid grid-cols-2 gap-2">
                          <SimpleRadio
                            label={t("Global.email")}
                            value={value === "email"}
                            onClick={() => onChange("email")}
                          />

                          <div className="flex gap-2 items-center">
                            <SimpleRadio
                              label={t("Global.SMS")}
                              value={value === "SMS"}
                              disabled={true}
                              onClick={() => onChange("SMS")}
                            />
                            {/* <div className="p-1 rounded-6px font-semibold bg-element-background-warning text-active text-[11px] cursor-not-allowed">
                              {t(
                                "AutoMessageList.Upsert.Channels.optionToActive"
                              )}
                            </div> */}
                          </div>
                        </div>
                      )}
                    />
                  </div>
                )}
              </>
            )}
          />
        </div>

        <input
          type="submit"
          value="go"
          ref={refButton as React.MutableRefObject<HTMLInputElement>}
          className="hidden"
        />
      </div>
    </form>
  );
};
