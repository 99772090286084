import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {ShortCodeListProps} from "./ShortCodeList.type";
import {TextInput} from "../../Common/TextInput/TextInput";

import SearchIcon from "../../../assets/icons/search.svg?react";
import {ShortCodeGroupListLine} from "./ShortCodeGroupListLine";
import {get} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import InformationActiveIcon from "../../../assets/icons/information-active.svg?react";
import {ShortCodeGroupListLineSkeleton} from "./ShortCodeGroupListLineSkeleton";
import {capitalizeFirstLetter, changeGroupCodeName} from "../../../helpers/stringHelper";

export const ShortCodeList: React.FC<ShortCodeListProps> = ({onInsert}) => {
  const {t} = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [shortcodes, setShortcodes] = useState<any[]>([]);
  const [filteredShortcodes, setFilteredShortcodes] = useState<any[]>([]);

  const getGroupName = (name: string) => {
    return capitalizeFirstLetter(name);
  };


  const transform = (data: any) => {
    if (!data) return data;

    let tmpCodes: any[] = [];
    if (data.custom) tmpCodes = [...tmpCodes, ...data.custom];
    if (data.default) tmpCodes = [...tmpCodes, ...data.default];

    const result: any[] = [];

    tmpCodes?.forEach((code: any) => {
      const indexGroup = result.findIndex((g: any) => g.id === code.type);

      if (indexGroup === -1) {
        result.push({
          id: code.type,
          name: t(capitalizeFirstLetter(changeGroupCodeName(code.type))).toUpperCase(),
          codes: [{...code}],
        });
      } else {
        result[indexGroup].codes.push(code);
      }
    });

    const order = ['guest', 'reservation', 'payment','guest_page', 'rental'];

    result.sort((a: any, b: any) => {
      const indexA = order.indexOf(a.id.toLowerCase());
      const indexB = order.indexOf(b.id.toLowerCase());

      if (indexA === -1 && indexB === -1) return 0;
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;

      return indexA - indexB;
    });

    return result;
  };

  const fetchShortCodes = async () => {
    setLoading(true);
    setError(null);

    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.SHORTCODES}`
    );

    if (response?.data?.success) {
      const nextCodes = transform(response.data.result?.shortcodes);
      setShortcodes(nextCodes);
      setFilteredShortcodes(nextCodes);
    } else {
      setError(response?.response?.data?.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchShortCodes();
  }, []);

  const handleSearch = (text: string) => {
    // Filtrer chaque élément de 'shortcodes'
    const nextFiltered = shortcodes
      .map((item) => {
        // Filtrer les codes pour ne garder que ceux qui correspondent au texte recherché
        const matchingCodes = item.codes.filter((code: any) => {
          return (
            code.trigger.toLowerCase().includes(text.toLowerCase()) ||
            code.description.toLowerCase().includes(text.toLowerCase())
          );
        });

        console.log("matchingCodes", matchingCodes);

        // Retourner l'élément avec seulement les codes correspondants, si au moins un code correspond
        if (matchingCodes.length > 0) {
          return {
            ...item,
            codes: matchingCodes,
          };
        }

        // Si aucun code correspondant n'est trouvé, ne pas inclure cet élément dans le résultat
        return false;
      })
      .filter(Boolean); // Filtrer pour éliminer les valeurs 'false'

    setFilteredShortcodes(nextFiltered);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row bg-element-background rounded-4px mb-4 w-full h-fit p-2 gap-2">
        <div className="">
          <InformationActiveIcon className="w-5 h-5" />
        </div>

        <div className="flex-1">
          <p className="font-semibold text-high-contrast">
            {t("AutoMessageUpsert.shortCodeInfosTitle")}
          </p>
          <p className="text-low-contrast mt-1">
            <Trans
                i18nKey="AutoMessageUpsert.shortCodeInfos"
                components={{
                  strong: <strong/>,
                }}
            />
          </p>
        </div>
      </div>

      <div>
        <TextInput
          label={t("AutoMessageUpsert.searchShortCode")}
          placeholder={t("AutoMessageUpsert.placeholderSearchShortCode")}
          Icon={SearchIcon}
          onChangeText={handleSearch}
          disabled={loading}
        />
      </div>

      <ErrorMessage>{error}</ErrorMessage>

      <div className="mt-3 pb-3">
        <table className="table-auto w-full rounded-4px border-1 border-element-border border-separate overflow-hidden border-spacing-0">
          {/* Table Header */}
          <tr className="bg-element-background border-b-1 border-element-border">
            <th className=" px-2 py-2 text-sm">
              <div className="flex flex-row items-center cursor-pointer hover:underline">
                <p>{t("AutoMessageUpsert.shortCodeDesc")}</p>
              </div>
            </th>

            <th className=" px-2 py-2 text-sm">
              <div className="flex flex-row items-center cursor-pointer hover:underline">
                <p>{t("AutoMessageUpsert.shortCodeName")}</p>
              </div>
            </th>

            <th className=" px-2 py-2 text-sm">
              <div className="flex flex-row items-center cursor-pointer hover:underline">
                <p>{t("AutoMessageUpsert.shortCodeAction")}</p>
              </div>
            </th>
          </tr>

          {loading && <ShortCodeGroupListLineSkeleton/>}

          {!loading &&
              filteredShortcodes?.map((group, index) => (
                  <ShortCodeGroupListLine
                      id={index}
                      name={group.name}
                codes={group.codes}
                onInsert={onInsert}
              />
            ))}
        </table>
      </div>
    </div>
  );
};
