import React, {useEffect, useState} from "react";
import {ConversationFilterProps} from "../ConversationList/ConversationList.type";
import {RightModal} from "../../Common/RightModal/RightModal";
import {useTranslation} from "react-i18next";
import {SimpleBox} from "../../Modal/Airbnb/Connect/AirbnbConnectStepImport";
import CloseCircleIcon from "../../../assets/icons/close-circle-red.svg?react";
import {Button} from "../../Common/Button/Button";
import checkIcon from "../../../assets/icons/check-white.svg?react";
import CheckCircleGreenIcon from "../../../assets/icons/check-circle-green.svg?react";
import {Controller, useForm} from "react-hook-form";
import {CalendarInput} from "../../Common/CalendarInput/CalendarInput";
import moment from "moment/moment";
import {ValueType} from "../../../types/commonTypes";
import {PlatformEnum, ReservationStatusFilterEnum} from "../../../enums/GETenums";
import {MultiSelect} from "../../Common/MultiSelect/MultiSelect";
import {useTablePage} from "../../../hooks/useTablePage";
import paths from "../../../constants/paths";
import {useRentalList} from "../../../hooks/api/rental";
export const ConversationFilterModal: React.FC<ConversationFilterProps> = ({isVisible, onClose, onFinish, onSelect}) => {

    const { t } = useTranslation();
    const value = {
        ended: true,
        cancelByTraveler: true,
        preApproved: true,
        request: true,
        confirmInProgress: true,
        confirmComing: true,
        cancel: true,
    }
    const [selectedRentals, setSelectedRentals] = useState<ValueType[]>(
        []);
    const [prevSelectedRentals, setPrevSelectedRentals] = useState<ValueType[]>(
        []);

    type formSchema = {
        startDateTime: Date;
        endDateTime: Date;
    };

    const form = useForm<formSchema>({
        defaultValues: {
            startDateTime: moment(new Date()).subtract(1, 'months').toDate(),
            endDateTime: moment(new Date()).toDate(),
    }});

    const platform = {
        airbnb: true,
        booking: true,
        direct: true
    }

    const [filter, setFilter] = useState(value)
    const [platformFilter, setPlatformFilter] = useState(platform)

    const [isVisibleSecondModal, setIsVisibleSecondModal] = useState(false)

    const handleReinitialize = () => {
        setFilter(value)
        setPlatformFilter(platform)
    }


    const handlePrevFilter = () => {

        const prevStatus = [];
        const prevPlatformIds = [];



        if ( value.cancel) {
            prevStatus.push(ReservationStatusFilterEnum.CANCEL);
        }

        if(value.confirmComing) {
            prevStatus.push(ReservationStatusFilterEnum.CONFIRM_COMING);
        }
        if(value.confirmInProgress) {
            prevStatus.push(ReservationStatusFilterEnum.CONFIRM_IN_PROGRESS);
        }

        if(value.ended) {
            prevStatus.push(ReservationStatusFilterEnum.ENDED);
        }

        if(value.request) {
            prevStatus.push(ReservationStatusFilterEnum.REQUEST);
        }
        if (value.cancelByTraveler) {
            prevStatus.push(ReservationStatusFilterEnum.CANCEL_BY_TRAVELER);
        }

        if(value.preApproved) {
            prevStatus.push(ReservationStatusFilterEnum.PRE_APPROVED);
        }

        if(platform.airbnb) {
            prevPlatformIds.push(PlatformEnum.AIRBNB)
        }
        if(platform.booking) {
            prevPlatformIds.push(PlatformEnum.BOOKING)
        }
        if(platform.direct) {
            prevPlatformIds.push(PlatformEnum.DIRECT)
        }
        const prevValues = {
            status: prevStatus,
            plaformIds: prevPlatformIds,
            rental_ids: prevSelectedRentals
        }

        return prevValues;
    }
    const handleFilter = async  () => {
        const status = [];
        const plaformIds = [];
        if (filter.cancel) {
            status.push(ReservationStatusFilterEnum.CANCEL);
        }

        if(filter.confirmComing) {
            status.push(ReservationStatusFilterEnum.CONFIRM_COMING);
        }
        if(filter.confirmInProgress) {
            status.push(ReservationStatusFilterEnum.CONFIRM_IN_PROGRESS);
        }

        if(filter.ended) {
            status.push(ReservationStatusFilterEnum.ENDED);
        }

        if(filter.request) {
            status.push(ReservationStatusFilterEnum.REQUEST);
        }
        if (filter.cancelByTraveler) {
            status.push(ReservationStatusFilterEnum.CANCEL_BY_TRAVELER);
        }
        if(filter.preApproved) {
            status.push(ReservationStatusFilterEnum.PRE_APPROVED);
        }

        if(platformFilter.airbnb) {
            plaformIds.push(PlatformEnum.AIRBNB)
        }
        if(platformFilter.booking) {
            plaformIds.push(PlatformEnum.BOOKING)
        }
        if(platformFilter.direct) {
            plaformIds.push(PlatformEnum.DIRECT)
        }
        console.log(value, 'status');

        const values = {
            status: status,
            plaformIds: plaformIds,
            startDateTime: moment(form.getValues('startDateTime')).format('YYYY-MM-DD'),
            endDateTime: moment(form.getValues('endDateTime')).format('YYYY-MM-DD'),
        }

        const prevValues = handlePrevFilter();
        const isFiltered = prevValues.status.length !== status.length || prevValues.plaformIds.length !== plaformIds.length;
        onSelect(values, isFiltered)
        onClose()
    }

    const {
        control,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm<{
        rentals: ValueType[];
    }>({
        mode: "all",
    });


    const tablePage = useTablePage(
        `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`,
        "rentals"
    );
    const displayRentals = () => {
       useRentalList(
           (data)=> {
               const selectedRentals_ = data?.map((rental : any) => rental.id);
               setSelectedRentals(selectedRentals_);
               setPrevSelectedRentals(selectedRentals_);
           },
           (error) => {
               console.log(error, 'error');
           }
       )

    }

    useEffect(() => {
        displayRentals()
    }, []);

    const handleSelectRentals = ()=> {
        const values = {
            rentals: selectedRentals,
        }

        const prevValues = handlePrevFilter();
        const isFiltered = prevValues.rental_ids.length !== selectedRentals.length;
        console.log(isFiltered, 'values');
        onSelect(values, isFiltered)
        setIsVisibleSecondModal(false)
        onClose()
    }

    const handleSelectRental = (id: ValueType) => {
        const exists = selectedRentals?.findIndex((i) => i === id) > -1;

        if (exists) {
            setSelectedRentals((prev) => [...prev.filter((i) => i !== id)]);
        } else {
            setSelectedRentals((prev) => [...prev, id]);
        }
    };


    return (
        <RightModal isVisible={isVisible}
                    onClose={onClose}
                    isLarge={false}
                    title={t("MessageList.filtreMessageTitle")}
                    closeOnEmpty={false}
                    isDouble={true}
                    secondTitle={t("Filter.filtreAccommodationTitle")}
                    isVisibleSecondModal={isVisibleSecondModal}
                    onCloseSecondModal={() => setIsVisibleSecondModal(false)}

        >
            <form onSubmit={form.handleSubmit(handleFilter)} className="flex flex-col w-full h-full">
                    <div className="w-full flex-1 overflow-y-auto ">
                        <p className="text-low-contrast"> {t("Filter.filterBy")} </p>
                        <p className="font-semibold">{t("Filter.statutReservation")}</p>
                        <div className="flex flex-row justify-between gap-10">
                            <div className="flex flex-col gap-3 ">
                                <div className="flex flex-row gap-2" onClick={() => setFilter(prevFilter => ({
                                    ...prevFilter,
                                    request: !prevFilter.request
                                }))}>
                                    <div className="bg-gray-100 p-3 rounded-lg  w-full">
                                        <p className="text-center text-request-text font-semibold">
                                            {t("Filter.reservationRequest")}
                                        </p>
                                    </div>

                                    <div className="mt-3">
                                        <SimpleBox value={filter.request}/>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2" onClick={() => setFilter(prevFilter => ({
                                    ...prevFilter,
                                    confirmComing: !prevFilter.confirmComing
                                }))}>
                                    <div className="bg-gray-100 p-3 rounded-lg  w-full">
                                        <p className=" text-center rounded-lg text-low-contrast font-semibold">
                                            {t("Filter.upcomingStay")}
                                        </p>
                                    </div>

                                    <div className="mt-3">
                                        <SimpleBox value={filter.confirmComing}/>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2" onClick={() => setFilter(prevFilter => ({
                                    ...prevFilter,
                                    ended: !prevFilter.ended
                                }))}>
                                    <div className="bg-gray-100 p-3 rounded-lg  w-full">
                                        <p className=" text-center rounded-lg text-low-contrast font-semibold">
                                            {t("Filter.stayEnded")}
                                        </p>
                                    </div>

                                    <div className="mt-3">
                                        <SimpleBox value={filter.ended}/>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2" onClick={() => setFilter(prevFilter => ({
                                    ...prevFilter,
                                    cancelByTraveler: !prevFilter.cancelByTraveler
                                }))}>
                                    <div
                                        className="flex flex-row  bg-red-100 p-3 rounded-lg  w-full justify-center gap-1">
                                        <CloseCircleIcon className="w-5 h-5 fill-red justify-center"/>
                                        <p className="rounded-lg text-error font-semibold">
                                            {t("Filter.cancelByTaveler")}
                                        </p>
                                    </div>

                                    <div className="mt-3">
                                        <SimpleBox value={filter.cancelByTraveler}/>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-3 w-[47%]">
                                <div className="flex flex-row gap-2" onClick={() => setFilter(prevFilter => ({
                                    ...prevFilter,
                                    preApproved: !prevFilter.preApproved
                                }))}>
                                    <div className="bg-gray-100 p-3 rounded-lg  w-full">
                                        <p className="text-center text-green font-semibold">
                                            {t("Filter.preApprovedRequest")}
                                        </p>
                                    </div>

                                    <div className="mt-3">
                                        <SimpleBox value={filter.preApproved}/>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2" onClick={() => setFilter(prevFilter => ({
                                    ...prevFilter,
                                    confirmInProgress: !prevFilter.confirmInProgress
                                }))}>
                                    <div
                                        className="flex flex-row bg-filter-current p-3 rounded-lg  w-full justify-center gap-1">
                                        <CheckCircleGreenIcon className="w-5 h-5 fill-red justify-center"/>
                                        <p className="rounded-lg text-green font-semibold">
                                            {t("Filter.currentStay")}
                                        </p>
                                    </div>

                                    <div className="mt-3">
                                        <SimpleBox value={filter.confirmInProgress}/>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2" onClick={() => setFilter(prevFilter => ({
                                    ...prevFilter,
                                    cancel: !prevFilter.cancel
                                }))}>
                                    <div
                                        className="flex flex-row  bg-red-100 p-3 rounded-lg w-full justify-center gap-1">
                                        <CloseCircleIcon className="w-5 h-5" fill="#0F172A"/>
                                        <p className="rounded-lg text-error font-semibold">
                                            {t("Filter.canceled")}
                                        </p>
                                    </div>

                                    <div className="mt-3">
                                        <SimpleBox value={filter.cancel}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-4 mt-3">
                            <Controller
                                control={form.control}
                                name="startDateTime"
                                render={({field: {onChange, value}}) => (
                                    <CalendarInput
                                        label={`${t("Filter.startDateTime")}`}
                                        value={value}
                                        onChangeDate={onChange}
                                        classNames={{button: "p-3 rounded-lg w-full"}}
                                    />
                                )}
                            />
                            <Controller
                                control={form.control}
                                name="endDateTime"
                                render={({field: {onChange, value}}) => (
                                    <CalendarInput
                                        label={`${t("Filter.endDateTime")}`}
                                        value={value}
                                        onChangeDate={onChange}
                                        classNames={{button: "p-3 rounded-lg w-full"}}
                                        anchor="end"
                                    />
                                )}
                            />
                        </div>

                        <div className="flex flex-col mt-5  gap-3">
                            <p className="font-semibold">{t("Filter.platformReservation")}</p>
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col gap-3 w-[47%]">
                                    <div className="flex flex-row gap-2"
                                         onClick={() => setPlatformFilter(prevFilter => ({
                                             ...prevFilter,
                                             airbnb: !prevFilter.airbnb
                                         }))}>
                                        <div className="bg-red-100 p-3 rounded-lg  w-full">
                                            <p className=" text-center rounded-lg text-error font-semibold">
                                                {t("Filter.airbnb")}
                                            </p>
                                        </div>

                                        <div className="mt-3">
                                            <SimpleBox value={platformFilter.airbnb}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-3 w-[47%]">
                                    <div className="flex flex-row gap-2"
                                         onClick={() => setPlatformFilter(prevFilter => ({
                                             ...prevFilter,
                                             booking: !prevFilter.booking
                                         }))}>
                                        <div className="bg-filter-booking p-3 rounded-lg  w-full">
                                            <p className="text-center rounded-lg text-horizontal-booking font-semibold">
                                                {t("Filter.booking")}
                                            </p>
                                        </div>

                                        <div className="mt-3">
                                            <SimpleBox value={platformFilter.booking}/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col gap-3 w-[47%]">
                                    <div className="flex flex-row gap-2"
                                         onClick={() => setPlatformFilter(prevFilter => ({
                                             ...prevFilter,
                                             direct: !prevFilter.direct
                                         }))}>
                                        <div className="bg-filter-booking p-3 rounded-lg  w-full">
                                            <p className="text-center rounded-lg text-horizontal-booking font-semibold">
                                                {t("Filter.direct")}
                                            </p>
                                        </div>

                                        <div className="mt-3">
                                            <SimpleBox value={platformFilter.direct}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Button type="secondary" onClick={() => setIsVisibleSecondModal(!isVisibleSecondModal)}
                                    displayLargeBtn={true}>
                                {t("Filter.filterByHebergement")}
                            </Button>

                        </div>
                    </div>
                    <div className="flex gap-3 border-t-1 border-element-border pt-4 mb-2 mt-4">
                        <Button type="secondary" onClick={onClose} disabled={isVisibleSecondModal}>
                            {t("Global.cancel")}
                        </Button>
                        <Button type="secondary" onClick={() => handleReinitialize()} displayLargeBtn={true}
                                disabled={isVisibleSecondModal}>
                            {t("Global.reinitialize")}
                        </Button>
                        <Button RightIcon={checkIcon} onClick={() => {
                            handleSubmit()
                        }} disabled={isVisibleSecondModal}>
                        {t("Filter.apply")}
                        </Button>
                    </div>
            </form>


            <div className="flex flex-col w-full h-full">
                    <div className="w-full flex-1 overflow-y-auto">
                            <p className="font-semibold text-low-contrast">{t('Filter.filterByHebergementTitle')}</p>
                            <div className="flex flex-col w-full h-full">
                                <Controller
                                    control={control}
                                    name="rentals"
                                    rules={{
                                        required: true,
                                        validate: {
                                            notEmpty: () => {
                                                return (
                                                    selectedRentals.length > 0 ||
                                                    t("RentalList.rentalGroupNameNotEmptyError").toString()
                                                );
                                            },
                                        },
                                        onChange: (event) => handleSelectRental(event.target.value),
                                    }}
                                    render={({field: {onChange}}) => (
                                        <MultiSelect
                                            selectedRentals={selectedRentals}
                                            options={{
                                                withInfoText: false,
                                                withSubmitButton: false,
                                            }}
                                            onSelectRental={onChange}
                                            onNext={() => {
                                            }}
                                            loading={false}
                                        />
                                    )}
                                />
                            </div>
                    </div>
                    <div className="flex gap-3 border-t-1 border-element-border pt-4 mb-2 mt-4">
                    <Button type="secondary" onClick={() => setIsVisibleSecondModal(false)}>
                        {t("Global.cancel")}
                    </Button>
                    <Button RightIcon={checkIcon} onClick={handleSubmit(handleSelectRentals)}>
                        {t("Filter.apply")}
                    </Button>
                </div>
            </div>

        </RightModal>
    )
}
