import React from "react";
import { ShortCodeListLineProps } from "./ShortCodeList.type";

export const ShortCodeGroupListLineSkeleton: React.FC<{}> = ({}) => {
  return (
    <>
      <tr
        className="bg-element-background/70 cursor-pointer animate-pulse"
        key={`group-skeleton`}
      >
        <td
          className="text-center font-semibold text-low-contrast py-2 border-b-1 border-element-border/30"
          colSpan={3}
        >
          <p className="flex flex-row flex-1 justify-between items-center mx-2 text-[12px] h-2 w-52 bg-slate-200 rounded my-1"></p>
        </td>
      </tr>

      {Array.from({ length: 5 }).map((value, index) => (
        <tr
          key={`code-skeleton-${index}`}
          className="hover:bg-element-background/40 cursor-pointer animate-pulse"
        >
          <td className="p-2 pr-0 text-high-contrast font-semibold">
            <p className="w-40 h-2 rounded bg-slate-300"></p>
          </td>
          <td className="p-2 pr-0 text-low-contrast font-light ">
            <p className="h-2 bg-slate-200 rounded w-48"></p>
          </td>
          <td className="p-2 w-20">
            <div className="bg-slate-200 h-8 rounded-6px" />
          </td>
        </tr>
      ))}
    </>
  );
};
