import { FacilitiesGroupType } from "../components/Rental/Infos/Facilities/Facilities.type";

import BathIcon from "../assets/icons/bath.svg?react";
import DoubleBedIcon from "../assets/icons/double-bed.svg?react";
import FireIcon from "../assets/icons/fire.svg?react";
import Fork2Icon from "../assets/icons/fork-2.svg?react";
import ParkingIcon from "../assets/icons/parking.svg?react";
import PinOnMapIcon from "../assets/icons/pin-on-map.svg?react";
import ServiceBellIcon from "../assets/icons/service-bell.svg?react";
import StarIcon from "../assets/icons/star.svg?react";
import WifiIcon from "../assets/icons/wifi.svg?react";

const res: FacilitiesGroupType[] = [
  {
    nameI18n: "Rental.Infos.Facilities.title_common",
    Icon: StarIcon,
    facilities: [
      {
        nameI18n: "Rental.Infos.Facilities.base_equipments",
        code: "base_equipments",
      },
      {
        nameI18n: "Rental.Infos.Facilities.linens",
        code: "linens",
      },
      {
        nameI18n: "Rental.Infos.Facilities.kitchen_base",
        code: "kitchen_base",
      },
      {
        nameI18n: "Rental.Infos.Facilities.cleaning",
        code: "cleaning",
      },
      {
        nameI18n: "Rental.Infos.Facilities.cutlery",
        code: "cutlery",
      },
      {
        nameI18n: "Rental.Infos.Facilities.clim",
        code: "clim",
      },
      {
        nameI18n: "Rental.Infos.Facilities.heating",
        code: "heating",
      },
      {
        nameI18n: "Rental.Infos.Facilities.dryer",
        code: "dryer",
      },
      {
        nameI18n: "Rental.Infos.Facilities.hair_dryer",
        code: "hair_dryer",
      },
      {
        nameI18n: "Rental.Infos.Facilities.parking",
        code: "parking",
      },
      {
        nameI18n: "Rental.Infos.Facilities.Wifi",
        code: "Wifi",
      },
    ],
  },

  {
    nameI18n: "Rental.Infos.Facilities.title_room",
    Icon: DoubleBedIcon,
    facilities: [
      {
        nameI18n: "Rental.Infos.Facilities.simple_bed",
        code: "simple_bed",
      },
      {
        nameI18n: "Rental.Infos.Facilities.double_bed",
        code: "double_bed",
      },
      {
        nameI18n: "Rental.Infos.Facilities.king_size_bed",
        code: "king_size_bed",
      },
      {
        nameI18n: "Rental.Infos.Facilities.murphy_bed",
        code: "murphy_bed",
      },
      {
        nameI18n: "Rental.Infos.Facilities.queen_size_bed",
        code: "queen_size_bed",
      },
      {
        nameI18n: "Rental.Infos.Facilities.sofa_bed",
        code: "sofa_bed",
      },
      {
        nameI18n: "Rental.Infos.Facilities.cradle_bed",
        code: "cradle_bed",
      },
      {
        nameI18n: "Rental.Infos.Facilities.crib_bed",
        code: "crib_bed",
      },
      {
        nameI18n: "Rental.Infos.Facilities.bunk_bed",
        code: "bunk_bed",
      },
      {
        nameI18n: "Rental.Infos.Facilities.pack_n_play_travel_crib",
        code: "pack_n_play_travel_crib",
      },
      {
        nameI18n: "Rental.Infos.Facilities.wardrobe_or_closet",
        code: "wardrobe_or_closet",
      },
    ],
  },

  {
    nameI18n: "Rental.Infos.Facilities.title_bathroom",
    Icon: BathIcon,
    facilities: [
      {
        nameI18n: "Rental.Infos.Facilities.bath_tub",
        code: "bath_tub",
      },
      {
        nameI18n: "Rental.Infos.Facilities.jetted_tub",
        code: "jetted_tub",
      },
      {
        nameI18n: "Rental.Infos.Facilities.bidet",
        code: "bidet",
      },
      {
        nameI18n: "Rental.Infos.Facilities.shower",
        code: "shower",
      },
      {
        nameI18n: "Rental.Infos.Facilities.sink",
        code: "sink",
      },
      {
        nameI18n: "Rental.Infos.Facilities.soap",
        code: "soap",
      },
      {
        nameI18n: "Rental.Infos.Facilities.conditioner",
        code: "conditioner",
      },
      {
        nameI18n: "Rental.Infos.Facilities.hair_dryer",
        code: "hair_dryer",
      },
      {
        nameI18n: "Rental.Infos.Facilities.outdoor_shower",
        code: "outdoor_shower",
      },
      {
        nameI18n: "Rental.Infos.Facilities.shampoo",
        code: "shampoo",
      },
      {
        nameI18n: "Rental.Infos.Facilities.shower_gel",
        code: "shower_gel",
      },
      {
        nameI18n: "Rental.Infos.Facilities.wc",
        code: "wc",
      },
      {
        nameI18n: "Rental.Infos.Facilities.towels",
        code: "towels",
      },
      {
        nameI18n: "Rental.Infos.Facilities.laundry_dryer",
        code: "laundry_dryer",
      },
      {
        nameI18n: "Rental.Infos.Facilities.linens", // doublon
        code: "linens",
      },
      {
        nameI18n: "Rental.Infos.Facilities.iron",
        code: "iron",
      },
      {
        nameI18n: "Rental.Infos.Facilities.hot_water",
        code: "hot_water",
      },
      {
        nameI18n: "Rental.Infos.Facilities.baby_bath",
        code: "baby_bath",
      },
      {
        nameI18n: "Rental.Infos.Facilities.changing_table",
        code: "changing_table",
      },
      {
        nameI18n: "Rental.Infos.Facilities.beach_essentials",
        code: "beach_essentials",
      },
    ],
  },

  {
    nameI18n: "Rental.Infos.Facilities.title_kitchen",
    Icon: Fork2Icon,
    facilities: [
      {
        nameI18n: "Rental.Infos.Facilities.hotplates",
        code: "hotplates",
      },
      {
        nameI18n: "Rental.Infos.Facilities.bbq",
        code: "bbq",
      },
      {
        nameI18n: "Rental.Infos.Facilities.bread_maker",
        code: "bread_maker",
      },
      {
        nameI18n: "Rental.Infos.Facilities.blender",
        code: "blender",
      },
      {
        nameI18n: "Rental.Infos.Facilities.coffee",
        code: "coffee",
      },
      {
        nameI18n: "Rental.Infos.Facilities.coffee_maker",
        code: "coffee_maker",
      },
      {
        nameI18n: "Rental.Infos.Facilities.kitchen_base",
        code: "kitchen_base",
      },
      {
        nameI18n: "Rental.Infos.Facilities.dinner_table",
        code: "dinner_table",
      },
      {
        nameI18n: "Rental.Infos.Facilities.cutlery", // doublon
        code: "cutlery",
      },
      {
        nameI18n: "Rental.Infos.Facilities.dishwasher",
        code: "dishwasher",
      },
      {
        nameI18n: "Rental.Infos.Facilities.freezer",
        code: "freezer",
      },
      {
        nameI18n: "Rental.Infos.Facilities.electric_kettle",
        code: "electric_kettle",
      },
      {
        nameI18n: "Rental.Infos.Facilities.kitchen",
        code: "kitchen",
      },
      {
        nameI18n: "Rental.Infos.Facilities.kitchenette",
        code: "kitchenette",
      },
      {
        nameI18n: "Rental.Infos.Facilities.microwave",
        code: "microwave",
      },
      {
        nameI18n: "Rental.Infos.Facilities.mini_fridge",
        code: "mini_fridge",
      },
      {
        nameI18n: "Rental.Infos.Facilities.hoven",
        code: "hoven",
      },
      {
        nameI18n: "Rental.Infos.Facilities.fridge",
        code: "fridge",
      },
      {
        nameI18n: "Rental.Infos.Facilities.stove",
        code: "stove",
      },
      {
        nameI18n: "Rental.Infos.Facilities.toaster",
        code: "toaster",
      },
      {
        nameI18n: "Rental.Infos.Facilities.waste_compactor",
        code: "waste_compactor",
      },
      {
        nameI18n: "Rental.Infos.Facilities.wine_glasses",
        code: "wine_glasses",
      },
      {
        nameI18n: "Rental.Infos.Facilities.wood_stove",
        code: "wood_stove",
      },
      {
        nameI18n: "Rental.Infos.Facilities.spices",
        code: "spices",
      },
      {
        nameI18n: "Rental.Infos.Facilities.high_chair",
        code: "high_chair",
      },
      {
        nameI18n: "Rental.Infos.Facilities.ice_machine",
        code: "ice_machine",
      },
      {
        nameI18n: "Rental.Infos.Facilities.kitchen_island",
        code: "kitchen_island",
      },
      {
        nameI18n: "Rental.Infos.Facilities.vacuum",
        code: "vacuum",
      },
      {
        nameI18n: "Rental.Infos.Facilities.childrens_dinnerware",
        code: "childrens_dinnerware",
      },
      {
        nameI18n: "Rental.Infos.Facilities.barbeque_utensils",
        code: "barbeque_utensils",
      },
      {
        nameI18n: "Rental.Infos.Facilities.dishes_and_silverware",
        code: "dishes_and_silverware",
      },
      {
        nameI18n: "Rental.Infos.Facilities.refrigerator",
        code: "refrigerator",
      },
      {
        nameI18n: "Rental.Infos.Facilities.rice_maker",
        code: "rice_maker",
      },
    ],
  },

  {
    nameI18n: "Rental.Infos.Facilities.title_clim",
    Icon: FireIcon,
    facilities: [
      {
        nameI18n: "Rental.Infos.Facilities.clim",
        code: "clim",
      },
      {
        nameI18n: "Rental.Infos.Facilities.ceiling_fan",
        code: "ceiling_fan",
      },
      {
        nameI18n: "Rental.Infos.Facilities.heating",
        code: "heating",
      },
      {
        nameI18n: "Rental.Infos.Facilities.chimney",
        code: "chimney",
      },
      {
        nameI18n: "Rental.Infos.Facilities.portable_fan",
        code: "portable_fan",
      },
    ],
  },

  {
    nameI18n: "Rental.Infos.Facilities.title_parking",
    Icon: ParkingIcon,
    facilities: [
      {
        nameI18n: "Rental.Infos.Facilities.shared_pool",
        code: "shared_pool",
      },
      {
        nameI18n: "Rental.Infos.Facilities.heated_pool",
        code: "heated_pool",
      },
      {
        nameI18n: "Rental.Infos.Facilities.indoor_pool",
        code: "indoor_pool",
      },
      {
        nameI18n: "Rental.Infos.Facilities.private_pool",
        code: "private_pool",
      },
      {
        nameI18n: "Rental.Infos.Facilities.jacuzzi",
        code: "jacuzzi",
      },
      {
        nameI18n: "Rental.Infos.Facilities.sauna",
        code: "sauna",
      },
      {
        nameI18n: "Rental.Infos.Facilities.parking",
        code: "parking",
      },
      {
        nameI18n: "Rental.Infos.Facilities.parking_street",
        code: "parking_street",
      },
      {
        nameI18n: "Rental.Infos.Facilities.gym",
        code: "gym",
      },
      {
        nameI18n: "Rental.Infos.Facilities.parking_paid_outside",
        code: "parking_paid_outside",
      },
      {
        nameI18n: "Rental.Infos.Facilities.parking_paid",
        code: "parking_paid",
      },
      {
        nameI18n: "Rental.Infos.Facilities.garden",
        code: "garden",
      },
      {
        nameI18n: "Rental.Infos.Facilities.monoxide_detector",
        code: "monoxide_detector",
      },
      {
        nameI18n: "Rental.Infos.Facilities.extinguisher",
        code: "extinguisher",
      },
      {
        nameI18n: "Rental.Infos.Facilities.first_aid_kit",
        code: "first_aid_kit",
      },
      {
        nameI18n: "Rental.Infos.Facilities.smoke_detector",
        code: "smoke_detector",
      },
      {
        nameI18n: "Rental.Infos.Facilities.game_room",
        code: "game_room",
      },
      {
        nameI18n: "Rental.Infos.Facilities.garage",
        code: "garage",
      },
      {
        nameI18n: "Rental.Infos.Facilities.children_books",
        code: "children_books",
      },
      {
        nameI18n: "Rental.Infos.Facilities.books",
        code: "books",
      },
      {
        nameI18n: "Rental.Infos.Facilities.dvd",
        code: "dvd",
      },
      {
        nameI18n: "Rental.Infos.Facilities.baby_foot",
        code: "baby_foot",
      },
      {
        nameI18n: "Rental.Infos.Facilities.games",
        code: "games",
      },
      {
        nameI18n: "Rental.Infos.Facilities.cds",
        code: "cds",
      },
      {
        nameI18n: "Rental.Infos.Facilities.pingpong",
        code: "pingpong",
      },
      {
        nameI18n: "Rental.Infos.Facilities.pool_table",
        code: "pool_table",
      },
      {
        nameI18n: "Rental.Infos.Facilities.cable_satellite",
        code: "cable_satellite",
      },
      {
        nameI18n: "Rental.Infos.Facilities.connected_tv",
        code: "connected_tv",
      },
      {
        nameI18n: "Rental.Infos.Facilities.hifi_system",
        code: "hifi_system",
      },
      {
        nameI18n: "Rental.Infos.Facilities.tv",
        code: "tv",
      },
      {
        nameI18n: "Rental.Infos.Facilities.toys",
        code: "toys",
      },
      {
        nameI18n: "Rental.Infos.Facilities.video_games",
        code: "video_games",
      },
      {
        nameI18n: "Rental.Infos.Facilities.movies",
        code: "movies",
      },
      {
        nameI18n: "Rental.Infos.Facilities.car_charger",
        code: "car_charger",
      },
      {
        nameI18n: "Rental.Infos.Facilities.fire_emergency_contact",
        code: "fire_emergency_contact",
      },
      {
        nameI18n: "Rental.Infos.Facilities.extinguisher", // doublon
        code: "extinguisher",
      },
      {
        nameI18n: "Rental.Infos.Facilities.brasero",
        code: "brasero",
      },
      {
        nameI18n: "Rental.Infos.Facilities.first_aid_kit",
        code: "first_aid_kit",
      },
      {
        nameI18n: "Rental.Infos.Facilities.baby_monitor",
        code: "baby_monitor",
      },
      {
        nameI18n: "Rental.Infos.Facilities.arcade_machine",
        code: "arcade_machine",
      },
      {
        nameI18n: "Rental.Infos.Facilities.batting_cage",
        code: "batting_cage",
      },
      {
        nameI18n: "Rental.Infos.Facilities.bowling_alley",
        code: "bowling_alley",
      },
      {
        nameI18n: "Rental.Infos.Facilities.climbing_wall",
        code: "climbing_wall",
      },
      {
        nameI18n: "Rental.Infos.Facilities.laser_tag",
        code: "laser_tag",
      },
      {
        nameI18n: "Rental.Infos.Facilities.life_size_games",
        code: "life_size_games",
      },
      {
        nameI18n: "Rental.Infos.Facilities.mini_golf",
        code: "mini_golf",
      },
      {
        nameI18n: "Rental.Infos.Facilities.home_theater",
        code: "home_theater",
      },
      {
        nameI18n: "Rental.Infos.Facilities.piano",
        code: "piano",
      },
      {
        nameI18n: "Rental.Infos.Facilities.record_player",
        code: "record_player",
      },
      {
        nameI18n: "Rental.Infos.Facilities.sound_system",
        code: "sound_system",
      },
      {
        nameI18n: "Rental.Infos.Facilities.theme_room",
        code: "theme_room",
      },
      {
        nameI18n: "Rental.Infos.Facilities.childrens_bike",
        code: "childrens_bike",
      },
      {
        nameI18n: "Rental.Infos.Facilities.playroom",
        code: "playroom",
      },
      {
        nameI18n: "Rental.Infos.Facilities.stair_gates",
        code: "stair_gates",
      },
      {
        nameI18n: "Rental.Infos.Facilities.board_games",
        code: "board_games",
      },
      {
        nameI18n: "Rental.Infos.Facilities.fireplace_guards",
        code: "fireplace_guards",
      },
      {
        nameI18n: "Rental.Infos.Facilities.playground",
        code: "playground",
      },
      {
        nameI18n: "Rental.Infos.Facilities.outlet_covers",
        code: "outlet_covers",
      },
      {
        nameI18n: "Rental.Infos.Facilities.table_corner_guards",
        code: "table_corner_guards",
      },
      {
        nameI18n: "Rental.Infos.Facilities.window_guards",
        code: "window_guards",
      },
      {
        nameI18n: "Rental.Infos.Facilities.laptop_friendly_workspace",
        code: "laptop_friendly_workspace",
      },
      {
        nameI18n: "Rental.Infos.Facilities.resort_access",
        code: "resort_access",
      },
      {
        nameI18n: "Rental.Infos.Facilities.bicycle",
        code: "bicycle",
      },
      {
        nameI18n: "Rental.Infos.Facilities.boat_slip",
        code: "boat_slip",
      },
      {
        nameI18n: "Rental.Infos.Facilities.alfresco_dining",
        code: "alfresco_dining",
      },
      {
        nameI18n: "Rental.Infos.Facilities.outdoor_seating",
        code: "outdoor_seating",
      },
      {
        nameI18n: "Rental.Infos.Facilities.patio_or_balcony",
        code: "patio_or_balcony",
      },
      {
        nameI18n: "Rental.Infos.Facilities.sun_loungers",
        code: "sun_loungers",
      },
      {
        nameI18n: "Rental.Infos.Facilities.elevator",
        code: "elevator",
      },
      {
        nameI18n: "Rental.Infos.Facilities.hockey_rink",
        code: "hockey_rink",
      },
      {
        nameI18n: "Rental.Infos.Facilities.pool",
        code: "pool",
      },
    ],
  },

  {
    nameI18n: "Rental.Infos.Facilities.title_desk",
    Icon: WifiIcon,
    facilities: [
      {
        nameI18n: "Rental.Infos.Facilities.desk",
        code: "desk",
      },
      {
        nameI18n: "Rental.Infos.Facilities.computer_screen",
        code: "computer_screen",
      },
      {
        nameI18n: "Rental.Infos.Facilities.desk_chair",
        code: "desk_chair",
      },
      {
        nameI18n: "Rental.Infos.Facilities.wifi", // doublon
        code: "wifi",
      },
      {
        nameI18n: "Rental.Infos.Facilities.wif_portable",
        code: "wif_portable",
      },
      {
        nameI18n: "Rental.Infos.Facilities.wifi_paid",
        code: "wifi_paid",
      },
      {
        nameI18n: "Rental.Infos.Facilities.printer",
        code: "printer",
      },
      {
        nameI18n: "Rental.Infos.Facilities.phone",
        code: "phone",
      },
      {
        nameI18n: "Rental.Infos.Facilities.ethernet_connection",
        code: "ethernet_connection",
      },
      {
        nameI18n: "Rental.Infos.Facilities.cabinet_locks",
        code: "cabinet_locks",
      },
    ],
  },

  {
    nameI18n: "Rental.Infos.Facilities.title_place",
    Icon: PinOnMapIcon,
    facilities: [
      {
        nameI18n: "Rental.Infos.Facilities.beach",
        code: "beach",
      },
      {
        nameI18n: "Rental.Infos.Facilities.laundromat",
        code: "laundromat",
      },
      {
        nameI18n: "Rental.Infos.Facilities.private_access",
        code: "private_access",
      },
      {
        nameI18n: "Rental.Infos.Facilities.hotel_access",
        code: "hotel_access",
      },
      {
        nameI18n: "Rental.Infos.Facilities.ski_access",
        code: "ski_access",
      },
      {
        nameI18n: "Rental.Infos.Facilities.beachfront",
        code: "beachfront",
      },
      {
        nameI18n: "Rental.Infos.Facilities.duty_free",
        code: "duty_free",
      },
      {
        nameI18n: "Rental.Infos.Facilities.spa",
        code: "spa",
      },
      {
        nameI18n: "Rental.Infos.Facilities.marina",
        code: "marina",
      },
      {
        nameI18n: "Rental.Infos.Facilities.museums",
        code: "museums",
      },
      {
        nameI18n: "Rental.Infos.Facilities.amusement_park",
        code: "amusement_park",
      },
      {
        nameI18n: "Rental.Infos.Facilities.water_park",
        code: "water_park",
      },
      {
        nameI18n: "Rental.Infos.Facilities.wine_tours",
        code: "wine_tours",
      },
      {
        nameI18n: "Rental.Infos.Facilities.zoo",
        code: "zoo",
      },
      {
        nameI18n: "Rental.Infos.Facilities.bird_watching",
        code: "bird_watching",
      },
      {
        nameI18n: "Rental.Infos.Facilities.casino",
        code: "casino",
      },
      {
        nameI18n: "Rental.Infos.Facilities.horse_riding",
        code: "horse_riding",
      },
      {
        nameI18n: "Rental.Infos.Facilities.factory_outlets",
        code: "factory_outlets",
      },
      {
        nameI18n: "Rental.Infos.Facilities.shopping",
        code: "shopping",
      },
      {
        nameI18n: "Rental.Infos.Facilities.water_sports",
        code: "water_sports",
      },
      {
        nameI18n: "Rental.Infos.Facilities.whale_watching",
        code: "whale_watching",
      },
      {
        nameI18n: "Rental.Infos.Facilities.fauna_watching",
        code: "fauna_watching",
      },
      {
        nameI18n: "Rental.Infos.Facilities.hospital",
        code: "hospital",
      },
      {
        nameI18n: "Rental.Infos.Facilities.city_center",
        code: "city_center",
      },
      {
        nameI18n: "Rental.Infos.Facilities.golf",
        code: "golf",
      },
      {
        nameI18n: "Rental.Infos.Facilities.golf_view",
        code: "golf_view",
      },
      {
        nameI18n: "Rental.Infos.Facilities.lake",
        code: "lake",
      },
      {
        nameI18n: "Rental.Infos.Facilities.lake_border",
        code: "lake_border",
      },
      {
        nameI18n: "Rental.Infos.Facilities.lake_view",
        code: "lake_view",
      },
      {
        nameI18n: "Rental.Infos.Facilities.mountain",
        code: "mountain",
      },
      {
        nameI18n: "Rental.Infos.Facilities.mountain_view",
        code: "mountain_view",
      },
      {
        nameI18n: "Rental.Infos.Facilities.see",
        code: "see",
      },
      {
        nameI18n: "Rental.Infos.Facilities.see_border",
        code: "see_border",
      },
      {
        nameI18n: "Rental.Infos.Facilities.see_view",
        code: "see_view",
      },
      {
        nameI18n: "Rental.Infos.Facilities.holiday_village",
        code: "holiday_village",
      },
      {
        nameI18n: "Rental.Infos.Facilities.river",
        code: "river",
      },
      {
        nameI18n: "Rental.Infos.Facilities.campaign",
        code: "campaign",
      },
      {
        nameI18n: "Rental.Infos.Facilities.slopes_access",
        code: "slopes_access",
      },
      {
        nameI18n: "Rental.Infos.Facilities.access_to_slopes",
        code: "access_to_slopes",
      },
      {
        nameI18n: "Rental.Infos.Facilities.access_to_slopes",
        code: "access_to_slopes",
      },
      {
        nameI18n: "Rental.Infos.Facilities.city",
        code: "city",
      },
      {
        nameI18n: "Rental.Infos.Facilities.river_view",
        code: "river_view",
      },
      {
        nameI18n: "Rental.Infos.Facilities.lake_border",
        code: "lake_border",
      },
      {
        nameI18n: "Rental.Infos.Facilities.basket_ball",
        code: "basket_ball",
      },
      {
        nameI18n: "Rental.Infos.Facilities.alpin_ski",
        code: "alpin_ski",
      },
      {
        nameI18n: "Rental.Infos.Facilities.cycling",
        code: "cycling",
      },
      {
        nameI18n: "Rental.Infos.Facilities.deep_sea_fishing",
        code: "deep_sea_fishing",
      },
      {
        nameI18n: "Rental.Infos.Facilities.fishing",
        code: "fishing",
      },
      {
        nameI18n: "Rental.Infos.Facilities.coastal_fishing",
        code: "coastal_fishing",
      },
      {
        nameI18n: "Rental.Infos.Facilities.fly_fishing",
        code: "fly_fishing",
      },
      {
        nameI18n: "Rental.Infos.Facilities.freshwater_fishing",
        code: "freshwater_fishing",
      },
      {
        nameI18n: "Rental.Infos.Facilities.angling",
        code: "angling",
      },
      {
        nameI18n: "Rental.Infos.Facilities.optional_golf_package",
        code: "optional_golf_package",
      },
      {
        nameI18n: "Rental.Infos.Facilities.hiking",
        code: "hiking",
      },
      {
        nameI18n: "Rental.Infos.Facilities.hunting",
        code: "hunting",
      },
      {
        nameI18n: "Rental.Infos.Facilities.ice_skating",
        code: "ice_skating",
      },
      {
        nameI18n: "Rental.Infos.Facilities.jet_ski",
        code: "jet_ski",
      },
      {
        nameI18n: "Rental.Infos.Facilities.kayaking",
        code: "kayaking",
      },
      {
        nameI18n: "Rental.Infos.Facilities.vtt",
        code: "vtt",
      },
      {
        nameI18n: "Rental.Infos.Facilities.cycling",
        code: "cycling",
      },
      {
        nameI18n: "Rental.Infos.Facilities.escalation",
        code: "escalation",
      },
      {
        nameI18n: "Rental.Infos.Facilities.alpinism",
        code: "alpinism",
      },
      {
        nameI18n: "Rental.Infos.Facilities.parasailing",
        code: "parasailing",
      },
      {
        nameI18n: "Rental.Infos.Facilities.pontoon_fishing",
        code: "pontoon_fishing",
      },
      {
        nameI18n: "Rental.Infos.Facilities.rafting",
        code: "rafting",
      },
      {
        nameI18n: "Rental.Infos.Facilities.rock_climbing",
        code: "rock_climbing",
      },
      {
        nameI18n: "Rental.Infos.Facilities.veil",
        code: "veil",
      },
      {
        nameI18n: "Rental.Infos.Facilities.diving",
        code: "diving",
      },
      {
        nameI18n: "Rental.Infos.Facilities.optional_lift_pass",
        code: "optional_lift_pass",
      },
      {
        nameI18n: "Rental.Infos.Facilities.ski_lift_pass",
        code: "ski_lift_pass",
      },
      {
        nameI18n: "Rental.Infos.Facilities.ski",
        code: "ski",
      },
      {
        nameI18n: "Rental.Infos.Facilities.ski_nautic",
        code: "ski_nautic",
      },
      {
        nameI18n: "Rental.Infos.Facilities.snorkelling",
        code: "snorkelling",
      },
      {
        nameI18n: "Rental.Infos.Facilities.diving",
        code: "diving",
      },
      {
        nameI18n: "Rental.Infos.Facilities.snowboarding",
        code: "snowboarding",
      },
      {
        nameI18n: "Rental.Infos.Facilities.snowmobile",
        code: "snowmobile",
      },
      {
        nameI18n: "Rental.Infos.Facilities.potholing",
        code: "potholing",
      },
      {
        nameI18n: "Rental.Infos.Facilities.surf",
        code: "surf",
      },
      {
        nameI18n: "Rental.Infos.Facilities.bathing",
        code: "bathing",
      },
      {
        nameI18n: "Rental.Infos.Facilities.towed_buoy",
        code: "towed_buoy",
      },
      {
        nameI18n: "Rental.Infos.Facilities.rafting", // doublon
        code: "rafting",
      },
      {
        nameI18n: "Rental.Infos.Facilities.sailboard",
        code: "sailboard",
      },
      {
        nameI18n: "Rental.Infos.Facilities.wheelchair_accessible",
        code: "wheelchair_accessible",
      },
      {
        nameI18n: "Rental.Infos.Facilities.wheelchair_not_accessible",
        code: "wheelchair_not_accessible",
      },
      {
        nameI18n: "Rental.Infos.Facilities.family",
        code: "family",
      },
      {
        nameI18n: "Rental.Infos.Facilities.historical",
        code: "historical",
      },
      {
        nameI18n: "Rental.Infos.Facilities.romantic",
        code: "romantic",
      },
      {
        nameI18n: "Rental.Infos.Facilities.single_level_home",
        code: "single_level_home",
      },
    ],
  },

  {
    nameI18n: "Rental.Infos.Facilities.title_services",
    Icon: ServiceBellIcon,
    facilities: [
      {
        nameI18n: "Rental.Infos.Facilities.breakfast_reservation",
        code: "breakfast_reservation",
      },
      {
        nameI18n: "Rental.Infos.Facilities.breakfast_included",
        code: "breakfast_included",
      },
      {
        nameI18n: "Rental.Infos.Facilities.housekeeping_available",
        code: "housekeeping_available",
      },
      {
        nameI18n: "Rental.Infos.Facilities.housekeeping_included",
        code: "housekeeping_included",
      },
      {
        nameI18n: "Rental.Infos.Facilities.housekeeping_optional",
        code: "housekeeping_optional",
      },
      {
        nameI18n: "Rental.Infos.Facilities.long_stay_ok",
        code: "long_stay_ok",
      },
      {
        nameI18n: "Rental.Infos.Facilities.luggage_storage_ok",
        code: "luggage_storage_ok",
      },
      {
        nameI18n: "Rental.Infos.Facilities.meal_delivery_service",
        code: "meal_delivery_service",
      },
      {
        nameI18n: "Rental.Infos.Facilities.car_available",
        code: "car_available",
      },
      {
        nameI18n: "Rental.Infos.Facilities.driver",
        code: "driver",
      },
      {
        nameI18n: "Rental.Infos.Facilities.concierge",
        code: "concierge",
      },
      {
        nameI18n: "Rental.Infos.Facilities.massage",
        code: "massage",
      },
      {
        nameI18n: "Rental.Infos.Facilities.private_cooker",
        code: "private_cooker",
      },
      {
        nameI18n: "Rental.Infos.Facilities.babysitter_recommendations",
        code: "babysitter_recommendations",
      },
    ],
  },
];

export default res;
