import React from "react";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { CheckBox } from "../../../Common/CheckBox/CheckBox";
import {
  RegisterPlatformCheckboxesProps,
  RegisterPlatformOtherItemProps,
} from "./RegisterPlatform.type";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { Button } from "../../../Common/Button/Button";
import TrashIcon from "../../../../assets/icons/trash.svg?react";
import AirBnBIcon from "../../../../assets/icons/airbnb.svg?react";
import BookingIcon from "../../../../assets/icons/booking.svg?react";
import VRBOIcon from "../../../../assets/icons/vrbo.svg?react";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import {Title} from "../../../Common/Title/Title";

export const RegisterPlatformCheckboxes: React.FC<
  RegisterPlatformCheckboxesProps
> = ({ value, error, loading = false, onChange = () => {} }) => {
  const { t } = useTranslation();

  const [isOtherVisible, setIsOtherVisible] = useState<boolean>(true);
  const [currentValue, setCurrentValue] = useState<{
    platforms: string[];
    others: string[];
  }>({ platforms: ["booking", "vrbo"], others: [] });

  const [message, setMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    const platforms = value?.filter(
      (v) => v === "airbnb" || v === "booking" || v === "vrbo"
    );
    const others = value?.filter(
      (v) => v !== "airbnb" && v !== "booking" && v !== "vrbo"
    );

    if (others?.length > 0) {
      setCurrentValue({
        platforms,
        others,
      });

      setIsOtherVisible(true);
    } else {
      setCurrentValue({
        platforms,
        others: [""],
      });

      setIsOtherVisible(false);
    }
  }, []);

  const handleChangeOtherPlatorm = (index: number, value: string) => {
    const nextValue = currentValue.others;
    nextValue[index] = value;

    setCurrentValue({
      platforms: currentValue.platforms,
      others: nextValue,
    });

    onChange({
      platforms: currentValue.platforms,
      others: nextValue,
      isOtherVisible,
    });
  };

  const handleAddPlatform = () => {
    const nextValue = currentValue.others;
    if(nextValue.length > 10) {
      setMessage(t("Register.Platform.maxOther"));
      return;
    }
    nextValue.push("");
    setCurrentValue({
      platforms: currentValue.platforms,
      others: nextValue,
    });

    onChange({
      platforms: currentValue.platforms,
      others: nextValue,
      isOtherVisible,
    });
  };

  const handleDeletePlatform = (index: number) => {
    const nextValue = currentValue.others;
    nextValue.splice(index, 1);
    setCurrentValue({
      platforms: currentValue.platforms,
      others: nextValue,
    });

    if (typeof onChange === "function") {
      onChange({
        platforms: currentValue.platforms,
        others: nextValue,
        isOtherVisible,
      });
    }
  };

  const handleChangePlatforms = (platform: string, value: boolean) => {
    const nextPlatforms = currentValue.platforms?.filter((p) => p !== platform);
    if (value) {
      nextPlatforms.push(platform);
    }

    setCurrentValue({
      platforms: nextPlatforms,
      others: currentValue.others,
    });

    onChange({
      platforms: nextPlatforms,
      others: currentValue.others,
      isOtherVisible,
    });
  };

  const handleChangeIsOtherVisible = (value: boolean) => {
    setIsOtherVisible(value);

    onChange({
      platforms: currentValue.platforms,
      others: currentValue.others,
      isOtherVisible: value,
    });
  };

  return (
    <div>
      <div className="mt-8">
        <p className="font-semibold text-sm">{t("Register.Platform.select")}</p>

        <CheckBox
          label={
            <span className="font-semibold text-sm">
              {t("Register.Platform.airbnb")}{" "}
            </span>
          }
          onChange={(value) => handleChangePlatforms("airbnb", value)}
          value={currentValue?.platforms.includes("airbnb")}
          disabled={loading}
          withBorder={true}
          Icon={AirBnBIcon}
        />

        <CheckBox
          label={
            <span className="font-semibold text-sm">
              {t("Register.Platform.booking")}{" "}
            </span>
          }
          onChange={(value) => handleChangePlatforms("booking", value)}
          value={currentValue?.platforms.includes("booking")}
          disabled={loading}
          withBorder={true}
          Icon={BookingIcon}
        />

        <CheckBox
          label={
            <span className="font-semibold text-sm text-secondary">
              {t("Register.Platform.vrbo")}
            </span>
          }
          onChange={(value) => handleChangePlatforms("vrbo", value)}
          value={currentValue?.platforms.includes("vrbo")}
          withBorder={true}
          Icon={VRBOIcon}
          disabled={true}
        />

        <CheckBox
          label={
            <span className="font-semibold text-sm">
              {t("Register.Platform.other")}
            </span>
          }
          onChange={handleChangeIsOtherVisible}
          disabled={loading}
          value={isOtherVisible}
          withBorder={true}
        />
      </div>

      {isOtherVisible && (
        <div className="flex flex-col mt-6">
          <Title type="secondary">
            {t("Register.Platform.addOther")}
            {message && <ErrorMessage errorType="FORM">{message}</ErrorMessage>}

          </Title>


          {currentValue.others?.map((other, index) => {
            return (
              <RegisterPlatformOtherItem
                key={index}
                value={other}
                loading={loading}
                onChange={(value) => handleChangeOtherPlatorm(index, value)}
                onDelete={() => handleDeletePlatform(index)}
              />
            );
          })}

          <div className="flex mt-1">
            <Button
              type="secondary"
              onClick={handleAddPlatform}
              loading={loading}
              disabled={currentValue.others.length > 10}
            >
              {t("Register.Platform.add")}
            </Button>
          </div>
        </div>
      )}
      {error && <ErrorMessage errorType="FORM">{error}</ErrorMessage>}
    </div>
  );
};

const RegisterPlatformOtherItem: React.FC<RegisterPlatformOtherItemProps> = ({
  value,
  loading = false,
  onChange,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex space-x-2 items-end mb-1">
      <div className="flex-1">
        <TextInput
          value={value}
          placeholder={t("Register.Platform.platformName")}
          onChangeText={onChange}
          disabled={loading}
        />
      </div>

      <div>
        <Button
          type="secondary"
          LeftIcon={TrashIcon}
          onClick={onDelete}
        ></Button>
      </div>
    </div>
  );
};
