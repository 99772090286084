import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../Common/Button/Button";
import { TableListItemProps } from "../../Common/TableList/TableList.type";

import AirbnbIcon from "../../../assets/icons/airbnb.svg?react";
import BookingIcon from "../../../assets/icons/booking.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import LogInIcon from "../../../assets/icons/log-in.svg?react";
import LogOutIcon from "../../../assets/icons/log-out.svg?react";
import UserGroupIcon from "../../../assets/icons/user-group.svg?react";
import { RentalLightListItemResponse } from "../../../types/GETTypes";

export const ProgressionRentalsRulesTableItem: React.FC<TableListItemProps> = ({
  data,
  onClick = () => {},
}) => {
  const { t } = useTranslation();
  const rental: RentalLightListItemResponse = data;

  return (
    <tr
      className="cursor-pointer hover:bg-element-background/30"
      onClick={() => onClick(rental.id!)}
    >
      {/* Rental */}
      <td className="px-4 py-2 text-sm border-t-1 border-element-border">
        <div className="flex flex-row items-center gap-2">
          <div className="flex-shrink-0 object-fill w-12 h-12 overflow-hidden rounded-4px border-1 border-element-border/50">
            <img
              src={rental.cover}
              alt={rental?.name}
              className="flex-shrink-0 object-fill w-12 h-12"
            />
          </div>

          <div className="flex flex-col max-w-[300px]">
            <p className="font-semibold text-high-contrast">{rental?.name}</p>
            <p className="font-light text-low-contrast">{rental?.address}</p>
            <p className="font-light text-low-contrast">
              {`${rental?.postal_code} ${rental?.city}, ${rental?.country.name}`}
            </p>
          </div>
        </div>
      </td>

      {/* CAPACITY */}
      <td className="p-2 border-t-1 border-element-border w-52">
        <div className="flex items-center justify-center h-full gap-2">
          <UserGroupIcon />
          <div>
            <p className="font-light text-low-contrast">
              {rental?.guests_max_capacity > 1
                ? t("Progression.RentalsRules.guests", {
                    count: rental?.guests_max_capacity,
                  })
                : t("Progression.RentalsRules.guest", {
                    count: rental?.guests_max_capacity ?? 0,
                  })}
            </p>
          </div>
        </div>
      </td>

      {/* Checkin / Checkout */}
      <td className="p-2 border-t-1 border-element-border">
        {rental?.checkin_min_time || rental?.checkout_max_time ? (
          <div className="flex flex-col items-center gap-2">
            {rental?.checkin_min_time && (
              <div className="flex flex-row space-x-1 text-low-contrast">
                <LogInIcon />
                <span>{rental?.checkin_min_time}</span>
              </div>
            )}
            <div className="flex flex-row space-x-1 text-low-contrast">
              <LogOutIcon />
              <span>{rental?.checkout_max_time ?? "-"}</span>
            </div>
          </div>
        ) : (
          <p className="text-base font-light text-center text-low-contrast">
            {t("Global.notDefined")}
          </p>
        )}
      </td>

      {/* Extra traveller */}
      <td className="w-48 p-2 border-t-1 border-element-border">
        {rental?.price_night_added_per_guests! <= 0 ? (
          <p className="text-base font-light text-center text-low-contrast">
            {t("Global.notDefined")}
          </p>
        ) : (
          <p className="text-lg font-bold text-center text-high-contrast">
            {`+${rental?.price_night_added_per_guests}${t(
              "Global.currencySymbol"
            )}`}
          </p>
        )}
      </td>

      {/* Cleaning fees*/}
      <td className="w-48 p-2 border-t-1 border-element-border">
        <p className="text-lg font-bold text-center text-high-contrast">{`${
          rental?.cleaning_default
        }${t("Global.currencySymbol")}`}</p>
      </td>

      {/* Deposit */}
      <td className="w-48 p-2 border-t-1 border-element-border">
        {Number(rental?.deposit) ? (
          <p className="text-lg font-bold text-center text-high-contrast">
            {`${rental?.deposit}${t("Global.currencySymbol")}`}
          </p>
        ) : (
          <p className="text-base font-light text-center text-low-contrast">
            {t("Global.notDefined")}
          </p>
        )}
      </td>

      {/* Holiday tax */}
      <td className="w-48 p-2 border-t-1 border-element-border">
        {Number(rental?.city_tax_default) ? (
          <p className="text-lg font-bold text-center text-high-contrast">
            {`${rental?.city_tax_default}${t("Global.currencySymbol")}`}
          </p>
        ) : null}

        {Number(rental?.city_tax_percent) ? (
          <p className="text-lg font-bold text-center text-high-contrast">
            {`${rental?.city_tax_percent}%`}
          </p>
        ) : null}

        {!Number(rental?.city_tax_default) &&
        !Number(rental?.city_tax_percent) ? (
          <p className="text-base font-light text-center text-low-contrast">
            {t("Global.notDefined")}
          </p>
        ) : null}
      </td>

      {/* Price Multiplier */}
      <td className="p-2 border-t-1 border-element-border">
        <div className="flex flex-col justify-center gap-2">
          {rental?.external_connexions?.platform_airbnb ||
          rental?.external_connexions?.platform_booking ? (
            <>
              {rental?.external_connexions?.platform_airbnb && (
                <div className="flex flex-row items-center justify-center space-x-1 text-low-contrast">
                  <AirbnbIcon width={24} height={24} />
                  <span>
                    +
                    {
                      rental?.external_connexions?.platform_airbnb
                        .price_multiplier_value
                    }
                    %
                  </span>
                </div>
              )}

              {rental?.external_connexions?.platform_booking && (
                <div className="flex flex-row items-center justify-center space-x-1 text-low-contrast">
                  <BookingIcon width={24} height={24} />
                  <span>
                    +
                    {
                      rental?.external_connexions?.platform_booking
                        .price_multiplier_value
                    }
                    %
                  </span>
                </div>
              )}
            </>
          ) : (
            <p className="text-base font-light text-center text-low-contrast">
              {t("Global.notDefined")}
            </p>
          )}
        </div>
      </td>

      {/* Edit button */}
      <td className="p-4 w-28 border-t-1 border-element-border">
        <Button
          type="secondary"
          onClick={() => onClick(rental.id!)}
          RightIcon={EditIcon}
          size="small"
        >
          {t("Global.edit")}
        </Button>
      </td>
    </tr>
  );
};
