import React from "react";
import {useTranslation} from "react-i18next";
import {File} from "../../types/GETTypes";
import {ValueType} from "../../types/commonTypes";
import {mimeTypeLabel} from "../../helpers/fileHelper";
import DownloadIcon from "../../assets/icons/download.svg?react";
import moment from "moment";

export interface BookingContractDocsItemProps {
  data: any;
  onClick: (id: ValueType) => void;
}
export const BookingContractDocsItem: React.FC<
  BookingContractDocsItemProps
> = ({data, onClick = () => {}}) => {
  const { mime_type, original_url, name,updated_at}: File = data;
  const {t} = useTranslation();

  const {type} = mimeTypeLabel(t, mime_type) || {};

  const isImage = type === "image";
  const isVideo = type === "video";

  const date = updated_at ? moment(updated_at).format("DD/MM/YYYY") : ""

  return (
    <tr
      className="hover:bg-element-background/40"
      // onClick={() => onClick(id)}
    >
      <td className="flex flex-row items-center py-4 space-x-4 text-sm border-t-1 border-element-border ps-4 pe-2">
        <div className="relative">
          {isImage ? (
            <div className="object-fill w-24 h-20 overflow-hidden rounded-4px border-1 border-element-border/50">
              <img src={original_url} className="object-fill w-24 h-20" />
            </div>
          ) : isVideo ? (
            <div className="relative w-24 h-20">
              <video
                src={original_url}
                className="h-full w-full object-fill rounded-lg"
                muted
                preload="metadata"
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-25">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.752 11.168l-7.197-4.132A1 1 0 006 7.868v8.264a1 1 0 001.555.832l7.197-4.132a1 1 0 000-1.664z"
                  />
                </svg>
              </div>
            </div>
          ) : (
            <div className="w-24 h-20 rounded-4px border-1 border-element-border/50 bg-slate-200" />
          )}
        </div>
      </td>

      <td className="py-3 text-sm ps-4 pe-2 pl-8 text-sm border-t-1 border-element-border">
        <div className="flex flex-col">
          <p className="font-semibold text-high-contrast">{name}</p>
        </div>
      </td>

      <td className="px-2 py-4 text-sm border-t-1 pl-8 border-element-border">
        <div className="flex flex-col">
          <p className="font-semibold text-high-contrast uppercase">{date}</p>
        </div>
      </td>

      <td className="px-2 py-4 text-sm border-t-1 pl-8 border-element-border">
        <div className="flex flex-col">
          <p className="font-semibold text-high-contrast uppercase">{type}</p>
        </div>
      </td>

      <td className="px-2 py-4 text-sm border-t-1  pl-8 border-element-border pr-4">
        <div className="flex flex-col">
          <a className="" href={original_url} download={name} target="_blank">
            <DownloadIcon className="text-low-contrast text-xl cursor-pointer" />
          </a>
        </div>
      </td>
    </tr>
  );
};
