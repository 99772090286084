import React from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "../../../assets/icons/edit.svg?react";
import HomeIcon from "../../../assets/icons/home.svg?react";
import { Card } from "../../Common/Card/Card";

export const ReservationGuestCardSkeleton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Card
      Icon={HomeIcon}
      label={t("Booking.Guest.title")}
      button={{ Icon: EditIcon, label: t("Global.edit"), skeleton: true }}
    >
      <div className="animate-pulse">
        <div className="flex flex-row items-center justify-between px-4 pb-2 space-x-3 border-b-1 border-element-border">
          <div className="flex items-center space-x-4">
            <div className="relative">
              <div
                className="absolute z-10 border-2 border-white rounded-full bg-slate-200"
                style={{ top: 22, left: 26, width: 16, height: 16 }}
              />

              <div className="w-10 h-10 rounded-full border-1 border-element-border/50 bg-slate-200" />
            </div>

            <div>
              <p className="w-5/6 h-3 rounded bg-slate-200"></p>
              <div>
                <p className="w-5/6 h-3 rounded bg-slate-200"></p>
                <br />
                <p className="w-5/6 h-3 rounded bg-slate-200"></p>
              </div>
            </div>
          </div>

          <p className="w-5/6 h-3 rounded bg-slate-200"></p>
        </div>

        <div className="flex flex-col gap-4 p-4">
          <div className="flex flex-row items-center">
            <div className="flex-1 space-y-1">
              <p className="w-5/6 h-3 rounded bg-slate-200"></p>
              <p className="w-5/6 h-3 rounded bg-slate-200"></p>
            </div>

            <div className="flex-1 space-y-1">
              <p className="w-5/6 h-3 rounded bg-slate-200"></p>
              <p className="w-5/6 h-3 rounded bg-slate-200"></p>
            </div>
          </div>

          <div className="flex flex-row items-center">
            <div className="flex-1 space-y-1">
              <p className="w-5/6 h-3 rounded bg-slate-200"></p>
              <p className="w-5/6 h-3 rounded bg-slate-200"></p>
            </div>

            <div className="flex-1 space-y-1">
              <p className="w-5/6 h-3 rounded bg-slate-200"></p>
              <div className="flex items-center space-x-2">
                <p className="w-5/6 h-3 rounded bg-slate-200"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
