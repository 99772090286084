import React from "react";
import { useTranslation } from "react-i18next";
import { InProgressPlaceholder } from "../../components/Common/Dashboard/InProgressPlaceholder";
import { SubscriptionLayout } from "../../components/Layout/SubscriptionLayout/SubscriptionLayout";

export const SettingDetailsFacturationPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SubscriptionLayout navbarActiveItem="details">
      <InProgressPlaceholder />
    </SubscriptionLayout>
  );
};
