import { TableListItemProps } from "../../../Common/TableList/TableList.type";
import { cn } from "../../../../helpers/classHelper";

import AirbnbIcon from "../../../../assets/icons/airbnb-white.svg?react";
import ArrowRedirect from "../../../../assets/icons/arrow-up-right.svg?react";
import { Badge } from "../../../Common/Badge/Badge";

export const AirbnbRentalsListItem = ({
  isSelected,
  draggableItem,
  disabled,
  onClick,
  data,
  onClickAction,
}: TableListItemProps) => {
  return (
    <>
      <tr className="cursor-pointer hover:bg-element-background/40">
        <td
          className={cn(
            `flex flex-row px-4 py-4 space-x-4 text-sm border-element-border border-t`,
          )}
        >
          <div className={"relative"}>
            <img
              src={
                "https://media.discordapp.net/attachments/991289102882320384/1309189365632598026/image.png?ex=6740ad1c&is=673f5b9c&hm=65fc1f8a0a79cff5878ef8997753fcadc8e76d0e8637768bafe83c9d28f76c50&=&format=webp&quality=lossless"
              }
              width={80}
              className={"border-2 rounded-md border-gray-400"}
            />
            <AirbnbIcon
              className={"absolute top-1/3 right-0 w-6 h-6 translate-x-3"}
            />
          </div>
          <div className={"flex flex-col"}>
            <a
              href={""}
              className={"underline flex items-center gap-2 font-bold text-lg"}
            >
              Lafayette Cosy Flat
              <ArrowRedirect className={"w-4 h-4"} />
            </a>
            <p className={"mt-1 text-gray-500 text-lg"}>
              51 Boulevard Garibaldi <br />
              75015 Paris
            </p>
          </div>
        </td>
        <td className={cn(`font-bold border-t text-lg`)}>
          <p>elysio@superhote.com</p>
        </td>
        <td className={cn("border-t")}>
          <Badge size={"large"} status={"success"} label={"Connecté"}></Badge>
        </td>
      </tr>
    </>
  );
};
