import React from "react";
import { CalloutProps } from "./Callout.type";
import CheckCircleIcon from "../../../assets/icons/check-circle-active.svg?react";
export const Callout: React.FC<CalloutProps> = ({ title, message }) => {
  return (
    <div className="flex flex-row space-x-2 p-2 rounded-6px bg-element-background">
      <div>
        <CheckCircleIcon className="w-5 h-5" />
      </div>

      <div>
        <p className="text-sm font-semibold">{title}</p>

        {message && (
          <p className="mt-1 text-sm font-light text-low-contrast">{message}</p>
        )}
      </div>
    </div>
  );
};
