import React from "react";

export const ChecklistTableSkeleton: React.FC = () => {
  return (
    <tr className="cursor-pointer hover:bg-element-background/40 animate-pulse">
      <td className="w-4/6 px-2 py-2 text-sm border-t-1 border-element-border">
        <div className="flex flex-col">
          <p className="h-2 font-semibold rounded w-60 text-high-contrast bg-slate-300"></p>
          <p className="w-32 h-2 mt-2 font-light rounded text-low-contrast bg-slate-200"></p>
        </div>
      </td>

      <td className="w-1/6 px-2 py-2 text-sm border-t-1 border-element-border">
        <div className="h-9 w-28 bg-slate-200 rounded-6px"></div>
      </td>

      <td className="w-1/6 px-2 py-2 text-sm border-t-1 border-element-border">
        <p className="w-12 h-2 font-semibold rounded text-high-contrast bg-slate-300"></p>
      </td>

      <td className="w-1/6 px-2 py-2 text-sm border-t-1 border-element-border">
        <div className="w-8 h-8 rounded bg-slate-200"></div>
      </td>
    </tr>
  );
};
