import React, {useState} from "react";
import {Button} from "../../../Common/Button/Button";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import SmartPhoneIcon from "../../../../assets/icons/smartphone.svg?react";
import DesktopIcon from "../../../../assets/icons/desktop.svg?react";
import {useTranslation} from "react-i18next";

interface PreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  url: string;
}
const PreviewModal = ({isOpen, onClose, url}: PreviewModalProps) => {
  const {t} = useTranslation();
  const [view, setView] = useState("mobile");

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[999] flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="w-full h-full flex flex-col bg-white rounded-lg shadow-lg">
        <div className="flex p-4 flex-col h-full justify-center">
          <div className="flex flex-1 mb-4 ">
            <div className="flex">
              <Button
                LeftIcon={ArrowLeftIcon}
                displayLargeBtn={false}
                type="secondary"
                onClick={onClose}
              >
                {t("Global.back")}
              </Button>
            </div>
            <div className="flex flex-1 justify-center pr-[9rem]">
              <Button
                LeftIcon={SmartPhoneIcon}
                displayLargeBtn={false}
                type="secondary"
                buttonClassName={`rounded-none rounded-l-[6px] ${
                  view === "mobile" && "bg-none shadow-none bg-gray-100"
                }`}
                onClick={() => setView("mobile")}
              >
                {t("Rental.Infos.TravelerPage.Preview.mobile")}
              </Button>
              <Button
                LeftIcon={DesktopIcon}
                displayLargeBtn={false}
                type="secondary"
                buttonClassName={`rounded-none rounded-r-[6px] ${
                  view === "desktop" && "bg-none shadow-none bg-gray-100"
                }`}
                onClick={() => setView("desktop")}
              >
                {t("Rental.Infos.TravelerPage.Preview.desktop")}
              </Button>
            </div>
          </div>
          <div
            className={`flex justify-center items-center ${
              view === "mobile" ? "w-full h-full" : "hidden"
            }`}
          >
            <iframe
              src={url}
              className="w-[400px] h-full border-1 border-element-border rounded-6px"
            ></iframe>
          </div>
          <div
            className={`flex justify-center items-center ${
              view === "desktop" ? "w-full h-full" : "hidden"
            }`}
          >
            <iframe
              src={url}
              className="w-full h-full border-1 border-element-border rounded-6px"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewModal;
