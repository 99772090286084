export default [
  {
    id: 1,
    platform: "airbnb",
    fullName: "Elie Al Shami",
    date: "04/05/2023",
    rate: 4.8,
    comment:
      "È stato un soggiorno fantastico! La città è meravigliosa e la location è stata perfetta per visitaria. Appartamento vicino alla metro, supermercato ebar/ristoranti. Confortevole e pulito, letto comodissimo e tutti i servizi disponibili incluso Wi-Fi e Netflix. Host sempre disponibile e reattivo ad ogni messaggio in pochissimo tempo. Torneremo sicuramente!",
  },
  {
    id: 2,
    platform: "booking",
    fullName: "Sophie Martin",
    date: "15/02/2023",
    rate: 4.5,
    comment:
      "We had a wonderful stay at this lovely apartment. The location was great, close to shops and restaurants. The apartment was clean and well-equipped. The host was very responsive to our messages. Highly recommended!",
  },
  {
    id: 3,
    platform: "VRBO",
    fullName: "Hans Müller",
    date: "23/11/2022",
    rate: 4.2,
    comment:
      "Es war ein toller Aufenthalt in dieser Ferienwohnung. Die Lage war perfekt und die Wohnung war sauber und gemütlich. Der Gastgeber war freundlich und hilfsbereit. Wir kommen gerne wieder!",
  },
  {
    id: 4,
    platform: "airbnb",
    fullName: "Maria Rodriguez",
    date: "10/07/2022",
    rate: 5.0,
    comment:
      "Ha sido una estancia maravillosa. El apartamento está muy bien ubicado, limpio y cómodo. El anfitrión fue muy amable y atento. Sin duda, volveremos a alojarnos aquí en nuestro próximo viaje.",
  },
  {
    id: 5,
    platform: "booking",
    fullName: "John Smith",
    date: "02/04/2022",
    rate: 4.7,
    comment:
      "We had a fantastic time staying at this property. The location was ideal, and the apartment was well-maintained and cozy. The host was extremely helpful and accommodating. We will definitely be back!",
  },
  {
    id: 6,
    platform: "VRBO",
    fullName: "Sophie Dupont",
    date: "18/09/2021",
    rate: 4.0,
    comment:
      "Nous avons passé un excellent séjour dans cet appartement. L'emplacement était idéal, proche des commerces et des transports en commun. L'appartement était propre et confortable. Le propriétaire était très réactif et serviable. Nous recommandons vivement cette location.",
  },
  {
    id: 7,
    platform: "booking",
    fullName: "Mark Johnson",
    date: "05/03/2021",
    rate: 3.8,
    comment:
      "Our stay was decent overall. The apartment was fine, but there were some minor issues with cleanliness. The location was convenient, and the host was responsive to our needs. It could use some improvements, but it served its purpose for our trip.",
  },
  {
    id: 8,
    platform: "airbnb",
    fullName: "Elena Petrova",
    date: "12/12/2020",
    rate: 4.3,
    comment:
      "Было замечательное пребывание. Квартира находится в отличном месте, близко ко всему. Квартира чистая и уютная. Хозяин всегда был готов помочь. Мы обязательно вернемся сюда в следующий раз!",
  },
  {
    id: 9,
    platform: "airbnb",
    fullName: "David Wilson",
    date: "27/07/2020",
    rate: 4.9,
    comment:
      "Our stay was amazing! The apartment was spotless and had all the amenities we needed. The location was perfect, close to restaurants and attractions. The host was incredibly helpful and responsive. We will definitely stay here again!",
  },
  {
    id: 10,
    platform: "booking",
    fullName: "Laura Garcia",
    date: "08/04/2020",
    rate: 3.5,
    comment:
      "Nuestra estancia fue aceptable. El apartamento tenía un precio razonable. La ubicación era buena, pero la limpieza podría haber sido mejor. El anfitrión fue amable y nos ayudó con nuestras preguntas. En general, fue una experiencia decente.",
  },
  {
    id: 11,
    platform: "VRBO",
    fullName: "Isabella Rossi",
    date: "14/03/2020",
    rate: 4.6,
    comment:
      "Abbiamo avuto un soggiorno fantastico in questo appartamento. La posizione era perfetta, vicino ai negozi e ai trasporti pubblici. L'appartamento era pulito e accogliente. Il proprietario è stato molto disponibile e gentile. Lo consigliamo vivamente!",
  },
  {
    id: 12,
    platform: "airbnb",
    fullName: "Thomas Müller",
    date: "05/09/2019",
    rate: 4.2,
    comment:
      "Unser Aufenthalt war angenehm. Die Wohnung war in Ordnung, aber es gab einige kleinere Probleme mit der Sauberkeit. Die Lage war praktisch, und der Gastgeber reagierte auf unsere Bedürfnisse. Es könnte einige Verbesserungen vertragen, aber es hat seinen Zweck für unsere Reise erfüllt.",
  },
  {
    id: 13,
    platform: "booking",
    fullName: "Emma Johnson",
    date: "20/05/2019",
    rate: 4.7,
    comment:
      "We had a great time at this property. The apartment was clean and well-maintained. The location was excellent, close to restaurants and public transportation. The host was friendly and responsive. We would stay here again in a heartbeat!",
  },
  {
    id: 14,
    platform: "airbnb",
    fullName: "Sophie Dupuis",
    date: "09/01/2019",
    rate: 3.9,
    comment:
      "Notre séjour était correct dans l'ensemble. L'appartement était correct, mais il y avait quelques problèmes mineurs de propreté. L'emplacement était pratique, et le propriétaire était réactif à nos besoins. Il pourrait utiliser quelques améliorations, mais il a rempli son rôle pour notre voyage.",
  },
  {
    id: 15,
    platform: "VRBO",
    fullName: "Michael Smith",
    date: "18/08/2018",
    rate: 4.8,
    comment:
      "We had a wonderful stay at this vacation rental. The location was perfect, close to the beach and local attractions. The rental was clean and well-equipped. The owner was very responsive and accommodating. Highly recommended!",
  },
  {
    id: 16,
    platform: "booking",
    fullName: "Maria Fernandez",
    date: "03/03/2018",
    rate: 4.4,
    comment:
      "Nuestra estancia fue agradable en general. El apartamento estaba limpio y cómodo. La ubicación era conveniente, cerca de tiendas y restaurantes. El anfitrión fue amable y respondió a nuestras preguntas. Recomendaríamos este lugar.",
  },
  {
    id: 17,
    platform: "VRBO",
    fullName: "Jean-Pierre Martin",
    date: "14/11/2017",
    rate: 4.6,
    comment:
      "Nous avons passé un excellent séjour dans cette location de vacances. L'emplacement était idéal, près de la plage et des attractions locales. La location était propre et bien équipée. Le propriétaire était très réactif et accommodant. Hautement recommandé !",
  },
  {
    id: 18,
    platform: "airbnb",
    fullName: "Oliver Schmidt",
    date: "27/06/2017",
    rate: 4.3,
    comment:
      "Unser Aufenthalt war angenehm. Die Wohnung war in Ordnung, aber es gab einige kleinere Probleme mit der Sauberkeit. Die Lage war praktisch, und der Gastgeber reagierte auf unsere Bedürfnisse. Es könnte einige Verbesserungen vertragen, aber es hat seinen Zweck für unsere Reise erfüllt.",
  },
  {
    id: 19,
    platform: "airbnb",
    fullName: "Lucía Rodríguez",
    date: "08/02/2017",
    rate: 4.9,
    comment:
      "Nuestra estancia fue increíble. El apartamento estaba impecable y tenía todas las comodidades que necesitábamos. La ubicación era perfecta, cerca de restaurantes y atracciones. El anfitrión fue increíblemente servicial y receptivo. ¡Definitivamente nos alojaremos aquí de nuevo!",
  },
  {
    id: 20,
    platform: "booking",
    fullName: "Julie Dupont",
    date: "12/09/2016",
    rate: 3.7,
    comment:
      "Notre séjour était correct dans l'ensemble. L'appartement était correct, mais il y avait quelques problèmes mineurs de propreté. L'emplacement était pratique, et le propriétaire était réactif à nos besoins. Il pourrait utiliser quelques améliorations, mais il a rempli son rôle pour notre voyage.",
  },
  {
    id: 21,
    platform: "VRBO",
    fullName: "Sophia Miller",
    date: "24/04/2016",
    rate: 4.5,
    comment:
      "We had a great experience staying at this vacation rental. The location was excellent, close to the beach and local attractions. The rental was clean and well-equipped. The owner was very responsive and provided useful local tips. Highly recommended!",
  },
  {
    id: 22,
    platform: "booking",
    fullName: "Luca Ferrari",
    date: "09/12/2015",
    rate: 4.8,
    comment:
      "Il nostro soggiorno è stato fantastico! L'appartamento era pulito e ben attrezzato. La posizione era perfetta, vicino a ristoranti e attrazioni locali. Il padrone di casa è stato molto disponibile e ci ha fornito preziosi consigli sulla zona. Altamente raccomandato!",
  },
  {
    id: 23,
    platform: "airbnb",
    fullName: "Emma Johnson",
    date: "15/07/2015",
    rate: 4.6,
    comment:
      "Our stay was wonderful. The apartment was clean and comfortable. The location was convenient, close to shops and restaurants. The host was friendly and provided us with helpful recommendations. We would definitely stay here again!",
  },
  {
    id: 24,
    platform: "airbnb",
    fullName: "Javier Rodríguez",
    date: "03/03/2015",
    rate: 4.7,
    comment:
      "Nuestra estancia fue muy agradable. El apartamento estaba limpio y bien equipado. La ubicación era perfecta, cerca de restaurantes y lugares de interés. El anfitrión fue amable y nos proporcionó valiosas recomendaciones. ¡Definitivamente volveríamos a alojarnos aquí!",
  },
  {
    id: 25,
    platform: "booking",
    fullName: "Marie Lefebvre",
    date: "18/11/2014",
    rate: 3.9,
    comment:
      "Notre séjour était correct. L'appartement était propre et bien situé. Cependant, il y avait quelques petits problèmes avec l'équipement. L'hôte était réactif, mais il y avait place à amélioration. Dans l'ensemble, c'était une expérience décente.",
  },
  {
    id: 26,
    platform: "VRBO",
    fullName: "Sophie Dupont",
    date: "24/06/2014",
    rate: 4.4,
    comment:
      "Nous avons passé un séjour agréable dans cette location de vacances. L'emplacement était idéal, proche de la plage et des attractions. La location était propre et bien équipée. Le propriétaire était réactif et serviable. Nous recommandons cette location.",
  },
  {
    id: 27,
    platform: "booking",
    fullName: "John Smith",
    date: "09/03/2014",
    rate: 4.9,
    comment:
      "Our stay was amazing! The apartment was spotless and had all the amenities we needed. The location was perfect, close to restaurants and attractions. The host was incredibly helpful and responsive. We will definitely stay here again!",
  },
  {
    id: 28,
    platform: "airbnb",
    fullName: "Elena Petrova",
    date: "16/12/2013",
    rate: 4.3,
    comment:
      "Было замечательное пребывание. Квартира находится в отличном месте, близко ко всему. Квартира чистая и уютная. Хозяин всегда был готов помочь. Мы обязательно вернемся сюда в следующий раз!",
  },
  {
    id: 29,
    platform: "airbnb",
    fullName: "David Wilson",
    date: "22/08/2013",
    rate: 4.8,
    comment:
      "Our stay was fantastic! The apartment was clean and well-maintained. The location was great, close to shops and restaurants. The host was friendly and provided us with helpful tips for the area. We would definitely return!",
  },
  {
    id: 30,
    platform: "booking",
    fullName: "Laura Garcia",
    date: "05/05/2013",
    rate: 3.6,
    comment:
      "Nuestra estancia fue aceptable. El apartamento tenía un precio razonable. La ubicación era buena, pero la limpieza podría haber sido mejor. El anfitrión fue amable y nos ayudó con nuestras preguntas. En general, fue una experiencia decente.",
  },
  {
    id: 31,
    platform: "VRBO",
    fullName: "Isabella Rossi",
    date: "14/01/2013",
    rate: 4.7,
    comment:
      "Abbiamo avuto un soggiorno fantastico in questo appartamento. La posizione era perfetta, vicino ai negozi e ai trasporti pubblici. L'appartamento era pulito e accogliente. Il proprietario è stato molto disponibile e gentile. Lo consigliamo vivamente!",
  },
  {
    id: 32,
    platform: "booking",
    fullName: "Thomas Müller",
    date: "27/08/2012",
    rate: 4.1,
    comment:
      "Unser Aufenthalt war angenehm. Die Wohnung war in Ordnung, aber es gab einige kleinere Probleme mit der Sauberkeit. Die Lage war praktisch, und der Gastgeber reagierte auf unsere Bedürfnisse. Es könnte einige Verbesserungen vertragen, aber es hat seinen Zweck für unsere Reise erfüllt.",
  },
  {
    id: 33,
    platform: "airbnb",
    fullName: "Emma Johnson",
    date: "10/03/2012",
    rate: 4.6,
    comment:
      "Our stay was wonderful. The apartment was clean and comfortable. The location was convenient, close to shops and restaurants. The host was friendly and provided us with helpful recommendations. We would definitely stay here again!",
  },
  {
    id: 34,
    platform: "airbnb",
    fullName: "Javier Rodríguez",
    date: "05/11/2011",
    rate: 4.8,
    comment:
      "Nuestra estancia fue muy agradable. El apartamento estaba limpio y bien equipado. La ubicación era perfecta, cerca de restaurantes y lugares de interés. El anfitrión fue amable y nos proporcionó valiosas recomendaciones. ¡Definitivamente volveríamos a alojarnos aquí!",
  },
  {
    id: 35,
    platform: "booking",
    fullName: "Marie Lefebvre",
    date: "18/07/2011",
    rate: 3.8,
    comment:
      "Notre séjour était correct. L'appartement était propre et bien situé. Cependant, il y avait quelques petits problèmes avec l'équipement. L'hôte était réactif, mais il y avait place à amélioration. Dans l'ensemble, c'était une expérience décente.",
  },
  {
    id: 36,
    platform: "VRBO",
    fullName: "Sophie Dupont",
    date: "24/02/2011",
    rate: 4.5,
    comment:
      "Nous avons passé un séjour agréable dans cette location de vacances. L'emplacement était idéal, proche de la plage et des attractions. La location était propre et bien équipée. Le propriétaire était réactif et serviable. Nous recommandons cette location.",
  },
  {
    id: 37,
    platform: "booking",
    fullName: "John Smith",
    date: "09/08/2010",
    rate: 4.9,
    comment:
      "Our stay was amazing! The apartment was spotless and had all the amenities we needed. The location was perfect, close to restaurants and attractions. The host was incredibly helpful and responsive. We will definitely stay here again!",
  },
  {
    id: 38,
    platform: "airbnb",
    fullName: "Elena Petrova",
    date: "16/04/2010",
    rate: 4.3,
    comment:
      "Было замечательное пребывание. Квартира находится в отличном месте, близко ко всему. Квартира чистая и уютная. Хозяин всегда был готов помочь. Мы обязательно вернемся сюда в следующий раз!",
  },
  {
    id: 39,
    platform: "airbnb",
    fullName: "David Wilson",
    date: "22/12/2009",
    rate: 4.8,
    comment:
      "Our stay was fantastic! The apartment was clean and well-maintained. The location was great, close to shops and restaurants. The host was friendly and provided us with helpful tips for the area. We would definitely return!",
  },
  {
    id: 40,
    platform: "booking",
    fullName: "Laura Garcia",
    date: "05/08/2009",
    rate: 3.6,
    comment:
      "Nuestra estancia fue aceptable. El apartamento tenía un precio razonable. La ubicación era buena, pero la limpieza podría haber sido mejor. El anfitrión fue amable y nos ayudó con nuestras preguntas. En general, fue una experiencia decente.",
  },
  {
    id: 41,
    platform: "VRBO",
    fullName: "Megan Brown",
    date: "14/02/2009",
    rate: 4.7,
    comment:
      "We had a fantastic stay at this vacation rental. The location was perfect, close to the beach and local attractions. The rental was clean and well-equipped. The owner was very responsive and provided us with great local recommendations. Highly recommended!",
  },
  {
    id: 42,
    platform: "booking",
    fullName: "Daniel Martinez",
    date: "27/08/2008",
    rate: 4.9,
    comment:
      "Our stay was amazing! The apartment was spotless and had all the amenities we needed. The location was perfect, close to restaurants and attractions. The host was incredibly helpful and responsive. We will definitely stay here again!",
  },
  {
    id: 43,
    platform: "airbnb",
    fullName: "Luis Hernandez",
    date: "16/04/2007",
    rate: 4.3,
    comment:
      "Nuestra estancia fue estupenda. El apartamento estaba limpio y acogedor. La ubicación era perfecta, cerca de restaurantes y lugares de interés. El anfitrión fue amable y siempre dispuesto a ayudar. ¡Definitivamente volveremos!",
  },
  {
    id: 44,
    platform: "airbnb",
    fullName: "Emily Smith",
    date: "22/12/2006",
    rate: 4.8,
    comment:
      "Our stay was fantastic! The apartment was clean and well-maintained. The location was great, close to shops and restaurants. The host was friendly and provided us with helpful tips for the area. We would definitely return!",
  },
  {
    id: 45,
    platform: "booking",
    fullName: "Sophie Martin",
    date: "05/08/2006",
    rate: 3.6,
    comment:
      "Notre séjour était correct. L'appartement avait un bon rapport qualité-prix. L'emplacement était pratique, mais le ménage aurait pu être meilleur. L'hôte était sympathique et nous a aidés avec nos questions. Dans l'ensemble, une expérience correcte.",
  },
  {
    id: 46,
    platform: "VRBO",
    fullName: "Benjamin Wilson",
    date: "24/01/2006",
    rate: 4.5,
    comment:
      "We had a pleasant stay in this vacation rental. The location was ideal, close to the beach and local attractions. The rental was clean and well-equipped. The owner was responsive and helpful. We recommend this rental.",
  },
  {
    id: 47,
    platform: "VRBO",
    fullName: "Julia Garcia",
    date: "09/08/2005",
    rate: 4.9,
    comment:
      "Our stay was amazing! The apartment was spotless and had all the amenities we needed. The location was perfect, close to restaurants and attractions. The host was incredibly helpful and responsive. We will definitely stay here again!",
  },
  {
    id: 48,
    platform: "airbnb",
    fullName: "Marco Rodriguez",
    date: "15/04/2005",
    rate: 4.3,
    comment:
      "Nuestra estancia fue estupenda. El apartamento estaba limpio y acogedor. La ubicación era perfecta, cerca de restaurantes y lugares de interés. El anfitrión fue amable y siempre dispuesto a ayudar. ¡Definitivamente volveremos!",
  },
  {
    id: 49,
    platform: "airbnb",
    fullName: "Anna Smith",
    date: "22/12/2004",
    rate: 4.8,
    comment:
      "Our stay was fantastic! The apartment was clean and well-maintained. The location was great, close to shops and restaurants. The host was friendly and provided us with helpful tips for the area. We would definitely return!",
  },
  {
    id: 50,
    platform: "booking",
    fullName: "Thomas Dupont",
    date: "05/08/2004",
    rate: 3.6,
    comment:
      "Notre séjour était correct. L'appartement avait un bon rapport qualité-prix. L'emplacement était pratique, mais le ménage aurait pu être meilleur. L'hôte était sympathique et nous a aidés avec nos questions. Dans l'ensemble, une expérience correcte.",
  },
  {
    id: 51,
    platform: "VRBO",
    fullName: "Sophia Williams",
    date: "14/02/2004",
    rate: 4.7,
    comment:
      "We had a fantastic stay at this vacation rental. The location was perfect, close to the beach and local attractions. The rental was clean and well-equipped. The owner was very responsive and provided us with great local recommendations. Highly recommended!",
  },
  {
    id: 52,
    platform: "booking",
    fullName: "Carlos Martinez",
    date: "27/08/2003",
    rate: 4.9,
    comment:
      "Our stay was amazing! The apartment was spotless and had all the amenities we needed. The location was perfect, close to restaurants and attractions. The host was incredibly helpful and responsive. We will definitely stay here again!",
  },
  {
    id: 53,
    platform: "airbnb",
    fullName: "Maria Hernandez",
    date: "16/04/2002",
    rate: 4.3,
    comment:
      "Nuestra estancia fue estupenda. El apartamento estaba limpio y acogedor. La ubicación era perfecta, cerca de restaurantes y lugares de interés. El anfitrión fue amable y siempre dispuesto a ayudar. ¡Definitivamente volveremos!",
  },
  {
    id: 54,
    platform: "airbnb",
    fullName: "Joseph Smith",
    date: "22/12/2001",
    rate: 4.8,
    comment:
      "Our stay was fantastic! The apartment was clean and well-maintained. The location was great, close to shops and restaurants. The host was friendly and provided us with helpful tips for the area. We would definitely return!",
  },
  {
    id: 55,
    platform: "booking",
    fullName: "Léa Martin",
    date: "05/08/2001",
    rate: 3.6,
    comment:
      "Notre séjour était correct. L'appartement avait un bon rapport qualité-prix. L'emplacement était pratique, mais le ménage aurait pu être meilleur. L'hôte était sympathique et nous a aidés avec nos questions. Dans l'ensemble, une expérience correcte.",
  },
  {
    id: 56,
    platform: "VRBO",
    fullName: "Richard Wilson",
    date: "24/01/2001",
    rate: 4.5,
    comment:
      "We had a pleasant stay in this vacation rental. The location was ideal, close to the beach and local attractions. The rental was clean and well-equipped. The owner was responsive and helpful. We recommend this rental.",
  },
  {
    id: 57,
    platform: "VRBO",
    fullName: "Isabelle Garcia",
    date: "09/08/2000",
    rate: 4.9,
    comment:
      "Our stay was amazing! The apartment was spotless and had all the amenities we needed. The location was perfect, close to restaurants and attractions. The host was incredibly helpful and responsive. We will definitely stay here again!",
  },
  {
    id: 58,
    platform: "airbnb",
    fullName: "Marco Hernandez",
    date: "15/04/2000",
    rate: 4.3,
    comment:
      "Nuestra estancia fue estupenda. El apartamento estaba limpio y acogedor. La ubicación era perfecta, cerca de restaurantes y lugares de interés. El anfitrión fue amable y siempre dispuesto a ayudar. ¡Definitivamente volveremos!",
  },
  {
    id: 59,
    platform: "airbnb",
    fullName: "Emily Smith",
    date: "22/12/1999",
    rate: 4.8,
    comment:
      "Our stay was fantastic! The apartment was clean and well-maintained. The location was great, close to shops and restaurants. The host was friendly and provided us with helpful tips for the area. We would definitely return!",
  },
  {
    id: 60,
    platform: "booking",
    fullName: "Lucas Dupont",
    date: "05/08/1999",
    rate: 3.6,
    comment:
      "Notre séjour était correct. L'appartement avait un bon rapport qualité-prix. L'emplacement était pratique, mais le ménage aurait pu être meilleur. L'hôte était sympathique et nous a aidés avec nos questions. Dans l'ensemble, une expérience correcte.",
  },
  {
    id: 61,
    platform: "VRBO",
    fullName: "Alexandra Johnson",
    date: "14/02/2023",
    rate: 4.7,
    comment:
      "We had a fantastic stay at this vacation rental. The location was perfect, close to the beach and local attractions. The rental was clean and well-equipped. The owner was very responsive and provided us with great local recommendations. Highly recommended!",
  },
  {
    id: 62,
    platform: "booking",
    fullName: "Andreas Schmidt",
    date: "27/08/2022",
    rate: 4.9,
    comment:
      "Our stay was amazing! The apartment was spotless and had all the amenities we needed. The location was perfect, close to restaurants and attractions. The host was incredibly helpful and responsive. We will definitely stay here again!",
  },
  {
    id: 63,
    platform: "airbnb",
    fullName: "Sophie Dubois",
    date: "16/04/2022",
    rate: 4.3,
    comment:
      "Notre séjour a été fantastique. L'appartement était propre et confortable. L'emplacement était parfait, proche des restaurants et des attractions. L'hôte était sympathique et toujours prêt à aider. Nous reviendrons certainement!",
  },
  {
    id: 64,
    platform: "airbnb",
    fullName: "Michael Smith",
    date: "22/12/2021",
    rate: 4.8,
    comment:
      "Our stay was fantastic! The apartment was clean and well-maintained. The location was great, close to shops and restaurants. The host was friendly and provided us with helpful tips for the area. We would definitely return!",
  },
  {
    id: 65,
    platform: "booking",
    fullName: "Caroline Martin",
    date: "05/08/2021",
    rate: 3.6,
    comment:
      "Notre séjour était correct. L'appartement avait un bon rapport qualité-prix. L'emplacement était pratique, mais le ménage aurait pu être meilleur. L'hôte était sympathique et nous a aidés avec nos questions. Dans l'ensemble, une expérience correcte.",
  },
  {
    id: 66,
    platform: "VRBO",
    fullName: "David Wilson",
    date: "24/01/2021",
    rate: 4.5,
    comment:
      "We had a pleasant stay in this vacation rental. The location was ideal, close to the beach and local attractions. The rental was clean and well-equipped. The owner was responsive and helpful. We recommend this rental.",
  },
  {
    id: 67,
    platform: "VRBO",
    fullName: "Isabella Garcia",
    date: "09/08/2020",
    rate: 4.9,
    comment:
      "Our stay was amazing! The apartment was spotless and had all the amenities we needed. The location was perfect, close to restaurants and attractions. The host was incredibly helpful and responsive. We will definitely stay here again!",
  },
  {
    id: 68,
    platform: "airbnb",
    fullName: "Marco Hernandez",
    date: "15/04/2020",
    rate: 4.3,
    comment:
      "Nuestra estancia fue estupenda. El apartamento estaba limpio y acogedor. La ubicación era perfecta, cerca de restaurantes y lugares de interés. El anfitrión fue amable y siempre dispuesto a ayudar. ¡Definitivamente volveremos!",
  },
  {
    id: 69,
    platform: "airbnb",
    fullName: "Emily Smith",
    date: "22/12/2019",
    rate: 4.8,
    comment:
      "Our stay was fantastic! The apartment was clean and well-maintained. The location was great, close to shops and restaurants. The host was friendly and provided us with helpful tips for the area. We would definitely return!",
  },
  {
    id: 70,
    platform: "booking",
    fullName: "Lucas Dupont",
    date: "05/08/2019",
    rate: 3.6,
    comment:
      "Notre séjour était correct. L'appartement avait un bon rapport qualité-prix. L'emplacement était pratique, mais le ménage aurait pu être meilleur. L'hôte était sympathique et nous a aidés avec nos questions. Dans l'ensemble, une expérience correcte.",
  },
  {
    id: 71,
    platform: "VRBO",
    fullName: "Olivia Brown",
    date: "14/02/2024",
    rate: 4.7,
    comment:
      "We had a fantastic stay at this vacation rental. The location was perfect, close to the beach and local attractions. The rental was clean and well-equipped. The owner was very responsive and provided us with great local recommendations. Highly recommended!",
  },
  {
    id: 72,
    platform: "booking",
    fullName: "Maximilian Müller",
    date: "27/08/2023",
    rate: 4.9,
    comment:
      "Our stay was amazing! The apartment was spotless and had all the amenities we needed. The location was perfect, close to restaurants and attractions. The host was incredibly helpful and responsive. We will definitely stay here again!",
  },
  {
    id: 73,
    platform: "airbnb",
    fullName: "Sophie Dubois",
    date: "16/04/2023",
    rate: 4.3,
    comment:
      "Notre séjour a été fantastique. L'appartement était propre et confortable. L'emplacement était parfait, proche des restaurants et des attractions. L'hôte était sympathique et toujours prêt à aider. Nous reviendrons certainement!",
  },
  {
    id: 74,
    platform: "airbnb",
    fullName: "Michael Smith",
    date: "22/12/2022",
    rate: 4.8,
    comment:
      "Our stay was fantastic! The apartment was clean and well-maintained. The location was great, close to shops and restaurants. The host was friendly and provided us with helpful tips for the area. We would definitely return!",
  },
  {
    id: 75,
    platform: "booking",
    fullName: "Caroline Martin",
    date: "05/08/2022",
    rate: 3.6,
    comment:
      "Notre séjour était correct. L'appartement avait un bon rapport qualité-prix. L'emplacement était pratique, mais le ménage aurait pu être meilleur. L'hôte était sympathique et nous a aidés avec nos questions. Dans l'ensemble, une expérience correcte.",
  },
  {
    id: 76,
    platform: "VRBO",
    fullName: "David Wilson",
    date: "24/01/2022",
    rate: 4.5,
    comment:
      "We had a pleasant stay in this vacation rental. The location was ideal, close to the beach and local attractions. The rental was clean and well-equipped. The owner was responsive and helpful. We recommend this rental.",
  },
  {
    id: 77,
    platform: "VRBO",
    fullName: "Isabella Garcia",
    date: "09/08/2021",
    rate: 4.9,
    comment:
      "Our stay was amazing! The apartment was spotless and had all the amenities we needed. The location was perfect, close to restaurants and attractions. The host was incredibly helpful and responsive. We will definitely stay here again!",
  },
  {
    id: 78,
    platform: "airbnb",
    fullName: "Marco Hernandez",
    date: "15/04/2021",
    rate: 4.3,
    comment:
      "Nuestra estancia fue estupenda. El apartamento estaba limpio y acogedor. La ubicación era perfecta, cerca de restaurantes y lugares de interés. El anfitrión fue amable y siempre dispuesto a ayudar. ¡Definitivamente volveremos!",
  },
  {
    id: 79,
    platform: "airbnb",
    fullName: "Emily Smith",
    date: "22/12/2020",
    rate: 4.8,
    comment:
      "Our stay was fantastic! The apartment was clean and well-maintained. The location was great, close to shops and restaurants. The host was friendly and provided us with helpful tips for the area. We would definitely return!",
  },
  {
    id: 80,
    platform: "booking",
    fullName: "Lucas Dupont",
    date: "05/08/2020",
    rate: 3.6,
    comment:
      "Notre séjour était correct. L'appartement avait un bon rapport qualité-prix. L'emplacement était pratique, mais le ménage aurait pu être meilleur. L'hôte était sympathique et nous a aidés avec nos questions. Dans l'ensemble, une expérience correcte.",
  },
  {
    id: 81,
    platform: "VRBO",
    fullName: "Ella Anderson",
    date: "14/02/2025",
    rate: 4.7,
    comment:
      "We had a fantastic stay at this vacation rental. The location was perfect, close to the beach and local attractions. The rental was clean and well-equipped. The owner was very responsive and provided us with great local recommendations. Highly recommended!",
  },
  {
    id: 82,
    platform: "booking",
    fullName: "Felix Wagner",
    date: "27/08/2024",
    rate: 4.9,
    comment:
      "Our stay was amazing! The apartment was spotless and had all the amenities we needed. The location was perfect, close to restaurants and attractions. The host was incredibly helpful and responsive. We will definitely stay here again!",
  },
  {
    id: 83,
    platform: "airbnb",
    fullName: "Sophie Dubois",
    date: "16/04/2024",
    rate: 4.3,
    comment:
      "Notre séjour a été fantastique. L'appartement était propre et confortable. L'emplacement était parfait, proche des restaurants et des attractions. L'hôte était sympathique et toujours prêt à aider. Nous reviendrons certainement!",
  },
  {
    id: 84,
    platform: "airbnb",
    fullName: "Michael Smith",
    date: "22/12/2023",
    rate: 4.8,
    comment:
      "Our stay was fantastic! The apartment was clean and well-maintained. The location was great, close to shops and restaurants. The host was friendly and provided us with helpful tips for the area. We would definitely return!",
  },
  {
    id: 85,
    platform: "booking",
    fullName: "Caroline Martin",
    date: "05/08/2023",
    rate: 3.6,
    comment:
      "Notre séjour était correct. L'appartement avait un bon rapport qualité-prix. L'emplacement était pratique, mais le ménage aurait pu être meilleur. L'hôte était sympathique et nous a aidés avec nos questions. Dans l'ensemble, une expérience correcte.",
  },
  {
    id: 86,
    platform: "VRBO",
    fullName: "David Wilson",
    date: "24/01/2023",
    rate: 4.5,
    comment:
      "We had a pleasant stay in this vacation rental. The location was ideal, close to the beach and local attractions. The rental was clean and well-equipped. The owner was responsive and helpful. We recommend this rental.",
  },
  {
    id: 87,
    platform: "VRBO",
    fullName: "Isabella Garcia",
    date: "09/08/2022",
    rate: 4.9,
    comment:
      "Our stay was amazing! The apartment was spotless and had all the amenities we needed. The location was perfect, close to restaurants and attractions. The host was incredibly helpful and responsive. We will definitely stay here again!",
  },
  {
    id: 88,
    platform: "airbnb",
    fullName: "Marco Hernandez",
    date: "15/04/2022",
    rate: 4.3,
    comment:
      "Nuestra estancia fue estupenda. El apartamento estaba limpio y acogedor. La ubicación era perfecta, cerca de restaurantes y lugares de interés. El anfitrión fue amable y siempre dispuesto a ayudar. ¡Definitivamente volveremos!",
  },
  {
    id: 89,
    platform: "airbnb",
    fullName: "Emily Smith",
    date: "22/12/2021",
    rate: 4.8,
    comment:
      "Our stay was fantastic! The apartment was clean and well-maintained. The location was great, close to shops and restaurants. The host was friendly and provided us with helpful tips for the area. We would definitely return!",
  },
  {
    id: 90,
    platform: "booking",
    fullName: "Lucas Dupont",
    date: "05/08/2021",
    rate: 3.6,
    comment:
      "Notre séjour était correct. L'appartement avait un bon rapport qualité-prix. L'emplacement était pratique, mais le ménage aurait pu être meilleur. L'hôte était sympathique et nous a aidés avec nos questions. Dans l'ensemble, une expérience correcte.",
  },
  {
    id: 91,
    platform: "VRBO",
    fullName: "Julie Dupont",
    date: "14/02/2025",
    rate: 2.3,
    comment:
      "Notre séjour a été décevant. L'appartement était sale à notre arrivée, et l'hôte a été peu réactif pour résoudre les problèmes. De plus, l'emplacement n'était pas aussi pratique que prévu. Nous ne recommandons pas cet endroit.",
  },
  {
    id: 92,
    platform: "booking",
    fullName: "Thomas Müller",
    date: "27/08/2024",
    rate: 2.0,
    comment:
      "Nous avons été très déçus de notre séjour. L'appartement était en mauvais état, avec des problèmes de plomberie et de chauffage. L'hôte n'a pas été utile pour résoudre ces problèmes. Nous ne reviendrons pas ici.",
  },
  {
    id: 93,
    platform: "airbnb",
    fullName: "Sophie Dubois",
    date: "16/04/2024",
    rate: 2.1,
    comment:
      "Notre expérience a été terrible. L'appartement était sale et mal entretenu. De plus, l'emplacement était bruyant et inconfortable. Nous avons eu des problèmes avec l'hôte qui n'a pas répondu à nos demandes. Évitez cet endroit à tout prix.",
  },
  {
    id: 94,
    platform: "airbnb",
    fullName: "Michael Smith",
    date: "22/12/2023",
    rate: 1.9,
    comment:
      "Nous avons vécu un cauchemar dans cet appartement. La propreté laissait à désirer, et l'hôte était inutile pour résoudre les problèmes. Nous avons dû quitter plus tôt que prévu en raison de l'état de l'appartement. Ne recommande absolument pas.",
  },
  {
    id: 95,
    platform: "booking",
    fullName: "Caroline Martin",
    date: "05/08/2023",
    rate: 2.2,
    comment:
      "Notre séjour a été désastreux. L'appartement était sale et malodorant. L'emplacement était bruyant et peu sûr. L'hôte ne s'est pas soucié de nos problèmes. Évitez cet endroit si vous le pouvez.",
  },
  {
    id: 96,
    platform: "VRBO",
    fullName: "David Wilson",
    date: "24/01/2023",
    rate: 2.5,
    comment:
      "Nous avons été très déçus de notre séjour. L'appartement n'était pas propre, et il y avait des problèmes de nuisibles. L'hôte a été peu réactif, et nous avons dû nous occuper nous-mêmes des problèmes. Ne recommande pas du tout.",
  },
  {
    id: 97,
    platform: "VRBO",
    fullName: "Isabella Garcia",
    date: "09/08/2022",
    rate: 2.0,
    comment:
      "Notre séjour a été un désastre complet. L'appartement était sale et en mauvais état. L'hôte était inexistant lorsque nous avions besoin d'aide. Nous avons dû trouver un autre logement en urgence. Évitez cet endroit à tout prix.",
  },
  {
    id: 98,
    platform: "airbnb",
    fullName: "Marco Hernandez",
    date: "15/04/2022",
    rate: 2.3,
    comment:
      "Nous avons eu une expérience horrible dans cet appartement. La propreté était inacceptable, et l'hôte n'a pas fait grand-chose pour résoudre les problèmes. Nous avons regretté d'avoir choisi cet endroit.",
  },
  {
    id: 99,
    platform: "airbnb",
    fullName: "Emily Smith",
    date: "22/12/2021",
    rate: 2.1,
    comment:
      "Notre séjour a été un cauchemar. L'appartement était sale et en mauvais état. De plus, l'emplacement était peu sûr. L'hôte n'a pas répondu à nos appels à l'aide. Évitez cet endroit à tout prix",
  },
  {
    id: 100,
    platform: "booking",
    fullName: "Lucas Dupont",
    date: "05/08/2021",
    rate: 1.8,
    comment:
      "Notre séjour a été une catastrophe. L'appartement était sale et en désordre à notre arrivée. L'hôte était impossible à joindre lorsque nous avions des problèmes. Nous avons dû quitter prématurément en raison de la mauvaise expérience. Évitez cet endroit à tout prix.",
  },
];
