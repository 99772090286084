import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import paths from "../../../constants/paths";
import { get } from "../../../helpers/APIHelper";
import { UserResponse } from "../../../types/GETTypes";

export const ProgressionRentalsRulesBanner = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [user, setUser] = useState<UserResponse>();

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const responseUser = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.USER.GET_USER}`
      );

      if (responseUser.response?.status === 401) navigate(paths.LOGIN);
      else if (responseUser?.data?.success) {
        setUser(responseUser?.data?.result);
      }
    };

    fetchCurrentUser();

    console.log("CHECK USER LOGIN");
  }, []);

  return (
    <div className="flex items-center gap-4 p-4 bg-element-background rounded-8px border-1 border-element-border-light">
      {user && (
        <div className="w-8 h-8 rounded-full border-1 border-element-border bg-slate-200">
          <img src={user?.photo!} className="rounded-full" />
        </div>
      )}
      <div>
        <p className="font-semibold text-high-contrast">
          {t("Progression.RentalsRules.bannerTitle")}
        </p>
        <p className="my-2 font-light text-low-contrast ">
          {t("Progression.RentalsRules.bannerContent")}
        </p>
        <p className="font-light text-low-contrast">
          {t("Progression.RentalsRules.bannerSubContent")}
        </p>
        <p className="font-light text-low-contrast">
          {t("Progression.RentalsRules.bannerSubContent2")}
        </p>
      </div>
    </div>
  );
};
