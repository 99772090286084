import React, {useState} from "react";
import {RatingProps} from "./Rating.type";
import StarEmptyIcon from "../../../assets/icons/star.svg?react";
import StarHalfIcon from "../../../assets/icons/star-half.svg?react";
import StarFilledIcon from "../../../assets/icons/star-filled.svg?react";
import {RatingSkeleton} from "./RatingSkeleton";
import {cn} from "../../../helpers/classHelper";

export const Rating: React.FC<RatingProps> = ({
  loading,
  maxStars = 5,
  value,
  classNames,
  displayNote = true,
  isClickable = false,
  onChange,
}) => {
  const val = value ? value : 0;

  const [rating, setRating] = useState(value || 0);

  const handleStarClick = (event: React.MouseEvent, index: number) => {
    if (!isClickable) return;

    const {left, width} = event.currentTarget.getBoundingClientRect();
    const clickX = event.clientX - left;
    const clickedHalf = clickX > width / 2 ? 1 : 0.5;

    const newRating = index + clickedHalf;
    setRating(newRating);
    if (onChange) {
      onChange(newRating);
    }
  };

  const getStars = () => {
    const stars = [];
    for (let i = 0; i < maxStars; i++) {
      if (rating >= i + 1) {
        stars.push(
          <StarFilledIcon
            key={i}
            className={cn(`${isClickable && "cursor-pointer"}`,classNames?.star)}
            onClick={(event) => handleStarClick(event, i)}
          />
        );
      } else if (rating >= i + 0.5) {
        stars.push(
          <StarHalfIcon
            key={i}
            className={cn(`${isClickable && "cursor-pointer"}`,classNames?.star)}
            onClick={(event) => handleStarClick(event, i)}
          />
        );
      } else {
        stars.push(
          <StarEmptyIcon
            key={i}
            className={cn(`${isClickable && "cursor-pointer"}`,classNames?.star)}
            onClick={(event) => handleStarClick(event, i)}
          />
        );
      }
    }
    return <>{stars}</>;
  };

  // const ratePercentage: number = (value / maxStars) * 100;
  // const starPercentage: number = 100 / maxStars;

  // TODO: [RATING] - Clean and implement decimal value logic
  // const stars = (): ReactElement[] => {
  //   let array: ReactElement[] = [];

  //   if (value > maxStars) {
  //     [...Array(maxStars)].forEach((v) => {
  //       array.push(<StarFilledIcon />);
  //     });
  //   } else {
  //     if (Number.isInteger(value)) {
  //       [...Array(value)].forEach((v) => {
  //         array.push(<StarFilledIcon />);
  //       });

  //       let remainingEmptyStars: number = maxStars - value;
  //       if (remainingEmptyStars > 0) {
  //         [...Array(maxStars - value)].forEach((v) => {
  //           array.push(<StarEmptyIcon />);
  //         });
  //       }
  //     }
  //   }

  //   return array;
  // };

  if (loading) return <RatingSkeleton maxStars={maxStars} />;

  return (
    <div className="flex space-x-3">
      <>
        {displayNote && (
          <p className={cn(classNames?.label)}>{`${
            val && val > maxStars ? maxStars : val
          }/${maxStars}`}</p>
        )}
        <div
          className={cn(
            "flex items-center space-x-1 w-max h-4",
            classNames?.starDiv
          )}
        >
          {getStars()}
        </div>
      </>
    </div>
  );
};
