import React from "react";
import {Button} from "../../Common/Button/Button";
import {useTranslation} from "react-i18next";
import useWindowSize from "../../../hooks/useWindowSize";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import {nameTypeFile} from "./GuestPageForm";
import {Badge} from "../../Common/Badge/Badge";
import CheckCircleGreenIcon from "../../../assets/icons/check-circle-green.svg?react";
import TrashIcon from "../../../assets/icons/trash.svg?react";

interface FileButtonComponentProps {
  nameType: nameTypeFile;
  loading: boolean;
  label?: string;
  buttonLabel: string;
  checkMissingFiles: (docName?: nameTypeFile) => boolean;
  openModal: (fileType: nameTypeFile) => void;
  deleteFiles: (fileType: nameTypeFile) => void;
}

const FileButtonComponent: React.FC<FileButtonComponentProps> = ({
  nameType,
  loading,
  label,
  buttonLabel,
  checkMissingFiles,
  openModal,
  deleteFiles,
}) => {
  const {t} = useTranslation();

  const isFill = !checkMissingFiles(nameType);
  return (
    <div
      className={`flex flex-1 ${
        isFill ? "flex-row" : "flex-col"
      } md:flex-row justify-between items-center`}
    >
      {label && (
        <label
          className={`text-xs font-normal text-left text-low-contrast w-full flex flex-1 flex-row items-center md:ml-1 md:text-lg md:mr-2 ${
            isFill ? "mb-1" : "mb-4"
          } md:mb-1`}
        >
          <div className="w-1 h-1 bg-low-contrast rounded-full mr-2"></div>{" "}
          {label}
        </label>
      )}
      {label && isFill ? (
        <div className="flex justify-end md:min-w-[280px] space-x-4">
          <Badge
            Icon={CheckCircleGreenIcon}
            label={t("GuestPage.LoginDetails.addFileBadge")}
            labelStyle="font-bold"
            status="success"
          />
          <Button
            type="secondary"
            displayLargeBtn={false}
            onClick={() => deleteFiles(nameType)}
          >
            <TrashIcon />
          </Button>
        </div>
      ) : (
        label && (
          <div className="md:min-w-[280px] w-full md:w-[280px] ml-2 md:ml-0">
            <Button
              type="secondary"
              className="min-w-[250px]"
              buttonClassName="md:h-12 bg-element-border/10"
              textClassName="md:text-base"
              RightIcon={PlusIcon}
              disabled={loading}
              displayLargeBtn={true}
              onClick={() => openModal(nameType)}
            >
              {t(`GuestPage.LoginDetails.${buttonLabel}`)}
            </Button>
          </div>
        )
      )}
    </div>
  );
};

export default FileButtonComponent;
