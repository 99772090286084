import React from "react";
import {PlatformConfirmModalProps, RentalPlatformsAddModalType} from "../../../Rental/Infos/Platforms/Platforms.type";
import {useTranslation} from "react-i18next";
import CloseIcon from "../../../../assets/icons/close.svg?react";
import checkIcon from "../../../../assets/icons/check-white.svg?react";
import {CenterModal} from "../../../Common/CenterModal/CenterModal";
import {Button} from "../../../Common/Button/Button";

export const PlatformConfirmModal: React.FC<PlatformConfirmModalProps> = ({isVisible, onClose, handleConfirm,platformActive}) => {
    const { t } = useTranslation();

    return (
        <CenterModal
            isVisible={isVisible}
            onClose={() => onClose()}
        >
            {
                platformActive === "airbnb" && (
                    <div className="flex flex-col gap-10">
                        <div className="flex flex-row  pb-0">
                            <div className="flex flex-col gap-5">
                                <p className="text-[20px] font-extrabold leading-snug	">
                                    {t("Rental.Infos.Platforms.SynchronizeModal.titleAirbnb")}
                                </p>
                                <p className="text-low-contrast">
                                    {t("Rental.Infos.Platforms.SynchronizeModal.contentAirbnb1")}
                                </p>
                                <div className="flex ml-4">
                                    <ul className="list-disc">
                                        <li className="text-low-contrast">
                                            {t("Rental.Infos.Platforms.SynchronizeModal.contentAirbnb2")}
                                        </li>
                                        <li className="text-low-contrast">
                                            {t("Rental.Infos.Platforms.SynchronizeModal.content3")}
                                        </li>
                                        <li className="text-low-contrast">
                                            {t("Rental.Infos.Platforms.SynchronizeModal.contentAirbnb3")}
                                        </li>
                                        <li className="text-low-contrast">
                                            {t("Rental.Infos.Platforms.SynchronizeModal.content5")}
                                        </li>
                                    </ul>
                                </div>
                                <p className="text-low-contrast font-bold">
                                    {t("Rental.Infos.Platforms.SynchronizeModal.content2")}
                                </p>
                            </div>
                            <div className="cursor-pointer"
                                 onClick={() => onClose()}>
                                <CloseIcon width={18} height={16}/>
                            </div>
                        </div>


                        <div className="flex gap-3">
                            <Button type="secondary" onClick={() => {
                                onClose()
                            }}>
                                {t("Global.cancel")}
                            </Button>
                            <Button type="destructive"
                                    onClick={() => handleConfirm()}
                                    RightIcon={checkIcon}
                            >
                                {t("Global.verified")}
                            </Button>
                        </div>
                    </div>
                )
            }
            {
                platformActive === "booking" && (
                    <div className="flex flex-col gap-10">
                        <div className="flex flex-row  pb-0">
                            <div className="flex flex-col gap-5">
                                <p className="text-[20px] font-extrabold leading-snug	">
                                    {t("Rental.Infos.Platforms.SynchronizeModal.title")}
                                </p>
                                <p className="text-low-contrast">
                                    {t("Rental.Infos.Platforms.SynchronizeModal.content1")}
                                </p>
                                <div className="flex ml-4">
                                    <ul className="list-disc">
                                        <li className="text-low-contrast">
                                            {t("Rental.Infos.Platforms.SynchronizeModal.content3")}
                                        </li>
                                        <li className="text-low-contrast">
                                            {t("Rental.Infos.Platforms.SynchronizeModal.content4")}
                                        </li>
                                        <li className="text-low-contrast">
                                            {t("Rental.Infos.Platforms.SynchronizeModal.content5")}
                                        </li>
                                    </ul>
                                </div>
                                <p className="text-low-contrast font-bold">
                                    {t("Rental.Infos.Platforms.SynchronizeModal.content2")}
                                </p>

                                <p className="text-low-contrast">
                                    {t("Rental.Infos.Platforms.SynchronizeModal.content6")}
                                </p>
                            </div>
                            <div className="cursor-pointer"
                                 onClick={() => onClose()}>
                                <CloseIcon width={18} height={16}/>
                            </div>
                        </div>


                        <div className="flex gap-3">
                            <Button type="secondary" onClick={() => {
                                onClose()
                            }}>
                                {t("Global.cancel")}
                            </Button>
                            <Button type="destructive"
                                    onClick={() => handleConfirm()}
                                    RightIcon={checkIcon}
                            >
                                {t("Global.verified")}
                            </Button>
                        </div>
                    </div>
                )
            }

        </CenterModal>
    );
}
