import { PricelabsSettingsForm, PricelabsConnectionForm, RentalPricelabsResponse } from "../../components/Rental/Services/Pricelabs/Pricelabs.type";
import paths from "../../constants/paths";
import { post } from "../../helpers/APIHelper";
import { ValueType } from "../../types/commonTypes";

/**
 * Custom hook to manage pricelabs.
 *
 * @param {Object} params - Parameters for the hook.
 * @param {ValueType} params.rentalId - The ID of the rental.
 * @param {Function} params.onSuccess - Callback function to be called on successful operation.
 * @param {Function} params.onError - Callback function to be called on error.
 * @param {Function} [params.onStart] - Optional callback function to be called at the start of an operation.
 * @param {Function} [params.onEnd] - Optional callback function to be called at the end of an operation.
 * @returns {Object} An object containing methods to create, update, remove, and duplicate checklists.
 */
export const usePricelabs = ({
  rentalId,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  rentalId: ValueType;
  onSuccess: (pricelabsResponse?: RentalPricelabsResponse) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}) => {
  /**
   * Connect a rental to pricelabs.
   *
   * @param {PricelabsConnectionForm} values - The form values for pricelabs connection.
   */
  const connect = async (values: PricelabsConnectionForm) => {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      if (rentalId === null)
        throw new Error("usePricelabs - connect() : rentalId not defined !");

      const formData = new FormData();
      formData.append("rental_id", rentalId.toString());
      formData.append("email", values.email);

      const URL = `${import.meta.env.VITE_API_URL}${
        paths.API.PRICELABS.CONNECT_RENTAL
      }`;
      const res = await post(URL, formData);

      if (res?.data?.success) {
        onSuccess(res.data.result);
      } else {
        onError(res?.data?.message ?? res.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };

  /**
   * Disconnect a rental from pricelabs.
   */
  const disconnect = async () => {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      if (rentalId === null)
        throw new Error("usePricelabs - disconnect() : rentalId not defined !");

      const formData = new FormData();
      formData.append("rental_id", rentalId.toString());

      const URL = `${import.meta.env.VITE_API_URL}${
        paths.API.PRICELABS.DECONNECT_RENTAL
      }`;
      const res = await post(URL, formData);

      if (res?.data?.success) {
        onSuccess(res.data.result);
      } else {
        onError(res?.data?.message ?? res.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };

  /**
   * Update pricelabs settings.
   * 
   * @param {PricelabsSettingsForm} values - The form values for pricelabs configuration.
   * @param {boolean} synchronizationEnabled - Enable or disable synchronization.
   */
  const update = async (values: PricelabsSettingsForm, synchronizationEnabled: boolean) => {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      if (rentalId === null)
        throw new Error("usePricelabs - update() : rentalId not defined !");

      const formData = new FormData();
      formData.append("rental_id", rentalId.toString());
      formData.append("api_key", values.api_key);
      formData.append("is_synchronization_enabled", synchronizationEnabled ? "1" : "0");

      const URL = `${import.meta.env.VITE_API_URL}${
        paths.API.PRICELABS.UPDATE_SETTINGS
      }`;
      const res = await post(URL, formData);

      if (res?.data?.success) {
        onSuccess(res.data.result);
      } else {
        onError(res?.data?.message ?? res.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };

  return { connect, disconnect, update };
};
