import React from "react";
import { useTranslation } from "react-i18next";
import SuperhoteIcon from "../../assets/icons/superhote.svg?react";
import { Reset } from "../../components/Authentication/Reset/Reset";
import { ResetSubmitType } from "../../components/Authentication/Reset/Reset.type";
import { ResetThanks } from "../../components/Authentication/ResetThanks/ResetThanks";
import { Title } from "../../components/Common/Title/Title";
import paths from "../../constants/paths";
import { usePost } from "../../hooks/usePost";
import { useUrlSearchParams } from "../../hooks/useUrlSearchParams";
import { ResetPasswordPageProps } from "./ResetPasswordPage.type";

export const ResetPasswordPage: React.FC<ResetPasswordPageProps> = () => {
  const { t } = useTranslation();

  const query = useUrlSearchParams();

  const { perform: post, success, loading, error } = usePost();

  const handleReset = async ({
    password,
    confirmation_password,
  }: ResetSubmitType) => {
    post(`${import.meta.env.VITE_API_URL}${paths.API.RESET_PASSWORD}`, {
      password,
      confirmation_password,
      email: query.get("email"),
      token: query.get("token"),
    });
  };

  return (
    <div className="flex w-full justify-center ">
      <div className="flex flex-row min-w-[1024px] w-4/5 max-w-[1270px] min-h-screen">
        {/* Left */}
        <div className="w-2/5 max-w-md min-h-screen p-2">
          <div className="flex flex-col min-h-full bg-subtle border-1 border-element-border/50 p-8 rounded-6px">
            <div className="flex-1 pb-4">
              <SuperhoteIcon />
            </div>

            <div>
              <Title>{t("Recover.leftTitle1")}</Title>
              <Title>{t("Recover.leftTitle2")}</Title>
              <p className="mt-1 text-sm font-light text-low-contrast">
                {t("Recover.leftSubTitle")}
              </p>
            </div>
          </div>
        </div>

        {/* Right */}
        <div className="flex-1 px-12 py-8">
          {!success ? (
            <Reset
              onSubmit={handleReset}
              error={error ?? ""}
              loading={loading}
            />
          ) : (
            <ResetThanks />
          )}
        </div>
      </div>
    </div>
  );
};
