import React from "react";
import { SidebarItemProps } from "./Sidebar.type";
import paths from "../../../constants/paths";
import {useCheckAuth} from "../../../hooks/useCheckAuth";

export const SidebarItem: React.FC<SidebarItemProps> = ({
  Icon,
  IconActive = null,
  label,
  url,
  isActive = false,
  isClosed = false,
  displayIcon = true,
  handleClick
}) => {
  const getBgColor = () => {
    return isActive && displayIcon
      && "bg-element-background-selection-subtle"
  };

  const getTextColor = () => {
    return isActive ? "text-icon-active" : "text-low-contrast";
  };

  const getFontLabelWeight = () => {
    return displayIcon ? "font-semibold" : "";
  };

  const getActiveIcon = () => {
    return displayIcon ? "hover:bg-element-background" : "";
  };

  const handleClick_ = (e: any) => {
    e.preventDefault();
    if(url!= paths.HELP){
      window.location.href= url;
    }else {
      if(window.$crisp) {
        window.$crisp.push(["do", "chat:open"])
      }
    }

  }
  return (
    <div
      className={`px-1 py-2 rounded-6px
      ${getBgColor()} 
      ${getActiveIcon()} `}
    >
      <a
          href={url != "" ? url : "#"}
          onClick={handleClick_}
        className={`flex flex-row space-x-2 items-center ${
          isClosed ? "justify-center" : ""
        }`}
      >
        {displayIcon && (
          <div className="w-5 h-5">
            {isActive && IconActive ? (
              <IconActive className="w-5 h-5" />
            ) : (<>
                  <Icon className="w-5 h-5" />
            </>

            )}
          </div>
        )}

        {!isClosed && (
          <p className={`text-sm  ${getFontLabelWeight()} ${getTextColor()}`}>
            {label}
          </p>
        )}
      </a>
    </div>
  );
};
