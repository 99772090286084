import React from "react";

export const BookingContractDocsSkeleton: React.FC<{}> = () => {
  return (
    <tr className="hover:bg-element-background/40 cursor-pointer animate-pulse">
      {/* image */}
      <td className="w-96 border-t-1 border-element-border text-sm px-2 py-3 flex flex-row items-center space-x-4">
        <div className="relative">
          <div className="w-10 h-10 rounded-4px border-1 border-element-border/50 bg-slate-200" />
        </div>
      </td>

      {/* name */}
      <td className="border-t-1 border-element-border text-sm px-2 py-2">
        <div className="h-9 w-28 bg-slate-200 rounded-6px"></div>
      </td>

      {/* date */}
      <td className="border-t-1 border-element-border text-sm px-2 py-2">
        <div className="h-9 w-28 bg-slate-200 rounded-6px"></div>
      </td>

      {/* format */}
      <td className="border-t-1 border-element-border text-sm px-2 py-2">
        <div className="h-9 w-28 bg-slate-200 rounded-6px"></div>
      </td>

      {/* button */}
      <td className="border-t-1 border-element-border text-sm px-2 py-2">
        <div className="h-9 w-28 bg-slate-200 rounded-6px"></div>
      </td>
    </tr>
  );
};
