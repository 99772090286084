import paths from "../../constants/paths";
import { get } from "../../helpers/APIHelper";
import { LanguageResponse } from "../../types/GETTypes";

/**
 * Hook to fetch languages
 * @param {Function} onStart - Function to execute when the fetch starts.
 * @param {Function} onEnd - Function to execute when the fetch ends.
 * @param {Function} onSuccess - Function to execute when the fetch is successful.
 * @param {Function} onError - Function to execute when there is an error.
 */
export const useLanguages = async (
  onSuccess: (languages: LanguageResponse[] | undefined) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const res = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.LANGUAGES}`
    );

    if (res?.data.success) {
      const data: LanguageResponse[] | undefined = res?.data?.result.languages;

      if (data) {
        onSuccess(data);
      }
    } else {
      onError(res?.data.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};
