import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Common/Button/Button";
import { Title } from "../../components/Common/Title/Title";
import { AnalyticsSummaryPageProps } from "./AnalyticsSummaryPage.type";

import ArrowLeftIcon from "../../assets/icons/arrow-left.svg?react";
import ArrowRightActiveIcon from "../../assets/icons/arrow-right-active.svg?react";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import UploadIcon from "../../assets/icons/upload-white.svg?react";
import { MultiSelectRentals } from "../../components/Common/MultiSelectRentals/MultiSelectRentals";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { useGetRentals } from "../../hooks/useGetRentals";

export const AnalyticsSummaryPage: React.FC<AnalyticsSummaryPageProps> = () => {
  const { t } = useTranslation();

  const [currentYear, setCurrentYear] = useState<number>(2023);

  const { currentRentals, rentalGroups, setCurrentRentals } = useGetRentals();

  const tableLines = [
    {
      pediod: "Janvier 2023",
      nights: "0",
      remaining: "31",
      occRate: "0 %",
      commissions: "0,00 €",
      tax: "0,00 €",
      revenue: "0,00 €",
      net: "0,00 €",
      isActive: false,
      isAfter: false,
    },

    {
      pediod: "Février 2023",
      nights: "1",
      remaining: "27",
      occRate: "3,60 %",
      commissions: "4,46 €",
      tax: "0,00 €",
      revenue: "94,00 €",
      net: "89,54 €",
      isActive: false,
      isAfter: false,
    },

    {
      pediod: "Mars 2023",
      nights: "27",
      remaining: "4",
      occRate: "87,71 %",
      commissions: "107,57 €",
      tax: "0,00 €",
      revenue: "2 643,00 €",
      net: "2 535,43 €",
      isActive: false,
      isAfter: false,
    },

    {
      pediod: "Avril 2023",
      nights: "30",
      remaining: "0",
      occRate: "100,00 %",
      commissions: "119,49 €",
      tax: "0,00 €",
      revenue: "2 669,25 €",
      net: "2 849,77 €",
      isActive: false,
      isAfter: false,
    },

    {
      pediod: "Mai 2023",
      nights: "31",
      remaining: "0",
      occRate: "100,00 %",
      commissions: "129,63 €",
      tax: "0,00 €",
      revenue: "3 124,00 €",
      net: "3 037,00 €",
      isActive: false,
      isAfter: false,
    },

    {
      pediod: "Juin 2023",
      nights: "30",
      remaining: "0",
      occRate: "100,00 %",
      commissions: "117,98 €",
      tax: "155,67 €",
      revenue: "3 388,67 €",
      net: "3 114,03 €",
      isActive: false,
      isAfter: false,
    },

    {
      pediod: "Juillet 2023",
      nights: "30",
      remaining: "1",
      occRate: "96,08 %",
      commissions: "129,06 €",
      tax: "194,39 €",
      revenue: "3 778,89 €",
      net: "3 455,44 €",
      isActive: false,
      isAfter: false,
    },

    {
      pediod: "Aout 2023",
      nights: "29",
      remaining: "2",
      occRate: "93,60 %",
      commissions: "127,69 €",
      tax: "198,72 €",
      revenue: "3 745,22 €",
      net: "3 418.81 €",
      isActive: false,
      isAfter: false,
    },

    {
      pediod: "Septembre 2023",
      nights: "29",
      remaining: "1",
      occRate: "96,60 %",
      commissions: "199,31 €",
      tax: "198,72 €",
      revenue: "3 852,81",
      net: "3 521.97 €",
      isActive: false,
      isAfter: false,
    },

    {
      pediod: "Octobre 2023",
      nights: "28",
      remaining: "3",
      occRate: "90,30 %",
      commissions: "137,50 €",
      tax: "199,17 €",
      revenue: "3 825,95 €",
      net: "3 496.21 €",
      isActive: false,
      isAfter: false,
    },

    {
      pediod: "Novembre 2023",
      nights: "11",
      remaining: "19",
      occRate: "36,60 %",
      commissions: "52,73 €",
      tax: "86,74 €",
      revenue: "1 551,45 €",
      net: "1 411,99 €",
      isActive: false,
      isAfter: false,
    },

    {
      pediod: "Decembre 2023",
      nights: "3",
      remaining: "28",
      occRate: "9,60 %",
      commissions: "13,86 €",
      tax: "22,19 €",
      revenue: "407,19 €",
      net: "371,14  €",
      isActive: true,
      isAfter: false,
    },
  ];

  const getTitle = () => {
    return (
      <Title>
        <p className="flex flex-row items-center gap-1">
          {t("Analytics.title")} <ChevronRightIcon />
          {t("Analytics.summary")}
        </p>
      </Title>
    );
  };

  return (
    <>
      <MainLayout
        title={getTitle()}
        sidebarActiveItem="analytics"
        sidebarActiveSubItem="summary"
      >
        {/* Filter */}
        <div className="flex flex-row justify-between mt-2">
          <div className="flex flex-row gap-2">
            <div className="w-52">
              <MultiSelectRentals
                initialRentals={currentRentals}
                onSelect={(values) => setCurrentRentals(values)}
              />
            </div>
          </div>

          <div>
            <Button onClick={() => {}} RightIcon={UploadIcon}>
              {t("Analytics.export")}
            </Button>
          </div>
        </div>

        {/* Year Nav */}
        <div className="flex flex-row items-center justify-between gap-6 mt-4">
          <div>
            <Button
              type="secondary"
              onClick={() => setCurrentYear(currentYear - 1)}
            >
              <ArrowLeftIcon />
            </Button>
          </div>

          <div className="flex items-center justify-center rounded-sm w-96 h-9 bg-element-background-selection-subtle">
            <p className="font-semibold text-icon-active">{currentYear}</p>
          </div>

          <div>
            <Button
              type="secondary"
              onClick={() => setCurrentYear(currentYear + 1)}
            >
              <ArrowRightIcon />
            </Button>
          </div>
        </div>

        {/* Table */}
        <div className="mt-2">
          <table className="w-full overflow-hidden border-b-0 border-separate table-auto rounded-4px border-1 border-element-border border-spacing-0">
            {/* Table Header */}
            <tr className="bg-element-background border-b-1 border-element-border">
              <th className="w-40 px-2 py-2 text-sm">
                <div className="flex flex-row items-center underline cursor-pointer">
                  <p>{t("Analytics.period")}</p>
                </div>
              </th>
              <th className="px-2 py-2 text-sm">
                <div className="flex flex-row items-center justify-end cursor-pointer hover:underline">
                  <p className="text-right">{t("Analytics.nights")}</p>
                </div>
              </th>
              <th className="px-2 py-2 text-sm">
                <div className="flex flex-row items-center justify-end cursor-pointer hover:underline">
                  <p className="text-right">{t("Analytics.remaining")}</p>
                </div>
              </th>
              <th className="px-2 py-2 text-sm">
                <div className="flex flex-row items-center justify-end cursor-pointer hover:underline">
                  <p className="text-right">{t("Analytics.occRate")}</p>
                </div>
              </th>
              <th className="px-2 py-2 text-sm">
                <div className="flex flex-row items-center justify-end cursor-pointer hover:underline">
                  <p className="text-right">{t("Analytics.commissions")}</p>
                </div>
              </th>
              <th className="px-2 py-2 text-sm">
                <div className="flex flex-row items-center justify-end cursor-pointer hover:underline">
                  <p className="text-right">{t("Analytics.tax")}</p>
                </div>
              </th>

              <th className="px-2 py-2 text-sm">
                <div className="flex flex-row items-center justify-end cursor-pointer hover:underline">
                  <p className="text-right">{t("Analytics.revenue")}</p>
                </div>
              </th>

              <th className="px-2 py-2 text-sm">
                <div className="flex flex-row items-center justify-end cursor-pointer hover:underline">
                  <p className="text-right">{t("Analytics.net")}</p>
                </div>
              </th>
            </tr>

            {tableLines?.map((t) => (
              <TableLine
                period={t.pediod}
                nights={t.nights}
                remaining={t.remaining}
                occRate={t.occRate}
                commissions={t.commissions}
                tax={t.tax}
                revenue={t.revenue}
                net={t.net}
                isActive={t.isActive}
                isAfter={t.isAfter}
              />
            ))}

            <TableLine
              period={t("Analytics.total")}
              nights={"350"}
              remaining={"80"}
              occRate={"73,00 %"}
              commissions={"4356,24 €"}
              tax={"1967,34 €"}
              revenue={"34 892,34 €"}
              net={"32 342,78 €"}
              isActive={false}
              isAfter={false}
              isTotal={true}
            />
          </table>
        </div>
      </MainLayout>
    </>
  );
};

type TableLineProps = {
  period: string;
  nights: string;
  remaining: string;
  occRate: string;
  commissions: string;
  tax: string;
  revenue: string;
  net: string;
  isActive: boolean;
  isAfter: boolean;
  isTotal?: boolean;
};

const TableLine: React.FC<TableLineProps> = ({
  period,
  nights,
  remaining,
  occRate,
  commissions,
  tax,
  revenue,
  net,
  isActive,
  isAfter,
  isTotal,
}) => {
  const textColor = isAfter ? "text-low-contrast" : "text-high-contrast";
  const font = isTotal ? "font-semibold" : "";

  return (
    <tr
      className={`text-sm hover:bg-element-background/40 ${
        isAfter || isTotal ? "bg-element-background" : ""
      }`}
    >
      <td className="flex flex-row items-center border-b-1 border-element-border">
        {isActive && <ArrowRightActiveIcon className="w-4 h-4 ml-2" />}
        <p
          className={`mx-2 my-2 font-semibold ${
            isActive ? "text-icon-active" : textColor
          }`}
        >
          {period}
        </p>
      </td>

      <td className="text-right border-b-1 border-element-border">
        <p className={`mx-2 my-2 ${textColor} ${font}`}>{nights}</p>
      </td>

      <td className="text-right border-b-1 border-element-border">
        <p className={`mx-2 my-2 ${textColor} ${font}`}>{remaining}</p>
      </td>

      <td className="text-right border-b-1 border-element-border">
        <p className={`mx-2 my-2 ${textColor} ${font}`}>{occRate}</p>
      </td>

      <td className="text-right border-b-1 border-element-border">
        <p className={`mx-2 my-2 ${textColor} ${font}`}>{commissions}</p>
      </td>

      <td className="text-right border-b-1 border-element-border">
        <p className={`mx-2 my-2 ${textColor} ${font}`}>{tax}</p>
      </td>

      <td className="text-right border-b-1 border-element-border">
        <p className={`mx-2 my-2 ${textColor} ${font}`}>{revenue}</p>
      </td>

      <td className="text-right border-b-1 border-element-border">
        <p className={`mx-2 my-2 ${textColor} ${font}`}>{net}</p>
      </td>
    </tr>
  );
};
