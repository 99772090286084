import React, {useEffect, useState} from "react";
import {GuestPageExtraDetail} from "../../../../types/GETTypes";
import {ReservationExtraOrderStatusEnum} from "../../../../enums/GETenums";
import {getReservationExtraOrderStatusBadge} from "../../../../helpers/reservationHelper";
import {Button} from "../../../Common/Button/Button";
import {useTranslation} from "react-i18next";
import ArrowTopRightIcon from "../../../../assets/icons/arrow-up-right.svg?react";
import {CenterModal} from "../../../Common/CenterModal/CenterModal";
import {Switch} from "../../../Common/Switch/Switch";
import {post} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {popUp} from "./ExtraOrderModal";

const ExtraCard = ({
  extra,
  locale,
  currency,
  onChange,
  openPop,
}: {
  extra: GuestPageExtraDetail;
  locale: string;
  currency: string;
  onChange: (extraId: number, value: boolean) => void;
  openPop: (popUpToOpen: popUp, extra?: GuestPageExtraDetail) => void;
}) => {
  const {t} = useTranslation();
  const {
    photo,
    name,
    description,
    quantity,
    unit_price_value,
    payment_status,
    extra_available,
  } = extra || {};

  const nameExtra = name[locale];
  const descriptionExtra = description[locale];

  const changeAvailable = () => {
    onChange(extra.id, !extra_available);
  };

  useEffect(() => {}, [extra]);

  const RightButtonComponentByStatus = () => {
    switch (payment_status) {
      case ReservationExtraOrderStatusEnum.REFUNDED:
        return (
          <div className="flex flex-col items-end space-y-2">
            {getReservationExtraOrderStatusBadge(payment_status)}
            <Button
              type="secondary"
              className="w-fit h-9"
              disabled={true}
              RightIcon={ArrowTopRightIcon}
            >
              {t("Booking.ExtrasOrder.ExtraOrderModal.displayDetails")}
            </Button>
          </div>
        );
      case ReservationExtraOrderStatusEnum.CANCELED:
      case ReservationExtraOrderStatusEnum.UNPAID:
        return getReservationExtraOrderStatusBadge(payment_status);
      case ReservationExtraOrderStatusEnum.PRE_AUTHORIZED:
        return (
          <div
            className="flex items-center cursor-pointer space-x-2"
            onClick={changeAvailable}
          >
            <Switch value={extra_available ?? false} />
            <span
              className={`font-semibold font-bold ${
                extra_available ? "text-active" : "text-low-contrast"
              }`}
            >
              {t(
                `Booking.ExtrasOrder.ExtraOrderModal.${
                  extra_available ? "available" : "unavailable"
                }`
              )}
            </span>
          </div>
        );
      case ReservationExtraOrderStatusEnum.PAID:
        return (
          <Button
            type="alert"
            className="w-fit h-9"
            buttonClassName="border-active"
            onClick={() => openPop("refund", extra)}
          >
            {t("Booking.ExtrasOrder.ExtraOrderModal.toRefund")}
          </Button>
        );
      default:
        return <></>;
    }
  };

  if (!extra) {
    return null;
  }

  return (
    <>
      <div className="flex items-center space-x-4 mb-4 md:w-full">
        <img
          src={photo?.[0]?.original_url}
          alt={nameExtra}
          className="w-[96px] h-[96px] rounded-md object-cover"
        />
        <div className="flex-1">
          <div className="flex justify-between">
            <div className="flex flex-col w-1/2">
              <h2 className="text-high-contrast font-semibold">{nameExtra}</h2>
              <p className="text-low-contrast">{descriptionExtra}</p>
              <div className="flex items-center justify-between">
                <span id="quantity" className="text-high-contrast font-bold">
                  {quantity} x {unit_price_value} {currency}
                </span>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex mt-4">
                <RightButtonComponentByStatus />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExtraCard;
