import React from "react";
import { useTranslation } from "react-i18next";
import {
  RegisterTypeCheckboxesProps,
  RegisterTypeSubmitType,
} from "./RegisterType.type";
import { useEffect, useState } from "react";
import { CheckBox } from "../../../Common/CheckBox/CheckBox";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";

export const RegisterTypeCheckboxes: React.FC<RegisterTypeCheckboxesProps> = ({
  value,
  error,
  loading = false,
  onChange,
}) => {
  const { t } = useTranslation();

  const [currentValue, setCurrentValue] = useState<RegisterTypeSubmitType>({
    investor: false,
    sub: false,
    janitorial: false,
    agency: false,
  });

  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }
  }, [value]);

  const handleChange = (nextValue: RegisterTypeSubmitType) => {
    setCurrentValue(nextValue);
    onChange(nextValue);
  };

  return (
    <div>
      <div className="mt-8">
        <p className="font-semibold text-sm">{t("Register.Type.select")}</p>

        <CheckBox
          label={
            <span className="font-semibold text-sm">
              {t("Register.Type.investorRealEstate")}
            </span>
          }
          onChange={(val) => handleChange({ ...currentValue, investor: val })}
          value={currentValue.investor}
          disabled={loading}
          withBorder={true}
        />

        <CheckBox
          label={
            <span className="font-semibold text-sm">
              {t("Register.Type.sub")}
            </span>
          }
          onChange={(val) => handleChange({ ...currentValue, sub: val })}
          value={currentValue.sub}
          disabled={loading}
          withBorder={true}
        />

        <CheckBox
          label={
            <span className="font-semibold text-sm">
              {t("Register.Type.janitorial")}
            </span>
          }
          onChange={(val) => handleChange({ ...currentValue, janitorial: val })}
          value={currentValue.janitorial}
          disabled={loading}
          withBorder={true}
        />

        <CheckBox
          label={
            <span className="font-semibold text-sm">
              {t("Register.Type.agency")}
            </span>
          }
          onChange={(val) => handleChange({ ...currentValue, agency: val })}
          value={currentValue.agency}
          disabled={loading}
          withBorder={true}
        />
      </div>

      <ErrorMessage>{error}</ErrorMessage>
    </div>
  );
};
