import React, { useState } from "react";
import { CenterModal } from "../../Common/CenterModal/CenterModal";
import { t } from "i18next";
import { TaskItemResponse } from "../../../types/GETTypes";
import { SimpleRadio } from "../../Common/SimpleRadio/SimpleRadio";
import { TextInput } from "../../Common/TextInput/TextInput";
import { Button } from "../../Common/Button/Button";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { post } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import { useTranslation } from "react-i18next";

type ReasonType =
  | "USELESS_TASK"
  | "NOT_AVAILABLE_FOR_TASK"
  | "PROBLEM_TO_DO_TASK"
  | "OTHER"
  | undefined;

export const CancelTask: React.FC<{
  task: TaskItemResponse | undefined | null;
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ task, isVisible, onClose, onSuccess }) => {
  const [reason, setReason] = useState<ReasonType>();
  const [remarks, setRemarks] = useState<string>("");

  const [errorForm, setErrorForm] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleChangeReason = (nextReason: ReasonType) => {
    setErrorForm(null);
    setReason(nextReason);
  };

  const handleCancel = async () => {
    setError(null);
    setErrorForm(null);

    if (
      task?.assigned_or_not_task_data?.is_owned_by_me !== true &&
      reason === undefined
    ) {
      setErrorForm(t("Task.Cancel.reasonRequired"));
    }

    setLoading(true);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.TASKS.CANCEL
      }`,
      {
        task_id: task?.id?.toString(),
        cancelation_reason:
          task?.assigned_or_not_task_data?.is_owned_by_me === true
            ? "CANCELED_BY_OWNER"
            : reason,
        cancelation_remarks: remarks,
      }
    );

    if (res.data?.success) {
      onSuccess();
      onClose();
    } else {
      setError(res?.response?.data?.message);
    }

    setLoading(false);
  };

  const handleClose = () => {
    if (loading) return;

    setReason(undefined);
    setRemarks("");
    setError(null);
    setErrorForm(null);

    onClose();
  };

  return (
    <CenterModal
      title={t("Task.Cancel.title")}
      isVisible={isVisible}
      onClose={handleClose}
    >
      <div className="flex flex-col gap-2 text-sm">
        {task?.assigned_or_not_task_data?.is_owned_by_me === true ? (
          <p className="font-light text-low-contrast">
            {t("Task.Cancel.content1")}
          </p>
        ) : (
          <p className="font-light text-low-contrast">
            {t("Task.Cancel.content2")}
          </p>
        )}

        {task?.assigned_or_not_task_data?.is_owned_by_me !== true && (
          <>
            <Reason
              value="USELESS_TASK"
              onChange={handleChangeReason}
              selectedValue={reason}
            />

            <Reason
              value="NOT_AVAILABLE_FOR_TASK"
              onChange={handleChangeReason}
              selectedValue={reason}
            />

            <Reason
              value="PROBLEM_TO_DO_TASK"
              onChange={handleChangeReason}
              selectedValue={reason}
            />

            <Reason
              value="OTHER"
              onChange={handleChangeReason}
              selectedValue={reason}
            />

            <ErrorMessage errorType="FORM">{errorForm}</ErrorMessage>

            <TextInput
              label={t("Task.Cancel.remarks")}
              placeholder={t("Task.Cancel.remarksPlaceholder")}
              value={remarks}
              onChangeText={setRemarks}
            />
          </>
        )}

        <ErrorMessage>{error}</ErrorMessage>

        <div className="flex gap-2 mt-2">
          <Button type="secondary" disabled={loading} onClick={handleClose}>
            {t("Global.cancel")}
          </Button>

          <Button onClick={handleCancel} loading={loading}>
            {t("Task.Cancel.button")}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};

const Reason: React.FC<{
  value: ReasonType;
  onChange: (value: ReasonType) => void;
  selectedValue: ReasonType;
}> = ({ value, onChange, selectedValue }) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-2 cursor-pointer" onClick={() => onChange(value)}>
      <SimpleRadio value={selectedValue === value} />

      <div className="flex flex-col gap-1">
        <p className="font-semibold text-high-contrast">
          {t(`Task.Cancel.${value}Title`)}
        </p>
        <p className="font-light text-low-contrast">
          {t(`Task.Cancel.${value}Content`)}
        </p>
      </div>
    </div>
  );
};
