import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CenterModal} from "../../Common/CenterModal/CenterModal";
import {NotificationStatusesListItemResponse} from "../../../types/GETTypes";
import {Button} from "../../Common/Button/Button";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import {update} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import {Controller, useForm} from "react-hook-form";
import {CalendarInput} from "../../Common/CalendarInput/CalendarInput";
import {TimeInput} from "../../Common/TimeInput/TimeInput";
import {capitalizeFirstLetter} from "../../../helpers/stringHelper";
import moment from "moment";
import {validateDate} from "../../../helpers/calendarHelper";
import {formatTime} from "../../../helpers/dateHelper";

type ScheduleForm = {scheduleDate: Date; scheduleTime: string};

export const ScheduledMessageEnableModal: React.FC<{
  message: NotificationStatusesListItemResponse | null | undefined;
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({message, isVisible, onClose, onSuccess}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [formError, setFormError] = useState<string | null>(null);

  const form = useForm<ScheduleForm>({
    defaultValues: {
      scheduleDate: new Date(),
      scheduleTime: "00:00",
    },
  });

  useEffect(() => {
    if (message) {
      form.setValue("scheduleDate", moment(message?.ready_at).toDate());
      form.setValue("scheduleTime", formatTime(message?.ready_at));
    }
  }, [message]);

  

  const handleEnable = async (values: ScheduleForm) => {
    setError(null);
    setFormError(null);

    const {isValid, reason, value} = validateDate(values);

    if (!isValid) {
      reason === "missing"
        ? setFormError(t("ScheduledMessageList.Enable.formErrorMissing")) : 
      reason === "day"
        ? setFormError(t("ScheduledMessageList.Enable.formErrorDay", {value}))
        : setFormError(t("ScheduledMessageList.Enable.formErrorTime", {value}));
      return;
    }

    setLoading(true);

    const URL = `${import.meta.env.VITE_API_URL}${
      paths.API.SCHEDULED_MESSAGE
    }/${message?.id}${paths.API.SCHEDULED_MESSAGE_ENABLE}`;

    const res = await update(URL, {
      ready_at: `${moment(values.scheduleDate).format("YYYY-MM-DD")} ${
        values.scheduleTime
      }`,
    });

    if (res.data?.success) {
      onSuccess();
      handleClose();
    } else {
      setError(res?.response?.data?.message);
    }

    setLoading(false);
  };

  const handleClose = () => {
    setFormError(null);
    setError(null);
    onClose();
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={handleClose}
      title={t("ScheduledMessageList.Enable.title")}
    >
      <form onSubmit={form.handleSubmit(handleEnable)}>
        <p className="font-light text-low-contrast">
          {t("ScheduledMessageList.Enable.description")}
        </p>

        <div className="h-4" />

        <Controller
          control={form.control}
          name="scheduleDate"
          rules={{
            minLength: {
              value: 5,
              message: t("ScheduledMessageList.Enable.scheduleError"),
            },
            maxLength: {
              value: 5,
              message: t("ScheduledMessageList.Enable.scheduleError"),
            },
          }}
          render={({field: {value, onChange}}) => (
            <CalendarInput
              label={t("ScheduledMessageList.Enable.schedule")}
              dateType="default"
              value={value}
              onChangeDate={onChange}
              classNames={{button: "h-8"}}
              isExcludeDate={(date: Date) => {
                const d = moment(date).startOf("day");
                const today = moment().startOf("day");
                return d.isBefore(today, "day");
              }}
            />
          )}
        />

        <div className="h-4" />

        <Controller
          control={form.control}
          name="scheduleTime"
          render={({field: {value, onChange}}) => (
            <TimeInput
              label={capitalizeFirstLetter(t("Global.at"))}
              value={value}
              onChange={onChange}
            />
          )}
        />

        <ErrorMessage errorType="FORM">{formError}</ErrorMessage>
        <ErrorMessage>{error}</ErrorMessage>

        <div className="flex flex-row gap-3 mt-4">
          <Button type="secondary" onClick={handleClose} disabled={loading}>
            {t("Global.cancel")}
          </Button>

          <Button loading={loading} RightIcon={CheckWhiteIcon}>
            {t("ScheduledMessageList.Enable.save")}
          </Button>
        </div>
      </form>
    </CenterModal>
  );
};
