import moment from "moment";
import React, { useEffect, useState } from "react";
import { CalendarLayout } from "../../components/Layout/CalendarLayout/CalendarLayout";
import {InProgressPlaceholder} from "../../components/Common/Dashboard/InProgressPlaceholder";

export const CalendarYearPage: React.FC<{}> = () => {
  return (
      <CalendarLayout
        navbarActiveItem="year"
        isCheckinPrice={false}
        displayBy="none"
      >
       <InProgressPlaceholder/>
      </CalendarLayout>

  );
};
