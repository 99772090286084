import React, { forwardRef, useImperativeHandle } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LogInIcon from "../../../../assets/icons/log-in.svg?react";
import LogOutIcon from "../../../../assets/icons/log-out.svg?react";
import { RequiredFields } from "../../../../types/commonTypes";
import { RentalLightListItemResponse } from "../../../../types/GETTypes";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import { NumberInput } from "../../../Common/NumberInput/NumberInput";
import { Separator } from "../../../Common/Separator/Separator";
import { SimpleRadio } from "../../../Common/SimpleRadio/SimpleRadio";
import { TimeInput } from "../../../Common/TimeInput/TimeInput";
import {
  ProgressionRentalsRulesModalNextStepOtherFields,
  ProgressionRentalsRulesModalRef,
} from "../ProgressionRentalsRulesModal.type";

export type ProgressionRentalsRulesRulesForm = {
  checkin_min_time: string;
  checkin_max_time: string;
  checkout_min_time: string;
  checkout_max_time: string;
  reservation_daytime_limit: string;
  min_days_prior_reservations: number;
  hours_or_days: "hours" | "days";
  min_nights_default: number;
  max_nights_default: number;
};

export const ProgressionRentalsRulesRules = forwardRef<
  ProgressionRentalsRulesModalRef,
  {
    rental?: RentalLightListItemResponse;
    onNext: (
      nextRental: RentalLightListItemResponse,
      nextOtherFields: ProgressionRentalsRulesModalNextStepOtherFields
    ) => void;
    rentalRules: UseFormReturn<
      ProgressionRentalsRulesRulesForm,
      any,
      undefined
    >;
    otherFields: ProgressionRentalsRulesModalNextStepOtherFields | undefined;
  }
>(({ rental, otherFields, onNext, rentalRules }, ref) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    getValues,
    formState: { errors },
    trigger,
    watch,
  } = rentalRules;

  const requiredFields: RequiredFields<ProgressionRentalsRulesRulesForm> = {
    checkin_min_time: false,
    checkin_max_time: false,
    checkout_min_time: false,
    checkout_max_time: true,
    reservation_daytime_limit: false,
    min_days_prior_reservations: false,
    hours_or_days: false,
    min_nights_default: false,
    max_nights_default: false,
  };

  watch();

  const handleNext = () => {
    const nextRental = {
      ...rental!,
      checkin_min_time: getValues("checkin_min_time"),
      checkin_max_time: getValues("checkin_max_time"),
      checkout_min_time: getValues("checkout_min_time"),
      checkout_max_time: getValues("checkout_max_time"),
      reservation_daytime_limit: getValues("reservation_daytime_limit"),
      min_days_prior_reservations: getValues("min_days_prior_reservations"),
      min_nights_default: getValues("min_nights_default"),
      max_nights_default: getValues("max_nights_default"),
    };

    onNext(nextRental, {
      ...otherFields!,
      hours_or_days: getValues("hours_or_days"),
    });
  };

  // * Expose method to submit from parent component (ProgressionRentalsRulesModal)
  useImperativeHandle(ref, () => ({
    submit: async () =>
      await trigger()
        .then((validFields) => {
          if (validFields) {
            handleNext();
          }
        })
        .catch((err) => console.error(err)),
  }));

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-1">
        <LogInIcon className="w-6 h-6" />
        <p className="font-semibold text-low-contrast">
          {t("Progression.RentalsRules.Rules.checkin")}
        </p>
      </div>

      <div className="grid grid-cols-2 gap-3">
        <div>
          <InputSelect
            register={register("checkin_min_time", {
              required: {
                value: requiredFields.checkin_min_time,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Progression.RentalsRules.Rules.minTimeIn"),
                }),
              },
            })}
            required={requiredFields.checkin_min_time}
            label={t("Progression.RentalsRules.Rules.minTimeIn")}
            items={[
              {
                label: "Flexible",
                value: "flexible",
              },
              {
                label: "08:00",
                value: "08:00",
              },
              {
                label: "09:00",
                value: "09:00",
              },
              {
                label: "10:00",
                value: "10:00",
              },
              {
                label: "11:00",
                value: "11:00",
              },
              {
                label: "12:00",
                value: "12:00",
              },
              {
                label: "13:00",
                value: "13:00",
              },
              {
                label: "14:00",
                value: "14:00",
              },
              {
                label: "15:00",
                value: "15:00",
              },
              {
                label: "16:00",
                value: "16:00",
              },
              {
                label: "17:00",
                value: "17:00",
              },
              {
                label: "18:00",
                value: "18:00",
              },
              {
                label: "19:00",
                value: "19:00",
              },
              {
                label: "20:00",
                value: "20:00",
              },
              {
                label: "21:00",
                value: "21:00",
              },
              {
                label: "22:00",
                value: "22:00",
              },
              {
                label: "23:00",
                value: "23:00",
              },
            ]}
            selectedValue={getValues("checkin_min_time")}
            error={errors.checkin_min_time?.message}
          />
        </div>

        <div>
          <InputSelect
            register={register("checkin_max_time", {
              required: {
                value: requiredFields.checkin_max_time,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Progression.RentalsRules.Rules.maxTimeIn"),
                }),
              },
            })}
            required={requiredFields.checkin_max_time}
            label={t("Progression.RentalsRules.Rules.maxTimeIn")}
            items={[
              {
                label: "Flexible",
                value: "flexible",
              },
              {
                label: "17:00",
                value: "17:00",
              },
              {
                label: "18:00",
                value: "18:00",
              },
              {
                label: "19:00",
                value: "19:00",
              },
              {
                label: "20:00",
                value: "20:00",
              },
              {
                label: "21:00",
                value: "21:00",
              },
              {
                label: "22:00",
                value: "22:00",
              },
              {
                label: "23:00",
                value: "23:00",
              },
              {
                label: "00:00",
                value: "00:00",
              },
              {
                label: "01:00 (jour suivant)",
                value: "01:00",
              },
              {
                label: "02:00 (jour suivant)",
                value: "02:00",
              },
            ]}
            selectedValue={getValues("checkin_max_time")}
            error={errors.checkin_max_time?.message}
          />
        </div>
      </div>

      <Separator />

      <div className="flex items-center gap-1">
        <LogOutIcon className="w-6 h-6" />
        <p className="font-semibold text-low-contrast">
          {t("Progression.RentalsRules.Rules.checkout")}
        </p>
      </div>

      <div className="grid grid-cols-2 gap-3">
        <div>
          <InputSelect
            register={register("checkout_min_time", {
              required: {
                value: requiredFields.checkout_min_time,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Progression.RentalsRules.Rules.minTimeOut"),
                }),
              },
            })}
            required={requiredFields.checkout_min_time}
            label={t("Progression.RentalsRules.Rules.minTimeOut")}
            items={[
              {
                label: "00:00",
                value: "00:00",
              },
              {
                label: "01:00",
                value: "01:00",
              },
              {
                label: "02:00",
                value: "02:00",
              },
              {
                label: "03:00",
                value: "03:00",
              },
              {
                label: "04:00",
                value: "04:00",
              },
              {
                label: "05:00",
                value: "05:00",
              },
              {
                label: "06:00",
                value: "06:00",
              },
              {
                label: "07:00",
                value: "07:00",
              },
              {
                label: "08:00",
                value: "08:00",
              },
              {
                label: "09:00",
                value: "09:00",
              },
              {
                label: "10:00",
                value: "10:00",
              },
              {
                label: "11:00",
                value: "11:00",
              },
              {
                label: "12:00",
                value: "12:00",
              },
              {
                label: "13:00",
                value: "13:00",
              },
              {
                label: "14:00",
                value: "14:00",
              },
              {
                label: "15:00",
                value: "15:00",
              },
              {
                label: "16:00",
                value: "16:00",
              },
              {
                label: "17:00",
                value: "17:00",
              },
              {
                label: "18:00",
                value: "18:00",
              },
              {
                label: "19:00",
                value: "19:00",
              },
              {
                label: "20:00",
                value: "20:00",
              },
              {
                label: "21:00",
                value: "21:00",
              },
              {
                label: "22:00",
                value: "22:00",
              },
              {
                label: "23:00",
                value: "23:00",
              },
            ]}
            selectedValue={getValues("checkout_min_time")}
            error={errors.checkout_min_time?.message}
          />
        </div>

        <div>
          <InputSelect
            register={register("checkout_max_time", {
              required: {
                value: requiredFields.checkout_max_time,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Progression.RentalsRules.Rules.maxTimeOut"),
                }),
              },
            })}
            required={requiredFields.checkout_max_time}
            label={t("Progression.RentalsRules.Rules.maxTimeOut")}
            items={[
              {
                label: "00:00",
                value: "00:00",
              },
              {
                label: "01:00",
                value: "01:00",
              },
              {
                label: "02:00",
                value: "02:00",
              },
              {
                label: "03:00",
                value: "03:00",
              },
              {
                label: "04:00",
                value: "04:00",
              },
              {
                label: "05:00",
                value: "05:00",
              },
              {
                label: "06:00",
                value: "06:00",
              },
              {
                label: "07:00",
                value: "07:00",
              },
              {
                label: "08:00",
                value: "08:00",
              },
              {
                label: "09:00",
                value: "09:00",
              },
              {
                label: "10:00",
                value: "10:00",
              },
              {
                label: "11:00",
                value: "11:00",
              },
              {
                label: "12:00",
                value: "12:00",
              },
              {
                label: "13:00",
                value: "13:00",
              },
              {
                label: "14:00",
                value: "14:00",
              },
              {
                label: "15:00",
                value: "15:00",
              },
              {
                label: "16:00",
                value: "16:00",
              },
              {
                label: "17:00",
                value: "17:00",
              },
              {
                label: "18:00",
                value: "18:00",
              },
              {
                label: "19:00",
                value: "19:00",
              },
              {
                label: "20:00",
                value: "20:00",
              },
              {
                label: "21:00",
                value: "21:00",
              },
              {
                label: "22:00",
                value: "22:00",
              },
              {
                label: "23:00",
                value: "23:00",
              },
            ]}
            selectedValue={getValues("checkout_max_time")}
            error={errors.checkout_max_time?.message}
          />
        </div>
      </div>

      <p className="font-semibold text-high-contrast">
        {t("Progression.RentalsRules.Rules.daytimeLimit")}
      </p>

      <div className="flex items-center gap-3">
        {getValues("hours_or_days") === "hours" && (
          <TimeInput
            register={register("reservation_daytime_limit", {
              required: {
                value: requiredFields.reservation_daytime_limit,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Progression.RentalsRules.Rules.daytimeLimit"),
                }),
              },
            })}
            required={requiredFields.reservation_daytime_limit}
            placeholder="23:00"
            value={getValues("reservation_daytime_limit")}
            error={errors.reservation_daytime_limit?.message}
          />
        )}

        {getValues("hours_or_days") === "days" && (
          <NumberInput
            register={register("min_days_prior_reservations", {
              required: {
                value: requiredFields.min_days_prior_reservations,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Progression.RentalsRules.Rules.daytimeLimit"),
                }),
              },
            })}
            required={requiredFields.min_days_prior_reservations}
            placeholder="2"
            value={getValues("min_days_prior_reservations")}
            error={errors.min_days_prior_reservations?.message}
          />
        )}
        <div className="flex items-center space-x-2">
          <Controller
            control={control}
            name="hours_or_days"
            render={({ field: { onChange, value } }) => (
              <>
                <SimpleRadio
                  label={t("Progression.RentalsRules.Rules.limit_hour")}
                  onClick={() => onChange("hours")}
                  value={value === "hours"}
                  classNames={{
                    parent: "flex-col items-start",
                    label: "text-xxs font-bold",
                  }}
                />
                <SimpleRadio
                  label={t("Progression.RentalsRules.Rules.limit_day")}
                  onClick={() => onChange("days")}
                  value={value === "days"}
                  classNames={{
                    parent: "flex-col items-start",
                    label: "text-xxs font-bold",
                  }}
                />
              </>
            )}
          />
        </div>
      </div>

      <Separator />

      <div className="grid grid-cols-2 gap-3">
        <NumberInput
          label={t("Progression.RentalsRules.Rules.minNights")}
          register={register("min_nights_default", {
            required: {
              value: requiredFields.min_nights_default,
              message: t("Global.Errors.requiredField", {
                fieldName: t("Progression.RentalsRules.Rules.minNights"),
              }),
            },
          })}
          required={requiredFields.min_nights_default}
          placeholder="1"
          value={getValues("min_nights_default")}
          error={errors.min_nights_default?.message}
        />

        <NumberInput
          label={t("Progression.RentalsRules.Rules.maxNights")}
          register={register("max_nights_default", {
            required: {
              value: requiredFields.max_nights_default,
              message: t("Global.Errors.requiredField", {
                fieldName: t("Progression.RentalsRules.Rules.maxNights"),
              }),
            },
          })}
          required={requiredFields.max_nights_default}
          placeholder="30"
          value={getValues("max_nights_default")}
          error={errors.max_nights_default?.message}
        />
      </div>

      <p className="text-sm text-low-contrast">
        {t("Progression.RentalsRules.Rules.information")}
      </p>
    </div>
  );
});
