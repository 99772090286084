import React from "react";
import { cn } from "../../../helpers/classHelper";
import { SettingElementProps } from "./SettingElement.type";
import PhotoEmpty from "../../../assets/icons/photo-hide.svg?react";

export const SettingElement: React.FC<SettingElementProps> = ({
  Icon,
  title,
  labelTooltip,
  description,
  children,
  secondChildren,
  classNames,
  disabled,
  onClick,
  src,
  type,
  subtile
}) => {


  const getFileType = (fileUrl: string) => {
    if (!fileUrl) return "unknown";
    const imageTypes =/\.(jpg|jpeg|png|gif|bmp|tiff|webp|heif)(?:\?.*)?$/i;
    if (imageTypes.test(fileUrl)) {
      return "image"
    }
  };
  return (
    <div
      className={cn(
        `flex flex-col p-2 space-y-4 rounded bg-subtle border-1 border-element-active`,
        {
          "cursor-pointer": onClick,
          "cursor-not-allowed": disabled,
        }
      )}
      aria-disabled={disabled}
      onClick={onClick}
    >
      <div
        className={cn(
          `flex items-center justify-between`,
          `${classNames?.container ?? ""}`
        )}
      >
        <div className="flex items-center gap-3">
          {
            getFileType(src ?? '') !== "image" && type!='file'  ? (
                    Icon ? <Icon width={24} height={24}/> : null) :
                getFileType(src ?? '') !== "image" &&  type ==='file' ?
                    <div className="bg-gray-100 p-5">
                      <PhotoEmpty width={24} height={24}/>
                    </div>

                    :
                <img src={src} className="h-16 w-16 border-2 border-element-border/50 rounded-6px object-cover object-center bg-element-background"/>
          }

          <div className="flex flex-col">
            <div className="flex items-center space-x-1">
              <p className="font-bold text-high-contrast text-md">{title}</p>
              {labelTooltip ? labelTooltip : null}
            </div>
            <p className="text-low-contrast text-md">{description}</p>
            {
              subtile ? <p className="text-low-contrast text-md italic">({subtile})</p> : null
            }

          </div>
        </div>
        {children}
      </div>
      {secondChildren ? secondChildren : null}
    </div>
  );
};
