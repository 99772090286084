import {create} from "zustand";
import {ExtrasOrder} from "../../components/GuestPageUser/Common/ExtraComponent";
import {SendExtraData, ValidateExtraResponse} from "../../types/GETTypes";

interface ExtrasOrderState {
  extrasOrder: ExtrasOrder[];
  storeExtraOrder: (extrasOrder: ExtrasOrder[]) => void;
  extrasValidateData: ValidateExtraResponse | undefined;
  storeExtraValidateData: (extrasValidateData: ValidateExtraResponse) => void;
  sendExtraData: SendExtraData | undefined;
  storeSendExtraData: (sendExtraData: SendExtraData) => void;
}

const useExtrasStore = create<ExtrasOrderState>((set, get) => ({
  extrasOrder: [],
  storeExtraOrder: (newExtrasOrder: ExtrasOrder[]) =>
    set((state) => ({
      extrasOrder: newExtrasOrder,
    })),
  extrasValidateData: undefined,
  storeExtraValidateData: (newExtrasValidateData: ValidateExtraResponse) =>
    set((state) => ({
      extrasValidateData: newExtrasValidateData,
    })),
  sendExtraData: undefined,
  storeSendExtraData: (newSendExtraData: SendExtraData) =>
    set((state) => ({
      sendExtraData: newSendExtraData,
    })),
}));

export default useExtrasStore;
