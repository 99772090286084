import React from "react";
import { TableListItemProps } from "../../Common/TableList/TableList.type";
import { getPlatformIcon } from "../../../helpers/platformHelper";
import { RentalLightListItemResponse } from "../../../types/GETTypes";

export const ProgressionStepRentalsTableItem: React.FC<TableListItemProps> = ({
  data,
  onClick,
}) => {
  const rental: RentalLightListItemResponse = data;

  console.log("ProgressionStepRentalsTableItem", rental);

  return (
    <tr>
      <td
        className="p-2 text-sm border-t-1 border-element-border cursor-pointer"
        onClick={() => onClick(rental?.id)}
      >
        <div className="flex gap-4">
          <div className="relative">
            <img
              src={rental?.cover}
              className="w-24 h-16 shrink-0 object-cover rounded-6px border-element-border border-1"
            />

            {rental?.external_connexions?.platform_airbnb && (
              <div className="absolute" style={{ top: 22, left: 83 }}>
                {getPlatformIcon({ id: 1, logo: "", name: "AIRBNB.COM" })}
              </div>
            )}
          </div>

          <div className="flex flex-col mt-1">
            <p className="font-semibold text-high-contrast">{rental?.name}</p>
            <p className="font-light text-low-contrast">{rental?.address}</p>
            <p className="font-light text-low-contrast">
              {rental?.postal_code} {rental?.city} {rental?.country.name}
            </p>
          </div>
        </div>
      </td>
    </tr>
  );
};
