import React from "react";
import { useTranslation } from "react-i18next";
import { RentalRules2 } from "../../../../pages/RentalPage/RentalPage.type";
import { SettingElement } from "../../../Common/SettingElement/SettingElement";

import EuroIcon from "../../../../assets/icons/euro.svg?react";
import GivingMoneyIcon from "../../../../assets/icons/giving-money.svg?react";
import MoneyIcon from "../../../../assets/icons/money.svg?react";
import PercentageIcon from "../../../../assets/icons/percentage.svg?react";

export const RentalPricingView: React.FC<{
  pricingRules: RentalRules2 | undefined;
}> = ({ pricingRules }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3">
      {/* CURRENCY */}
      <SettingElement
        Icon={EuroIcon}
        title={t("Rental.Pricing.PricingRules.currencyTitle")}
        description={t("Rental.Pricing.PricingRules.currencyDesc")}
      >
        <span className="text-lg font-semibold text-active">
          {`${pricingRules?.currency.name}`}
        </span>
      </SettingElement>

      {/* BASE PRICE */}
      <SettingElement
        Icon={MoneyIcon}
        title={t("Rental.Pricing.PricingRules.priceTitle")}
        description={t("Rental.Pricing.PricingRules.priceDesc")}
      >
        <span className="text-lg font-semibold text-active">
          {pricingRules?.price_night_default} €
        </span>
      </SettingElement>

      {/* MIN PRICE */}
      <SettingElement
        Icon={MoneyIcon}
        title={t("Rental.Pricing.PricingRules.minPriceTitle")}
        description={t("Rental.Pricing.PricingRules.minPriceDesc")}
      >
        <span className="text-lg font-semibold text-active">
          {pricingRules?.price_night_min} €
        </span>
      </SettingElement>

      {/* VAT */}
      <SettingElement
        Icon={PercentageIcon}
        title={t("Rental.Pricing.PricingRules.vatTitle")}
        description={t("Rental.Pricing.PricingRules.vatDesc")}
      >
        <span className="text-lg font-semibold text-active">
          {pricingRules?.vat_percentage} %
        </span>
      </SettingElement>

      {/* HOLIDAY TAX */}
      <SettingElement
        Icon={GivingMoneyIcon}
        title={t("Rental.Pricing.PricingRules.taxTitle")}
        description={t("Rental.Pricing.PricingRules.taxDesc")}
      >
        {pricingRules?.city_tax_percent !== null ? (
          <span className="text-lg font-semibold text-active">
            {`${pricingRules?.city_tax_percent} %`}
          </span>
        ) : null}

        {pricingRules?.city_tax_default !== null ? (
          <span className="text-lg font-semibold text-active">
            {`${pricingRules?.city_tax_default} €`}
          </span>
        ) : null}
      </SettingElement>
    </div>
  );
};
