import React from "react";
import { TaskItemResponse } from "../../../types/GETTypes";
import { RightModal } from "../../Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import CloseCircleRedIcon from "../../../assets/icons/close-circle-red.svg?react";
import moment from "moment";
import { Separator } from "../../Common/Separator/Separator";
import { PreviewImage } from "../../Common/PreviewImage/PreviewImage";
import { useModal } from "../../../hooks/useModal";

export const EditTaskCanceled: React.FC<{
  isVisible: boolean;
  onClose: () => void;
  task: TaskItemResponse;
}> = ({ isVisible, onClose, task }) => {
  const { t } = useTranslation();
  const previewImage = useModal();

  const getTitle = () => {
    return (
      <div className="flex items-center justify-between text-base">
        <p className="text-high-contrast font-semibold">{task?.name}</p>

        <div className="flex gap-2 bg-[#FFEFEF] rounded-6px items-center w-fit p-3">
          <CloseCircleRedIcon className="size-4" />
          <p className="text-[#CD2B31] font-semibold">
            {t("Task.List.canceled")}
          </p>
        </div>
      </div>
    );
  };

  const getOperatorsAvatars = (task: TaskItemResponse) => {
    return (
      <div
        className="relative"
        style={{ minWidth: 32 + 16 * (task.assigned_operators?.length - 1) }}
      >
        {task.assigned_operators?.map((ope, index) => (
          <img
            className="size-8 rounded-full border-1 border-element-border"
            src={ope.photo}
            style={{ paddingLeft: index * 16 }}
          />
        ))}
      </div>
    );
  };

  const getOperatorsNames = (task: TaskItemResponse) => {
    const names = task.assigned_operators?.map(
      (ope) => `${ope.first_name} ${ope.last_name}`,
    );

    return (
      <p className="font-semibold text-high-contrast line-clamp-1">
        {names?.join(",")}
      </p>
    );
  };

  return (
    <RightModal title={getTitle()} isVisible={isVisible} onClose={onClose}>
      <div className="flex flex-col h-full w-full">
        <label className="text-low-contrast font-bold text-sm">
          {t("Global.info")}
        </label>

        <p>
          {t("Task.Modal.dateToDo")}{" "}
          <span className="font-semibold text-high-contrast">
            {moment(task?.scheduled_date).format("dddd DD MMMM YYYY")} -
            {" " + task?.scheduled_time}
          </span>
        </p>

        {/* Rental and Assignees */}
        <div className="flex justify-between">
          {/* Rental infos */}
          <div className="flex gap-2 my-2 mx-2 items-center">
            <img
              className="size-12 rounded-6px border-1 border-element-border shrink-0 cursor-pointer"
              src={task?.rental?.cover}
              onClick={() => previewImage.open()}
            />

            <PreviewImage
              imageUrl={task?.rental?.cover}
              imageModal={previewImage}
            />

            <div className="flex flex-col">
              <p className="font-semibold text-high-contrast">
                {task?.rental?.name}
              </p>
              <p className="font-light text-low-contrast">
                {task?.rental?.address} {task?.rental?.postal_code}{" "}
                {task?.rental?.city}
              </p>
            </div>
          </div>

          {task && (
            <div className="flex gap-2 items-center min-w-44">
              {getOperatorsAvatars(task)}
              <div className="flex flex-col">
                {getOperatorsNames(task)}
                <p className="text-low-contrast">
                  {t("Task.Modal.personAssigned")}
                </p>
              </div>
            </div>
          )}
        </div>

        <Separator />

        <p className="font-semibold text-low-contrast flex gap-1 items-center">
          <span>
            {t("Task.Modal.taskCanceledAt", {
              date: moment(task?.canceled_task_data?.cancelation_date).format(
                "DD/MM/YYYY",
              ),
              time: moment(task?.canceled_task_data?.cancelation_date).format(
                "HH:mm",
              ),
            })}
          </span>

          <img
            className="size-6 rounded-full border border-element-border"
            src={task?.canceled_task_data?.canceled_by?.photo}
          />

          <span className="text-high-contrast">
            {task?.canceled_task_data?.canceled_by?.first_name}{" "}
            {task?.canceled_task_data?.canceled_by?.last_name}
          </span>
        </p>

        <p className="text-high-contrast font-light mt-2">
          {t("Task.Modal.cancelReason")}
        </p>
        <p className="text-high-contrast font-semibold">
          {t(
            `Task.Cancel.${task?.canceled_task_data?.cancelation_reason}Title`,
          )}
        </p>

        {task?.canceled_task_data?.cancelation_remarks !== null &&
          task?.canceled_task_data?.cancelation_remarks !== "" && (
            <>
              <p className="mt-2">{t("Task.Modal.cancelRemarks")}</p>
              <p className="text-high-contrast font-semibold">
                {task?.canceled_task_data?.cancelation_remarks}
              </p>
            </>
          )}
      </div>
    </RightModal>
  );
};
