import React from "react";
import {CenterModal} from "../../Common/CenterModal/CenterModal";
import {DocsState, nameTypeFile} from "./GuestPageForm";
import {useTranslation} from "react-i18next";
import CarteIdentite from "../../../assets/images/carteidentite.svg?react";
import Passeport from "../../../assets/images/passeport.svg?react";
import Selfie from "../../../assets/images/selfie-large.svg?react";
import CB from "../../../assets/images/cb.svg?react";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import FilesRequiredComponent, {CustomFile} from "./FilesRequiredComponent";
import {Button} from "../../Common/Button/Button";
import useWindowSize from "../../../hooks/useWindowSize";

interface ModalProps {
  type: nameTypeFile | null;
  onClose: () => void;
  isVisible: boolean;
  docs: DocsState;
  handleFiles: (files: CustomFile[]) => void;
  setNewFiles: React.Dispatch<React.SetStateAction<CustomFile[] | undefined>>;
  newFiles: CustomFile[] | undefined;
  loading: boolean;
  files: CustomFile[];
}
const ModalFilesFormComponent = ({
  type,
  onClose,
  isVisible,
  handleFiles,
  setNewFiles,
  newFiles,
  loading,
  docs,
  files,
}: ModalProps) => {
  const {t} = useTranslation();
  const {isMobile} = useWindowSize();

  const getTitle = () => {
    switch (type) {
      case "id_card_recto":
      case "id_card_verso":
        return t("GuestPage.LoginDetails.cardIdModalTitle");
      case "passport":
        return t("GuestPage.LoginDetails.passeportModalTitle");
      case "selfie":
        return t("GuestPage.LoginDetails.selfieModalTitle");
      case "cb":
        return t("GuestPage.LoginDetails.cbModalTitle");
      default:
        return "";
    }
  };

  const getDescription = () => {
    switch (type) {
      case "id_card_recto":
      case "id_card_verso":
        return t("GuestPage.LoginDetails.cardIdDescription");
      case "passport":
        return t("GuestPage.LoginDetails.passeportDescription");
      case "selfie":
        return t("GuestPage.LoginDetails.selfieDescription");
      case "cb":
        return t("GuestPage.LoginDetails.cbDescription");
      default:
        return "";
    }
  };

  const getCheckList = () => {
    switch (type) {
      case "id_card_recto":
      case "id_card_verso":
        return [
          t("GuestPage.LoginDetails.photoWellFramed"),
          t("GuestPage.LoginDetails.noReflection"),
          t("GuestPage.LoginDetails.photoClearGoodQuality"),
          t("GuestPage.LoginDetails.allInformationReadable"),
          t("GuestPage.LoginDetails.nameMatchesReservation"),
        ];
      case "passport":
        return [
          t("GuestPage.LoginDetails.photoWellFramed"),
          t("GuestPage.LoginDetails.noReflection"),
          t("GuestPage.LoginDetails.photoClearGoodQuality"),
          t("GuestPage.LoginDetails.allInformationReadable"),
          t("GuestPage.LoginDetails.nameMatchesReservation"),
        ];
      case "selfie":
        return [
          t("GuestPage.LoginDetails.faceVisibleNotHiddenByCard"),
          t("GuestPage.LoginDetails.cardYouAreHoldingIsReadable"),
          t("GuestPage.LoginDetails.photoClearGoodQuality"),
          t("GuestPage.LoginDetails.nameMatchesReservation"),
        ];
      case "cb":
        return [
          t("GuestPage.LoginDetails.photoWellFramed"),
          t("GuestPage.LoginDetails.noReflection"),
          t("GuestPage.LoginDetails.photoClearGoodQuality"),
          t("GuestPage.LoginDetails.cardFieldsHiddenNameVisible"),
          t("GuestPage.LoginDetails.nameMatchesReservation"),
        ];
      default:
        return [];
    }
  };

  const getRules = () => {
    switch (type) {
      case "id_card_recto":
      case "id_card_verso":
      case "passport":
        return [
          t("GuestPage.LoginDetails.acceptedFormatsPdfPngJpgJpeg"),
          t("GuestPage.LoginDetails.maxFileSize10Mb"),
        ];
      case "selfie":
      case "cb":
        return [
          t("GuestPage.LoginDetails.acceptedFormatsPngJpgJpeg"),
          t("GuestPage.LoginDetails.maxFileSize10Mb"),
        ];
      default:
        return [];
    }
  };

  const getImage = () => {
    switch (type) {
      case "id_card_recto":
      case "id_card_verso":
        return <CarteIdentite className="w-36 h-24 object-cover" />;
      case "passport":
        return <Passeport className="w-36 h-24 object-cover" />;
      case "selfie":
        return <Selfie className="w-36 h-56 object-cover" />;
      case "cb":
        return <CB className="w-36 h-24 object-cover" />;
      default:
        return "";
    }
  };

  const getFiles = () => {
    switch (type) {
      case "id_card_recto":
      case "id_card_verso":
        return docs.card;
      case "passport":
        return docs.passport;
      case "selfie":
        return docs.selfie;
      case "cb":
        return docs.cb;
      default:
        return [];
    }
  };

  const getFileLabel = (typeDoc: nameTypeFile) => {
    switch (typeDoc) {
      case "id_card_recto":
        return t("GuestPage.LoginDetails.addFileRectoLabel");
      case "id_card_verso":
        return t("GuestPage.LoginDetails.addFileVersoLabel");
      case "passport":
        return t("GuestPage.LoginDetails.addFileLabel");
      case "selfie":
        return t("GuestPage.LoginDetails.addFileLabel");
      case "cb":
        return t("GuestPage.LoginDetails.addFileLabel");
      default:
        return "";
    }
  };

  const getFileAccept = () => {
    switch (type) {
      case "id_card_recto":
      case "id_card_verso":
      case "passport":
        return "image/jpeg, image/png, image/jpg, application/pdf";
      case "selfie":
      case "cb":
        return "image/jpeg, image/png, image/jpg";
      default:
        return undefined;
    }
  };

  const getEnabled = () => {
    switch (type) {
      case "id_card_recto":
      case "id_card_verso":
        return Boolean(
          newFiles?.find((file) => file.name === "id_card_recto") &&
            newFiles?.find((file) => file.name === "id_card_verso")
        );
      case "passport":
      case "selfie":
      case "cb":
        return Boolean(newFiles?.find((file) => file.name === type));
      default:
        return false;
    }
  };

  const prepareFile = (filesToAddForValidate: CustomFile[]) => {
    let filesToValidate = [...files];

    filesToAddForValidate.forEach((newFile) => {
      const existingFileIndex = filesToValidate.findIndex(
        (f) => f.name === newFile.name
      );

      if (existingFileIndex !== -1) {
        filesToValidate[existingFileIndex] = {
          file: newFile.file,
          name: newFile.name,
        };
      } else {
        filesToValidate.push({file: newFile.file, name: newFile.name});
      }
    });

    return filesToValidate;
  };

  const validateFile = () => {
    let filesToAddForValidate: CustomFile[] = [];
    switch (type) {
      case "id_card_recto":
      case "id_card_verso":
        filesToAddForValidate =
          newFiles?.filter(
            (f) => f.name === "id_card_recto" || f.name === "id_card_verso"
          ) || [];
        break;
      case "passport":
      case "selfie":
      case "cb":
        const foundFile = newFiles?.find((f) => f.name === type);
        if (foundFile) filesToAddForValidate = [foundFile];
        break;
      default:
        break;
    }

    if (filesToAddForValidate.length > 0) {
      const filesToValidate = prepareFile(filesToAddForValidate);
      handleFiles(filesToValidate);
    } else {
      console.log("No files to validate");
    }
    onClose();
  };

  return (
    <CenterModal
      isVisible={isVisible}
      title={getTitle()}
      onClose={onClose}
      size="large"
      classNames={{divModal: "w-full md:w-1/2 max-w-2xl"}}
    >
      <div className="">
        <div className="text-high-contrast my-3 text-sm">
          {getDescription()}
        </div>
        <div className="flex flex-col md:flex-row h-2/3 border-b-1 border-element-border mb-3 pb-3">
          <div className="flex w-full md:w-1/4 justify-center md:justify-start items-center mr-4">
            {getImage()}
          </div>
          <div className="flex flex-col text-sm space-y-2 w-2/3">
            <div className="flex flex-col mb-2">
              <p className="font-bold mb-4">
                {t("GuestPage.LoginDetails.checkFile")} :
              </p>
              <div className="flex flex-col text-xs space-y-2 ml-2">
                {getCheckList().map((text) => (
                  <li>{text}</li>
                ))}
              </div>
            </div>
            <div className="flex flex-col ">
              <p className="font-bold mb-4">
                {t("GuestPage.LoginDetails.rulesFile")} :
              </p>
              <div className="flex flex-col text-xs space-y-2 ml-2">
                {getRules().map((text) => (
                  <li>{text}</li>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col h-1/3">
          <p className="text-sm font-bold mb-4">
            {t("GuestPage.LoginDetails.importFile")} :
          </p>
          <div className="flex flex-row justify-center space-x-12 mr-12 mb-2">
            {getFiles().map((doc) => {
              return (
                <FilesRequiredComponent
                  doc={doc}
                  loading={loading}
                  setNewFiles={setNewFiles}
                  newFiles={newFiles}
                  key={doc.name}
                  addFileLabel={getFileLabel(doc.name)}
                  accept={getFileAccept()}
                />
              );
            })}
          </div>
          <div className="flex flex-row justify-center">
            <Button
              className=""
              buttonClassName="md:h-10 bg-element-border/10 md:min-w-[250px]"
              textClassName="md:text-base"
              RightIcon={CheckIcon}
              disabled={!getEnabled()}
              displayLargeBtn={isMobile ? true : false}
              loading={loading}
              onClick={validateFile}
            >
              {t("Global.validate")}
            </Button>
          </div>
        </div>
      </div>
    </CenterModal>
  );
};

export default ModalFilesFormComponent;
