import paths from "../../constants/paths";
import { get } from "../../helpers/APIHelper";
import { ValueType } from "../../types/commonTypes";
import {
  OperationalManagementTeamMemberForUserItemResponse,
  OperationalManagementTeamMemberResponse,
} from "../../types/GETTypes";

export const useOperationalManagementMember = ({
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  onSuccess: (
    data?: OperationalManagementTeamMemberResponse[] | undefined
  ) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}) => {
  /**
   * Asynchronously retrieves all members from the operational management API.
   *
   * This function initiates an API call to fetch member data. It triggers the
   * `onStart` callback if defined, and upon completion, it calls either the
   * `onSuccess` or `onError` callback based on the API response. The `onEnd`
   * callback is executed after the operation concludes, regardless of success
   * or failure.
   *
   * @async
   * @function getAll
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   */
  const getAll = async () => {
    if (typeof onStart === "function") {
      onStart();
    }
    try {
      const response = await get(
        `${import.meta.env.VITE_API_URL}${
          paths.API.OPERATIONNAL_MANAGEMENT.MEMBERS
        }`
      );
      if (response?.data?.success) {
        onSuccess(response?.data?.result?.members);
      } else {
        onError(response?.data?.message ?? response.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };
  return { getAll };
};

export const useOperationalManagementTeam = ({
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  onSuccess: (
    data?: OperationalManagementTeamMemberForUserItemResponse[] | undefined
  ) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}) => {
  /**
   * Fetches all teams associated with a specific user.
   *
   * This function makes an asynchronous request to retrieve teams for a given user
   * identified by `memberId`. It triggers lifecycle callbacks `onStart`, `onSuccess`,
   * `onError`, and `onEnd` at appropriate stages of the request.
   *
   * @param {Object} params - The parameters for the request.
   * @param {ValueType | undefined} params.memberId - The ID of the member whose teams are to be fetched.
   *
   * @returns {Promise<void>} - A promise that resolves when the operation is complete.
   */
  const getAllForUser = async ({
    memberId,
  }: {
    memberId: ValueType | undefined;
  }) => {
    if (typeof onStart === "function") {
      onStart();
    }
    if (memberId) {
      try {
        const response = await get(
          `${import.meta.env.VITE_API_URL}${
            paths.API.OPERATIONNAL_MANAGEMENT.GET_TEAMS_FOR_USER
          }/${memberId}`
        );
        if (response?.data?.success) {
          onSuccess(response?.data?.result?.teams);
        } else {
          onError(response?.data?.message ?? response.message);
        }
      } catch (error: any) {
        onError(error.message);
      } finally {
        if (typeof onEnd === "function") {
          onEnd();
        }
      }
    }
  };
  return { getAllForUser };
};
