import React from "react";
import {useTranslation} from "react-i18next";
import moment from "moment-timezone";
import {
  GuestPageExtraOrder,
  PaymentExtraListItemResponse,
  ReservationResponse,
} from "../../../../types/GETTypes";

const ExtraOrderInfoCard = ({
  reservation,
  extraOrder,
}: {
  reservation: ReservationResponse | PaymentExtraListItemResponse | undefined;
  extraOrder: GuestPageExtraOrder | undefined;
}) => {
  const {t} = useTranslation();
  const {guest} = reservation || {};
  const {payment_success_date} = extraOrder || {};
  const userTimezone = moment.tz.guess();
  const paidDate = payment_success_date ? moment.utc(payment_success_date).tz(userTimezone).format("DD MMMM YYYY HH:mm") : null

  if (!reservation || !extraOrder) {
    return null;
  }
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col items-start flex-1 space-y-1">
        <p className="text-base text-low-contrast">
          {t("Payments.PaymentReservationList.emailLabel")}
        </p>
        <p className="font-semibold text-high-contrast">{guest?.email}</p>
      </div>

      <div className="flex flex-col items-start flex-1 space-y-1">
        <p className="text-base text-low-contrast">
          {t("Payments.PaymentReservationList.paidOnLabel")}
        </p>
        <p className="font-semibold text-high-contrast">
          {paidDate ?? "N/A"}
        </p>
      </div>
    </div>
  );
};

export default ExtraOrderInfoCard;
