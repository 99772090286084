import {TFunction} from "i18next";
import {ExtraAvailableUntil} from "../enums/GETenums";

const listAvailableUntil = (t: TFunction<"translation", undefined>) => [
  {
    value: "CHECKOUT",
    label: t("Rental.Infos.TravelerPage.ExtraCard.immediatelyAfterReservation"),
  },
  {
    value: "CHECKOUT-1",
    label: t("Rental.Infos.TravelerPage.ExtraCard.immediatelyAfterCheckin"),
  },
  {
    value: "CHECKOUT-2",
    label: t("Rental.Infos.TravelerPage.ExtraCard.onHourLaterCheckin"),
  },
  {
    value: "CHECKOUT-3",
    label: t("Rental.Infos.TravelerPage.ExtraCard.fourHourLaterCheckin"),
  },
  {
    value: "CHECKOUT-4",
    label: t("Rental.Infos.TravelerPage.ExtraCard.onDayAfterCheckin"),
  },
  {
    value: "CHECKOUT-5",
    label: t("Rental.Infos.TravelerPage.ExtraCard.fourHourAfterCheckout"),
  },
  {
    value: "CHECKOUT-6",
    label: t("Rental.Infos.TravelerPage.ExtraCard.untilCheckout"),
  },
];

export const getListAvailableUntilData = (
  t: TFunction<"translation", undefined>,
  value?: keyof ExtraAvailableUntil
) => {
  if (!value) {
    return {
      value: "",
      label: "",
    };
  }
  return listAvailableUntil(t).find((item) => item.value === value);
};
