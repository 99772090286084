export enum AutoMessageUpsertStepEnum {
  general = 0,
  message,
  rentals,
  platforms,
}

export type AutoMessageUpsertModalProps = {
  type: "add" | "edit";
  isVisible: boolean;
  onClose: () => void;
};
