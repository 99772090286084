import React from "react";
import {useTranslation} from "react-i18next";
import {usePaymentReservationStatusBadge} from "../../../pages/PaymentReservationList/PaymentReservationList.hooks";
import {GuestPagePaymentScheduleItem} from "../../../types/GETTypes";
import {Button} from "../../Common/Button/Button";
import moment from "moment";
import {getPaymentStatusBadge} from "../../../helpers/reservationHelper";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import {PaymentReservationStatusEnum} from "../../../enums/GETenums";
import useWindowSize from "../../../hooks/useWindowSize";
import {getPlatformByName} from "../../../helpers/platformHelper";
import {PLATFORM_RESERVATION} from "../../../pages/Booking/BookingPage.type";

const GuestPagePaymentItemComponent = ({
  payment,
  onClick,
}: {
  payment: GuestPagePaymentScheduleItem;
  onClick: ({payment_link}: {payment_link: string}) => void;
}) => {
  const {isMobile} = useWindowSize();
  const {guestPageData, isPreviewMode, rentalPreview} = useGuestPageDataStore();
  const {rental, platform_name} = guestPageData || {};
  const {currency} = rental || {};
  const {tarification: tarificationPreview} = rentalPreview || {};
  const {rules: {rules: {currency: currencyPreview} = {}} = {}} =
    tarificationPreview || {};
  const {payment_status, price_value, specific_date, payment_link} = payment;
  const {t} = useTranslation();
  const paymentDate = moment(specific_date).format("DD/MM/YYYY");

  const disabled = !(
    payment_status === PaymentReservationStatusEnum.UNPAID ||
    payment_status === PaymentReservationStatusEnum.OVERDUE
  );

  const displayPayNow =
    getPlatformByName(platform_name) !==
    (PLATFORM_RESERVATION.AIRBNB || PLATFORM_RESERVATION.BOOKING);

  return (
    <div className="md:flex flex-col md:flex-row justify-between">
      <div className="flex flex-row justify-between items-center mt-8">
        <div className="">
          <div className="text-low-contrast mb-1 md:text-base">
            {t("GuestPage.Payment.account", {date: paymentDate})}
          </div>
          <div className="text-high-contrast font-bold md:text-lg">
            {price_value}{" "}
            {(isPreviewMode ? currencyPreview?.symbol : currency?.symbol) ??
              "€"}
          </div>
        </div>
        {isMobile && (
          <div className="flex flex-row justify-between items-center order-2 md:ml-2">
            {getPaymentStatusBadge(payment_status)}
          </div>
        )}
      </div>
      <div className="flex items-center justify-between">
        {displayPayNow && (
          <div className="mt-2 md:mt-0 w-full md:w-52 order-1">
            <Button
              type="secondary"
              className="md:flex md:flex-1 md:justify-end"
              buttonClassName="w-fit"
              textClassName="md:text-base font-bold"
              onClick={() => onClick({payment_link})}
              disabled={isPreviewMode || disabled}
            >
              {t("GuestPage.Payment.payNow")}
            </Button>
          </div>
        )}
        {!isMobile && (
          <div className="flex flex-row justify-between items-center order-2 md:ml-2">
            {getPaymentStatusBadge(payment_status)}
          </div>
        )}
      </div>
    </div>
  );
};

export default GuestPagePaymentItemComponent;
