import React from "react";
import { useTranslation } from "react-i18next";
import { ProgressionStep } from "../../../pages/Progression/ProgressionStep";
import { ProgressionStepTeamProps } from "../Progression.type";
import { Button } from "../../Common/Button/Button";

export const ProgressionStepTeam: React.FC<ProgressionStepTeamProps> = ({
  status,
  visible,
  number,
  error,
  loading,
}) => {
  const { t } = useTranslation();

  if (!visible) return null;

  return (
    <ProgressionStep
      number={number.toString()}
      title={t("Progression.StepTeam.title")}
      status={status}
      error={error}
      loading={loading}
    >
      <p className="text-low-contrast font-light mt-1">
        {t("Progression.StepTeam.content")}
      </p>

      <div className="w-72 mt-4">
        <Button>{t("Progression.StepTeam.button")}</Button>
      </div>
    </ProgressionStep>
  );
};
