import React from "react";
import {tabType} from "../GuestPageHome/GuestPageHomeComponent";
import GuestPageTabsNav from "../GuestPageHome/GuestPageTabsNav";
import {GuestPageConnectResponse} from "../../../types/GETTypes";

const GuestPageLayout = ({
  tab,
  setTab,
  children,
}: {
  tab: tabType;
  setTab: React.Dispatch<React.SetStateAction<tabType>>;
  children?: React.ReactNode;
}) => {
  return (
    <GuestPageTabsNav tab={tab} setTab={setTab}>
      {children}
    </GuestPageTabsNav>
  );
};

export default GuestPageLayout;
