import React from "react";
import { Card } from "../../Common/Card/Card";
import {
  OperationalManagementTeamListItemResponse,
  OperationalManagementTeamMemberResponse,
} from "../../../types/GETTypes";
import { Trans, useTranslation } from "react-i18next";
import UsersIcon from "../../../assets/icons/users.svg?react";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import { ManagementTeamMemberItem } from "./ManagementTeamMemberItem";
import { ValueType } from "../../../types/commonTypes";
import { ManagementTeamListEmpty } from "./ManagementTeamListEmpty";
import LogOutRedIcon from "../.././../assets/icons/log-out-active.svg?react";

export const ManagementTeamListGroupItem: React.FC<{
  team: OperationalManagementTeamListItemResponse;
  onAdd: (teamId: ValueType) => void;
  onEdit: (team: OperationalManagementTeamListItemResponse) => void;
  onViewMember: (params: {
    member: OperationalManagementTeamMemberResponse;
    team: OperationalManagementTeamListItemResponse;
  }) => void;
  onEditMember: (params: {
    member: OperationalManagementTeamMemberResponse;
  }) => void;
  onLeave: (team: OperationalManagementTeamListItemResponse) => void;
}> = ({ team, onAdd, onEdit, onViewMember, onEditMember, onLeave }) => {
  const { t } = useTranslation();

  const getLabelNode = () => {
    return (
      <div className="flex gap-2 items-center">
        <p className="text-high-contrast font-semibold">
          {t("Management.TeamList.teamOf", { name: team.name })}
        </p>

        <img
          className="size-8 rounded-full border-1 border-element-border"
          src={team.owner.photo!}
        />

        <p className="text-high-contrast font-semibold">{team.owner.name}</p>
      </div>
    );
  };

  return (
    <Card
      Icon={UsersIcon}
      labelNode={getLabelNode()}
      button={{
        label: t("Management.addMember"),
        Icon: PlusIcon,
        onClick: () => onAdd(team.id),
      }}
      secondaryButton={{
        label: "",
        Icon: EditIcon,
        onClick: () => onEdit(team),
      }}
      customButton={{
        label: t("Management.leaveTeam"),
        type: "alert",
        rightIcon: LogOutRedIcon,
        onClick: () => onLeave(team),
      }}
    >
      <div className="flex flex-col gap-3">
        {team.members?.length === 0 && <ManagementTeamListEmpty />}

        {team.members?.map((member) => (
          <ManagementTeamMemberItem
            key={member.id}
            member={member}
            onView={() => onViewMember({ member, team })}
            onEdit={() => onEditMember({ member })}
          />
        ))}
      </div>
    </Card>
  );
};
