import React from "react";

import UserGroupIcon from "../../../assets/icons/user-group.svg?react";
import LogInIcon from "../../../assets/icons/log-in.svg?react";
import LogOutIcon from "../../../assets/icons/log-out.svg?react";

export const ProgressionRentalsRulesTableItemSkeleton: React.FC<{}> = () => {
  return (
    <tr className="animate-pulse hover:bg-element-background/30 cursor-pointer">
      {/* Rental */}
      <td className="w-32 px-4 py-2 text-sm border-t-1 border-element-border">
        <div className="flex flex-row gap-2 items-center">
          <div className="w-12 h-12 object-fill flex-shrink-0 rounded-4px border-1 border-element-border/50 overflow-hidden bg-slate-200"></div>

          <div className="flex flex-col max-w-[300px]">
            <p className="text-high-contrast font-semibold h-2 bg-slate-200 rounded w-32"></p>
            <p className="text-low-contrast font-light h-2 bg-slate-200 w-32 rounded mt-1"></p>
            <p className="text-low-contrast font-light h-2 bg-slate-200 w-32 rounded mt-1"></p>
          </div>
        </div>
      </td>

      {/* CAPACITY */}
      <td className="w-1/12 p-2 border-t-1 border-element-border">
        <div className="flex gap-2 justify-center items-center h-full">
          <UserGroupIcon />
          <div>
            <p className="font-light text-low-contrast h-2 w-16 bg-slate-200 rounded"></p>
          </div>
        </div>
      </td>

      {/* Checkin / Checkout */}
      <td className="w-1/12 p-2 border-t-1 border-element-border">
        <div className="flex flex-col items-center gap-2">
          <div className="flex flex-row space-x-1 text-low-contrast items-center">
            <LogInIcon />
            <p className="font-light text-low-contrast h-2 w-16 bg-slate-200 rounded mt-1"></p>
          </div>
          <div className="flex flex-row space-x-1 text-low-contrast items-center">
            <LogOutIcon />
            <p className="font-light text-low-contrast h-2 w-16 bg-slate-200 rounded mt-1"></p>
          </div>
        </div>
      </td>

      {/* Extra traveller */}
      <td className="w-2/12 p-2 border-t-1 border-element-border">
        <p className="block mx-auto font-bold text-high-contrast text-lg text-center bg-slate-200 h-2 rounded w-24"></p>
      </td>

      {/* Cleaning fees*/}
      <td className="w-1/12 p-2 border-t-1 border-element-border">
        <p className="block mx-auto font-bold text-high-contrast text-lg text-center bg-slate-200 h-2 rounded w-24"></p>
      </td>

      {/* Deposit */}
      <td className="w-1/12 p-2 border-t-1 border-element-border">
        <p className="block mx-auto font-bold text-high-contrast text-lg text-center bg-slate-200 h-2 rounded w-24"></p>
      </td>

      {/* Holiday tax */}
      <td className="w-1/12 p-2 border-t-1 border-element-border">
        <p className="block mx-auto font-light text-low-contrast h-2 w-16 bg-slate-200 rounded"></p>
      </td>

      {/* Price Multiplier */}
      <td className="w-1/12 p-2 border-t-1 border-element-border">
        <div className="flex flex-col justify-center items-center gap-2">
          <div className="flex items-center gap-2">
            <div className="rounded-full bg-slate-200 w-6 h-6"></div>
            <p className="font-light text-low-contrast h-2 w-16 bg-slate-200 rounded"></p>
          </div>
          <div className="flex items-center gap-2">
            <div className="rounded-full bg-slate-200 w-6 h-6"></div>
            <p className="font-light text-low-contrast h-2 w-16 bg-slate-200 rounded"></p>
          </div>
        </div>
      </td>

      {/* Edit button */}
      <td className="w-1/12 p-4 border-t-1 border-element-border">
        <div className="block mx-auto h-8 bg-slate-200 w-28 rounded-6px" />
      </td>
    </tr>
  );
};
