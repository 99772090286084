import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "use-local-storage";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg?react";
import SuperhoteIcon from "../../assets/icons/superhote.svg?react";
import Carousel1Image from "../../assets/images/register-carousel-1.svg?react";
import Carousel2Image from "../../assets/images/register-carousel-2.svg?react";
import Carousel3Image from "../../assets/images/register-carousel-3.svg?react";
import { RegisterPassword } from "../../components/Authentication/Register/Password/RegisterPassword";
import { RegisterPasswordSubmit } from "../../components/Authentication/Register/Password/RegisterPassword.type";
import { RegisterPersonnal } from "../../components/Authentication/Register/Personnal/RegisterPersonnal";
import { RegisterPersonnalSubmitType } from "../../components/Authentication/Register/Personnal/RegisterPersonnal.type";
import { RegisterPlatform } from "../../components/Authentication/Register/Platform/RegisterPlatform";
import { RegisterPlatformSubmitType } from "../../components/Authentication/Register/Platform/RegisterPlatform.type";
import { RegisterSummary } from "../../components/Authentication/Register/Summary/RegisterSummary";
import { RegisterThanks } from "../../components/Authentication/Register/Thanks/RegisterThanks";
import {
  RegisterThanksForm,
  RegisterThanksObjectives,
} from "../../components/Authentication/Register/Thanks/RegisterThanks.type";
import { RegisterType } from "../../components/Authentication/Register/Type/RegisterType";
import { RegisterTypeSubmitType } from "../../components/Authentication/Register/Type/RegisterType.type";
import { Button } from "../../components/Common/Button/Button";
import { Carousel } from "../../components/Common/Carousel/Carousel";
import { Loader } from "../../components/Common/Loader/Loader";
import { FullScreenLayout } from "../../components/Layout/FullScreenLayout/FullScreenLayout";
import { StepProgress } from "../../components/StepProgress/StepProgress";
import { AccessDenied } from "../../components/WorkInProgress/AccessDenied";
import paths from "../../constants/paths";
import { get, post } from "../../helpers/APIHelper";
import {
  RegisterDataType,
  RegisterPageProps,
  RegisterPageStep,
} from "./RegisterPage.type";

export const RegisterPage: React.FC<RegisterPageProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [storeEmail, setStoreEmail] = useLocalStorage<string | null>(
    "email",
    null
  );

  const [registerData, setRegisterData] = useState<RegisterDataType>({
    current_step: RegisterPageStep.personnal,
    personnal: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
    },
    properties: {
      types: [],
      platforms: [],
    },
  });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");

  const [password, setPassword] = useState<string>("");

  const [fetchLoading, setFetchLoading] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [isTokenValid, setIsTokenValid] = useState<boolean | undefined>(
    undefined
  );

  const isValidToken = async () => {
    const response = await get(
      `${
        import.meta.env.VITE_API_URL
      }/auth/register/invitations/verify-token/${token}`
    );
    if (response.data?.success) {
      setIsTokenValid(true);
    } else {
      setIsTokenValid(false);
    }
  };

  useEffect(() => {
    const fetchCurrentStep = async (email: string) => {
      const response = await get(
        `${import.meta.env.VITE_API_URL}/auth/register?email=${email}`
      );
      if (response.data?.success) {
        setRegisterData(response.data.result);
      }

      setFetchLoading(false);
    };
    if (storeEmail) {
      fetchCurrentStep(storeEmail);
    } else {
      setFetchLoading(false);
    }
    isValidToken();
  }, []);

  const handleBack = () => {
    const backStep = registerData.current_step - 1;

    setRegisterData({
      ...registerData,
      current_step: backStep < 0 ? registerData.current_step : backStep,
    });
  };

  const needToDisplayBackButton: () => boolean = () => {
    if (
      registerData.current_step &&
      registerData.current_step >= 1 &&
      registerData.current_step < RegisterPageStep.thanks
    ) {
      return true;
    }
    return false;
  };

  const initSubmit = () => {
    setError("");
    setLoading(true);
  };

  const updateStates = (response: any) => {
    setLoading(false);
    if (response.data?.success) {
      setRegisterData({
        ...response.data?.result,
        properties: {
          ...response.data?.result.properties,
          platforms: registerData?.properties.platforms,
        },
      });
    } else {
      setError(response.response.data.message);
    }
  };

  const handleSubmitPersonnal = async (value: RegisterPersonnalSubmitType) => {
    initSubmit();
    setStoreEmail(value.email);

    const response = await post(
      `${import.meta.env.VITE_API_URL}/auth/register/personnal`,
      {
        first_name: value.firstName,
        last_name: value.lastName,
        email: value.email,
        phone: value.phone,
        locale: "fr",
        token: token,
      }
    );

    updateStates(response);
  };

  const registerTypeSubmitTypeToStringArray = (
    value: RegisterTypeSubmitType
  ) => {
    const types = [];
    if (value.agency) {
      types.push("agency");
    }
    if (value.investor) {
      types.push("investor");
    }
    if (value.janitorial) {
      types.push("janitorial");
    }
    if (value.sub) {
      types.push("sub");
    }

    return types;
  };

  const handleChangeTypes = (value: RegisterTypeSubmitType) => {
    const types = registerTypeSubmitTypeToStringArray(value);

    setRegisterData({
      ...registerData,
      properties: { ...registerData.properties, types },
    });
  };

  const handleSubmitTypes = async (value: RegisterTypeSubmitType) => {
    initSubmit();

    const types = registerTypeSubmitTypeToStringArray(value);

    const response = await post(
      `${import.meta.env.VITE_API_URL}/auth/register/types`,
      {
        email: registerData.personnal.email,
        types,
      }
    );

    updateStates(response);
  };

  const handleChangePlatforms = (value: RegisterPlatformSubmitType) => {
    setRegisterData({
      ...registerData,
      properties: { ...registerData.properties, platforms: value.platforms },
    });
  };

  const handleSubmitPlatforms = async (value: RegisterPlatformSubmitType) => {
    initSubmit();

    const response = await post(
      `${import.meta.env.VITE_API_URL}/auth/register/platforms`,
      {
        email: registerData.personnal.email,
        platforms: value.platforms,
      }
    );

    updateStates(response);
  };

  const handleSubmitPassword = async (value: RegisterPasswordSubmit) => {
    initSubmit();

    setPassword(value.password);

    const response = await post(
      `${import.meta.env.VITE_API_URL}/auth/register/password`,
      {
        first_name: registerData?.personnal?.first_name!,
        last_name: registerData?.personnal?.last_name!,
        email: registerData?.personnal?.email!,
        phone: registerData?.personnal?.phone!,
        locale: "fr",
        password: value.password,
      }
    );

    updateStates(response);
  };

  const handleLogin = async (data: RegisterThanksForm) => {
    initSubmit();
    await post(`${import.meta.env.VITE_API_URL}/auth/register/quiz`, {
      email: registerData.personnal?.email,
      usage: data.usage,
      objectives: Object.keys(data.objectives)
        .filter(
          (key) =>
            data.objectives[key as keyof RegisterThanksObjectives] === true
        )
        .join(","),
      other_objective_value: data.objectives.otherValue,
      token: token,
    });
    const response = await post(`${import.meta.env.VITE_API_URL}/auth/login`, {
      email: registerData.personnal?.email,
      password: password,
    });
    if (response.data?.success) {
      navigate(paths.PROGRESSION);
    } else {
      navigate(paths.LOGIN);
    }
  };

  if (typeof isTokenValid != "undefined" && !isTokenValid)
    return (
      <FullScreenLayout isCheckAuthEnabled={false}>
        <AccessDenied />
      </FullScreenLayout>
    );
  if (typeof isTokenValid != "undefined" && isTokenValid)
    return (
      <FullScreenLayout isCheckAuthEnabled={false}>
        {/* Left */}
        <div className="w-2/5 max-w-md p-2 max-h-[850px]">
          <div className="flex flex-col min-h-full p-8 bg-subtle border-1 border-element-border/50 rounded-6px">
            <div className="flex-1 pb-4">
              <SuperhoteIcon />
            </div>
            {registerData.current_step === null ||
            registerData.current_step === RegisterPageStep.personnal ? (
              <Carousel
                items={[
                  {
                    Image: Carousel1Image,
                    title: t("Register.carousel1Title"),
                    subTitle: t("Register.carousel1SubTitle"),
                  },
                  {
                    Image: Carousel2Image,
                    title: t("Register.carousel2Title"),
                    subTitle: t("Register.carousel2SubTitle"),
                  },
                  {
                    Image: Carousel3Image,
                    title: t("Register.carousel3Title"),
                    subTitle: t("Register.carousel3SubTitle"),
                  },
                ]}
              />
            ) : (
              <SummaryBottom
                registerState={registerData!}
                currentStep={registerData.current_step!}
              />
            )}
          </div>
        </div>

        {/* Right */}
        {fetchLoading && (
          <div className="flex justify-center flex-1 px-12 py-8">
            <Loader />
          </div>
        )}

        {!fetchLoading && (
          <div className="flex-1 px-12 py-8">
            {needToDisplayBackButton() && (
              <div className="mb-12 w-fit">
                <Button
                  type="secondary"
                  LeftIcon={ArrowLeftIcon}
                  onClick={handleBack}
                >
                  {t("Global.previousStep")}
                </Button>
              </div>
            )}

            {(registerData.current_step === null ||
              registerData.current_step === RegisterPageStep.personnal) && (
              <RegisterPersonnal
                error={error}
                firstName={registerData?.personnal?.first_name}
                lastName={registerData?.personnal?.last_name!}
                email={registerData?.personnal?.email!}
                phone={registerData?.personnal?.phone}
                accept={false}
                loading={loading}
                onSubmit={handleSubmitPersonnal}
              />
            )}

            {registerData.current_step === RegisterPageStep.type && (
              <RegisterType
                firstName={registerData?.personnal?.first_name}
                investor={registerData?.properties.types.includes("investor")}
                sub={registerData?.properties.types.includes("sub")}
                janitorial={registerData?.properties.types.includes(
                  "janitorial"
                )}
                agency={registerData?.properties.types.includes("agency")}
                loading={loading}
                onChange={handleChangeTypes}
                onSubmit={handleSubmitTypes}
              />
            )}

            {registerData.current_step === RegisterPageStep.platform && (
              <RegisterPlatform
                platforms={registerData?.properties.platforms}
                loading={loading}
                onChange={handleChangePlatforms}
                onSubmit={handleSubmitPlatforms}
              />
            )}

            {registerData.current_step === RegisterPageStep.password && (
              <RegisterPassword
                loading={loading}
                error={error}
                onSubmit={handleSubmitPassword}
              />
            )}

            {registerData.current_step === RegisterPageStep.thanks && (
              <RegisterThanks
                firstName={registerData?.personnal?.first_name!}
                onConfigure={() => {}}
                onSubmit={handleLogin}
                loading={loading}
              />
            )}
          </div>
        )}
      </FullScreenLayout>
    );
};

const SummaryBottom: React.FC<{
  registerState: RegisterDataType;
  currentStep: number;
}> = ({ registerState, currentStep }) => {
  const { t } = useTranslation();

  /**
   * * maxStep constant explanation ⬇️
   * * The number of steps is obtained by counting the number of options using the RegisterPageStep type.
   * * We deliberately exclude "billing" and "personal" because they should not be counted in the registration steps.
   */
  const maxStep = Object.keys(RegisterPageStep).filter(
    (t) => isNaN(Number(t)) && t !== "personnal"
  ).length;

  const displayCurrentStep = () => {
    if (currentStep >= maxStep) return maxStep;
    return currentStep;
  };

  return (
    <div>
      <RegisterSummary
        firstName={registerState.personnal?.first_name!}
        lastName={registerState.personnal?.last_name!}
        types={registerState.properties.types}
        platforms={registerState.properties?.platforms}
      />
      <div className="mt-4">
        <p className="text-lg font-semibold text-low-contrast">
          {t("Register.step", { currentStep: displayCurrentStep(), maxStep })}
        </p>
        <p className="mt-2 text-lg font-bold">
          {t(`Register.${currentStep}Step`)}
        </p>
        <p className="mt-3 text-sm font-light text-low-contrast">
          {t(`Register.typeSubTitle${currentStep}`)}
        </p>
      </div>

      <StepProgress maxStep={maxStep} currentStep={currentStep} />
    </div>
  );
};
