import React from "react";
import DoubleMessagesIcon from "../../../assets/icons/double-messages.svg?react";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg?react";
import { Button } from "../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import { NoConvMessageListProps } from "./NoConvMessageList.type";

export const NoConvMessageList: React.FC<NoConvMessageListProps> = ({
  onNavigateBookings,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex bg-element-background w-full h-[85%] rounded-6px mt-2 items-center justify-center p-4">
      <div className="flex flex-col items-center">
        <DoubleMessagesIcon width={64} height={64} />

        <p className="mt-4 font-semibold text-high-contrast text-xxl">
          {t("MessageList.noConvTitle")}
        </p>

        <p className="font-ligth text-low-contrast text-center max-w-lg text-base mt-2">
          {t("MessageList.noConvSubTitle")}
        </p>

        <div className="mt-4 space-x-4">
          <Button
            type="secondary"
            RightIcon={ArrowRightIcon}
            onClick={onNavigateBookings}
          >
            {t("MessageList.navigateBookings")}
          </Button>
        </div>
      </div>
    </div>
  );
};
