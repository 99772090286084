/**
 * Main page of /platforms route
 *
 * @author Elysio
 */

import React, { useState } from "react";

import AirbnbLogo from "../../assets/logos/airbnb.svg?react";
import BookingLogo from "../../assets/logos/booking.svg?react";
import IgloohomeLogo from "../../assets/logos/igloohome.svg?react";
import MakeLogo from "../../assets/logos/make.svg?react";
import NukiLogo from "../../assets/logos/nuki.svg?react";
import PricelabsLogo from "../../assets/logos/pricelabs.svg?react";
import StripeLogo from "../../assets/logos/stripe.svg?react";
import TtlockLogo from "../../assets/logos/ttlock.svg?react";
import Slack from "../../assets/logos/slack.svg?react";
import MicrosoftTeam from "../../assets/logos/microsoft-teams.svg?react";
import Zoom from "../../assets/logos/zoom.svg?react";
import Skype from "../../assets/logos/skype.svg?react";
import Discord from "../../assets/logos/discord.svg?react";
import WhatsApp from "../../assets/logos/whatsapp.svg?react";
import Telegram from "../../assets/logos/telegram.svg?react";
import FacebookMessenger from "../../assets/logos/messenger.svg?react";
import Trello from "../../assets/logos/trello.svg?react";
import Asana from "../../assets/logos/asana.svg?react";
import Monday from "../../assets/logos/monday.svg?react";
import Jira from "../../assets/logos/jira.svg?react";
import Basecamp from "../../assets/logos/basecamp.svg?react";
import Clickup from "../../assets/logos/clickup.svg?react";
import Wrike from "../../assets/logos/wrike.svg?react";
import Notion from "../../assets/logos/notion.svg?react";
import Salesforce from "../../assets/logos/salesforce.svg?react";
import Hubspot from "../../assets/logos/hubspot.svg?react";
import ZohoCRM from "../../assets/logos/zoho.svg?react";
import Pipedrive from "../../assets/logos/pipedrive.svg?react";
import Freshsales from "../../assets/logos/freshsales.svg?react";
import AgileCRM from "../../assets/logos/agile.svg?react";
import Insightly from "../../assets/logos/insightly.svg?react";
import Sendinblue from "../../assets/logos/sendinblue.svg?react";
import ActiveCampaign from "../../assets/logos/activecampaign.svg?react";
import GetResponse from "../../assets/logos/getresponse.svg?react";
import ConvertKit from "../../assets/logos/convertkit.svg?react";
import AWeber from "../../assets/logos/aweber.svg?react";
import CampaignMonitor from "../../assets/logos/campaignmonitor.svg?react";
import Klaviyo from "../../assets/logos/klaviyo.svg?react";
import MailerLite from "../../assets/logos/mailerlite.svg?react";
import BenchmarkEmail from "../../assets/logos/benchmarkemail.svg?react";
import Shopify from "../../assets/logos/shopify.svg?react";
import Freshdesk from "../../assets/logos/freshdesk.svg?react";
import LiveChat from "../../assets/logos/livechat.svg?react";
import Crisp from "../../assets/logos/crisp.svg?react";
import Tawk from "../../assets/logos/tawk.svg?react";
import ZohoDesk from "../../assets/logos/zohodesk.svg?react";
import Agicap from "../../assets/logos/agicap.svg?react";
import Pennylane from "../../assets/logos/pennylane.svg?react";
import Wise from "../../assets/logos/wise.svg?react";
import Square from "../../assets/logos/square.svg?react";
import Wave from "../../assets/logos/wave.svg?react";
import Automate from "../../assets/logos/automate.svg?react";
import N8n from "../../assets/logos/n8n.svg?react";
import WooCommerce from "../../assets/logos/woocommerce.svg?react";
import Magento from "../../assets/logos/magento.svg?react";
import BigCommerce from "../../assets/logos/bigcommerce.svg?react";
import Prestashop from "../../assets/logos/prestashop.svg?react";
import SquarespaceCommerce from "../../assets/logos/squarespace.svg?react";
import Etsy from "../../assets/logos/etsy.svg?react";
import Zendesk from "../../assets/logos/zendesk.svg?react";
import Keap from "../../assets/logos/keap.svg?react";
import Mailchimp from "../../assets/logos/mailchimp.svg?react";
import Intercom from "../../assets/logos/intercom.svg?react";
import HelpScout from "../../assets/logos/helpscout.svg?react";
import Olark from "../../assets/logos/olark.svg?react";
import UserLike from "../../assets/logos/userlike.svg?react";
import QuickBooks from "../../assets/logos/quickbook.svg?react";
import Xero from "../../assets/logos/xero.svg?react";
import PayPal from "../../assets/logos/paypal.svg?react";
import RevolutBusiness from "../../assets/logos/revolut.svg?react";
import GoogleDrive from "../../assets/logos/google-drive.svg?react";
import Dropbox from "../../assets/logos/dropbox.svg?react";
import OneDrive from "../../assets/logos/onedrive.svg?react";
import Box from "../../assets/logos/box.svg?react";
import Zapier from "../../assets/logos/zapier.svg?react";
import Ifttt from "../../assets/logos/ifttt.svg?react";

import { PageDetailsInterface } from "./Platform.type";

import { AirbnbConnectModal } from "../../components/Modal/Airbnb/Connect/AirbnbConnectModal";
import { useModal } from "../../hooks/useModal";
import { BookingConnectModal } from "../../components/Modal/Booking/Connect/BookingConnectModal";
import { AddStripeAccountModal } from "../../components/Payment/AccountList/AddStripeAccountModal";
import { PricelabsConnectionModal } from "../../components/Rental/Services/Pricelabs/PricelabsConnectionModal";
import { useCheckAuth } from "../../hooks/useCheckAuth";
import { MainPageThirdParty } from "./MainPageThirdParty";
import { MainPageDetailsThirdParty } from "./MainPageDetailsThirdParty";
import { useTranslation } from "react-i18next";
import { AirbnbRentalsList } from "../../components/PlatForm/Lists/Airbnb/AirbnbRentalsList";
import { MakeModalConnect } from "../../components/PlatForm/Integrations/MakeModalConnect";

export const PlatFormPage = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<string>("default");
  const [pageData, setPageData] = useState<PageDetailsInterface | null>(null);
  const modal = useModal();
  const { user } = useCheckAuth(true);

  /**
   * Utils function to reduce const platforms data. (Banger LOL)
   *
   * @author Elysio
   * @param platform
   */
  const defaultSteps = (platform: string) => {
    const stepFour = t(`ThirdParty.${platform}.stepFour`);
    const stepFive = t(`ThirdParty.${platform}.stepFive`);

    return {
      stepOne: t(`ThirdParty.${platform}.stepOne`),
      stepTwo: t(`ThirdParty.${platform}.stepTwo`),
      stepThree: t(`ThirdParty.${platform}.stepThree`),
      stepFour:
        stepFour !== `ThirdParty.${platform}.stepFour` && stepFour.trim() !== ""
          ? stepFour
          : null,
      stepFive:
        stepFive !== `ThirdParty.${platform}.stepFive` && stepFive.trim() !== ""
          ? stepFive
          : null,
      titleTuto: t(`ThirdParty.${platform}.titleTuto`),
      howToConnect: t(`ThirdParty.${platform}.howToConnect`),
      videoUrl: t(`ThirdParty.${platform}.videoUrl`),
    };
  };

  const redirectToMake = () => {
    setPage("make");
    setPageData(getPageData("make"));
  };

  /**
   * All platforms availables.
   * Use this for add new platform :)
   *
   * @author Elysio
   */
  const platformsConfig = [
    {
      category: t("ThirdParty.platforms"),
      items: [
        {
          platform: "Airbnb",
          Icon: AirbnbLogo,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Booking",
          Icon: BookingLogo,
          ModalConnect: BookingConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
      ],
    },
    {
      category: t("ThirdParty.tarifs"),
      items: [
        {
          platform: "Stripe",
          Icon: StripeLogo,
          ModalConnect: AddStripeAccountModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
        },
        {
          platform: "Pricelabs",
          Icon: PricelabsLogo,
          ModalConnect: PricelabsConnectionModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
        },
      ],
    },
    {
      category: t("ThirdParty.locks"),
      items: [
        {
          platform: "Igloohome",
          Icon: IgloohomeLogo,
          ModalConnect: PricelabsConnectionModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
        },
        {
          platform: "Nuki",
          Icon: NukiLogo,
          ModalConnect: PricelabsConnectionModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
        },
        {
          platform: "TTlock",
          Icon: TtlockLogo,
          ModalConnect: PricelabsConnectionModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
        },
      ],
    },
    {
      category: t("ThirdParty.auto"),
      items: [
        {
          platform: "Make",
          Icon: MakeLogo,
          ModalConnect: MakeModalConnect,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
        },
      ],
    },
    {
      category: t("ThirdParty.money"),
      items: [
        {
          platform: "QuickBooks",
          Icon: QuickBooks,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Xero",
          Icon: Xero,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Agicap",
          Icon: Agicap,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Pennylane",
          Icon: Pennylane,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Paypal",
          Icon: PayPal,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Square",
          Icon: Square,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Wave",
          Icon: Wave,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "RevolutBusiness",
          Icon: RevolutBusiness,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Wise",
          Icon: Wise,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
      ],
    },
    {
      category: t("ThirdParty.communication"),
      items: [
        {
          platform: "Slack",
          Icon: Slack,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "MicrosoftTeams",
          Icon: MicrosoftTeam,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Zoom",
          Icon: Zoom,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Skype",
          Icon: Skype,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Discord",
          Icon: Discord,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "WhatsApp",
          Icon: WhatsApp,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Telegram",
          Icon: Telegram,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "FacebookMessenger",
          Icon: FacebookMessenger,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
      ],
    },
    {
      category: t("ThirdParty.manageProject"),
      items: [
        {
          platform: "Trello",
          Icon: Trello,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Asana",
          Icon: Asana,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Monday",
          Icon: Monday,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Jira",
          Icon: Jira,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Basecamp",
          Icon: Basecamp,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Clickup",
          Icon: Clickup,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Wrike",
          Icon: Wrike,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Notion",
          Icon: Notion,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
      ],
    },
    {
      category: t("ThirdParty.crm"),
      items: [
        {
          platform: "Salesforce",
          Icon: Salesforce,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Hubspot",
          Icon: Hubspot,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Keap",
          Icon: Keap,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Insightly",
          Icon: Insightly,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "ZohoCRM",
          Icon: ZohoCRM,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Pipedrive",
          Icon: Pipedrive,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Freshsales",
          Icon: Freshsales,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "AgileCRM",
          Icon: AgileCRM,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
      ],
    },
    {
      category: t("ThirdParty.emailAds"),
      items: [
        {
          platform: "Mailchimp",
          Icon: Mailchimp,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Sendinblue",
          Icon: Sendinblue,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
        },
        {
          platform: "Activecampaign",
          Icon: ActiveCampaign,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Getresponse",
          Icon: GetResponse,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Convertkit",
          Icon: ConvertKit,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Aweber",
          Icon: AWeber,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "CampaignMonitor",
          Icon: CampaignMonitor,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Klaviyo",
          Icon: Klaviyo,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "MailerLite",
          Icon: MailerLite,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "BenchmarkEmail",
          Icon: BenchmarkEmail,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
      ],
    },
    {
      category: t("ThirdParty.ecommerce"),
      items: [
        {
          platform: "Shopify",
          Icon: Shopify,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "WooCommerce",
          Icon: WooCommerce,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Magento",
          Icon: Magento,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "BigCommerce",
          Icon: BigCommerce,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "PrestaShop",
          Icon: Prestashop,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "SquarespaceCommerce",
          Icon: SquarespaceCommerce,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Etsy",
          Icon: Etsy,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
      ],
    },
    {
      category: t("ThirdParty.support"),
      items: [
        {
          platform: "Zendesk",
          Icon: Zendesk,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Freshdesk",
          Icon: Freshdesk,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Intercom",
          Icon: Intercom,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "HelpScout",
          Icon: HelpScout,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "LiveChat",
          Icon: LiveChat,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Olark",
          Icon: Olark,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Crisp",
          Icon: Crisp,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Tawk",
          Icon: Tawk,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Userlike",
          Icon: UserLike,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "ZohoDesk",
          Icon: ZohoDesk,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
      ],
    },
    {
      category: t("ThirdParty.storage"),
      items: [
        {
          platform: "GoogleDrive",
          Icon: GoogleDrive,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Dropbox",
          Icon: Dropbox,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "OneDrive",
          Icon: OneDrive,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Box",
          Icon: Box,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
      ],
    },
    {
      category: t("ThirdParty.auto"),
      items: [
        {
          platform: "Zapier",
          Icon: Zapier,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Ifttt",
          Icon: Ifttt,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "Automate",
          Icon: Automate,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
        {
          platform: "N8n",
          Icon: N8n,
          ModalConnect: AirbnbConnectModal,
          DataList: AirbnbRentalsList,
          checkConnect: () => false,
          isRedirectButton: true,
          onClickButton: redirectToMake,
        },
      ],
    },
  ];

  /**
   * Generate all platforms
   */
  const platforms = platformsConfig.map(({ category, items }) => ({
    category,
    items: items.map(
      ({
        platform,
        Icon,
        ModalConnect,
        DataList,
        checkConnect,
        isRedirectButton,
        onClickButton,
      }) => ({
        Icon,
        title: t(`ThirdParty.${platform}.title`),
        description: t(`ThirdParty.${platform}.description`),
        longDescription: t(`ThirdParty.${platform}.longDescription`),
        buttonName: t(`ThirdParty.${platform}.buttonConnect`),
        page: platform.toLowerCase(),
        ModalConnect,
        DataList,
        step: defaultSteps(platform),
        checkConnect,
        isRedirectButton: isRedirectButton,
        onClickButton: onClickButton,
      }),
    ),
  }));

  /**
   * Function to get page data to show to user.
   *
   * @author Elysio
   * @param page
   */
  const getPageData = (page: string): PageDetailsInterface | null => {
    return (
      platforms.flatMap((platform) =>
        platform.items.filter((item) => item.page === page),
      )[0] ?? null
    );
  };

  /**
   * Function for filter all platform when search state is updated.
   *
   * @author Elysio
   */
  const filteredPlatforms = platforms
    .map((template) => ({
      ...template,
      items: template.items.filter((platform) =>
        platform.title.toLowerCase().includes(search.toLowerCase()),
      ),
    }))
    .filter((template) => template.items.length > 0);

  /**
   * Function to setSearch value and filteredPlatforms() is executed.
   *
   * @author Elysio
   * @param search
   */
  const handleSearchAction = (search: string) => {
    setSearch(search);
  };

  /**
   * Function to handle click with platform and get data page to show tuto.
   *
   * @author Elysio
   * @param page
   */
  const handleClickItem = (page: string) => {
    setPage(page);
    setPageData(getPageData(page));
  };

  return (
    <>
      {page !== "default" ? (
        <MainPageDetailsThirdParty
          pageData={pageData}
          setPage={setPage}
          setPageData={setPageData}
          modal={modal}
          page={page}
          isConnected={pageData?.checkConnect()}
        />
      ) : (
        <MainPageThirdParty
          handleSearchAction={handleSearchAction}
          handleClickItem={handleClickItem}
          filteredPlatforms={filteredPlatforms}
        />
      )}
    </>
  );
};
