import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import CheckWhiteIcon from "../../../../assets/icons/check-white.svg?react";
import InformationActiveIcon from "../../../../assets/icons/information-active.svg?react";
import ArrowTopRightIcon from "../../../../assets/icons/arrow-up-right.svg?react";
import { usePricelabs } from "../../../../hooks/api/pricelabs";
import { ValueType } from "../../../../types/commonTypes";
import { Button } from "../../../Common/Button/Button";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { RightModal } from "../../../Common/RightModal/RightModal";
import { Switch } from "../../../Common/Switch/Switch";
import { TextInput } from "../../../Common/TextInput/TextInput";
import {
  PricelabsData,
  PricelabsSettingsForm,
  RentalPricelabsResponse,
} from "./Pricelabs.type";
import { PricelabsDisconnectionConfirmationModal } from "./PricelabsDisconnectionConfirmationModal";
import { Badge } from "../../../Common/Badge/Badge";

export const PricelabsSettingsModal: React.FC<{
  rentalId: ValueType;
  pricelabsData: PricelabsData | undefined;
  isVisible: boolean;
  onOpenPricelabsWebsite: () => void;
  isCompletelySynchronized: () => boolean;
  onSuccessDisconnect: () => void;
  onSuccessUpdate: (pricelabsResponse: RentalPricelabsResponse) => void;
  onClose: () => void;
}> = ({
  rentalId,
  pricelabsData,
  isVisible,
  onOpenPricelabsWebsite,
  isCompletelySynchronized,
  onSuccessDisconnect,
  onSuccessUpdate,
  onClose,
}) => {
  const [pricelabsSyncEnabled, setPricelabsSyncEnabled] =
    useState<boolean>(false);
  const [disconnectionModalOpened, setDisconnectionModalOpened] =
    useState(false);

  const form = useForm<PricelabsSettingsForm>({
    defaultValues: {
      email: "",
      api_key: "",
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (pricelabsData) {
      setPricelabsSyncEnabled(pricelabsData.enabled);
      form.setValue("email", pricelabsData.email);
      form.setValue("api_key", pricelabsData.api_key);
    }
  }, [pricelabsData]);

  const handleEnablePricelabsSynchronization = () => {
    setPricelabsSyncEnabled(!pricelabsSyncEnabled);
  };

  const { update } = usePricelabs({
    rentalId: rentalId,
    onSuccess: (pricelabsResponse) => {
      onSuccessUpdate(pricelabsResponse!);
      handleClose();
    },
    onError: (message: string | null) => {
      setError(message);
    },
    onStart: () => {
      setLoading(true);
      setError(null);
    },
    onEnd: () => {
      setLoading(false);
    },
  });

  const handleClose = () => {
    if (loading) return;
    form.reset();
    setError(null);
    onClose();
  };

  const handleOpenDisconnectionModal = () => {
    handleClose();
    setDisconnectionModalOpened(true);
  };

  const handleCloseDisconnectionModal = () => {
    setDisconnectionModalOpened(false);
  };

  const handleValid = (values: PricelabsSettingsForm) => {
    update(values, pricelabsSyncEnabled);
  };

  return (
    <>
      <PricelabsDisconnectionConfirmationModal
        rentalId={rentalId!}
        isVisible={disconnectionModalOpened}
        onClose={handleCloseDisconnectionModal}
        onSuccessDisconnect={onSuccessDisconnect}
      />
      <RightModal
        title={t("Rental.Services.Pricelabs.SettingsModal.title")}
        isVisible={isVisible}
        onClose={onClose}
        classNames={{
          mainContentParent: "overflow-y-auto",
        }}
        rightHeaderNode={
          <Badge
            label={
              isCompletelySynchronized()
                ? t("Global.connected")
                : t("Rental.Services.Pricelabs.pendingBadgeLabel")
            }
            status={isCompletelySynchronized() ? "success" : "pending"}
          ></Badge>
        }
      >
        <form
          onSubmit={form.handleSubmit(handleValid)}
          className="flex-1 w-full h-full"
        >
          <div className="flex flex-col justify-between h-full">
            <div className="flex flex-col flex-1 w-full gap-3 pb-4 overflow-y-auto">
              {!isCompletelySynchronized() && (
                <div className="flex flex-row w-full gap-2 p-2 mb-4 bg-element-background rounded-4px h-fit">
                  <div className="">
                    <InformationActiveIcon className="w-5 h-5" />
                  </div>

                  <div className="flex-1">
                    <p className="text-low-contrast">
                      <Trans
                        i18nKey="Rental.Services.Pricelabs.SettingsModal.syncInfo1"
                        components={{
                          strong: <strong />,
                        }}
                      />
                    </p>
                    <p className="mt-3 text-low-contrast">
                      <Trans
                        i18nKey="Rental.Services.Pricelabs.SettingsModal.syncInfo2"
                        components={{
                          strong: <strong />,
                        }}
                      />
                    </p>
                    <p className="text-low-contrast">
                      {t(
                        "Rental.Services.Pricelabs.SettingsModal.syncInfoStep1"
                      )}
                    </p>
                    <p className="text-low-contrast">
                      {t(
                        "Rental.Services.Pricelabs.SettingsModal.syncInfoStep2"
                      )}
                    </p>
                    <p className="text-low-contrast">
                      {t(
                        "Rental.Services.Pricelabs.SettingsModal.syncInfoStep3"
                      )}
                    </p>
                    <p className="text-low-contrast mb-3">
                      {t(
                        "Rental.Services.Pricelabs.SettingsModal.syncInfoStep4"
                      )}
                    </p>
                    <div className="flex flex-col flex-1 w-full">
                      <Button
                        type="secondary"
                        size="small"
                        RightIcon={ArrowTopRightIcon}
                        onClick={onOpenPricelabsWebsite}
                      >
                        {t("Rental.Services.Pricelabs.pricelabsButtonLabel")}
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex flex-row items-center justify-between p-3 space-x-3 bg-gray-100 rounded-lg">
                <p className="font-semibold ">
                  {pricelabsSyncEnabled
                    ? t(
                        "Rental.Services.Pricelabs.SettingsModal.syncSwitchEnabledLabel"
                      )
                    : t(
                        "Rental.Services.Pricelabs.SettingsModal.syncSwitchDisabledLabel"
                      )}
                </p>
                <div className="flex flex-col gap-1">
                  <Switch
                    value={pricelabsSyncEnabled}
                    onClick={() => handleEnablePricelabsSynchronization()}
                  />
                </div>
              </div>

              <div>
                <Controller
                  control={form.control}
                  name="email"
                  rules={{
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: t(
                        "Rental.Services.Pricelabs.ConnectionModal.emailInvalid"
                      ),
                    },
                    required: {
                      value: true,
                      message: t(
                        "Rental.Services.Pricelabs.ConnectionModal.emailRequired"
                      ),
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      label={t(
                        "Rental.Services.Pricelabs.SettingsModal.emailLabel"
                      )}
                      required={true}
                      placeholder={t(
                        "Rental.Services.Pricelabs.ConnectionModal.emailPlaceholder"
                      )}
                      disabled={true}
                      value={value}
                      error={form.formState.errors.email?.message}
                      onChangeText={onChange}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  control={form.control}
                  name="api_key"
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      label={t(
                        "Rental.Services.Pricelabs.SettingsModal.apiKeyLabel"
                      )}
                      required={true}
                      placeholder={t(
                        "Rental.Services.Pricelabs.SettingsModal.apiKeyPlaceholder"
                      )}
                      disabled={false}
                      value={value}
                      error={form.formState.errors.api_key?.message}
                      onChangeText={onChange}
                    />
                  )}
                />
              </div>

              <div className="flex flex-row w-full gap-2 p-2 mb-4 bg-element-background rounded-4px h-fit">
                <div className="">
                  <InformationActiveIcon className="w-5 h-5" />
                </div>

                <div className="flex-1">
                  <p className="text-low-contrast">
                    <Trans
                      i18nKey="Rental.Services.Pricelabs.SettingsModal.info1"
                      components={{
                        strong: <strong />,
                      }}
                    />
                  </p>
                  <p className="mt-3 text-low-contrast">
                    <Trans
                      i18nKey="Rental.Services.Pricelabs.SettingsModal.info2"
                      components={{
                        strong: <strong />,
                      }}
                    />
                  </p>
                  <p className="mt-3 text-low-contrast">
                    {t("Rental.Services.Pricelabs.SettingsModal.info3")}
                  </p>
                </div>
              </div>

              <Link
                to={
                  "https://help.pricelabs.co/portal/fr/kb/articles/customer-api"
                }
                className="text-active"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Rental.Services.Pricelabs.SettingsModal.tutorialLink")}
              </Link>
            </div>

            <div className="flex flex-col pt-4 gap-y-3 border-t-1 border-element-border">
              <ErrorMessage>{error}</ErrorMessage>
              <div className="flex gap-x-3">
                <Button
                  type="secondary"
                  disabled={loading}
                  onClick={handleClose}
                >
                  {t("Global.cancel")}
                </Button>

                <Button
                  type="destructive"
                  onClick={handleOpenDisconnectionModal}
                >
                  {t("Global.disconnect")}
                </Button>

                <Button RightIcon={CheckWhiteIcon} loading={loading}>
                  {t("Global.record")}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </RightModal>
    </>
  );
};
