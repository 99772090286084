import React from "react";
import { useTranslation } from "react-i18next";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import CloseIcon from "../../../assets/icons/close.svg?react";
import paths from "../../../constants/paths";
import { post } from "../../../helpers/APIHelper";
import { Button } from "../../Common/Button/Button";
import { CenterModal } from "../../Common/CenterModal/CenterModal";
type DisableRentalModalForRentalDoneProps = {
  isVisible: boolean;
  rentalId: ValueType | undefined;
  onClose: () => void;
  onDisable: () => void;
};
export const DisableRentalModalForRentalDone: React.FC<
  DisableRentalModalForRentalDoneProps
> = ({ isVisible, rentalId, onClose, onDisable }) => {
  const { t } = useTranslation();
  const disableRental = async () => {
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.RENTAL_DISABLE
      }`
    );
    if (res.data.success) {
      onDisable();
    } else {
    }
  };
  const handleClick = () => {
    disableRental();
  };
  return (
    <CenterModal isVisible={isVisible} onClose={onClose}>
      <div className="flex flex-col gap-5">
        <div className="flex items-start justify-between">
          <p className="text-xl font-extrabold">
            {t("Rental.Infos.Infos.removeActiveRentalModalTitle")}
          </p>
          <div className="cursor-pointer">
            <CloseIcon width={20} height={20} onClick={onClose} />
          </div>
        </div>
        <p className="text-base text-low-contrast">
          {t("Rental.Infos.Infos.removeActiveRentalModalDescription1")}
        </p>
        <p className="text-base text-low-contrast">
          {t("Rental.Infos.Infos.removeActiveRentalModalDescription3")}
        </p>
        <div className="flex gap-3">
          <div className="flex-1">
            <Button type="secondary" onClick={onClose}>
              {t("Global.cancel")}
            </Button>
          </div>
          <div className="flex-1">
            <Button type="destructive" onClick={handleClick}>
              {t("Global.deactivateWithConfirmation")}
            </Button>
          </div>
        </div>
      </div>
    </CenterModal>
  );
};
