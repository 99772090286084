import { t } from "i18next";
import React, { useState } from "react";
import CloseIcon from "../../../../assets/icons/close.svg?react";
import { usePricelabs } from "../../../../hooks/api/pricelabs";
import { ValueType } from "../../../../types/commonTypes";
import { Button } from "../../../Common/Button/Button";
import { CenterModal } from "../../../Common/CenterModal/CenterModal";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";

export const PricelabsDisconnectionConfirmationModal: React.FC<{
  rentalId: ValueType;
  isVisible: boolean;
  onClose: () => void;
  onSuccessDisconnect: () => void;
}> = ({ rentalId, isVisible, onClose, onSuccessDisconnect }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleClose = () => {
    if (loading) return;
    onClose();
  };

  const handleSuccess = () => {
    disconnect();
  };

  const { disconnect } = usePricelabs({
    rentalId: rentalId,
    onSuccess: () => {
      onSuccessDisconnect();
      handleClose();
    },
    onError: (message: string | null) => {
      setError(message);
    },
    onStart: () => {
      setLoading(true);
      setError(null);
    },
    onEnd: () => {
      setLoading(false);
    },
  });

  return (
    <CenterModal isVisible={isVisible} onClose={loading ? () => {} : onClose}>
      <div className="flex flex-col gap-5">
        <div className="flex items-start justify-between">
          <p className="text-xl font-extrabold">
            {t("Rental.Services.Pricelabs.DisconnectionModal.title")}
          </p>
          <div className="cursor-pointer">
            <CloseIcon width={20} height={20} onClick={onClose} />
          </div>
        </div>
        <p className="text-base text-low-contrast">
          {t("Rental.Services.Pricelabs.DisconnectionModal.info1")}
        </p>
        <p className="text-base text-low-contrast">
          {t("Rental.Services.Pricelabs.DisconnectionModal.info2")}
        </p>

        <ErrorMessage>{error}</ErrorMessage>

        <div className="flex gap-3">
          <div className="flex-1">
            <Button type="secondary" onClick={handleClose} disabled={loading}>
              {t("Global.cancel")}
            </Button>
          </div>
          <div className="flex-1">
            <Button
              type="destructive"
              onClick={() => handleSuccess()}
              loading={loading}
            >
              {t("Global.disconnect")}
            </Button>
          </div>
        </div>
      </div>
    </CenterModal>
  );
};
