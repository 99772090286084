import { TFunction } from "i18next";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useForm, UseFormReturn, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import CopyIcon from "../../../../assets/icons/copy.svg?react";
import IgloohomeRenderIcon from "../../../../assets/icons/igloohome-render.svg?react";
import IgloohomeRoundedIcon from "../../../../assets/icons/igloohome-rounded.svg?react";
import InformationIcon from "../../../../assets/icons/information.svg?react";
import KeyHoleIcon from "../../../../assets/icons/keyhole.svg?react";
import NukiRoundedIcon from "../../../../assets/icons/nuki-rounded.svg?react";
import Ttlock2Icon from "../../../../assets/icons/ttlock-2.svg?react";
import { SmartLocksEnum } from "../../../../constants/locks";
import { cn } from "../../../../helpers/classHelper";
import {
  capitalizeFirstLetter,
  capitalizeLetters,
} from "../../../../helpers/stringHelper";
import {
  IgloohomeAuthenticationPayload,
  LockAuthenticationPayload,
  NukiAuthenticationPayload,
  useIgloohomeAuthentication,
  useLinkedLocks,
  useLockAccounts,
  useLockAuthentication,
  useLockLink,
  useLocks,
  useLockUnlink,
  useNukiAuthentication,
  useStoreStaticLock,
} from "../../../../hooks/api/locks";
import { useUrlSearchParams } from "../../../../hooks/useUrlSearchParams";
import { Rental } from "../../../../pages/RentalPage/RentalPage.type";
import { IconType } from "../../../../types/commonTypes";
import {
  SmartlocksAccountItemResponse,
  SmartlocksIdListItemResponse,
  SmartlocksLinkedItemResponse,
  SmartlocksUnlinkResponse,
  SmartlocksUpdateResponse,
} from "../../../../types/GETTypes";
import { InputSelectOptionProps } from "../../../Common/InputSelect/InputSelect.type";
import {
  RentalSmartLocksProvider,
  SmartlocksAddStaticPayload,
  SmartlocksInformationsForm,
  SmartlocksInformationsManualForm,
  SmartlocksLinkPayload,
  SmartlocksLoginIgloohomeForm,
  SmartlocksLoginManualForm,
  SmartlocksLoginNukiForm,
  SmartlocksLoginStepForm,
  SmartlocksLoginTtlockForm,
} from "./Smartlocks.type";

type ConnectionStatus = "loading" | "success" | "error" | null;

interface SmartLocksContextType {
  t: TFunction<"translation", undefined>;
  navigate: NavigateFunction;
  loadingState: [boolean, Dispatch<SetStateAction<boolean>>];
  validationLoading: boolean;
  apiError: string | undefined;
  timeList: InputSelectOptionProps[];
  getLockProviderIcon: (providerName: string) => IconType;
  getLockFormattedProviderName: (providerName: string) => string | undefined;
  LockItem: React.FC<{
    mode?: "listing" | "update";
    lock: SmartlocksLinkedItemResponse;
    rental: Rental | undefined;
  }>;
  updateLockDynamicField: (input: string | undefined) => void;
  LockDynamicFieldInfo: React.FC<{ type: "create" | "created" }>;
  currentCarouselWizardIndex: number;
  connectionStatus: ConnectionStatus;
  attemptConnection: () => void;
  resetConnection: () => void;
  changeStep: (step: number) => void;
  locks: SmartlocksLinkedItemResponse[] | undefined;
  loginForm: UseFormReturn<SmartlocksLoginStepForm>;
  igloohomeLoginForm: UseFormReturn<SmartlocksLoginIgloohomeForm>;
  nukiLoginForm: UseFormReturn<SmartlocksLoginNukiForm>;
  ttlockLoginForm: UseFormReturn<SmartlocksLoginTtlockForm>;
  staticLoginForm: UseFormReturn<SmartlocksLoginManualForm>;
  informationsForm: UseFormReturn<SmartlocksInformationsForm>;
  informationsManualForm: UseFormReturn<SmartlocksInformationsManualForm>;
  igloohomeLockAccounts: SmartlocksAccountItemResponse[] | undefined;
  nukiLockAccounts: SmartlocksAccountItemResponse[] | undefined;
  ttlockLockAccounts: SmartlocksAccountItemResponse[] | undefined;
  lockIdItems: InputSelectOptionProps[] | undefined;
  currentLock: SmartlocksLinkedItemResponse | undefined;
  onNextStep: ({
    which,
  }: {
    which: "informations" | "automated_messages";
  }) => void;
  isAddModalVisible: boolean;
  onOpenAddModal: () => void;
  onCloseAddModal: () => void;
  isUpdateModalVisible: boolean;
  onOpenUpdateModal: (lock: SmartlocksLinkedItemResponse) => void;
  onCloseUpdateModal: () => void;
  onUpdateLock: () => {
    onStart: () => void;
    onSuccess: (smartlocksUpdateResponse: SmartlocksUpdateResponse) => void;
    onError: (message: string | undefined) => void;
    onEnd: () => void;
  };
  onUnlinkLock: () => void;
  deleteValidationLoading: boolean;
  isDeleteModalVisible: boolean;
  onOpenDeleteModal: () => void;
  onCloseDeleteModal: () => void;
}

const RentalSmartlocksContext = createContext<
  SmartLocksContextType | undefined
>(undefined);

export const useSmartlocksContext = () => {
  const context = useContext(RentalSmartlocksContext);
  if (!context) {
    throw new Error(
      "useSmartlocksContext doit être utilisé dans SmartlocksProvider"
    );
  }
  return context;
};

export const SmartlocksProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { rentalId } = useParams();

  // * Smart locks utils functions
  const getLockProviderIcon = (providerName: string): IconType => {
    switch (providerName.toUpperCase()) {
      case SmartLocksEnum.IGLOOHOMEV2 || SmartLocksEnum.IGLOOHOME:
        return IgloohomeRoundedIcon;
      case SmartLocksEnum.NUKI:
        return NukiRoundedIcon;
      case SmartLocksEnum.TTLOCK:
        return Ttlock2Icon;
      default:
        return IgloohomeRoundedIcon;
    }
  };

  const getLockFormattedProviderName = (providerName: string) => {
    switch (providerName.toUpperCase()) {
      case SmartLocksEnum.IGLOOHOMEV2 || SmartLocksEnum.IGLOOHOME:
        return capitalizeFirstLetter(SmartLocksEnum.IGLOOHOME.toLowerCase());
      case SmartLocksEnum.NUKI:
        return capitalizeFirstLetter(SmartLocksEnum.NUKI.toLowerCase());
      case SmartLocksEnum.TTLOCK:
        return capitalizeLetters(SmartLocksEnum.TTLOCK.toLowerCase(), [0, 1]);
      default:
        return capitalizeFirstLetter(SmartLocksEnum.IGLOOHOMEV2.toLowerCase());
    }
  };

  // * Smart locks common part
  const [loading, setLoading] = useState<boolean>(false);
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string | undefined>();

  const RentalLockItem: React.FC<{
    mode?: "listing" | "update";
    lock: SmartlocksLinkedItemResponse;
    rental: Rental | undefined;
  }> = ({ mode = "listing", lock, rental }) => {
    const device = rental?.services.locks.devices.find(
      (device) => device.id === lock.id
    );
    const manualCode = rental?.services.locks.manual_codes.find(
      (code) => code.id === lock.id
    );

    const getLockIcon = () => {
      if (lock.provider === "static") {
        return <KeyHoleIcon className="size-7" />;
      } else if (
        lock.provider === SmartLocksEnum.IGLOOHOME.toLowerCase() ||
        lock.provider === SmartLocksEnum.IGLOOHOMEV2.toLowerCase()
      ) {
        return <IgloohomeRenderIcon className="size-7" />;
      } else if (lock.provider === SmartLocksEnum.NUKI.toLowerCase()) {
        return <NukiRoundedIcon className="size-7" />;
      } else if (lock.provider === SmartLocksEnum.TTLOCK.toLowerCase()) {
        return <Ttlock2Icon className="size-7" />;
      }
    };

    const getLockType = () => {
      let lockType = t("Booking.Locks.staticLockType");

      if (
        lock.provider === SmartLocksEnum.IGLOOHOME.toLowerCase() ||
        lock.provider === SmartLocksEnum.IGLOOHOMEV2.toLowerCase()
      ) {
        lockType = t("Booking.Locks.lockType", {
          name: capitalizeFirstLetter(SmartLocksEnum.IGLOOHOME.toLowerCase()),
        });
      } else if (lock.provider === SmartLocksEnum.NUKI.toLowerCase()) {
        lockType = t("Booking.Locks.lockType", {
          name: capitalizeFirstLetter(SmartLocksEnum.NUKI.toLowerCase()),
        });
      } else if (lock.provider === SmartLocksEnum.TTLOCK.toLowerCase()) {
        lockType = t("Booking.Locks.lockType", {
          name: capitalizeFirstLetter(SmartLocksEnum.TTLOCK.toLowerCase()),
        });
      }

      return lockType;
    };

    return (
      <div
        className={cn(
          `flex items-center justify-between w-full p-2 rounded bg-subtle border-1 border-element-border`,
          {
            "cursor-pointer": mode === "listing",
          }
        )}
        onClick={mode === "update" ? undefined : () => onOpenUpdateModal(lock)}
      >
        <div className="flex items-center space-x-4">
          {getLockIcon()}
          <div className="flex flex-col space-y-1">
            <p className="text-base font-semibold text-nowrap text-high-contrast">
              {getLockType()}
            </p>
            {lock.provider !== "static" ? (
              <p className="text-base break-all whitespace-normal text-low-contrast">
                {lock.device_id}
              </p>
            ) : null}
          </div>
        </div>

        {lock.provider === "static" && manualCode !== undefined && (
          <div className="flex items-center space-x-4">
            <p className="text-base font-light truncate select-none max-w-96 text-low-contrast">
              {manualCode.name}
            </p>
            <p className="p-1 font-bold bg-white rounded select-none text-nowrap border-1 border-element-border text-low-contrast">
              {t("Rental.Services.Smartlocks.fixedCode")}
            </p>
            <p className="text-xl font-semibold text-active text-nowrap">
              {manualCode.code}
            </p>
          </div>
        )}

        {lock.provider !== "static" && device !== undefined && (
          <>
            {device.static_code && (
              <div className="flex items-center space-x-4">
                <p className="text-base font-light truncate select-none max-w-96 text-low-contrast">
                  {device.name}
                </p>
                <p className="p-1 font-bold bg-white rounded select-none text-nowrap border-1 border-element-border text-low-contrast">
                  {t("Rental.Services.Smartlocks.fixedCode")}
                </p>
                <p className="text-xl font-semibold text-active text-nowrap">
                  {device.static_code}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const [lockDynamicField, setLockDynamicField] = useState<
    string | undefined
  >();

  const updateLockDynamicField = (input: string | undefined) => {
    if (input && input.trim().length > 0) {
      setLockDynamicField(`
        [RESERVATION_ALL_DOOR_CODE]`);
    } else {
      setLockDynamicField(undefined);
    }
  };

  const LockDynamicFieldInfo: React.FC<{
    type: "create" | "created";
  }> = ({ type = "create" }) => {
    return lockDynamicField ? (
      <>
        {type === "create" ? (
          <div className="p-4 rounded bg-subtle">
            <div className="flex items-start space-x-4">
              <InformationIcon width={25} height={25} />
              <div className="flex flex-col flex-1 space-y-1.5">
                <p className="text-base font-light leading-normal tracking-wide select-none text-low-contrast">
                  {t(
                    "Rental.Services.Smartlocks.UpdateModal.dynamicFieldCreateLabel"
                  )}
                </p>
                <p className="text-base font-bold break-all whitespace-normal text-low-contrast">
                  {lockDynamicField}
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {type === "created" ? (
          <div className="p-4 rounded bg-subtle">
            <div className="flex items-end justify-between">
              <div className="flex flex-col flex-1 space-y-1.5">
                <p className="text-base font-light leading-normal tracking-wide select-none text-low-contrast">
                  {t(
                    "Rental.Services.Smartlocks.UpdateModal.dynamicFieldCreatedLabel"
                  )}
                </p>
                <p className="text-base font-bold break-all whitespace-normal text-low-contrast">
                  {lockDynamicField}
                </p>
              </div>

              <CopyIcon
                className="cursor-pointer ps-1"
                width={25}
                height={25}
                onClick={() => navigator.clipboard.writeText(lockDynamicField)}
              />
            </div>
          </div>
        ) : null}
      </>
    ) : null;
  };

  const isValidSmartLockProvider = (
    provider: string
  ): provider is Lowercase<RentalSmartLocksProvider> => {
    const validProviders: (Lowercase<RentalSmartLocksProvider> | "static")[] = [
      "igloohome",
      "igloohome-v2",
      "nuki",
      "ttlock",
      "static",
    ];
    return validProviders.includes(
      provider.toLowerCase() as Lowercase<RentalSmartLocksProvider>
    );
  };

  const timeList: InputSelectOptionProps[] = (() => {
    const timeList: InputSelectOptionProps[] = [];
    const totalMinutesInDay: number = 24 * 60;
    const interval: number = 60;

    for (let i: number = 0; i < totalMinutesInDay; i += interval) {
      const hours: number = Math.floor(i / 60);
      const minutes: number = i % 60;

      const formattedHours: string = hours < 10 ? `0${hours}` : `${hours}`;
      const formattedMinutes: string =
        minutes < 10 ? `0${minutes}` : `${minutes}`;

      const time: string = `${formattedHours}:${formattedMinutes}`;

      timeList.push({
        value: time,
        label: time,
      });
    }

    return timeList;
  })();

  // * Smart locks listing
  const [locks, setLocks] = useState<
    SmartlocksLinkedItemResponse[] | undefined
  >();

  useEffect(() => {
    useLinkedLocks(
      rentalId,
      (smartlocksLinkedItem: SmartlocksLinkedItemResponse[]) =>
        setLocks(smartlocksLinkedItem),
      (message: string | undefined) => setApiError(message)
    );
  }, [rentalId]);

  // * Smart locks add modal
  const [currentCarouselWizardIndex, setCurrentCarouselWizardIndex] =
    useState<number>(0);
  const maxIndex = 2;
  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);

  const onOpenAddModal = () => {
    if (currentCarouselWizardIndex === maxIndex) {
      setCurrentCarouselWizardIndex(0);
    }

    setIsAddModalVisible(true);
  };

  const onCloseAddModal = () => setIsAddModalVisible(false);

  // * Smart locks login step
  const [connectionStatus, setConnectionStatus] =
    useState<ConnectionStatus>(null);

  const [lockAuthenticationId, setLockAuthenticationId] = useState<
    number | undefined
  >();

  const urlParams = useUrlSearchParams<"lock_auth_id">();

  const [lockAccounts, setLockAccounts] = useState<
    SmartlocksAccountItemResponse[] | undefined
  >();

  const [igloohomeLockAccounts, setIgloohomeLockAccounts] = useState<
    SmartlocksAccountItemResponse[] | undefined
  >();

  const [nukiLockAccounts, setNukiLockAccounts] = useState<
    SmartlocksAccountItemResponse[] | undefined
  >();

  const [ttlockLockAccounts, setTtlockLockAccounts] = useState<
    SmartlocksAccountItemResponse[] | undefined
  >();

  const [lockIdItems, setLockIdItems] = useState<
    InputSelectOptionProps[] | undefined
  >();

  const loginForm = useForm<SmartlocksLoginStepForm>({
    mode: "all",
    defaultValues: {
      lockProvider: SmartLocksEnum.IGLOOHOMEV2,
    },
  });

  const igloohomeLoginForm = useForm<SmartlocksLoginIgloohomeForm>({
    mode: "all",
    defaultValues: {
      lockAccount: undefined,
    },
  });

  const nukiLoginForm = useForm<SmartlocksLoginNukiForm>({
    mode: "all",
    defaultValues: {
      lockAccount: undefined,
    },
  });

  const ttlockLoginForm = useForm<SmartlocksLoginTtlockForm>({
    mode: "all",
    defaultValues: {
      lockAccount: undefined,
      email: undefined,
      password: undefined,
    },
  });

  const staticLoginForm = useForm<SmartlocksLoginManualForm>({
    mode: "all",
    defaultValues: {
      name: undefined,
    },
  });

  useEffect(() => {
    if (
      loginForm.getValues("lockProvider") !== "static" &&
      currentCarouselWizardIndex === 0 &&
      !lockAccounts
    ) {
      useLockAccounts(
        (smartlocksAccounts) => {
          setLockAccounts(smartlocksAccounts);
          setIgloohomeLockAccounts(
            smartlocksAccounts.filter(
              (account) =>
                account.provider === SmartLocksEnum.IGLOOHOME.toLowerCase() ||
                account.provider === SmartLocksEnum.IGLOOHOMEV2.toLowerCase()
            )
          );
          setNukiLockAccounts(
            smartlocksAccounts.filter(
              (account) =>
                account.provider === SmartLocksEnum.NUKI.toLowerCase()
            )
          );
          setTtlockLockAccounts(
            smartlocksAccounts.filter(
              (account) =>
                account.provider === SmartLocksEnum.TTLOCK.toLowerCase()
            )
          );
        },
        (message: string | undefined) => setApiError(message),
        () => {
          setApiError(undefined);
          setLoading(true);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [loginForm.getValues("lockProvider"), currentCarouselWizardIndex]);

  useEffect(() => {
    if (igloohomeLockAccounts && igloohomeLockAccounts.length > 0) {
      igloohomeLoginForm.setValue("lockAccount", igloohomeLockAccounts[0].id);
    }
  }, [igloohomeLockAccounts]);

  useEffect(() => {
    if (nukiLockAccounts && nukiLockAccounts.length > 0) {
      console.log("set value nuki", nukiLockAccounts[0].id);
      nukiLoginForm.setValue("lockAccount", nukiLockAccounts[0].id);
    }
  }, [nukiLockAccounts]);

  useEffect(() => {
    if (ttlockLockAccounts && ttlockLockAccounts.length > 0) {
      nukiLoginForm.setValue("lockAccount", ttlockLockAccounts[0].id);
    }
  }, [ttlockLockAccounts]);

  useEffect(() => {
    if (loginForm.getValues("lockProvider") === SmartLocksEnum.IGLOOHOMEV2) {
      setLockAuthenticationId(igloohomeLoginForm.getValues("lockAccount"));
    } else if (loginForm.getValues("lockProvider") === SmartLocksEnum.NUKI) {
      setLockAuthenticationId(nukiLoginForm.getValues("lockAccount"));
    } else if (loginForm.getValues("lockProvider") === SmartLocksEnum.TTLOCK) {
      setLockAuthenticationId(ttlockLoginForm.getValues("lockAccount"));
    }
  }, [
    loginForm.getValues("lockProvider"),
    useWatch({ control: igloohomeLoginForm.control }).lockAccount,
    useWatch({ control: nukiLoginForm.control }).lockAccount,
    useWatch({ control: ttlockLoginForm.control }).lockAccount,
  ]);

  useEffect(() => {
    // * We defined the lock provider from lock_auth_id url params
    if (
      lockAccounts &&
      lockAccounts.length > 0 &&
      urlParams.has("lock_auth_id") &&
      !isNaN(Number(urlParams.get("lock_auth_id")))
    ) {
      const provider = lockAccounts.find(
        (lock) => lock.id === Number(urlParams.get("lock_auth_id"))
      )?.provider as RentalSmartLocksProvider;

      setLockAuthenticationId(Number(urlParams.get("lock_auth_id")));
      setIsAddModalVisible(true);

      if (provider) {
        loginForm.setValue("lockProvider", provider);
        setConnectionStatus("success");
      } else {
        setConnectionStatus("error");
      }
    }
  }, [lockAccounts, urlParams.get("lock_auth_id")]);

  const attemptConnection = () => {
    console.log("attempt connection : ", loginForm.getValues("lockProvider"));
    if (loginForm.getValues("lockProvider") === "static") {
      setConnectionStatus("success");
    } else {
      if (isValidSmartLockProvider(loginForm.getValues("lockProvider"))) {
        switch (loginForm.getValues("lockProvider").toUpperCase()) {
          case SmartLocksEnum.IGLOOHOMEV2 || SmartLocksEnum.IGLOOHOME:
            const igloohomePayload: IgloohomeAuthenticationPayload = {
              rental_id: rentalId!,
            };

            useIgloohomeAuthentication(
              igloohomePayload,
              (url: string) => {
                window.location.href = url;
              },
              (message: string | undefined) => {
                setApiError(message);
                setConnectionStatus("error");
              },
              () => {
                setApiError(undefined);
                setConnectionStatus("loading");
              }
            );
            break;
          case SmartLocksEnum.NUKI:
            const nukiPayload: NukiAuthenticationPayload = {
              rental_id: rentalId!,
            };

            useNukiAuthentication(
              nukiPayload,
              (url: string) => {
                window.location.href = url;
              },
              (message: string | undefined) => {
                setApiError(message);
                setConnectionStatus("error");
              },
              () => {
                setApiError(undefined);
                setConnectionStatus("loading");
              }
            );
            break;
          case SmartLocksEnum.TTLOCK:
            let lockAuthenticationPayload: LockAuthenticationPayload = {
              provider: loginForm
                .getValues("lockProvider")
                .toLowerCase() as Lowercase<RentalSmartLocksProvider>,
              username: ttlockLoginForm.getValues("email"),
              password: ttlockLoginForm.getValues("password"),
            };

            useLockAuthentication(
              lockAuthenticationPayload,
              (lockAuthenticationId: number) => {
                setConnectionStatus("success");
                setLockAuthenticationId(lockAuthenticationId);
              },
              (message: string | undefined) => {
                setApiError(message);
                setConnectionStatus("error");
              },
              () => {
                setApiError(undefined);
                setConnectionStatus("loading");
              }
            );
            break;
        }
      } else {
        throw new Error(
          `Invalid provider: ${loginForm.getValues("lockProvider")}`
        );
      }
    }
  };

  const resetConnection = () => {
    setCurrentCarouselWizardIndex(0);
    setConnectionStatus(null);
    setApiError(undefined);
  };

  const changeStep = (step: number) => {
    if (step > currentCarouselWizardIndex) {
      setCurrentCarouselWizardIndex(step);
      setConnectionStatus(null);
    }
  };

  // * Smart locks informations step
  const informationsForm = useForm<SmartlocksInformationsForm>({
    mode: "all",
    defaultValues: {
      lockName: undefined,
      lockId: "",
      checkinTime: "22:00",
      checkoutTime: "13:00",
    },
  });

  const informationsStaticForm = useForm<SmartlocksInformationsManualForm>({
    mode: "all",
    defaultValues: {
      code: undefined,
    },
  });

  useEffect(() => {
    if (lockIdItems && lockIdItems.length > 0) {
      informationsForm.setValue("lockId", lockIdItems[0].value);
    }
  }, [lockIdItems]);

  const onNextStep = ({
    which,
  }: {
    which: "informations" | "automated_messages";
  }) => {
    if (which === "informations") {
      if (loginForm.getValues("lockProvider") !== "static") {
        useLocks(
          Number(lockAuthenticationId),
          (smartlocksIds) => {
            setLockIdItems(
              smartlocksIds?.map((lock) => {
                return {
                  value: lock.id,
                  label: lock.name,
                };
              })
            );

            changeStep(currentCarouselWizardIndex + 1);
          },
          (message: string | undefined) => setApiError(message),
          () => {
            setApiError(undefined);
            setLoading(true);
          },
          () => {
            setLoading(false);
          }
        );
      }
    } else if (which === "automated_messages") {
      if (loginForm.getValues("lockProvider") === "static") {
        const data: SmartlocksAddStaticPayload = {
          name: staticLoginForm.getValues("name"),
          code: informationsStaticForm.getValues("code"),
        };

        useStoreStaticLock(
          rentalId,
          data,
          (lock) => {
            setLocks((prevLocks) => {
              // const { rental, onUpdateRental } = useRentalPageContext();
              // if (rental) {
              //   onUpdateRental({
              //     ...rental,
              //     services: {
              //       ...rental.services,
              //       locks: {
              //         ...rental.services.locks,
              //         manual_codes: [
              //           ...rental.services.locks.manual_codes,
              //           {
              //             id: lock.id,
              //             name: lock.name,
              //             code: lock.static_code!,
              //           },
              //         ],
              //       },
              //     },
              //   });
              // }

              if (prevLocks) {
                return [...prevLocks, lock];
              }

              return [lock];
            });

            changeStep(currentCarouselWizardIndex + 1);
          },
          (message: string | undefined) => setApiError(message),
          () => {
            setApiError(undefined);
            setLoading(true);
          },
          () => {
            setLoading(false);
          }
        );
      } else {
        if (lockAuthenticationId !== undefined) {
          const linkPayload: SmartlocksLinkPayload = {
            lock_authentication_id: lockAuthenticationId,
            device_id: informationsForm.getValues("lockId")?.toString() ?? "",
            name: informationsForm.getValues("lockName") ?? "",
          };

          // * TODO: [SmartLocks] Ajouter asap les codes
          useLockLink(
            rentalId,
            linkPayload,
            (lock) => {
              setLocks((prevLocks) => {
                if (
                  prevLocks &&
                  isValidSmartLockProvider(loginForm.getValues("lockProvider"))
                ) {
                  return [...prevLocks, lock];
                }

                return [lock];
              });
              changeStep(currentCarouselWizardIndex + 1);
              urlParams.remove("lock_auth_id");
            },
            (message: string | undefined) => setApiError(message),
            () => {
              setApiError(undefined);
              setValidationLoading(true);
            },
            () => {
              setValidationLoading(false);
            }
          );
        }
      }
    }
  };

  // * Smart locks update modal
  const [currentLock, setCurrentLock] = useState<
    SmartlocksLinkedItemResponse | undefined
  >();
  const [isUpdateModalVisible, setIsUpdateModalVisible] =
    useState<boolean>(false);
  const [deleteValidationLoading, setDeleteValidationLoading] =
    useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);

  const onCloseUpdateModal = () => setIsUpdateModalVisible(false);

  const onOpenUpdateModal = (lock: SmartlocksLinkedItemResponse) => {
    setLoading(true);
    setCurrentLock((prevCurrentLock) => {
      if (
        !prevCurrentLock ||
        (prevCurrentLock && prevCurrentLock.id !== lock.id)
      ) {
        if (lock.provider !== "static") {
          useLocks(
            lock.lock_authentication_id,
            (smartlocksIds: SmartlocksIdListItemResponse[]) => {
              setLockIdItems(
                smartlocksIds.map((lock) => {
                  return {
                    value: lock.id,
                    label: lock.name,
                  };
                })
              );
            },
            (message: string | undefined) => setApiError(message),
            () => {
              setApiError(undefined);
              setLoading(true);
            },
            () => {
              setLoading(false);
            }
          );
        }
      }

      return lock;
    });
    setIsUpdateModalVisible(true);
    setLoading(false);
  };

  const onOpenDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };

  const onCloseDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const onUpdateLock = () => {
    const onStart = () => {
      setApiError(undefined), setValidationLoading(true);
    };

    const onSuccess = (smartlocksUpdateResponse: SmartlocksUpdateResponse) => {
      if (locks) {
        setLocks((prevLocks) => {
          if (prevLocks) {
            return prevLocks.map((lock) => {
              if (lock.id === smartlocksUpdateResponse.id) {
                return {
                  id: smartlocksUpdateResponse.id,
                  lock_authentication_id: lock.lock_authentication_id,
                  device_id: smartlocksUpdateResponse.device_id,
                  name: smartlocksUpdateResponse.name,
                  code: smartlocksUpdateResponse.code,
                  static_code: lock.static_code,
                  provider: lock.provider,
                  provider_static_code: lock.provider_static_code,
                  checkin: smartlocksUpdateResponse.checkin,
                  checkout: smartlocksUpdateResponse.checkout,
                };
              }

              return lock;
            });
          }

          return undefined;
        });

        setIsUpdateModalVisible(false);
      }
    };

    const onError = (message: string | undefined) => {
      setApiError(message);
    };

    const onEnd = () => {
      setApiError(undefined), setValidationLoading(false);
    };

    return {
      onStart,
      onSuccess,
      onError,
      onEnd,
    };
  };

  const onUnlinkLock = () => {
    if (currentLock) {
      useLockUnlink(
        rentalId,
        {
          lock_rental_id: currentLock.id,
        },
        (smartLockUnlinkResponse: SmartlocksUnlinkResponse) => {
          setLocks(smartLockUnlinkResponse);
          setIsDeleteModalVisible(false);
          setIsUpdateModalVisible(false);
          setCurrentLock(undefined);
        },
        (message: string | undefined) => {
          setApiError(message);
          setIsDeleteModalVisible(false);
        },
        () => {
          setApiError(undefined);
          setDeleteValidationLoading(true);
        },
        () => {
          setDeleteValidationLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    if (!isAddModalVisible || !isUpdateModalVisible) {
      updateLockDynamicField(undefined);
    }

    if (isAddModalVisible && informationsForm.getValues("lockName")) {
      updateLockDynamicField(informationsForm.getValues("lockName"));
    }

    if (isUpdateModalVisible && currentLock) {
      updateLockDynamicField(currentLock.name);
    }
  }, [isAddModalVisible, isUpdateModalVisible, currentLock]);

  return (
    <RentalSmartlocksContext.Provider
      value={{
        t,
        navigate,
        loadingState: [loading, setLoading],
        validationLoading,
        apiError,
        currentCarouselWizardIndex,
        getLockProviderIcon,
        getLockFormattedProviderName,
        LockItem: RentalLockItem,
        timeList,
        updateLockDynamicField,
        LockDynamicFieldInfo,
        connectionStatus,
        attemptConnection,
        resetConnection,
        changeStep,
        locks,
        loginForm,
        igloohomeLoginForm,
        nukiLoginForm,
        ttlockLoginForm,
        staticLoginForm,
        informationsForm,
        informationsManualForm: informationsStaticForm,
        lockIdItems,
        igloohomeLockAccounts,
        nukiLockAccounts,
        ttlockLockAccounts,
        currentLock,
        onNextStep,
        isAddModalVisible,
        onOpenAddModal,
        onCloseAddModal,
        isUpdateModalVisible,
        onOpenUpdateModal,
        onCloseUpdateModal,
        onUpdateLock,
        onUnlinkLock,
        deleteValidationLoading,
        isDeleteModalVisible,
        onOpenDeleteModal,
        onCloseDeleteModal,
      }}
    >
      {children}
    </RentalSmartlocksContext.Provider>
  );
};
