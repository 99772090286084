const rentalTypes = [
  {
    id: 1,
    rental_category_id: 1,
    slug: "apartment",
    fr: "Appartement",
    en: "Apartment",
    es: "Apartamento",
    pt: "Apartamento",
    it: "Appartamento",
  },
  {
    id: 2,
    rental_category_id: 1,
    slug: "condominium",
    fr: "Copropriété",
    en: "Condominium",
    es: "Condominio",
    pt: "CondomÃ­nio",
    it: "Condominio",
  },
  {
    id: 3,
    rental_category_id: 1,
    slug: "loft",
    fr: "Loft",
    en: "Loft",
    es: "Loft",
    pt: "Loft",
    it: "Loft",
  },
  {
    id: 4,
    rental_category_id: 1,
    slug: "serviced_apartment",
    fr: "Appartement meublé",
    en: "Serviced Apartment",
    es: "Apartamento con servicios",
    pt: "Apartamento com serviÃ§os",
    it: "Appartamento ammobiliato",
  },
  {
    id: 5,
    rental_category_id: 2,
    slug: "house",
    fr: "Maison",
    en: "House",
    es: "Casa",
    pt: "Casa",
    it: "Casa",
  },
  {
    id: 6,
    rental_category_id: 2,
    slug: "cabin",
    fr: "Cabane",
    en: "Cabin",
    es: "CabaÃ±a",
    pt: "Cabana",
    it: "Capanna",
  },
  {
    id: 7,
    rental_category_id: 2,
    slug: "villa",
    fr: "Villa",
    en: "Villa",
    es: "Villa",
    pt: "Villa",
    it: "Villa",
  },
  {
    id: 8,
    rental_category_id: 2,
    slug: "townhouse",
    fr: "Maison de ville",
    en: "Townhouse",
    es: "Casa de pueblo",
    pt: "Moradia em banda",
    it: "Casa a schiera",
  },
  {
    id: 9,
    rental_category_id: 2,
    slug: "cottage",
    fr: "Cottage",
    en: "Cottage",
    es: "Casa de campo",
    pt: "Chalé",
    it: "Cottage",
  },
  {
    id: 10,
    rental_category_id: 2,
    slug: "bungalow",
    fr: "Bungalow",
    en: "Bungalow",
    es: "BungalÃ³",
    pt: "BangalÃ´",
    it: "Bungalow",
  },
  {
    id: 11,
    rental_category_id: 2,
    slug: "earthhouse",
    fr: "Maison de terre",
    en: "Earthhouse",
    es: "Casa de tierra",
    pt: "Casa de terra",
    it: "Casa di terra",
  },
  {
    id: 12,
    rental_category_id: 2,
    slug: "houseboat",
    fr: "Péniche",
    en: "Houseboat",
    es: "Casa flotante",
    pt: "Casa-barco",
    it: "Casa galleggiante",
  },
  {
    id: 13,
    rental_category_id: 2,
    slug: "hut",
    fr: "Hutte",
    en: "Hut",
    es: "CabaÃ±a",
    pt: "Cabana",
    it: "Capanna",
  },
  {
    id: 14,
    rental_category_id: 2,
    slug: "farm_stay",
    fr: "Séjour à  la ferme",
    en: "Farm Stay",
    es: "Estancia en granja",
    pt: "Estadia na fazenda",
    it: "Soggiorno in fattoria",
  },
  {
    id: 15,
    rental_category_id: 2,
    slug: "dome_house",
    fr: "Maison dôme",
    en: "Dome House",
    es: "Casa cÃºpula",
    pt: "Casa cÃºpula",
    it: "Casa cupola",
  },
  {
    id: 16,
    rental_category_id: 2,
    slug: "chalet",
    fr: "Chalet",
    en: "Chalet",
    es: "Chalet",
    pt: "Chalé",
    it: "Chalet",
  },
  {
    id: 17,
    rental_category_id: 2,
    slug: "lighthouse",
    fr: "Phare",
    en: "Lighthouse",
    es: "Faro",
    pt: "Farol",
    it: "Faro",
  },
  {
    id: 18,
    rental_category_id: 2,
    slug: "shepherds_hut",
    fr: "Cabane de berger",
    en: "Shepherds Hut",
    es: "CabaÃ±a de pastor",
    pt: "Cabana de pastor",
    it: "Capanna del pastore",
  },
  {
    id: 19,
    rental_category_id: 2,
    slug: "tiny_house",
    fr: "Petite maison",
    en: "Tiny House",
    es: "Casa pequeÃ±a",
    pt: "Casa pequena",
    it: "Piccola casa",
  },
  {
    id: 20,
    rental_category_id: 3,
    slug: "guesthouse",
    fr: "Maison d'hôte",
    en: "Guesthouse",
    es: "Casa de huéspedes",
    pt: "Casa de hÃ³spedes",
    it: "Casa per ospiti",
  },
  {
    id: 21,
    rental_category_id: 3,
    slug: "guest_suite",
    fr: "Suite d'invités",
    en: "Guest Suite",
    es: "Suite de invitados",
    pt: "SuÃ­te de hÃ³spedes",
    it: "Suite per ospiti",
  },
  {
    id: 22,
    rental_category_id: 3,
    slug: "farm_stay",
    fr: "Séjour à  la ferme",
    en: "Farm Stay",
    es: "Estancia en granja",
    pt: "Estadia na fazenda",
    it: "Soggiorno in fattoria",
  },
  {
    id: 23,
    rental_category_id: 4,
    slug: "barn",
    fr: "Grange",
    en: "Barn",
    es: "Granero",
    pt: "Celeiro",
    it: "Fienile",
  },
  {
    id: 24,
    rental_category_id: 4,
    slug: "boat",
    fr: "Bateau",
    en: "Boat",
    es: "Barco",
    pt: "Barco",
    it: "Barca",
  },
  {
    id: 25,
    rental_category_id: 4,
    slug: "bus",
    fr: "Bus",
    en: "Bus",
    es: "AutobÃºs",
    pt: "Ã”nibus",
    it: "Autobus",
  },
  {
    id: 26,
    rental_category_id: 4,
    slug: "rv",
    fr: "Camping-car",
    en: "RV",
    es: "Autocaravana",
    pt: "Autocaravana",
    it: "Camper",
  },
  {
    id: 27,
    rental_category_id: 4,
    slug: "treehouse",
    fr: "Cabane dans les arbres",
    en: "Treehouse",
    es: "Casa del Ã¡rbol",
    pt: "Casa na Ã¡rvore",
    it: "Casa sullâ€™albero",
  },
  {
    id: 28,
    rental_category_id: 4,
    slug: "campsite",
    fr: "Camping",
    en: "Campsite",
    es: "Camping",
    pt: "Parque de campismo",
    it: "Campeggio",
  },
  {
    id: 29,
    rental_category_id: 4,
    slug: "castle",
    fr: "Château",
    en: "Castle",
    es: "Castillo",
    pt: "Castelo",
    it: "Castello",
  },
  {
    id: 30,
    rental_category_id: 4,
    slug: "cave",
    fr: "Grotte",
    en: "Cave",
    es: "Cueva",
    pt: "Caverna",
    it: "Grotta",
  },
  {
    id: 31,
    rental_category_id: 4,
    slug: "dome_house",
    fr: "Maison dôme",
    en: "Dome House",
    es: "Casa cÃºpula",
    pt: "Casa cÃºpula",
    it: "Casa cupola",
  },
  {
    id: 32,
    rental_category_id: 4,
    slug: "earthhouse",
    fr: "Maison de terre",
    en: "Earthhouse",
    es: "Casa de tierra",
    pt: "Casa de terra",
    it: "Casa di terra",
  },
  {
    id: 33,
    rental_category_id: 4,
    slug: "farm_stay",
    fr: "Séjour à  la ferme",
    en: "Farm Stay",
    es: "Estancia en granja",
    pt: "Estadia na fazenda",
    it: "Soggiorno in fattoria",
  },
  {
    id: 34,
    rental_category_id: 4,
    slug: "holiday_park",
    fr: "Parc de vacances",
    en: "Holiday Park",
    es: "Parque de vacaciones",
    pt: "Parque de férias",
    it: "Parco vacanze",
  },
  {
    id: 35,
    rental_category_id: 4,
    slug: "houseboat",
    fr: "Péniche",
    en: "Houseboat",
    es: "Casa flotante",
    pt: "Casa-barco",
    it: "Casa galleggiante",
  },
  {
    id: 36,
    rental_category_id: 4,
    slug: "hut",
    fr: "Hutte",
    en: "Hut",
    es: "CabaÃ±a",
    pt: "Cabana",
    it: "Capanna",
  },
  {
    id: 37,
    rental_category_id: 4,
    slug: "igloo",
    fr: "Igloo",
    en: "Igloo",
    es: "IglÃº",
    pt: "Iglu",
    it: "Igloo",
  },
  {
    id: 38,
    rental_category_id: 4,
    slug: "island",
    fr: "Île",
    en: "Island",
    es: "Isla",
    pt: "Ilha",
    it: "Isola",
  },
  {
    id: 39,
    rental_category_id: 4,
    slug: "lighthouse",
    fr: "Phare",
    en: "Lighthouse",
    es: "Faro",
    pt: "Farol",
    it: "Faro",
  },
  {
    id: 40,
    rental_category_id: 4,
    slug: "plane",
    fr: "Avion",
    en: "Plane",
    es: "AviÃ³n",
    pt: "AviÃ£o",
    it: "Aereo",
  },
  {
    id: 41,
    rental_category_id: 4,
    slug: "ranch",
    fr: "Ranch",
    en: "Ranch",
    es: "Rancho",
    pt: "Rancho",
    it: "Ranch",
  },
  {
    id: 42,
    rental_category_id: 4,
    slug: "religious_building",
    fr: "Bâtiment religieux",
    en: "Religious Building",
    es: "Edificio religioso",
    pt: "EdifÃ­cio religioso",
    it: "Edificio religioso",
  },
  {
    id: 43,
    rental_category_id: 4,
    slug: "shepherds_hut",
    fr: "Cabane de berger",
    en: "Shepherds Hut",
    es: "CabaÃ±a de pastor",
    pt: "Cabana de pastor",
    it: "Capanna del pastore",
  },
  {
    id: 44,
    rental_category_id: 4,
    slug: "shipping_container",
    fr: "Conteneur maritime",
    en: "Shipping Container",
    es: "Contenedor de envÃ­o",
    pt: "Contentor de transporte",
    it: "Contenitore di spedizione",
  },
  {
    id: 45,
    rental_category_id: 4,
    slug: "tent",
    fr: "Tente",
    en: "Tent",
    es: "Tienda de campaÃ±a",
    pt: "Tenda",
    it: "Tenda",
  },
  {
    id: 46,
    rental_category_id: 4,
    slug: "tiny_house",
    fr: "Petite maison",
    en: "Tiny House",
    es: "Casa pequeÃ±a",
    pt: "Casa pequena",
    it: "Piccola casa",
  },
  {
    id: 47,
    rental_category_id: 4,
    slug: "tipi",
    fr: "Tipi",
    en: "Tipi",
    es: "Tipi",
    pt: "Tipi",
    it: "Tipi",
  },
  {
    id: 48,
    rental_category_id: 4,
    slug: "tower",
    fr: "Tour",
    en: "Tower",
    es: "Torre",
    pt: "Torre",
    it: "Torre",
  },
  {
    id: 49,
    rental_category_id: 4,
    slug: "train",
    fr: "Train",
    en: "Train",
    es: "Tren",
    pt: "Trem",
    it: "Treno",
  },
  {
    id: 50,
    rental_category_id: 4,
    slug: "windmill",
    fr: "Moulin à  vent",
    en: "Windmill",
    es: "Molino de viento",
    pt: "Moinho de vento",
    it: "Mulino a vento",
  },
  {
    id: 51,
    rental_category_id: 4,
    slug: "yurt",
    fr: "Yourte",
    en: "Yurt",
    es: "Yurta",
    pt: "Yurt",
    it: "Yurta",
  },
  {
    id: 52,
    rental_category_id: 4,
    slug: "riad",
    fr: "Riad",
    en: "Riad",
    es: "Riad",
    pt: "Riad",
    it: "Riad",
  },
  {
    id: 53,
    rental_category_id: 4,
    slug: "other",
    fr: "Autre",
    en: "Other",
    es: "Otro",
    pt: "Outro",
    it: "Altro",
  },
  {
    id: 54,
    rental_category_id: 5,
    slug: "bnb",
    fr: "BNB",
    en: "BNB",
    es: "BNB",
    pt: "BNB",
    it: "BNB",
  },
  {
    id: 55,
    rental_category_id: 5,
    slug: "lodge",
    fr: "Pavillon",
    en: "Lodge",
    es: "Lodge",
    pt: "Lodge",
    it: "Lodge",
  },
  {
    id: 56,
    rental_category_id: 5,
    slug: "farm_stay",
    fr: "Séjour à  la ferme",
    en: "Farm Stay",
    es: "Estancia en granja",
    pt: "Estadia na fazenda",
    it: "Soggiorno in fattoria",
  },
  {
    id: 57,
    rental_category_id: 6,
    slug: "hotel",
    fr: "Hôtel",
    en: "Hotel",
    es: "Hotel",
    pt: "Hotel",
    it: "Hotel",
  },
  {
    id: 58,
    rental_category_id: 6,
    slug: "hostel",
    fr: "Auberge",
    en: "Hostel",
    es: "Hostal",
    pt: "Hostel",
    it: "Ostello",
  },
  {
    id: 59,
    rental_category_id: 6,
    slug: "resort",
    fr: "Complexe hôtelier",
    en: "Resort",
    es: "Resort",
    pt: "Resort",
    it: "Resort",
  },
  {
    id: 60,
    rental_category_id: 6,
    slug: "lodge",
    fr: "Pavillon",
    en: "Lodge",
    es: "Lodge",
    pt: "Lodge",
    it: "Lodge",
  },
  {
    id: 61,
    rental_category_id: 6,
    slug: "boutique_hotel",
    fr: "Hôtel de charme",
    en: "Boutique Hotel",
    es: "Hotel boutique",
    pt: "Hotel boutique",
    it: "Hotel boutique",
  },
  {
    id: 62,
    rental_category_id: 6,
    slug: "aparthotel",
    fr: "Apparthôtel",
    en: "Aparthotel",
    es: "Apartahotel",
    pt: "Apart-hotel",
    it: "Aparthotel",
  },
  {
    id: 63,
    rental_category_id: 6,
    slug: "serviced_apartment",
    fr: "Appartement meublé",
    en: "Serviced Apartment",
    es: "Apartamento con servicios",
    pt: "Apartamento com serviÃ§os",
    it: "Appartamento ammobiliato",
  },
];

export default rentalTypes;
