import Rental1 from "../assets/images/rental1.webp";
import Rental2 from "../assets/images/rental2.webp";
import Rental3 from "../assets/images/rental3.webp";
import Rental4 from "../assets/images/rental4.webp";
import Rental5 from "../assets/images/rental5.webp";

export default [
  {
    id: 1,
    name: "Appartement Le Marais",
    address: "16 rue Charlemagne 75004 Paris",
    img: Rental1,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 128,
        minNights: 12,
        maxNights: 9,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-26": {
        isAvailable: true,
        price: 394,
        minNights: 23,
        maxNights: 5,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-27": {
        isAvailable: false,
        price: 685,
        minNights: 5,
        maxNights: 33,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-28": {
        isAvailable: true,
        price: 262,
        minNights: 16,
        maxNights: 22,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-29": {
        isAvailable: false,
        price: 788,
        minNights: 2,
        maxNights: 38,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-30": {
        isAvailable: true,
        price: 151,
        minNights: 27,
        maxNights: 15,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-08-31": {
        isAvailable: false,
        price: 712,
        minNights: 0,
        maxNights: 13,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-01": {
        isAvailable: true,
        price: 245,
        minNights: 8,
        maxNights: 38,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-02": {
        isAvailable: true,
        price: 682,
        minNights: 14,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-03": {
        isAvailable: false,
        price: 721,
        minNights: 35,
        maxNights: 37,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-04": {
        isAvailable: false,
        price: 493,
        minNights: 16,
        maxNights: 21,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-05": {
        isAvailable: true,
        price: 319,
        minNights: 10,
        maxNights: 38,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-06": {
        isAvailable: true,
        price: 203,
        minNights: 8,
        maxNights: 12,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-07": {
        isAvailable: true,
        price: 271,
        minNights: 6,
        maxNights: 35,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-08": {
        isAvailable: true,
        price: 412,
        minNights: 23,
        maxNights: 11,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-09": {
        isAvailable: true,
        price: 178,
        minNights: 19,
        maxNights: 31,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-10": {
        isAvailable: true,
        price: 387,
        minNights: 31,
        maxNights: 7,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-11": {
        isAvailable: true,
        price: 92,
        minNights: 29,
        maxNights: 40,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-12": {
        isAvailable: true,
        price: 546,
        minNights: 8,
        maxNights: 30,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-13": {
        isAvailable: true,
        price: 740,
        minNights: 12,
        maxNights: 14,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-14": {
        isAvailable: true,
        price: 545,
        minNights: 6,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-15": {
        isAvailable: true,
        price: 134,
        minNights: 22,
        maxNights: 29,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-16": {
        isAvailable: true,
        price: 134,
        minNights: 22,
        maxNights: 29,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-17": {
        isAvailable: true,
        price: 134,
        minNights: 22,
        maxNights: 29,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-18": {
        isAvailable: true,
        price: 134,
        minNights: 22,
        maxNights: 29,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-19": {
        isAvailable: true,
        price: 134,
        minNights: 22,
        maxNights: 29,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-20": {
        isAvailable: true,
        price: 134,
        minNights: 22,
        maxNights: 29,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-21": {
        isAvailable: true,
        price: 134,
        minNights: 22,
        maxNights: 29,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-22": {
        isAvailable: true,
        price: 134,
        minNights: 22,
        maxNights: 29,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-23": {
        isAvailable: true,
        price: 134,
        minNights: 22,
        maxNights: 29,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 134,
        minNights: 22,
        maxNights: 29,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-25": {
        isAvailable: true,
        price: 134,
        minNights: 22,
        maxNights: 29,
        noCheckin: false,
        noCheckout: false,
      },
    },
    bookings: [
      {
        checkin: "2023-08-29",
        checkout: "2023-08-31",
        platform: "booking",
        fullName: "Adam Birkett",
      },
      {
        checkin: "2023-08-31",
        checkout: "2023-09-05",
        platform: "booking",
        fullName: "Amine Ney",
      },
    ],
  },
  {
    id: 2,
    name: "Appartement Tour Eiffel",
    address: "Place du Champs de Mars 75007 Paris",
    img: Rental2,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 256,
      },
      "2023-08-26": {
        isAvailable: true,
        price: 256,
      },
      "2023-08-27": {
        isAvailable: true,
        price: 128,
      },
      "2023-08-28": {
        isAvailable: true,
        price: 128,
      },
      "2023-08-29": {
        isAvailable: true,
        price: 250,
      },
      "2023-08-30": {
        isAvailable: true,
        price: 450,
      },
      "2023-08-31": {
        isAvailable: true,
        price: 450,
      },
      "2023-09-01": {
        isAvailable: true,
        price: 450,
      },
      "2023-09-02": {
        isAvailable: true,
        price: 450,
      },
      "2023-09-03": {
        isAvailable: false,
        price: 450,
      },
      "2023-09-04": {
        isAvailable: false,
        price: 450,
      },
      "2023-09-05": {
        isAvailable: false,
        price: 450,
      },
      "2023-09-06": {
        isAvailable: false,
        price: 250,
      },
      "2023-09-07": {
        isAvailable: false,
        price: 250,
      },
      "2023-09-08": {
        isAvailable: false,
        price: 250,
      },
      "2023-09-09": {
        isAvailable: false,
        price: 250,
      },
      "2023-09-10": {
        isAvailable: false,
        price: 250,
      },
      "2023-09-11": {
        isAvailable: false,
        price: 250,
      },
      "2023-09-12": {
        isAvailable: false,
        price: 250,
      },
      "2023-09-13": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-14": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-15": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-16": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-17": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-18": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-19": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-20": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-21": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-22": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-23": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-25": {
        isAvailable: true,
        price: 250,
      },
    },
    bookings: [
      {
        checkin: "2023-09-03",
        checkout: "2023-09-13",
        platform: "booking",
        fullName: "Elie Al Shami",
      },
    ],
  },
  {
    id: 3,
    name: "Loft Bohème Belleville",
    address: "22 Rue de la Liberté, 75001 Paris, France",
    img: Rental3,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 128,
        minNights: 12,
        maxNights: 9,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-26": {
        isAvailable: true,
        price: 846,
        minNights: 18,
        maxNights: 7,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-27": {
        isAvailable: false,
        price: 442,
        minNights: 0,
        maxNights: 10,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-28": {
        isAvailable: true,
        price: 192,
        minNights: 1,
        maxNights: 39,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-29": {
        isAvailable: true,
        price: 274,
        minNights: 9,
        maxNights: 25,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-30": {
        isAvailable: false,
        price: 685,
        minNights: 7,
        maxNights: 13,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-31": {
        isAvailable: true,
        price: 122,
        minNights: 32,
        maxNights: 10,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-01": {
        isAvailable: false,
        price: 607,
        minNights: 26,
        maxNights: 1,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-02": {
        isAvailable: true,
        price: 754,
        minNights: 19,
        maxNights: 26,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-03": {
        isAvailable: true,
        price: 883,
        minNights: 4,
        maxNights: 25,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-04": {
        isAvailable: true,
        price: 128,
        minNights: 12,
        maxNights: 9,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-05": {
        isAvailable: true,
        price: 846,
        minNights: 18,
        maxNights: 7,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-06": {
        isAvailable: false,
        price: 442,
        minNights: 0,
        maxNights: 10,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-07": {
        isAvailable: true,
        price: 192,
        minNights: 1,
        maxNights: 39,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-08": {
        isAvailable: true,
        price: 274,
        minNights: 9,
        maxNights: 25,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-09": {
        isAvailable: false,
        price: 685,
        minNights: 7,
        maxNights: 13,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-10": {
        isAvailable: true,
        price: 122,
        minNights: 32,
        maxNights: 10,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-11": {
        isAvailable: false,
        price: 607,
        minNights: 26,
        maxNights: 1,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-12": {
        isAvailable: true,
        price: 754,
        minNights: 19,
        maxNights: 26,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-13": {
        isAvailable: true,
        price: 883,
        minNights: 4,
        maxNights: 25,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-14": {
        isAvailable: true,
        price: 883,
        minNights: 4,
        maxNights: 25,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-15": {
        isAvailable: true,
        price: 883,
        minNights: 4,
        maxNights: 25,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-16": {
        isAvailable: true,
        price: 883,
        minNights: 4,
        maxNights: 25,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-17": {
        isAvailable: true,
        price: 883,
        minNights: 4,
        maxNights: 25,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-18": {
        isAvailable: true,
        price: 883,
        minNights: 4,
        maxNights: 25,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-19": {
        isAvailable: true,
        price: 883,
        minNights: 4,
        maxNights: 25,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-20": {
        isAvailable: true,
        price: 883,
        minNights: 4,
        maxNights: 25,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-21": {
        isAvailable: true,
        price: 883,
        minNights: 4,
        maxNights: 25,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-22": {
        isAvailable: true,
        price: 883,
        minNights: 4,
        maxNights: 25,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-23": {
        isAvailable: true,
        price: 883,
        minNights: 4,
        maxNights: 25,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 883,
        minNights: 4,
        maxNights: 25,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-25": {
        isAvailable: true,
        price: 883,
        minNights: 4,
        maxNights: 25,
        noCheckin: false,
        noCheckout: false,
      },
    },
    bookings: [
      {
        checkin: "2023-09-09",
        checkout: "2023-09-12",
        platform: "booking",
        fullName: "Lucas Tremblay",
      },
    ],
  },
  {
    id: 4,
    name: "Appartement Cosy Montmartre",
    address: "10 Avenue des Champs-Élysées, 75008 Paris, France",
    img: Rental4,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 794,
        minNights: 33,
        maxNights: 10,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-08-26": {
        isAvailable: false,
        price: 770,
        minNights: 19,
        maxNights: 29,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-27": {
        isAvailable: true,
        price: 508,
        minNights: 12,
        maxNights: 17,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-28": {
        isAvailable: true,
        price: 53,
        minNights: 4,
        maxNights: 23,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-29": {
        isAvailable: false,
        price: 830,
        minNights: 23,
        maxNights: 36,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-30": {
        isAvailable: false,
        price: 838,
        minNights: 14,
        maxNights: 16,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-31": {
        isAvailable: false,
        price: 506,
        minNights: 27,
        maxNights: 16,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-01": {
        isAvailable: true,
        price: 137,
        minNights: 24,
        maxNights: 8,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-02": {
        isAvailable: true,
        price: 215,
        minNights: 3,
        maxNights: 25,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-03": {
        isAvailable: false,
        price: 508,
        minNights: 17,
        maxNights: 20,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-04": {
        isAvailable: true,
        price: 800,
        minNights: 19,
        maxNights: 7,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-05": {
        isAvailable: false,
        price: 485,
        minNights: 10,
        maxNights: 9,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-06": {
        isAvailable: true,
        price: 191,
        minNights: 26,
        maxNights: 16,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-07": {
        isAvailable: false,
        price: 256,
        minNights: 35,
        maxNights: 29,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-08": {
        isAvailable: true,
        price: 685,
        minNights: 1,
        maxNights: 2,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-09": {
        isAvailable: false,
        price: 742,
        minNights: 33,
        maxNights: 1,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-10": {
        isAvailable: false,
        price: 699,
        minNights: 20,
        maxNights: 29,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-11": {
        isAvailable: true,
        price: 442,
        minNights: 38,
        maxNights: 30,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-12": {
        isAvailable: true,
        price: 129,
        minNights: 13,
        maxNights: 20,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-13": {
        isAvailable: false,
        price: 700,
        minNights: 14,
        maxNights: 3,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-14": {
        isAvailable: false,
        price: 365,
        minNights: 11,
        maxNights: 8,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-15": {
        isAvailable: true,
        price: 37,
        minNights: 2,
        maxNights: 3,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-16": {
        isAvailable: false,
        price: 643,
        minNights: 26,
        maxNights: 15,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-17": {
        isAvailable: true,
        price: 615,
        minNights: 7,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-18": {
        isAvailable: false,
        price: 816,
        minNights: 30,
        maxNights: 24,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-19": {
        isAvailable: false,
        price: 614,
        minNights: 5,
        maxNights: 19,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-20": {
        isAvailable: false,
        price: 523,
        minNights: 29,
        maxNights: 33,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-21": {
        isAvailable: false,
        price: 523,
        minNights: 29,
        maxNights: 33,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-22": {
        isAvailable: true,
        price: 615,
        minNights: 7,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-23": {
        isAvailable: false,
        price: 816,
        minNights: 30,
        maxNights: 24,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-24": {
        isAvailable: false,
        price: 614,
        minNights: 5,
        maxNights: 19,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-25": {
        isAvailable: false,
        price: 523,
        minNights: 29,
        maxNights: 33,
        noCheckin: true,
        noCheckout: false,
      },
    },
    bookings: [
      {
        checkin: "2023-08-29",
        checkout: "2023-09-01",
        platform: "booking",
        fullName: "Emma Deschamps",
      },
    ],
  },
  {
    id: 5,
    name: "Studio Lumière Saint-Germain",
    addresse: "45 Rue du Faubourg Saint-Honoré, 75008 Paris, France",
    img: Rental5,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 794,
        minNights: 33,
        maxNights: 10,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-08-26": {
        isAvailable: false,
        price: 770,
        minNights: 19,
        maxNights: 29,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-27": {
        isAvailable: true,
        price: 508,
        minNights: 12,
        maxNights: 17,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-28": {
        isAvailable: true,
        price: 53,
        minNights: 4,
        maxNights: 23,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-29": {
        isAvailable: false,
        price: 830,
        minNights: 23,
        maxNights: 36,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-30": {
        isAvailable: false,
        price: 838,
        minNights: 14,
        maxNights: 16,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-31": {
        isAvailable: false,
        price: 506,
        minNights: 27,
        maxNights: 16,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-01": {
        isAvailable: true,
        price: 137,
        minNights: 24,
        maxNights: 8,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-02": {
        isAvailable: true,
        price: 215,
        minNights: 3,
        maxNights: 25,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-03": {
        isAvailable: false,
        price: 508,
        minNights: 17,
        maxNights: 20,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-04": {
        isAvailable: true,
        price: 800,
        minNights: 19,
        maxNights: 7,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-05": {
        isAvailable: false,
        price: 485,
        minNights: 10,
        maxNights: 9,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-06": {
        isAvailable: true,
        price: 191,
        minNights: 26,
        maxNights: 16,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-07": {
        isAvailable: false,
        price: 256,
        minNights: 35,
        maxNights: 29,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-08": {
        isAvailable: true,
        price: 685,
        minNights: 1,
        maxNights: 2,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-09": {
        isAvailable: false,
        price: 742,
        minNights: 33,
        maxNights: 1,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-10": {
        isAvailable: false,
        price: 699,
        minNights: 20,
        maxNights: 29,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-11": {
        isAvailable: true,
        price: 442,
        minNights: 38,
        maxNights: 30,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-12": {
        isAvailable: true,
        price: 129,
        minNights: 13,
        maxNights: 20,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-13": {
        isAvailable: false,
        price: 700,
        minNights: 14,
        maxNights: 3,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-14": {
        isAvailable: false,
        price: 365,
        minNights: 11,
        maxNights: 8,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-15": {
        isAvailable: true,
        price: 37,
        minNights: 2,
        maxNights: 3,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-16": {
        isAvailable: false,
        price: 643,
        minNights: 26,
        maxNights: 15,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-17": {
        isAvailable: true,
        price: 615,
        minNights: 7,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-18": {
        isAvailable: false,
        price: 816,
        minNights: 30,
        maxNights: 24,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-19": {
        isAvailable: false,
        price: 614,
        minNights: 5,
        maxNights: 19,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-20": {
        isAvailable: false,
        price: 523,
        minNights: 29,
        maxNights: 33,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-21": {
        isAvailable: true,
        price: 609,
        minNights: 10,
        maxNights: 36,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-22": {
        isAvailable: false,
        price: 663,
        minNights: 11,
        maxNights: 18,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-23": {
        isAvailable: false,
        price: 869,
        minNights: 21,
        maxNights: 29,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 211,
        minNights: 27,
        maxNights: 12,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-25": {
        isAvailable: true,
        price: 249,
        minNights: 33,
        maxNights: 31,
        noCheckin: true,
        noCheckout: false,
      },
    },
    bookings: [
      {
        checkin: "2023-08-29",
        checkout: "2023-09-01",
        platform: "booking",
        fullName: "Nathan Girard",
      },
    ],
  },
  {
    id: 6,
    name: "Villa Oasis Côte d'Azur",
    address: "15 Boulevard de la Croisette, 06400 Cannes, France",
    img: Rental1,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 256,
        minNights: 12,
        maxNights: 9,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-26": {
        isAvailable: true,
        price: 256,
      },
      "2023-08-27": {
        isAvailable: true,
        price: 128,
      },
      "2023-08-28": {
        isAvailable: true,
        price: 128,
      },
      "2023-08-29": {
        isAvailable: true,
        price: 250,
      },
      "2023-08-30": {
        isAvailable: true,
        price: 450,
      },
      "2023-08-31": {
        isAvailable: true,
        price: 450,
      },
      "2023-09-01": {
        isAvailable: true,
        price: 450,
      },
      "2023-09-02": {
        isAvailable: true,
        price: 450,
      },
      "2023-09-03": {
        isAvailable: false,
        price: 450,
      },
      "2023-09-04": {
        isAvailable: false,
        price: 450,
      },
      "2023-09-05": {
        isAvailable: false,
        price: 450,
      },
      "2023-09-06": {
        isAvailable: false,
        price: 250,
      },
      "2023-09-07": {
        isAvailable: false,
        price: 250,
      },
      "2023-09-08": {
        isAvailable: false,
        price: 250,
      },
      "2023-09-09": {
        isAvailable: false,
        price: 250,
      },
      "2023-09-10": {
        isAvailable: false,
        price: 250,
      },
      "2023-09-11": {
        isAvailable: false,
        price: 250,
      },
      "2023-09-12": {
        isAvailable: false,
        price: 250,
      },
      "2023-09-13": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-14": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-15": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-16": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-17": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-18": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-19": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-20": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-21": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-22": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-23": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 250,
      },
      "2023-09-25": {
        isAvailable: true,
        price: 250,
      },
    },
    bookings: [
      {
        checkin: "2023-09-02",
        checkout: "2023-09-13",
        platform: "booking",
        fullName: "Léa Mercier",
      },
    ],
  },
  {
    id: 7,
    name: "Logement Charme Provence",
    address: "8 Promenade des Anglais, 06000 Nice, France",
    img: Rental2,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 794,
        minNights: 33,
        maxNights: 10,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-08-26": {
        isAvailable: false,
        price: 770,
        minNights: 19,
        maxNights: 29,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-27": {
        isAvailable: true,
        price: 508,
        minNights: 12,
        maxNights: 17,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-28": {
        isAvailable: true,
        price: 53,
        minNights: 4,
        maxNights: 23,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-29": {
        isAvailable: false,
        price: 830,
        minNights: 23,
        maxNights: 36,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-30": {
        isAvailable: false,
        price: 838,
        minNights: 14,
        maxNights: 16,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-31": {
        isAvailable: false,
        price: 506,
        minNights: 27,
        maxNights: 16,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-01": {
        isAvailable: true,
        price: 137,
        minNights: 24,
        maxNights: 8,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-02": {
        isAvailable: true,
        price: 215,
        minNights: 3,
        maxNights: 25,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-03": {
        isAvailable: false,
        price: 508,
        minNights: 17,
        maxNights: 20,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-04": {
        isAvailable: true,
        price: 800,
        minNights: 19,
        maxNights: 7,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-05": {
        isAvailable: false,
        price: 485,
        minNights: 10,
        maxNights: 9,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-06": {
        isAvailable: true,
        price: 191,
        minNights: 26,
        maxNights: 16,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-07": {
        isAvailable: false,
        price: 256,
        minNights: 35,
        maxNights: 29,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-08": {
        isAvailable: true,
        price: 685,
        minNights: 1,
        maxNights: 2,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-09": {
        isAvailable: false,
        price: 742,
        minNights: 33,
        maxNights: 1,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-10": {
        isAvailable: false,
        price: 699,
        minNights: 20,
        maxNights: 29,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-11": {
        isAvailable: true,
        price: 442,
        minNights: 38,
        maxNights: 30,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-12": {
        isAvailable: true,
        price: 129,
        minNights: 13,
        maxNights: 20,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-13": {
        isAvailable: false,
        price: 700,
        minNights: 14,
        maxNights: 3,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-14": {
        isAvailable: false,
        price: 365,
        minNights: 11,
        maxNights: 8,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-15": {
        isAvailable: true,
        price: 37,
        minNights: 2,
        maxNights: 3,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-16": {
        isAvailable: false,
        price: 643,
        minNights: 26,
        maxNights: 15,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-17": {
        isAvailable: true,
        price: 615,
        minNights: 7,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-18": {
        isAvailable: false,
        price: 816,
        minNights: 30,
        maxNights: 24,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-19": {
        isAvailable: false,
        price: 614,
        minNights: 5,
        maxNights: 19,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-20": {
        isAvailable: false,
        price: 523,
        minNights: 29,
        maxNights: 33,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-21": {
        isAvailable: true,
        price: 609,
        minNights: 10,
        maxNights: 36,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-22": {
        isAvailable: false,
        price: 663,
        minNights: 11,
        maxNights: 18,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-23": {
        isAvailable: false,
        price: 869,
        minNights: 21,
        maxNights: 29,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 211,
        minNights: 27,
        maxNights: 12,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-25": {
        isAvailable: true,
        price: 249,
        minNights: 33,
        maxNights: 31,
        noCheckin: true,
        noCheckout: false,
      },
    },
    bookings: [
      {
        checkin: "2023-08-26",
        checkout: "2023-09-04",
        platform: "booking",
        fullName: "Hugo Dupuis",
      },
    ],
  },
  {
    id: 8,
    name: "Appartement Cosy Courbevoie",
    address: "25 Avenue Princesse Grace, 98000 Monaco",
    img: Rental3,
    days: {
      "2023-08-25": {
        isAvailable: false,
        price: 845,
        minNights: 30,
        maxNights: 11,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-26": {
        isAvailable: true,
        price: 265,
        minNights: 3,
        maxNights: 38,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-08-27": {
        isAvailable: false,
        price: 556,
        minNights: 27,
        maxNights: 5,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-28": {
        isAvailable: true,
        price: 549,
        minNights: 6,
        maxNights: 8,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-29": {
        isAvailable: true,
        price: 520,
        minNights: 14,
        maxNights: 19,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-30": {
        isAvailable: false,
        price: 637,
        minNights: 29,
        maxNights: 18,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-08-31": {
        isAvailable: true,
        price: 587,
        minNights: 23,
        maxNights: 27,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-01": {
        isAvailable: false,
        price: 786,
        minNights: 9,
        maxNights: 26,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-02": {
        isAvailable: true,
        price: 228,
        minNights: 10,
        maxNights: 9,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-03": {
        isAvailable: false,
        price: 876,
        minNights: 23,
        maxNights: 29,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-04": {
        isAvailable: false,
        price: 749,
        minNights: 6,
        maxNights: 18,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-05": {
        isAvailable: true,
        price: 158,
        minNights: 17,
        maxNights: 33,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-06": {
        isAvailable: false,
        price: 390,
        minNights: 24,
        maxNights: 17,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-07": {
        isAvailable: true,
        price: 644,
        minNights: 26,
        maxNights: 26,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-08": {
        isAvailable: true,
        price: 325,
        minNights: 21,
        maxNights: 23,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-09": {
        isAvailable: false,
        price: 583,
        minNights: 11,
        maxNights: 15,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-10": {
        isAvailable: false,
        price: 964,
        minNights: 25,
        maxNights: 36,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-11": {
        isAvailable: true,
        price: 314,
        minNights: 27,
        maxNights: 25,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-12": {
        isAvailable: true,
        price: 165,
        minNights: 4,
        maxNights: 40,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-13": {
        isAvailable: false,
        price: 72,
        minNights: 12,
        maxNights: 4,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-14": {
        isAvailable: true,
        price: 284,
        minNights: 19,
        maxNights: 33,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-15": {
        isAvailable: true,
        price: 149,
        minNights: 5,
        maxNights: 3,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-16": {
        isAvailable: false,
        price: 817,
        minNights: 24,
        maxNights: 23,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-17": {
        isAvailable: false,
        price: 372,
        minNights: 19,
        maxNights: 26,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-18": {
        isAvailable: true,
        price: 465,
        minNights: 6,
        maxNights: 10,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-19": {
        isAvailable: false,
        price: 895,
        minNights: 17,
        maxNights: 3,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-20": {
        isAvailable: true,
        price: 664,
        minNights: 9,
        maxNights: 37,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-21": {
        isAvailable: false,
        price: 607,
        minNights: 10,
        maxNights: 25,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-22": {
        isAvailable: false,
        price: 797,
        minNights: 2,
        maxNights: 9,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-23": {
        isAvailable: true,
        price: 666,
        minNights: 18,
        maxNights: 18,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 218,
        minNights: 27,
        maxNights: 27,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-25": {
        isAvailable: false,
        price: 729,
        minNights: 35,
        maxNights: 31,
        noCheckin: false,
        noCheckout: false,
      },
    },
    bookings: [
      {
        checkin: "2023-08-25",
        checkout: "2023-08-28",
        platform: "booking",
        fullName: "Manon Lefévère",
      },
      {
        checkin: "2023-08-30",
        checkout: "2023-09-01",
        platform: "booking",
        fullName: "Mathis Martin",
      },
      {
        checkin: "2023-09-01",
        checkout: "2023-09-05",
        platform: "booking",
        fullName: "Clara Gagnon",
      },
    ],
  },
  {
    id: 9,
    name: "Duplex Canal Saint-Martin",
    address: "30 Rue Saint-Antoine, 75004 Paris, France",
    img: Rental4,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 642,
        minNights: 16,
        maxNights: 23,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-26": {
        isAvailable: true,
        price: 457,
        minNights: 26,
        maxNights: 14,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-27": {
        isAvailable: true,
        price: 78,
        minNights: 7,
        maxNights: 33,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-08-28": {
        isAvailable: false,
        price: 648,
        minNights: 4,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-29": {
        isAvailable: true,
        price: 443,
        minNights: 16,
        maxNights: 15,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-30": {
        isAvailable: false,
        price: 749,
        minNights: 12,
        maxNights: 29,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-31": {
        isAvailable: true,
        price: 987,
        minNights: 28,
        maxNights: 37,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-01": {
        isAvailable: true,
        price: 155,
        minNights: 17,
        maxNights: 24,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-02": {
        isAvailable: false,
        price: 387,
        minNights: 15,
        maxNights: 32,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-03": {
        isAvailable: false,
        price: 645,
        minNights: 14,
        maxNights: 30,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-04": {
        isAvailable: true,
        price: 313,
        minNights: 24,
        maxNights: 28,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-05": {
        isAvailable: true,
        price: 528,
        minNights: 3,
        maxNights: 21,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-06": {
        isAvailable: false,
        price: 236,
        minNights: 12,
        maxNights: 8,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-07": {
        isAvailable: true,
        price: 877,
        minNights: 11,
        maxNights: 20,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-08": {
        isAvailable: false,
        price: 734,
        minNights: 17,
        maxNights: 38,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-09": {
        isAvailable: true,
        price: 242,
        minNights: 29,
        maxNights: 16,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-10": {
        isAvailable: false,
        price: 897,
        minNights: 17,
        maxNights: 21,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-11": {
        isAvailable: true,
        price: 482,
        minNights: 14,
        maxNights: 30,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-12": {
        isAvailable: false,
        price: 455,
        minNights: 26,
        maxNights: 16,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-13": {
        isAvailable: true,
        price: 554,
        minNights: 21,
        maxNights: 11,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-14": {
        isAvailable: false,
        price: 623,
        minNights: 20,
        maxNights: 23,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-15": {
        isAvailable: true,
        price: 761,
        minNights: 26,
        maxNights: 37,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-16": {
        isAvailable: false,
        price: 72,
        minNights: 29,
        maxNights: 18,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-17": {
        isAvailable: true,
        price: 572,
        minNights: 20,
        maxNights: 10,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-18": {
        isAvailable: false,
        price: 332,
        minNights: 10,
        maxNights: 22,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-19": {
        isAvailable: true,
        price: 421,
        minNights: 11,
        maxNights: 33,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-20": {
        isAvailable: true,
        price: 486,
        minNights: 12,
        maxNights: 12,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-21": {
        isAvailable: false,
        price: 516,
        minNights: 8,
        maxNights: 24,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-22": {
        isAvailable: true,
        price: 98,
        minNights: 30,
        maxNights: 30,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-23": {
        isAvailable: false,
        price: 394,
        minNights: 33,
        maxNights: 10,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 632,
        minNights: 5,
        maxNights: 31,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-25": {
        isAvailable: false,
        price: 392,
        minNights: 9,
        maxNights: 36,
        noCheckin: true,
        noCheckout: false,
      },
    },
  },
  {
    id: 10,
    name: "Penthouse Luxe Champs-Élysées",
    address: "43 Avenue des Champs-Élysées, 75008 Paris, France",
    img: Rental5,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 572,
        minNights: 24,
        maxNights: 8,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-26": {
        isAvailable: false,
        price: 715,
        minNights: 35,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-27": {
        isAvailable: true,
        price: 186,
        minNights: 25,
        maxNights: 14,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-28": {
        isAvailable: true,
        price: 375,
        minNights: 20,
        maxNights: 16,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-29": {
        isAvailable: true,
        price: 945,
        minNights: 37,
        maxNights: 25,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-30": {
        isAvailable: false,
        price: 876,
        minNights: 17,
        maxNights: 25,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-31": {
        isAvailable: true,
        price: 567,
        minNights: 15,
        maxNights: 31,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-01": {
        isAvailable: true,
        price: 272,
        minNights: 22,
        maxNights: 15,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-02": {
        isAvailable: false,
        price: 652,
        minNights: 11,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-03": {
        isAvailable: true,
        price: 379,
        minNights: 9,
        maxNights: 40,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-04": {
        isAvailable: true,
        price: 456,
        minNights: 8,
        maxNights: 10,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-05": {
        isAvailable: false,
        price: 494,
        minNights: 26,
        maxNights: 20,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-06": {
        isAvailable: true,
        price: 889,
        minNights: 16,
        maxNights: 14,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-07": {
        isAvailable: true,
        price: 628,
        minNights: 8,
        maxNights: 22,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-08": {
        isAvailable: true,
        price: 93,
        minNights: 4,
        maxNights: 31,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-09": {
        isAvailable: false,
        price: 528,
        minNights: 5,
        maxNights: 15,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-10": {
        isAvailable: true,
        price: 652,
        minNights: 25,
        maxNights: 32,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-11": {
        isAvailable: true,
        price: 88,
        minNights: 12,
        maxNights: 6,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-12": {
        isAvailable: false,
        price: 443,
        minNights: 16,
        maxNights: 13,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-13": {
        isAvailable: false,
        price: 707,
        minNights: 28,
        maxNights: 10,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-14": {
        isAvailable: true,
        price: 561,
        minNights: 7,
        maxNights: 27,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-15": {
        isAvailable: true,
        price: 528,
        minNights: 23,
        maxNights: 16,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-16": {
        isAvailable: false,
        price: 688,
        minNights: 31,
        maxNights: 28,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-17": {
        isAvailable: true,
        price: 621,
        minNights: 6,
        maxNights: 29,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-18": {
        isAvailable: false,
        price: 681,
        minNights: 3,
        maxNights: 3,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-19": {
        isAvailable: true,
        price: 740,
        minNights: 14,
        maxNights: 34,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-20": {
        isAvailable: true,
        price: 253,
        minNights: 29,
        maxNights: 1,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-21": {
        isAvailable: false,
        price: 571,
        minNights: 22,
        maxNights: 29,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-22": {
        isAvailable: true,
        price: 468,
        minNights: 32,
        maxNights: 35,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-23": {
        isAvailable: true,
        price: 846,
        minNights: 23,
        maxNights: 14,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-24": {
        isAvailable: false,
        price: 376,
        minNights: 19,
        maxNights: 14,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-25": {
        isAvailable: true,
        price: 771,
        minNights: 21,
        maxNights: 20,
        noCheckin: true,
        noCheckout: true,
      },
    },
  },
  {
    id: 11,
    name: "Studio Soho Montparnasse",
    adresse: "18 Rue de Charenton, 75012 Paris, France",
    img: Rental1,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 520,
        minNights: 19,
        maxNights: 35,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-26": {
        isAvailable: false,
        price: 717,
        minNights: 20,
        maxNights: 23,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-27": {
        isAvailable: true,
        price: 482,
        minNights: 8,
        maxNights: 10,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-28": {
        isAvailable: true,
        price: 431,
        minNights: 22,
        maxNights: 10,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-29": {
        isAvailable: false,
        price: 628,
        minNights: 6,
        maxNights: 36,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-30": {
        isAvailable: true,
        price: 266,
        minNights: 29,
        maxNights: 3,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-31": {
        isAvailable: false,
        price: 243,
        minNights: 16,
        maxNights: 24,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-01": {
        isAvailable: true,
        price: 843,
        minNights: 13,
        maxNights: 19,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-02": {
        isAvailable: true,
        price: 811,
        minNights: 17,
        maxNights: 36,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-03": {
        isAvailable: false,
        price: 204,
        minNights: 5,
        maxNights: 19,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-04": {
        isAvailable: true,
        price: 813,
        minNights: 7,
        maxNights: 26,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-05": {
        isAvailable: true,
        price: 878,
        minNights: 10,
        maxNights: 25,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-06": {
        isAvailable: true,
        price: 87,
        minNights: 15,
        maxNights: 8,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-07": {
        isAvailable: false,
        price: 147,
        minNights: 31,
        maxNights: 3,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-08": {
        isAvailable: true,
        price: 824,
        minNights: 33,
        maxNights: 36,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-09": {
        isAvailable: false,
        price: 364,
        minNights: 15,
        maxNights: 6,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-10": {
        isAvailable: true,
        price: 271,
        minNights: 9,
        maxNights: 40,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-11": {
        isAvailable: true,
        price: 475,
        minNights: 21,
        maxNights: 9,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-12": {
        isAvailable: false,
        price: 577,
        minNights: 16,
        maxNights: 12,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-13": {
        isAvailable: false,
        price: 393,
        minNights: 13,
        maxNights: 21,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-14": {
        isAvailable: true,
        price: 900,
        minNights: 30,
        maxNights: 26,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-15": {
        isAvailable: true,
        price: 571,
        minNights: 15,
        maxNights: 14,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-16": {
        isAvailable: true,
        price: 53,
        minNights: 35,
        maxNights: 8,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-17": {
        isAvailable: false,
        price: 444,
        minNights: 26,
        maxNights: 13,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-18": {
        isAvailable: true,
        price: 864,
        minNights: 19,
        maxNights: 28,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-19": {
        isAvailable: true,
        price: 534,
        minNights: 10,
        maxNights: 24,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-20": {
        isAvailable: true,
        price: 869,
        minNights: 24,
        maxNights: 20,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-21": {
        isAvailable: false,
        price: 730,
        minNights: 7,
        maxNights: 40,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-22": {
        isAvailable: true,
        price: 960,
        minNights: 30,
        maxNights: 35,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-23": {
        isAvailable: false,
        price: 888,
        minNights: 3,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 465,
        minNights: 29,
        maxNights: 19,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-25": {
        isAvailable: true,
        price: 763,
        minNights: 17,
        maxNights: 40,
        noCheckin: false,
        noCheckout: true,
      },
    },
  },
  {
    id: 12,
    name: "Appartement Zen Vincennes",
    address: "36 Rue de Paris, 94120 Fontenay-sous-Bois, France",
    img: Rental2,
    days: {
      "2023-08-25": {
        isAvailable: false,
        price: 178,
        minNights: 28,
        maxNights: 28,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-26": {
        isAvailable: true,
        price: 792,
        minNights: 22,
        maxNights: 17,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-08-27": {
        isAvailable: true,
        price: 688,
        minNights: 12,
        maxNights: 38,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-28": {
        isAvailable: false,
        price: 688,
        minNights: 32,
        maxNights: 36,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-29": {
        isAvailable: false,
        price: 688,
        minNights: 6,
        maxNights: 36,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-30": {
        isAvailable: true,
        price: 688,
        minNights: 30,
        maxNights: 18,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-31": {
        isAvailable: false,
        price: 688,
        minNights: 7,
        maxNights: 25,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-01": {
        isAvailable: true,
        price: 688,
        minNights: 8,
        maxNights: 11,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-02": {
        isAvailable: false,
        price: 688,
        minNights: 27,
        maxNights: 28,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-03": {
        isAvailable: false,
        price: 688,
        minNights: 11,
        maxNights: 17,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-04": {
        isAvailable: true,
        price: 688,
        minNights: 17,
        maxNights: 36,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-05": {
        isAvailable: true,
        price: 688,
        minNights: 32,
        maxNights: 4,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-06": {
        isAvailable: true,
        price: 688,
        minNights: 17,
        maxNights: 27,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-07": {
        isAvailable: true,
        price: 688,
        minNights: 10,
        maxNights: 40,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-08": {
        isAvailable: false,
        price: 688,
        minNights: 13,
        maxNights: 7,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-09": {
        isAvailable: true,
        price: 688,
        minNights: 22,
        maxNights: 29,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-10": {
        isAvailable: true,
        price: 688,
        minNights: 7,
        maxNights: 13,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-11": {
        isAvailable: true,
        price: 688,
        minNights: 24,
        maxNights: 30,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-12": {
        isAvailable: false,
        price: 688,
        minNights: 29,
        maxNights: 24,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-13": {
        isAvailable: true,
        price: 688,
        minNights: 40,
        maxNights: 25,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-14": {
        isAvailable: true,
        price: 688,
        minNights: 38,
        maxNights: 8,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-15": {
        isAvailable: false,
        price: 688,
        minNights: 16,
        maxNights: 22,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-16": {
        isAvailable: false,
        price: 688,
        minNights: 23,
        maxNights: 14,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-17": {
        isAvailable: true,
        price: 688,
        minNights: 17,
        maxNights: 36,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-18": {
        isAvailable: true,
        price: 688,
        minNights: 24,
        maxNights: 20,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-19": {
        isAvailable: false,
        price: 688,
        minNights: 6,
        maxNights: 7,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-20": {
        isAvailable: true,
        price: 688,
        minNights: 19,
        maxNights: 5,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-21": {
        isAvailable: true,
        price: 688,
        minNights: 25,
        maxNights: 13,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-22": {
        isAvailable: true,
        price: 688,
        minNights: 6,
        maxNights: 19,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-23": {
        isAvailable: true,
        price: 688,
        minNights: 19,
        maxNights: 9,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 688,
        minNights: 31,
        maxNights: 4,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-25": {
        isAvailable: true,
        price: 688,
        minNights: 23,
        maxNights: 26,
        noCheckin: true,
        noCheckout: false,
      },
    },
  },
  {
    id: 13,
    name: "Appartement Loft République",
    address: "25 Avenue de Vincennes, 75020 Paris, France",
    img: Rental3,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 274,
        minNights: 9,
        maxNights: 25,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-26": {
        isAvailable: true,
        price: 487,
        minNights: 5,
        maxNights: 17,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-27": {
        isAvailable: true,
        price: 914,
        minNights: 26,
        maxNights: 17,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-28": {
        isAvailable: true,
        price: 823,
        minNights: 1,
        maxNights: 12,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-29": {
        isAvailable: false,
        price: 537,
        minNights: 32,
        maxNights: 6,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-08-30": {
        isAvailable: false,
        price: 532,
        minNights: 25,
        maxNights: 14,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-31": {
        isAvailable: false,
        price: 468,
        minNights: 17,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-01": {
        isAvailable: true,
        price: 765,
        minNights: 6,
        maxNights: 28,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-02": {
        isAvailable: true,
        price: 560,
        minNights: 18,
        maxNights: 3,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-03": {
        isAvailable: false,
        price: 641,
        minNights: 15,
        maxNights: 33,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-04": {
        isAvailable: false,
        price: 573,
        minNights: 1,
        maxNights: 36,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-05": {
        isAvailable: true,
        price: 447,
        minNights: 15,
        maxNights: 10,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-06": {
        isAvailable: true,
        price: 474,
        minNights: 25,
        maxNights: 25,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-07": {
        isAvailable: false,
        price: 500,
        minNights: 2,
        maxNights: 19,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-08": {
        isAvailable: false,
        price: 672,
        minNights: 32,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-09": {
        isAvailable: false,
        price: 379,
        minNights: 26,
        maxNights: 14,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-10": {
        isAvailable: true,
        price: 655,
        minNights: 25,
        maxNights: 16,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-11": {
        isAvailable: true,
        price: 956,
        minNights: 2,
        maxNights: 27,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-12": {
        isAvailable: true,
        price: 894,
        minNights: 25,
        maxNights: 4,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-13": {
        isAvailable: true,
        price: 395,
        minNights: 30,
        maxNights: 12,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-14": {
        isAvailable: false,
        price: 525,
        minNights: 28,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-15": {
        isAvailable: false,
        price: 579,
        minNights: 19,
        maxNights: 6,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-16": {
        isAvailable: true,
        price: 659,
        minNights: 22,
        maxNights: 6,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-17": {
        isAvailable: true,
        price: 780,
        minNights: 11,
        maxNights: 4,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-18": {
        isAvailable: true,
        price: 37,
        minNights: 23,
        maxNights: 12,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-19": {
        isAvailable: true,
        price: 994,
        minNights: 2,
        maxNights: 19,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-20": {
        isAvailable: false,
        price: 766,
        minNights: 29,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-21": {
        isAvailable: true,
        price: 776,
        minNights: 29,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-22": {
        isAvailable: false,
        price: 618,
        minNights: 15,
        maxNights: 20,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-23": {
        isAvailable: false,
        price: 592,
        minNights: 4,
        maxNights: 16,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 585,
        minNights: 16,
        maxNights: 21,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-25": {
        isAvailable: false,
        price: 900,
        minNights: 31,
        maxNights: 12,
        noCheckin: false,
        noCheckout: true,
      },
    },
  },
  {
    id: 14,
    name: "Maison Vintage Montreuil",
    address: "7 Rue de Montreuil, 93100 Montreuil, France",
    img: Rental4,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 837,
        minNights: 14,
        maxNights: 12,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-26": {
        isAvailable: false,
        price: 853,
        minNights: 4,
        maxNights: 24,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-27": {
        isAvailable: false,
        price: 915,
        minNights: 16,
        maxNights: 33,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-28": {
        isAvailable: true,
        price: 462,
        minNights: 20,
        maxNights: 5,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-29": {
        isAvailable: true,
        price: 653,
        minNights: 12,
        maxNights: 27,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-30": {
        isAvailable: false,
        price: 479,
        minNights: 16,
        maxNights: 35,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-31": {
        isAvailable: true,
        price: 710,
        minNights: 23,
        maxNights: 8,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-01": {
        isAvailable: false,
        price: 570,
        minNights: 31,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-02": {
        isAvailable: true,
        price: 785,
        minNights: 18,
        maxNights: 14,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-03": {
        isAvailable: false,
        price: 724,
        minNights: 3,
        maxNights: 33,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-04": {
        isAvailable: false,
        price: 875,
        minNights: 20,
        maxNights: 36,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-05": {
        isAvailable: true,
        price: 804,
        minNights: 11,
        maxNights: 11,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-06": {
        isAvailable: false,
        price: 586,
        minNights: 18,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-07": {
        isAvailable: true,
        price: 971,
        minNights: 6,
        maxNights: 15,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-08": {
        isAvailable: true,
        price: 491,
        minNights: 27,
        maxNights: 4,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-09": {
        isAvailable: false,
        price: 642,
        minNights: 31,
        maxNights: 11,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-10": {
        isAvailable: true,
        price: 637,
        minNights: 29,
        maxNights: 31,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-11": {
        isAvailable: true,
        price: 555,
        minNights: 25,
        maxNights: 37,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-12": {
        isAvailable: false,
        price: 661,
        minNights: 17,
        maxNights: 13,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-13": {
        isAvailable: false,
        price: 872,
        minNights: 28,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-14": {
        isAvailable: true,
        price: 641,
        minNights: 11,
        maxNights: 12,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-15": {
        isAvailable: false,
        price: 530,
        minNights: 8,
        maxNights: 0,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-16": {
        isAvailable: false,
        price: 810,
        minNights: 10,
        maxNights: 32,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-17": {
        isAvailable: true,
        price: 498,
        minNights: 20,
        maxNights: 28,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-18": {
        isAvailable: true,
        price: 97,
        minNights: 4,
        maxNights: 40,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-19": {
        isAvailable: false,
        price: 699,
        minNights: 29,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-20": {
        isAvailable: true,
        price: 640,
        minNights: 6,
        maxNights: 21,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-21": {
        isAvailable: true,
        price: 587,
        minNights: 19,
        maxNights: 21,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-22": {
        isAvailable: true,
        price: 207,
        minNights: 16,
        maxNights: 28,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-23": {
        isAvailable: true,
        price: 207,
        minNights: 6,
        maxNights: 26,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-24": {
        isAvailable: false,
        price: 453,
        minNights: 24,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-25": {
        isAvailable: true,
        price: 718,
        minNights: 12,
        maxNights: 18,
        noCheckin: true,
        noCheckout: false,
      },
    },
  },
  {
    id: 15,
    name: "Appartement Chic Neuilly",
    address: "28 Boulevard de Neuilly, 92200 Neuilly-sur-Seine, France",
    img: Rental5,
    days: {
      "2023-08-25": {
        isAvailable: false,
        price: 562,
        minNights: 22,
        maxNights: 7,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-26": {
        isAvailable: true,
        price: 403,
        minNights: 18,
        maxNights: 32,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-27": {
        isAvailable: false,
        price: 772,
        minNights: 5,
        maxNights: 17,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-28": {
        isAvailable: true,
        price: 856,
        minNights: 21,
        maxNights: 38,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-29": {
        isAvailable: true,
        price: 93,
        minNights: 19,
        maxNights: 11,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-30": {
        isAvailable: false,
        price: 998,
        minNights: 17,
        maxNights: 7,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-31": {
        isAvailable: true,
        price: 587,
        minNights: 21,
        maxNights: 26,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-01": {
        isAvailable: true,
        price: 743,
        minNights: 5,
        maxNights: 27,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-02": {
        isAvailable: false,
        price: 66,
        minNights: 25,
        maxNights: 9,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-03": {
        isAvailable: true,
        price: 933,
        minNights: 26,
        maxNights: 40,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-04": {
        isAvailable: true,
        price: 563,
        minNights: 19,
        maxNights: 8,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-05": {
        isAvailable: false,
        price: 562,
        minNights: 22,
        maxNights: 7,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-06": {
        isAvailable: true,
        price: 899,
        minNights: 3,
        maxNights: 24,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-07": {
        isAvailable: false,
        price: 165,
        minNights: 7,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-08": {
        isAvailable: false,
        price: 711,
        minNights: 18,
        maxNights: 23,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-09": {
        isAvailable: true,
        price: 894,
        minNights: 27,
        maxNights: 28,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-10": {
        isAvailable: true,
        price: 691,
        minNights: 22,
        maxNights: 30,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-11": {
        isAvailable: false,
        price: 638,
        minNights: 22,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-12": {
        isAvailable: true,
        price: 805,
        minNights: 20,
        maxNights: 33,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-13": {
        isAvailable: false,
        price: 769,
        minNights: 12,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-14": {
        isAvailable: true,
        price: 502,
        minNights: 6,
        maxNights: 23,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-15": {
        isAvailable: false,
        price: 827,
        minNights: 18,
        maxNights: 0,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-16": {
        isAvailable: true,
        price: 826,
        minNights: 16,
        maxNights: 25,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-17": {
        isAvailable: false,
        price: 919,
        minNights: 16,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-18": {
        isAvailable: true,
        price: 662,
        minNights: 11,
        maxNights: 32,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-19": {
        isAvailable: true,
        price: 838,
        minNights: 27,
        maxNights: 16,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-20": {
        isAvailable: false,
        price: 884,
        minNights: 17,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-21": {
        isAvailable: true,
        price: 954,
        minNights: 7,
        maxNights: 23,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-22": {
        isAvailable: false,
        price: 948,
        minNights: 24,
        maxNights: 0,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-23": {
        isAvailable: true,
        price: 398,
        minNights: 9,
        maxNights: 30,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-24": {
        isAvailable: false,
        price: 48,
        minNights: 9,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-25": {
        isAvailable: true,
        price: 453,
        minNights: 22,
        maxNights: 27,
        noCheckin: true,
        noCheckout: false,
      },
    },
  },
  {
    id: 16,
    name: "Studio Lumineux Levallois",
    address: "10 Rue de Levallois, 92300 Levallois-Perret, France",
    img: Rental1,
    days: {
      "2023-08-25": {
        isAvailable: false,
        price: 517,
        minNights: 26,
        maxNights: 27,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-26": {
        isAvailable: true,
        price: 950,
        minNights: 4,
        maxNights: 13,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-27": {
        isAvailable: true,
        price: 578,
        minNights: 5,
        maxNights: 35,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-28": {
        isAvailable: false,
        price: 885,
        minNights: 23,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-29": {
        isAvailable: false,
        price: 203,
        minNights: 25,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-30": {
        isAvailable: true,
        price: 37,
        minNights: 2,
        maxNights: 11,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-31": {
        isAvailable: false,
        price: 495,
        minNights: 10,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-01": {
        isAvailable: true,
        price: 610,
        minNights: 16,
        maxNights: 33,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-02": {
        isAvailable: true,
        price: 879,
        minNights: 20,
        maxNights: 34,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-03": {
        isAvailable: true,
        price: 265,
        minNights: 1,
        maxNights: 38,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-04": {
        isAvailable: true,
        price: 240,
        minNights: 15,
        maxNights: 22,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-05": {
        isAvailable: false,
        price: 781,
        minNights: 29,
        maxNights: 0,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-06": {
        isAvailable: false,
        price: 525,
        minNights: 30,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-07": {
        isAvailable: false,
        price: 262,
        minNights: 22,
        maxNights: 0,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-08": {
        isAvailable: true,
        price: 203,
        minNights: 8,
        maxNights: 38,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-09": {
        isAvailable: true,
        price: 790,
        minNights: 29,
        maxNights: 30,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-10": {
        isAvailable: true,
        price: 707,
        minNights: 25,
        maxNights: 20,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-11": {
        isAvailable: false,
        price: 283,
        minNights: 19,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-12": {
        isAvailable: true,
        price: 707,
        minNights: 17,
        maxNights: 25,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-13": {
        isAvailable: false,
        price: 943,
        minNights: 29,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-14": {
        isAvailable: true,
        price: 473,
        minNights: 8,
        maxNights: 17,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-15": {
        isAvailable: true,
        price: 181,
        minNights: 19,
        maxNights: 36,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-16": {
        isAvailable: false,
        price: 428,
        minNights: 12,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-17": {
        isAvailable: false,
        price: 907,
        minNights: 18,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-18": {
        isAvailable: true,
        price: 830,
        minNights: 22,
        maxNights: 39,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-19": {
        isAvailable: true,
        price: 274,
        minNights: 20,
        maxNights: 34,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-20": {
        isAvailable: true,
        price: 75,
        minNights: 10,
        maxNights: 26,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-21": {
        isAvailable: false,
        price: 870,
        minNights: 20,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-22": {
        isAvailable: true,
        price: 768,
        minNights: 22,
        maxNights: 40,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-23": {
        isAvailable: false,
        price: 694,
        minNights: 5,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 49,
        minNights: 2,
        maxNights: 9,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-25": {
        isAvailable: false,
        price: 398,
        minNights: 29,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
    },
  },
  {
    id: 17,
    name: "Appartement Modern Marne-la-Vallée",
    address: "6 Avenue des Lilas, 93500 Marne-la-Vallée, France",
    img: Rental2,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 425,
        minNights: 10,
        maxNights: 25,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-26": {
        isAvailable: false,
        price: 685,
        minNights: 9,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-27": {
        isAvailable: true,
        price: 689,
        minNights: 4,
        maxNights: 30,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-28": {
        isAvailable: false,
        price: 983,
        minNights: 27,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-29": {
        isAvailable: false,
        price: 635,
        minNights: 30,
        maxNights: 0,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-08-30": {
        isAvailable: true,
        price: 212,
        minNights: 1,
        maxNights: 39,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-31": {
        isAvailable: true,
        price: 45,
        minNights: 12,
        maxNights: 21,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-01": {
        isAvailable: false,
        price: 873,
        minNights: 14,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-02": {
        isAvailable: true,
        price: 413,
        minNights: 18,
        maxNights: 26,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-03": {
        isAvailable: true,
        price: 820,
        minNights: 7,
        maxNights: 32,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-04": {
        isAvailable: false,
        price: 390,
        minNights: 21,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-05": {
        isAvailable: false,
        price: 570,
        minNights: 18,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-06": {
        isAvailable: true,
        price: 412,
        minNights: 12,
        maxNights: 25,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-07": {
        isAvailable: true,
        price: 154,
        minNights: 14,
        maxNights: 38,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-08": {
        isAvailable: false,
        price: 605,
        minNights: 9,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-09": {
        isAvailable: true,
        price: 365,
        minNights: 2,
        maxNights: 21,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-10": {
        isAvailable: false,
        price: 122,
        minNights: 27,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-11": {
        isAvailable: true,
        price: 703,
        minNights: 23,
        maxNights: 34,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-12": {
        isAvailable: true,
        price: 145,
        minNights: 13,
        maxNights: 36,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-13": {
        isAvailable: false,
        price: 90,
        minNights: 5,
        maxNights: 0,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-14": {
        isAvailable: true,
        price: 456,
        minNights: 12,
        maxNights: 23,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-15": {
        isAvailable: false,
        price: 122,
        minNights: 18,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-16": {
        isAvailable: false,
        price: 534,
        minNights: 14,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-17": {
        isAvailable: true,
        price: 95,
        minNights: 5,
        maxNights: 38,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-18": {
        isAvailable: true,
        price: 385,
        minNights: 10,
        maxNights: 22,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-19": {
        isAvailable: false,
        price: 534,
        minNights: 14,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-20": {
        isAvailable: true,
        price: 408,
        minNights: 5,
        maxNights: 28,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-21": {
        isAvailable: false,
        price: 236,
        minNights: 9,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-22": {
        isAvailable: false,
        price: 295,
        minNights: 12,
        maxNights: 0,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-23": {
        isAvailable: true,
        price: 138,
        minNights: 5,
        maxNights: 32,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 46,
        minNights: 3,
        maxNights: 35,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-25": {
        isAvailable: false,
        price: 221,
        minNights: 28,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
    },
  },
  {
    id: 18,
    name: "Loft Industriel Père Lachaise",
    address: "18 Rue de Joffre, 75012 Paris, France",
    img: Rental3,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 475,
        minNights: 15,
        maxNights: 30,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-26": {
        isAvailable: false,
        price: 840,
        minNights: 8,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-27": {
        isAvailable: true,
        price: 765,
        minNights: 5,
        maxNights: 35,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-28": {
        isAvailable: false,
        price: 985,
        minNights: 28,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-29": {
        isAvailable: true,
        price: 315,
        minNights: 2,
        maxNights: 40,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-08-30": {
        isAvailable: false,
        price: 178,
        minNights: 1,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-31": {
        isAvailable: true,
        price: 69,
        minNights: 12,
        maxNights: 20,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-01": {
        isAvailable: false,
        price: 920,
        minNights: 14,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-02": {
        isAvailable: true,
        price: 529,
        minNights: 19,
        maxNights: 30,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-03": {
        isAvailable: true,
        price: 710,
        minNights: 7,
        maxNights: 36,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-04": {
        isAvailable: false,
        price: 425,
        minNights: 22,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-05": {
        isAvailable: false,
        price: 645,
        minNights: 17,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-06": {
        isAvailable: true,
        price: 316,
        minNights: 11,
        maxNights: 28,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-07": {
        isAvailable: true,
        price: 165,
        minNights: 14,
        maxNights: 37,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-08": {
        isAvailable: false,
        price: 550,
        minNights: 9,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-09": {
        isAvailable: true,
        price: 377,
        minNights: 2,
        maxNights: 20,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-10": {
        isAvailable: false,
        price: 130,
        minNights: 25,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-11": {
        isAvailable: true,
        price: 695,
        minNights: 24,
        maxNights: 32,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-12": {
        isAvailable: true,
        price: 142,
        minNights: 13,
        maxNights: 34,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-13": {
        isAvailable: false,
        price: 100,
        minNights: 6,
        maxNights: 0,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-14": {
        isAvailable: true,
        price: 476,
        minNights: 12,
        maxNights: 25,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-15": {
        isAvailable: false,
        price: 120,
        minNights: 19,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-16": {
        isAvailable: false,
        price: 560,
        minNights: 14,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-17": {
        isAvailable: true,
        price: 88,
        minNights: 5,
        maxNights: 38,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-18": {
        isAvailable: true,
        price: 420,
        minNights: 11,
        maxNights: 22,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-19": {
        isAvailable: false,
        price: 530,
        minNights: 13,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-20": {
        isAvailable: true,
        price: 398,
        minNights: 5,
        maxNights: 28,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-21": {
        isAvailable: false,
        price: 260,
        minNights: 10,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-22": {
        isAvailable: false,
        price: 320,
        minNights: 12,
        maxNights: 0,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-23": {
        isAvailable: true,
        price: 158,
        minNights: 5,
        maxNights: 32,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 53,
        minNights: 3,
        maxNights: 35,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-25": {
        isAvailable: false,
        price: 240,
        minNights: 27,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
    },
  },
  {
    id: 19,
    name: "Appartement Bois de Boulogne",
    address: "15 Avenue de Vincennes, 75012 Paris, France",
    img: Rental4,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 463,
        minNights: 14,
        maxNights: 29,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-26": {
        isAvailable: false,
        price: 735,
        minNights: 7,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-27": {
        isAvailable: true,
        price: 643,
        minNights: 4,
        maxNights: 34,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-28": {
        isAvailable: false,
        price: 920,
        minNights: 27,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-29": {
        isAvailable: true,
        price: 288,
        minNights: 1,
        maxNights: 39,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-08-30": {
        isAvailable: false,
        price: 145,
        minNights: 2,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-31": {
        isAvailable: true,
        price: 79,
        minNights: 11,
        maxNights: 19,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-01": {
        isAvailable: false,
        price: 870,
        minNights: 13,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-02": {
        isAvailable: true,
        price: 503,
        minNights: 18,
        maxNights: 29,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-03": {
        isAvailable: true,
        price: 684,
        minNights: 6,
        maxNights: 35,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-04": {
        isAvailable: false,
        price: 405,
        minNights: 21,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-05": {
        isAvailable: false,
        price: 624,
        minNights: 16,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-06": {
        isAvailable: true,
        price: 302,
        minNights: 10,
        maxNights: 27,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-07": {
        isAvailable: true,
        price: 158,
        minNights: 13,
        maxNights: 36,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-08": {
        isAvailable: false,
        price: 543,
        minNights: 8,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-09": {
        isAvailable: true,
        price: 360,
        minNights: 1,
        maxNights: 19,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-10": {
        isAvailable: false,
        price: 119,
        minNights: 24,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-11": {
        isAvailable: true,
        price: 676,
        minNights: 23,
        maxNights: 31,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-12": {
        isAvailable: true,
        price: 139,
        minNights: 12,
        maxNights: 33,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-13": {
        isAvailable: false,
        price: 96,
        minNights: 5,
        maxNights: 0,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-14": {
        isAvailable: true,
        price: 464,
        minNights: 11,
        maxNights: 24,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-15": {
        isAvailable: false,
        price: 116,
        minNights: 18,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-16": {
        isAvailable: false,
        price: 560,
        minNights: 13,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-17": {
        isAvailable: true,
        price: 85,
        minNights: 4,
        maxNights: 37,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-18": {
        isAvailable: true,
        price: 406,
        minNights: 10,
        maxNights: 21,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-19": {
        isAvailable: false,
        price: 518,
        minNights: 12,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-20": {
        isAvailable: true,
        price: 388,
        minNights: 4,
        maxNights: 27,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-21": {
        isAvailable: false,
        price: 268,
        minNights: 9,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-22": {
        isAvailable: true,
        price: 718,
        minNights: 7,
        maxNights: 40,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-23": {
        isAvailable: true,
        price: 175,
        minNights: 3,
        maxNights: 38,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 521,
        minNights: 14,
        maxNights: 22,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-25": {
        isAvailable: false,
        price: 657,
        minNights: 10,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
    },
  },
  {
    id: 20,
    name: "Duplex Romantique Montsouris",
    address: "45 Avenue des Lilas, 93170 Bagnolet, France",
    img: Rental5,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 673,
        minNights: 9,
        maxNights: 28,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-26": {
        isAvailable: true,
        price: 482,
        minNights: 10,
        maxNights: 21,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-27": {
        isAvailable: false,
        price: 793,
        minNights: 5,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-28": {
        isAvailable: true,
        price: 559,
        minNights: 7,
        maxNights: 32,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-29": {
        isAvailable: true,
        price: 78,
        minNights: 15,
        maxNights: 24,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-30": {
        isAvailable: false,
        price: 467,
        minNights: 3,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-31": {
        isAvailable: true,
        price: 237,
        minNights: 13,
        maxNights: 28,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-01": {
        isAvailable: true,
        price: 832,
        minNights: 8,
        maxNights: 40,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-02": {
        isAvailable: false,
        price: 42,
        minNights: 1,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-03": {
        isAvailable: true,
        price: 685,
        minNights: 14,
        maxNights: 33,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-04": {
        isAvailable: true,
        price: 243,
        minNights: 6,
        maxNights: 39,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-05": {
        isAvailable: false,
        price: 914,
        minNights: 2,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-06": {
        isAvailable: true,
        price: 129,
        minNights: 11,
        maxNights: 26,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-07": {
        isAvailable: true,
        price: 634,
        minNights: 4,
        maxNights: 35,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-08": {
        isAvailable: false,
        price: 197,
        minNights: 7,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-09": {
        isAvailable: true,
        price: 594,
        minNights: 2,
        maxNights: 23,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-10": {
        isAvailable: true,
        price: 569,
        minNights: 9,
        maxNights: 30,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-11": {
        isAvailable: false,
        price: 766,
        minNights: 13,
        maxNights: 0,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-12": {
        isAvailable: true,
        price: 344,
        minNights: 5,
        maxNights: 38,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-13": {
        isAvailable: true,
        price: 687,
        minNights: 6,
        maxNights: 36,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-14": {
        isAvailable: false,
        price: 77,
        minNights: 8,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-15": {
        isAvailable: true,
        price: 374,
        minNights: 3,
        maxNights: 26,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-16": {
        isAvailable: true,
        price: 670,
        minNights: 12,
        maxNights: 24,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-17": {
        isAvailable: false,
        price: 871,
        minNights: 5,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-18": {
        isAvailable: true,
        price: 198,
        minNights: 9,
        maxNights: 28,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-19": {
        isAvailable: true,
        price: 412,
        minNights: 10,
        maxNights: 30,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-20": {
        isAvailable: false,
        price: 521,
        minNights: 7,
        maxNights: 0,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-21": {
        isAvailable: true,
        price: 636,
        minNights: 4,
        maxNights: 39,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-22": {
        isAvailable: true,
        price: 152,
        minNights: 11,
        maxNights: 35,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-23": {
        isAvailable: false,
        price: 868,
        minNights: 6,
        maxNights: 0,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 293,
        minNights: 12,
        maxNights: 31,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-25": {
        isAvailable: true,
        price: 487,
        minNights: 14,
        maxNights: 26,
        noCheckin: true,
        noCheckout: true,
      },
    },
  },
  {
    id: 21,
    name: "Villa Luxueuse Nice",
    address: "8 Promenade des Anglais, 06000 Nice, France",
    img: Rental1,
  },
  {
    id: 22,
    name: "Appartement Rétro Pigalle",
    address: "45 Avenue des Lilas, 93170 Bagnolet, France",
    img: Rental2,
  },
  {
    id: 23,
    name: "Appartement Charmant Courbevoie",
    address: "45 Rue de la Charme, 92400 Courbevoie, France",
    img: Rental3,
  },
  {
    id: 24,
    name: "Studio Créatif Montparnasse",
    address: "28 Rue Montparnasse, 75014 Paris, France",
    img: Rental4,
  },
  {
    id: 25,
    name: "Appartement Vue Seine",
    address: "12 Quai de la Seine, 75019 Paris, France",
    img: Rental5,
  },
  {
    id: 26,
    name: "Appartement Cosy Saint-Denis",
    address: "15 Rue Cosy, 93200 Saint-Denis, France",
    img: Rental1,
  },
  {
    id: 27,
    name: "Loft Élégant Le Marais",
    address: "35 Rue du Marais, 75004 Paris, France",
    img: Rental2,
  },
  {
    id: 28,
    name: "Appartement Moderne Lyon",
    address: "24 Rue Moderne, 69001 Lyon, France",
    img: Rental3,
  },
  {
    id: 29,
    name: "Appartement Vintage Versailles",
    img: Rental4,
  },
  {
    id: 30,
    name: "Maison de Ville Aix-en-Provence",
    address: "10 Rue Vintage, 78000 Versailles, France",
    img: Rental5,
  },
  {
    id: 31,
    name: "Studio Design Belleville",
    address: "7 Rue du Design, 75020 Paris, France",
    img: Rental1,
  },
  {
    id: 32,
    name: "Appartement Chaleureux Cannes",
    address: "15 Rue Chaleureuse, 06400 Cannes, France",
    img: Rental2,
  },
  {
    id: 33,
    name: "Duplex Paisible Montmartre",
    address: "18 Rue Paisible, 75018 Paris, France",
    img: Rental3,
  },
  {
    id: 34,
    name: "Appartement Art Déco Neuilly",
    address: "38 Rue de l'Art Déco, 92200 Neuilly-sur-Seine, France",
    img: Rental4,
  },
  {
    id: 35,
    name: "Appartement Vintage Levallois",
    address: "22 Rue Vintage, 92300 Levallois-Perret, France",
    img: Rental5,
  },
  {
    id: 36,
    name: "Appartement Lumière Marseille",
    address: "42 Rue de la Lumière, 13002 Marseille, France",
    img: Rental1,
  },
  {
    id: 37,
    name: "Villa Tranquille Antibes",
    address: "20 Rue de la Tranquillité, 06600 Antibes, France",
    img: Rental2,
  },
  {
    id: 38,
    name: "Villa Saint_Pierre Colmar",
    address: "20 Boulevard Saint-Pierre, 68000 Colmar, France",
    img: Rental2,
  },
  {
    id: 39,
    name: "Loft Spacieux Montreuil",
    address: "30 Rue Spacieuse, 93100 Montreuil, France",
    img: Rental4,
  },
  {
    id: 40,
    name: "Appartement Chic Bordeaux",
    address: "25 Rue Chic, 33000 Bordeaux, France",
    img: Rental5,
  },
  {
    id: 41,
    name: "Appartement Vue sur Mer",
    address: "8 Boulevard de la Mer, 06000 Nice, France",
    img: Rental1,
  },
  {
    id: 42,
    name: "Studio Paisible Montparnasse",
    img: Rental2,
    address: "14 Rue Paisible, 75014 Paris, France",
  },
  {
    id: 43,
    name: "Appartement Charme Toulouse",
    img: Rental3,
    address: "20 Rue du Charme, 31000 Toulouse, France",
  },
  {
    id: 44,
    name: "Appartement Boisé Lyon",
    img: Rental4,
    address: "30 Rue Boisée, 69000 Lyon, France",
  },
  {
    id: 45,
    name: "Duplex Cosy Marais",
    img: Rental5,
    address: "25 Rue du Marais, 75004 Paris, France",
  },
  {
    id: 46,
    name: "Appartement Lumineux Lille",
    img: Rental1,
    address: "10 Rue Lumineuse, 59000 Lille, France",
  },
  {
    id: 47,
    name: "Appartement Moderne Nantes",
    img: Rental2,
    address: "18 Rue Moderne, 44000 Nantes, France",
  },
  {
    id: 48,
    name: "Appartement Vintage Nice",
    img: Rental3,
    address: "22 Rue Vintage, 06000 Nice, France",
  },
  {
    id: 49,
    name: "Studio Créatif Strasbourg",
    img: Rental4,
    address: "15 Rue Créative, 67000 Strasbourg, France",
  },
  {
    id: 50,
    name: "Loft Élégant Cannes",
    img: Rental5,
    address: "10 Rue Élégante, 06400 Cannes, France",
  },
  {
    id: 51,
    name: "Appartement Vintage Bastille",
    address: "32 rue du Vintage, 75014 Paris, France",
    img: Rental3,
    days: {
      "2023-08-25": {
        isAvailable: true,
        price: 794,
        minNights: 33,
        maxNights: 10,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-08-26": {
        isAvailable: false,
        price: 770,
        minNights: 19,
        maxNights: 29,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-27": {
        isAvailable: true,
        price: 508,
        minNights: 12,
        maxNights: 17,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-28": {
        isAvailable: true,
        price: 53,
        minNights: 4,
        maxNights: 23,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-08-29": {
        isAvailable: false,
        price: 830,
        minNights: 23,
        maxNights: 36,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-08-30": {
        isAvailable: false,
        price: 838,
        minNights: 14,
        maxNights: 16,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-08-31": {
        isAvailable: false,
        price: 506,
        minNights: 27,
        maxNights: 16,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-01": {
        isAvailable: true,
        price: 137,
        minNights: 24,
        maxNights: 8,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-02": {
        isAvailable: true,
        price: 215,
        minNights: 3,
        maxNights: 25,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-03": {
        isAvailable: false,
        price: 508,
        minNights: 17,
        maxNights: 20,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-04": {
        isAvailable: true,
        price: 800,
        minNights: 19,
        maxNights: 7,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-05": {
        isAvailable: false,
        price: 485,
        minNights: 10,
        maxNights: 9,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-06": {
        isAvailable: true,
        price: 191,
        minNights: 26,
        maxNights: 16,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-07": {
        isAvailable: false,
        price: 256,
        minNights: 35,
        maxNights: 29,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-08": {
        isAvailable: true,
        price: 685,
        minNights: 1,
        maxNights: 2,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-09": {
        isAvailable: false,
        price: 742,
        minNights: 33,
        maxNights: 1,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-10": {
        isAvailable: false,
        price: 699,
        minNights: 20,
        maxNights: 29,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-11": {
        isAvailable: true,
        price: 442,
        minNights: 38,
        maxNights: 30,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-12": {
        isAvailable: true,
        price: 129,
        minNights: 13,
        maxNights: 20,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-13": {
        isAvailable: false,
        price: 700,
        minNights: 14,
        maxNights: 3,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-14": {
        isAvailable: false,
        price: 365,
        minNights: 11,
        maxNights: 8,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-15": {
        isAvailable: true,
        price: 37,
        minNights: 2,
        maxNights: 3,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-16": {
        isAvailable: false,
        price: 643,
        minNights: 26,
        maxNights: 15,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-17": {
        isAvailable: true,
        price: 615,
        minNights: 7,
        maxNights: 0,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-18": {
        isAvailable: false,
        price: 816,
        minNights: 30,
        maxNights: 24,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-19": {
        isAvailable: false,
        price: 614,
        minNights: 5,
        maxNights: 19,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-20": {
        isAvailable: false,
        price: 523,
        minNights: 29,
        maxNights: 33,
        noCheckin: true,
        noCheckout: false,
      },
      "2023-09-21": {
        isAvailable: true,
        price: 609,
        minNights: 10,
        maxNights: 36,
        noCheckin: false,
        noCheckout: false,
      },
      "2023-09-22": {
        isAvailable: false,
        price: 663,
        minNights: 11,
        maxNights: 18,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-23": {
        isAvailable: false,
        price: 869,
        minNights: 21,
        maxNights: 29,
        noCheckin: true,
        noCheckout: true,
      },
      "2023-09-24": {
        isAvailable: true,
        price: 211,
        minNights: 27,
        maxNights: 12,
        noCheckin: false,
        noCheckout: true,
      },
      "2023-09-25": {
        isAvailable: true,
        price: 249,
        minNights: 33,
        maxNights: 31,
        noCheckin: true,
        noCheckout: false,
      },
    },
  },
  {
    id: 52,
    name: "Loft Élégant Cannes",
    img: Rental1,
    address: "10 Rue Élégante, 06400 Cannes, France",
  },
  {
    id: 53,
    name: "Appartement de Luxe Paris",
    img: Rental2,
    address: "15 Avenue de Luxe, 75016 Paris, France",
  },
  {
    id: 54,
    name: "Villa Vue sur Mer Nice",
    img: Rental4,
    address: "28 Boulevard de la Mer, 06000 Nice, France",
  },
  {
    id: 55,
    name: "Chalet Cosy Chamonix",
    img: Rental5,
    address: "12 Rue Cosy, 74400 Chamonix-Mont-Blanc, France",
  },
  {
    id: 56,
    name: "Maison de Charme Bordeaux",
    img: Rental2,
    address: "22 Rue Charmante, 33000 Bordeaux, France",
  },
  {
    id: 57,
    name: "Studio Moderne Lyon",
    img: Rental3,
    address: "18 Rue Moderne, 69001 Lyon, France",
  },
  {
    id: 58,
    name: "Penthouse de Prestige Marseille",
    img: Rental4,
    address: "30 Boulevard de Prestige, 13008 Marseille, France",
  },
  {
    id: 59,
    name: "Cottage Romantique Toulouse",
    img: Rental1,
    address: "5 Rue Romantique, 31000 Toulouse, France",
  },
  {
    id: 60,
    name: "Maison de Plage Biarritz",
    img: Rental2,
    address: "14 Avenue de la Plage, 64200 Biarritz, France",
  },
  {
    id: 61,
    name: "Appartement Historique Nantes",
    img: Rental3,
    address: "22 Rue Historique, 44000 Nantes, France",
  },
  {
    id: 62,
    name: "Chalet de Montagne Grenoble",
    img: Rental2,
    address: "35 Rue de Montagne, 38000 Grenoble, France",
  },
  {
    id: 63,
    name: "Villa de Luxe Nice",
    img: Rental3,
    address: "40 Boulevard de Luxe, 06000 Nice, France",
  },
  {
    id: 64,
    name: "Loft Design Montpellier",
    img: Rental4,
    address: "18 Rue du Design, 34000 Montpellier, France",
  },
  {
    id: 65,
    name: "Appartement Spacieux Lyon",
    img: Rental5,
    address: "30 Avenue Spacieuse, 69003 Lyon, France",
  },
  {
    id: 66,
    name: "Maison de Ville Avignon",
    img: Rental1,
    address: "12 Rue de la Ville, 84000 Avignon, France",
  },
  {
    id: 67,
    name: "Penthouse de Luxe Cannes",
    img: Rental3,
    address: "15 Boulevard de Luxe, 06400 Cannes, France",
  },
  {
    id: 68,
    name: "Villa en Bord de Mer Marseille",
    img: Rental4,
    address: "22 Avenue de la Mer, 13008 Marseille, France",
  },
  {
    id: 69,
    name: "Appartement de Charme Strasbourg",
    img: Rental2,
    address: "10 Rue de Charme, 67000 Strasbourg, France",
  },
  {
    id: 70,
    name: "Loft Artistique Paris",
    img: Rental3,
    address: "28 Rue Artistique, 75018 Paris, France",
  },
  {
    id: 71,
    name: "Maison de Campagne Dijon",
    img: Rental4,
    address: "20 Rue de la Campagne, 21000 Dijon, France",
  },
  {
    id: 72,
    name: "Appartement Vue sur Mer Nice",
    img: Rental2,
    address: "8 Boulevard de la Mer, 06000 Nice, France",
  },
  {
    id: 73,
    name: "Villa de Montagne Grenoble",
    img: Rental3,
    address: "15 Rue de Montagne, 38000 Grenoble, France",
  },
  {
    id: 74,
    name: "Chalet de Luxe Chamonix",
    img: Rental4,
    address: "30 Rue de Luxe, 74400 Chamonix-Mont-Blanc, France",
  },
  {
    id: 75,
    name: "Studio Cosy Bordeaux",
    img: Rental5,
    address: "12 Rue Cosy, 33000 Bordeaux, France",
  },
  {
    id: 76,
    name: "Maison de Vacances Toulouse",
    img: Rental2,
    address: "25 Rue de Vacances, 31000 Toulouse, France",
  },
  {
    id: 77,
    name: "Penthouse Élégant Lyon",
    img: Rental5,
    address: "18 Rue Élégante, 69002 Lyon, France",
  },
  {
    id: 78,
    name: "Appartement de Luxe Nantes",
    img: Rental3,
    address: "22 Avenue de Luxe, 44000 Nantes, France",
  },
  {
    id: 79,
    name: "Villa avec Piscine Montpellier",
    img: Rental4,
    address: "40 Rue de la Piscine, 34000 Montpellier, France",
  },
  {
    id: 80,
    name: "Loft Moderne Marseille",
    img: Rental1,
    address: "28 Rue Moderne, 13008 Marseille, France",
  },
  {
    id: 81,
    name: "Appartement Historique Nice",
    img: Rental3,
    address: "22 Rue Historique, 06000 Nice, France",
  },
  {
    id: 82,
    name: "Maison de Campagne Bordeaux",
    img: Rental2,
    address: "15 Rue de la Campagne, 33000 Bordeaux, France",
  },
  {
    id: 83,
    name: "Chalet de Montagne Cannes",
    img: Rental3,
    address: "10 Rue de Montagne, 06400 Cannes, France",
  },
  {
    id: 84,
    name: "Penthouse de Prestige Paris",
    img: Rental4,
    address: "30 Boulevard de Prestige, 75016 Paris, France",
  },
  {
    id: 85,
    name: "Studio Cosy Lyon",
    img: Rental5,
    address: "18 Rue Cosy, 69002 Lyon, France",
  },
  {
    id: 86,
    name: "Maison de Ville Marseille",
    img: Rental1,
    address: "12 Rue de la Ville, 13008 Marseille, France",
  },
  {
    id: 87,
    name: "Villa en Bord de Mer Nice",
    img: Rental3,
    address: "8 Avenue de la Mer, 06000 Nice, France",
  },
  {
    id: 88,
    name: "Appartement de Charme Strasbourg",
    img: Rental2,
    address: "25 Rue de Charme, 67000 Strasbourg, France",
  },
  {
    id: 89,
    name: "Loft Artistique Paris",
    img: Rental3,
    address: "28 Rue Artistique, 75018 Paris, France",
  },
  {
    id: 90,
    name: "Maison de Campagne Dijon",
    img: Rental4,
    address: "20 Rue de la Campagne, 21000 Dijon, France",
  },
  {
    id: 91,
    name: "Appartement Vue sur Mer Nice",
    img: Rental1,
    address: "10 Boulevard de la Mer, 06000 Nice, France",
  },
  {
    id: 92,
    name: "Villa de Montagne Grenoble",
    img: Rental2,
    address: "15 Rue de Montagne, 38000 Grenoble, France",
  },
  {
    id: 93,
    name: "Chalet de Luxe Chamonix",
    img: Rental3,
    address: "30 Rue de Luxe, 74400 Chamonix-Mont-Blanc, France",
  },
  {
    id: 94,
    name: "Studio Cosy Bordeaux",
    img: Rental4,
    address: "12 Rue Cosy, 33000 Bordeaux, France",
  },
  {
    id: 95,
    name: "Maison de Vacances Toulouse",
    img: Rental5,
    address: "25 Rue de Vacances, 31000 Toulouse, France",
  },
  {
    id: 96,
    name: "Penthouse Élégant Lyon",
    img: Rental4,
    address: "18 Rue Élégante, 69002 Lyon, France",
  },
  {
    id: 97,
    name: "Appartement de Luxe Nantes",
    img: Rental2,
    address: "22 Avenue de Luxe, 44000 Nantes, France",
  },
  {
    id: 98,
    name: "Villa avec Piscine Montpellier",
    img: Rental3,
    address: "40 Rue de la Piscine, 34000 Montpellier, France",
  },
  {
    id: 99,
    name: "Loft Moderne Marseille",
    img: Rental1,
    address: "28 Rue Moderne, 13008 Marseille, France",
  },
  {
    id: 100,
    name: "Maison de Vacances Strasbourg",
    img: Rental5,
    address: "15 Rue de Vacances, 67000 Strasbourg, France",
  },
  {
    id: 102,
    name: "Villa à Colmar",
    img: Rental2,
    address: "15 Rue de Anémones, 68000 Colmar, France",
  },
];
