import React, {useState, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../../Common/Button/Button";
import CloseIcon from "../../../assets/icons/close.svg?react";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg?react";
import {isImageFile, isVideoFile} from "../../../helpers/fileHelper";
import {FileInstruction} from "../../../types/GETTypes";
import CarouselGuestPage from "./CarouselGuestPage";

interface ImageGalleryModalProps {
  images: FileInstruction[];
  isOpen: boolean;
  onClose: () => void;
  selectedImageIndex: number;
  setSelectedImageIndex: React.Dispatch<React.SetStateAction<number>>;
  title?: string;
}

const ImageGalleryModal: React.FC<ImageGalleryModalProps> = ({
  title,
  images,
  isOpen,
  onClose,
  selectedImageIndex,
  setSelectedImageIndex,
}) => {
  const {t} = useTranslation();
  const [isAnimating, setIsAnimating] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isAnimating) {
      const timeout = setTimeout(() => setIsAnimating(false), 300);
      return () => clearTimeout(timeout);
    }
  }, [isAnimating]);

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleNext = () => {
    if (selectedImageIndex < images?.length - 1) {
      setIsAnimating(true);
      setTimeout(() => {
        setSelectedImageIndex((prevIndex) => prevIndex + 1);
      }, 200);
    }
  };

  const handlePrev = () => {
    if (selectedImageIndex > 0) {
      setIsAnimating(true);
      setTimeout(() => {
        setSelectedImageIndex((prevIndex) => prevIndex - 1);
      }, 150);
    }
  };

  const renderMedia = (file: FileInstruction) => {
    if (!file) {
      return null;
    }
    if (isImageFile(file.mime_type)) {
      return (
        <img
          src={file.original_url}
          alt={file.name}
          className={`w-full rounded-lg transition-transform duration-300 ease-in-out transform ${
            isAnimating ? "scale-95 opacity-0" : "scale-100 opacity-100"
          }`}
        />
      );
    } else if (isVideoFile(file.mime_type)) {
      return (
        <video
          controls
          src={file.original_url}
          className={`w-full rounded-lg transition-transform duration-300 ease-in-out transform ${
            isAnimating ? "scale-95 opacity-0" : "scale-100 opacity-100"
          }`}
        ></video>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 w-full">
      <div
        ref={modalRef}
        className="bg-white w-full max-w-md mx-auto rounded-lg overflow-hidden"
      >
        <div className="flex justify-between items-center p-4">
          <h2 className="text-lg font-semibold md:text-xl">{title ?? ""}</h2>
          <div className="hover:cursor-pointer" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className="p-4">
          <div className="mb-4 relative flex justify-center items-center">
            <div
              className="absolute inset-0 bg-white transition-opacity duration-200 ease-in-out"
              style={{opacity: isAnimating ? 1 : 0}}
            ></div>
            {renderMedia(images[selectedImageIndex])}
          </div>
          <div className="flex flex-row items-center justify-between">
            <Button
              type="secondary"
              onClick={handlePrev}
              disabled={selectedImageIndex === 0}
              displayLargeBtn={false}
              buttonClassName="w-6 h-10"
            >
              <ArrowLeftIcon className="w-4 h-4" />
            </Button>
            <div className="flex flex-1 max-w-[300px] md:max-w-[600px] items-center ">
              <CarouselGuestPage
                images={images}
                selectedImageIndex={selectedImageIndex}
                setSelectedImageIndex={setSelectedImageIndex}
                isAnimating={isAnimating}
                setIsAnimating={setIsAnimating}
              />
            </div>
            <Button
              type="secondary"
              onClick={handleNext}
              disabled={selectedImageIndex === images?.length - 1}
              displayLargeBtn={false}
              buttonClassName="w-6 h-10"
            >
              <ArrowRightIcon className="w-4 h-4" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGalleryModal;
