import React from "react";
import { Link } from "react-router-dom";
import { CarouselWizard } from "../../../Common/CarouselWizard/CarouselWizard";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { RightModal } from "../../../Common/RightModal/RightModal";
import {
  useSmartLocksAutomatedMessagesStep,
  useSmartLocksInformationsStep,
  useSmartLocksLoginStep,
} from "./Smartlocks.hooks";
import { useSmartlocksContext } from "./SmartlocksContext";

export const RentalSmartlocksAddModal: React.FC = () => {
  const {
    t,
    apiError,
    currentCarouselWizardIndex,
    changeStep,
    isAddModalVisible,
    onCloseAddModal,
  } = useSmartlocksContext();

  return (
    <RightModal
      title={t("Rental.Services.Smartlocks.AddModal.title")}
      isVisible={isAddModalVisible}
      onClose={currentCarouselWizardIndex === 2 ? () => {} : onCloseAddModal}
    >
      <div className="flex flex-col w-full gap-3">
        <ErrorMessage>{apiError}</ErrorMessage>

        <div className="flex-1">
          <CarouselWizard
            currentStepIndex={currentCarouselWizardIndex}
            steps={[
              {
                title: t(
                  "Rental.Services.Smartlocks.AddModal.Steps.Login.title"
                ),
                Node: useSmartLocksLoginStep(),
              },
              {
                title: t(
                  "Rental.Services.Smartlocks.AddModal.Steps.Informations.title"
                ),
                Node: useSmartLocksInformationsStep(),
              },
              {
                title: t(
                  "Rental.Services.Smartlocks.AddModal.Steps.AutomatedMessages.title"
                ),
                Node: useSmartLocksAutomatedMessagesStep(),
              },
            ]}
            stepsUnlocked={false}
            onChangeStep={(step: number) => changeStep(step)}
          />
        </div>

        <Link
          to="https://superhote.com/v2-tutoriels-smartlocks"
          target="_blank"
          className="text-active"
        >
          {t("Rental.Services.Smartlocks.AddModal.textTutorial")}
        </Link>
      </div>
    </RightModal>
  );
};
