import paths from "../../constants/paths";
import { del, post, update } from "../../helpers/APIHelper";

export const useCreateRentalGroup = async (
  name: string,
  rental_ids: string,
  onSuccess: () => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (typeof onStart === "function") {
      onStart();
    }

    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_GROUPS}`,
      {
        name,
        rental_ids,
      }
    );

    if (response?.data?.success) {
      window.history.pushState(
        {},
        "",
        `${paths.RENTALS}/${paths.RENTALS_GROUP_VIEW}`
      );
      onSuccess();
    } else {
      onError(response?.data.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useUpdateRentalGroup = async (
  id: number | null,
  name: string,
  rental_ids: string,
  onSuccess: () => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (id !== null && id !== 0) {
      if (typeof onStart === "function") {
        onStart();
      }

      const response = await update(
        `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_GROUPS}/${id}`,
        {
          name,
          rental_ids,
        }
      );

      if (response?.data?.success) {
        window.history.pushState(
          {},
          "",
          `${paths.RENTALS}/${paths.RENTALS_GROUP_VIEW}`
        );
        onSuccess();
      } else {
        onError(response?.data.message ?? response.message);
      }
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useDeleteRentalGroup = async (
  id: number | undefined,
  onSuccess: () => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (id !== undefined) {
      if (typeof onStart === "function") {
        onStart();
      }

      const response = await del(
        `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_GROUPS}/${id}`,
        {
          method: "DELETE",
        }
      );

      if (response?.data?.success) {
        window.history.pushState(
          {},
          "",
          `${paths.RENTALS}/${paths.RENTALS_GROUP_VIEW}`
        );
        onSuccess();
      } else {
        onError(response?.data.message ?? response.message);
      }
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};
