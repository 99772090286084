import React, {useEffect, useState} from "react";
import {BookingLayout} from "../../components/Layout/BookingLayout/BookingLayout";
import {BookingContractPageProps} from "./BookingContractPage.type";
import {TableList} from "../../components/Common/TableList/TableList";
import {BookingContractDocsTableHeader} from "./BookingContractDocsTableHeader";
import {BookingContractDocsItem} from "./BookingContractDocsItem";
import {useTablePage} from "../../hooks/useTablePage";
import paths from "../../constants/paths";
import {useParams} from "react-router-dom";
import {ValueType} from "../../types/commonTypes";
import {BookingContractDocsSkeleton} from "./BookingContractDocsSkeleton";
import {File, ReservationResponse} from "../../types/GETTypes";
import {downloadFile} from "../../helpers/fileHelper";
import {fetchOneReservation} from "../../helpers/reservationHelper";

export const BookingContractPage: React.FC<BookingContractPageProps> = () => {
  const {reservationId} = useParams();
  const [reservation, setReservation] = useState<ReservationResponse>();

  const tableMessages = useTablePage(
      `${import.meta.env.VITE_API_URL}${paths.API.MESSAGES}/${reservationId}`,
      "messages",
      { combine: true, reverseData: true }
  );

  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${
      paths.API.RESERVATIONS
    }/${reservationId}/docs`,
    "docs"
  );
  const handleReservationSuccess = (value: ReservationResponse) => {
    setReservation(value);
  };

  useEffect(() => {
    if (reservationId) {
      fetchOneReservation(
          reservationId,
          handleReservationSuccess,
          ()=> {}
      );

      tableMessages.fetch({
        limit: 10,
        sort_field: "captured_at",
        sort_direction: "desc",
      });

    }
  }, [reservationId]);

  useEffect(() => {
    tablePage.fetch({});
  }, []);

  const handleClickBooking = (id: ValueType) => {
    const file = tablePage.data?.find((f:File) => f.id === id) as File;
    if(file){
      const {original_url,name} = file
      downloadFile({original_url,name})
    }
  };

  return (
    <BookingLayout navbarActiveItem="contracts" reservation={reservation}>
      <TableList
        i18nElement="Global.docs"
        Header={BookingContractDocsTableHeader}
        Item={BookingContractDocsItem}
        Skeleton={BookingContractDocsSkeleton}
        NoData={<></>}
        tablePage={tablePage}
        onClick={handleClickBooking}
      />
    </BookingLayout>
  );
};
