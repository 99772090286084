import React from "react";
import ExtraComponent from "../Common/ExtraComponent";
import {useTranslation} from "react-i18next";
import {GuestPageConnectResponse} from "../../../types/GETTypes";
import {tabType} from "../GuestPageHome/GuestPageHomeComponent";

const ExtraBlockComponent = ({
  onChangeTab,
}: {
  onChangeTab: ((tab: tabType) => void) | undefined;
}) => {
  const {t} = useTranslation();
  return (
    <ExtraComponent
      title={t("GuestPage.Reservation.extras")}
      icon={"✨"}
      fromPage="home"
      onChangeTab={onChangeTab}
      displayTitles
      blockStyle={{titleStyle:"md:text-[32px]"}}
    />
  );
};

export default ExtraBlockComponent;
