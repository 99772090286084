// src/hooks/useWindowSize.js
import {useState, useEffect} from "react";

type WindowSize = {
  width: undefined | number;
  height: undefined | number;
  isMobile: boolean;
};
function useWindowSize() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 768 && window.innerWidth <= 1024);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  
  useEffect(() => {
    const handleWindowResize = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      
      setWidth(newWidth);
      setHeight(newHeight);
      
      setIsMobile(newWidth <= 768);
      setIsTablet(newWidth > 768 && newWidth <= 1024);
      setIsDesktop(newWidth > 1024);
    }
  
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  
  return { width, height, isMobile, isTablet, isDesktop };
}

export default useWindowSize;
