import React, { useEffect } from "react";
import { Loader } from "../../components/Common/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { post } from "../../helpers/APIHelper";
import paths from "../../constants/paths";

export const LogoutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const logout = async () => {
      localStorage.clear();
      await post(`${import.meta.env.VITE_API_URL}${paths.API.LOGOUT}`);

      setTimeout(() => {
        navigate(paths.LOGIN);
      }, 200);
    };

    logout();
  }, []);

  return (
    <div className="flex w-full h-screen items-center justify-center">
      <Loader />
    </div>
  );
};
