export type RegisterPageProps = {};

export enum RegisterPageStep {
  personnal = 0,
  type,
  platform,
  password,
  thanks,
}

export type RegisterDataType = {
  current_step: RegisterPageStep;
  personnal: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
  };
  properties: {
    types: string[];
    platforms: string[];
  };
};
