import React, { useEffect, useState } from "react";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { Trans, useTranslation } from "react-i18next";
import { useTablePage } from "../../hooks/useTablePage";
import paths from "../../constants/paths";
import { TableListWithGroup } from "../../components/Common/TableList/TableListWithGroup";
import { ShortcodeListPageHeader } from "../../components/ShortcodeList/ShortcodeListPageHeader";
import { ShortcodeListPageItem } from "../../components/ShortcodeList/ShortcodeListPageItem";
import { ShortcodeListPageSkeleton } from "../../components/ShortcodeList/ShortcodeListPageSkeleton";
import {
  capitalizeFirstLetter,
  changeGroupCodeName,
} from "../../helpers/stringHelper";
import { ShortcodeListPageFilter } from "../../components/ShortcodeList/ShortcodeListPageFilter";
import { GroupButton } from "../../components/Common/GroupButton/GroupButton";
import { ShortcodeCategoryEnum } from "./ShortcodeListPage.type";
import { ValueType } from "../../types/commonTypes";
import { useModal } from "../../hooks/useModal";
import { ShortcodeListPageRemoveModal } from "../../components/ShortcodeList/ShortcodeListPageRemoveModal";
import { ShortcodeListItemResponse } from "../../types/GETTypes";
import { ShortcodeUpsertModal } from "../../components/ShortcodeList/ShortcodeUpsertModal";
import InformationActiveIcon from "../../assets/icons/information-active.svg?react";

export const ShortcodeListPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  const [currentCategory, setCurrentCategory] = useState<ShortcodeCategoryEnum>(
    ShortcodeCategoryEnum.ALL
  );

  const removeModal = useModal<ValueType>();
  const upsertModal = useModal<ShortcodeListItemResponse>();

  const transform = (data: any) => {
    if (!data) return data;

    let tmpCodes: any[] = [];
    if (data.custom) tmpCodes = [...tmpCodes, ...data.custom];
    if (data.default) tmpCodes = [...tmpCodes, ...data.default];

    const result: any[] = [];

    tmpCodes?.forEach((code: any) => {
      const indexGroup = result.findIndex((g: any) => g.id === code.type);

      if (indexGroup === -1) {
        result.push({
          id: code.type,
          name: t(changeGroupCodeName(code.type.toLowerCase())).toUpperCase(),
          rentals: [{ ...code }],
        });
      } else {
        result[indexGroup].rentals.push(code);
      }
    });

    const order = ["guest", "reservation", "payment", "guest_page", "rental"];

    result.sort((a: any, b: any) => {
      const indexA = order.indexOf(a.id.toLowerCase());
      const indexB = order.indexOf(b.id.toLowerCase());

      if (indexA === -1 && indexB === -1) return 0;
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;

      return indexA - indexB;
    });

    return result;
  };

  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.SHORTCODES}`,
    "shortcodes",
    {
      transform,
      otherMetas: ["total_custom_shortcodes", "total_default_shortcodes"],
    }
  );

  useEffect(() => {
    tablePage.fetch({});
  }, []);

  const handleChangeCategory = (value: ShortcodeCategoryEnum) => {
    setCurrentCategory(value);
    tablePage.handleFilterCustom("category", value);
  };

  const handleClickItem = async (id: ValueType | null, action: ValueType) => {
    if (action === "remove") {
      removeModal.open(id ?? 0);
    } else {
      const indexCustom = tablePage?.data.findIndex(
        (d: any) => d.id === "custom"
      );
      const index = tablePage.data[indexCustom].rentals.findIndex(
        (d: any) => d.id === id
      );
      if (index !== -1) {
        upsertModal.open(tablePage.data[indexCustom].rentals[index]);
      }
    }
  };

  const handleRemoveSuccess = () => {
    removeModal.close();
    tablePage.fetch({ search: tablePage.currentSearch });
  };

  const handleUpsertSuccess = () => {
    upsertModal.close();
    setCurrentCategory(ShortcodeCategoryEnum.ALL);
    tablePage.fetch({ search: tablePage.currentSearch });
  };

  return (
    <>
      <ShortcodeListPageRemoveModal
        id={removeModal.data}
        isVisible={removeModal.isVisible}
        onClose={removeModal.close}
        onSuccess={handleRemoveSuccess}
      />

      <ShortcodeUpsertModal
        data={upsertModal.data}
        isVisible={upsertModal.isVisible}
        onClose={upsertModal.close}
        onUpsertSuccess={handleUpsertSuccess}
      />

      <MainLayout
        title={t("ShortcodeList.title")}
        sidebarActiveItem="auto_messages"
        sidebarActiveSubItem="shortcodes"
      >
        <div className="flex flex-col">
          <ShortcodeListPageFilter
            onSearchText={tablePage.handleChangeSearch}
            onAdd={upsertModal.open}
          />
          <div className="flex flex-row bg-element-background rounded-4px w-full h-fit p-2 gap-2 mt-6">
            <div className="">
              <InformationActiveIcon className="w-5 h-5" />
            </div>

            <div className="flex-1">
              <p className="font-semibold text-high-contrast">
                {t("AutoMessageUpsert.shortCodeInfosTitle")}
              </p>
              <p className="text-low-contrast mt-1">
                <Trans
                  i18nKey="AutoMessageUpsert.shortCodeInfos"
                  components={{
                    strong: <strong />,
                  }}
                />
              </p>
            </div>
          </div>

          <div className="h-4 w-full" />

          <GroupButton
            items={[
              {
                label: t("ShortcodeList.all"),
                value: ShortcodeCategoryEnum.ALL,
                isActive: currentCategory === ShortcodeCategoryEnum.ALL,
                badge: tablePage.total?.toString(),
              },
              {
                label: t("ShortcodeList.default"),
                value: ShortcodeCategoryEnum.DEFAULT,
                isActive: currentCategory === ShortcodeCategoryEnum.DEFAULT,
                badge: tablePage.currentOtherMetas
                  .find((m) => m.key === "total_default_shortcodes")
                  ?.value?.toString(),
              },
              {
                label: t("ShortcodeList.custom"),
                value: ShortcodeCategoryEnum.CUSTOM,
                isActive: currentCategory === ShortcodeCategoryEnum.CUSTOM,
                badge: tablePage.currentOtherMetas
                  .find((m) => m.key === "total_custom_shortcodes")
                  ?.value?.toString(),
              },
            ]}
            onClick={(value) =>
              handleChangeCategory(value as ShortcodeCategoryEnum)
            }
          />

          <TableListWithGroup
            i18nElement="ShortcodeList.shortcode"
            // Filters={getGroupViewFiltersNode()}
            // ViewTypeGroupButton={getGroupButtonViewTypeNode()}
            Header={ShortcodeListPageHeader}
            Item={ShortcodeListPageItem}
            Skeleton={ShortcodeListPageSkeleton}
            NoData={<div>NODATA</div>}
            tablePage={tablePage}
            nbCols={3}
            onClick={(id) => handleClickItem(id, "edit")}
            onClickAction={handleClickItem}
          />
        </div>
      </MainLayout>
    </>
  );
};
