import React from "react";
import { ValueType } from "../../../../types/commonTypes";
import { GroupButton } from "../../../Common/GroupButton/GroupButton";
import {
  useHandleClickNavbarItem,
  useRentalLayoutNavbarItems,
} from "./RentalLayoutNavbar.hooks";
import { RentalLayoutNavbarProps } from "./RentalLayoutNavbar.type";

export const RentalLayoutNavbar: React.FC<RentalLayoutNavbarProps> = ({
  activeItem,
  onActiveItem,
  // activeSubItem,
  // onActiveSubItem,
  rental,
}) => {
  return (
    <div className="flex flex-col space-y-6">
      <GroupButton
        items={useRentalLayoutNavbarItems(activeItem)}
        onClick={(value: ValueType) =>
          useHandleClickNavbarItem(value, rental, onActiveItem)
        }
      />

      {/* -- SUB MENU -- */}
      {/* <GroupButton
        items={useRentalLayoutNavbarSubItems(activeItem, activeSubItem)}
        onClick={(value: ValueType) =>
          useHandleClickNavbarSubItem(value, rental, onActiveSubItem)
        }
      /> */}
    </div>
  );
};
