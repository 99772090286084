import React from "react";
import {
  TasksFilterStatusEnum,
  TasksFilterStatusMeta,
} from "../../pages/ManagementTaskList/ManagementTaskListPage.type";
import { useTranslation } from "react-i18next";
import { GroupButton } from "../Common/GroupButton/GroupButton";

export const TaskFilterStatus: React.FC<{
  filter: TasksFilterStatusEnum;
  metas: TasksFilterStatusMeta;
  onChange: (value: TasksFilterStatusEnum) => void;
}> = ({ metas, filter, onChange }) => {
  const { t } = useTranslation();

  return (
    <GroupButton
      items={[
        {
          label: t("Task.Filter.Status.all"),
          value: TasksFilterStatusEnum.all,
          badge: metas.all,
          isActive: filter === TasksFilterStatusEnum.all,
        },
        {
          label: t("Task.Filter.Status.notAssigned"),
          value: TasksFilterStatusEnum.notAssigned,
          badge: metas.notAssigned,
          isActive: filter === TasksFilterStatusEnum.notAssigned,
        },
        {
          label: t("Task.Filter.Status.scheduled"),
          value: TasksFilterStatusEnum.scheduled,
          badge: metas.scheduled,
          isActive: filter === TasksFilterStatusEnum.scheduled,
        },
        {
          label: t("Task.Filter.Status.pending"),
          value: TasksFilterStatusEnum.pending,
          badge: metas.pending,
          isActive: filter === TasksFilterStatusEnum.pending,
        },
        {
          label: t("Task.Filter.Status.completed"),
          value: TasksFilterStatusEnum.completed,
          badge: metas.completed,
          isActive: filter === TasksFilterStatusEnum.completed,
        },
        {
          label: t("Task.Filter.Status.refused"),
          value: TasksFilterStatusEnum.refused,
          badge: metas.refused,
          isActive: filter === TasksFilterStatusEnum.refused,
        },
        {
          label: t("Task.Filter.Status.canceled"),
          value: TasksFilterStatusEnum.canceled,
          badge: metas.canceled,
          isActive: filter === TasksFilterStatusEnum.canceled,
        },
      ]}
      onClick={(value) => onChange(value as TasksFilterStatusEnum)}
    />
  );
};
