import React from "react";
import { useTranslation } from "react-i18next";
import { RightModal } from "../../Common/RightModal/RightModal";
import { Separator } from "../../Common/Separator/Separator";

export const PaymentDepositDetailsModalSkeleton: React.FC<{}> = ({}) => {
  const { t } = useTranslation();

  return (
    <RightModal
      title={""}
      isVisible={true}
      hasSkeleton={true}
      onClose={() => {}}
    >
      <div className="flex flex-col w-full animate-pulse">
        <div className="flex flex-col flex-1 gap-y-3">
          <p className="w-32 h-3 rounded bg-slate-200"></p>

          <div>
            <p className="w-32 h-3 rounded bg-slate-200"></p>
            <div className="flex flex-row items-center justify-between p-2 space-x-3">
              <div className="flex items-center space-x-4">
                <div className="relative">
                  {/* LOGO */}
                  <div
                    className="absolute z-10 border-2 border-white rounded-full bg-slate-200"
                    style={{ top: 22, left: 26, width: 16, height: 16 }}
                  />

                  {/* PHOTO */}
                  <div className="w-10 h-10 rounded-full border-1 border-element-border/50 bg-slate-200" />
                </div>

                <div className="flex flex-col space-y-2">
                  {/* FIRSTNAME & LASTNAME */}
                  <p className="w-32 h-3 rounded bg-slate-200"></p>

                  {/* CITY & COUNTRY */}
                  <p className="w-32 h-3 rounded bg-slate-200"></p>
                </div>
              </div>

              {/* RATING */}
              <div className="w-40 h-4 rounded bg-slate-200"></div>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex flex-col space-y-3">
              <div className="space-y-1">
                <p className="w-32 h-3 rounded bg-slate-200"></p>
                <p className="w-32 h-3 rounded bg-slate-200"></p>
                <p className="w-32 h-3 rounded bg-slate-200"></p>
              </div>

              <div className="flex items-center flex-1 space-x-3">
                <div className="w-12 h-12 rounded-6px bg-slate-200"></div>
                <div className="flex flex-col space-y-2">
                  <p className="h-3 rounded w-52 bg-slate-200"></p>
                  <p className="h-3 rounded w-52 bg-slate-200"></p>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-end flex-1 space-y-2">
              <div className="flex items-center space-x-2">
                <p className="w-32 h-3 rounded bg-slate-200"></p>
              </div>

              <div className="flex items-center space-x-2">
                <p className="w-32 h-3 rounded bg-slate-200"></p>
              </div>

              <div className="flex items-center space-x-2">
                <p className="w-32 h-3 rounded bg-slate-200"></p>
              </div>

              <div className="flex items-center space-x-2">
                <p className="w-32 h-3 rounded bg-slate-200"></p>
              </div>
            </div>
          </div>

          <Separator />

          <>
            <p className="h-3 rounded w-52 bg-slate-200"></p>

            <div className="flex flex-col space-y-3">
              <div className="flex items-center justify-between">
                <p className="w-32 h-3 rounded bg-slate-200"></p>
                <div className="flex items-center space-x-3">
                  <p className="h-3 rounded w-52 bg-slate-200"></p>

                  <div className="w-10 h-10 rounded bg-slate-200"></div>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <p className="w-32 h-3 rounded bg-slate-200"></p>
                <div className="flex items-center space-x-3">
                  <p className="h-3 rounded w-52 bg-slate-200"></p>

                  <div className="w-10 h-10 rounded bg-slate-200"></div>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <p className="w-32 h-3 rounded bg-slate-200"></p>
                <div className="flex items-center space-x-3">
                  <p className="h-3 rounded w-52 bg-slate-200"></p>

                  <div className="w-10 h-10 rounded bg-slate-200"></div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <p className="w-32 h-3 rounded bg-slate-200"></p>
              <p className="w-32 h-3 rounded bg-slate-200"></p>
            </div>
          </>
        </div>
      </div>
    </RightModal>
  );
};
