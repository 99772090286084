import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CenterModal } from "../../Common/CenterModal/CenterModal";
import { NotificationStatusesListItemResponse } from "../../../types/GETTypes";
import { Button } from "../../Common/Button/Button";
import SendWhiteIcon from "../../../assets/icons/send-white.svg?react";
import { update } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import moment from "moment";

export const ScheduledMessageReSendModal: React.FC<{
  message: NotificationStatusesListItemResponse | null | undefined;
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ message, isVisible, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleReSend = async () => {
    setLoading(true);
    setError(null);

    const URL = `${import.meta.env.VITE_API_URL}${
      paths.API.SCHEDULED_MESSAGE
    }/${message?.id}${paths.API.SCHEDULED_MESSAGE_RESEND}`;

    const res = await update(URL, {
      ready_at: moment(new Date())
        .add(1, "minute")
        .format("YYYY-MM-DD HH:mm:ss"),
    });
    // 2024-06-13 19:00:00

    if (res.data?.success) {
      onSuccess();
      handleClose();
    } else {
      setError(res?.response?.data?.message);
    }

    setLoading(false);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={handleClose}
      title={t("ScheduledMessageList.ReSend.title")}
    >
      <p className="font-light text-low-contrast">
        {t("ScheduledMessageList.ReSend.content")}
      </p>

      <ErrorMessage>{error}</ErrorMessage>

      <div className="flex flex-row gap-3 mt-4">
        <Button type="secondary" onClick={handleClose} disabled={loading}>
          {t("Global.cancel")}
        </Button>

        <Button
          RightIcon={SendWhiteIcon}
          onClick={handleReSend}
          loading={loading}
        >
          {t("ScheduledMessageList.ReSend.send")}
        </Button>
      </div>
    </CenterModal>
  );
};
