import React from "react";
import { ProgressionStep } from "../../../pages/Progression/ProgressionStep";
import { useTranslation } from "react-i18next";
import AirBnBIcon from "../../../assets/icons/airbnb.svg?react";
import { Button } from "../../Common/Button/Button";
import {
  ProgressionStatusEnum,
  ProgressionStepAirbnbProps,
  ProgressionStepEnum,
} from "../Progression.type";

export const ProgressionStepAirbnb: React.FC<ProgressionStepAirbnbProps> = ({
  currentProgression,
  status,
  number,
  visible,
  loading,
  error,
  onAirbnbClick,

}) => {
  const { t } = useTranslation();
  if (!visible) return null;

  return (
    <ProgressionStep
      number={number.toString()}
      title={t("Progression.StepAirbnb.title")}
      status={status}
      error={error}
      loading={loading}
    >
      <p className="text-low-contrast font-light">
        {t("Progression.StepAirbnb.content1")}
      </p>

      <div className="flex flex-row gap-2 mt-4">
        <div className="flex flex-row gap-2 flex-1 h-9 border-1 border-element-border rounded-6px items-center p-2 px-3">
          <AirBnBIcon className="w-5 h-5" />
          <p className="text-high-contrast font-semibold text-[13px]">Airbnb</p>
        </div>
        <div>
          <Button
            onClick={onAirbnbClick}
            disabled={
              currentProgression.status === ProgressionStatusEnum.PENDING
            }
          >
            {t("Progression.connect")}
          </Button>
        </div>
      </div>
    </ProgressionStep>
  );
};
