import React from "react";
import { cn } from "../../../helpers/classHelper";
import { SimpleRadioProps } from "./SimpleRadio.type";

export const SimpleRadio: React.FC<SimpleRadioProps> = ({
  classNames,
  Icon,
  label,
  description,
  value,
  disabled = false,
  onClick,
}) => {
  const getBorder = () => {
    if (!value) {
      return `
      border-1
      border-element-border
      hover:border-element-border-hover 
      active:border-element-border-active`;
    }

    return "";
  };

  const getBackground = () => {
    if (value) {
      return "bg-button-primary-default-top";
    } else if (disabled) {
      return "bg-element-background-disabled";
    } else {
      return "bg-white";
    }
  };

  const opacity = disabled ? "opacity-64" : "";
  const cursor = disabled ? "cursor-not-allowed" : "cursor-pointer";

  const handleClick = () => {
    if (!disabled && onClick) onClick();
  };

  return (
    <div
      className={cn(`flex items-center gap-2`, classNames?.parent)}
      onClick={handleClick}
    >
      <div
        className={cn(
          `flex w-4 h-4 items-center justify-center rounded-full`,
          getBorder(),
          getBackground(),
          opacity,
          cursor,
          classNames?.radio
        )}
      >
        {value ? (
          <div
            className={cn(
              "w-1 h-1 bg-white rounded-full",
              classNames?.contentRadio
            )}
          ></div>
        ) : null}
      </div>

      <div className="flex flex-col space-y-0.5">
        {label ? (
          <p
            className={cn(
              `text-high-contrast select-none tracking-wide`,
              classNames?.label,
              classNames?.style,
              cursor
            )}
          >
            {label}
          </p>
        ) : null}

        {description ? (
          <p
            className={cn(
              `text-low-contrast select-none tracking-wide`,
              classNames?.description,
              classNames?.style,
              cursor
            )}
          >
            {description}
          </p>
        ) : null}
      </div>
    </div>
  );
};
