import React from "react";
import { ProgressionStep } from "../../../pages/Progression/ProgressionStep";
import { useTranslation } from "react-i18next";
import { ProgressionStepMessagesProps } from "../Progression.type";
import { Button } from "../../Common/Button/Button";
import { useNavigate } from "react-router-dom";
import paths from "../../../constants/paths";

export const ProgressionStepMessages: React.FC<
  ProgressionStepMessagesProps
> = ({ status, visible, number, error, loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(paths.AUTO_MESSAGES);
  };

  if (!visible) return null;

  return (
    <ProgressionStep
      number={number.toString()}
      title={t("Progression.StepMessages.title")}
      status={status}
      error={error}
      loading={loading}
    >
      <p className="text-low-contrast font-light mt-1">
        {t("Progression.StepMessages.content")}
      </p>

      <div className="w-72 mt-4">
        <Button onClick={handleNavigate}>
          {t("Progression.StepMessages.button")}
        </Button>
      </div>
    </ProgressionStep>
  );
};
