import moment from "moment";
import React, { useEffect, useState } from "react";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { DateInputOnBlur, DateInputProps } from "./DateInput.type";

export const DateInput: React.FC<DateInputProps> = ({
  register,
  label,
  value,
  placeholder = "",
  size = "normal",
  disabled = false,
  error,
  onChangeText,
  onFocused,
}) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [date, setDate] = useState<string>("");

  useEffect(() => {
    if (value !== undefined) {
      setDate(moment(value).format("YYYY-MM-DD"));
    }
  }, [value]);

  const getBorder = () => {
    return error
      ? "border-element-border-error"
      : `
      border-element-border
      hover:border-element-border-hover 
      active:border-element-border-active`;
  };

  const getOutline = () => {
    if (isFocus && error) {
      return "outline outline-2 outline-error/50";
    } else if (isFocus && !error) {
      return "outline outline-2 outline-focus";
    } else {
      return "";
    }
  };

  const bgColor = disabled ? "bg-element-background-disabled" : "bg-white";
  const cursor = disabled ? "cursor-not-allowed" : "cursor-text";
  const textColor = disabled ? "text-low-contrast " : "text-high-contrast ";
  const textSize = size === "large" ? "text-sm" : "text-sm";

  const handleBlur: DateInputOnBlur = (e) => {
    if (typeof onFocused === "function") {
      onFocused(false);
    }
    setIsFocus(false);
    register?.onBlur(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;
    if (typeof onChangeText === "function") {
      onChangeText(value);
    }
  };

  return (
    <div className="flex flex-col flex-1">
      {label ? (
        <label
          className="mb-1 text-sm font-semibold text-left"
          htmlFor={`input-${register?.name}`}
        >
          {label}
        </label>
      ) : null}
      <div
        className={`
        flex flex-row items-center
        overflow-hidden
        ${getBorder()}
        ${bgColor}
        ${cursor}
        ${getOutline()}
        px-2
        ${size === "large" ? "h-9" : "h-8"}
        border-1 rounded-6px`}
      >
        <input
          {...register}
          value={date}
          id={`input-${register?.name}`}
          className={`flex-1
            w-12
            ${textColor}
          placeholder:text-low-contrast placeholder:font-light
            focus:outline-none
            ${bgColor}
            ${cursor}
            ${textSize}
            `}
          placeholder={placeholder?.toString()}
          disabled={disabled}
          onChange={register?.onChange ?? handleChange}
          onFocus={() => {
            if (typeof onFocused === "function") {
              onFocused(true);
            }

            setIsFocus(true);
          }}
          onBlur={handleBlur}
          type={"date"}
        />
      </div>

      <ErrorMessage errorType="FORM">{error}</ErrorMessage>
    </div>
  );
};
