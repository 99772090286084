import React from "react";
import { useTranslation } from "react-i18next";
import UsersIcon from "../../../assets/icons/users.svg?react";

export const ManagementTeamListEmpty = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center">
      <UsersIcon className="size-8" />
      <p className="text-high-contrast font-semibold mt-3">
        {t("Management.TeamList.Empty.title")}
      </p>
      <p className="text-low-contrast font-light mt-1">
        {t("Management.TeamList.Empty.content")}
      </p>
    </div>
  );
};
