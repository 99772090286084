import React from "react";
import { Title } from "../../Common/Title/Title";
import { useTranslation } from "react-i18next";
import { Link } from "../../Common/Link/Link";
import { Button } from "../../Common/Button/Button";
import { LoginFormType, LoginProps } from "./Login.type";
import LoginIcon from "../../../assets/icons/log-in-white.svg?react";
import { TextInput } from "../../Common/TextInput/TextInput";
import { SubmitHandler, useForm } from "react-hook-form";
import paths from "../../../constants/paths";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { Keys } from "../../../values";

export const Login: React.FC<LoginProps> = ({ error, loading, onLogin }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormType>();

  const handleLogin: SubmitHandler<LoginFormType> = async (data) => {
    if (typeof onLogin === "function") {
      onLogin({ email: data.email, password: data.password });
    }
  };

  return (
    <>
      <Title>{t("Login.title")}</Title>

      <div className="mt-4">
        <Title type="secondary">{t("Login.subTitle1")}</Title>
        <Title type="secondary">
          {t("Login.subTitle2")}{" "}
          <Link url={paths.REGISTER}>{t("Login.register")}</Link>
        </Title>
      </div>

      <div className="mt-4">
        <div>
          <TextInput
            register={register("email", {
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: t("Login.emailInvalid"),
              },
              required: {
                value: true,
                message: t("Login.emailRequired"),
              },
            })}
            label={t("Login.email")}
            placeholder="jane.doe@mail.com"
            error={errors?.email?.message}
            disabled={loading}
          />
        </div>

        <div className="mt-4">
          <TextInput
            register={register("password", {
              required: {
                value: true,
                message: t("Login.passwordRequired"),
              },
              minLength: {
                value: 8,
                message: t("Login.passwordMin"),
              },
            })}
            label={t("Login.password")}
            type="password"
            error={errors.password?.message}
            disabled={loading}
            placeholder={t("Global.placeholderPassword")}
          />
        </div>
      </div>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      <div className="flex mt-4">
        <Button
          loading={loading}
          RightIcon={LoginIcon}
          keyToSubmit={Keys.Enter}
          onClick={handleSubmit(handleLogin)}
        >
          {t("Login.submit")}
        </Button>
      </div>

      <div className="mt-6 text-center">
        <Title type="secondary">
          <Link url={paths.FORGOT_PASSWORD}>{t("Login.forget")}</Link>
        </Title>
      </div>
    </>
  );
};
