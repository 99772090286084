import React from "react";
import { SimpleBox } from "../../Modal/Airbnb/Connect/AirbnbConnectStepImport";

export const MultiSelectRentalsTableSkeleton: React.FC<{}> = () => {
  return (
    <tr>
      <td className="w-8 p-2">
        <SimpleBox value={false} disabled={true} />
      </td>

      <td className="p-2">
        <div className="flex items-center gap-2">
          <div className="w-12 h-12 rounded-6px border-1 border-element-border-light bg-slate-200" />

          <div className="flex flex-col">
            <p className="h-2 rounded w-72 bg-slate-300"></p>
            <p className="h-2 mt-1 rounded w-72 bg-slate-200"></p>
            <p className="h-2 mt-1 rounded w-52 bg-slate-200"></p>
          </div>
        </div>
      </td>
    </tr>
  );
};
