import React, {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {BadgeSize, BadgeStatus} from "../components/Common/Badge/Badge.type";
import paths from "../constants/paths";
import {
  CONTRACT_STATUS,
  PLATFORM_RESERVATION,
  RESERVATION_STATUS,
} from "../pages/Booking/BookingPage.type";
import {ReservationResponse} from "../types/GETTypes";
import {get} from "./APIHelper";
import {
  PaymentAccountTypeEnum,
  PaymentReservationDepositStatusEnum,
  PaymentReservationStatusEnum,
  ReservationExtraOrderStatusEnum,
} from "../enums/GETenums";
import {Badge} from "../components/Common/Badge/Badge";

import CheckCircleGreenIcon from "../assets/icons/check-circle-green.svg?react";
import ClockWarningIcon from "../assets/icons/clock-warning.svg?react";
import CloseCircleRedIcon from "../assets/icons/close-circle-red.svg?react";
import CloseCircleIcon from "../assets/icons/close-circle.svg?react";
import ForbidWarningIcon from "../assets/icons/forbid-warning.svg?react";
import MoneyIcon from "../assets/icons/money.svg?react";
import SendMoneyWarningIcon from "../assets/icons/send-money-warning.svg?react";
import DoubleCheckGreenIcon from "../assets/icons/two-checkmarks-green.svg?react";
import WarningTriangleRedIcon from "../assets/icons/warning-triangle-red.svg?react";
import {TFunction} from "i18next";
import {capitalizeFirstLetter} from "./stringHelper";
import {IconType} from "../types/commonTypes";

export const fetchOneReservation = async (
  id: string,
  onSuccess: (reservation: ReservationResponse) => void,
  onError: (message: string) => void
) => {
  const URL = `${import.meta.env.VITE_API_URL}${paths.API.RESERVATIONS}/${id}`;
  const response = await get(URL);

  if (response.data?.success) {
    onSuccess(response.data.result);
  } else {
    onError(response.response.data.message);
  }
};

export const fetchReservationJournal = async (
  reservationId: string,
  onSuccess: (journals: any) => void,
  onError: (message: string) => void
) => {
  const URL = `${import.meta.env.VITE_API_URL}${
    paths.API.JOURNAL.RESERVATION
  }/${reservationId}`;
  const response = await get(URL);

  if (response.data?.success) {
    onSuccess(response.data.result);
  } else {
    onError(response.response.data.message);
  }
};

export const getReservationStatusBadge = (
  status?: RESERVATION_STATUS | null
) => {
  const {t} = useTranslation();

  const result: {label: string; status: BadgeStatus} = {
    label: t("Global.ko"),
    status: "fail",
  };

  switch (status) {
    case RESERVATION_STATUS.CANCELED:
      result.label = t("Booking.Status.canceled");
      result.status = "fail";
      return result;
    case RESERVATION_STATUS.CANCELED_BY_TRAVELER:
      result.label = t("Booking.Status.canceledByTraveler");
      result.status = "fail";
      return result;
    case RESERVATION_STATUS.REQUEST:
      result.label = t("Booking.Status.request");
      result.status = "pending";
      return result;
    case RESERVATION_STATUS.PRE_APPROVED:
      result.label = t("Booking.Status.preApprouved");
      result.status = "pending";
      return result;
    case RESERVATION_STATUS.CONFIRMED_COMING:
      result.label = t("Booking.Status.coming");
      result.status = "pending";
      return result;
    case RESERVATION_STATUS.CONFIRMED_FINISHED:
      result.label = t("Booking.Status.finished");
      result.status = "success";
      return result;
    case RESERVATION_STATUS.CONFIRMED_PROGRESS:
      result.label = t("Booking.Status.progress");
      result.status = "success";
      return result;
    default:
      return result;
  }
};

export const getDepositStatusBadge = (
  status?: PaymentReservationDepositStatusEnum | null
) => {
  const {t} = useTranslation();

  switch (status) {
    case PaymentReservationDepositStatusEnum.UNPAID:
      return (
        <Badge
          Icon={CloseCircleRedIcon}
          status="fail"
          label={t("Booking.DepositStatus.unpaid")}
        />
      );

    case PaymentReservationDepositStatusEnum.OVERDUE:
      return (
        <Badge
          Icon={WarningTriangleRedIcon}
          status="fail"
          label={t("Booking.DepositStatus.overdue")}
        />
      );

    case PaymentReservationDepositStatusEnum.PRE_AUTHORIZED:
      return (
        <Badge
          Icon={CheckCircleGreenIcon}
          status="success"
          label={t("Booking.DepositStatus.preAuthorized")}
        />
      );

    case PaymentReservationDepositStatusEnum.RELEASED:
      return (
        <Badge
          Icon={DoubleCheckGreenIcon}
          status="success"
          label={t("Booking.DepositStatus.released")}
        />
      );

    case PaymentReservationDepositStatusEnum.CAPTURED:
      return (
        <Badge
          Icon={SendMoneyWarningIcon}
          status="pending"
          label={t("Booking.DepositStatus.captured")}
        />
      );

    case PaymentReservationDepositStatusEnum.PAID:
      return (
        <Badge
          Icon={CheckCircleGreenIcon}
          status="success"
          label={t("Booking.DepositStatus.paid")}
        />
      );

    case PaymentReservationDepositStatusEnum.REFUNDED:
      return (
        <Badge
          Icon={DoubleCheckGreenIcon}
          status="success"
          label={t("Booking.DepositStatus.refunded")}
        />
      );

    case PaymentReservationDepositStatusEnum.NOT_REFUNDED:
      return (
        <Badge
          Icon={ForbidWarningIcon}
          status="pending"
          label={t("Booking.DepositStatus.notRefunded")}
        />
      );

    case PaymentReservationDepositStatusEnum.CANCELED:
      return (
        <Badge
          Icon={CloseCircleIcon}
          label={t("Booking.DepositStatus.canceled")}
          customBg="bg-[#EDEDED]"
          customColor="text-[#6F6F6F]"
        />
      );

    case PaymentReservationDepositStatusEnum.NOT_REQUIRED:
      return (
        <Badge
          Icon={CloseCircleIcon}
          label={t("Booking.DepositStatus.notRequired")}
          customBg="bg-[#EDEDED]"
          customColor="text-[#6F6F6F]"
        />
      );

    case PaymentReservationDepositStatusEnum.MANAGED_BY_PLATFORM:
      return (
        <Badge
          Icon={CheckCircleGreenIcon}
          label={t("Booking.DepositStatus.managedByPlatform")}
          status="success"
        />
      );
  }
};

export const useDepositStatusBadge = (
  status?: PaymentReservationDepositStatusEnum | null,
  size: BadgeSize = "large"
): ReactNode => {
  const { t } = useTranslation();
  let badgeStatus: BadgeStatus = "success";
  let icon: IconType | undefined = undefined;
  let label: string = "";
  let customBg: string | undefined = undefined;
  let customColor: string | undefined = undefined;

  switch (status) {
    case PaymentReservationDepositStatusEnum.UNPAID:
      badgeStatus = "fail";
      icon = CloseCircleRedIcon;
      label = t("Booking.DepositStatus.unpaid");
      break;
    case PaymentReservationDepositStatusEnum.OVERDUE:
      badgeStatus = "fail";
      icon = WarningTriangleRedIcon;
      label = t("Booking.DepositStatus.overdue");
      break;
    case PaymentReservationDepositStatusEnum.PRE_AUTHORIZED:
      badgeStatus = "success";
      icon = CheckCircleGreenIcon;
      label = t("Booking.DepositStatus.preAuthorized");
      break;
    case PaymentReservationDepositStatusEnum.RELEASED:
      badgeStatus = "success";
      icon = DoubleCheckGreenIcon;
      label = t("Booking.DepositStatus.released");
      break;
    case PaymentReservationDepositStatusEnum.CAPTURED:
      badgeStatus = "pending";
      icon = SendMoneyWarningIcon;
      label = t("Booking.DepositStatus.captured");
      break;
    case PaymentReservationDepositStatusEnum.PAID:
      badgeStatus = "success";
      icon = CheckCircleGreenIcon;
      label = t("Booking.DepositStatus.paid");
      break;
    case PaymentReservationDepositStatusEnum.REFUNDED:
      badgeStatus = "success";
      icon = DoubleCheckGreenIcon;
      label = t("Booking.DepositStatus.refunded");
      break;
    case PaymentReservationDepositStatusEnum.NOT_REFUNDED:
      badgeStatus = "pending";
      icon = ForbidWarningIcon;
      label = t("Booking.DepositStatus.notRefunded");
      break;
    case PaymentReservationDepositStatusEnum.CANCELED:
      icon = CloseCircleIcon;
      label = t("Booking.DepositStatus.canceled");
      customBg = "bg-[#EDEDED]";
      customColor = "text-[#6F6F6F]";
      break;
    case PaymentReservationDepositStatusEnum.NOT_REQUIRED:
      icon = CloseCircleIcon;
      label = t("Booking.DepositStatus.notRequired");
      customBg = "bg-[#EDEDED]";
      customColor = "text-[#6F6F6F]";
      break;
    case PaymentReservationDepositStatusEnum.MANAGED_BY_PLATFORM:
      badgeStatus = "success";
      icon = CheckCircleGreenIcon;
      label = t("Booking.DepositStatus.managedByPlatform");
      break;
    default:
      break;
  }

  return (
    <Badge
      Icon={icon}
      status={badgeStatus}
      label={label}
      size={size}
      customBg={customBg}
      customColor={customColor}
    />
  );
};

export const getPaymentStatusBadge = (
  status?:
    | PaymentReservationStatusEnum
    | ReservationExtraOrderStatusEnum
    | null,
  t?: TFunction<"translation", undefined>,
  style?: {
    iconSize?: number;
    labelStyle?: string;
    size?: BadgeSize;
  }
) => {
  if (!t) {
    t = useTranslation().t;
  }

  switch (status) {
    case PaymentReservationStatusEnum.UNPAID:
      return (
        <Badge
          Icon={CloseCircleRedIcon}
          status="fail"
          label={t("Booking.PaymentStatus.unpaid")}
          labelStyle={style?.labelStyle}
          iconSize={style?.iconSize}
          size={style?.size}
        />
      );
    case PaymentReservationStatusEnum.OVERDUE:
      return (
        <Badge
          Icon={WarningTriangleRedIcon}
          status="fail"
          label={t("Booking.PaymentStatus.overdue")}
          labelStyle={style?.labelStyle}
          iconSize={style?.iconSize}
          size={style?.size}
        />
      );
    case PaymentReservationStatusEnum.CANCELED:
      return (
        <Badge
          Icon={CloseCircleRedIcon}
          status="fail"
          label={t("Booking.PaymentStatus.canceled")}
          labelStyle={style?.labelStyle}
          iconSize={style?.iconSize}
          size={style?.size}
        />
      );
    case PaymentReservationStatusEnum.PARTIALLY_PAID:
      return (
        <Badge
          Icon={ClockWarningIcon}
          label={t("Booking.PaymentStatus.partiallyPaid")}
          customBg="bg-[#FFF4D5]"
          customColor="text-[#AD5700]"
          labelStyle={style?.labelStyle}
          iconSize={style?.iconSize}
          size={style?.size}
        />
      );
    case PaymentReservationStatusEnum.PAID:
      return (
        <Badge
          Icon={CheckCircleGreenIcon}
          label={t("Booking.PaymentStatus.paid")}
          status="success"
          labelStyle={style?.labelStyle}
          iconSize={style?.iconSize}
          size={style?.size}
        />
      );
    case ReservationExtraOrderStatusEnum.PRE_AUTHORIZED:
      return (
        <Badge
          Icon={ClockWarningIcon}
          label={t("Booking.PaymentStatus.preAuthorized")}
          customBg="bg-[#FFF4D5]"
          customColor="text-[#AD5700]"
          labelStyle={style?.labelStyle}
          iconSize={style?.iconSize}
          size={style?.size}
        />
      );
    case PaymentReservationStatusEnum.REFUNDED:
      return (
        <Badge
          Icon={MoneyIcon}
          label={t("Booking.PaymentStatus.refunded")}
          customBg="bg-[#EDEDED]"
          customColor="text-[#6F6F6F]"
          labelStyle={style?.labelStyle}
          iconSize={style?.iconSize}
          size={style?.size}
        />
      );
  }
};

export const getContractStatusBadge = (status?: CONTRACT_STATUS | null) => {
  const {t} = useTranslation();

  const result: {label: string; status: BadgeStatus} = {
    label: "",
    status: "fail",
  };

  switch (status) {
    case CONTRACT_STATUS.KO:
      result.label = t("Global.ko");
      result.status = "fail";
      return result;
    case CONTRACT_STATUS.OK:
      result.label = t("Global.ok");
      result.status = "success";
      return result;
    default:
      return result;
  }
};

export const getPaymentReservationAccountType = (
  type: PaymentAccountTypeEnum,
  t?: TFunction<"translation", undefined>
): string => {
  if (!t) {
    t = useTranslation().t;
  }

  let text: string = "";

  switch (type) {
    case PaymentAccountTypeEnum.BANK_TRANSFER:
      text = t("Payments.PaymentReservationList.bankTransferPayment") || "";
      break;
    case PaymentAccountTypeEnum.BANK_CHECK:
      text = t("Payments.PaymentReservationList.bankCheckPayment") || "";
      break;
    case PaymentAccountTypeEnum.CASH:
      text = t("Payments.PaymentReservationList.cashPayment") || "";
      break;
    case PaymentAccountTypeEnum.STRIPE:
      text = t("Payments.PaymentReservationList.stripePayment") || "";
      break;
    case PaymentAccountTypeEnum.BY_PLATFORM:
      text = t("Payments.PaymentReservationList.platformPayment") || "";
      break;
  }

  return capitalizeFirstLetter(text);
};

export const getReservationExtraOrderStatusBadge = (
  status?: ReservationExtraOrderStatusEnum | null | undefined,
  t?: TFunction<"translation", undefined>,
  style?: {
    iconSize?: number;
    labelStyle?: string;
    size?: BadgeSize;
  }
) => {
  if (!t) {
    t = useTranslation().t;
  }

  switch (status) {
    case ReservationExtraOrderStatusEnum.UNPAID:
      return (
        <Badge
          Icon={CloseCircleRedIcon}
          status="fail"
          label={t("Booking.PaymentStatus.unpaid")}
          labelStyle={style?.labelStyle}
          iconSize={style?.iconSize}
          size={style?.size}
        />
      );

    case ReservationExtraOrderStatusEnum.PRE_AUTHORIZED:
      return (
        <Badge
          Icon={ClockWarningIcon}
          label={t("Booking.PaymentStatus.preAuthorized")}
          customBg="bg-[#FFF4D5]"
          customColor="text-[#AD5700]"
          labelStyle={style?.labelStyle}
          iconSize={style?.iconSize}
          size={style?.size}
        />
      );

    case ReservationExtraOrderStatusEnum.PAID:
      return (
        <Badge
          Icon={CheckCircleGreenIcon}
          label={t("Booking.PaymentStatus.paid")}
          status="success"
          labelStyle={style?.labelStyle}
          iconSize={style?.iconSize}
          size={style?.size}
        />
      );

    case ReservationExtraOrderStatusEnum.REFUNDED:
      return (
        <Badge
          Icon={MoneyIcon}
          label={t("Booking.PaymentStatus.refunded")}
          customBg="bg-[#EDEDED]"
          customColor="text-[#6F6F6F]"
          labelStyle={style?.labelStyle}
          iconSize={style?.iconSize}
          size={style?.size}
        />
      );

    case ReservationExtraOrderStatusEnum.CANCELED:
      return (
        <Badge
          Icon={CloseCircleRedIcon}
          status="fail"
          label={t("Booking.PaymentStatus.canceled")}
          labelStyle={style?.labelStyle}
          iconSize={style?.iconSize}
          size={style?.size}
        />
      );
    default:
      return null;
  }
};
