import { UseFormGetValues } from "react-hook-form";
import {
  ReservationGuestForm,
  ReservationGuestPayload,
} from "../../components/Reservation/Guest/GuestCard.type";
import paths from "../../constants/paths";
import { get, post } from "../../helpers/APIHelper";
import { ValueType } from "../../types/commonTypes";
import { ReservationResponse } from "../../types/GETTypes";
import { debounce } from "lodash";

/**
 *
 * @param reservationId
 * @param onSuccess
 * @param onError
 * @param onStart
 * @param onEnd
 */
export const useReservationById = async (
  reservationId: ValueType | undefined,
  onSuccess: (reservationResponse: ReservationResponse | undefined) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (reservationId !== undefined) {
      if (typeof onStart === "function") {
        onStart();
      }

      const response = await get(
        `${import.meta.env.VITE_API_URL}${
          paths.API.RESERVATIONS
        }/${reservationId}`
      );
      if (response?.data?.success) {
        onSuccess(response?.data.result);
      } else {
        onError(response?.data?.message ?? response.message);
      }
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useUpdateReservationGuest = async (
  reservationGuestId: ValueType | undefined,
  values: UseFormGetValues<ReservationGuestForm>,
  onSuccess: (res?:any) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }

  try {
    if (reservationGuestId !== undefined) {
      const payload: ReservationGuestPayload = {
        locale: values("communicationLanguage"),
        phone: values("phoneNumber"),
        first_name: values("firstName"),
        last_name: values("lastName"),
        email: values("email"),
        address: values("address"),
        postal_code: values("postalCode"),
        city: values("city"),
        country: values("country"),
        // photo: values("photo") ?? "",
        country_code: values("countryCode"),
      };

      const response = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.RESERVATION_GUESTS
        }/${reservationGuestId}`,
        {
          _method: "PUT",
          ...payload,
        }
      );

      if (response?.data?.success) {
        onSuccess(response?.data?.result);
      } else {
        onError(response?.data?.message ?? response.message);
      }
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useGetMessage = async (
  reservationId: string | undefined,
  onSuccess: (data: any) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.MESSAGES}/${reservationId}`
    );
    //      `${import.meta.env.VITE_API_URL}${paths.API.MESSAGES}?read_status_filter=0&limit=100`

    if (response?.data?.success) {
      onSuccess(response?.data?.result);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useGetConversationNotRead = async (
    onSuccess: (data: any) => void,
    onError: (message: string | undefined) => void,
    onStart?: () => void,
    onEnd?: () => void
) => {
  try {
    const response = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.MESSAGES}?read_status_filter=0&limit=100`
    );

    if (response?.data?.success) {
      onSuccess(response?.data?.result);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useGetConversations = async (
  data: any,
  onSuccess: (data: any) => void,
  onError: (message: string | undefined) => void,
  onEnd?: () => void
) => {
  try {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.MESSAGES}`,
      { params: data }
    );
    if (response?.data?.success) {
      onSuccess(response?.data?.result);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const debouncedUseGetConversations = debounce(useGetConversations, 300);
