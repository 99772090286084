import React, { useCallback, useEffect, useState } from "react";
import { FullScreenLayout } from "../../components/Layout/FullScreenLayout/FullScreenLayout";
import SuperhoteIcon from "../../assets/icons/superhote.svg?react";
import { useTranslation } from "react-i18next";
import { Carousel } from "../../components/Common/Carousel/Carousel";
import Carousel1Image from "../../assets/images/register-carousel-1.svg?react";
import Carousel2Image from "../../assets/images/register-carousel-2.svg?react";
import Carousel3Image from "../../assets/images/register-carousel-3.svg?react";
import { RegisterMemberInfosForm } from "../../components/RegisterMember/RegisterMemberStepInfos.type";
import { RegisterMemberPasswordForm } from "../../components/RegisterMember/RegisterMemberStepPassword.type";
import { get, post } from "../../helpers/APIHelper";
import paths from "../../constants/paths";
import { useNavigate, useParams } from "react-router-dom";
import currencies from "../../res/currencies";
import { RegisterMemberSteps } from "../../components/RegisterMember/RegisterMemberSteps";
import { RegisterMemberAccessDenied } from "../../components/RegisterMember/RegisterMemberAccessDenied";

export const RegisterMemberPage: React.FC<{}> = () => {
  const { invitId } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loadingInvit, setLoadingInvit] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [accepted, setAccepted] = useState<number | undefined>(undefined);
  const [currentStep, setCurrentStep] = useState<"infos" | "password">("infos");
  const [stepData, setStepData] = useState<{
    infos: RegisterMemberInfosForm;
    password: RegisterMemberPasswordForm;
  }>({
    infos: {
      email: "",
      firstName: "",
      lastName: "",
      company: "",
      phone: "",
      address: "",
      city: "",
      zip: "",
      country: 73,
      language: "fr",
      currency: 23,
      accept: false,
    },
    password: {
      password: "",
      confirmationPassword: "",
    },
  });

  const fetchInvit = useCallback(async () => {
    setLoadingInvit(true);

    const res = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.INVIT
      }/${invitId}`
    );

    if (res.data?.success) {
      const { email, accepted: newAccepted } = res.data.result?.invitation;

      setAccepted(newAccepted);
      setStepData({ ...stepData, infos: { ...stepData.infos, email } });
    } else {
      setError(res.response?.data?.message);
    }

    setLoadingInvit(false);
  }, []);

  useEffect(() => {
    fetchInvit();
  }, []);

  const handleStepInfos = (infos: RegisterMemberInfosForm) => {
    setStepData({ ...stepData, infos });
    setCurrentStep("password");
  };

  const handleBackToInfos = () => {
    setCurrentStep("infos");
  };

  const handleStepPassword = async (password: RegisterMemberPasswordForm) => {
    setLoading(true);
    setError(null);
    setStepData({ ...stepData, password });

    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.CREATE_MEMBER
      }/${invitId}`,
      {
        first_name: stepData.infos.firstName,
        last_name: stepData.infos.lastName,
        company_name: stepData.infos.company,
        phone: stepData.infos.phone,
        address: stepData.infos.address,
        city: stepData.infos.city,
        postal_code: stepData.infos.zip,
        country_id: stepData.infos.country,
        currency_name: currencies?.find((c) => c.id === stepData.infos.currency)
          ?.name,
        locale: stepData.infos.language,
        password: password.password,
        time_zone: "AZE",
      }
    );

    if (res.data?.success) {
      const loginResponse = await post(
        `${import.meta.env.VITE_API_URL}/auth/login`,
        {
          email: stepData.infos.email,
          password: password.password,
        }
      );

      if (loginResponse.data?.success) {
        navigate(paths.PROGRESSION);
      } else {
        navigate(paths.LOGIN);
      }
    } else {
      setError(res?.response?.data?.message);
    }

    setLoading(false);
  };

  if (!loadingInvit && (accepted === undefined || accepted === 1))
    return <RegisterMemberAccessDenied />;

  return (
    <>
      <div className="hidden md:block w-full h-full">
        <FullScreenLayout isCheckAuthEnabled={false}>
          {/* Left */}
          <div className="w-2/5 max-w-md p-2">
            <div className="flex flex-col min-h-full bg-subtle border-1 border-element-border/50 p-8 rounded-6px">
              <div className="flex-1 pb-4">
                <SuperhoteIcon />
              </div>

              <div>
                <Carousel
                  items={[
                    {
                      Image: Carousel1Image,
                      title: t("Register.carousel1Title"),
                      subTitle: t("Register.carousel1SubTitle"),
                    },
                    {
                      Image: Carousel2Image,
                      title: t("Register.carousel2Title"),
                      subTitle: t("Register.carousel2SubTitle"),
                    },
                    {
                      Image: Carousel3Image,
                      title: t("Register.carousel3Title"),
                      subTitle: t("Register.carousel3SubTitle"),
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          {/* Right */}
          <RegisterMemberSteps
            loadingInvit={loadingInvit}
            loading={loading}
            error={error}
            currentStep={currentStep}
            stepData={stepData}
            onBackToInfos={handleBackToInfos}
            onSubmitStepInfos={handleStepInfos}
            onSubmitStepPassword={handleStepPassword}
          />
        </FullScreenLayout>
      </div>

      {/* Mobile view */}
      <div className="flex flex-col md:hidden w-full h-full">
        <div className="px-12 pt-8">
          <SuperhoteIcon />
        </div>

        <RegisterMemberSteps
          loadingInvit={loadingInvit}
          loading={loading}
          error={error}
          currentStep={currentStep}
          stepData={stepData}
          onBackToInfos={handleBackToInfos}
          onSubmitStepInfos={handleStepInfos}
          onSubmitStepPassword={handleStepPassword}
        />
      </div>
    </>
  );
};
