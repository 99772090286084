import React, {useEffect, useState} from "react";
import ExtraOrderModal, {popUp} from "./ExtraOrderModal";
import {
  GuestPageExtraDetail,
  GuestPageExtraOrder,
} from "../../../../types/GETTypes";
import {useTranslation} from "react-i18next";
import InfoCard from "../../../Common/Card/InfoCard";
import {ReservationExtraOrderStatusEnum} from "../../../../enums/GETenums";
import ExtraCard from "./ExtraCard";

const ExtraOrderListCard = ({
  currencySymbol = "€",
  extraOrder,
  setExtraOrder,
  locale = "FR",
  openPop,
}: {
  extraOrder: GuestPageExtraOrder | undefined;
  setExtraOrder: React.Dispatch<
    React.SetStateAction<GuestPageExtraOrder | undefined>
  >;
  currencySymbol?: string;
  locale?: string;
  openPop: (popUpToOpen: popUp, extra?: GuestPageExtraDetail) => void;
}) => {
  const {t} = useTranslation();
  const {total_price_value, payment_status, extras} = extraOrder || {};
  const [total, setTotal] = useState(total_price_value);

  const onChangeExtraAvailability = (extraId: number, value: boolean) => {
    if (extraOrder && extras) {
      setExtraOrder((order) => {
        return order
          ? {
              ...order,
              extras: extras.map((extra) =>
                extra.id === extraId
                  ? {...extra, extra_available: value}
                  : extra
              ),
            }
          : undefined;
      });
    }
  };

  const calculateTotal = extraOrder?.extras
    .filter((extra) => extra?.extra_available)
    .reduce((total, current) => {
      return total + current.total_price_value;
    }, 0);

  useEffect(() => {
    setTotal(extraOrder?.total_price_value);
  }, [extraOrder]);
  

  const InfoByStatus = () => {
    switch (payment_status) {
      case ReservationExtraOrderStatusEnum.REFUNDED:
      case ReservationExtraOrderStatusEnum.CANCELED:
        return (
          <InfoCard
            title={t(
              "Booking.ExtrasOrder.ExtraOrderModal.extraUnavailableTitle"
            )}
            description={t(
              "Booking.ExtrasOrder.ExtraOrderModal.extraUnavilableDescription"
            )}
          />
        );
      case ReservationExtraOrderStatusEnum.PRE_AUTHORIZED:
        return (
          <InfoCard
            description={t(
              "Booking.ExtrasOrder.ExtraOrderModal.extraAvailableDescription"
            )}
          />
        );
      case ReservationExtraOrderStatusEnum.PAID:
        return (
          <InfoCard
            description={t(
              "Booking.ExtrasOrder.ExtraOrderModal.extraToRefundDescription"
            )}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="flex flex-col space-y-2">
        {extras?.map((extra) => (
          <ExtraCard
            extra={extra}
            locale={locale}
            currency={currencySymbol}
            onChange={onChangeExtraAvailability}
            openPop={openPop}
          />
        ))}
      </div>
      <div className="flex flex-col space-y-2">
        <span className="text-low-contrast  text-base">
          {t("Booking.ExtrasOrder.ExtraOrderModal.total")}
        </span>
        <span className="text-high-contrast font-bold text-base">
          {calculateTotal !== undefined ? calculateTotal : total}{" "}
          {currencySymbol}
        </span>
      </div>
      <div>
        <InfoByStatus />
      </div>
    </>
  );
};

export default ExtraOrderListCard;
