import React, {useState} from "react";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import {useTranslation} from "react-i18next";
import LayoutBlockComponent from "../Common/LayoutBlockComponent";
import {Button} from "../../Common/Button/Button";
import WhiteStarIcon from "../../../assets/icons/star-white.svg?react";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import {TextAreaInput} from "../../Common/TextAreaInput/TextAreaInput";
import {Rating} from "../../Common/Rating/Rating";
import {post} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import {Badge} from "../../Common/Badge/Badge";
import CheckCircleGreenIcon from "../../../assets/icons/check-circle-green.svg?react";

const ReviewComponent = () => {
  const {t} = useTranslation();
  const {guestPageData, rentalPreview, isPreviewMode, userPreview} =
    useGuestPageDataStore();
  const {
    reviews_texts,
    guest: guestData,
    id: reservation_id,
  } = guestPageData || {};
  const {guest_page: {reviews_texts: reviewTextPreview} = {}} =
    rentalPreview || {};
  const {email} = guestData || {};
  const guest = isPreviewMode ? userPreview : guestData;
  const savedLanguage = localStorage.getItem("language")
  const lang = (savedLanguage ?? guest?.locale ?? "en")?.toLowerCase();
  const txt = isPreviewMode ? reviewTextPreview : reviews_texts;
  const subTitle = txt?.[lang]
  ? txt[lang]
  : txt && txt["en"]
  ? txt["en"]
  : "";
  const [openReview, setOpenReview] = useState(false);
  const [loadReview, setLoadReview] = useState(false);
  const [reviewText, setReviewText] = useState("");
  const [rating, setRating] = useState(5);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleRatingChange = (newRating: number) => {
    setRating(newRating);
  };

  const handleReview = async () => {
    if (isPreviewMode) {
      return;
    }
    setLoadReview(true);
    const data = {
      reservation_id: String(reservation_id),
      email,
      guest_page_rating_stars: rating,
      guest_page_review_text: reviewText,
    };
    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.GUEST_PAGE.ADD_RESERVATION_REVIEW
      }`,
      data
    );
    if (res?.data?.success) {
      setError("");
      close();
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } else {
      setError(res?.response?.data?.message);
    }

    setLoadReview(false);
  };

  const close = () => {
    setError("");
    setRating(5);
    setReviewText("");
    setOpenReview(false);
  };

  return (
    <LayoutBlockComponent
      title={`${t("GuestPage.Reviews.title")} ⭐`}
      subTitle={subTitle}
      blockStyle={{
        titleStyle: "flex flex-row items-center md:text-[32px]",
        childrenDivStyle: "pl-2",
        subTitleStyle: "md:mt-3",
      }}
      displayTitles
    >
      {openReview ? (
        <div className="flex flex-col">
          <div className="flex mb-4 justify-end">
            <Rating
              value={rating}
              displayNote={false}
              classNames={{starDiv: "space-x-4 h-6", star: "h-8 w-6"}}
              onChange={handleRatingChange}
              isClickable={true}
              maxStars={5}
            />
          </div>
          <div className="">
            <TextAreaInput
              value={reviewText}
              placeholder={t("GuestPage.Reviews.yourComment")}
              onTextChange={setReviewText}
              rows={3}
              classNames={{
                input: "md:text-lg",
                label: "md:text-lg",
                labelRequired: "md:text-xl",
                error: "md:text-lg",
              }}
            />
          </div>
          <div className="w-full">
            <ErrorMessage childrenClassName="md:text-lg">{error}</ErrorMessage>
          </div>

          <div className="flex flex-row w-full justify-between mt-2 md:mb-4">
            <Button
              buttonClassName="w-[180px] md:h-12"
              textClassName="md:text-xl"
              type="secondary"
              disabled={loadReview}
              displayLargeBtn={false}
              onClick={close}
            >
              {t("Global.cancel")}
            </Button>
            <Button
              buttonClassName="w-[180px] md:h-12"
              textClassName="md:text-xl"
              RightIcon={CheckWhiteIcon}
              disabled={isPreviewMode || loadReview}
              displayLargeBtn={false}
              loading={loadReview}
              onClick={handleReview}
            >
              {t("Global.validate")}
            </Button>
          </div>
        </div>
      ) : (
        <>
          {success ? (
            <div className="w-full md:w-2/3 mb-4">
              <Badge
                Icon={CheckCircleGreenIcon}
                label={t("GuestPage.Reviews.successMessage")}
                status="success"
              />
            </div>
          ) : null}
          <Button
            buttonClassName="md:h-12"
            textClassName="md:text-xl"
            RightIcon={WhiteStarIcon}
            disabled={loadReview}
            displayLargeBtn={true}
            loading={loadReview}
            onClick={() => setOpenReview(true)}
          >
            {t("GuestPage.Reviews.writeReview")}
          </Button>
        </>
      )}
    </LayoutBlockComponent>
  );
};

export default ReviewComponent;
