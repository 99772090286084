import React from "react";
import { ProgressionBarProps } from "./ProgressionBar.type";

export const ProgressionBar: React.FC<ProgressionBarProps> = ({
  progression,
}) => {
  return (
    <div className="w-full h-3 bg-white rounded-6px relative overflow-hidden border-1 border-element-border-light">
      {typeof progression === "number" ? (
        <div
          className={`h-full bg-icon-active`}
          style={{ width: `${progression}%` }}
        />
      ) : (
        <div className={`h-full w-[100%] bg-slate-200`} />
      )}
    </div>
  );
};
