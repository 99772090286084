import React from "react";
import { Separator } from "../../components/Common/Separator/Separator";

export const PaymentDepositPageSkeleton: React.FC = () => {
  return (
    <div className="flex flex-col items-center w-full h-screen animate-pulse gap-y-10">
      <header className="flex items-center justify-between w-full px-12 py-10 bg-slate-200">
        <div className="w-16 h-16 bg-slate-200"></div>
        <p className="h-4 rounded-lg w-80 bg-slate-300"></p>
      </header>

      <section className="flex justify-center w-full max-w-5xl gap-x-10">
        <article className="flex flex-col flex-1 space-y-4">
          <h2 className="w-48 h-3 rounded-lg bg-slate-200"></h2>

          {/* RENTAL */}
          <div className="flex items-center space-x-3">
            <div className="w-28 h-28 bg-slate-200"></div>
            <div>
              <p className="h-3 rounded w-52 bg-slate-200"></p>
              <p className="w-40 h-2 mt-3 rounded bg-slate-200"></p>
            </div>
          </div>

          <div className="flex items-center justify-between">
            {/* CHECKIN / CHECKOUT */}
            <div className="flex flex-col justify-between space-y-5">
              <div>
                <div className="flex items-center space-x-2">
                  <p className="rounded w-7 h-7 bg-slate-200"></p>
                  <p className="w-32 h-2 rounded bg-slate-200"></p>
                </div>
                <p className="w-32 h-2 mt-2 rounded bg-slate-200"></p>
                <p className="w-32 h-2 mt-2 rounded bg-slate-200"></p>
              </div>

              <div>
                <div className="flex items-center space-x-2">
                  <p className="rounded w-7 h-7 bg-slate-200"></p>
                  <p className="w-32 h-2 rounded bg-slate-200"></p>
                </div>
                <p className="w-32 h-2 mt-2 rounded bg-slate-200"></p>
                <p className="w-32 h-2 mt-2 rounded bg-slate-200"></p>
              </div>
            </div>

            <div className="flex flex-col space-y-2">
              <div className="flex items-center justify-end space-x-2">
                <p className="w-32 h-2 rounded bg-slate-200"></p>
                <div className="w-5 h-5 bg-slate-200"></div>
              </div>

              <div className="flex items-center justify-end space-x-2">
                <p className="w-32 h-2 rounded bg-slate-200"></p>
                <div className="w-5 h-5 bg-slate-200"></div>
              </div>

              <div className="flex items-center justify-end space-x-2">
                <p className="w-32 h-2 rounded bg-slate-200"></p>
                <div className="w-5 h-5 bg-slate-200"></div>
              </div>

              <div className="flex items-center justify-end space-x-2">
                <p className="w-32 h-2 rounded bg-slate-200"></p>
                <div className="w-5 h-5 bg-slate-200"></div>
              </div>
            </div>
          </div>

          <Separator />

          <div className="flex flex-col pt-6 gap-y-6">
            <h4 className="h-3 rounded w-52 bg-slate-200"></h4>

            {/* PAYMENT SCHEDULE ITEMS */}
            <div className="flex flex-col space-y-2">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="w-6 h-6 bg-slate-200"></div>
                  <p className="w-32 h-2 rounded bg-slate-200"></p>
                </div>

                <div className="flex items-center space-x-3">
                  <span className="w-32 h-2 rounded bg-slate-200"></span>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <span className="w-32 h-2 rounded bg-slate-200"></span>
              <p className="w-32 h-2 rounded bg-slate-200"></p>
            </div>
          </div>
        </article>

        <article className="flex flex-col items-center justify-between flex-1 w-full h-full p-6 bg-slate-200">
          <div className="w-full h-8 rounded bg-slate-300"></div>

          <div className="flex items-center justify-between w-full space-x-3">
            <div className="flex-1 h-8 rounded bg-slate-300"></div>
            <div className="flex-1 h-8 rounded bg-slate-300"></div>
          </div>

          <div className="w-full h-8 rounded bg-slate-300"></div>

          <div className="flex flex-col w-full space-y-2">
            <p className="w-full h-2 rounded bg-slate-300"></p>
            <p className="w-full h-2 rounded bg-slate-300"></p>
            <p className="w-full h-2 rounded bg-slate-300"></p>
          </div>

          <div className="w-full h-8 rounded bg-slate-300"></div>

          <p className="w-56 h-4 rounded bg-slate-300"></p>
        </article>
      </section>
    </div>
  );
};
