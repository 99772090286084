import React from "react";
import { useTranslation } from "react-i18next";
import AirbnbIcon from "../../../../assets/icons/airbnb.svg?react";
import BookingIcon from "../../../../assets/icons/booking.svg?react";
import TrendingUpIcon from "../../../../assets/icons/trending-up.svg?react";
import { Card } from "../../../Common/Card/Card";
import { SettingElement } from "../../../Common/SettingElement/SettingElement";

export const RentalPlatformRateSkeleton: React.FC = ({}) => {
  const { t } = useTranslation();

  return (
    <Card
      Icon={TrendingUpIcon}
      label={t("Rental.Pricing.PriceMultipliers.title")}
      button={{
        skeleton: true,
      }}
    >
      <div className="flex flex-col space-y-3 animate-pulse">
        {/* AIRBNB PRICE MULTIPLIER */}
        <SettingElement
          Icon={AirbnbIcon}
          title={t("Rental.Pricing.PriceMultipliers.airbnbTitle")}
          description={t("Rental.Pricing.PriceMultipliers.airbnbDescription")}
        >
          <p className="w-12 h-2 text-base font-bold rounded bg-active"></p>
        </SettingElement>

        {/* BOOKING PRICE MULTIPLIER */}
        <SettingElement
          Icon={BookingIcon}
          title={t("Rental.Pricing.PriceMultipliers.bookingTitle")}
          description={t("Rental.Pricing.PriceMultipliers.bookingDescription")}
        >
          <p className="w-12 h-2 text-base font-bold rounded bg-active"></p>
        </SettingElement>
      </div>
    </Card>
  );
};
