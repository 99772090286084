import React from "react";
import { useTranslation } from "react-i18next";
import PlusIcon from "../../../../assets/icons/plus.svg?react";
import SendMoneyIcon from "../../../../assets/icons/send-money-2.svg?react";
import { Card } from "../../../Common/Card/Card";

export const RentalPaymentPolicyCardSkeleton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Card
      Icon={SendMoneyIcon}
      label={t("Rental.Pricing.PaymentPolicy.title")}
      button={{
        Icon: PlusIcon,
      }}
    >
      <div className="flex flex-col animate-pulse gap-y-3">
        <div className="flex items-center h-24 p-4 cursor-pointer border-1 rounded-6px bg-slate-200 border-element-border/50"></div>
        <div className="flex items-center h-24 p-4 cursor-pointer border-1 rounded-6px bg-slate-200 border-element-border/50"></div>
        <div className="flex items-center h-24 p-4 cursor-pointer border-1 rounded-6px bg-slate-200 border-element-border/50"></div>
      </div>
    </Card>
  );
};
