import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../Common/Button/Button";
import { CarouselWizard } from "../../../Common/CarouselWizard/CarouselWizard";
import { RightModal } from "../../../Common/RightModal/RightModal";
import { BookingConnectModalProps } from "./BookingConnectModal.type";

import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import { Separator } from "../../../Common/Separator/Separator";
import { BookingConnectStep1 } from "./BookingConnectStep1";
import { BookingConnectStep2 } from "./BookingConnectStep2";

export const BookingConnectModal: React.FC<BookingConnectModalProps> = ({
  isVisible,
  onClose,
  onFinish,
}) => {
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState(0);

  const handleCancel = () => {
    if (currentStep === 0) {
      onClose();
    } else {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const handleSubmit = () => {
    if (currentStep === 1) {
      onFinish();
      onClose();
    }
  };

  return (
    <RightModal
      isVisible={isVisible}
      onClose={onClose}
      title={t("Progression.bookingConnect")}
    >
      <div className="flex flex-col w-full">
        <div className="flex-1 overflow-y-scroll">
          <CarouselWizard
            currentStepIndex={currentStep}
            onChangeStep={(value) => setCurrentStep(value)}
            steps={[
              {
                title: t("Progression.bookingConnectStep1Title"),
                Node: (
                  <BookingConnectStep1
                    onNext={() => setCurrentStep((prev) => prev + 1)}
                  />
                ),
              },
              {
                title: t("Progression.bookingConnectStep2Title"),
                Node: <BookingConnectStep2 />,
              },
            ]}
          />
        </div>

        <Separator />

        <div className="flex gap-3">
          <Button type="secondary" onClick={handleCancel}>
            {t("Global.cancel")}
          </Button>
          <Button
            RightIcon={CheckIcon}
            disabled={currentStep === 0}
            onClick={handleSubmit}
          >
            {t("Global.finish")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
