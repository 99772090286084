import React from "react";
import { Card } from "../../Common/Card/Card";
import { useTranslation } from "react-i18next";
import FileTextIcon from "../../../assets/icons/file-text.svg?react";

export const ReservationNoteCardSkeleton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Card
      label={t("Booking.Note.title")}
      button={{ Icon: FileTextIcon, label: t("Global.edit"), skeleton: true }}
    >
      <div className="flex flex-col gap-2 animate-pulse">
        <p className="h-3 w-full bg-slate-200 rounded"></p>
        <p className="h-3 w-full bg-slate-200 rounded"></p>
        <p className="h-3 w-full bg-slate-200 rounded"></p>
        <p className="h-3 w-full bg-slate-200 rounded"></p>
        <p className="h-3 w-32 bg-slate-200 rounded"></p>
      </div>
    </Card>
  );
};
