/**
 * Component for show platform explains.
 *
 * @author Elysio
 * @constructor
 */
import { PlatformProps } from "./Platform.type";

import React from "react";

import { Button } from "../Common/Button/Button";
import { Badge } from "../Common/Badge/Badge";
import { useTranslation } from "react-i18next";

import ArrowRight from "../../assets/icons/arrow-right.svg?react";

export const Platform = ({
  Icon,
  title,
  description,
  page,
  className,
  button = false,
  onClickButton = () => {},
  handleClickPlatform = (page: string) => {},
  buttonName = "",
  longDescription = "",
  isLongDescription = false,
  isConnected = false,
}: PlatformProps) => {
  const { t } = useTranslation();

  return (
    <article
      onClick={() => handleClickPlatform(page)}
      className={
        "border border-gray-300 bg-gray-50 rounded-xl p-3 flex justify-between gap-4 items-center " +
        (isLongDescription
          ? ""
          : "w-[48%] hover:scale-[103%] hover:shadow-lg transition cursor-pointer ") +
        className
      }
    >
      <div
        className={
          "flex items-center gap-4 " + (isLongDescription ? "w-full" : "w-4/5")
        }
      >
        {/* Icône */}
        <Icon className="w-16 h-16" />

        {/* Contenu principal */}
        <div className="flex flex-col gap-2 flex-grow">
          <h1 className="font-bold text-lg">{title}</h1>

          {isLongDescription ? (
            <p className="text-gray-500 w-2/6">{longDescription}</p>
          ) : (
            <p className="text-gray-500">{description}</p>
          )}
        </div>

        {/* Bouton conditionnel */}
        {button &&
          (isLongDescription ? (
            isConnected ? (
              <div className="flex items-center gap-4">
                <Button
                  RightIcon={ArrowRight}
                  type={"secondary"}
                  onClick={onClickButton}
                >
                  {t("ThirdParty.addOtherAccount")}
                </Button>
                <Badge status={"success"} label={t("ThirdParty.connected")} />
              </div>
            ) : (
              <div className="flex-shrink-0">
                <Button onClick={onClickButton}>{buttonName}</Button>
              </div>
            )
          ) : (
            <div className="flex-shrink-0">
              <Button onClick={onClickButton}>{buttonName}</Button>
            </div>
          ))}
      </div>

      {/* Statut de connexion */}
      {!isLongDescription && (
        <div className="w-auto">
          {isConnected ? (
            <Badge status={"success"} label={t("ThirdParty.connected")} />
          ) : (
            <Badge status={"pending"} label={t("ThirdParty.noConnected")} />
          )}
        </div>
      )}
    </article>
  );
};
