import React from "react";
import {
  CALENDAR_CELL_WIDTH,
  CALENDAR_RESTRICTION_HEIGHT,
} from "../../constants/calendar";

export const CalendarRestrictionCellSkeleton = () => {
  return (
    <td
      style={{
        minWidth: CALENDAR_CELL_WIDTH,
        height: CALENDAR_RESTRICTION_HEIGHT,
      }}
      className={`border-r-1 border-t-1 border-element-border cursor-pointer bg-gray-50`}
    >
      <div className="h-2 w-4 bg-slate-300 ml-12 animate-pulse" />
    </td>
  );
};
