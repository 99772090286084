import React, { useEffect, useState } from "react";
import { UseFormGetValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import CalendarIcon from "../../../assets/icons/calendar.svg?react";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import InputCrossIcon from "../../../assets/icons/input-cross.svg?react";
import MoneyBagIcon from "../../../assets/icons/money-bag.svg?react";
import { PaymentAccountTypeEnum } from "../../../enums/GETenums";
import { getFormattedPrice } from "../../../helpers/currencyHelper";
import { capitalizeFirstLetter } from "../../../helpers/stringHelper";
import { UseModal } from "../../../hooks/useModal";
import {
  NotificationStatusesListItemResponse,
  PaymentReservationScheduleResponse,
} from "../../../types/GETTypes";
import { Button } from "../../Common/Button/Button";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import {
  PaymentPolicyDepositPaymentOptionEnum,
  PaymentPolicyForm,
  PaymentPolicyItem,
  PaymentPolicyResponse,
} from "../../Payment/Payment.type";
import { AddReservationType } from "../AddReservation.type";
import { useAddReservationNextStep } from "./AddReservationPayment.hooks";

export const AddReservationPayment: React.FC<{
  modal: UseModal<{}>;
  paymentPolicyFormValues: UseFormGetValues<PaymentPolicyForm>;
  reservation: AddReservationType;
  onCancel: () => void;
  onNext: (
    reservation_id: number,
    payment_schedule: PaymentReservationScheduleResponse,
    notification_statuses: NotificationStatusesListItemResponse[]
  ) => void;
  onBack: () => void;
  currentPolicy: PaymentPolicyResponse | undefined;
}> = ({
  modal,
  paymentPolicyFormValues,
  reservation,
  onCancel,
  onNext,
  onBack,
  currentPolicy,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();

  const getNbTimesPayment = (pp: PaymentPolicyItem[] | undefined): string => {
    let string = t("AddReservation.Payment.NbTimes.one");
    if (pp) {
      if (pp.length === 1) {
        string = t("AddReservation.Payment.NbTimes.one");
      } else if (pp.length === 2) {
        string = t("AddReservation.Payment.NbTimes.two");
      } else if (pp.length === 3) {
        string = t("AddReservation.Payment.NbTimes.three");
      }
    }

    return string;
  };

  const handleNext = (values: PaymentPolicyForm) => {
    useAddReservationNextStep({
      reservation,
      values,
      onStart: () => {
        setError(undefined);
        setLoading(true);
      },
      onEnd: (
        reservationId: number,
        payment_schedule: PaymentReservationScheduleResponse,
        notification_statuses: NotificationStatusesListItemResponse[]
      ) => {
        setLoading(false);
        onNext(reservationId, payment_schedule, notification_statuses);
      },
      onError: (message: string) => setError(message),
    });
  };

  const [paymentMethods, setPaymentMethods] = useState<
    Set<PaymentAccountTypeEnum | string>
  >(new Set());

  useEffect(() => {
    if (currentPolicy) {
      setLoading(false);
    }

    if (
      currentPolicy?.payments_policy_items &&
      currentPolicy?.payments_policy_items.length > 0
    ) {
      setPaymentMethods(
        new Set(
          currentPolicy.payments_policy_items
            .map((pp) => {
              if (pp.payment_account_type === PaymentAccountTypeEnum.CASH) {
                return t("Global.PaymentAccounts.cash").toLowerCase();
              } else if (
                pp.payment_account_type === PaymentAccountTypeEnum.BANK_CHECK
              ) {
                return t("Global.PaymentAccounts.bank_check").toLowerCase();
              } else if (
                pp.payment_account_type === PaymentAccountTypeEnum.BANK_TRANSFER
              ) {
                return t("Global.PaymentAccounts.transfer").toLowerCase();
              }

              return capitalizeFirstLetter(pp.payment_account_type ?? "");
            })
            .filter((value) => value.trim())
        )
      );
    }
  }, [currentPolicy]);

  const displayPaymentMethods = (
    paymentMethods: Set<PaymentAccountTypeEnum | string>
  ) => {
    let newPaymentMethods = Array.from(paymentMethods);

    if (newPaymentMethods.length === 0) return "";
    if (newPaymentMethods.length === 1) return newPaymentMethods[0];
    if (newPaymentMethods.length === 2)
      return newPaymentMethods.join(` ${t("Global.and")} `);

    return (
      newPaymentMethods.slice(0, -1).join(", ") +
      ` ${t("Global.and")} ` +
      newPaymentMethods[newPaymentMethods.length - 1]
    );
  };

  const displayDepositPaymentMethod = (): string | undefined => {
    let newDepositPaymentMethod = undefined;

    if (currentPolicy) {
      if (
        currentPolicy.deposit_payment_option ===
        PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION
      ) {
        newDepositPaymentMethod = t(
          "Payments.DepositPaymentOptions.preAuthorisation"
        );
      } else if (
        currentPolicy.deposit_payment_option ===
        PaymentPolicyDepositPaymentOptionEnum.CARD_PAYMENT_TO_REFUND
      ) {
        newDepositPaymentMethod = t("Payments.DepositPaymentOptions.cbRefund");
      } else if (
        currentPolicy.deposit_payment_option ===
        PaymentPolicyDepositPaymentOptionEnum.BANK_TRANSFER_TO_REFUND
      ) {
        newDepositPaymentMethod = t(
          "Payments.DepositPaymentOptions.sepaRefund"
        );
      } else if (
        currentPolicy.deposit_payment_option ===
        PaymentPolicyDepositPaymentOptionEnum.BANK_CHECK_OR_CASH_AT_ARRIVAL
      ) {
        newDepositPaymentMethod = t(
          "Payments.DepositPaymentOptions.checkOrCash"
        );
      }
    }

    return newDepositPaymentMethod?.toLowerCase();
  };

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="flex-1">
          <div className="flex justify-start pb-12">
            <div className="w-44">
              <Button
                type="secondary"
                LeftIcon={ArrowLeftIcon}
                onClick={onBack}
                disabled={!reservation}
              >
                {t("Global.previousStep")}
              </Button>
            </div>
          </div>

          <p className="mb-8 font-semibold text-xxl text-high-contrast">
            {t("AddReservation.Payment.title")}
          </p>

          <div className="flex flex-col p-3 rounded-lg gap-y-6 bg-element-background border-1 border-element-border">
            {/* PAYMENT CALENDAR */}
            {loading ||
            (currentPolicy?.payments_policy_items &&
              currentPolicy.payments_policy_items.length > 0) ? (
              <div className="flex-col items-center space-y-1">
                <div className="flex items-center space-x-1">
                  <CalendarIcon className="size-5" />
                  <p className="text-sm font-light text-low-contrast">
                    {t("AddReservation.Payment.calendarPayment")}
                  </p>
                </div>

                {loading ? (
                  <p className="w-3/4 h-3 rounded-lg bg-slate-300 animate-pulse"></p>
                ) : (
                  <>
                    {currentPolicy?.payments_policy_items &&
                    currentPolicy.payments_policy_items.length > 0 ? (
                      <p className="text-base font-semibold text-high-contrast">
                        {t(
                          "AddReservation.Payment.calendarPaymentDescription",
                          {
                            nbTimes: getNbTimesPayment(
                              currentPolicy.payments_policy_items
                            ),
                            paymentMethods:
                              displayPaymentMethods(paymentMethods),
                          }
                        )}
                      </p>
                    ) : null}
                  </>
                )}
              </div>
            ) : null}

            {/* CANCELLATION POLICY */}
            {loading || currentPolicy?.is_refundable !== undefined ? (
              <div className="flex-col items-center space-y-1">
                <div className="flex items-center space-x-1">
                  <InputCrossIcon className="size-5" />
                  <p className="text-sm font-light text-low-contrast">
                    {t("AddReservation.Payment.cancellationPolicy")}
                  </p>
                </div>

                {loading ? (
                  <p className="w-3/4 h-3 rounded-lg bg-slate-300 animate-pulse"></p>
                ) : (
                  <>
                    {currentPolicy?.is_refundable !== undefined ? (
                      <p className="text-base font-semibold text-high-contrast">
                        {t(
                          `${
                            currentPolicy.is_refundable
                              ? "AddReservation.Payment.isRefundable"
                              : "AddReservation.Payment.isNotRefundable"
                          }`
                        )}
                      </p>
                    ) : null}
                  </>
                )}
              </div>
            ) : null}

            {/* DEPOSIT */}
            {loading ||
            (currentPolicy?.deposit_value !== undefined &&
              currentPolicy?.deposit_value !== null) ? (
              <div className="flex-col items-center space-y-1">
                <div className="flex items-center space-x-1">
                  <MoneyBagIcon className="size-5" />
                  <p className="text-sm font-light text-low-contrast">
                    {t("AddReservation.Payment.deposit")}
                  </p>
                </div>
                {loading ? (
                  <p className="w-3/4 h-3 rounded-lg bg-slate-300 animate-pulse"></p>
                ) : (
                  <>
                    {currentPolicy?.deposit_value !== undefined &&
                    currentPolicy?.deposit_value !== null ? (
                      <p className="text-base font-semibold text-high-contrast">
                        {t("AddReservation.Payment.depositInfo", {
                          amount: getFormattedPrice({
                            price: currentPolicy.deposit_value,
                            locale: "fr-FR",
                            decimals: 0,
                          }),
                          paymentMethod: displayDepositPaymentMethod(),
                        })}
                      </p>
                    ) : null}
                  </>
                )}
              </div>
            ) : null}

            <div className="w-max">
              <Button
                type="secondary"
                RightIcon={EditIcon}
                onClick={modal.open}
              >
                {t("AddReservation.Payment.updatePricingCondtionsButton")}
              </Button>
            </div>
          </div>
        </div>

        <ErrorMessage>{error}</ErrorMessage>

        <div className="flex gap-4 mt-4">
          <Button type="secondary" disabled={!reservation} onClick={onCancel}>
            {t("Global.cancel")}
          </Button>
          <Button
            RightIcon={CheckWhiteIcon}
            onClick={() => handleNext(paymentPolicyFormValues())}
          >
            {t("AddReservation.Payment.nextStep")}
          </Button>
        </div>
      </div>
    </>
  );
};
