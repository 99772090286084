import React from "react";
import { useTranslation } from "react-i18next";
import { ProgressionStep } from "../../../pages/Progression/ProgressionStep";
import { ProgressionStepNoneProps } from "../Progression.type";

export const ProgressionStepNone: React.FC<ProgressionStepNoneProps> = ({
  status,
  visible,
  number,
  error,
  loading,
}) => {
  const { t } = useTranslation();

  if (!visible) return null;

  return (
    <ProgressionStep
      number={number.toString()}
      title={t("Progression.StepTeam.title")}
      status={status}
      error={error}
      loading={loading}
    />
  );
};
