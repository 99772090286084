import React, {useEffect, useState} from "react";
import PhoneNumberInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {cn} from "../../../helpers/classHelper";
import {ErrorMessage} from "../ErrorMessage/ErrorMessage";
import {PhoneInputProps} from "./PhoneInput.type";

export const PhoneInput: React.FC<PhoneInputProps> = ({
  label,
  name,
  value,
  defaultCountry = "FR",
  placeholder = "",
  size = "normal",
  disabled = false,
  error,
  onChangeText,
  required = false,
  classNames = undefined,
  leftButton,
}) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const getBorder = () => {
    return error
      ? "border-element-border-error"
      : `
      border-element-border
      hover:border-element-border-hover 
      active:border-element-border-active`;
  };

  const getOutline = () => {
    if (isFocus && error) {
      return "outline outline-2 outline-error/50";
    } else if (isFocus && !error) {
      return "outline outline-2 outline-focus";
    } else {
      return "";
    }
  };

  const bgColor = disabled ? "bg-element-background-disabled" : "bg-white";
  const cursor = disabled ? "cursor-not-allowed" : "cursor-text";
  const textColor = disabled ? "text-low-contrast " : "text-high-contrast ";
  const textSize = size === "large" ? "text-sm" : "text-sm";

  const handleChange = (value: any) => {
    if (onChangeText) {
      if (value) onChangeText(value.toString());
      else onChangeText("");
    }
  };

  useEffect(() => {}, [defaultCountry]);

  return (
    <div className="flex flex-col flex-1">
      <div className="flex justify-between">
        <div className="flex flex-1 items-start space-x-1">
          {label && (
            <label
              className={cn(
                `font-semibold text-left mb-1 text-sm`,
                classNames?.label
              )}
              htmlFor={`input-${name ?? "phone"}`}
            >
              {label}
            </label>
          )}
          {label && required ? (
            <span
              className={cn(
                "relative text-lg -top-1 text-active",
                classNames?.labelRequired
              )}
            >
              *
            </span>
          ) : null}
        </div>
        {leftButton && leftButton}
      </div>

      <div
        className={cn(
          `
        flex flex-row items-center
        overflow-hidden
        px-2
        ${getBorder()}
        ${bgColor}
        ${cursor}
        ${getOutline()}
        ${size === "large" ? "h-9" : "h-8"}
        border-1 rounded-6px
        ${textColor}
        ${textSize}
        `,
          classNames?.input
        )}
      >
        <PhoneNumberInput
          id={`input-${name ?? "phone"}`}
          // @ts-ignore
          defaultCountry={defaultCountry}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          disabled={disabled}
        />
      </div>

      <ErrorMessage errorType="FORM">{error}</ErrorMessage>
    </div>
  );
};
