import React from "react";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import {
  GuestPageGuestConnectData,
  GuestPageRental,
  PaymentDetail,
} from "../../../types/GETTypes";
import {useTranslation} from "react-i18next";
import {generateLoremIpsum} from "./paymentDataPreview";

const InvoiceLineComponent = ({
  payment_detail,
  rental,
}: {
  payment_detail?: PaymentDetail | undefined;
  rental?: GuestPageRental | undefined;
}) => {
  const {rentalPreview, isPreviewMode} = useGuestPageDataStore();
  const {label, amount} = payment_detail || {};
  const {currency} = rental || {};
  const {tarification: tarificationPreview} = rentalPreview || {};
  const {rules: {rules: {currency: currencyPreview} = {}} = {}} =
    tarificationPreview || {};
  const symbol = isPreviewMode ? currencyPreview?.symbol : currency?.symbol;
  const returnValue = (value: number | string | undefined) => {
    if (isPreviewMode) {
      return (Math.random() * 1000).toFixed(2);
    }
    return value ? value : "";
  };
  const labelItem = isPreviewMode ? generateLoremIpsum(3) : label;
  const hasAValue = labelItem && (amount) // here add (amount || otherValue || etc)
  if(!hasAValue){
    return null
  }
  return (
    <tr className="">
      <td className="p-2 text-low-contrast md:text-lg">{labelItem}</td>
      <td className="p-2 text-center text-low-contrast md:text-lg">
        <span style={{whiteSpace: "nowrap"}}>
          {returnValue(amount)} {symbol ?? "€"}
        </span>
      </td>
      <td className="p-2 text-center text-low-contrast md:text-lg"></td>
      <td className="p-2 text-low-contrast text-center md:text-lg"></td>
      <td className="p-2 text-center text-low-contrast md:text-lg"></td>
      <td className="p-2 text-center text-high-contrast text-center md:text-lg">
        <span style={{whiteSpace: "nowrap"}}>
          {returnValue(amount)} {symbol ?? "€"}
        </span>
      </td>
    </tr>
  );
};
const InvoiceComponent = () => {
  const {t} = useTranslation();
  const {guestPageData, rentalPreview, isPreviewMode, userPreview} =
    useGuestPageDataStore();
  const {guest, payment_details, payment_schedule, rental} =
    guestPageData || {};
  const {total_price_value} = payment_schedule || {};
  const {first_name, last_name, address, postal_code, city} = guest || {};
  const {currency} = rental || {};
  const {tarification: tarificationPreview} = rentalPreview || {};
  const {rules: {rules: {currency: currencyPreview} = {}} = {}} =
    tarificationPreview || {};
  const {first_name: firstNameUserPreview, last_name: lastNameUserPreview} =
    userPreview || {};
  const firstName = isPreviewMode ? firstNameUserPreview : first_name;
  const lastName = isPreviewMode ? lastNameUserPreview : last_name;
  const addressGuest = isPreviewMode ? "" : address;
  const postalCodeGuest = isPreviewMode ? "" : postal_code;
  const cityGuest = isPreviewMode ? "" : city;
  const symbol = isPreviewMode ? currencyPreview?.symbol : currency?.symbol;

  return (
    <div className="w-full bg-white">
      <div className="flex justify-end">
        <div>
          <h2 className="text-sm text-low-contrast font-bold text-end mb-2 md:text-xl">
            {t("GuestPage.Invoice.guest")}
          </h2>
          <p className="text-end text-high-contrast md:text-lg">
            {firstName} {lastName}
          </p>
          <p className="text-end text-high-contrast md:text-lg">
            {addressGuest}
          </p>
          <p className="text-end text-high-contrast md:text-lg">
            {postalCodeGuest} {cityGuest}
          </p>
        </div>
      </div>

      <table className="w-full mt-8 border-collapse">
        <thead>
          <tr className="">
            <th className="text-left p-1 underline md:text-lg">
              {t("GuestPage.Invoice.name")}
            </th>
            <th className="p-1 text-center md:text-lg">
              {t("GuestPage.Invoice.amount")}
            </th>
            <th className=" p-1 text-center md:text-lg">
              {t("GuestPage.Invoice.quantity")}
            </th>
            <th className="p-1  text-center md:text-lg">
              <span style={{whiteSpace: "nowrap"}}>
                {t("GuestPage.Invoice.totalHT")}
              </span>
            </th>
            <th className="p-1 text-center md:text-lg">
              {t("GuestPage.Invoice.tva")}
            </th>
            <th className="p-1  text-center md:text-lg">
              <span style={{whiteSpace: "nowrap"}}>
                {t("GuestPage.Invoice.totalTTC")}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {isPreviewMode
            ? Array.from({length: 5}).map((nb) => (
                <InvoiceLineComponent
                  payment_detail={undefined}
                  rental={rental}
                />
              ))
            : payment_details?.map((payment_detail) => (
                <InvoiceLineComponent
                  payment_detail={payment_detail}
                  rental={rental}
                />
              ))}
        </tbody>
      </table>
      <div className="flex w-full border-t mb-8">
        <div className="flex w-full justify-between mt-4 items-center">
          <td colSpan={5} className="p-2 text-right font-bold md:text-lg">
            {t("Global.total")}
          </td>
          <td className="p-2 font-bold text-xxl text-high-contrast md:text-lg">
            {isPreviewMode
              ? (Math.random() * 1000).toFixed(2)
              : total_price_value}{" "}
            {symbol ?? "€"}
          </td>
        </div>
      </div>
    </div>
  );
};

export default InvoiceComponent;
