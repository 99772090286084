import React from "react";
import { useTranslation } from "react-i18next";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import { useForm } from "react-hook-form";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { Button } from "../../../Common/Button/Button";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";

export const AirbnbConnectStepCreds: React.FC<{
  onNext: (email: string, password: string) => void;
  apiError?: string | null;
  loading?: boolean;
}> = ({ onNext = () => {}, apiError, loading }) => {
  const { t } = useTranslation();

  type AirbnbCredsForm = {
    email: string;
    password: string;
  };

  const {
    register,
    getValues,
    formState: { errors, isValid },
    trigger,
  } = useForm<AirbnbCredsForm>({
    mode: "all",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const handleConnect = () => {
    console.log("handle connect");
    trigger("email");
    trigger("password");

    if (typeof onNext === "function") {
      onNext(getValues("email"), getValues("password"));
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <p className="text-sm font-light text-low-contrast">
        {t("Progression.Airbnb.Creds.content1")}
      </p>
      <p className="text-sm font-light text-low-contrast">
        {t("Progression.Airbnb.Creds.content2")}
      </p>
      <p className="text-sm font-light text-low-contrast">
        {t("Progression.Airbnb.Creds.content3")}
      </p>

      <TextInput
        register={register("email", {
          required: {
            value: true,
            message: t("Global.Errors.requiredField", {
              fieldName: t("Progression.Airbnb.Creds.emailLabel"),
            }),
          },
          pattern: {
            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
            message: t("Progression.Airbnb.Creds.emailErrorInvalid").toString(),
          },
        })}
        label={t("Progression.Airbnb.Creds.emailLabel")}
        placeholder={t("Global.placeholderEmail")}
        error={errors.email?.message}
        disabled={loading}
      />
      <TextInput
        register={register("password", {
          required: {
            value: true,
            message: t("Global.Errors.requiredField", {
              fieldName: t("Progression.Airbnb.Creds.passwordLabel"),
            }),
          },
        })}
        type="password"
        label={t("Progression.Airbnb.Creds.passwordLabel")}
        placeholder={t("Global.placeholderPassword")}
        error={errors.password?.message}
        disabled={loading}
      />

      <ErrorMessage>{apiError}</ErrorMessage>

        {/*
          <p className="text-sm font-light text-low-contrast">
            <span>{t("Progression.Airbnb.Creds.content4")}</span>
            <a
              className="ps-1 text-blue-700"
              target="_blank"
              href="https://www.airbnb.fr/help/article/76"
            >
              {t("Global.clickHere")}
            </a>
          </p>
        */}

      <Button
        RightIcon={CheckIcon}
        onClick={handleConnect}
        disabled={!isValid}
        loading={loading}
      >
        {t("Progression.connect")}
      </Button>
    </div>
  );
};
