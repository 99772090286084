/**
 * Component Header for TableList
 *
 * @author Elysio
 */

import React from "react";

import ChevronDownIcon from "../../../assets/icons/chevron-down.svg?react";
import ChevronUpIcon from "../../../assets/icons/chevron-up.svg?react";

import { useTranslation } from "react-i18next";
import { CheckBox } from "../../../components/Common/CheckBox/CheckBox";
import { TableListHeaderProps } from "../../../components/Common/TableList/TableList.type";

export const GuestHeaderList: React.FC<TableListHeaderProps> = ({
  sortField,
  sortDirection,
  onChangeSort = () => {},
  onSelectAll = () => {},
  areAllSelected,
}) => {
  const { t } = useTranslation();

  const handleChangeSort = (column: string) => {
    onChangeSort(column);
  };

  const getIcon = (column: string) => {
    const isActive = sortField == column;

    if (isActive && sortDirection === "asc") return <ChevronDownIcon />;
    else if (isActive && sortDirection === "desc") return <ChevronUpIcon />;
    else return <ChevronDownIcon />;
  };

  return (
    <tr className="bg-element-background border-b-1 border-element-border">
      <th className="px-4 py-3 text-sm">
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "enabled" ? "underline" : null
          }`}
        >
          <CheckBox onChange={onSelectAll} value={areAllSelected} />
        </div>
      </th>
      <th
        className="px-2 py-3 text-sm"
        onClick={() => handleChangeSort("first_name")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "enabled" ? "underline" : null
          }`}
        >
          <p>{t("Payments.PaymentReservationList.guestLabel")}</p>{" "}
          {getIcon("first_name")}
        </div>
      </th>

      <th
        className="px-2 py-3 text-sm"
        onClick={() => handleChangeSort("languages")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "checkin" ? "underline" : null
          }`}
        >
          <p>{t("AddReservation.Done.Send.language")}</p> {getIcon("languages")}
        </div>
      </th>

      <th
        className="px-2 py-3 text-sm"
        onClick={() => handleChangeSort("email")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline justify-center ${
            sortField === "checkout" ? "underline" : null
          }`}
        >
          <p>{t("Booking.email")}</p> {getIcon("email")}
        </div>
      </th>

      <th
        className="px-2 py-3 text-sm"
        onClick={() => handleChangeSort("phone")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline justify-center ${
            sortField === "status_reservation" ? "underline" : null
          }`}
        >
          <p>{t("Dashboard.phone")}</p> {getIcon("phone")}
        </div>
      </th>

      <th className="w-1/12 px-2 py-3 text-sm">
        <div className={`flex flex-row items-center translate-x-8`}>
          <p>{t("Booking.Guest.days")}</p>
        </div>
      </th>

      <th
        className="py-3 text-sm ps-2 pe-4"
        onClick={() => handleChangeSort("average_rating")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline justify-end ${
            sortField === "status_payment" ? "underline" : null
          }`}
        >
          <p>{t("Booking.Guest.reviews")}</p> {getIcon("average_rating")}
        </div>
      </th>

      <th className="py-3 text-sm ps-4 pe-8">
        <div
          className={`flex flex-row items-center cursor-pointer translate-x-10`}
        >
          <p>{t("Booking.Guest.allSpend")}</p>
        </div>
      </th>
      <th className="py-3 text-sm ps-2 pe-4">
        <div className={`flex flex-row items-center justify-end`}></div>
      </th>
    </tr>
  );
};
