import React from "react";
import { BadgeProps } from "./Badge.type";

import CheckCircleGreenIcon from "../../../assets/icons/check-circle-green.svg?react";
import ClockIcon from "../../../assets/icons/clock-warning.svg?react";
import CloseCircleRedIcon from "../../../assets/icons/close-circle-red.svg?react";
import {cn} from "../../../helpers/classHelper";

export const Badge: React.FC<BadgeProps> = ({
  label,
  Icon,
  customBg,
  customColor,
  status = "pending",
  size = "normal",
  labelStyle,
  iconSize = 18
}) => {
  const getBg = () => {
    if (!customBg) {
      if (status === "pending") {
        return "bg-element-background-warning";
      } else if (status === "fail") {
        return "bg-element-background-error";
      } else {
        return "bg-element-background-success";
      }
    } else {
      return customBg;
    }
  };

  const getColor = () => {
    if (!customColor) {
      if (status === "pending") {
        return "text-warning";
      } else if (status === "fail") {
        return "text-error";
      } else {
        return "text-success";
      }
    } else {
      return customColor;
    }
  };

  const getHeight = () => {
    if (size === "large") return "h-10";
    else if (size === "normal") return "h-9";
    else return "h-8";
  };

  return (
    <div
      className={`
      ${getBg()}
      ${getColor()}
      ${getHeight()}
      px-2
      w-fit rounded-6px flex flex-row space-x-1 items-center`}
    >
      {Icon === undefined ? (
        <>
          {status === "pending" ? <ClockIcon width={iconSize} height={iconSize} /> : null}
          {status === "fail" ? (
            <CloseCircleRedIcon width={iconSize} height={iconSize} />
          ) : null}
          {status === "success" ? (
            <CheckCircleGreenIcon width={iconSize} height={iconSize} />
          ) : null}
        </>
      ) : null}

      {Icon ? <Icon width={iconSize} height={iconSize} /> : null}

      {label ? (
        <p className={cn("text-sm font-semibold text-center",labelStyle)}>{label}</p>
      ) : null}
    </div>
  );
};
