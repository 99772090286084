import { t } from "i18next";
import React from "react";
import MoneyIcon from "../../../assets/icons/money.svg?react";
import { Card } from "../../Common/Card/Card";
import { WorkInProgress } from "../../WorkInProgress/WorkInProgress";

type ReservationSummaryCardProps = {};

export const ReservationSummaryCard: React.FC<
  ReservationSummaryCardProps
> = ({}) => {
  return (
    <Card Icon={MoneyIcon} label={t("Booking.Summary.title")}>
      <WorkInProgress />
    </Card>
  );
};
