import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { getFormattedPrice } from "../../../helpers/currencyHelper";
import {
  capitalizeFirstLetter,
  displayPriceWith2DigitsIfNeeded,
} from "../../../helpers/stringHelper";
import { AddReservationCalendarType } from "../AddReservation.type";

type CalendarResultType = {
  month: string;
  dates: {
    day: number;
    price: number;
  }[];
}[];

export const AddReservationPricesCalendar: React.FC<{
  calendar: AddReservationCalendarType;
}> = ({ calendar }) => {
  const { t } = useTranslation();

  const getTotalNights = () => {
    let total = 0;
    calendar?.forEach((d) => (total += d.price));
    return total;
  };

  function transformCalendar(
    input: AddReservationCalendarType
  ): CalendarResultType {
    const groupedData: { [key: string]: { day: number; price: number }[] } = {};

    input.forEach((item) => {
      const month = moment(item.date).format("MMMM");
      const day = moment(item.date).date();

      if (!groupedData[month]) {
        groupedData[month] = [];
      }

      groupedData[month].push({ day, price: item.price });
    });

    return Object.keys(groupedData).map((month) => ({
      month,
      dates: groupedData[month],
    }));
  }

  return (
    <div className="flex flex-col gap-2 p-4 bg-element-background border-1 border-element-border rounded-6px">
      {transformCalendar(calendar)?.map((month, index) => (
        <>
          <div className="flex flex-col justify-between gap-y-3">
            <p className="font-light text-low-contrast">
              {index === 0 &&
                t("AddReservation.Prices.DetailPricesModal.priceByNight")}
            </p>
            <p className="text-base font-semibold text-high-contrast">
              {capitalizeFirstLetter(month.month)}
            </p>
          </div>

          <div className="flex flex-wrap gap-3 mt-1">
            {month?.dates?.map((date) => (
              <div className="flex flex-col items-center justify-center gap-1 p-2 bg-white rounded-6px">
                <div className="text-base font-semibold text-high-contrast">
                  {date.day}
                </div>
                <div className="text-base font-light text-low-contrast">
                  {displayPriceWith2DigitsIfNeeded(date.price)}
                </div>
              </div>
            ))}
          </div>
        </>
      ))}

      <p className="mt-2 text-lg font-bold text-high-contrast">
        {t("AddReservation.Prices.DetailPricesModal.totalNightsFull", {
          total: getFormattedPrice({
            price: getTotalNights(),
            locale: "fr-FR",
            currency: "EUR",
            decimals: 2,
          }),
        })}
      </p>
    </div>
  );
};
