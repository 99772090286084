import React from "react";
import {PlatformConfirmModalProps, RentalPlatformsAddModalType} from "./Platforms.type";
import {useTranslation} from "react-i18next";
import CloseIcon from "../../../../assets/icons/close.svg?react";
import checkIcon from "../../../../assets/icons/check-white.svg?react";
import {CenterModal} from "../../../Common/CenterModal/CenterModal";
import {Button} from "../../../Common/Button/Button";

export const CancelSynchroPlaformModal: React.FC<PlatformConfirmModalProps> = ({isVisible, onClose, handleConfirm,platformActive}) => {
    const { t } = useTranslation();

    return (
        <CenterModal
            isVisible={isVisible}
            onClose={onClose}
        >

            <div className="flex flex-col gap-10">
                <div className="flex flex-row  pb-0">
                    <div className="flex flex-col gap-5">
                        <p className="text-[20px] font-extrabold leading-snug">

                            { platformActive === "booking" ?
                                t("Rental.Infos.Platforms.SynchronizeModal.cancelTitle"):
                                t("Rental.Infos.Platforms.SynchronizeModal.cancelTitleAirbnb")
                            }
                        </p>
                        <p className="text-low-contrast">
                            {t("Rental.Infos.Platforms.SynchronizeModal.cancelDescription")}
                        </p>
                    </div>
                </div>


                <div className="flex gap-3">
                    <Button type="secondary" onClick={handleConfirm}>
                        {t("Global.returned")}
                    </Button>
                    <Button type="destructive"
                            onClick={onClose}
                    >
                        {t("Global.leave")}
                    </Button>
                </div>
            </div>
        </CenterModal>
    );
}
