import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AnnotationIcon from "../../../assets/icons/annotation-dots.svg?react";
import { Card } from "../../Common/Card/Card";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import BedIcon from "../../../assets/icons/double-bed.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import { ReservationPlatformNoteProps } from "./PlatformNoteCard.type";
import { ReservationPlatforNoteCardSkeleton } from "./PlatformNoteCardSkeleton";
import { Controller, useForm } from "react-hook-form";
import { post } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { TextAreaInput } from "../../Common/TextAreaInput/TextAreaInput";
import { Button } from "../../Common/Button/Button";

export const ReservationPlatformNoteCard: React.FC<
  ReservationPlatformNoteProps
> = ({ loading, reservation, disabled, onUpdate }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { isValid, errors },
    getValues,
    setValue,
    control,
  } = useForm<{
    platformNotes: string;
  }>({
    mode: "all",
  });

  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const { platform_notes, guest_page_beds_count } = reservation || {};

  useEffect(() => {
    setValue("platformNotes", platform_notes ?? "");
  }, [reservation]);

  const updatePlatformNote = async () => {
    if (reservation !== undefined) {
      setValidationLoading(true);

      const payload = {
        platform_notes: getValues("platformNotes"),
      };

      const response = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.RESERVATIONS}/${
          reservation?.id
        }`,
        {
          _method: "PUT",
          ...payload,
        }
      );

      if (response.data?.success) {
        setEditMode(false);
        onUpdate({
          platform_notes: getValues("platformNotes"),
        });
      } else {
        setError(response.data?.message ?? response.message);
      }
      setValidationLoading(false);
    }
  };

  const handlePlatformNoteEdit = () => {
    updatePlatformNote();
  };

  if (loading) return <ReservationPlatforNoteCardSkeleton />;

  return (
    <Card
      Icon={AnnotationIcon}
      label={t("Booking.PlatformNote.title")}
      button={{
        Icon: editMode
          ? CheckIcon
          : Boolean(getValues("platformNotes"))
          ? EditIcon
          : PlusIcon,
        type: editMode ? "primary" : "secondary",
        disabled: (editMode && (!isValid || validationLoading)) || disabled,
        label: editMode
          ? t("Global.record")
          : Boolean(getValues("platformNotes"))
          ? t("Global.edit")
          : t("Global.add"),
        onClick: editMode
          ? handleSubmit(handlePlatformNoteEdit)
          : () => setEditMode(true),
      }}
      loading={validationLoading}
    >
      <ErrorMessage>{error}</ErrorMessage>
      {editMode ? (
        <div className="flex flex-col gap-2">
          <Controller
            control={control}
            name="platformNotes"
            render={({ field: { value, onChange } }) => {
              return (
                <div className="">
                  <TextAreaInput
                    disabled={validationLoading}
                    value={value}
                    onTextChange={onChange}
                    classNames={{
                      input:
                        "w-full p-2 rounded resize-y text-hight-contrast border-1 border-element-border focus:outline-none h-max",
                    }}
                    rows={2}
                  />
                </div>
              );
            }}
          />

          <ErrorMessage errorType="FORM">
            {errors.platformNotes?.message}
          </ErrorMessage>

          <Button
            type="secondary"
            size="small"
            onClick={() => setEditMode(false)}
            displayLargeBtn={false}
            disabled={validationLoading}
          >
            {t("Global.cancel")}
          </Button>
        </div>
      ) : (
        <p className="whitespace-pre text-low-contrast">
          {getValues("platformNotes")}
        </p>
      )}
      <div className="p-4 mt-4 rounded-md bg-element-background border-1 border-element-border">
        <div className="flex flex-row items-center space-x-3">
          <div className="">
            <BedIcon />
          </div>
          <div className="flex flex-col">
            <span className="font-bold text-high-contrast">
              {t("Booking.PlatformNote.bedsCount", {
                count: guest_page_beds_count,
              })}
            </span>
            <span className="text-low-contrast">
              {t("Booking.PlatformNote.bedsCountInfo")}
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};
