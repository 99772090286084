import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useUpdateProgression } from "../../../hooks/api/progression";
import {
  useChangeStep,
  useHandleSubmit,
  useIconSubmitButton,
  useInitProgressionRentalsRulesModal,
  useInitProgressionRentalsRulesModalStates,
  useSelectRental,
  useStep,
  useTextSubmitButton,
} from "../../../pages/ProgressionRentalsRules/ProgressionRentalsRules.hooks";
import { ValueType } from "../../../types/commonTypes";
import { RentalLightListItemResponse } from "../../../types/GETTypes";
import { Button } from "../../Common/Button/Button";
import { CarouselWizard } from "../../Common/CarouselWizard/CarouselWizard";
import { CarouselWizardStep } from "../../Common/CarouselWizard/CarouselWizard.type";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { RightModal } from "../../Common/RightModal/RightModal";
import { Title } from "../../Common/Title/Title";
import {
  ProgressionRentalsRulesModalNextStepOtherFields,
  ProgressionRentalsRulesModalRef,
  ProgressionRentalsRulesModalStep,
} from "./ProgressionRentalsRulesModal.type";
import { ProgressionRentalsRulesMulti } from "./Steps/Multi";
import {
  ProgressionRentalsRulesRules,
  ProgressionRentalsRulesRulesForm,
} from "./Steps/Rules";
import {
  ProgressionRentalsRulesPrice,
  ProgressionRentalsRulesPriceForm,
} from "./Steps/RulesPrice";

type ProgressionRentalsRulesModalProps = {
  currentRental?: RentalLightListItemResponse;
  rentals: RentalLightListItemResponse[];
  isVisible: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const ProgressionRentalsRulesModal: React.FC<
  ProgressionRentalsRulesModalProps
> = ({ currentRental, rentals, isVisible, onClose, onSuccess }) => {
  const { t } = useTranslation();

  const initStates = useInitProgressionRentalsRulesModalStates(currentRental);
  const [loading, setLoading] = initStates.loadingState;
  const [error, setError] = initStates.errorState;
  const [currentStep, setCurrentStep] = initStates.currentStepState;
  const [finalStep, setFinalStep] = initStates.finalStepState;
  const [selectedRentals, setSelectedRentals] = initStates.selectedRentalsState;
  const [countRentals, setCountRentals] = initStates.countRentalsState;
  const [newCurrentRental, setNewCurrentRental] =
    initStates.newCurrentRentalState;
  const [otherFields, setOtherFields] = initStates.otherFieldsState;

  useInitProgressionRentalsRulesModal(
    rentals,
    [countRentals, setCountRentals],
    [currentStep, setCurrentStep],
    [finalStep, setFinalStep],
    [selectedRentals, setSelectedRentals],
    [newCurrentRental, setNewCurrentRental],
    setOtherFields,
    setLoading,
    setError,
    onSuccess,
    onClose
  );

  useEffect(() => {
    if (currentRental) console.log("data : ", Boolean(currentRental?.deposit));
  }, [currentRental]);

  const rulesPriceForm = useForm<ProgressionRentalsRulesPriceForm>({
    mode: "all",
    defaultValues: {
      price_night_default: currentRental?.price_night_default ?? 0,
      min_night_price: currentRental?.price_night_min ?? 0,
      guests_max_capacity: currentRental?.guests_max_capacity ?? 0,
      price_night_added_per_guests:
        currentRental?.price_night_added_per_guests ?? 0,
      cleaning_fees: currentRental?.cleaning_default ?? 0,
      deposit: currentRental?.deposit,
      fixed_or_percentage:
        currentRental?.city_tax_default === null ? "percentage" : "fixed",
      adult_tax_per_night: currentRental?.city_tax_default ?? 0,
      night_price_percentage: currentRental?.city_tax_percent,
      night_price_limit_guest: currentRental?.city_tax_limit,
      adult_tax_percentage_per_night: currentRental?.city_tax_additional ?? 0,
      airbnb_price_multiplier:
        currentRental?.external_connexions?.platform_airbnb
          ?.price_multiplier_value ?? undefined,
      booking_price_multiplier:
        currentRental?.external_connexions?.platform_booking
          ?.price_multiplier_value ?? undefined,
    },
  });

  const setRulesPriceFormValue = () => {
    const { setValue } = rulesPriceForm;
    setValue("price_night_default", currentRental?.price_night_default ?? 0);
    setValue("min_night_price", currentRental?.price_night_min ?? 0);
    setValue("guests_max_capacity", currentRental?.guests_max_capacity ?? 0);
    setValue(
      "price_night_added_per_guests",
      currentRental?.price_night_added_per_guests ?? 0
    );
    setValue("cleaning_fees", currentRental?.cleaning_default ?? 0);
    setValue(
      "deposit",
      Boolean(currentRental?.deposit) ? currentRental?.deposit : 200
    );
    setValue(
      "fixed_or_percentage",
      currentRental?.city_tax_default === null ? "percentage" : "fixed"
    );
    setValue("adult_tax_per_night", currentRental?.city_tax_default ?? 0);
    setValue("night_price_percentage", currentRental?.city_tax_percent ?? null);
    setValue("night_price_limit_guest", currentRental?.city_tax_limit ?? null);
    setValue(
      "adult_tax_percentage_per_night",
      currentRental?.city_tax_additional ?? 0
    );
    setValue(
      "airbnb_price_multiplier",
      currentRental?.external_connexions?.platform_airbnb
        ?.price_multiplier_value ?? undefined
    );
    setValue(
      "booking_price_multiplier",
      currentRental?.external_connexions?.platform_booking
        ?.price_multiplier_value ?? undefined
    );
  };

  const rentalRules = useForm<ProgressionRentalsRulesRulesForm>({
    mode: "all",
    defaultValues: {
      checkin_min_time: currentRental?.checkin_min_time ?? "",
      checkin_max_time: currentRental?.checkin_max_time ?? "",
      checkout_min_time: currentRental?.checkout_min_time ?? "",
      checkout_max_time: currentRental?.checkout_max_time ?? "",
      reservation_daytime_limit: currentRental?.reservation_daytime_limit ?? "",
      min_days_prior_reservations:
        currentRental?.min_days_prior_reservations ?? 0,
      hours_or_days:
        currentRental?.reservation_daytime_limit === null ? "days" : "hours",
      min_nights_default: currentRental?.min_nights_default ?? 0,
      max_nights_default: currentRental?.max_nights_default ?? 0,
    },
  });

  const setRentalRulesFormValue = () => {
    const { setValue } = rentalRules;
    setValue("checkin_min_time", currentRental?.checkin_min_time ?? "");
    setValue("checkin_max_time", currentRental?.checkin_max_time ?? "");
    setValue("checkout_min_time", currentRental?.checkout_min_time ?? "");
    setValue("checkout_max_time", currentRental?.checkout_max_time ?? "");
    setValue(
      "reservation_daytime_limit",
      currentRental?.reservation_daytime_limit ?? ""
    );
    setValue(
      "min_days_prior_reservations",
      currentRental?.min_days_prior_reservations ?? 0
    );
    setValue(
      "hours_or_days",
      currentRental?.reservation_daytime_limit === null ? "days" : "hours"
    );
    setValue("min_nights_default", currentRental?.min_nights_default ?? 0);
    setValue("max_nights_default", currentRental?.max_nights_default ?? 0);
  };

  const handleSubmit = () => {
    if (currentRental) {
      const { getValues: getValuesRulesPrice } = rulesPriceForm;
      const { getValues: getValuesRentalRules } = rentalRules;

      const rentalToUpdate = {
        ...currentRental,
        rental_id: currentRental.id,
        rental_ids:
          selectedRentals && selectedRentals.length > 0
            ? selectedRentals.toString()
            : "",
        price_night_default: getValuesRulesPrice("price_night_default"),
        price_night_min: getValuesRulesPrice("min_night_price"),
        guests_max_capacity: getValuesRulesPrice("guests_max_capacity"),
        price_night_added_per_guests: getValuesRulesPrice(
          "price_night_added_per_guests"
        ),
        cleaning_default: getValuesRulesPrice("cleaning_fees"),
        deposit: getValuesRulesPrice("deposit"),
        city_tax_default:
          getValuesRulesPrice("fixed_or_percentage") === "percentage"
            ? null
            : getValuesRulesPrice("adult_tax_per_night"),
        city_tax_percent:
          getValuesRulesPrice("fixed_or_percentage") === "fixed"
            ? null
            : getValuesRulesPrice("night_price_percentage"),
        city_tax_limit:
          getValuesRulesPrice("fixed_or_percentage") === "fixed"
            ? null
            : getValuesRulesPrice("night_price_limit_guest"),
        city_tax_additional:
          getValuesRulesPrice("fixed_or_percentage") === "fixed"
            ? null
            : getValuesRulesPrice("adult_tax_percentage_per_night"),
        external_connexions: {
          platform_airbnb: !getValuesRulesPrice("airbnb_price_multiplier")
            ? null
            : {
                ...currentRental?.external_connexions?.platform_airbnb!,
                price_multiplier_value: getValuesRulesPrice(
                  "airbnb_price_multiplier"
                )!,
              },
          platform_booking: !getValuesRulesPrice("booking_price_multiplier")
            ? null
            : {
                ...currentRental?.external_connexions?.platform_booking!,
                price_multiplier_value: getValuesRulesPrice(
                  "booking_price_multiplier"
                )!,
              },
        },
        checkin_min_time: getValuesRentalRules("checkin_min_time"),
        checkin_max_time: getValuesRentalRules("checkin_max_time"),
        checkout_min_time: getValuesRentalRules("checkout_min_time"),
        checkout_max_time: getValuesRentalRules("checkout_max_time"),
        reservation_daytime_limit: getValuesRentalRules(
          "reservation_daytime_limit"
        ),
        min_days_prior_reservations: getValuesRentalRules(
          "min_days_prior_reservations"
        ),
        min_nights_default: getValuesRentalRules("min_nights_default"),
        max_nights_default: getValuesRentalRules("max_nights_default"),
      };

      useUpdateProgression(
        rentalToUpdate,
        () => {
          setCurrentStep(ProgressionRentalsRulesModalStep.PRICE_STEP);
          setNewCurrentRental(undefined);
          setOtherFields(null);
          setSelectedRentals([]);

          onSuccess();
          onClose();
        },
        (message: string | undefined) => {
          setError(message);
        },
        () => {
          setLoading(true);
          setError(undefined);
        },
        () => {
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    if (currentStep > finalStep) handleSubmit();
  }, [currentStep, currentRental]);

  useEffect(() => {
    setRulesPriceFormValue();
    setRentalRulesFormValue();
  }, []);

  const handleNextStep = (
    nextRental?: RentalLightListItemResponse,
    nextOtherFields?: ProgressionRentalsRulesModalNextStepOtherFields
  ) => {
    useChangeStep(
      () => setCurrentStep((prev) => prev + 1),
      () => setNewCurrentRental(nextRental),
      () => setOtherFields(nextOtherFields)
    );
  };

  const priceRef = useRef<ProgressionRentalsRulesModalRef>(null);
  const getPriceStep = () =>
    useStep(
      t("Progression.RentalsRules.Price.title"),
      <ProgressionRentalsRulesPrice
        rental={newCurrentRental}
        otherFields={otherFields}
        onNext={handleNextStep}
        rulesPriceForm={rulesPriceForm}
        ref={priceRef}
      />
    );

  const rulesRef = useRef<ProgressionRentalsRulesModalRef>(null);
  const getRulesStep = (): CarouselWizardStep => {
    return {
      title: t("Progression.RentalsRules.Rules.title"),
      Node: (
        <ProgressionRentalsRulesRules
          rental={newCurrentRental}
          otherFields={otherFields}
          onNext={handleNextStep}
          ref={rulesRef}
          rentalRules={rentalRules}
        />
      ),
    };
  };

  const rulesMultiRef = useRef<ProgressionRentalsRulesModalRef>(null);
  const getRulesMultiStep = () =>
    useStep(
      t("Progression.RentalsRules.Multi.title"),
      <ProgressionRentalsRulesMulti
        rental={newCurrentRental}
        excludeIds={rentals
          ?.filter((r) => currentRental?.id === r.id)
          .map((v) => v.id)}
        selectedRentals={selectedRentals}
        onSelectRental={(id: ValueType) =>
          useSelectRental(id, [selectedRentals, setSelectedRentals])
        }
        loading={loading}
        onNext={handleNextStep}
        ref={rulesMultiRef}
      />
    );

  const modalSteps = (): CarouselWizardStep[] => {
    const array: CarouselWizardStep[] = [
      getPriceStep(),
      getRulesStep(),
      getRulesMultiStep(),
    ];

    // * Remove the last step if there is only one currentRental
    if (countRentals === 1) {
      array.pop();
    }

    return array;
  };

  return (
    <RightModal
      isVisible={isVisible}
      onClose={loading ? () => {} : onClose}
      title={
        <Title>
          {t("Progression.RentalsRules.updateModalTitle")}
          <span className="underline">{currentRental?.name}</span>
        </Title>
      }
    >
      <div className="flex flex-col w-full gap-3">
        <div className="flex-1 overflow-y-scroll">
          <CarouselWizard
            disableClickBefore={loading}
            stepsUnlocked={true}
            currentStepIndex={currentStep > finalStep ? finalStep : currentStep}
            onChangeStep={(step: number) => {
              setCurrentStep(step);
            }}
            steps={modalSteps()}
          />
        </div>

        <ErrorMessage>{error}</ErrorMessage>

        {/* Cancel | Next or Submit button */}
        <div className="flex gap-3 mt-3">
          <Button type="secondary" onClick={onClose} disabled={loading}>
            {t("Global.cancel")}
          </Button>
          <Button
            RightIcon={useIconSubmitButton(currentStep, finalStep)}
            onClick={() =>
              useHandleSubmit(currentStep, priceRef, rulesRef, rulesMultiRef)
            }
            loading={loading}
          >
            {useTextSubmitButton(currentStep, finalStep)}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
