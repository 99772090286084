import React, { useState } from "react";
import { CardToggleProps } from "./CardToggle.type";

import ChevronDownIcon from "../../../assets/icons/chevron-down.svg?react";
import ChevronUpIcon from "../../../assets/icons/chevron-up.svg?react";

export const CardToggle: React.FC<CardToggleProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  if (isOpen) {
    return (
      <div className="flex flex-col w-full text-sm">
        <div
          className="flex justify-between items-center w-full p-2 text-sm bg-element-background border-1 border-element-border rounded-t-4px cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <div>{title}</div>

          <ChevronUpIcon />
        </div>

        <div className="border-1 border-t-0 border-element-border rounded-b-4px">
          {children}
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col w-full text-sm">
        <div
          className="flex justify-between items-center w-full p-2 text-sm bg-element-background border-1 border-element-border rounded-4px cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          <div>{title}</div>

          <ChevronDownIcon />
        </div>
      </div>
    );
  }
};
