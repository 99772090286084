import React from "react";

export const TemplateItemSkeleton: React.FC<{}> = ({}) => {
  return (
    <td className="flex flex-row space-x-2 px-2 py-3 border-b-1 last:border-b-0 border-element-border-light">
      <div className="flex-1 flex flex-col text-sm">
        <div className="flex gap-2 items-center">
          <p className="text-high-contrast font-semibold h-2 rounded w-64 bg-slate-300 mt-2"></p>
        </div>

        <p className="text-low-contrast font-light h-2 rounded w-52 bg-slate-200 mt-2"></p>
      </div>

      <div>
        <div className="w-12 h-9 rounded-6px bg-slate-200" />
      </div>

      <div>
        <div className="w-32 h-9 rounded-6px bg-slate-200" />
      </div>
    </td>
  );
};
