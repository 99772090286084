import React from "react";
import { SimpleBox } from "../../Modal/Airbnb/Connect/AirbnbConnectStepImport";
import { ChecklistSection } from "../../../types/GETTypes";
import { cn } from "../../../helpers/classHelper";
import { ValueType } from "../../../types/commonTypes";

export const ChecklistCheckbox: React.FC<{
  checklistSections: ChecklistSection[];
  disabled?: boolean;
  onCheckStep?: (
    sectionId: ValueType,
    stepId: ValueType,
    value: boolean
  ) => void;
  onCheckSection?: (sectionId: ValueType, value: boolean) => void;
}> = ({ checklistSections, disabled = true, onCheckSection, onCheckStep }) => {
  const handleCheckStep = (
    sectionId: ValueType,
    stepId: ValueType,
    value: boolean
  ) => {
    onCheckStep && onCheckStep(sectionId, stepId, value);
  };

  const handleCheckSection = (sectionId: ValueType, value: boolean) => {
    onCheckSection && onCheckSection(sectionId, value);
  };

  return checklistSections.map((section) => {
    return (
      <ChecklistSectionItem
        section={section}
        disabled={disabled}
        onCheckSection={handleCheckSection}
        onCheckStep={handleCheckStep}
      />
    );
  });
};

const ChecklistSectionItem: React.FC<{
  section: ChecklistSection;
  disabled: boolean;
  onCheckStep: (
    sectionId: ValueType,
    stepId: ValueType,
    value: boolean
  ) => void;
  onCheckSection: (sectionId: ValueType, value: boolean) => void;
}> = ({ section, disabled, onCheckStep, onCheckSection }) => {
  const isSectionChecked = section.done ?? false;

  return (
    <div className="flex flex-col gap-2">
      <div
        className={cn(
          "flex gap-2 items-center",
          disabled ? "cursor-not-allowed opacity-75" : "cursor-pointer",
          isSectionChecked && "line-through"
        )}
        onClick={() => onCheckSection(section.section_id, !isSectionChecked)}
      >
        <SimpleBox value={isSectionChecked} disabled={false} />

        <p className="font-semibold text-lg text-high-contrast">
          {section.name}
        </p>
      </div>

      {section.steps?.map((step) => {
        const isStepChecked = step.done ?? false;

        return (
          <div
            className={cn(
              "flex gap-2 items-center border-b-1 border-element-border pb-2",
              disabled ? "cursor-not-allowed opacity-75" : "cursor-pointer",
              isStepChecked && "line-through"
            )}
            onClick={() =>
              onCheckStep(section.section_id, step.step_id, !isStepChecked)
            }
          >
            <div className="w-4" />

            <SimpleBox value={isStepChecked} disabled={false} />

            <p className="font-semibold text-low-contrast">{step.name}</p>
          </div>
        );
      })}
    </div>
  );
};
