import { PlatformDetailsProps } from "../../../pages/PlatForms/Platform.type";
import { MainLayout } from "../../Layout/MainLayout/MainLayout";
import { useTranslation } from "react-i18next";
import { BreadcrumbLayout } from "../../Layout/BreadcrumbLayout/BreadcrumbLayout";
import { Breadcrumbs } from "@mantine/core";
import { AirbnbConnectModal } from "../../Modal/Airbnb/Connect/AirbnbConnectModal";
import { useModal } from "../../../hooks/useModal";

export const PlatFormDetails = ({
  Icon,
  title,
  description,
  children,
  onClickDefaultPage,
  ModalConnect,
}: PlatformDetailsProps) => {
  const { t } = useTranslation();
  const modal = useModal();

  return (
    <BreadcrumbLayout
      sidebarActiveItem="platforms"
      sidebarActiveSubItem="general"
      breadcrumb={
        <Breadcrumbs>
          <h2
            onClick={onClickDefaultPage}
            className={"font-bold text-lg text-gray-500 cursor-pointer"}
          >
            {t("Global.platforms")}
          </h2>
          <h1 className={"font-bold text-xl"}>{title}</h1>
        </Breadcrumbs>
      }
    >
      {children}
    </BreadcrumbLayout>
  );
};
