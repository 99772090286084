import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import InformationIcon from "../../../../assets/icons/information.svg?react";
import InstructionsCheckin from "../../../../assets/images/instructions_checkin.png";
import InstructionsCheckout from "../../../../assets/images/instructions_checkout.png";
import InstructionsGuide from "../../../../assets/images/instructions_guide.png";
import {
  useDisableInformations,
  useEnableInformations,
} from "../../../../hooks/api/guestPage";
import { Badge } from "../../../Common/Badge/Badge";
import { Card } from "../../../Common/Card/Card";
import { SettingElement } from "../../../Common/SettingElement/SettingElement";
import { Tooltip } from "../../../Common/Tooltip/Tooltip";
import { RentalInstructionsProps } from "../Contact/ContactCard.type";
import { EditInstructionModal } from "./EditInstructionModal";
import { InstructionCardProps } from "./InstructionCard.type";
import { InstructionCardSkeleton } from "./InstructionCardSkeleton";
import useUserStore from "../../../../stores/useUserStore";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";

type instructions_type =
  | "arrival_instruction"
  | "checkout_procedure"
  | "welcome_guide";
export const InstructionCard: React.FC<RentalInstructionsProps> = ({
  loading,
  rentals,
  rentalId,
  instructions,
  guestPage,
  workspaceOwner,
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [list_instructions, setListInstructions] = useState<
    InstructionCardProps[]
  >(instructions || []);
  const [instructionSelected, setInstructionSelected] = useState(null);
  const [titleModal, setTitleModal] = useState("");
  const [activeInformation, setActiveInformation] = useState<boolean>(
    guestPage?.infos_enabled === 1 ? true : false
  );

  const handleActiveInformationTravelerPage = () => {
    console.log("handleActiveInformationTravelerPage");
    // Functionality for handling traveler page information
    setActiveInformation(!activeInformation);
    setValidationLoading(true);
    if (activeInformation) {
      useDisableInformations(
        rentalId,
        (response) => {
          setValidationLoading(false);
          console.log("useDisableInformations", response);
        },
        (error) => {
          setValidationLoading(false);
          console.log("useDisableInformations", error);
        }
      );
    } else {
      useEnableInformations(
        rentalId,
        (response) => {
          setValidationLoading(false);
        },
        (error) => {
          setValidationLoading(false);
          console.log("useEnableInformations", error);
        }
      );
    }
  };

  const [isEditModalVisible, setEditModalVisible] = useState(false);

  if (loading) return <InstructionCardSkeleton />;

  const handleEditInstruction = (value: any, title: string) => {
    setInstructionSelected(value);
    setTitleModal(title);
    setEditModalVisible(true);
  };

  const displayImage = (type?: instructions_type) => {
    switch (type) {
      case "arrival_instruction":
        return InstructionsCheckin;
      case "checkout_procedure":
        return InstructionsCheckout;
      case "welcome_guide":
        return InstructionsGuide;
      default:
        return "";
    }
  };

  const getDescription = (type?: instructions_type) => {
    let description = "";
    switch (type) {
      case "arrival_instruction":
        description = "checkinDescription";
        break;
      case "checkout_procedure":
        description = "checkoutDescription";
        break;
      case "welcome_guide":
        description = "welcomeDescription";
        break;
    }
    return description
      ? t(`Rental.Infos.TravelerPage.InstructionCard.${description}`)
      : description;
  };

  return (
    <Card
      loading={validationLoading}
      Icon={InformationIcon}
      label={t("Rental.Infos.TravelerPage.InstructionCard.title")}
      button={{}}
      secondaryButton={undefined}
      switchButton={{
        label: t("Rental.Infos.TravelerPage.ContactCard.switch"),
        value: activeInformation,
        loading: validationLoading,
        disabled: !getCurrentCanEdit({ user: userStore.user, workspaceOwner }),
        onClick: () => {
          handleActiveInformationTravelerPage();
        },
      }}
    >
      <div className="flex flex-col gap-y-4">
        {editMode ? (
          <></>
        ) : (
          <>
            {list_instructions.map((instruction, index) => (
              <div className="flex flex-col space-y-2" key={index}>
                {/* ADVANCE RESERVATION NOTICE */}
                <SettingElement
                  type="file"
                  Icon={InformationIcon}
                  title={t(`Rental.Infos.TravelerPage.${instruction.type}`)}
                  description={
                    <div>
                      {getDescription(instruction?.type as instructions_type)}
                    </div>
                  }
                  src={displayImage(instruction?.type as instructions_type)}
                >
                  <div className="flex items-center space-x-10">
                    <div className="flex space-x-4">
                      <div>
                        <Tooltip
                          Icon={
                            <Badge
                              label={
                                instruction.infos_enabled
                                  ? t("GuestPage.Contact.emailVisible")
                                  : t("GuestPage.Contact.emailNotVisible")
                              }
                              status={
                                instruction.infos_enabled ? "success" : "fail"
                              }
                              size="large"
                            />
                          }
                          value={
                            instruction.infos_enabled
                              ? t("GuestPage.Contact.emailVisible")
                              : t("GuestPage.Contact.emailNotVisible")
                          }
                          classNames={{
                            container: "top-full mt-1 right-0",
                          }}
                        />
                      </div>
                      <div
                        className="mt-2 font-bold"
                        onClick={() =>
                          handleEditInstruction(
                            instruction,
                            t(`Rental.Infos.TravelerPage.${instruction.type}`)
                          )
                        }
                      >
                        <EditIcon />
                      </div>
                    </div>
                  </div>
                </SettingElement>
              </div>
            ))}

            {instructionSelected != null && (
              <EditInstructionModal
                isVisible={isEditModalVisible}
                onClose={() => setEditModalVisible(false)}
                onFinish={() => setEditModalVisible(false)}
                instruction={instructionSelected}
                titleModal={titleModal}
                rentalId={rentalId}
                setListInstructions={setListInstructions}
              />
            )}
          </>
        )}
      </div>
    </Card>
  );
};
