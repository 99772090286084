import { t } from "i18next";
import { useMemo } from "react";
import {
  ManagementAutomationListMenuSections,
  ManagementAutomationNavigationMenuItem,
} from "../../components/ManagementAutomationList/ManagementAutomationFilters/ManagementAutomationListFilterButtons.type";

export const useManagementAutomationNavbarItems = (
  activeItem: ManagementAutomationListMenuSections
): ManagementAutomationNavigationMenuItem[] => {
  const navbarItems = useMemo(() => {
    const items: {
      value: ManagementAutomationListMenuSections;
      labelKey: string;
    }[] = [
      { value: "all", labelKey: "Automation.all" },
      { value: "cleaning", labelKey: "Automation.cleaning" },
      {
        value: "checkout",
        labelKey: "Automation.passengers_departure",
      },
      { value: "other", labelKey: "Automation.others" },
    ];

    return items.map((item) => ({
      value: item.value,
      label: t(item.labelKey),
      isActive: activeItem === item.value,
    }));
  }, [activeItem, t]);

  return navbarItems;
};
