import React, {useEffect, useRef, useState} from "react";
import {ResetProps, ResetFormType} from "./Reset.type";
import {Title} from "../../Common/Title/Title";
import {useTranslation} from "react-i18next";
import {TextInput} from "../../Common/TextInput/TextInput";
import {Button} from "../../Common/Button/Button";
import {useForm} from "react-hook-form";
import LockIcon from "../../../assets/icons/lock-white.svg?react";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import {Keys} from "../../../values";
import AlertCircle from "../../../assets/icons/alert-circle.svg?react";

export const Reset: React.FC<ResetProps> = ({
  error,
  loading,
  onSubmit = () => {},
}) => {
  const {t} = useTranslation();
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: {errors},
  } = useForm<ResetFormType>();

  const passwordRef = useRef({});
  passwordRef.current = watch("password", "");

  const [criteria, setCriteria] = useState({
    length: false,
    upper: false,
    number: false,
    specialChar: false,
  });
  const password = watch("password");

  const handleSave = () => {
    onSubmit({
      password: getValues().password,
      confirmation_password: getValues().confirmation_password,
    });
  };

  const validatePassword = (password: string) => {
    setCriteria({
      length: password?.length >= 8,
      upper: /[A-Z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[\W_]/.test(password),
    });
  };

  useEffect(() => {
    validatePassword(passwordRef.current as string);
  }, [passwordRef.current]);

  return (
    <div>
      <Title>{t("Reset.title")}</Title>

      <div className="mt-4">
        <Title type="secondary">{t("Reset.subTitle")}</Title>
      </div>

      <div className="mt-8">
        <TextInput
          register={register("password", {
            required: t("Reset.required").toString(),
            minLength: {
              value: 8,
              message: t("Reset.minLength").toString(),
            },
            maxLength: {
              value: 24,
              message: t("Reset.maxLength").toString(),
            },
            pattern: {
              value:
                /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,24}$/,
              message: t("Reset.invalidFormat").toString(),
            },
          })}
          label={t("Reset.create")}
          type="password"
          error={errors.password?.message}
          required={true}
          disabled={loading}
          autoComplete="password"
        />
      </div>

      <div>
        <p
          className={`mt-2 text-sm font-light text-left ${
            criteria.length ? "text-green" : "text-low-contrast "
          } flex flex-row gap-1`}
        >
          <AlertCircle
            className={`${
              criteria.length ? "fill-green" : "fill-gray-500 "
            } me-2 shrink-0`}
            width={18}
            height={18}
          />
          {t("Reset.rule1Title")}
        </p>
        <p
          className={`mt-2 text-sm font-light text-left ${
            criteria.upper ? "text-green" : "text-low-contrast "
          } flex flex-row gap-1`}
        >
          <AlertCircle
            className={`${
              criteria.upper ? "fill-green" : "fill-gray-500 "
            } me-2 shrink-0`}
            width={18}
            height={18}
          />
          {t("Reset.rule4Title")}
        </p>
        <p
          className={`mt-2 text-sm font-light text-left ${
            criteria.number ? "text-green" : "text-low-contrast "
          }  flex flex-row gap-1`}
        >
          <AlertCircle
            className={`${
              criteria.number ? "fill-green" : "fill-gray-500 "
            } me-2 shrink-0`}
            width={18}
            height={18}
          />
          {t("Reset.rule2Title")}
        </p>
        <p
          className={`mt-2 text-sm font-light text-left ${
            criteria.specialChar ? "text-green" : "text-low-contrast "
          }  flex flex-row gap-1`}
        >
          <AlertCircle
            className={`${
              criteria.specialChar ? "fill-green" : "fill-gray-500 "
            } me-2 shrink-0`}
            width={18}
            height={18}
          />
          {t("Reset.rule3Title")}
        </p>
      </div>

      <div className="mt-4">
        <TextInput
          register={register("confirmation_password", {
            validate: (value) =>
              value === passwordRef.current || t("Reset.notMatch").toString(),
          })}
          label={t("Reset.confirm")}
          type="password"
          error={errors.confirmation_password?.message}
          disabled={loading}
          required={true}
        />
      </div>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      <div className="flex mt-4">
        <Button
          loading={loading}
          keyToSubmit={Keys.Enter}
          onClick={handleSubmit(handleSave)}
          displayLargeBtn={false}
          className="flex w-[50%]"
        >
          {t("Reset.submit")}
        </Button>
      </div>
    </div>
  );
};
