import React from "react";
import { useTranslation } from "react-i18next";
import { RentalFacilitiesViewGroupProps } from "./Facilities.type";

export const RentalFacilitiesViewGroup: React.FC<
  RentalFacilitiesViewGroupProps
> = ({ equipments, group, displayBorder = true }) => {
  const { t } = useTranslation();

  const containsAnEquipment = () => {
    let result = false;

    group.facilities.forEach((f) => {
      if (
        equipments?.findIndex(
          (e) => e.toLowerCase() === f.code.toLowerCase()
        ) !== -1
      )
        result = true;
    });

    return result;
  };

  if (!containsAnEquipment()) return null;

  return (
    <div
      className={`flex flex-col gap-2 pb-3 my-3 ${
        displayBorder && "border-b-1 border-element-border last:border-0"
      }`}
    >
      <div className="flex items-center gap-2">
        {group.Icon && <group.Icon className="w-4 h-4" />}
        <p className="font-semibold text-high-contrast">{t(group.nameI18n)}</p>
      </div>

      <ul className="grid grid-cols-2 pl-4 font-light list-disc text-low-contrast">
        {group.facilities.map((f) => {
          if (
            equipments?.findIndex(
              (e) => e.toLowerCase() === f.code.toLowerCase()
            ) !== -1
          )
            return (
              <li key={f.code} className="mr-3">
                {t(f.nameI18n)}
              </li>
            );
        })}
      </ul>
    </div>
  );
};
