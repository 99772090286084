import { RefObject, useEffect } from "react";

type UseOutsideDetectorProps = (
  ref: RefObject<HTMLElement>,
  onClick: () => void
) => void;

export const useOutsideDetector: UseOutsideDetectorProps = (ref, onClick) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && ref.current.contains(event.target as Node)) {
        return;
      }
      onClick();
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClick]);
};
