import React from "react";
import { useTranslation } from "react-i18next";

import ChevronDownIcon from "../../../assets/icons/chevron-down.svg?react";
import ChevronUpIcon from "../../../assets/icons/chevron-up.svg?react";

type RentalTabHeaderProps = {
  sortField: string;
  sortDirection: "asc" | "desc";
  onChangeSort: (column: string) => void;
};

export const RentalTableHeader: React.FC<RentalTabHeaderProps> = ({
  sortField,
  sortDirection,
  onChangeSort = () => {},
}) => {
  const { t } = useTranslation();

  const handleChangeSort = (column: string) => {
    let nextDirection: "asc" | "desc" = "asc";

    if (column === sortField && sortDirection === "asc") nextDirection = "desc";

    onChangeSort(column);
  };

  const getIcon = (column: string) => {
    const isActive = sortField === column;

    if (isActive && sortDirection === "asc") return <ChevronDownIcon />;
    else if (isActive && sortDirection === "desc") return <ChevronUpIcon />;
    else return <ChevronDownIcon />;
  };

  return (
    <tr className="table-header-group w-full bg-element-background border-b-1 border-element-border">
      <th
        className="w-2/12 px-2 py-3 text-sm ps-4 pe-2 rounded-tl-6px"
        onClick={() => handleChangeSort("name")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "name" && "underline"
          }`}
        >
          <p>{t("RentalList.rental")}</p> {getIcon("name")}
        </div>
      </th>

      <th
        className="w-1/12 px-2 py-3 text-sm"
        onClick={() => handleChangeSort("enabled")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "enabled" && "underline"
          }`}
        >
          <p>{t("RentalList.status")}</p> {getIcon("enabled")}
        </div>
      </th>

      <th
        className="w-1/12 px-2 py-3 text-sm"
        onClick={() => handleChangeSort("synced")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "synced" && "underline"
          }`}
        >
          <p>{t("RentalList.sync")}</p> {getIcon("synced")}
        </div>
      </th>

      <th
        className="w-1/12 px-2 py-3 text-sm"
        onClick={() => handleChangeSort("guests_max_capacity")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "guests_max_capacity" && "underline"
          }`}
        >
          <p>{t("RentalList.capacityTraveler")}</p>{" "}
          {getIcon("guests_max_capacity")}
        </div>
      </th>

      <th className="w-1/12 px-2 py-3 text-sm">
        <div className="flex flex-row items-center justify-center cursor-pointer hover:underline">
          <p>{t("RentalList.checkinCheckout")}</p>
        </div>
      </th>

      <th className="w-1/12 px-2 py-3 text-sm rounded-tr-6px">
        <div className="flex flex-row items-center justify-center cursor-pointer hover:underline">
          <p>{t("RentalList.cleaningFees")}</p>
        </div>
      </th>
    </tr>
  );
};
