import React, { ReactNode, isValidElement, useRef, useState } from "react";
import InformationIcon from "../../../assets/icons/information-fill.svg?react";
import { cn } from "../../../helpers/classHelper";
import { useOutsideDetector } from "../../../hooks/useOutsideDetector";
import { TooltipProps } from "./Tooltip.type";

export const Tooltip: React.FC<TooltipProps> = ({
  Icon = InformationIcon,
  value,
  mode = "hover",
  size = "normal",
  classNames,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const tooltipContainerRef = useRef(null);
  useOutsideDetector(tooltipContainerRef, () => setIsActive(false));

  const getTooltipNode = (): ReactNode => {
    let node: ReactNode | null = null;
    if (mode === "click") {
      node = (
        <>
          {isActive ? (
            <div
              className={cn(
                `absolute flex flex-col flex-wrap items-center mt-6 transition-all duration-300 ease-in z-50 tooltip_information`,
                classNames?.container,
                {
                  "top-0 left-0": classNames?.container === undefined,
                  "w-44": size === "normal",
                  "w-52": size === "medium",
                  "w-60": size === "large",
                  "w-max": size === "full",
                }
              )}
            >
              <span className="relative z-10 p-2 text-xs font-bold leading-none text-white rounded shadow-lg select-none bg-element-background-solid">
                {value}
              </span>
            </div>
          ) : null}
        </>
      );
    } else if (mode === "hover") {
      node = (
        <div
          className={cn(
            `absolute flex flex-col flex-wrap items-center mt-6 transition-all duration-300 ease-in z-50 tooltip_information invisible opacity-0 group-hover/tooltip_information:visible group-hover/tooltip_information:opacity-100`,
            classNames?.container,
            {
              "top-0 left-0": classNames?.container === undefined,
              "w-44": size === "normal",
              "w-52": size === "medium",
              "w-60": size === "large",
              "w-max": size === "full",
            }
          )}
        >
          <span className="relative z-10 p-2 text-xs font-bold leading-none text-white rounded shadow-lg select-none bg-element-background-solid">
            {value}
          </span>
        </div>
      );
    }

    return node;
  };

  return (
    <div
      className={`relative top-0 flex flex-col items-center group/tooltip_information w-max ${
        mode === "click" ? "cursor-pointer" : ""
      }`}
      ref={tooltipContainerRef}
      onClick={() => setIsActive(!isActive)}
    >
      {typeof Icon === "function" ? <Icon /> : null}

      {isValidElement(Icon) ? Icon : null}

      {getTooltipNode()}
    </div>
  );
};
