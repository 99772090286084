import React from "react";
import { useTranslation } from "react-i18next";

import ArrowRightIcon from "../../../assets/icons/arrow-right.svg?react";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import MailIcon from "../../../assets/icons/mail.svg?react";
import PhoneIcon from "../../../assets/icons/phone.svg?react";
import FlagIcon from "../../../assets/icons/flag.svg?react";
import UsersIcon from "../../../assets/icons/users.svg?react";
import EmojiHappyIcon from "../../../assets/icons/emoji-happy.svg?react";
import BuildingIcon from "../../../assets/icons/building.svg?react";
import MoonIcon from "../../../assets/icons/moon.svg?react";
import CashIcon from "../../../assets/icons/cash.svg?react";

export const ReservationSmallDetailsSkeleton: React.FC<{
  removeToHeight?: number;
}> = ({ removeToHeight = 120 }) => {
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-col w-80 h-full px-3 py-4 animate-pulse"
      style={{
        height: `calc(100vh - ${removeToHeight}px)`,
      }}
    >
      <div className="flex flex-row space-x-2 items-center mt-2">
        <div className="w-8 h-8 rounded-full border-1 border-element-border bg-slate-200"></div>

        <div className="flex-1">
          <p className="h-2 w-full rounded bg-slate-300"></p>
          <p className="h-2 w-full rounded bg-slate-200 mt-1"></p>
          <p className="h-2 w-16 rounded bg-slate-200 mt-1"></p>
        </div>
      </div>

      <div className="flex flex-row space-x-2 mt-6 border-b-1 border-element-border-light pb-2 items-center">
        <MailIcon className="w-6" />
        <p className="h-2 w-full rounded bg-slate-300"></p>
      </div>

      <div className="flex flex-row space-x-2 mt-3 border-b-1 border-element-border-light pb-2 items-center">
        <PhoneIcon className="w-6" />
        <p className="h-2 w-full rounded bg-slate-300"></p>
      </div>

      <div className="flex flex-row space-x-2 mt-3 border-b-1 border-element-border-light pb-2 items-center">
        <FlagIcon className="w-6" />
        <p className="h-2 w-8 rounded bg-slate-300"></p>
        <p className="h-2 w-16 rounded bg-slate-200"></p>
      </div>

      <div className="mt-6 flex w-full">
        <div className="w-full h-9 bg-slate-200 rounded-6px" />
      </div>

      <div className="flex flex-row text-sm font-semibold border-b-1 border-element-border-light gap-2">
        <div className="flex-1 flex border-r-1 border-element-border-light py-3 items-center">
          <UsersIcon className="inline mr-1 w-6" />
          <p className="h-2 w-8 rounded bg-slate-300"></p>
        </div>
        <div className="flex-1 py-3 flex items-center">
          <EmojiHappyIcon className="inline mr-1 w-6" />
          <p className="h-2 w-8 rounded bg-slate-300"></p>
        </div>
      </div>

      <div className="flex flex-row space-x-2 mt-3 border-b-1 border-element-border-light pb-2 items-center">
        <BuildingIcon className="w-6" />
        <p className="h-2 w-full rounded bg-slate-300"></p>
      </div>

      <div className="flex flex-row text-sm font-semibold border-b-1 border-element-border-light gap-2">
        <div className="flex-1 border-r-1 border-element-border-light py-3 flex items-center">
          <MoonIcon className="inline mr-1 w-6" />
          <p className="h-2 w-8 rounded bg-slate-300"></p>
        </div>
        <div className="flex-1 py-3 flex items-center">
          <CashIcon className="inline mr-1 w-6" />
          <p className="h-2 w-8 rounded bg-slate-300"></p>
        </div>
      </div>

      <div className="flex flex-row space-x-2 mt-5 border-b-1 border-element-border-light pb-2 items-center">
        <ArrowRightIcon className="w-6" />
        <p className="h-2 w-52 rounded bg-slate-300"></p>
      </div>

      <div className="flex flex-row space-x-2 mt-3 border-b-1 border-element-border-light pb-2 items-center">
        <ArrowLeftIcon className="w-6" />
        <p className="h-2 w-52 rounded bg-slate-300"></p>
      </div>

      <p className="text-sm text-low-contrast font-light mt-3">
        {t("MessageList.remarks")}
      </p>

      <div className="mt-1 w-full h-24 border-1 border-element-border rounded-6px hover:border-element-border-hover active:border-element-border-active focus:outline-1 focus:outline-focus p-2 bg-slate-200" />

      <p className="text-sm text-low-contrast font-light mt-3">
        {t("MessageList.otherInformations")}
      </p>

      <div className="mt-1 w-full h-24 border-1 border-element-border rounded-6px hover:border-element-border-hover active:border-element-border-active focus:outline-1 focus:outline-focus p-2 bg-slate-200" />
    </div>
  );
};
