import React from "react";
import { HistoricalStepProps } from "./HistoricalStep.type";
import { Button } from "../Button/Button";

export const HistoricalStep: React.FC<HistoricalStepProps> = ({
  title,
  description,
  Icon,
  type = "neutral",
  isLast = false,
  isLineThrough,
  button,
  loading,
  onClick = () => {},
}) => {
  const getBg = () => {
    if (type === "neutral") {
      return "bg-element-background";
    } else if (type === "success") {
      return "bg-green-200";
    } else if (type === "error") {
      return "bg-red-200";
    }
  };

  return (
    <div className="flex flex-row gap-2 justify-between">
      <div className="flex flex-rown gap-2">
        <div className="flex flex-col items-center">
          {loading ? (
            <div className="w-8 h-8 rounded-4px bg-slate-200 animate-pulse" />
          ) : (
            <div
              className={`flex ${getBg()} w-8 h-8 rounded-4px items-center justify-center`}
            >
              <Icon className="w-6 h-6" />
            </div>
          )}

          {!isLast && (
            <div className="border-1 border-element-border h-10 w-0 rounded-2px my-1"></div>
          )}
        </div>

        {loading ? (
          <div className="mt-2">
            <p className={`h-2 bg-slate-300 w-52 rounded animate-pulse`} />

            <p className={`h-2 bg-slate-200 w-72 rounded mt-1 animate-pulse`} />
          </div>
        ) : (
          <div className="mt-2">
            <p
              className={`font-semibold text-high-contrast ${
                isLineThrough && "line-through"
              }`}
            >
              {title}
            </p>

            <p className="font-light text-low-contrast">{description}</p>
          </div>
        )}
      </div>

      {button && !loading && (
        <div className="flex flex-row gap-1">
          <Button
            type="secondary"
            size="small"
            onClick={onClick}
            LeftIcon={button.Icon}
            dropItems={button.dropItems}
          >
            {button.label}
          </Button>
        </div>
      )}

      {button && loading && (
        <div className="flex flex-row gap-1">
          <div className="h-8 w-24 bg-slate-200 rounded animate-pulse" />
        </div>
      )}
    </div>
  );
};

export const HistoricalStepSkeleton: React.FC<{
  isLast?: boolean;
  isLineThrough?: boolean;
}> = ({ isLast, isLineThrough }) => {
  return (
    <div className="flex flex-row gap-2 justify-between cursor-pointer">
      <div className="flex flex-rown gap-2">
        <div className="flex flex-col items-center">
          <div
            className={`flex w-8 h-8 rounded-4px items-center justify-center bg-slate-200`}
          >
            <div className="w-6 h-6 bg-slate-200" />
          </div>

          {!isLast && (
            <div className="border-1 border-element-border h-10 w-0 rounded-2px my-1"></div>
          )}
        </div>

        <div className="mt-2">
          <p
            className={`w-52 h-2 rouded bg-slate-300 ${
              isLineThrough && "line-through"
            }`}
          ></p>

          <p className="bg-slate-200 w-52 h-2 rounded mt-1"></p>
        </div>
      </div>
    </div>
  );
};
