import React from "react";
import {RentalGroup} from "../../../pages/RentalPage/RentalPage.type";
import {ErrorMessage} from "../ErrorMessage/ErrorMessage";
import {PagingButtons} from "../PagingButtons/PagingButtons";
import {TableListWithGroupProps} from "./TableList.type";
import {TableListWithGroupItem} from "./TableListWithGroupItem";

export const TableListWithGroup: React.FC<TableListWithGroupProps> = ({
  tablePage,
  i18nElement,
  Filters,
  ViewTypeGroupButton,
  Header,
  Item,
  Skeleton,
  NoData,
  selectedItems = [],
  nbCols = 2,
  onTriggerUpdateGroup,
  onTriggerRemoveGroup,
  onClick,
  onClickAction,
}) => {
  const {
    error,
    loading,
    currentSortField,
    currentSortDirection,
    currentOtherMetas,
    handleChangeSort,
    currentNbPerPage,
    currentPage,
    total,
    lastPage,
    handleChangeNbPerPage,
    handlePaginationBack,
    handlePaginationNext,
    currentSearch,
    data,
  } = tablePage;
  const totalRentals: number = Number(
    currentOtherMetas.find((meta) => meta.key === "total_rentals")?.value
  );

  const getAreAllSelected = () => {
    let result = true;

    data?.forEach((d: any) => {
      const exists = selectedItems?.findIndex((i) => i === d.id) > -1;
      if (!exists) result = false;
    });

    return result;
  };

  const handleSelectAll = () => {
    if (getAreAllSelected()) {
      // Si tout est selectionné, on envoie un évènement de Click sur tous les éléments, comme ça ça les déselectionne
      data?.forEach((d: any) => {
        onClick(d.id);
      });
    } else {
      // sinon, on envoie un évènement de Click sur tous les éléments non sélectionnés pour qu'ils soient tous sélectionné
      data?.forEach((d: any) => {
        const exists = selectedItems?.findIndex((i) => i === d.id) > -1;

        if (!exists) onClick(d.id);
      });
    }
  };

  if (error && error !== "") return <ErrorMessage>{error}</ErrorMessage>;

  return (
    <>
      {Filters}

      <div className="mt-4">
        {ViewTypeGroupButton}

        <table
          className={`w-full overflow-y-auto border-separate table-auto ${
            !loading && data.length === 0
              ? "border-s-1 border-e-1 border-t-1 rounded-ss-8px rounded-se-8px"
              : "border-1 rounded-8px"
          } border-element-border border-spacing-0`}
        >
          <Header
            sortField={currentSortField}
            sortDirection={currentSortDirection}
            areAllSelected={getAreAllSelected()}
            onChangeSort={handleChangeSort}
            onSelectAll={handleSelectAll}
          />

          {!loading && data.length === 0 ? (
            <tr className="relative">
              <td className="absolute w-full border-1 border-element-border">
                {NoData}
              </td>
            </tr>
          ) : (
            <>
              {loading &&
                Array.from({ length: currentNbPerPage }).map((v, index) => (
                  <Skeleton key={index} />
                ))}

              {!loading &&
                data?.map((group: RentalGroup, index: number) => (
                  <TableListWithGroupItem
                    key={index}
                    Item={Item}
                    group={group}
                    nbCols={nbCols}
                    selectedItems={selectedItems}
                    onClick={onClick}
                    onClickAction={onClickAction}
                    onTriggerRemoveGroup={onTriggerRemoveGroup}
                    onTriggerUpdateGroup={onTriggerUpdateGroup}
                  />
                ))}
            </>
          )}
        </table>
      </div>

      <PagingButtons
        i18nElement={i18nElement}
        loading={loading}
        nbPerPage={currentNbPerPage}
        page={currentPage}
        total={totalRentals}
        lastPage={lastPage}
        onChangeNbPerPage={handleChangeNbPerPage}
        onPaginationBack={handlePaginationBack}
        onPaginationNext={handlePaginationNext}
      />
    </>
  );
};
