import React, { useEffect, useMemo } from "react";
import { Title } from "../Common/Title/Title";
import { Trans, useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { RegisterMemberInfosForm } from "./RegisterMemberStepInfos.type";
import { TextInput } from "../Common/TextInput/TextInput";
import { Button } from "../Common/Button/Button";
import ArrowRightWhiteIcon from "../../assets/icons/arrow-right-white.svg?react";
import { PhoneInput } from "../Common/PhoneInput/PhoneInput";
import { InputSelect } from "../Common/InputSelect/InputSelect";
import countries from "../../constants/countries";
import languages from "../../res/languages";
import currencies from "../../res/currencies";
import { CheckBox } from "../Common/CheckBox/CheckBox";
import { Link } from "../Common/Link/Link";
import paths from "../../constants/paths";

export const RegisterMemberStepInfos: React.FC<{
  infos: RegisterMemberInfosForm;
  onNextStep: (values: RegisterMemberInfosForm) => void;
}> = ({ infos, onNextStep }) => {
  const { t } = useTranslation();
  const form = useForm<RegisterMemberInfosForm>({
    defaultValues: {
      email: "todo@todo.com",
      firstName: "",
      lastName: "",
      company: "",
      phone: "",
      address: "",
      city: "",
      zip: "",
      country: 73,
      language: "fr",
      currency: 28,
      accept: false,
    },
  });

  useEffect(() => {
    if (infos) {
      form.setValue("email", infos.email);
      form.setValue("firstName", infos.firstName);
      form.setValue("lastName", infos.lastName);
      form.setValue("company", infos.company);
      form.setValue("phone", infos.phone);
      form.setValue("address", infos.address);
      form.setValue("city", infos.city);
      form.setValue("zip", infos.zip);
      form.setValue("country", infos.country);
      form.setValue("language", infos.language);
      form.setValue("currency", infos.currency);
      form.setValue("accept", infos.accept);
    }
  }, [infos]);

  const currencyItems = useMemo(
    () =>
      currencies.map((c) => {
        return { value: c.id, label: `${c.name} (${c.symbol})` };
      }),
    []
  );

  return (
    <form onSubmit={form.handleSubmit(onNextStep)}>
      <div className="flex flex-col space-y-4 text-sm">
        <Title>{t("RegisterMember.Infos.title")}</Title>

        <p className="font-light text-low-contrast">
          {t("RegisterMember.Infos.subTitle")}
        </p>

        <div className="h-1" />

        <Controller
          control={form.control}
          name="email"
          render={({ field: { value } }) => (
            <TextInput
              label={t("RegisterMember.Infos.email")}
              value={value}
              disabled={true}
            />
          )}
        />

        <div className="grid grid-cols-2 gap-4">
          <Controller
            control={form.control}
            name="firstName"
            rules={{
              required: {
                value: true,
                message: t("RegisterMember.Infos.firstNameRequired"),
              },
            }}
            render={({ field: { value, onChange } }) => (
              <TextInput
                label={t("RegisterMember.Infos.firstName")}
                required={true}
                error={form.formState.errors.firstName?.message}
                value={value}
                onChangeText={onChange}
              />
            )}
          />

          <Controller
            control={form.control}
            name="lastName"
            rules={{
              required: {
                value: true,
                message: t("RegisterMember.Infos.lastNameRequired"),
              },
            }}
            render={({ field: { value, onChange } }) => (
              <TextInput
                label={t("RegisterMember.Infos.lastName")}
                required={true}
                error={form.formState.errors.lastName?.message}
                value={value}
                onChangeText={onChange}
              />
            )}
          />
        </div>

        <Controller
          control={form.control}
          name="company"
          render={({ field: { value, onChange } }) => (
            <TextInput
              label={t("RegisterMember.Infos.company")}
              value={value}
              onChangeText={onChange}
            />
          )}
        />

        <Controller
          control={form.control}
          name="phone"
          rules={{
            required: t("RegisterMember.Infos.phoneRequired").toString(),
            minLength: {
              value: 10,
              message: t("RegisterMember.Infos.phoneRequired").toString(),
            },
          }}
          render={({ field: { value, onChange } }) => (
            <PhoneInput
              label={t("RegisterMember.Infos.phone")}
              required={true}
              value={value}
              error={form.formState.errors.phone?.message}
              onChangeText={onChange}
            />
          )}
        />

        <div className="grid grid-cols-2 gap-4">
          <Controller
            control={form.control}
            name="address"
            render={({ field: { value, onChange } }) => (
              <TextInput
                label={t("RegisterMember.Infos.address")}
                value={value}
                onChangeText={onChange}
              />
            )}
          />

          <Controller
            control={form.control}
            name="city"
            render={({ field: { value, onChange } }) => (
              <TextInput
                label={t("RegisterMember.Infos.city")}
                value={value}
                onChangeText={onChange}
              />
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <Controller
            control={form.control}
            name="zip"
            render={({ field: { value, onChange } }) => (
              <TextInput
                label={t("RegisterMember.Infos.zip")}
                value={value}
                onChangeText={onChange}
              />
            )}
          />

          <Controller
            control={form.control}
            name="country"
            render={({ field: { value, onChange } }) => (
              <div className="flex-1">
                <InputSelect
                  label={t("RegisterMember.Infos.country")}
                  selectedValue={value}
                  size="small"
                  items={countries}
                  error={form.formState.errors.country?.message}
                  onSelect={onChange}
                />
              </div>
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-4 items-end">
          <Controller
            control={form.control}
            name="language"
            render={({ field: { value, onChange } }) => (
              <InputSelect
                label={t("RegisterMember.Infos.language")}
                selectedValue={value}
                required={true}
                size="small"
                items={languages}
                error={form.formState.errors.country?.message}
                onSelect={onChange}
              />
            )}
          />

          <Controller
            control={form.control}
            name="currency"
            render={({ field: { value, onChange } }) => (
              <div className="flex-1">
                <InputSelect
                  label={t("RegisterMember.Infos.currency")}
                  selectedValue={value}
                  required={true}
                  size="small"
                  items={currencyItems}
                  error={form.formState.errors.country?.message}
                  onSelect={onChange}
                />
              </div>
            )}
          />
        </div>

        <Controller
          control={form.control}
          name="accept"
          rules={{
            required: {
              value: true,
              message: t("RegisterMember.Infos.acceptError"),
            },
          }}
          render={({ field: { value, onChange } }) => (
            <CheckBox
              value={value}
              onChange={onChange}
              label={
                <span className="text-secondary text-sm font-light">
                  <Trans
                    i18nKey="Register.Personnal.acceptGCUandPPandEthic"
                    components={{
                      gcu: (
                        <Link url={paths.GLOBAL.CGV} openTab={true}>
                          {t("Register.Personnal.GCU")}
                        </Link>
                      ),
                      pp: (
                        <Link url={paths.GLOBAL.PRIVACY} openTab={true}>
                          {t("Register.Personnal.PP")}
                        </Link>
                      ),
                      ethic: (
                        <Link url={paths.GLOBAL.ETHIC} openTab={true}>
                          {t("Register.Personnal.ETHIC")}
                        </Link>
                      ),
                    }}
                  />
                </span>
              }
              required={true}
              error={form.formState.errors.accept?.message}
            />
          )}
        />

        <div className="flex flex-col md:grid md:grid-cols-2 pt-4">
          <div />
          <Button RightIcon={ArrowRightWhiteIcon}>
            {t("Global.nextStep")}
          </Button>
        </div>
      </div>
    </form>
  );
};
