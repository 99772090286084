import React, { useEffect, useState } from "react";
import { RegisterSummaryProps } from "./RegisterSummary.type";
import { useTranslation } from "react-i18next";
import AirbnbIcon from "../../../../assets/icons/airbnb.svg?react";
import BookingIcon from "../../../../assets/icons/booking.svg?react";
import VRBOIcon from "../../../../assets/icons/vrbo.svg?react";

export const RegisterSummary: React.FC<RegisterSummaryProps> = ({
  firstName,
  lastName,
  types,
  platforms,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <div className="bg-white border-1 border-element-border p-4 rounded-t-lg">
        <p className="font-semibold">
          {firstName} {lastName}
        </p>

        <p className="text-sm font-light text-secondary">
          {t("Register.Sum.new")}
        </p>
      </div>

      <div className="flex flex-row items-center p-4 bg-white border-1 border-t-0 border-element-border">
        <p className="w-40 font-semibold text-sm">{t("Register.Sum.types")}</p>

        <div className="flex flex-row overflow-clip space-x-2 h-9 items-center justify-center p-1">
          {types?.filter((x) => x !== undefined).length === 1 && (
            <>
              <div className=" p-2 bg-element-background rounded-8px text-sm text-low-contrast font-semibold">
                {t(`Register.Sum.${types[0]}`)}
              </div>
            </>
          )}

          {types && types?.filter((x) => x !== undefined).length > 1 && (
            <>
              <div className=" p-2 bg-element-background rounded-8px text-sm text-low-contrast font-semibold">
                {t(`Register.Sum.${types[0]}`)}
              </div>

              <div className=" p-2 bg-element-background rounded-8px text-sm text-low-contrast font-semibold">
                ...
              </div>
            </>
          )}
        </div>
      </div>

      <div className="flex flex-row items-center p-4 bg-white border-1 border-t-0 border-element-border">
        <p className="w-40 font-semibold text-sm">
          {t("Register.Sum.platforms")}
        </p>

        <div className="flex flex-row h-9 space-x-2 items-center overflow-hidden">
          {(!platforms || platforms.length === 0) &&
            [1, 2, 3, 4].map((i) => {
              return (
                <span
                  key={i}
                  className="w-8 h-8 rounded-full bg-element-background border-1 border-dashed border-element-border"
                ></span>
              );
            })}

          {platforms?.slice(0, 4).map((p) => {
            switch (p) {
              case "airbnb":
                return <AirbnbIcon key={p} className="w-8 h-8" />;
              case "booking":
                return <BookingIcon key={p} className="w-8 h-8" />;
              case "vrbo":
                return <VRBOIcon key={p} className="w-8 h-8" />;
              default:
                return (
                  <span
                    key={p}
                    className="flex w-8 h-8 rounded-full bg-element-background border-1 border-element-border items-center justify-center"
                  >
                    <p className="font-bold text-xl text-low-contrast">
                      {p.substring(0, 1).toUpperCase()}
                    </p>
                  </span>
                );
            }
          })}
            {
                platforms &&  platforms.length > 4 && (
                <div className=" p-2 bg-element-background rounded-8px text-sm text-low-contrast font-semibold">
                    ...
                </div>
                )
            }
        </div>
      </div>

        <div className="p-4 border-1 border-y-0 border-element-border bg-gradient-to-b from-white"></div>
    </div>
  );
};
