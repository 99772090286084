import React from "react";
import { Separator } from "../Separator/Separator";
import {} from "./Sidebar.type";
import SuperhoteIcon from "../../../assets/icons/superhote.svg?react";

import SidebarCloseIcon from "../../../assets/icons/sidebar-close.svg?react";

export const SidebarSkeleton: React.FC<{}> = ({}) => {
  const isClosed = false;

  return (
    <>
      <div
        className={`${
          isClosed ? "w-[64px] px-2" : "w-[256px] p-6"
        } h-full overflow-y-scroll bg-subtle border-r-1 border-border-subtle`}
      >
        <div className="flex flex-col">
          <div className="flex justify-between pb-4">
            <SuperhoteIcon className="w-[55%]" />
            <div className="p-2 bg-element-background rounded-4px hover:cursor-pointer w-12 flex items-center justify-center">
              <SidebarCloseIcon className="w-5 h-5 shrink-0" />
            </div>
          </div>

          <div
            className={`flex flex-row space-x-2 items-centeranimate-pulse cursor-pointer`}
          >
            <div className="w-8 h-8 rounded-full bg-slate-200"></div>

            <div className="flex-1">
              <p className="w-full h-2 mt-1 text-sm font-semibold rounded text-high-contrast bg-slate-300"></p>
            </div>
          </div>
        </div>

        <div className="mt-1 animate-pulse">
          <Separator />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <Separator />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <Separator />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <Separator />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />

          <Separator />

          <div className="w-full h-2 rounded bg-slate-300 mb-5" />
        </div>
      </div>
    </>
  );
};
