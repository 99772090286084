import React from "react";
import LogInIcon from "../../../assets/icons/log-in.svg?react";
import LogOutIcon from "../../../assets/icons/log-out.svg?react";
import MoneyIcon from "../../../assets/icons/money.svg?react";
import EmojiHappyIcon from "../../../assets/icons/emoji-happy.svg?react";
import MoonIcon from "../../../assets/icons/moon-2.svg?react";
import UsersIcon from "../../../assets/icons/users.svg?react";
import {useTranslation} from "react-i18next";
import {capitalizeFirstLetter} from "../../../helpers/stringHelper";
import moment from "moment";
import LayoutBlockComponent from "../Common/LayoutBlockComponent";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import CardRentalComponent from "./CardRentalComponent";

const CardReservationComponent = ({}: {}) => {
  const {t} = useTranslation();
  const {guestPageData} = useGuestPageDataStore();
  const {
    rental,
    checkin,
    checkout,
    nights_count,
    adults_count,
    children_count,
    payment_schedule,
  } = guestPageData || {};
  const {
    cover: rentalCover,
    name: rentalName,
    address: rentalAddress,
    postal_code: rentalPostalCode,
    city: rentalCity,
    checkin_min_time: rentalCheckinMinTime,
    checkin_max_time: rentalCheckinMaxTime,
    checkout_min_time: rentalCheckoutMinTime,
    checkout_max_time: rentalCheckoutMaxTime,
  } = rental || {};
  const {total_price_value} = payment_schedule || {};
  return (
    <LayoutBlockComponent
      title={t("GuestPage.Reservation.title")}
      displayTitles
      blockStyle={{
        parentDivStyle: "mx-0 my-0 pb-0",
        titleStyle: "px-0 md:text-[32px]",
        childrenDivStyle: "border-b-0 px-0 pb-0",
      }}
    >
      <>
        <CardRentalComponent
          name={rentalName}
          cover={rentalCover}
          address={ `${rentalAddress} ${rentalPostalCode} ${rentalCity}`}
          postalCode={rentalPostalCode}
          city={rentalCity}
        />
        <div className="flex flex-col text-sm text-low-contrast mt-3 justify-between">
          <div className="flex flex-row justify-between ">
            <div className="flex flex-col">
              <div className="flex md:text-lg md:items-center">
                <LogInIcon className="mr-1" />{" "}
                {t("GuestPage.Reservation.checkin")}
              </div>
              <span className=" text-high-contrast font-bold md:text-lg">
                {capitalizeFirstLetter(
                  moment(checkin).format("dddd D MMMM YYYY")
                )}
              </span>
              <span className="font-light text-low-contrast md:text-lg">
                {t("GuestPage.Reservation.between")} {rentalCheckinMinTime}{" "}
                {t("GuestPage.Reservation.and")} {rentalCheckinMaxTime}
              </span>
            </div>
            <div className="flex flex-col justify-center">
              <div className="flex items-center text-high-contrast font-bold justify-end md:text-lg">
                {t("Global.nights", {
                  count: nights_count ?? 0,
                }).toLowerCase()}
                <MoonIcon className="flex-shrink-0 inline w-5 mr-1" />{" "}
              </div>
              <div className="flex items-center justify-end md:text-lg">
                {t("Global.adults", {count: adults_count ?? 0})}
                <UsersIcon className="flex-shrink-0 inline w-5 mr-1" />{" "}
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between md:mt-4">
            <div className="flex flex-col">
              <div className="flex md:text-lg md:items-center">
                <LogOutIcon className="mr-1" />{" "}
                {t("GuestPage.Reservation.checkout")}
              </div>
              <span className="text-high-contrast font-bold md:text-lg">
                {capitalizeFirstLetter(
                  moment(checkout).format("dddd D MMMM YYYY")
                )}
              </span>
              <span className="font-light text-low-contrast md:text-lg">
                {t("GuestPage.Reservation.between")} {rentalCheckoutMinTime}{" "}
                {t("GuestPage.Reservation.and")} {rentalCheckoutMaxTime}
              </span>
            </div>
            <div className="flex flex-col justify-center">
              <div className="flex items-center justify-end md:text-lg">
                {t("Global.children", {
                  count: children_count ?? 0,
                })}
                <EmojiHappyIcon className="flex-shrink-0 inline w-5 mr-1" />{" "}
              </div>
              <div className="flex items-center justify-end md:text-lg">
                {total_price_value ?? 0} €
                <MoneyIcon className="flex-shrink-0 inline w-5 mr-1" />{" "}
              </div>
            </div>
          </div>
        </div>
      </>
    </LayoutBlockComponent>
  );
};

export default CardReservationComponent;
