import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import resFacilities from "../../../../res/facilities";
import { Card } from "../../../Common/Card/Card";
import { FacilitiesGroupType } from "./Facilities.type";
import { RentalFacilitiesEditGroup } from "./FacilitiesEditGroup";
import { RentalFacilitiesViewGroup } from "./FacilitiesViewGroup";

import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import CheckWhiteIcon from "../../../../assets/icons/check-white.svg?react";
import CoffeeMachineIcon from "../../../../assets/icons/coffee-machine.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import paths from "../../../../constants/paths";
import { post } from "../../../../helpers/APIHelper";
import { useRentalPageContext } from "../../../../pages/RentalPage/RentalPageContext";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { RentalFacilitiesViewGroupSkeleton } from "./FacilitiesViewGroupSkeleton";
import useUserStore from "../../../../stores/useUserStore";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";

export const RentalFacilities: React.FC = () => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  const {
    loadingState: { loading },
    rental,
    onUpdateRental,
    currentRentalState: { currentRental, setCurrentRental },
  } = useRentalPageContext();

  const [mode, setMode] = useState<"view" | "edit">("view");
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [equipments, setEquipments] = useState<string[] | undefined>(
    rental?.details?.equipments
  );
  const facilitiesGroups: FacilitiesGroupType[] = resFacilities;

  const handleClickMode = () => {
    if (mode === "view") setMode("edit");
    else save();
  };

  const save = async () => {
    setSaving(true);
    setError(null);

    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${
        rental?.id
      }${paths.API.RENTAL.UPDATE_EQUIPMENTS}`,
      {
        equipments: rental?.details?.equipments?.toString(),
      }
    );

    if (response.data?.success) {
      if (rental) {
        setCurrentRental({
          ...rental,
          details: {
            ...rental?.details,
            equipments: response.data.result.equipments,
          },
        });
      }
      setSaving(false);
      setMode("view");
    } else {
      setSaving(false);
      setError(response?.response?.data?.message);
    }
  };

  const handleCancel = () => {
    setMode("view");
    if (currentRental && currentRental?.details?.equipments) {
      onUpdateRental({
        ...currentRental,
        details: {
          ...currentRental?.details,
          equipments: currentRental.details.equipments,
        },
      });
    }
  };

  useEffect(() => {
    if (rental?.details?.equipments) {
      setEquipments(rental?.details?.equipments);
    }
  }, [rental?.details?.equipments]);

  return (
    <Card
      label={t("Rental.Infos.Facilities.title")}
      Icon={CoffeeMachineIcon}
      loading={saving}
      button={{
        Icon: mode === "view" ? EditIcon : CheckWhiteIcon,
        label: mode === "view" ? t("Global.edit") : t("Global.record"),
        type: mode === "view" ? "secondary" : "primary",
        disabled: !getCurrentCanEdit({
          user: userStore.user,
          workspaceOwner: rental?.workspace_owner,
        }),
        onClick: handleClickMode,
        skeleton: loading,
      }}
      secondaryButton={
        mode === "edit"
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => handleCancel(),
            }
          : undefined
      }
      bgSubtleLight={true}
    >
      <ErrorMessage>{error}</ErrorMessage>

      {loading && mode === "view"
        ? facilitiesGroups.map((group, index) => (
            <RentalFacilitiesViewGroupSkeleton key={index} group={group} />
          ))
        : null}

      {!loading && mode === "view"
        ? facilitiesGroups.map((group, index) => (
            <RentalFacilitiesViewGroup
              key={index}
              group={group}
              equipments={rental?.details?.equipments}
            />
          ))
        : null}

      {mode === "edit"
        ? facilitiesGroups.map((group, index) => (
            <RentalFacilitiesEditGroup
              key={index}
              group={group}
              equipments={equipments}
              loading={saving}
              onChangeFacility={(equipments: string[]) => {
                console.log("on change facility : ", rental);
                if (rental) {
                  onUpdateRental({
                    ...rental,
                    details: {
                      ...rental.details,
                      equipments: equipments ?? [],
                    },
                  });
                }
              }}
            />
          ))
        : null}
    </Card>
  );
};
