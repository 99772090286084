import { t } from "i18next";
import React from "react";
import { UseModal } from "../../hooks/useModal";
import { ValueType } from "../../types/commonTypes";
import { Button } from "../Common/Button/Button";
import { SelectModal } from "../Common/SelectModal/SelectModal";

type ManagementAutomationListMultipleSelectionModalProps = {
  modal: UseModal<ValueType[]>;
  onClose: () => void;
  onTriggerAssign: () => void;
  onTriggerDeactivate: () => void;
  onTriggerRemove: () => void;
};

export const ManagementAutomationListMultipleSelectionModal: React.FC<
  ManagementAutomationListMultipleSelectionModalProps
> = ({
  modal,
  onClose,
  onTriggerDeactivate,
  onTriggerAssign,
  onTriggerRemove,
}) => {
  return (
    <SelectModal
      isVisible={modal.isVisible}
      title={t("Automation.SelectModal.title", {
        count: modal.data?.length,
      })}
      onClose={onClose}
    >
      <div className="flex space-x-4">
        <Button type="secondary" onClick={onTriggerDeactivate}>
          {t("Global.deactivate")}
        </Button>
        <Button type="secondary" onClick={onTriggerAssign}>
          {t("Global.assign")}
        </Button>
        <Button type="destructive" onClick={onTriggerRemove}>
          {t("Global.remove")}
        </Button>
      </div>
    </SelectModal>
  );
};
