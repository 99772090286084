import React from "react";
import { SeparatorProps } from "./Separator.type";
import {cn} from "../../../helpers/classHelper";

export const Separator: React.FC<SeparatorProps> = ({
  accent = "normal",
  children,
  classNames
}) => {
  const getBorderColor = () => {
    if (accent === "normal") {
      return "border-separator";
    } else {
      return "border-element-border";
    }
  };

  return (
    <div className={cn("flex flex-row items-center my-3",classNames?.divParent)}>
      <div className={`flex-1 border-b-1 ${getBorderColor()}`}></div>
      {children && (
        <div className="mx-2 font-extralight text-secondary">{children}</div>
      )}
      <div className={`flex-1 border-b-1 ${getBorderColor()}`}></div>
    </div>
  );
};
