import React, { useState } from "react";
import { BookingConnectRentalLineProps } from "./BookingConnectModal.type";
import { useTranslation } from "react-i18next";

import { get, post } from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { Button } from "../../../Common/Button/Button";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";

import CloseIcon from "../../../../assets/icons/close.svg?react";
import ArrowRightIcon from "../../../../assets/icons/arrow-right.svg?react";
import BookingIcon from "../../../../assets/icons/booking.svg?react";
import InfoCircleIcon from "../../../../assets/icons/information.svg?react";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";

export const BookingConnectRentalLine: React.FC<
  BookingConnectRentalLineProps
> = ({ rental, onReload = () => {}, setIsAssociated }) => {
  const { t } = useTranslation();

  const [hotelID, setHotelID] = useState<string>("");
  const [loadingHotelID, setLoadingHotelID] = useState<boolean>(false);
  const [errorHotelID, setErrorHotelID] = useState<string | null>(null);

  const [rooms, setRooms] = useState<{ id: string; name: string }[] | null>(
    null
  );
  const [rates, setRates] = useState<{ id: string; name: string }[] | null>(
    null
  );
  const [currentRoom, setCurrentRoom] = useState<string | null>(null);
  const [currentRate, setCurrentRate] = useState<string | null>(null);

  const [loadingAssociate, setLoadingAssociate] = useState<boolean>(false);
  const [errorAssociate, setErrorAssociate] = useState<string | null>(null);

  const [successAssociate, setSuccessAssociate] = useState<boolean>(false);

  const handleSearchHotelID = async () => {
    if (hotelID === "") {
      setErrorHotelID(t("Progression.errorNoHotelID"));
      return;
    }

    setLoadingHotelID(true);

    const response = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.BOOKING.GET_PROPERTY_ROOMS
      }/${hotelID}`
    );

    if (response.data?.success) {
      setErrorHotelID(null);

      setRooms(response?.data?.result?.rooms);
      setRates(response?.data?.result?.rates);

      setCurrentRoom(
        response?.data?.result?.rooms?.length > 0
          ? response?.data?.result?.rooms[0]?.id
          : null
      );

      const standardRateId = response?.data?.result?.rates?.find(
        (x: any) => x.name.toLowerCase() === "standard rate"
      )?.id;

      setCurrentRate(standardRateId ?? response?.data?.result?.rates[0]?.id);
    } else {
      setErrorHotelID(response.response?.data?.message);
    }

    setLoadingHotelID(false);
  };

  const handleAssociate = async () => {
    setErrorAssociate(null);

    if (!currentRoom || !currentRate) {
      setErrorAssociate(t("Progression.errorRoomAndRateRequired"));
      return;
    }

    setLoadingAssociate(true);

    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.BOOKING.CONNECT_RENTAL}`,
      {
        rental_id: rental?.id,
        hotel_id: hotelID,
        room_id: currentRoom,
        rate_id: currentRate,
        room_name: rooms?.find((room) => room.id === currentRoom)?.name ?? "",
        rate_name: rates?.find((rate) => rate.id === currentRate)?.name ?? "",
      }
    );

    if (response.data?.success) {
      console.log("response shfbhsdbh", response);
      setSuccessAssociate(true);
      if(setIsAssociated)
      {
        console.log("setIsAssociated", setIsAssociated);
        setIsAssociated(true);
      }
    } else {
      setErrorAssociate(response.response?.data?.message);
      if(setIsAssociated)
      {
        setIsAssociated(false);
      }
    }

    setLoadingAssociate(false);
  };

  const handleRemoveAll = async () => {
    console.log("handleRemoveAll");
    setSuccessAssociate(false);

    setErrorAssociate(null);
    setErrorHotelID(null);

    if(setIsAssociated)
    {
      setIsAssociated(false);
    }

    setLoadingAssociate(false);
    setLoadingHotelID(false);

    setHotelID("");
    setRooms(null);
    setRates(null);

    await post(
      `${import.meta.env.VITE_API_URL}${paths.API.BOOKING.DECONNECT_RENTAL}`,
      {
        rental_id: rental?.id,
      }
    );

    onReload();
  };

  return (
    <>
      <tr>
        <td className="p-4 border-t-1 border-element-border">
          <div className="flex gap-x-3">
            <div className="object-fill w-12 h-12 shrink-0 rounded-4px border-1 border-element-border/50 overflow-hidden">
              <img
                src={rental?.cover}
                className="object-fill w-12 h-12 shrink-0"
              />
            </div>

            <div className="flex flex-col gap-y-3 w-full">
              <div>
                <p className="font-semibold text-high-contrast underline underline-offset-4">
                  {rental?.name}
                </p>
                <p className="font-light text-low-contrast mt-1">
                  {rental?.address}
                </p>
                <p className="font-light text-low-contrast mt-1">
                  {`${rental?.postal_code} ${rental?.city}`}
                </p>
              </div>

              {!rental?.external_connexions?.platform_booking?.hotel_id && (
                <>
                  {!successAssociate && (
                    <div className="flex flex-col gap-3">
                      {/* Ligne 1 */}
                      <div className="flex gap-3 items-end">
                        <TextInput
                          label={t("Progression.Booking.hotelIdBookingLabel")}
                          placeholder="1637498"
                          disabled={rooms !== null || loadingHotelID}
                          value={hotelID}
                          size="large"
                          onChangeText={(value) => {
                            setHotelID(value);
                            setErrorHotelID(null);
                          }}
                        />

                        {rooms === null && (
                          <div className="pr-2">
                            <div className="w-24">
                              <Button
                                onClick={handleSearchHotelID}
                                type="secondary"
                                RightIcon={ArrowRightIcon}
                                loading={loadingHotelID}
                              >
                                {t("Global.next")}
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>

                      {/* Ligne 2 & 3 */}
                      {rooms !== null && (
                        <>
                          <InputSelect
                            label={t("Progression.Booking.roomIdBookingLabel")}
                            size="normal"
                            items={rooms.map((r) => {
                              return {
                                value: r.id,
                                label: `${r.id} - ${r.name}`,
                              };
                            })}
                            onSelect={(value) =>
                              setCurrentRoom(value.toString())
                            }
                            selectedValue={currentRoom ?? 0}
                            disabled={loadingAssociate}
                          />

                          <InputSelect
                            size="normal"
                            label={t("Progression.Booking.rateIdBookingLabel")}
                            items={
                              rates
                                ? rates.map((r) => {
                                    return { value: r.id, label: r.name };
                                  })
                                : []
                            }
                            onSelect={(value) =>
                              setCurrentRate(value.toString())
                            }
                            selectedValue={currentRate ?? 0}
                            disabled={loadingAssociate}
                          />
                        </>
                      )}

                      {/* Ligne 4 */}
                      {rooms !== null && (
                        <div className="flex flex-row gap-2 items-center">
                          <Button
                            onClick={handleAssociate}
                            size="normal"
                            type="secondary"
                            loading={loadingAssociate}
                          >
                            {t("Progression.associate")}
                          </Button>

                          <div>
                            <Button
                              onClick={() => {
                                setRooms(null);
                                setRates(null);
                                setHotelID("");
                                setErrorAssociate(null);
                                setErrorHotelID(null);
                              }}
                              size="normal"
                              type="secondary"
                              LeftIcon={CloseIcon}
                            ></Button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {successAssociate && (
                    <BookingAssociate
                      roomID={currentRoom ?? ""}
                      roomName={
                        rooms?.find((room) => room.id === currentRoom)?.name ??
                        ""
                      }
                      rateName={
                        rates?.find((rate) => rate.id === currentRate)?.name ??
                        ""
                      }
                      onRemoveAll={handleRemoveAll}
                    />
                  )}
                </>
              )}

              {rental?.external_connexions?.platform_booking?.hotel_id && (
                <BookingAssociate
                  roomID={
                    rental?.external_connexions?.platform_booking.room_id ?? ""
                  }
                  roomName={
                    rental?.external_connexions?.platform_booking?.room_name ??
                    ""
                  }
                  rateName={
                    rental?.external_connexions?.platform_booking?.rate_name ??
                    ""
                  }
                  onRemoveAll={handleRemoveAll}
                />
              )}

              {errorAssociate && (
                <div className="w-full">
                  <ErrorMessage>{errorAssociate}</ErrorMessage>
                </div>
              )}
              {errorHotelID && (
                <div className="w-full">
                  <ErrorMessage>{errorHotelID}</ErrorMessage>
                </div>
              )}
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

type BookingAssociateProps = {
  roomID: string;
  roomName: string;
  rateName: string;
  onRemoveAll: () => {};
};

const BookingAssociate: React.FC<BookingAssociateProps> = ({
  roomID,
  roomName,
  rateName,
  onRemoveAll,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex w-full gap-3">
        <div>
          <p className="text-low-contrast">Room ID</p>
          <p className="font-bold text-high-contrast">{roomID}</p>
        </div>

        <div>
          <p className="text-low-contrast">Rate</p>
          <p className="font-bold text-high-contrast">{rateName}</p>
        </div>
      </div>

      <div>
        <p className="text-low-contrast">Hébergement Booking</p>
        <p className="font-bold text-high-contrast">{roomName}</p>
      </div>

      <div className="flex-1">
        <div className="flex flex-1 border-1 border-element-border rounded-6px h-8 overflow-hidden">
          <div className="flex-1 flex items-center px-2 gap-2">
            <BookingIcon className="w-5 h-5" />

            <p className="font-semibold text-high-contrast text-[11px]">
              {t("Progression.associateToBookingRoom")} {roomID}
            </p>
          </div>

          <div className="flex items-center gap-4">
            <InfoCircleIcon className="w-4 h-4 cursor-pointer" />
            <div
              className="flex items-center justify-center w-8 h-full bg-element-background border-l-1 border-element-border cursor-pointer hover:bg-element-border-hover active:bg-element-background-active"
              onClick={onRemoveAll}
            >
              <CloseIcon className="w-4 h-4" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
