import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import {InProgressPlaceholder} from "../../components/Common/Dashboard/InProgressPlaceholder";


export const SettingsNotificationsPage: React.FC<{}> = () => {
  const { t } = useTranslation();



  const getTitle = () => {
    return (
      <p className="flex flex-row items-center gap-1">
        {t("Settings.title")} <ChevronRightIcon />
        {t("Settings.notifications")}
      </p>
    );
  };


  return (
    <>
      <MainLayout
        title={getTitle()}
        sidebarActiveItem="settings"
        sidebarActiveSubItem="notifications"
      >
        <InProgressPlaceholder />
      </MainLayout>
    </>
  );
};
