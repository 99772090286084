import React, { useRef, useState } from "react";
import { RightModal } from "../../Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import { CarouselWizard } from "../../Common/CarouselWizard/CarouselWizard";
import { Button } from "../../Common/Button/Button";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import SendWhiteIcon from "../../../assets/icons/send-white.svg?react";
import { AddMemberStepEmail } from "./AddMemberStepEmail";
import {
  AddMemberEmailForm,
  AddMemberRentalsForm,
  AddMemberRolesForm,
  AddMemberType,
} from "./AddMemberModal.type";
import { AddMemberStepRentals } from "./AddMemberStepRentals";
import { AddMemberStepRoles } from "./AddMemberStepRoles";
import {
  OperationalManagementRoleListItemResponse,
  OperationalManagementTeamMemberResponse,
} from "../../../types/GETTypes";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { post } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import { ValueType } from "../../../types/commonTypes";

enum ManagementAddMemberStepsEnum {
  EMAIL,
  RENTALS,
  ROLES,
}

export const AddMemberModal: React.FC<{
  data: { teamId: ValueType } | undefined | null;
  isVisible: boolean;
  roles: OperationalManagementRoleListItemResponse[];
  onAddSuccess: (member: OperationalManagementTeamMemberResponse) => void;
  onClose: () => void;
}> = ({ isVisible, data, roles, onAddSuccess, onClose }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [currentStep, setCurrentStep] = useState<ManagementAddMemberStepsEnum>(
    ManagementAddMemberStepsEnum.EMAIL
  );

  const [stepsData, setStepsData] = useState<AddMemberType>({
    email: undefined,
    rentals: undefined,
    rolesId: undefined,
  });

  const handleClose = () => {
    if (loading) return;

    onClose();
  };

  const refButtonEmail = useRef<HTMLInputElement>();
  const refButtonRentals = useRef<HTMLInputElement>();
  const refButtonRoles = useRef<HTMLInputElement>();

  const handleClickInsideStep = () => {
    if (currentStep === ManagementAddMemberStepsEnum.EMAIL)
      refButtonEmail?.current?.click();
    else if (currentStep === ManagementAddMemberStepsEnum.RENTALS) {
      refButtonRentals?.current?.click();
    } else if (currentStep === ManagementAddMemberStepsEnum.ROLES) {
      refButtonRoles?.current?.click();
    }
  };

  const handleNextStepEmail = (values: AddMemberEmailForm) => {
    setStepsData({ ...stepsData, email: values });
    setCurrentStep(ManagementAddMemberStepsEnum.RENTALS);
  };

  const handleNextStepRentals = (values: AddMemberRentalsForm) => {
    setStepsData({ ...stepsData, rentals: values });
    setCurrentStep(ManagementAddMemberStepsEnum.ROLES);
  };

  const handleNextStepRoles = async (values: AddMemberRolesForm) => {
    const newStepData = { ...stepsData, rolesId: values };

    setStepsData(newStepData);

    setLoading(true);
    setError(null);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.SEND_INVIT
      }`,
      {
        email: newStepData.email?.email,
        rentals: newStepData.rentals?.rentalsId,
        team_id: data?.teamId,
        postes: newStepData.rolesId.rolesId,
      }
    );

    if (res.data?.success) {
      onClose();
      onAddSuccess(res.data?.result);
      setStepsData({
        email: undefined,
        rentals: undefined,
        rolesId: undefined,
      });
      setCurrentStep(ManagementAddMemberStepsEnum.EMAIL);
    } else {
      setError(res?.data?.message);
    }

    setLoading(false);
  };

  return (
    <RightModal
      isVisible={isVisible}
      onClose={onClose}
      title={t("Management.AddMember.title")}
    >
      <div className="flex-1 flex flex-col">
        {/* Carousel */}
        <div className="w-full flex-1">
          <CarouselWizard
            loading={loading}
            currentStepIndex={currentStep}
            steps={[
              {
                title: t("Management.AddMember.Email.title"),
                Node: (
                  <AddMemberStepEmail
                    refButton={refButtonEmail}
                    initialData={stepsData.email}
                    onNextStep={handleNextStepEmail}
                  />
                ),
              },
              {
                title: t("Management.AddMember.Rentals.title"),
                Node: (
                  <AddMemberStepRentals
                    initialData={stepsData.rentals}
                    refButton={refButtonRentals}
                    onNextStep={handleNextStepRentals}
                  />
                ),
              },
              {
                title: t("Management.AddMember.Roles.title"),
                Node: (
                  <AddMemberStepRoles
                    initialData={stepsData.rolesId}
                    refButton={refButtonRoles}
                    roles={roles}
                    onNextStep={handleNextStepRoles}
                  />
                ),
              },
            ]}
            onChangeStep={setCurrentStep}
          />
        </div>

        {/* Buttons */}
        <div className="flex flex-col gap-3 pt-4 pb-2 mt-4 border-t-1 border-element-border">
          <ErrorMessage>{error}</ErrorMessage>

          <div className="flex flex-row gap-4">
            <Button type="secondary" disabled={loading} onClick={handleClose}>
              {t("Global.cancel")}
            </Button>

            {currentStep < ManagementAddMemberStepsEnum.ROLES && (
              <Button
                RightIcon={ArrowRightWhiteIcon}
                loading={loading}
                onClick={handleClickInsideStep}
              >
                {t("Global.nextStep")}
              </Button>
            )}

            {currentStep === ManagementAddMemberStepsEnum.ROLES && (
              <Button
                LeftIcon={SendWhiteIcon}
                onClick={handleClickInsideStep}
                loading={loading}
              >
                {t("Management.AddMember.sendInvit")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </RightModal>
  );
};
