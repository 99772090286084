import { ValueType } from "../../types/commonTypes";
import { GoogleAddressType } from "../GoogleAddressInput/GoogleAddressInput";
import { AddRentalRulesType } from "./Rules/AddRentalRules";

export enum ADD_RENTAL_STEPS {
  INFORMATIONS = 1,
  CONFIRM_ADDRESS = 1.5,
  ROOMS = 2,
  FACILITIES = 3,
  PHOTOS = 4,
  RULES = 5,
  TARIFICATION = 6,
  DONE = 7,
  SEASON = 8,
}

export type AddRentalInfosType = {
  name: string;
  rentalCategory: ValueType;
  rentalType: ValueType | null;
  rentingType: ValueType;
  managementType: ValueType;
  address: string|GoogleAddressType;
  city: string;
  postal_code: string;
  track_number: string;
  country: number;
  maxGuests: number;
  bathrooms: number;
  maxAdults: number;
  maxChildren: number;
  longitude: number;
  latitude: number;
};

export type AddRentalRoomType = {
  id: number | undefined;
  name: string;
  room_type_id: number;
  private_room: boolean;
  private_bathroom: number;
  adults_count: number;
  children_count: number;
  infants_beds_count: number;
  children_beds_count: number;
  double_beds_count: number;
  wall_beds_count: number;
  queen_beds_count: number;
  king_beds_count: number;
  mezannine_beds_count: number;
  sofa_beds_count: number;
  single_beds_count: number;
};

export type AddRentalPhotosType = {
  description: string;
  photos: Blob[];
};

export type AddRentalTarificationType = {
  priceByNight: number;
  currency: ValueType;
  vat: number;
  cleaning: number;
  linen: number;
  extraGuestPrice: number;
  extraGuestCapacity: number;
  cityTax: {
    type: "fix" | "percent";
    fixValue: number;
    percentValue: number;
    percentMax: number;
    percentExtra: number;
  };
};

export type AddRentalType = {
  rental_id?: ValueType;
  isActive?: boolean;
  infos?: AddRentalInfosType;
  rooms?: AddRentalRoomType[];
  photos?: AddRentalPhotosType;
  facilities?: string[];
  rules?: AddRentalRulesType;
  tarification?: AddRentalTarificationType;
};
