import React from "react";
import { MultiSelectItemProps } from "./MultiSelectRentals.type";

import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";

export const MultiSelectRentalItem: React.FC<MultiSelectItemProps> = ({
  rental,
  label,
  selectMode = "none",
  isBold = false,
  onClick = () => {},
}) => {
  return (
    <div
      className={`py-3 px-4 flex flex-row gap-2 items-center hover:bg-element-background-selection-subtle cursor-pointer border-b-1 border-element-border-light last:border-0`}
      onClick={onClick}
    >
      <div
        className={`min-w-[16px] min-h-[16px] flex items-center justify-center border-element-border rounded-sm  ${
          selectMode === "none"
            ? "bg-white border-1"
            : "bg-icon-active border-none"
        } overflow-hidden`}
      >
        {selectMode === "full" && (
          <CheckWhiteIcon className="w-[12px] h-[12px]" />
        )}

        {selectMode === "semi" && <div className="w-[8px] h-[2px] bg-white" />}
      </div>

      <div className="flex flex-col">
        {label && (
          <p
            className={`text-sm ${
              isBold ? "font-semibold text-high-contrast" : "text-low-contrast"
            }`}
          >
            {label}
          </p>
        )}

        {rental && (
          <div className="flex flex-row gap-2 items-center">
            {/* Cover */}
            <div className="object-fill w-12 h-12 shrink-0 rounded-4px border-1 border-element-border/50 overflow-hidden">
              <img
                src={rental?.cover}
                className="object-fill w-12 h-12 shrink-0"
              />
            </div>

            {/* Name and address */}
            <div className="text-left">
              <p className="text-high-contrast font-semibold">{rental?.name}</p>
              <p className="font-light text-low-contrast">{rental?.address}</p>
              <p className="font-light text-low-contrast">
                {rental?.postal_code} {rental?.city} {rental?.country?.name}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
