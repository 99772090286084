import { PaymentAccountTypeEnum } from "../../enums/GETenums";
import { ValueType } from "../../types/commonTypes";

export type PaymentAccount = {
  id: number;
  type: PaymentAccountTypeEnum;
  name: string;
  stripe_account_id: string;
  stripe_account_name: string;
  stripe_account_email: string;
};

// * -- PAYMENT POLICY --

// REFUND
export enum PaymentPolicyRefundConditionEnum {
  AT_RESERVATION = "AT_RESERVATION",
  TWENTY_FOUR_HOURS_BEFORE_ARRIVAL = "24_BEFORE_ARRIVAL",
  FIVE_DAYS_BEFORE_ARRIVAL = "5D_BEFORE_ARRIVAL",
  SEVEN_DAYS_BEFORE_ARRIVAL = "7D_BEFORE_ARRIVAL",
  FOURTEEN_DAYS_BEFORE_ARRIVAL = "14D_BEFORE_ARRIVAL",
  THIRTY_DAYS_BEFORE_ARRIVAL = "30D_BEFORE_ARRIVAL",
  SIXTY_DAYS_BEFORE_ARRIVAL = "60D_BEFORE_ARRIVAL",
}

export type PaymentPolicyRefundCondition =
  | "AT_RESERVATION"
  | "24_BEFORE_ARRIVAL"
  | "5D_BEFORE_ARRIVAL"
  | "7D_BEFORE_ARRIVAL"
  | "14D_BEFORE_ARRIVAL"
  | "30D_BEFORE_ARRIVAL"
  | "60D_BEFORE_ARRIVAL";

export const PaymentPolicyRefundConditionItems = [
  "AT_RESERVATION",
  "24_BEFORE_ARRIVAL",
  "5D_BEFORE_ARRIVAL",
  "7D_BEFORE_ARRIVAL",
  "14D_BEFORE_ARRIVAL",
  "30D_BEFORE_ARRIVAL",
  "60D_BEFORE_ARRIVAL",
];

// DEPOSIT
export enum PaymentPolicyDepositValueTypeEnum {
  FIXED = "FIXED",
  PERCENT = "PERCENT",
}

export enum PaymentPolicyDepositPaymentOptionEnum {
  PRE_AUTHORISATION = "PRE_AUTHORISATION",
  CARD_PAYMENT_TO_REFUND = "CARD_PAYMENT_TO_REFUND",
  BANK_TRANSFER_TO_REFUND = "BANK_TRANSFER_TO_REFUND",
  BANK_CHECK_OR_CASH_AT_ARRIVAL = "BANK_CHECK_OR_CASH_AT_ARRIVAL",
}

export type PaymentPolicyDepositPaymentOption =
  | "PRE_AUTHORISATION"
  | "CARD_PAYMENT_TO_REFUND"
  | "BANK_TRANSFER_TO_REFUND"
  | "BANK_CHECK_OR_CASH_AT_ARRIVAL";

export const PaymentPolicyDepositPaymentOptionItems = [
  "PRE_AUTHORISATION",
  "CARD_PAYMENT_TO_REFUND",
  "BANK_TRANSFER_TO_REFUND",
  "BANK_CHECK_OR_CASH_AT_ARRIVAL",
];

export type PaymentPolicyDepositPaymentTrigger =
  | "DAYS_BEFORE_ARRIVAL"
  | "HOURS_BEFORE_ARRIVAL";
export const PaymentPolicyDepositPaymentTriggerItems = [
  "DAYS_BEFORE_ARRIVAL",
  "HOURS_BEFORE_ARRIVAL",
];

// PAYMENT
export type PaymentPolicyValueType = "FIXED" | "PERCENT";

export enum PaymentPolicyValueTypeEnum {
  FIXED = "FIXED",
  PERCENT = "PERCENT",
}

export type PaymentPolicyPaymentTriggerItem =
  | "AT_RESERVATION"
  | "AT_CHECKIN"
  | "SPECIFIC_DATE"
  | "24H_BEFORE_ARRIVAL"
  | "5D_BEFORE_ARRIVAL"
  | "7D_BEFORE_ARRIVAL"
  | "14D_BEFORE_ARRIVAL"
  | "30D_BEFORE_ARRIVAL"
  | "60D_BEFORE_ARRIVAL";

export enum PaymentPolicyPaymentTriggerEnum {
  AT_RESERVATION = "AT_RESERVATION",
  AT_CHECKIN = "AT_CHECKIN",
  SPECIFIC_DATE = "SPECIFIC_DATE",
  TWENTY_FOUR_HOURS_BEFORE_ARRIVAL = "24H_BEFORE_ARRIVAL",
  FIVE_DAYS_BEFORE_ARRIVAL = "5D_BEFORE_ARRIVAL",
  SEVEN_DAYS_BEFORE_ARRIVAL = "7D_BEFORE_ARRIVAL",
  FOURTEEN_DAYS_BEFORE_ARRIVAL = "14D_BEFORE_ARRIVAL",
  THIRTY_DAYS_BEFORE_ARRIVAL = "30D_BEFORE_ARRIVAL",
  SIXTY_DAYS_BEFORE_ARRIVAL = "60D_BEFORE_ARRIVAL",
}

export type PaymentPolicyResponse = {
  id: number;
  name: string;
  description: string;
  is_default_policy: number;
  is_favorite_policy: number;
  is_single_payment: number;
  is_refundable: number;
  refund_value: number | null;
  refund_condition: string | null;
  is_deposit_required: number;
  deposit_value_type: string | null;
  deposit_value: number | null;
  deposit_payment_option: string | null;
  deposit_payment_account_id: number | null;
  deposit_payment_days_delay: number | null;
  payments_policy_items: PaymentPolicyItem[];
};

export type PaymentPolicyPayload = {
  name: string;
  description: string;
  is_favorite_policy: number;
  is_single_payment: number;
  is_refundable: number;
  refund_value?: number | null;
  refund_condition?: string | null;
  is_deposit_required: number;
  deposit_value_type?: string | null;
  deposit_value?: number | null;
  deposit_payment_option?: string | null;
  deposit_payment_account_id?: number | null;
  deposit_payment_days_delay?: number | null;
  payment_value_type_items: string;
  payment_value_items: string;
  payment_trigger_items: string;
  payment_specific_date: string;
  payment_account_id_items: string;
};

export type PaymentPolicyItem = {
  id: number;
  trigger: string;
  value_type: string;
  value: number;
  specific_date: string | null;
  payment_account_type: PaymentAccountTypeEnum | null;
  payment_account: PaymentAccountResponse;
};

export type PaymentAccountResponse = {
  id: number;
  name?: string;
  stripe_account_email?: string;
  stripe_account_id?: string;
  stripe_account_name?: string;
  type?: PaymentAccountTypeEnum;
} | null;

export type PaymentPolicyForm = {
  name: string;
  description: string;
  is_favorite_policy: boolean;
  is_single_payment: boolean;
  payments_policy_items: PaymentPolicyItemForm[];
  is_refundable: boolean;
  refund_value: number | null;
  refund_condition: PaymentPolicyRefundCondition | null;
  is_deposit_required: boolean;
  deposit_value: number | null;
  deposit_payment_option: PaymentPolicyDepositPaymentOption | null;
  deposit_payment_account_id: number | null;
  deposit_payment_days_delay: number | null;
};
export type PaymentPolicyItemForm = {
  trigger: PaymentPolicyPaymentTriggerItem;
  valueType: PaymentPolicyValueTypeEnum;
  value: number;
  specificDate: Date | null;
  paymentAccountId: ValueType | null;
};
