import React from "react";

export const StepProgress: React.FC<{
  currentStep: number;
  maxStep: number;
}> = ({ currentStep, maxStep }) => {
  const getStepsArianeConfig = () => {
    const result: { isActive: boolean }[] = [];
    for (let i = 0; i <= maxStep - 1; i++) {
      result.push({ isActive: i < currentStep });
    }
    return result;
  };

  const activeBg = currentStep <= maxStep ? "bg-low-contrast" : "bg-success";

  return (
    <div className="flex flex-row justify-center mt-6 space-x-2">
      {getStepsArianeConfig().map((step, index) => {
        if (step.isActive) {
          return (
            <div
              key={index}
              className={`flex-1 h-2 rounded-full ${activeBg}`}
            ></div>
          );
        } else {
          return (
            <div
              key={index}
              className="flex-1 h-2 rounded-full bg-element-background"
            ></div>
          );
        }
      })}
    </div>
  );
};
