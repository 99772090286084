import React from "react";
import { GroupButtonItemTypeType, GroupButtonProps } from "./GroupButton.type";
import { GroupButtonItem } from "./GroupButtonItem";

export const GroupButton: React.FC<GroupButtonProps> = ({
  items,
  width = "fit",
  onClick = () => {},
  onChangePeriod = () => {},
}) => {
  return (
    <div className="flex flex-row cursor-pointer">
      {items?.map((item, index) => {
        let type: GroupButtonItemTypeType = "middle";
        if (index === 0) type = "first";
        else if (index === items?.length - 1) type = "last";

        return (
          <GroupButtonItem
            key={index}
            label={item.label}
            badge={item.badge}
            type={type}
            isActive={item.isActive}
            Icon={item.Icon}
            width={width}
            isPeriod={item.isPeriod}
            from={item.from}
            to={item.to}
            disabled={item.disabled}
            onClick={() => onClick(item.value)}
            onChangePeriod={({ type, value }) =>
              onChangePeriod({ type, value })
            }
          />
        );
      })}
    </div>
  );
};
