/**
 * Component to display an image in full screen
 * with the ability to close the full-screen mode.
 * To be used for image display: when clicking on an image,
 * this component is triggered to show it in full screen.
 */
import React, { useEffect, useState } from "react";
import CloseIcon from "../../../assets/icons/close-white.svg?react";
import { cn } from "../../../helpers/classHelper";
import { PreviewImageProps } from "./PreviewImage.type";

export const PreviewImage: React.FC<PreviewImageProps> = ({
  imageUrl,
  imageModal,
  altText = "",
  onClose = () => {},
}) => {
  /**
   * Animation for display, very cool react :)
   */
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Prevents unwanted scrolling while the modal is open
    const body: HTMLElement | null = document.querySelector("body");
    if (body != null) {
      body.style.overflow = imageModal.isVisible ? "hidden" : "visible";
    }

    if (imageModal.isVisible) {
      setIsVisible(true);
    } else {
      setTimeout(() => setIsVisible(false), 300);
    }
  }, [imageModal.isVisible]);

  /**
   * Function for handle closing preview.
   */
  const handleOnCloseImage = () => {
    if (typeof onClose === "function") {
      onClose();
    }
    imageModal.close();
  };

  return (
    <div
      className={cn(
        "!ml-0 !mr-0 fixed inset-0 z-[100] flex items-center justify-center bg-high-contrast bg-opacity-80 transition-opacity duration-300",
        {
          "opacity-100": imageModal.isVisible,
          "opacity-0 pointer-events-none": !imageModal.isVisible,
          visible: isVisible,
          invisible: !isVisible,
        }
      )}
      onClick={handleOnCloseImage}
    >
      <div className="relative w-full h-full">
        <CloseIcon
          className="absolute cursor-pointer right-5 top-5 size-8"
          onClick={onClose}
        />
        <img
          src={imageUrl}
          alt={altText}
          className="absolute object-cover object-center w-4/6 max-h-full scale-100 -translate-x-1/2 -translate-y-1/2 rounded-lg cursor-pointer h-5/6 top-1/2 left-1/2"
        />
      </div>
    </div>
  );
};
