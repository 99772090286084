import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ConversationListItemProps } from "./ConversationList.type";
import moment from "moment";
import { getReservationStatusBadge } from "../../../helpers/reservationHelper";
import { getPlatformIcon } from "../../../helpers/platformHelper";

import ArrowRightIcon from "../../../assets/icons/arrow-right.svg?react";
import momentTimezone from "moment-timezone";

export const ConversationListItem: React.FC<ConversationListItemProps> = ({
  avatarSrc,
  status,
  lastMessageDate = new Date(),
  fullName,
  checkin,
  checkout,
  unread,
  platform,
  isSelected = false,
  rental_name,
  messagePreview,
  onClick,
}) => {
  const { t } = useTranslation();

  const isToday: (date: Date) => boolean = (date) => {
    const now = new Date();

    return (
      now.getDate() === date.getDate() &&
      now.getMonth() === date.getMonth() &&
      now.getFullYear() === date.getFullYear()
    );
  };

  const areSameMonth: (date1: Date, date2: Date) => boolean = (
    date1,
    date2
  ) => {
    return (
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const displayLastMessageDate: () => string = () => {
    if (isToday(lastMessageDate)) {
      return moment(lastMessageDate).locale("fr").format("HH:mm");
    } else {
      const currentTimeZone = momentTimezone.tz.guess();
      const messageDate = moment.utc(lastMessageDate).tz(currentTimeZone);
      return messageDate.format('DD MMMM HH:mm');
    }
  };

  const displayCheckinCheckout = () => {
    if (areSameMonth(checkin, checkout)) {
      return (
        <span>
          {moment(checkin).locale("fr").format("DD")}{" "}
          <ArrowRightIcon className="inline" width={12} height={12} />{" "}
          {moment(checkout).locale("fr").format("DD MMM")}
        </span>
      );
    } else {
      return (
        <span>
          {moment(checkin).locale("fr").format("DD MMM")}{" "}
          <ArrowRightIcon className="inline" width={12} height={12} />{" "}
          {moment(checkout).locale("fr").format("DD MMM")}
        </span>
      );
    }
  };

  const reservationStatus = getReservationStatusBadge(status);

  return (
    <div
      className={`flex flex-row cursor-pointer hover:bg-element-background/40 ${
        isSelected && "bg-element-border-active/10"
      }`}
      onClick={onClick}
    >
      {/* Avatar & Platform */}
      <div className="p-4 relative">
        {avatarSrc ? (
          <img
            src={avatarSrc}
            className="w-12 h-12 rounded-full object-cover border-1 border-element-border"
          />
        ) : (
          <div className="w-12 h-12 rounded-full bg-slate-200 border-1 border-element-border" />
        )}

        <div className="absolute left-11 top-11 w-5 h-5">
          {getPlatformIcon(platform)}
        </div>
      </div>
      {/* Other infos */}
      <div className="flex-1 flex-col p-4 pl-0 pt-2">
        <div className="flex flex-row text-sm">
          {reservationStatus.status === "success" && (
            <p className="flex-1 text-success">{reservationStatus.label}</p>
          )}

          {reservationStatus.status === "pending" && (
            <p className="flex-1 text-pending">{reservationStatus.label}</p>
          )}

          {reservationStatus.status === "fail" && (
            <p className="flex-1 text-error">{reservationStatus.label}</p>
          )}

          <p
            className={`${
              unread ? "font-semibold text-high-contrast" : "text-low-contrast"
            } flex flex-row items-center space-x-1`}
          >
            <div>{displayLastMessageDate()}</div>

            {unread && (
              <div className="h-1 w-1 bg-icon-active rounded-full"></div>
            )}
          </p>
        </div>

        <p className="text-high-contrast text-base font-semibold mt-1">
          {fullName}
        </p>

        <p
          className={`${
            unread ? "text-high-contrast font-semibold" : "text-low-contrast"
          } text-base`}
        >
          {messagePreview}
        </p>

        <p className="text-low-contrast text-[12px] mt-1 font-light line-clamp-1">
          {displayCheckinCheckout()} · {rental_name}
        </p>
      </div>
    </div>
  );
};
