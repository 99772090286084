import React from "react";
import { useTranslation } from "react-i18next";
import ChevronDownIcon from "../../../assets/icons/chevron-down.svg?react";
import ChevronUpIcon from "../../../assets/icons/chevron-up.svg?react";

export type AccountListTableHeaderProps = {
  sortField: string;
  sortDirection: "asc" | "desc";
  onChangeSort: (field: string) => void;
};

export const AccountListTableHeader: React.FC<AccountListTableHeaderProps> = ({
  sortField,
  sortDirection,
  onChangeSort = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <tr className="bg-element-background border-b-1 border-element-border">
      <th className="w-4/12 py-3 text-sm ps-4 pe-2">
        <div className="flex flex-row items-center">
          <p>{t("Payments.accountName")}</p>
        </div>
      </th>

      <th className="px-2 py-3 text-sm">
        <div className="flex flex-row items-center">
          <p>{t("Payments.accountDetails")}</p>
        </div>
      </th>

      <th className="px-2 py-3 text-sm w-44">
        <div className="flex flex-row items-center justify-center">
          <p>{t("Payments.accountActivity")}</p>
        </div>
      </th>

      <th className="px-2 py-3 text-sm w-36">
        <div className="flex flex-row items-center justify-center">
          <p>{t("Payments.accountType")}</p>
        </div>
      </th>

      <th className="w-16 px-2 py-3 text-sm">{/* For action button */}</th>
    </tr>
  );
};
