import React from "react";
import Image from "../assets/images/register-carousel-2.svg?react";
import { useTranslation } from "react-i18next";

export default function Root() {
  const { t } = useTranslation();

  console.log(t);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col w-1/2 text-center gap-2">
        <Image className="w-full" />
        <p className="font-semibold text-low-contrast mt-4">{t("WIP.title")}</p>
        <p className="font-light text-low-contrast">{t("WIP.content1")}</p>
        <p className="font-light text-low-contrast">{t("WIP.content2")}</p>
        <p className="font-light text-low-contrast">{t("WIP.content3")}</p>
      </div>
    </div>
  );
}
