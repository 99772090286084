import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { UseFormSetValue, UseFormTrigger } from "react-hook-form";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import {
  Rental,
  RentalGroup,
} from "../../../../pages/RentalPage/RentalPage.type";
import {
  RentalGroupModalForm,
  RentalGroupUseMode,
} from "./RentalGroupModal.type";

export const useInitRentalGroupModalStates = (
  rentalGroup: RentalGroup | undefined
) => {
  const [validationLoading, setValidationLoading] = useState<boolean>(false);

  const [selectedRentals, setSelectedRentals] = useState<ValueType[]>(
    Array.from(new Set(rentalGroup?.rentals.map((r: Rental) => r.id!) ?? []))
  );

  return {
    validationLoadingState: [validationLoading, setValidationLoading],
    selectedRentalsState: [selectedRentals, setSelectedRentals],
  } as const;
};

export const useInitRentalGroup = (
  rentalGroup: RentalGroup | undefined,
  useMode: RentalGroupUseMode,
  selectedRentals: ValueType[],
  setValue: UseFormSetValue<RentalGroupModalForm>,
  trigger: UseFormTrigger<RentalGroupModalForm>
) => {
  useEffect(() => {
    if (rentalGroup) {
      if (useMode !== "create") {
        setValue("name", rentalGroup?.name ?? "");
        setValue("rentals", rentalGroup.rentals.map((r: Rental) => r.id) ?? []);
      }
    }
  }, []);

  useEffect(() => {
    trigger("rentals");
  }, [selectedRentals]);
};

export const useHandleSelectRental = (
  id: ValueType,
  selectedRentalsState: [ValueType[], Dispatch<SetStateAction<ValueType[]>>]
) => {
  const [selectedRentals, setSelectedRentals] = selectedRentalsState;

  const exists = selectedRentals?.findIndex((i) => i === id) > -1;

  if (exists) {
    setSelectedRentals((prev) =>
      Array.from(new Set([...prev.filter((i) => i !== id)]))
    );
  } else {
    setSelectedRentals((prev) => Array.from(new Set([...prev, id])));
  }
};
