import moment from "moment";
import paths from "../../../constants/paths";
import { get, post } from "../../../helpers/APIHelper";
import { ValueType } from "../../../types/commonTypes";
import {
  NotificationStatusesListItemResponse,
  PaymentReservationScheduleResponse,
} from "../../../types/GETTypes";
import {
  PaymentPolicyForm,
  PaymentPolicyResponse,
} from "../../Payment/Payment.type";
import { AddReservationType } from "../AddReservation.type";

// * -- API HOOKS --

export const useFetchPaymentPolicies = async (
  rentalId: ValueType | undefined,
  onSuccess: (paymentPolicies: PaymentPolicyResponse[]) => void,
  onError: (message: string) => void
) => {
  if (rentalId) {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTAL_UPDATE}/${rentalId}${
        paths.API.PAYMENTS.POLICIES
      }`
    );

    if (response?.data?.success) {
      const nextPaymentPolicies: PaymentPolicyResponse[] =
        response?.data.result;
      onSuccess(nextPaymentPolicies);
    } else {
      onError(response.data.message ?? response?.message);
    }
  }
};

export const useAddReservationNextStep = async ({
  reservation,
  values,
  onStart,
  onEnd,
  onError,
}: {
  reservation: AddReservationType;
  values: PaymentPolicyForm;
  onStart: () => void;
  onEnd: (
    reservation_id: number,
    payment_schedule: PaymentReservationScheduleResponse,
    notification_statuses: NotificationStatusesListItemResponse[]
  ) => void;
  onError: (message: string) => void;
}) => {
  onStart();

  const res = await post(
    `${import.meta.env.VITE_API_URL}${paths.API.RESERVATION_ADD_CHECK_POLICY}`,
    {
      rental_id: reservation.rental?.id.toString(),
      platform_id: reservation.general?.platform_id,
      checkin: moment(reservation?.general?.checkin).format("YYYY-MM-DD"),
      checkout: moment(reservation?.general?.checkout).format("YYYY-MM-DD"),
      time_in: reservation?.general?.time_in,
      time_out: reservation?.general?.time_out,
      adults_count: reservation.general?.adults_count,
      children_count: reservation?.general?.children_count,
      nights_count: reservation?.general?.nights_count,
      nights_total_price: reservation?.tarification?.totalNights,
      city_tax: reservation?.tarification?.cityTaxes,
      cleaning_default: reservation?.tarification?.cleanFees,
      additional_guests_total_price: reservation?.tarification?.extraGuestFees,
      pet_default: reservation?.tarification?.animalsFees,
      infant_bed_default: reservation?.tarification?.otherFees,
      reservation_total_price: reservation.tarification?.total,
      other: reservation.tarification?.otherFees,
      guest_id: reservation.guest?.id.toString(),

      // TODO WITH POLICY COMPONENT
      is_single_payment: values.is_single_payment ? 1 : 0,
      is_refundable: values.is_refundable ? 1 : 0,
      refund_value: values.refund_value,
      refund_condition: values.refund_condition,
      is_deposit_required: values.is_deposit_required ? 1 : 0,
      deposit_value_type: "FIXED",
      deposit_value: values.deposit_value,
      deposit_payment_option: values.deposit_payment_option,
      deposit_payment_account_id: values.deposit_payment_account_id,
      deposit_payment_days_delay: values.deposit_payment_days_delay,
      payment_value_type_items: values.payments_policy_items
        .map((p) => p.valueType)
        .join(","),
      payment_value_items: values.payments_policy_items
        .map((p) => p.value)
        .join(","),
      payment_trigger_items: values.payments_policy_items
        .map((p) => p.trigger)
        .join(","),
      payment_specific_date: values.payments_policy_items
        .map((p) =>
          p.specificDate ? moment(p.specificDate).format("YYYY-MM-DD") : "null"
        )
        .join(","),
      payment_account_id_items: values.payments_policy_items
        .map((p) => p.paymentAccountId ?? null)
        .join(","),
    }
  );

  if (!res.data?.success) {
    onError(res.response?.data?.message ?? res.response.message);
    return;
  }

  onEnd(
    res.data.result.reservation_id,
    res.data.result.payment_schedule,
    res.data.result.notification_statuses
  );
};
