import React from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import InformationIcon from "../../../../assets/icons/information.svg?react";
import { Card } from "../../../Common/Card/Card";
import { Separator } from "../../../Common/Separator/Separator";

export const LocalisationCardSkeleton: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Card
      Icon={InformationIcon}
      label={t("Rental.Localisation.title")}
      button={{ Icon: EditIcon, label: t("Global.edit"), skeleton: true }}
    >
      <div className="flex flex-col animate-pulse">

        <div className="flex flex-col justify-between gap-3">
          <div className="flex flex-col mt-2 gap-2 ">
            <p className="w-56 h-4 rounded bg-slate-200"></p>
            <p className="h-2 rounded-lg w-52 bg-slate-200"></p>

          </div>
          <div className="flex flex-col mt-2 gap-2">
          <p className="w-56 h-4 rounded bg-slate-200"></p>

            <p className="h-2 rounded-lg w-52 bg-slate-200"></p>

          </div>
          <div className="flex flex-col mt-2  gap-2">
          <p className="w-56 h-4 rounded bg-slate-200"></p>
            <p className="h-2 rounded-lg w-52 bg-slate-200"></p>
          </div>
        </div>
      </div>
    </Card>
  );
};
