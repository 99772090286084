import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import paths from "../../../../constants/paths";
import { ValueType } from "../../../../types/commonTypes";
import { GroupButton } from "../../../Common/GroupButton/GroupButton";
import {
  SubscriptionLayoutNavbarActiveItemType,
  SubscriptionLayoutNavbarProps,
} from "./SubscriptionLayoutNavbar.type";

export const SubscriptionLayoutNavbar: React.FC<
  SubscriptionLayoutNavbarProps
> = ({ activeItem }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentStatus, setCurrentStatus] = useState("subscription");

  const { reservationId } = useParams();

  const handleClick = (value: ValueType) => {
    const casted = value as SubscriptionLayoutNavbarActiveItemType;
    console.log(casted, "[handleClick]");
    setCurrentStatus(casted);

    console.log(casted);
    switch (casted) {
      case "details":
        navigate(`${paths.DETAILS_FACTURATION}`);
        return;
      case "subscription":
        navigate(`${paths.YOUR_SUBSCRIPTION}`);
        return;
    }
  };
  useEffect(() => {
    if (activeItem === "subscription") {
      navigate(`${paths.YOUR_SUBSCRIPTION}`);
    } else {
      navigate(`${paths.DETAILS_FACTURATION}`);
    }
  }, []);

  return (
    <div className="flex justify-between">
      <GroupButton
        items={[
          {
            value: "subscription",
            label: t("Settings.yourSubscription"),
            isActive: currentStatus === "subscription",
          },
          {
            value: "details",
            label: t("Settings.detailsFacturation"),
            isActive: currentStatus === "details",
          },
        ]}
        onClick={handleClick}
      />
    </div>
  );
};
