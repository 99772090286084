import React from "react";
import { TableListItemProps } from "../../Common/TableList/TableList.type";
import { Button } from "../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import DotsIcon from "../../../assets/icons/dots-vertical.svg?react";
import TrashRedIcon from "../../../assets/icons/trash-active.svg?react";
import { ValueType } from "../../../types/commonTypes";
import moment from "moment";
import StripeLogo from "../../../assets/icons/stripe.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import useUserStore from "../../../stores/useUserStore";
import { getCurrentCanEdit } from "../../../helpers/workspaceHelper";

export const AccountListTableItem: React.FC<TableListItemProps> = ({
  data,
  onClick,
  onClickAction,
}) => {
  const { t } = useTranslation();
  const userStore = useUserStore();

  return (
    <tr className="fcursor-pointer hover:bg-element-background/40 cursor-pointer">
      <td
        className="px-2 py-4 text-sm border-t-1 border-element-border"
        onClick={() => onClick && onClick(data.id)}
      >
        <div className="flex flex-col">
          <p className="font-semibold text-high-contrast">{data?.name}</p>
        </div>
      </td>

      <td
        className="px-2 py-4 text-sm border-t-1 border-element-border"
        onClick={() => onClick && onClick(data.id)}
      >
        <div className="flex flex-col gap-1">
          <p className="font-semibold text-high-contrast">
            {data?.stripe_account_name}
          </p>
          {data?.stripe_account_email && data?.stripe_account_email !== "" && (
            <p className="font-semibold text-high-contrast">
              {data?.stripe_account_email}
            </p>
          )}

          <p
            className="font-light text-low-contrast"
            onClick={() => onClick && onClick(data.id)}
          >
            {data?.stripe_account_id}
          </p>
        </div>
      </td>

      <td
        className="px-2 py-4 text-sm border-t-1 border-element-border"
        onClick={() => onClick && onClick(data.id)}
      >
        <div className="flex flex-col text-center">
          <p className="font-light text-low-contrast">
            {moment(data?.updated_at).format("DD/MM/YYYY")}
          </p>
        </div>
      </td>

      <td
        className="px-2 py-4 text-sm border-t-1 border-element-border"
        onClick={() => onClick && onClick(data.id)}
      >
        <div className="flex flex-col items-center justify-center">
          {data.type === "iban" && (
            <p className="text-lg font-bold pr-2">IBAN</p>
          )}
          {data.type === "stripe" && <StripeLogo className="w-16" />}
        </div>
      </td>

      <td className="px-2 py-4 text-sm border-t-1 border-element-border">
        <div>
          <Button
            disabled={
              !getCurrentCanEdit({
                user: userStore.user,
                workspaceOwner: data.workspace_owner,
              })
            }
            RightIcon={DotsIcon}
            type="secondary"
            dropItems={[
              {
                label: t("Global.edit"),
                value: "edit",
                Icon: EditIcon,
              },
              {
                label: t("Global.remove"),
                value: "remove",
                Icon: TrashRedIcon,
                isRed: true,
              },
            ]}
            onClick={(action: ValueType) =>
              onClickAction && onClickAction(data.id, action)
            }
          />
        </div>
      </td>
    </tr>
  );
};
