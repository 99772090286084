import { useState } from "react";
import { post } from "../helpers/APIHelper";
import { AxiosRequestConfig } from "axios";

export function usePost() {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<{
    message: string;
    result: any;
    success: boolean;
  } | null>(null);

  const perform = async (
    url: string,
    data?: any,
    config?: AxiosRequestConfig<any> | undefined
  ) => {
    setLoading(true);
    setSuccess(false);
    setError(null);
    setData(null);

    const response = await post(url, data, config);

    if (!response?.data?.success) {
      setData(response.response.data);
      setError(response.response.data.message);
    } else {
      setData(response.data);
      setSuccess(true);
    }

    setLoading(false);
  };

  return { perform, loading, success, error, data };
}
