import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "../../assets/icons/check-circle-white.svg?react";
import { Button } from "../../components/Common/Button/Button";
import { TableList } from "../../components/Common/TableList/TableList";
import { TextInput } from "../../components/Common/TextInput/TextInput";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import paths from "../../constants/paths";
import { post } from "../../helpers/APIHelper";
import { useTablePage } from "../../hooks/useTablePage";
import { ValueType } from "../../types/commonTypes";
import { ProgressionRentalsRulesPageProps } from "./ProgressionRentalsRulesPage.type";

import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import SearchIcon from "../../assets/icons/search.svg?react";
import { ErrorMessage } from "../../components/Common/ErrorMessage/ErrorMessage";
import { ProgressionStepEnum } from "../../components/Progression/Progression.type";
import { ProgressionRentalsRulesModal } from "../../components/Progression/ProgressionRentalsRulesModal/ProgressionRentalsRulesModal";
import { ProgressionRentalsRulesBanner } from "../../components/Progression/RentalsRules/Banner";
import { ProgressionRentalsRulesTableHeader } from "../../components/Progression/RentalsRules/TableHeader";
import { ProgressionRentalsRulesTableItem } from "../../components/Progression/RentalsRules/TableItem";
import { ProgressionRentalsRulesTableItemSkeleton } from "../../components/Progression/RentalsRules/TableItemSkeleton";
import { RentalLightListItemResponse } from "../../types/GETTypes";

export const ProgressionRentalsRulesPage: React.FC<
  ProgressionRentalsRulesPageProps
> = ({}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isRulesModalVisible, setIsRulesModalVisible] =
    useState<boolean>(false);
  const [currentRental, setCurrentRental] = useState<
    RentalLightListItemResponse | undefined
  >();
  const [loadingValidate, setLoadingValidate] = useState<boolean>(false);
  const [errorValidate, setErrorValidate] = useState<string | null>(null);

  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`,
    "rentals"
  );

  useEffect(() => {
    tablePage.fetch({});
  }, []);

  const handleClickRental = (id: ValueType) => {
    setCurrentRental(tablePage.data.find((d: any) => d.id === id));
    setIsRulesModalVisible(true);
  };

  const handleSuccess = () => {
    tablePage.fetch({
      include_ids: tablePage.currentRentalsId,
      limit: tablePage.currentNbPerPage,
      page: tablePage.currentPage,
      search: tablePage.currentSearch,
      sort_direction: tablePage.currentSortDirection,
      sort_field: tablePage.currentSortField,
    });
  };

  const completeCurrentStep = async () => {
    setErrorValidate(null);
    setLoadingValidate(true);

    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.COMPLETE_PROGRESSION_STEP}`,
      { progression_step: ProgressionStepEnum.STEP_SYNCHRONIZE }
    );

    if (!response?.data?.success) {
      setErrorValidate(response?.response?.data?.message);
      setLoadingValidate(false);
    } else navigate(`${paths.PROGRESSION}?sync_in_progress=true`);
  };

  const getTitle = () => {
    return (
      <>
        <div className="flex flex-row items-center space-x-1">
          <h1 className="m-0 text-xl text-low-contrast">
            {t("Progression.progression")}
          </h1>

          <ChevronRightIcon />

          <h1 className="pl-1 text-xl text-high-contrast">
            {t("Progression.RentalsRules.pageTitle")}
          </h1>
        </div>
      </>
    );
  };

  return (
    <>
      <ProgressionRentalsRulesModal
        currentRental={currentRental}
        rentals={tablePage.data}
        isVisible={isRulesModalVisible}
        onClose={() => setIsRulesModalVisible(false)}
        onSuccess={handleSuccess}
        key={currentRental?.id}
      />

      <MainLayout title={getTitle()} sidebarActiveItem="progression">
        <ProgressionRentalsRulesBanner />

        <div className="flex flex-col gap-1 mt-8">
          {!tablePage.total ? (
            <p className="h-2 my-2 rounded bg-slate-300 animate-pulse w-72" />
          ) : (
            <p className="font-semibold text-high-contrast">
              {tablePage.total}{" "}
              {t("Progression.RentalsRules.rentalsInSH", {
                count: tablePage.total,
              })}
            </p>
          )}

          <TextInput
            Icon={SearchIcon}
            placeholder={t("Progression.RentalsRules.placeholderSearchRentals")}
            disabled={tablePage.loading}
            onChangeText={tablePage.handleChangeSearch}
          />

          <ErrorMessage>{errorValidate}</ErrorMessage>

          <TableList
            Header={ProgressionRentalsRulesTableHeader}
            Item={ProgressionRentalsRulesTableItem}
            NoData={null}
            Skeleton={ProgressionRentalsRulesTableItemSkeleton}
            tablePage={tablePage}
            i18nElement="Global.rentals"
            onClick={handleClickRental}
          />

          <div className="flex justify-end mt-4">
            <div className="w-max">
              <Button
                onClick={completeCurrentStep}
                LeftIcon={CheckCircleIcon}
                disabled={tablePage.loading || loadingValidate}
                loading={loadingValidate}
              >
                {t("Progression.RentalsRules.validate")}
              </Button>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};
