import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {GuestPageTab} from "../GuestPageHome/GuestPageHomeComponent";
import useGuestPageTextCoverStore from "../../../stores/guestPageStore/useGuestPageTextCoverStore";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import {Button} from "../../Common/Button/Button";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import LayoutBlockComponent from "../Common/LayoutBlockComponent";
import InstructionImagesComponent from "./InstructionImagesComponent";
import {InstructionFilesComponent} from "./InstructionFilesComponent";
import {filterFilesByType} from "../../../helpers/fileHelper";
import {replaceWithBr} from "../../../helpers/stringHelper";
import {scrollToTop} from "../../../helpers/eventHelper";

const GuestPageWelcomeGuide = ({onChangeTab}: GuestPageTab) => {
  const {t} = useTranslation();
  const {storeTitle, storeSubTitle} = useGuestPageTextCoverStore();
  const {
    guestPageData,
    rentalPreview: rentalPreviewData,
    isPreviewMode,
    userPreview,
  } = useGuestPageDataStore();
  const {instructions: instructionsData, guest: guestData} =
    guestPageData || {};
  const {guest_page: {instructions: instructionsPreviewData} = {}} =
    rentalPreviewData || {};

  const instructions = isPreviewMode
    ? instructionsPreviewData
    : instructionsData;
  const guest = isPreviewMode ? userPreview : guestData;
  const instruction = instructions?.find(
    (instr) => instr.type === "welcome_guide"
  );
  const {content, files} = instruction || {};
  const savedLanguage = localStorage.getItem("language")
  const lang = (savedLanguage ?? guest?.locale ?? "en")?.toLowerCase();
  const contentTrad = content?.[lang]
  ? content[lang]
  : content && content["en"]
  ? content["en"]
  : "";
  const title = t("GuestPage.WelcomeGuide.title");
  const subTitle = t("GuestPage.WelcomeGuide.description");
  useEffect(() => {
    storeTitle(title);
    storeSubTitle && storeSubTitle(subTitle);
    scrollToTop()
  }, []);
  return (
    <div className="flex flex-col mb-16 md:w-full">
      <Button
        LeftIcon={ArrowLeftIcon}
        type="secondary"
        displayLargeBtn={false}
        className="m-4 mt-8 md:mt-12"
        buttonClassName="md:h-12"
        textClassName="md:text-xl"
        onClick={() => onChangeTab && onChangeTab("reservation")}
      >
        {t("Global.back")}
      </Button>
      <LayoutBlockComponent
        title={title}
        subTitle={subTitle}
        blockStyle={{
          titleStyle: "text-[32px]",
          childrenDivStyle: "pl-2 md:w-full md:h-full border-b-0",
          parentDivStyle: "md:w-full",
        }}
      >
          <div className={"text-high-contrast text-xl font-bold pb-4"}>
          {t("GuestPage.Reservation.info")}
        </div>
        <div
          className="text-high-contrast text-base mb-6 md:text-lg border-b-1 border-element-border pb-5 mb-3"
          dangerouslySetInnerHTML={{__html: replaceWithBr(contentTrad)}}
        />
        <InstructionImagesComponent
          images={filterFilesByType(files, "image")}
        />
        <InstructionFilesComponent files={filterFilesByType(files, "file")} />
      </LayoutBlockComponent>
    </div>
  );
};

export default GuestPageWelcomeGuide;
