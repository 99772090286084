// src/components/BottomNav.js
import React, {useEffect} from "react";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {tabType} from "./GuestPageHomeComponent";
import EuroIcon from "../../../assets/icons/euro.svg?react";
import EuroRedIcon from "../../../assets/icons/euro-red.svg?react";
import CartIcon from "../../../assets/icons/cart.svg?react";
import CartRedIcon from "../../../assets/icons/cart-red.svg?react";
import HomeIcon from "../../../assets/icons/home.svg?react";
import HomeRedIcon from "../../../assets/icons/home-red.svg?react";
import DoubleChatBubbleIcon from "../../../assets/icons/double-chat-bubble.svg?react";
import DoubleChatBubbleRedIcon from "../../../assets/icons/double-chat-bubble-red.svg?react";
import FileTextIcon from "../../../assets/icons/file-text.svg?react";
import FileTextRedIcon from "../../../assets/icons/file-text-red.svg?react";
import "../../../index.css";
import useWindowSize from "../../../hooks/useWindowSize";
import moment from "moment";
import {GuestPageConnectResponse} from "../../../types/GETTypes";
import {calculateDaysUntilCheckin} from "../../../helpers/dateHelper";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import useGuestPageTextCoverStore from "../../../stores/guestPageStore/useGuestPageTextCoverStore";

const GuestPageTabsNav = ({
  tab,
  setTab,
  children,
}: {
  tab: tabType;
  setTab: React.Dispatch<React.SetStateAction<tabType>>;
  children?: React.ReactNode;
}) => {
  const {isMobile} = useWindowSize();
  const {t} = useTranslation();
  const {title, subTitle} = useGuestPageTextCoverStore();
  const isActiveTab = (tabName: tabType) => tabName === tab;
  const activeTabStyle = (tabName: tabType) =>
    tabName === tab ? "fill-current text-active font-semibold " : "";
  const isActiveExtra =
    isActiveTab("extra") ||
    isActiveTab("extraPayment") ||
    isActiveTab("extraHistorical");
  const isActiveResa =
    isActiveTab("reservation") ||
    isActiveTab("instructionCheckin") ||
    isActiveTab("instructionCheckout") ||
    isActiveTab("welcomeGuide");
  const {
    guestPageData,
    rentalPreview: rentalPreviewData,
    isPreviewMode,
  } = useGuestPageDataStore();
  const {extras_enabled} = guestPageData || {};
  const {guest_page: guestPagePreview} = rentalPreviewData || {};
  const {extras_enabled: extrasEnabledPreview} = guestPagePreview || {};
  const {rental: rentalData} = guestPageData || {};
  const {guest_page: {instructions: instructionsPreviewData} = {}} =
    rentalPreviewData || {};

  const rental = isPreviewMode
    ? rentalPreviewData?.details.informations
    : rentalData;
  const rentalCover = isPreviewMode
    ? rentalPreviewData?.details?.photos.cover
    : rental && "cover" in rental
    ? rental?.cover
    : "";

  const hideExtra = isPreviewMode ? !extrasEnabledPreview : !extras_enabled;

  if (isMobile) {
    return (
      <>
        <div className="z-[999] fixed bottom-0 md:bottom-0 left-0 right-0 md:right-auto bg-white shadow-lg border-t border-gray-200 flex md:flex-col justify-around items-center py-2">
          <div
            onClick={() => setTab("reservation")}
            className="flex flex-col items-center text-gray-600 hover:text-red-500 cursor-pointer"
          >
            {isActiveResa ? (
              <HomeRedIcon className={`w-6 h-6`} />
            ) : (
              <HomeIcon className={`w-6 h-6`} />
            )}
            <span
              className={`text-xs ${activeTabStyle(
                "reservation"
              )} ${activeTabStyle("instructionCheckin")} ${activeTabStyle(
                "instructionCheckout"
              )} ${activeTabStyle("welcomeGuide")}`}
            >
              {t("GuestPage.Tab.reservation")}
            </span>
          </div>
          <div
            onClick={() => setTab("contact")}
            className="flex flex-col items-center text-gray-600 hover:text-red-500 cursor-pointer"
          >
            {isActiveTab("contact") ? (
              <DoubleChatBubbleRedIcon className={`w-6 h-6`} />
            ) : (
              <DoubleChatBubbleIcon className={`w-6 h-6`} />
            )}
            <span className={`text-xs ${activeTabStyle("contact")}`}>
              {t("GuestPage.Tab.contact")}
            </span>
          </div>
          {!hideExtra ? (
            <div
              onClick={() => setTab("extra")}
              className="flex flex-col items-center text-gray-600 hover:text-red-500 cursor-pointer"
            >
              {isActiveExtra ? (
                <CartRedIcon className={`w-6 h-6`} />
              ) : (
                <CartIcon className={`w-6 h-6`} />
              )}
              <span
                className={`text-xs ${activeTabStyle("extra")} ${activeTabStyle(
                  "extraPayment"
                )} ${activeTabStyle("extraHistorical")}`}
              >
                {t("GuestPage.Tab.extra")}
              </span>
            </div>
          ) : null}
          <div
            onClick={() => setTab("payment")}
            className="flex flex-col items-center text-gray-600 hover:text-red-500 cursor-pointer"
          >
            {isActiveTab("payment") ? (
              <EuroRedIcon className={`w-6 h-6`} />
            ) : (
              <EuroIcon className={`w-6 h-6`} />
            )}
            <span className={`text-xs ${activeTabStyle("payment")}`}>
              {t("GuestPage.Tab.payment")}
            </span>
          </div>

          {/* <div
            onClick={() => setTab("contract")}
            className="flex flex-col items-center text-gray-600 hover:text-red-500 cursor-pointer"
          >
            {isActiveTab("contract") ? (
              <FileTextRedIcon className={`w-6 h-6`} />
            ) : (
              <FileTextIcon className={`w-6 h-6`} />
            )}
            <span className={`text-xs ${activeTabStyle("contract")}`}>
              {t("GuestPage.Tab.contract")}
            </span>
          </div> */}
        </div>
        {children}
      </>
    );
  }

  const getTitle = () => {
    if (title) {
      return title;
    }
    return "";
  };

  const getSubtitle = () => {
    if (subTitle) {
      return subTitle;
    }
    return "";
  };
  return (
    <div className="">
      <div className="flex flex-col">
        <div
          className="relative h-32 md:h-32 bg-cover bg-center"
          style={{backgroundImage: `url('${rentalCover}')`}}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center">
            <div className="text-white px-4 mx-auto ml-[15%]">
              <h1 className="flex  text-[32px] md:text-[40px] font-bold items-center">
                {getTitle()}
              </h1>
              <p className="mt-1 text-base md:text-xl font-normal leading-7">
                {getSubtitle()}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-1 gap-8 md:flex-row md:m-auto md:w-[80%]">
          <div className="pt-[50px] left-0 right-0 bottom-0 right-auto flex flex-col justify-around items-end py-2 size-[230px] min-w-[230px] min-h-[230px] space-y-14 mr-6">
            <div
              onClick={() => setTab("reservation")}
              className="flex flex-row items-center text-gray-600 hover:text-red-500 cursor-pointer space-x-2"
            >
              <span
                className={`text-xs md:text-xl ${activeTabStyle(
                  "reservation"
                )} ${activeTabStyle("instructionCheckin")} ${activeTabStyle(
                  "instructionCheckout"
                )} ${activeTabStyle("welcomeGuide")}`}
              >
                {t("GuestPage.Tab.reservation")}
              </span>
              {isActiveResa ? (
                <HomeRedIcon className={`w-6 h-6`} />
              ) : (
                <HomeIcon className={`w-6 h-6`} />
              )}
            </div>
            <div
              onClick={() => setTab("contact")}
              className="flex flex-row items-center text-gray-600 hover:text-red-500 cursor-pointer space-x-1"
            >
              <span
                className={`text-xs md:text-xl ${activeTabStyle("contact")}`}
              >
                {t("GuestPage.Tab.contact")}
              </span>
              {isActiveTab("contact") ? (
                <DoubleChatBubbleRedIcon className={`w-6 h-6`} />
              ) : (
                <DoubleChatBubbleIcon className={`w-6 h-6`} />
              )}
            </div>
            {!hideExtra ? (
              <div
                onClick={() => setTab("extra")}
                className="flex flex-row items-center text-gray-600 hover:text-red-500 cursor-pointer space-x-1"
              >
                <span
                  className={`text-xs md:text-xl ${activeTabStyle(
                    "extra"
                  )} ${activeTabStyle("extraPayment")} ${activeTabStyle(
                    "extraHistorical"
                  )}`}
                >
                  {t("GuestPage.Tab.extra")}
                </span>
                {isActiveExtra ? (
                  <CartRedIcon className={`w-6 h-6`} />
                ) : (
                  <CartIcon className={`w-6 h-6`} />
                )}
              </div>
            ) : null}

            <div
              onClick={() => setTab("payment")}
              className="flex flex-row items-center text-gray-600 hover:text-red-500 cursor-pointer space-x-1"
            >
              <span
                className={`text-xs md:text-xl ${activeTabStyle("payment")}`}
              >
                {t("GuestPage.Tab.payment")}
              </span>
              {isActiveTab("payment") ? (
                <EuroRedIcon className={`w-6 h-6`} />
              ) : (
                <EuroIcon className={`w-6 h-6`} />
              )}
            </div>
            {/* <div
        onClick={() => setTab("contract")}
        className="flex flex-row items-center text-gray-600 hover:text-red-500 cursor-pointer space-x-1"
      >
        <span className={`text-xs ${activeTabStyle("contract")}`}>
          {t("GuestPage.Tab.contract")}
        </span>
        {isActiveTab("contract") ? (
          <FileTextRedIcon className={`w-6 h-6`} />
        ) : (
          <FileTextIcon className={`w-6 h-6`} />
        )}
      </div> */}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default GuestPageTabsNav;
