export default {
  API: {
    LOGOUT: "/auth/logout",
    CSRF_COOKIE: "/sanctum/csrf-cookie",
    FORGOT_PASSWORD: "/auth/forgot-password",
    RESET_PASSWORD: "/auth/reset-password",
    INIT_SUBSCRIPTION: "/auth/register/init-subscription",
    CREATE_SUBSCRIPTION: "/auth/register/create-subscription",
    USER: {
      GET_USER: "/user",
      USER_LEAD_UPDATE: "/user-lead/update",
    },
    UPDATE_FROM_PROGRESSION: "/rentals/update-from-progression",
    RENTALS: "/rentals",
    RENTALS_UPDATE: "/rentals/update",
    UPDATE_ORDER_RENTALS: "/rentals/update-custom-order",
    DESCRIPTION_DETAILS: "/details/description",
    INFORMATIONS_DETAILS: "/details/informations",
    INFORMATIONS_LOCALISATION: "/details/localisation",

    RENTAL_ADD_CHECK_INFOS: "/rentals/create/step-1/check-general-infos",
    RENTAL_ADD_CHECK_ROOMS: "/rentals/create/step-2/check-rooms",
    RENTAL_ADD_CHECK_FACILITIES: "/rentals/create/step-3/check-equipments",
    RENTAL_ADD_CHECK_PHOTOS: "/rentals/create/step-4/check-description",
    RENTAL_ADD_CHECK_RULES: "/rentals/create/step-5/check-rules",
    RENTAL_ADD_CHECK_TARIFICATION: "/rentals/create/step-6/check-tarification",
    RENTAL_UPDATE: "/rentals/update",
    RENTAL_ENABLE: "/details/enable",
    RENTAL_DISABLE: "/details/disable",

    RENTAL_CHECKLIST: "/rentals/checklist/",

    DESCRIPTION: "/description",
    RENTAL_GROUPS: "/rental-groups",
    CALENDAR: "/calendar",
    RESTRICTION: "/rentals/get-restrictions",
    CALENDAR_LIST: "/calendar/get-list-view-data",
    RESERVATIONS: "/reservations",
    RESERVATION_ADD_CHECK_AVAILABILITY:
      "/reservations/create/step-1/check-availability",
    RESERVATION_ADD_CHECK_TARIFICATION:
      "/reservations/create/step-2/check-tarification",
    RESERVATION_ADD_CHECK_GUEST: "/reservations/create/step-3/check-guest",
    RESERVATION_ADD_CHECK_POLICY:
      "/reservations/create/step-4/check-payment-schedule",
    RESERVATION_GUESTS: "/reservation-guests",

    RESERVATION: {
      CANCEL: "/reservations/cancel",
      REFUND_EXTRA: (orderId: number, extraId: number) =>
        `/payment-extras-orders/${orderId}/refund-extra/${extraId}`,
      VALIDATE_ORDER: (orderId: number) =>
        `/payment-extras-orders/${orderId}/validate-order`,
    },

    CHECKLIST_FILTER: "/checklist/filter",

    MESSAGES: "/messages",
    SEND_MESSAGE: "/messages/send",
    GET_SHORT_CODES_REPLACED_TEXT: "/get-short-codes-replaced-text",
    JOURNAL: {
      RESERVATION: "/journal/reservation",
    },
    BOOKING: {
      GET_PROPERTY_ROOMS: "/booking/get-property-rooms",
      CONNECT_RENTAL: "/booking/connect-rental",
      DECONNECT_RENTAL: "/booking/deconnect-rental",
      DISABLE_SYNCHRONIZATION: "/booking/disable-synchronization",
    },
    AIRBNB: {
      LOGIN: "/airbnb/login",
      STATUS: "/airbnb/login/status",
      GET_LISTINGS: "/airbnb/listings",
      SEND_MFA: "/airbnb/login/mfa/send",
      CHECK_MFA: "/airbnb/login/mfa/check",
      DISABLE_SYNCHRONIZATION: "/airbnb/disable-synchronization",
      SYNCHRONIZE: "/airbnb/enable-synchronization",
      ACCOUNT_LISTING: "/airbnb/listing",
      ACCOUNT_LISTINGS: "/airbnb/listings-account",
    },
    PRICELABS: {
      WEBSITE_URL: "https://pricelabs.co/pricing",
      CONNECT_RENTAL: "/pricelabs/connect-rental",
      DECONNECT_RENTAL: "/pricelabs/disconnect-rental",
      UPDATE_SETTINGS: "/pricelabs/update-settings",
    },
    PROGRESSION: "/user/progression/get-current-step",
    GET_PROGRESSION: "get-synchronization-progression",
    START_SYNCHRONIZATION: "start-synchronization",
    ALL_STEPS: "/user/progression/get-all-steps",
    COMPLETE_PROGRESSION_STEP: "/user/progression/complete-current-step",
    MESSAGE_TEMPLATES: "/messages-template",
    LANGUAGES: "/languages",
    SHORTCODES: "/shortcodes",
    RENTAL: {
      UPDATE_PHOTOS_RENTAL: "/details/photos",
      UPDATE_EQUIPMENTS: "/details/equipments",
      UPDATE_RESERVATIONS_RULES: "/details/rules",
      UPDATE_PRIVATE_NOTE: "/details/private-notes",
      UPDATE_BEDROOM: "/details/rooms",
      UPDATE_PRICING_RULES: "/tarification/rules/rules",
      UPDATE_PRICE_MULTIPLIERS: "/tarification/rules/price-multipliers",
      UPDATE_ADDITIONAL_FEES: "/tarification/fees-discounts/additional-fees",
      UPDATE_FAVORITE_PAYMENT_POLICY:
        "/tarification/policies-seasons/favorite-policy",
      UPDATE_PAYMENT_WAYS: "/tarification/rules/payment-ways",
      FIND_BY_ID: (rentalId: number) => "/rentals/" + rentalId,
    },
    CHECKLIST: {
      FILTER: "/checklist/filter",
    },
    PAYMENTS: {
      ACCOUNTS: "/payment-accounts",
      ACCOUNTS_FOR_DROPDOWN: "/payment-accounts/get-dropdown-list-items",
      POLICIES: "/tarification/policies-seasons/payment-policies",
      RESERVATIONS: "/payment-reservations",
      GET_LINK: "/get-payment-link",
      DEPOSITS: "/payment-deposits",
      GET_DEPOSIT_LINK: "/get-deposit-link",
      DEPOSIT_RELEASE: "release-deposit",
      DEPOSIT_REFUND: "refund-deposit",
      DEPOSIT_NO_REFUND: "no-refund-deposit",
      DEPOSIT_CAPTURE: "capture-deposit",
      SET_TO_PAID: "set-payment-as-paid-manually",
      SEND_PAYMENT: "/payment-reservations/send-payment-link-manually",
      SEND_DEPOSIT: "/payment-deposits/send-deposit-link-manually",
      EXTRAS_ORDERS: "/payment-extras-orders",
    },
    LOCKS: {
      LOCKS: "/locks",
      LOCK_AUTHENTICATIONS: "/lock-authentications",
      DEVICES: "/devices",
      AUTHENTICATION: "/locks/lock-authentications/connect",
      GET_ALL_LINKED_LOCKS: "/locks/rentals",
      GET_LOCK: "/lock",
    },
    GUESTS: "/reservation-guests",
    GUEST_PAGE_CONTACTS: "/guest-page/contacts",
    GUEST_PAGE: {
      INDEX: "/guest-page",
      GET_SCREEN_DETAILS: "/guest-page/get-login-screen-details",
      CONNECT: "/guest-page/connect",
      UPDATE_RESERVATION: "/guest-page/update-reservation",
      VALIDATE_ORDER_EXTRAS: "/guest-page/validate-extras-order",
      SEND_EXTRAS_ORDER_PAYMENT: "/guest-page/send-extras-order-payment",
      ADD_RESERVATION_REVIEW: "/guest-page/add-reservation-review",
    },
    GUEST_PAGE_REVIEW: "/reviews",
    GUEST_INSTRUCTION: "/instruction",
    GUEST_PAGE_CONTACTS_DELETE: "/guest-page/delete/contacts",
    GUEST_PAGE_EXTRAS: "/guest-page/extras",
    GUEST_PAGE_EXTRA: "/guest-page/extra",
    GUEST_PAGE_ENABLE_INFORMATIONS: "/guest-page/enable-informations",
    GUEST_PAGE_DISABLE_INFORMATIONS: "/guest-page/disable-informations",
    GUEST_PAGE_DISABLE_REVIEWS: "/guest-page/disable-reviews",
    GUEST_PAGE_ENABLE_EXTRAS: "/guest-page/enable-extras",
    GUEST_PAGE_DISABLE_EXTRAS: "/guest-page/disable-extras",
    GUEST_PAGE_ENABLE_REVIEWS: "/guest-page/enable-reviews",
    GUEST_PAGE_REQUIRED_RESSOURCES: "/guest-page/required-resources",
    GUEST_PAGE_EXTRAS_PAYMENT_ACCOUNT: "/extras-payment-account",
    GUEST_PAGE_UPDATE_WIFI: "/guest-page/wifi",
    GUEST_PAGE_UPDATE_CGV: "/guest-page/cgv",
    GUEST_PAGE_UPDATE_CODE: "/guest-page/code",

    GUEST_PAGE_DISABLE: "/guest-page/disable",
    GUEST_PAGE_ENABLE: "/guest-page/enable",

    AUTO_MESSAGES: "/notifications",
    AUTO_MESSAGES_DISABLE: "/notifications/disable-notification",
    AUTO_MESSAGES_ENABLE: "/notifications/enable-notification",

    SCHEDULED_MESSAGE: "/notifications-status",
    SCHEDULED_MESSAGE_RESEND: "/resend",
    SCHEDULED_MESSAGE_CANCEL: "/cancel",
    SCHEDULED_MESSAGE_ENABLE: "/enable",
    SCHEDULED_MESSAGE_EDIT: "/update",

    OPERATIONNAL_MANAGEMENT: {
      ROLES: "/operational-management/postes",
      TEAMS: "/operational-management/teams",
      TEAMS_MEMBERS: "/operational-management/teams/member",
      GET_TEAMS_FOR_USER: "/operational-management/teams/member",
      CREATE_TEAM: "/operational-management/team/create",
      DELETE_TEAM: "/operational-management/teams/delete",
      LEAVE_TEAM: "/operational-management/leave-team",
      LEAVE_WORKSPACE: "/operational-management/leave-workspace",
      CHANGE_WORKSPACE: "/operational-management/change-workspace",
      MEMBERS: "/operational-management/members",
      UPDATE_TEAM: "/operational-management/teams/update",
      INVIT: "/operational-management/invitation",
      ACCEPT_INVIT: "/operational-management/invitation/accepted",
      CANCEL_INVIT: "/operational-management/cancel-invitation",
      SEND_INVIT: "/operational-management/send-invitation",
      RESEND_INVIT: "/operational-management/resend-invitation",
      CREATE_ROLE: "/operational-management/postes/create",
      UPDATE_ROLE: "/operational-management/postes/update",
      DELETE_ROLE: "/operational-management/postes/delete",
      UPDATE_MEMBER: "/operational-management/members/update",
      PERMISSIONS: "/operational-management/access",
      CREATE_MEMBER: "/operational-management/create_user",
      WORKSPACES: "/operational-management/all-workspaces",
      TASKS: {
        LISTS: "/task-management/tasks",
        CREATE: "/task-management/tasks",
        START: "/task-management/operator/start",
        ACCEPT: "/task-management/operator/accept",
        DECLINE: "/task-management/operator/decline",
        COMPLETE: "/task-management/operator/complete",
        CANCEL: "/task-management/operator/cancel",
        EDIT: "/task-management/operator/edit",
      },
      GET_AUTOMATIONS: "/automations",
      DISABLE_MULTIPLE_AUTOMATION: "/automations/disable-multiple",
      DELETE_MULTIPLE_AUTOMATION: "/automations/delete-multiple",
      ASSIGN_MULTIPLE_AUTOMATION: "/automations/assign-multiple",
      UPDATE_AUTOMATION_SETTINGS: "/automations/settings",
    },

    CRM_GUEST_MODULE: {
      GET_ALL: "/guests",
      GET_BY_ID: (guestId: number) => "/guests/" + guestId,
      UPDATE: (guestId: number) => "/guests/" + guestId,
      DELETE: (guestId: number) => "/guests/" + guestId,
      EXPORT: "/guests/export",
      MULTI_DELETE: "/guests/multi-destroy",
      CREATE: "guests",
      MERGE: "/guests/merge",
    },

    ICALENDAR: {
      EXPORT: "/icalendar/export-calendar",
      IMPORT: "/icalendar/import-calendar",
      DISCONNECT: "/icalendar/disconnect-rental",
      CONNECT: "/icalendar/connect-rental",
    },
  },
  ROOT: "/",
  NOT_FOUND: "not-found",
  LOGIN: "/login",
  LOGOUT: "/logout",
  REGISTER: "/register",
  REGISTER_MEMBER: "/register-member",
  REGISTER_MEMBER_ACCEPTED: "/register-member/accepted",
  SUBSCRIPTION: "/subscription",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  GLOBAL: {
    CGV: "https://www.superhote.com/legal/cgu",
    PRIVACY: "https://www.superhote.com/legal/privacy",
    ETHIC: "https://www.superhote.com/legal/charte-ethique",
  },
  DASHBOARD: "/dashboard",
  CALENDAR_LIST: "/calendar/list",
  CALENDAR_MONTH: "/calendar/month",
  CALENDAR_YEAR: "/calendar/year",
  CALENDAR: "/calendar",
  UPDATE_MIN_NIGHTS: "/calendar/update-min-nights",
  UPDATE_MAX_NIGHTS: "/calendar/update-max-nights",
  UPDATE_PRICES: "/calendar/update-prices",
  UPDATE_AVALABILITIES: "/calendar/update-availabilities",
  UPDATE_NOCHECKIN_NOCHECKOUT: "/calendar/update-no-checkins-checkouts",
  RESERVATION_MESSAGES: "messages",
  RESERVATION_PAYMENTS: "payments",
  RESERVATION_CONTRACTS: "contracts",
  RESERVATION_CHECKLISTS: "checklists",
  RESERVATION_TASKS: "tasks",
  RESERVATION_ACTIVITY: "activity",
  // RESERVATION_RESOURCES: "resources",
  RESERVATIONS: "/reservations",
  RESERVATIONS_ADD: "/reservations/new",
  RENTALS: "/rentals",
  RENTALS_ADD: "/rentals/new",
  RENTALS_GROUP_VIEW: "group",
  RENTALS_LIST_VIEW: "list",
  PAYMENT_RESERVATION: "reservation/:reservationId/payment/:paymentId",
  PAYMENTS: "/payments",
  PAYMENTS_RESERVATIONS: "/payments/reservations",
  PAYMENTS_DEPOSITS: "/payments/deposits",
  PAYMENTS_EXTRAS: "/payments/extras",
  PAYMENTS_ACCOUNTS: "/payments/accounts",
  PAYMENTS_INVOICES: "/payments/invoices",
  PAYMENTS_ACTIVITIES: "/payments/activities",
  DEPOSIT_PRE_AUTHORIZATION: "/reservation/:reservationId/deposit/:depositId",
  ACTIVITY: "/activity",
  MESSAGES: "/messages",
  AUTO_MESSAGES: "/auto-messages",
  SHORTCODES: "/shortcodes",
  MANAGEMENT: "/management",
  MANAGEMENT_TEAMS: "/management/teams",
  MANAGEMENT_ROLES: "/management/roles",
  MANAGEMENT_OWNERS: "/management/owners",
  MANAGEMENT_TASKS: "/operational-management/tasks",
  MANAGEMENT_AUTOMATIONS: "/operational-management/automations",
  MANAGEMENT_PAYMENTS: "/operational-management/payments",
  MANAGEMENT_ACTIVITY: "/operational-management/activity",
  ANALYTICS: "/analytics",
  ANALYTICS_EVALUATIONS: "/analytics/evaluations",
  ANALYTICS_SUMMARY: "/analytics/summary",
  PLATFORMS: "/platforms",
  BOOKING_SITE: "/booking-site",
  GUESTS: "/guests",
  SETTINGS: "/settings",
  SETTINGS_ACTIVITIES: "/settings/activities",
  SETTINGS_NOTIFICATIONS: "/settings/notifications",
  SETTINGS_PROFIL: "/settings/profil",
  YOUR_SUBSCRIPTION: "/settings/profil/your-subscription",
  DETAILS_FACTURATION: "/settings/profil/details-facturation",
  HELP: "/help",
  PROGRESSION: "/progression",
  PROGRESSION_RENTALS_RULES: "/progression/rentals-rules",
  GUEST_PAGE: "/reservation/:reservationId/guest-page/:guestPageLink",
};
