import {create} from "zustand";

interface ProgressionStoreState {
  refreshProgression: boolean;
  triggerRefreshProgression: () => void;
}

const useProgressionStore = create<ProgressionStoreState>((set) => ({
  refreshProgression: false,
  triggerRefreshProgression: () =>
    set((state) => ({
      refreshProgression: !state.refreshProgression,
    })),
}));

export default useProgressionStore;
