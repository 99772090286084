import React from "react";

export const MessageItemSkeleton: React.FC<{ isUser: boolean }> = ({
  isUser,
}) => {
  return (
    <div
      className={`flex flex-row mt-2 ${
        isUser ? "justify-end" : ""
      } pb-2 animate-pulse`}
    >
      <div className="w-2/3 items-end flex flex-col">
        <div
          className={`flex flex-row border-1 border-element-border-light p-2 w-full rounded-6px mb-1 ${
            isUser ? "bg-gray-50/80" : "bg-gray-50/30"
          }`}
        >
          {!isUser && (
            <div className="w-8 h-8 rounded-full mr-2">
              <div className="w-8 h-8 rounded-full object-cover bg-slate-200" />
            </div>
          )}

          {/* Message */}
          <div className="flex-1">
            <div className="flex flex-row space-x-1 items-center">
              {isUser ? (
                <div className="flex flex-row flex-1 justify-between pr-2 gap-1 items-center">
                  <div className="w-4 h-4 rounded-full bg-slate-200 border-1 border-element-border" />

                  <p className="text-low-contrast font-semibold h-2 w-16 bg-slate-300 rounded"></p>
                  <p className="flex-1"></p>
                </div>
              ) : (
                <>
                  <p className="text-high-contrast font-semibold flex-1 bg-slate-300 h-2 rounded"></p>
                  <p className="text-low-contrast font-semibold h-2 w-16 bg-slate-300 rounded" />
                  <div className="w-4 h-4 rounded-full bg-slate-200 border-1 border-element-border" />
                </>
              )}
            </div>
            <div className="text-low-contrast font-medium mt-1">
              <p className="h-2 w-5/6 rounded bg-slate-200" />
              <p className="h-2 w-4/6 rounded bg-slate-200 mt-1" />
            </div>
          </div>
          {/* Avatar right for isUser */}

          {isUser && (
            <div className="w-8 h-8 rounded-full border-1 border-element-border">
              <div className="w-8 h-8 rounded-full object-cover bg-slate-200" />
            </div>
          )}
        </div>
        <p className="font-light text-low-contrast h-2 bg-slate-200 w-16 rounded"></p>
      </div>
    </div>
  );
};
