import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../../Common/Card/Card";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { PaymentExtraProps } from "../Contact/ContactCard.type";
import { RequestOpinionCardSkeleton } from "./RequestOpinionCardSkeleton";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import { Controller, useForm } from "react-hook-form";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import StarIcon from "../../../../assets/icons/star.svg?react";
import { ValueType } from "../../../../types/commonTypes";
import { post } from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import { GroupButton } from "../../../Common/GroupButton/GroupButton";
import FrenchIcon from "../../../../assets/flags/France.svg?react";
import EnglishIcon from "../../../../assets/flags/USA.svg?react";
import { TextAreaInput } from "../../../Common/TextAreaInput/TextAreaInput";
import {
  ReviewContentsForm,
  ReviewContentsSchema,
} from "../Instructions/InstructionCard.type";
import {
  useDisableReviews,
  useEnableReviews,
} from "../../../../hooks/api/guestPage";
import SpanishIcon from "../../../../assets/flags/Spain.svg?react";
import GermanIcon from "../../../../assets/flags/Germany.svg?react";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import useUserStore from "../../../../stores/useUserStore";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";

export const RequestOpinionCard: React.FC<PaymentExtraProps> = ({
  loading,
  guestPage,
  rentalId,
  workspaceOwner,
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [review, setReview] = useState({
    reviews_texts: guestPage?.reviews_texts,
    visibility: guestPage?.reviews_days_visibility,
  });
  const [currentLang, setCurrentLang] = useState<ValueType>("french");

  const form = useForm<ReviewContentsSchema>({
    defaultValues: {
      reviews_texts: {
        fr: { message: "" },
        en: { message: "" },
        de: { message: "" },
        es: { message: "" },
      },
      visibility: 1,
    },
  });
  const handleChangeLang = (nextLang: ValueType) => {
    setCurrentLang(nextLang);
  };

  const initSubmit = () => {
    setError("");
    setValidationLoading(true);
  };

  const [activeReview, setActiveReview] = useState<boolean>(
    guestPage?.reviews_enabled === 1 ? true : false,
  );
  const handleUpdateReview = async (data: ReviewContentsSchema) => {
    console.log("data", data);

    if (data.reviews_texts.fr?.message === "") {
      form.setError("reviews_texts.fr", {
        message: t("Rental.Infos.TravelerPage.InstructionCard.frenchRequired"),
      });
      return;
    }

    initSubmit();

    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS_UPDATE}/${rentalId}${
        paths.API.GUEST_PAGE.INDEX
      }${paths.API.GUEST_PAGE_REVIEW}`,
      {
        reviews_texts: JSON.stringify({
          fr: data.reviews_texts.fr?.message,
          en: data.reviews_texts.en?.message,
          de: data.reviews_texts.de?.message,
          es: data.reviews_texts.es?.message,
        }),
        reviews_days_visibility: data.visibility,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    if (response.data?.success) {
      setReview({
        reviews_texts: response.data.result.guest_page.reviews_texts,
        visibility: response.data.result.guest_page.reviews_days_visibility,
      });
      setValidationLoading(false);
      setEditMode(false);
    } else {
      setError(response.data?.message);
      setValidationLoading(false);
    }
  };

  const listVisibilty = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
  ];

  const handleStatusReview = () => {
    setActiveReview(!activeReview);
    setValidationLoading(true);

    if (activeReview) {
      useDisableReviews(
        rentalId,
        (response) => {
          setValidationLoading(false);
        },
        (error) => {
          setValidationLoading(false);
        },
      );
    } else {
      useEnableReviews(
        rentalId,
        (response) => {
          setValidationLoading(false);
        },
        (error) => {
          console.log("error", error);
          setValidationLoading(false);
        },
      );
    }
  };

  useEffect(() => {
    if (guestPage?.reviews_texts) {
      form.setValue("reviews_texts.fr", {
        message: guestPage.reviews_texts.fr,
      });
      form.setValue("reviews_texts.en", {
        message: guestPage.reviews_texts.en,
      });
      form.setValue("reviews_texts.es", {
        message: guestPage.reviews_texts.es,
      });
      form.setValue("reviews_texts.de", {
        message: guestPage.reviews_texts.de,
      });
      form.setValue("visibility", guestPage.reviews_days_visibility);
    }
  }, [guestPage?.reviews_texts]);

  if (loading) return <RequestOpinionCardSkeleton />;
  return (
    <Card
      loading={validationLoading}
      Icon={StarIcon}
      label={t("Rental.Infos.TravelerPage.RequestOptionCard.title")}
      switchButton={{
        label: t("Rental.Infos.TravelerPage.ContactCard.switch"),
        value: activeReview,
        loading: validationLoading,
        disabled: !getCurrentCanEdit({ user: userStore.user, workspaceOwner }),
        onClick: () => {
          handleStatusReview();
        },
      }}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        disabled: !getCurrentCanEdit({ user: userStore.user, workspaceOwner }),
        onClick: editMode
          ? form.handleSubmit((data: any) => {
              handleUpdateReview(data);
            })
          : () => {
              setEditMode(!editMode);
              /**
               * Fix values not reset to initial values
               */
              if (guestPage?.reviews_texts) {
                form.setValue("reviews_texts.fr", {
                  message: review.reviews_texts.fr,
                });
                form.setValue("reviews_texts.en", {
                  message: review.reviews_texts.en,
                });
                form.setValue("reviews_texts.es", {
                  message: review.reviews_texts.es,
                });
                form.setValue("reviews_texts.de", {
                  message: review.reviews_texts.de,
                });
              }
            },
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => {
                setEditMode(false);
                form.clearErrors();
              },
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-y-4">
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        {editMode ? (
          <div className="flex flex-col gap-3">
            <GroupButton
              items={[
                {
                  label: t("AutoMessageList.Upsert.Contents.french"),
                  value: "french",
                  Icon: FrenchIcon,
                  isActive: currentLang === "french",
                },
                {
                  label: t("AutoMessageList.Upsert.Contents.english"),
                  value: "english",
                  Icon: EnglishIcon,
                  isActive: currentLang === "english",
                },
                {
                  label: t("AutoMessageList.Upsert.Contents.spanish"),
                  value: "spanish",
                  Icon: SpanishIcon,
                  isActive: currentLang === "spanish",
                },
                {
                  label: t("AutoMessageList.Upsert.Contents.german"),
                  value: "german",
                  Icon: GermanIcon,
                  isActive: currentLang === "german",
                },
              ]}
              onClick={handleChangeLang}
            />

            {currentLang === "french" && (
              <Controller
                control={form.control}
                name="reviews_texts.fr"
                render={({ field: { value, onChange } }) => {
                  return (
                    <div className="flex flex-col">
                      <TextAreaInput
                        label={t(
                          "Rental.Infos.TravelerPage.RequestOptionCard.textDemand",
                        )}
                        placeholder={t(
                          "Rental.Infos.TravelerPage.RequestOptionCard.textDemand",
                        )}
                        required={true}
                        value={value?.message ?? ""}
                        onTextChange={(message) => {
                          onChange({ ...value, message });
                        }}
                        error={
                          form.formState.errors?.reviews_texts?.fr?.message
                        }
                      />
                    </div>
                  );
                }}
              />
            )}

            {currentLang === "english" && (
              <Controller
                control={form.control}
                name="reviews_texts.en"
                render={({ field: { value, onChange } }) => {
                  return (
                    <div className="flex flex-col">
                      <TextAreaInput
                        label={t(
                          "Rental.Infos.TravelerPage.RequestOptionCard.textDemand",
                        )}
                        placeholder={t(
                          "Rental.Infos.TravelerPage.RequestOptionCard.textDemand",
                        )}
                        required={true}
                        value={value?.message ?? ""}
                        onTextChange={(message) => {
                          onChange({ ...value, message });
                        }}
                      />
                    </div>
                  );
                }}
              />
            )}

            {currentLang === "german" && (
              <Controller
                control={form.control}
                name="reviews_texts.de"
                render={({ field: { value, onChange } }) => {
                  return (
                    <div className="flex flex-col">
                      <TextAreaInput
                        label={t(
                          "Rental.Infos.TravelerPage.RequestOptionCard.textDemand",
                        )}
                        placeholder={t(
                          "Rental.Infos.TravelerPage.RequestOptionCard.textDemand",
                        )}
                        required={true}
                        value={value?.message ?? ""}
                        onTextChange={(message) => {
                          onChange({ ...value, message });
                        }}
                      />
                    </div>
                  );
                }}
              />
            )}

            {currentLang === "spanish" && (
              <Controller
                control={form.control}
                name="reviews_texts.es"
                render={({ field: { value, onChange } }) => {
                  return (
                    <div className="flex flex-col">
                      <TextAreaInput
                        label={t(
                          "Rental.Infos.TravelerPage.RequestOptionCard.textDemand",
                        )}
                        placeholder={t(
                          "Rental.Infos.TravelerPage.RequestOptionCard.textDemand",
                        )}
                        required={true}
                        value={value?.message ?? ""}
                        onTextChange={(message) => {
                          onChange({ ...value, message });
                        }}
                      />
                    </div>
                  );
                }}
              />
            )}

            <Controller
              control={form.control}
              name="visibility"
              rules={{
                required: true,
                onChange: (e) => {
                  form.setValue("visibility", e.target.value);
                },
              }}
              render={({ field: { onChange } }) => (
                <div className="flex-1">
                  <InputSelect
                    disabled={validationLoading}
                    label={t(
                      "Rental.Infos.TravelerPage.RequestOptionCard.visibility",
                    )}
                    selectedValue={form.getValues("visibility")}
                    defaultValue={form.getValues("visibility")}
                    items={listVisibilty}
                    required={true}
                    onSelect={onChange}
                  />
                </div>
              )}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <p className="font-bold">
                {t("Rental.Infos.TravelerPage.RequestOptionCard.textDemand")}
              </p>
              <p className="text-low-contrast">{review.reviews_texts?.fr}</p>
            </div>

            <div className="flex flex-col gap-2">
              <p className="font-bold">
                {t("Rental.Infos.TravelerPage.RequestOptionCard.visibility")}
              </p>
              <p className="text-low-contrast">
                {review.visibility}{" "}
                {t("Rental.Infos.TravelerPage.RequestOptionCard.daysAfterStay")}
              </p>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};
