import React from "react";
import { useTranslation } from "react-i18next";
import { TableListHeaderProps } from "../../Common/TableList/TableList.type";

export const ManagementRoleTableHeader: React.FC<
  TableListHeaderProps
> = ({}) => {
  const { t } = useTranslation();

  return (
    <tr className="table-header-group bg-element-background border-b-1 border-element-border ">
      <th className="px-2 py-3 text-sm rounded-tl-6px text-left">
        <p className="pl-1">{t("Management.RoleList.name")}</p>
      </th>

      <th className="px-2 py-3 text-sm text-left">
        <p>{t("Management.RoleList.membersWithThisRole")}</p>
      </th>

      <th className="px-2 py-3 text-sm text-left">
        <p>{t("Management.RoleList.roles")}</p>
      </th>

      <th className="px-2 py-3 text-sm w-16 rounded-tr-6px"></th>
    </tr>
  );
};
