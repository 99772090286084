/**
 * Component that adds:
 * - Search bar
 * - Filter button
 * - Export button
 * - "Add Traveler" button
 *
 * @author Elysio
 */

import React from "react";

import ExportIcon from "../../../assets/icons/export.svg?react";
import PlusIcon from "../../../assets/icons/plus-white.svg?react";
import SearchIcon from "../../../assets/icons/search.svg?react";

import { useTranslation } from "react-i18next";
import { Button } from "../../Common/Button/Button";
import { TextInput } from "../../Common/TextInput/TextInput";
import { ModalFilterGuest } from "./ModalFilter/ModalFilterGuest";
import { ExportButton } from "./ExportButton";
import { TravelerAddModal } from "../AddTraveler/TravelerAddModal";

import { UseTablePageReturn } from "../../../hooks/useTablePage";

export type GuestFiltersProps = {
  onAddGuest: (guestId: string) => void;
  onSearchText: (value: string) => void;
  modalTravelerAddVisible: boolean;
  tablePage: UseTablePageReturn;
};

export const GuestFilters: React.FC<GuestFiltersProps> = ({
  onAddGuest,
  onSearchText,
  modalTravelerAddVisible,
  tablePage,
}) => {
  const { t } = useTranslation();
  const [modalExportVisible, setModalExportVisible] = React.useState(false);
  const [modalAddingTravelerVisible, setModalAddingTravelerVisible] =
    React.useState(modalTravelerAddVisible);

  /**
   * Function for handle click action in button "Exporter"
   * and close action in the modal export.
   *
   * @author Elysio
   */
  const toggleModalVisible = () => {
    setModalExportVisible(!modalExportVisible);
  };

  /**
   * Function for set visible adding modal form traveler.
   *
   * @author Elysio
   */
  const toggleTravelerAddVisible = () => {
    setModalAddingTravelerVisible(!modalAddingTravelerVisible);
  };

  return (
    <div className="flex flex-row items-center space-x-3">
      <div className="flex-1">
        <TextInput
          size="large"
          Icon={SearchIcon}
          placeholder={t("Booking.Guest.search")}
          onChangeText={onSearchText}
        />
      </div>

      <div>
        <ModalFilterGuest tablePage={tablePage}></ModalFilterGuest>
      </div>

      <div title={t("Global.wip")} className={"relative"}>
        <Button
          type="secondary"
          RightIcon={ExportIcon}
          disabled={false}
          onClick={toggleModalVisible}
        >
          {t("Global.export")}
        </Button>
        <ExportButton isVisible={modalExportVisible} />
      </div>
      <div>
        <Button onClick={toggleTravelerAddVisible} RightIcon={PlusIcon}>
          {t("Booking.Guest.addTextButton")}
        </Button>
        <TravelerAddModal
          isVisible={modalAddingTravelerVisible}
          setIsVisible={setModalAddingTravelerVisible}
          onAddTraveler={onAddGuest}
        ></TravelerAddModal>
      </div>
    </div>
  );
};
