import React, {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {GuestPageTab} from "../GuestPageHome/GuestPageHomeComponent";
import useGuestPageTextCoverStore from "../../../stores/guestPageStore/useGuestPageTextCoverStore";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import {Button} from "../../Common/Button/Button";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import LayoutBlockComponent from "../Common/LayoutBlockComponent";
import CardRentalComponent from "../Common/CardRentalComponent";
import InstructionImagesComponent from "./InstructionImagesComponent";
import {filterFilesByType} from "../../../helpers/fileHelper";
import {InstructionFilesComponent} from "./InstructionFilesComponent";
import {replaceWithBr} from "../../../helpers/stringHelper";
import {scrollToTop} from "../../../helpers/eventHelper";
import CodeComponent from "./CodeComponent";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import useWindowSize from "../../../hooks/useWindowSize";

const GuestPageInstructionCheckin = ({onChangeTab}: GuestPageTab) => {
  const {isMobile} = useWindowSize();
  const {t} = useTranslation();
  const {storeTitle, storeSubTitle} = useGuestPageTextCoverStore();
  const {
    guestPageData,
    rentalPreview: rentalPreviewData,
    isPreviewMode,
    userPreview,
  } = useGuestPageDataStore();
  const {
    instructions: instructionsData,
    rental: rentalData,
    guest: guestData,
  } = guestPageData || {};
  const {guest_page: {instructions: instructionsPreviewData} = {}} =
    rentalPreviewData || {};

  const instructions = isPreviewMode
    ? instructionsPreviewData
    : instructionsData;
  const rental = isPreviewMode
    ? rentalPreviewData?.details.informations
    : rentalData;
  const rentalCover = isPreviewMode
    ? rentalPreviewData?.details?.photos.cover
    : rental && "cover" in rental
    ? rental?.cover
    : "";
  const {
    name: rentalName,
    address: rentalAddress,
    postal_code: rentalPostalCode,
    city: rentalCity,
  } = rental || {};
  const guest = isPreviewMode ? userPreview : guestData;
  const instruction = instructions?.find(
    (instr) => instr.type === "arrival_instruction"
  );
  const {content, files, key_box_code, entry_code} = instruction || {};
  const savedLanguage = localStorage.getItem("language")
  const lang = (savedLanguage ?? guest?.locale ?? "en")?.toLowerCase();
  console.log("🚀 ~ GuestPageInstructionCheckin ~ lang:", lang)
  const contentTrad = content?.[lang]
    ? content[lang]
    : content && content["en"]
    ? content["en"]
    : "";
  const title = t("GuestPage.InstructionCheckin.title");
  const subTitle = t("GuestPage.InstructionCheckin.description");
  useEffect(() => {
    storeTitle(title);
    storeSubTitle && storeSubTitle(subTitle);
    scrollToTop();
  }, []);
  return (
    <div className="flex flex-col mb-16 md:w-full">
      <Button
        LeftIcon={ArrowLeftIcon}
        type="secondary"
        displayLargeBtn={false}
        className="m-4 mt-8 md:mt-12"
        buttonClassName="md:h-12"
        textClassName="md:text-xl"
        onClick={() => onChangeTab && onChangeTab("reservation")}
      >
        {t("Global.back")}
      </Button>
      <LayoutBlockComponent
        title={title}
        subTitle={subTitle}
        blockStyle={{
          titleStyle: "text-[32px]",
          childrenDivStyle: "pl-2 md:w-full md:h-full border-b-0",
          parentDivStyle: "md:w-full",
        }}
      >
        {(entry_code || key_box_code) && (
          <>
          <div className="flex flex-row md:mb-2 md:text-lg border-b-1 border-element-border pb-4 mb-2">
            <div className="w-1/2 md:w-auto mr-1 md:mr-8">
              <CodeComponent
                label={t("GuestPage.Reservation.codeBuilding")}
                code={entry_code ?? ""}
              />
            </div>
            <div className="w-1/2 md:w-auto ml-1 md:ml-8">
              <CodeComponent
                label={t("GuestPage.Reservation.codeKeys")}
                code={key_box_code ?? ""}
              />
            </div>
          </div>
          <p className="text-low-contrast mb-4">
            {t("GuestPage.InstructionCheckin.codeDescription")}
          </p>
          </>
        )}
        <div className="md:flex md:flex-1 md:flex-col md:pt-2 md:justify-between border-b-1 border-element-border pb-2 mb-3">
          <div className={"text-high-contrast text-xl font-bold pb-2"}>
            {t("GuestPage.Reservation.goMaps")}
          </div>
          <CardRentalComponent
            name={rentalName}
            cover={rentalCover}
            address={`${rentalAddress} ${rentalPostalCode} ${rentalCity}`}
            postalCode={rentalPostalCode}
            city={rentalCity}
            divImageStyle="w-16 h-16"
          />
        </div>
        <div className={"text-high-contrast text-xl font-bold pb-4"}>
          {t("GuestPage.Reservation.info")}
        </div>
        <div
          className="text-high-contrast text-base md:text-lg border-b-1 border-element-border pb-5 mb-3"
          dangerouslySetInnerHTML={{__html: replaceWithBr(contentTrad)}}
        />

        <InstructionImagesComponent
          images={[...filterFilesByType(files, "image")]}
        />
        <InstructionFilesComponent files={filterFilesByType(files, "file")} />
        <div className="mb-6 md:mb-0 border-t-1 border-element-border md:flex md:justify-end">
          <Button
            RightIcon={ArrowRightWhiteIcon}
            buttonClassName="md:h-12 mt-3"
            textClassName="md:text-xl"
            displayLargeBtn={isMobile ? true : false}
            onClick={() => onChangeTab && onChangeTab("welcomeGuide")}
          >
            {t("GuestPage.Reservation.welcomeGuide")}
          </Button>
        </div>
      </LayoutBlockComponent>
    </div>
  );
};

export default GuestPageInstructionCheckin;
