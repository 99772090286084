import React from "react";
import { CenterModal } from "../Common/CenterModal/CenterModal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "../Common/Button/Button";

export const AddRentalCloseModal: React.FC<{
  isVisible: boolean;
  onClose: () => void;
}> = ({ isVisible, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={onClose}
      title={t("AddRental.Close.title")}
    >
      <p className="font-light text-low-contrast text-sm pb-4">
        {t("AddRental.Close.content")}
      </p>

      <div className="flex gap-3">
        <Button type="secondary" onClick={onClose}>
          {t("Global.cancel")}
        </Button>
        <Button type="destructive" onClick={handleClose}>
          {t("AddRental.Close.button")}
        </Button>
      </div>
    </CenterModal>
  );
};
