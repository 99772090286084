import React from "react";
import { CenterModal } from "../../Common/CenterModal/CenterModal";
import { Button } from "../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import { Template } from "./TemplatesModal.type";

export type RemoveTemplateModalProps = {
  isVisble: boolean;
  template: Template;
  onClose: () => void;
  onRemove: () => void;
};

export const RemoveTemplateModal: React.FC<RemoveTemplateModalProps> = ({
  isVisble,
  template,
  onClose = () => {},
  onRemove = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <CenterModal isVisible={isVisble} onClose={onClose} header="remove">
      <div className="flex flex-col gap-2">
        <p className="text-high-contrast font-semibold text-lg">
          {t("Templates.removeTitle")} "{`${template?.title}`}" ?
        </p>

        <p className="text-low-contrast font-light">
          {t("Templates.removeContent")}
        </p>

        <div className="grid grid-cols-2 gap-2 mt-3">
          <Button type="secondary" onClick={onClose}>
            {t("Global.cancel")}
          </Button>
          <Button type="destructive" onClick={onRemove}>
            {t("Global.remove")}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};
