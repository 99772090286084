import React, { useState } from "react";
import { CheckoutFormProps } from "./CheckoutForm.type";
import { useTranslation } from "react-i18next";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import paths from "../../../constants/paths";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { Button } from "../../Common/Button/Button";

import ArrowRightIcon from "../../../assets/icons/arrow-right-white.svg?react";
import { post } from "../../../helpers/APIHelper";

export const CheckoutForm: React.FC<CheckoutFormProps> = ({
  email,
  plan,
  onSuccess = () => {},
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null | undefined>(null);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      setError(t("Stripe.noStripe"));
      return;
    }

    const confirmed = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
    });

    console.log("confirmSetup confirmed", confirmed);

    if (confirmed.error) {
      setError(confirmed.error.message);
      setLoading(false);
    } else {
      console.log("payment method", confirmed.setupIntent?.payment_method);

      const createSubscriptionResponse = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.CREATE_SUBSCRIPTION}`,
        {
          email,
          plan,
          payment_method_id: confirmed.setupIntent?.payment_method,
        }
      );

      console.log("create-subscription response", createSubscriptionResponse);
      console.log(
        "createSubscriptionResponse.data.success",
        createSubscriptionResponse.data?.success
      );
      console.log(
        "createSubscriptionResponse.response?.data?.message",
        createSubscriptionResponse.response?.data?.message
      );

      if (createSubscriptionResponse.data?.success) {
        onSuccess();
      } else {
        setError(createSubscriptionResponse.response?.data?.message);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div style={{ display: loading ? "none" : "block" }}>
        <form>
          <PaymentElement />
        </form>

        <br />
      </div>

      <ErrorMessage>{error}</ErrorMessage>

      <Button
        RightIcon={ArrowRightIcon}
        onClick={handleSubmit}
        loading={loading}
      >
        {t("Register.Billing.submit")}
      </Button>
    </>
  );
};
