import React from "react";
import { NoAutoMessageProps } from "./NoAutoMessage.type";
import RobotIcon from "../../../assets/icons/robot.svg?react";
import PlusCircleIcon from "../../../assets/icons/plus-circle.svg?react";
import PlugIcon from "../../../assets/icons/plug-white.svg?react";
import { Button } from "../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import useUserStore from "../../../stores/useUserStore";

export const NoAutoMessageList: React.FC<NoAutoMessageProps> = ({
  onAdd = () => {},
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  return (
    <div
      className="flex bg-element-background w-full rounded-6px mt-2 items-center justify-center p-4"
      style={{ height: "calc(100vh - 200px)" }}
    >
      <div className="flex flex-col items-center">
        <RobotIcon width={56} height={56} />

        <p className="my-4 font-semibold text-xxl text-high-contrast">
          {t("RentalList.noAutoMessageTitle")}
        </p>

        <p className="mb-4 font-light text-base text-low-contrast text-center w-96">
          {t("RentalList.noAutoMessageSubTitle")}
        </p>

        <div className="flex mt-4 gap-4">
          <div className="w-72">
            <Button
              type="secondary"
              RightIcon={PlusCircleIcon}
              disabled={!userStore.canEdit}
              onClick={onAdd}
            >
              {t("RentalList.addReservation")}
            </Button>
          </div>

          <div className="w-72">
            <Button
              RightIcon={PlugIcon}
              disabled={true}
              onClick={() => console.log("Connect platform")}
            >
              {t("RentalList.connectPlatform")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
