import {create} from "zustand";

interface GuestPageTextState {
  title: string;
  storeTitle: (title: string) => void;
  subTitle?: string;
  storeSubTitle?: (subTitle: string) => void;
}

const useGuestPageTextCoverStore = create<GuestPageTextState>((set, get) => ({
  title: "",
  storeTitle: (newTitle: string) =>
    set((state) => ({
      title: newTitle,
    })),
  subTitle: "",
  storeSubTitle: (newSubTitle: string) =>
    set((state) => ({
      subTitle: newSubTitle,
    })),
}));

export default useGuestPageTextCoverStore;
