import React from "react";
import { useTranslation } from "react-i18next";
import HomeIcon from "../../assets/icons/home.svg?react";
import EditIcon from "../../assets/icons/edit.svg?react";
import { Separator } from "../Common/Separator/Separator";
import { Card } from "../Common/Card/Card";

export const ReservationCardSkeleton: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Card
      Icon={HomeIcon}
      label={t("Booking.Reservation.title")}
      button={{ Icon: EditIcon, label: t("Global.edit"), skeleton: true }}
    >
      <div className="animate-pulse">
        <div className="flex flex-row space-x-3 items-center justify-between border-b-1 border-element-border px-4 pb-2">
          <div className="flex items-center space-x-4">
            <div className="relative">
              <div
                className="absolute z-10 border-2 border-white rounded-full bg-slate-200"
                style={{ top: 8, left: 26, width: 24, height: 24 }}
              />

              <div className="w-10 h-10 rounded-4px border-1 border-element-border/50 bg-slate-200" />
            </div>

            <div>
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
              <div>
                <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
                <br />
                <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
              </div>
            </div>
          </div>

          <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
        </div>

        <div className="flex flex-col gap-4 p-4">
          <div className="flex flex-row items-center">
            <div className="flex-1 space-y-1">
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
            </div>

            <div className="flex-1 space-y-1">
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
            </div>
          </div>

          <div className="flex flex-row items-center">
            <div className="flex-1 space-y-1">
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
            </div>

            <div className="flex-1 space-y-1">
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
              <div className="flex flex-row text-high-contrast font-semibold items-center space-x-1">
                <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
              </div>
            </div>
          </div>

          <Separator />

          <div className="flex flex-row space-x-2 items-center">
            <div className="flex-1 space-y-1">
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
            </div>
            <div className="flex-1 space-y-1">
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
            </div>
          </div>

          <div className="flex flex-row space-x-2 items-center">
            <div className="flex-1 space-y-1">
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
            </div>
            <div className="flex-1 space-y-1">
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
            </div>
          </div>

          <div className="flex flex-row space-x-2 items-center">
            <div className="flex-1 space-y-1">
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
            </div>
            <div className="flex-1 space-y-1">
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
              <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
            </div>
          </div>

          <p className="h-3 w-5/6 bg-slate-200 rounded"></p>
        </div>
      </div>
    </Card>
  );
};
