import React from "react";
import {useTranslation} from "react-i18next";

export const BookingContractDocsTableHeader = ({}) => {
  const {t} = useTranslation();

  return (
    <tr className="bg-element-background border-b-1 border-element-border">
      <th className="w-1/4 py-3 text-sm ps-4 pe-2">
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline`}
        >
          <p></p>
        </div>
      </th>

      <th className="w-1/4 py-3 text-sm ps-4 pe-2">
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline`}
        >
          <p>{t("BookingContract.docName")}</p>
        </div>
      </th>

      <th className="w-1/4 py-3 text-sm ps-4 pe-2">
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline`}
        >
          <p>{t("BookingContract.date")}</p>
        </div>
      </th>

      <th className="w-1/4 px-2 py-3 text-sm">
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline `}
        >
          <p>{t("BookingContract.type")}</p>
        </div>
      </th>

      <th className="w-1/4 px-2 py-3 text-sm pr-4">
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline `}
        >
          {/* <p>{t("BookingContract.download")}</p> */}
        </div>
      </th>
    </tr>
  );
};
