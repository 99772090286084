import React, { SyntheticEvent } from "react";

import CheckCircleIcon from "../../../assets/icons/check-circle-green.svg?react";
import CloseIcon from "../../../assets/icons/close.svg?react";
import TrashIcon from "../../../assets/icons/trash-active.svg?react";
import { cn } from "../../../helpers/classHelper";
import { SelectModalProps } from "./SelectModal.type";

export const SelectModal: React.FC<SelectModalProps> = ({
  children,
  isVisible = false,
  title,
  size = "normal",
  onClose = () => {},
  header,
  CustomHeader = null,
  classNames,
}) => {
  const handleClickStopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      {isVisible && (
        <div
          className={cn(
            `${
              size === "normal" ? "w-[400px]" : "w-1/2 max-w-3xl"
            } bg-white rounded-16px fixed top-4 left-1/2 -translate-x-1/2 border-element-border border z-20`,
            classNames?.divModal
          )}
          onClick={handleClickStopPropagation}
        >
          {/* Header */}

          {header === "success" && (
            <div className="relative flex items-center justify-center h-32 bg-element-background-success">
              <CheckCircleIcon width={32} height={32} />
              <div
                className="absolute cursor-pointer right-3 top-3"
                onClick={onClose}
              >
                <CloseIcon width={16} height={16} />
              </div>
            </div>
          )}

          {header === "remove" && (
            <div className="relative flex items-center justify-center h-32 bg-element-background-selection-subtle">
              <TrashIcon width={32} height={32} />
              <div
                className="absolute cursor-pointer right-3 top-3"
                onClick={onClose}
              >
                <CloseIcon width={16} height={16} />
              </div>
            </div>
          )}

          {CustomHeader}

          {title && (
            <div className="flex flex-row p-6 pb-0">
              <p className="flex-1 text-base font-light tracking-wide text-low-contrast">
                {title}
              </p>

              <div className="cursor-pointer" onClick={onClose}>
                <CloseIcon width={20} height={20} />
              </div>
            </div>
          )}

          {/* Content */}
          <div className="h-auto p-6">{children}</div>
        </div>
      )}
    </>
  );
};
