import * as React from "react";
const SvgClickup = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "xmlns:x": "ns_extend;", "xmlns:i": "ns_ai;", "xmlns:graph": "ns_graphs;", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 54.8 65.8", style: {
  enableBackground: "new 0 0 54.8 65.8"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("style", { type: "text/css" }, "\n  .st0{fill-rule:evenodd;clip-rule:evenodd;fill:url(#SVGID_1_);}\n	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:url(#SVGID_2_);}\n "), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_1_", gradientUnits: "userSpaceOnUse", x1: 0, y1: 15.0492, x2: 54.8446, y2: 15.0492, gradientTransform: "matrix(1 0 0 -1 0 69.3604)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#8930FD"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#49CCF9"
} })), /* @__PURE__ */ React.createElement("path", { className: "st0", d: "M0,50.6l10.1-7.8c5.4,7,11.1,10.3,17.4,10.3c6.3,0,11.9-3.2,17-10.2l10.3,7.6c-7.4,10-16.6,15.3-27.3,15.3 C16.9,65.8,7.6,60.5,0,50.6z" }), /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_2_", gradientUnits: "userSpaceOnUse", x1: 1.1953, y1: 53.166, x2: 53.7447, y2: 53.166, gradientTransform: "matrix(1 0 0 -1 0 69.3604)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#FF02F0"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#FFC800"
} })), /* @__PURE__ */ React.createElement("path", { className: "st1", d: "M27.5,16.9l-18,15.5l-8.3-9.7L27.6,0l26.2,22.7l-8.4,9.6L27.5,16.9z" })));
export default SvgClickup;
