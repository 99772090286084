import { RightModal } from "../../Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../Common/TextInput/TextInput";
import { Button } from "../../Common/Button/Button";
import { useForm } from "react-hook-form"; // Import React Hook Form
import RecordIcon from "../../../assets/icons/check-white.svg?react";

export const MakeModalConnect = ({ isVisible, onClose, onFinish }) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // TODO : send form
    console.log("Form data:", data);
    onFinish?.(data);
  };

  return (
    <RightModal
      title={t("ThirdParty.Make.connection")}
      isVisible={isVisible}
      onClose={onClose}
    >
      <form
        onSubmit={handleSubmit(onSubmit)} // Associe la soumission au hook
        className={"h-full flex flex-col w-full justify-between"}
      >
        <section className={"flex flex-col w-full"}>
          <p className={"text-md text-gray-500"}>
            {t("ThirdParty.Make.api_key_input")}
          </p>
          <div className={"w-full mt-4"}>
            <TextInput
              label={t("ThirdParty.Make.api_key")}
              register={register("apiKey", {
                required: {
                  value: true,
                  message: t("ThirdParty.Make.required"),
                },
              })}
              error={errors.apiKey?.message}
            />
          </div>
        </section>
        <div className={"flex justify-between items-center gap-4"}>
          <Button type={"secondary"} onClick={onClose}>
            {t("Global.cancel")}
          </Button>
          <Button type="submit" RightIcon={RecordIcon}>
            {t("Global.record")}
          </Button>
        </div>
      </form>
    </RightModal>
  );
};
