import React from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import { ValueType } from "../../../../types/commonTypes";
import {Button} from "../../../Common/Button/Button";
import SendWhiteIcon from "../../../../assets/icons/arrow-right-white.svg?react";

export const AirbnbConnectStepSendMFA: React.FC<{
  options: { id: string; friction: string; obfuscated: string }[] | null;
  selectedOption: { id: string; friction: string; obfuscated: string };
  onSelectOption: (option: {
    id: string;
    friction: string;
    obfuscated: string;
  }) => void;
  error?: string | null;
  loading?: boolean;
  onNext: () => void;
}> = ({ options, selectedOption, onSelectOption, error, loading, onNext }) => {
  const { t } = useTranslation();

  const getFrictionTypes = () => {
    const result = [];

    if (
      options?.some(
        (o) => o.friction.toUpperCase() === "EMAIL_CODE_VERIFICATION"
      )
    ) {
      result.push({
        value: "EMAIL_CODE_VERIFICATION",
        label: t("Global.email"),
      });
    }

    if (
      options?.some(
        (o) => o.friction.toUpperCase() === "PHONE_VERIFICATION_VIA_TEXT"
      )
    ) {
      result.push({
        value: "PHONE_VERIFICATION_VIA_TEXT",
        label: t("Global.SMS"),
      });
    }

    if (
      options?.some(
        (o) => o.friction.toUpperCase() === "PHONE_VERIFICATION_VIA_CALL"
      )
    ) {
      result.push({
        value: "PHONE_VERIFICATION_VIA_CALL",
        label: t("Global.call"),
      });
    }

    return result;
  };

  const handleSelectionFriction = (friction: ValueType) => {
    if (options && options.filter((o) => o.friction === friction).length > 0) {
      const option = options.filter((o) => o.friction === friction).at(0);
      onSelectOption(option!);
    } else {
      onSelectOption({ id: "", obfuscated: "", friction: friction.toString() });
    }
  };

  const handleSelectionOption = (id: ValueType) => {
    const option = options?.find(
      (o) => o.friction === selectedOption.friction && o.id === id.toString()
    );

    console.log("select option", option);

    onSelectOption(option!);
  };

  return (
      <div className="flex flex-col gap-2">
        <p className="text-sm font-semibold text-low-contrast">
          {t("Progression.Airbnb.SendMFA.content1")}
        </p>

        <div className="flex flex-row px-12 my-2">
          <InputSelect
              items={getFrictionTypes()}
              selectedValue={selectedOption.friction}
              onSelect={handleSelectionFriction}
              disabled={loading}
          />
        </div>


        {options &&
            options.filter(
                (o) =>
                    o.friction?.toUpperCase() === selectedOption.friction?.toUpperCase()
            ).length > 0 && (
                <div className="flex flex-row px-12 mb-2">
                  <InputSelect
                      items={options
                          .filter((o) => o.friction === selectedOption.friction)
                          .map((option) => {
                            return {value: option.id, label: option.obfuscated};
                          })}
                      selectedValue={selectedOption.id}
                      onSelect={handleSelectionOption}
                      disabled={loading}
                  />
                </div>
            )}

        <div className="flex gap-3 mt-8">
          <Button RightIcon={SendWhiteIcon} loading={loading}
          onClick={()=> onNext()}>
            {t("AddReservation.Done.Send.button")}
          </Button>
        </div>

        <ErrorMessage>{error}</ErrorMessage>
      </div>
  );
};
