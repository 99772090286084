import React from "react";
import { useTranslation } from "react-i18next";
import {
  AutoMessageUpsertPlatformsProps,
  PlatformLineProps,
} from "./AutoMessageUpsertPlatforms.type";
import { CheckBox } from "../../../Common/CheckBox/CheckBox";
import { PlatformLine } from "./PlatformLine";

import AirBnBIcon from "../../../../assets/icons/airbnb.svg?react";
import BookingIcon from "../../../../assets/icons/booking.svg?react";
import VRBOIcon from "../../../../assets/icons/vrbo.svg?react";
import DirectIcon from "../../../../assets/icons/tv.svg?react";

export const AutoMessageUpsertPlatforms: React.FC<
  AutoMessageUpsertPlatformsProps
> = () => {
  const { t } = useTranslation();

  const platforms: PlatformLineProps[] = [
    {
      id: 1,
      name: "AirBnB",
      nbRentals: 5,
      Icon: AirBnBIcon,
      selected: true,
    },
    {
      id: 2,
      name: "Booking",
      nbRentals: 5,
      Icon: BookingIcon,
      selected: true,
    },
    {
      id: 3,
      name: "VRBO",
      nbRentals: 0,
      Icon: VRBOIcon,
      selected: false,
    },
    {
      id: 3,
      name: "Direct",
      nbRentals: 5,
      Icon: DirectIcon,
      selected: false,
    },
  ];

  return (
    <>
      <div className="mt-3">
        <table className="table-auto w-full rounded-4px border-1 border-element-border border-separate overflow-hidden border-spacing-0">
          {/* Table Header */}
          <tr className="bg-element-background border-b-1 border-element-border">
            <th className="p-2 pr-0 text-sm">
              <CheckBox />
            </th>
            <th className="px-2 py-2 text-sm">
              <div className="flex flex-row items-center cursor-pointer hover:underline">
                <p>{t("AutoMessageUpsert.platforms")}</p>
              </div>
            </th>
          </tr>

          {platforms?.map((p) => (
            <PlatformLine
              Icon={p.Icon}
              key={p.id}
              id={p.id}
              name={p.name}
              nbRentals={p.nbRentals}
              selected={p.selected}
            />
          ))}
        </table>
      </div>
    </>
  );
};
