import React from "react";
import { CleaninessType } from "./EditTaskStarted.type";
import { useTranslation } from "react-i18next";
import FaceSadIcon from "../../../assets/icons/face-sad.svg?react";
import FaceSadRedIcon from "../../../assets/icons/face-sad-red.svg?react";
import FaceMediumIcon from "../../../assets/icons/face-medium.svg?react";
import FaceMediumWarningIcon from "../../../assets/icons/face-medium-warning.svg?react";
import FaceHappyIcon from "../../../assets/icons/face-happy.svg?react";
import FaceHappyGreenIcon from "../../../assets/icons/face-happy-green.svg?react";
import { cn } from "../../../helpers/classHelper";

export const CleaninessInput: React.FC<{
  value: CleaninessType | undefined;
  onlyValue?: boolean;
  onChange: (value: CleaninessType) => void;
}> = ({ value, onlyValue = false, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-2">
      <div
        className={cn(
          "p-2 rounded-6px cursor-pointer flex gap-1 items-center",
          value === "DIRTY"
            ? "bg-red-200 border-1 border-red-700 text-red-700"
            : "bg-element-background text-low-contrast border-1 border-element-background",
          onlyValue && value !== "DIRTY" && "hidden"
        )}
        onClick={() => onChange("DIRTY")}
      >
        {value === "DIRTY" ? <FaceSadRedIcon /> : <FaceSadIcon />}
        {t("Task.Modal.Cleaniness.dirty")}
      </div>

      <div
        className={cn(
          "p-2 rounded-6px cursor-pointer flex gap-1 items-center",
          value === "MEDIUM"
            ? "bg-orange-200 border-1 border-orange-700 text-orange-700"
            : "bg-element-background text-low-contrast border-1 border-element-background",
          onlyValue && value !== "MEDIUM" && "hidden"
        )}
        onClick={() => onChange("MEDIUM")}
      >
        {value === "MEDIUM" ? <FaceMediumWarningIcon /> : <FaceMediumIcon />}
        {t("Task.Modal.Cleaniness.medium")}
      </div>

      <div
        className={cn(
          "p-2 rounded-6px cursor-pointer flex gap-1 items-center",
          value === "CLEAN"
            ? "bg-element-background-success border-1 border-success text-success"
            : "bg-element-background text-low-contrast border-1 border-element-background",
          onlyValue && value !== "CLEAN" && "hidden"
        )}
        onClick={() => onChange("CLEAN")}
      >
        {value === "CLEAN" ? <FaceHappyGreenIcon /> : <FaceHappyIcon />}
        {t("Task.Modal.Cleaniness.clean")}
      </div>
    </div>
  );
};
