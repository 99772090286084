import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import CheckIcon from "../../../assets/icons/check.svg?react";
import ChevronUpDownIcon from "../../../assets/icons/chevron-up-and-down.svg?react";
import { cn } from "../../../helpers/classHelper";
import { ValueType } from "../../../types/commonTypes";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import {
  InputMultiSelectOptionProps,
  InputMultiSelectProps,
} from "./InputMultiSelect.type";
import { useTranslation } from "react-i18next";
import { useOutsideDetector } from "../../../hooks/useOutsideDetector";
import { TextInput } from "../TextInput/TextInput";

export const InputMultiSelect: React.FC<InputMultiSelectProps> = ({
  classNames,
  required = false,
  register,
  label,
  selectedValues,
  items,
  maxItemWidth = false,
  size = "normal",
  disabled = false,
  error,
  onSelect = (values: ValueType[]) => {},
}) => {
  const { t } = useTranslation();

  const [areOptionsVisible, setAreOptionsVisible] = useState<boolean>(false);

  const divRef = useRef(null);
  useOutsideDetector(divRef, () => setAreOptionsVisible(false));

  useEffect(() => {
    items.map((item) => ({
      ...item,
      isVisible: item.isVisible !== undefined ? item.isVisible : true,
    }));
  }, []);

  const getFirstSelectedItem = () => {
    if (!selectedValues || selectedValues.length === 0) return null;

    const index = items?.findIndex((item) => item.value === selectedValues[0]);

    if (index !== -1) return items[index];
    else return null;
  };

  const getFirstSelectedIcon = () => {
    let Icon = getFirstSelectedItem()?.Icon;
    if (!Icon) return null;

    return <Icon className="flex-shrink-0 w-5 h-5 rounded" />;
  };

  const getFirstSelectedImg = () => {
    let imgSrc = getFirstSelectedItem()?.imgSrc;
    if (!imgSrc) return null;

    return <img src={imgSrc} className="flex-shrink-0 w-5 h-5 rounded-full" />;
  };

  const displaySelectedItems = () => {
    if (!selectedValues || selectedValues.length === 0) return "";
    else if (selectedValues.length === 1)
      return getFirstSelectedItem()?.label ?? "";
    else
      return `${getFirstSelectedItem()?.label ?? ""} + ${t("Global.others", {
        count: selectedValues.length - 1,
      })}`;
  };

  const isItemSelected = (item: InputMultiSelectOptionProps) => {
    return (
      selectedValues &&
      selectedValues.findIndex((sv) => sv === item.value) !== -1
    );
  };

  const handleChangeValue = (value: ValueType) => {
    let nextValues: ValueType[] = [];
    if (!selectedValues) nextValues = [];
    else if (selectedValues.findIndex((sv) => sv === value) === -1)
      nextValues = [...selectedValues, value];
    else nextValues = selectedValues.filter((sv) => sv !== value);

    if (register !== undefined && typeof register.onChange === "function") {
      register?.onChange({
        target: { name: register?.name, value: nextValues },
      });
    } else {
      onSelect(nextValues);
    }
  };

  const [search, setSearch] = useState<string>("");
  const handleSearch = (text: string) => {
    setSearch(text);
  };

  return (
    <Listbox>
      {() => (
        <div className="flex flex-col" ref={divRef}>
          <div className="flex items-start space-x-1">
            {label ? (
              <Listbox.Label
                className="block text-sm font-semibold leading-6 text-left"
                htmlFor={`input-${register?.name}`}
              >
                {label}
              </Listbox.Label>
            ) : null}

            {label && required ? (
              <span className="relative text-lg -top-1 text-active">*</span>
            ) : null}
          </div>
          <div
            className={`relative cursor-pointer ${
              size === "small" ? "h-8" : "h-9"
            }`}
          >
            <Listbox.Button
              className={cn(
                `relative w-full h-full rounded-md py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm ${
                  disabled ? "bg-element-background-disabled" : "bg-white"
                } ${disabled ? "cursor-not-allowed" : "cursor-pointer"} ${
                  error && "border-1 border-error"
                }`,
                classNames?.button
              )}
              aria-disabled={disabled}
              onClick={() => setAreOptionsVisible(!areOptionsVisible)}
            >
              <span className="flex items-center gap-2">
                {getFirstSelectedIcon()}

                {getFirstSelectedImg()}

                <span className={cn(`block truncate text-high-contrast`)}>
                  {displaySelectedItems()}
                </span>
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                <ChevronUpDownIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={areOptionsVisible}
              as={Fragment}
              enter="transition ease-in duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-out duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`absolute z-50 ${
                  !maxItemWidth ? "w-full" : "w-max"
                } py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
              >
                <Listbox.Option
                  value="search"
                  onClick={(e) => e.stopPropagation()}
                >
                  <TextInput
                    placeholder={t("Global.search")}
                    value={search}
                    onChangeText={handleSearch}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Listbox.Option>

                {items.length > 0 &&
                  items
                    .filter((item) => item.isVisible !== false)
                    .filter((item) => item.label.match(search))
                    .map((item: InputMultiSelectOptionProps) => (
                      <Listbox.Option
                        key={item.value}
                        title={item.label}
                        className={() =>
                          cn(
                            `relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-active hover:text-white`
                          )
                        }
                        onClick={() => handleChangeValue(item.value)}
                        value={item.label}
                      >
                        {({ active }) => (
                          <>
                            <div
                              className="flex items-center gap-3"
                              title={item.label!}
                            >
                              {item.imgSrc && (
                                <img
                                  src={item.imgSrc}
                                  className="flex-shrink-0 w-5 h-5 rounded-full"
                                />
                              )}
                              {item.Icon ? (
                                <item.Icon className="flex-shrink-0 w-5 h-5 rounded" />
                              ) : null}
                              <span
                                className={`block truncate ${
                                  isItemSelected(item)
                                    ? "font-semibold"
                                    : "font-normal"
                                }`}
                              >
                                {item.label}
                              </span>
                            </div>

                            {isItemSelected(item) ? (
                              <span
                                className={cn(
                                  active ? "text-white" : "text-high-contrast",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                {active ? (
                                  <CheckWhiteIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <CheckIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
              </Listbox.Options>
            </Transition>
          </div>
          <ErrorMessage errorType="FORM">{error}</ErrorMessage>
        </div>
      )}
    </Listbox>
  );
};
