import { t } from "i18next";
import React from "react";
import { useParams } from "react-router-dom";
import LinkIcon from "../../../assets/icons/link.svg?react";
import SendIcon from "../../../assets/icons/send-white.svg?react";
import { PaymentReservationScheduleResponse } from "../../../types/GETTypes";
import { Card } from "../../Common/Card/Card";
import { CopyLink } from "../../Common/CopyLink/CopyLink";
import { ReservationPaymentAndDepositLinksSkeleton } from "./PaymentAndDepositLinksSkeleton";

type ReservationPaymentAndDepositLinksCardProps = {
  paymentSchedule: PaymentReservationScheduleResponse | undefined;
  sendSuccessed: {
    payments: boolean[];
    deposit: boolean;
  };
  onSendPaymentOrDeposit: (index: number, isDeposit: boolean) => void;
};

export const ReservationPaymentAndDepositLinksCard: React.FC<
  ReservationPaymentAndDepositLinksCardProps
> = ({ paymentSchedule, sendSuccessed, onSendPaymentOrDeposit }) => {
  const { reservationId } = useParams();

  return (
    <Card Icon={LinkIcon} label={t("Booking.PaymentAndDepositLink.title")}>
      {paymentSchedule === undefined ? (
        <ReservationPaymentAndDepositLinksSkeleton />
      ) : (
        <div className="flex flex-col gap-y-4">
          <CopyLink
            label={t("Booking.PaymentAndDepositLink.depositLabel")}
            link={`${location.protocol}//${location.host}/reservation/${reservationId}/deposit/${paymentSchedule?.deposit_payment_link}`}
            isCopied={sendSuccessed.deposit}
            button={{
              type: "primary",
              text: t("Booking.PaymentAndDepositLink.sendDeposit"),
              Icon: SendIcon,
              onClick: () => onSendPaymentOrDeposit(0, true),
            }}
          />

          {paymentSchedule && paymentSchedule.payments_schedule_items.length > 0
            ? paymentSchedule.payments_schedule_items.map((ps, index) => (
                <CopyLink
                  label={t("Booking.PaymentAndDepositLink.paymentLabel", {
                    number: index + 1,
                  })}
                  link={`${location.protocol}//${location.host}/reservation/${reservationId}/payment/${ps.payment_link}`}
                  isCopied={sendSuccessed.payments[index]}
                  button={{
                    type: "primary",
                    text: t("Booking.PaymentAndDepositLink.sendPayment"),
                    Icon: SendIcon,
                    onClick: () => onSendPaymentOrDeposit(index, false),
                  }}
                />
              ))
            : null}
        </div>
      )}
    </Card>
  );
};
