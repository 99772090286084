/**
 * Component list for TableList components.
 */

import React from "react";
import { cn } from "../../../helpers/classHelper";
import { CheckBox } from "../../../components/Common/CheckBox/CheckBox";

import { CountryResponse, GuestResponse } from "../../../types/GETTypes";
import { TableListItemProps } from "../../../components/Common/TableList/TableList.type";
import { ClassName } from "react-calendar/dist/cjs/shared/types";

import StarFiled from "../../../assets/icons/star-filled.svg?react";
import StarHalf from "../../../assets/icons/star-half.svg?react";
import StarEmpty from "../../../assets/icons/star-empty.svg?react";
import Trash from "../../../assets/icons/trash-white.svg?react";

import { ACTION_EDIT, ACTION_TRASH } from "./GuestList.type";
import Countries from "../../../constants/countries";
import { Button } from "../../../components/Common/Button/Button";
import { useTranslation } from "react-i18next";
import { useModal } from "../../../hooks/useModal";
import { PreviewImage } from "../../../components/Common/PreviewImage/PreviewImage";

export const GuestItemsList: React.FC<TableListItemProps> = ({
  data,
  onClick = () => {},
  onClickAction = () => {},
  isSelected,
}) => {
  const { t } = useTranslation();
  const previewImage = useModal();
  const guest: GuestResponse = data;
  const getBorder = (): ClassName =>
    "border-element-border border-t-2 border-gray-200";
  const photo = guest.photo
    ? guest.photo
    : "https://cdn-icons-png.flaticon.com/512/3135/3135715.png";

  /**
   * Function for getting language icon based on the guest's country.
   *
   * @author Elysio
   */
  const getLanguageIcon = () => {
    const country: CountryResponse = guest.country;
    const allCountries = Countries;
    let icon = null;

    allCountries.map((countryObject) => {
      if (countryObject.value === country.id) {
        icon = countryObject.Icon;
      }
    });

    if (!icon) {
      return <p>-</p>;
    }

    return <p className={"pl-5"}>{React.createElement(icon)}</p>;
  };

  /**
   * Function for generating star ratings based on the guest's average rating.
   *
   * @author Elysio
   */
  const generateStarsReviews = () => {
    const averageRating = guest.average_rating;

    if (averageRating == null) {
      return (
        <>
          {Array.from({ length: 5 }).map((_, index) => (
            <StarEmpty key={`empty-${index}`} />
          ))}
        </>
      );
    }

    const fullStars = Math.floor(averageRating);
    const hasHalfStar = averageRating % 1 >= 0.5;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    return (
      <>
        {Array.from({ length: fullStars }).map((_, index) => (
          <StarFiled key={`full-${index}`} />
        ))}
        {hasHalfStar && <StarHalf key="half" />}
        {Array.from({ length: emptyStars }).map((_, index) => (
          <StarEmpty key={`empty-${index}`} />
        ))}
      </>
    );
  };

  /**
   * Function to handle the click action for the 'trash' button.
   * This function opens a modal to confirm deletion of the guest.
   *
   * @author Elysio
   */
  const handleClicktTrash = () => {
    onClickAction(guest.id, ACTION_TRASH);
  };

  /**
   * Function to handle the click action for the 'edit' button.
   * This function opens a modal to edit the guest's information.
   *
   * @author Elysio
   */
  const handleClickEdit = () => {
    onClickAction(guest.id, ACTION_EDIT);
  };

  return (
    <tbody>
      <tr className="cursor-pointer hover:bg-element-background/40">
        {/* Checkbox */}
        <td
          className={cn(
            `flex flex-row items-center px-4 py-4  text-sm border-element-border`,
            `${getBorder()}`,
          )}
        >
          <div className={"translate-y-1.5"}>
            <CheckBox
              value={isSelected}
              onChange={() => {
                onClick(guest.id);
              }}
            />
          </div>
        </td>

        {/* Voyageur */}
        <td className={cn(`px-2 py-4`, `${getBorder()}`)}>
          <span className="font-bold tracking-normal flex items-center gap-6">
            <img
              className={"w-8 h-8 rounded-full"}
              src={photo}
              alt={"traveler image"}
              onClick={() => previewImage.open()}
            />
            <PreviewImage imageUrl={photo} imageModal={previewImage} />
            {guest.first_name + " " + guest.last_name}
          </span>
        </td>

        {/* Languages */}
        <td
          onClick={() => onClickAction(guest.id, 2)}
          className={`${getBorder()}`}
        >
          {getLanguageIcon()}
        </td>

        {/* E-mail */}
        <td
          onClick={() => onClickAction(guest.id, 2)}
          className={cn(` py-4`, `${getBorder()}`)}
        >
          <p className="text-gray-500 tracking-normal gap-6 text-center">
            {guest.email}
          </p>
        </td>

        {/* Phone */}
        <td
          onClick={() => onClickAction(guest.id, 2)}
          className={cn(` py-4`, `${getBorder()}`)}
        >
          <p className="text-gray-500 tracking-normal gap-6 text-center">
            {guest.phone}
          </p>
        </td>

        {/* Sejours */}
        <td
          onClick={() => onClickAction(guest.id, 2)}
          className={cn(` py-4`, `${getBorder()}`)}
        >
          <p className="text-gray-500 tracking-normal gap-6 text-center">
            {guest.total_stay_days}
          </p>
        </td>

        {/* Review */}
        <td
          onClick={() => onClickAction(guest.id, 2)}
          className={cn(`py-4 pl-8`, `${getBorder()}`)}
        >
          <div className="text-gray-500 tracking-normal gap-1 text-center flex items-center justify-center">
            {React.createElement(generateStarsReviews)}
          </div>
        </td>

        {/* Total */}
        <td
          onClick={() => onClickAction(guest.id, 2)}
          className={cn(`px-4 py-4 pl-8`, `${getBorder()}`)}
        >
          <p className="text-gray-500 tracking-normal gap-1 text-center">
            {guest.total_spend}€
          </p>
        </td>

        {/* Actions */}
        <td className={cn(`px-4 py-4`, `${getBorder()}`)}>
          <div className="text-gray-500 tracking-normal gap-2 flex items-center">
            <Button onClick={handleClickEdit} type={"secondary"}>
              {t("Global.update")}
            </Button>
            <div>
              <Button>
                <Trash onClick={handleClicktTrash} />
              </Button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  );
};
