import { TableList } from "../../../Common/TableList/TableList";
import { useTablePage } from "../../../../hooks/useTablePage";
import { useTranslation } from "react-i18next";
import { AirbnbRentalsListHeader } from "./AirbnbRentalsListHeader";
import { AirbnbRentalsListItem } from "./AirbnbRentalsListItem";
import { AirbnbRentalsListSkeleton } from "./AirbnbRentalsListSkeleton";

import { DataListPlatformProps } from "../../Platform.type";
import paths from "../../../../constants/paths";

export const AirbnbRentalsList = () => {
  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.AUTO_MESSAGES}`,
    "notifications",
  );
  const { t } = useTranslation();

  return (
    <div className={"mt-4"}>
      <TableList
        tablePage={tablePage}
        Header={AirbnbRentalsListHeader}
        Item={AirbnbRentalsListItem}
        Skeleton={AirbnbRentalsListSkeleton}
        NoData={null}
        i18nElement={t("ThirdParty.Airbnb.titleList")}
      />
    </div>
  );
};
