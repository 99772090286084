import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import MinusIcon from "../../../assets/icons/minus.svg?react";
import FilePlusIcon from "../../../assets/icons/file-plus-2.svg?react";
import {Doc, nameTypeFile} from "./GuestPageForm";
import {isImageFile, isPdfFile} from "../../../helpers/fileHelper";

export interface CustomFile {
  name: nameTypeFile;
  file: Blob | string | File;
}

interface FilesRequired {
  loading: boolean;
  doc: Doc;
  newFiles: CustomFile[] | undefined;
  setNewFiles: React.Dispatch<React.SetStateAction<CustomFile[] | undefined>>;
  addFileLabel?: string;
  accept?: string;
}

interface Preview {
  newFile: CustomFile;
}

const PreviewComponent: React.FC<Preview> = ({newFile}) => {
  const file = newFile?.file as File;
  if (isImageFile(file?.type)) {
    return (
      <img
        src={URL.createObjectURL(file as Blob)}
        className="object-cover w-full h-full"
      />
    );
  } else if (isPdfFile(file?.type)) {
    return (
      <span className="text-xs w-full text-center md:text-sm flex-wrap">
        {file?.name ?? ""}
      </span>
    );
  }

  return null;
};

const FilesRequiredComponent: React.FC<FilesRequired> = ({
  loading,
  doc,
  newFiles,
  setNewFiles,
  addFileLabel,
  accept,
}) => {
  const {t} = useTranslation();
  const {name, enabled, missing} = doc;

  const newFile = newFiles?.find((file) => file.name === name);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (event: any) => {
    event.preventDefault();

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file: Blob = event.dataTransfer.files[0];
      handleChangeNewFile(file);
      event.dataTransfer.clearData(); // Efface les fichiers de l'objet dataTransfer
    }
  };

  const handleChangeNewFile = (file: Blob) => {
    let files;

    if (newFiles) {
      const index = newFiles.findIndex((f) => f.name === name);

      if (index !== -1) {
        files = [
          ...newFiles.slice(0, index),
          {name, file},
          ...newFiles.slice(index + 1),
        ];
      } else {
        files = [...newFiles, {name, file}];
      }
    } else {
      files = [{name, file}];
    }
    setNewFiles(files);
  };
  const handleAddFromInput = (event: any) => {
    const file = event.target.files[0];
    let files;

    if (newFiles) {
      const index = newFiles.findIndex((f) => f.name === name);

      if (index !== -1) {
        // If an object with the same name exists, replace it
        files = [
          ...newFiles.slice(0, index),
          {name, file},
          ...newFiles.slice(index + 1),
        ];
      } else {
        files = [...newFiles, {name, file}];
      }
    } else {
      files = [{name, file}];
    }
    setNewFiles(files);
  };

  const handleRemoveFile = () => {
    let files: any;

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    if (newFiles) {
      files = newFiles.filter((f) => f.name !== name);
    } else {
      files = [];
    }
    setNewFiles(files);
  };

  const handleAdd = () => {
    fileInputRef.current?.click();
  };

  if (!enabled) {
    return null;
  }

  return (
    <div className={`h-32 w-[100px]`}>
      <div
        className="flex flex-col mt-2 w-[100px]"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          type="file"
          multiple={false}
          style={{display: "none"}}
          ref={fileInputRef}
          accept={accept}
          onChange={handleAddFromInput}
        />
        {!newFile?.file ? (
          <div
            className={`flex flex-col items-center justify-center w-[140px] h-[115px] p-4 gap-2 border-dashed cursor-pointer bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active font-semibold ${
              missing && "border-element-border-error border-2 md:border-[3px]"
            }`}
            onClick={handleAdd}
          >
            <FilePlusIcon className="w-10 h-10" />
            <span className="text-xs w-full text-center md:text-sm">
              {addFileLabel ?? t("GuestPage.LoginDetails.addFileLabel")}
            </span>
          </div>
        ) : (
          <div className="w-full h-full group">
            <div className="flex flex-col items-center justify-center w-[140px] h-[115px] gap-2 overflow-hidden border-dashed bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active">
              {newFile && (
                <>
                  <PreviewComponent newFile={newFile} />
                  {!loading && (
                    <div
                      className="absolute bg-white rounded-sm cursor-pointer border-element-border border-1 group-hover:flex"
                      style={{marginBottom: 80, marginLeft: 90}}
                      onClick={handleRemoveFile}
                    >
                      <MinusIcon width={20} height={20} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilesRequiredComponent;
