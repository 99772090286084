import React from "react";
import { useTranslation } from "react-i18next";
import { PlatformColorList } from "../../constants/colors";
import { CenterModal } from "../Common/CenterModal/CenterModal";
import { ConnectionLoader } from "../Common/ConnectionLoader/ConnectionLoader";
import { ProgressionBar } from "../Common/ProgressionBar/ProgressionBar";
import { ListingImportWaitingModalProps } from "./Progression.type";

import AirBnBIcon from "../../assets/icons/airbnb.svg?react";

export const ListingImportWaitingModal: React.FC<
  ListingImportWaitingModalProps
> = ({ isVisible, progression, processedJobs = 0, totalJobs = 0 }) => {
  const { t } = useTranslation();

  const header = () => {
    return (
      <ConnectionLoader
        platformLeft={{
          icon: AirBnBIcon,
          color: PlatformColorList.Airbnb,
        }}
      />
    );
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={() => {}}
      CustomHeader={header()}
    >
      <p className="text-high-contrast font-semibold">
        {t("Progression.Airbnb.Sync.title")}
      </p>

      <p className="text-low-contrast font-light mt-4 text-sm">
        {t("Progression.Airbnb.Sync.content", { count: totalJobs })}
      </p>

      <p className="text-low-contrast font-light mt-4 text-sm">
        {t("Progression.Airbnb.Sync.content2")}
      </p>

      <div className="mt-4">
        <ProgressionBar progression={progression} />
      </div>

      <p className="text-secondary font-light mt-2 text-sm">
        {t("Progression.Airbnb.Sync.progressInfo", {
          processedJobs,
          count: totalJobs,
        })}
      </p>
    </CenterModal>
  );
};
