import React, { useEffect } from "react";
import { ProgressionStep } from "../../../pages/Progression/ProgressionStep";
import { useTranslation } from "react-i18next";
import { ProgressionStepEnum, ProgressionStepRentalsProps } from "../Progression.type";
import { Button } from "../../Common/Button/Button";

import PlusIcon from "../../../assets/icons/plus.svg?react";
import { useTablePage } from "../../../hooks/useTablePage";
import paths from "../../../constants/paths";
import { TableList } from "../../Common/TableList/TableList";
import { ProgressionStepRentalsTableHeader } from "./RentalsTableHeader";
import { ProgressionStepRentalsTableItem } from "./RentalsTableItem";
import { ProgressionStepRentalsTableSkeleton } from "./RentalsTableSkeleton";
import { NoRentalProgressionStepRentals } from "./NoRentalProgressionStepRentals";
import { useNavigate } from "react-router-dom";
import { ValueType } from "../../../types/commonTypes";

export const ProgressionStepRentals: React.FC<ProgressionStepRentalsProps> = ({
  currentProgression,
  status,
  number,
  visible,
  loading,
  error,
  onCompleteStep,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const rentalsTable = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.RENTALS}`,
    "rentals"
  );

  useEffect(() => {
    if (visible && status === "active") {
      console.log("useEffect rentals step");
      rentalsTable.fetch({});
    }
  }, [visible, status]);

  const handleAddRental = () => {
    navigate(`${paths.RENTALS_ADD}?from_progression=true`);
  };

  const handleClickRental = (id: ValueType) => {
    navigate(`${paths.RENTALS}/${id}`);
  };

  if (!visible) return null;
  return (
    <ProgressionStep
      number={number.toString()}
      title={t("Progression.StepRentals.title")}
      status={status}
      error={error}
      loading={loading}
    >
      <div className="flex flex-col gap-2">
        <p className="font-light text-low-contrast">
          {t("Progression.StepRentals.content1")}
        </p>

        <TableList
          Header={ProgressionStepRentalsTableHeader}
          Item={ProgressionStepRentalsTableItem}
          NoData={<NoRentalProgressionStepRentals />}
          Skeleton={ProgressionStepRentalsTableSkeleton}
          i18nElement="Global.rentals"
          tablePage={rentalsTable}
          onClick={handleClickRental}
          disabled={false}
        />

        <div className="flex gap-3 mt-1">
          <Button
            type="secondary"
            RightIcon={PlusIcon}
            onClick={handleAddRental}
          >
            {t("Progression.StepRentals.addRental")}
          </Button>
          <Button
            onClick={onCompleteStep}
            disabled={!rentalsTable?.data || rentalsTable?.data?.length === 0}
          >
            {t("Progression.StepRentals.next")}
          </Button>
        </div>
      </div>
    </ProgressionStep>
  );
};

