import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "../../assets/icons/alert-circle.svg?react";
import ChevronDownIcon from "../../assets/icons/chevron-down.svg?react";
import ChevronUpIcon from "../../assets/icons/chevron-up.svg?react";
import { Badge } from "../../components/Common/Badge/Badge";
import { Button } from "../../components/Common/Button/Button";
import { TableList } from "../../components/Common/TableList/TableList";
import { TableListItemProps } from "../../components/Common/TableList/TableList.type";
import { SubscriptionLayout } from "../../components/Layout/SubscriptionLayout/SubscriptionLayout";
import { ActiveRentalModal } from "../../components/Modal/Rental/ActiveRentalModal";
import { NoRentalRentalList } from "../../components/RentalList/NoRental/NoRentalRentalList";
import { DisableRentalModalForSubscription } from "../../components/Setting/Subscription/DisableRentalModalForSubscription";
import { SettingSubscriptionSkeleton } from "../../components/Setting/Subscription/SettingSubscriptionSkeleton";
import paths from "../../constants/paths";
import { useTablePage } from "../../hooks/useTablePage";
import { ValueType } from "../../types/commonTypes";
import { RentalLightListItemResponse } from "../../types/GETTypes";

export const SettingSubscriptionPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const rentalsTablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`,
    "rentals"
  );

  useEffect(() => {
    rentalsTablePage.fetch({
      sort_field: "custom_sort_order",
    });
  }, []);

  const handleClickRental = (id: ValueType | null) => {
    if (id !== null) {
      navigate(`${paths.RENTALS}/${id}`);
    }
  };

  return (
    <SubscriptionLayout navbarActiveItem="subscription">
      <div className="flex flex-col gap-5">
        <div className="flex-1 p-4 bg-element-background-success">
          <div className="flex flex-row gap-3 rounded-6px">
            <ChevronRightIcon width={18} height={18} fill="green" />
            <div className="flex flex-col gap-2">
              <p className="font-bold text-success">{t("Settings.message1")}</p>
              <p className="text-success">{t("Settings.message2")}</p>
            </div>
          </div>
        </div>
        <p className="text-lg font-bold">{t("Settings.planActuality")}</p>
        <div className="flex justify-between  gap-4 self-stretch p-4 rounded border border-[#dbdbdb] bg-[#f8f8f8]">
          <div className="flex flex-col items-start justify-center gap-2 frame_1">
            <div className="superhote_alpha_test text-neutral-900 font-['Inter'] text-lg font-semibold leading-5">
              {t("Settings.superhoteAlphaTest")}
            </div>

            <ul className="ml-2 space-y-2 list-disc list-inside">
              <li className="text-sm text-low-contrast">
                {t("Settings.accessAllFunctionality")}
              </li>
              <li className="text-sm text-low-contrast">
                {t("Settings.messageAutomatic")}
              </li>
              <li className="text-sm text-low-contrast">
                {t("Settings.supportAvailable")}
              </li>
              <li className="text-sm text-low-contrast">
                {t("Settings.connexionPlatformLabel")}
              </li>
            </ul>
          </div>
          <div className="flex flex-col justify-center items-center text-center font-['Inter'] text-lg font-semibold">
            {t("Settings.free")}
          </div>
        </div>

        <p className="text-lg font-bold">{t("Settings.Accommodation")}</p>

        <TableList
          i18nElement="Global.rentals"
          Header={SubscriptionTableHeader}
          Item={SubscriptionItem}
          Skeleton={SettingSubscriptionSkeleton}
          NoData={<NoRentalRentalList />}
          tablePage={rentalsTablePage}
          onClick={handleClickRental}
        />
      </div>
    </SubscriptionLayout>
  );
};

export type SubscriptionTableHeaderProps = {
  sortField: string;
  sortDirection: "asc" | "desc";
  onChangeSort: (field: string) => void;
};

export const SubscriptionTableHeader: React.FC<
  SubscriptionTableHeaderProps
> = ({ sortField, sortDirection, onChangeSort = () => {} }) => {
  const { t } = useTranslation();
  const handleChangeSort = (column: string) => {
    if (column === sortField && sortDirection === "asc") onChangeSort(column);
  };

  const getIcon = (column: string) => {
    const isActive = sortField === column;

    if (isActive && sortDirection === "asc") return <ChevronDownIcon />;
    else if (isActive && sortDirection === "desc") return <ChevronUpIcon />;
    else return <ChevronDownIcon />;
  };

  return (
    <tr className="bg-element-background border-b-1 border-element-border">
      <th
        className="w-6/12 py-3 text-sm ps-4 pe-2"
        onClick={() => handleChangeSort("name")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "name" && "underline"
          }`}
        >
          <p>{t("BookingList.rental")}</p> {getIcon("name")}
        </div>
      </th>

      <th
        className="w-2/12 px-2 py-3 text-sm"
        onClick={() => handleChangeSort("status_reservation")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "status_reservation" && "underline"
          }`}
        >
          <p>{t("Settings.status")}</p>
          {getIcon("status_reservation")}
        </div>
      </th>

      <th
        className="w-1/12 px-2 py-3 text-sm"
        onClick={() => handleChangeSort("status_payment")}
      >
        <div
          className={`flex flex-row items-center cursor-pointer hover:underline ${
            sortField === "status_payment" && "underline"
          }`}
        >
          <p>{t("Settings.cost")}</p> {getIcon("status_payment")}
        </div>
      </th>
    </tr>
  );
};
export const SubscriptionItem: React.FC<TableListItemProps> = ({
  data,
  onClick = () => {},
}) => {
  const rental: RentalLightListItemResponse = data;
  const { t } = useTranslation();
  const [disableRentalModalVisible, setDisableRentalModalVisible] =
    useState<boolean>(false);
  const [isRentalActive, setIsRentalActive] = useState<boolean | number>(
    rental?.enabled || false
  );

  return (
    <>
      {isRentalActive ? (
        <DisableRentalModalForSubscription
          isVisible={disableRentalModalVisible}
          rentalId={rental?.id}
          onClose={() => setDisableRentalModalVisible(false)}
          onDisable={() => {
            setIsRentalActive(false);
            setDisableRentalModalVisible(!disableRentalModalVisible);
          }}
        />
      ) : (
        <ActiveRentalModal
          isVisible={disableRentalModalVisible}
          rentalId={rental?.id}
          onClose={() => setDisableRentalModalVisible(false)}
          onActive={() => {
            setIsRentalActive(true);
            setDisableRentalModalVisible(!disableRentalModalVisible);
          }}
        />
      )}

      {
        <tr className="cursor-pointer hover:bg-element-background/40">
          <td
            className="flex flex-row items-center px-4 py-2 space-x-4 text-sm border-t-1 border-element-border"
            onClick={() => onClick(rental.id)}
          >
            <div className="flex items-center">
              <div className="relative">
                <div className="object-fill w-10 h-10 overflow-hidden rounded-4px border-1 border-element-border/50">
                  <img
                    src={rental.cover}
                    alt={rental?.name}
                    className="object-fill w-full h-full"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <p className="font-semibold text-high-contrast">{rental?.name}</p>
              <p className="font-light text-low-contrast">{rental?.address}</p>
              <p className="font-light text-low-contrast">
                {rental?.postal_code} {rental?.city} {rental?.country?.name}
              </p>
            </div>
          </td>

          <td className="py-2 text-sm align-middle border-t-1 border-element-border">
            {isRentalActive ? (
              <Badge label={t("RentalList.statusActive")} status="success" />
            ) : (
              <Badge label={t("RentalList.statusInactive")} status="fail" />
            )}
          </td>

          <td className="border-t-1 border-element-border">
            <div className="flex flex-row gap-10  text-center font-['Inter'] m-2">
              <p className="mt-2 text-lg font-semibold ">
                {" "}
                {t("Settings.free")}
              </p>
              <Button
                type="secondary"
                onClick={() => setDisableRentalModalVisible(true)}
              >
                {isRentalActive
                  ? t("Settings.disabled")
                  : t("Settings.enabled")}
              </Button>
            </div>
          </td>
        </tr>
      }
    </>
  );
};
