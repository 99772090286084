import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ValueType } from "../../types/commonTypes";
import { CalendarPageProps } from "./CalendarPage.type";

import { debounce } from "lodash";
import { CalendarReservationModal } from "../../components/Calendar/CalendarReservationModal";
import { CalendarHeader } from "../../components/Calendar/Header";
import { CalendarRentalLine } from "../../components/Calendar/RentalLine";
import { CalendarRentalLineSkeleton } from "../../components/Calendar/RentalLineSkeleton";
import { CalendarLayout } from "../../components/Layout/CalendarLayout/CalendarLayout";
import { NoRentalRentalList } from "../../components/RentalList/NoRental/NoRentalRentalList";
import {
  CALENDAR_CELL_HEIGHT,
  CALENDAR_CELL_WIDTH,
  CALENDAR_CHECKIN_BANNER,
  CALENDAR_NB_DAYS_BEFORE,
  CALENDAR_REMOVE_TO_END,
} from "../../constants/calendar";
import paths from "../../constants/paths";
import { get } from "../../helpers/APIHelper";

import { CalendarArrowToScroll } from "../../components/Calendar/ArrowToScroll";
import { CalendarMaxNightsModal } from "../../components/Calendar/MaxNightsModal";
import { CalendarMinNightsModal } from "../../components/Calendar/MinNightsModal";
import { CalendarNoCheckinCheckoutModal } from "../../components/Calendar/NoCheckinCheckoutModal";
import { CalendarPriceAndAvailabilityModal } from "../../components/Calendar/PriceAndAvailabilityModal";
import {
  CalendarRestrictionUnavailabilityReasonEnum,
  ReservationStatusEnum,
} from "../../enums/GETenums";
import { getDatesBetweenDates } from "../../helpers/dateHelper";
import {
  CalendarReservationResponse,
  CalendarRestrictionResponse,
} from "../../types/GETTypes";
import useUserStore from "../../stores/useUserStore";
import {
  formatWorkspaceParam,
  getCurrentCanEdit,
} from "../../helpers/workspaceHelper";

export const CalendarPage: React.FC<CalendarPageProps> = () => {
  const userStore = useUserStore();
  const selectedWorkspaces = useUserStore((state) => state.selectedWorkspaces);

  const [currentSearch, setCurrentSearch] = useState<string>("");
  const [currentFrom, setCurrentFrom] = useState(moment("2024-01-21"));
  const [currentTo, setCurrentTo] = useState(
    moment(new Date()).add(30, "days")
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number | null>(null);
  const [nbPerPage, setNbPerPage] = useState<number>(10);
  const [rentals, setRentals] = useState<any[]>([]);
  const [total, setTotal] = useState<number | undefined>();
  const [isCheckinPrice, setIsCheckinPrice] = useState<boolean>(false);

  const [reservation, setReservation] = useState<
    CalendarReservationResponse | undefined
  >();
  const [reservationVisible, setReservationVisible] = useState<boolean>(false);

  const [displayBy, setDisplayBy] = useState<"platform" | "status">("platform");

  // Manage workspace selection
  useEffect(() => {
    if (selectedWorkspaces) {
      fetchRentals({});
    }
  }, [selectedWorkspaces]);

  // Modals
  const [minNightsModal, setMinNightsModal] = useState<{
    isVisible: boolean;
    rental: any;
    date: moment.Moment;
    minNights: number;
  }>({
    isVisible: false,
    rental: null,
    date: moment(new Date()),
    minNights: 0,
  });

  const [maxNightsModal, setMaxNightsModal] = useState<{
    isVisible: boolean;
    rental: any;
    date: moment.Moment;
    maxNights: number;
  }>({
    isVisible: false,
    rental: null,
    date: moment(new Date()),
    maxNights: 0,
  });

  const [noCheckinCheckoutModal, setNoCheckinCheckoutModal] = useState<{
    isVisible: boolean;
    rental: any;
    date: moment.Moment;
    noCheckin: number;
    noCheckout: number;
  }>({
    isVisible: false,
    rental: null,
    date: moment(new Date()),
    noCheckin: 0,
    noCheckout: 0,
  });

  const [priceAndAvailabilityModal, setPriceAndAvailabilityModal] = useState<{
    isAvailable: number;
    price: number;
    isVisible: boolean;
    reason: string;
    rental: any;
    date: moment.Moment;
  }>({
    isAvailable: 0,
    price: 0,
    isVisible: false,
    reason: "OTHER",
    rental: null,
    date: moment(new Date()),
  });

  // START of scroll size management
  const [calendarWidth, setCalendarWidth] = useState<number>(0);
  const [diffWidthSidebar, setDiffWidthSidebar] = useState<number>(0);

  const [selectedRentals, setSelectedRentals] = useState<ValueType[]>([]);
  const [openedRentals, setOpenedRentals] = useState<ValueType[]>([]);

  const getCalendarHeight = () => {
    if (isCheckinPrice)
      return screen.height - CALENDAR_REMOVE_TO_END - CALENDAR_CHECKIN_BANNER;
    else return screen.height - CALENDAR_REMOVE_TO_END;
  };

  const divRef = useRef(null);
  const scrollableDivRef = useRef(null);

  const measureDivSize = useCallback(() => {
    const divElement = divRef.current;
    if (divElement) {
      // @ts-ignore
      const rect = divElement.getBoundingClientRect();
      const width = rect.width - 3;

      setCalendarWidth(width);
      const isClosed =
        localStorage.getItem("sidebar_closed") === "true" ? true : false;
      if (width > calendarWidth) {
        if (isClosed) {
          setDiffWidthSidebar(width + 192);
        } else {
          setDiffWidthSidebar(width - calendarWidth);
        }
      }
    }
  }, [calendarWidth]);

  useEffect(() => {
    measureDivSize();
    const handleResize = debounce(measureDivSize, 100);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSidebarOpen = (value: boolean) => {
    console.log("handleSidebarOpen");
    if (value) {
      setDiffWidthSidebar((prev) => prev + 192);
    } else setDiffWidthSidebar((prev) => prev - 192);
  };

  const fetchRentals = async ({
    page = 1,
    limit,
    from = currentFrom,
    to = currentTo,
    isFirstLoad = false,
    search = "",
    rental_ids = [],
  }: {
    page?: number;
    limit?: number;
    from?: moment.Moment;
    to?: moment.Moment;
    isFirstLoad?: boolean;
    search?: string;
    rental_ids?: ValueType[];
  }) => {
    setLoading(true);

    if (selectedWorkspaces && selectedWorkspaces.length === 0) {
      setRentals([]);
      return;
    }

    const params = {
      from: from.format("YYYY-MM-DD"),
      to: to.format("YYYY-MM-DD"),
      limit: limit ?? nbPerPage,
      page,
      search,
      rental_ids: rental_ids.toString(),
      workspace_id: formatWorkspaceParam(selectedWorkspaces),
    };

    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.CALENDAR}`,
      { params }
    );

    setLoading(false);

    if (response.data?.success) {
      //TODO : Load more bottom
      // const nextRentals = [...rentals, ...response.data?.result?.rentals];
      const nextRentals = response.data?.result?.rentals;

      setRentals(nextRentals);
      setLastPage(response.data?.result?.last_page);
      setCurrentPage(response.data?.result?.current_page);
      setTotal(response.data?.result?.total);
      setIsCheckinPrice(
        response.data?.result?.show_progression_checking_banner
      );

      console.log("isFirstLoad", isFirstLoad);
      if (isFirstLoad) {
        // @ts-ignore
        scrollableDivRef.current.scrollLeft =
          CALENDAR_CELL_WIDTH * CALENDAR_NB_DAYS_BEFORE +
          CALENDAR_CELL_WIDTH * moment(new Date()).diff(from, "days") -
          CALENDAR_CELL_WIDTH * 2; // Pour avoir 2 jours de marge avant
      }

      return true;
    }

    return false;
  };

  const debouncedLoadAfter = React.useRef(
    debounce(async (limit) => {
      console.log("LOAF AFTER FUNCTION");
      const nextTo = currentTo.add(10, "days");

      console.log("loadAfter limit : ", limit);

      fetchRentals({
        page: 1,
        limit,
        from: currentFrom,
        to: nextTo,
        search: currentSearch,
        rental_ids: selectedRentals,
      });

      setCurrentTo(nextTo);
    }, 300)
  ).current;

  React.useEffect(() => {
    return () => {
      debouncedLoadAfter.cancel();
    };
  }, [debouncedLoadAfter]);

  const debouncedLoadBefore = React.useRef(
    debounce(async (limit) => {
      const nbDaysToLoad = 10;

      const nextFrom = currentFrom.add(nbDaysToLoad * -1, "days");

      await fetchRentals({
        page: 1,
        limit,
        from: nextFrom,
        to: currentTo,
        search: currentSearch,
        rental_ids: selectedRentals,
      });

      // @ts-ignore
      scrollableDivRef.current.scrollLeft += nbDaysToLoad * CALENDAR_CELL_WIDTH;

      setCurrentFrom(nextFrom);
    }, 300)
  ).current;

  React.useEffect(() => {
    return () => {
      debouncedLoadBefore.cancel();
    };
  }, [debouncedLoadBefore]);

  const handleScroll = (e: any) => {
    const nbDays = currentTo.diff(currentFrom, "days");
    console.log("nbDays", nbDays);

    const bottom =
      e.target.scrollHeight - e.target.scrollTop <=
      e.target.clientHeight + CALENDAR_CELL_HEIGHT * (nbPerPage + 1);

    if (
      e.target.scrollLeft <=
      CALENDAR_CELL_WIDTH * (CALENDAR_NB_DAYS_BEFORE + 2)
    ) {
      console.log("DETECT LOAD BEFORE");
      debouncedLoadBefore(rentals.length);
    }

    if (e.target.scrollLeft > CALENDAR_CELL_WIDTH * (nbDays - 13)) {
      console.log("DETECT LOAD AFTER");
      debouncedLoadAfter(rentals.length);
    }

    if (bottom && !loading && (!lastPage || currentPage < lastPage)) {
      fetchRentals({
        page: currentPage + 1,
        search: currentSearch,
        rental_ids: selectedRentals,
      });
    }
  };

  const debouncedSearchRentals = React.useRef(
    debounce(async (nextSearch: string) => {
      const nextPage = 1;

      await fetchRentals({
        page: nextPage,
        limit: nbPerPage,
        from: currentFrom,
        to: currentTo,
        search: nextSearch,
        rental_ids: selectedRentals,
      });

      setCurrentPage(nextPage);
      setLoadingSearch(false);
    }, 300)
  ).current;

  const debouncedFilterRentals = React.useRef(
    debounce(async (ids: ValueType[]) => {
      const nextPage = 1;

      await fetchRentals({
        page: nextPage,
        limit: nbPerPage,
        from: currentFrom,
        to: currentTo,
        search: currentSearch,
        rental_ids: ids,
      });

      setCurrentPage(nextPage);
      setLoadingSearch(false);
    }, 300)
  ).current;

  const handleSearchRentals = async (nextSearch: string) => {
    setLoadingSearch(true);

    debouncedSearchRentals(nextSearch);

    setCurrentSearch(nextSearch);
  };

  useEffect(() => {
    const nbRentalsOnScreenHeight = Math.ceil(
      getCalendarHeight() / CALENDAR_CELL_HEIGHT
    );

    setNbPerPage(nbRentalsOnScreenHeight);

    fetchRentals({
      limit: nbRentalsOnScreenHeight,
      isFirstLoad: true,
      search: currentSearch,
      rental_ids: selectedRentals,
    });
  }, []);

  const handleReservationClick = (value: CalendarReservationResponse) => {
    setReservationVisible(true);
    setReservation(value);
  };

  const handleFilterRentals = async (ids: ValueType[]) => {
    setLoadingSearch(true);

    debouncedFilterRentals(ids);

    setSelectedRentals(ids);
  };

  const handleToggleAllRentals = () => {
    if (openedRentals.length === 0) {
      setOpenedRentals(rentals?.map((rental) => rental.id));
    } else {
      setOpenedRentals([]);
    }
  };

  const handleOpenOneRental = (id: ValueType) => {
    const openIndex = openedRentals.findIndex((open) => open === id);

    if (openIndex === -1) setOpenedRentals([...openedRentals, id]);
    else setOpenedRentals(openedRentals.filter((open) => open !== id));
  };

  const handleScrollLeft = () => {
    // @ts-ignore
    const scrollXPosition = scrollableDivRef.current.scrollLeft;

    // @ts-ignore
    scrollableDivRef.current.scrollTo({
      left: scrollXPosition - CALENDAR_CELL_WIDTH * 7,
      behavior: "smooth",
    });
  };

  const handleScrollRight = () => {
    // @ts-ignore
    const scrollXPosition = scrollableDivRef.current.scrollLeft;

    // @ts-ignore
    scrollableDivRef.current.scrollTo({
      left: scrollXPosition + CALENDAR_CELL_WIDTH * 7,
      behavior: "smooth",
    });
  };

  const handleMinNightsClick = (
    rental: any,
    date: moment.Moment,
    value: string
  ) => {
    if (
      !getCurrentCanEdit({
        user: userStore.user,
        workspaceOwner: rental.workspace_owner,
      })
    )
      return;

    setMinNightsModal({
      isVisible: true,
      rental,
      date,
      minNights: Number(value),
    });
  };

  const handleMaxNightsClick = (
    rental: any,
    date: moment.Moment,
    value: string
  ) => {
    if (
      !getCurrentCanEdit({
        user: userStore.user,
        workspaceOwner: rental.workspace_owner,
      })
    )
      return;

    setMaxNightsModal({
      isVisible: true,
      rental,
      date,
      maxNights: Number(value),
    });
  };

  const handleNoCheckinCheckoutClick = (
    rental: any,
    date: moment.Moment,
    noCheckin: number,
    noCheckout: number
  ) => {
    if (
      !getCurrentCanEdit({
        user: userStore.user,
        workspaceOwner: rental.workspace_owner,
      })
    )
      return;

    setNoCheckinCheckoutModal({
      isVisible: true,
      rental,
      date,
      noCheckin,
      noCheckout,
    });
  };

  const handlePriceAndAvailability = (
    rental: any,
    date: moment.Moment,
    isAvailable: number,
    price: number,
    reason: string
  ) => {
    if (
      !getCurrentCanEdit({
        user: userStore.user,
        workspaceOwner: rental.workspace_owner,
      })
    )
      return;

    setPriceAndAvailabilityModal({
      isVisible: true,
      rental,
      date,
      isAvailable,
      reason,
      price,
    });
  };

  const hotUpdateCalendar = ({
    startDate,
    endDate,
    rentalIds,
    weekDays,
    minNights,
    maxNights,
    noCheckin,
    noCheckout,
    price,
    isAvailable,
    reason,
  }: {
    startDate: string;
    endDate: string;
    rentalIds: ValueType[];
    weekDays: string;
    minNights?: number;
    maxNights?: number;
    noCheckin?: number;
    noCheckout?: number;
    price?: number;
    isAvailable?: number;
    reason?: string;
  }) => {
    const nextRentals = rentals;

    const dates = getDatesBetweenDates(startDate, endDate);

    rentalIds.forEach((rentalId) => {
      const rentalIndex = rentals.findIndex((r) => r.id === rentalId);
      const rental = rentals[rentalIndex];

      dates.forEach((date) => {
        if (weekDays.includes(moment(date).toDate().getDay().toString())) {
          const restrictionIndex = rental.restrictions.findIndex(
            (re: any) => re.date === date
          );

          if (minNights !== undefined)
            nextRentals[rentalIndex].restrictions[restrictionIndex].min_nights =
              minNights;

          if (maxNights !== undefined)
            nextRentals[rentalIndex].restrictions[restrictionIndex].max_nights =
              maxNights;

          if (noCheckin !== undefined)
            nextRentals[rentalIndex].restrictions[restrictionIndex].no_checkin =
              noCheckin;

          if (noCheckout !== undefined)
            nextRentals[rentalIndex].restrictions[
              restrictionIndex
            ].no_checkout = noCheckout;

          if (price !== undefined)
            nextRentals[rentalIndex].restrictions[restrictionIndex].price =
              price;

          if (isAvailable !== undefined)
            nextRentals[rentalIndex].restrictions[
              restrictionIndex
            ].is_available = isAvailable;

          if (reason !== undefined)
            nextRentals[rentalIndex].restrictions[
              restrictionIndex
            ].unavailability_reason = reason;
        }
      });
    });

    setRentals(nextRentals);
  };

  const handleSuccessUpdateMinNights = (
    startDate: string,
    endDate: string,
    weekDays: string,
    rentalIds: ValueType[],
    minNights: number
  ) => {
    setMinNightsModal({
      isVisible: false,
      rental: null,
      date: moment(new Date()),
      minNights: 0,
    });

    hotUpdateCalendar({ startDate, endDate, rentalIds, weekDays, minNights });
  };

  const handleSuccessUpdateMaxNights = (
    startDate: string,
    endDate: string,
    weekDays: string,
    rentalIds: ValueType[],
    maxNights: number
  ) => {
    setMaxNightsModal({
      isVisible: false,
      rental: null,
      date: moment(new Date()),
      maxNights: 0,
    });

    hotUpdateCalendar({ startDate, endDate, rentalIds, weekDays, maxNights });
  };

  const handleSuccessUpdateNoCheckinCheckout = (
    startDate: string,
    endDate: string,
    weekDays: string,
    rentalIds: ValueType[],
    noCheckin: number,
    noCheckout: number
  ) => {
    setNoCheckinCheckoutModal({
      isVisible: false,
      rental: null,
      date: moment(new Date()),
      noCheckin: 0,
      noCheckout: 0,
    });

    hotUpdateCalendar({
      startDate,
      endDate,
      rentalIds,
      weekDays,
      noCheckin,
      noCheckout,
    });
  };

  const handleSuccessUpdatePrice = (
    startDate: string,
    endDate: string,
    weekDays: string,
    rentalIds: ValueType[],
    price: number
  ) => {
    setPriceAndAvailabilityModal({
      isVisible: false,
      rental: null,
      date: moment(new Date()),
      isAvailable: 0,
      price: 0,
      reason: "",
    });

    hotUpdateCalendar({
      startDate,
      endDate,
      rentalIds,
      weekDays,
      price,
    });
  };

  const handleSuccessUpdateAvailability = (
    startDate: string,
    endDate: string,
    weekDays: string,
    rentalIds: ValueType[],
    isAvailable: number,
    reason: string
  ) => {
    setPriceAndAvailabilityModal({
      isVisible: false,
      rental: null,
      date: moment(new Date()),
      isAvailable: 0,
      price: 0,
      reason: "",
    });

    hotUpdateCalendar({
      startDate,
      endDate,
      rentalIds,
      weekDays,
      isAvailable,
      reason,
    });
  };

  const hotUpdateReservationStatus = ({
    rentalId,
    reservationId,
    status,
    restrictions,
  }: {
    reservationId: ValueType | undefined;
    rentalId: ValueType | undefined;
    status: ReservationStatusEnum;
    restrictions: CalendarRestrictionResponse[];
  }) => {
    // Update reservation in modal
    if (reservation) {
      setReservation({ ...reservation, status_reservation: status });
    }

    // Prepare removing reservation in Calendar
    const rentalIndex = rentals?.findIndex((r) => r.id === rentalId);
    if (rentalIndex < 0) return;

    const resaIndex = rentals[rentalIndex].reservations?.findIndex(
      (r: any) => r.id === reservationId
    );

    if (resaIndex < 0) return;

    const nextRentals = JSON.parse(JSON.stringify(rentals));
    nextRentals[rentalIndex].reservations.splice(resaIndex, 1);

    // Prepare updating restrictions in Calendar
    restrictions.forEach((newRestriction) => {
      let baseRestriction = nextRentals[rentalIndex].restrictions.find(
        (restriction: CalendarRestrictionResponse) =>
          restriction.date === newRestriction.date
      );
      if (baseRestriction) {
        Object.assign(baseRestriction, newRestriction);
      }
    });

    // Push updates in state
    setRentals(nextRentals);
  };

  return (
    <>
      <CalendarReservationModal
        isVisible={reservationVisible}
        reservation={reservation}
        onChangeStatusResa={hotUpdateReservationStatus}
        onClose={() => setReservationVisible(false)}
      />

      <CalendarMinNightsModal
        {...minNightsModal}
        onSuccess={handleSuccessUpdateMinNights}
        onClose={() =>
          setMinNightsModal({
            isVisible: false,
            rental: null,
            date: moment(new Date()),
            minNights: 0,
          })
        }
      />

      <CalendarMaxNightsModal
        {...maxNightsModal}
        onSuccess={handleSuccessUpdateMaxNights}
        onClose={() =>
          setMaxNightsModal({
            isVisible: false,
            rental: null,
            date: moment(new Date()),
            maxNights: 0,
          })
        }
      />

      <CalendarNoCheckinCheckoutModal
        {...noCheckinCheckoutModal}
        onSuccess={handleSuccessUpdateNoCheckinCheckout}
        onClose={() =>
          setNoCheckinCheckoutModal({
            isVisible: false,
            rental: null,
            date: moment(new Date()),
            noCheckin: 0,
            noCheckout: 0,
          })
        }
      />

      <CalendarPriceAndAvailabilityModal
        date={priceAndAvailabilityModal.date}
        isAvailable={priceAndAvailabilityModal.isAvailable}
        isVisible={priceAndAvailabilityModal.isVisible}
        price={priceAndAvailabilityModal.price}
        reason={
          priceAndAvailabilityModal.reason as CalendarRestrictionUnavailabilityReasonEnum
        }
        rental={priceAndAvailabilityModal.rental}
        onSuccessPrice={handleSuccessUpdatePrice}
        onSuccessAvailability={handleSuccessUpdateAvailability}
        onClose={() =>
          setPriceAndAvailabilityModal({
            isVisible: false,
            isAvailable: 0,
            price: 0,
            rental: null,
            reason: "OTHER",
            date: moment(new Date()),
          })
        }
      />

      <CalendarLayout
        hasRentals={Boolean(rentals.length > 0)}
        navbarActiveItem="week"
        isCheckinPrice={isCheckinPrice}
        onSidebarOpen={handleSidebarOpen}
        displayBy={displayBy}
        onSelectDisplayBy={setDisplayBy}
        noRightButtons={
          !loadingSearch &&
          !loading &&
          currentSearch === "" &&
          rentals?.length === 0
        }
      >
        {!loadingSearch &&
          !loading &&
          currentSearch === "" &&
          rentals?.length === 0 && <NoRentalRentalList />}

        {(loading ||
          loadingSearch ||
          rentals?.length > 0 ||
          currentSearch !== "") && (
          <div
            id="calendar"
            className="relative mt-4 overflow-y-scroll border-1 border-element-border rounded-8px"
            ref={divRef}
          >
            <CalendarArrowToScroll
              onLeft={handleScrollLeft}
              onRight={handleScrollRight}
            />

            <div
              className="flex flex-row overflow-x-scroll "
              style={{
                width: diffWidthSidebar,
                maxHeight: getCalendarHeight(),
              }}
              ref={scrollableDivRef}
              onScroll={handleScroll}
            >
              <table className="relative">
                {/* Table Header */}
                <CalendarHeader
                  from={currentFrom}
                  to={currentTo}
                  total={total}
                  loading={loading}
                  daysOffset={moment(new Date()).diff(currentFrom, "days")}
                  search={currentSearch}
                  selectedRentals={selectedRentals}
                  onSelectRentals={handleFilterRentals}
                  upToOneRentalOpen={openedRentals.length > 0}
                  onSearchTextChange={handleSearchRentals}
                  onToggleAllRentals={handleToggleAllRentals}
                />

                {/* All rental lines when no loading search */}
                {!loadingSearch &&
                  rentals.map((rental, index) => (
                    <CalendarRentalLine
                      key={rental.id ?? index}
                      rental={rental}
                      from={currentFrom}
                      to={currentTo}
                      daysOffset={moment(new Date()).diff(currentFrom, "days")}
                      restrictionVisible={
                        openedRentals.findIndex(
                          (open) => open === rental.id
                        ) !== -1
                      }
                      displayBy={displayBy}
                      onToggleOpen={() => handleOpenOneRental(rental.id)}
                      onReservationClick={handleReservationClick}
                      onDateClick={handlePriceAndAvailability}
                      onMinNightsClick={handleMinNightsClick}
                      onMaxNightsClick={handleMaxNightsClick}
                      onNoCheckinCheckoutClick={handleNoCheckinCheckoutClick}
                    />
                  ))}

                {/* All rental lines during loading search */}
                {loadingSearch &&
                  rentals.map(() => (
                    <CalendarRentalLineSkeleton
                      from={currentFrom}
                      to={currentTo}
                      daysOffset={moment(new Date()).diff(currentFrom, "days")}
                    />
                  ))}

                {/* Skeleton loading line */}
                {total !== 0 &&
                  (!lastPage || currentPage < lastPage) &&
                  Array.from({ length: nbPerPage }).map(() => (
                    <CalendarRentalLineSkeleton
                      from={currentFrom}
                      to={currentTo}
                      daysOffset={moment(new Date()).diff(currentFrom, "days")}
                    />
                  ))}

                {/* {Array.from({ length: nbPerPage }).map(() => (
                  <CalendarRentalLineSkeleton />
                ))} */}
              </table>
            </div>
          </div>
        )}
      </CalendarLayout>
    </>
  );
};
