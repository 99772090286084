import React from "react";
import { IconType, ValueType } from "../../../types/commonTypes";

type ValuePlaceholderProps = {
  Icon?: IconType;
  value?: ValueType;
  size?: "small" | "medium" | "large";
  bgClass?: string;
  colorClass?: string;
};

export const ValuePlaceholder: React.FC<ValuePlaceholderProps> = ({
  Icon,
  value = "Valeur manquante",
  size = "medium",
  bgClass = "bg-gray-200",
  colorClass = "text-high-contrast",
}) => {
  const getSize = (): {
    container: string;
    icon: { width: number; height: number };
  } => {
    let value: {
      container: string;
      icon: { width: number; height: number };
    } = {
      container: "text-sm px-2 py-1",
      icon: { width: 20, height: 20 },
    };
    switch (size) {
      case "small":
        value = {
          container: "text-xs px-1.5 py-1",
          icon: { width: 15, height: 15 },
        };
        return value;
      case "medium":
        value = {
          container: "text-sm px-2 py-1",
          icon: { width: 20, height: 20 },
        };
        return value;
      case "large":
        value = {
          container: "text-lg px-2 py-2",
          icon: { width: 20, height: 20 },
        };
        return value;
    }
  };

  return (
    <div
      className={`flex items-center ${
        getSize().container
      } flex-1 space-x-1 ${bgClass} w-max rounded-6px leading-4`}
    >
      {Icon && (
        <Icon width={getSize().icon.width} height={getSize().icon.height} />
      )}
      <p className={`font-normal ${colorClass}`}>{value}</p>
    </div>
  );
};
