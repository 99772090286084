const rentalCategories = [
  {
    id: 1,
    slug: "apartments",
    fr: "Appartement",
    en: "Apartment",
    es: "Apartamento",
    pt: "Apartamento",
    it: "Appartamento",
  },
  {
    id: 2,
    slug: "houses",
    fr: "Maison",
    en: "House",
    es: "Casa",
    pt: "Casa",
    it: "Casa",
  },
  {
    id: 3,
    slug: "secondary_units",
    fr: "Unités secondaires",
    en: "Secondary units",
    es: "Unidades secundarias",
    pt: "Unidades secundÃ¡rias",
    it: "UnitÃ  secondarie",
  },
  {
    id: 4,
    slug: "unique_homes",
    fr: "Logements uniques",
    en: "Unique Homes",
    es: "Hogares Ãºnicos",
    pt: "Casas Ãºnicas",
    it: "Case uniche",
  },
  {
    id: 5,
    slug: "bnb",
    fr: "BNB",
    en: "BNB",
    es: "BNB",
    pt: "BNB",
    it: "BNB",
  },
  {
    id: 6,
    slug: "boutique_hotels_and_more",
    fr: "Autres",
    en: "Quiz",
    es: "Otros",
    pt: "Outros",
    it: "Altri",
  },
];

export default rentalCategories;
