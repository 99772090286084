import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import FileTextIcon from "../../../assets/icons/file-text.svg?react";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import paths from "../../../constants/paths";
import { post } from "../../../helpers/APIHelper";
import { Card } from "../../Common/Card/Card";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { ReservationNotePayload, ReservationNoteProps } from "./NoteCard.type";
import { ReservationNoteCardSkeleton } from "./NoteCardSkeleton";

export const ReservationNoteCard: React.FC<ReservationNoteProps> = ({
  loading,
  reservation,
  disabled,
  onUpdate,
}) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    getValues,
    setValue,
  } = useForm<{
    privateNotes: string;
  }>({
    mode: "all",
  });

  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    setValue("privateNotes", reservation?.private_notes ?? "");
  }, [reservation]);

  const updateNote = async () => {
    if (reservation !== undefined) {
      setValidationLoading(true);

      const payload: ReservationNotePayload = {
        private_notes: getValues("privateNotes"),
      };

      const response = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.RESERVATIONS}/${
          reservation?.id
        }`,
        {
          _method: "PUT",
          ...payload,
        }
      );

      if (response.data?.success) {
        setEditMode(false);
        onUpdate({
          private_notes: getValues("privateNotes"),
        });
      } else {
        setError(response.data?.message ?? response.message);
      }
      setValidationLoading(false);
    }
  };

  const handleNoteEdit = () => {
    updateNote();
  };

  const noteRef = useRef<HTMLTextAreaElement | null>(null);
  const { ref, ...restNoteRegister } = register("privateNotes", {
    required: {
      value: false,
      message: t("Global.Errors.requiredField", {
        fieldName: t("Booking.Note.title"),
      }),
    },
  });

  useEffect(() => {
    if (editMode) {
      if (noteRef.current) {
        noteRef.current.style.height = noteRef.current.scrollHeight + "px";
      }
    }
  }, [editMode, noteRef.current]);

  if (loading) return <ReservationNoteCardSkeleton />;

  return (
    <Card
      Icon={FileTextIcon}
      label={t("Booking.Note.title")}
      loading={validationLoading}
      hasBody={editMode || Boolean(getValues("privateNotes"))}
      button={{
        Icon: editMode
          ? CheckIcon
          : Boolean(getValues("privateNotes"))
          ? EditIcon
          : PlusIcon,
        type: editMode ? "primary" : "secondary",
        disabled: (editMode && (!isValid || validationLoading)) || disabled,
        label: editMode
          ? t("Global.record")
          : Boolean(getValues("privateNotes"))
          ? t("Global.edit")
          : t("Global.add"),
        onClick: editMode
          ? handleSubmit(handleNoteEdit)
          : () => setEditMode(true),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => setEditMode(false),
            }
          : undefined
      }
    >
      <ErrorMessage>{error}</ErrorMessage>
      {editMode ? (
        <div className="flex flex-col gap-2">
          <p className="mt-2 text-base text-low-contrast">
            {t("Booking.Note.label")}
          </p>
          <textarea
            ref={(e) => {
              ref(e);
              noteRef.current = e;
            }}
            className="w-full p-2 rounded resize-y text-hight-contrast border-1 border-element-border focus:outline-none h-max"
            disabled={validationLoading}
            {...restNoteRegister}
          ></textarea>
          <ErrorMessage errorType="FORM">
            {errors.privateNotes?.message}
          </ErrorMessage>
        </div>
      ) : (
        <p className="text-low-contrast">{getValues("privateNotes")}</p>
      )}
    </Card>
  );
};
