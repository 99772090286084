import { UseFormGetValues } from "react-hook-form";
import paths from "../../constants/paths";
import { post } from "../../helpers/APIHelper";
import { EditPlatform } from "../../pages/Settings/Settings.type";
import { ValueType } from "../../types/commonTypes";
import { UserResponse } from "../../types/GETTypes";

/**
 * Updates the user lead with the provided lead ID and form values.
 *
 * @param {ValueType} leadId - The ID of the lead to be updated.
 * @param {UseFormGetValues<EditPlatform>} values - Function to get form values.
 * @param {function} onSuccess - Callback function to be called on successful update.
 * @param {function} onError - Callback function to be called on error.
 * @param {function} [onStart] - Optional callback function to be called at the start of the update process.
 * @param {function} [onEnd] - Optional callback function to be called at the end of the update process.
 *
 * @returns {Promise<void>} - A promise that resolves when the update process is complete.
 */
export const useUpdateUserLead = async (
  leadId: ValueType,
  values: UseFormGetValues<EditPlatform>,
  onSuccess: (user: UserResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
): Promise<void> => {
  if (typeof onStart === "function") {
    onStart();
  }

  try {
    const platformList =
      typeof values("platforms").platforms !== "undefined" &&
      typeof values("platforms").others !== "undefined"
        ? values("platforms").platforms.concat(values("platforms").others)
        : typeof values("platforms").platforms !== "undefined" &&
          typeof values("platforms").others === "undefined"
        ? values("platforms").platforms
        : typeof values("platforms").platforms === "undefined" &&
          typeof values("platforms").others !== "undefined"
        ? values("platforms").others
        : values("platforms");

    const typeList = Object.entries(values("types"))
      .filter(([, value]) => value === true)
      .map(([key]) => key);

    const response = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.USER.USER_LEAD_UPDATE
      }/${leadId}`,
      {
        platforms: platformList,
        types: typeList,
      }
    );

    if (response?.data?.success) {
      onSuccess(response?.data.result);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};
