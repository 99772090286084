import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ValueType } from "../../types/commonTypes";
import { CenterModal } from "../Common/CenterModal/CenterModal";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";
import { Button } from "../Common/Button/Button";
import paths from "../../constants/paths";
import { post } from "../../helpers/APIHelper";
import { ReservationStatusEnum } from "../../enums/GETenums";
import { SimpleRadio } from "../Common/SimpleRadio/SimpleRadio";
import {
  CalendarRestrictionResponse,
  ReservationResponse,
} from "../../types/GETTypes";

export const ResaCancelModal: React.FC<{
  isVisible: boolean;
  reservationId: ValueType | undefined | null;
  onClose: () => void;
  onSuccess: (
    nextStatus: ReservationStatusEnum,
    nextRestrictions: CalendarRestrictionResponse[]
  ) => void;
}> = ({ isVisible, reservationId, onClose, onSuccess }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [isCanceledByGuest, setIsCanceledByGuest] = useState<boolean>(true);

  const handleClose = () => {
    if (loading) return;

    setError(null);
    onClose();
  };

  const handlePerformCancel = async () => {
    setError(null);
    setLoading(true);

    const URL = `${import.meta.env.VITE_API_URL}${
      paths.API.RESERVATION.CANCEL
    }/${reservationId}`;

    const res = await post(URL, {
      is_canceled_by_traveler: isCanceledByGuest ? 1 : 0,
    });

    if (res.data?.success) {
      const newResa: ReservationResponse = res.data.result.reservation;

      onSuccess(newResa.status_reservation, res.data?.result?.restrictions);
    } else {
      setError(res.response.data.message);
    }

    setLoading(false);
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={handleClose}
      title={t("Calendar.CancelResaModal.title")}
      classNames={{divParentModal:"z-[999]"}}
    >
      <p className="text-low-contrast font-light text-sm">
        {t("Calendar.CancelResaModal.content1")}
      </p>

      <p className="text-low-contrast font-light text-sm mt-2">
        {t("Calendar.CancelResaModal.content3")}
      </p>

      <div
        className="flex flex-row gap-2 text-sm mt-4 items-center cursor-pointer"
        onClick={() => setIsCanceledByGuest(true)}
      >
        <SimpleRadio value={isCanceledByGuest} />
        <div className="flex flex-col gap-1">
          <p className="text-high-contrast font-semibold">
            {t("Calendar.CancelResaModal.ByGuest.title")}
          </p>
          <p className="text-low-contrast font-light">
            {t("Calendar.CancelResaModal.ByGuest.content")}
          </p>
        </div>
      </div>

      <div
        className="flex flex-row gap-2 text-sm mt-2 items-center cursor-pointer"
        onClick={() => setIsCanceledByGuest(false)}
      >
        <SimpleRadio value={!isCanceledByGuest} />
        <div className="flex flex-col gap-1">
          <p className="text-high-contrast font-semibold">
            {t("Calendar.CancelResaModal.ByHost.title")}
          </p>
          <p className="text-low-contrast font-light">
            {t("Calendar.CancelResaModal.ByHost.content")}
          </p>
        </div>
      </div>

      <ErrorMessage>{error}</ErrorMessage>

      <div className="flex flex-row gap-2 pt-4">
        <Button type="secondary" onClick={handleClose} disabled={loading}>
          {t("Global.back")}
        </Button>
        <Button loading={loading} type="primary" onClick={handlePerformCancel}>
          {t("Calendar.CancelResaModal.button")}
        </Button>
      </div>
    </CenterModal>
  );
};
