import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import RightWhiteArrowIcon from "../../../../assets/icons/arrow-right-white.svg?react";
import paths from "../../../../constants/paths";
import {Button} from "../../../Common/Button/Button";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {InputSelect} from "../../../Common/InputSelect/InputSelect";
import {Controller, UseFormReturn} from "react-hook-form";
import {get} from "../../../../helpers/APIHelper";

export type AirbnbSynchronizeForm = {
    account_id: string;
    listing_id: string;
};

export const AirbnbSynchronizeStepAccount: React.FC<{
    form:UseFormReturn<AirbnbSynchronizeForm, any, undefined>,
    apiError: string | null;
    formError: string | null;
    onNext: () => void;
    selectAccount: any;
}> = ({
          form,
          apiError,
          formError,
          onNext = () => {},
          selectAccount
      }) => {
    const {t} = useTranslation();
    form.watch()
    const [listings, setListings] = useState<[{}]>([{}]);


    const fetchAirbnbAccount= async () => {
        const res = await get(
            `${import.meta.env.VITE_API_URL}${
                paths.API.AIRBNB.ACCOUNT_LISTINGS
            }`
        );
        if (res?.data?.success) {
           const  accounts = res?.data?.result;
           let dataAccount = [];
           accounts.map((account: any) => {
               const data = {
                   value:account.id,
                   label:account.email
               }
               dataAccount.push(data)
           })
            setListings(dataAccount);
        }
    };

    useEffect(() => {
        selectAccount(form.getValues("account_id"));
    }, [form.watch("account_id")]);

    useEffect(() => {
        fetchAirbnbAccount();
        }, []);

    return (
        <>
            <ErrorMessage>{apiError}</ErrorMessage>
            <Controller
                control={form.control}
                name="account_id"
                rules={{
                    required:true
                }}
                render={({ field: { onChange, value } }) => (
                    <InputSelect
                        label={t( "Rental.Infos.Platforms.SynchronizeModal.Airbnb.airbnbSynchronizeStep1SelectTitle")}
                        required={true}
                        items={listings}
                        onSelect={onChange}
                        selectedValue={value}
                    />
                )}
            />
            {/* Errors */}
            <ErrorMessage errorType="FORM">{formError}</ErrorMessage>
            <div className="mt-6">
                <Button
                    RightIcon={RightWhiteArrowIcon}
                    disabled={form.getValues("account_id") ? false : true}
                    onClick={onNext}
                >
                    {t("Global.next")}
                </Button>
            </div>
        </>
    );
};
