import React, { useEffect, useState } from "react";
import { DashboardPageProps } from "./DashboardPage.type";
import { Button } from "../../components/Common/Button/Button";
import { useTranslation } from "react-i18next";
import { Separator } from "../../components/Common/Separator/Separator";
import { Title } from "../../components/Common/Title/Title";
import { RightModal } from "../../components/Common/RightModal/RightModal";
import { HistoricalStep } from "../../components/Common/HistoricalStep/HistoricalStep";
import { useNavigate } from "react-router-dom";
import paths from "../../constants/paths";
import moment from "moment";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";

import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import BookingIcon from "../../assets/icons/booking.svg?react";
import UsersIcon from "../../assets/icons/users.svg?react";
import EmojiHappyIcon from "../../assets/icons/emoji-happy.svg?react";
import EyeIcon from "../../assets/icons/eye.svg?react";
import CheckIcon from "../../assets/icons/check-success.svg?react";
import StarFilledIcon from "../../assets/icons/star-filled.svg?react";
import StarHalfIcon from "../../assets/icons/star-half.svg?react";
import ClockIcon from "../../assets/icons/clock.svg?react";
import MessageIcon from "../../assets/icons/message.svg?react";
import SettingsIcon from "../../assets/icons/settings.svg?react";
import LightBulbIcon from "../../assets/icons/light-bulb.svg?react";
import UserAvatar from "../../assets/images/user-avatar.svg?react";
import Rental1Thumbnail from "../../assets/images/rental1.webp";
import MaritzImg from "../../assets/images/maritz.png";
import { InfoModal } from "../../components/Modal/InfoModal/InfoModal";

import resBookings from "../../res/bookings";
import resRentals from "../../res/rentals";
import { useGetRentals } from "../../hooks/useGetRentals";
import {InProgressPlaceholder} from "../../components/Common/Dashboard/InProgressPlaceholder";

export const DashboardPage: React.FC<DashboardPageProps> = () => {
  const { t } = useTranslation();

  const [isBookingModalVisible, setBookingModalVisible] =
    useState<boolean>(false);
  const [isInfoVisible, setInfoVisible] = useState<boolean>(false);

  const [bookings, setBookings] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentNbPerPage, setCurrentNbPerPage] = useState<number>(5);

  const [isCheckin, setIsCheckin] = useState<boolean>(true);

  useEffect(() => {
    const filteredBookings = resBookings.filter(
      (b) =>
        moment(b.checkin_date, "DD/MM/YYYY") >= moment("2023-09-01") &&
        moment(b.checkin_date, "DD/MM/YYYY") <= moment("2023-09-30")
    );

    const result: any[] = [];
    filteredBookings.map((b, index) => {
      if (
        index >= (currentPage - 1) * currentNbPerPage &&
        index < currentPage * currentNbPerPage
      ) {
        // @ts-ignore
        b.rental = resRentals.find((r) => r.id === b.rental_id);
        result.push(b);
      }
    });
    setBookings(result);
  }, [currentPage, currentNbPerPage, isCheckin]);

  return (
    <>
      <BookingDetailsModal
        isVisible={isBookingModalVisible}
        onClose={() => setBookingModalVisible(false)}
      />

      <InfoModal
        isVisible={isInfoVisible}
        onClose={() => setInfoVisible(false)}
        title={"Informations"}
        content={"General info"}
      />

      <MainLayout title={t("Dashboard.title")} sidebarActiveItem="dashboard">
      <InProgressPlaceholder/>
      </MainLayout>
    </>
  );
};

type BookingDetailsModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

const BookingDetailsModal: React.FC<BookingDetailsModalProps> = ({
  isVisible,
  onClose = () => {},
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickBookingDetails = () => {
    navigate(`${paths.RESERVATIONS}/123`);
  };

  const handleClickSendMessage = () => {
    navigate(`${paths.RESERVATIONS}/123/messages`);
  };

  return (
    <RightModal
      isVisible={isVisible}
      onClose={onClose}
      title={t("Dashboard.titleCheckinDetails")}
    >
      <div className="flex flex-col">
        <div className="flex flex-row gap-4">
          <div className="relative">
            <img
              src={UserAvatar}
              className="w-12 h-12 rounded-full object-cover"
            />
            <BookingIcon
              className="absolute left-8 top-8"
              width={20}
              height={20}
            />
          </div>

          <div className="flex-1">
            <p className="text-high-contrast font-semibold">Elie El Shami</p>
            <p className="text-low-contrast font-light">
              {t("Dashboard.booking")} Booking.com{" "}
              {t("Dashboard.from").toLowerCase()} mardi 8 juillet{" "}
              {t("Dashboard.to")} vendredi 11 juillet 2023
            </p>
          </div>
        </div>

        <div className="flex flex-row gap-3 mt-4">
          <Button type="secondary" onClick={handleClickBookingDetails}>
            {t("Dashboard.bookingDetails")}
          </Button>
          <Button onClick={handleClickSendMessage}>
            {t("Dashboard.sendMessage")}
          </Button>
        </div>

        <div className="mt-4">
          <Separator />

          <div className="grid grid-cols-2">
            <div>
              <p className="font-light text-low-contrast">
                {t("Dashboard.email")}
              </p>
              <p className="font-semibold text-high-contrast">
                elie.alshami@gmail.com
              </p>
            </div>

            <div>
              <p className="font-light text-low-contrast">
                {t("Dashboard.phone")}
              </p>
              <p className="font-semibold text-high-contrast">
                +44 58 13 21 34
              </p>
            </div>
          </div>

          <Separator />

          <div className="grid grid-cols-2">
            <div>
              <p className="font-light text-low-contrast">
                {t("Dashboard.timeZone")}
              </p>
              <p className="font-semibold text-high-contrast">GMT+1 (08:37)</p>
            </div>
            <div>
              <p className="font-light text-low-contrast">
                {t("Dashboard.rate")}
              </p>
              <div className="flex flex-row items-center">
                <p className="font-semibold text-high-contrast mr-1">4.8/5</p>
                <StarFilledIcon />
                <StarFilledIcon />
                <StarFilledIcon />
                <StarFilledIcon />
                <StarHalfIcon />
              </div>
            </div>
          </div>

          <Separator />

          <div className="flex flex-row gap-3">
            <img
              src={Rental1Thumbnail}
              alt="Rental thumbnail"
              className="w-10 h-10 rounded-4px border-1 border-element-border/50"
            />
            <div className="flex flex-col">
              <p className="text-high-contrast font-semibold">
                Appartement Le Marais
              </p>
              <p className="text-low-contrast font-light">
                16 rue Charlemagne 75004 Paris
              </p>
            </div>
          </div>

          <Separator />

          <div className="grid grid-cols-2">
            <div>
              <p className="font-light text-low-contrast">
                {t("Dashboard.checkinDate")}
              </p>
              <p className="font-semibold text-high-contrast">
                Mardi 8 juillet 2023
              </p>
            </div>

            <div>
              <p className="font-light text-low-contrast">
                {t("Dashboard.checkinTime")}
              </p>
              <p className="font-semibold text-high-contrast">15:00</p>
            </div>
          </div>

          <Separator />
        </div>

        <div className="mt-3">
          <div className="flex flex-row items-center">
            <div className="w-10 pl-2">
              <MessageIcon />
            </div>
            <p className="text-high-contrast font-semibold">
              {t("Dashboard.scheduledMessages")}
            </p>
          </div>

          <div className="mt-4">
            <HistoricalStep
              title="Partager les informations avant l'arrivée"
              description="hier, à 19:00"
              Icon={CheckIcon}
              type="success"
            />

            <HistoricalStep
              title="Demande d’avis juste après le départ"
              description="Programmé pour le 06/11/2022 à 13:00"
              Icon={ClockIcon}
              isLast={true}
            />
          </div>
        </div>

        <Separator />

        <div className="mt-3 pb-4">
          <div className="flex flex-row items-center">
            <div className="w-10 pl-2">
              <SettingsIcon />
            </div>
            <p className="text-high-contrast font-semibold">
              {t("Dashboard.operational")}
            </p>
          </div>

          <div className="mt-4">
            <HistoricalStep
              title="Assigner le ménage"
              description="Hier, à 22:00 — validé par K. Maritz"
              Icon={CheckIcon}
              type="success"
            />

            <HistoricalStep
              title="Transmettre les instructions de ménage"
              description="À faire avant 17:00 aujourd’hui"
              Icon={ClockIcon}
              isLast={true}
            />
          </div>
        </div>
      </div>
    </RightModal>
  );
};

type DashboardTipsProps = {};

const DashboardTips: React.FC<DashboardTipsProps> = () => {
  const { t } = useTranslation();

  const tips: DashboardTipsItemProps[] = [
    {
      title: "Automatisez l’envoi de vos message",
      description: "de l’arrivée au départ des voyageurs",
      url: "#",
      linkLabel: "Activer",
    },

    {
      title: "Installez une serrure connectée",
      description: "et gagnez en autonomie",
      url: "#",
      linkLabel: "Découvrir",
    },

    {
      title: "Créez un guide de bienvenue",
      description: "partagez tous vos conseils et adresses",
      url: "#",
      linkLabel: "Découvrir",
    },

    {
      title: "Automatisez vos message 2",
      description: "de l’arrivée au départ des voyageurs",
      url: "#",
      linkLabel: "Activer",
    },

    {
      title: "Installez une serrure connectée 2",
      description: "et gagnez en autonomie",
      url: "#",
      linkLabel: "Découvrir",
    },

    {
      title: "Créez un guide de bienvenue 2",
      description: "partagez tous vos conseils et adresses",
      url: "#",
      linkLabel: "Découvrir",
    },
  ];

  return (
    <div>
      <Title>{t("Dashboard.tips")}</Title>

      <div className="w-full h-[206px] overflow-scroll bg-gray-200 relative p-4 rounded-6px">
        {/* Contenu à l'intérieur du rectangle */}
        <div className="left-0 top-0 w-full h-full relative">
          {tips.map((tip, index) => (
            <div className="absolute" style={{ left: index * 270 }}>
              <DashboardTipsItem
                title={tip.title}
                description={tip.description}
                url={tip.url}
                linkLabel={tip.linkLabel}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

type DashboardTipsItemProps = {
  title: string;
  description: string;
  linkLabel: string;
  url: string;
};

const DashboardTipsItem: React.FC<DashboardTipsItemProps> = ({
  title,
  description,
  linkLabel,
  url,
}) => {
  return (
    <div className="flex flex-col h-[176px] w-[256px] rounded-8px border-1 border-element-border overflow-hidden bg-white mr-4">
      <div className="p-4 flex-1">
        <p className="text-xl font-bold text-high-contrast">
          <LightBulbIcon />
        </p>

        <div className="h-full flex flex-col pt-4">
          <p className="flex flex-row font-semibold text-high-contrast items-center gap-1">
            {title}
          </p>

          <p className="flex flex-row gap-2 mt-1">
            <span className="text-low-contrast font-light">{description}</span>
          </p>
        </div>
      </div>

      <a
        className="flex flex-row h-10 bg-element-background border-t-1 border-element-border px-4 items-center cursor-pointer hover:bg-element-background-active"
        href={url}
      >
        <p className="flex-1 text-low-contrast font-semibold">{linkLabel}</p>

        <ArrowRightIcon />
      </a>
    </div>
  );
};

type BookingLineProps = {
  booking: {
    id: number;
    rental_id: number;
    full_name: string;
    checkin_date: string;
    checkin_time: string;
    checkout_date: string;
    checkout_time: string;
    nb_adults: number;
    nb_children: number;
    status: string;
    paid_status: string;
    team_checkin: boolean;
    team_cleaning: boolean;
    rental: {
      id: number;
      name: string;
      img: string;
      address: string;
    };
  };
  isCheckin: boolean;
  onClick: (value: number) => void;
};

const BookingLine: React.FC<BookingLineProps> = ({
  booking,
  isCheckin,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <tr
      className="hover:bg-element-background/40 cursor-pointer"
      onClick={() => onClick(booking.id)}
    >
      <td className="w-96 border-t-1 border-element-border text-sm px-2 py-2 flex flex-row items-center space-x-4">
        <div className="relative">
          <BookingIcon
            className="absolute z-10 border-2 border-white rounded-full"
            width={24}
            height={24}
            style={{ top: 8, left: 26 }}
          />
          <img
            src={booking.rental?.img}
            alt="Rental thumbnail"
            className="w-10 h-10 rounded-4px border-1 border-element-border/50"
          />
        </div>
        <div className="flex flex-col">
          <p className="text-high-contrast font-semibold">
            {booking.rental?.name}
          </p>
          <p className="text-low-contrast font-light">
            {booking.rental?.address}
          </p>
          <div className="flex flex-row text-low-contrast font-light items-center space-x-1">
            <UsersIcon width={12} height={12} />
            <p>
              {booking.nb_adults} {t("BookingList.adults")}
            </p>
            {booking.nb_children > 0 && (
              <>
                <EmojiHappyIcon width={12} height={12} />
                <p>
                  {booking.nb_children} {t("BookingList.children")}
                </p>
              </>
            )}
          </div>
        </div>
      </td>

      <td className="border-t-1 border-element-border text-sm px-2 py-2">
        <div className="flex flex-col">
          <p className="text-high-contrast font-semibold">
            {booking.full_name}
          </p>
        </div>
      </td>

      <td className="border-t-1 border-element-border text-sm px-2 py-2">
        <div className="flex flex-col">
          <p className="text-low-contrast font-light">
            {!isCheckin ? booking.checkin_date : booking.checkout_date}
          </p>
        </div>
      </td>

      <td className="border-t-1 border-element-border text-sm px-2 py-2">
        <div className="flex flex-col">
          <div className="flex flex-row text-low-contrast font-light items-center space-x-1">
            {!isCheckin ? booking.checkin_time : booking.checkout_time}
          </div>
        </div>
      </td>

      <td className="border-t-1 border-element-border">
        {isCheckin && booking.team_checkin && (
          <div className="flex gap-1 items-center">
            <div className="p-4 relative">
              <img
                src={MaritzImg}
                className="w-10 h-10 rounded-full object-cover"
              />
              <div
                className="absolute flex items-center justify-center rounded-full border-2 border-white w-6 h-6 bg-green-200"
                style={{ left: 40, top: 40 }}
              >
                <CheckIcon width={16} height={16} />
              </div>
            </div>
            <div className="flex-col">
              <p className="font-semibold text-green-700">Programmé</p>
              <p className="text-green-500 ">Assigné à K.Maritz</p>
            </div>
          </div>
        )}
        {isCheckin && !booking.team_checkin && (
          <div className="flex gap-2 items-center">
            <div className="p-4 relative">
              <div className="w-10 h-10 rounded-full object-cover border-1 border-dashed border-orange-200" />
              <div
                className="absolute flex items-center justify-center rounded-full border-2 border-white w-6 h-6 bg-orange-200"
                style={{ left: 40, top: 40 }}
              >
                <span className="text-orange-400 font-semibold">!</span>
              </div>
            </div>

            <div className="flex-col">
              <p className="text-orange-500 font-semibold">À assigner</p>
              <p className="text-orange-400">Départ prévu dans 2h</p>
            </div>
          </div>
        )}

        {!isCheckin && booking.team_cleaning && (
          <div className="flex gap-1 items-center">
            <div className="p-4 relative">
              <img
                src={MaritzImg}
                className="w-10 h-10 rounded-full object-cover"
              />
              <div
                className="absolute flex items-center justify-center rounded-full border-2 border-white w-6 h-6 bg-green-200"
                style={{ left: 40, top: 40 }}
              >
                <CheckIcon width={16} height={16} />
              </div>
            </div>
            <div className="flex-col">
              <p className="font-semibold text-green-700">Programmé</p>
              <p className="text-green-500 ">Assigné à K.Maritz</p>
            </div>
          </div>
        )}
        {!isCheckin && !booking.team_cleaning && (
          <div className="p-4 relative">
            <div className="w-10 h-10 rounded-full object-cover border-1 border-dashed border-orange-200" />
            <div
              className="absolute flex items-center justify-center rounded-full border-2 border-white w-6 h-6 bg-orange-200"
              style={{ left: 40, top: 40 }}
            >
              <span className="text-orange-400 font-semibold">!</span>
            </div>
          </div>
        )}
      </td>

      <td className="border-t-1 border-element-border w-40 pr-3">
        <Button
          onClick={() => onClick(booking.id)}
          type="secondary"
          size="normal"
          RightIcon={EyeIcon}
        >
          {t("Dashboard.bookingDetails")}
        </Button>
      </td>
    </tr>
  );
};
