import React from "react";
import Calendar from "react-calendar";
import { CalendarInputPopupProps } from "./CalendarInput.type";

export const CalendarInputPopup: React.FC<CalendarInputPopupProps> = ({
  currentDate,
  onChange,
}) => {
  return (
    <div className="pt-2">
      <Calendar onChange={(value) => onChange(value)} value={currentDate} />
    </div>
  );
};
