import {create} from "zustand";
import {GuestPageConnectResponse, RentalResponse, UserResponse} from "../../types/GETTypes";

interface GuestPageDataState {
  guestPageData: GuestPageConnectResponse | undefined;
  storeGuestPageData: (guestPageData: GuestPageConnectResponse) => void;
  isPreviewMode: boolean;
  storeIsPreviewMode: (previewMode: boolean) => void;
  userPreview: UserResponse | undefined;
  storeUserPreview: (userPreview: UserResponse) => void;
  rentalPreview: RentalResponse | undefined;
  storeRentalPreview: (rentalPreview: RentalResponse) => void;
}

const useGuestPageDataStore = create<GuestPageDataState>((set, get) => ({
  guestPageData: undefined,
  storeGuestPageData: (newGuestPageData: GuestPageConnectResponse) =>
    set((state) => ({
      guestPageData: newGuestPageData,
    })),
  isPreviewMode: false,
  storeIsPreviewMode: (previewMode: boolean) =>
    set((state) => ({
      isPreviewMode: previewMode,
    })),
  userPreview: undefined,
  storeUserPreview: (newUserPreview: UserResponse) =>
    set((state) => ({
      userPreview: newUserPreview,
    })),
  rentalPreview: undefined,
  storeRentalPreview: (newRentalPreview: RentalResponse) =>
    set((state) => ({
      rentalPreview: newRentalPreview,
    })),
}));

export default useGuestPageDataStore;
