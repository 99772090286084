import React from "react";
import {FileInstruction} from "../../../types/GETTypes";
import FileIcon from "../../../assets/icons/file.svg?react";
import DownloadIcon from "../../../assets/icons/download.svg?react";
import {downloadFile} from "../../../helpers/fileHelper";

export const InstructionFilesComponent = ({
  files,
}: {
  files?: FileInstruction[];
}) => {
  return (
    <div className="flex flex-col flex-wrap w-full pb-2 mb-3">
      {files?.map((file) => {
        const {original_url, name} = file;
        return (
          <div className="flex items-center p-4 border border-1 border-element-border rounded-lg shadow-sm bg-element-background ml-0 my-1">
            <div className="flex-shrink-0">
              <FileIcon className="text-low-contrast font-bold text-3xl" />
            </div>
            <div className="ml-4 flex-grow">
              <div className="font-medium text-high-contrast md:text-lg">
                {name}
              </div>
            </div>
            <a className="" href={original_url} download={name} target="_blank">
              <DownloadIcon className="text-low-contrast text-xl cursor-pointer" />
            </a>
          </div>
        );
      })}
    </div>
  );
};
