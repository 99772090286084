/**
 * Empty page for data list Guests.
 * In this page, is possible to create new guest :)
 *
 * @author Elysio
 */

import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { Button } from "../../components/Common/Button/Button";
import { useTranslation } from "react-i18next";

import React from "react";
import UserCross from "../../assets/icons/user-cross.svg?react";
import PlusWhite from "../../assets/icons/plus-white.svg?react";

export interface GuestsPageEmptyProps {
  setModalTravelerAddVisible: (isVisible: boolean) => void;
}

export default function GuestsPageEmpty({
  setModalTravelerAddVisible,
}: GuestsPageEmptyProps) {
  const { t } = useTranslation();

  return (
    <>
      <MainLayout
        title={t("Global.guests")}
        sidebarActiveItem="guests"
        sidebarActiveSubItem="general"
      >
        <div
          className="flex bg-element-background w-full rounded-6px mt-2 items-center justify-center p-4 border"
          style={{ height: "calc(100vh - 200px)" }}
        >
          <div className="flex flex-col items-center">
            <UserCross width={56} height={56} />

            <p className="my-4 font-semibold text-xl text-high-contrast">
              {t("Booking.Guest.guestTitleEmpty")}
            </p>

            <p className="mb-4 font-light text-base text-low-contrast text-center w-96">
              {t("Booking.Guest.guestContentEmpty")}
            </p>
            <Button
              textClassName={"font-normal"}
              RightIcon={PlusWhite}
              onClick={() => {
                setModalTravelerAddVisible(true);
              }}
            >
              {t("Booking.Guest.addTextButton")}
            </Button>
          </div>
        </div>
      </MainLayout>
    </>
  );
}
