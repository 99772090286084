import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {RightModal} from "../Common/RightModal/RightModal";
import {
  EditProfilForm,
  PasswordResetType,
  ResetPasswordModalProps,
} from "../../pages/Settings/Settings.type";
import {Separator} from "../Common/Separator/Separator";
import {Button} from "../Common/Button/Button";
import CheckIcon from "../../assets/icons/check-white.svg?react";
import {Controller, useForm} from "react-hook-form";
import {AddRentalRoomType} from "../AddRental/AddRental.type";
import {TextInput} from "../Common/TextInput/TextInput";
import {RegisterPasswordForm} from "../Authentication/Register/Password/RegisterPassword.type";
import {post} from "../../helpers/APIHelper";
import {useCheckAuth} from "../../hooks/useCheckAuth";
import {RequiredFields} from "../../types/commonTypes";
import {ErrorMessage} from "../Common/ErrorMessage/ErrorMessage";
import {useNavigate} from "react-router-dom";
import paths from "../../constants/paths";
import AlertCircle from "../../assets/icons/alert-circle.svg?react";

export const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  isVisible,
  onClose,
  onFinish,
}) => {
  const {t} = useTranslation();
  const [error, setError] = useState<string | undefined>();
  const [criteria, setCriteria] = useState({
    length: false,
    upper: false,
    low: false,
    number: false,
    specialChar: false,
  });
  const navigate = useNavigate();

  const {
    register,
    formState: {errors},
    handleSubmit,
    getValues,
    watch,
  } = useForm<PasswordResetType>({
    defaultValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
  });
  const initSubmit = () => {
    setError("");
    setDisabled(true);
  };

  const {user} = useCheckAuth();

  const [disabled, setDisabled] = useState<boolean>(false);

  const handleSave = async (values: PasswordResetType) => {
    console.log(values);
    initSubmit();

    const response = await post(
      `${import.meta.env.VITE_API_URL}/user/update-password/${user?.id}`,
      {
        old_password: values?.old_password!,
        new_password: values?.new_password!,
        confirm_password: values.confirm_password!,
      }
    );

    if (response?.data?.success) {
      onFinish();
      onClose();
      navigate(paths.LOGOUT);
      setDisabled(false);
    } else {
      setError(response?.response?.data?.message);
      setDisabled(false);
    }
  };

  const minPasswordLength = 8;
  const maxPasswordLength = 24;

  const requiredFields: RequiredFields<PasswordResetType> = {
    old_password: true,
    new_password: true,
    confirm_password: true,
  };

  const newPasswordRef = useRef({});
  newPasswordRef.current = watch("new_password", "");

  const validatePassword = (password: string) => {
    setCriteria({
      length: password?.length >= 8,
      upper: /[A-Z]/.test(password),
      low: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[\W_]/.test(password),
    });
  };

  useEffect(() => {
    validatePassword(newPasswordRef.current as string);
  }, [newPasswordRef.current]);

  return (
    <RightModal
      isVisible={isVisible}
      onClose={onClose}
      isLarge={false}
      title={t("Profil.updatePasswordTitle")}
      closeOnEmpty={false}
    >
      <form onSubmit={handleSubmit(handleSave)} className="w-full">
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-1 flex-col gap-3 w-full">
            <div className="flex flex-col gap-5">
              {error && <ErrorMessage>{error}</ErrorMessage>}

              <TextInput
                register={register("old_password", {
                  required: t("Register.Password.required").toString(),
                  minLength: {
                    value: minPasswordLength,
                    message: t("Register.Password.min", {
                      length: minPasswordLength,
                    }).toString(),
                  },
                  maxLength: {
                    value: maxPasswordLength,
                    message: t("Register.Password.max", {
                      length: maxPasswordLength,
                    }).toString(),
                  },
                })}
                label={t("Profil.oldPasswordLabel")}
                type="password"
                error={errors.old_password?.message}
                required={requiredFields.old_password}
                placeholder={t("Profil.oldPasswordPlaceholder")}
                autoComplete="old_password"
              />

              <TextInput
                register={register("new_password", {
                  required: t("Profil.newPasswordRequired").toString(),
                  minLength: {
                    value: minPasswordLength,
                    message: t("Profil.newPasswordMin", {
                      length: minPasswordLength,
                    }).toString(),
                  },
                  maxLength: {
                    value: maxPasswordLength,
                    message: t("Profil.newPasswordMax", {
                      length: maxPasswordLength,
                    }).toString(),
                  },
                  pattern: {
                    value:
                      /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,24}$/,
                    message: t("Profil.newPasswordInvalidFormat").toString(),
                  },
                })}
                label={t("Profil.newPasswordLabel")}
                type="password"
                error={errors.new_password?.message}
                required={requiredFields.new_password}
                placeholder={t("Profil.newPasswordPlaceholder")}
                autoComplete="new_password"
              />

              <div>
                <p
                    className={`text-sm font-light text-left ${
                        criteria.length ? "text-green" : "text-low-contrast "
                    } flex flex-row gap-1`}
                >
                  <AlertCircle
                      className={`${
                          criteria.length ? "fill-green" : "fill-gray-500 "
                      } me-2 shrink-0`}
                      width={18}
                      height={18}
                  />
                  {t("Reset.rule1Title")}
                </p>
                <p
                    className={`mt-2 text-sm font-light text-left ${
                        criteria.upper ? "text-green" : "text-low-contrast "
                    } flex flex-row gap-1`}
                >
                  <AlertCircle
                      className={`${
                          criteria.upper ? "fill-green" : "fill-gray-500 "
                      } me-2 shrink-0`}
                      width={18}
                      height={18}
                  />
                  {t("Reset.rule4Title")}
                </p>

                <p
                    className={`mt-2 text-sm font-light text-left ${
                        criteria.low ? "text-green" : "text-low-contrast "
                    } flex flex-row gap-1`}
                >
                  <AlertCircle
                      className={`${
                          criteria.low ? "fill-green" : "fill-gray-500 "
                      } me-2 shrink-0`}
                      width={18}
                      height={18}
                  />
                  {t("Reset.rule5Title")}
                </p>
                <p
                    className={`mt-2 text-sm font-light text-left ${
                        criteria.number ? "text-green" : "text-low-contrast "
                    }  flex flex-row gap-1`}
                >
                  <AlertCircle
                      className={`${
                          criteria.number ? "fill-green" : "fill-gray-500 "
                      } me-2 shrink-0`}
                      width={18}
                      height={18}
                  />
                  {t("Reset.rule2Title")}
                </p>
                <p
                    className={`mt-2 text-sm font-light text-left ${
                        criteria.specialChar ? "text-green" : "text-low-contrast "
                    }  flex flex-row gap-1`}
                >
                  <AlertCircle
                      className={`${
                          criteria.specialChar ? "fill-green" : "fill-gray-500 "
                      } me-2 shrink-0`}
                      width={18}
                      height={18}
                  />
                  {t("Reset.rule3Title")}
                </p>
              </div>

              <TextInput
                  register={register("confirm_password", {
                    validate: (value) =>
                        value === newPasswordRef.current ||
                        t("Profil.PasswordNotMatch").toString(),
                  })}
                  label={t("Profil.confirmPasswordLabel")}
                  type="password"
                  required={requiredFields.confirm_password}
                  error={errors.confirm_password?.message}
                  autoComplete="confirm_password"
                  placeholder={t("Profil.confirmPasswordPlaceholder")}
              />
            </div>
          </div>
          <div className="flex gap-3 border-t-1 border-element-border pt-4 m-2">
            <Button type="secondary" onClick={onClose}>
              {t("Global.cancel")}
            </Button>
            <Button
              RightIcon={CheckIcon}
              disabled={disabled}
              onClick={() => {}}
            >
              {t("Profil.validate")}
            </Button>
          </div>
        </div>
      </form>
    </RightModal>
  );
};
