import React from "react";
import { useTranslation } from "react-i18next";

import SearchIcon from "../../assets/icons/search.svg?react";
import { TextInput } from "../Common/TextInput/TextInput";
import { Button } from "../Common/Button/Button";
import PlusCircleWhiteIcon from "../../assets/icons/plus-circle-white.svg?react";
import useUserStore from "../../stores/useUserStore";

export const ShortcodeListPageFilter: React.FC<{
  onAdd: () => void;
  onSearchText: (text: string) => void;
}> = ({ onAdd = () => {}, onSearchText }) => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center space-x-3">
      <div className="flex-1">
        <TextInput
          size="large"
          Icon={SearchIcon}
          placeholder={t("Global.search")}
          onChangeText={onSearchText}
        />
      </div>
      <div>
        <Button
          onClick={onAdd}
          disabled={!userStore.canEdit}
          RightIcon={PlusCircleWhiteIcon}
        >
          {t("ShortcodeList.add")}
        </Button>
      </div>
    </div>
  );
};
