import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import {InProgressPlaceholder} from "../../components/Common/Dashboard/InProgressPlaceholder";
import {useCheckAuth} from "../../hooks/useCheckAuth";

export const HelpPage = () => {
    const { t } = useTranslation();
    const { user } = useCheckAuth();

  return (
    <>

      <MainLayout
        title={t("Global.settings")}
        sidebarActiveItem="help"
        sidebarActiveSubItem="general"
      >
        <InProgressPlaceholder/>
      </MainLayout>
    </>
  );
};
