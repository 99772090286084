import {
  PaymentMethodCreateParams,
  Stripe,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CheckCircleIcon from "../../assets/icons/check-circle-green.svg?react";
import EmojiHappyIcon from "../../assets/icons/emoji-happy.svg?react";
import LogInIcon from "../../assets/icons/log-in.svg?react";
import LogOutIcon from "../../assets/icons/log-out.svg?react";
import MoneyIcon from "../../assets/icons/money.svg?react";
import MoonIcon from "../../assets/icons/moon-2.svg?react";
import UsersIcon from "../../assets/icons/users.svg?react";
import { Separator } from "../../components/Common/Separator/Separator";
import { PaymentReservationStatusEnum } from "../../enums/GETenums";
import { cn } from "../../helpers/classHelper";
import { capitalizeFirstLetter } from "../../helpers/stringHelper";
import { PaymentReservationLinkResponse } from "../../types/GETTypes";
import { PaymentReservationFormOrInfo } from "./PaymentReservationFormOrInfo";
import {
  useGetPaymentReservationPrice,
  useGetPaymentReservationStatus,
  useInitPaymentReservationPage,
  useOnValidatePaymentReservation,
} from "./PaymentReservationPage.hooks";
import { PaymentReservationPageSkeleton } from "./PaymentReservationPageSkeleton";

export const PaymentReservationPage: React.FC = () => {
  const { t } = useTranslation();

  const params = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [paymentData, setPaymentData] = useState<
    PaymentReservationLinkResponse | undefined
  >();
  const [billingDetails, setBillingDetails] = useState<
    PaymentMethodCreateParams.BillingDetails | undefined
  >();
  const [stripePromise, setStripePromise] = useState<
    Promise<Stripe | null> | undefined
  >();
  const [stripeOptions, setStripeOptions] = useState<
    StripeElementsOptions | undefined
  >();

  useInitPaymentReservationPage(
    setLoading,
    [paymentData, setPaymentData],
    [stripePromise, setStripePromise],
    setStripeOptions,
    setBillingDetails
  );

  if (loading) return <PaymentReservationPageSkeleton />;

  return (
    <div className="flex flex-col items-center w-full h-screen gap-y-10">
      <header
        className="flex items-center justify-end w-full px-12 py-10 bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url('${paymentData?.payment_schedules.rental.cover}')`,
        }}
      >
        <h1 className="text-xl font-bold text-white">
          {paymentData?.payment_schedules.rental.name}
        </h1>
      </header>

      <section className="flex justify-center w-full max-w-5xl gap-x-10">
        <article className="flex flex-col flex-1 space-y-4">
          <h2 className="text-[22px] font-bold tracking-wider text-high-contrast">
            {t("Payments.PaymentReservation.title")}
          </h2>

          {/* RENTAL */}
          <div className="flex items-center space-x-3">
            <img
              className="rounded w-28 h-28"
              src={paymentData?.payment_schedules.rental.cover}
              alt={paymentData?.payment_schedules.rental.name}
            />
            <div>
              <p className="text-[16px] font-bold text-high-contrast">
                {paymentData?.payment_schedules.rental.name}
              </p>
              <p className="text-[14px] text-low-contrast mt-1">
                {`${paymentData?.payment_schedules.rental.address} ${paymentData?.payment_schedules.rental.postal_code} ${paymentData?.payment_schedules.rental.city}`}
              </p>
            </div>
          </div>

          <div className="flex items-center justify-between">
            {/* CHECKIN / CHECKOUT */}
            <div className="flex flex-col justify-between space-y-5">
              <div>
                <div className="flex space-x-2">
                  <LogInIcon />
                  <h3 className="text-low-contrast">
                    {t("Global.checkin", { count: 1 })}
                  </h3>
                </div>
                <p className="font-bold text-high-contrast">
                  {capitalizeFirstLetter(
                    moment(paymentData?.payment_schedules.checkin).format(
                      "dddd DD MMMM YYYY"
                    )
                  )}
                </p>
                <p className="text-low-contrast font-light text-[14px]">
                  {t("Payments.PaymentReservation.rangeDate", {
                    minTime:
                      paymentData?.payment_schedules.rental.checkin_min_time,
                    maxTime:
                      paymentData?.payment_schedules.rental.checkin_max_time,
                  })}
                </p>
              </div>

              <div>
                <div className="flex space-x-2">
                  <LogOutIcon />
                  <h3 className="text-low-contrast">
                    {t("Global.checkout", { count: 1 })}
                  </h3>
                </div>
                <p className="font-bold text-high-contrast">
                  {capitalizeFirstLetter(
                    moment(paymentData?.payment_schedules.checkout).format(
                      "dddd DD MMMM YYYY"
                    )
                  )}
                </p>
                <p className="text-low-contrast font-light text-[14px]">
                  {t("Payments.PaymentReservation.rangeDate", {
                    minTime:
                      paymentData?.payment_schedules.rental.checkout_min_time,
                    maxTime:
                      paymentData?.payment_schedules.rental.checkout_max_time,
                  })}
                </p>
              </div>
            </div>

            <div className="flex flex-col space-y-2">
              <div className="flex items-center justify-end space-x-2">
                <p className="font-bold text-md text-high-contrast">
                  {t("Global.nights", {
                    count: paymentData?.payment_schedules.nights_count ?? 0,
                  })}
                </p>
                <MoonIcon className="w-6 h-6" />
              </div>

              <div className="flex items-center justify-end space-x-2">
                <p className="text-[14px] text-low-contrast">
                  {t("Global.adults", {
                    count: paymentData?.payment_schedules.adults_count ?? 0,
                  })}
                </p>
                <UsersIcon className="w-5 h-5" />
              </div>

              <div className="flex items-center justify-end space-x-2">
                <p className="text-[14px] text-low-contrast">
                  {t("Global.children", {
                    count: paymentData?.payment_schedules.children_count ?? 0,
                  })}
                </p>
                <EmojiHappyIcon className="w-5 h-5" />
              </div>

              <div className="flex items-center justify-end space-x-2">
                <p className="text-[14px] text-low-contrast">
                  {`${
                    paymentData?.payment_schedules.payment_schedule
                      .total_price_value
                  } ${t("Global.currencySymbol")}`}
                </p>
                <MoneyIcon className="w-6 h-6" />
              </div>
            </div>
          </div>

          <Separator />

          <div className="flex flex-col pt-6 gap-y-6">
            <h4 className="text-xl font-bold tracking-wider text-high-contrast">
              {t("Payments.PaymentReservation.secondTitle")}
            </h4>

            {/* PAYMENT SCHEDULE ITEMS */}
            <div className="flex flex-col space-y-2">
              {paymentData &&
              paymentData.payment_schedules.payment_schedule
                .payments_schedule_items.length > 0
                ? paymentData.payment_schedules.payment_schedule.payments_schedule_items.map(
                    (v) => (
                      <div
                        className="flex items-center justify-between"
                        key={v.id}
                      >
                        <div className="flex items-center space-x-3">
                          {v.payment_status ===
                          PaymentReservationStatusEnum.PAID ? (
                            <CheckCircleIcon width={25} height={25} />
                          ) : null}
                          <span
                            className={cn(`font-light text-low-contrast`, {
                              "font-bold text-high-contrast":
                                moment(new Date()).toDate() <=
                                moment(v.specific_date).toDate(),
                              "text-success":
                                v.payment_status ===
                                PaymentReservationStatusEnum.PAID,
                            })}
                          >
                            {v.specific_date &&
                            moment(new Date()).toDate() ===
                              moment(v.specific_date).toDate()
                              ? `${t("Global.today")}${
                                  v.percentage_value
                                    ? ", " + v.percentage_value + " %"
                                    : ""
                                }`
                              : `${t(
                                  "Payments.PaymentReservation.onCustomDate",
                                  {
                                    date: moment(v.specific_date).format(
                                      "DD/MM/YYYY"
                                    ),
                                  }
                                )}${
                                  v.percentage_value
                                    ? ", " + v.percentage_value + " %"
                                    : ""
                                }`}
                          </span>
                        </div>

                        <div className="flex items-center space-x-3">
                          {/* TODO : [PaymentReservation] - Implement real value */}
                          <span
                            className={cn(`font-light`, {
                              "text-success":
                                v.payment_status ===
                                PaymentReservationStatusEnum.PAID,
                            })}
                          >
                            {t("Payments.PaymentReservation.cbPayment")}
                          </span>
                          <span
                            className={cn(`font-bold text-low-contrast`, {
                              "text-high-contrast":
                                moment(new Date()).toDate() <=
                                moment(v.specific_date).toDate(),
                              "text-success":
                                v.payment_status ===
                                PaymentReservationStatusEnum.PAID,
                            })}
                          >{`${v.price_value} ${t(
                            "Global.currencySymbol"
                          )}`}</span>
                        </div>
                      </div>
                    )
                  )
                : null}
            </div>

            <div className="flex items-center justify-between">
              <span className="text-[16px] text-low-contrast">
                {t("Global.total")}
              </span>
              <span className="text-[18px] text-low-contrast font-bold">{`${
                paymentData?.payment_schedules.payment_schedule
                  .total_price_value
              } ${t("Global.currencySymbol")}`}</span>
            </div>
          </div>
        </article>

        <article className="flex flex-col items-center justify-between flex-1 w-full h-full">
          <PaymentReservationFormOrInfo
            status={useGetPaymentReservationStatus(useParams(), paymentData)}
            amount={useGetPaymentReservationPrice(useParams(), paymentData)}
            stripeOptions={stripeOptions}
            stripePromise={stripePromise}
            stripeClientSecret={paymentData?.stripe_client_secret}
            billingDetails={billingDetails}
            onValidatePayment={(
              status: PaymentReservationStatusEnum | undefined
            ) =>
              useOnValidatePaymentReservation(status, params, [
                paymentData,
                setPaymentData,
              ])
            }
          />
        </article>
      </section>
    </div>
  );
};
