import React, {useEffect, useState} from "react";
import {GuestPageTab} from "../GuestPageHome/GuestPageHomeComponent";
import {Controller, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {guestPageReservationSchema} from "../../../validators/guestPage";
import {useTranslation} from "react-i18next";
import {TimeInput} from "../../Common/TimeInput/TimeInput";
import {Loader} from "../../Common/Loader/Loader";
import {Button} from "../../Common/Button/Button";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import moment from "moment";
import ReservationComponent from "./ReservationComponent";
import InstructionCheckinComponent from "./InstructionCheckinComponent";
import InstructionCheckoutComponent from "./InstructionCheckoutComponent";
import ExtraBlockComponent from "./ExtraBlockComponent";
import {calculateDaysUntilCheckin} from "../../../helpers/dateHelper";
import useWindowSize from "../../../hooks/useWindowSize";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import {update} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import useGuestPageTextCoverStore from "../../../stores/guestPageStore/useGuestPageTextCoverStore";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import ReviewComponent from "./ReviewComponent";
import {Badge} from "../../Common/Badge/Badge";
import CheckCircleGreenIcon from "../../../assets/icons/check-circle-green.svg?react";
import {
  findClosestTime,
  generateHoursRentalTime,
} from "../../../helpers/selectHelper";
import {InputSelect} from "../../Common/InputSelect/InputSelect";
import WifiComponent from "./WifiComponent";

interface GuestPageTimes {
  reservation_id: number;
  time_in: string;
  time_out: string;
}

const GuestPageReservation = ({onChangeTab}: GuestPageTab) => {
  const {t} = useTranslation();
  const {isMobile} = useWindowSize();
  const {title, subTitle, storeTitle, storeSubTitle} =
    useGuestPageTextCoverStore();
  const {
    guestPageData,
    storeGuestPageData,
    isPreviewMode,
    rentalPreview: rentalPreviewData,
  } = useGuestPageDataStore();

  const {
    rental: rentalData,
    guest,
    time_in: reservation_time_in,
    time_out: reservation_time_out,
    id: reservation_id,
    checkin,
    checkout,
    instructions_checkin_enabled,
    instructions_checkout_enabled,
    extras_enabled,
    guest_page_review_enabled,
    guest_page_wifi_enabled,
  } = guestPageData || {};

  const {
    checkin_min_time: rentalPreviewCheckinMinTime,
    checkout_min_time: rentalPreviewCheckoutMinTime,
  } = rentalPreviewData?.details?.informations || {};

  const {guest_page: {instructions: instructionsPreviewData} = {}} =
    rentalPreviewData || {};

  const rental = isPreviewMode
    ? rentalPreviewData?.details.informations
    : rentalData;
  const rentalCover = isPreviewMode
    ? rentalPreviewData?.details?.photos.cover
    : rental && "cover" in rental
    ? rental?.cover
    : "";

  const {
    checkout_min_time: rentalCheckoutMinTime,
    checkout_max_time: rentalCheckoutMaxTime,
    checkin_min_time: rentalCheckinMinTime,
    checkin_max_time: rentalCheckinMaxTime,
  } = rental || {};

  const {first_name, email} = guest || {};
  const dayUntilCheckin = calculateDaysUntilCheckin(checkin ?? "");
  // const [resolver, setResolver] = useState(() => zodResolver(guestPageReservationSchema(t, "", "", rentalCheckinMinTime || "", rentalCheckoutMaxTime || "")));
  const [loadingForm, setLoadingForm] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const checkinFormat = moment(checkin).format("DD/MM/YYYY");
  const checkoutFormat = moment(checkout).format("DD/MM/YYYY");
  const checkinTimes = generateHoursRentalTime(
    rentalCheckinMinTime,
    rentalCheckinMaxTime
  );
  const checkoutTimes = generateHoursRentalTime(
    rentalCheckoutMinTime,
    rentalCheckoutMaxTime
  );

  const time_in = isPreviewMode
    ? rentalPreviewCheckinMinTime
    : reservation_time_in;
  const time_out = isPreviewMode
    ? rentalPreviewCheckoutMinTime
    : reservation_time_out;

  const {
    control,
    handleSubmit,
    formState: {errors},
    setValue,
    getValues,
  } = useForm<GuestPageTimes>({
    // resolver,
    defaultValues: {
      reservation_id,
      time_in,
      time_out,
    },
    mode: "all",
  });

  const handleSave = async (values: GuestPageTimes) => {
    if (isPreviewMode) {
      return;
    }
    setLoadingForm(true);
    const {time_in, time_out} = values;
    const res = await update(
      `${import.meta.env.VITE_API_URL}${
        paths.API.GUEST_PAGE.UPDATE_RESERVATION
      }`,
      {reservation_id: String(reservation_id), email, time_in, time_out}
    );
    if (res?.data?.success) {
      setError("");
      const {time_in, time_out} = res?.data?.result;
      if (guestPageData) {
        storeGuestPageData({...guestPageData, time_in, time_out});
      }
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } else {
      setError(res?.response?.data?.message);
    }
    setLoadingForm(false);
  };

  const dayCheckinText =
    dayUntilCheckin > 1
      ? t("GuestPage.Reservation.daysUntilReservation", {
          day: dayUntilCheckin,
        })
      : dayUntilCheckin === 1
      ? t("GuestPage.Reservation.dayUntilReservation", {
          day: dayUntilCheckin,
        })
      : dayUntilCheckin === 0
      ? t("GuestPage.Reservation.todayUntilReservation")
      : t("GuestPage.Reservation.pastUntilReservation");

  useEffect(() => {
    if (!isPreviewMode) {
      if(dayUntilCheckin === 0){
        storeTitle &&
        storeTitle(
          `${t("GuestPage.Reservation.helloFirstName", {
            firstName: first_name,
          })} !`
        );
      }
     else {
      storeTitle &&
      storeTitle(
        `${t("GuestPage.Reservation.dDay", {
          day: dayUntilCheckin,
        })} ${first_name} !`
      );
     }
      storeSubTitle && storeSubTitle(dayCheckinText);
      return;
    }
    storeTitle && storeTitle(t("GuestPage.LoginDetails.preview"));
    storeSubTitle && storeSubTitle("");
  }, []);

  // useEffect(() => {
  //   const timeIn = getValues('time_in') || "";
  //   const timeOut = getValues('time_out') || "";

  //   const newResolver = zodResolver(
  //     guestPageReservationSchema(
  //       t,
  //       findClosestTime(timeIn, checkinTimes) || "",
  //       findClosestTime(timeOut, checkoutTimes) || "",
  //       rentalCheckinMinTime || "",
  //       rentalCheckoutMaxTime || ""
  //     )
  //   );

  //   setResolver(() => newResolver);
  // }, [t, checkinTimes, checkoutTimes, rentalCheckinMinTime, rentalCheckoutMaxTime]);

  const {guest_page: guestPagePreview} = rentalPreviewData || {};
  const {
    wifi_enabled,
    reviews_enabled,
    instructions_checkin_enabled: instructionCheckinPreviewEnabled,
    instructions_checkout_enabled: instructionCheckoutPreviewEnabled,
    extras_enabled: extraPreviewEnabled,
  } = guestPagePreview || {};

  const data = isPreviewMode ? guestPagePreview : guestPageData;
  const wifi = isPreviewMode ? wifi_enabled : guest_page_wifi_enabled;
  const review = isPreviewMode ? reviews_enabled : guest_page_review_enabled;
  const instructionCheckin = isPreviewMode
    ? instructionCheckinPreviewEnabled
    : instructions_checkin_enabled;
  const extras = isPreviewMode ? extraPreviewEnabled : extras_enabled;
  const instructionCheckout = isPreviewMode
    ? instructionCheckoutPreviewEnabled
    : instructions_checkout_enabled;
  const getTitle = () => {
    if (title) {
      return title;
    }
    return "";
  };

  const getSubtitle = () => {
    if (subTitle) {
      return subTitle;
    }
    return "";
  };

  return (
    <div className="w-full mb-20">
      {isMobile && (
        <div
          className="relative h-32 md:h-32 bg-cover bg-center"
          style={{backgroundImage: `url('${rentalCover}')`}}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center">
            <div className="text-white px-4 md:w-2/3 md:pl-0">
              <h1 className="flex text-[32px] md:text-[40px] font-bold">
                {getTitle()}
              </h1>
              <p className="mt-1 text-base md:text-xl font-medium leading-7">
                {getSubtitle()}
              </p>
            </div>
          </div>
        </div>
      )}
      <form
        onSubmit={handleSubmit(handleSave)}
        className="md:justify-center md:flex md:flex-1 md:flex-col mt-4 "
      >
        {success ? (
          <div className="w-auto md:w-2/3 mb-4 mx-4 md:ml-0">
            <Badge
              Icon={CheckCircleGreenIcon}
              label={t("GuestPage.LoginDetails.successMessageTime")}
              status="success"
            />
          </div>
        ) : null}
        <div className="bg-element-background py-4 mx-4 pt-0 rounded-md md:ml-0">
          <div className="gap-8 h-[80%] py-4 md:w-full px-2 ">
            <div className="flex flex-1 flex-row">
              <div className="flex w-1/2 md:w-full mr-2 flex-col mt-3">
                <Controller
                  control={control}
                  name="time_in"
                  render={({field: {value}}) => {
                    return (
                      <div className=" ">
                        <InputSelect
                          label={t("GuestPage.LoginDetails.timeIn")}
                          items={checkinTimes}
                          selectedValue={findClosestTime(
                            value ?? "",
                            checkinTimes
                          )}
                          onSelect={(value) => {
                            setValue("time_in", value?.toString());
                          }}
                          error={errors.time_in?.message}
                          disabled={loadingForm}
                          classNames={{
                            input: "md:text-lg",
                            label: "md:text-lg w-[150px] md:w-auto",
                            labelRequired: "md:text-xl",
                            buttonText: "md:text-lg",
                            error: "md:text-lg",
                            option: "md:text-lg",
                            labelSelected: "md:text-lg",
                            labelSelectedActive: "md:text-lg",
                          }}
                        />
                      </div>
                    );
                  }}
                />

                <span className="font-light text-low-contrast md:text-lg text-sm mt-2">
                  {t("GuestPage.Reservation.between")} {moment(rentalCheckinMinTime,'HH:mm').format("HH:mm")}{" "}
                  {t("GuestPage.Reservation.and")?.toLocaleLowerCase()}{" "}
                  {moment(rentalCheckinMaxTime,'HH:mm').format("HH:mm")}{" "}
                  {t("GuestPage.Reservation.theDate", {date: checkinFormat})}{" "}
                </span>
              </div>
              <div className="flex w-1/2 md:w-full ml-2 flex-col mt-3">
                <Controller
                  control={control}
                  name="time_out"
                  render={({field: {value}}) => {
                    return (
                      <div className="">
                        <InputSelect
                          label={t("GuestPage.LoginDetails.timeOut")}
                          items={checkoutTimes}
                          selectedValue={findClosestTime(
                            value ?? "",
                            checkoutTimes
                          )}
                          onSelect={(value) => {
                            setValue("time_out", value?.toString());
                          }}
                          error={errors.time_out?.message}
                          disabled={loadingForm}
                          classNames={{
                            input: "md:text-lg",
                            label: "md:text-lg w-[150px] md:w-auto",
                            labelRequired: "md:text-xl",
                            buttonText: "md:text-lg",
                            error: "md:text-lg",
                            option: "md:text-lg",
                            labelSelected: "md:text-lg",
                            labelSelectedActive: "md:text-lg",
                          }}
                        />
                      </div>
                    );
                  }}
                />
                <span className="font-light text-low-contrast md:text-lg text-sm mt-2">
                  {t("GuestPage.Reservation.between")} { moment(rentalCheckoutMinTime,'HH:mm').format("HH:mm")}{" "}
                  {t("GuestPage.Reservation.and")?.toLocaleLowerCase()}{" "}
                  {moment(rentalCheckoutMaxTime,'HH:mm').format("HH:mm")}{" "}
                  {t("GuestPage.Reservation.theDate", {date: checkoutFormat})}{" "}
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-1 flex-col justify-end  md:items-center px-2">
            <ErrorMessage childrenClassName="md:text-lg">{error}</ErrorMessage>
            <Button
              className="flex flex-1 md:w-full md:mx-2"
              buttonClassName="md:h-12"
              textClassName="md:text-xl"
              RightIcon={CheckIcon}
              disabled={isPreviewMode || loadingForm}
              displayLargeBtn={false}
              loading={loadingForm}
            >
              {t("Global.validate")}
            </Button>
          </div>
        </div>
      </form>
      {data && (
        <>
          <ReservationComponent />
          {review ? <ReviewComponent /> : null}
          {instructionCheckin ? (
            <InstructionCheckinComponent onChangeTab={onChangeTab} />
          ) : null}
          {wifi ? <WifiComponent /> : null}
          {extras ? <ExtraBlockComponent onChangeTab={onChangeTab} /> : null}
          {instructionCheckout ? (
            <InstructionCheckoutComponent onChangeTab={onChangeTab} />
          ) : null}
        </>
      )}
    </div>
  );
};

export default GuestPageReservation;
