import { useEffect, useState } from "react";
import { post } from "../helpers/APIHelper";
import paths from "../constants/paths";

export function useEditPrivateNote(reservation: any) {
  const [privateNotes, setPrivateNotes] = useState<string>("");
  const [lastSavedPrivateNotes, setLastSavedPrivateNotes] =
    useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (reservation) {
      setPrivateNotes(reservation.private_notes ?? "");
      setLastSavedPrivateNotes(reservation.private_notes ?? "");
    }
  }, [reservation]);

  const handleSave = async () => {
    setLoading(true);
    setError(null);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.RESERVATIONS}/${
        reservation?.id
      }`,
      {
        _method: "PUT",
        private_notes: privateNotes,
      }
    );

    if (!res.data?.success) {
      setError(res.response.data.message);
    }

    setLastSavedPrivateNotes(privateNotes);
    setLoading(false);
  };

  const handleCancel = async () => {
    setPrivateNotes(lastSavedPrivateNotes);
  };

  const handleChange = (text: string) => {
    setPrivateNotes(text);
  };

  return {
    privateNotes,
    error,
    loading,
    handleChange,
    handleSave,
    handleCancel,
  };
}
