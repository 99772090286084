import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import { CalendarDay } from "../../../pages/CalendarList/CalendarListPage.type";
import { CalendarInput } from "../CalendarInput/CalendarInput";
import {
  useChangeDateCalendar,
  useChangeEndDateInputCalendar,
  useChangeStartDateInputCalendar,
} from "../CalendarInput/CalendarInput.hooks";
import {
  CalendarInputValue,
  CalendarInputValueRange,
} from "../CalendarInput/CalendarInput.type";
import { InputSelect } from "../InputSelect/InputSelect";
import {
  useChangeDateRangePreset,
  useHandleChangeDates,
} from "./DateFilter.hooks";
import { DateFilterProps, DateRangePreset } from "./DateFilter.type";

export const DateFilter: React.FC<DateFilterProps> = ({
  onDatesChanged,
  onDateRangePresetChanged,
  onLoading,
  onError,
}) => {
  const { t } = useTranslation();

  const [dates, setDates] = useState<CalendarInputValue>([null, null]);
  const [nbRangeDate, setNbRangeDate] = useState<number>(0);
  const [days, setDays] = useState<CalendarDay | undefined>();
  const [isDateRangePreset, setIsDateRangePreset] = useState<boolean>(false);
  const [dateRangePreset, setDateRangePreset] =
    useState<DateRangePreset>("next_seven_days");

  const today: Date = new Date();

  // * We update the date when we click on the predefined date range selection option.
  useEffect(() => {
    const nextSevenDays: Date = moment(today).add(6, "days").toDate();
    const lastFourWeeks: Date = moment(today).add(-4, "weeks").toDate();
    const lastThreeMonths: Date = moment(today).add(-3, "months").toDate();
    const lastTwelveMonths: Date = moment(today).add(-12, "months").toDate();

    switch (dateRangePreset) {
      case "all":
        setDates([null, null]);
        return;
      case "today":
        setDates([today, today]);
        return;
      case "next_seven_days":
        setDates([today, nextSevenDays]);
        return;
      case "last_four_weeks":
        setDates([lastFourWeeks, today]);
        return;
      case "last_three_months":
        setDates([lastThreeMonths, today]);
        return;
      case "last_twelve_months":
        setDates([lastTwelveMonths, today]);
        return;
    }
  }, [dateRangePreset]);

  useEffect(() => {
    useHandleChangeDates(
      dates as CalendarInputValueRange,
      (nbRangeDate: number) => setNbRangeDate(nbRangeDate),
      (days: CalendarDay | undefined) => setDays(days),
      (message: string | undefined) => {
        if (typeof onError === "function") {
          onError(message);
        }
      },
      () => {
        if (typeof onLoading === "function") {
          onLoading(true);
        }
        if (typeof onError === "function") {
          onError(undefined);
        }
      },
      () => {
        if (typeof onLoading === "function") {
          onLoading(false);
        }
      }
    );
  }, [dates]);

  useEffect(() => {
    if (typeof onDatesChanged === "function") {
      onDatesChanged(dates as CalendarInputValueRange, nbRangeDate, days);
    }
  }, [dates, nbRangeDate, days]);

  useEffect(() => {
    if (typeof onDateRangePresetChanged === "function") {
      onDateRangePresetChanged(dateRangePreset, isDateRangePreset);
    }
  }, [dateRangePreset, isDateRangePreset]);

  return (
    <div className="flex items-center w-full">
      <InputSelect
        classNames={{
          button: "rounded-e-none",
        }}
        maxItemWidth={true}
        items={[
          {
            label: t("Global.today"),
            value: "today",
          },
          {
            label: t("Components.DateFilter.nextSevenDays"),
            value: "next_seven_days",
          },
          {
            label: t("Components.DateFilter.lastFourWeeks"),
            value: "last_four_weeks",
          },
          {
            label: t("Components.DateFilter.lastTreeMonths"),
            value: "last_three_months",
          },
          {
            label: t("Components.DateFilter.custom"),
            value: "custom",
            isVisible: false,
          },
        ]}
        selectedValue={dateRangePreset}
        onSelect={(value: ValueType) =>
          useChangeDateRangePreset(
            value,
            setDateRangePreset,
            setIsDateRangePreset
          )
        }
      />
      <CalendarInput
        classNames={{
          button: "border-s-0 rounded-md rounded-s-none bg-white",
        }}
        height="full"
        dateType={"range"}
        value={dates}
        onChangeStartDateInput={(date: string) =>
          useChangeStartDateInputCalendar(
            date,
            [dates, setDates],
            setIsDateRangePreset
          )
        }
        onChangeEndDateInput={(date: string) =>
          useChangeEndDateInputCalendar(
            date,
            [dates, setDates],
            setIsDateRangePreset
          )
        }
        onChangeDate={(value: CalendarInputValue) =>
          useChangeDateCalendar(value, setDates, setIsDateRangePreset)
        }
      />
    </div>
  );
};
