import React from "react";
import { PaymentReservationDepositStatusEnum } from "../../enums/GETenums";
import { cn } from "../../helpers/classHelper";
import { PaymentDepositInfoProps } from "./PaymentDepositInfo.type";

export const PaymentDepositInfo: React.FC<PaymentDepositInfoProps> = ({
  icon,
  title,
  description,
  status,
}) => {
  return (
    <div className="flex flex-col items-center w-full p-6 space-y-4 rounded-lg bg-element-background">
      {icon}
      <h3
        className={cn(`text-xl font-bold`, {
          "text-success":
            status === PaymentReservationDepositStatusEnum.PAID ||
            status === PaymentReservationDepositStatusEnum.PRE_AUTHORIZED ||
            status === PaymentReservationDepositStatusEnum.RELEASED ||
            status === PaymentReservationDepositStatusEnum.REFUNDED ||
            status === PaymentReservationDepositStatusEnum.MANAGED_BY_PLATFORM,
        })}
      >
        {title}
      </h3>
      <p className="font-light text-low-contrast text-md">{description}</p>
    </div>
  );
};
