import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { AddMemberRentalsForm } from "./AddMemberModal.type";
import { useTranslation } from "react-i18next";
import { ValueType } from "../../../types/commonTypes";
import { MultiSelect } from "../../Common/MultiSelect/MultiSelect";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";

export const AddMemberStepRentals: React.FC<{
  refButton: React.MutableRefObject<HTMLInputElement | undefined>;
  initialData: AddMemberRentalsForm | undefined;
  onNextStep: (values: AddMemberRentalsForm) => void;
}> = ({ refButton, initialData, onNextStep }) => {
  const form = useForm<AddMemberRentalsForm>({
    defaultValues: { rentalsId: [] },
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (initialData) {
      form.setValue("rentalsId", initialData.rentalsId);
    }
  }, [initialData]);

  const handleNextStep = (values: AddMemberRentalsForm) => {
    if (values.rentalsId?.length === 0) {
      form.setError("rentalsId", {
        message: t("Management.AddMember.Rentals.rentalsIdRequired"),
      });
    } else {
      form.clearErrors();
      onNextStep(values);
    }
  };

  return (
    <form onSubmit={form.handleSubmit(handleNextStep)}>
      <p className="font-light text-low-contrast">
        {t("Management.AddMember.Rentals.content1")}
      </p>

      <Controller
        control={form.control}
        name="rentalsId"
        render={({ field: { value, onChange } }) => {
          const handleChange = (id: ValueType) => {
            const index = value.findIndex((v) => v === id);
            if (index === -1) onChange([...value, id]);
            else onChange(value.filter((v) => v !== id));
          };

          return (
            <>
              <MultiSelect
                options={{ withInfoText: false, withSubmitButton: false }}
                loading={false}
                onSelectRental={handleChange}
                selectedRentals={value}
              />

              <ErrorMessage errorType="FORM">
                {form.formState.errors.rentalsId?.message}
              </ErrorMessage>
            </>
          );
        }}
      />

      <input
        type="submit"
        value="go"
        ref={refButton as React.MutableRefObject<HTMLInputElement>}
        className="hidden"
      />
    </form>
  );
};
