import React from "react";
import { useTranslation } from "react-i18next";
import { AutoMessageUpsertRentalsProps } from "./AutoMessageUpsertRentals.type";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { CheckBox } from "../../../Common/CheckBox/CheckBox";

import SearchIcon from "../../../../assets/icons/search.svg?react";
import Rental1Thumbnail from "../../../../assets/images/rental1.webp";
import Rental2Thumbnail from "../../../../assets/images/rental2.webp";
import Rental3Thumbnail from "../../../../assets/images/rental3.webp";
import Rental4Thumbnail from "../../../../assets/images/rental4.webp";
import Rental5Thumbnail from "../../../../assets/images/rental5.webp";

export const AutoMessageUpsertRentals: React.FC<
  AutoMessageUpsertRentalsProps
> = () => {
  const { t } = useTranslation();

  const rentalsGroups = [
    {
      id: 1,
      name: "Paris",
      rentals: [
        {
          id: 1,
          name: "Appartement Le Marais",
          address: "16 Rue Charlemagne",
          zip: "75004",
          city: "Paris",
          selected: true,
          thumbnail: Rental1Thumbnail,
        },
        {
          id: 2,
          name: "Appartement Tour Eiffel",
          address: "Place du Champs de Mars",
          zip: "75007",
          city: "Paris",
          selected: true,
          thumbnail: Rental2Thumbnail,
        },
        {
          id: 3,
          name: "Appartement Moulin Rouge",
          address: "15 Rue Alfred Dehodencq",
          zip: "75004",
          city: "Paris",
          selected: false,
          thumbnail: Rental3Thumbnail,
        },
      ],
    },
    {
      id: 2,
      name: "Versailles",
      rentals: [
        {
          id: 4,
          name: "Appartement Lafayette",
          address: "51 Boulevard Garibaldi",
          zip: "78000",
          city: "Versailles",
          selected: false,
          thumbnail: Rental4Thumbnail,
        },
        {
          id: 5,
          name: "Appartement Saint-Denis",
          address: "16 Boulevard Saint-Denis",
          zip: "78000",
          city: "Versailles",
          selected: true,
          thumbnail: Rental5Thumbnail,
        },
      ],
    },
  ];

  return (
    <>
      <div className="flex flex-row items-end space-x-3">
        <TextInput
          label={t("AutoMessageUpsert.searchRental")}
          placeholder={t("AutoMessageUpsert.placeholderSearchRental")}
          Icon={SearchIcon}
        />

        <div className="flex px-2 py-1 rounded-6px font-semibold bg-icon-active text-white text-sm h-8 items-center">
          3
        </div>
      </div>

      <div className="mt-3">
        <table className="table-auto w-full rounded-4px border-1 border-element-border border-separate overflow-hidden border-spacing-0">
          {/* Table Header */}
          <tr className="bg-element-background border-b-1 border-element-border">
            <th className="p-2 pr-0 text-sm">
              <CheckBox />
            </th>
            <th className=" px-2 py-2 text-sm">
              <div className="flex flex-row items-center cursor-pointer hover:underline">
                <p>{t("AutoMessageUpsert.rental")}</p>
              </div>
            </th>
          </tr>

          {rentalsGroups?.map((group) => (
            <RentalGroupLine
              id={group.id}
              name={group.name}
              rentals={group.rentals}
            />
          ))}
        </table>
      </div>
    </>
  );
};

type RentalGroupLineProps = {
  id: number;
  name: string;
  rentals: {
    id: number;
    name: string;
    address: string;
    zip: string;
    city: string;
    selected: boolean;
    thumbnail: string;
  }[];
};

const RentalGroupLine: React.FC<RentalGroupLineProps> = ({
  id,
  name,
  rentals,
}) => {
  return (
    <>
      <tr
        className="bg-element-background/70 cursor-pointer"
        key={`group-${id}`}
      >
        <td className="p-2 pr-0">
          <CheckBox />
        </td>
        <td className="text-left text-low-contrast text-sm pl-2 py-1">
          {name?.toUpperCase()}
        </td>
      </tr>

      {rentals?.map((rental) => (
        <tr
          key={`rental-${rental.id}`}
          className="hover:bg-element-background/40 cursor-pointer"
        >
          <td className="p-2 pr-0">
            <CheckBox value={rental.selected} />
          </td>
          <td className="py-2 flex flex-row space-x-2 items-center">
            <div>
              <img
                src={rental.thumbnail}
                alt="Rental thumbnail"
                className="w-10 h-10 rounded-4px border-1 border-element-border/50"
              />
            </div>
            <div className="flex-1">
              <p className="font-semibold text-high-contrast">{rental.name}</p>
              <p className="font-light text-low-contrast">
                {rental.address} {rental.zip} {rental.city}
              </p>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};
