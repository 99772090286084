import moment from "moment";
import React from "react";
import {ClassName} from "react-calendar/dist/cjs/shared/types";
import {useTranslation} from "react-i18next";
import {cn} from "../../../helpers/classHelper";
import {getPlatformNode} from "../../../helpers/platformHelper";

import {PaymentExtraListItemResponse} from "../../../types/GETTypes";
import {TableListItemProps} from "../../Common/TableList/TableList.type";
import {getReservationExtraOrderStatusBadge} from "../../../helpers/reservationHelper";

export const PaymentExtrasListItem: React.FC<TableListItemProps> = ({
  data,
  onClick = () => {},
}) => {
  const paymentReservation: PaymentExtraListItemResponse = data;
  const {t} = useTranslation();

  const getBorder = (): ClassName => "border-element-border border-t-1";

  return (
    <>
      <tr
        className="cursor-pointer hover:bg-element-background/40"
        onClick={() => onClick(paymentReservation.id)}
      >
        <td
          className={cn(
            `flex flex-row items-center px-4 py-4 space-x-4 text-sm border-element-border`,
            `${getBorder()}`
          )}
        >
          <div className="flex items-center">
            <div className="relative">
              <div className="object-fill w-12 h-12 overflow-hidden rounded-4px border-1 border-element-border/50">
                <img
                  src={paymentReservation.rental?.details?.photos?.cover!}
                  alt={paymentReservation.rental?.details?.informations?.name}
                  className="object-fill w-full h-full"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-base font-semibold tracking-normal text-high-contrast">
              {paymentReservation.rental?.details?.informations?.name}
            </p>
            <div>
              <p className="font-light tracking-normal text-low-contrast">
                {paymentReservation.rental?.details?.informations?.address}
              </p>
              <p className="font-light tracking-normal text-low-contrast">
                {paymentReservation.rental?.details?.informations?.postal_code}{" "}
                {paymentReservation.rental?.details?.informations?.city}
              </p>
            </div>
          </div>
        </td>

        <td className={cn(`px-2 py-4`, `${getBorder()}`)}>
          <span className="font-bold tracking-normal">{`${paymentReservation.guest.first_name} ${paymentReservation.guest.last_name}`}</span>
        </td>

        <td className={cn(`px-2 py-4`, `${getBorder()}`)}>
          {t("Payments.PaymentReservationList.idCommand",{id:paymentReservation.id})}
        </td>

        <td className={cn(`py-4 text-center`, `${getBorder()}`)}>
          <span className="text-base">
            {paymentReservation?.payment_extras_order?.order_date
              ? moment(
                  paymentReservation?.payment_extras_order?.order_date
                ).format("DD/MM/YYYY")
              : "-"}
          </span>
        </td>

        <td className={cn(`py-4`, `${getBorder()}`)}>
          {getReservationExtraOrderStatusBadge(
            paymentReservation?.payment_extras_order?.payment_status
          )}
        </td>

        <td className={cn(`py-4 text-right pe-4`, `${getBorder()}`)}>
          <span className="font-bold tracking-wide">
            {`${Number(
              paymentReservation?.payment_extras_order?.total_price_value
            ).toFixed(2)} ${t("Global.currencySymbol")}`}
          </span>
        </td>
      </tr>
    </>
  );
};
