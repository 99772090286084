import React, { CSSProperties, ReactNode } from "react";
import InformationIcon from "../../../assets/icons/information.svg?react";
import { cn } from "../../../helpers/classHelper";
import { Trans } from "react-i18next";

const InfoCard = ({
  title,
  description,
  useTransComponent = false,
  classNames,
}: {
  title?: string | ReactNode;
  description?: string | ReactNode;
  useTransComponent?: boolean;
  classNames?: { description: CSSProperties };
}) => {
  return (
    <div className="flex items-start p-4 rounded-lg select-none gap-x-3 bg-element-background">
      <div className="flex flex-col">
        <InformationIcon />
      </div>
      <div className="flex flex-col whitespace-pre-line">
        <p
          className={cn("text-low-contrast", {
            "pb-3": title,
          })}
        >
          {useTransComponent ? <Trans i18nKey={title} /> : title}
        </p>
        <p
          className={cn(
            "font-light text-low-contrast",
            classNames?.description
          )}
        >
          {useTransComponent ? <Trans i18nKey={description} /> : description}
        </p>

        <p className="font-light text-low-contrast">{description}</p>
      </div>
    </div>
  );
};

export default InfoCard;
