import {
  RentalSmartLocksProvider,
  SmartlocksAddStaticPayload,
  SmartlocksLinkPayload,
  SmartlocksUnlinkPayload,
  SmartlocksUpdatePayload,
} from "../../components/Rental/Services/Smartlocks/Smartlocks.type";
import { SmartLocksEnum } from "../../constants/locks";
import paths from "../../constants/paths";
import { del, get, post } from "../../helpers/APIHelper";
import { ValueType } from "../../types/commonTypes";
import {
  SmartlocksAccountItemResponse,
  SmartlocksIdListItemResponse,
  SmartlocksLinkedItemResponse,
  SmartlocksUnlinkResponse,
  SmartlocksUpdateResponse,
} from "../../types/GETTypes";

export type LockAuthenticationPayload = {
  provider: Lowercase<RentalSmartLocksProvider>;
  api_key?: string;
  username?: string;
  password?: string;
};

export type IgloohomeAuthenticationPayload = {
  rental_id: ValueType;
};

export type NukiAuthenticationPayload = {
  rental_id: ValueType;
};

/**
 * Fetches linked smart locks for a given rental ID and handles the response.
 *
 * @param {string | undefined} rentalId - The ID of the rental to fetch linked smart locks for.
 * @param {(smartlocksLinkedItem: SmartlocksLinkedItemResponse[]) => void} onSuccess - Callback function to handle successful response.
 * @param {(message: string | undefined) => void} onError - Callback function to handle errors.
 * @param {() => void} [onStart] - Optional callback function to be called before the request starts.
 * @param {() => void} [onEnd] - Optional callback function to be called after the request ends.
 * @returns {Promise<void>} - A promise that resolves when the operation is complete.
 */
export const useLinkedLocks = async (
  rentalId: string | undefined,
  onSuccess: (smartlocksLinkedItem: SmartlocksLinkedItemResponse[]) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
): Promise<void> => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.LOCKS.LOCKS}${
        paths.API.RENTALS
      }/${rentalId}${paths.API.LOCKS.LOCKS}`
    );
    if (response?.data?.success) {
      onSuccess(response.data.result);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Updates the lock information for a given rental.
 *
 * @param {string | undefined} rentalId - The ID of the rental.
 * @param {SmartlocksUpdatePayload} data - The payload containing the update data for the smart lock.
 * @param {(smartlocksUpdateResponse: SmartlocksUpdateResponse) => void} onSuccess - Callback function to be called on successful update.
 * @param {(message: string | undefined) => void} onError - Callback function to be called on error.
 * @param {() => void} [onStart] - Optional callback function to be called at the start of the update process.
 * @param {() => void} [onEnd] - Optional callback function to be called at the end of the update process.
 * @returns {Promise<void>} - A promise that resolves when the update process is complete.
 */
export const useUpdateLock = async (
  rentalId: string | undefined,
  data: SmartlocksUpdatePayload,
  onSuccess: (smartlocksUpdateResponse: SmartlocksUpdateResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
): Promise<void> => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.LOCKS.LOCKS}${
        paths.API.RENTALS
      }/${rentalId}${paths.API.LOCKS.GET_LOCK}`,
      {
        _method: "PUT",
        ...data,
      }
    );
    if (response?.data?.success) {
      onSuccess(response.data.result);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * useLockAuthentication - A function to handle lock authentication process.
 *
 * @param {SmartlocksAddPayload} data - The payload data required for lock authentication.
 * @param {(lockAuthenticationId: number) => void} onSuccess - Callback function to be called on successful authentication with the lock authentication ID.
 * @param {(message: string | undefined) => void} onError - Callback function to be called on authentication error with an error message.
 * @param {() => void} [onStart] - Optional callback function to be called at the start of the authentication process.
 * @param {() => void} [onEnd] - Optional callback function to be called at the end of the authentication process.
 *
 * @returns {Promise<void>} - A promise that resolves when the authentication process is complete.
 */
export const useLockAuthentication = async (
  data: LockAuthenticationPayload,
  onSuccess: (lockAuthenticationId: number) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const URL = `${import.meta.env.VITE_API_URL}${
      paths.API.LOCKS.AUTHENTICATION
    }`;

    const response = await post(URL, data);
    if (response?.data?.success) {
      onSuccess(response.data.result.id);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Authenticates with the Igloohome API using the provided payload.
 *
 * @param {IgloohomeAuthenticationPayload} payload - The payload containing authentication details.
 * @param {(url: string) => void} onSuccess - Callback function to be called upon successful authentication with the resulting URL.
 * @param {(message: string | undefined) => void} onError - Callback function to be called if an error occurs, with an error message.
 * @param {() => void} [onStart] - Optional callback function to be called before the authentication process starts.
 * @param {() => void} [onEnd] - Optional callback function to be called after the authentication process ends, regardless of success or failure.
 *
 * @returns {Promise<void>} A promise that resolves when the authentication process is complete.
 */
export const useIgloohomeAuthentication = async (
  payload: IgloohomeAuthenticationPayload,
  onSuccess: (url: string) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const URL = `${import.meta.env.VITE_API_URL}${
      paths.API.LOCKS.AUTHENTICATION
    }/${SmartLocksEnum.IGLOOHOME.toLowerCase()}`;

    const response = await post(URL, payload);
    if (response?.data?.success) {
      onSuccess(response.data.result.url);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Handles the Nuki authentication process by sending a POST request to the authentication endpoint.
 *
 * @param payload - The payload containing authentication data for Nuki.
 * @param onSuccess - Callback function to be called when authentication is successful. Receives the URL as a parameter.
 * @param onError - Callback function to be called when an error occurs during authentication. Receives an error message as a parameter.
 * @param onStart - Optional callback function to be called before the authentication process starts.
 * @param onEnd - Optional callback function to be called after the authentication process ends, regardless of success or failure.
 */
export const useNukiAuthentication = async (
  payload: NukiAuthenticationPayload,
  onSuccess: (url: string) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const URL = `${import.meta.env.VITE_API_URL}${
      paths.API.LOCKS.AUTHENTICATION
    }/${SmartLocksEnum.NUKI.toLowerCase()}`;

    const response = await post(URL, payload);
    if (response?.data?.success) {
      onSuccess(response.data.result.url);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Asynchronously fetches lock accounts and handles the response.
 *
 * @param onSuccess - Callback function to be called with the smartlocks accounts data if the request is successful.
 * @param onError - Callback function to be called with an error message if the request fails.
 * @param onStart - Optional callback function to be called before the request starts.
 * @param onEnd - Optional callback function to be called after the request completes, regardless of success or failure.
 */
export const useLockAccounts = async (
  onSuccess: (smartlocksAccounts: SmartlocksAccountItemResponse[]) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.LOCKS.LOCKS}${
        paths.API.LOCKS.LOCK_AUTHENTICATIONS
      }`
    );
    if (response?.data?.success) {
      onSuccess(response.data.result);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Fetches lock data based on the provided lock authentication ID and handles the response.
 *
 * @param {number | undefined} lockAuthenticationId - The ID used for lock authentication.
 * @param {(smartlocksIds: SmartlocksIdListItemResponse[]) => void} onSuccess - Callback function to handle successful response.
 * @param {(message: string | undefined) => void} onError - Callback function to handle errors.
 * @param {() => void} [onStart] - Optional callback function to be called before the request starts.
 * @param {() => void} [onEnd] - Optional callback function to be called after the request ends.
 * @returns {Promise<void>} - A promise that resolves when the operation is complete.
 */
export const useLocks = async (
  lockAuthenticationId: number | undefined,
  onSuccess: (smartlocksIds: SmartlocksIdListItemResponse[]) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.LOCKS.LOCKS}${
        paths.API.LOCKS.LOCK_AUTHENTICATIONS
      }/${lockAuthenticationId}${paths.API.LOCKS.DEVICES}`
    );
    if (response?.data?.success) {
      onSuccess(response.data.result);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Asynchronous function to handle the linking of smart locks to a rental property.
 *
 * @param {string | undefined} rentalId - The ID of the rental property.
 * @param {SmartlocksLinkPayload} data - The payload data required for linking the smart locks.
 * @param {(message: string | undefined) => void} onError - Callback function to be executed on error, with an error message.
 * @param {() => void} [onStart] - Optional callback function to be executed at the start of the process.
 * @param {() => void} [onEnd] - Optional callback function to be executed at the end of the process.
 *
 * @returns {Promise<void>} - A promise that resolves when the process is complete.
 */
export const useLockLink = async (
  rentalId: string | undefined,
  data: SmartlocksLinkPayload,
  onSuccess: (smartLockLinkItem: SmartlocksLinkedItemResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
): Promise<void> => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const response = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.LOCKS.GET_ALL_LINKED_LOCKS
      }/${rentalId}${paths.API.LOCKS.GET_LOCK}`,
      data
    );
    if (response?.data?.success) {
      onSuccess(response.data.result);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

export const useStoreStaticLock = async (
  rentalId: ValueType | undefined,
  data: SmartlocksAddStaticPayload,
  onSuccess: (smartLock: SmartlocksLinkedItemResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (rentalId && data.code && data.name) {
    if (typeof onStart === "function") {
      onStart();
    }
    try {
      const URL = `${import.meta.env.VITE_API_URL}${paths.API.LOCKS.LOCKS}${
        paths.API.RENTALS
      }/${rentalId}${paths.API.LOCKS.GET_LOCK}/manual`;

      const response = await post(URL, data);
      if (response?.data?.success) {
        onSuccess(response.data.result);
      } else {
        onError(response?.data?.message ?? response.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  }
};

/**
 * Unlinks a smart lock from a rental property.
 *
 * @param {string | undefined} rentalId - The ID of the rental property.
 * @param {SmartlocksUnlinkPayload} data - The payload containing the unlink data.
 * @param {(smartLockUnlinkResponse: SmartlocksUnlinkResponse) => void} onSuccess - Callback function to be called on successful unlink.
 * @param {(message: string | undefined) => void} onError - Callback function to be called on error.
 * @param {() => void} [onStart] - Optional callback function to be called at the start of the operation.
 * @param {() => void} [onEnd] - Optional callback function to be called at the end of the operation.
 * @returns {Promise<void>} - A promise that resolves when the operation is complete.
 */
export const useLockUnlink = async (
  rentalId: string | undefined,
  data: SmartlocksUnlinkPayload,
  onSuccess: (smartLockUnlinkResponse: SmartlocksUnlinkResponse) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
): Promise<void> => {
  if (typeof onStart === "function") {
    onStart();
  }
  try {
    const response = await del(
      `${import.meta.env.VITE_API_URL}${paths.API.LOCKS.LOCKS}${
        paths.API.RENTALS
      }/${rentalId}${paths.API.LOCKS.GET_LOCK}`,
      {
        data,
      }
    );
    if (response?.data?.success) {
      onSuccess(response.data.result);
    } else {
      onError(response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};
