import React from "react";
import {TableListHeaderProps} from "../Common/TableList/TableList.type";
import {useTranslation} from "react-i18next";

export const ShortcodeListPageHeader: React.FC<TableListHeaderProps> = () => {
  const { t } = useTranslation();

  return (
      <tr className="bg-element-background border-b-1 border-element-border">

          <th className={`px-2 py-2 text-sm`}>
              <div className="flex flex-row items-center">
                  <p>{t("ShortcodeList.description")}</p>
              </div>
          </th>

          <th className="px-2 py-2 text-sm rounded-tl-6px w-72">
              <div className={`flex flex-row items-center`}>
                  <p>{t("ShortcodeList.name")}</p>
              </div>
          </th>
          <th className={`px-2 py-2 text-sm w-40`}>
              <div className="flex flex-row items-center">
                  <p>{t("ShortcodeList.actions")}</p>
              </div>
          </th>
      </tr>
  );
};
