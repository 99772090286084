import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import ChildIcon from "../../../assets/icons/emoji-happy.svg?react";
import HomeIcon from "../../../assets/icons/home.svg?react";
import InfosIcon from "../../../assets/icons/information.svg?react";
import CheckinIcon from "../../../assets/icons/log-in.svg?react";
import CheckoutIcon from "../../../assets/icons/log-out.svg?react";
import MailIcon from "../../../assets/icons/mail.svg?react";
import MoneyIcon from "../../../assets/icons/money.svg?react";
import MoonIcon from "../../../assets/icons/moon.svg?react";
import PhoneIcon from "../../../assets/icons/phone.svg?react";
import UserIcon from "../../../assets/icons/user.svg?react";
import UsersIcon from "../../../assets/icons/users.svg?react";
import { displayPriceWith2DigitsIfNeeded } from "../../../helpers/stringHelper";
import rental_types from "../../../res/rental_types";
import { StepProgress } from "../../StepProgress/StepProgress";
import {
  ADD_RESERVATION_STEPS,
  AddReservationType,
} from "../AddReservation.type";

export const AddReservationSummary: React.FC<{
  reservation: AddReservationType | undefined;
  currentStep: ADD_RESERVATION_STEPS;
}> = ({ reservation, currentStep }) => {
  const { t } = useTranslation();

  const getStepTitle = () => {
    if (currentStep === ADD_RESERVATION_STEPS.INFORMATIONS)
      return t("AddReservation.Summary.infosTitle");
    else if (currentStep === ADD_RESERVATION_STEPS.PRICES)
      return t("AddReservation.Summary.pricesTitle");
    else if (currentStep === ADD_RESERVATION_STEPS.GUEST)
      return t("AddReservation.Summary.guestTitle");
    else if (currentStep === ADD_RESERVATION_STEPS.PAYMENT)
      return t("AddReservation.Summary.paymentTitle");
    else return t("AddReservation.Summary.doneTitle");
  };

  const getStepDescription = () => {
    if (currentStep === ADD_RESERVATION_STEPS.INFORMATIONS)
      return t("AddReservation.Summary.infosDesc");
    else if (currentStep === ADD_RESERVATION_STEPS.PRICES)
      return t("AddReservation.Summary.pricesDesc");
    else if (currentStep === ADD_RESERVATION_STEPS.GUEST)
      return t("AddReservation.Summary.guestDesc");
    else if (currentStep === ADD_RESERVATION_STEPS.PAYMENT)
      return t("AddReservation.Summary.paymentDesc");
    else return t("AddReservation.Summary.doneDesc");
  };

  const getFees = (): {
    amount: number;
    wording: string;
  } => {
    let amount: number = 0;
    let wording: string[] = [];

    if (reservation?.tarification) {
      if (reservation.tarification.cityTaxes) {
        amount += reservation.tarification.cityTaxes;
        wording.push(t("AddReservation.Summary.ExtraNames.city"));
      }

      if (reservation.tarification.cleanFees) {
        amount += reservation.tarification.cleanFees;
        wording.push(t("AddReservation.Summary.ExtraNames.clean"));
      }

      if (reservation.tarification.extraGuestFees) {
        amount += reservation.tarification.extraGuestFees;
        wording.push(t("AddReservation.Summary.ExtraNames.guest"));
      }

      if (reservation.tarification.animalsFees) {
        amount += reservation.tarification.animalsFees;
        wording.push(t("AddReservation.Summary.ExtraNames.animal"));
      }

      if (reservation.tarification.babyBedFees) {
        amount += reservation.tarification.babyBedFees;
        wording.push(t("AddReservation.Summary.ExtraNames.baby"));
      }

      if (reservation.tarification.otherFees) {
        amount += reservation.tarification.otherFees;
        wording.push(t("AddReservation.Summary.ExtraNames.other"));
      }
    }

    let wordingValue: string = "";
    if (wording.length > 0) {
      wordingValue = `(${wording.join(", ")})`;
    }

    return {
      amount: amount,
      wording: wordingValue,
    };
  };

  return (
    <div className="flex flex-col w-full h-full text-sm rounded-lg bg-subtle border-1 border-border-subtle">
      {/* Card */}
      <div className="m-4 rounded-lg border-1 border-element-border bg-subtle-light">
        {/* Cover */}
        {reservation?.rental &&
        currentStep > ADD_RESERVATION_STEPS.INFORMATIONS ? (
          <img
            className="object-cover object-center w-full h-28 rounded-t-6px border-b-1 border-element-border bg-element-background"
            src={reservation?.rental?.cover}
          />
        ) : (
          <div className="w-full h-28 bg-element-background rounded-t-6px border-b-1 border-element-border"></div>
        )}

        {/* Rental */}
        {reservation?.rental &&
        currentStep > ADD_RESERVATION_STEPS.INFORMATIONS ? (
          <div className="flex items-center justify-between px-2 py-4 border-b-1 border-element-border">
            <div>
              <p className="text-base font-semibold text-high-contrast">
                {`${t("Global.reservation")} -  ${reservation.rental.name}`}
              </p>
              <p className="mt-1 text-xs font-light text-low-contrast">
                {reservation?.rental.address},{" "}
                {reservation?.rental?.postal_code}{" "}
                {reservation?.rental?.country.name}
              </p>
            </div>
            <div className="flex items-center gap-1 p-1 rounded-sm bg-element-background">
              <HomeIcon className="w-4 h-4" />

              <p className="text-xs font-semibold text-low-contrast">
                {
                  rental_types.find(
                    (t) => t.id === reservation?.rental?.rental_type_id
                  )?.fr
                }
              </p>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-between px-2 py-4 border-b-1 border-element-border">
            <div>
              <p className="text-base font-semibold text-high-contrast">
                {t("AddReservation.newReservation")}
              </p>
              <p className="h-2 mt-2 rounded bg-element-background-solid/40 w-52"></p>
            </div>
            <div>
              <p className="w-16 h-2 rounded bg-element-background-solid/40"></p>
            </div>
          </div>
        )}

        {/* Global infos */}
        {reservation?.rental &&
        currentStep > ADD_RESERVATION_STEPS.INFORMATIONS ? (
          <div className="flex flex-col px-2 py-4 border-b-1 border-element-border">
            <p className="flex items-center font-semibold text-high-contrast">
              <InfosIcon className="w-5 h-5 pr-1" />
              {t("AddReservation.globalInfos")}
            </p>

            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <CheckinIcon className="w-4 h-4" />
                  <p className="font-light text-low-contrast">
                    <span className="font-semibold">
                      {moment(reservation?.general?.checkin).format(
                        "DD/MM/YYYY"
                      )}
                    </span>{" "}
                    {t("Global.at")} {reservation?.general?.time_in}
                  </p>
                </div>

                <div className="flex items-center gap-1">
                  <CheckoutIcon className="w-4 h-4" />
                  <p className="font-light text-low-contrast">
                    <span className="font-semibold">
                      {moment(reservation?.general?.checkout).format(
                        "DD/MM/YYYY"
                      )}
                    </span>{" "}
                    {t("Global.at")} {reservation?.general?.time_out}
                  </p>
                </div>
              </div>

              <div className="flex flex-col items-end">
                <div className="flex items-center gap-1">
                  <p className="font-semibold text-high-contrast">
                    {t("Global.nights", {
                      count: reservation?.general?.nights_count,
                    })}
                  </p>
                  <MoonIcon className="w-4 h-4" />
                </div>

                <div className="flex items-center gap-1 mt-1">
                  <p className="font-light text-low-contrast">
                    {t("Global.adults", {
                      count: Number(reservation?.general?.adults_count),
                    })}
                  </p>
                  <UsersIcon className="w-4 h-4" />
                </div>

                <div className="flex items-center gap-1 mt-1">
                  <p className="font-light text-low-contrast">
                    {t("Global.children", {
                      count: Number(reservation?.general?.children_count),
                    })}
                  </p>
                  <ChildIcon className="w-4 h-4" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col px-2 py-4 border-b-1 border-element-border">
            <p className="flex items-center font-semibold text-high-contrast">
              <InfosIcon className="w-5 h-5 pr-1" />
              {t("AddReservation.globalInfos")}
            </p>

            <div className="flex items-center justify-between">
              <div>
                <div className="flex items-center gap-1">
                  <CheckinIcon className="w-4 h-4" />
                  <p className="h-2 rounded bg-element-background-solid/40 w-36"></p>
                </div>

                <div className="flex items-center gap-1 mt-2">
                  <CheckoutIcon className="w-4 h-4" />
                  <p className="h-2 rounded bg-element-background-solid/40 w-36"></p>
                </div>
              </div>

              <div className="flex flex-col items-end">
                <div className="flex items-center gap-1">
                  <p className="w-16 h-2 rounded bg-element-background-solid/40"></p>
                  <MoonIcon className="w-4 h-4" />
                </div>

                <div className="flex items-center gap-1 mt-2">
                  <p className="w-12 h-2 rounded bg-element-background-solid/40"></p>
                  <UsersIcon className="w-4 h-4" />
                </div>

                <div className="flex items-center gap-1 mt-2">
                  <p className="h-2 rounded bg-element-background-solid/40 w-14"></p>
                  <ChildIcon className="w-4 h-4" />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Prices */}
        {reservation?.tarification &&
        currentStep > ADD_RESERVATION_STEPS.PRICES ? (
          <div className="flex flex-col px-2 py-4 border-b-1 border-element-border">
            <p className="flex items-center font-semibold text-high-contrast">
              <MoneyIcon className="w-6 h-6 pr-1" />
              {t("AddReservation.prices")}
            </p>

            <div className="flex items-center justify-between gap-x-2">
              <div className="flex flex-col gap-1 mt-3">
                <div className="flex items-center gap-1">
                  <p className="font-light text-low-contrast">
                    {t("AddReservation.Summary.totalNights")}{" "}
                    {displayPriceWith2DigitsIfNeeded(
                      reservation.tarification.totalNights
                    )}
                  </p>
                </div>

                <div className="flex items-center flex-1 gap-1">
                  <p className="font-light text-low-contrast">
                    {t("AddReservation.Summary.extra", {
                      amount: displayPriceWith2DigitsIfNeeded(getFees().amount),
                      extras: getFees().wording,
                    })}
                  </p>
                </div>
              </div>

              <div className="flex flex-col items-end">
                <div className="flex gap-1 items-center text-[10px] font-light text-low-contrast w-max">
                  {t("Global.priceWithTaxes")}
                </div>

                <div className="flex items-center gap-1 w-max">
                  <p className="text-base font-semibold text-high-contrast text-nowrap">
                    {displayPriceWith2DigitsIfNeeded(
                      reservation.tarification.total
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col px-2 py-4 border-b-1 border-element-border">
            <p className="flex items-center font-semibold text-high-contrast">
              <MoneyIcon className="w-6 h-6 pr-1" />
              {t("AddReservation.prices")}
            </p>

            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-3 mt-3">
                <div className="flex items-center gap-1">
                  <p className="w-56 h-2 rounded bg-element-background-solid/40"></p>
                </div>

                <div className="flex items-center gap-1">
                  <p className="w-48 h-2 rounded bg-element-background-solid/40"></p>
                </div>

                <div className="flex items-center gap-1">
                  <p className="h-2 rounded bg-element-background-solid/40 w-52"></p>
                </div>

                <div className="flex items-center gap-1">
                  <p className="h-2 rounded bg-element-background-solid/40 w-52"></p>
                </div>
              </div>

              <div className="flex flex-col items-end">
                <div className="flex gap-1 items-center text-[10px] font-light text-low-contrast">
                  {t("Global.priceWithTaxes")}
                </div>

                <div className="flex items-center gap-1 mt-2">
                  <p className="w-12 h-2 rounded bg-element-background-solid/40"></p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Guest */}
        {reservation?.guest && currentStep > ADD_RESERVATION_STEPS.GUEST ? (
          <div className="flex flex-col justify-center px-2 py-4">
            <p className="flex items-center font-semibold text-high-contrast">
              <UserIcon className="w-5 h-5 pr-1" />
              {t("AddReservation.guest")}
            </p>

            <div className="flex items-center gap-2 mt-2">
              <img
                className="w-12 h-12 rounded-full bg-element-background border-element-border border-1"
                src={reservation?.guest?.photo}
              />

              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <p className="text-base font-semibold text-high-contrast">
                    {reservation?.guest?.first_name}{" "}
                    {reservation?.guest?.last_name}
                  </p>
                </div>

                {reservation?.guest?.email && (
                  <div className="flex items-center gap-1">
                    <MailIcon className="w-4 h-4" />
                    <p className="font-light text-low-contrast">
                      {reservation?.guest?.email}
                    </p>
                  </div>
                )}

                {reservation?.guest?.phone && (
                  <div className="flex items-center gap-1">
                    <PhoneIcon className="w-4 h-4" />
                    <p className="font-light text-low-contrast">
                      {reservation?.guest?.phone}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center px-2 py-4">
            <p className="flex items-center font-semibold text-high-contrast">
              <UserIcon className="w-5 h-5 pr-1" />
              {t("AddReservation.guest")}
            </p>

            <div className="flex items-center gap-2 mt-2">
              <div className="w-12 h-12 rounded-full bg-element-background border-element-border border-1" />

              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-1 pb-1">
                  <p className="w-56 h-2 rounded bg-element-background-solid/40"></p>
                </div>

                <div className="flex items-center gap-1">
                  <MailIcon className="w-4 h-4" />
                  <p className="w-48 h-2 rounded bg-element-background-solid/40"></p>
                </div>

                <div className="flex items-center gap-1">
                  <PhoneIcon className="w-4 h-4" />
                  <p className="h-2 rounded bg-element-background-solid/40 w-52"></p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Flex full space */}
      <div className="flex-1" />

      {/* Steps */}
      <div className="px-12 pt-4 pb-8">
        <p className="font-semibold text-low-contrast">
          {currentStep <= 4 ? (
            <>
              {t("Global.step")} {currentStep} / 4
            </>
          ) : (
            <>{t("Global.hereItIs")}</>
          )}
        </p>

        <p className="mt-2 text-lg font-semibold text-high-contrast">
          {getStepTitle()}
        </p>

        <p className="mt-3 font-light text-low-contrast">
          {getStepDescription()}
        </p>

        <StepProgress maxStep={4} currentStep={currentStep} />
      </div>
    </div>
  );
};
