import React, { useEffect, useState } from "react";
import { RightModal } from "../../Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import StripeLogo from "../../../assets/icons/stripe.svg?react";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import { Button } from "../../Common/Button/Button";
import { Separator } from "../../Common/Separator/Separator";
import { TextInput } from "../../Common/TextInput/TextInput";
import { ValueType } from "../../../types/commonTypes";
import { post } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { PaymentAccountListItemResponse } from "../../../types/GETTypes";

export const EditStripeAccountModal: React.FC<{
  account: PaymentAccountListItemResponse | null;
  isVisible: boolean;
  onClose: () => void;
  onRemove: (id: ValueType) => void;
  onFinish: () => void;
}> = ({ account, isVisible, onClose, onRemove, onFinish }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");

  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    if (account) {
      setName(account.name ?? "");
      setEmail(account.stripe_account_email ?? "");
    }
  }, [account]);

  const handleChangeName = (text: string) => {
    setName(text);

    if (text === "") setNameError(t("Payments.Stripe.nameError"));
    else setNameError("");
  };

  const handleSave = async () => {
    if (name.trim() === "") {
      setNameError(t("Payments.Stripe.nameError"));

      return;
    }

    setError(null);
    setNameError("");
    setLoading(true);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.ACCOUNTS}/${
        account?.id
      }`,
      {
        _method: "PUT",
        name,
      }
    );

    if (res?.data?.success) {
      onFinish();
    } else {
      setError(res.response.data.message);
    }

    setLoading(false);
  };

  return (
    <RightModal
      isVisible={isVisible}
      onClose={onClose}
      title={t("Payments.addStripe")}
    >
      <div className="flex flex-col w-full">
        <div className="flex flex-col flex-1">
          <StripeLogo />

          {/* Account name */}
          <div className="mt-4">
            <TextInput
              label={t("Payments.Stripe.name")}
              placeholder={t("Payments.Stripe.namePlaceholder")}
              onChangeText={handleChangeName}
              disabled={loading}
              value={name}
              error={nameError}
            />
            <p className="text-[10px] text-low-contrast font-light">
              {t("Payments.Stripe.nameHint")}
            </p>
          </div>

          {/* Account email */}
          <div className="mt-2">
            <TextInput
              label={t("Payments.Stripe.email")}
              placeholder={t("Payments.Stripe.emailPlaceholder")}
              value={email}
              disabled={true}
            />
          </div>

          <div className="mt-2">
            <ErrorMessage>{error}</ErrorMessage>
          </div>
        </div>

        <Separator />

        <div className="flex gap-3">
          <Button type="secondary" onClick={onClose} disabled={loading}>
            {t("Global.cancel")}
          </Button>
          <Button
            type="destructive"
            onClick={() => onRemove(account?.id ?? "")}
            disabled={loading}
          >
            {t("Global.remove")}
          </Button>
          <Button
            RightIcon={CheckWhiteIcon}
            loading={loading}
            onClick={handleSave}
          >
            {t("Global.save")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
