import React from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../TextInput/TextInput";
import { MultiSelectRentalsTableProps } from "./MultiSelectRentalsTable.type";

import SearchIcon from "../../../assets/icons/search.svg?react";
import { TableList } from "../TableList/TableList";
import { MultiSelectRentalsTableHeader } from "./MultiSelectRentalsTableHeader";
import { MultiSelectRentalsTableItem } from "./MultiSelectRentalsTableItem";
import { MultiSelectRentalsTableSkeleton } from "./MultiSelectRentalsTableSkeleton";

export const MultiSelectRentalsTable: React.FC<
  MultiSelectRentalsTableProps
> = ({ tablePage, disabled, selectedRentals, onSelectRental }) => {
  const { t } = useTranslation();

  const handleSearch = (text: string) => {
    tablePage.handleChangeSearch(text);
  };

  return (
    <div className="flex flex-col w-full">
      <div>
        <p className="mb-1 font-bold text-md text-high-contrast">
          {t("Global.rentals")}
        </p>
        <TextInput
          placeholder={t("Global.searchRental")}
          Icon={SearchIcon}
          disabled={disabled}
          onChangeText={handleSearch}
        />
      </div>

      <TableList
        Header={MultiSelectRentalsTableHeader}
        Item={MultiSelectRentalsTableItem}
        NoData={null}
        Skeleton={MultiSelectRentalsTableSkeleton}
        i18nElement="Global.rentals"
        tablePage={tablePage}
        selectedItems={selectedRentals}
        onClick={onSelectRental}
        disabled={disabled}
      />
    </div>
  );
};
