import {RefObject, useRef} from "react";

export const callFunction = (func?: any) => {
  return typeof func === "function" ? func : () => {};
};

export const scrollToTop = (topElementRef?: RefObject<HTMLDivElement>) => {
  if (topElementRef?.current) {
    topElementRef.current.scrollIntoView({behavior: "smooth"});
  } else {
    window.scrollTo({top: 0, behavior: "smooth"});
  }
};
