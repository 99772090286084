import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../../helpers/stringHelper";

export const TaskListNoItem: React.FC<{ date: string }> = ({ date }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col px-2 py-3 border-1 border-element-border bg-element-background-light rounded-6px">
      <p className="text-base font-semibold text-high-contrast">
        {capitalizeFirstLetter(moment(date).format("dddd DD MMMM YYYY"))}
      </p>

      <p className="font-light text-low-contrast">
        {t("Task.List.noTaskToday")}
      </p>
    </div>
  );
};
