import React from "react";
import {useTranslation} from "react-i18next";
import CoffeeIcon from "../../../assets/icons/coffee-machine.svg?react";
import FileTextIcon from "../../../assets/icons/file-text.svg?react";
import HomeIcon from "../../../assets/icons/home.svg?react";
import UsersIcon from "../../../assets/icons/user-group.svg?react";
import {getCurrencySigle} from "../../../helpers/currencyHelper";
import resFacilities from "../../../res/facilities";
import {FacilitiesGroupType} from "../../Rental/Infos/Facilities/Facilities.type";
import {RentalFacilitiesViewGroup} from "../../Rental/Infos/Facilities/FacilitiesViewGroup";
import {StepProgress} from "../../StepProgress/StepProgress";
import {ADD_RENTAL_STEPS, AddRentalType} from "../AddRental.type";

export const AddRentalSummary: React.FC<{
  rental: AddRentalType | undefined;
  currentStep: ADD_RENTAL_STEPS;
}> = ({ rental, currentStep }) => {
  const { t } = useTranslation();

  const facilitiesGroups: FacilitiesGroupType[] = resFacilities;

  const getStepTitle = () => {
    if (currentStep === ADD_RENTAL_STEPS.INFORMATIONS || currentStep === ADD_RENTAL_STEPS.CONFIRM_ADDRESS)
      return t("AddRental.Summary.infosTitle");
    else if (currentStep === ADD_RENTAL_STEPS.ROOMS)
      return t("AddRental.Summary.roomsTitle");
    else if (currentStep === ADD_RENTAL_STEPS.PHOTOS)
      return t("AddRental.Summary.photosTitle");
    else if (currentStep === ADD_RENTAL_STEPS.FACILITIES)
      return t("AddRental.Summary.facilitiesTitle");
    else if (currentStep === ADD_RENTAL_STEPS.RULES)
      return t("AddRental.Summary.rulesTitle");
    else if (currentStep === ADD_RENTAL_STEPS.TARIFICATION)
      return t("AddRental.Summary.tarificationTitle");
    else if (currentStep === ADD_RENTAL_STEPS.DONE)
      return t("AddRental.Summary.doneTitle");
    else if (currentStep === ADD_RENTAL_STEPS.SEASON)
      return t("AddRental.Summary.seasonTitle");
  };

  const getStepDescription = () => {
    if (currentStep === ADD_RENTAL_STEPS.INFORMATIONS || currentStep === ADD_RENTAL_STEPS.CONFIRM_ADDRESS)
      return t("AddRental.Summary.infosDesc");
    else if (currentStep === ADD_RENTAL_STEPS.ROOMS)
      return t("AddRental.Summary.roomsDesc");
    else if (currentStep === ADD_RENTAL_STEPS.PHOTOS)
      return t("AddRental.Summary.photosDesc");
    else if (currentStep === ADD_RENTAL_STEPS.FACILITIES)
      return t("AddRental.Summary.facilitiesDesc");
    else if (currentStep === ADD_RENTAL_STEPS.RULES)
      return t("AddRental.Summary.rulesDesc");
    else if (currentStep === ADD_RENTAL_STEPS.TARIFICATION)
      return t("AddRental.Summary.tarificationDesc");
    else if (currentStep === ADD_RENTAL_STEPS.DONE)
      return t("AddRental.Summary.doneDesc");
    else if (currentStep === ADD_RENTAL_STEPS.SEASON)
      return t("AddRental.Summary.seasonDesc");
  };

  const getRentingLabel = () => {
    if (rental?.infos?.rentingType === "ENTIRE_HOUSE")
      return t("AddRental.Infos.RentingTypeLabel.full");
    else if (rental?.infos?.rentingType === "SINGLE_ROOM")
      return t("AddRental.Infos.RentingTypeLabel.room");
    else if (rental?.infos?.rentingType === "SHARED_ROOM")
      return t("AddRental.Infos.RentingTypeLabel.shared");
  };

  const getTotalBeds = () => {
    if (rental?.rooms) {
      let result = 0;

      rental?.rooms?.forEach((r) => {
        result +=
          r.children_beds_count +
          r.double_beds_count +
          r.infants_beds_count +
          r.king_beds_count +
          r.mezannine_beds_count +
          r.queen_beds_count +
          r.single_beds_count +
          r.sofa_beds_count +
          r.wall_beds_count;
      });

      return result;
    }

    return 0;
  };

  return (
    <div className="flex flex-col w-full h-full text-sm rounded-lg bg-subtle border-1 border-border-subtle">
      {/* Card */}
      <div className="m-4 rounded-lg border border-element-border bg-subtle-light max-h-[80%] overflow-y-auto">
        {/* Cover */}
        {currentStep > ADD_RENTAL_STEPS.PHOTOS ? (
            <img
                className="object-cover object-center w-full h-10 rounded-t-6px border-b-1 border-element-border bg-element-background"
            src={URL.createObjectURL(rental?.photos?.photos[0]!)}
          />
        ) : (
          <div className="w-full h-24 bg-element-background rounded-t-6px border-b-1 border-element-border"></div>
        )}

        {/* Informations */}
        {currentStep < ADD_RENTAL_STEPS.ROOMS && (
          <div className="flex flex-col px-2 py-4 border-b-1 border-element-border">
            <p className="text-base font-semibold text-high-contrast">
              {t("AddRental.Summary.yourRental")}
            </p>

            <div className="flex items-center justify-between mt-2">
              <div className="flex flex-col gap-1">
                <p className="w-48 h-2 rounded bg-element-background-solid/40"></p>
                <p className="w-48 h-2 rounded bg-element-background-solid/40"></p>
                <div className="flex items-center gap-3">
                  <UsersIcon className="w-5 h-5" />
                  <p className="w-40 h-2 rounded bg-element-background-solid/40"></p>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <div className="w-24 h-8 rounded-6px bg-element-background" />
                <p className="w-24 h-2 rounded bg-element-background-solid/40"></p>
              </div>
            </div>
          </div>
        )}

        {/* When rooms not set yet */}
        {currentStep === ADD_RENTAL_STEPS.ROOMS && (
          <div className="flex flex-col px-2 py-4 border-b-1 border-element-border">
            <p className="text-base font-semibold text-high-contrast">
              {rental?.infos?.name}
            </p>

            <div className="flex items-center justify-between mt-2">
              <div className="flex flex-col">
                <p className="font-light text-low-contrast">
                  {rental?.infos?.address}, {rental?.infos?.postal_code}{" "}
                  {rental?.infos?.city}
                </p>
                <p className="font-light text-low-contrast">
                  {t("AddRental.Summary.rooms_bathrooms", {
                    count: rental?.infos?.bathrooms,
                  })}
                </p>
                <div className="flex items-center gap-3">
                  <UsersIcon className="w-5 h-5" />
                  <p className="font-light text-low-contrast">
                    {t("Global.adults", { count: rental?.infos?.maxAdults })} -{" "}
                    {t("Global.children", {
                      count: rental?.infos?.maxChildren,
                    })}
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <div className="flex items-center h-8 gap-1 p-2 rounded-6px bg-element-background">
                  <HomeIcon className="w-4 h-4" />
                  <p className="font-semibold text-low-contrast text-[7px]">
                    {getRentingLabel()}
                  </p>
                </div>
                <p className="w-24 h-2 rounded bg-element-background-solid/40"></p>
              </div>
            </div>
          </div>
        )}

        {/* When rooms are set */}
        {currentStep > ADD_RENTAL_STEPS.ROOMS && (
          <div className="flex flex-col px-2 py-4 border-b-1 border-element-border">
            <p className="text-base font-semibold text-high-contrast">
              {rental?.infos?.name}
            </p>

            <div className="flex items-center justify-between mt-2">
              <div className="flex flex-col">
                <p className="font-light text-low-contrast">
                  {rental?.infos?.address}, {rental?.infos?.postal_code}{" "}
                  {rental?.infos?.city}
                </p>
                <p className="font-light text-low-contrast">
                  {t("AddRental.Summary.rooms_only", {
                    count: rental?.rooms?.length,
                  })}{" "}
                  {t("AddRental.Summary.beds_only", {
                    count: getTotalBeds(),
                  })}{" "}
                  {t("AddRental.Summary.bathrooms_only", {
                    count: rental?.infos?.bathrooms,
                  })}{" "}
                </p>
                <div className="flex items-center gap-3">
                  <UsersIcon className="w-5 h-5" />
                  <p className="font-light text-low-contrast">
                    {t("Global.adults", { count: rental?.infos?.maxAdults })} -{" "}
                    {t("Global.children", {
                      count: rental?.infos?.maxChildren,
                    })}
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-1">
                <div className="flex items-center w-24 h-8 gap-1 p-2 rounded-6px bg-element-background">
                  <HomeIcon className="w-4 h-4" />
                  <p className="font-semibold text-low-contrast text-[7px]">
                    {getRentingLabel()}
                  </p>
                </div>
                {currentStep <= ADD_RENTAL_STEPS.TARIFICATION ? (
                  <p className="w-24 h-2 rounded bg-element-background-solid/40"></p>
                ) : (
                  <p className="font-semibold text-high-contrast">
                    {rental?.tarification?.priceByNight}{" "}
                    {getCurrencySigle(rental?.tarification?.currency ?? "")}{" "}
                    {t("Global.byNights").toLowerCase()}
                  </p>
                )}
              </div>
            </div>
          </div>
        )}

        {/* Facilities */}
        {currentStep <= ADD_RENTAL_STEPS.FACILITIES && (
          <div className="flex flex-col px-2 py-4 border-b-1 border-element-border">
            <div className="flex items-center gap-2">
              <CoffeeIcon className="w-4 h-4" />

              <p className="text-base font-semibold text-high-contrast">
                {t("AddRental.Summary.facilities")}
              </p>
            </div>

            <p className="w-40 h-2 mt-2 rounded bg-element-background-solid/40"></p>
          </div>
        )}
        {currentStep > ADD_RENTAL_STEPS.FACILITIES && (
          <div className="flex flex-col px-2 py-4 border-b-1 border-element-border">
            <div className="flex items-center gap-2">
              <CoffeeIcon className="w-4 h-4" />

              <p className="text-base font-semibold text-high-contrast">
                {t("AddRental.Summary.facilities")}
              </p>
            </div>

            {facilitiesGroups.map(
              (group) =>
                group.nameI18n === "Rental.Infos.Facilities.title_common" && (
                  <RentalFacilitiesViewGroup
                    group={group}
                    equipments={rental?.facilities}
                    displayBorder={false}
                  />
                )
            )}

            {facilitiesGroups && facilitiesGroups.length > 1 && (
              <div className=" flex p-1 bg-element-background justify-center items-center border-2 rounded-8px text-sm text-low-contrast  w-[40px]">
                ...
              </div>
            )}
          </div>
        )}

        {/* Description */}
        {currentStep <= ADD_RENTAL_STEPS.PHOTOS ? (
          <div className="flex flex-col px-2 py-4 border-b-1 border-element-border">
            <div className="flex items-center gap-2">
              <FileTextIcon className="w-4 h-4" />

              <p className="text-base font-semibold text-high-contrast">
                {t("AddRental.Summary.description")}
              </p>
            </div>

            <p className="w-40 h-2 mt-2 rounded bg-element-background-solid/40"></p>
          </div>
        ) : (
          <div className="flex flex-col px-2 py-4 border-b-1 border-element-border">
            <div className="flex items-center gap-2">
              <FileTextIcon className="w-4 h-4" />

              <p className="text-base font-semibold text-high-contrast">
                {t("AddRental.Summary.description")}
              </p>
            </div>

            {rental?.photos?.description &&
            rental?.photos?.description?.length > 500 ? (
              <div className="flex flex-col">
                <p className="font-light text-low-contrast">
                  {" "}
                  {rental?.photos?.description.substring(0, 250)}
                </p>
                {
                  rental?.photos?.description?.length > 250 && (
                        <p className="flex bg-element-background w-[20px]  justify-center items-center border-2 rounded-8px text-sm">
                          ...
                        </p>
                    )
                }

              </div>
            ) : (
                <p className="font-light text-low-contrast">
                  {" "}
                {rental?.photos?.description}
              </p>
            )}
          </div>
        )}

        {/* Rules */}
        {currentStep <= ADD_RENTAL_STEPS.RULES && (
          <div className="flex gap-3 px-2 py-4">
            <div className="flex-1">
              <p className="text-xs font-light text-low-contrast">
                {t("AddRental.Summary.minCheckin")}
              </p>
              <p className="w-full h-2 mt-1 rounded bg-element-background-solid/40"></p>
            </div>

            <div className="flex-1">
              <p className="text-xs font-light text-low-contrast">
                {t("AddRental.Summary.maxCheckin")}
              </p>
              <p className="w-full h-2 mt-1 rounded bg-element-background-solid/40"></p>
            </div>

            <div className="flex-1">
              <p className="text-xs font-light text-low-contrast">
                {t("AddRental.Summary.checkout")}
              </p>
              <p className="w-full h-2 mt-1 rounded bg-element-background-solid/40"></p>
            </div>
          </div>
        )}

        {currentStep > ADD_RENTAL_STEPS.RULES && (
          <div className="flex gap-3 px-2 py-4">
            <div className="flex-1">
              <p className="text-xs font-light text-low-contrast">
                {t("AddRental.Summary.minCheckin")}
              </p>
              <p className="font-semibold text-high-contrast">
                {rental?.rules?.minTimein}
              </p>
            </div>

            <div className="flex-1">
              <p className="text-xs font-light text-low-contrast">
                {t("AddRental.Summary.maxCheckin")}
              </p>
              <p className="font-semibold text-high-contrast">
                {rental?.rules?.maxTimein}
              </p>
            </div>

            <div className="flex-1">
              <p className="text-xs font-light text-low-contrast">
                {t("AddRental.Summary.checkout")}
              </p>
              <p className="font-semibold text-high-contrast">
                {rental?.rules?.minTimeout}
              </p>
            </div>
          </div>
        )}
      </div>

      {/* Flex full space */}
      <div className="flex-1" />

      {/* Steps */}
      <div className="px-10 pt-4 pb-8">
        <p className="font-semibold text-low-contrast">
          {currentStep <= 6 && (
            <>
              {t("Global.step")} {currentStep <= 6 ? currentStep === 1.5 ? 1: currentStep  : 6} / 6
            </>
          )}

          {currentStep === ADD_RENTAL_STEPS.DONE && <>{t("Global.hereItIs")}</>}

          {currentStep === ADD_RENTAL_STEPS.SEASON && (
            <>{t("Global.optionalStep")}</>
          )}
        </p>

        <p className="mt-2 text-lg font-semibold text-high-contrast">
          {getStepTitle()}
        </p>

        <p className="mt-3 font-light text-low-contrast">
          {getStepDescription()}
        </p>

        <StepProgress maxStep={6} currentStep={currentStep === 1.5 ? 1: currentStep} />
      </div>
    </div>
  );
};
