import React from "react";
import { useTranslation } from "react-i18next";
import { cn } from "../../../helpers/classHelper";

export const PaymentDepositListItemSkeleton: React.FC<{}> = () => {
  return (
    <>
      <tr className="cursor-pointer hover:bg-element-background/40">
        <td
          className={cn(
            `flex flex-row items-center px-4 py-4 space-x-4 text-sm border-element-border border-t-1`
          )}
        >
          <div className="flex items-center">
            <div className="relative">
              <div className="object-fill w-12 h-12 overflow-hidden rounded-4px border-1 border-element-border/50 bg-slate-200"></div>
            </div>
          </div>
          <div className="flex flex-col space-y-1">
            <p className="text-base font-semibold tracking-normal text-high-contrast h-2 bg-slate-300 rounded w-52"></p>
            <div>
              <p className="font-light tracking-normal text-low-contrast h-2 bg-slate-200 rounded w-40 mt-1"></p>
              <p className="font-light tracking-normal text-low-contrast h-2 bg-slate-200 rounded w-40 mt-1"></p>
            </div>
          </div>
        </td>

        <td className={cn(`px-2 py-4 border-element-border border-t-1`)}>
          <p className="h-2 bg-slate-300 w-40 rounded" />
        </td>

        <td className={cn(`px-2 py-4 border-element-border border-t-1`)}>
          <p className="h-2 bg-slate-300 w-40 rounded" />
        </td>

        <td className={cn(`px-2 py-4 border-element-border border-t-1`)}>
          <p className="h-2 bg-slate-300 w-40 rounded" />
        </td>

        <td className={cn(`px-2 py-4 border-element-border border-t-1`)}>
          <p className="h-2 bg-slate-300 w-40 rounded" />
        </td>

        <td className={cn(`px-2 py-4 border-element-border border-t-1`)}>
          <p className="h-2 bg-slate-300 w-16 rounded" />
        </td>
      </tr>
    </>
  );
};
