import AfghanistanFlag from "../assets/flags/Afghanistan.svg?react";
import AlbaniaFlag from "../assets/flags/Albania.svg?react";
import AlgeriaFlag from "../assets/flags/Algeria.svg?react";
import AndorraFlag from "../assets/flags/Andorra.svg?react";
import AngolaFlag from "../assets/flags/Angola.svg?react";
import AntiguaFlag from "../assets/flags/Antigua and Barbuda.svg?react";
import ArgentinaFlag from "../assets/flags/Argentina.svg?react";
import ArmeniaFlag from "../assets/flags/Armenia.svg?react";
import ArubaFlag from "../assets/flags/Aruba.svg?react";
import AustraliaFlag from "../assets/flags/Australia.svg?react";
import AustriaFlag from "../assets/flags/Austria.svg?react";
import AzerbaijanFlag from "../assets/flags/Azerbaijan.svg?react";
import BahamasFlag from "../assets/flags/Bahamas.svg?react";
import BahrainFlag from "../assets/flags/Bahrain.svg?react";
import BangladeshFlag from "../assets/flags/Bangladesh.svg?react";
import BarbadosFlag from "../assets/flags/Barbados.svg?react";
import BelarusFlag from "../assets/flags/Belarus.svg?react";
import BelgiumFlag from "../assets/flags/Belgium.svg?react";
import BelizeFlag from "../assets/flags/Belize.svg?react";
import BeninFlag from "../assets/flags/Benin.svg?react";
import BhutanFlag from "../assets/flags/Bhutan.svg?react";
import BoliviaFlag from "../assets/flags/Bolivia.svg?react";
import BosniaFlag from "../assets/flags/Bosnia and Herzegovina.svg?react";
import BotswanaFlag from "../assets/flags/Botswana.svg?react";
import BrazilFlag from "../assets/flags/Brazil.svg?react";
import BruneiFlag from "../assets/flags/Brunei darussalam.svg?react";
import BulgariaFlag from "../assets/flags/Bulgaria.svg?react";
import BurkinaFlag from "../assets/flags/Burkina faso.svg?react";
import BurundiFlag from "../assets/flags/Burundi.svg?react";
import CambodiaFlag from "../assets/flags/Cambodia.svg?react";
import CameroonFlag from "../assets/flags/Cameroon.svg?react";
import CanadaFlag from "../assets/flags/Canada.svg?react";
import CapeFlag from "../assets/flags/Cape verde.svg?react";
import CentralAfricanFlag from "../assets/flags/Central African Republic.svg?react";
import ChadFlag from "../assets/flags/Chad.svg?react";
import ChileFlag from "../assets/flags/Chile.svg?react";
import ChinaFlag from "../assets/flags/China.svg?react";
import ColombiaFlag from "../assets/flags/Colombia.svg?react";
import ComorosFlag from "../assets/flags/Comoros.svg?react";
import CongoFlag from "../assets/flags/Congo.svg?react";
import CostaFlag from "../assets/flags/Costa rica.svg?react";
import CroatiaFlag from "../assets/flags/Croatia.svg?react";
import CubaFlag from "../assets/flags/Cuba.svg?react";
import CuraFlag from "../assets/flags/Cura ao.svg?react";
import CyprusFlag from "../assets/flags/Cyprus.svg?react";
import CzechFlag from "../assets/flags/Czech Republic.svg?react";
import DenmarkFlag from "../assets/flags/Denmark.svg?react";
import DjiboutiFlag from "../assets/flags/Djibouti.svg?react";
import DominicaFlag from "../assets/flags/Dominica.svg?react";
import DominicanRepublicFlag from "../assets/flags/Dominican Republic.svg?react";
import DrCongoFlag from "../assets/flags/Dr congo.svg?react";
import EcuadorFlag from "../assets/flags/Ecuador.svg?react";
import EgyptFlag from "../assets/flags/Egypt.svg?react";
import ElSalvadorFlag from "../assets/flags/El Salvador.svg?react";
import EquatorialGuineaFlag from "../assets/flags/Equatorial Guinea.svg?react";
import EritreaFlag from "../assets/flags/Eritrea.svg?react";
import EstoniaFlag from "../assets/flags/Estonia.svg?react";
import EswatiniFlag from "../assets/flags/Eswatini.svg?react";
import EthiopiaFlag from "../assets/flags/Ethiopia.svg?react";
import FijiFlag from "../assets/flags/Fiji.svg?react";
import FinlandFlag from "../assets/flags/Finland.svg?react";
import FranceFlag from "../assets/flags/France.svg?react";
import GabonFlag from "../assets/flags/Gabon.svg?react";
import GambiaFlag from "../assets/flags/Gambia.svg?react";
import GeorgiaFlag from "../assets/flags/Georgia.svg?react";
import GermanyFlag from "../assets/flags/Germany.svg?react";
import GhanaFlag from "../assets/flags/Ghana.svg?react";
import GreeceFlag from "../assets/flags/Greece.svg?react";
import GrenadaFlag from "../assets/flags/Grenada.svg?react";
import GuatemalaFlag from "../assets/flags/Guatemala.svg?react";
import GuineaBissauFlag from "../assets/flags/Guinea Bissau.svg?react";
import GuineaFlag from "../assets/flags/Guinea.svg?react";
import GuyanaFlag from "../assets/flags/Guyana.svg?react";
import HaitiFlag from "../assets/flags/Haiti.svg?react";
import HolySeeFlag from "../assets/flags/Holy see.svg?react";
import HondurasFlag from "../assets/flags/Honduras.svg?react";
import HongKongFlag from "../assets/flags/Hong kong.svg?react";
import HungaryFlag from "../assets/flags/Hungary.svg?react";
import IcelandFlag from "../assets/flags/Iceland.svg?react";
import IndiaFlag from "../assets/flags/India.svg?react";
import IndonesiaFlag from "../assets/flags/Indonesia.svg?react";
import IranFlag from "../assets/flags/Iran.svg?react";
import IraqFlag from "../assets/flags/Iraq.svg?react";
import IrelandFlag from "../assets/flags/Ireland.svg?react";
import IsraelFlag from "../assets/flags/Israel.svg?react";
import ItalyFlag from "../assets/flags/Italy.svg?react";
import IvoryCoastFlag from "../assets/flags/Ivory Coast.svg?react";
import JamaicaFlag from "../assets/flags/Jamaica.svg?react";
import JapanFlag from "../assets/flags/Japan.svg?react";
import JordanFlag from "../assets/flags/Jordan.svg?react";
import KazakhstanFlag from "../assets/flags/Kazakhstan.svg?react";
import KenyaFlag from "../assets/flags/Kenya.svg?react";
import KuwaitFlag from "../assets/flags/Kuwait.svg?react";
import KyrgyzstanFlag from "../assets/flags/Kyrgyzstan.svg?react";
import LaosFlag from "../assets/flags/Laos.svg?react";
import LatviaFlag from "../assets/flags/Latvia.svg?react";
import LebanonFlag from "../assets/flags/Lebanon.svg?react";
import LesothoFlag from "../assets/flags/Lesotho.svg?react";
import LiberiaFlag from "../assets/flags/Liberia.svg?react";
import LibyaFlag from "../assets/flags/Libya.svg?react";
import LiechtensteinFlag from "../assets/flags/Liechtenstein.svg?react";
import LithuaniaFlag from "../assets/flags/Lithuania.svg?react";
import LuxembourgFlag from "../assets/flags/Luxembourg.svg?react";
import MadagascarFlag from "../assets/flags/Madagascar.svg?react";
import MalawiFlag from "../assets/flags/Malawi.svg?react";
import MalaysiaFlag from "../assets/flags/Malaysia.svg?react";
import MaldivesFlag from "../assets/flags/Maldives.svg?react";
import MaliFlag from "../assets/flags/Mali.svg?react";
import MaltaFlag from "../assets/flags/Malta.svg?react";
import MarshallIslandsFlag from "../assets/flags/Marshall Islands.svg?react";
import MauritaniaFlag from "../assets/flags/Mauritania.svg?react";
import MauritiusFlag from "../assets/flags/Mauritius.svg?react";
import MexicoFlag from "../assets/flags/Mexico.svg?react";
import MicronesiaFlag from "../assets/flags/Micronesia.svg?react";
import MoldovaFlag from "../assets/flags/Moldova.svg?react";
import MongoliaFlag from "../assets/flags/Mongolia.svg?react";
import MontenegroFlag from "../assets/flags/Montenegro.svg?react";
import MoroccoFlag from "../assets/flags/Morocco.svg?react";
import MozambiqueFlag from "../assets/flags/Mozambique.svg?react";
import MyanmarFlag from "../assets/flags/Myanmar.svg?react";
import NamibiaFlag from "../assets/flags/Namibia.svg?react";
import NauruFlag from "../assets/flags/Nauru.svg?react";
import NepalFlag from "../assets/flags/Nepal.svg?react";
import NetherlandsFlag from "../assets/flags/Netherlands.svg?react";
import NewZealandFlag from "../assets/flags/New Zealand.svg?react";
import NicaraguaFlag from "../assets/flags/Nicaragua.svg?react";
import NigerFlag from "../assets/flags/Niger.svg?react";
import NigeriaFlag from "../assets/flags/Nigeria.svg?react";
import NorthMacedoniaFlag from "../assets/flags/North Macedonia.svg?react";
import NorwayFlag from "../assets/flags/Norway.svg?react";
import OmanFlag from "../assets/flags/Oman.svg?react";
import PakistanFlag from "../assets/flags/Pakistan.svg?react";
import PalauFlag from "../assets/flags/Palau.svg?react";
import PalestineFlag from "../assets/flags/Palestine.svg?react";
import PanamaFlag from "../assets/flags/Panama.svg?react";
import PapuaFlag from "../assets/flags/Papua New Guinea.svg?react";
import ParaguayFlag from "../assets/flags/Paraguay.svg?react";
import PeruFlag from "../assets/flags/Peru.svg?react";
import PhilippinesFlag from "../assets/flags/Philippines.svg?react";
import PolandFlag from "../assets/flags/Poland.svg?react";
import PortugalFlag from "../assets/flags/Portugal.svg?react";
import QatarFlag from "../assets/flags/Qatar.svg?react";
import RomaniaFlag from "../assets/flags/Romania.svg?react";
import RussiaFlag from "../assets/flags/Russian Federation.svg?react";
import RwandaFlag from "../assets/flags/Rwanda.svg?react";
import SaintLuciaFlag from "../assets/flags/Saint lucia.svg?react";
import SaintVincentFlag from "../assets/flags/Saint Vincent and the Grenadines.svg?react";
import SamoaFlag from "../assets/flags/Samoa.svg?react";
import SanMarinoFlag from "../assets/flags/San marino.svg?react";
import SaoTomeAndPrincipeFlag from "../assets/flags/Sao Tome and Principe.svg?react";
import SaudiArabiaFlag from "../assets/flags/Saudi arabia.svg?react";
import SenegalFlag from "../assets/flags/Senegal.svg?react";
import SerbiaFlag from "../assets/flags/Serbia.svg?react";
import SeychellesFlag from "../assets/flags/Seychelles.svg?react";
import SierraLeoneFlag from "../assets/flags/Sierra Leone.svg?react";
import SingaporeFlag from "../assets/flags/Singapore.svg?react";
import SintMaartenFlag from "../assets/flags/Sint Maarten.svg?react";
import SlovakiaFlag from "../assets/flags/Slovakia.svg?react";
import SloveniaFlag from "../assets/flags/Slovenia.svg?react";
import SolomonFlag from "../assets/flags/Solomon Islands.svg?react";
import SomaliaFlag from "../assets/flags/Somalia.svg?react";
import SouthAfricaFlag from "../assets/flags/South africa.svg?react";
import SouthKoreanFlag from "../assets/flags/South korea.svg?react";
import SouthSudanFlag from "../assets/flags/South sudan.svg?react";
import SpainFlag from "../assets/flags/Spain.svg?react";
import SriLankaFlag from "../assets/flags/Sri lanka.svg?react";
import SudanFlag from "../assets/flags/Sudan.svg?react";
import SurinameFlag from "../assets/flags/Suriname.svg?react";
import SwedenFlag from "../assets/flags/Sweden.svg?react";
import SwitzerlandFlag from "../assets/flags/Switzerland.svg?react";
import SyrianFlag from "../assets/flags/Syrian Arab Republic.svg?react";
import TajikistanFlag from "../assets/flags/Tajikistan.svg?react";
import TanzaniaFlag from "../assets/flags/Tanzania.svg?react";
import ThailandFlag from "../assets/flags/Thailand.svg?react";
import TimorFlag from "../assets/flags/Timor leste.svg?react";
import TogoFlag from "../assets/flags/Togo.svg?react";
import TongaFlag from "../assets/flags/Tonga.svg?react";
import TrinidadFlag from "../assets/flags/Trinidad and Tobago.svg?react";
import TunisiaFlag from "../assets/flags/Tunisia.svg?react";
import TurkeyFlag from "../assets/flags/Turkey.svg?react";
import TurkmenistanFlag from "../assets/flags/Turkmenistan.svg?react";
import TuvaluFlag from "../assets/flags/Tuvalu.svg?react";
import UgandaFlag from "../assets/flags/Uganda.svg?react";
import UkraineFlag from "../assets/flags/Ukraine.svg?react";
import UAEFlag from "../assets/flags/United Arab Emirates.svg?react";
import UKFlag from "../assets/flags/United Kingdom.svg?react";
import UruguayFlag from "../assets/flags/Uruguay.svg?react";
import USAFlag from "../assets/flags/USA.svg?react";
import UzbekistanFlag from "../assets/flags/Uzbekistan.svg?react";
import VanuatuFlag from "../assets/flags/Vanuaty.svg?react";
import VenezuelaFlag from "../assets/flags/Venezuela.svg?react";
import VietnamFlag from "../assets/flags/Viet nam.svg?react";
import VirginIslandsFlag from "../assets/flags/Virgin islands.svg?react";
import YemenFlag from "../assets/flags/Yemen.svg?react";
import ZambiaFlag from "../assets/flags/Zambia.svg?react";
import ZimbabweFlag from "../assets/flags/Zimbabwe.svg?react";

export default  [
  { value: 1, Icon: AfghanistanFlag, label: "Afghanistan", phone: "+93", code: "AF"},
  { value: 2, Icon: AlbaniaFlag, label: "Albania", phone: "+355", code: "AL"},
  { value: 3, Icon: AlgeriaFlag, label: "Algeria", phone: "+213", code: "DZ"},
  { value: 4, Icon: AndorraFlag, label: "Andorra", phone: "+376", code: "AD"},
  { value: 5, Icon: AngolaFlag, label: "Angola", phone: "+244", code: "AO"},
  { value: 6, Icon: ArgentinaFlag, label: "Argentina", phone: "+54", code: "AR"},
  { value: 7, Icon: ArmeniaFlag, label: "Armenia", phone: "+374", code: "AM"},
  { value: 8, Icon: AustraliaFlag, label: "Australia", phone: "+61", code: "AU"},
  { value: 9, Icon: AustriaFlag, label: "Austria", phone: "+43", code: "AT"},
  { value: 10, Icon: AzerbaijanFlag, label: "Azerbaijan", phone: "+994", code: "AZ"},
  { value: 11, Icon: BahamasFlag, label: "Bahamas", phone: "+1-242", code: "BS"},
  { value: 12, Icon: BahrainFlag, label: "Bahrain", phone: "+973" , code: "BH"},
  { value: 13, Icon: BangladeshFlag, label: "Bangladesh", phone: "+880" , code: "BD"},
  { value: 14, Icon: BarbadosFlag, label: "Barbados", phone: "+1-246", code: "BB"},
  { value: 15, Icon: BelarusFlag, label: "Belarus", phone: "+375", code: "BY"},
  { value: 16, Icon: BelgiumFlag, label: "Belgium", phone: "+32", code: "BE"},
  { value: 17, Icon: BelizeFlag, label: "Belize", phone: "+501" , code: "BZ"},
  { value: 18, Icon: BeninFlag, label: "Benin", phone: "+229",  code: "BJ"},
  { value: 19, Icon: BhutanFlag, label: "Bhutan", phone: "+975", code: "BT"},
  { value: 20, Icon: BoliviaFlag, label: "Bolivia", phone: "+591", code: "BO"},
  { value: 21, Icon: BosniaFlag , label: "Bosnia and Herzegovina", phone: "+387", code: "BA"},
  { value: 22, Icon: BotswanaFlag, label: "Botswana", phone: "+267", code: "BW"},
  { value: 23, Icon: BrazilFlag, label: "Brazil", phone: "+55", code: "BR"},
  { value: 24, Icon: BruneiFlag, label: "Brunei", phone: "+673", code: "BN"},
  { value: 25, Icon: BulgariaFlag, label: "Bulgaria", phone: "+359", code: "BG"},
  { value: 26, Icon: BurkinaFlag, label: "Burkina Faso", phone: "+226", code: "BF"},
  { value: 27, Icon: BurundiFlag, label: "Burundi", phone: "+257", code: "BI"},
  { value: 28, Icon: CapeFlag, label: "Cabo Verde", phone: "+238", code: "CV"},
  { value: 29, Icon: CambodiaFlag, label: "Cambodia", phone: "+855", code: "KH"},
  { value: 30, Icon: CameroonFlag, label: "Cameroon", phone: "+237", code: "CM"},
  { value: 31, Icon: CanadaFlag, label: "Canada", phone: "+1" , code: "CA"},
  { value: 32, Icon: CentralAfricanFlag, label: "Central African Republic", phone: "+236" , code: "CF"},
  { value: 33, Icon: ChadFlag, label: "Chad", phone: "+235", code: "TD"},
  { value: 34, Icon: ChileFlag, label: "Chile", phone: "+56" , code: "CL"},
  { value: 35, Icon: ChinaFlag, label: "China", phone: "+86", code: "CN"},
  { value: 36, Icon: ColombiaFlag, label: "Colombia", phone: "+57", code: "CO"},
  { value: 37, Icon: ComorosFlag, label: "Comoros", phone: "+269", code: "KM"},
  { value: 38, Icon: CongoFlag, label: "Congo (Democratic Republic)", phone: "+243", code: "CD"},
  { value: 39, Icon: DrCongoFlag, label: "Congo (Republic)", phone: "+242" , code: "CG"},
  { value: 40, Icon: CostaFlag, label: "Costa Rica", phone: "+506" ,  code: "CR"},
  { value: 41, Icon: CroatiaFlag, label: "Croatia", phone: "+385", code: "HR"},
  { value: 42, Icon: CubaFlag, label: "Cuba", phone: "+53", code: "CU"},
  { value: 43, Icon: CyprusFlag, label: "Cyprus", phone: "+357", code: "CY"},
  { value: 44, Icon: CzechFlag, label: "Czech Republic", phone: "+420", code: "CZ"},
  { value: 45, Icon: DenmarkFlag, label: "Denmark", phone: "+45", code: "DK"},
  { value: 46, Icon: DjiboutiFlag, label: "Djibouti", phone: "+253",  code: "DJ"},
  { value: 47, Icon: DominicaFlag, label: "Dominica", phone: "+1-767", code: "DM"},
  { value: 48, Icon: DominicanRepublicFlag, label: "Dominican Republic", phone: "+1-809", code: "DO"},
  { value: 49, Icon: EcuadorFlag, label: "Ecuador", phone: "+593", code: "EC"},
  { value: 50, Icon: EgyptFlag, label: "Egypt", phone: "+20", code: "EG"},
  { value: 51, Icon: ElSalvadorFlag, label: "El Salvador", phone: "+503", code: "SV"},
  { value: 52, Icon: EquatorialGuineaFlag, label: "Equatorial Guinea", phone: "+240", code: "GQ"},
  { value: 53, Icon: EritreaFlag, label: "Eritrea", phone: "+291" , code: "ER"},
  { value: 54, Icon: EstoniaFlag, label: "Estonia", phone: "+372", code: "EE"},
  { value: 55, Icon: EswatiniFlag, label: "Eswatini", phone: "+268" , code: "SZ"},
  { value: 56, Icon: EthiopiaFlag, label: "Ethiopia", phone: "+251", code: "ET"},
  { value: 57, Icon: FijiFlag, label: "Fiji", phone: "+679", code: "FJ"},
  { value: 58, Icon: FinlandFlag, label: "Finland", phone: "+358", code: "FI"},
  { value: 59, Icon: HungaryFlag, label: "Hungary", phone: "+36", code: "HU"},
  { value: 60, Icon: GabonFlag, label: "Gabon", phone: "+241", code: "GA"},
  { value: 61, Icon: GambiaFlag, label: "Gambia", phone: "+220", code: "GM"},
  { value: 62, Icon: GeorgiaFlag, label: "Georgia", phone: "+995", code: "GE"},
  { value: 63, Icon: GermanyFlag, label: "Germany", phone: "+49", code: "DE"},
  { value: 64, Icon: GhanaFlag, label: "Ghana", phone: "+233", code: "GH"},
  { value: 65, Icon: GreeceFlag, label: "Greece", phone: "+30" , code: "GR"},
  { value: 66, Icon: GrenadaFlag, label: "Grenada", phone: "+1-473", code: "GD"},
  { value: 67, Icon: GuatemalaFlag, label: "Guatemala", phone: "+502", code: "GT"},
  { value: 68, Icon: GuineaFlag, label: "Guinea", phone: "+224", code: "GN"},
  { value: 69, Icon: GuineaBissauFlag, label: "Guinea-Bissau", phone: "+245", code: "GW"},
  { value: 70, Icon: GuyanaFlag, label: "Guyana", phone: "+592", code: "GY"},
  { value: 71, Icon: HaitiFlag, label: "Haiti", phone: "+509", code: "HT" },
  { value: 72, Icon: HondurasFlag, label: "Honduras", phone: "+504", code: "HN" },
  { value: 73, Icon: FranceFlag, label: "France", phone: "+33" , code: "FR"},
  { value: 74, Icon: IcelandFlag, label: "Iceland", phone: "+354" , code: "IS"},
  { value: 75, Icon: IndiaFlag, label: "India", phone: "+91", code: "IN"},
  { value: 76, Icon: IndonesiaFlag, label: "Indonesia", phone: "+62", code: "ID"},
  { value: 77, Icon: IranFlag, label: "Iran", phone: "+98", code: "IR"},
  { value: 78, Icon: IraqFlag, label: "Iraq", phone: "+964" , code: "IQ"},
  { value: 79, Icon: IrelandFlag, label: "Ireland", phone: "+353" , code: "IE"},
  { value: 80, Icon: IsraelFlag, label: "Israel", phone: "+972" , code: "IL"},
  { value: 81, Icon: ItalyFlag, label: "Italy", phone: "+39", code: "IT"},
  { value: 82, Icon: JamaicaFlag, label: "Jamaica", phone: "+1-876", code: "JM"},
  { value: 83, Icon: JapanFlag, label: "Japan", phone: "+81", code: "JP"},
  { value: 84, Icon: JordanFlag, label: "Jordan", phone: "+962" , code: "JO"},
  { value: 85, Icon: KazakhstanFlag, label: "Kazakhstan", phone: "+7" , code: "KZ"},
  { value: 86, Icon: KenyaFlag, label: "Kenya", phone: "+254",  code: "KE"},
  { value: 90, Icon: KuwaitFlag, label: "Kuwait", phone: "+965", code: "KW"},
  { value: 91, Icon: KyrgyzstanFlag, label: "Kyrgyzstan", phone: "+996" , code: "KG"},
  { value: 92, Icon: LaosFlag, label: "Laos", phone: "+856", code: "LA"},
  { value: 93, Icon: LatviaFlag, label: "Latvia", phone: "+371" , code: "LV"},
  { value: 94, Icon: LebanonFlag, label: "Lebanon", phone: "+961" , code: "LB"},
  { value: 95, Icon: LesothoFlag, label: "Lesotho", phone: "+266", code: "LS"},
  { value: 96, Icon: LiberiaFlag, label: "Liberia", phone: "+231", code: "LR"},
  { value: 97, Icon: LibyaFlag, label: "Libya", phone: "+218", code: "LY"},
  { value: 98, Icon: LiechtensteinFlag, label: "Liechtenstein", phone: "+423", code: "LI"},
  { value: 99, Icon: LithuaniaFlag, label: "Lithuania", phone: "+370", code: "LT"},
  { value: 100, Icon: LuxembourgFlag, label: "Luxembourg", phone: "+352", code: "LU"},
  { value: 101, Icon: MadagascarFlag, label: "Madagascar", phone: "+261", code: "MG"},
  { value: 102, Icon: MalawiFlag, label: "Malawi", phone: "+265", code: "MW"},
  { value: 103, Icon: MalaysiaFlag, label: "Malaysia", phone: "+60", code: "MY"},
  { value: 104, Icon: MaldivesFlag, label: "Maldives", phone: "+960", code: "MV"},
  { value: 105, Icon: MaliFlag, label: "Mali", phone: "+223", code: "ML"},
  { value: 106, Icon: MaltaFlag, label: "Malta", phone: "+356", code: "MT"},
  { value: 107, Icon: MarshallIslandsFlag, label: "Marshall Islands", phone: "+692",  code: "MH"},
  { value: 108, Icon: MauritaniaFlag, label: "Mauritania", phone: "+222" , code: "MR"},
  { value: 109, Icon: MauritiusFlag, label: "Mauritius", phone: "+230", code: "MU"},
  { value: 110, Icon: MexicoFlag, label: "Mexico", phone: "+52", code: "MX"},
  { value: 111, Icon: MicronesiaFlag, label: "Micronesia", phone: "+691", code: "FM"},
  { value: 112, Icon: MoldovaFlag, label: "Moldova", phone: "+373", code: "MD"},
  { value: 113, Icon: SyrianFlag, label: "Monaco", phone: "+377", code: "MC"},
  { value: 114, Icon: MongoliaFlag, label: "Mongolia", phone: "+976", code: "MN"},
  { value: 115, Icon: MontenegroFlag, label: "Montenegro", phone: "+382", code: "ME"},
  { value: 116, Icon: MoroccoFlag, label: "Morocco", phone: "+212", code: "MA"},
  { value: 117, Icon: MozambiqueFlag, label: "Mozambique", phone: "+258", code: "MZ"},
  { value: 118, Icon: MyanmarFlag, label: "Myanmar", phone: "+95", code: "MM"},
  { value: 119, Icon: NamibiaFlag, label: "Namibia", phone: "+264", code: "NA"},
  { value: 120, Icon: NauruFlag, label: "Nauru", phone: "+674", code: "NR"},
  { value: 121, Icon: NepalFlag, label: "Nepal", phone: "+977", code: "NP"},
  { value: 122, Icon: NetherlandsFlag, label: "Netherlands", phone: "+31", code: "NL"},
  { value: 123, Icon: NewZealandFlag, label: "New Zealand", phone: "+64", code: "NZ"},
  { value: 124, Icon: NicaraguaFlag, label: "Nicaragua", phone: "+505", code: "NI"},
  { value: 125, Icon: NigerFlag, label: "Niger", phone: "+227" , code: "NE"},
  { value: 126, Icon: NigeriaFlag, label: "Nigeria", phone: "+234", code: "NG"},
  { value: 127, Icon: NorthMacedoniaFlag, label: "North Macedonia", phone: "+389" , code: "MK"},
  { value: 128, Icon: NorwayFlag, label: "Norway", phone: "+47" , code: "NO"},
  { value: 129, Icon: OmanFlag, label: "Oman", phone: "+968", code: "OM"},
  { value: 130, Icon: PakistanFlag, label: "Pakistan", phone: "+92", code: "PK"},
  { value: 131, Icon: PalauFlag, label: "Palau", phone: "+680", code: "PW"},
  { value: 132, Icon: PalestineFlag, label: "Palestine", phone: "+970", code: "PS"},
  { value: 133, Icon: PanamaFlag, label: "Panama", phone: "+507", code: "PA"},
  { value: 134, Icon: SyrianFlag, label: "Papua New Guinea", phone: "+675", code: "PG"},
  { value: 135, Icon: ParaguayFlag, label: "Paraguay", phone: "+595", code: "PY"},
  { value: 136, Icon: PeruFlag, label: "Peru", phone: "+51", code: "PE"},
  { value: 137, Icon: PhilippinesFlag, label: "Philippines", phone: "+63" , code: "PH"},
  { value: 138, Icon: PolandFlag, label: "Poland", phone: "+48", code: "PL"},
  { value: 139, Icon: PortugalFlag, label: "Portugal", phone: "+351", code: "PT"},
  { value: 140, Icon: QatarFlag, label: "Qatar", phone: "+974", code: "QA"},
  { value: 141, Icon: RomaniaFlag, label: "Romania", phone: "+40", code: "RO"},
  { value: 142, Icon: RussiaFlag, label: "Russia", phone: "+7", code: "RU"},
  { value: 143, Icon: RwandaFlag, label: "Rwanda", phone: "+250", code: "RW"},
  { value: 144, Icon: SyrianFlag, label: "Saint Kitts and Nevis", phone: "+1-869", code: "KN"},
  { value: 145, Icon: SaintLuciaFlag, label: "Saint Lucia", phone: "+1-758" , code: "LC"},
  { value: 146, Icon: SaintVincentFlag, label: "Saint Vincent and the Grenadines", phone: "+1-784", code: "VC"},
  { value: 147, Icon: SamoaFlag, label: "Samoa", phone: "+685", code: "WS"},
  { value: 148, Icon: SanMarinoFlag, label: "San Marino", phone: "+378", code: "SM"},
  { value: 149, Icon: SaoTomeAndPrincipeFlag, label: "Sao Tome and Principe", phone: "+239", code: "ST"},
  { value: 150, Icon: SaudiArabiaFlag, label: "Saudi Arabia", phone: "+966", code: "SA"},
  { value: 151, Icon: SenegalFlag, label: "Senegal", phone: "+221", code: "SN"},
  { value: 152, Icon: SerbiaFlag, label: "Serbia", phone: "+381", code: "RS"},
  { value: 153, Icon: SeychellesFlag, label: "Seychelles", phone: "+248", code: "SC"},
  { value: 154, Icon: SierraLeoneFlag, label: "Sierra Leone", phone: "+232" , code: "SL"},
  { value: 155, Icon: SingaporeFlag, label: "Singapore", phone: "+65", code: "SG"},
  { value: 156, Icon: SlovakiaFlag, label: "Slovakia", phone: "+421", code: "SK"},
  { value: 157, Icon: SloveniaFlag, label: "Slovenia", phone: "+386", code: "SI"},
  { value: 158, Icon: SolomonFlag, label: "Solomon Islands", phone: "+677", code: "SB"},
  { value: 159, Icon: SomaliaFlag, label: "Somalia", phone: "+252", code: "SO"},
  { value: 160, Icon: SouthAfricaFlag, label: "South Africa", phone: "+27", code: "ZA"},
  { value: 161, Icon: SouthSudanFlag, label: "South Sudan", phone: "+211", code: "SS"},
  { value: 162, Icon: SpainFlag, label: "Spain", phone: "+34", code: "ES"},
  { value: 163, Icon: SriLankaFlag, label: "Sri Lanka", phone: "+94", code: "LK"},
  { value: 164, Icon: SudanFlag, label: "Sudan", phone: "+249", code: "SD"},
  { value: 165, Icon: SurinameFlag, label: "Suriname", phone: "+597", code: "SR"},
  { value: 166, Icon: SwedenFlag, label: "Sweden", phone: "+46", code: "SE"},
  { value: 167, Icon: SwitzerlandFlag, label: "Switzerland", phone: "+41", code: "CH"},
  { value: 168, Icon: SyrianFlag, label: "Syria", phone: "+963", code: "SY"},
  { value: 169, Icon: SyrianFlag, label: "Taiwan", phone: "+886" , code: "TW"},
  { value: 170, Icon: TajikistanFlag, label: "Tajikistan", phone: "+992", code: "TJ"},
  { value: 171, Icon: TanzaniaFlag, label: "Tanzania", phone: "+255", code: "TZ"},
  { value: 172, Icon: ThailandFlag, label: "Thailand", phone: "+66", code: "TH"},
  { value: 173, Icon: TimorFlag, label: "Timor-Leste", phone: "+670", code: "TL"},
  { value: 174, Icon: TogoFlag, label: "Togo", phone: "+228", code: "TG"},
  { value: 175, Icon: TongaFlag, label: "Tonga", phone: "+676", code: "TO"},
  { value: 176, Icon: TrinidadFlag, label: "Trinidad and Tobago", phone: "+1-868", code: "TT"},
  { value: 177, Icon: TunisiaFlag, label: "Tunisia", phone: "+216", code: "TN"},
  { value: 178, Icon: TurkeyFlag, label: "Turkey", phone: "+90" , code: "TR"},
  { value: 179, Icon: TurkmenistanFlag, label: "Turkmenistan", phone: "+993" , code: "TM"},
  { value: 180, Icon: TuvaluFlag, label: "Tuvalu", phone: "+688", code: "TV"},
  { value: 181, Icon: UgandaFlag, label: "Uganda", phone: "+256", code: "UG"},
  { value: 182, Icon: UkraineFlag, label: "Ukraine", phone: "+380", code: "UA"},
  { value: 183, Icon: UAEFlag, label: "United Arab Emirates", phone: "+971", code: "AE"},
  { value: 184, Icon: UKFlag, label: "United Kingdom", phone: "+44" , code: "GB"},
  { value: 185, Icon: USAFlag, label: "United States", phone: "+1", code: "US"},
  { value: 186, Icon: UruguayFlag, label: "Uruguay", phone: "+598", code: "UY"},
  { value: 187, Icon: UzbekistanFlag, label: "Uzbekistan", phone: "+998", code: "UZ"},
  { value: 188, Icon: VanuatuFlag, label: "Vanuatu", phone: "+678", code: "VU"},
  { value: 189, Icon: SyrianFlag, label: "Vatican City", phone: "+379", code: "VA"},
  { value: 190, Icon: VenezuelaFlag, label: "Venezuela", phone: "+58", code: "VE"},
  { value: 191, Icon: VietnamFlag, label: "Vietnam", phone: "+84", code: "VN"},
  { value: 192, Icon: YemenFlag, label: "Yemen", phone: "+967", code: "YE"},
  { value: 193, Icon: ZambiaFlag, label: "Zambia", phone: "+260", code: "ZM"},
  { value: 194, Icon: ZimbabweFlag, label: "Zimbabwe", phone: "+263" , code: "ZW"},
];
