import React from "react";

export const ShortcodeListPageSkeleton: React.FC<{}> = () => {
  return (
    <tr className="hover:bg-element-background/40 cursor-pointer animate-pulse">
      {/* name */}
      <td className="border-t-1 border-element-border text-sm px-2 py-3 flex flex-row items-center space-x-4">
        <p className="h-2 w-52 bg-slate-300 rounded"></p>
      </td>

      {/* description */}
      <td className="border-t-1 border-element-border text-sm px-2 py-2">
        <p className="h-2 w-52 bg-slate-300 rounded"></p>
      </td>

      {/* actions */}
      <td className="border-t-1 border-element-border text-sm px-2 py-2">
        <div className="flex gap-2">
          <div className="h-9 w-24 bg-slate-200 rounded-6px"></div>
          <div className="h-9 w-10 bg-slate-200 rounded-6px"></div>
        </div>
      </td>
    </tr>
  );
};
