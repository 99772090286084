import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import {
  useCreateRentalGroup,
  useUpdateRentalGroup,
} from "../../../../hooks/api/rentalGroups";
import { Button } from "../../../Common/Button/Button";
import { MultiSelect } from "../../../Common/MultiSelect/MultiSelect";
import { RightModal } from "../../../Common/RightModal/RightModal";
import { Separator } from "../../../Common/Separator/Separator";
import { TextInput } from "../../../Common/TextInput/TextInput";
import {
  useHandleSelectRental,
  useInitRentalGroup,
  useInitRentalGroupModalStates,
} from "./RentalGroupModal.hooks";
import {
  RentalGroupModalForm,
  RentalGroupModalProps,
} from "./RentalGroupModal.type";

export const RentalGroupModal: React.FC<RentalGroupModalProps> = ({
  isVisible,
  rentalGroup,
  useMode,
  onTriggerRemoveRentalGroup,
  onClose,
  onCreateGroupError,
  onUpdateGroupError,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const initStates = useInitRentalGroupModalStates(rentalGroup);
  const [validationLoading, setValidationLoading] =
    initStates.validationLoadingState;

  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, errors },
    getValues,
    setValue,
    trigger,
  } = useForm<RentalGroupModalForm>({
    mode: "all",
  });

  const [selectedRentals, setSelectedRentals] = initStates.selectedRentalsState;

  useInitRentalGroup(rentalGroup, useMode, selectedRentals, setValue, trigger);

  const handleCreateOrUpdate: SubmitHandler<RentalGroupModalForm> = () => {
    if (useMode === "create") {
      useCreateRentalGroup(
        getValues("name"),
        selectedRentals.join(","),
        () => navigate(0),
        (message: string | undefined) => onCreateGroupError(message),
        () => {
          onCreateGroupError(undefined);
          setValidationLoading(true);
        },
        () => {
          setValidationLoading(false);
        }
      );
    } else if (useMode === "update") {
      console.log("selectedRentals : ", selectedRentals.join(","));
      useUpdateRentalGroup(
        rentalGroup?.id!,
        getValues("name"),
        selectedRentals.join(","),
        () => navigate(0),
        (message: string | undefined) => onUpdateGroupError(message),
        () => {
          onUpdateGroupError(undefined);
          setValidationLoading(true);
        },
        () => {
          setValidationLoading(false);
        }
      );
    }
  };

  return (
    <RightModal
      isVisible={isVisible}
      title={
        useMode === "create"
          ? t("RentalList.addRentalGroupTitle")
          : t("RentalList.updateRentalGroupTitle")
      }
      onClose={validationLoading ? () => {} : onClose}
    >
      <div className="flex flex-col justify-between w-full">
        <div className="flex flex-col w-full gap-2 overflow-scroll">
          <p className="text-xs text-low-contrast">{t("Global.info")}</p>

          <div>
            <TextInput
              type="text"
              label={t("RentalList.FieldNames.rentalGroupName")}
              placeholder={t("RentalList.addRentalGroupNamePlaceholder")}
              disabled={validationLoading}
              register={register("name", {
                required: {
                  value: true,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("RentalList.FieldNames.rentalGroupName"),
                  }),
                },
              })}
              error={errors.name?.message}
            />
          </div>

          <Separator />

          <p className="text-xs text-low-contrast">
            {t("RentalList.rentalGroupTitle")}
          </p>

          <Controller
            control={control}
            name="rentals"
            rules={{
              required: true,
              validate: {
                notEmpty: () => {
                  return (
                    selectedRentals.length > 0 ||
                    t("RentalList.rentalGroupNameNotEmptyError").toString()
                  );
                },
              },
              onChange: (event) =>
                useHandleSelectRental(event.target.value, [
                  selectedRentals,
                  setSelectedRentals,
                ]),
            }}
            render={({ field: { onChange } }) => (
              <MultiSelect
                selectedRentals={selectedRentals}
                loading={validationLoading}
                options={{
                  withInfoText: false,
                  withSubmitButton: false,
                }}
                onSelectRental={onChange}
                onNext={() => {}}
                error={errors.rentals?.message}
              />
            )}
          />
        </div>

        <div>
          <Separator />

          <div className="flex gap-3">
            <Button
              type="secondary"
              onClick={onClose}
              disabled={validationLoading}
            >
              {t("Global.cancel")}
            </Button>
            {useMode === "update" ? (
              <Button
                type="destructive"
                onClick={onTriggerRemoveRentalGroup}
                disabled={validationLoading}
              >
                {t("Global.remove")}
              </Button>
            ) : null}
            <Button
              type="primary"
              RightIcon={CheckIcon}
              disabled={!isValid}
              loading={validationLoading}
              onClick={handleSubmit(handleCreateOrUpdate)}
            >
              {useMode === "create"
                ? t("RentalList.createRentalGroup")
                : t("Global.record")}
            </Button>
          </div>
        </div>
      </div>
    </RightModal>
  );
};
