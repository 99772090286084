import React from "react";

import UsersIcon from "../../assets/icons/users.svg?react";
import EmojiHappyIcon from "../../assets/icons/emoji-happy.svg?react";

export const ReservationItemSkeleton: React.FC<{}> = () => {
  return (
    <tr className="cursor-pointer hover:bg-element-background/40 animate-pulse">
      <td className="flex flex-row items-center px-2 py-2 space-x-4 text-sm border-t-1 border-element-border">
        <div className="relative">
          <div
            className="absolute z-10 border-2 border-white rounded-full bg-slate-300"
            style={{ top: 8, left: 26, width: 24, height: 24 }}
          />
          <div className="w-10 h-10 rounded-4px border-1 border-element-border/50 bg-slate-200" />
        </div>
        <div className="flex flex-col">
          <p className="h-2 font-semibold rounded text-high-contrast bg-slate-300 w-52"></p>
          <p className="w-32 h-2 mt-1 font-light rounded text-low-contrast bg-slate-200"></p>
        </div>
      </td>

      <td className="w-1/12 px-2 py-2 text-sm border-t-1 border-element-border">
        <div className="flex flex-col">
          <p className="w-32 h-2 font-semibold rounded text-high-contrast bg-slate-300"></p>
          <div className="flex flex-row items-center gap-1 mt-1 font-light text-low-contrast">
            <UsersIcon width={12} height={12} />
            <p className="w-12 h-2 rounded bg-slate-200"></p>
            <EmojiHappyIcon width={12} height={12} />
            <p className="w-12 h-2 rounded bg-slate-200"></p>
          </div>
        </div>
      </td>

      <td className="w-1/12 px-2 py-2 text-sm border-t-1 border-element-border">
        <div className="flex flex-col">
          <p className="w-32 h-2 font-semibold rounded text-high-contrast bg-slate-300"></p>
          <p className="w-16 h-2 mt-1 font-light rounded text-low-contrast bg-slate-200"></p>
        </div>
      </td>

      <td className="w-1/12 px-2 py-2 text-sm border-t-1 border-element-border">
        <div className="flex flex-col">
          <p className="w-32 h-2 font-semibold rounded text-high-contrast bg-slate-300"></p>
          <p className="w-16 h-2 mt-1 font-light rounded text-low-contrast bg-slate-200"></p>
        </div>
      </td>

      <td className="w-1/12 px-2 py-2 text-sm border-t-1 border-element-border">
        <div className="flex flex-col">
          <p className="w-32 h-2 font-semibold rounded text-high-contrast bg-slate-300"></p>
          <p className="font-light text-low-contrast"></p>
        </div>
      </td>

      <td className="w-1/12 px-2 py-2 text-sm border-t-1 border-element-border">
        <div className="h-9 w-28 bg-slate-200 rounded-6px"></div>
      </td>
    </tr>
  );
};
