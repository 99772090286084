import React from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "../../../assets/icons/chevron-right.svg?react";
import paths from "../../../constants/paths";
import { RentalTitleProps } from "./RentalLayout.type";

export const RentalTitle: React.FC<RentalTitleProps> = ({ rental }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-row items-center space-x-1">
      <h1
        className="m-0 text-lg font-bold cursor-pointer text-low-contrast"
        onClick={() => navigate(paths.RENTALS)}
      >
        {t("Rental.title")}
      </h1>

      <ChevronRightIcon />

      <img
        src={rental?.details.photos.cover}
        className="object-cover w-6 h-6 rounded-full"
      />
      <h1 className="pl-1 text-lg font-bold text-high-contrast">
        {rental?.details?.informations?.name}
      </h1>
    </div>
  );
};
