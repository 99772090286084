import React from "react";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import CheckCircleGreenIcon from "../../../assets/icons/check-circle-green.svg?react";
import { IconType } from "../../../types/commonTypes";
import { Button } from "../Button/Button";
import { ButtonType } from "../Button/Button.type";
import { TextInput } from "../TextInput/TextInput";

type CopyLinkProps = {
  label?: string | undefined;
  link: string;
  button?: {
    type: ButtonType;
    Icon?: IconType | undefined;
    text: ValueType;
    onClick: () => void;
  };
  isCopied: boolean;
};

export const CopyLink: React.FC<CopyLinkProps> = ({
  label,
  link,
  button,
  isCopied,
}) => {
  return (
    <div className="flex flex-col gap-1">
      {label ? (
        <p className="font-semibold text-high-contrast">{label}</p>
      ) : null}
      <div className="flex gap-4">
        <TextInput type="clipboard" value={link} size="large" />

        {isCopied ? (
          <div>
            <CheckCircleGreenIcon className="w-9 h-9" />
          </div>
        ) : (
          <>
            {button?.text || button?.Icon ? (
              <div className="w-52">
                <Button
                  RightIcon={button?.Icon ?? undefined}
                  onClick={button?.onClick}
                >
                  {button?.text}
                </Button>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};
