import { PaymentAccount } from "../../components/Payment/Payment.type";
import paths from "../../constants/paths";
import { get } from "../../helpers/APIHelper";

/**
 * Hook to fetch payment accounts.
 * @param {Function} onStart - Function to execute when the fetch starts.
 * @param {Function} onEnd - Function to execute when the fetch ends.
 * @param {Function} onSuccess - Function to execute when the fetch is successful.
 * @param {Function} onError - Function to execute when there is an error.
 */
export const useFetchPaymentAccounts = async (
  onSuccess: (paymentAccounts: PaymentAccount[]) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  if (typeof onStart === "function") {
    onStart();
  }

  try {
    const response = await get(
      `${import.meta.env.VITE_API_URL}${
        paths.API.PAYMENTS.ACCOUNTS_FOR_DROPDOWN
      }`
    );

    if (response?.data?.success) {
      onSuccess(response?.data.result?.payment_accounts);
    } else {
      onError(response?.response?.data?.message ?? response.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};
