import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "../../../Common/Button/Button";
import { NumberInput } from "../../../Common/NumberInput/NumberInput";
import { Separator } from "../../../Common/Separator/Separator";
import { SimpleRadio } from "../../../Common/SimpleRadio/SimpleRadio";

export const AirbnbConnectStepOptimization: React.FC<{
  onNext: (pricingCut: number) => void;
}> = ({ onNext = () => {} }) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{
    withDiscount: boolean;
    priceWithDiscount: number;
  }>({
    mode: "all",
    defaultValues: {
      withDiscount: false,
      priceWithDiscount: 10,
    },
  });

  const { t } = useTranslation();

  const handleNext = () => {
    if (watch("withDiscount")) onNext(watch("priceWithDiscount"));
    else onNext(0);
  };

  return (
    <div className="flex flex-col gap-6 text-sm">
      <p className="text-base text-low-contrast font-semibold">
        {t("Progression.Airbnb.Optimization.content1")}
      </p>

      <Controller
        control={control}
        name="withDiscount"
        render={({ field: { value, onChange } }) => (
          <div className="flex flex-row gap-3">
            {/* No discount */}
            <div
              className="flex flex-row items-center gap-2 cursor-pointer"
              onClick={() => onChange(false)}
            >
              <SimpleRadio value={!value} />
              <p
                className={`${
                  !value
                    ? "text-active font-semibold"
                    : "text-low-contrast font-semibold"
                }`}
              >
                {t("Progression.Airbnb.Optimization.priceRadio")}
              </p>
            </div>

            {/* With discount */}
            <div
              className="flex flex-row items-center gap-2 cursor-pointer"
              onClick={() => onChange(true)}
            >
              <SimpleRadio value={value} />
              <p
                className={`${
                  value
                    ? "text-active font-semibold"
                    : "text-low-contrast font-semibold"
                }`}
              >
                {t("Progression.Airbnb.Optimization.discountRadio")}
              </p>
            </div>
          </div>
        )}
      />

      {watch("withDiscount") && (
        <Controller
          control={control}
          name="priceWithDiscount"
          rules={{
            required: true,
            onChange: (event) => {
              setValue("priceWithDiscount", Number(event.target.value ?? 1));
            },
          }}
          render={({ field: { value, onChange } }) => (
            <>
              <NumberInput
                label={t("Progression.Airbnb.Optimization.labelDiscountField")}
                onChangeText={onChange}
                value={value}
                currency={"%"}
                error={errors.priceWithDiscount?.message}
                min={0}
                max={99}
              />

              <p className="text-base text-low-contrast">
                {t("Progression.Airbnb.Optimization.content2", {
                  discount: watch("priceWithDiscount"),
                  priceWithDiscount:
                    100 - (100 * Number(watch("priceWithDiscount"))) / 100,
                })}
              </p>
            </>
          )}
        />
      )}

      <p className="text-base text-low-contrast">
        {t("Progression.Airbnb.Optimization.content3")}
      </p>

      <Button onClick={handleNext}>
        {t("Progression.Airbnb.Optimization.button")}
      </Button>
    </div>
  );
};
