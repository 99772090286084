import { t } from "i18next";
import React, { useEffect, useState } from "react";
import RobotIcon from "../../assets/icons/robot.svg?react";
import { ErrorMessage } from "../../components/Common/ErrorMessage/ErrorMessage";
import { TableList } from "../../components/Common/TableList/TableList";
import { ManagementLayout } from "../../components/Layout/ManagementLayout/ManagementLayout";
import { ManagementAutomationListAddModal } from "../../components/ManagementAutomationList/AddModal";
import { ManagementAutomationListAssignModal } from "../../components/ManagementAutomationList/AssignModal";
import { ManagementAutomationListDeactivateModal } from "../../components/ManagementAutomationList/DeactivateModal";
import { ManagementAutomationFilters } from "../../components/ManagementAutomationList/ManagementAutomationFilters/ManagementAutomationFilters";
import { ManagementAutomationListFilterButtons } from "../../components/ManagementAutomationList/ManagementAutomationFilters/ManagementAutomationListFilterButtons";
import { ManagementAutomationListMenuSections } from "../../components/ManagementAutomationList/ManagementAutomationFilters/ManagementAutomationListFilterButtons.type";
import { ManagementAutomationSettingsModal } from "../../components/ManagementAutomationList/ManagementAutomationSettingsModal/ManagementAutomationSettingsModal";
import { ManagementAutomationItem } from "../../components/ManagementAutomationList/ManagementAutomationTable/ManagementAutomationItem";
import { ManagementAutomationItemSkeleton } from "../../components/ManagementAutomationList/ManagementAutomationTable/ManagementAutomationItemSkeleton";
import { ManagementAutomationTableHeader } from "../../components/ManagementAutomationList/ManagementAutomationTable/ManagementAutomationTableHeader";
import { ManagementAutomationListMultipleSelectionModal } from "../../components/ManagementAutomationList/MultipleSelectionModal";
import { NoAutomationAutomationList } from "../../components/ManagementAutomationList/NoAutomation/NoAutomationAutomationList";
import { ManagementAutomationListRemoveModal } from "../../components/ManagementAutomationList/RemoveModal";
import { ManagementAutomationListRemoveModalFromUpdate } from "../../components/ManagementAutomationList/RemoveModalFromUpdate";
import { ManagementAutomationListUpdateModal } from "../../components/ManagementAutomationList/UpdateModal";
import paths from "../../constants/paths";
import { useAutomation } from "../../hooks/api/automation";
import { useModal } from "../../hooks/useModal";
import { useTablePage } from "../../hooks/useTablePage";
import { ValueType } from "../../types/commonTypes";
import { AutomationItemResponse } from "../../types/GETTypes";

export const ManagementAutomationListPage: React.FC<{}> = () => {
  const automationTablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${
      paths.API.OPERATIONNAL_MANAGEMENT.GET_AUTOMATIONS
    }`,
    "automations"
  );

  const [error, setError] = useState<string | null>(null);

  const [activeNavbarItem, setActiveNavbarItem] =
    useState<ManagementAutomationListMenuSections>("all");

  const [selectedItems, setSelectedItems] = useState<ValueType[]>([]);

  const addModal = useModal<{}>();
  const updateModal = useModal<AutomationItemResponse>();
  const parametersModal = useModal<{}>();
  const removeModal = useModal<ValueType | ValueType[]>();
  const removeModalFromUpdate = useModal<ValueType>();
  const deactivateModal = useModal<ValueType | ValueType[]>();
  const multipleSelectionModal = useModal<ValueType[]>();
  const assignModal = useModal<ValueType[]>();

  const getListViewFiltersNode = () => (
    <ManagementAutomationFilters
      initialRentals={automationTablePage.currentRentalsId ?? []}
      addAutomationModal={addModal}
      onSearchText={automationTablePage.handleChangeSearch}
      onSelectedRentals={automationTablePage.handleFilterRentalsIds}
    />
  );

  const getGroupButtonViewTypeNode = () => (
    <ManagementAutomationListFilterButtons
      activeItem={activeNavbarItem}
      onActiveItem={(value) => {
        setActiveNavbarItem(value);
        if (value === "all") {
          automationTablePage.fetch({
            sort_field: "id",
            sort_direction: "desc",
          });
        } else if (value === "cleaning") {
          automationTablePage.fetch({
            type: "cleaning",
            sort_field: "id",
            sort_direction: "desc",
          });
        } else if (value === "checkout") {
          automationTablePage.fetch({
            type: "checkout",
            sort_field: "id",
            sort_direction: "desc",
          });
        } else if (value === "other") {
          automationTablePage.fetch({
            type: "other",
            sort_field: "id",
            sort_direction: "desc",
          });
        }
      }}
      modal={parametersModal}
      automations={automationTablePage.data}
    />
  );

  useEffect(() => {
    automationTablePage.fetch({
      sort_field: "id",
      sort_direction: "desc",
    });
  }, []);

  // useEffect(() => {
  //   if (automationTablePage.data && automationTablePage.data.length > 0) {
  //     setItems(
  //       automationTablePage.data.map((automation: AutomationItemResponse) => {
  //         return {
  //           item_id: automation.id,
  //           isSelected: false,
  //         };
  //       })
  //     );
  //   }
  // }, [automationTablePage.data]);

  const handleClickActions = (id: ValueType, action: ValueType) => {
    if (action === "select") {
      const exists = selectedItems?.findIndex((i) => i === id) > -1;
      setSelectedItems((prevSelectedItems) => {
        if (prevSelectedItems) {
          if (!exists) {
            return [...prevSelectedItems, id];
          } else {
            return prevSelectedItems.filter((i) => i !== id);
          }
        }

        return [id];
      });
    } else if (action === "remove") {
      removeModal.open(id);
    } else if (action === "edit") {
      updateModal.open(
        automationTablePage.data.find(
          (automation: AutomationItemResponse) => automation.id === id
        )
      );
    } else if (action === "activate") {
      const { enable } = useAutomation({
        onSuccess: (data) => {
          if (data && !Array.isArray(data)) {
            automationTablePage.setData(
              (prevAutomation: AutomationItemResponse[]) => {
                if (prevAutomation) {
                  return prevAutomation.map((automation) => {
                    if (automation.id === data.id) {
                      return data;
                    } else {
                      return automation;
                    }
                  });
                }
                return [];
              }
            );
          }
        },
        onError: (message: string | null) => setError(message),
        onStart: () => {
          setError(null);
        },
      });

      enable(id);
    } else if (action === "deactivate") {
      deactivateModal.open(id);
    }
  };

  const handleAdd = (automation: AutomationItemResponse) =>
    automationTablePage.setData((prevAutomations: AutomationItemResponse[]) => {
      if (prevAutomations) {
        return [...prevAutomations, automation];
      }

      return [automation];
    });

  const handleUpdate = (automation: AutomationItemResponse) => {
    automationTablePage.setData((prevAutomations: AutomationItemResponse[]) => {
      if (Array.isArray(prevAutomations) && prevAutomations.length > 0) {
        return prevAutomations.map((currentAutomation) =>
          currentAutomation.id === automation.id
            ? automation
            : currentAutomation
        );
      }

      return [];
    });
  };

  const handleDeactivate = (
    data: AutomationItemResponse | AutomationItemResponse[]
  ) => {
    automationTablePage.setData((prevAutomations: AutomationItemResponse[]) => {
      if (Array.isArray(prevAutomations) && prevAutomations.length > 0) {
        if (Array.isArray(data)) {
          const selectedIds = data.map((d) => d.id);

          const dataMap = new Map(
            data.map((d: AutomationItemResponse) => [d.id, d])
          );

          return prevAutomations.map((automation: AutomationItemResponse) =>
            selectedIds.includes(automation.id)
              ? dataMap.get(automation.id)
              : automation
          );
        } else {
          return prevAutomations.map((automation) => {
            if (automation.id === data.id) {
              return data;
            } else {
              return automation;
            }
          });
        }
      }

      return [];
    });

    setSelectedItems([]);
  };

  const handleRemove = (
    data: AutomationItemResponse | AutomationItemResponse[]
  ) => {
    automationTablePage.setData((prevAutomations: AutomationItemResponse[]) => {
      if (Array.isArray(prevAutomations) && prevAutomations.length > 0) {
        if (Array.isArray(data)) {
          const selectedIds = data.map((d) => d.id);

          return prevAutomations.filter(
            (currentAutomation) => !selectedIds.includes(currentAutomation.id)
          );
        } else {
          return prevAutomations.filter(
            (currentAutomation) => currentAutomation.id !== data.id
          );
        }
      }

      return [];
    });

    setSelectedItems([]);
    updateModal.close();
  };

  const handleAssignMultiple = (data: AutomationItemResponse[]) => {
    automationTablePage.setData((prevAutomations) => {
      if (Array.isArray(prevAutomations) && prevAutomations.length > 0) {
        const selectedIds = data.map((d) => d.id);

        const dataMap = new Map(
          data.map((d: AutomationItemResponse) => [d.id, d])
        );

        return prevAutomations.map((automation: AutomationItemResponse) =>
          selectedIds.includes(automation.id)
            ? dataMap.get(automation.id)
            : automation
        );
      }

      return [];
    });
  };

  const handleError = (message: string | null) => {
    setError(message);

    if (message) {
      addModal.close();
      updateModal.close();
      parametersModal.close();
      removeModal.close();
      removeModalFromUpdate.close();
      deactivateModal.close();
      assignModal.close();

      setSelectedItems([]);
    }
  };

  useEffect(() => {
    if (selectedItems.length === 0) {
      multipleSelectionModal.close();
    } else {
      multipleSelectionModal.open(selectedItems);
    }
  }, [selectedItems]);

  return (
    <ManagementLayout subTitle="automations" sidebarActiveSubItem="automations">
      <ManagementAutomationListAddModal modal={addModal} onAdd={handleAdd} />

      <ManagementAutomationListUpdateModal
        modal={updateModal}
        removeModal={removeModalFromUpdate}
        onUpdate={handleUpdate}
      />

      <ManagementAutomationListRemoveModalFromUpdate
        modal={removeModalFromUpdate}
        onRemove={handleRemove}
        onError={handleError}
      />

      <ManagementAutomationSettingsModal modal={parametersModal} />

      <ManagementAutomationListRemoveModal
        modal={removeModal}
        onRemove={handleRemove}
        onError={handleError}
      />

      <ManagementAutomationListDeactivateModal
        modal={deactivateModal}
        onDeactivate={handleDeactivate}
        onError={handleError}
      />

      <ManagementAutomationListAssignModal
        modal={assignModal}
        onAssign={handleAssignMultiple}
        onError={handleError}
      />

      <ManagementAutomationListMultipleSelectionModal
        modal={multipleSelectionModal}
        onClose={() => {
          setSelectedItems([]);
        }}
        onTriggerDeactivate={() => deactivateModal.open(selectedItems)}
        onTriggerAssign={() => assignModal.open(selectedItems)}
        onTriggerRemove={() => removeModal.open(selectedItems)}
      />

      <div className="flex flex-col gap-y-6">
        <ErrorMessage>{error}</ErrorMessage>
        {/* AUTOMATION INFO */}
        <div className="flex gap-2 p-4 bg-element-background rounded-6px">
          <RobotIcon className="relative -top-1 size-7 shrink-0" />

          <div>
            <p className="mb-1 text-base font-semibold text-high-contrast">
              {t("Automation.List.whatIsTitle")}
            </p>
            <p className="text-base font-light text-low-contrast">
              {t("Automation.List.whatIsContent")}
            </p>
          </div>
        </div>

        <TableList
          i18nElement="Global.automations"
          Filters={getListViewFiltersNode()}
          ViewTypeGroupButton={getGroupButtonViewTypeNode()}
          Header={ManagementAutomationTableHeader}
          Item={ManagementAutomationItem}
          selectedItems={selectedItems}
          Skeleton={ManagementAutomationItemSkeleton}
          NoData={
            <NoAutomationAutomationList
              onAddAutomation={() => addModal.open()}
            />
          }
          tablePage={automationTablePage}
          onClick={(id) => handleClickActions(id, "edit")}
          onClickAction={handleClickActions}
        />
      </div>
    </ManagementLayout>
  );
};
