import React from "react";
import { cn } from "../../../helpers/classHelper";
import { Badge } from "../Badge/Badge";
import { Button } from "../Button/Button";
import { Loader } from "../Loader/Loader";
import { Switch } from "../Switch/Switch";
import { Tooltip } from "../Tooltip/Tooltip";
import { CardProps } from "./Card.type";

export const Card: React.FC<CardProps> = ({
  Icon,
  label,
  labelNode,
  tooltip,
  button,
  secondaryButton,
  badge,
  bgSubtleLight = false,
  children,
  loading,
  hasBody = true,
  switchButton,
  customButton,
  classNames,
}) => {
  return (
    <div className="flex flex-col border-1 border-element-border rounded-6px">
      {/* Header */}
      <div
        className={`flex flex-row items-center justify-between h-12 p-4 space-x-2 ${
          hasBody ? "border-b-1 border-element-border" : ""
        }`}
      >
        <div
          className={cn(
            "flex items-center space-x-2",
            classNames?.iconAndToolTip
          )}
        >
          {Icon ? <Icon /> : null}
          {label && (
            <p className="flex-1 text-sm font-semibold tracking-wide">
              {label}
            </p>
          )}
          {labelNode}
          {tooltip ? (
            <div>
              <Tooltip
                mode={tooltip.mode}
                Icon={tooltip.Icon}
                value={tooltip.value}
                size={tooltip.size}
              />
            </div>
          ) : null}
        </div>

        {badge ? (
          <>
            {badge.skeleton ? (
              <div className="w-24 h-8 rounded bg-slate-200 animate-pulse" />
            ) : null}

            {!badge.skeleton ? (
              <div>
                <Badge label={badge.label} status={badge.status} size="small" />
              </div>
            ) : null}
          </>
        ) : null}

        {loading ? (
          <div className="pr-4">
            <Loader />
          </div>
        ) : (
          <div className="flex space-x-3">
            {switchButton && (
              <>
                {switchButton.loading && (
                  <div className="pr-4">
                    <Loader />
                  </div>
                )}
                <div className="flex items-center space-x-2">
                  <Switch
                    value={switchButton.value}
                    onClick={switchButton.onClick}
                    disabled={switchButton.disabled}
                  />
                </div>
              </>
            )}

            {secondaryButton ? (
              <>
                {secondaryButton.skeleton ? (
                  <div className="w-24 h-8 rounded bg-slate-200 animate-pulse" />
                ) : null}

                {!secondaryButton.skeleton ? (
                  <div>
                    <Button
                      type={secondaryButton.type ?? "secondary"}
                      RightIcon={secondaryButton.Icon}
                      LeftIcon={secondaryButton.LeftIcon}
                      size="small"
                      disabled={secondaryButton.disabled ?? false}
                      onClick={
                        secondaryButton.onClick
                          ? secondaryButton.onClick
                          : () => {}
                      }
                    >
                      {secondaryButton.label}
                    </Button>
                  </div>
                ) : null}
              </>
            ) : null}

            {customButton ? (
              <>
                <div>
                  <Button
                    type={customButton.type ?? "secondary"}
                    RightIcon={customButton.rightIcon}
                    LeftIcon={customButton.leftIcon}
                    size="small"
                    disabled={customButton.disabled}
                    onClick={customButton.onClick}
                  >
                    {customButton.label}
                  </Button>
                </div>
              </>
            ) : null}

            {button ? (
              <>
                {button.skeleton ? (
                  <div className="w-24 h-8 rounded bg-slate-200 animate-pulse" />
                ) : null}

                {!button.skeleton ? (
                  <div className="flex flex-row space-x-3">
                    {button.label !== undefined || button.Icon ? (
                      <Button
                        type={button.type ?? "secondary"}
                        RightIcon={button.Icon}
                        size="small"
                        onClick={button.onClick ? button.onClick : () => {}}
                        disabled={button.disabled ?? false}
                      >
                        {button.label}
                      </Button>
                    ) : null}
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        )}
      </div>

      {/* Body */}
      {hasBody ? (
        <div
          className={`p-5 text-sm flex-1 rounded-b-6px ${
            bgSubtleLight && "bg-subtle-light"
          }`}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};
