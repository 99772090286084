import React, {useState, useEffect} from "react";
import {GoogleMap, LoadScript, MarkerF} from "@react-google-maps/api";
import axios from "axios";
import {cn} from "../../../helpers/classHelper";
import {Button} from "../../Common/Button/Button";
import ArrowTopRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import {useTranslation} from "react-i18next";
declare global {
  interface Window {
    MSStream: any;
  }
}

interface Coordinates {
  lat: number;
  lng: number;
}

interface MapComponentProps {
  address: string;
  fallbackAddress: string;
  mapContainerStyle?: {
    height: string;
    width: string;
  };
  label?: string;
  mapClassNames?: {
    label?: string;
  };
}

const containerStyle = {
  height: "400px",
  width: "100%",
};

const MapComponent: React.FC<MapComponentProps> = ({
  address,
  fallbackAddress,
  mapContainerStyle,
  label,
  mapClassNames,
}) => {
  const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
  const {t} = useTranslation();
  const [coordinates, setCoordinates] = useState<Coordinates>({lat: 0, lng: 0});
  const [mapUrl, setMapUrl] = useState<string>("");
  const [showMap, setShowMap] = useState(true);
  const [refreshMap, setRefreshMap] = useState(false);

  const isIOS = (): boolean => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const getMapUrl = (lat: number, lng: number): string => {
    if (isIOS()) {
      // Apple Maps URL
      return `http://maps.apple.com/?daddr=${lat},${lng}`;
    } else {
      // Google Maps URL
      return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    }
  };

  const defaultMapOptions = {
    fullscreenControl: false,
    streetViewControl: false, // Disable the Pegman control
    mapTypeControl: false, // Disable the Plan or Satellite control
  };

  const geocodeAddress = async () => {
    if (address) {
      generateCoordinates(address).then(
        ({success}) => {
          if (!success) {
            console.error(
              "Geocoding error: No results found for address : ",
              address
            );
            setShowMap(false);
          }
          setRefreshMap(!refreshMap);
        }
      );
    }
  };

  const generateCoordinates = async (address: string) => {
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(
      address
    )}&key=${apiKey}`;

    try {
      const {data} = await axios.get(geocodingUrl);
      if (data.results && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        setCoordinates({lat: location.lat, lng: location.lng});
        setMapUrl(getMapUrl(location.lat, location.lng));
        return {success: true};
      } else {
        return {success: false};
      }
    } catch (error) {
      console.error("Geocoding error:", error, address);
      return {success: false};
    }
  };

  useEffect(() => {
    geocodeAddress();
  }, [address]);

  const openUrl = () => {
    if (mapUrl) {
      window.open(mapUrl, "_blank");
    }
  };

  useEffect(() => {}, [refreshMap, coordinates]);

  if (!showMap) {
    return <div className="mt-4 md:mt-0" />;
  }
  return (
    <div className="w-full md:w-1/2 mt-4 md:mt-0">
      <div className="mb-2 mt-2 md:mt-0">
        <span
          className={cn(
            "text-low-contrast text-xs md:text-lg",
            mapClassNames?.label
          )}
        >
          {label ?? ""}
        </span>
      </div>
      <div className="mb-2">
        <LoadScript googleMapsApiKey={apiKey}>
          {coordinates.lat !== 0 && coordinates.lng !== 0 && (
            <GoogleMap
              mapContainerStyle={mapContainerStyle ?? containerStyle}
              center={coordinates}
              zoom={16}
              options={defaultMapOptions}
            >
              <MarkerF position={coordinates} onClick={openUrl} visible />
              <div className="absolute top-[10px] right-[10px]">
                <Button
                  RightIcon={ArrowTopRightWhiteIcon}
                  type="primary"
                  textClassName="font-bold"
                  onClick={openUrl}
                >
                  {t("GuestPage.Reservation.goMaps")}
                </Button>
              </div>
            </GoogleMap>
          )}
        </LoadScript>
      </div>
    </div>
  );
};

export default MapComponent;
