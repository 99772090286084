import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import SendWhiteIcon from "../../../assets/icons/send-white.svg?react";
import paths from "../../../constants/paths";
import { useModal } from "../../../hooks/useModal";
import { Button } from "../../Common/Button/Button";
import { CopyLink } from "../../Common/CopyLink/CopyLink";
import { Separator } from "../../Common/Separator/Separator";
import { ScheduledMessageList } from "../../Message/ScheduledMessageList/ScheduledMessageList";
import { ReservationPaymentAndDepositLinksModal } from "../../Reservation/PaymentAndDepositLinks/PaymentAndDepositLinksModal";
import { AddReservationType } from "../AddReservation.type";
import { AddReservationNoMessage } from "./AddReservationNoMessage";

export const AddReservationDone: React.FC<{
  reservation: AddReservationType;
}> = ({ reservation }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sendModal = useModal<{ index: number; isDeposit: boolean }>();

  const [sendSuccessed, setSendSuccessed] = useState<{
    payments: boolean[];
    deposit: boolean;
  }>({ payments: [false, false, false], deposit: false });

  const handleNavigateReservation = () => {
    navigate(`${paths.RESERVATIONS}/${reservation.reservation_id}`);
  };

  const handleOpenSendModal = (index: number, isDeposit: boolean) => {
    sendModal.open({ index, isDeposit });
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const hasStripePayment = () => {
    if (
      !reservation?.payment_schedule?.payments_schedule_items ||
      reservation?.payment_schedule?.payments_schedule_items?.length === 0
    )
      return false;

    let result = false;
    reservation?.payment_schedule?.payments_schedule_items?.map((payment) => {
      if (payment.payment_account_type?.toLowerCase() === "stripe")
        result = true;
    });

    return result;
  };

  const hasStripeDeposit = () => {
    return (
      reservation?.payment_schedule?.is_deposit_required === 1 &&
      (reservation?.payment_schedule?.deposit_payment_option?.toUpperCase() ===
        "PRE_AUTHORISATION" ||
        reservation?.payment_schedule?.deposit_payment_option?.toUpperCase() ===
          "CARD_PAYMENT_TO_REFUND")
    );
  };

  const handleSuccess = (
    data: { index: number; isDeposit: boolean } | undefined | null
  ) => {
    // Update buttons
    if (data?.isDeposit) {
      setSendSuccessed({ ...sendSuccessed, deposit: true });
    } else {
      const nextPayments = JSON.parse(JSON.stringify(sendSuccessed?.payments));
      nextPayments[data?.index ?? 0] = true;
      setSendSuccessed({ ...sendSuccessed, payments: nextPayments });
    }

    // Close modal
    sendModal.close();
  };

  return (
    <>
      <ReservationPaymentAndDepositLinksModal
        reservation={reservation}
        data={sendModal.data}
        isVisible={sendModal.isVisible}
        onClose={sendModal.close}
        onSuccess={handleSuccess}
      />

      <div className="flex flex-col text-sm">
        <p className="text-lg font-semibold text-high-contrast">
          {t("AddReservation.Done.title")}
        </p>

        <p className="mt-4 font-light text-low-contrast">
          {t("AddReservation.Done.subTitle")}
        </p>

        {(hasStripePayment() || hasStripeDeposit()) && (
          <>
            <p className="mt-12 font-light text-low-contrast">
              {t("AddReservation.Done.content1")}
            </p>

            {reservation.payment_schedule?.payments_schedule_items?.map(
              (payment, index) => {
                if (payment.payment_account_type?.toLowerCase() === "stripe")
                  return (
                    <div className="mt-4">
                      <CopyLink
                        label={`${t("AddReservation.Done.paymentLink")} ${
                          index + 1
                        }`}
                        link={`${location.protocol}//${location.host}/reservation/${reservation?.reservation_id}/payment/${payment.payment_link}`}
                        isCopied={sendSuccessed.payments[index]}
                        button={{
                          type: "primary",
                          text: t("AddReservation.Done.sendPayment"),
                          Icon: SendWhiteIcon,
                          onClick: () => handleOpenSendModal(index, false),
                        }}
                      />
                    </div>
                  );
              }
            )}

            {hasStripeDeposit() && (
              <div className="mt-4">
                <CopyLink
                  label={t("AddReservation.Done.depositLink")}
                  link={`${location.protocol}//${location.host}/reservation/${reservation?.reservation_id}/deposit/${reservation?.payment_schedule?.deposit_payment_link}`}
                  isCopied={sendSuccessed.deposit}
                  button={{
                    type: "primary",
                    text: t("AddReservation.Done.sendPayment"),
                    Icon: SendWhiteIcon,
                    onClick: () => handleOpenSendModal(0, false),
                  }}
                />
              </div>
            )}

            <p className="mt-4 mb-4 font-light text-low-contrast">
              {t("AddReservation.Done.content2")}
            </p>

            <Separator />
          </>
        )}

        {reservation?.notification_statuses &&
        reservation?.notification_statuses?.length > 0 ? (
          <ScheduledMessageList
            loading={false}
            withActionButtons={false}
            messages={reservation?.notification_statuses}
            onRefresh={() => {}}
            guest_email={reservation?.guest?.email ?? ""}
          />
        ) : (
          <AddReservationNoMessage />
        )}

        <div className="flex gap-4 pb-4 mt-16">
          <Button type="secondary" onClick={handleNavigateBack}>
            {t("Global.done")}
          </Button>

          <Button
            onClick={handleNavigateReservation}
            RightIcon={ArrowRightWhiteIcon}
          >
            {t("AddReservation.Done.nextStep")}
          </Button>
        </div>
      </div>
    </>
  );
};
