import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import ArrowRight from "../../../../assets/icons/arrow-right-white.svg?react";
import paths from "../../../../constants/paths";
import { Keys } from "../../../../values";
import { Button } from "../../../Common/Button/Button";
import { CheckBox } from "../../../Common/CheckBox/CheckBox";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { Link } from "../../../Common/Link/Link";
import { PhoneInput } from "../../../Common/PhoneInput/PhoneInput";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { Title } from "../../../Common/Title/Title";
import {
  RegisterPersonnalInputs,
  RegisterPersonnalProps,
} from "./RegisterPersonnal.type";

export const RegisterPersonnal: React.FC<RegisterPersonnalProps> = ({
  error,
  firstName,
  lastName,
  email,
  phone,
  accept = true,
  loading = false,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    control,
  } = useForm<RegisterPersonnalInputs>({
    defaultValues: {
      firstName,
      lastName,
      email,
      phone,
      accept,
    },
  });
  const handleRegister: SubmitHandler<RegisterPersonnalInputs> = (data) => {
    onSubmit(data);
  };

  return (
    <div>
      <Title>{t("Register.Personnal.title")}</Title>

        <Title type="secondary">
            {t("Register.Personnal.subTitle")} <br/>
            <br/><br/>
            {t("Register.Personnal.alreadyAnAccount")}{" "}
            <Link url={paths.LOGIN}>{t("Register.Personnal.login")}</Link>
        </Title>

        {error && <ErrorMessage>{error}</ErrorMessage>}

      <div className="mt-4 mb-4">
        <div className="flex flex-row space-x-4">
          <TextInput
            label={t("Register.Personnal.firstName")}
            value={getValues().firstName}
            placeholder="Jane"
            register={register("firstName", {
              required: t("Register.Personnal.firstNameError").toString(),
            })}
            error={errors.firstName?.message}
            disabled={loading}
            required={true}
          />

          <TextInput
            label={t("Register.Personnal.lastName")}
            value={getValues().lastName}
            placeholder="Doe"
            register={register("lastName", {
              required: t("Register.Personnal.lastNameError").toString(),
            })}
            error={errors.lastName?.message}
            disabled={loading}
            required={true}
          />
        </div>
      </div>

      <div className="mb-4">
        <TextInput
          label={t("Register.Personnal.email")}
          value={getValues().email}
          placeholder="jane.doe@mail.com"
          register={register("email", {
            required: t("Register.Personnal.emailError").toString(),
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: t("Register.Personnal.emailErrorInvalid").toString(),
            },
          })}
          error={errors.email?.message}
          disabled={loading}
          required={true}
        />
      </div>

      <div className="mb-4">
        <Controller
          control={control}
          name="phone"
          rules={{
            required: t("Register.Personnal.phoneError").toString(),
            minLength: {
              value: 10,
              message: t("Register.Personnal.phoneErrorInvalid").toString(),
            },
          }}
          render={({ field: { onChange, value } }) => (
            <PhoneInput
              label={t("Register.Personnal.phone")}
              value={value}
              placeholder="06 11 23 58 13"
              onChangeText={onChange}
              error={errors.phone?.message}
              disabled={loading}
              required={true}
            />
          )}
        />
      </div>

      <div>
        <CheckBox
          register={register("accept", {
            required: t("Register.Personnal.acceptError").toString(),
          })}
          value={getValues().accept}
          label={
            <span className="text-sm font-light text-secondary">
              <Trans
                i18nKey="Register.Personnal.acceptGCUandPPandEthic"
                components={{
                  gcu: (
                    <Link url={paths.GLOBAL.CGV} openTab={true}>
                      {t("Register.Personnal.GCU")}
                    </Link>
                  ),
                  pp: (
                    <Link url={paths.GLOBAL.PRIVACY} openTab={true}>
                      {t("Register.Personnal.PP")}
                    </Link>
                  ),
                  ethic: (
                    <Link url={paths.GLOBAL.ETHIC} openTab={true}>
                      {t("Register.Personnal.ETHIC")}
                    </Link>
                  ),
                }}
              />
            </span>
          }
          required={true}
          error={errors.accept?.message}
        />
      </div>

      <div className="flex mt-6">
        <Button
          RightIcon={ArrowRight}
          keyToSubmit={Keys.Enter}
          onClick={handleSubmit(handleRegister)}
          loading={loading}
          displayLargeBtn={false}
        >
          {t("Register.Personnal.submit")}
        </Button>
      </div>
    </div>
  );
};
