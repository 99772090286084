import React from "react";
import { CardToggle } from "../../Common/CardToggle/CardToggle";
import { TemplateItemSkeleton } from "./TemplateItemSkeleton";

export const TemplateGroupItemSkeleton: React.FC<{}> = ({}) => {
  const getTitle = () => {
    return (
      <div className="flex flex-row items-center cursor-pointer hover:underline gap-2">
        <p className="font-semibold text-high-contrast h-2 rounded w-72 bg-slate-300 animate-pulse"></p>
      </div>
    );
  };

  return (
    <CardToggle title={getTitle()}>
      <div className="animate-pulse">
        {Array.from({ length: 3 }).map((template, index) => (
          <TemplateItemSkeleton key={index} />
        ))}
      </div>
    </CardToggle>
  );
};
