import React, { useState } from "react";
import ChevronDownIcon from "../../../assets/icons/chevron-down.svg?react";
import ChevronUpIcon from "../../../assets/icons/chevron-up.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import TrashIcon from "../../../assets/icons/trash.svg?react";
import { Rental, RentalGroup } from "../../../pages/RentalPage/RentalPage.type";
import { ValueType } from "../../../types/commonTypes";
import { RentalGroupUseMode } from "../../Modal/Rental/Group/RentalGroupModal.type";
import { TableListItemProps } from "./TableList.type";

export const TableListWithGroupItem: React.FC<{
  nbCols: number;
  group: any;
  Item: React.FC<TableListItemProps>;
  selectedItems?: ValueType[];
  onClick: (id: ValueType | null) => void;
  onClickAction?: (id: ValueType, action: ValueType) => void;
  onTriggerUpdateGroup?: (
    useMode: RentalGroupUseMode,
    isVisible: boolean,
    rentalGroup: RentalGroup | undefined
  ) => void;
  onTriggerRemoveGroup?: (
    isVisible: boolean,
    rentalGroup: RentalGroup | undefined
  ) => void;
}> = ({
  Item,
  nbCols,
  group,
  selectedItems = [],
  onClick,
  onClickAction = () => {},
  onTriggerRemoveGroup,
  onTriggerUpdateGroup,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <>
      <tr
        className="cursor-pointer select-none bg-element-background"
        onClick={() => setIsOpen(!isOpen)}
      >
        <td
          className="px-4 py-2.5 text-sm font-semibold leading-4 text-low-contrast border-t-1 border-element-border"
          colSpan={nbCols - 1}
        >
          <h3 className="pointer-events-none w-max">
            {group.name.toUpperCase()}
          </h3>
        </td>

        <td className="flex items-center justify-end px-3 py-2 space-x-3 text-sm border-t-1 border-element-border">
          {group?.id !== 0 && (
            <div className="flex space-x-1">
              {onTriggerUpdateGroup && (
                <EditIcon
                  width={20}
                  height={20}
                  onClick={(e) => {
                    e.stopPropagation();
                    onTriggerUpdateGroup("update", true, group);
                  }}
                />
              )}
              {onTriggerRemoveGroup && (
                <TrashIcon
                  width={20}
                  height={20}
                  onClick={(e) => {
                    e.stopPropagation();
                    onTriggerRemoveGroup(true, group);
                  }}
                />
              )}
            </div>
          )}
          {isOpen ? (
            <ChevronUpIcon
              className="pointer-events-none"
              width={30}
              height={30}
            />
          ) : (
            <ChevronDownIcon
              className="pointer-events-none"
              width={30}
              height={30}
            />
          )}
        </td>
      </tr>
      <tbody>
        {isOpen === true &&
          group.rentals.map((rental: Rental, index: number) => (
            <Item
              key={index}
              data={rental}
              isSelected={selectedItems?.findIndex((i) => i === rental.id) > -1}
              onClick={() => onClick(rental.id)}
              onClickAction={onClickAction}
            />
          ))}
      </tbody>
    </>
  );
};
