import React, { useEffect, useState } from "react";
import { Button } from "../../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { Switch } from "../../../Common/Switch/Switch";
import { Separator } from "../../../Common/Separator/Separator";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import { Controller, useForm } from "react-hook-form";
import { get, post } from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import { InputSelectOptionProps } from "../../../Common/InputSelect/InputSelect.type";
import { Rental } from "../../../../pages/RentalPage/RentalPage.type";
import { TemplateUpsertRental } from "./TemplateUpsertRental";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { TextAreaInput } from "../../../Common/TextAreaInput/TextAreaInput";

import CheckWhiteIcon from "../../../../assets/icons/check-white.svg?react";
import { PictureInput } from "../../../Common/PictureInput/PictureInput";
import { Template } from "../TemplatesModal.type";
import { ShortCodeList } from "../../../Message/ShortCodeList/ShortCodeList";
import { insertTextAtPosition } from "../../../../helpers/stringHelper";
import {
  LanguageResponse,
  MessageTemplatePhotoResponse,
  RentalLightListItemResponse,
} from "../../../../types/GETTypes";
import {getLanguageIcon} from "../../../../helpers/languageHelper";

export type TemplateUpsert = {
  title: string;
  message: string;
  photos: any;
  language_code: string;
  rental_id: ValueType | null;
};

export type TemplateUpsertProps = {
  template?: Template | null | undefined;
  isShortcodesVisible?: boolean;
  onShowShortCode: () => void;
  onRemove: (template: Template) => void;
  onClose: () => void;
};

type TemplateUpsertFormValues = {
  title: string;
  message: string;
  language_code: string;
  rental_id: ValueType | null;
};

export const TemplateUpsert: React.FC<TemplateUpsertProps> = ({
  template,
  isShortcodesVisible,
  onClose,
  onRemove,
  onShowShortCode,
}) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
    register,
  } = useForm<TemplateUpsertFormValues>({
    defaultValues: { rental_id: null },
  });

  const language = watch("language_code");
  const title = watch("title");
  const message = watch("message");

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [languages, setLanguages] = useState<InputSelectOptionProps[]>([]);
  const [rentals, setRentals] = useState<InputSelectOptionProps[]>([]);

  const [currentNewFiles, setCurrentNewFiles] = useState<Blob[]>([]);
  const [currentOldFiles, setCurrentOldFiles] = useState<
    MessageTemplatePhotoResponse[]
  >([]);

  const [messageCursorPosition, setMessageCursorPosition] = useState<number>(0);

  

  const fetchLists = async () => {
    setLoading(true);
    setError(null);

    // Récupérer les langues
    const respLanguages = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.LANGUAGES}`
    );

    if (respLanguages.data?.success) {
      const nextLanguages = respLanguages.data?.result?.languages?.map(
        (l: LanguageResponse) => {
          return {
            label: l.name,
            value: l.code,
            Icon: getLanguageIcon(l.code),
          };
        }
      );

      setLanguages(nextLanguages);
    } else {
      setError(respLanguages.response?.data?.message);
    }

    // Récupérer les hébergements
    const respRentals = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}?limit=-1`
    );

    if (respRentals.data?.success) {
      const nextRentals = respRentals.data?.result?.rentals?.map(
        (r: RentalLightListItemResponse) => {
          return {
            label: r.name,
            value: r.id,
          };
        }
      );

      setRentals(nextRentals);
    } else {
      setError(respRentals.response?.data?.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchLists();
  }, []);

  useEffect(() => {
    if (template) {
      setValue("title", template.title!);
      setValue("message", template.message!);
      setValue("language_code", template.language.code!);
      setValue("rental_id", template.rental?.id!);

      setCurrentOldFiles(template.photos);
    } else {
      setValue("language_code", "FR");
    }
  }, [template]);

  const submitAddTemplate = async (data: TemplateUpsertFormValues) => {
    setError(null);
    setLoading(true);

    const form = new FormData();
    form.append("rental_id", data.rental_id?.toString() ?? "");
    form.append("language_code", data.language_code?.toString() ?? "");
    form.append("title", data.title?.toString() ?? "");
    form.append("message", data.message?.toString() ?? "");

    currentNewFiles.forEach((photo, i) => {
      form.append(`photos[${i}]`, photo);
    });

    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.MESSAGE_TEMPLATES}`,
      form
    );

    if (response?.data?.success) {
      onClose();
    } else {
      setError(response.response?.data?.message);
    }

    setLoading(false);
  };

  const submitEditTemplate = async (data: TemplateUpsertFormValues) => {
    setError(null);
    setLoading(true);

    const form = new FormData();
    form.append("_method", "PUT");
    form.append("rental_id", data.rental_id?.toString() ?? "");
    form.append("language_code", data.language_code?.toString() ?? "");
    form.append("title", data.title?.toString() ?? "");
    form.append("message", data.message?.toString() ?? "");

    currentNewFiles.forEach((photo, i) => {
      form.append(`photos[${i}]`, photo);
    });

    // get removed photos
    const removed = template?.photos?.filter(
      (origItem: MessageTemplatePhotoResponse) => {
        return !currentOldFiles.some(
          (finalItem) => finalItem.id === origItem.id
        );
      }
    );

    form.append(
      "del_photo_ids",
      removed.map((r: MessageTemplatePhotoResponse) => r.id).toString()
    );

    const response = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.MESSAGE_TEMPLATES}/${
        template?.id
      }`,
      form
    );

    if (response?.data?.success) {
      onClose();
    } else {
      setError(response.response?.data?.message);
    }

    setLoading(false);
  };

  const handleUpsert = (data: TemplateUpsertFormValues) => {
    if (template) {
      // Template exists, so this is an update
      submitEditTemplate(data);
    } else {
      // No template already exists, so we create the template
      submitAddTemplate(data);
    }
  };

  const handleRemove = (e: any) => {
    onRemove(template!);
  };

  const handleInsertShortCode = (trigger: string) => {
    setValue(
      "message",
      insertTextAtPosition(message, trigger, messageCursorPosition)
    );
  };

  const handleChangeCursor = (event: any) => {
    setMessageCursorPosition(event.target.selectionStart);
  };

  return (
    <div className="flex gap-4 overflow-y-auto flex-1">
      {isShortcodesVisible && (
        <div className="flex-1 overflow-y-scroll pr-4">
          <ShortCodeList onInsert={handleInsertShortCode} />
        </div>
      )}

      <div
        className={`flex flex-col flex-1 ${
          isShortcodesVisible ? "w-modal-inside" : "w-full"
        } overflow-y-visible`}
      >
        <form
          onSubmit={handleSubmit(handleUpsert)}
          className="flex flex-col flex-1"
        >
          <div className="flex flex-col w-full flex-1 justify-between">
            <div className="flex flex-col gap-2 w-full flex-1 overflow-y-auto">
              <p className="text-sm font-semibold text-low-contrast">
                {t("Templates.content")}
              </p>

              <div className="mt-2">
                <TextInput
                  {...register("title", {
                    required: {
                      value: true,
                      message: t("Templates.errorTitle"),
                    },
                  })}
                  label={t("Templates.name")}
                  placeholder={t("Templates.namePlaceholder")}
                  disabled={loading}
                  value={title}
                  onChangeText={(text) => setValue("title", text)}
                  error={errors.title?.message}
                />
              </div>

              <TextAreaInput
                {...register("message", {
                  required: {
                    value: true,
                    message: t("Templates.errorMessage"),
                  },
                })}
                label={t("Templates.message")}
                disabled={loading}
                placeholder={t("Templates.messagePlaceholder")}
                value={message}
                onSelect={handleChangeCursor}
                onTextChange={(text) => setValue("message", text)}
                error={errors.message?.message}
              />

              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={onShowShortCode}
              >
                <p className="text-low-contrast font-light">
                  {t("Templates.showShortcodes")}
                </p>
                <Switch value={isShortcodesVisible ?? false} />
              </div>

              {/* <PictureInput
                label={t("Templates.photos")}
                newFiles={currentNewFiles}
                oldFiles={currentOldFiles}
                disabled={loading}
                onChangeNewFiles={(files) => setCurrentNewFiles(files)}
                onChangeOldFiles={(files) => setCurrentOldFiles(files)}
              /> */}

              <Separator />

              <p className="text-sm font-semibold text-low-contrast">
                {t("Templates.informations")}
              </p>

              <InputSelect
                label={t("Templates.language")}
                items={languages}
                selectedValue={language}
                disabled={loading}
                onSelect={(value) =>
                  setValue("language_code", value?.toString())
                }
                error={errors.language_code?.message}
              />

              <Controller
                control={control}
                name="rental_id"
                render={({ field: { onChange, value } }) => (
                  <TemplateUpsertRental
                    rentals={rentals}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              <ErrorMessage>{error}</ErrorMessage>
            </div>

            <div className="flex gap-3 py-3 border-t-1 border-element-border">
              {!loading && (
                <Button type="secondary" onClick={onClose}>
                  {t("Global.cancel")}
                </Button>
              )}

              {!loading && template && (
                <Button type="destructive" onClick={handleRemove}>
                  {t("Global.remove")}
                </Button>
              )}

              <Button
                RightIcon={CheckWhiteIcon}
                disabled={loading}
                loading={loading}
              >
                {template ? t("Global.save") : t("Templates.addAction")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
