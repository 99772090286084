import React from "react";
import ConstructionIcon from "../../../assets/icons/Illustration.svg?react";
import { useTranslation } from "react-i18next";

export const MessagePlaceholder: React.FC = ({}) => {
    const { t } = useTranslation();

    return (
        <div
            className="flex bg-element-background w-full rounded-6px mt-2 items-center justify-center p-4"
            style={{ height: "calc(100vh - 200px)" }}
        >
            <div className="flex flex-col items-center">
                <ConstructionIcon  width={56} height={56} />
                <p className="my-4 font-semibold text-lg text-high-contrast">
                    {t("Global.notMessageUnread")}
                </p>
            </div>
        </div>
    );
};
