import React from "react";
import { SwitchProps } from "./Switch.type";

export const Switch: React.FC<SwitchProps> = ({
  value,
  disabled,
  onClick = () => {},
}) => {
  const bg = value ? "bg-icon-active" : "bg-element-background";
  const border = value ? "" : "border-1 border-element-border";
  const bgRound = value ? "bg-white" : "bg-icon-default";
  const position = value ? "right-1" : "left-1";
  const cursor = disabled ? "cursor-not-allowed" : "cursor-pointer";
  const opacity = disabled ? "opacity-40" : "";

  return (
    <div
      className={`flex items-center w-10 h-6 rounded-r-full rounded-l-full relative ${opacity} ${cursor} ${border} ${bg} `}
      onClick={!disabled ? onClick : () => {}}
    >
      <div
        className={`w-4 h-4 rounded-full absolute ${position} ${bgRound}`}
      ></div>
    </div>
  );
};
