import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import CheckWhiteIcon from "../../assets/icons/check-white.svg?react";
import { cn } from "../../helpers/classHelper";
import { useChecklist } from "../../hooks/api/checklist";
import { ValueType } from "../../types/commonTypes";
import {
  MessageTemplatePhotoResponse,
  PhotoListItemResponse,
  RentalChecklistsResponse,
} from "../../types/GETTypes";
import { Button } from "../Common/Button/Button";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../Common/InputSelect/InputSelect";
import { NumberInput } from "../Common/NumberInput/NumberInput";
import { PictureInput } from "../Common/PictureInput/PictureInput";
import { RightModal } from "../Common/RightModal/RightModal";
import { Separator } from "../Common/Separator/Separator";
import { SimpleRadio } from "../Common/SimpleRadio/SimpleRadio";
import { TextInput } from "../Common/TextInput/TextInput";
import { ChecklistUpsertForm } from "./Checklist.type";
import SectionsComponent from "./SectionsComponent";

export const ChecklistUpsertModal: React.FC<{
  checklist: RentalChecklistsResponse | null | undefined;
  rentalId: ValueType;
  isVisible: boolean;
  onClose: () => void;
  onSuccessAdd: (checklist: RentalChecklistsResponse) => void;
  onSuccessEdit: (checklist: RentalChecklistsResponse) => void;
  onAskRemove: (id: ValueType) => void;
}> = ({
  checklist,
  isVisible,
  rentalId,
  onClose,
  onSuccessAdd,
  onSuccessEdit,
  onAskRemove,
}) => {
  const { t } = useTranslation();
  const [oldFiles, setOldFiles] = useState<PhotoListItemResponse[]>([]);
  const [newFiles, setNewFiles] = useState<Blob[]>([]);
  const [photosRemoveIds, setPhotosRemoveIds] = useState<ValueType[]>([]);
  const [videosRemoveIds, setVideosRemoveIds] = useState<ValueType[]>([]);

  const form = useForm<ChecklistUpsertForm>({
    defaultValues: {
      name: "",
      description: "",
      type: "cleaning",
      remuneration_type: "fixed",
      amount_fixed: 0,
      amount_by_bed: 0,
      sections: [
        {
          section_id: 1,
          name: "Section 1",
          section_order: 0,
          steps: [
            {
              step_id: 1,
              name: t("Rental.Checklist.Upsert.stepName"),
              order: 0,
            },
          ],
        },
      ],
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (checklist) {
      form.setValue("name", checklist.name);
      form.setValue("description", checklist.description);
      form.setValue("type", checklist.type);
      form.setValue("remuneration_type", checklist.remuneration_type);
      form.setValue("amount_fixed", checklist.amount_fixed);
      form.setValue("amount_by_bed", checklist.amount_by_bed);
      form.setValue("sections", checklist.sections);

      console.log("useEffect sections", checklist.sections);

      setOldFiles(checklist.photos ?? []);
    }
  }, [checklist]);

  const getTitle = () => {
    if (!checklist) {
      return t("Rental.Checklist.Upsert.title");
    }

    return checklist?.name;
  };

  const exampleCalc = () => {
    return (
      Number(form.watch("amount_fixed")) +
      Number(3 * form.watch("amount_by_bed"))
    ).toFixed(2);
  };

  const handleClose = () => {
    if (loading) return;

    form.reset();
    setOldFiles([]);
    setPhotosRemoveIds([]);
    setNewFiles([]);
    setError(null);

    onClose();
  };

  const { create } = useChecklist({
    rentalId: rentalId,
    onSuccess: (checklist) => {
      onSuccessAdd(checklist!);
      handleClose();
    },
    onError: (message: string | null) => {
      setError(message);
    },
    onStart: () => {
      setLoading(true);
      setError(null);
    },
    onEnd: () => {
      setLoading(false);
    },
  });

  const { update } = useChecklist({
    rentalId: rentalId,
    onSuccess: (checklist) => {
      onSuccessEdit(checklist!);
      handleClose();
    },
    onError: (message: string | null) => {
      setError(message);
    },
    onStart: () => {
      setLoading(true);
      setError(null);
    },
    onEnd: () => {
      setLoading(false);
    },
  });

  const handleValid = (values: ChecklistUpsertForm) => {
    if (checklist)
      update(checklist.id, values, newFiles, photosRemoveIds, videosRemoveIds);
    else create(values, newFiles);
  };

  const handleRemovePhoto = (id: ValueType) => {
    setPhotosRemoveIds([...photosRemoveIds, id]);
  };

  return (
    <RightModal title={getTitle()} isVisible={isVisible} onClose={handleClose}>
      <form
        onSubmit={form.handleSubmit(handleValid)}
        className="flex-1 w-full h-full"
      >
        <div className="flex flex-col justify-between h-full">
          {/* Fields */}
          <div className="flex flex-col flex-1 w-full gap-3 pb-4 overflow-y-scroll">
            <p className="font-semibold text-low-contrast">
              {t("Rental.Checklist.Upsert.informations")}
            </p>

            <div>
              <Controller
                control={form.control}
                name="name"
                rules={{
                  required: {
                    value: true,
                    message: t("Rental.Checklist.Upsert.nameRequired"),
                  },
                }}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    label={t("Rental.Checklist.Upsert.name")}
                    required={true}
                    placeholder={t("Rental.Checklist.Upsert.namePlaceholder")}
                    disabled={loading}
                    value={value}
                    error={form.formState.errors.name?.message}
                    onChangeText={onChange}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                control={form.control}
                name="description"
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    label={t("Rental.Checklist.Upsert.description")}
                    placeholder={t(
                      "Rental.Checklist.Upsert.descriptionPlaceholder"
                    )}
                    disabled={loading}
                    value={value}
                    onChangeText={onChange}
                  />
                )}
              />
            </div>

            <div>
              <Controller
                control={form.control}
                name="type"
                render={({ field: { value, onChange } }) => (
                  <InputSelect
                    label={t("Rental.Checklist.Upsert.type")}
                    required={true}
                    items={[
                      {
                        label: t("Rental.Checklist.Upsert.cleaning"),
                        value: "cleaning",
                      },
                      {
                        label: t("Rental.Checklist.Upsert.checkin"),
                        value: "checkin",
                      },
                      {
                        label: t("Rental.Checklist.Upsert.checkout"),
                        value: "checkout",
                      },
                      {
                        label: t("Rental.Checklist.Upsert.incident"),
                        value: "incident",
                      },
                      {
                        label: t("Rental.Checklist.Upsert.other"),
                        value: "other",
                      },
                    ]}
                    disabled={loading}
                    onSelect={onChange}
                    selectedValue={value}
                  />
                )}
              />
            </div>

            {form.watch("type") === "cleaning" && (
              <>
                <p className="font-semibold text-low-contrast">
                  {t("Rental.Checklist.Upsert.howToCalculateCleaning")}
                </p>

                <Controller
                  control={form.control}
                  name="remuneration_type"
                  render={({ field: { value, onChange } }) => (
                    <div className="flex items-center gap-3">
                      <div
                        className="flex items-center gap-1 cursor-pointer w-52"
                        onClick={() => !loading && onChange("fixed")}
                      >
                        <SimpleRadio
                          value={value === "fixed"}
                          disabled={loading}
                        />
                        <p
                          className={
                            (cn("font-light"),
                            value === "fixed"
                              ? "text-active"
                              : "text-low-contrast")
                          }
                        >
                          {t("Rental.Checklist.Upsert.fixed")}
                        </p>
                      </div>

                      <div
                        className="flex items-center flex-1 gap-1 cursor-pointer"
                        onClick={() => !loading && onChange("by_bed")}
                      >
                        <SimpleRadio
                          value={value === "by_bed"}
                          disabled={loading}
                        />
                        <p
                          className={
                            (cn("font-light"),
                            value === "by_bed"
                              ? "text-active"
                              : "text-low-contrast")
                          }
                        >
                          {t("Rental.Checklist.Upsert.by_bed")}
                        </p>
                      </div>
                    </div>
                  )}
                />

                <div className="flex gap-3">
                  <div className="flex-1">
                    <Controller
                      control={form.control}
                      name="amount_fixed"
                      rules={{
                        required: {
                          value: true,
                          message: t(
                            "Rental.Checklist.Upsert.cleaningChargeRequired"
                          ),
                        },
                      }}
                      render={({ field: { value, onChange } }) => (
                        <NumberInput
                          label={t("Rental.Checklist.Upsert.cleaningCharge")}
                          required={true}
                          currency="€"
                          acceptDecimal={true}
                          acceptNegative={false}
                          disabled={loading}
                          error={form.formState.errors.amount_fixed?.message}
                          value={value}
                          onChangeText={onChange}
                        />
                      )}
                    />
                  </div>

                  {form.watch("remuneration_type") === "by_bed" && (
                    <div className="flex-1">
                      <Controller
                        control={form.control}
                        name="amount_by_bed"
                        rules={{
                          required: {
                            value: true,
                            message: t(
                              "Rental.Checklist.Upsert.byBedChargeRequired"
                            ),
                          },
                        }}
                        render={({ field: { value, onChange } }) => (
                          <NumberInput
                            label={t("Rental.Checklist.Upsert.byBedCharge")}
                            required={true}
                            currency="€"
                            acceptDecimal={true}
                            acceptNegative={false}
                            disabled={loading}
                            error={form.formState.errors.amount_by_bed?.message}
                            value={value}
                            onChangeText={onChange}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>

                {form.watch("remuneration_type") === "by_bed" && (
                  <div>
                    <p className="text-xs font-light text-low-contrast">
                      {t("Rental.Checklist.Upsert.descriptionExampleCalc")}
                    </p>
                    <p className="text-xs font-light text-low-contrast">
                      <Trans
                        i18nKey="Rental.Checklist.Upsert.exampleCalc"
                        components={{
                          strong: <span className="font-bold"></span>,
                        }}
                        values={{
                          cleaning: form.watch("amount_fixed"),
                          byBed: form.watch("amount_by_bed"),
                          result: exampleCalc(),
                        }}
                      />
                    </p>
                  </div>
                )}
              </>
            )}

            {form.watch("type") !== "cleaning" && (
              <div>
                <Controller
                  control={form.control}
                  name="amount_fixed"
                  rules={{
                    required: {
                      value: true,
                      message: t("Rental.Checklist.Upsert.amountRequired"),
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <NumberInput
                      label={t("Rental.Checklist.Upsert.amount")}
                      required={true}
                      acceptDecimal={true}
                      acceptNegative={false}
                      currency="€"
                      disabled={loading}
                      error={form.formState.errors.amount_fixed?.message}
                      value={value}
                      onChangeText={onChange}
                    />
                  )}
                />
              </div>
            )}

            <Separator classNames={{ divParent: "my-1" }} />

            <Controller
              control={form.control}
              name="sections"
              rules={{
                validate: (value) => {
                  console.log("validate", value);
                  if (value.length === 0)
                    return t("Rental.Checklist.Upsert.sectionsRequired");

                  const firstSection = value[0];
                  if (firstSection.steps?.length === 0)
                    return t("Rental.Checklist.Upsert.sectionsRequired");

                  return true;
                },
              }}
              render={({ field: { value, onChange } }) => (
                <>
                  <SectionsComponent sectionsData={value} onChange={onChange} />
                  <ErrorMessage errorType="FORM">
                    {form.formState.errors.sections?.message}
                  </ErrorMessage>
                </>
              )}
            />

            <Separator classNames={{ divParent: "my-1" }} />

            <p className="font-semibold text-low-contrast">
              {t("Rental.Checklist.Upsert.photos")}
            </p>

            <div className="flex flex-wrap items-center gap-2">
              <PictureInput
                displayCover={false}
                disabled={loading}
                mode="all"
                type=" image|file"
                oldFiles={
                  oldFiles?.filter(
                    (p) => !photosRemoveIds?.includes(p.id)
                  ) as MessageTemplatePhotoResponse[]
                }
                newFiles={newFiles}
                onChangeNewFiles={(files) => setNewFiles(files)}
                onDeleteFile={(file) => handleRemovePhoto(file)}
                title="Global.addFileHere"
              />
            </div>
          </div>

          {/* Error & Buttons */}
          <div className="flex flex-col pt-4 gap-y-3 border-t-1 border-element-border">
            <ErrorMessage>{error}</ErrorMessage>
            <div className="flex gap-x-3">
              <Button type="secondary" disabled={loading} onClick={handleClose}>
                {t("Global.cancel")}
              </Button>

              {checklist ? (
                <>
                  <Button
                    type="alert"
                    disabled={loading}
                    onClick={() => onAskRemove(checklist.id)}
                  >
                    {t("Global.remove")}
                  </Button>
                  <Button RightIcon={CheckWhiteIcon} loading={loading}>
                    {t("Global.save")}
                  </Button>
                </>
              ) : (
                <Button RightIcon={CheckWhiteIcon} loading={loading}>
                  {t("Rental.Checklist.Upsert.add")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </form>
    </RightModal>
  );
};
