import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MessageItem } from "../MessageItem/MessageItem";
import { MessageItemSkeleton } from "../MessageItem/MessageItemSkeleton";

import AlertCircle from "../../../assets/icons/alert-circle.svg?react";
import paths from "../../../constants/paths";
import { get } from "../../../helpers/APIHelper";
import { UserResponse } from "../../../types/GETTypes";
import { MessageItemService } from "../MessageItem/MessageItemService";
import { MessagePlaceholder } from "./MessagePlaceholder";
import { MessagesAreaProps } from "./ViewAndSendMessages.type";

export const MessagesArea: React.FC<MessagesAreaProps> = ({
  messages,
  loading,
  bottomElement,
  reservation,
  isLastPage,
  onLoad,
  isNewReservationsList,
  numberOfConversations,
}) => {
  const { t } = useTranslation();

  const divRef = useRef(null);

  // TODO PUT IN CONTEXT
  const [user, setUser] = useState<UserResponse>();
  const [currentScrollBottom, setCurrentScrollBottom] = useState<number>(0);
  const [currentNbMessages, setCurrentNbMessages] = useState<number>(0);
  const fetchUser = async () => {
    const responseUser = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.USER.GET_USER}`
    );

    if (responseUser?.data?.success) {
      setUser(responseUser?.data?.result);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (bottomElement.current) {
      bottomElement.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleScroll = (e: any) => {
    setCurrentScrollBottom(e.target.scrollHeight - e.target.scrollTop);

    if (!loading && e.target.scrollTop <= 100 && !isLastPage) {
      onLoad();
    }
  };

  useEffect(() => {
    const divElement = divRef.current;
    if (!divElement || !messages) return;
    if (messages.length <= currentNbMessages) return;

    divElement.scrollTop = divElement.scrollHeight - currentScrollBottom;

    setCurrentNbMessages(messages.length);
  }, [messages]);

  return (
    <div className="relative flex flex-col flex-1 overflow-y-scroll text-sm">
      {reservation?.platform?.name?.toUpperCase() === "DIRECT" &&
        !isNewReservationsList && (
          <div className="flex w-full p-5 bg-element-background border-b-1">
            <div className="flex justify-center gap-2">
              <AlertCircle className="w-5 h-5 shrink-0 fill-gray-500" />
              <div className="flex flex-col gap-2">
                <p className="font-semibold text-high-contrast">
                  {t("MessageList.messageInfo1")}
                </p>
                <p className="text-sm text-low-contrast">
                  {t("MessageList.messageInfo2")}
                </p>
              </div>
            </div>
          </div>
        )}
      <div
        className="flex flex-col p-4 pt-0 overflow-y-scroll"
        ref={divRef}
        onScroll={handleScroll}
      >
        {(!isLastPage || loading) &&
          !isNewReservationsList &&
          Array.from({ length: 2 }).map((value, index, array) => (
            <div key={index}>
              <MessageItemSkeleton isUser={true} />
              <MessageItemSkeleton isUser={false} />
            </div>
          ))}
        {!loading &&
          isLastPage &&
          (!messages || messages?.length === 0) &&
          !isNewReservationsList && (
            <MessageItemService
              content={t("MessageList.noMessages").toString()}
            />
          )}

        {!loading &&
          !isNewReservationsList &&
          messages?.map((message) => {
            if (message.from === "service")
              return (
                <MessageItemService
                  key={message.id}
                  content={message.content}
                />
              );

            return (
              <div className="flex flex-col w-full">
                <MessageItem
                  key={message.id}
                  isUser={message.from === "user"}
                  dateTime={message.captured_at}
                  fullName={
                    message.from === "user" &&
                    (message.display_name === null ||
                      message.display_name === "")
                      ? `${user?.first_name} ${user?.last_name} (${t(
                          "MessageList.you"
                        )})`
                      : message.display_name != null
                      ? message.display_name
                      : `${reservation?.guest?.first_name} ${reservation?.guest?.last_name}`
                  }
                  content={message.content}
                  isAutomated={false}
                  messageType={message.type}
                  readAt="à ???"
                  avatar={
                    message.type === "airbnb"
                      ? message.picture_url
                      : message.from === "user"
                      ? user?.photo!
                      : reservation?.guest?.photo!
                  }
                  photos={message.photos}
                  type={message.type}
                />
              </div>
            );
          })}
        <div ref={bottomElement}></div>
      </div>

      {isNewReservationsList && numberOfConversations > 0 && (
        <div className="flex items-center justify-center flex-1">
          <p className="text-sm font-semibold text-high-contrast">
            {" "}
            {t("Global.noConversationSelected")}
          </p>
        </div>
      )}

      {!loading && numberOfConversations === 0 && <MessagePlaceholder />}
    </div>
  );
};
