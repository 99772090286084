import React, { useState } from "react";
import { capitalizeFirstLetter } from "../../../helpers/stringHelper";
import moment from "moment";
import { TaskItemResponse, TaskListDayResponse } from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import CheckListBlueIcon from "../../../assets/icons/check-list-blue.svg?react";
import CheckCircleGreenIcon from "../../../assets/icons/check-circle-green.svg?react";
import ClockOrangeIcon from "../../../assets/icons/clock-warning.svg?react";
import ChevronDownIcon from "../../../assets/icons/chevron-down.svg?react";
import ChevronUpIcon from "../../../assets/icons/chevron-up.svg?react";
import CloseCircleRedIcon from "../../../assets/icons/close-circle-red.svg?react";
import { Button } from "../../Common/Button/Button";
import { UseModal, useModal } from "../../../hooks/useModal";
import { PreviewImage } from "../../Common/PreviewImage/PreviewImage";

export const TaskListWithTasks: React.FC<{
  date: string;
  taskListDay: TaskListDayResponse;
  openEditTaskModal: (task: TaskItemResponse) => void;
  onAcceptTask: (task: TaskItemResponse) => void;
  onDeclineTask: (task: TaskItemResponse) => void;
}> = ({
  date,
  taskListDay,
  openEditTaskModal,
  onAcceptTask,
  onDeclineTask,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const buildSummarySentence = () => {
    const types: string[] = [];

    if (taskListDay.total_cleaning_tasks > 0) {
      types.push(
        t("Task.List.countCleaning", {
          count: taskListDay.total_cleaning_tasks,
        }),
      );
    }

    if (taskListDay.total_checkin_tasks > 0) {
      types.push(
        t("Task.List.countCheckin", {
          count: taskListDay.total_checkin_tasks,
        }),
      );
    }

    if (taskListDay.total_checkout_tasks > 0) {
      types.push(
        t("Task.List.countCheckout", {
          count: taskListDay.total_checkout_tasks,
        }),
      );
    }

    if (taskListDay.total_incident_tasks > 0) {
      types.push(
        t("Task.List.countIncident", {
          count: taskListDay.total_incident_tasks,
        }),
      );
    }

    if (taskListDay.total_other_tasks > 0) {
      types.push(
        t("Task.List.countOther", {
          count: taskListDay.total_other_tasks,
        }),
      );
    }

    return types?.join(", ");
  };

  const getStatusBadge = (task: TaskItemResponse) => {
    if (task.status === "ASSIGNED")
      return (
        <div className="flex gap-2 bg-[#EBF0F9] rounded-6px items-center w-fit p-3">
          <CheckListBlueIcon className="size-4" />
          <p className="text-[#232F6F] font-semibold">
            {t("Task.List.scheduled")}
          </p>
        </div>
      );
    if (task.status === "NOT_ASSIGNED")
      return (
        <div className="flex gap-2 bg-[#EBF0F9] rounded-6px items-center w-fit p-3">
          <CheckListBlueIcon className="size-4" />
          <p className="text-[#232F6F] font-semibold">
            {t("Task.List.scheduled")}
          </p>
        </div>
      );
    if (task.status === "STARTED")
      return (
        <div className="flex gap-2 bg-[#FFF4D5] rounded-6px items-center w-fit p-3">
          <ClockOrangeIcon className="size-4" />
          <p className="text-[#AD5700] font-semibold">
            {t("Task.List.inProgress")}
          </p>
        </div>
      );
    if (task.status === "COMPLETED")
      return (
        <div className="flex gap-2 bg-[#EBF9EB] rounded-6px items-center w-fit p-3">
          <CheckCircleGreenIcon className="size-4" />
          <p className="text-[#297C3B] font-semibold">
            {t("Task.List.completed")}
          </p>
        </div>
      );
    if (task.status === "PARTIALLY_COMPLETED")
      return (
        <div className="flex gap-2 bg-[#FFF4D5] rounded-6px items-center w-fit p-3">
          <ClockOrangeIcon className="size-4" />
          <p className="text-[#AD5700] font-semibold">
            {t("Task.List.completed")}
          </p>
        </div>
      );
    if (task.status === "CANCELED")
      return (
        <div className="flex gap-2 bg-[#FFEFEF] rounded-6px items-center w-fit p-3">
          <CloseCircleRedIcon className="size-4" />
          <p className="text-[#CD2B31] font-semibold">
            {t("Task.List.canceled")}
          </p>
        </div>
      );

    return <p>{task.status}</p>;
  };

  const getOperatorsAvatars = (task: TaskItemResponse) => {
    return (
      <div
        className="relative"
        style={{ minWidth: 32 + 16 * (task.assigned_operators?.length - 1) }}
      >
        {task.assigned_operators?.map((ope, index) => (
          <img
            className="size-8 rounded-full border-1 border-element-border"
            src={ope.photo}
            style={{ paddingLeft: index * 16 }}
          />
        ))}
      </div>
    );
  };

  const getOperatorsNames = (task: TaskItemResponse) => {
    const names = task.assigned_operators?.map(
      (ope) => `${ope.first_name} ${ope.last_name}`,
    );

    return (
      <p className="font-semibold text-high-contrast line-clamp-1">
        {names?.join(",")}
      </p>
    );
  };

  const NotAssignedCell: React.FC<{
    task: TaskItemResponse;
    isDeclinedByMe: boolean;
    onAccept: (task: TaskItemResponse) => void;
    onDecline: (task: TaskItemResponse) => void;
  }> = ({ task, isDeclinedByMe, onAccept, onDecline }) => {
    return (
      <div className="flex flex-col items-center gap-2">
        <p className="font-semibold text-active">
          {t("Task.List.notAssigned")}
        </p>
        <div className="flex gap-2 items-center">
          {!isDeclinedByMe && (
            <Button type="alert" onClick={() => onDecline(task)}>
              {t("Task.List.refuse")}
            </Button>
          )}
          <Button onClick={() => onAccept(task)}>
            {t("Task.List.accept")}
          </Button>
        </div>
      </div>
    );
  };

  const modals = taskListDay?.day_tasks?.map(() => useModal());

  return (
    <div className="border-1 border-element-border rounded-6px flex flex-col overflow-hidden">
      <div
        className="flex items-center justify-between cursor-pointer bg-element-background-light"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex flex-col p-2 ">
          <p className="font-semibold text-high-contrast text-base">
            {capitalizeFirstLetter(moment(date).format("dddd DD MMMM YYYY"))}
          </p>

          <p className="font-light text-low-contrast">
            {buildSummarySentence()}
          </p>
        </div>

        <div className="pr-2">
          {isOpen ? (
            <ChevronUpIcon className="size-6" />
          ) : (
            <ChevronDownIcon className="size-6" />
          )}
        </div>
      </div>

      {isOpen && (
        <table>
          <tr className="text-left bg-element-background border-b-1 border-element-border">
            <th className="py-2 pl-2">{t("Task.List.rental")}</th>
            <th className="py-2 text-center">{t("Task.List.name")}</th>
            <th className="py-2 text-center">{t("Task.List.time")}</th>
            <th className="py-2 text-center">{t("Task.List.assignedTo")}</th>
            <th className="py-2 text-center">{t("Task.List.status")}</th>
          </tr>

          {taskListDay.day_tasks?.map(
            (task: TaskItemResponse, index: number) => {
              const previewImage = modals[index];
              return (
                <tr className="border-t-1 border-element-border cursor-pointer">
                  <td className="w-80">
                    <div className="flex gap-2 my-2 mx-2 items-center">
                      <img
                        className="size-12 rounded-6px border-1 border-element-border shrink-0"
                        src={task.rental?.cover}
                        onClick={() => previewImage.open()}
                      />
                      <PreviewImage
                        imageUrl={task.rental?.cover}
                        imageModal={previewImage}
                      />

                      <div className="flex flex-col">
                        <p className="font-semibold text-high-contrast">
                          {task.rental?.name}
                        </p>
                        <p className="font-light text-low-contrast">
                          {task.rental?.address} {task.rental?.postal_code}{" "}
                          {task.rental?.city}
                        </p>
                      </div>
                    </div>
                  </td>

                  <td className="w-64" onClick={() => openEditTaskModal(task)}>
                    <p className="font-semibold text-high-contrast text-center">
                      {task.name}
                    </p>
                  </td>

                  <td className="w-52" onClick={() => openEditTaskModal(task)}>
                    <p className="font-semibold text-high-contrast text-center">
                      {t("Task.List.fromTime", { time: task.scheduled_time })}
                    </p>
                  </td>

                  <td
                    onClick={() => {
                      if (task.assigned_operators?.length > 0)
                        openEditTaskModal(task);
                    }}
                  >
                    {task.assigned_operators?.length === 0 ? (
                      <NotAssignedCell
                        task={task}
                        isDeclinedByMe={
                          task.assigned_or_not_task_data?.is_declined_by_me ??
                          false
                        }
                        onAccept={onAcceptTask}
                        onDecline={onDeclineTask}
                      />
                    ) : (
                      <div className="h-8 flex justify-center items-center gap-1">
                        {getOperatorsAvatars(task)}
                        {getOperatorsNames(task)}
                      </div>
                    )}
                  </td>

                  <td onClick={() => openEditTaskModal(task)} className="w-32">
                    <div className="flex justify-center">
                      {getStatusBadge(task)}
                    </div>
                  </td>
                </tr>
              );
            },
          )}
        </table>
      )}
    </div>
  );
};
