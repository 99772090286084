import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg?react";
import CheckIcon from "../../assets/icons/check-white.svg?react";
import EditIcon from "../../assets/icons/edit.svg?react";
import EmojiHappyIcon from "../../assets/icons/emoji-happy.svg?react";
import HomeIcon from "../../assets/icons/home.svg?react";
import LogInIcon from "../../assets/icons/log-in.svg?react";
import MoneyIcon from "../../assets/icons/money.svg?react";
import UsersIcon from "../../assets/icons/users.svg?react";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import paths from "../../constants/paths";
import {
  PaymentReservationDepositStatusEnum,
  PaymentReservationStatusEnum,
} from "../../enums/GETenums";
import { get, post } from "../../helpers/APIHelper";
import { isValidHour } from "../../helpers/dateHelper";
import { getPlatformIcon } from "../../helpers/platformHelper";
import { isValueInEnum } from "../../helpers/validationHelper";
import {
  useDepositStatusLabel,
  useFetchPaymentSchedule,
  usePaymentReservationStatusBadge,
  usePaymentReservationStatusLabel,
  useReservationStatusBadge,
} from "../../pages/PaymentReservationList/PaymentReservationList.hooks";
import { RequiredFields } from "../../types/commonTypes";
import { Card } from "../Common/Card/Card";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../Common/InputSelect/InputSelect";
import { NumberInput } from "../Common/NumberInput/NumberInput";
import { Separator } from "../Common/Separator/Separator";
import { TimeInput } from "../Common/TimeInput/TimeInput";
import { ValuePlaceholder } from "../Common/ValuePlaceholder/ValuePlaceholder";
import { WorkInProgress } from "../WorkInProgress/WorkInProgress";
import {
  ReservationCardForm,
  ReservationCardPayload,
  ReservationCardProps,
} from "./ReservationCard.type";
import { ReservationCardSkeleton } from "./ReservationCardSkeleton";

import { PaymentReservationListModal } from "../Payment/PaymentReservationList/PaymentReservationListModal/PaymentReservationListModal";
import { PaymentDepositDetailsModal } from "../Payment/PaymentDepositList/PaymentDepositDetailsModal";
import { PaymentReservationModalUseMode } from "../Payment/PaymentReservationList/PaymentReservationListModal/PaymentReservationListModal.type";
import { useModal } from "../../hooks/useModal";
import { PaymentReservationResponse } from "../../types/GETTypes";
import { Button } from "../Common/Button/Button";
import { useDepositStatusBadge } from "../../helpers/reservationHelper";
import useUserStore from "../../stores/useUserStore";
import { getCurrentHidePrice } from "../../helpers/workspaceHelper";

export const ReservationCard: React.FC<ReservationCardProps> = ({
  loading,
  setLoading,
  reservation,
  disabled,
  onUpdate,
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();

  const {
    control,
    register,
    handleSubmit,
    formState: { isValid, errors },
    getValues,
    setValue,
    watch,
  } = useForm<ReservationCardForm>({
    mode: "all",
  });

  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [paymentReservationModalUseMode, setPaymentReservationModalUseMode] =
    useState<PaymentReservationModalUseMode>("paymentReservation");
  const [
    isPaymentReservationModalVisible,
    setIsPaymentReservationModalVisible,
  ] = useState<boolean>(false);
  const [currentPaymentReservation, setCurrentPaymentReservation] = useState<
    PaymentReservationResponse | undefined
  >();
  const [currentPaymentScheduleId, setCurrentPaymentScheduleId] = useState<
    number | undefined
  >();

  const paymentStatusBadge = usePaymentReservationStatusBadge(
    reservation?.status_payment as string
  );
  const depositStatusBadge = useDepositStatusBadge(reservation?.status_deposit);

  const depositModal = useModal();

  const fetchReservationPayment = async () => {
    const res = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.RESERVATIONS}/${
        reservation?.id
      }`
    );

    if (res.data?.success) {
      setCurrentPaymentReservation(res.data.result);
    }
  };

  const requiredFields: RequiredFields<ReservationCardForm> = {
    adults_count: true,
    children_count: true,
    time_in: true,
    time_out: true,
    status_payment: true,
    status_deposit: true,
  };

  useEffect(() => {
    setValue("adults_count", reservation?.adults_count ?? 0);
    setValue("children_count", reservation?.children_count ?? 0);
    setValue("time_in", reservation?.time_in ?? "");
    setValue("time_out", reservation?.time_out ?? "");
    if (
      reservation !== undefined &&
      isValueInEnum(reservation.status_payment, PaymentReservationStatusEnum)
    ) {
      setValue("status_payment", reservation.status_payment);
    } else {
      setValue("status_payment", PaymentReservationStatusEnum.UNPAID);
    }

    if (
      reservation !== undefined &&
      isValueInEnum(
        reservation.status_deposit,
        PaymentReservationDepositStatusEnum
      )
    ) {
      setValue("status_deposit", reservation.status_deposit);
    } else {
      setValue("status_deposit", PaymentReservationDepositStatusEnum.UNPAID);
    }

    if (reservation) {
      fetchReservationPayment();
    }
  }, [reservation]);

  const handleClickTransactionDetail = (
    paymentReservationId: number,
    paymentReservationDetailId: number
  ) => {
    if (
      paymentReservationId !== undefined &&
      paymentReservationId >= 0 &&
      paymentReservationDetailId !== undefined &&
      paymentReservationDetailId >= 0
    ) {
      useFetchPaymentSchedule(
        setLoading,
        [currentPaymentReservation, setCurrentPaymentReservation],
        paymentReservationId
      );
      console.log(
        "🚀 ~ paymentReservationDetailId:",
        paymentReservationDetailId
      );
      setCurrentPaymentScheduleId(paymentReservationDetailId);
      setPaymentReservationModalUseMode("paymentReservationDetail");
    }
  };

  const updateReservation = async () => {
    if (
      reservation !== undefined &&
      reservation?.platform.reservation_link === null &&
      reservation?.platform.reservation_reference === null
    ) {
      setError(undefined);
      setValidationLoading(true);

      const payload: ReservationCardPayload = {
        adults_count: getValues("adults_count"),
        children_count: getValues("children_count"),
        time_in: getValues("time_in"),
        time_out: getValues("time_out"),
        status_payment: getValues("status_payment"),
        status_deposit: getValues("status_deposit"),
      };

      console.log("data : ", payload);

      const response = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.RESERVATIONS}/${
          reservation?.id
        }`,
        {
          _method: "PUT",
          ...payload,
        }
      );

      if (response.data?.success) {
        onUpdate({
          adults_count: getValues("adults_count"),
          children_count: getValues("children_count"),
          time_in: getValues("time_in"),
          time_out: getValues("time_out"),
          status_payment: getValues("status_payment"),
          status_deposit: getValues("status_deposit"),
        });
        setEditMode(false);
      } else {
        setError(response.data?.message ?? response.message);
      }
      setValidationLoading(false);
    }
  };

  const handleReservationEdit = () => {
    if (editMode) {
      updateReservation();
    } else {
      setEditMode(true);
    }
  };

  watch();

  if (loading) return <ReservationCardSkeleton />;

  const isPlatform =
    reservation?.platform?.name?.toUpperCase() === "AIRBNB.COM" ||
    reservation?.platform?.name?.toUpperCase() === "BOOKING.COM";

  return (
    <>
      <Card
        Icon={HomeIcon}
        label={t("Booking.Reservation.title")}
        loading={validationLoading}
        button={
          reservation?.platform.reservation_link === null &&
          reservation?.platform.reservation_reference === null
            ? {
                Icon: editMode ? CheckIcon : EditIcon,
                type: editMode ? "primary" : "secondary",
                label: editMode ? t("Global.record") : t("Global.edit"),
                disabled:
                  (editMode && (validationLoading || !isValid)) || disabled,
                onClick: editMode
                  ? handleSubmit(handleReservationEdit)
                  : handleReservationEdit,
              }
            : undefined
        }
        secondaryButton={
          editMode
            ? {
                label: t("Global.cancel"),
                LeftIcon: ArrowLeftIcon,
                onClick: () => setEditMode(false),
              }
            : undefined
        }
      >
        <ErrorMessage>{error}</ErrorMessage>
        {editMode ? (
          <>
            <div className="flex flex-row items-center justify-between pb-5 space-x-3 border-b-1 border-element-border">
              <div className="flex items-center space-x-4">
                <div className="relative">
                  {reservation?.platform ? (
                    <div
                      className="absolute z-10"
                      style={{ top: 12, left: 34, width: 24, height: 24 }}
                    >
                      {getPlatformIcon(reservation?.platform!)}
                    </div>
                  ) : (
                    <div
                      className="absolute z-10 border-2 border-white rounded-full bg-slate-200"
                      style={{ top: 12, left: 34, width: 24, height: 24 }}
                    />
                  )}

                  {reservation?.rental?.details.photos.cover ? (
                    <img
                      src={reservation?.rental?.details.photos.cover}
                      alt="Rental thumbnail"
                      className="w-12 h-12 rounded-4px border-1 border-element-border/50 shrink-0"
                    />
                  ) : (
                    <div className="w-12 h-12 rounded-4px border-1 border-element-border/50 bg-slate-200" />
                  )}
                </div>

                <div className="flex flex-col space-y-1">
                  <p className="text-base font-semibold text-high-contrast">
                    {reservation?.rental?.details.informations.name}
                  </p>
                  <p className="text-sm font-light text-low-contrast">
                    {reservation?.rental?.details.informations.address}
                    <br />
                    {reservation?.rental?.details.informations.postal_code}{" "}
                    {reservation?.rental?.details.informations.city}{" "}
                    {reservation?.rental?.details.informations.country.name}
                  </p>
                </div>
              </div>

              {/* RESERVATION STATUS BADGE */}
              {useReservationStatusBadge(
                reservation?.status_reservation,
                "large"
              )}
            </div>

            <div className="flex flex-col gap-4 py-4">
              <div className="flex flex-row items-center">
                {/* REFERENCE */}
                <div className="flex-1 space-y-1">
                  <p className="text-sm font-light text-low-contrast">
                    {t("Booking.reference")}
                  </p>
                  {reservation?.platform.reservation_reference ? (
                    <p className="text-base font-semibold text-high-contrast">
                      {reservation?.platform.reservation_reference}
                    </p>
                  ) : (
                    <ValuePlaceholder
                      size="small"
                      value={t("Global.unknown", { context: "female" })}
                    />
                  )}
                </div>

                {/* TOTAL */}
                <div className="flex-1 space-y-1">
                  <p className="text-sm font-light text-low-contrast">
                    {t("Booking.total")}
                  </p>
                  <p className="text-base font-semibold text-high-contrast">
                    {reservation?.total_guest_price! > 0
                      ? reservation?.total_guest_price
                      : 0}{" "}
                    {t("Global.currencySymbol")}
                  </p>
                </div>
              </div>

              <div className="flex flex-row items-center">
                {/* NB NIGHTS */}
                <div className="flex-1 space-y-1">
                  <p className="text-sm font-light text-low-contrast">
                    {t("Booking.nbNights", {
                      count: moment(reservation?.checkout).diff(
                        moment(reservation?.checkin),
                        "days"
                      ),
                    })}
                  </p>
                  <p className="text-base font-semibold text-high-contrast">
                    {t("Global.nights", {
                      count:
                        moment(reservation?.checkout).diff(
                          moment(reservation?.checkin),
                          "days"
                        ) ?? 0,
                    }).toLowerCase()}
                  </p>
                </div>

                <div className="flex-1 space-y-1">
                  <div className="flex flex-row items-center space-x-4 font-semibold text-high-contrast">
                    {/* NB ADULTS */}
                    <NumberInput
                      required={requiredFields.adults_count}
                      disabled={validationLoading}
                      label={t("Booking.Reservation.nbAdultsLabel")}
                      acceptDecimal={false}
                      register={register("adults_count", {
                        required: {
                          value: requiredFields.adults_count,
                          message: t("Global.Errors.requiredField", {
                            fieldName: t("Booking.Reservation.nbAdultsLabel"),
                          }),
                        },
                      })}
                      value={getValues("adults_count")}
                      error={errors.adults_count?.message}
                    />

                    {/* NB CHILDREN */}
                    <NumberInput
                      required={requiredFields.children_count}
                      disabled={validationLoading}
                      label={t("Booking.Reservation.nbChildrenLabel")}
                      acceptDecimal={false}
                      register={register("children_count", {
                        required: {
                          value: requiredFields.children_count,
                          message: t("Global.Errors.requiredField", {
                            fieldName: t("Booking.Reservation.nbChildrenLabel"),
                          }),
                        },
                      })}
                      value={getValues("children_count")}
                      error={errors.children_count?.message}
                    />
                  </div>
                </div>
              </div>

              <Separator />

              <div className="flex flex-row items-center space-x-2">
                <div className="flex-1 space-y-1">
                  <p className="text-sm font-light text-low-contrast">
                    {t("Booking.checkinDate")}
                  </p>
                  <p className="text-base font-semibold text-high-contrast">
                    {moment(reservation?.checkin).format("dddd DD MMM YYYY")}
                  </p>
                </div>
                <div className="flex-1 space-y-1">
                  {/* TIME IN */}
                  <TimeInput
                    required={requiredFields.time_in}
                    disabled={validationLoading}
                    register={register("time_in", {
                      required: {
                        value: requiredFields.time_in,
                        message: t("Global.Errors.requiredField", {
                          fieldName: t("Rental.Infos.Infos.timeIn"),
                        }),
                      },
                    })}
                    label={t("Booking.checkinTime")}
                    size="large"
                    error={errors?.time_in?.message}
                    value={getValues("time_in")}
                  />
                </div>
              </div>

              <div className="flex flex-row items-center space-x-2">
                <div className="flex-1 space-y-1">
                  <p className="text-sm font-light text-low-contrast">
                    {t("Booking.checkoutDate")}
                  </p>
                  <p className="text-base font-semibold text-high-contrast">
                    {moment(reservation?.checkout).format("dddd DD MMM YYYY")}
                  </p>
                </div>
                <div className="flex-1 space-y-1">
                  {/* TIME OUT */}
                  <TimeInput
                    required={requiredFields.time_out}
                    disabled={validationLoading}
                    register={register("time_out", {
                      required: {
                        value: requiredFields.time_out,
                        message: t("Global.Errors.requiredField", {
                          fieldName: t("Rental.Infos.Infos.timeIn"),
                        }),
                      },
                    })}
                    label={t("Booking.checkoutTime")}
                    size="large"
                    error={errors?.time_out?.message}
                    value={getValues("time_out")}
                  />
                </div>
              </div>

              <Separator />

              {/* PAYMENTS */}
              <div className="flex flex-col p-2 space-y-4 rounded-md bg-subtle">
                <div className="flex items-center space-x-2">
                  <MoneyIcon className="w-6 h-6" />
                  <h3 className="text-sm font-light text-low-contrast">
                    {t("Global.payment", { count: 2 })}
                  </h3>
                </div>

                <div className="flex flex-row items-center space-x-2">
                  <div className="flex-col flex-1 space-y-1">
                    <Controller
                      control={control}
                      name="status_payment"
                      rules={{
                        required: {
                          value: requiredFields.status_payment,
                          message: t("Global.Errors.requiredField", {
                            fieldName: t(
                              "Booking.Reservation.paymentStatusLabel"
                            ),
                          }),
                        },
                        onChange: (event) => {
                          setValue("status_payment", event.target.value);
                        },
                      }}
                      render={({ field: { onChange } }) => (
                        <InputSelect
                          required={requiredFields.status_payment}
                          disabled={validationLoading}
                          label={t("Booking.Reservation.paymentStatusLabel")}
                          selectedValue={getValues("status_payment")}
                          maxItemWidth={true}
                          items={[
                            {
                              label: t("Booking.PaymentStatus.unpaid"),
                              value: PaymentReservationDepositStatusEnum.UNPAID,
                            },
                            {
                              label: t("Booking.PaymentStatus.overdue"),
                              value:
                                PaymentReservationDepositStatusEnum.OVERDUE,
                            },
                            {
                              label: t("Booking.PaymentStatus.paid"),
                              value: PaymentReservationDepositStatusEnum.PAID,
                            },
                            {
                              label: t("Booking.PaymentStatus.refunded"),
                              value:
                                PaymentReservationDepositStatusEnum.REFUNDED,
                            },
                          ]}
                          onSelect={onChange}
                        />
                      )}
                    />
                  </div>

                  <div className="flex-1 space-y-1">
                    <Controller
                      control={control}
                      name="status_deposit"
                      rules={{
                        required: {
                          value: requiredFields.status_deposit,
                          message: t("Global.Errors.requiredField", {
                            fieldName: t("Global.deposit"),
                          }),
                        },
                        onChange: (event) => {
                          setValue("status_deposit", event.target.value);
                        },
                      }}
                      render={({ field: { onChange } }) => (
                        <InputSelect
                          required={requiredFields.status_deposit}
                          disabled={validationLoading}
                          label={t("Global.deposit")}
                          selectedValue={getValues("status_deposit")}
                          maxItemWidth={true}
                          items={[
                            {
                              label: t("Booking.PaymentStatus.unpaid"),
                              value: PaymentReservationDepositStatusEnum.UNPAID,
                            },
                            {
                              label: t("Booking.PaymentStatus.overdue"),
                              value:
                                PaymentReservationDepositStatusEnum.OVERDUE,
                            },
                            {
                              label: t("Booking.PaymentStatus.paid"),
                              value: PaymentReservationDepositStatusEnum.PAID,
                            },
                            {
                              label: t("Booking.PaymentStatus.refunded"),
                              value:
                                PaymentReservationDepositStatusEnum.REFUNDED,
                            },
                          ]}
                          onSelect={onChange}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              {/* CHECKIN & CHECKOUT */}
              <div className="flex flex-col p-2 space-y-2 rounded-md bg-subtle">
                <div className="flex space-x-2">
                  <LogInIcon />
                  <p className="text-low-contrast">
                    {t("Booking.Reservation.checkinCheckoutTitle")}
                  </p>
                </div>
                <WorkInProgress />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row items-center justify-between pb-5 space-x-3 border-b-1 border-element-border">
              <div className="flex items-center space-x-4">
                <div className="relative">
                  {reservation?.platform ? (
                    <div
                      className="absolute z-10"
                      style={{ top: 12, left: 34, width: 24, height: 24 }}
                    >
                      {getPlatformIcon(reservation?.platform!)}
                    </div>
                  ) : (
                    <div
                      className="absolute z-10 border-2 border-white rounded-full bg-slate-200"
                      style={{ top: 12, left: 34, width: 24, height: 24 }}
                    />
                  )}

                  {reservation?.rental?.details.photos.cover ? (
                    <img
                      src={reservation?.rental?.details.photos.cover}
                      alt="Rental thumbnail"
                      className="w-12 h-12 rounded-4px border-1 border-element-border/50"
                    />
                  ) : (
                    <div className="w-12 h-12 rounded-4px border-1 border-element-border/50 bg-slate-200" />
                  )}
                </div>

                <div className="flex flex-col space-y-1">
                  <p className="text-base font-semibold text-high-contrast">
                    {reservation?.rental?.details.informations.name}
                  </p>
                  <p className="font-light text-low-contrast">
                    {reservation?.rental?.details.informations.address}
                    <br />
                    {reservation?.rental?.details.informations.postal_code}{" "}
                    {reservation?.rental?.details.informations.city}{" "}
                    {reservation?.rental?.details.informations.country.name}
                  </p>
                </div>
              </div>

              {/* RESERVATION STATUS BADGE */}
              {useReservationStatusBadge(
                reservation?.status_reservation,
                "large"
              )}
            </div>

            <div className="flex flex-col gap-4 py-4">
              <div className="flex flex-row items-center">
                {/* REFERENCE */}
                <div className="flex-1 space-y-1">
                  <p className="text-sm font-light text-low-contrast">
                    {t("Booking.reference")}
                  </p>
                  {reservation?.platform.reservation_reference ? (
                    <p className="text-base font-semibold text-high-contrast">
                      {reservation?.platform.reservation_reference}
                    </p>
                  ) : (
                    <ValuePlaceholder
                      size="small"
                      value={t("Global.unknown", { context: "female" })}
                    />
                  )}
                </div>

                {/* TOTAL */}
                <div className="flex-1 space-y-1">
                  <p className="text-sm font-light text-low-contrast">
                    {t("Booking.total")}
                  </p>
                  <p className="text-base font-semibold text-high-contrast">
                    {reservation?.total_guest_price! > 0
                      ? reservation?.total_guest_price
                      : 0}{" "}
                    {t("Global.currencySymbol")}
                  </p>
                </div>
              </div>

              <div className="flex flex-row items-center">
                {/* NB NIGHTS */}
                <div className="flex-1 space-y-1">
                  <p className="text-sm font-light text-low-contrast">
                    {t("Booking.nbNights", {
                      count: moment(reservation?.checkout).diff(
                        moment(reservation?.checkin),
                        "days"
                      ),
                    })}
                  </p>
                  <p className="text-base font-semibold text-high-contrast">
                    {t("Global.nights", {
                      count:
                        moment(reservation?.checkout).diff(
                          moment(reservation?.checkin),
                          "days"
                        ) ?? 0,
                    }).toLowerCase()}
                  </p>
                </div>

                {/* NB GUESTS */}
                <div className="flex-1 space-y-1">
                  <p className="text-sm font-light text-low-contrast">
                    {t("Booking.nbGuests")}
                  </p>
                  <div className="flex flex-row items-center space-x-3 text-base font-semibold text-high-contrast">
                    <div className="flex items-center space-x-1">
                      <UsersIcon width={20} height={20} />
                      <p>
                        {t("Global.adults", {
                          count: reservation?.adults_count,
                        }).toLowerCase()}
                      </p>
                    </div>
                    {reservation && reservation?.children_count > 0 ? (
                      <div className="flex items-center space-x-1">
                        <EmojiHappyIcon width={20} height={20} />
                        <p>
                          {t("Global.children", {
                            count: reservation?.children_count,
                          }).toLowerCase()}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <Separator />

              <div className="flex flex-row items-center space-x-2">
                {/* CHECKIN DATE */}
                <div className="flex-1 space-y-1">
                  <p className="text-sm font-light text-low-contrast">
                    {t("Booking.checkinDate")}
                  </p>
                  <p className="text-base font-semibold text-high-contrast">
                    {moment(reservation?.checkin).format("dddd DD MMM YYYY")}
                  </p>
                </div>
                {/* CHECKIN TIME */}
                <div className="flex-1 space-y-1">
                  <p className="text-sm font-light text-low-contrast">
                    {t("Booking.checkinTime")}
                  </p>
                  <p className="text-base font-semibold text-high-contrast">
                    {isValidHour(reservation?.time_in)
                      ? reservation?.time_in
                      : t("Global.notDefined")}
                  </p>
                </div>
              </div>

              <div className="flex flex-row items-center space-x-2">
                {/* CHECKOUT DATE */}
                <div className="flex-1 space-y-1">
                  <p className="text-sm font-light text-low-contrast">
                    {t("Booking.checkoutDate")}
                  </p>
                  <p className="text-base font-semibold text-high-contrast">
                    {moment(reservation?.checkout).format("dddd DD MMM YYYY")}
                  </p>
                </div>
                {/* CHECKOUT TIME */}
                <div className="flex-1 space-y-1">
                  <p className="text-sm font-light text-low-contrast">
                    {t("Booking.checkoutTime")}
                  </p>
                  <p className="text-base font-semibold text-high-contrast">
                    {isValidHour(reservation?.time_out)
                      ? reservation?.time_out
                      : t("Global.notDefined")}
                  </p>
                </div>
              </div>

              <Separator />

              {/* PAYMENTS */}
              <div className="flex flex-col p-2 space-y-4 rounded-md bg-subtle">
                <div className="flex items-center space-x-2">
                  <MoneyIcon className="w-6 h-6" />
                  <h3 className="text-sm font-light text-low-contrast">
                    {t("Global.payment", { count: 2 })}
                  </h3>
                </div>

                <div className="flex flex-row items-center space-x-2">
                  <div className="flex-col flex-1 space-y-1">
                    <p className="text-sm font-light text-low-contrast">
                      {t("Booking.Reservation.paymentStatusLabel")}
                    </p>
                    <div className="flex flex-row items-center">
                      <p className="text-base font-semibold text-high-contrast">
                        {paymentStatusBadge}
                      </p>
                      <Button
                        type="secondary"
                        onClick={() =>
                          !getCurrentHidePrice({
                            user: userStore.user,
                            workspaceOwner: reservation?.workspace_owner,
                          }) && setIsPaymentReservationModalVisible(true)
                        }
                        disabled={getCurrentHidePrice({
                          user: userStore.user,
                          workspaceOwner: reservation?.workspace_owner,
                        })}
                        displayLargeBtn={false}
                        className="ml-2"
                        RightIcon={ArrowRightIcon}
                      >
                        {t("Global.view")}
                      </Button>
                    </div>
                  </div>

                  <div className="flex-1 space-y-1">
                    <p className="text-sm font-light text-low-contrast">
                      {t("Global.deposit")}
                    </p>
                    <div className="flex flex-row items-center">
                      <p className="text-base font-semibold text-high-contrast">
                        {depositStatusBadge}
                      </p>
                      {!isPlatform ? (
                        <Button
                          type="secondary"
                          onClick={
                            !getCurrentHidePrice({
                              user: userStore.user,
                              workspaceOwner: reservation?.workspace_owner,
                            }) && depositModal.open
                          }
                          displayLargeBtn={false}
                          className="ml-2"
                          RightIcon={ArrowRightIcon}
                          disabled={getCurrentHidePrice({
                            user: userStore.user,
                            workspaceOwner: reservation?.workspace_owner,
                          })}
                        >
                          {t("Global.view")}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              {/* CHECKIN & CHECKOUT */}
              <div className="flex flex-col p-2 space-y-2 rounded-md bg-subtle">
                <div className="flex space-x-2">
                  <LogInIcon />
                  <p className="text-low-contrast">
                    {t("Booking.Reservation.checkinCheckoutTitle")}
                  </p>
                </div>
                <WorkInProgress />
              </div>
            </div>
          </>
        )}
      </Card>
      <PaymentReservationListModal
        loading={false}
        useMode={paymentReservationModalUseMode!}
        isVisible={isPaymentReservationModalVisible}
        paymentReservation={currentPaymentReservation}
        onBack={() => setPaymentReservationModalUseMode("paymentReservation")}
        onClose={() => setIsPaymentReservationModalVisible(false)}
        onTransactionDetailClick={handleClickTransactionDetail}
      />

      <PaymentDepositDetailsModal
        isVisible={depositModal.isVisible}
        paymentReservation={currentPaymentReservation}
        onBack={depositModal.close}
        onClose={() => {}}
      />
    </>
  );
};
