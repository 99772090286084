import React from "react";
import { RightModal } from "../../../Common/RightModal/RightModal";
import { useSmartlocksContext } from "./SmartlocksContext";

export const rentalSmartlocksListSkeleton = () => {
  return (
    <div className="flex flex-col w-full space-y-4 animate-pulse">
      <div className="h-20 rounded bg-slate-200"></div>
      <div className="h-20 rounded bg-slate-200"></div>
      <div className="h-20 rounded bg-slate-200"></div>
    </div>
  );
};

export const rentalSmartlocksAddInformationsLoginSkeleton = () => {
  const { loginForm } = useSmartlocksContext();

  return (
    <div className="flex flex-col space-y-4">
      {loginForm.getValues("lockProvider") === "static" ? (
        <>
          <div className="h-8 rounded bg-slate-200"></div>
        </>
      ) : (
        <>
          <div className="h-8 rounded bg-slate-200"></div>

          <div className="h-8 rounded bg-slate-200"></div>

          <div className="flex space-x-4">
            <div className="flex-1">
              <div className="h-8 rounded bg-slate-200"></div>
            </div>

            <div className="flex-1">
              <div className="h-8 rounded bg-slate-200"></div>
            </div>
          </div>

          <div>
            <div className="h-4 mb-2 rounded bg-slate-200"></div>
            <div className="h-4 rounded bg-slate-200"></div>
          </div>
        </>
      )}

      <div className="w-full">
        <div className="h-8 rounded bg-slate-200"></div>
      </div>
    </div>
  );
};

export const rentalSmartlocksUpdateModalSkeleton = () => {
  const { isUpdateModalVisible, onCloseUpdateModal } = useSmartlocksContext();
  return (
    <RightModal
      title=""
      isVisible={isUpdateModalVisible}
      onClose={onCloseUpdateModal}
      hasSkeleton={true}
    >
      <div className="flex flex-col justify-between w-full animate-pulse">
        <div className="flex flex-col space-y-4">
          {/* LOCK CARD */}
          <div className="h-20 rounded bg-slate-200"></div>

          <p className="h-16 bg-slate-200"></p>

          <div className="h-16 rounded bg-slate-200"></div>

          <div className="h-20 rounded bg-slate-200"></div>

          <div className="flex space-x-4">
            <div className="w-1/2 rounded h-14 bg-slate-200"></div>
            <div className="w-1/2 rounded h-14 bg-slate-200"></div>
          </div>

          <p className="h-8 rounded-2xl bg-slate-200"></p>
          <p className="h-8 rounded-2xl bg-slate-200"></p>
        </div>

        <div className="flex justify-between">
          <div className="w-32 h-8 rounded bg-slate-200"></div>
          <div className="w-32 h-8 rounded bg-slate-200"></div>
          <div className="w-32 h-8 rounded bg-slate-200"></div>
        </div>
      </div>
    </RightModal>
  );
};

export const rentalSmartlocksLoginStepFormSkeleton = () => {
  return (
    <div className="flex flex-col justify-center space-y-4 animate-pulse">
      <div>
        <p className="h-2 mb-2 rounded-lg w-52 bg-slate-200"></p>
        <div className="w-full h-8 rounded bg-slate-200"></div>
      </div>

      <div className="w-full h-8 rounded bg-slate-200"></div>

      <p className="block w-10 h-2 mx-auto rounded bg-slate-200"></p>

      <div className="w-full h-8 rounded bg-slate-200"></div>

      <div className="flex flex-col space-y-1">
        <p className="w-full h-2 rounded bg-slate-200"></p>
        <p className="w-full h-2 rounded bg-slate-200"></p>
        <p className="h-2 rounded w-52 bg-slate-200"></p>
      </div>
    </div>
  );
};
