import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import InformationIcon from "../../../../assets/icons/information.svg?react";
import paths from "../../../../constants/paths";
import { useTablePage } from "../../../../hooks/useTablePage";
import { ValueType } from "../../../../types/commonTypes";
import { RentalLightListItemResponse } from "../../../../types/GETTypes";
import { MultiSelectRentalsTable } from "../../../Common/MultiSelectRentalsTable/MultiSelectRentalsTable";
import { ProgressionRentalsRulesModalRef } from "../ProgressionRentalsRulesModal.type";

export const ProgressionRentalsRulesMulti = forwardRef<
  ProgressionRentalsRulesModalRef,
  {
    excludeIds: ValueType[];
    selectedRentals: ValueType[];
    loading: boolean;
    onSelectRental: (id: ValueType) => void;
    onNext: () => void;
    rental: RentalLightListItemResponse | undefined;
  }
>(
  (
    { excludeIds, selectedRentals, loading, onSelectRental, onNext, rental },
    ref
  ) => {
    const { t } = useTranslation();

    const tablePage = useTablePage(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`,
      "rentals"
    );

    if (tablePage.data && rental?.id) {
      tablePage.data = tablePage.data.filter((d: any) => d.id !== rental.id);
    }

    useEffect(() => {
      tablePage.fetch({ exclude_ids: excludeIds });
    }, []);

    // * Expose method to submit from parent component (ProgressionRentalsRulesModal)
    useImperativeHandle(ref, () => ({
      submit: () => onNext(),
    }));

    return (
      <div className="flex flex-col gap-1">
        <div className="flex items-start gap-2 p-4 bg-element-background rounded-8px">
          <InformationIcon className="w-12 h-auto" />
          <p className="font-semibold text-low-contrast">
            {t("Progression.RentalsRules.Multi.info")}
          </p>
        </div>

        <MultiSelectRentalsTable
          tablePage={tablePage}
          selectedRentals={selectedRentals}
          onSelectRental={onSelectRental}
        />
      </div>
    );
  }
);
