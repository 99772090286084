import { t } from "i18next";
import moment from "moment";
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg?react";
import CalendarIcon from "../../../assets/icons/calendar.svg?react";
import {
  convertInputDateToLocaleFormat,
  isValidStringDate,
} from "../../../helpers/dateHelper";
import { DateInput } from "../DateInput/DateInput";
import {
  DateInputProps,
  DateInputType,
  DatesType,
} from "../DateInput/DateInput.type";
import {
  CalendarInputValue,
  CalendarInputValueDefault,
  CalendarInputValueRange,
} from "./CalendarInput.type";

/**
 * This hooks is used to initialize states
 * @returns
 */
export const useInitCalendarInputStates = () => {
  const [dates, setDates] = useState<DatesType>("");
  const [isPopupVisible, setPopupVisible] = useState<boolean>(false);
  const [focusedInput, setFocusedInput] = useState<"start" | "end" | undefined>(
    undefined
  );

  return {
    datesState: [dates, setDates],
    isPopupVisibleState: [isPopupVisible, setPopupVisible],
    focusedInputState: [focusedInput, setFocusedInput],
  } as const;
};

/**
 * This hooks is used to initialize the component
 * @param value
 * @param setDates
 * @param isVisible
 * @param setPopupVisible
 */
export const useInitCalendarInput = (
  value: CalendarInputValue | undefined,
  focusedInputState: [
    "start" | "end" | undefined,
    Dispatch<SetStateAction<"start" | "end" | undefined>>
  ],
  dateType: "default" | "range",
  datesState: [DatesType, Dispatch<SetStateAction<DatesType>>],
  isVisible: boolean | undefined,
  setPopupVisible: Dispatch<SetStateAction<boolean>>
) => {
  const [focusedInput, setFocusedInput] = focusedInputState;
  const [dates, setDates] = datesState;

  useEffect(() => {
    if (value !== undefined) {
      if (dateType === "range" && Array.isArray(value) && value.length >= 2) {
        setDates([
          value[0] instanceof Date ? moment(value[0]).format("DD/MM/YYYY") : "",
          value[1] instanceof Date ? moment(value[1]).format("DD/MM/YYYY") : "",
        ]);
      } else if (dateType === "default" && !Array.isArray(value)) {
        setDates(value ? moment(value).format("DD/MM/YYYY") : "");
      }
    }
  }, [value]);

  useEffect(() => {
    if (isVisible !== undefined) {
      setPopupVisible(isVisible);
    }
  }, [isVisible]);
};

/**
 * This hooks is used to retrieve the default node
 * @param dates
 * @returns
 */
export const useGetCalendarInputDefaultNode = (dates: DatesType): ReactNode => {
  const date: string = dates as string;
  return (
    <>
      <p className="flex-1 pointer-events-none select-none">
        {date ? date : "dd/mm/yyyy"}
      </p>

      <CalendarIcon />
    </>
  );
};

/**
 * This hooks is used to retrieve the input range node
 * @param dates
 * @returns
 */
export const useGetCalendarInputRangeNode = (dates: DatesType): ReactNode => {
  let node: ReactNode | null = null;

  let newDates: DatesType = dates;
  let startDate: DateInputType = "";
  let endDate: DateInputType = "";
  if (Array.isArray(newDates) && newDates.length >= 2) {
    startDate = newDates[0];
    endDate = newDates[1];
  }

  node = (
    <div className="flex items-center gap-3 pointer-events-none select-none">
      <CalendarIcon />
      {startDate === "" && endDate === "" ? (
        <span className="text-base">
          {t("Components.DateFilter.notFiltered")}
        </span>
      ) : (
        <>
          {startDate === "" ? (
            <span className="text-base">dd/mm/yyyy</span>
          ) : (
            <span className="text-base">{startDate}</span>
          )}
          <ArrowRightIcon />
          {endDate === "" ? (
            <span className="text-base">dd/mm/yyyy</span>
          ) : (
            <span className="text-base">{endDate}</span>
          )}
        </>
      )}
    </div>
  );
  return node;
};

/**
 * This hooks is used to retrieve the inputs
 * @param dateType
 * @param dates
 * @param onChangeStartDateInput
 * @param onChangeEndDateInput
 * @returns
 */
export const useGetCalendarInputs = (
  dateType: "default" | "range",
  dates: DatesType,
  setFocusedInput: Dispatch<SetStateAction<"start" | "end" | undefined>>,
  onChangeStartDateInput: (value: string) => void,
  onChangeEndDateInput: (value: string) => void
): ReactNode | null => {
  let newDate: DatesType = "";
  let inputsNode: ReactNode | null = null;

  switch (dateType) {
    case "default":
      if (!Array.isArray(dates)) {
        newDate = dates as DateInputType;
      }

      let dateInputProps: DateInputProps = {};

      // * We add value prop only if newDate not null
      if (isValidStringDate(newDate)) {
        dateInputProps = {
          ...dateInputProps,
          value: moment(newDate, "DD/MM/YYYY").toDate(),
        };
      }

      inputsNode = <DateInput {...dateInputProps} />;
      return inputsNode;
    case "range":
      newDate = dates as DateInputType;
      let startDate: DateInputType = "";
      let endDate: DateInputType = "";
      if (Array.isArray(newDate) && newDate.length >= 2) {
        startDate = newDate[0];
        endDate = newDate[1];
      }

      let dateInputsProps: {
        startDateInput: DateInputProps;
        endDateInput: DateInputProps;
      } = {
        startDateInput: {
          onChangeText: onChangeStartDateInput,
          onFocused: (isFocused: boolean) =>
            isFocused ? setFocusedInput("start") : setFocusedInput(undefined),
        },
        endDateInput: {
          onChangeText: onChangeEndDateInput,
          onFocused: (isFocused: boolean) =>
            isFocused ? setFocusedInput("end") : setFocusedInput(undefined),
        },
      };

      // * We add value prop only if startDate is valid date string
      if (isValidStringDate(startDate) || startDate === "") {
        dateInputsProps = {
          ...dateInputsProps,
          startDateInput: {
            ...dateInputsProps.startDateInput,
            value: moment(startDate, "DD/MM/YYYY").toDate() ?? "",
          },
        };
      }

      // * We add value prop only if endDate is valid date string
      if (isValidStringDate(endDate) || endDate === "") {
        dateInputsProps = {
          ...dateInputsProps,
          endDateInput: {
            ...dateInputsProps.endDateInput,
            value: moment(endDate, "DD/MM/YYYY").toDate() ?? "",
          },
        };
      }

      inputsNode = (
        <>
          <DateInput {...dateInputsProps.startDateInput} />
          <ArrowRightIcon />
          <DateInput {...dateInputsProps.endDateInput} />
        </>
      );
      return inputsNode;
  }
};

/**
 *
 * @param value
 * @param datesState
 * @param setIsDateRangePreset
 */
export const useChangeStartDateInputCalendar = (
  value: DateInputType,
  datesState: [
    CalendarInputValue,
    Dispatch<SetStateAction<CalendarInputValue>>
  ],
  setIsDateRangePreset: Dispatch<SetStateAction<boolean>>
) => {
  const [dates, setDates] = datesState;

  const nextValue: string = convertInputDateToLocaleFormat(value, "DD/MM/YYYY");
  if (isValidStringDate(nextValue)) {
    const [startDate, endDate] = dates as CalendarInputValueRange;
    let nextStartDate: CalendarInputValueDefault = moment(
      nextValue,
      "DD/MM/YYYY"
    ).toDate();

    if (nextStartDate && endDate) {
      if (nextStartDate > endDate) {
        nextStartDate = startDate;
      }
    }

    setDates([nextStartDate, endDate]);
    setIsDateRangePreset(true);
  }
};

/**
 *
 * @param value
 * @param datesState
 * @param setIsDateRangePreset
 */
export const useChangeEndDateInputCalendar = (
  value: DateInputType,
  datesState: [
    CalendarInputValue,
    Dispatch<SetStateAction<CalendarInputValue>>
  ],
  setIsDateRangePreset: Dispatch<SetStateAction<boolean>>
) => {
  const [dates, setDates] = datesState;

  const nextValue: string = convertInputDateToLocaleFormat(value, "DD/MM/YYYY");
  if (isValidStringDate(nextValue)) {
    const [startDate, endDate] = dates as CalendarInputValueRange;
    let nextEndDate: CalendarInputValueDefault = moment(
      nextValue,
      "DD/MM/YYYY"
    ).toDate();

    if (startDate && nextEndDate) {
      if (nextEndDate < startDate) {
        nextEndDate = endDate;
      }
    }

    setDates([startDate, nextEndDate]);
    setIsDateRangePreset(true);
  }
};

/**
 *
 * @param dates
 * @param setDates
 * @param setIsDateRangePreset
 */
export const useChangeDateCalendar = (
  dates: CalendarInputValue,
  setDates: Dispatch<SetStateAction<CalendarInputValue>>,
  setIsDateRangePreset: Dispatch<SetStateAction<boolean>>
) => {
  const newDates: CalendarInputValueRange = dates as CalendarInputValueRange;
  setDates(newDates);
  setIsDateRangePreset(true);
};
