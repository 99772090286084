import React from "react";
import { TemplateGroupItemProps } from "./TemplatesModal.type";
import { getLanguageFlag } from "../../../helpers/languageHelper";
import { CardToggle } from "../../Common/CardToggle/CardToggle";
import { TemplateItem } from "./TemplateItem";

export const TemplateGroupItem: React.FC<TemplateGroupItemProps> = ({
  group,
  withFlag = false,
  onInsert,
  onEdit,
  onDuplicate,
  onRemove,
}) => {
  const getTitle = () => {
    return (
      <div className="flex flex-row items-center cursor-pointer hover:underline gap-2">
        <p className="font-semibold text-high-contrast">{group.label}</p>
        {withFlag && getLanguageFlag(group.items[0].language.code)}
      </div>
    );
  };

  return (
    <CardToggle title={getTitle()}>
      <div className="flex flex-col">
        {group?.items?.map((template, index) => (
          <TemplateItem
            key={index}
            template={template}
            onInsert={onInsert}
            onEdit={onEdit}
            onDuplicate={onDuplicate}
            onRemove={onRemove}
          />
        ))}
      </div>
    </CardToggle>
  );
};
