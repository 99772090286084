import React, { useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { TextType } from "../../types/commonTypes";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";

export type GoogleAddressType = {
  formattedAddress: string;
  place_id: string;
  street: string;
  cityName: string;
  cityZip: string;
  departmentName: string;
  regionName: string;
  countryName: string;
  countryCode: string;
  latitude: number;
  longitude: number;
} | null;

export const GoogleAddressInput: React.FC<{
  label?: TextType;
  required?: boolean;
  error?: string;
  defaultValue?: string;
  disabled?: boolean;
  onAddressValid?: (address: GoogleAddressType) => void;
}> = ({
        label,
        required,
        error,
        defaultValue,
        disabled,
        onAddressValid = () => {},
      }) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const getAddressProp = (
      place: any,
      type: string,
      propResult: string = "long_name"
  ) => {
    let result = "";
    place.address_components.forEach((component: any) => {
      if (component.types[0] === type) {
        result = component[propResult];
      }
    });

    return result;
  };

  const handlePlaceSelected = (place: any) => {
    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();
    onAddressValid({
      formattedAddress: place.formatted_address,
      place_id: place.place_id,
      street: `${getAddressProp(place, "street_number")} ${getAddressProp(
          place,
          "route"
      )}`,
      cityName: getAddressProp(place, "locality"),
      cityZip: getAddressProp(place, "postal_code"),
      departmentName: getAddressProp(place, "administrative_area_level_2"),
      regionName: getAddressProp(place, "administrative_area_level_1"),
      countryName: getAddressProp(place, "country"),
      countryCode: getAddressProp(place, "country", "short_name"),
      latitude,
      longitude
    });
  };

  const getBorder = () => {
    return error
        ? "border-element-border-error"
        : `
      border-element-border
      hover:border-element-border-hover 
      active:border-element-border-active`;
  };

  const getBg = () => {
    return disabled ? "bg-element-background-disabled" : "bg-white";
  };

  const getOutline = () => {
    if (isFocus && error) {
      return "outline outline-2 outline-error/50";
    } else if (isFocus && !error) {
      return "outline outline-2 outline-focus";
    } else {
      return "";
    }
  };

  return (
      <div className="flex flex-col flex-1">
        <div className="flex items-start space-x-1">
          {label && (
              <label className="mb-1 text-sm font-semibold text-left w-max">
                {label}
              </label>
          )}
          {required ? (
              <span className="relative text-lg -top-1 text-active">*</span>
          ) : null}
        </div>

        <ReactGoogleAutocomplete
            apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
            onPlaceSelected={handlePlaceSelected}
            onChange={() => onAddressValid && onAddressValid(null)}
            options={{ types: ["address"] }}
            className={`flex-1 rounded-6px p-2  border-1 ${getBorder()} ${getOutline()} ${getBg()}`}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            defaultValue={defaultValue}
        />

        <ErrorMessage errorType="FORM">{error}</ErrorMessage>
      </div>
  );
};
