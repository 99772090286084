import { Params } from "react-router-dom";
import paths from "../../constants/paths";
import { get } from "../../helpers/APIHelper";
import { PaymentDepositReservationLinkResponse } from "../../types/GETTypes";

/**
 * Hook to fetch payment deposits
 * @param params
 * @param {Function} onStart - Function to execute when the fetch starts.
 * @param {Function} onEnd - Function to execute when the fetch ends.
 * @param {Function} onSuccess - Function to execute when the fetch is successful.
 * @param {Function} onError - Function to execute when there is an error.
 */
export const usePaymentDeposits = async (
  params: Readonly<Params<string>>,
  onSuccess: (
    paymentDeposits: PaymentDepositReservationLinkResponse | undefined
  ) => void,
  onError: (message: string | undefined) => void,
  onStart?: () => void,
  onEnd?: () => void
) => {
  try {
    if (params.depositId) {
      if (typeof onStart === "function") {
        onStart();
      }
      const response = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.DEPOSITS}/${
          params.reservationId
        }${paths.API.PAYMENTS.GET_DEPOSIT_LINK}/${params.depositId}`
      );
      if (response?.data?.success) {
        onSuccess(response?.data?.result);
      } else {
        onError(response?.data.message ?? response.message);
      }
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};
