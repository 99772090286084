/**
 * Modal Form right for filter Guests values.
 *
 * StaysTo
 * StaysFrom
 * SpentTo
 * SpentFrom
 *
 * @author Elysio
 */
import React, { useState } from "react";

import CheckWhiteIcon from "../../../../assets/icons/check-white.svg?react";

import { ModalFilterProps } from "./ModalFilterGuests.type";

import { RightModal } from "../../../Common/RightModal/RightModal";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { Button } from "../../../Common/Button/Button";
import { useGuests } from "../../../../hooks/api/guests";
import { useTranslation } from "react-i18next";

export const ModalFilterGuestsModal: React.FC<ModalFilterProps> = ({
  isVisible,
  error,
  onClose,
  tablePage,
  setIsFiltered,
}) => {
  const { t } = useTranslation();
  const { getAllGuests } = useGuests();

  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isLoadingReset, setIsLoadingReset] = useState(false);

  const [filters, setFilters] = useState({
    stays_to: null,
    stays_from: null,
    spent_to: null,
    spent_from: null,
  });

  /**
   * Function to set filters when filters are updated.
   *
   * @author Elysio
   * @param key
   * @param value
   */
  const handleFilterChange = (key: string, value: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  /**
   * Function to submit the filter form and fetch filtered guests.
   *
   * @author Elysio
   */
  const handleClickSubmit = async () => {
    setIsLoadingFilter(true);
    const allGuests = await getAllGuests({
      stays_to: filters.stays_to,
      stays_from: filters.stays_from,
      spent_to: filters.spent_to,
      spent_from: filters.spent_from,
    });

    tablePage.setData(allGuests?.guests);
    setIsLoadingFilter(false);
    setIsFiltered(true);
  };

  /**
   * Function to reset the filters and fetch all guests.
   *
   * @author Elysio
   */
  const handleClickReset = async () => {
    setIsLoadingReset(true);
    await tablePage.fetch({});
    setIsLoadingReset(false); // Reset the loading state
    setIsFiltered(false);
  };

  return (
    <RightModal
      isVisible={isVisible}
      onClose={onClose}
      title={t("Components.GuestFilters.title")}
      classNames={{ mainContentParent: "overflow-y-auto" }}
    >
      <div className="flex flex-col text-high-contrast font-normal w-full">
        <div className="w-full flex-1">
          <p className={"text-gray-500 mb-4 mt-2"}>
            {t("Components.GuestFilters.spanFilter")}
          </p>
          <div className={"flex gap-2 items-center"}>
            <TextInput
              label={t("Components.GuestFilters.stayCount")}
              value={filters.stays_to}
              onChangeText={(e) => handleFilterChange("stays_to", e)}
            />
            <TextInput
              label={t("Components.GuestFilters.and")}
              value={filters.stays_from}
              onChangeText={(e) => handleFilterChange("stays_from", e)}
            />
          </div>
          <div className={"flex gap-2 items-center mt-6"}>
            <TextInput
              label={t("Components.GuestFilters.totalAmountSpent")}
              value={filters.spent_to}
              onChangeText={(e) => handleFilterChange("spent_to", e)}
            />
            <TextInput
              label={t("Components.GuestFilters.and")}
              value={filters.spent_from}
              onChangeText={(e) => handleFilterChange("spent_from", e)}
            />
          </div>
        </div>

        <ErrorMessage>{error}</ErrorMessage>

        {/* Buttons */}
        <div className="flex gap-3 pt-4 border-t-1 border-element-border">
          <Button type="secondary" onClick={onClose}>
            {t("Global.cancel")}
          </Button>
          <Button
            type="secondary"
            onClick={handleClickReset}
            disabled={isLoadingReset}
            loading={isLoadingReset}
          >
            {t("Global.reinit")}
          </Button>
          <Button
            RightIcon={CheckWhiteIcon}
            onClick={handleClickSubmit}
            disabled={isLoadingFilter}
            loading={isLoadingFilter}
          >
            {t("Global.filter")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
