import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import paths from "../../../../constants/paths";
import { post } from "../../../../helpers/APIHelper";
import { MessageTemplatePhotoResponse } from "../../../../types/GETTypes";
import { ValueType } from "../../../../types/commonTypes";
import { Button } from "../../../Common/Button/Button";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { PictureInput } from "../../../Common/PictureInput/PictureInput";
import { RightModal } from "../../../Common/RightModal/RightModal";
import { Separator } from "../../../Common/Separator/Separator";

type RentalUpdatePhotosModalProps = {
  isVisible: boolean;
  rentalId: string | undefined;
  oldFiles: MessageTemplatePhotoResponse[];
  newFiles: Blob[];
  onClose: () => void;
};

export const RentalUpdatePhotosModal: React.FC<
  RentalUpdatePhotosModalProps
> = ({ isVisible, rentalId, oldFiles, newFiles, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [error, setError] = useState<string | undefined>();
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);

  const [photoIdsToDelete, setPhotoIdsToDelete] = useState<ValueType[]>([]);
  const [oldFilesTemp, setOldFilesTemp] = useState<
    MessageTemplatePhotoResponse[]
  >([]);
  const [newFilesTemp, setNewFilesTemp] = useState<Blob[]>([]);

  useEffect(() => {
    setOldFilesTemp(oldFiles);
    setNewFilesTemp(newFiles);
  }, [isVisible]);

  const handleCancel = () => {
    setPhotoIdsToDelete([]);
    onClose();
  };

  const handleUpdatePhotos = () => {
    if (rentalId !== undefined) {
      const updatePhotos = async () => {
        setLoadingValidation(true);

        const updatePhotosData = new FormData();
        updatePhotosData.append(
          "del_photo_ids",
          [...new Set(photoIdsToDelete)].join(",")
        );
        newFilesTemp.forEach((photo, i) => {
          updatePhotosData.append(`photos[${i}]`, photo);
        });

        const response = await post(
          `${import.meta.env.VITE_API_URL}${
            paths.API.RENTALS_UPDATE
          }/${rentalId}${paths.API.RENTAL.UPDATE_PHOTOS_RENTAL}`,
          updatePhotosData
        );

        if (response.data?.success) {
          navigate(0);
        } else {
          setError(response?.response?.data?.message);
        }
        setLoadingValidation(false);
      };
      updatePhotos();
    }
  };

  return (
    <RightModal
      title={t("Rental.Infos.Photos.updateModalTitle")}
      isVisible={isVisible}
      onClose={onClose}
    >
      <div className="flex flex-col gap-3">
        <ErrorMessage>{error}</ErrorMessage>
        <p className="text-low-contrast">
          {t("Rental.Infos.Photos.updateModalLabel")}
        </p>
        <div className="flex-1">
          <PictureInput
            disabled={loadingValidation}
            mode="all"
            oldFiles={oldFilesTemp}
            newFiles={newFilesTemp}
            onChangeOldFiles={(files) => setOldFilesTemp(files)}
            onChangeNewFiles={(files) => setNewFilesTemp(files)}
            onDeleteFile={(id) =>
              setPhotoIdsToDelete((prevValue) => [...prevValue, id])
            }
          />
        </div>

        <Separator />

        <div className="flex gap-x-3">
          <Button
            type="secondary"
            onClick={handleCancel}
            disabled={loadingValidation}
          >
            {t("Global.cancel")}
          </Button>
          <Button
            type="primary"
            RightIcon={CheckIcon}
            loading={loadingValidation}
            onClick={handleUpdatePhotos}
          >
            {t("Global.record")}
          </Button>
        </div>
      </div>
    </RightModal>
  );
};
