import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import AirbnbIcon from "../../assets/icons/airbnb.svg?react";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg?react";
import CheckIcon from "../../assets/icons/check-white.svg?react";
import {
  EditPlatform,
  EditPlatformProps,
  EditPlatformTypes,
} from "../../pages/Settings/Settings.type";

import { useTranslation } from "react-i18next";
import AirBnBIcon from "../../assets/icons/airbnb.svg?react";
import BookingIcon from "../../assets/icons/booking.svg?react";
import EditIcon from "../../assets/icons/edit.svg?react";
import MonitorIcon from "../../assets/icons/monitor.svg?react";
import Sparkless from "../../assets/icons/sparkles.svg?react";
import { useUpdateUserLead } from "../../hooks/api/users";
import { useCheckAuth } from "../../hooks/useCheckAuth";
import { RegisterTypeCheckboxes } from "../Authentication/Register/Type/RegisterTypeCheckboxes";
import { Card } from "../Common/Card/Card";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";
import { RentalPlatformsCardElement } from "../Rental/Infos/Platforms/PlatformsCardElement";
import { SettingSkeleton } from "./SettingSkeleton";

export const EditPlatformCard: React.FC<EditPlatformProps> = ({
  lead,
  types,
  platforms,
  onUpdate,
  synchroList,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string | undefined>();
  const { t } = useTranslation();

  const { user, setUser } = useCheckAuth();

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm<EditPlatform>({
    mode: "all",
  });

  const allTypeValueAreFalse = (types?: EditPlatformTypes) => {
    if (types) {
      return Object.values(types).every((type) => !type);
    } else {
      return Object.values(getValues("types")).every((type) => !type);
    }
  };

  const updatePlatform = async () => {
    if (lead) {
      useUpdateUserLead(
        lead.id,
        getValues,
        (user: any) => {
          onUpdate(user);
          setUser(user);
          setValidationLoading(false);
          setEditMode(false);
        },
        (message: string | undefined) => setApiError(message),
        () => {
          setApiError(undefined);
          setValidationLoading(true);
        },
        () => {
          setValidationLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    setValue("platforms", platforms);
    setValue("types", {
      investor: types?.includes("investor") || false,
      sub: types?.includes("sub") || false,
      janitorial: types?.includes("janitorial") || false,
      agency: types?.includes("agency") || false,
      ...types.reduce((acc: any, key: string) => {
        acc[key] = true;
        return acc;
      }, {}),
    });
  }, [user?.lead, editMode]);

  if (typeof user?.lead === "undefined") {
    return <SettingSkeleton page="edit_profil" />;
  }

  return (
    <Card
      Icon={Sparkless}
      label={t("Global.profil")}
      loading={validationLoading}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        disabled:
          editMode &&
          (validationLoading || Object.entries(errors).length > 0 || !isValid),
        onClick: editMode
          ? handleSubmit(updatePlatform)
          : () => setEditMode(!editMode),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => setEditMode(false),
            }
          : undefined
      }
    >
      {editMode ? (
        <div>
          {apiError ? <ErrorMessage>{apiError}</ErrorMessage> : null}
          <div className="mt-6 space-y-3">
            <p className="text-lg font-semibold">{t("Register.Type.text1")}</p>
            <p className="font-light text-secondary">
              {t("Register.Type.text2")}
            </p>
          </div>
          {typeof types !== "undefined" && (
            <Controller
              control={control}
              name="types"
              rules={{
                onChange: (e) => {
                  if (
                    allTypeValueAreFalse(e.target.value as EditPlatformTypes)
                  ) {
                    setError("types", {
                      message: t("Register.Type.typesError"),
                    });
                  } else {
                    clearErrors("types");
                  }
                },
              }}
              render={({ field: { onChange, value } }) => (
                <RegisterTypeCheckboxes
                  onChange={onChange}
                  value={value}
                  loading={validationLoading}
                  error={errors.types?.message?.toString()}
                />
              )}
            />
          )}

          <div className="mt-6 mb-2 space-y-3">
            <p className="text-lg font-semibold">
              {t("Register.PlatformText1")}
            </p>
            <p className="font-light text-secondary">
              {t("Register.PlatformText2")}
            </p>
          </div>

          {typeof platforms !== "undefined" && (
            <div className="flex flex-col gap-2">
              {typeof platforms !== "undefined" &&
                platforms.includes("airbnb") &&
                synchroList.isSynchroWithAirbnb && (
                  <RentalPlatformsCardElement
                    classNames={{
                      container:
                        "bg-white cursor-default hover:bg-white hover:border-element-border-hover",
                    }}
                  >
                    <AirbnbIcon
                      className="border-white rounded-full"
                      width={24}
                      height={24}
                    />
                    <p className="font-bold"> Airbnb</p>
                  </RentalPlatformsCardElement>
                )}
              {typeof platforms !== "undefined" &&
                platforms.includes("booking") &&
                synchroList.isSynchroWithBooking && (
                  <RentalPlatformsCardElement
                    classNames={{
                      container:
                        "bg-white cursor-default hover:bg-white hover:border-element-border-hover",
                    }}
                  >
                    <BookingIcon
                      className="border-white rounded-full"
                      width={24}
                      height={24}
                    />
                    <p className="font-bold"> Booking.com</p>
                  </RentalPlatformsCardElement>
                )}
              {!synchroList.isSynchroWithAirbnb &&
                !synchroList.isSynchroWithBooking && (
                  <div className="flex flex-col items-center w-full h-full gap-2 p-4">
                    <MonitorIcon />
                    <p className="text-base text-low-contrast w-max">
                      {t("Global.noPlatformsConnected")}
                    </p>
                  </div>
                )}

              {typeof platforms !== "undefined" &&
                platforms.length > 0 &&
                platforms.map(
                  (platform: string, index: number) =>
                    platform !== "airbnb" &&
                    platform !== "booking" &&
                    platform != null &&
                    platform != "" && (
                      <RentalPlatformsCardElement
                        classNames={{
                          container:
                            "bg-white cursor-default hover:bg-white hover:border-element-border-hover",
                        }}
                      >
                        <p className="font-bold"> {platform} </p>
                      </RentalPlatformsCardElement>
                    )
                )}
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="flex flex-col items-start self-stretch gap-4 p-4">
            <div className="flex flex-col space-y-3">
              <p className="label w-[524px] text-[#6f6f6f] font-['Inter'] text-xs">
                {t("Profil.yourActivity")}
              </p>
              <div className="flex flex-row space-x-2">
                {typeof types !== "undefined" &&
                  types.map((type: string, index: number) => (
                    <button
                      key={index}
                      className="px-2 py-1 text-sm font-semibold rounded-6px bg-element-background-active text-low-contrast"
                    >
                      {t(`Register.Type.${type}`)}
                    </button>
                  ))}
              </div>
            </div>
            <div className="flex flex-col w-full space-y-3">
              <p className="label w-[524px] text-[#6f6f6f] font-['Inter'] text-xs">
                {t("Profil.platformsUsed")}
              </p>

              {!synchroList.isSynchroWithAirbnb &&
                !synchroList.isSynchroWithBooking && (
                  <div className="flex flex-col items-center w-full h-full gap-2 p-4">
                    <MonitorIcon />
                    <p className="text-base text-low-contrast w-max">
                      {t("Global.noPlatformsConnected")}
                    </p>
                  </div>
                )}
              {typeof platforms !== "undefined" &&
                platforms.includes("airbnb") &&
                synchroList.isSynchroWithAirbnb && (
                  <div className="flex flex-row space-x-2">
                    <AirBnBIcon
                      className="border-white rounded-full"
                      width={24}
                      height={24}
                    />
                    <p className="font-bold"> Airbnb</p>
                  </div>
                )}

              {typeof platforms !== "undefined" &&
                platforms.includes("booking") &&
                synchroList.isSynchroWithBooking && (
                  <div className="flex flex-row space-x-2">
                    <BookingIcon
                      className="border-white rounded-full"
                      width={24}
                      height={24}
                    />
                    <p className="font-bold"> Booking.com</p>
                  </div>
                )}

              {typeof platforms !== "undefined" &&
                platforms.length > 0 &&
                platforms.map(
                  (platform: string, index: number) =>
                    platform !== "airbnb" &&
                    platform !== "booking" &&
                    platform != null &&
                    platform != "" && (
                      <div key={index} className="flex flex-row space-x-2">
                        <p className="px-2 py-1 text-sm font-semibold rounded-6px bg-element-background-active text-low-contrast">
                          {platform}
                        </p>
                      </div>
                    )
                )}
            </div>
          </div>
        </>
      )}
    </Card>
  );
};
