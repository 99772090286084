import { t } from "i18next";
import moment from "moment";
import React, { useState } from "react";
import ArrowRightWhiteIcon from "../../assets/icons/arrow-right-white.svg?react";
import CalendarFullOkIcon from "../../assets/icons/calendar-full-ok.svg?react";
import CalendarKoIcon from "../../assets/icons/calendar-ko.svg?react";
import CalendarPartOkIcon from "../../assets/icons/calendar-part-ok.svg?react";
import CalendarRefundedIcon from "../../assets/icons/calendar-refunded.svg?react";
import {
  CALENDAR_CELL_HEIGHT,
  CALENDAR_CELL_WIDTH,
  CALENDAR_NB_DAYS_BEFORE,
  CALENDAR_RENTAL_NAME_WIDTH,
} from "../../constants/calendar";
import {
  PaymentReservationDepositStatusEnum,
  PaymentReservationStatusEnum,
} from "../../enums/GETenums";
import { isValidHour } from "../../helpers/dateHelper";
import { getPlatformIcon } from "../../helpers/platformHelper";
import { ReservationItemProps } from "./Calendar.type";

export const CalendarReservationItem: React.FC<ReservationItemProps> = ({
  reservation,
  mode = "status",
  from,
  onClick,
}) => {
  const [toolTipVisible, setToolTipVisible] = useState<boolean>(false);

  const checkinDay = moment(reservation.checkin);
  const checkoutDay = moment(reservation.checkout);
  const diffInitCheckinDays = checkinDay.diff(from, "days");
  const duration = checkoutDay.diff(checkinDay, "days");

  const width = duration * CALENDAR_CELL_WIDTH - 1; // (duration + 1) * day width
  const left =
    CALENDAR_RENTAL_NAME_WIDTH +
    CALENDAR_NB_DAYS_BEFORE * CALENDAR_CELL_WIDTH +
    diffInitCheckinDays * CALENDAR_CELL_WIDTH +
    22; // left rental menu + loading offset + diffInitCheckinDays * day width

  const getOpacity = () => {
    if (moment(reservation.checkout) < moment(new Date())) return "opacity-60";
    else return "opacity-100";
  };

  const getFullName = () => {
    return `${reservation?.guest?.first_name} ${reservation?.guest?.last_name}`;
  };

  const getPaymentStatusLabel = () => {
    switch (reservation.status_payment) {
      case PaymentReservationStatusEnum.PAID:
        return t("Calendar.PaymentLabel.paid");
      case PaymentReservationStatusEnum.OVERDUE:
        return t("Calendar.PaymentLabel.overdue");
      case PaymentReservationStatusEnum.PARTIALLY_PAID:
        return t("Calendar.PaymentLabel.partiallyPaid");
      case PaymentReservationStatusEnum.REFUNDED:
        return t("Calendar.PaymentLabel.refunded");
      case PaymentReservationStatusEnum.UNPAID:
        return t("Calendar.PaymentLabel.unpaid");
      case PaymentReservationStatusEnum.CANCELED:
        return t("Calendar.PaymentLabel.canceled");
      default:
        return "N/A";
    }
  };

  const getDepositStatus = () => {
    switch (reservation.status_deposit) {
      case PaymentReservationDepositStatusEnum.UNPAID:
        return t("Booking.DepositStatus.unpaid").toLowerCase();
      case PaymentReservationDepositStatusEnum.OVERDUE:
        return t("Booking.DepositStatus.overdue").toLowerCase();
      case PaymentReservationDepositStatusEnum.CANCELED:
        return t("Booking.DepositStatus.canceled").toLowerCase();
      case PaymentReservationDepositStatusEnum.PRE_AUTHORIZED:
        return t("Booking.DepositStatus.preAuthorized").toLowerCase();
      case PaymentReservationDepositStatusEnum.RELEASED:
        return t("Booking.DepositStatus.released").toLowerCase();
      case PaymentReservationDepositStatusEnum.CAPTURED:
        return t("Booking.DepositStatus.captured").toLowerCase();
      case PaymentReservationDepositStatusEnum.PAID:
        return t("Booking.DepositStatus.paid").toLowerCase();
      case PaymentReservationDepositStatusEnum.REFUNDED:
        return t("Booking.DepositStatus.refunded").toLowerCase();
      case PaymentReservationDepositStatusEnum.NOT_REFUNDED:
        return t("Booking.DepositStatus.notRefunded").toLowerCase();
      case PaymentReservationDepositStatusEnum.MANAGED_BY_PLATFORM:
        return t("Booking.DepositStatus.managedByPlatform").toLowerCase();
      case PaymentReservationDepositStatusEnum.NOT_REQUIRED:
        return t("Booking.DepositStatus.notRequired").toLowerCase();
      default:
        return "KO";
    }
  };

  const colors = {
    green: {
      bg: "#CDFCC9",
      border: "#5FD464",
      text: "#378C19",
      Icon: <CalendarFullOkIcon className="w-3 h-3" />,
    },
    blue: {
      bg: "#C9FCE6",
      border: "#5FD4BF",
      text: "#198C5C",
      Icon: <CalendarPartOkIcon className="w-3 h-3" />,
    },
    red: {
      bg: "#FFE1E1",
      border: "#C47575",
      text: "#CD2B31",
      Icon: <CalendarKoIcon className="w-3 h-3" />,
    },
    gray: {
      bg: "#F8F8F8",
      border: "#6F6F6F",
      text: "#6F6F6F",
      Icon: <CalendarRefundedIcon className="w-3 h-3" />,
    },
  };

  const getColors = () => {
    if (mode === "status") {
      if (
        reservation.status_payment === PaymentReservationStatusEnum.PAID &&
        (reservation.status_deposit ===
          PaymentReservationDepositStatusEnum.PRE_AUTHORIZED ||
          reservation.status_deposit ===
            PaymentReservationDepositStatusEnum.RELEASED ||
          reservation.status_deposit ===
            PaymentReservationDepositStatusEnum.CAPTURED ||
          reservation.status_deposit ===
            PaymentReservationDepositStatusEnum.PAID ||
          reservation.status_deposit ===
            PaymentReservationDepositStatusEnum.REFUNDED ||
          reservation.status_deposit ===
            PaymentReservationDepositStatusEnum.NOT_REFUNDED ||
          reservation.status_deposit ===
            PaymentReservationDepositStatusEnum.NOT_REQUIRED ||
          reservation.status_deposit ===
            PaymentReservationDepositStatusEnum.MANAGED_BY_PLATFORM)
      ) {
        return colors.green;
      } else if (
        reservation.status_deposit ===
          PaymentReservationDepositStatusEnum.CANCELED ||
        reservation.status_payment === PaymentReservationStatusEnum.REFUNDED ||
        reservation.status_payment === PaymentReservationStatusEnum.CANCELED
      ) {
        return colors.gray;
      } else if (
        reservation.status_payment === PaymentReservationStatusEnum.OVERDUE ||
        reservation.status_payment === PaymentReservationStatusEnum.UNPAID
      ) {
        return colors.red;
      } else {
        return colors.blue;
      }
    } else {
      return {
        bg: reservation.background_color ?? "#2B2F31",
        border: reservation.text_color ?? "#171717",
        text: reservation.text_color ?? "#171717",
        Icon: getPlatformIcon(reservation?.platform!),
      };
    }
  };

  return (
    <>
      <div
        className={`absolute rounded-6px p-2 flex flex-row items-center gap-2 cursor-pointer ${getOpacity()} border-1`}
        style={{
          left: left,
          width: width,
          zIndex: 4,
          top: 3,
          height: CALENDAR_CELL_HEIGHT - 6,
          backgroundColor: getColors()?.bg,
          color: getColors()?.text,
          borderColor: getColors()?.border,
        }}
        onClick={() => onClick(reservation)}
        onMouseOver={() => setToolTipVisible(true)}
        onMouseOut={() => setToolTipVisible(false)}
      >
        <div>{getColors()?.Icon}</div>
        <div>
          <p className={`font-semibold text-[13px] line-clamp-1`}>
            {getFullName()}
          </p>
        </div>
      </div>

      {toolTipVisible && (
        <div
          className="absolute bg-tooltip-bg rounded-6px p-2 text-[11px] text-white"
          style={{
            left: left,
            top: CALENDAR_CELL_HEIGHT - 133,
            zIndex: 99,
          }}
        >
          <div className="flex flex-col">
            <p className="flex items-center gap-1 font-semibold">
              {getPlatformIcon(reservation?.platform, "small")}
            </p>

            <p className="font-semibold">
              {reservation?.guest?.first_name} {reservation?.guest?.last_name}
            </p>

            <p className="flex items-center gap-1">
              {moment(reservation?.checkin).format("DD/MM/YYYY")}{" "}
              {isValidHour(reservation.time_in) ? reservation.time_in : null}
              <ArrowRightWhiteIcon className="w-4 h-4" />
              {moment(reservation?.checkout).format("DD/MM/YYYY")}{" "}
              {isValidHour(reservation.time_out) ? reservation.time_out : null}
            </p>

            {/* TODO : MANAGE CURRENCY */}
            <p className="flex gap-1">
              <span className="font-semibold text-[13px]">
                {reservation?.total_guest_price} €
              </span>
              <span>
                ({getPaymentStatusLabel()}
                {" - "}
                {t("Global.deposit")} {getDepositStatus()})
              </span>
            </p>
          </div>
        </div>
      )}
    </>
  );
};
