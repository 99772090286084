import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import InfoIcon from "../../assets/icons/information.svg?react";
import { useChecklist } from "../../hooks/api/checklist";
import { useModal, UseModal } from "../../hooks/useModal";
import { ValueType } from "../../types/commonTypes";
import { RentalChecklistsResponse } from "../../types/GETTypes";
import { ChecklistTableHeader } from "../ChecklistTable/ChecklistTableHeader";
import { ChecklistTableItem } from "../ChecklistTable/ChecklistTableItem";
import { ChecklistTableSkeleton } from "../ChecklistTable/ChecklistTableSkeleton";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";
import { TableListWithCustomItems } from "../Common/TableListWithCustomItems/TableListWithCustomItems";
import { ChecklistDeleteModal } from "./ChecklistDeleteModal";
import { ChecklistUpsertModal } from "./ChecklistUpsertModal";

export const ChecklistList: React.FC<{
  items: RentalChecklistsResponse[] | undefined;
  upsertModal: UseModal<RentalChecklistsResponse>;
}> = ({ items, upsertModal }) => {
  const [currentChecklists, setCurrentChecklists] = useState<
    RentalChecklistsResponse[]
  >([]);

  const { rentalId } = useParams();

  const deleteModal = useModal<ValueType>();

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (items) {
      setCurrentChecklists(items);
    }
  }, [items]);

  const handleSuccessAdd = (checklist: RentalChecklistsResponse) => {
    setCurrentChecklists([...currentChecklists, checklist]);
  };

  const handleSuccessEdit = (checklist: RentalChecklistsResponse) => {
    setCurrentChecklists((prevChecklists) =>
      prevChecklists.map((item) =>
        item.id === checklist.id ? checklist : item
      )
    );
  };

  const handleSuccessDelete = (id: ValueType) => {
    setCurrentChecklists((prevChecklists) =>
      prevChecklists.filter((item) => item.id !== id)
    );
    upsertModal.close();
  };

  const { duplicate } = useChecklist({
    rentalId: rentalId!,
    onSuccess: (checklist) => handleSuccessAdd(checklist!),
    onError: (message: string | null) => {
      setError(message);
    },
  });

  const handleClickEdit = (id: ValueType) => {
    const clickedChecklist = currentChecklists.find((item) => item.id === id);

    if (!clickedChecklist) return;

    upsertModal.open(clickedChecklist);
  };

  const handleClickDuplicate = (id: ValueType) => {
    const checklist = currentChecklists?.find((v) => v.id === id);

    if (!checklist) return;

    duplicate(checklist);
  };

  const handleClickAction = (id: ValueType, action: ValueType) => {
    if (action === "edit") {
      handleClickEdit(id);
    } else if (action === "duplicate") {
      handleClickDuplicate(id);
    } else if (action === "delete") {
      deleteModal.open(id);
    }
  };

  const handleAskRemove = (id: ValueType) => {
    deleteModal.open(id);
  };

  return (
    <>
      <ChecklistUpsertModal
        isVisible={upsertModal.isVisible}
        checklist={upsertModal.data}
        rentalId={rentalId!}
        onAskRemove={handleAskRemove}
        onSuccessAdd={handleSuccessAdd}
        onSuccessEdit={handleSuccessEdit}
        onClose={upsertModal.close}
      />

      <ChecklistDeleteModal
        isVisible={deleteModal.isVisible}
        onClose={deleteModal.close}
        checklistId={deleteModal.data}
        onSuccessDelete={handleSuccessDelete}
      />

      <ErrorMessage>{error}</ErrorMessage>

      {/* CHECKLIST INFO */}
      <div className="flex items-start justify-between p-4 rounded bg-element-background">
        <div className="flex items-start space-x-2">
          <InfoIcon />
          <div className="flex flex-col space-y-2">
            <p className="text-lg font-bold text-high-contrast">
              {t("Rental.Checklist.List.whatsChecklistTitle")}
            </p>
            <div>
              <p className="text-low-contrast">
                <Trans
                  i18nKey="Rental.Checklist.List.whatsChecklistDescription"
                  components={{
                    strong: <strong />,
                  }}
                />
              </p>

              <p className="text-low-contrast">
                <Trans
                  i18nKey="Rental.Checklist.List.whatsChecklistDescription2"
                  components={{
                    strong: <strong />,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>

      <TableListWithCustomItems
        Header={ChecklistTableHeader}
        Item={ChecklistTableItem}
        NoData={null}
        Skeleton={ChecklistTableSkeleton}
        items={currentChecklists}
        onClick={handleClickEdit}
        onClickAction={handleClickAction}
      />
    </>
  );
};
