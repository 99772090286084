import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Button/Button";
import { InputSelect } from "../InputSelect/InputSelect";
import { PagingButtonsProps } from "./PagingButtons.type";

import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg?react";

export const PagingButtons: React.FC<PagingButtonsProps> = ({
  i18nElement,
  loading,
  nbPerPage,
  total,
  page = 1,
  lastPage,
  onChangeNbPerPage,
  onPaginationBack,
  onPaginationNext,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {total! >= 10 && (
        <div className="flex flex-row items-center mt-6">
          <div className="flex flex-row items-center flex-1 space-x-2">
            <p className="text-sm font-semibold text-high-contrast">
              {t("RentalList.displayUpTo")}
            </p>

            <div>
              <InputSelect
                items={[
                  {
                    value: 25,
                    label: `25 ${t(i18nElement, {
                      count: total!,
                    })?.toLowerCase()}`,
                  },
                  {
                    value: 50,
                    label: `50 ${t(i18nElement, {
                      count: total!,
                    })?.toLowerCase()}`,
                  },
                  {
                    value: 100,
                    label: `100 ${t(i18nElement, {
                      count: total!,
                    })?.toLowerCase()}`,
                  },
                ]}
                selectedValue={nbPerPage}
                onSelect={onChangeNbPerPage}
                disabled={loading}
              />
            </div>

            {total !== undefined && total !== null ? (
              <p className="font-light text-low-contrast">
                {t("Global.total")} : {total}{" "}
                {t(i18nElement, { count: total })?.toLowerCase()}
              </p>
            ) : (
              <div className="w-40 h-2 bg-slate-200"></div>
            )}
          </div>

          {lastPage && lastPage > 1 && (
            <div className="flex flex-row space-x-2">
              <Button
                type="secondary"
                LeftIcon={ArrowLeftIcon}
                disabled={page === 1}
                onClick={onPaginationBack}
              ></Button>

              <Button type="secondary" onClick={() => {}}>
                <span className="px-1">{page}</span>
              </Button>

              <Button
                type="secondary"
                LeftIcon={ArrowRightIcon}
                disabled={lastPage ? page >= lastPage : false}
                onClick={onPaginationNext}
              ></Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
