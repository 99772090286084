import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import paths from "../../../../constants/paths";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {InputSelect} from "../../../Common/InputSelect/InputSelect";
import {Controller, UseFormReturn} from "react-hook-form";
type Listing = {
    id: string;
    name: string;
    image: string;
    status: string;
};

export type AirbnbSynchronizeForm = {
    account_id: string;
    listing_id: string;
};

export const AirbnbSynchronizeStepImport: React.FC<{
    form:UseFormReturn<AirbnbSynchronizeForm, any, undefined>,
    apiError: string | null;
    formError: string | null;
    announces: any;
    selectListing: any;
}> = ({
          form,
          apiError,
           formError,
           announces,
           selectListing
      }) => {
    const {t} = useTranslation();

    useEffect(() => {
        selectListing((prev) => ({...prev,  airbnb_listing_id: form.getValues("listing_id")}));

    }, [form.watch("listing_id")]);

    return (
        <>
            <ErrorMessage>{apiError}</ErrorMessage>
            <div className="my-3">
                <p className="text-low-contrast">
                    {t(
                        "Rental.Infos.Platforms.SynchronizeModal.Airbnb.airbnbSynchronizeStep2Description"
                    )}
                </p>
            </div>
            <Controller
                control={form.control}
                name="listing_id"
                rules={{
                    required:true
                }}
                render={({ field: { onChange, value } }) => (
                    <InputSelect
                        label={t( "Rental.Infos.Platforms.SynchronizeModal.Airbnb.airbnbSynchronizeStep2SelectTitle")}
                        required={true}
                        items={announces}
                        onSelect={onChange}
                        selectedValue={value}
                    />
                )}
            />
            {/* Errors */}
            <ErrorMessage errorType="FORM">{formError}</ErrorMessage>
        </>
    );
};
