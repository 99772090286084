import React from "react";

import ChevronUpIcon from "../../../assets/icons/chevron-up.svg?react";

export const TaskListWithTasksSkeleton: React.FC<{}> = () => {
  return (
    <div className="flex flex-col mt-4 animate-pulse border border-l-element-border rounded-6px">
      <div className="flex items-center justify-between p-4 rounded-md rounded-b-none cursor-pointer bg-element-background">
        <div className="flex flex-col gap-y-2">
          <p className="h-3 rounded-md w-52 bg-slate-300"></p>
          <p className="h-2 rounded w-52 bg-slate-300"></p>
        </div>

        <div className="pr-2">
          <ChevronUpIcon className="size-6" />
        </div>
      </div>

      {Array.from({ length: 10 }).map((v, index) => (
        <TaskListWithTasksItemSkeleton key={index} />
      ))}
    </div>
  );
};

export const TaskListWithTasksItemSkeleton: React.FC<{}> = () => {
  return (
    <div className="flex items-center">
      <div className="w-3/12">
        <div className="flex items-center gap-2 px-2 py-2">
          <div className="size-12 rounded-6px border-1 bg-slate-300 shrink-0"></div>

          <div className="flex flex-col gap-y-2">
            <p className="h-2 rounded-lg w-52 bg-slate-300"></p>
            <p className="w-40 h-2 rounded bg-slate-300"></p>
          </div>
        </div>
      </div>

      <div className="w-3/12">
        <p className="w-40 h-2 rounded-lg bg-slate-300"></p>
      </div>

      <div className="w-2/12">
        <p className="h-2 rounded-lg w-32 bg-slate-300"></p>
      </div>

      <div className="w-2/12">
        <div className="flex items-center justify-center gap-x-2">
          <div className="rounded-full bg-slate-300 size-10"></div>
          <p className="w-40 h-2 rounded-lg bg-slate-300"></p>
        </div>
      </div>

      <div className="w-2/12">
        <div className="w-32 h-10 mx-auto rounded bg-slate-300"></div>
      </div>
    </div>
  );
};
