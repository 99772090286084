import { t } from "i18next";
import React from "react";
import SettingsIcon from "../../../assets/icons/settings.svg?react";
import { useManagementAutomationNavbarItems } from "../../../pages/ManagementAutomationList/ManagementAutomationList.hooks";
import { ValueType } from "../../../types/commonTypes";
import { Button } from "../../Common/Button/Button";
import { GroupButton } from "../../Common/GroupButton/GroupButton";
import {
  ManagementAutomationListFilterButtonsProps,
  ManagementAutomationListMenuSections,
} from "./ManagementAutomationListFilterButtons.type";

export const ManagementAutomationListFilterButtons: React.FC<
  ManagementAutomationListFilterButtonsProps
> = ({ activeItem, onActiveItem, modal, automations }) => {
  const handleClick = (value: ValueType) => {
    if (!automations) return;

    const casted = value as ManagementAutomationListMenuSections;

    onActiveItem(casted);
  };

  return (
    <div className="flex items-center justify-between mb-6 select-none">
      <GroupButton
        items={useManagementAutomationNavbarItems(activeItem)}
        onClick={handleClick}
      />

      <div>
        <Button
          type="secondary"
          RightIcon={SettingsIcon}
          onClick={() => modal.open()}
        >
          {t("Global.settings")}
        </Button>
      </div>
    </div>
  );
};
