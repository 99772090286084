import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment, useEffect } from "react";
import { Trans } from "react-i18next";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import CheckIcon from "../../../assets/icons/check.svg?react";
import ChevronUpDownIcon from "../../../assets/icons/chevron-up-and-down.svg?react";
import InformationIcon from "../../../assets/icons/information-fill.svg?react";
import { cn } from "../../../helpers/classHelper";
import { ValueType } from "../../../types/commonTypes";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { Tooltip } from "../Tooltip/Tooltip";
import { InputSelectOptionProps, InputSelectProps } from "./InputSelect.type";

export const InputSelect: React.FC<InputSelectProps> = ({
  classNames,
  required = false,
  register,
  label,
  infoTooltip,
  infoTextI18nKey,
  infoTextI18nValues,
  defaultValue,
  selectedValue,
  items,
  maxItemWidth = false,
  size = "normal",
  disabled = false,
  error,
  onSelect = (value: ValueType) => {},
}) => {
  useEffect(() => {
    items.map((item) => ({
      ...item,
      isVisible: item.isVisible !== undefined ? item.isVisible : true,
    }));
  }, []);

  const getSelectedItem = () => {
    return items.find(
      (i) => i.value === selectedValue || i.value === defaultValue
    );
  };

  const getSelectedIcon = () => {
    const Icon = getSelectedItem()?.Icon;

    if (!Icon) return null;

    return <Icon className="flex-shrink-0 w-5 h-5 rounded" />;
  };

  const handleChangeValue = (newValue: ValueType) => {
    if (register !== undefined && typeof register.onChange === "function") {
      register?.onChange({ target: { name: register?.name, value: newValue } });
    } else {
      onSelect(newValue);
    }
  };

  return (
    <Listbox>
      {({ open }) => (
        <div className="flex flex-col">
          <div className="flex items-start space-x-1">
            {label ? (
              <Listbox.Label
                className={cn(
                  "block text-sm font-semibold leading-6 text-left",
                  classNames?.label
                )}
                htmlFor={`input-${register?.name}`}
              >
                {label}
              </Listbox.Label>
            ) : null}

            {label && required ? (
              <span
                className={cn(
                  "relative text-lg -top-1 text-active",
                  classNames?.labelRequired
                )}
              >
                *
              </span>
            ) : null}
            {infoTooltip ? (
              <Tooltip Icon={InformationIcon} value={infoTooltip} />
            ) : null}
          </div>
          <div
            className={`relative cursor-pointer ${
              size === "small" ? "h-8" : "h-9"
            }`}
          >
            <Listbox.Button
              className={cn(
                `relative w-full h-full rounded-md py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm ${
                  disabled ? "bg-element-background-disabled" : "bg-white"
                } ${disabled ? "cursor-not-allowed" : "cursor-pointer"} ${
                  error && "border-1 border-error"
                }`,
                classNames?.button
              )}
              aria-disabled={disabled}
            >
              <span className="flex items-center gap-3">
                {getSelectedIcon()}
                <span
                  className={cn(
                    `block truncate text-high-contrast text-base`,
                    {
                      "text-low-contrast":
                        defaultValue === getSelectedItem()?.value,
                    },
                    classNames?.buttonText
                  )}
                >
                  {getSelectedItem()?.label}
                </span>
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                <ChevronUpDownIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-in duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-out duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`absolute z-40 ${
                  !maxItemWidth ? "w-full" : "w-max"
                } mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
              >
                {items
                  .filter((item) => item.isVisible !== false)
                  .map((item: InputSelectOptionProps) => (
                    <Listbox.Option
                      key={item.value}
                      title={item.label}
                      className={({ active }) =>
                        cn(
                          `relative cursor-pointer select-none py-2 pl-3 pr-9 ${classNames?.option}`,
                          {
                            "bg-active text-white": active,
                            "bg-white text-high-contrast": !active,
                            "bg-element-background-disabled text-gray-400 cursor-not-allowed":
                              item.disabled,
                          }
                        )
                      }
                      onClick={() => handleChangeValue(item.value)}
                      disabled={item.disabled ?? false}
                      value={item.label}
                    >
                      {({ selected, active }) => (
                        <>
                          <div
                            className="flex items-center gap-3"
                            title={item.label!}
                          >
                            {item.Icon ? (
                              <item.Icon className="flex-shrink-0 w-5 h-5 rounded" />
                            ) : null}
                            <span
                              className={`block truncate ${
                                classNames?.labelSelected
                              } ${
                                selectedValue !== "custom" && selected
                                  ? "font-semibold"
                                  : "font-normal"
                              }`}
                            >
                              {item.label}
                            </span>
                          </div>

                          {selectedValue !== "custom" &&
                          (selected || selectedValue === item.value) ? (
                            <span
                              className={cn(
                                active ? "text-white" : "text-high-contrast",
                                "absolute inset-y-0 right-0 flex items-center pr-4 ",
                                classNames?.labelSelectedActive
                              )}
                            >
                              {active ? (
                                <CheckWhiteIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
          {infoTextI18nKey && infoTextI18nValues ? (
            <p className="mt-2 text-low-contrast">
              <Trans i18nKey={infoTextI18nKey} values={infoTextI18nValues} />
            </p>
          ) : null}
          <ErrorMessage errorType="FORM">{error}</ErrorMessage>
        </div>
      )}
    </Listbox>
  );
};
