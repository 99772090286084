import React, {useEffect, useState} from "react";
import {Separator} from "../../Common/Separator/Separator";
import {Sidebar} from "../../Common/Sidebar/Sidebar";
import {useCheckAuth} from "../../../hooks/useCheckAuth";
import useProgressionStore from "../../../stores/useProgressStore";
import {BreadcrumbLayoutType} from "./BreadcrumbLayout.type";

export const BreadcrumbLayout: React.FC<BreadcrumbLayoutType> = ({
    breadcrumb,
    sidebarActiveItem,
    sidebarActiveSubItem,
    children,
    onSidebarOpen,
    userData
                                                      }) => {
    const [isSidebarClosed, setIsSidebarClosed] = useState<boolean>(false);

    const { user } = useCheckAuth();


    const {refreshProgression} = useProgressionStore();

    const handleSidebarToggle = (value: boolean) => {
        setIsSidebarClosed(value);
        localStorage.setItem("sidebar_closed", value.toString());
        onSidebarOpen && onSidebarOpen(value);
    };

    useEffect(() => {
        const isClosed = localStorage.getItem("sidebar_closed");
        if (isClosed) {
            setIsSidebarClosed(isClosed === "true");
        }
    }, []);

    useEffect(() => {
    }, [refreshProgression]);

    return (
        <div className="flex justify-center w-full h-screen overflowy-y-scroll min-h-svh">
            <div className="flex flex-row flex-1 h-full">
                <Sidebar
                    activeItem={sidebarActiveItem}
                    activeSubItem={sidebarActiveSubItem}
                    user={userData ? userData : user}
                    isClosed={isSidebarClosed}
                    onToggle={handleSidebarToggle}
                />
                {/* Body */}
                <div className="flex flex-1 min-w-[777px] h-full py-4 px-8 flex-col text-sm overflow-y-scroll">
                    <div className="breadcrumb">{breadcrumb}</div>
                    <Separator/>
                    {children}
                </div>
            </div>
        </div>
    );
};
