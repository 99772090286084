import React, { useEffect, useState } from "react";
import { RightModal } from "../../Common/RightModal/RightModal";
import {
  OperationalManagementRoleListItemResponse,
  OperationalManagementTeamMemberResponse,
} from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { Button } from "../../Common/Button/Button";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import { Controller, useForm } from "react-hook-form";
import { ValueType } from "../../../types/commonTypes";
import { ManagementTeamRoleBadge } from "../ManagementTeamList/ManagementTeamRoleBadge";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { Separator } from "../../Common/Separator/Separator";
import { ManagementTeamTeamCheckbox } from "./ManagementTeamTeamCheckbox";
import { MultiSelect } from "../../Common/MultiSelect/MultiSelect";
import { post } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import { SimpleRadio } from "../../Common/SimpleRadio/SimpleRadio";

type EditMemberForm = {
  rolesId: ValueType[];
  teamsId: ValueType[];
  rentalsId: ValueType[];
};

export const EditMemberModal: React.FC<{
  isVisible: boolean;
  data: { member: OperationalManagementTeamMemberResponse } | undefined | null;
  roles: OperationalManagementRoleListItemResponse[] | undefined;
  teams: { id: ValueType; name: string }[];
  onClose: () => void;
  onSuccess: () => void;
}> = ({ isVisible, data, roles, teams, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const form = useForm<EditMemberForm>({
    defaultValues: { rolesId: [], teamsId: [], rentalsId: [] },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isVisible && data?.member) {
      form.setValue(
        "rolesId",
        data?.member?.postes?.map((role) => role.id)
      );

      form.setValue(
        "teamsId",
        data?.member?.teams?.map((team) => team.id)
      );

      form.setValue(
        "rentalsId",
        data.member.rentals_access?.map((rental) => rental.id)
      );
    }
  }, [isVisible, data?.member]);

  const isMemberAccepted = () => {
    return data?.member?.first_name !== "" && data?.member?.last_name !== "";
  };

  const getFullName = () => {
    if (isMemberAccepted())
      return `${data?.member?.first_name} ${data?.member?.last_name}`;
    else return data?.member?.email;
  };

  const handleClose = () => {
    if (loading) return;

    onClose();
  };

  const save = async (values: EditMemberForm) => {
    setLoading(true);
    setError(null);

    const res = await post(
      `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.UPDATE_MEMBER
      }/${data?.member.id}`,
      {
        rentals: values.rentalsId,
        teams: values.teamsId,
        postes: values.rolesId,
      }
    );

    if (res.data?.success) {
      onSuccess();
      onClose();
    } else {
      setError(res.response.data.message);
    }

    setLoading(false);
  };

  return (
    <RightModal
      isVisible={isVisible}
      onClose={handleClose}
      title={t("Management.EditMember.title", { fullName: getFullName() })}
    >
      <form className="w-full flex-1 h-full" onSubmit={form.handleSubmit(save)}>
        <div className="flex flex-col w-full h-full flex-1">
          <div className="flex flex-col gap-1 flex-1 w-full overflow-y-scroll">
            {/* Roles */}
            <p className="font-semibold text-low-contrast mb-3">
              {t("Management.EditMember.roles")}
            </p>

            <Controller
              control={form.control}
              name="rolesId"
              rules={{
                required: {
                  value: true,
                  message: t("Management.EditMember.rolesIdRequired"),
                },
              }}
              render={({ field: { value, onChange } }) => {
                const handleChange = (id: ValueType) => {
                  onChange([id]);
                };

                return (
                  <>
                    <div className="grid grid-cols-2 gap-2 pb-2">
                      {roles?.map((role) => (
                        <div
                          key={role.id}
                          className="flex gap-2 items-center cursor-pointer"
                          onClick={() => handleChange(role.id)}
                        >
                          <div className="flex-1">
                            <ManagementTeamRoleBadge role={role} />
                          </div>

                          <SimpleRadio
                            value={
                              value.findIndex((id) => role.id === id) !== -1
                            }
                          />
                        </div>
                      ))}
                    </div>

                    <ErrorMessage errorType="FORM">
                      {form.formState.errors?.rolesId?.message}
                    </ErrorMessage>
                  </>
                );
              }}
            />

            <Separator />

            {/* Teams */}
            <p className="font-semibold text-low-contrast mb-3">
              {t("Management.EditMember.assignedTeams")}
            </p>

            <Controller
              control={form.control}
              name="teamsId"
              rules={{
                required: {
                  value: true,
                  message: t("Management.EditMember.teamsIdRequired"),
                },
              }}
              render={({ field: { value, onChange } }) => {
                const handleChange = (id: ValueType) => {
                  const index = value.findIndex((v) => v === id);
                  if (index === -1) onChange([...value, id]);
                  else onChange(value.filter((v) => v !== id));
                };

                return (
                  <>
                    <div className="flex flex-col gap-2 pb-2 w-full">
                      {teams?.map((team) => (
                        <ManagementTeamTeamCheckbox
                          key={team.id}
                          teamName={team.name}
                          value={value.findIndex((id) => team.id === id) !== -1}
                          onClick={() => handleChange(team.id)}
                        />
                      ))}
                    </div>

                    <ErrorMessage errorType="FORM">
                      {form.formState.errors?.teamsId?.message}
                    </ErrorMessage>
                  </>
                );
              }}
            />

            <Separator />

            {/* Rentals */}
            <p className="font-semibold text-low-contrast">
              {t("Management.EditMember.assignedRentals")}
            </p>

            <Controller
              control={form.control}
              name="rentalsId"
              rules={{
                required: {
                  value: true,
                  message: t("Management.EditMember.rentalsIdRequired"),
                },
              }}
              render={({ field: { value, onChange } }) => {
                const handleChange = (id: ValueType) => {
                  const index = value.findIndex((v) => v === id);
                  if (index === -1) onChange([...value, id]);
                  else onChange(value.filter((v) => v !== id));
                };

                return (
                  <>
                    <MultiSelect
                      options={{ withInfoText: false, withSubmitButton: false }}
                      loading={false}
                      onSelectRental={handleChange}
                      selectedRentals={value}
                    />

                    <ErrorMessage errorType="FORM">
                      {form.formState.errors.rentalsId?.message}
                    </ErrorMessage>
                  </>
                );
              }}
            />
          </div>

          {/* Buttons */}
          <div className="flex flex-col gap-3 pt-2 mt-2 border-t-1 border-element-border">
            <ErrorMessage>{error}</ErrorMessage>

            <div className="flex flex-row gap-3">
              <Button type="secondary" onClick={onClose} disabled={loading}>
                {t("Global.cancel")}
              </Button>
              <Button RightIcon={CheckWhiteIcon} loading={loading}>
                {t("Global.save")}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </RightModal>
  );
};
