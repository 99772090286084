import React from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../assets/icons/close.svg?react";
import { useRentalPageContext } from "../../pages/RentalPage/RentalPageContext";
import { Button } from "../Common/Button/Button";
import { CenterModal } from "../Common/CenterModal/CenterModal";

export const DisableRentalModal: React.FC = () => {
  const { t } = useTranslation();
  const {
    informations: {
      disableRentalModalVisible,
      onDisableRental,
      onCloseDisableRental,
    },
  } = useRentalPageContext();

  return (
    <CenterModal
      isVisible={disableRentalModalVisible}
      onClose={onCloseDisableRental}
    >
      <div className="flex flex-col gap-5">
        <div className="flex items-start justify-between">
          <p className="text-xl font-extrabold">
            {t("Rental.Infos.Infos.removeActiveRentalModalTitle")}
          </p>
          <div className="cursor-pointer">
            <CloseIcon width={20} height={20} onClick={onCloseDisableRental} />
          </div>
        </div>
        <p className="text-base text-low-contrast">
          {t("Rental.Infos.Infos.removeActiveRentalModalDescription1")}
        </p>
        <p className="text-base text-low-contrast">
          {t("Rental.Infos.Infos.removeActiveRentalModalDescription3")}
        </p>
        <div className="flex gap-3">
          <div className="flex-1">
            <Button type="secondary" onClick={onCloseDisableRental}>
              {t("Global.cancel")}
            </Button>
          </div>
          <div className="flex-1">
            <Button type="destructive" onClick={onDisableRental}>
              {t("Global.deactivateWithConfirmation")}
            </Button>
          </div>
        </div>
      </div>
    </CenterModal>
  );
};
