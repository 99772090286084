import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ChildrenType, TextType } from "../../../types/commonTypes";

export type PageProps = {
  title: TextType | ChildrenType;
  children: ChildrenType;
};
export const Page: React.FC<PageProps> = ({ title, children }) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t(title as string);
  }, [title]);

  return <>{children}</>;
};

export default Page;
