import React from "react";
import { TableListHeaderProps } from "../TableList/TableList.type";
import { useTranslation } from "react-i18next";
import { SimpleBox } from "../../Modal/Airbnb/Connect/AirbnbConnectStepImport";

export const MultiSelectRentalsTableHeader: React.FC<TableListHeaderProps> = ({
  disabled,
  areAllSelected,
  onSelectAll,
}) => {
  const { t } = useTranslation();

  return (
    <tr
      className="bg-element-background border-b-1 border-element-border"
      onClick={onSelectAll}
    >
      <th className="w-8 p-2">
        <SimpleBox disabled={disabled} value={areAllSelected ?? false} />
      </th>

      <th className="p-2 text-left">
        <p className="font-semibold text-high-contrast">
          {t("Global.rentals")}
        </p>
      </th>
    </tr>
  );
};
