import React from "react";
import { NotificationStatusesListItemResponse } from "../../../types/GETTypes";
import { Button } from "../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { capitalizeFirstLetter } from "../../../helpers/stringHelper";

import DotsVerticalIcon from "../../../assets/icons/dots-vertical.svg?react";
import EyeSolidIcon from "../../../assets/icons/eye-solid.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import CloseCircleRedIcon from "../../../assets/icons/close-circle-red.svg?react";

export const ScheduledMessageSkeleton: React.FC<{}> = ({}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row gap-2 justify-between animate-pulse">
      <div className="flex flex-rown gap-2">
        <div className="flex flex-col items-center">
          <div
            className={`w-5 h-11 rounded-full mt-2 border-2 border-element-border-active`}
          ></div>

          <div className="border-1 border-element-border h-full w-0 rounded-2px my-1"></div>
        </div>

        <div className="mt-3">
          <p className="font-semibold text-high-contrast h-2 w-52 bg-slate-300 rounded"></p>

          <p className="font-light text-low-contrast mt-2 w-44 h-2 bg-slate-200 rounded"></p>
          <p className="font-light text-low-contrast mt-1 w-44 h-2 bg-slate-200 rounded"></p>
        </div>
      </div>

      <div className="flex flex-row gap-1 mt-2">
        <div className="w-14 h-8 rounded-6px bg-slate-200" />

        <div className="w-8 h-8 rounded-6px bg-slate-200" />
      </div>
    </div>
  );
};
