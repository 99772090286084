import React, { useState } from "react";
import { CenterModal } from "../../Common/CenterModal/CenterModal";
import { Button } from "../../Common/Button/Button";
import { useTranslation } from "react-i18next";
import { del } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { ValueType } from "../../../types/commonTypes";

export const RemoveAccountModal: React.FC<{
  isVisible: boolean;
  idToRemove: ValueType | null;
  onClose: () => void;
  onSuccess: () => void;
}> = ({ isVisible, idToRemove, onClose, onSuccess }) => {
  const { t } = useTranslation();

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleRemoveAccount = async () => {
    setError(null);
    setLoading(true);

    const res = await del(
      `${import.meta.env.VITE_API_URL}${
        paths.API.PAYMENTS.ACCOUNTS
      }/${idToRemove}`
    );

    if (res?.data?.success) {
      onSuccess();
      onClose();
    } else {
      setError(res?.response?.data?.message);
    }

    setLoading(false);
  };

  const handleClose = () => {
    setLoading(false);
    setError(null);

    onClose();
  };

  return (
    <CenterModal
      isVisible={isVisible}
      onClose={handleClose}
      title={t("Payments.removeAccountTitle")}
    >
      <div className="text-sm text-low-contrast font-light">
        {t("Payments.removeAccountContent")}
      </div>

      <ErrorMessage>{error}</ErrorMessage>
      <div className="flex gap-3 mt-4">
        <Button type="secondary" onClick={handleClose} disabled={loading}>
          {t("Global.cancel")}
        </Button>
        <Button
          type="destructive"
          loading={loading}
          onClick={handleRemoveAccount}
        >
          {t("Global.remove")}
        </Button>
      </div>
    </CenterModal>
  );
};
