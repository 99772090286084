/**
 * Component for handle export action, call API and download
 * in the web client.
 *
 * @author Elysio
 */

import React from "react";

import FileText from "../../../assets/icons/file-text.svg?react";
import Pdf from "../../../assets/icons/pdf.svg?react";
import Xls from "../../../assets/icons/xls.svg?react";

import { ExportButtonProps } from "./GuestsFilters.type";

import { useTranslation } from "react-i18next";
import { post } from "../../../helpers/APIHelper";

import paths from "../../../constants/paths";

export const ExportButton: React.FC<ExportButtonProps> = ({ isVisible }) => {
  const { t } = useTranslation();

  /**
   * Function to handle export action.
   *
   * @todo setup api url export
   * @param exportType
   * @author Elysio
   */
  const handleExportClick = (exportType: string) => {
    post(
      `${import.meta.env.VITE_API_URL}${paths.API.CRM_GUEST_MODULE.EXPORT}`,
      {
        export_type: exportType,
      },
      {
        responseType: "blob",
        AccessControlAllowOrigin: "*",
      },
    )
      .then((res) => {
        const href = URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "guests." + exportType);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  return (
    <div
      className={
        "bg-white absolute border rounded-lg w-[170px] text-lg top-12 right-0 shadow-sm z-50 " +
        (isVisible
          ? "opacity-100 translate-y-0"
          : "opacity-0 -translate-y-4 pointer-events-none") +
        " transition-opacity transition-transform duration-300 ease-out"
      }
    >
      <button
        onClick={() => handleExportClick("csv")}
        className={"flex items-center gap-2 p-2 hover:bg-gray-100 w-full"}
      >
        <FileText className={"w-4"} />
        {t("Components.GuestFilters.exportCsv")}
      </button>
      <div className={"w-full border-t"}></div>
      <button
        onClick={() => handleExportClick("pdf")}
        className={"flex items-center gap-2 p-2 hover:bg-gray-100 w-full"}
      >
        <Pdf className={"w-5"} />
        {t("Components.GuestFilters.exportPdf")}
      </button>
      <div className={"w-full border-t"}></div>
      <button
        onClick={() => handleExportClick("xls")}
        className={"flex items-center gap-2 p-2 hover:bg-gray-100 w-full"}
      >
        <Xls className={"w-5"} />
        {t("Components.GuestFilters.exportXls")}
      </button>
    </div>
  );
};
