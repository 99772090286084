import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { CustomStorage } from "../helpers/storageHelper";
import { UserResponse } from "../types/GETTypes";
import { ValueType } from "../types/commonTypes";

interface UserState {
  user: UserResponse | undefined;
  canEdit: boolean;
  selectedWorkspaces: ValueType[] | null;
  storeUser: (user: UserResponse | undefined) => void;
  setSelectedWorkspaces: (workspaces: ValueType[] | null) => void;
}

const useUserStore = create(
  persist<UserState>(
    (set, get) => ({
      user: undefined,
      canEdit: false,
      selectedWorkspaces: null,
      storeUser: (newUser: UserResponse | undefined) => {
        set({
          user: newUser,
          canEdit: newUserCanEdit(newUser),
        });
      },
      setSelectedWorkspaces: (workspaces: ValueType[] | null) =>
        set({ selectedWorkspaces: workspaces }),
    }),
    {
      name: "user-storage",
      storage: createJSONStorage(() => CustomStorage),
    }
  )
);

const newUserCanEdit = (newUser: UserResponse | undefined) => {
  if (newUser === undefined) return false;

  if (newUser?.all_postes === undefined || newUser?.all_postes?.length === 0)
    return true;

  let result = false;
  newUser.all_postes.forEach((p) => {
    if (!p.all_rights?.includes("no_edit")) result = true;
  });

  return result;
};
export default useUserStore;
