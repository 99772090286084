export const CALENDAR_REMOVE_TO_END = 420;
export const CALENDAR_CHECKIN_BANNER = 70;
export const CALENDAR_RENTAL_NAME_WIDTH = 250;
export const CALENDAR_HEADER_HEIGHT = 96;
export const CALENDAR_CELL_WIDTH = 77;
export const CALENDAR_CELL_HEIGHT = 42;
export const CALENDAR_RESTRICTION_HEIGHT = 28;

export const CALENDAR_NB_DAYS_BEFORE = 5;
export const CALENDAR_NB_DAYS_AFTER = 5;
