import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import CheckIcon from "../../assets/icons/check-white.svg?react";
import { useAutomation } from "../../hooks/api/automation";
import {
  useOperationalManagementMember,
  useOperationalManagementTeam,
} from "../../hooks/api/operationalManagement";
import { useCheckAuth } from "../../hooks/useCheckAuth";
import { UseModal } from "../../hooks/useModal";
import { RequiredFields, ValueType } from "../../types/commonTypes";
import {
  AutomationItemResponse,
  OperationalManagementTeamMemberForUserItemResponse,
  OperationalManagementTeamMemberResponse,
} from "../../types/GETTypes";
import { Button } from "../Common/Button/Button";
import InfoCard from "../Common/Card/InfoCard";
import { CenterModal } from "../Common/CenterModal/CenterModal";
import { InputMultiSelect } from "../Common/InputMultiSelect/InputMultiSelect";
import { InputMultiSelectOptionProps } from "../Common/InputMultiSelect/InputMultiSelect.type";
import { SimpleRadio } from "../Common/SimpleRadio/SimpleRadio";
import { ManagementAutomationListAssignationStepForm } from "./AddOrUpdateModalSteps/AssignationStep/AssignationStep.type";
import { TaskAssignmentFlow } from "./TaskAssignmentFlow";

type ManagementAutomationListAssignModalProps = {
  modal: UseModal<ValueType[]>;
  onAssign: (data: AutomationItemResponse[]) => void;
  onError: (message: string | null) => void;
};

type ManagementAutomationListAssignModalForm = {
  assignation_type: "OPERATORS" | "TEAMS";
  assigned_persons: ValueType[];
  assigned_teams: ValueType[];
};

export const ManagementAutomationListAssignModal: React.FC<
  ManagementAutomationListAssignModalProps
> = ({ modal, onAssign, onError }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [validationLoading, setValidationLoading] = useState<boolean>(false);

  const form = useForm<ManagementAutomationListAssignModalForm>({
    mode: "all",
    defaultValues: {
      assignation_type: "OPERATORS",
      assigned_persons: [],
      assigned_teams: [],
    },
  });

  const requiredFields: RequiredFields<ManagementAutomationListAssignationStepForm> =
    {
      assignation_type: true,
      assigned_persons: true,
      assigned_teams: true,
    };

  const userId = useCheckAuth().user?.id;

  const [persons, setPersons] = useState<
    OperationalManagementTeamMemberResponse[] | undefined
  >();
  const [teams, setTeams] = useState<
    OperationalManagementTeamMemberForUserItemResponse[] | undefined
  >();

  const getPersonsOrTeams = () => {
    if (
      form.getValues("assignation_type") === "OPERATORS" &&
      (!persons || persons?.length === 0)
    ) {
      const operationalManagementMemberApi = useOperationalManagementMember({
        onSuccess: (data) => {
          setPersons(data);
        },
        onError: (message: string | null) => onError(message),
        onStart: () => {
          onError(null);
          setLoading(true);
        },
        onEnd: () => setLoading(false),
      });

      operationalManagementMemberApi.getAll();
    } else if (
      form.getValues("assignation_type") === "TEAMS" &&
      (!teams || teams?.length === 0)
    ) {
      const operationalManagementTeamApi = useOperationalManagementTeam({
        onSuccess: (data) => {
          setTeams(data);
        },
        onError: (message: string | null) => onError(message),
        onStart: () => {
          onError(null);
          setLoading(true);
        },
        onEnd: () => setLoading(false),
      });

      operationalManagementTeamApi.getAllForUser({
        memberId: userId,
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPersonsOrTeams();
  }, [form.getValues("assignation_type")]);

  useEffect(() => {
    if (persons && persons.length > 0) {
      form.setValue("assigned_persons", [persons[0].id]);
    }
  }, [persons]);

  useEffect(() => {
    if (teams && teams.length > 0) {
      form.setValue("assigned_teams", [teams[0].id]);
    }
  }, [teams]);

  const getPersonsOrTeamsItems = () => {
    if (
      form.getValues("assignation_type") === "OPERATORS" &&
      persons &&
      persons.length > 0
    ) {
      return persons.map((person) => {
        return {
          label: `${person.first_name} ${person.last_name}`,
          value: person.id,
        };
      });
    } else if (
      form.getValues("assignation_type") === "TEAMS" &&
      teams &&
      teams.length > 0
    ) {
      return teams.map((team) => {
        return {
          label: team.name,
          value: team.id,
        };
      });
    }
    return [];
  };

  const getSelectedTeamsText = (
    selectedTeamIds: ValueType[],
    options: InputMultiSelectOptionProps[]
  ): string => {
    const selectedTeams = options.filter((option) =>
      selectedTeamIds.includes(option.value)
    );

    const selectedLabels = selectedTeams.map((team) => team.label);

    if (selectedLabels.length > 1) {
      const displayedLabels = selectedLabels.slice(0, 1).join(", ");
      const remainingCount = selectedLabels.length - 1;
      return `${displayedLabels} + ${t("Global.others", {
        count: remainingCount,
      })}`;
    } else {
      return selectedLabels.join(", ");
    }
  };

  const handleValidation = () => {
    const { assignMultiple } = useAutomation({
      onSuccess: (data) => {
        if (Array.isArray(data)) {
          onAssign(data);
        }
      },
      onError: (message: string | null) => onError(message),
      onStart: () => {
        onError(null);
        setValidationLoading(true);
      },
      onEnd: () => setValidationLoading(false),
    });

    assignMultiple({
      ids: modal.data!,
      assignee_ids:
        form.getValues("assignation_type") === "OPERATORS"
          ? form.getValues("assigned_persons")
          : form.getValues("assigned_teams"),
      assignation_type: form.getValues("assignation_type"),
    });
  };

  form.watch();

  return (
    <CenterModal
      title={t("Automation.AssignModal.title", {
        count: Array.isArray(modal.data) ? modal.data?.length : 1,
      })}
      classNames={{
        divModal: "w-min",
      }}
      isVisible={modal.isVisible}
      onClose={validationLoading ? () => {} : modal.close}
    >
      <div className="flex flex-col gap-y-4">
        <Controller
          control={form.control}
          name="assignation_type"
          rules={{
            required: requiredFields.assignation_type,
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <div>
                <p className="mb-4 text-xs font-medium text-low-contrast">
                  {t(
                    "Automation.AddOrUpdateModal.Steps.Assignation.assignationTypeLabel"
                  )}
                </p>
                <div className="flex text-sm font-semibold gap-x-3 text-low-contrast">
                  <SimpleRadio
                    classNames={{
                      label: "text-low-contrast font-light text-xs",
                    }}
                    label={t(
                      "Automation.AddOrUpdateModal.Steps.Assignation.assignationTypePersonsLabel"
                    )}
                    disabled={loading || validationLoading}
                    value={value === "OPERATORS"}
                    onClick={() => {
                      onChange("OPERATORS");
                      form.watch();
                    }}
                  />
                  <SimpleRadio
                    classNames={{
                      label: "text-low- font-light text-xs",
                    }}
                    label={t(
                      "Automation.AddOrUpdateModal.Steps.Assignation.assignationTypeTeamLabel"
                    )}
                    disabled={loading || validationLoading}
                    value={value === "TEAMS"}
                    onClick={() => {
                      onChange("TEAMS");
                      form.watch();
                    }}
                  />
                </div>
              </div>
            );
          }}
        />

        {form.getValues("assignation_type") === "OPERATORS" && (
          <InputMultiSelect
            register={form.register("assigned_persons", {
              validate: (value) => {
                if (
                  value.length === 0 &&
                  form.getValues("assignation_type") === "OPERATORS"
                ) {
                  return t("Global.Errors.requiredField", {
                    fieldName: t(
                      "Automation.AddOrUpdateModal.Steps.Assignation.assignationPersonsOrTeamsLabel"
                    ),
                  });
                }

                form.clearErrors("assigned_persons");
                return true;
              },
            })}
            label={t(
              "Automation.AddOrUpdateModal.Steps.Assignation.assignationPersonsOrTeamsLabel"
            )}
            required={requiredFields.assigned_persons}
            disabled={loading || validationLoading}
            items={getPersonsOrTeamsItems()}
            selectedValues={form.getValues("assigned_persons")}
            error={form.formState.errors.assigned_persons?.message}
          />
        )}

        {form.getValues("assignation_type") === "TEAMS" && (
          <InputMultiSelect
            register={form.register("assigned_teams", {
              validate: (value) => {
                if (
                  value.length === 0 &&
                  form.getValues("assignation_type") === "TEAMS"
                ) {
                  return t("Global.Errors.requiredField", {
                    fieldName: t(
                      "Automation.AddOrUpdateModal.Steps.Assignation.assignationPersonsOrTeamsLabel"
                    ),
                  });
                }

                form.clearErrors("assigned_teams");
                return true;
              },
            })}
            label={t(
              "Automation.AddOrUpdateModal.Steps.Assignation.assignationPersonsOrTeamsLabel"
            )}
            required={requiredFields.assigned_teams}
            disabled={loading || validationLoading}
            items={getPersonsOrTeamsItems()}
            selectedValues={form.getValues("assigned_teams")}
            error={form.formState.errors.assigned_teams?.message}
          />
        )}

        {form.getValues("assignation_type") === "TEAMS" &&
          form.getValues("assigned_teams") &&
          form.getValues("assigned_teams").length > 0 && (
            <InfoCard
              title={
                <Trans
                  i18nKey={
                    "Automation.AddOrUpdateModal.Steps.Assignation.assignationTeamsInfoTitle"
                  }
                  components={{
                    strong: <span className="font-bold"></span>,
                  }}
                  values={{
                    teamName: getSelectedTeamsText(
                      form.getValues("assigned_teams"),
                      getPersonsOrTeamsItems()
                    ),
                  }}
                />
              }
              description={
                <Trans
                  i18nKey={
                    "Automation.AddOrUpdateModal.Steps.Assignation.assignationTeamsInfoDescription"
                  }
                  components={{
                    strong: <span className="font-bold"></span>,
                  }}
                />
              }
            />
          )}

        <TaskAssignmentFlow />

        <div className="flex justify-between space-x-6">
          <Button
            type="secondary"
            onClick={() => modal.close}
            disabled={loading || validationLoading}
          >
            {t("Global.cancel")}
          </Button>
          <Button
            type="primary"
            onClick={handleValidation}
            disabled={loading || validationLoading || !form.formState.isValid}
            loading={validationLoading}
            RightIcon={CheckIcon}
          >
            {t("Global.validate")}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};
