import React from "react";
import {
  OperationalManagementRoleListItemResponse,
  OperationalManagementTeamMemberResponse,
} from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import EyeIcon from "../../../assets/icons/eye.svg?react";
import TrashIcon from "../../../assets/icons/trash.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import { Badge } from "../../Common/Badge/Badge";
import { ManagementTeamRoleBadge } from "./ManagementTeamRoleBadge";

export const ManagementTeamMemberItemSkeleton: React.FC<{}> = ({}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center gap-2">
      {/* Avatar */}

      <div className="size-12 rounded-full bg-element-background border-element-border border-1 shrink-0" />

      {/* Name, phone and email */}
      <div className="flex flex-col w-80">
        <p className="font-semibold text-high-contrast h-2 w-72 bg-slate-200 rounded mb-1"></p>

        <p className="font-light text-low-contrast w-52 bg-slate-200 rounded"></p>
      </div>

      {/* Nb of rentals */}
      <div className="flex-1">
        <p className="font-light text-low-contrast w-52 bg-slate-200 rounded"></p>
      </div>

      {/* Roles */}
      <div className="w-fit flex flex-row items-center gap-1">
        <div className="h-10 bg-element-background rounded-md flex flex-row gap-1 items-center px-2 w-40"></div>
      </div>

      {/* Actions */}
      <div className="flex items-center gap-2">
        <div className="size-5 bg-slate-200 rounded-full" />
        <div className="size-5 bg-slate-200 rounded-full" />
      </div>
    </div>
  );
};
