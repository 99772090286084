import moment from "moment";

//interval of 30mn between each hours
const intervalTime = 30

export const generateHoursRentalTime = (
  minTime: string = "",
  maxTime: string = ""
) => {
  const timeFormat = "HH:mm";

  // Set default times if minTime or maxTime is empty
  const startTime = moment(minTime || "00:00", timeFormat);
  let endTime = moment(maxTime || "23:30", timeFormat);

  // If endTime is earlier than startTime, assume it's the next day
  if (endTime.isBefore(startTime)) {
    endTime.add(1, "day");
  }

  const times = [];

  while (startTime <= endTime) {
    const timeLabel = startTime.format(timeFormat);
    times.push({
      label: timeLabel,
      value: timeLabel,
    });
    startTime.add(intervalTime, "minutes");
  }

  return times;
};

export const findClosestTime = (
  inputTime: string,
  timeOptions: {label: string; value: string}[]
) => {
  const inputMoment = moment(inputTime, "HH:mm");

  // Find the closest time by comparing the difference in minutes
  const closestTime = timeOptions.reduce((prev, curr) => {
    const prevDiff = Math.abs(inputMoment.diff(moment(prev.value, "HH:mm")));
    const currDiff = Math.abs(inputMoment.diff(moment(curr.value, "HH:mm")));

    return currDiff < prevDiff ? curr : prev;
  });

  return closestTime?.value ?? inputTime;
};
