import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../../Common/Card/Card";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { PaymentExtraProps } from "../Contact/ContactCard.type";
import { CgvCardSkeleton } from "./CgvCardSkeleton";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import { Controller, useForm } from "react-hook-form";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import { TextAreaInput } from "../../../Common/TextAreaInput/TextAreaInput";
import { CgvForm } from "../Instructions/InstructionCard.type";
import ContractIcon from "../../../../assets/icons/contract.svg?react";
import { post } from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import { Rental } from "../../../../pages/RentalPage/RentalPage.type";
import {
  replaceAsteriskWithStrongBalise,
  replaceWithBr,
} from "../../../../helpers/stringHelper";
import useUserStore from "../../../../stores/useUserStore";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";

export const CgvCard: React.FC<PaymentExtraProps> = ({
  loading,
  guestPage,
  rentalId,
  workspaceOwner,
}) => {
  const userStore = useUserStore();
  const { t } = useTranslation();
  const [validationLoading, setValidationLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [editMode, setEditMode] = useState<boolean>(false);

  const [cgvText, setCgvText] = useState(guestPage?.cgv ?? "");

  const form = useForm<CgvForm>({
    defaultValues: {
      cgv: cgvText,
    },
  });

  const initSubmit = () => {
    setError("");
    setValidationLoading(true);
  };

  const handleUpdateCgv = async (data: CgvForm) => {
    initSubmit();
    try {
      const res = await post(
        `${import.meta.env.VITE_API_URL}${
          paths.API.RENTALS_UPDATE
        }/${rentalId}${paths.API.GUEST_PAGE_UPDATE_CGV}`,
        data,
      );
      if (res?.data.success) {
        const data: Rental = res?.data?.result;
        if (data) {
          setCgvText(data?.guest_page?.cgv);
          setValidationLoading(false);
          setEditMode(false);
        }
      } else {
        setError(res?.data?.message ?? res.message);
        setValidationLoading(false);
      }
    } catch (error: any) {
      setError(error?.message);
    }
  };

  if (loading) return <CgvCardSkeleton />;
  return (
    <Card
      loading={validationLoading}
      Icon={ContractIcon}
      label={t("Rental.Infos.TravelerPage.CgvCard.title")}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        disabled: !getCurrentCanEdit({ user: userStore.user, workspaceOwner }),
        onClick: editMode
          ? form.handleSubmit((data: CgvForm) => {
              handleUpdateCgv(data);
            })
          : () => setEditMode(!editMode),
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => {
                setEditMode(false);
                form.setValue("cgv", cgvText);
                form.clearErrors();
              },
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-y-4">
        {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        {editMode ? (
          <div className="flex flex-col gap-3">
            <Controller
              control={form.control}
              name="cgv"
              rules={{
                required: {
                  value: true,
                  message: t("Rental.Infos.TravelerPage.CgvCard.requiredCgv"),
                },
              }}
              render={({
                field: { value, onChange },
                formState: { errors },
              }) => {
                const error = errors?.cgv?.message;
                return (
                  <div className="flex flex-col">
                    {error ? <ErrorMessage>{error}</ErrorMessage> : null}
                    <TextAreaInput
                      label={""}
                      placeholder={t(
                        "Rental.Infos.TravelerPage.CgvCard.cgvPlaceholder",
                      )}
                      required={true}
                      value={value}
                      onTextChange={(text) => {
                        onChange(text);
                      }}
                      rows={20}
                    />
                  </div>
                );
              }}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <p
              className="text-low-contrast whitespace-pre-line"
              dangerouslySetInnerHTML={{
                __html: replaceAsteriskWithStrongBalise(cgvText),
              }}
            />
          </div>
        )}
      </div>
    </Card>
  );
};
