/**
 * Component for merging two guests, this form action is executed in here.
 *
 * @author Elysio
 */

import Close from "../../../assets/icons/close.svg?react";

import { CenterModal } from "../../../components/Common/CenterModal/CenterModal";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Common/Button/Button";
import { useState } from "react";
import { ErrorMessage } from "../../../components/Common/ErrorMessage/ErrorMessage";

import { mergeValueType, useGuests } from "../../../hooks/api/guests";
import { UseModal } from "../../../hooks/useModal";

export interface ModalMergeProps {
  isVisible: boolean;
  modal: UseModal<any>;
  handleMergeAction?: () => void;
}

export const ModalMerge = ({
  isVisible,
  modal,
  handleMergeAction,
}: ModalMergeProps) => {
  const { t } = useTranslation();

  const guestOne = modal.data?.guestOne;
  const guestTwo = modal.data?.guestTwo;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { mergeGuests } = useGuests({}, (error) => {
    setError(error);
  });

  /**
   * Function to execute merging with two guests save with states :
   * - guestOne
   * - guestTwo
   * in modal.data
   *
   * @author Elysio
   * @param mergeType
   */
  const handleMerge = async (mergeType: mergeValueType) => {
    setIsLoading(true);
    await mergeGuests(guestOne.id, guestTwo.id, mergeType);
    setIsLoading(false);
    handleMergeAction();
  };

  return (
    <CenterModal size={"large"} isVisible={isVisible} onClose={modal.close}>
      <header className={"flex items-center justify-between"}>
        <h1 className={"text-lg font-bold"}>{t("Booking.Guest.mergeTitle")}</h1>
        <Close className={"cursor-pointer"} onClick={modal.close} />
      </header>
      {error && <ErrorMessage>{error}</ErrorMessage>}

      <section className={"mt-6 flex flex-col gap-6"}>
        <div className={"flex flex-col"}>
          <h2 className={"text-gray-500"}>
            {t("Booking.Guest.traveler")} 1 ({t("Booking.Guest.addTo")}{" "}
            {guestOne?.created_at ?? "10/10/2010"})
          </h2>
          <p className={"font-bold mt-2"}>
            {guestOne?.first_name + " " + guestOne?.last_name}
          </p>
          <p className={"text-warning"}>{guestOne?.email}</p>
          <p className={"font-bold"}>{guestOne?.phone}</p>
          <p className={"font-bold"}>
            {guestOne?.address +
              ", " +
              guestOne?.postal_code +
              " " +
              guestOne?.city +
              ", " +
              guestOne?.country.name}
          </p>
        </div>
        <div className={"flex flex-col"}>
          <h2 className={"text-gray-500"}>
            {t("Booking.Guest.traveler")} 2 ({t("Booking.Guest.addTo")}{" "}
            {guestTwo?.created_at ?? "10/10/2010"})
          </h2>
          <p className={"font-bold mt-2"}>
            {guestTwo?.first_name + " " + guestTwo?.last_name}
          </p>
          <p className={"text-warning"}>{guestTwo?.email}</p>
          <p className={"font-bold"}>{guestTwo?.phone}</p>
          <p className={"font-bold"}>
            {guestTwo?.address +
              ", " +
              guestTwo?.postal_code +
              " " +
              guestTwo?.city +
              ", " +
              guestTwo?.country.name}
          </p>
        </div>
      </section>
      <section className={"mt-8 flex flex-col gap-2"}>
        {isLoading ? (
          <Button loading={true} />
        ) : (
          <>
            <Button
              type={"secondary"}
              onClick={() => handleMerge("keep_guest_1")}
            >
              {t("Booking.Guest.mergeOneToTwo")}
            </Button>
            <Button
              type={"secondary"}
              onClick={() => handleMerge("keep_guest_2")}
            >
              {t("Booking.Guest.mergeTwoToOne")}
            </Button>
            <Button
              type={"primary"}
              onClick={() => handleMerge("keep_most_recent")}
            >
              {t("Booking.Guest.mergeMostRecent")}
            </Button>
          </>
        )}
      </section>
    </CenterModal>
  );
};
