import React, {useEffect, useState} from "react";
import LayoutBlockComponent from "../Common/LayoutBlockComponent";
import {GuestPageTab} from "../GuestPageHome/GuestPageHomeComponent";
import {useTranslation} from "react-i18next";
import ExtraCardComponent from "../Common/ExtraCardComponent";
import {Loader} from "../../Common/Loader/Loader";
import {Button} from "../../Common/Button/Button";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import CartSleeping from "../../../assets/images/cart-sleeping.svg?react";
import {GuestPageExtraOrder as GuestPageExtraHistoricalType} from "../../../types/GETTypes";
import moment from "moment";
import {Badge} from "../../Common/Badge/Badge";
import {
  getPaymentStatusBadge,
  getReservationStatusBadge,
} from "../../../helpers/reservationHelper";
import {usePaymentReservationStatusBadge} from "../../../pages/PaymentReservationList/PaymentReservationList.hooks";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import useGuestPageTextCoverStore from "../../../stores/guestPageStore/useGuestPageTextCoverStore";

const GuestPageExtraHistorical = ({onChangeTab}: GuestPageTab) => {
  const {t} = useTranslation();
  const {storeTitle, storeSubTitle} = useGuestPageTextCoverStore();
  const {guestPageData} = useGuestPageDataStore();
  const {extras_order_historical, guest, rental} = guestPageData || {};
  const {currency} = rental || {};
  const nbOrder = extras_order_historical?.length;
  const isEmpty =
    extras_order_historical?.length === 0 || !extras_order_historical;

  useEffect(() => {
    storeTitle(t("GuestPage.ExtraHistorical.title"));
    storeSubTitle &&
      storeSubTitle(
        nbOrder && nbOrder > 1
          ? t("GuestPage.ExtraHistorical.nbOrders", {nb: nbOrder})
          : nbOrder === 1
          ? t("GuestPage.ExtraHistorical.nbOrder", {nb: nbOrder})
          : t("GuestPage.ExtraHistorical.nbOrderEmpty")
      );
  }, []);
  return (
    <div className="flex flex-col md:w-full">
      <Button
        LeftIcon={ArrowLeftIcon}
        type="secondary"
        displayLargeBtn={false}
        className="m-4 mt-8 md:mt-14"
        buttonClassName="md:h-12"
        textClassName="md:text-xl"
        onClick={() => onChangeTab && onChangeTab("extra")}
      >
        {t("GuestPage.ExtraHistorical.goBack")}
      </Button>
      <LayoutBlockComponent
        title={t("GuestPage.ExtraHistorical.title")}
        subTitle={
          nbOrder && nbOrder > 1
            ? t("GuestPage.ExtraHistorical.nbOrders", {nb: nbOrder})
            : nbOrder === 1
            ? t("GuestPage.ExtraHistorical.nbOrder", {nb: nbOrder})
            : t("GuestPage.ExtraHistorical.nbOrderEmpty")
        }
        blockStyle={{
          titleStyle: "text-[32px]",
          childrenDivStyle: "pl-2 md:w-full md:h-full border-b-0",
          parentDivStyle: "md:w-full",
        }}
      >
        {isEmpty ? (
          <div className="flex justify-center">
            <CartSleeping className="w-96 h-96" />
          </div>
        ) : (
          extras_order_historical?.map(
            (historical: GuestPageExtraHistoricalType) => {
              const {
                total_price_value,
                order_date,
                extras,
                payment_status,
                id,
              } = historical;
              const date = moment(order_date).format("DD/MM/YYYY");
              return (
                <div className="border-b-1 border-element-border pb-4 mb-4 md:w-full">
                  <div className="flex items-center justify-between">
                    <div className="flex-col ">
                      <div className="text-high-contrast text-base font-bold md:text-lg">
                        {t("GuestPage.ExtraHistorical.commandeDate", {date})}
                      </div>
                      <div className="text-low-contrast text-base md:text-lg">
                        {t("GuestPage.ExtraHistorical.idCommand", {
                          numCommand: id,
                        })}
                      </div>
                      <div className="flex flex-1 text-low-contrast font-bold items-center text-base md:text-lg">
                        <span>
                          {t("GuestPage.ExtrasPayment.total")} :{" "}
                          {total_price_value} {currency?.symbol ?? "€"}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="mt-4 md:text-lg">
                      {payment_status &&
                        getPaymentStatusBadge(payment_status,t, {
                          labelStyle: "md:text-lg",
                          iconSize: 26,
                          size: "large",
                        })}
                    </div>
                  </div>
                  <div className="mt-4">
                    {extras.map((extra) => {
                      return (
                        <ExtraCardComponent
                          extra={extra}
                          guest={guest}
                          fromPage={"extraHistorical"}
                        />
                      );
                    })}
                  </div>
                  <div className="text-low-contrast text-base md:text-lg">
                    {/* {t("GuestPage.ExtraHistorical.historical")} */}
                  </div>
                  <div className="mt-2 text-high-contrast text-base font-bold md:text-lg">
                    {/* ??????? */}
                  </div>
                </div>
              );
            }
          )
        )}
      </LayoutBlockComponent>
    </div>
  );
};

export default GuestPageExtraHistorical;
