import React from "react";
import { cn } from "../../../helpers/classHelper";

type AvatarGroupProps = {
  maxDisplay: number;
  avatars: {
    name: string;
    photoSrc: string;
  }[];
};

export const AvatarGroup: React.FC<AvatarGroupProps> = ({
  maxDisplay,
  avatars,
}) => {
  const getNames = () => {
    if (avatars.length === 1 || avatars.length <= maxDisplay) {
      return avatars[0].name;
    } else if (avatars.length > maxDisplay) {
      return `${avatars[0].name} +${avatars.length - maxDisplay}`;
    } else {
      return "";
    }
  };

  return (
    <div className="flex items-center">
      {avatars.length > 0 ? (
        <>
          {avatars.slice(0, maxDisplay).map((avatar, index) => (
            <img
              key={index}
              src={avatar.photoSrc}
              alt={avatar.name}
              className={cn(
                `w-10 h-10 rounded-full border-2 border-white -ml-3`,
                {
                  "ml-0": index === 0,
                }
              )}
            />
          ))}
          <p className="pl-2 text-sm font-semibold leading-normal tracking-wide text-high-contrast">
            {getNames()}
          </p>
        </>
      ) : null}
    </div>
  );
};
