/**
 * Modal Form handle right for filter Guests values.
 *
 * StaysTo
 * StaysFrom
 * SpentTo
 * SpentFrom
 *
 * @author Elysio
 */
import React, { useEffect, useState } from "react";

import FilterIcon from "../../../../assets/icons/filter_.svg?react";
import FilterFullIcon from "../../../../assets/icons/filter-active.svg?react";

import { FiltersProps } from "./ModalFilterGuests.type";

import { ModalFilterGuestsModal } from "./ModalFilterGuestsModal";
import { Button } from "../../../Common/Button/Button";

export const ModalFilterGuest: React.FC<FiltersProps> = ({
  size = "normal",
  tablePage,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  useEffect(() => {
    setError(null);
  }, [isModalVisible]);

  /**
   * Function to close modal filters guests.
   *
   * @author Elysio
   */
  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <ModalFilterGuestsModal
        isVisible={isModalVisible}
        error={error}
        onClose={handleClose}
        tablePage={tablePage}
        setIsFiltered={setIsFiltered}
      />

      <Button
        type="secondary"
        size={size}
        onClick={() => setIsModalVisible(true)}
        borderButton={
          isFiltered ? "border-1 border-active" : "border-1 border-black/12"
        }
      >
        {isFiltered ? (
          <FilterFullIcon className="w-4 h-4" />
        ) : (
          <FilterIcon className="w-4 h-4" />
        )}
      </Button>
    </>
  );
};
