import React from "react";
import { MainLayout } from "../MainLayout/MainLayout";
import { useTranslation } from "react-i18next";
import { ChildrenType, ValueType } from "../../../types/commonTypes";
import ChevronRightIcon from "../../../assets/icons/chevron-right.svg?react";
import { GroupButton } from "../../Common/GroupButton/GroupButton";
import { useNavigate } from "react-router-dom";
import paths from "../../../constants/paths";
import useUserStore from "../../../stores/useUserStore";
import { InProgressPlaceholder } from "../../Common/Dashboard/InProgressPlaceholder";
import { isMainAccount } from "../../../helpers/workspaceHelper";

type SubTitleType =
  | "teams"
  | "roles"
  | "owners"
  | "tasks"
  | "automations"
  | "payments"
  | "activity";

export const ManagementLayout: React.FC<{
  children: ChildrenType;
  subTitle?: SubTitleType;
  sidebarActiveSubItem:
    | "tasks"
    | "teams"
    | "automations"
    | "payments"
    | "activity";
}> = ({ children, subTitle, sidebarActiveSubItem }) => {
  const userStore = useUserStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickNavbar = (values: ValueType) => {
    switch (values) {
      case "teams":
        navigate(paths.MANAGEMENT_TEAMS);
        return;
      case "roles":
        navigate(paths.MANAGEMENT_ROLES);
        return;
      case "owners":
        navigate(paths.MANAGEMENT_OWNERS);
        return;
      default:
        navigate(paths.MANAGEMENT_TEAMS);
        return;
    }
  };

  return (
    <MainLayout
      title={<Title subTitle={subTitle} />}
      sidebarActiveItem="management"
      sidebarActiveSubItem={sidebarActiveSubItem}
    >
      {sidebarActiveSubItem === "teams" && (
        <>
          {isMainAccount(userStore.user) && (
            <>
              <div className="h-2" />

              <GroupButton
                items={[
                  {
                    label: t("Management.teams"),
                    value: "teams",
                    isActive: subTitle === "teams",
                  },
                  {
                    label: t("Management.roles"),
                    value: "roles",
                    isActive: subTitle === "roles",
                  },
                ]}
                onClick={handleClickNavbar}
              />
            </>
          )}
        </>
      )}

      {children}
    </MainLayout>
  );
};

const Title: React.FC<{
  subTitle?: SubTitleType;
}> = ({ subTitle }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center gap-1">
      <p className="text-lg font-semibold text-low-contrast">
        {t("Management.title")}
      </p>
      {subTitle && (
        <>
          <ChevronRightIcon className="size-4" />
          <p className="text-lg font-semibold text-high-contrast">
            {subTitle === "teams" && t("Management.teams")}
            {subTitle === "roles" && t("Management.roles")}
            {subTitle === "owners" && t("Management.owners")}
            {subTitle === "tasks" && t("Management.tasks")}
            {subTitle === "automations" && t("Management.automations")}
            {subTitle === "payments" && t("Management.payments")}
            {subTitle === "activity" && t("Management.activity")}
          </p>
        </>
      )}
    </div>
  );
};
