import paths from "../../constants/paths";
import { get } from "../../helpers/APIHelper";
import { OperationalManagementPositionItemResponse } from "../../types/GETTypes";

export const useOperationalManagementPosition = ({
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  onSuccess: (
    positions?: OperationalManagementPositionItemResponse[] | undefined
  ) => void;
  onError: (message: string | undefined) => void;
  onStart?: () => void;
  onEnd?: () => void;
}) => {
  const getAll = async () => {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      const URL = `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.ROLES
      }`;

      const res = await get(URL);

      if (res?.data?.success) {
        onSuccess(res.data.result.postes);
      } else {
        onError(res?.data?.message ?? res.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };

  return { getAll };
};
