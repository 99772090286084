import React, { useState } from "react";
import { OperationalManagementRolePermissionNameResponse } from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { Switch } from "../../Common/Switch/Switch";
import ChevronDownIcon from "../../../assets/icons/chevron-down.svg?react";
import ChevronUpIcon from "../../../assets/icons/chevron-up.svg?react";
import EntryIcon from "../../../assets/icons/entry.svg?react";

type AccessType =
  | "dashboard"
  | "calendars"
  | "reservations"
  | "rentals"
  | "payments"
  | "messages"
  | "auto_messages"
  | "management"
  | "analytics";

export const PermissionItem: React.FC<{
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  accessName: AccessType;
  subItems?: OperationalManagementRolePermissionNameResponse[];
  selectedAccesses: OperationalManagementRolePermissionNameResponse[];
  onChange: (
    accessName: OperationalManagementRolePermissionNameResponse
  ) => void;
}> = ({ Icon, accessName, subItems, selectedAccesses, onChange }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleHeaderClick = () => {
    if (subItems !== undefined) {
      setIsOpen(!isOpen);
    } else {
      onChange(accessName as OperationalManagementRolePermissionNameResponse);
    }
  };

  const handleSubItemClick = (
    subItemAccessname: OperationalManagementRolePermissionNameResponse
  ) => {
    onChange(subItemAccessname);
  };

  const getChevronOrSwitch = () => {
    if (subItems !== undefined && isOpen)
      return <ChevronUpIcon className="size-6" />;
    if (subItems !== undefined && !isOpen)
      return <ChevronDownIcon className="size-6" />;

    return (
      <Switch
        value={selectedAccesses.findIndex((a) => a === accessName) !== -1}
      />
    );
  };

  return (
    <div className="border-1 border-element-border bg-element-background rounded-6px p-2 flex flex-col gap-3">
      {/* Righ Header */}
      <div
        className="flex flex-row gap-3 items-center cursor-pointer"
        onClick={handleHeaderClick}
      >
        <Icon className="size-5" />

        <div className="flex flex-col flex-1">
          <p className="font-semibold text-high-contrast">
            {t(`Management.Right.${accessName}Title`)}
          </p>
          <p className="text-low-contrast font-light">
            {t(`Management.Right.${accessName}SubTitle`)}
          </p>
        </div>

        <div>{getChevronOrSwitch()}</div>
      </div>

      {/* Sub items */}
      {isOpen &&
        subItems !== undefined &&
        subItems?.map((item) => {
          return (
            <div
              key={item}
              className="flex gap-3 items-center cursor-pointer"
              onClick={() => handleSubItemClick(item)}
            >
              <div className="pl-8">
                <EntryIcon className="size-2.5" />
              </div>

              <div className="flex flex-col flex-1">
                <p className="font-semibold text-high-contrast">
                  {t(`Management.Right.${item}Title`)}
                </p>
                <p className="text-low-contrast font-light">
                  {t(`Management.Right.${item}SubTitle`)}
                </p>
              </div>

              <Switch
                value={selectedAccesses.findIndex((a) => a === item) !== -1}
              />
            </div>
          );
        })}
    </div>
  );
};
