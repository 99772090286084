import React, {useEffect, useState} from "react";
import {RightModal} from "../../../Common/RightModal/RightModal";
import {ExtraOrderModalProps} from "../ReservationExtrasCard.type";
import {useTranslation} from "react-i18next";
import {getReservationExtraOrderStatusBadge} from "../../../../helpers/reservationHelper";
import ExtraOrderReservationCard from "./ExtraOrderReservationCard";
import ExtraOrderInfoCard from "./ExtraOrderInfoCard";
import ExtraOrderListCard from "./ExtraOrderListCard";
import {useCheckAuth} from "../../../../hooks/useCheckAuth";
import {CenterModal} from "../../../Common/CenterModal/CenterModal";
import {Button} from "../../../Common/Button/Button";
import {post} from "../../../../helpers/APIHelper";
import paths from "../../../../constants/paths";
import {
  GuestPageExtraDetail,
  GuestPageExtraOrder,
} from "../../../../types/GETTypes";
import {ReservationExtraOrderStatusEnum} from "../../../../enums/GETenums";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";

export type popUp = "" | "refund" | "extraUnavailable" | "oneExtraUnavailable";

const ExtraOrderModal = ({
  isVisible,
  closeModal,
  extraOrderModal,
  reservation,
  page = "details",
  onSuccess,
}: ExtraOrderModalProps) => {
  const {t} = useTranslation();
  const {user} = useCheckAuth();
  const [extraOrder, setExtraOrder] = useState(extraOrderModal);
  const {id: orderId, payment_status, extras} = extraOrder || {};
  const [openPopUp, setOpenPopUp] = useState(false);
  const [typePopUp, setTypePopUp] = useState<popUp>("");
  const [extraPopUpRefund, setExtraPopUpRefund] =
    useState<GuestPageExtraDetail>();
  const [loadingValidation, setLoadingValidation] = useState(false);
  const [error, setError] = useState("");

  const openPop = (popUpToOpen: popUp, extra?: GuestPageExtraDetail) => {
    if (extra) {
      setExtraPopUpRefund(extra);
    }
    setTypePopUp(popUpToOpen);
    setOpenPopUp(true);
  };

  const closePopUp = () => {
    if (!loadingValidation) {
      setExtraPopUpRefund(undefined);
      setTypePopUp("");
      setOpenPopUp(false);
    }
  };

  const getPopUpTitle = () => {
    switch (typePopUp) {
      case "refund":
        return t("Booking.ExtrasOrder.ExtraOrderModal.PopUp.refundTitle");
      case "extraUnavailable":
        return t(
          "Booking.ExtrasOrder.ExtraOrderModal.PopUp.extraUnavailableTitle"
        );
      case "oneExtraUnavailable":
        return t(
          "Booking.ExtrasOrder.ExtraOrderModal.PopUp.oneExtraUnavailableTitle"
        );
      default:
        return "";
    }
  };

  const getPopUpDescription = () => {
    switch (typePopUp) {
      case "refund":
        return t(
          "Booking.ExtrasOrder.ExtraOrderModal.PopUp.refundDescription",
          {value: extraPopUpRefund?.total_price_value}
        );
      case "extraUnavailable":
        return t(
          "Booking.ExtrasOrder.ExtraOrderModal.PopUp.extraUnavailableDescription"
        );
      case "oneExtraUnavailable":
        return t(
          "Booking.ExtrasOrder.ExtraOrderModal.PopUp.oneExtraUnavailableDescription"
        );
      default:
        return "";
    }
  };

  const getPopUpButtonTitle = () => {
    switch (typePopUp) {
      case "refund":
        return t("Booking.ExtrasOrder.ExtraOrderModal.PopUp.confirmRefund");
      case "extraUnavailable":
        return t(
          "Booking.ExtrasOrder.ExtraOrderModal.PopUp.confirmExtraUnavailable"
        );
      case "oneExtraUnavailable":
        return t(
          "Booking.ExtrasOrder.ExtraOrderModal.PopUp.confirmOneExtraUnavailable"
        );
      default:
        return "";
    }
  };

  const getExtrasIdValue = () => {
    if (extras) {
      return JSON.stringify(
        extras.map((extra) => {
          return {
            [extra.id]: extra?.extra_available ? extra.extra_available : false,
          };
        })
      );
    }
    return JSON.stringify([]);
  };

  const onConfirmPopUp = async () => {
    if (orderId && extras) {
      let response;
      setLoadingValidation(true);
      try {
        switch (typePopUp) {
          case "refund":
            if (extraPopUpRefund) {
              response = await post(
                `${
                  import.meta.env.VITE_API_URL
                }${paths.API.RESERVATION.REFUND_EXTRA(
                  orderId,
                  extraPopUpRefund.id
                )}`
              );
            }
            break;
          case "extraUnavailable":
          case "oneExtraUnavailable":
            response = await post(
              `${
                import.meta.env.VITE_API_URL
              }${paths.API.RESERVATION.VALIDATE_ORDER(orderId)}`,
              {extras_ids: getExtrasIdValue()}
            );
            break;
        }

        setLoadingValidation(false);
        handleResponse(response);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onValidate = async () => {
    if (extras && orderId) {
      const allExtrasAvailable = extras.every(
        (extra) => extra.extra_available === true
      );
      const allExtrasUnavailable = extras.every(
        (extra) => extra.extra_available === false
      );
      const oneExtraUnavailable = extras.some(
        (extra) => extra.extra_available === false
      );
      if (allExtrasAvailable) {
        setLoadingValidation(true);
        const response = await post(
          `${
            import.meta.env.VITE_API_URL
          }${paths.API.RESERVATION.VALIDATE_ORDER(orderId)}`,
          {extras_ids: getExtrasIdValue()}
        );
        setLoadingValidation(false);
        handleResponse(response);
      } else if (allExtrasUnavailable) {
        openPop("extraUnavailable");
      } else if (oneExtraUnavailable) {
        openPop("oneExtraUnavailable");
      }
    }
  };

  const handleResponse = (response: any) => {
    const {success, result} = response?.data || {};
    const {message} = response?.response?.data || {};
    if (success) {
      onSuccess(result);
      setTimeout(() => {
        closeAllModal();
      }, 200);
    } else {
      setError(message);
    }
  };

  const closeAllModal = () => {
    if (!loadingValidation) {
      setError("");
      closePopUp();
      closeModal();
    }
  };

  useEffect(() => {
    addExtrasAvailableToExtrasList(extraOrderModal);
  }, [extraOrderModal]);

  const addExtrasAvailableToExtrasList = (
    order: GuestPageExtraOrder | undefined
  ) => {
    const array = order?.extras
      ? order.extras?.map((extra) => ({...extra, extra_available: true}))
      : [];
    order && setExtraOrder({...order, extras: array});
  };

  return (
    <>
      <RightModal
        isVisible={isVisible}
        onClose={closeAllModal}
        title={t("Booking.ExtrasOrder.order", {uuid: orderId})}
        rightHeaderNode={getReservationExtraOrderStatusBadge(payment_status)}
        separatorAccent="dark"
        classNames={{divModal: "h-[98%]"}}
      >
        <CenterModal
          isVisible={openPopUp}
          onClose={closePopUp}
          title={getPopUpTitle()}
          classNames={{divModal: "w-1/2 max-w-2xl"}}
        >
          <>
            <div className="flex flex-row">
              <p className="flex-1 text-low-contrast text-base">
                {getPopUpDescription()}
              </p>
            </div>
            <div className="flex flex-1 items-center mt-8 mb-2 space-x-6">
              <Button
                type="secondary"
                onClick={closePopUp}
                disabled={loadingValidation}
              >
                {t("Global.cancel")}
              </Button>
              <Button
                type="destructive"
                onClick={onConfirmPopUp}
                buttonClassName="w-full"
                loading={loadingValidation}
              >
                {getPopUpButtonTitle()}
              </Button>
            </div>
          </>
        </CenterModal>
        <div className="w-full mb-6">
          <div className="flex flex-col pb-3 border-b-1 border-element-border w-full text-xs space-y-4">
            <span className="text-low-contrast font-bold">
              {t("Booking.ExtrasOrder.ExtraOrderModal.context")}
            </span>
            <ExtraOrderReservationCard reservation={reservation} />
          </div>
          <div className="flex flex-col py-3 border-b-1 border-element-border w-full text-xs space-y-4">
            <span className="text-low-contrast font-bold">
              {t("Booking.ExtrasOrder.ExtraOrderModal.extrasInOrder")}
            </span>
            <ExtraOrderListCard
              extraOrder={extraOrder}
              setExtraOrder={setExtraOrder}
              currencySymbol={
                reservation?.rental?.tarification?.rules?.rules?.currency
                  ?.symbol
              }
              locale={user?.locale}
              openPop={openPop}
            />
          </div>
          <div className="flex flex-col py-3 w-full text-xs space-y-4">
            <span className="text-low-contrast font-bold">
              {t("Booking.ExtrasOrder.ExtraOrderModal.info")}
            </span>
            <ExtraOrderInfoCard
              reservation={reservation}
              extraOrder={extraOrderModal}
            />
          </div>

          {payment_status ===
            ReservationExtraOrderStatusEnum.PRE_AUTHORIZED && (
            <div className="flex flex-col py-2  border-t-1 border-element-border">
              <ErrorMessage>{error}</ErrorMessage>
              <Button
                onClick={onValidate}
                loading={loadingValidation}
                className={`${error && "mt-2"}`}
              >
                {t("Global.validate")}
              </Button>
            </div>
          )}
        </div>
      </RightModal>
    </>
  );
};

export default ExtraOrderModal;
