import React, { useEffect, useRef } from "react";
import { SendMessageAreaProps } from "./ViewAndSendMessages.type";
import { useTranslation } from "react-i18next";
import { SimpleRadio } from "../../Common/SimpleRadio/SimpleRadio";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { TextAreaInput } from "../../Common/TextAreaInput/TextAreaInput";
import { PictureWithDelete } from "../../Common/PictureWithDelete/PictureWithDelete";
import { Button } from "../../Common/Button/Button";

import SparklesIcon from "../../../assets/icons/sparkles.svg?react";
import MessageIcon from "../../../assets/icons/message.svg?react";
import SendWhiteIcon from "../../../assets/icons/send-white.svg?react";
import PhotoIcon from "../../../assets/icons/photo.svg?react";

export const SendMessageArea: React.FC<SendMessageAreaProps> = ({
  reservation,
  content,
  photos,
  type,
  loading,
  loadingSend,
  errorSend,
  onChangeType,
  onChangeContent,
  onChangeCursor,
  onClickTemplate,
  onChangePhotos,
  onSend,
}) => {
  const { t } = useTranslation();

  const sendMessageAreaRef = useRef<HTMLDivElement>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClickPhotoIcon = () => {
    fileInputRef.current?.click();
  };

  const handleAddPhoto = (event: any) => {
    const nextPhotos = [...photos, ...event.target.files];
    onChangePhotos(nextPhotos);
  };

  const handleDeletePhoto = (value: any) => {
    onChangePhotos(photos.filter((p: any) => p.name !== value.name));
  };

  return (
    <div
      className="border-t-1 border-element-border-light p-4 pb-2"
      ref={sendMessageAreaRef}
    >
      <p className="text-low-contrast font-light mb-2">
        {t("AutoMessageList.Upsert.Channels.sendBy")}
      </p>
      {/* Message types */}
      <div className="flex flex-row gap-1 text-sm flex-wrap">
        {reservation?.platform?.name?.toUpperCase() === "AIRBNB.COM" && (
          <>
            <SimpleRadio
              value={type === "airbnb"}
              disabled={loadingSend || loading}
              onClick={() => onChangeType("airbnb")}
            />
            <span
              className="mr-2 cursor-pointer"
              onClick={() => onChangeType("airbnb")}
            >
              {t("AutoMessageList.Upsert.Channels.byAirbnb")}
            </span>
          </>
        )}
        {reservation?.platform?.name?.toUpperCase() === "BOOKING.COM" && (
          <>
            <SimpleRadio
              value={type === "booking"}
              disabled={loadingSend || loading}
              onClick={() => onChangeType("booking")}
            />
            <span
              className="mr-2 cursor-pointer"
              onClick={() => onChangeType("booking")}
            >
              {t("AutoMessageList.Upsert.Channels.byBooking")}
            </span>
          </>
        )}


         {reservation?.platform?.name?.toUpperCase() !== "BOOKING.COM" &&
          reservation?.platform?.name?.toUpperCase() !== "AIRBNB.COM" && (
            <>
              <SimpleRadio
                value={type === "email"}
                disabled={loadingSend || loading}
                onClick={() => onChangeType("email")}
              />
              <span
                className="mr-2 cursor-pointer"
                onClick={() => onChangeType("email")}
              >
                E-mail
              </span>
            </>
          )}
        {/* <SimpleRadio
          value={type === "sms"}
          disabled={loadingSend || loading}
          onClick={() => onChangeType("sms")}
        />
        <span
          className="mr-2 cursor-pointer"
          onClick={() => onChangeType("sms")}
        >
          SMS
        </span> */}

        {/* <SimpleRadio
          value={type === "whatsapp"}
          disabled={loadingSend || loading}
          onClick={() => onChangeType("whatsapp")}
        />
        <span
          className="mr-2 cursor-pointer"
          onClick={() => onChangeType("whatsapp")}
        >
          WhatsApp
        </span> */}
      </div>

      <ErrorMessage>{errorSend}</ErrorMessage>

      {/* Type and send */}
      <div className="flex flex-col mt-3">
        <TextAreaInput
          value={content}
          placeholder={t("MessageList.typeYourMessage")}
          rows={3}
          onTextChange={onChangeContent}
          onSelect={onChangeCursor}
          disabled={
            loadingSend ||
            loading ||
            (reservation?.platform?.name?.toUpperCase() !== "BOOKING.COM" &&
              reservation?.platform?.name?.toUpperCase() !== "AIRBNB.COM" &&
              reservation?.platform?.name?.toUpperCase() !== "DIRECT")
          }
        />

        <div
          className="flex flex-col pt-3 border-1 border-t-0 border-element-border rounded-b-6px flex-wrap"
          style={{ marginTop: -4 }}
        >
          {/* Photos */}
          {/* {(type === "airbnb" || type === "booking" || type === "email") &&
            photos &&
            photos?.length > 0 && (
              <div className="flex flex-row gap-2 flex-wrap py-2 border-b-1 border-element-border p-2 mb-2 pb-3">
                {photos?.map((photo) => (
                  <PictureWithDelete
                    src={URL.createObjectURL(photo)}
                    disabled={loadingSend || loading}
                    onRemove={() => handleDeletePhoto(photo)}
                  />
                ))}
              </div>
            )} */}

          {/* Buttons */}
          <div className="flex justify-between p-2">
            <div className="flex-1 flex flex-row gap-2">
              {/* <div>
                <Button
                  onClick={() => {}}
                  type="secondary"
                  RightIcon={SparklesIcon}
                  disabled={loadingSend || loading}
                >
                  {t("MessageList.automatedMessages")}
                </Button>
              </div> */}

              <div>
                <Button
                  onClick={onClickTemplate}
                  type="secondary"
                  RightIcon={MessageIcon}
                  disabled={loadingSend || loading}
                >
                  {t("MessageList.templates")}
                </Button>
              </div>

              {/* {(type === "airbnb" ||
                type === "booking" ||
                type === "email") && (
                <div>
                  <input
                    type="file"
                    multiple={true}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                    accept="image/jpeg, image/png"
                    onChange={handleAddPhoto}
                  />
                  <Button
                    onClick={handleClickPhotoIcon}
                    type="secondary"
                    RightIcon={PhotoIcon}
                    disabled={loadingSend || loading}
                  ></Button>
                </div>
              )} */}
            </div>

            <div>
              <Button
                onClick={onSend}
                disabled={content?.length === 0 && photos?.length === 0}
                loading={loadingSend}
                RightIcon={SendWhiteIcon}
              >
                {t("MessageList.send")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
