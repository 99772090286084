import React, {useEffect, useRef, useState} from "react";
import { BookingMessagePageProps } from "./BookingMessagePage.type";
import { BookingLayout } from "../../components/Layout/BookingLayout/BookingLayout";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Common/Button/Button";
import { ValueType } from "../../types/commonTypes";
import { CenterModal } from "../../components/Common/CenterModal/CenterModal";
import { RightModal } from "../../components/Common/RightModal/RightModal";
import { AutoMessageUpsertModal } from "../../components/Message/AutoMessageUpsert/AutoMessageUpsertModal/AutoMessageUpsertModal";
import { useParams } from "react-router-dom";
import { fetchOneReservation } from "../../helpers/reservationHelper";
import paths from "../../constants/paths";
import { useTablePage } from "../../hooks/useTablePage";
import { debounce } from "lodash";
import { ViewAndSendMessages } from "../../components/Message/ViewAndSendMessages/ViewAndSendMessages";
import { ReservationResponse } from "../../types/GETTypes";
import { ScheduledMessageList } from "../../components/Message/ScheduledMessageList/ScheduledMessageList";

import CheckCircleWhiteIcon from "../../assets/icons/check-circle-white.svg?react";
import ClockIcon from "../../assets/icons/clock.svg?react";
import TrashWhiteIcon from "../../assets/icons/trash-white.svg?react";
import CloseIcon from "../../assets/icons/close.svg?react";

export const BookingMessagePage: React.FC<BookingMessagePageProps> = () => {
  const { reservationId } = useParams();
  const bottomElement = useRef(null);


  const scrollToBottom = () => {
    // @ts-ignore
    bottomElement?.current?.scrollIntoView();
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [reservation, setReservation] = useState<ReservationResponse>();

  const tableMessages = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.MESSAGES}/${reservationId}`,
    "messages",
    { combine: true, reverseData: true }
  );

  const tableScheduledMessages = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.MESSAGES}/${reservationId}`,
    "notification_statuses"
  );

  const [isAutoView, setIsAutoView] = useState<boolean>(false);
  const [isAutoRemove, setIsAutoRemove] = useState<boolean>(false);
  const [isAutoEdit, setIsAutoEdit] = useState<boolean>(false);
  const [isAutoCopy, setIsAutoCopy] = useState<boolean>(false);

  const handleClickAutoMessage = (action?: ValueType) => {
    if (action === "remove") setIsAutoRemove(true);
    else if (action === "edit") setIsAutoEdit(true);
    else if (action === "copy") setIsAutoCopy(true);
    else setIsAutoView(true);
  };

  const handleReservationSuccess = (value: ReservationResponse) => {
    console.log("handleReservationSuccess", value);
    setReservation(value);
    setLoading(false);

    scrollToBottom();
  };

  const handleReservationError = (value: string) => {
    setError(value);
    setLoading(false);
  };

  useEffect(() => {
    if (reservationId) {
      fetchOneReservation(
        reservationId,
        handleReservationSuccess,
        handleReservationError
      );

      tableMessages.fetch({
        limit: 10,
        sort_field: "captured_at",
        sort_direction: "desc",
      });

      tableScheduledMessages.fetch({});
    }
  }, [reservationId]);

  const debouncedLoadMessages = React.useRef(
    debounce(async (tm) => {
      tm.handlePaginationNext();
    }, 200)
  ).current;

  const handleLoadMessages = () => {
    debouncedLoadMessages(tableMessages);
  };

  return (
    <>
      <RemoveAutoMessageModal
        isVisible={isAutoRemove}
        onClose={() => setIsAutoRemove(false)}
      />

      <ViewAutoMessageModal
        isVisible={isAutoView}
        onClose={() => setIsAutoView(false)}
      />

      <AutoMessageUpsertModal
        type="edit"
        isVisible={isAutoEdit}
        onClose={() => setIsAutoEdit(false)}
      />

      <CopyAutoMessageModal
        isVisible={isAutoCopy}
        onClose={() => setIsAutoCopy(false)}
      />


      <BookingLayout navbarActiveItem="messages" reservation={reservation}>
        <div
          className="flex flex-row border-1 border-element-border rounded-6px border-r-0"
          style={{ height: "calc(100vh - 150px)" }}
        >
          {/* Messages and send message */}
          <ViewAndSendMessages
            loading={loading}
            onLoadMessages={handleLoadMessages}
            reservation={reservation}
            tableMessages={tableMessages}
          />

          {/* Scheduled messages */}
          <div className="w-[500px] border-r-1 h-full overflow-y-scroll">
            <ScheduledMessageList
              messages={tableScheduledMessages.data}
              loading={tableScheduledMessages.loading}
              guest_email={reservation?.guest.email ?? ""}
              onRefresh={() => tableScheduledMessages.fetch({})}
            />
          </div>
        </div>
      </BookingLayout>
    </>
  );
};

type RemoveAutoMessageModalProps = { isVisible: boolean; onClose: () => void };

const RemoveAutoMessageModal: React.FC<RemoveAutoMessageModalProps> = ({
  isVisible,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <CenterModal isVisible={isVisible} header="remove" onClose={onClose}>
      <div className="text-sm">
        <p className="text-high-contrast-contrast font-semibold">
          {t("Booking.removeAutoMessageTitle")}
        </p>
        <p className="text-low-contrast font-light mt-1">
          {t("Booking.removeAutoMessageContent")}
        </p>

        <div className="flex flex-row mt-4 gap-3">
          <Button
            onClick={onClose}
            type="destructive"
            LeftIcon={TrashWhiteIcon}
          >
            {t("Global.remove")}
          </Button>

          <Button onClick={onClose} type="secondary" LeftIcon={CloseIcon}>
            {t("Global.cancel")}
          </Button>
        </div>
      </div>
    </CenterModal>
  );
};

type ViewAutoMessageModalProps = { isVisible: boolean; onClose: () => void };

const ViewAutoMessageModal: React.FC<ViewAutoMessageModalProps> = ({
  isVisible,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <RightModal
      title="Envoi détails Checkin"
      isVisible={isVisible}
      onClose={onClose}
    >
      <div className="flex flex-col">
        <p className="text-low-contrast font-semibold flex flex-row items-center gap-1">
          <ClockIcon className="w-5 h-5" />
          <p>Programmé pour le samedi 2 septembre à 19:00</p>
        </p>

        <p className="text-low-contrast font-light mt-4 ">
          {t("Global.title")}
        </p>
        <p className="text-high-contrast font-semibold">Bienvenu Elie !</p>

        <p className="text-low-contrast font-light mt-4">
          {t("Global.content")}
        </p>
        <p className="text-high-contrast mt-1">
          Bonjour Elie, <br />
          <br />
          Voici vos informations de checkin pour votre séjour.
          <br />
          <br />
          On sait depuis longtemps que travailler avec du texte lisible et
          contenant du sens est source de distractions, et empêche de se
          concentrer sur la mise en page elle-même. L'avantage du Lorem Ipsum
          sur un texte générique.
          <br />
          <br />
          Bon séjour
        </p>
      </div>
    </RightModal>
  );
};

type CopyAutoMessageModalProps = { isVisible: boolean; onClose: () => void };

const CopyAutoMessageModal: React.FC<CopyAutoMessageModalProps> = ({
  isVisible,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <CenterModal isVisible={isVisible} header="success" onClose={onClose}>
      <div className="text-sm">
        <p className="text-high-contrast-contrast font-semibold">
          {t("Booking.copyAutoMessageTitle")}
        </p>
        <p className="text-low-contrast font-light mt-1 mb-4">
          {t("Booking.copyAutoMessageContent")}
        </p>

        <Button onClick={onClose} LeftIcon={CheckCircleWhiteIcon}>
          {t("Global.ok")}
        </Button>
      </div>
    </CenterModal>
  );
};
