import React from "react";
import { useTranslation } from "react-i18next";

import ArrowRight from "../../../../assets/icons/arrow-right-white.svg?react";
import AcceptBookingGif from "../../../../assets/images/accept_booking.gif";
import { Button } from "../../../Common/Button/Button";

type BookingConnectStep1Props = {
  onNext: () => void;
};

export const BookingConnectStep1: React.FC<BookingConnectStep1Props> = ({
  onNext = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3">
      <p className="font-semibold text-low-contrast underline">
        {/* <!-- Start Booking.com widget code snippet --> */}
        <a
          id="booking-widget"
          className=""
          href="https://admin.booking.com/?groups_redirect=%2Fhotel%2Fhoteladmin%2Fextranet_ng%2Fmanage%2Fchannel-manager%2Fwidget.html?provider_id=1547"
          data-provider-name="SuperHote"
          data-widget-width="900"
          target="_blank"
        >
          {t("Progression.bookingConnectStep1Content1")}
        </a>
        <script src="https://bstatic.com/psb/provdash/js/booking-widget.js"></script>
        {/* <!-- End Booking.com widget code snippet --> */}
      </p>

      <p className="font-light text-low-contrast">
        {t("Progression.bookingConnectStep1Content2")}{" "}
        <span className="font-semibold">
          {t("Progression.bookingConnectStep1Content3")}
        </span>
      </p>

      <p className="font-light text-low-contrast">
        {t("Progression.bookingConnectStep1Content4")}
      </p>

      <img src={AcceptBookingGif} className="w-full rounded-6px" />
      <Button onClick={onNext} RightIcon={ArrowRight}>
        {t("Global.nextStep")}
      </Button>
    </div>
  );
};
