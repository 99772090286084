import React from "react";
import { useTranslation } from "react-i18next";
import { MainLayout } from "../MainLayout/MainLayout";
import { BookingLayoutProps } from "./BookingLayout.type";

import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "../../../assets/icons/chevron-right.svg?react";
import paths from "../../../constants/paths";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { BookingLayoutNavbar } from "./Navbar/BookingLayoutNavbar";

export const BookingLayout: React.FC<BookingLayoutProps> = ({
  children,
  rightChildren,
  navbarActiveItem,
  reservation,
  error,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getTitle = () => {
    return (
      <div className="flex flex-row items-center space-x-1">
        <h1
          className="m-0 text-lg font-bold cursor-pointer text-low-contrast"
          onClick={() => navigate(paths.RESERVATIONS)}
        >
          {t("Booking.title")}
        </h1>

        <ChevronRightIcon />
        {reservation ? (
          <>
            <img
              src={reservation?.guest?.photo!}
              className="object-cover w-6 h-6 rounded-full"
            />
            <h1 className="pl-1 text-lg font-bold text-high-contrast">
              {reservation?.guest?.first_name} {reservation?.guest?.last_name}
            </h1>
          </>
        ) : null}

        {!reservation ? (
          <div className="flex items-center gap-2 animate-pulse">
            <div className="object-cover w-6 h-6 rounded-full bg-slate-300" />
            <h1 className="h-2 pl-1 text-lg font-bold rounded text-high-contrast w-52 bg-slate-300"></h1>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <MainLayout title={getTitle()} sidebarActiveItem="bookings">
      {/* Navbar */}
      <div className="flex flex-row items-center gap-2 pb-5 mt-3">
        <div className="flex-1">
          <BookingLayoutNavbar
            reservation={reservation}
            activeItem={navbarActiveItem}
          />
        </div>

        {rightChildren ? <div>{rightChildren}</div> : null}
      </div>

      {error ? <ErrorMessage>{error}</ErrorMessage> : children}
    </MainLayout>
  );
};
