import React, { useEffect, useState } from "react";
import { RightModal } from "../Common/RightModal/RightModal";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NumberInput } from "../Common/NumberInput/NumberInput";
import { ValueType } from "../../types/commonTypes";
import { Controller, useForm } from "react-hook-form";
import { CalendarInput } from "../Common/CalendarInput/CalendarInput";
import { Switch } from "../Common/Switch/Switch";
import paths from "../../constants/paths";
import { useTablePage } from "../../hooks/useTablePage";
import { MultiSelectRentalsTable } from "../Common/MultiSelectRentalsTable/MultiSelectRentalsTable";
import { Button } from "../Common/Button/Button";
import { Daysfilter } from "../Common/DaysFilter/DaysFilter";
import { post } from "../../helpers/APIHelper";
import { ErrorMessage } from "../Common/ErrorMessage/ErrorMessage";

type formSchema = {
  nbNights: number;
  range: [Date | null, Date | null];
  days: {
    isActive: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
  rentals: {
    isActive: false;
    ids: ValueType[];
  };
};

export const CalendarMinNightsModal: React.FC<{
  isVisible: boolean;
  rental: any;
  date: moment.Moment;
  minNights: number;
  onClose: () => void;
  onSuccess: (
    startDate: string,
    endDate: string,
    weekDays: string,
    rentalIds: ValueType[],
    minNights: number
  ) => void;
}> = ({ isVisible, rental, date, minNights, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const form = useForm<formSchema>({
    defaultValues: {
      nbNights: minNights,
      range: [date.toDate(), date.toDate()],
      days: {
        isActive: false,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      },
      rentals: {
        isActive: false,
        ids: [],
      },
    },
  });

  const [selectedRentals, setSelectedRentals] = useState<ValueType[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const rentalsTable = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.RENTALS}`,
    "rentals"
  );

  useEffect(() => {
    setError(null);

    if (isVisible) {
      rentalsTable.handleFilterCustom("exclude_ids", rental?.id);
    }
  }, [isVisible]);

  useEffect(() => {
    form.setValue("nbNights", minNights);
  }, [minNights]);

  useEffect(() => {
    form.setValue("range", [date.toDate(), date.toDate()]);
    form.setValue("days", {
      isActive: false,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    });
    form.setValue("rentals", {
      isActive: false,
      ids: [],
    });
  }, [date]);

  const handleSelectRental = (id: ValueType) => {
    const exists = selectedRentals?.findIndex((i) => i === id) > -1;

    if (exists) {
      setSelectedRentals((prev) => [...prev.filter((i) => i !== id)]);
    } else {
      setSelectedRentals((prev) => [...prev, id]);
    }
  };

  useEffect(() => {
    form.setValue("rentals.ids", selectedRentals);
  }, [selectedRentals]);

  function getDaysString(days: {
    isActive: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  }) {
    // Vérifie si isActive est à false
    if (!days.isActive) {
      return "0,1,2,3,4,5,6";
    }

    // Tableau pour stocker les jours actifs
    const activeDays = [];

    // Vérifie chaque jour et ajoute sa valeur si true
    if (days.monday) activeDays.push(1);
    if (days.tuesday) activeDays.push(2);
    if (days.wednesday) activeDays.push(3);
    if (days.thursday) activeDays.push(4);
    if (days.friday) activeDays.push(5);
    if (days.saturday) activeDays.push(6);
    if (days.sunday) activeDays.push(0);

    // Retourne la chaîne de caractères des jours actifs, séparés par des virgules
    return activeDays.join(",");
  }

  const handleSave = async (values: formSchema) => {
    setLoading(true);
    setError(null);

    const rental_ids = values.rentals.isActive
      ? [rental.id, ...values.rentals.ids].join(",")
      : rental.id.toString();

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.UPDATE_MIN_NIGHTS}`,
      {
        rental_ids,
        start_date: moment(values.range[0]).format("YYYY-MM-DD"),
        end_date: moment(values.range[1]).format("YYYY-MM-DD"),
        week_days: getDaysString(values.days),
        min_nights: values.nbNights,
      }
    );

    setLoading(false);

    if (res.data?.success) {
      onSuccess(
        moment(values.range[0]).format("YYYY-MM-DD"),
        moment(values.range[1]).format("YYYY-MM-DD"),
        getDaysString(values.days),
        values.rentals.isActive
          ? [rental.id, ...values.rentals.ids]
          : [rental.id],
        values.nbNights
      );
    } else {
      setError(res.response.data.message);
    }
  };

  return (
    <RightModal
      title={`${t("Calendar.MinNightsModal.title")} ${rental?.name}`}
      isVisible={isVisible}
      onClose={onClose}
    >
      <form onSubmit={form.handleSubmit(handleSave)} className="w-full">
        <div className="flex flex-col w-full h-full">
          {/* Fields */}
          <div className="flex flex-1 flex-col gap-5 w-full overflow-y-scroll">
            <div>
              <Controller
                control={form.control}
                name="nbNights"
                render={({ field: { value, onChange } }) => (
                  <NumberInput
                    label={t("Calendar.MinNightsModal.nbNights")}
                    min={1}
                    value={value}
                    onChangeText={onChange}
                    disabled={loading}
                  />
                )}
              />
            </div>

            <Controller
              control={form.control}
              name="range"
              render={({ field: { value, onChange } }) => (
                <div>
                  <CalendarInput
                    label={t("Calendar.MinNightsModal.dates")}
                    classNames={{ button: "h-8" }}
                    dateType="range"
                    value={value}
                    height="full"
                    onChangeDate={onChange}
                    onChangeEndDateInput={() => {}}
                    onChangeStartDateInput={() => {}}
                  />
                </div>
              )}
            />

            <Controller
              control={form.control}
              name="days"
              render={({ field: { value, onChange } }) => (
                <Daysfilter
                  value={value}
                  onChange={onChange}
                  disabled={loading}
                />
              )}
            />

            <Controller
              control={form.control}
              name="rentals"
              render={({ field: { value, onChange } }) => (
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col">
                    <div
                      className="flex justify-between items-center cursor-pointer"
                      onClick={() =>
                        onChange({ ...value, isActive: !value.isActive })
                      }
                    >
                      <p className="font-semibold text-low-contrast">
                        {t("Global.applyOnOtherRentals")}
                      </p>

                      <Switch value={value.isActive} disabled={loading} />
                    </div>

                    {value.isActive && (
                      <div className="mt-2">
                        <MultiSelectRentalsTable
                          disabled={loading}
                          selectedRentals={selectedRentals}
                          tablePage={rentalsTable}
                          onSelectRental={handleSelectRental}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            />

            <ErrorMessage>{error}</ErrorMessage>
          </div>

          {/* Buttons */}
          <div className="flex gap-4 border-t-1 border-element-border pt-4 mt-4">
            <Button type="secondary" disabled={loading} onClick={onClose}>
              {t("Global.cancel")}
            </Button>
            <Button loading={loading}>
              {t("Calendar.MinNightsModal.save")}
            </Button>
          </div>
        </div>
      </form>
    </RightModal>
  );
};
