import React, { useEffect, useState } from "react";
import { SubscriptionPageProps } from "./SubscriptionPage.type";
import SuperhoteIcon from "../../assets/icons/superhote.svg?react";
import { Title } from "../../components/Common/Title/Title";
import { useTranslation } from "react-i18next";
import { RegisterBilling } from "../../components/Authentication/Register/Billing/RegisterBilling";
import useLocalStorage from "use-local-storage";
import { useNavigate } from "react-router-dom";
import paths from "../../constants/paths";
import { CenterModal } from "../../components/Common/CenterModal/CenterModal";
import { RegisterThanks } from "../../components/Authentication/Register/Thanks/RegisterThanks";

import LogOutIcon from "../../assets/icons/log-out.svg?react";
import { Carousel } from "../../components/Common/Carousel/Carousel";
import Carousel1Image from "../../assets/images/register-carousel-1.svg?react";
import Carousel2Image from "../../assets/images/register-carousel-2.svg?react";
import Carousel3Image from "../../assets/images/register-carousel-3.svg?react";

export const SubscriptionPage: React.FC<SubscriptionPageProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [storeEmail, setStoreEmail] = useLocalStorage<string | null>(
    "email",
    null
  );

  const [success, setSuccess] = useState<boolean>(false);

  const handleSubmitBilling = () => {
    setSuccess(true);
    navigate(paths.DASHBOARD);
  };

  useEffect(() => {
    console.log("storeEmail", storeEmail);
    if (storeEmail === null) navigate(paths.LOGIN);
  }, [storeEmail]);

  return (
    <>
      <CenterModal
        isVisible={success}
        header="success"
        size="large"
        onClose={() => navigate(paths.DASHBOARD)}
      >
        <RegisterThanks
          onConfigure={() => {}}
          onSubmit={() => navigate(paths.DASHBOARD)}
        />
      </CenterModal>

      <div className="flex w-full justify-center ">
        <div className="flex flex-row min-w-[1024px] w-4/5 max-w-[1270px] min-h-screen">
          {/* Left */}
          <div className="w-2/5 max-w-md min-h-screen p-2">
            <div className="flex flex-col min-h-full bg-subtle border-1 border-element-border/50 p-8 rounded-6px">
              <div className="flex-1 pb-4">
                <SuperhoteIcon />
              </div>

              <Carousel
                items={[
                  {
                    Image: Carousel1Image,
                    title: t("Register.carousel1Title"),
                    subTitle: t("Register.carousel1SubTitle"),
                  },
                  {
                    Image: Carousel2Image,
                    title: t("Register.carousel2Title"),
                    subTitle: t("Register.carousel2SubTitle"),
                  },
                  {
                    Image: Carousel3Image,
                    title: t("Register.carousel3Title"),
                    subTitle: t("Register.carousel3SubTitle"),
                  },
                ]}
              />
            </div>
          </div>

          {/* Right */}
          <div className="flex-1 px-12 py-8">
            <div className="flex">
              <div className="mb-1 flex-1">
                <Title>{t("Subscription.title")}</Title>
              </div>

              <div
                className="flex gap-1"
                onClick={() => navigate(paths.LOGOUT)}
              >
                <LogOutIcon />
                <p className="text-sm font-light text-low-contrast hover:underline cursor-pointer">
                  {t("Subscription.logout")}
                </p>
              </div>
            </div>

            <div className="mb-4">
              <Title type="secondary">{t("Subscription.subTitle")}</Title>
            </div>

            <RegisterBilling
              email={storeEmail!}
              onSubmit={handleSubmitBilling}
            />
          </div>
        </div>
      </div>
    </>
  );
};
