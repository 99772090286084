import React from "react";
import { useTranslation } from "react-i18next";
import MoneyIcon from "../../../../assets/icons/money.svg?react";
import { Card } from "../../../Common/Card/Card";

export const RentalAdditionalCostSkeleton: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Card
      label={t("Rental.Pricing.AdditionalCost.title")}
      button={{ Icon: MoneyIcon, label: t("Global.edit"), skeleton: true }}
    >
      <div className="flex flex-col gap-2 animate-pulse">
        <p className="w-full h-10 rounded bg-slate-200"></p>
        <p className="w-full h-10 rounded bg-slate-200"></p>
        <p className="w-full h-10 rounded bg-slate-200"></p>
        <p className="w-full h-10 rounded bg-slate-200"></p>
      </div>
    </Card>
  );
};
