import React from "react";
import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import { ErrorPageProps } from "./ErrorPage.type";

export const ErrorPage: React.FC<ErrorPageProps> = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        {isRouteErrorResponse(error) && <i>{error.statusText || error.data}</i>}
      </p>
    </div>
  );
};
