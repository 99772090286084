import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SendIcon from "../../../assets/icons/send-white.svg?react";
import { RequiredFields } from "../../../types/commonTypes";
import { Button } from "../../Common/Button/Button";
import { CenterModal } from "../../Common/CenterModal/CenterModal";
import { InputSelect } from "../../Common/InputSelect/InputSelect";
import { SimpleRadio } from "../../Common/SimpleRadio/SimpleRadio";
import { TextAreaInput } from "../../Common/TextAreaInput/TextAreaInput";
import { TextInput } from "../../Common/TextInput/TextInput";

type PaymentReservationSendPaymentModalProps = {
  isVisible: boolean;
  paymentReservationDetailId: number;
  onClose: () => void;
  onSendPayment: (id: number) => void;
};

type PaymentReservationSendPaymentModalForm = {
  type: "email" | "sms";
  language: string;
  email: string;
  subject: string;
  message: string;
};

export const PaymentReservationSendPaymentModal: React.FC<
  PaymentReservationSendPaymentModalProps
> = ({ isVisible, paymentReservationDetailId, onClose, onSendPayment }) => {
  const { t } = useTranslation();

  const requiredFields: RequiredFields<PaymentReservationSendPaymentModalForm> =
    {
      type: true,
      language: true,
      email: true,
      subject: true,
      message: true,
    };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<PaymentReservationSendPaymentModalForm>({
    mode: "all",
    defaultValues: {
      type: "email",
      language: "",
      email: "",
      subject: "",
      message: "",
    },
  });

  const onSubmit: SubmitHandler<PaymentReservationSendPaymentModalForm> = () =>
    onSendPayment(paymentReservationDetailId);

  watch();

  return (
    <CenterModal
      title={t("Payments.PaymentReservationList.sendPaymentModalTitle")}
      size="normal"
      isVisible={isVisible}
      onClose={onClose}
    >
      <div className="flex flex-col space-y-3">
        <div className="flex flex-col flex-1">
          <div className="flex space-x-6">
            <Controller
              control={control}
              name="type"
              rules={{
                required: {
                  value: requiredFields.type,
                  message: t("Global.Errors.requiredField", {
                    fieldName: t("Payments.PaymentReservationList.typeLabel"),
                  }),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <>
                  <SimpleRadio
                    label={t("Payments.PaymentReservationList.typeEmailLabel")}
                    onClick={() => onChange("email")}
                    value={value === "email"}
                  />
                  <SimpleRadio
                    label={t("Payments.PaymentReservationList.typeSMSLabel")}
                    onClick={() => onChange("sms")}
                    value={value === "sms"}
                  />
                </>
              )}
            />
          </div>

          <InputSelect
            register={register("language", {
              required: {
                value: requiredFields.language,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Payments.PaymentReservationList.languageLabel"),
                }),
              },
            })}
            label={t("Payments.PaymentReservationList.languageLabel")}
            items={[
              {
                label: "Anglais",
                value: "english",
              },
            ]}
            selectedValue={getValues("language")}
          />

          <TextInput
            label={t("Payments.PaymentReservationList.emailLabel")}
            value={getValues().email}
            register={register("email", {
              required: t("Global.Errors.requiredField", {
                fieldName: t("Payments.PaymentReservationList.emailLabel"),
              }).toString(),
            })}
            error={errors.email?.message}
          />

          <TextInput
            label={t("Payments.PaymentReservationList.subjectLabel")}
            value={getValues().subject}
            register={register("subject", {
              required: t("Global.Errors.requiredField", {
                fieldName: t("Payments.PaymentReservationList.subjectLabel"),
              }).toString(),
            })}
            error={errors.subject?.message}
          />

          <TextAreaInput
            register={register("message", {
              required: {
                value: requiredFields.message,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("Payments.PaymentReservationList.messageLabel"),
                }),
              },
            })}
            label={t("Payments.PaymentReservationList.messageLabel")}
            value={getValues("message")}
            resize={{
              y: true,
              x: false,
            }}
            error={errors?.message?.message}
          />
        </div>

        <div className="flex space-x-3">
          <Button type="secondary" onClick={onClose}>
            {t("Global.cancel")}
          </Button>
          <div className="flex-1" title={t("Global.wip")}>
            <Button
              RightIcon={SendIcon}
              onClick={handleSubmit(onSubmit)}
              disabled={true}
            >
              {t("Global.send")}
            </Button>
          </div>
        </div>
      </div>
    </CenterModal>
  );
};
