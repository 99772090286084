import React from "react";
import { useTranslation } from "react-i18next";
import CleanIcon from "../../../assets/icons/clean.svg?react";
import DotsVerticalIcon from "../../../assets/icons/dots-vertical.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import LoginIcon from "../../../assets/icons/log-in.svg?react";
import { TableListItemProps } from "../../Common/TableList/TableList.type";
import { RentalLightListItemResponse } from "../../../types/GETTypes";
import { Button } from "../../Common/Button/Button";

export const ManagementOwnerTableItem: React.FC<TableListItemProps> = ({
  data,
  onClick,
}) => {
  const { t } = useTranslation();
  const rental: RentalLightListItemResponse = data;

  return (
    <tr className="cursor-pointer hover:bg-element-background/40">
      <td className="py-3 px-2">
        <div className="flex items-center gap-2">
          <div className="size-12 rounded-full bg-element-background border-element-border border-1 shrink-0" />
          <p className="font-semibold text-high-contrast">Matéo Silvani</p>
        </div>
      </td>

      <td className="py-3 px-2">
        <div className="flex items-center gap-2">
          <p className="font-light text-low-contrast">mateo.silvani@mail.com</p>
        </div>
      </td>

      <td className="py-3 px-2">
        <div className="flex items-center gap-2">
          <p className="font-light text-low-contrast">06 78 09 09 34</p>
        </div>
      </td>

      <td className="py-3 px-2">
        <div className="flex items-center gap-2">
          <p className="font-light text-low-contrast">10 hébergements</p>
        </div>
      </td>

      <td className="py-3 px-2">
        <div className="w-fit flex flex-row items-center gap-1">
          <div className="h-10 bg-element-background rounded-md flex flex-row gap-1 items-center px-2">
            <CleanIcon className="size-4" />
            <p className="font-semibold text-low-contrast">Ménage</p>
          </div>

          <div className="h-10 bg-element-background rounded-md flex flex-row gap-1 items-center px-2">
            <LoginIcon className="size-4" />
            <p className="font-semibold text-low-contrast">Checkin</p>
          </div>
        </div>
      </td>

      <td className="py-3 px-2">
        <div className="flex items-center gap-2">
          <Button
            RightIcon={DotsVerticalIcon}
            type="secondary"
            dropItems={[
              { label: t("Global.edit"), value: "edit", Icon: EditIcon },
            ]}
          />
        </div>
      </td>
    </tr>
  );
};
