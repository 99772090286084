import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { AddMemberRolesForm } from "./AddMemberModal.type";
import { useTranslation } from "react-i18next";
import { OperationalManagementRoleListItemResponse } from "../../../types/GETTypes";
import { ManagementTeamRoleBadge } from "../ManagementTeamList/ManagementTeamRoleBadge";
import { SimpleBox } from "../../Modal/Airbnb/Connect/AirbnbConnectStepImport";
import { ValueType } from "../../../types/commonTypes";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { SimpleRadio } from "../../Common/SimpleRadio/SimpleRadio";

export const AddMemberStepRoles: React.FC<{
  refButton: React.MutableRefObject<HTMLInputElement | undefined>;
  initialData: AddMemberRolesForm | undefined;
  roles: OperationalManagementRoleListItemResponse[];
  onNextStep: (values: AddMemberRolesForm) => void;
}> = ({ refButton, initialData, roles, onNextStep }) => {
  const form = useForm<AddMemberRolesForm>({
    defaultValues: { rolesId: [] },
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (initialData) {
      form.setValue("rolesId", initialData.rolesId);
    }
  }, [initialData]);

  const handleNextStep = (values: AddMemberRolesForm) => {
    if (values.rolesId?.length === 0) {
      form.setError("rolesId", {
        message: t("Management.AddMember.Roles.rolesIdRequired"),
      });
    } else {
      form.clearErrors();
      onNextStep(values);
    }
  };

  return (
    <form onSubmit={form.handleSubmit(handleNextStep)}>
      <p className="font-light text-low-contrast mb-3">
        {t("Management.AddMember.Roles.content1")}
      </p>

      <p className="font-light text-low-contrast mb-3">
        {t("Management.AddMember.Roles.content2")}
      </p>

      <p className="font-semibold text-low-contrast mb-3">
        {t("Management.AddMember.Roles.roles")}
      </p>

      <Controller
        control={form.control}
        name="rolesId"
        render={({ field: { value, onChange } }) => {
          const handleChange = (id: ValueType) => {
            onChange([id]);
          };

          return (
            <>
              <div className="grid grid-cols-2 gap-2 pb-2">
                {roles?.map((role) => (
                  <div
                    key={role.id}
                    className="flex gap-2 items-center cursor-pointer"
                    onClick={() => handleChange(role.id)}
                  >
                    <div className="flex-1">
                      <ManagementTeamRoleBadge role={role} />
                    </div>

                    <SimpleRadio
                      value={value.findIndex((id) => role.id === id) !== -1}
                    />
                  </div>
                ))}
              </div>

              <ErrorMessage errorType="FORM">
                {form.formState.errors?.rolesId?.message}
              </ErrorMessage>
            </>
          );
        }}
      />

      <input
        type="submit"
        value="go"
        ref={refButton as React.MutableRefObject<HTMLInputElement>}
        className="hidden"
      />
    </form>
  );
};
