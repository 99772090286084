import React from "react";

import RobotIcon from "../../../assets/icons/robot.svg?react";
import { useTranslation } from "react-i18next";

export const AddReservationNoMessage = () => {
  const { t } = useTranslation();

  return (
    <>
      <p className="text-low-contrast font-semibold mt-8">
        {t("AddReservation.Done.messageTitle")}
      </p>

      <div className="p-4 rounded-6px border-element-border-hover bg-element-background flex flex-col items-center mt-2">
        <RobotIcon className="w-12 h-12" />

        <p className="mt-4 text-high-contrast font-semibold">
          {t("AddReservation.Done.NoMessage.title")}
        </p>

        <p className="mt-2 text-low-contrast font-light">
          {t("AddReservation.Done.NoMessage.content")}
        </p>
      </div>
    </>
  );
};
