export enum ProgressionRentalsRulesModalStep {
  PRICE_STEP = 0,
  RULES_STEP = 1,
  RULES_MULTI_STEP = 2,
}

export type ProgressionRentalsRulesModalRef = {
  submit: () => void;
};

export type ProgressionRentalsRulesModalNextStepOtherFields = {
  fixed_or_percentage: "fixed" | "percentage" | null;
  hours_or_days: "hours" | "days" | null;
} | null;
