import React from "react";
import { TextInput } from "../../../Common/TextInput/TextInput";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import { useTranslation } from "react-i18next";
import { AutoMessageUpsertGeneralProps } from "./AutoMessageUpsertGeneral.type";

export const AutoMessageUpsertGeneral: React.FC<
  AutoMessageUpsertGeneralProps
> = () => {
  const { t } = useTranslation();

  return (
    <>
      <TextInput
        label={`${t("AutoMessageUpsert.name")} *`}
        placeholder={t("AutoMessageUpsert.placeholderName")}
      />

      <div className="mt-3">
        <InputSelect
          label={t("AutoMessageUpsert.trigger")}
          items={[
            { label: t("AutoMessageUpsert.triggerNewBooking"), value: 0 },
          ]}
          selectedValue={0}
        />
      </div>

      <div className="mt-3 flex flex-row space-x-4">
        <InputSelect
          label={t("AutoMessageUpsert.delay")}
          items={[{ label: t("AutoMessageUpsert.delayNow"), value: 0 }]}
          selectedValue={0}
        />

        <InputSelect
          label={`${t("AutoMessageUpsert.channel")} (multi)`}
          items={[{ label: t("AutoMessageUpsert.channelEmail"), value: 0 }]}
          selectedValue={0}
        />
      </div>
    </>
  );
};
