import React from "react";
import { ErrorMessageProps } from "./ErrorMessage.type";
import AlertCircle from "../../../assets/icons/alert-circle.svg?react";
import {cn} from "../../../helpers/classHelper";

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  errorType = "API",
  children,
  childrenClassName = ""
}) => {
  if (children) {
    return (
      <>
        {errorType === "API" && (
          <div className={cn("p-4 rounded-6px bg-error my-2 text-white text-sm w-full",childrenClassName)}>
            {children}
          </div>
        )}

        {errorType === "FORM" && (
          <div className="flex items-center mt-2">
            <AlertCircle
              className="fill-error me-2 shrink-0"
              width={20}
              height={20}
            />
            <p className={cn("text-left text-base text-error font-light",childrenClassName)}>
              {children}
            </p>
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
};
