import React from "react";

export const RatingSkeleton: React.FC<{
  maxStars: number;
}> = ({ maxStars }) => {
  return (
    <div className="animate-pulse flex items-center space-x-3">
      {[...Array(maxStars)].map((v, i) => (
        <div className="bg-slate-300 rounded"></div>
      ))}
    </div>
  );
};
