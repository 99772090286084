import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../../Common/Card/Card";
import { RentalPlatformsCardElement } from "./PlatformsCardElement";

import AirbnbIcon from "../../../../assets/icons/airbnb.svg?react";
import BookingIcon from "../../../../assets/icons/booking.svg?react";
import PlugIcon from "../../../../assets/icons/plug.svg?react";
import PlusIcon from "../../../../assets/icons/plus.svg?react";

export const RentalPlatformsCardSkeleton: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <Card
      Icon={PlugIcon}
      label={t("Rental.Infos.Platforms.title")}
      button={{ Icon: PlusIcon, label: "???", skeleton: true }}
    >
      <div className="flex flex-col w-full gap-2 animate-pulse">
        <p className="font-semibold text-low-contrast">
          {t("Rental.Infos.Platforms.bookingPlatforms")}
        </p>

        <RentalPlatformsCardElement>
          <div className="w-3 h-3 rounded-full bg-slate-300" />

          <AirbnbIcon className="w-6 h-6" />

          <p className="h-2 font-semibold rounded text-low-contrast w-52 bg-slate-200"></p>
        </RentalPlatformsCardElement>

        <RentalPlatformsCardElement>
          <div className="w-3 h-3 rounded-full bg-slate-300" />

          <BookingIcon className="w-6 h-6" />

          <p className="h-2 font-semibold rounded text-low-contrast w-52 bg-slate-200"></p>
        </RentalPlatformsCardElement>

        {/* <RentalPlatformsCardElement>
          <div className="w-3 h-3 rounded-full bg-slate-300" />

          <VRBOIcon className="w-6 h-6" />

          <p className="h-2 font-semibold rounded text-low-contrast w-52 bg-slate-200"></p>
        </RentalPlatformsCardElement> */}

        {/* <Separator />

        <p className="font-semibold text-low-contrast">
          {t("Rental.Infos.Platforms.ical")}
        </p> */}

        {/* <div className="w-full h-9 bg-slate-200 rounded-6px" /> */}
      </div>
    </Card>
  );
};
