import React from "react";
import { useTranslation } from "react-i18next";
import { AutoMessageUpsertMessageProps } from "./AutoMessageUpsertMessage.type";
import { GroupButton } from "../../../Common/GroupButton/GroupButton";
import { TextInput } from "../../../Common/TextInput/TextInput";

import Attachment1Picture from "../../../../assets/images/attachment1.webp";
import Attachment2Picture from "../../../../assets/images/attachment2.webp";
import Attachment3Picture from "../../../../assets/images/attachment3.webp";
import Attachment4Picture from "../../../../assets/images/attachment4.webp";
import MinusIcon from "../../../../assets/icons/minus-small.svg?react";
import PlusIcon from "../../../../assets/icons/plus.svg?react";
import GlobeIcon from "../../../../assets/icons/globe-active.svg?react";

export const AutoMessageUpsertMessage: React.FC<
  AutoMessageUpsertMessageProps
> = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-row bg-element-background rounded-4px mb-4">
        <div>
          <GlobeIcon className="h-6 w-6 m-2 mt-2" />
        </div>
        <div className="p-2">
          <p className="font-semibold text-high-contrast">
            {t("AutoMessageUpsert.translateTitle")}
          </p>
          <p className="font-light text-low-contrast mt-1">
            {t("AutoMessageUpsert.translateSubTitle")}
          </p>
        </div>
      </div>

      <p className="bg-purple-500 p-4 mb-3 text-white rounded-md">
        PARTIE À MIEUX COMPRENDRE
      </p>

      <GroupButton
        width="full"
        items={[
          { value: 0, label: t("AutoMessageUpsert.auto"), isActive: true },
          { value: 0, label: t("AutoMessageUpsert.french") },
          { value: 0, label: t("AutoMessageUpsert.english") },
          { value: 0, label: t("AutoMessageUpsert.italian") },
        ]}
      />

      <div className="mt-3">
        <TextInput
          label={t("AutoMessageUpsert.subject")}
          placeholder={t("AutoMessageUpsert.placeholderSubject")}
        />
      </div>

      <div className="mt-3">
        <label
          className="font-semibold text-left mb-1"
          // htmlFor={`input-${register?.name}`}
        >
          {t("AutoMessageUpsert.message")}
        </label>
        <textarea
          className="mt-1 w-full border-1 border-element-border rounded-6px rounded-b-none hover:border-element-border-hover active:border-element-border-active focus:outline-1 focus:outline-focus p-2"
          rows={8}
        ></textarea>
        <div
          className="border-1 border-element-border border-t-0 rounded-b-6px p-3 flex flex-row gap-3 flex-wrap"
          style={{ marginTop: "-5px" }}
        >
          <PictureWithDelete src={Attachment1Picture} />
          <PictureWithDelete src={Attachment2Picture} />
          <PictureWithDelete src={Attachment3Picture} />
          <PictureWithDelete src={Attachment4Picture} />

          <div className="h-16 w-16 border-2 border-element-border/50 rounded-6px flex items-center justify-center cursor-pointer">
            <PlusIcon className="h-8 w-8" />
          </div>
        </div>
      </div>
    </>
  );
};

type PictureWithDeleteProps = {
  src: string;
};

const PictureWithDelete: React.FC<PictureWithDeleteProps> = ({ src }) => {
  return (
    <div className="group relative">
      <img
        src={src}
        className="h-16 w-16 border-2 border-element-border/50 rounded-6px"
      />
      <div
        className="h-6 w-6 bg-white rounded-sm border-1 border-element-border flex items-center justify-center absolute group-hover:opacity-100 opacity-0 transition-opacity duration-300 cursor-pointer"
        style={{ right: -6, top: -6 }}
      >
        <MinusIcon width={20} height={20} />
      </div>
    </div>
  );
};
