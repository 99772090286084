import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../../../Common/Button/Button";
import {RightModal} from "../../../Common/RightModal/RightModal";
import {Separator} from "../../../Common/Separator/Separator";
import {TextInput} from "../../../Common/TextInput/TextInput";
import {RentalPlatformsAirbnbSettingsProps} from "./Platforms.type";

import LinkIcon from "../../../../assets/icons/link.svg?react";
import paths from "../../../../constants/paths";
import {post} from "../../../../helpers/APIHelper";
import {ErrorMessage} from "../../../Common/ErrorMessage/ErrorMessage";
import {InputSelect} from "../../../Common/InputSelect/InputSelect";

export const RentalPlatformsAirbnbSettingsModal: React.FC<
  RentalPlatformsAirbnbSettingsProps
> = ({
  platformAirbnb,
  isVisible,
  onClose,
  setPlatform,
  setOpenDesynchronizeModal,
  airBnbListingData,
}) => {
  const {t} = useTranslation();

  const {email, name, listing_id, airbnb_link} = airBnbListingData || {};

  const isConnected = platformAirbnb !== null;

  const openConfirmModal = () => {
    setPlatform("airbnb");
    setOpenDesynchronizeModal(true);
  };

  return (
    <RightModal
      isVisible={isVisible}
      onClose={onClose}
      title={t("Rental.Infos.Platforms.Airbnb.title")}
    >
      {isConnected ? (
        <div className="flex flex-col w-full">
          <div className="flex-1 space-y-3">
            <a href={`${airbnb_link ?? ""}`} target="_blank">
              <Button
                RightIcon={LinkIcon}
                type="secondary"
                disabled={!isConnected}
              >
                {t("Rental.Infos.Platforms.Airbnb.view")}
              </Button>
            </a>

            <TextInput
              label={t("Rental.Infos.Platforms.Airbnb.emailFieldLabel")}
              disabled={true}
              placeholder={`${email ?? ""}`}
            />
            <TextInput
              label={t("Rental.Infos.Platforms.Airbnb.adFieldLabel")}
              disabled={true}
              placeholder={`${listing_id ?? ""} - ${name ?? ""}`}
            />
          </div>

          {/* <p className="font-semibold cursor-pointer text-icon-active hover:underline">
            <Link
              to="https://help.superhote.com/support/solutions/articles/150000113541-synchroniser-et-importer-les-logements-depuis-booking-vers-superhote"
              target="_blank"
            >
              {t("Rental.Infos.Platforms.Booking.tutorial")}
            </Link>
          </p> */}

          <Separator />

          <div className="flex gap-x-3">
            <Button type="secondary" onClick={onClose}>
              {t("Global.cancel")}
            </Button>

            <Button type="destructive" onClick={openConfirmModal}>
              {t("Global.desynchronize")}
            </Button>
          </div>
        </div>
      ) : null}
    </RightModal>
  );
};
