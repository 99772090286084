import React, {useEffect, useState} from "react";
import GuestPageTabsNav from "./GuestPageTabsNav";
import GuestPageReservation from "../GuestPageReservation/GuestPageReservation";
import GuestPageExtra from "../GuestPageExtra/GuestPageExtra";
import GuestPagePayment from "../GuestPagePayment/GuestPagePayment";
import GuestPageContact from "../GuestPageContact/GuestPageContact";
import {
  GuestPageConnectResponse,
  ReservationResponse,
} from "../../../types/GETTypes";
import GuestPageContract from "../GuestPageContract/GuestPageContract";
import GuestPageExtraPayment from "../GuestPageExtra/GuestPageExtraPayment";
import GuestPageExtraHistorical from "../GuestPageExtra/GuestPageExtraHistorical";
import GuestPageLayout from "../Common/GuestPageLayout";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import GuestPageInstructionCheckin from "../GuestPageReservation/GuestPageInstructionCheckin";
import GuestPageInstructionCheckout from "../GuestPageReservation/GuestPageInstructionCheckout";
import GuestPageWelcomeGuide from "../GuestPageReservation/GuestPageWelcomeGuide";
import {capitalizeFirstLetter} from "../../../helpers/stringHelper";
import {useTranslation} from "react-i18next";
import {defaultLang} from "../../../constants/lang";

interface GuestPage {}
function getTabAfterUuid(url: string) {
  const urlObj = new URL(url);

  const urlTabValue = urlObj.searchParams.get("tab");

  return urlTabValue;
}

export type tabType =
  | "reservation"
  | "instructionCheckin"
  | "instructionCheckout"
  | "welcomeGuide"
  | "extra"
  | "extraPayment"
  | "extraHistorical"
  | "payment"
  | "contact"
  | "contract";

export type tabUrlType =
  | "checkin"
  | "checkout"
  | "guide"
  | "extras"
  | "extrasHistorical"
  | "invoice"
  | "";

export interface GuestPageTab {
  onChangeTab?: (tab: tabType) => void;
  tabUrl?: tabUrlType;
}

const GuestPageHomeComponent: React.FC<GuestPage> = ({}) => {
  const {t, i18n} = useTranslation();
  const {guestPageData} = useGuestPageDataStore();
  const [tab, setTab] = useState<tabType>("reservation");
  const [tabUrl, setTabUrl] = useState<tabUrlType>("");
  const onChangeTab = (tab: tabType) => {
    setTab(tab);
  };
  useEffect(() => {
    changeTitle();
  }, [tab]);
  useEffect(() => {
    detectSavedLanguage();
    detectUrl();
  }, []);

  const changeTitle = () => {
    const {rental, guest} = guestPageData || {};
    let title = "";
    switch (tab) {
      case "extra":
      case "extraHistorical":
      case "extraPayment":
        title = t("Tab.guestPageTitle");
        break;
      default:
        title = `${capitalizeFirstLetter(
          rental?.name
        )} - ${capitalizeFirstLetter(
          guest?.first_name
        )} ${capitalizeFirstLetter(guest?.last_name)}`;
        break;
    }
    document.title = title;
  };

  const detectUrl = () => {
    const url = window.location.href;
    const tabUrl = getTabAfterUuid(url) as tabUrlType;
    setTabUrl(tabUrl);
    switch (tabUrl) {
      case "checkin":
        setTab("instructionCheckin");
        break;
      case "checkout":
        setTab("instructionCheckout");
        break;
      case "guide":
        setTab("welcomeGuide");
        break;
      case "extras":
        setTab("extra");
        break;
      case "extrasHistorical":
        setTab("extraHistorical");
        break;
      case "invoice":
        setTab("payment");
        break;
    }
  };

  const detectSavedLanguage = () => {
    const savedLanguage = localStorage.getItem("language") || defaultLang; // Default to English
    i18n.changeLanguage(savedLanguage.toLowerCase());
  };
  const pageByTab = () => {
    switch (tab) {
      case "reservation":
        return (
          <GuestPageLayout tab={tab} setTab={setTab}>
            <GuestPageReservation onChangeTab={onChangeTab} />
          </GuestPageLayout>
        );
      case "instructionCheckin":
        return (
          <GuestPageLayout tab={tab} setTab={setTab}>
            <GuestPageInstructionCheckin onChangeTab={onChangeTab} />
          </GuestPageLayout>
        );
      case "instructionCheckout":
        return (
          <GuestPageLayout tab={tab} setTab={setTab}>
            <GuestPageInstructionCheckout onChangeTab={onChangeTab} />
          </GuestPageLayout>
        );
      case "welcomeGuide":
        return (
          <GuestPageLayout tab={tab} setTab={setTab}>
            <GuestPageWelcomeGuide onChangeTab={onChangeTab} />
          </GuestPageLayout>
        );
      case "extra":
        return (
          <GuestPageLayout tab={tab} setTab={setTab}>
            <GuestPageExtra onChangeTab={onChangeTab} />
          </GuestPageLayout>
        );
      case "extraPayment":
        return (
          <GuestPageLayout tab={tab} setTab={setTab}>
            <GuestPageExtraPayment onChangeTab={onChangeTab} />
          </GuestPageLayout>
        );
      case "extraHistorical":
        return (
          <GuestPageLayout tab={tab} setTab={setTab}>
            <GuestPageExtraHistorical onChangeTab={onChangeTab} />
          </GuestPageLayout>
        );
      case "payment":
        return (
          <GuestPageLayout tab={tab} setTab={setTab}>
            <GuestPagePayment tabUrl={tabUrl} />
          </GuestPageLayout>
        );
      case "contact":
        return (
          <GuestPageLayout tab={tab} setTab={setTab}>
            <GuestPageContact />
          </GuestPageLayout>
        );
      case "contract":
        return (
          <GuestPageLayout tab={tab} setTab={setTab}>
            <GuestPageContract />
          </GuestPageLayout>
        );
      default:
        return (
          <GuestPageLayout tab={tab} setTab={setTab}>
            <GuestPageReservation onChangeTab={onChangeTab} />
          </GuestPageLayout>
        );
    }
  };
  return pageByTab();
};

export default GuestPageHomeComponent;
