import React from "react";
import { Title } from "../../Common/Title/Title";
import { useTranslation } from "react-i18next";
import { ResetThanksProps } from "./ResetThanks.type";
import { Callout } from "../../Common/Callout/Callout";
import { Button } from "../../Common/Button/Button";
import { useNavigate } from "react-router-dom";
import paths from "../../../constants/paths";

import ArrowRightIcon from "../../../assets/icons/arrow-right-white.svg?react";

export const ResetThanks: React.FC<ResetThanksProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(paths.LOGIN);
  };

  return (
    <div>
      <Title>{t("ResetThanks.title")}</Title>

      <div className="mt-6">
        <Callout title={t("ResetThanks.calloutTitle")} />
      </div>

      <div className="mt-6">
        <Button onClick={handleClick} RightIcon={ArrowRightIcon}>
          {t("ResetThanks.submit")}
        </Button>
      </div>
    </div>
  );
};
