import React from "react";
import { OperationalManagementRoleListItemResponse } from "../../../types/GETTypes";
import { getRoleIcon } from "../ManagementRoleList/ManagementRoleTableItem";

export const ManagementTeamRoleBadge: React.FC<{
  role: OperationalManagementRoleListItemResponse;
}> = ({ role }) => {
  return (
    <div className="h-10 bg-element-background rounded-md flex flex-row gap-1 items-center px-2">
      {getRoleIcon(role)}
      <p className="font-semibold text-low-contrast">{role.name}</p>
    </div>
  );
};
