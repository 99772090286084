import React from "react";

export const ProgressionStepRentalsTableSkeleton: React.FC<{}> = () => {
  return (
    <tr>
      <td className="p-2 text-sm">
        <div className="flex gap-4">
          <img className="w-24 h-16 shrink-0 object-cover rounded-6px border-element-border border-1 bg-element-background" />

          <div className="flex flex-col gap-1 mt-1 animate-pulse">
            <p className="h-2 rounded bg-slate-300 w-72" />
            <p className="h-2 rounded bg-slate-200 w-72" />
            <p className="h-2 rounded bg-slate-200 w-72" />
          </div>
        </div>
      </td>
    </tr>
  );
};
