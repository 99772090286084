import React from "react";
import LayoutBlockComponent from "../Common/LayoutBlockComponent";
import {useTranslation} from "react-i18next";
import {GuestPageConnectResponse} from "../../../types/GETTypes";
import {tabType} from "../GuestPageHome/GuestPageHomeComponent";
import {Button} from "../../Common/Button/Button";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import {replaceWithBr} from "../../../helpers/stringHelper";

const InstructionCheckoutComponent = ({
  onChangeTab,
}: {
  onChangeTab: ((tab: tabType) => void) | undefined;
}) => {
  const {t} = useTranslation();
  const {guestPageData, rentalPreview, isPreviewMode, userPreview} =
    useGuestPageDataStore();
  const {instructions, guest: guestData} = guestPageData || {};
  const {guest_page: {instructions: instructionsPreview} = {}} =
    rentalPreview || {};
  const instructionsData = isPreviewMode ? instructionsPreview : instructions;
  const instruction = instructionsData?.find(
    (instr) => instr.type === "checkout_procedure"
  );
  const {content, infos_enabled} = instruction || {};
  const guest = isPreviewMode ? userPreview : guestData;
  const savedLanguage = localStorage.getItem("language")
  const lang = (savedLanguage ?? guest?.locale ?? "en")?.toLowerCase();
  const contentTrad = content?.[lang]
    ? content[lang]
    : content && content["en"]
    ? content["en"]
    : "";
  return infos_enabled ? (
    <LayoutBlockComponent
      title={t("GuestPage.Reservation.instructionCheckout")}
      icon={"🚪"}
      displayTitles
      blockStyle={{
        childrenDivStyle: "px-1 pl-2 pb-8",
        parentDivStyle: "mb-8 ",
        titleStyle: "md:text-[32px]",
      }}
    >
      <div className="md:flex md:flex-col md:mt-6 md:w-full md:mr-6 md:max-h-[265px]">
        <p
          className="text-high-contrast text-base md:w-full line-clamp-instruction md:text-lg"
          dangerouslySetInnerHTML={{__html: replaceWithBr(contentTrad)}}
        />
      </div>
      <div className="flex flex-row justify-start w-full md:w-80 md:self-end mt-2">
        <Button
          type="secondary"
          displayLargeBtn={true}
          buttonClassName="md:h-12 ml-0"
          textClassName="md:text-xl"
          onClick={() => onChangeTab && onChangeTab("instructionCheckout")}
        >
          {t("GuestPage.Reservation.instructionsDetails")}
        </Button>
      </div>
    </LayoutBlockComponent>
  ) : null;
};

export default InstructionCheckoutComponent;
