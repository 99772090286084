import React from "react";
import { useTranslation } from "react-i18next";
import { SettingElement } from "../../../Common/SettingElement/SettingElement";

import EuroIcon from "../../../../assets/icons/euro.svg?react";
import GivingMoneyIcon from "../../../../assets/icons/giving-money.svg?react";
import Moon2Icon from "../../../../assets/icons/moon-2.svg?react";
import MoonIcon from "../../../../assets/icons/moon.svg?react";
import PercentageIcon from "../../../../assets/icons/percentage.svg?react";

export const RentalPricingViewSkeleton: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3 animate-pulse">
      <SettingElement
        Icon={EuroIcon}
        title={t("Rental.Pricing.PricingRules.currencyTitle")}
        description={t("Rental.Pricing.PricingRules.currencyDesc")}
      >
        <span className="w-12 h-2 text-lg font-semibold rounded text-active bg-active"></span>
      </SettingElement>

      <SettingElement
        Icon={MoonIcon}
        title={t("Rental.Pricing.PricingRules.priceTitle")}
        description={t("Rental.Pricing.PricingRules.priceDesc")}
      >
        <span className="w-12 h-2 text-lg font-semibold rounded text-active bg-active"></span>
      </SettingElement>

      <SettingElement
        Icon={Moon2Icon}
        title={t("Rental.Pricing.PricingRules.minPriceTitle")}
        description={t("Rental.Pricing.PricingRules.minPriceDesc")}
      >
        <span className="w-12 h-2 text-lg font-semibold rounded text-active bg-active"></span>
      </SettingElement>

      <SettingElement
        Icon={PercentageIcon}
        title={t("Rental.Pricing.PricingRules.vatTitle")}
        description={t("Rental.Pricing.PricingRules.vatDesc")}
      >
        <span className="w-12 h-2 text-lg font-semibold rounded text-active bg-active"></span>
      </SettingElement>

      <SettingElement
        Icon={GivingMoneyIcon}
        title={t("Rental.Pricing.PricingRules.taxTitle")}
        description={t("Rental.Pricing.PricingRules.taxDesc")}
      >
        <span className="w-12 h-2 text-lg font-semibold rounded text-active bg-active"></span>
      </SettingElement>
    </div>
  );
};
