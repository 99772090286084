import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {Button} from "../../Common/Button/Button";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import {
  GuestPageConnectData,
  GuestPageConnectResponse,
  GuestPageLoginDetailsResponse,
} from "../../../types/GETTypes";
import {InputSelect} from "../../Common/InputSelect/InputSelect";
import {InputSelectOptionProps} from "../../Common/InputSelect/InputSelect.type";
import {TextInput} from "../../Common/TextInput/TextInput";
import {PhoneInput} from "../../Common/PhoneInput/PhoneInput";
import AlertCircle from "../../../assets/icons/alert-circle.svg?react";
import {CustomFile} from "./FilesRequiredComponent";
import {post} from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";
import {TextAreaInput} from "../../Common/TextAreaInput/TextAreaInput";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import {CheckBox} from "../../Common/CheckBox/CheckBox";
import useGuestPageTextCoverStore from "../../../stores/guestPageStore/useGuestPageTextCoverStore";
import {
  findClosestTime,
  generateHoursRentalTime,
} from "../../../helpers/selectHelper";
import useGuestPageDataStore from "../../../stores/guestPageStore/useGuestPageDataStore";
import useWindowSize from "../../../hooks/useWindowSize";
import {cn} from "../../../helpers/classHelper";
import GuestPageConditions from "../Common/GuestPageConditions";
import cguText from "./cguText";
import ModalFilesFormComponent from "./ModalFilesFormComponent";
import FileButtonComponent from "./FileButtonComponent";
import {useParams} from "react-router-dom";
import {NumberInput} from "../../Common/NumberInput/NumberInput";
import {defaultLang} from "../../../constants/lang";
import {ValueType} from "../../../types/commonTypes";

interface GuestPageFormProps {
  guestLoginDetails: GuestPageLoginDetailsResponse | undefined;
  languages: InputSelectOptionProps[];
  handleLogin: (guestData?: GuestPageConnectResponse) => void;
}

export type Doc = {
  label: string;
  enabled: boolean;
  file: string;
  name: nameTypeFile;
  hasLabel: boolean;
  missing: boolean;
};

export type DocsState = {
  card: Doc[];
  passport: Doc[];
  selfie: Doc[];
  cb: Doc[];
};

export type nameTypeFile =
  | "id_card_recto"
  | "id_card_verso"
  | "passport"
  | "selfie"
  | "cb";

const GuestPageForm: React.FC<GuestPageFormProps> = ({
  guestLoginDetails,
  languages,
  handleLogin,
}) => {
  const {isMobile} = useWindowSize();
  const {guestPageLink} = useParams();
  const {t, i18n} = useTranslation();
  const {storeTitle, storeSubTitle, title, subTitle} =
    useGuestPageTextCoverStore();
  const {isPreviewMode, userPreview, rentalPreview} = useGuestPageDataStore();
  const {
    reservation_id,
    rental_cover,
    guest_first_name,
    guest_locale,
    rental_checkin_min_time,
    rental_checkin_max_time,
    rental_checkout_min_time,
    rental_checkout_max_time,
    rental_max_beds,
    reservation_time_in,
    reservation_time_out,
    id_card_enabled: idCardEnabled,
    passport_enabled: passportEnabled,
    selfie_enabled: selfieEnabled,
    cb_enabled: cbEnabled,
    guest_page_beds_count,
  } = guestLoginDetails || {};

  const {
    id_card_enabled: idCardEnabledPreview,
    passport_enabled: passportEnabledPreview,
    selfie_enabled: selfieEnabledPreview,
    cb_enabled: cbEnabledPreview,
  } = rentalPreview?.guest_page || {};

  const {
    checkin_min_time: rentalPreviewCheckinMinTime,
    checkout_min_time: rentalPreviewCheckoutMinTime,
  } = rentalPreview?.details?.informations || {};
  const {cover: rentalPreviewCover} = rentalPreview?.details?.photos || {};
  const checkinTimes = generateHoursRentalTime(
    rental_checkin_min_time,
    rental_checkin_max_time
  );
  const checkoutTimes = generateHoursRentalTime(
    rental_checkout_min_time,
    rental_checkout_max_time
  );

  const bedsCount = isPreviewMode ? 1 : guest_page_beds_count;
  const time_in = isPreviewMode
    ? rentalPreviewCheckinMinTime
    : reservation_time_in;
  const time_out = isPreviewMode
    ? rentalPreviewCheckoutMinTime
    : reservation_time_out;
  const rentalCover = isPreviewMode ? rentalPreviewCover : rental_cover;

  const id_card_enabled = isPreviewMode ? idCardEnabledPreview : idCardEnabled;
  const passport_enabled = isPreviewMode
    ? passportEnabledPreview
    : passportEnabled;
  const selfie_enabled = isPreviewMode ? selfieEnabledPreview : selfieEnabled;
  const cb_enabled = isPreviewMode ? cbEnabledPreview : cbEnabled;

  const checkDoc =
    !!id_card_enabled || !!passport_enabled || !!selfie_enabled || !!cb_enabled;

  const {
    control,
    handleSubmit,
    formState: {errors},
    setValue,
    getValues,
  } = useForm<GuestPageConnectData>({
    // resolver,
    defaultValues: {
      reservation_id,
      language: guest_locale?.toUpperCase() ?? "EN",
      email: "",
      phone: "",
      guest_page_beds_count: bedsCount,
      time_in: time_in,
      time_out: time_out,
      remarks: "",
    },
    mode: "all",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [cguCheck, setCguCheck] = useState(false);
  const [cguRequiredError, setCguRequiredError] = useState("");
  const [openCgu, setOpenCgu] = useState(false);
  const [openModalFile, setOpenModalFile] = useState(false);
  const [fileType, setFileType] = useState<nameTypeFile | null>(null);
  const [newFiles, setNewFiles] = useState<CustomFile[]>();
  const [docs, setDocs] = useState<DocsState>({
    card: [
      {
        label: t("GuestPage.LoginDetails.card_id"),
        enabled: !!id_card_enabled,
        file: "",
        name: "id_card_recto" as nameTypeFile,
        hasLabel: true,
        missing: false,
      },
      {
        label: "",
        enabled: !!id_card_enabled,
        file: "",
        name: "id_card_verso" as nameTypeFile,
        hasLabel: false,
        missing: false,
      },
    ],
    passport: [
      {
        label: t("GuestPage.LoginDetails.passport"),
        enabled: !!passport_enabled,
        file: "",
        name: "passport" as nameTypeFile,
        hasLabel: true,
        missing: false,
      },
    ],
    selfie: [
      {
        label: t("GuestPage.LoginDetails.selfie"),
        enabled: !!selfie_enabled,
        file: "",
        name: "selfie" as nameTypeFile,
        hasLabel: true,
        missing: false,
      },
    ],
    cb: [
      {
        label: t("GuestPage.LoginDetails.cb"),
        enabled: !!cb_enabled,
        file: "",
        name: "cb" as nameTypeFile,
        hasLabel: true,
        missing: false,
      },
    ],
  });
  const [files, setFiles] = useState<CustomFile[]>([]);

  const updateDocs = ({
    setter = true,
    docName,
  }: {
    setter?: boolean;
    docName?: nameTypeFile;
  }): DocsState => {
    let updatedDocs: DocsState = {...docs};
    const updated = {...docs};

    (Object.keys(updatedDocs) as (keyof DocsState)[]).forEach((category) => {
      updated[category] = updated[category].map((doc) => {
        if (docName && doc.name !== docName) {
          // If a specific docName is provided and it doesn't match, return the doc as is
          return doc;
        }

        const fileExists = doc.enabled
          ? files.some(
              (file) => file.name === doc.name && file.file && doc.enabled
            )
          : true;

        return {...doc, missing: !fileExists};
      });
    });

    setter && setDocs(updated);
    return updated;
  };

  const handleSave = async (values: GuestPageConnectData) => {
    setLoading(true);
    if (isPreviewMode) {
      handleLogin();
      setLoading(false);
      return;
    }
    const formData = new FormData();
    for (const [key, value] of Object.entries(values)) {
      formData.append(key, value?.toString() ?? "");
    }

    const updatedDocs = updateDocs({});

    ////////////////////////////
    //Check if any document is missing and cgu is check
    const anyMissing = Object.values(updatedDocs)
      .flat()
      .some((doc) => doc.missing);
    if (anyMissing) {
      setLoading(false);
      return;
    }
    if (!cguCheck) {
      setCguRequiredError(t("GuestPage.LoginDetails.acceptCgu"));
      setLoading(false);
      return;
    }
    setCguRequiredError("");
    ////////////////////////////////////////////////////////////////

    files.forEach((fileObj) => {
      if (fileObj.file) {
        formData.append(fileObj.name, fileObj.file);
      }
    });
    formData.append("reservation_id", String(reservation_id));
    formData.append("guest_page_link", guestPageLink ?? "");
    formData.append("guest_page_connected", "0");
    try {
      const res = await post(
        `${import.meta.env.VITE_API_URL}${paths.API.GUEST_PAGE.CONNECT}`,
        formData
      );

      if (res?.data?.success) {
        const guestData = res?.data?.result;
        setError("");
        handleLogin(guestData);
      } else {
        setError(res?.response?.data?.message);
      }
    } catch (e: any) {
      setError(e?.message);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleFiles = (files: CustomFile[]) => {
    setFiles(files);
  };

  const deleteFiles = (fileName: nameTypeFile) => {
    let newUpdatedFiles: CustomFile[] = [];
    switch (fileName) {
      case "id_card_recto":
      case "id_card_verso":
        newUpdatedFiles = files.filter(
          (file) =>
            file.name !== "id_card_recto" && file.name !== "id_card_verso"
        );
        break;
      case "passport":
      case "selfie":
      case "cb":
        newUpdatedFiles = files.filter((file) => file.name !== fileName);
        break;
      default:
        newUpdatedFiles = files;
        break;
    }
    setNewFiles(newUpdatedFiles);
    setFiles(newUpdatedFiles);
  };

  const cardLabel = docs.card.find(
    (item) => item.hasLabel && item.enabled
  )?.label;
  const passPortLabel = docs.passport.find(
    (item) => item.hasLabel && item.enabled
  )?.label;
  const selfieLabel = docs.selfie.find(
    (item) => item.hasLabel && item.enabled
  )?.label;
  const cbLabel = docs.cb.find((item) => item.hasLabel && item.enabled)?.label;

  const [defaultCountry, setDefaultCountry] = useState("FR");

  const handleAppChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang?.toLowerCase()).catch(() => {
      // Handle error and fallback
      i18n.changeLanguage(defaultLang); // Fallback to English
      handleDefaultCountry(defaultLang?.toUpperCase());
    });
    handleDefaultCountry(lang?.toUpperCase());
  };

  const handleDefaultCountry = (country?: string) => {
    let defaultCountry = country
      ? country
      : isPreviewMode
      ? userPreview?.locale?.toUpperCase()
      : getValues("language")?.toUpperCase();
    if (defaultCountry === "EN") {
      defaultCountry = "GB";
    }
    if (defaultCountry) {
      setDefaultCountry(defaultCountry);
    }
  };

  const checkMissingFiles = (docName?: nameTypeFile) => {
    //Check if any document is missing
    const updatedDocs = updateDocs({setter: false, docName});

    const anyMissing = Object.values(updatedDocs)
      .flat()
      .some((doc) => doc.missing);
    return anyMissing;
  };

  const openModal = (fileType: nameTypeFile) => {
    setFileType(fileType);
    setOpenModalFile(true);
  };
  const closeModal = () => {
    setFileType(null);
    setOpenModalFile(false);
  };

  useEffect(() => {
    if (!openCgu) {
      if (isPreviewMode) {
        storeTitle(`${t("GuestPage.LoginDetails.preview")}`);
        storeSubTitle && storeSubTitle("");
      } else {
        storeTitle(
          `${t("GuestPage.LoginDetails.hello")} ${guest_first_name} !`
        );
        storeSubTitle &&
          storeSubTitle(t("GuestPage.LoginDetails.descriptionAccess"));
      }
    } else {
      storeTitle(t("GuestPage.LoginDetails.cgu"));
      storeSubTitle && storeSubTitle("");
    }
  }, [openCgu, i18n.language]);

  useEffect(() => {
    const locale = isPreviewMode ? userPreview?.locale : guest_locale;
    const language = locale?.toLowerCase();
    const defaut = (language !== "en" && language !== "fr") ? "en" : language;

    i18n
      .changeLanguage(defaut)
      .then(() => {
        defaut && localStorage.setItem("language", defaut); // Save the language to localStorage
      })
      .catch(() => {
        // Handle error and fallback
        i18n.changeLanguage(defaultLang); // Fallback to English
      });
    if (locale) {
      locale && setValue("language", defaut.toUpperCase());
    }
    handleDefaultCountry(defaut.toUpperCase());
  }, []);

  const onChangeLang = (value: ValueType) => {
    const val = (value !== "EN" && value !== "FR") ? "EN" : value;
    setValue("language", value?.toString());
    handleAppChangeLanguage(val?.toString());
    val && localStorage.setItem("language", val?.toString()?.toLowerCase());
  };

  const openUrlCgu = () => {
    window.open('https://www.superhote.com/legal/cgu', '_blank');
  }

  return (
    <>
      <div className="w-full">
        {openCgu ? (
          <>
            <div
              className="relative h-44 md:h-32 bg-cover bg-center hidden md:block"
              style={{backgroundImage: `url('${rentalCover}')`}}
            >
              <div
                className={`absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center justify-center ${
                  isPreviewMode ? "justify-start md:justify-center " : ""
                }`}
              >
                <div className="text-white px-4 md:w-2/3 md:pl-0">
                  <h1 className="flex text-[32px] md:text-[40px] font-bold">
                    {title}
                  </h1>
                  <p className="mt-1 text-base md:text-xl font-medium leading-7">
                    {subTitle}
                  </p>
                </div>
              </div>
            </div>
            <div className="md-justify-center md:flex md:flex-1 md:flex-col md:items-center">
              <div className="gap-8 h-[80%] md:w-[70%]">
                <GuestPageConditions
                  title={t("GuestPage.LoginDetails.cgu")}
                  conditions={cguText}
                  setOpenConditions={setOpenCgu}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <ModalFilesFormComponent
              type={fileType}
              onClose={closeModal}
              isVisible={openModalFile}
              docs={docs}
              loading={loading}
              handleFiles={handleFiles}
              setNewFiles={setNewFiles}
              files={files}
              newFiles={newFiles}
            />
            <div
              className="relative h-44 md:h-40 bg-cover bg-center"
              style={{backgroundImage: `url('${rentalCover}')`}}
            >
              <div
                className={cn(
                  `absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center ${
                    isPreviewMode ? "justify-start md:justify-center " : ""
                  }`
                )}
              >
                <div className={`text-white px-4 md:w-2/3 md:pl-0 mb-4`}>
                  <h1 className="flex text-[28px] md:text-[40px] font-bold">
                    {title}
                  </h1>
                  <p className="mt-1 text-base md:text-xl font-medium leading-7">
                    {subTitle}
                  </p>
                </div>
              </div>
            </div>
            <div className="md-justify-center md:flex md:flex-1 md:flex-col md:items-center">
              <div className="gap-8 h-[80%] p-4 px-6 md:w-[70%]">
                <Controller
                  control={control}
                  name="language"
                  rules={{
                    required: {
                      value: !isPreviewMode,
                      message: t("GuestPage.LoginDetails.fieldRequired"),
                    },
                    min: {
                      value: 1,
                      message: t("GuestPage.LoginDetails.fieldRequired"),
                    },
                  }}
                  render={({field: {value}}) => {
                    return (
                      <div className="mt-3">
                        <InputSelect
                          label={t("GuestPage.LoginDetails.language")}
                          items={languages}
                          selectedValue={value}
                          onSelect={(value) => {
                            onChangeLang(value);
                          }}
                          error={errors.language?.message}
                          required={true}
                          disabled={loading}
                          classNames={{
                            input: "md:text-lg",
                            label: "md:text-lg",
                            labelRequired: "md:text-xl",
                            buttonText: "md:text-lg",
                            error: "md:text-lg",
                            option: "md:text-lg",
                            labelSelected: "md:text-lg",
                            labelSelectedActive: "md:text-lg",
                          }}
                        />
                      </div>
                    );
                  }}
                />

                <div className="mt-4 bg-element-background border-1 rounded-md border-element-border p-4">
                  <div className="flex-col">
                    <div className="text-high-contrast font-bold text-xl md:text-xl">
                      {t("GuestPage.LoginDetails.infos")}
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col md:flex-row md:items-center justify-between">
                    <Controller
                      control={control}
                      name="email"
                      rules={{
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: t("Login.emailInvalid"),
                        },
                        required: {
                          value: !isPreviewMode,
                          message: t("GuestPage.LoginDetails.fieldRequired"),
                        },
                      }}
                      render={({field: {value, onChange}}) => {
                        return (
                          <div className="mt-3 md:w-1/2 md:mr-2">
                            <TextInput
                              label={t("GuestPage.LoginDetails.email")}
                              value={value}
                              size="large"
                              required
                              placeholder={t(
                                "GuestPage.LoginDetails.emailPlaceholder"
                              )}
                              onChangeText={onChange}
                              error={errors.email?.message}
                              disabled={loading}
                              classNames={{
                                input: "md:text-lg",
                                label:
                                  "md:text-lg md:w-auto md:w-auto md:max-w-full",
                                labelRequired: "md:text-xl",
                                error: "md:text-lg",
                              }}
                            />
                          </div>
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="phone"
                      rules={{
                        required: {
                          value: !isPreviewMode,
                          message: t("GuestPage.LoginDetails.fieldRequired"),
                        },
                      }}
                      render={({field: {onChange, value}}) => (
                        <div className="mt-3 md:w-1/2 md:ml-2">
                          <PhoneInput
                            label={t("GuestPage.LoginDetails.phone")}
                            required
                            classNames={{
                              input: "md:text-lg h-9",
                              label:
                                "md:text-lg md:w-auto md:w-auto md:max-w-full",
                              labelRequired: "md:text-xl",
                              error: "md:text-lg",
                              divLabel: "mb-0 justify-start",
                            }}
                            value={value}
                            placeholder={t(
                              `GuestPage.LoginDetails.${
                                isMobile
                                  ? "phonePlaceholderMobile"
                                  : "phonePlaceholder"
                              }`
                            )}
                            onChangeText={onChange}
                            error={errors.phone?.message}
                            disabled={loading}
                            defaultCountry={defaultCountry}
                            // errorType="FORM"
                          />
                        </div>
                      )}
                    />
                  </div>

                  <Controller
                    control={control}
                    name="guest_page_beds_count"
                    render={({field: {onChange, value}}) => (
                      <div className="my-3 md:mt-3 md:mb-0">
                        <NumberInput
                          label={t("GuestPage.LoginDetails.beds")}
                          classNames={{
                            input: "md:text-lg",
                            label:
                              "md:text-lg md:w-auto md:w-auto md:max-w-full",
                            labelRequired: "md:text-lg",
                            error: "md:text-lg",
                            divLabel: "mb-0 justify-start h-9",
                          }}
                          size="large"
                          value={value}
                          onChangeText={onChange}
                          error={errors.guest_page_beds_count?.message}
                          disabled={loading}
                          max={rental_max_beds ?? Infinity}
                        />
                      </div>
                    )}
                  />

                  <span className="font-light text-low-contrast md:text-xs text-sm">
                    {t("GuestPage.LoginDetails.bedsDetails")}
                  </span>

                  <Controller
                    control={control}
                    name="remarks"
                    render={({field: {value, onChange}}) => {
                      return (
                        <div className="my-3">
                          <TextAreaInput
                            label={t("GuestPage.LoginDetails.remarks")}
                            value={value}
                            placeholder={t(
                              "GuestPage.LoginDetails.remarksPlaceholder"
                            )}
                            onTextChange={onChange}
                            error={errors.remarks?.message}
                            disabled={loading}
                            rows={2}
                            classNames={{
                              input: "md:text-lg",
                              label: "md:text-lg",
                              labelRequired: "md:text-xl",
                              error: "md:text-lg",
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </div>

                <div className="mt-4 bg-element-background border-1 rounded-md border-element-border p-4">
                  <div className="flex-col">
                    <div className="text-high-contrast font-bold text-xl md:text-xl">
                      {t("GuestPage.LoginDetails.checkinCheckout")}
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col">
                    <div className="flex flex-col md:flex-row">
                      <Controller
                        control={control}
                        name="time_in"
                        render={({field: {value}}) => {
                          return (
                            <div className="mt-3 md:w-1/2 md:mr-2">
                              <InputSelect
                                label={t("GuestPage.LoginDetails.timeIn")}
                                items={checkinTimes}
                                selectedValue={findClosestTime(
                                  value ?? "",
                                  checkinTimes
                                )}
                                onSelect={(value) => {
                                  setValue("time_in", value?.toString());
                                }}
                                error={errors.time_in?.message}
                                disabled={loading}
                                classNames={{
                                  input: "md:text-lg",
                                  label: "md:text-lg md:w-auto",
                                  labelRequired: "md:text-xl",
                                  buttonText: "md:text-lg",
                                  error: "md:text-lg",
                                  option: "md:text-lg",
                                  labelSelected: "md:text-lg",
                                  labelSelectedActive: "md:text-lg",
                                }}
                              />
                            </div>
                          );
                        }}
                      />
                      <Controller
                        control={control}
                        name="time_out"
                        render={({field: {value}}) => {
                          return (
                            <div className="mt-3 md:w-1/2 md:ml-2">
                              <InputSelect
                                label={t("GuestPage.LoginDetails.timeOut")}
                                items={checkoutTimes}
                                selectedValue={findClosestTime(
                                  value ?? "",
                                  checkoutTimes
                                )}
                                onSelect={(value) => {
                                  setValue("time_out", value?.toString());
                                }}
                                error={errors.time_out?.message}
                                disabled={loading}
                                classNames={{
                                  input: "md:text-lg",
                                  label: "md:text-lg md:w-auto",
                                  labelRequired: "md:text-xl",
                                  buttonText: "md:text-lg",
                                  error: "md:text-lg",
                                  option: "md:text-lg",
                                  labelSelected: "md:text-lg",
                                  labelSelectedActive: "md:text-lg",
                                }}
                              />
                            </div>
                          );
                        }}
                      />
                    </div>
                    <div
                      className={`flex flex-1 flex-row h-22 pb-3 pt-3 rounded-lg bg-gray-200 items-center mt-3 md:mt-5 px-3`}
                    >
                      <div className="">
                        <AlertCircle
                          className="fill-low-contrast shrink-0"
                          width={15}
                          height={20}
                        />
                      </div>
                      <div className="flex-column ml-2">
                        <p className="font-bold text-sm text-low-contrast md:text-lg">
                          {t("GuestPage.LoginDetails.descriptionTimes")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {checkDoc ? (
                  <div className="mt-4 bg-element-background border-1 rounded-md border-element-border p-4 pb-6">
                    <div className="flex-col">
                      <div className="text-high-contrast font-bold text-xl md:text-xl">
                        {t("GuestPage.LoginDetails.filesRequired")}
                      </div>
                    </div>
                    <>
                      <div className="flex flex-1 flex-col my-4">
                        <p className="mb-1 text-xs font-normal text-left text-low-contrast md:text-lg">
                          {t("GuestPage.LoginDetails.filesRequiredDescription")}
                        </p>
                      </div>
                      <div className="flex flex-col gap-y-6">
                        {cardLabel && (
                          <FileButtonComponent
                            nameType={"id_card_recto"}
                            loading={loading}
                            label={cardLabel}
                            buttonLabel={"cardIdButtonLabel"}
                            checkMissingFiles={checkMissingFiles}
                            openModal={openModal}
                            deleteFiles={deleteFiles}
                          />
                        )}
                        {passPortLabel && (
                          <FileButtonComponent
                            nameType={"passport"}
                            loading={loading}
                            label={passPortLabel}
                            buttonLabel={"passeportButtonLabel"}
                            checkMissingFiles={checkMissingFiles}
                            openModal={openModal}
                            deleteFiles={deleteFiles}
                          />
                        )}
                        {selfieLabel && (
                          <FileButtonComponent
                            nameType={"selfie"}
                            loading={loading}
                            label={selfieLabel}
                            buttonLabel={"selfieButtonLabel"}
                            checkMissingFiles={checkMissingFiles}
                            openModal={openModal}
                            deleteFiles={deleteFiles}
                          />
                        )}
                        {cbLabel && (
                          <FileButtonComponent
                            nameType={"cb"}
                            loading={loading}
                            label={cbLabel}
                            buttonLabel={"cbButtonLabel"}
                            checkMissingFiles={checkMissingFiles}
                            openModal={openModal}
                            deleteFiles={deleteFiles}
                          />
                        )}
                      </div>
                    </>
                  </div>
                ) : null}

                <div className="flex flex-1 flex-col md:flex-row justify-between w-full space-y-4 md:mt-8 mb-4 mt-4">
                  <div
                    className={`flex flex-1 md:flex-0 flex-wrap mt-4  ${
                      cguRequiredError
                        ? "md:flex-col items-center md:items-start"
                        : "items-center"
                    }`}
                  >
                    <CheckBox
                      onChange={(value) => setCguCheck(value)}
                      value={cguCheck}
                      error={cguRequiredError}
                      classNames={{error: "mt-0", parent: "flex flex-row"}}
                    />
                    <div className="flex flex-1 flex-row text-low-contrast text-sm md:text-lg">
                      <label className="">
                        {t("GuestPage.LoginDetails.cguCheckbox")}{" "}
                        <label
                          className="underline underline-offset-2 cursor-pointer"
                          // onClick={() => setOpenCgu(true)}
                          onClick={openUrlCgu}
                        >
                          {t("GuestPage.LoginDetails.cgu")}
                        </label>{" "}
                        <label className="">↗︎.</label>
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-col md:w-[25%]">
                    <ErrorMessage childrenClassName="md:text-lg">
                      {error}
                    </ErrorMessage>
                    <Button
                      className="flex"
                      buttonClassName="md:h-12"
                      textClassName="md:text-xl"
                      RightIcon={ArrowRightWhiteIcon}
                      disabled={
                        isPreviewMode
                          ? false
                          : checkMissingFiles() || !cguCheck || loading
                      }
                      displayLargeBtn={false}
                      loading={loading}
                      onClick={handleSubmit(handleSave)}
                      typeButton="button"
                    >
                      {t("Global.validate")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default GuestPageForm;
