import React from "react";

const GuestPageSkeleton = () => {
  return (
    <div className="w-full">
      <div className="relative h-44 lg:h-32 bg-cover bg-center bg-slate-400 animate-pulse">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="text-white px-4">
            <h1 className="flex lg:text-3xl text-[32px] font-bold"></h1>
            <p className="mt-1 text-base font-normal leading-7"></p>
          </div>
        </div>
      </div>
      <form className="lg-justify-center lg:flex lg:flex-1 lg:flex-col lg:items-center">
        <div className="gap-8 h-[80%] p-4 px-6 lg:w-[70%]">
          <div className="mt-3">
            <div
              className={`flex flex-1 flex-row h-10 pl-4 pr-4 pb-3 pt-3 rounded-lg bg-slate-400 animate-pulse items-start my-4 `}
            >
              <div className="mt-0.5"></div>
              <div className="flex-column ml-2"></div>
            </div>
          </div>
          <div className="mt-3">
            <div
              className={`flex flex-1 flex-row h-10 pl-4 pr-4 pb-3 pt-3 rounded-lg bg-slate-400 animate-pulse items-start my-4 `}
            >
              <div className="mt-0.5"></div>
              <div className="flex-column ml-2"></div>
            </div>
          </div>
          <div className="mt-3">
            <div
              className={`flex flex-1 flex-row h-10 pl-4 pr-4 pb-3 pt-3 rounded-lg bg-slate-400 animate-pulse items-start my-4 mb-0 `}
            >
              <div className="mt-0.5"></div>
              <div className="flex-column ml-2"></div>
            </div>
          </div>
          <div className="flex flex-1 flex-row">
            <div className="w-1/2 mr-2">
              <div
                className={`flex flex-1 flex-row h-10 pl-4 pr-4 pb-3 pt-3 rounded-lg bg-slate-400 animate-pulse items-start my-4 mb-0`}
              >
                <div className="mt-0.5"></div>
                <div className="flex-column ml-2"></div>
              </div>
            </div>
            <div className="w-1/2 ml-2">
              <div
                className={`flex flex-1 flex-row h-10 pl-4 pr-4 pb-3 pt-3 rounded-lg bg-slate-400 animate-pulse items-start my-4 mb-0`}
              >
                <div className="mt-0.5"></div>
                <div className="flex-column ml-2"></div>
              </div>
            </div>
          </div>
          <div
            className={`flex flex-1 flex-row h-10 pl-4 pr-4 pb-3 pt-3 rounded-lg bg-slate-400 animate-pulse items-start my-4 `}
          >
            <div className="mt-0.5"></div>
            <div className="flex-column ml-2"></div>
          </div>

          <div className="flex flex-1 flex-row">
            <div className="flex flex-col items-center justify-center w-[130px] h-[115px] p-4 gap-2 border-dashed cursor-pointer bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active font-semibold bg-slate-400 animate-pulse"></div>
            <div className="flex flex-col items-center justify-center w-[130px] h-[115px] p-4 gap-2 border-dashed cursor-pointer bg-element-background border-1 border-element-border rounded-6px hover:bg-element-background-active font-semibold ml-4 bg-slate-400 animate-pulse"></div>
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-end mb-4 mt-2 mx-6 lg:m-4 lg:mx-4 lg:w-[65%] lg:items-end">
          <div className={`flex flex-1 relative group/tooltip h-9 lg:w-1/4`}>
            <button
              role="button"
              className={`flex-1 bg-gradient-to-b px-2 h-9 border-1 border-black/12 focus:outline-focus rounded-6px font-semibold shadow-sm bg-slate-400 animate-pulse`}
            ></button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GuestPageSkeleton;
